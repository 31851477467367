import { useCallback } from "react";
import { useSearchParams } from "react-router-dom-v5-compat";

export function useSearchParam(
  name: string
): [string | null, (value: string) => void] {
  const [searchParams, setURLSearchParams] = useSearchParams();
  const value = searchParams.get(name) || null;
  const setValue = useCallback(
    (value: string) => {
      setURLSearchParams((current) => {
        const currentParams = Object.fromEntries(current);
        if (value) {
          return { ...currentParams, [name]: value };
        } else {
          delete currentParams[name];
          return currentParams;
        }
      });
    },
    [name, setURLSearchParams]
  );

  return [value, setValue];
}

export default useSearchParam;
