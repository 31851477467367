import { WalletOffer } from "@b2bportal/hopper-wallet-api";
import { CallState } from "@hopper-b2b/types";
import { createSelector } from "@reduxjs/toolkit";
import { ILodgingAppState } from "../../../../reducer";

export const getWalletOffers = (
  state: ILodgingAppState
): WalletOffer[] | undefined => state.wallet.walletOffers;

export const getWalletBestOffers = (
  state: ILodgingAppState
): WalletOffer | undefined => state.wallet.bestOffer;

export const getWalletCreditOffer = (state: ILodgingAppState): WalletOffer =>
  state.wallet.creditOffer;

export const getWalletOffersCallState = (state: ILodgingAppState): CallState =>
  state.wallet.walletOfferCallState;

export const getSelectedOffer = (
  state: ILodgingAppState
): WalletOffer | undefined => state.wallet.selectedWalletOffers;

export const hasSelectedCreditOffer = (state: ILodgingAppState): boolean =>
  !!state.wallet.selectedCreditOffer;

export const getSelectedCreditOffer = (state: ILodgingAppState): WalletOffer =>
  state.wallet.selectedCreditOffer;

export const isWalletOffersLoading = createSelector(
  getWalletOffersCallState,
  (walletOfferCallState: CallState) =>
    walletOfferCallState === CallState.InProcess
);

export const getHasWalletOffers = createSelector(
  getWalletOffers,
  getWalletCreditOffer,
  (offers?: WalletOffer[], creditOffer?: WalletOffer) =>
    !!offers?.length || !!creditOffer
);

export const getTotalWalletSavings = createSelector(
  getSelectedOffer,
  getSelectedCreditOffer,
  (voucher, creditOffer) => {
    return {
      ...voucher?.amount.fiat,
      ...creditOffer?.amount.fiat,
      value:
        (voucher?.amount.fiat.value || 0) +
        (creditOffer?.amount.fiat.value || 0),
    };
  }
);
