import {
  hopperWalletApi,
  GetApplicableWalletOffersRequest,
  GetApplicableWalletOffersResponseSuccess,
} from "@b2bportal/hopper-wallet-api"; //TODO: update import when we move wallet to b2b-interfaces
import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import axios from "axios";
import { trackEvent } from "../tracking/trackEvent";

export const fetchApplicableWalletOffers = async (
  request: GetApplicableWalletOffersRequest,
  apiConfig?: IApiConfig
): Promise<GetApplicableWalletOffersResponseSuccess> => {
  try {
    const response = await hopperWalletApi(axios).apiV0WalletApplicablePost(
      request
    );
    return response.data;
  } catch (error) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      apiConfig
    );
    throw error;
  }
};
