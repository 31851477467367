import {
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
  FlightItinerarySegment,
  FlightItinerarySlice,
  ItinerarySeats,
  Seat,
} from "@b2bportal/air-booking-api";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCaretDown,
  faCaretUp,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trackEvent } from "@hopper-b2b/api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  ClientName,
  DisruptionExerciseProductType,
  DisruptionExerciseProgress,
  DisruptionTrackingEvents,
  IOpenModal,
  IPerson,
  MyTripsFilter,
  MyTripsModalTypes,
  PortalItineraryStatusEnum,
  ScheduleChangeSeverity,
  SeatInfo,
  SelectedSeatsSegment,
  TagColors,
  TagInfo,
  getDepartureSlice,
  getFlightInfoDetails,
  getReturnSlice,
} from "@hopper-b2b/types";
import {
  ActionLink,
  ActionLinks,
  IActionLink,
  Icon,
  IconName,
  MyTripsCard,
  SliceDetails,
  SliceToShow,
  TripInfoDetails,
} from "@hopper-b2b/ui";
import {
  getEnvVariables,
  useDeviceTypes,
  useEnableCfar,
  useEnableDisruptionProtection,
  useEnableMissedConnection,
} from "@hopper-b2b/utilities";
import { Box, Popover, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { MouseEvent, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import {
  fetchFlightCfarCancellationQuote,
  fetchFlightCfarCancellationQuoteV1,
  populateTripQueryParams,
  setDisruptionExerciseProductType,
  setDisruptionExerciseProgress,
  setSelectedFlight,
} from "../../../../../../../TripsList/actions/actions";
import { getLineItems } from "../../../../../../utils/helpers";
import {
  DATE_FORMAT,
  DESKTOP_OFFSET_SCROLL,
  MOBILE_OFFSET_SCROLL,
} from "../../../../constants";
import { AddOnEnum, AddOnsSummary } from "../../../AddOnsSummary";
import { ConfirmationSummary } from "../../../ConfirmationSummary";
import { getConfirmationNumbers } from "../../../ItinerariesModal/components/ConfirmationModalContent/component";
import { PaymentSummary } from "../../../PaymentSummary";
import { RedeemFTCButton } from "../../../RedeemFTCButton";
import { SummaryCard } from "../../../SummaryCard";
import { TravelersSummary } from "../../../TravelersSummary";
import { addFlightType } from "../../helpers";

import "./styles.scss";

interface IDesktopFlightCardProps {
  airlineMap: { [key: string]: Airline | undefined };
  airportMap: { [key: string]: Airport | undefined };
  expandedCard: string;
  flight: BookedFlightItineraryWithDepartureTime;
  banner?: JSX.Element;
  confirmationCodeClassName?: string;
  isPastTrips: boolean;
  isCancel?: boolean;
  isMobile?: boolean;
  onExpandCard: (cardId: string) => void;
  setOpenModal: (modalType: IOpenModal) => void;
  tripsFilter: MyTripsFilter;
}

export const DesktopFlightCard = (props: IDesktopFlightCardProps) => {
  const {
    airlineMap,
    airportMap,
    expandedCard,
    flight,
    isMobile,
    banner,
    confirmationCodeClassName,
    isPastTrips,
    isCancel,
    onExpandCard,
    setOpenModal,
    tripsFilter,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, formatFiatCurrency } = useI18nContext();
  const {
    ancillaries,
    bookedItinerary,
    status,
    travelCredit,
    travelCreditCopy,
  } = flight;

  const departureSlice = getDepartureSlice(bookedItinerary);
  const returnSlice = getReturnSlice(bookedItinerary);
  const { cfar, delay, missedConnection } = ancillaries;
  const passengerMap = {};
  bookedItinerary.passengers.alone.reduce((map, p) => {
    if (!map[p.person.id]) {
      map[p.person.id] = p.person;
    }
    return passengerMap;
  }, passengerMap);
  bookedItinerary.passengers.withLapInfants.reduce((map, p) => {
    if (!map[p.adult.person.id]) {
      map[p.adult.person.id] = p.adult.person;
    }
    return passengerMap;
  }, passengerMap);

  const { matchesMobile } = useDeviceTypes();
  const ftcPopoverRef = useRef<HTMLButtonElement>(null);
  const [ftcPopoverOpen, setFTCPopoverOpen] = useState(false);

  const showFlightCFAR = useEnableCfar();
  const showMissedConnection = useEnableMissedConnection();
  const showDisruption = useEnableDisruptionProtection();

  const hasCfar = showFlightCFAR && !!cfar;
  const hasMissedConnection = showMissedConnection && !!missedConnection;
  const hasDelayDisruption = showDisruption && !!delay;

  // note: hasAddOn can be expanded in the future as we have more features added in
  const hasAddOn = hasCfar || hasMissedConnection || hasDelayDisruption;
  const hasFTC = !!travelCredit && !!travelCreditCopy;

  const closeFTCPopover = () => {
    setFTCPopoverOpen(false);
  };

  const goToExchange = (e?: MouseEvent) => {
    e?.stopPropagation();

    onOpenModal(MyTripsModalTypes.ExchangeFlight);
  };

  const openFTCPopover = () => {
    setFTCPopoverOpen(true);
  };

  const onOpenModal = (type: MyTripsModalTypes) =>
    setOpenModal({
      type,
      selectedItinerary: addFlightType(flight),
    });

  const isWithin24Hours = (flight: BookedFlightItineraryWithDepartureTime) => {
    const timeDiff = dayjs(flight.departureTime).diff(dayjs(), "h");
    return timeDiff <= 24;
  };

  const openCfarModal = () => {
    const contractId = ancillaries.cfar.id.policyId;
    const itineraryId = flight.bookedItinerary.id;

    const isUber = getEnvVariables("clientName") === ClientName.UBER;

    if (isUber) {
      // if Uber migrates to v1, v0 can be deleted along with this client check
      dispatch(
        fetchFlightCfarCancellationQuote({
          contractId,
          itineraryId,
        })
      );
    } else {
      dispatch(
        fetchFlightCfarCancellationQuoteV1({
          itineraryId,
        })
      );
    }

    dispatch(populateTripQueryParams(history));
    onOpenModal(MyTripsModalTypes.CfarFlight);
  };

  const openMissedConnectionModal = () => {
    onOpenModal(MyTripsModalTypes.MissedConnection);
  };

  const openDelayDisruptionModal = () => {
    dispatch(setSelectedFlight(flight));
    dispatch(
      setDisruptionExerciseProductType(
        DisruptionExerciseProductType.ScheduleChange
      )
    );
    trackEvent({
      eventName: DisruptionTrackingEvents.ENTER_DISRUPTION_EXERCISE_FLOW,
      properties: null,
    });
    dispatch(
      setDisruptionExerciseProgress(DisruptionExerciseProgress.LandingPage)
    );
    dispatch(populateTripQueryParams(history));
    onOpenModal(MyTripsModalTypes.DelayDisruption);
  };

  const getActions = () => {
    const actions: IActionLink[] = [];

    if (!isCancel && !isPastTrips) {
      actions.push({
        content: t("tripReviewLinks.changeFlight"),
        onClick: goToExchange,
      });
      // note: replace cancel-flight with cancel-for-any-reason when CFAR is included
      if (hasCfar) {
        actions.push({
          linkClassName: "desktop-cfar-link",
          content: (
            <>
              <Icon className="cfar-icon" name={IconName.CheckShieldBlue} />
              {t("cancelForAnyReason")}
            </>
          ),
          onClick: openCfarModal,
        });
      } else {
        actions.push({
          content: t("tripReviewLinks.cancelFlight"),
          onClick: () => onOpenModal(MyTripsModalTypes.SelfServeCancelFlight),
        });
      }
    } else if (hasFTC) {
      // flight is cancelled && has travel credit
      actions.push({
        linkClassName: "how-ftc-works-link",
        content: (
          <>
            {t("selfServe.howTcWorks")}
            <Icon className="info-icon" name={IconName.InfoCircle} />
          </>
        ),
        linkRef: ftcPopoverRef,
        onClick: openFTCPopover,
      });
    }

    if (hasMissedConnection) {
      actions.push({
        content: t("missedConnection"),
        onClick: openMissedConnectionModal,
      });
    }

    if (hasDelayDisruption) {
      actions.push({
        content: t("delayDisruption"),
        onClick: openDelayDisruptionModal,
      });
    }

    actions.push(
      {
        content: t("tripReviewLinks.resendEmail"),
        onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
      },
      {
        content: t("tripReviewLinks.contactSupport"),
        onClick: () => onOpenModal(MyTripsModalTypes.ContactSupport),
      }
    );

    return actions;
  };

  const TopRightAction = useMemo(() => {
    if (status === PortalItineraryStatusEnum.Canceled && hasFTC) {
      return (
        <RedeemFTCButton onClick={goToExchange} travelCredit={travelCredit} />
      );
    } else if (
      tripsFilter !== MyTripsFilter.PAST_TRIPS &&
      !isCancel &&
      isWithin24Hours(flight)
    ) {
      return (
        <ActionLink
          className="watch-opt-in-popup-close-button"
          onClick={() => {
            onOpenModal(MyTripsModalTypes.CheckInFlight);
          }}
          showTappableArea={true}
          content={
            <Typography variant="body2" className="check-in-link">
              {t("tripReviewLinks.checkInForFlight")}
              <FontAwesomeIcon
                className="check-in-icon"
                icon={faEdit as IconProp}
              />
            </Typography>
          }
        />
      );
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flight, tripsFilter]);

  const hasMajorScheduleChange =
    flight.bookedItinerary.scheduleChange?.severity ===
    ScheduleChangeSeverity.Major;

  const getTitleTag = (
    flight: BookedFlightItineraryWithDepartureTime,
    hasMajorScheduleChange?: boolean
  ): TagInfo | undefined => {
    switch (flight.status) {
      case PortalItineraryStatusEnum.Pending:
        return {
          label: t("pending"),
          type: TagColors.GREY,
          iconName: IconName.InfoCircle,
          tooltipCopy: t("tooltip.pending"),
        };
      case PortalItineraryStatusEnum.Canceled:
        return {
          label: t("cancelled"),
          type: TagColors.RED,
          iconName: hasMajorScheduleChange ? IconName.InfoCircle : undefined,
          tooltipCopy: hasMajorScheduleChange ? t("tooltip.cancelled") : "",
        };
      case PortalItineraryStatusEnum.Modified:
        return {
          label: t("itineraryModified"),
          type: hasMajorScheduleChange ? TagColors.RED : TagColors.YELLOW,
          iconName: hasMajorScheduleChange ? IconName.InfoCircle : undefined,
          tooltipCopy: hasMajorScheduleChange ? t("tooltip.modified") : "",
        };
      default:
        return undefined;
    }
  };

  const getTitles = () => {
    const titleTag = getTitleTag(
      flight,
      flight.bookedItinerary.scheduleChange?.severity ===
        ScheduleChangeSeverity.Major
    );

    const flightInfoDetails = getFlightInfoDetails(
      flight,
      (date: string) => dayjs(date).format(DATE_FORMAT),
      airportMap,
      airlineMap
    );

    return {
      confirmationLabel: `${t("confirmation")}`,
      startLabel: `${t("departure")}:`,
      endLabel: `${t("return")}:`,
      titleTag: titleTag,
      ...flightInfoDetails,
    };
  };

  return (
    <Box
      id={bookedItinerary.id}
      key={bookedItinerary.id}
      className={clsx("flight-trip-container", {
        mobile: isMobile,
      })}
    >
      <MyTripsCard
        className="flight-trip-card trip-card-title"
        banner={banner}
        content={
          <TripInfoDetails
            confirmationCodeClassName={confirmationCodeClassName}
            hideTitleTag={isCancel || hasMajorScheduleChange}
            titles={getTitles()}
          />
        }
        actions={<ActionLinks actions={getActions()} />}
        isExpanded={expandedCard === bookedItinerary.id}
        expandString={
          expandedCard === bookedItinerary.id ? t("viewLess") : t("viewDetails")
        }
        expandIcon={
          (expandedCard === bookedItinerary.id
            ? faCaretUp
            : faCaretDown) as IconProp
        }
        topRightAction={TopRightAction}
        onExpandClick={() => {
          onExpandCard(bookedItinerary.id);
          setTimeout(() => {
            const OFFSET = matchesMobile
              ? MOBILE_OFFSET_SCROLL
              : DESKTOP_OFFSET_SCROLL;
            const cardTop =
              document
                ?.getElementById(bookedItinerary.id)
                ?.getBoundingClientRect().top || 0;
            window.scrollBy({
              top: (cardTop as number) - OFFSET,
              behavior: matchesMobile ? "auto" : "smooth",
            });
          }, 100);
        }}
      />
      {bookedItinerary.id === expandedCard && (
        <Box className="desktop-expanded-container">
          <Box className="details-info-container">
            <SliceDetails
              isOutgoing
              airlineMap={airlineMap}
              airportMap={airportMap}
              flight={flight}
              openScheduleChangeModal={() =>
                onOpenModal(MyTripsModalTypes.ScheduleChange)
              }
              passengerMap={passengerMap}
              seats={bookedItinerary.seats}
              slice={departureSlice as FlightItinerarySlice}
              sliceToShow={SliceToShow.both}
            />
            {returnSlice && (
              <SliceDetails
                airlineMap={airlineMap}
                airportMap={airportMap}
                flight={flight}
                openScheduleChangeModal={() =>
                  onOpenModal(MyTripsModalTypes.ScheduleChange)
                }
                passengerMap={passengerMap}
                seats={bookedItinerary.seats}
                slice={returnSlice as FlightItinerarySlice}
                sliceToShow={SliceToShow.both}
              />
            )}
          </Box>
          <Box
            className={clsx("summary-info-container", {
              "two-column-view": hasAddOn,
            })}
          >
            <SummaryCard
              className="travellers-summary"
              action={
                <ActionLink
                  className="details-link"
                  onClick={() => onOpenModal(MyTripsModalTypes.TravelersModal)}
                  content="Details"
                />
              }
            >
              <TravelersSummary
                travelers={flight.bookedItinerary.passengers.alone
                  .map(({ person }) => person?.givenName)
                  .join(", ")}
              />
            </SummaryCard>
            <SummaryCard
              className="payment-summary"
              action={
                <ActionLink
                  className="details-link"
                  onClick={() => onOpenModal(MyTripsModalTypes.PaymentModal)}
                  content="Details"
                />
              }
            >
              <PaymentSummary
                tripTotalAmount={formatFiatCurrency(
                  flight.bookedItinerary.sellingPricing.totalPricing.total.fiat
                )}
                paymentLineItems={getLineItems(
                  t,
                  flight.bookedItinerary.paymentBreakdown
                )}
              />
            </SummaryCard>
            <SummaryCard
              className="confirmation-summary"
              action={
                <ActionLink
                  className="details-link"
                  onClick={() =>
                    onOpenModal(MyTripsModalTypes.ConfirmationModal)
                  }
                  content={t("viewAllConfirmation", {
                    count: getConfirmationNumbers({ flight, airlineMap })
                      ?.length,
                  })}
                />
              }
            >
              <ConfirmationSummary
                confirmationCode={
                  flight.bookedItinerary.travelItinerary.locators?.agent
                    ?.value || ""
                }
              />
            </SummaryCard>
            {hasAddOn && (
              <SummaryCard className="add-ons-summary">
                <AddOnsSummary
                  addOns={[
                    {
                      expiryDate: cfar?.expired ?? "",
                      onClick: openCfarModal,
                      type: AddOnEnum.Cfar,
                    },
                    {
                      createdDate: ancillaries?.delay?.created ?? "",
                      onClick: openDelayDisruptionModal,
                      type: AddOnEnum.DelayDisruption,
                    },
                    {
                      expiryDate: ancillaries?.missedConnection?.created ?? "",
                      onClick: openMissedConnectionModal,
                      type: AddOnEnum.MissedConnection,
                    },
                  ]}
                />
              </SummaryCard>
            )}
          </Box>
        </Box>
      )}
      {status === PortalItineraryStatusEnum.Canceled && hasFTC && (
        <Popover
          anchorEl={ftcPopoverRef.current}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          className="how-ftc-works-popover"
          onClose={closeFTCPopover}
          open={ftcPopoverOpen}
        >
          <Typography className="subtitle">
            <I18nMarkup tKey={travelCreditCopy} />
          </Typography>
        </Popover>
      )}
    </Box>
  );
};

export const getSeatSegments = (
  itinerarySeats: ItinerarySeats,
  segments: FlightItinerarySegment[],
  passengerMap: { [key: string]: IPerson },
  isOutgoing: boolean
): SelectedSeatsSegment[] => {
  if (!itinerarySeats) return [];
  const filteredSeats = (itinerarySeats as ItinerarySeats).seats.filter(
    (s) => s.outgoing === isOutgoing
  );
  const seatSegmentMap = filteredSeats.reduce((map, seat) => {
    const key = `${seat.origin}-${seat.destination}-${seat.personId}`;
    map[key] = getSeatInfo(seat, passengerMap);
    return map;
  }, {});
  return segments.map((s: FlightItinerarySegment, idx: number) => {
    const key = `${s.origin.locationCode}-${s.destination.locationCode}`;
    const seatSegment: SelectedSeatsSegment = {
      originCode: s.origin.locationCode,
      flightNumber: s.marketingAirline.flightNumber.toString(),
      seats: {
        departureDate: s.scheduledDeparture,
        originCode: s.origin.locationCode,
        destinationCode: s.destination.locationCode,
        seats: Object.values(passengerMap).map((p) =>
          seatSegmentMap[`${key}-${p.id}`]
            ? seatSegmentMap[`${key}-${p.id}`]
            : getSeatNotSelected(p)
        ),
      },
      isOutgoing,
      idx,
    };
    return seatSegment;
  });
};

export const getSeatInfo = (
  seat: Seat,
  passengerMap: { [key: string]: IPerson }
): SeatInfo => {
  const passenger = passengerMap[seat.personId];
  const name = `${passenger?.givenName} ${passenger?.surname}`;
  return {
    carrier: seat.carrier,
    display_name: name,
    pax: name,
    pax_id: seat.passengerId.value,
    person_id: seat.personId,
    seat_id: seat.seatId.value,
    total: {
      amount: seat.price,
      amountUsd: seat.price,
      currency: seat.currency,
      decimalPlaces: 1,
      display: seat.price.toString(),
      mantissa: seat.price,
      mantissaUsd: seat.price,
    },
  };
};

export const getSeatNotSelected = (p: IPerson) => ({
  carrier: "",
  display_name: "",
  pax: `${p?.givenName} ${p?.surname}`,
  pax_id: "",
  person_id: p?.id,
  seat_id: "",
  total: {
    amount: 0,
    amountUsd: 0,
    currency: "",
    decimalPlaces: 0,
    display: "",

    mantissa: 0,
    mantissaUsd: 0,
  },
});
