/* eslint-disable no-case-declarations */
import {
  AirlineCode,
  Currency,
  DateTimeZone,
  FareclassShelfBrandName,
  IDateBucket,
  IMonthBucket,
  IPassengerCounts,
  ITimeRange,
  ITripTerminus,
  IUserPreferences,
  Locale,
  SliceStopCountFilter,
  TripCategory,
} from "@hopper-b2b/types";

import { parseQueryFromString } from "../../shop/utils/parseQueryString";
import { actions, actionTypes } from "../actions";
import { ITripTerminusCategory } from "../types";

export enum MobileFlightSearchStep {
  LocationSearch,
  CalendarPicker,
  Complete,
}

export interface IFlightNumberFilter {
  flightNumber: string;
  airlineCode: string;
}
export interface IFilterState {
  stopsOption: SliceStopCountFilter;
  maxPriceFilter: number;
  fareclassOptionFilter: FareclassOptionFilter;
  outboundDepartureTimeRange: ITimeRange;
  outboundArrivalTimeRange: ITimeRange;
  returnDepartureTimeRange: ITimeRange;
  returnArrivalTimeRange: ITimeRange;
  airlineFilter: AirlineCode[];
  airportFilter: string[];
  flightNumberFilter: IFlightNumberFilter[];
}
export interface IFlightSearchState extends IFilterState {
  openDatesModal: boolean;
  numTravelers: number;
  tripCategory: TripCategory;
  renderSearchInput: string;
  calendarVisited: boolean;
  origin: ITripTerminus | null;
  originCategories: ITripTerminusCategory[] | null;
  originCategoriesLoading: boolean | null;
  destination: ITripTerminus | null;
  destinationCategories: ITripTerminusCategory[] | null;
  destinationCategoriesLoading: boolean | null;
  currency: string | null;
  departureMonths: IMonthBucket[] | null;
  departureDateBuckets: IDateBucket[] | null;
  departureDateBucketsLoading: boolean | null;
  departureDate: Date | null;
  returnDate: Date | null;
  preferences: IUserPreferences;
  adultsCount: number;
  childrenCount: number;
  infantsInSeatCount: number;
  infantsOnLapCount: number;
  awaitingRefetch: boolean;
  mobileSearchStep: MobileFlightSearchStep;
}

export type FareclassOptionFilter = {
  [key in FareclassShelfBrandName]: boolean;
};

export const initialFareclassOptionFilter: FareclassOptionFilter = {
  basic: false,
  standard: false,
  enhanced: false,
  premium: false,
  luxury: false,
};

export const noLCCFareclassOptionFilter: FareclassOptionFilter = {
  basic: false,
  standard: true,
  enhanced: true,
  premium: true,
  luxury: true,
};

export const INITIAL_MAX_PRICE = 0;
// number of minutes in a day = 60 min/hour * 24 hour = 1440 min
export const TIME_RANGE_MAX = 1439;

export const MAXIMUM_COUNT = 6;
export const MINIMUM_ADULT_COUNT = 1;

const initialTimeRange: ITimeRange = {
  min: 0,
  // note: time measured in minutes
  max: TIME_RANGE_MAX,
};

export const initialFilterOptions: IFilterState = {
  stopsOption: SliceStopCountFilter.ANY_NUMBER,
  maxPriceFilter: Number.MAX_SAFE_INTEGER,
  fareclassOptionFilter: initialFareclassOptionFilter,
  outboundDepartureTimeRange: initialTimeRange,
  outboundArrivalTimeRange: initialTimeRange,
  returnDepartureTimeRange: initialTimeRange,
  returnArrivalTimeRange: initialTimeRange,
  airlineFilter: [],
  airportFilter: [],
  flightNumberFilter: [],
};

export const initialState = (query?: string): IFlightSearchState => {
  const defaults = {
    numTravelers: 1,
    tripCategory: TripCategory.ROUND_TRIP,
    renderSearchInput: "",
    calendarVisited: false,
    originCategories: [],
    destinationCategories: [],
    originCategoriesLoading: false,
    destinationCategoriesLoading: false,
    openDatesModal: false,

    origin: null,
    destination: null,
    currency: null,
    departureMonths: null,
    departureDateBuckets: null,
    departureDateBucketsLoading: false,
    departureDate: null,
    returnDate: null,
    preferences: {
      locale: Locale.EN_US,
      currency: Currency.CAD,
      timeZone: DateTimeZone.AMERICA_TORONTO,
    },
    adultsCount: MINIMUM_ADULT_COUNT,
    childrenCount: 0,
    infantsInSeatCount: 0,
    infantsOnLapCount: 0,
    awaitingRefetch: false,
    mobileSearchStep: MobileFlightSearchStep.LocationSearch,
    ...initialFilterOptions,
  };

  if (query) {
    const queryParams = parseQueryFromString(query);
    return {
      ...defaults,
      tripCategory: queryParams.tripCategory || defaults.tripCategory,
      departureDate: queryParams.departureDate || defaults.departureDate,
      returnDate: queryParams.returnDate || defaults.returnDate,
    };
  } else {
    return defaults;
  }
};

export function reducer(
  state: IFlightSearchState = initialState(window?.location?.search),
  action: actions.FlightSearchActions
): IFlightSearchState {
  switch (action.type) {
    case actionTypes.SET_OPEN_DATES_MODAL:
      return {
        ...state,
        openDatesModal: action.openDatesModal,
      };

    case actionTypes.SET_TRIP_CATEGORY:
      const { tripCategory } = action;
      return { ...state, tripCategory, returnDate: null };

    case actionTypes.SET_RENDER_SEARCH_INPUT:
      return { ...state, renderSearchInput: action.renderSearchInput };

    case actionTypes.SET_CALENDAR_VISITED:
      return { ...state, calendarVisited: action.calendarVisited };

    case actionTypes.SET_PASSENGER_COUNTS:
      const counts = action.counts as IPassengerCounts;
      const numTravelers =
        counts.adultsCount +
        counts.childrenCount +
        counts.infantsInSeatCount +
        counts.infantsOnLapCount;
      return { ...state, ...action.counts, numTravelers };

    case actionTypes.SET_ORIGIN:
      const { origin } = action;
      return { ...state, origin, awaitingRefetch: true };

    case actionTypes.SET_ORIGIN_CATEGORIES:
      return {
        ...state,
        originCategories: action.originCategories,
        originCategoriesLoading: false,
      };

    case actionTypes.FETCH_ORIGIN_CATEGORIES:
      return { ...state, originCategories: [], originCategoriesLoading: true };

    case actionTypes.SET_DESTINATION:
      const { destination } = action;
      return { ...state, destination, awaitingRefetch: true };

    case actionTypes.SET_DESTINATION_CATEGORIES:
      return {
        ...state,
        destinationCategories: action.destinationCategories,
        destinationCategoriesLoading: false,
      };

    case actionTypes.FETCH_DESTINATION_CATEGORIES:
      return {
        ...state,
        destinationCategories: [],
        destinationCategoriesLoading: true,
      };

    case actionTypes.FETCH_DEPARTURE_CALENDAR:
      return {
        ...state,
        currency: null,
        departureMonths: null,
        departureDateBuckets: null,
        departureDateBucketsLoading: true,
      };

    case actionTypes.SET_DEPARTURE_CALENDAR:
      return {
        ...state,
        currency:
          typeof action.currency === "undefined" ? null : action.currency,
        departureMonths:
          typeof action.departureMonths === "undefined"
            ? null
            : action.departureMonths,
        departureDateBuckets:
          typeof action.departureDateBuckets === "undefined"
            ? null
            : action.departureDateBuckets,
        departureDateBucketsLoading: false,
      };

    case actionTypes.SET_DEPARTURE_DATE:
      return {
        ...state,
        departureDate: action.date ? action.date : null,
        awaitingRefetch: true,
      };

    case actionTypes.SET_RETURN_DATE:
      return {
        ...state,
        returnDate: action.date ? action.date : null,
        awaitingRefetch: true,
      };

    case actionTypes.SET_STOPS_OPTION:
      return { ...state, stopsOption: action.stopsOption };

    case actionTypes.SET_MAX_PRICE_FILTER:
      return { ...state, maxPriceFilter: action.maxPriceFilter };

    case actionTypes.RESET_FARECLASS_OPTION_FILTER:
      return {
        ...state,
        fareclassOptionFilter: initialFareclassOptionFilter,
      };

    case actionTypes.SET_FARECLASS_OPTION_FILTER:
      return {
        ...state,
        fareclassOptionFilter: { ...action.fareclassOptionFilter },
      };

    case actionTypes.SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER:
      return {
        ...state,
        outboundDepartureTimeRange: action.outboundDepartureTimeRange,
      };

    case actionTypes.SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER:
      return {
        ...state,
        outboundArrivalTimeRange: action.outboundArrivalTimeRange,
      };

    case actionTypes.SET_RETURN_DEPARTURE_TIME_RANGE_FILTER:
      return {
        ...state,
        returnDepartureTimeRange: action.returnDepartureTimeRange,
      };

    case actionTypes.SET_RETURN_ARRIVAL_TIME_RANGE_FILTER:
      return {
        ...state,
        returnArrivalTimeRange: action.returnArrivalTimeRange,
      };

    case actionTypes.SET_AIRLINE_FILTER:
      return {
        ...state,
        airlineFilter: [...action.airlineFilter],
      };

    case actionTypes.SET_AIRPORT_FILTER:
      return {
        ...state,
        airportFilter: [...action.airportFilter],
      };

    case actionTypes.SET_AWAITING_REFETCH:
      return {
        ...state,
        awaitingRefetch: action.awaitingRefetch,
      };

    case actionTypes.SET_MOBILE_SEARCH_PROGRESS:
      return {
        ...state,
        mobileSearchStep: action.step,
      };

    case actionTypes.RESET_FILTERS: {
      return {
        ...state,
        ...initialFilterOptions,
      };
    }

    case actionTypes.SET_FLIGHT_NUMBER_FILTER:
      const airlines = action.flightNumberFilter.map(
        (filter) => filter.airlineCode
      );

      // Reset airline filter if chosen flight number is on a different airline.
      if (!state.airlineFilter.some((airline) => airlines.includes(airline))) {
        return {
          ...state,
          airlineFilter: [],
          flightNumberFilter: [...action.flightNumberFilter],
        };
      }

      return {
        ...state,
        flightNumberFilter: [...action.flightNumberFilter],
      };

    default:
      return state;
  }
}

export * from "./selectors";
