import axios from "axios";
import { paymentApiPrefix } from "../../paths";
import {
  IApiConfig,
  NETWORK_CALL_FAILED,
  PaymentSessionToken,
} from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";

const END_POINT = `${paymentApiPrefix}/session/close`;

export const closeSession = (
  token: PaymentSessionToken,
  apiConfig?: IApiConfig
): Promise<any> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(END_POINT, { ...token });

      const responseBody = res.data;
      resolve(responseBody);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });
