import { useRef, useContext, useEffect } from "react";
import { useDay } from "@datepicker-react/hooks";
import { context as DateRangePickerContext } from "../../context";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import "./styles.scss";
import dayjs from "dayjs";
import { ArrowKeyEnum } from "@hopper-b2b/types";
import { PickerType } from "../../types";

export interface IDayProps {
  date: Date;
  dayLabel: string;
  monthLabel: string;
  bucket?: number;
  className?: string;
  isMiniDay?: boolean;
  currentFocusDate?: Date;
  setCurrentFocusDate?: (date?: Date) => void;
  setIsDateFocused?: (bool: boolean) => void;
  isDateFocused?: boolean;
  priceLabelName?: string;
}
export const generateBucketClassName = (index: number): string =>
  `bucket-${index}`;

export const generatePriceLabel = (
  index: number,
  priceTags?: string[]
): string => (priceTags && priceTags.length > 0 ? priceTags[index] : "");

export const priceBucketLabels = ["$", "$$", "$$$", "$$$$"];

export const Day = (props: IDayProps) => {
  const {
    date,
    dayLabel,
    className,
    bucket,
    isMiniDay,
    setCurrentFocusDate,
    currentFocusDate,
    setIsDateFocused,
    isDateFocused: isDateButtonFocused,
    priceLabelName,
  } = props;

  const dayRef = useRef(null);

  const currentDateRef = useRef<HTMLDivElement>(null);

  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
    pickerType,
    startDate,
    endDate,
    hoveredDate,
  } = useContext(DateRangePickerContext);

  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onMouseEnter,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  if (!dayLabel) {
    return <Box className="empty-date"></Box>;
  }

  const calculateStyle = generateCalculateStyle({
    isWithinHoverRange,
    disabledDate,
    isSelected,
    isSelectedStartOrEnd,
    startDate,
    endDate,
    hoveredDate,
    isDateBlocked,
    pickerType,
  });
  const isFocusedDate =
    !disabledDate && dayjs(currentFocusDate).isSame(dayjs(date), "day");

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (isFocusedDate && currentDateRef.current && isDateButtonFocused) {
      currentDateRef.current.focus();
    }
  }, [currentFocusDate]);

  const handleArrowClicks = (e: any) => {
    const getNewDate = (keyPress: string) => {
      switch (keyPress) {
        case ArrowKeyEnum.UP:
          return dayjs(date).subtract(7, "day");
        case ArrowKeyEnum.DOWN:
          return dayjs(date).add(7, "day");
        case ArrowKeyEnum.LEFT:
          return dayjs(date).subtract(1, "day");
        case ArrowKeyEnum.RIGHT:
          return dayjs(date).add(1, "day");
        default:
          return undefined;
      }
    };

    if (Object.values(ArrowKeyEnum).includes(e.key)) {
      const newDate = getNewDate(e.key);
      if (newDate && setCurrentFocusDate) setCurrentFocusDate(newDate.toDate());
    }
  };

  const geDayAriaLabel = () => {
    const dateAria = dayjs(date).format("dddd MMMM DD, YYYY");

    if (startDate && dayjs(date).isSame(startDate, "day")) {
      return `Selected start date ${dateAria} ${priceLabelName}`;
    } else if (endDate && dayjs(date).isSame(endDate, "day")) {
      return `Selected end date ${dateAria} ${priceLabelName}`;
    } else {
      return `${dateAria}, ${priceLabelName}`;
    }
  };

  return (
    <div
      role="button"
      className={clsx(
        "day-root",
        { "mini-day": isMiniDay },
        className,
        { focused: isFocusedDate && isDateButtonFocused },
        ...calculateStyle(date)
      )}
      aria-label={geDayAriaLabel()}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClick();
        }
        handleArrowClicks(e);
      }}
      onMouseEnter={onMouseEnter}
      tabIndex={isFocusedDate && !disabledDate ? 0 : -1}
      ref={!disabledDate ? currentDateRef : null}
      onFocus={() => {
        if (setCurrentFocusDate) setCurrentFocusDate(date);
        if (setIsDateFocused) setIsDateFocused(true);
      }}
      onBlur={() => {
        if (setIsDateFocused) setIsDateFocused(false);
      }}
    >
      <Box className="label-wrapper">
        <Typography variant="body1" className="day-label">
          {dayLabel}
        </Typography>
        {bucket >= 0 && (
          <Typography className="price-bucket-label">
            {priceBucketLabels[bucket]}
          </Typography>
        )}
      </Box>
    </div>
  );
};

const generateCalculateStyle =
  ({
    isWithinHoverRange,
    disabledDate,
    isSelected,
    isSelectedStartOrEnd,
    startDate,
    endDate,
    hoveredDate,
    isDateBlocked,
    pickerType,
  }: {
    isWithinHoverRange: boolean;
    disabledDate: boolean;
    isSelected: boolean;
    isSelectedStartOrEnd: boolean;
    startDate: Date | null;
    endDate: Date | null;
    hoveredDate: Date | null;
    isDateBlocked: (date: Date) => boolean;
    pickerType: PickerType;
  }) =>
  (date: Date) => {
    const classes: string[] = [];

    // if (isSelectedStartOrEnd && pickerType === PickerType.DAY) {
    //   classes.push("hover-range");
    // }

    if (isWithinHoverRange && !isSelectedStartOrEnd) {
      !classes.includes("hover-range") && classes.push("hover-range");
    }

    if (disabledDate && !isWithinHoverRange) {
      classes.push("disabled");
    }

    if (
      isSelectedStartOrEnd &&
      startDate &&
      dayjs(date).isSame(startDate, "day")
    ) {
      classes.push("start-date");
    }

    if (isSelectedStartOrEnd && endDate && dayjs(date).isSame(endDate, "day")) {
      classes.push("end-date");
    }

    if (
      startDate &&
      hoveredDate &&
      !isDateBlocked(hoveredDate) &&
      dayjs(date).isSame(hoveredDate, "day")
    ) {
      if (hoveredDate > startDate) {
        !classes.includes("end-date") && classes.push("end-date");
      } else {
        !classes.includes("start-date") && classes.push("start-date");
      }
    }

    if (isSelected || isSelectedStartOrEnd) {
      classes.push("selected-range");
    }

    return classes;
  };
