import { ActionButton } from "@hopper-b2b/ui";
import "./ApacActionButton.styles.scss";
import clsx from "clsx";

export interface IApacActionButtonProps {
  onClick?: (e?: React.MouseEvent) => void;
  className?: string;
  disabled?: boolean;
  message: string;
  fullWidth?: boolean;
  variant?: "outlined" | "primary";
}

/**
 * Width: You control the with by passing in the className.
 *
 * Secondary/Outlined button: passed in "outlined" className.
 *
 * example:
 * <ApacActionButton
      className="search-button outlined"
      message={t("searchButton")}
      onClick={() => {
        fetchFlights();
        setIsBarEditMode(false);
        setSearchChanged(false);
      }}
    />

    .search-button {
      width: 172px;
    }
 */
export const ApacActionButton = ({
  className,
  onClick,
  message,
  disabled = false,
  fullWidth,
  variant = "primary",
}: IApacActionButtonProps) => {
  return (
    <ActionButton
      className={clsx("apac-action-button", className, {
        outlined: variant === "outlined",
        "full-width": fullWidth,
      })}
      onClick={onClick}
      message={message}
      disabled={disabled}
    />
  );
};
