import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { schedulePayment } from "../../../../api/v1/book/book-flow/schedulePayment";
import {
  ConnectionResultEnum,
  InvalidEnum,
  ConnectionResult,
} from "@hopper-b2b/types";
import { flightApiConfigStoreKey } from "../../../../reducers/types";
import { IStoreState } from "../../../../reducers/types";

export function* schedulePaymentSaga({
  payload,
  agentFee,
}: actions.ISetSchedulePayment) {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const response: ConnectionResult = yield schedulePayment(
      payload,
      apiConfig
    );

    if (response) {
      if ("Invalid" in response) {
        yield put(actions.setSchedulePaymentFailed(response));
      } else {
        yield put(actions.setSchedulePaymentSuccess());
        yield put(actions.pollFinalized(agentFee));
      }
    } else {
      yield put(
        actions.setSchedulePaymentFailed({
          Invalid: InvalidEnum.Missing,
          ConnectionResult: ConnectionResultEnum.Invalid,
        })
      );
    }
  } catch (e) {
    yield put(
      actions.setSchedulePaymentFailed({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );
  }
}
