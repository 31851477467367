import {
  HotelItineraryRequest,
  HotelItineraryResponse,
  lodgingApi,
} from "@b2bportal/lodging-api";
import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";

export const fetchHotels = async (
  req: HotelItineraryRequest
): Promise<HotelItineraryResponse> =>
  new Promise((resolve, reject) => {
    lodgingApi(axiosInstance)
      .apiV0TripsHotelPut(req)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        trackEvent({
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        });
        reject(e);
      });
  });
