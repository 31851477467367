import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { trackEvent } from "../../tracking/trackEvent";
import { axiosInstance } from "../../../index";
import { AxiosResponse } from "axios";
import {
  GetWatchAlertDetailsRequest,
  GetWatchAlertDetailsResponseResponse,
  airPriceWatchApi,
} from "@b2bportal/air-price-watch-api";

export const getWatchDetails = (
  request: GetWatchAlertDetailsRequest,
  apiConfig?: IApiConfig
): Promise<GetWatchAlertDetailsResponseResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<GetWatchAlertDetailsResponseResponse> =
        await airPriceWatchApi(axiosInstance).apiV0AlertWatchDetailsPost(
          request
        );
      resolve(res.data);
    } catch (err) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(err);
    }
  });
};
