export const apiVersionPrefix = "/api/v0";

export const flightItinerariesApiPrefix = `${apiVersionPrefix}/itinerary/flight`;
export const hotelItinerariesApiPrefix = `${apiVersionPrefix}/trips/hotel`;
export const hotelReservationApiPrefix = `${apiVersionPrefix}/trips/hotel/getReservation`;
export const groundItinerariesApiPrefix = `${apiVersionPrefix}/itinerary/ground`;
export const rewardsApiPrefix = `${apiVersionPrefix}/rewards`;
export const watchApiPrefix = `${apiVersionPrefix}/alert/watch`;
export const priceFreezePrefix = `${apiVersionPrefix}/pricefreeze/list`;
export const fetchUserInfoPath = `${apiVersionPrefix}/userInfo`;
export const analyticsApiPrefix = `${apiVersionPrefix}/tracking`;
export const analyticsEventApi = `${analyticsApiPrefix}/event`;
export const experimentsApiPrefix = `${apiVersionPrefix}/experiments`;

// email
export const emailPrefix = `${apiVersionPrefix}/email`;
export const resendEmailEndpoint = `${emailPrefix}/resend`;

// Self Serve
const selfServePrefix = `${apiVersionPrefix}/selfserve`;
const selfServeFlightPrefix = `${selfServePrefix}/flight`;
const selfServeGroundPrefix = `${selfServePrefix}/ground`;
const selfServeHotelsPrefix = `${selfServePrefix}/lodging`;
const selfServeNonCartPrefix = `${selfServePrefix}/app`;
const selfServeNonCartFlightPrefix = `${selfServeNonCartPrefix}/flight`;
const selfServeNonCartHotelPrefix = `${selfServeNonCartPrefix}/lodging`;

export const getFlightExchangePolicyPath = `${selfServePrefix}/flight/exchangeEligibilityQuote`;
export const cancelGroundByReservationPath = `${selfServeGroundPrefix}/cancel`;
export const getGroundCancellationInfoPath = `${cancelGroundByReservationPath}/policy`;
export const pollHotelCancelFulfillPath = `${selfServeHotelsPrefix}/cancelFulfillmentPoll`;
export const confirmFlightCancelPath = `${selfServeFlightPrefix}/cancelFulfill`;
export const confirmHotelCancelPath = `${selfServeHotelsPrefix}/cancelFulfill`;
export const confirmNonCartFlightCancelPath = `${selfServeNonCartFlightPrefix}/cancelFulfill`;
export const confirmNonCartHotelCancelPath = `${selfServeNonCartHotelPrefix}/cancelFulfill`;
export const getFlightCancelEligibilityPath = `${selfServeNonCartFlightPrefix}/cancelEligibility`;
export const getFlightCancelQuotePath = `${selfServeFlightPrefix}/cancelQuote`;
export const getFlightCancelQuoteAnyPath = `${selfServeFlightPrefix}/cancelQuoteAny`;
export const getHotelCancelQuotePath = `${selfServeHotelsPrefix}/cancelQuote`;
export const getHotelCancelQuoteAnyPath = `${selfServeHotelsPrefix}/cancelQuoteAny`;
export const getHotelChangeQuotePath = `${selfServeHotelsPrefix}/changeQuote`;
export const pollFlightCancelQuotePath = `${selfServeNonCartFlightPrefix}/cancelQuotePoll`;
export const pollFlightCancelFulfillPath = `${selfServeFlightPrefix}/flightCancelFulfillmentPoll`;
export const pollNonCartFlightCancelFulfillPath = `${selfServeNonCartFlightPrefix}/flightCancelFulfillmentPoll`;

// Disruption
const disruptionExercisePrefix = `${apiVersionPrefix}/disruption/exercise`;
export const disruptionRefundPlanPath = `${disruptionExercisePrefix}/refund/plan`;
export const disruptionRefundFulfillPath = `${disruptionExercisePrefix}/refund/fulfill`;
export const disruptionRefundPollPath = `${disruptionExercisePrefix}/refund/fulfill/poll`;
export const disruptionRebookPlanPath = `${disruptionExercisePrefix}/rebook/plan`;
export const disruptionRebookShopPath = `${disruptionExercisePrefix}/rebook/shop`;
export const disruptionRebookQuotePath = `${disruptionExercisePrefix}/rebook/quote/schedule`;
export const disruptionRebookQuotePollPath = `${disruptionExercisePrefix}/rebook/quote/poll`;
export const disruptionRebookFulfillPath = `${disruptionExercisePrefix}/rebook/fulfill/schedule`;
export const disruptionRebookFulfillPollPath = `${disruptionExercisePrefix}/rebook/fulfill/poll`;
export const disruptionFetchTripDetailsPath = `${apiVersionPrefix}/tripDetail`;

// IVR
const ivrPrefix = `${apiVersionPrefix}/ivr`;
export const bookingSupportIdPath = `${ivrPrefix}/encode`;
