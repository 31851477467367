import {
  AirCancelQuoteResponse,
  AirCancelQuoteResponseEnum,
} from "@b2bportal/air-cancel-api";
import { NETWORK_CALL_FAILED, Uuid } from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { getFlightCancelQuoteAnyPath } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

/**
 * @description The first of two endpoints in the self serve cancel flight flow
 * @param {Uuid} reservationId Contains the reservation id
 * @return {AirCancelQuoteResponse} The cancellation info for the itinerary passed in
 */
const getFlightCancelQuoteAny = async (
  reservationId: Uuid,
  locator?: string
) => {
  try {
    const req = {
      reservationId,
      bookingLocator: locator ? locator : undefined,
    };
    const res = await axiosInstance.post<AirCancelQuoteResponse>(
      getFlightCancelQuoteAnyPath,
      req
    );
    const { AirCancelQuoteResponse: acqRes } = res.data;

    if (acqRes !== AirCancelQuoteResponseEnum.AirCancelQuoteFailure) {
      return res.data;
    }

    throw res;
  } catch (err) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
  }
};

export default getFlightCancelQuoteAny;
