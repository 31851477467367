import { AirChfarOffer } from "@b2bportal/air-chfar-api";
import * as actionTypes from "./constants";

export interface ISetChfarOffer {
  type: actionTypes.SET_CHFAR_OFFER;
  offers?: AirChfarOffer[];
}

export const setChfarOffers = (args: {
  offers?: AirChfarOffer[];
}): ISetChfarOffer => ({
  type: actionTypes.SET_CHFAR_OFFER,
  ...args,
});

export interface IFetchChfarOffer {
  type: actionTypes.FETCH_CHFAR_OFFER;
  tripId: string;
  fareId: string;
}

export const fetchChfarOffer = (
  tripId: string,
  fareId: string
): IFetchChfarOffer => ({
  type: actionTypes.FETCH_CHFAR_OFFER,
  tripId,
  fareId,
});

export interface ISetChfarOfferFailed {
  type: actionTypes.SET_CHFAR_OFFER_FAILED;
}

export const setChfarOfferFailed = (): ISetChfarOfferFailed => ({
  type: actionTypes.SET_CHFAR_OFFER_FAILED,
});

export interface ISetSelectedChfarOffer {
  type: actionTypes.SET_SELECTED_CHFAR_OFFER;
  selectedChfarOffer?: AirChfarOffer;
}

export const setSelectedChfarOffer = (
  offer?: AirChfarOffer
): ISetSelectedChfarOffer => ({
  type: actionTypes.SET_SELECTED_CHFAR_OFFER,
  selectedChfarOffer: offer,
});

export type FlightChfarActions =
  | ISetChfarOffer
  | IFetchChfarOffer
  | ISetChfarOfferFailed
  | ISetSelectedChfarOffer;
