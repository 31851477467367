import { useEffect, Dispatch, SetStateAction } from "react";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { ActionButton, B2BSpinner, Icon, IconName } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";

import { RouteComponentProps } from "react-router-dom";
import {
  ScheduleChangeUserActionEnum,
  CallState,
  FlightItineraryState,
} from "@hopper-b2b/types";
import { ScheduleChangeState } from "../../component";
import { ScheduleChangeStateModalContentConnectorProps } from "./container";
import "./styles.scss";
import clsx from "clsx";
export interface IScheduleChangeRequestedModalContentProps
  extends ScheduleChangeStateModalContentConnectorProps,
    RouteComponentProps {
  onClose: () => void;
  setScheduleChangeState: Dispatch<SetStateAction<ScheduleChangeState>>;
  scheduleChangeState: ScheduleChangeState;
  action: ScheduleChangeUserActionEnum | null;
  acceptCallState: CallState;
  denyCallState: CallState;
}

export const ScheduleChangeStateModal = ({
  action,
  onClose,
  scheduleChangeState,
  setScheduleChangeState,
  acceptCallState,
  denyCallState,
  fetchFlights,
  history,
}: IScheduleChangeRequestedModalContentProps) => {
  const { t } = useI18nContext();
  useEffect(() => {
    const callState =
      action === ScheduleChangeUserActionEnum.Accept
        ? acceptCallState
        : denyCallState;
    switch (action) {
      case ScheduleChangeUserActionEnum.Accept:
        switch (callState) {
          case CallState.Success:
            setScheduleChangeState(ScheduleChangeState.Accepted);
            break;
          case CallState.Failed:
            setScheduleChangeState(ScheduleChangeState.Failure);
            break;
          default:
            break;
        }
        break;
      case ScheduleChangeUserActionEnum.Deny:
        switch (callState) {
          case CallState.Success:
            setScheduleChangeState(ScheduleChangeState.Requested);
            break;
          case CallState.Failed:
            setScheduleChangeState(ScheduleChangeState.Failure);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }, [acceptCallState, denyCallState, action]);

  const getIcon = () => {
    switch (scheduleChangeState) {
      case ScheduleChangeState.Failure:
        return <Icon name={IconName.ErrorState} />;
      default:
        return <Icon className="checked-icon" name={IconName.Checked} />;
    }
  };
  const getHeader = () => {
    switch (scheduleChangeState) {
      case ScheduleChangeState.Accepted:
        return t("scheduleChangeRequestedModal.acceptedTitle");
      case ScheduleChangeState.Failure:
        return t("genericErrorTitle");
      default:
        return t("scheduleChangeRequestedModal.confirmationTitle");
    }
  };
  const getSubtitle = () => {
    switch (scheduleChangeState) {
      case ScheduleChangeState.Accepted:
        return t("scheduleChangeRequestedModal.acceptedSubtitle");
      case ScheduleChangeState.Failure:
        return t("scheduleChangeRequestedModal.failureSubtitle");
      default:
        return t("scheduleChangeRequestedModal.confirmationSubtitle");
    }
  };

  const onClick =
    scheduleChangeState === ScheduleChangeState.Failure
      ? () => setScheduleChangeState(ScheduleChangeState.Display)
      : () => {
          onClose();
          if (
            scheduleChangeState === ScheduleChangeState.Accepted ||
            scheduleChangeState === ScheduleChangeState.Requested
          ) {
            fetchFlights(
              {
                states: [
                  FlightItineraryState.Canceled,
                  FlightItineraryState.Future,
                  FlightItineraryState.Present,
                  FlightItineraryState.Past,
                ],
                referenceDateTime: dayjs().format(),
              },
              history
            );
          }
        };

  return (
    <Box className="schedule-change-requested-modal">
      {scheduleChangeState === ScheduleChangeState.InProgress ? (
        <B2BSpinner />
      ) : (
        <>
          {getIcon()}
          <Typography className="header" variant="h2" color="textPrimary">
            {getHeader()}
          </Typography>
          <Typography className="sub-header" color="textSecondary" variant="h5">
            {getSubtitle()}
          </Typography>
          <ActionButton
            className={clsx("primary-button", "b2b")}
            defaultStyle="h4r-primary"
            message={
              scheduleChangeState === ScheduleChangeState.Failure
                ? t("retry")
                : t("done")
            }
            onClick={onClick}
          />
        </>
      )}
    </Box>
  );
};
