import { ApacIconComponent, ApacIconName } from "@commbank/ui";
import {
  CheckInInstructions,
  CheckInTime,
  CheckInTimeEnum,
} from "@b2bportal/lodging-api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { URL_PARAM_KEYS } from "@hopper-b2b/lodging-utils";
import { CallState, LodgingShopTrackingEvents } from "@hopper-b2b/types";
import { ActionButton, TripAdvisorBadge } from "@hopper-b2b/ui";
import { toStarRating, useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Divider, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom-v5-compat";
import { HotelShopHeader } from "../../../../components/HotelShopHeader/HotelShopHeader";
import {
  PATH_HOME,
  PATH_HOTELS_ROOT,
  PATH_SEARCH_RELATIVE,
} from "../../../../util";
import { ProductType, useTrackEvents } from "../../../../util/trackEvent";
import { numberWithCommas, scrollTopWithOffset } from "../../../../util/utils";
import { getTotalNights } from "../../../availability/reducer";
import { MobileHotelSearchStep } from "../../../search/types";
import { setSelectedRate, setSelectedRoom } from "../../actions/actions";
import { useGoToNextStep, useGoToShopLodgingDetailsStep } from "../../hooks";
import { useAvailabilityLink } from "../../hooks/useAvailabilityLink";
import {
  FiatAndPointPrice,
  getCheckInInstructions,
  getRooms,
  getRoomsCallState,
  getSelectedLodging,
  getSelectedLodgingLowestPrice,
  getSelectedRoomId,
  getSelectedRoomRateId,
  getShopCallState,
  getSelectedRoomFiatAndPointPrice,
} from "../../reducer/selectors";
import { AboutHotelSection } from "../AboutHotelSection";
import { Amenities } from "../Amenities";
import { CheckInPolicies } from "../CheckInPolicies";
import { CustomerReviews } from "../CustomerReviews";
import { HotelHeader, MobileHotelHeader } from "../HotelHeader";
import { ImageGallery } from "../ImageGallery";
import { PriceSummary } from "../PriceSummary";
import { RoomSelect } from "../RoomSelect";
import { SearchDetailsSection } from "../SearchControlsSection";
import { ShopPage } from "../ShopPage/component";
import styles from "./LodgingDetailsPage.module.scss";
import { LodgingDetailsPageSkeleton, SkeletonCard } from "./components";
import { useRoomSelectedTrackingProperties } from "../../hooks/useRoomSelectedTrackingProperties";

export interface ILodgingDetailsPageProps {
  fromDate: string;
  untilDate: string;
  rooms: number;
  guests: {
    adults: number;
    children: number[];
  };
}

export const CheckInAndOutSection = ({
  checkInInstructions,
  isMobile,
}: {
  checkInInstructions: CheckInInstructions;
  isMobile: boolean;
}) => {
  const { t } = useI18nContext();

  const format12Hour = (time24Hour: string) =>
    dayjs(time24Hour, "HH:mm").format("hh:mm A");

  const checkInStr = (checkInTime: CheckInTime) => {
    return checkInTime?.CheckInTime === CheckInTimeEnum.Anytime
      ? t("anytime")
      : checkInTime?.checkInTimeUntil
      ? t("hotelShop.checkinTimeRange", {
          start: format12Hour(checkInTime?.checkInTimeFrom),
          end: format12Hour(checkInTime?.checkInTimeUntil),
        })
      : t("checkinTime", {
          time: format12Hour(checkInTime?.checkInTimeFrom),
        });
  };

  return (
    <div
      className={clsx(styles.CheckInAndOutSection, isMobile && styles.mobile)}
    >
      <Typography variant="h2">{t("hotelShop.checkinAndOutTitle")}</Typography>
      <div className={styles["check-in"]}>
        <Typography className={styles["section-title"]} variant="h6">
          {t("checkin")}
        </Typography>
        {!checkInInstructions.checkInTime ? null : (
          <Typography variant="body1" className={styles["section-content"]}>
            {checkInStr(checkInInstructions.checkInTime)}
          </Typography>
        )}
      </div>
      <div className={styles["check-out"]}>
        <Typography className={styles["section-title"]} variant="h6">
          {t("checkout")}
        </Typography>
        <Typography variant="body1" className={styles["section-content"]}>
          {t("checkoutTime", {
            time: format12Hour(checkInInstructions.checkOutTime),
          })}
        </Typography>
      </div>
    </div>
  );
};

export const LodgingDetailsPage = ({
  rooms,
  guests,
  fromDate,
  untilDate,
}: ILodgingDetailsPageProps) => {
  const { t, formatFiatCurrency } = useI18nContext();
  const goToShopLodgingDetails = useGoToShopLodgingDetailsStep();
  const lodging = useSelector(getSelectedLodging);
  const navigate = useNavigate();
  const isLoadingLodging =
    useSelector(getShopCallState) === CallState.InProcess;
  const isLoadingRooms = useSelector(getRoomsCallState) === CallState.InProcess;
  const goToNextStep = useGoToNextStep();
  const hotelReviewScore = lodging?.lodging.score * 5;
  const nights = useSelector(getTotalNights);
  const lowestPrice = useSelector(getSelectedLodgingLowestPrice);
  const dispatch = useDispatch();
  const roomList = useSelector(getRooms);
  const roomId = useSelector(getSelectedRoomId);
  const rateId = useSelector(getSelectedRoomRateId);
  const checkInInstructions = useSelector(getCheckInInstructions);
  const selectedPrice = useSelector(getSelectedRoomFiatAndPointPrice);
  const selectedRoomTrackingProperties = useRoomSelectedTrackingProperties();
  const trackEvent = useTrackEvents();
  const selectedRoom = useMemo(() => {
    if (!roomList || !roomId || !rateId) {
      return undefined;
    } else {
      return roomList.find((r) => r?.roomInfo?.roomId === roomId);
    }
  }, [roomList, roomId, rateId]);

  const selectedRoomRate = useMemo(() => {
    if (!selectedRoom || !rateId) {
      return undefined;
    } else {
      return selectedRoom.products.find((r) => r.rateId?.value === rateId)
        ?.total;
    }
  }, [selectedRoom, rateId]);

  const { matchesMobile: isMobile } = useDeviceTypes();

  const roomElementRef = useRef<HTMLDivElement | null>(null);
  const viewRoomOnClick = useCallback(() => {
    if (isMobile) {
      goToNextStep();
    } else {
      scrollTopWithOffset(roomElementRef.current);
    }
  }, [goToNextStep, isMobile]);

  const onSelectedRate = useCallback(() => {
    // TODO - do we need to select the room and rate here?
    // it is already selected in the <RoomSelect/> component
    dispatch(
      setSelectedRoom(roomList.find((r) => r?.roomInfo?.roomId === roomId))
    );
    dispatch(setSelectedRate(rateId));

    trackEvent(
      LodgingShopTrackingEvents.hotel_tapped_room_selection_continue,
      ProductType.Hotel,
      selectedRoomTrackingProperties.properties,
      selectedRoomTrackingProperties.encryptedProperties
    );

    goToNextStep();
  }, [
    dispatch,
    goToNextStep,
    roomList,
    roomId,
    rateId,
    trackEvent,
    selectedRoomTrackingProperties,
  ]);

  const availabilityLink = useAvailabilityLink(lodging?.lodging.city);

  const totalPriceString = useCallback(
    (pricing: FiatAndPointPrice) =>
      pricing?.points
        ? t("commBank.totalPrice", {
            cashPriceWithSymbol: formatFiatCurrency(pricing.fiat),
            numberOfNights: nights,
            pointPrice: numberWithCommas(pricing.points?.value),
          })
        : t("priceForNights", {
            price: formatFiatCurrency(pricing.fiat),
            count: nights,
          }),
    [formatFiatCurrency, nights, t]
  );

  const lodgingDetails = lodging?.lodging;

  useEffect(() => {
    if (lodgingDetails) {
      trackEvent(
        LodgingShopTrackingEvents.hotel_viewed_cover,
        ProductType.Hotel,
        lodging.trackingPropertiesV2.properties,
        lodging.trackingPropertiesV2.encryptedProperties
          ? [lodging.trackingPropertiesV2.encryptedProperties]
          : []
      );
    }
  }, [trackEvent, lodgingDetails]);

  if (isLoadingLodging || !lodging) {
    return <LodgingDetailsPageSkeleton isMobile={isMobile} />;
  }

  return (
    <ShopPage
      className={clsx(styles.LodingDetailsPage, isMobile && styles.mobile)}
      // This allows to have a fullwidth section with different bg color
      footer={isMobile ? null : <RoomSelect ref={roomElementRef} />}
      stickyFooter={
        isMobile ? (
          <div className="continue-button-container">
            <PriceSummary
              title={t("hotelShop.startingPriceLabel", {
                price: lowestPrice?.fiat
                  ? formatFiatCurrency({
                      ...lowestPrice.fiat,
                      value: lowestPrice.fiat.value / nights / rooms,
                    })
                  : 0,
              })}
              price={lowestPrice}
              nights={nights}
            />
            <ActionButton
              className={clsx("primary-button", "full-width")}
              onClick={() => goToNextStep()}
              message={t("hotelShop.chooseRoomButtonLabel")}
            ></ActionButton>
          </div>
        ) : (
          (lowestPrice ||
            (selectedRoomRate && selectedRoom && selectedPrice)) && (
            <div className={styles.ReserveButtonContainer}>
              {selectedRoom && selectedRoomRate && selectedPrice ? (
                <>
                  <PriceSummary
                    title={selectedRoom.roomInfo.name}
                    price={selectedPrice}
                    nights={nights}
                  />
                  <button
                    onClick={onSelectedRate}
                    className={styles.ReserveButton}
                  >
                    {t("continue")}
                  </button>
                </>
              ) : (
                <>
                  <PriceSummary
                    title={t("hotelShop.startingPriceLabel", {
                      price: lowestPrice?.fiat
                        ? formatFiatCurrency({
                            ...lowestPrice.fiat,
                            value: lowestPrice.fiat.value / nights / rooms,
                          })
                        : 0,
                    })}
                    price={lowestPrice}
                    nights={nights}
                  />
                  <button
                    onClick={viewRoomOnClick}
                    className={styles.ReserveButton}
                  >
                    {t("hotelShop.chooseRoomButtonLabel")}
                  </button>
                </>
              )}
            </div>
          )
        )
      }
    >
      {isMobile && (
        <HotelShopHeader
          searchState={{
            place: {
              label: lodging?.lodging?.name,
            },
            fromDate,
            untilDate,
            guests,
            rooms,
          }}
          onBack={() => {
            navigate(availabilityLink);
          }}
          onEdit={() => {
            navigate(
              `${PATH_HOME}${PATH_HOTELS_ROOT}${PATH_SEARCH_RELATIVE}?${URL_PARAM_KEYS.PREV_SEARCH}=true&${URL_PARAM_KEYS.STEP}=${MobileHotelSearchStep.CalendarPicker}`
            );
          }}
        />
      )}

      {isMobile ? (
        <>
          <div className="image-gallery">
            <ImageGallery viewRoomOnClick={viewRoomOnClick} />
          </div>

          <Box
            className="star-and-review"
            display="flex"
            gridGap="1rem"
            alignItems="flex-start"
            flexDirection="column"
          >
            <Box
              className="hotel-star"
              display="flex"
              gridGap="0.5rem"
              alignItems="center"
            >
              <ApacIconComponent name={ApacIconName.Star} />
              <Typography>
                {t("nStarHotelRating", {
                  numOfStars: toStarRating(lodging?.lodging.starRating),
                })}
              </Typography>
            </Box>
            {hotelReviewScore > 1 && (
              <Box
                className="tripadvisor"
                display="flex"
                gridGap="0"
                alignItems="flex-end"
              >
                <TripAdvisorBadge
                  translateToTheLeft
                  withTripAdvisorIcon
                  scoreOutOfFive={hotelReviewScore}
                />
                (
                <I18nMarkup
                  className="review-text"
                  tKey="tripAdvisorReviewCountNoParentheses_other"
                  replacements={{
                    reviewsCount:
                      lodging.lodging.tripAdvisorReviews.reviewsCount,
                  }}
                />
                )
              </Box>
            )}
          </Box>
          <Divider className="divider" />
          <MobileHotelHeader lodging={lodging} isMobile={isMobile} />
        </>
      ) : (
        <>
          <HotelHeader lodging={lodging} isMobile={isMobile} />
          <ImageGallery viewRoomOnClick={viewRoomOnClick} />
          <Divider className="divider" />
        </>
      )}

      <Box className={styles.TopSection}>
        <div
          className={clsx(styles.Grid, isMobile && styles.mobile)}
          id="lodging-content-container"
        >
          {!isMobile && <Amenities isMobile={isMobile} />}

          {isMobile && <Divider className="divider" />}
          <AboutHotelSection />

          {lodging.lodging.tripAdvisorReviews ? (
            <div>
              {isMobile && <Divider className="divider" />}
              <CustomerReviews {...lodging.lodging.tripAdvisorReviews} />
            </div>
          ) : null}

          {!!checkInInstructions && (
            <div>
              {isMobile && <Divider className="divider" />}
              <CheckInAndOutSection
                checkInInstructions={checkInInstructions}
                isMobile={isMobile}
              />
            </div>
          )}

          {isLoadingRooms ? (
            <>
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
            </>
          ) : (
            <div>
              {isMobile && <Divider className="divider" />}
              <CheckInPolicies />
            </div>
          )}
        </div>
        {!isMobile && (
          <SearchDetailsSection
            lodging={lodging}
            rooms={rooms}
            lowestPrice={lowestPrice}
            totalPriceString={totalPriceString}
            initialCheckinDate={fromDate}
            initialCheckoutDate={untilDate}
            onDatesChanged={(checkinDate: string, checkoutDateDate: string) => {
              goToShopLodgingDetails(true, checkinDate, checkoutDateDate);
            }}
            initialGuestCount={{
              adults: guests.adults,
              children: guests.children,
              rooms: rooms,
            }}
            onGuestCountChanged={(guestCount) => {
              goToShopLodgingDetails(true, fromDate, untilDate, guestCount);
            }}
            onChooseRoomClicked={viewRoomOnClick}
          />
        )}
      </Box>
    </ShopPage>
  );
};
