import { takeLeading, takeLatest } from "redux-saga/effects";

import { actionTypes } from "../actions";
import { setUpFlightFreezeParamsSaga } from "./setUpFlightFreezeParamsSaga";
import { getPriceFreezeOfferSaga } from "./getPriceFreezeOfferSaga";
import { fetchPriceFreezeSaga } from "./fetchPriceFreezeSaga";
import { refundPriceFreezeSaga } from "./refundPriceFreezeSaga";

export function* watchSetUpFlightFreezeParams() {
  yield takeLeading(
    actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS,
    setUpFlightFreezeParamsSaga
  );
}

export function* watchGetPriceFreezeOffer() {
  yield takeLatest(actionTypes.GET_PRICE_FREEZE_OFFER, getPriceFreezeOfferSaga);
}

export function* watchFetchPriceFreeze() {
  yield takeLatest(actionTypes.FETCH_PRICE_FREEZE, fetchPriceFreezeSaga);
}

export function* watchRefundPriceFreeze() {
  yield takeLatest(actionTypes.REFUND_PRICE_FREEZE, refundPriceFreezeSaga);
}
