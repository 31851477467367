import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../reducers/types";
import {
  fetchCars,
  fetchFlights,
  fetchHotels,
  listPriceFreeze,
  listWatches,
  setOpenModal,
  setSelectedWatch,
  setTripsFilter,
} from "../TripsList/actions/actions";
import {
  getOpenModal,
  getSelectedCar,
  getSelectedFlight,
  getSelectedHotel,
  getSelectedWatch,
  getTripsFilter,
  getTripsLoading,
  hasFetchTripsError,
  hasTripsToDisplay,
} from "../TripsList/reducer";
import { TripsList } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  tripsFilter: getTripsFilter(state),
  selectedFlight: getSelectedFlight(state),
  selectedHotel: getSelectedHotel(state),
  selectedCar: getSelectedCar(state),
  selectedWatch: getSelectedWatch(state),
  tripsLoading: getTripsLoading(state),
  hasError: hasFetchTripsError(state),
  hasTripsToDisplay: hasTripsToDisplay(state),
  openModal: getOpenModal(state),
});

const mapDispatchToProps = {
  fetchCars,
  fetchFlights,
  fetchHotels,
  listWatches,
  setTripsFilter,
  listPriceFreeze,
  setSelectedWatch,
  setOpenModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TripsListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedTripsList = connector(TripsList);
