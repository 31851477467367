import {
  AirCancelFulfillmentPollRequest,
  AirCancelFulfillmentPollResponse,
  airCancelApi,
} from "@b2bportal/air-cancel-api";
import { axiosInstance } from "@hopper-b2b/api";

export const cancelFulfillPoll = async (
  fulfillmentSessionId
): Promise<AirCancelFulfillmentPollResponse> => {
  const body: AirCancelFulfillmentPollRequest = {
    fulfillmentSessionId,
  };

  return new Promise((resolve, reject) => {
    try {
      airCancelApi(axiosInstance)
        .apiV0SelfserveFlightFlightCancelFulfillmentPollPut(body)
        .then((res) => resolve(res.data));
    } catch (e) {
      reject(e);
    }
  });
};
