import { Box } from "@material-ui/core";
import clsx from "clsx";
import { FlightIconCard } from "../../FlightIconCard";
import { IFlightGridRowSkeletonProps } from "../component";
import { FareCard } from "../../FareCard";
// TODO add parameters for localization
export const FlightGridRowSkeleton = ({
  className,
}: IFlightGridRowSkeletonProps) => {
  return (
    <Box className={clsx("flight-grid-row", className)}>
      <Box className="fastest-tag-container"></Box>
      <FlightIconCard type="skeleton" />
      <Box className="flight-fares">
        <FareCard type="skeleton" />
      </Box>
    </Box>
  );
};

export default FlightGridRowSkeleton;
