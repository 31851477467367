import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { IPassengerCounts, GuestsSelection } from "@hopper-b2b/types";
import { setPassengerCounts } from "../../../../actions/actions";
import { FlightPassengerCountPicker } from "./component";
import {
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
} from "../../../../reducer";

const mapStateToProps = (state: IStoreState) => {
  const counts: IPassengerCounts = {
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
  };
  return {
    counts,
  };
};

const mapDispatchToProps = {
  setPassengerCounts: (counts: IPassengerCounts | GuestsSelection) =>
    setPassengerCounts(counts as IPassengerCounts),
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ConnectedFlightPassengerCountPickerProps = ConnectedProps<
  typeof connector
>;
export const ConnectedPassengerCountPicker = connector(
  FlightPassengerCountPicker
);
