import { ReactNode, lazy, Suspense } from "react";
import { ApacModalLink, IApacActionLinkProps, CBASpinner } from "../../";
const ApacTermsApp = lazy(() => import("@commbank/terms"));

export interface IApacTermsLinkProps
  extends Omit<IApacActionLinkProps, "message"> {
  scrollSelector?: string;
  children?: ReactNode;
}

/**
 * A link component which loads the T&C app in a modal
 * and scrolls to the specified section
 */
export const ApacTermsLink = ({
  children,
  scrollSelector,
  ...linkProps
}: IApacTermsLinkProps) => {
  return (
    <ApacModalLink
      linkProps={linkProps}
      scrollSelector={scrollSelector}
      content={
        <Suspense fallback={<CBASpinner />}>
          <ApacTermsApp />
        </Suspense>
      }
    >
      {children}
    </ApacModalLink>
  );
};
