import { Person } from "@b2bportal/lodging-api";
import { getTravelerType } from "@checkout/components";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Slot } from "@hopper-b2b/ui";
import { Box, Typography } from "@material-ui/core";
import "./styles.scss";
import dayjs from "dayjs";
import { getDateTimeWithFormat } from "@hopper-b2b/utilities";

export interface TravelerInformationProps {
  travelers: Array<Person>;
}

export const TravelerInformation = ({
  travelers,
}: TravelerInformationProps) => {
  const { t } = useI18nContext();

  return (
    <Box className="flight-traveler-information-container apac">
      {travelers.map((traveler) => {
        const age = dayjs().diff(
          getDateTimeWithFormat(traveler.dateOfBirth, "YYYY-MM-DD"),
          "year"
        );
        return (
          <Box key={traveler.id} className="flight-traveler-item">
            <Slot id="mytrips-traveler-icon" age={age} />
            <Box className="traveler-details">
              <Typography variant="body1" className="traveler-name">
                {traveler.givenName + " " + traveler.surname}
              </Typography>
              <Typography variant="body1" className="traveler-type">
                {getTravelerType(traveler, {
                  adultTitle: t("passengerInformation.adult"),
                  childTitle: t("passengerInformation.child"),
                  infantSeatTitle: t("passengerInformation.infant"),
                  infantLapTitle: t("passengerInformation.infant"),
                })}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
