import { useState } from "react";
import * as React from "react";
import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  Typography,
} from "@material-ui/core";
import { ReferenceObject } from "popper.js";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Icon, IconName } from "../Icon";
import clsx from "clsx";
import "./styles.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTenantIcons } from "@hopper-b2b/utilities";

export interface DatePickerButtonProps {
  buttonProps?: ButtonBaseProps;
  dateFormat?: string;
  startDate: Date | null;
  endDate: Date | null;
  disabled?: boolean;
  startLabel: string;
  endLabel: string;
  renderCalendarPopup?: (extraProps: {
    open: boolean;
    closePopup: () => void;
    anchorEl: ReferenceObject;
  }) => React.ReactElement;
  onClick?: () => void;
  tabIndex?: number;
  buttonClass?: string;
  hideEndDate?: boolean;
  hideSeparator?: boolean;
  ariaLabel?: string;
  classes?: string[];
}

type IAnchorEl = EventTarget | null;

interface ContentProps {
  label: string;
  date: Date | null;
}

export const DatePickerButton = (props: DatePickerButtonProps) => {
  const {
    startDate,
    endDate,
    startLabel,
    endLabel,
    disabled,
    renderCalendarPopup,
    onClick,
    buttonProps = {},
    dateFormat,
    tabIndex,
    buttonClass,
    hideEndDate,
    hideSeparator,
    ariaLabel,
    classes = [],
  } = props;

  const tenantIcons = useTenantIcons();

  const [anchorEl, setAnchorEl] = useState<IAnchorEl>(null);

  const handleClick = (target: EventTarget) => {
    setAnchorEl(anchorEl ? null : target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const calendarIcon = tenantIcons.airplaneCalendar ?? tenantIcons.calendar;

  const startIcon =
    tenantIcons.calendarStartIcon || calendarIcon ? (
      <img src={tenantIcons.calendarStartIcon ?? calendarIcon} alt="" />
    ) : (
      <Icon
        className="calendar-icon"
        name={IconName.CheckInCalendar}
        ariaLabel=""
        aria-hidden={true}
      />
    );

  const endIcon =
    tenantIcons.calendarEndIcon || calendarIcon ? (
      <img src={tenantIcons.calendarEndIcon ?? calendarIcon} alt="" />
    ) : (
      <Icon
        className="calendar-icon"
        name={IconName.CheckOutCalendar}
        ariaLabel=""
        aria-hidden={true}
      />
    );

  const Content = ({ date, label }: ContentProps) => (
    <Box className="button-content">
      <Typography color="textSecondary" className="content-top-label" noWrap>
        {date ? label : ""}
      </Typography>
      <Typography
        color="textSecondary"
        className={clsx("content-main-content", { label: !date })}
      >
        {date ? dayjs(date).format(dateFormat || "ddd D, MMM YYYY") : label}
      </Typography>
    </Box>
  );

  const startDateComponent = (
    <Box className="start-date-container">
      {startIcon}
      <Content date={startDate} label={startLabel} />
    </Box>
  );

  const endDateComponent = (
    <Box className="end-date-container">
      {endIcon}
      <Content date={endDate} label={endLabel} />
    </Box>
  );

  const separatorIcon = (
    <Box className="separator-container">
      <FontAwesomeIcon icon={faArrowRight as IconProp} />
    </Box>
  );

  return (
    <Box className={clsx("date-picker-button-container", ...classes)}>
      <ButtonBase
        className={clsx("date-picker-button", buttonClass)}
        aria-label={ariaLabel}
        disabled={disabled}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleClick(e.currentTarget);
            onClick && onClick();
          }
        }}
        onClick={
          disabled
            ? undefined
            : (e) => {
                handleClick(e.currentTarget);
                onClick && onClick();
              }
        }
        tabIndex={tabIndex}
        disableTouchRipple
        disableRipple
        {...buttonProps}
      >
        {startDateComponent}
        {!hideEndDate && !hideSeparator && separatorIcon}
        {!hideEndDate && endDateComponent}
      </ButtonBase>
      {renderCalendarPopup && (
        <div>
          {renderCalendarPopup({
            open: Boolean(anchorEl),
            closePopup: handleClose,
            anchorEl: anchorEl as unknown as ReferenceObject,
          })}
        </div>
      )}
    </Box>
  );
};
