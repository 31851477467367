import {
  FlightItinerarySegment,
  FlightItinerarySlice,
  PassengerTripPricing,
} from "@b2bportal/air-booking-api";
import {
  AirExchangeAgentFulfillSegment,
  AirExchangeAgentFulfillSlice,
  Airline,
  Airport,
  ExchangeActionEnum,
  ExchangeScenario,
} from "@b2bportal/air-exchange-api";
import {
  Fare,
  FareSliceOutbound,
  Flights,
  Slice,
  TripSegment,
  TripSlice,
} from "@b2bportal/air-shopping-api";
import { FareDetails, FareSliceDetails } from "@hopper-b2b/types";
import dayjs from "dayjs";
import { last } from "lodash-es";

import { ITripDetailsByTripId } from "../../shop/reducer";
import { IFlightListData } from "../../shop/v3/components/FlightList/FlightList";
import { IPartialAirportMap } from "../types";

export function skipShopAction(selection?: ExchangeActionEnum) {
  return Boolean(
    !selection ||
      selection === ExchangeActionEnum.Keep ||
      selection === ExchangeActionEnum.Remove
  );
}

export const getPolicyAttr = <T = any>(
  attr: string,
  policy?: ExchangeScenario | null
) => {
  if (policy && attr in policy) return policy[attr] as T;

  return null;
};

export const getAirportCityName = (
  airports: IPartialAirportMap,
  code?: string
): string => (code ? airports[code]?.cityName ?? code : "");

export const prevSegToTripSegment = (
  airlines: Record<string, Airline>,
  airports: Record<string, Airport>,
  segment: FlightItinerarySegment
): TripSegment => {
  const {
    destination,
    marketingAirline: { code: marketingCode },
    operatingAirline: { code: operatingCode, flightNumber },
    origin,
    plusDays,
    stops,
    updatedArrival,
    updatedDeparture,
    zonedUpdatedArrival,
    zonedUpdatedDeparture,
  } = segment;
  const marketingName = airlines[marketingCode]?.displayName ?? "";
  const operatingName = airlines[operatingCode]?.displayName ?? "";

  return {
    plusDays,
    stops,
    airlineCode: operatingCode,
    airlineName: operatingName,
    arrivalTime: updatedArrival,
    departureTime: updatedDeparture,
    destinationCode: destination.locationCode,
    destinationName: airports[destination.locationCode]?.name,
    flightNumber: String(flightNumber),
    marketingAirline: { code: marketingCode, name: marketingName },
    operatingAirline: { code: operatingCode, name: operatingName },
    originCode: origin.locationCode,
    originName: airports[origin.locationCode]?.name,
    zonedArrivalTime: zonedUpdatedArrival,
    zonedDepartureTime: zonedUpdatedDeparture,
  };
};

export const convertPrevToTripSlice = (
  airlines: Record<string, Airline>,
  airports: Record<string, Airport>,
  slice: FlightItinerarySlice
): TripSlice => {
  const { segments } = slice;
  const {
    origin: { locationCode: originCode },
    updatedDeparture,
    zonedUpdatedDeparture,
  } = segments[0];
  const {
    destination: { locationCode: destCode },
    updatedArrival,
  } = last(segments);
  const totalDuration = dayjs(updatedArrival).diff(updatedDeparture, "minutes");
  let totalStops = 0;

  const segmentDetails = segments.map<TripSegment>((seg) => {
    const tripSegment = prevSegToTripSegment(airlines, airports, seg);

    totalStops += seg.stops;

    return tripSegment;
  });

  return {
    segmentDetails,
    arrivalTime: updatedArrival,
    departureTime: updatedDeparture,
    destinationCode: destCode,
    destinationName: getAirportCityName(airports, destCode),
    id: "",
    originCode: originCode,
    originName: getAirportCityName(airports, originCode),
    outgoing: true,
    stops: totalStops,
    totalDurationMinutes: totalDuration,
    zonedDepartureTime: zonedUpdatedDeparture,
  };
};

export function buildOutgoingShopSlice(
  flight: IFlightListData,
  fareId: string,
  shoppedFlights: Flights
) {
  const { fares, slice } = flight;
  const { fares: allFares, fareSlices } = shoppedFlights;
  const flightFares = fares as FareSliceOutbound[];
  const selectedFare =
    flightFares.find((f) => f.example?.fare === fareId) ?? allFares[fareId];
  let fareSliceId = "";
  let tripId = "";

  if ("example" in selectedFare) {
    fareSliceId = selectedFare.fareSlice;
    tripId = selectedFare.example.trip;
  } else {
    fareSliceId = selectedFare.outbound;
    tripId = selectedFare.tripId;
  }

  return {
    tripId,
    outgoingFareId: fareId,
    outgoingFareRating: fareSlices[fareSliceId].fareShelf?.value,
    outgoingSliceId: slice,
  };
}

export const buildPayloadSlice = (
  slice: FlightItinerarySlice | Slice | null,
  fareSlice?: FareSliceDetails
): AirExchangeAgentFulfillSlice => {
  let segments: AirExchangeAgentFulfillSegment[] = [];

  if (slice) {
    if ("id" in slice) {
      // handles new slice from shop response
      const bookingCodes =
        fareSlice?.fareDetails.segments.map((fSeg) => fSeg.bookingCode ?? "") ??
        [];

      segments = slice.segments.map((seg, idx) => ({
        arrivalTime: seg.arrival,
        departureTime: seg.departure,
        destination: seg.destination,
        fareClassCode: bookingCodes[idx],
        flightNumber: seg.flightNumber,
        marketingCarrier: seg.marketingAirline,
        origin: seg.origin,
      }));
    } else {
      // handles retained slice from original booking
      segments = slice.segments.map((seg) => ({
        arrivalTime: seg.updatedArrival,
        departureTime: seg.updatedDeparture,
        destination: seg.destination.locationCode,
        fareClassCode: seg.bookingClassCode,
        flightNumber: String(seg.marketingAirline.flightNumber),
        marketingCarrier: seg.marketingAirline.code,
        origin: seg.origin.locationCode,
      }));
    }
  }

  return { segments };
};

export function buildReturnShopSlice(
  flight: IFlightListData,
  fareId: string,
  fareSlices: Flights["fareSlices"],
  tripDetailsMap: ITripDetailsByTripId
) {
  const flightFares = flight.fares as Fare[];
  const fare = flightFares.find((f) => f.id === fareId);

  if (fare) {
    const { tripId = "" } = fare;
    const tripDetails = tripDetailsMap[tripId];
    const fareDetails = tripDetails?.fareDetails.find((fd) => fd.id === fareId);
    const sliceId = fareSlices[fare.return].slice;

    return {
      returnFareId: fareId,
      returnFareRating: fareDetails?.slices[1]?.fareShelf?.rating,
      returnSliceId: sliceId,
      tripId: tripDetails?.id || fare.tripId,
    };
  }
}
export const getPricingInfo = (
  paxPricing: PassengerTripPricing[],
  type: string,
  tripFareDetails?: FareDetails
) => {
  const { paxPricings = [] } = tripFareDetails ?? {};
  const ogPaxPricing = paxPricing?.find(
    (pricing) => pricing.passengerType === type
  );
  const pTypePricing = paxPricings.find((pricing) => pricing.paxType === type);
  const { baseWithoutMargin, taxes } = ogPaxPricing;
  const { baseAmount, taxAmount } = pTypePricing?.pricing ?? {};
  const ogBaseAmt = baseWithoutMargin?.fiat.value ?? 0;
  const ogTaxAmt = taxes?.fiat.value ?? 0;
  const currBase = baseAmount?.fiat.value ?? 0;
  const currTax = taxAmount?.fiat.value ?? 0;

  return {
    base: ogBaseAmt + currBase,
    tax: ogTaxAmt + currTax,
  };
};

export function getStopsCopyKey(numStops: number) {
  if (numStops === 0) return "stopDetails.nonstop";
  if (numStops === 1) return "stopDetails.stop_one";
  return "stopDetails.stop_other";
}
