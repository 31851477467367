import { Box, Typography } from "@material-ui/core";
import { ApacIconName } from "../ApacIcon";
import { ApacIconComponent } from "../ApacIconComponent";
import "./styles.scss";
import clsx from "clsx";

export const ErrorMessageWrapper = ({
  message,
  isWarning,
  disabled,
}: {
  message: string;
  isWarning?: boolean;
  disabled?: boolean;
}) => {
  return (
    <Box
      className={clsx("apac-error-message-wrapper", {
        hide: !message,
        isWarning,
        disabled,
      })}
    >
      <ApacIconComponent
        name={isWarning ? ApacIconName.Warn : ApacIconName.Info}
      />
      <Typography variant="body1">{message}</Typography>
    </Box>
  );
};
