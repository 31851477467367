import { Checkout, ParentState } from "@hopper-b2b/checkout";
import {
  CFAR_OFFER_QUOTE_ID_QUERY_PARAM,
  CHFAR_ID_QUERY_PARAM,
  CHFAR_OFFER_QUOTE_ID_QUERY_PARAM,
  CheckoutProps,
  PRICE_FREEZE_ID_QUERY_PARAM,
} from "@hopper-b2b/types";
import {
  getSliceIndex,
  useFeatureFlagsContext,
  useSessionContext,
} from "@hopper-b2b/utilities";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { PATH_PRICE_FREEZE_PURCHASE } from "../../utils/urlPaths";
import { getCfarOffers, getSelectedCfarOffer } from "../cfar/reducer";
import { getChfarOffers, getSelectedChfarOffer } from "../chfar/reducer";
import {
  getDisruptionOffers,
  getSelectedDisruptionOffers,
} from "../disruption/reducer";
import {
  getCandidateIdIfPDPEligible,
  getWatches,
  predictionSelector,
} from "../shop/reducer";
import { getSelectedCreditOffer, getSelectedOffer } from "../wallet/reducer";
import { FlightsCheckoutConnectorProps } from "./container";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useDocumentTitle } from "../../../../hooks/src/useDocumentTitle";

export interface FlightsCheckoutProps
  extends CheckoutProps,
    FlightsCheckoutConnectorProps {}

export const FlightsCheckout = (props: FlightsCheckoutProps) => {
  const {
    actions,
    airports,
    clientAssets,
    Component,
    context,
    departureDate,
    destination,
    destinationCountryCode,
    getInitialContext,
    guards,
    onCleanUp,
    onLoad,
    onPathnameChange,
    onStateValueChange,
    origin,
    originCountryCode,
    returnDate,
    selectedTrip,
    services,
    sessionInfo,
    shopPricingInfo,
    stateMachine,
    tripCategory,
    tripDetails,
    tripResultEventProperties,
    validateContext,
    cheapestPriceFreezeOfferData,
    offers,
  } = props;
  const { t } = useI18nContext();
  useDocumentTitle(t("paymentStepTitle"));

  const featureFlags = useFeatureFlagsContext();

  const queryString = useLocation().search;
  const queryParams = useMemo(
    () => new URLSearchParams(queryString),
    [queryString]
  );
  const priceFreezeId = useMemo(
    () => queryParams.get(PRICE_FREEZE_ID_QUERY_PARAM),
    [queryParams]
  );
  const cfarQuoteId = useMemo(
    () => queryParams.get(CFAR_OFFER_QUOTE_ID_QUERY_PARAM),
    [queryParams]
  );
  const chfarQuoteId = useMemo(
    () => queryParams.get(CHFAR_OFFER_QUOTE_ID_QUERY_PARAM),
    [queryParams]
  );

  const chfarId = useMemo(
    () => queryParams.get(CHFAR_ID_QUERY_PARAM),
    [queryParams]
  );

  const departureSliceIndex = getSliceIndex(true, tripDetails);
  const departureSlice = tripDetails?.slices[departureSliceIndex];

  const returnSliceIndex = getSliceIndex(false, tripDetails);
  const hasReturnFlight = returnSliceIndex !== -1;

  const returnSlice = hasReturnFlight
    ? tripDetails?.slices[returnSliceIndex]
    : null;

  const departureLabel = useMemo(
    () =>
      airports[departureSlice?.destinationCode]
        ? airports[departureSlice?.destinationCode].cityName
        : departureSlice?.destinationName,
    [airports, departureSlice?.destinationCode, departureSlice?.destinationName]
  );

  const returnLabel = useMemo(
    () =>
      airports[returnSlice?.destinationCode || ""]
        ? airports[returnSlice?.destinationCode || ""]?.cityName
        : returnSlice?.destinationName,
    [airports, returnSlice?.destinationCode, returnSlice?.destinationName]
  );

  const cfarOffers = useSelector(getCfarOffers);
  const chfarOffers = useSelector(getChfarOffers);

  const selectedCfarOffer = useSelector(getSelectedCfarOffer);
  const selectedChfarOffer = useSelector(getSelectedChfarOffer);

  const disruptionOffers = useSelector(getDisruptionOffers);
  const selectedDisruptionOffers = useSelector(getSelectedDisruptionOffers);

  const offer = useSelector(getSelectedOffer);
  const creditOffer = useSelector(getSelectedCreditOffer);

  const watches = useSelector(getWatches);
  const candidateId = useSelector(getCandidateIdIfPDPEligible);
  const prediction = useSelector(predictionSelector);

  const { countryCode, phoneNumber, email } = useSessionContext();

  const contextToInitialize = useMemo(() => {
    const result = {
      ...context,
      sessionInfo: {
        ...(context?.sessionInfo || {}),
        ...sessionInfo,
      },
      [ParentState.contactInformation]: {
        contactInfo: {
          phoneNumber: phoneNumber,
          email: email,
          countryCode: countryCode,
        },
      },
      flightShop: {
        ...(context?.fligthShop || {}),
        tripDetails,
        selectedTrip,
        shopPricingInfo,
        departureLabel,
        airports,
        returnLabel,
        tripResultEventProperties,
        offers,
        watches,
      },
      flightSearch: {
        ...(context?.flightSearch || {}),
        tripCategory,
        origin,
        departureDate,
        destination,
        returnDate,
        originCountryCode,
        destinationCountryCode,
      },
      featureFlags,
      [ParentState.priceFreeze]: {
        priceFreezeId: priceFreezeId,
        priceFreezePurchaseParams: {
          urlPrefix: PATH_PRICE_FREEZE_PURCHASE,
        },
        cheapestPriceFreezeOfferData,
      },
      [ParentState.cancelForAnyReason]: {
        cfarQuoteId,
        cfarOffers,
        selectedOffer: selectedCfarOffer,
      },
      [ParentState.changeForAnyReason]: {
        chfarQuoteId,
        chfarOffers,
        selectedOffer: selectedChfarOffer,
      },
      [ParentState.changeForAnyReasonDiscount]: {
        chfarId: chfarId,
      },
      [ParentState.disruption]: {
        selectedOffers: selectedDisruptionOffers,
        offersResponse: disruptionOffers,
      },
      [ParentState.priceDrop]: {
        candidateId,
        prediction,
      },
      //TODO: poplulate from state or queryParams, when Wallet is added to shop
      [ParentState.wallet]: {
        offer: offer,
        creditOffer: creditOffer,
      },
    };

    return result;
  }, [
    context,
    sessionInfo,
    phoneNumber,
    email,
    countryCode,
    tripDetails,
    selectedTrip,
    shopPricingInfo,
    departureLabel,
    airports,
    returnLabel,
    tripResultEventProperties,
    offers,
    watches,
    tripCategory,
    origin,
    departureDate,
    destination,
    returnDate,
    originCountryCode,
    destinationCountryCode,
    featureFlags,
    priceFreezeId,
    cheapestPriceFreezeOfferData,
    cfarQuoteId,
    cfarOffers,
    selectedCfarOffer,
    chfarQuoteId,
    chfarOffers,
    selectedChfarOffer,
    chfarId,
    selectedDisruptionOffers,
    disruptionOffers,
    candidateId,
    prediction,
    offer,
    creditOffer,
  ]);

  return (
    <Checkout
      actions={actions}
      Component={Component}
      context={contextToInitialize}
      guards={guards}
      onCleanUp={onCleanUp}
      onLoad={onLoad}
      services={services}
      stateMachine={stateMachine}
      getInitialContext={getInitialContext}
      clientAssets={clientAssets}
      onStateValueChange={onStateValueChange}
      onPathnameChange={onPathnameChange}
      validateContext={validateContext}
    />
  );
};
