/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/alt-text */
import { useMemo } from "react";
import clsx from "clsx";
import { FintechCoverageType, FintechProductOption } from "@hopper-b2b/types";
import { IconName } from "../Icon";
import { IconComponent } from "../IconComponent";
import { OfferLoadingCard } from "../OfferLoadingCard";
import "./styles.scss";
import { ButtonWrap } from "../ButtonWrap";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Radio } from "@material-ui/core";

interface FintechPurchaseCardProps {
  option: FintechProductOption;
  className?: string;
  clientAssets?: Record<string, any>;
  loading?: boolean;
  disabled?: boolean;
  isHalfSheet?: boolean;
}

export const FintechPurchaseCard = ({
  option,
  className,
  clientAssets,
  loading = false,
  isHalfSheet = false,
  disabled = false,
}: FintechPurchaseCardProps) => {
  const { t } = useI18nContext();

  if (loading) {
    <OfferLoadingCard />;
  }

  const checkmarkIcon = useMemo(() => {
    return option.selected ? (
      <>
        {clientAssets && clientAssets["checkmarkFilled"] ? (
          <img
            src={clientAssets["checkmarkFilled"]}
            className="fintech-purchase-card-icon"
          />
        ) : (
          <IconComponent
            className="fintech-purchase-card-icon"
            name={IconName.CheckCircleFilled}
          />
        )}
      </>
    ) : (
      <>
        {clientAssets && clientAssets["checkmarkSimple"] ? (
          <img
            src={clientAssets["checkmarkSimple"]}
            className="fintech-purchase-card-icon"
          />
        ) : (
          <IconComponent
            className="fintech-purchase-card-icon"
            name={IconName.Checkmark}
          />
        )}
      </>
    );
  }, [clientAssets, option]);

  return (
    <ButtonWrap
      className={clsx("fintech-purchase-card", className, {
        selected: option.selected,
      })}
      disabled={disabled}
      onClick={option.onClick}
    >
      <div className="fintech-purchase-card-header">
        <p className="fintech-purchase-card-title">{option.title}</p>
        {!isHalfSheet && option.cost ? (
          <p className="fintech-purchase-card-price">{option.cost}</p>
        ) : null}
      </div>
      {isHalfSheet ? (
        <div className="fintech-purchase-card-description-container">
          <div>
            <ul className="fintech-purchase-card-description">
              {(option.description as string[]).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <Radio
            checked={!!option.selected}
            className={clsx("radio", { selected: option.selected })}
          />
        </div>
      ) : (
        <div className="fintech-purchase-card-description">
          {(option.description as string[]).map((item, index) => (
            <div key={index} className="fintech-purchase-card-list-item">
              {option.value === FintechCoverageType.None ? null : checkmarkIcon}
              <p className="fintech-purchase-card-list-item-label">{item}</p>
            </div>
          ))}
        </div>
      )}
      {isHalfSheet && option.cost && (
        <div className="fintech-purchase-price">
          {option.cost}/{t("traveler")}
        </div>
      )}
    </ButtonWrap>
  );
};
