import { FiatPrice } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { B2BLoadingPopup, ErrorPopup } from "@hopper-b2b/ui";
import {
  useDeviceTypes,
  useEnableAirExchange,
  useUberBridge,
} from "@hopper-b2b/utilities";
import clsx from "clsx";
import { size } from "lodash-es";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useHistory, useLocation } from "react-router";

import { ClientContext } from "../../ApacFlightApp";
import { PATH_TRIPS } from "../../utils/urlPaths";
import { actions as searchActions } from "../search/actions";
import { getDepartureDate, getDestination, getOrigin } from "../search/reducer";
import { setFlightShopProgress } from "../shop/actions/actions";
import { FlightShopStep, flightShopProgressSelector } from "../shop/reducer";
import { actions as exchangeActions } from "./actions";
import {
  ConfirmExchangeReqStep,
  DesktopLandingPage,
  ErrorBoundary,
  ExchangeHeader,
  ExchangeShopStep,
  GateKeeperPopup,
  MobileCalendarPicker,
  MobileTerminiPicker,
  PriceBreakdownStep,
  RequestSubmittedStep,
  ReviewItineraryStep,
  SlicePickerStep,
} from "./components";
import { getExchangeContext } from "./reducer/policy";
import { getIsLoadingContext, getShowSlicePicker } from "./reducer/selectors";
import { submitExchangeReq } from "./reducer/shop";
import { getIsExchangeable, getIsFtc } from "./selectors";

import "./styles.scss";

export type IFlightExchangeProps = RouteComponentProps;

const FlightExchange = () => {
  const clientContext = useContext(ClientContext);
  const { matchesMobile } = useDeviceTypes();
  const dispatch = useDispatch();
  const enableAirExchange = useEnableAirExchange();
  const history = useHistory();
  const { t } = useI18nContext();
  const location = useLocation();
  const { postEvent, setHeader } = useUberBridge();

  const isExchangeable = useSelector(getIsExchangeable);
  const isFtc = useSelector(getIsFtc);
  const isLoadingContext = useSelector(getIsLoadingContext);
  const selectedDepartureDate = useSelector(getDepartureDate);
  const selectedDest = useSelector(getDestination);
  const selectedOrigin = useSelector(getOrigin);
  const shopStep = useSelector(flightShopProgressSelector);
  const showSlicePicker = useSelector(getShowSlicePicker);

  const searchParams = new URLSearchParams(location?.search);

  const [bookingId] = useState(searchParams.get("bookingId"));

  const setExchangeStep = useCallback(
    (nextStep: FlightShopStep) => {
      dispatch(setFlightShopProgress(nextStep));
    },
    [dispatch]
  );

  const backToTrips = useCallback(
    () =>
      history.push({
        pathname: PATH_TRIPS,
        search: bookingId ? `?tripId=${bookingId}` : "",
      }),
    [history, bookingId]
  );

  const fetchFlights = useCallback(() => {
    if (
      selectedDepartureDate &&
      selectedOrigin &&
      selectedDest &&
      !!bookingId
    ) {
      dispatch(exchangeActions.getFlightSummaries(bookingId, matchesMobile));
    }
  }, [
    bookingId,
    dispatch,
    matchesMobile,
    selectedDepartureDate,
    selectedOrigin,
    selectedDest,
  ]);

  const checkForSlicePicker = useCallback(() => {
    if (showSlicePicker) {
      setExchangeStep(FlightShopStep.ExchangeSlicePicker);
    } else {
      fetchFlights();
    }
  }, [fetchFlights, setExchangeStep, showSlicePicker]);

  const fetchPolicy = useCallback(
    async (bookingId) => {
      dispatch(getExchangeContext(bookingId));
    },
    [dispatch]
  );

  const handleLocationSelect = useCallback(async () => {
    if (!selectedDest || !selectedOrigin) return;

    batch(() => {
      dispatch(searchActions.fetchDepartureCalendar());

      if (matchesMobile) {
        setExchangeStep(FlightShopStep.ExchangeDatesPicker);
      }
    });
  }, [dispatch, matchesMobile, selectedDest, selectedOrigin, setExchangeStep]);

  const submitExchange = useCallback(
    (totalPrice: FiatPrice) => {
      dispatch(submitExchangeReq(totalPrice));
    },
    [dispatch]
  );

  const ExchangeStepContent = useMemo(() => {
    let loadingMessage = t("exchangeable.loading.context");

    // fixes a bug where page would be partially scrolled down on step render
    setTimeout(
      () => window.scrollTo({ behavior: "smooth", left: 0, top: 0 }),
      0
    );

    if (matchesMobile) {
      switch (shopStep) {
        case FlightShopStep.ExchangeLoadingContext:
        case FlightShopStep.ExchangeSearching:
        case FlightShopStep.ExchangeSubmitting:
          if (shopStep === FlightShopStep.ExchangeSearching) {
            loadingMessage = t("exchangeable.loading.shop") || "";
          } else if (shopStep === FlightShopStep.ExchangeSubmitting) {
            loadingMessage = t("exchangeable.loading.submit") || "";
          }

          return (
            <B2BLoadingPopup
              open
              className="flight-exchange-loading"
              message={" "}
              secondaryMessage={loadingMessage}
              fullScreen
              popupSize={matchesMobile ? "mobile" : "desktop"}
            />
          );
        case FlightShopStep.ExchangeGatekeeper:
          return <GateKeeperPopup backToTrips={backToTrips} />;
        case FlightShopStep.ExchangeLanding:
          return <MobileTerminiPicker onContinue={handleLocationSelect} />;
        case FlightShopStep.ExchangeDatesPicker:
          return <MobileCalendarPicker onSearch={checkForSlicePicker} />;
        case FlightShopStep.ExchangeSlicePicker:
          return <SlicePickerStep onContinue={fetchFlights} />;
        case FlightShopStep.ExchangePriceBreakdown:
          return <PriceBreakdownStep isMobile={matchesMobile} />;
        case FlightShopStep.ExchangeSubmitted:
          return <RequestSubmittedStep backToTrips={backToTrips} />;
      }
    }

    if (isLoadingContext) {
      return (
        <DesktopLandingPage
          backToTrips={backToTrips}
          onSearch={fetchFlights}
          setExchangeStep={setExchangeStep}
        />
      );
    }

    switch (shopStep) {
      case FlightShopStep.ExchangeLoadingContext:
      case FlightShopStep.ExchangeLanding:
      case FlightShopStep.ExchangeLocationsPicker:
      case FlightShopStep.ExchangeDatesPicker:
      case FlightShopStep.ExchangeSlicePicker:
        return (
          <DesktopLandingPage
            backToTrips={backToTrips}
            onSearch={fetchFlights}
            setExchangeStep={setExchangeStep}
          />
        );
      case FlightShopStep.ChooseDeparture:
      case FlightShopStep.ChooseReturn:
        return (
          <ExchangeShopStep
            bookingId={bookingId}
            setExchangeStep={setExchangeStep}
            onSearch={fetchFlights}
          />
        );
      case FlightShopStep.ReviewItinerary:
        return (
          <ReviewItineraryStep
            isMobile={matchesMobile}
            onSubmitReq={submitExchange}
            setExchangeStep={setExchangeStep}
          />
        );
      case FlightShopStep.ExchangeConfirmReq:
      case FlightShopStep.ExchangeSubmitted:
      case FlightShopStep.ExchangeSubmitting:
        return <ConfirmExchangeReqStep backToTrips={backToTrips} />;
      case FlightShopStep.ExchangeError:
        return (
          <ErrorPopup
            primaryOnClick={() => fetchPolicy(bookingId)}
            primaryButtonText={t("exchangeable.backToTrips")}
            // secondaryButtonText={t("tripReviewLinks.getHelp") || ""}
            // secondaryOnClick={contactCustomerSupport}
            open
            onClose={backToTrips}
            title={t("error.title") || ""}
            subtitle={""}
          />
        );
      default:
        return null;
    }
  }, [
    backToTrips,
    bookingId,
    checkForSlicePicker,
    fetchFlights,
    fetchPolicy,
    handleLocationSelect,
    isLoadingContext,
    matchesMobile,
    setExchangeStep,
    shopStep,
    submitExchange,
    t,
  ]);

  useEffect(() => {
    if (!enableAirExchange) {
      backToTrips();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableAirExchange]);

  useEffect(() => {
    if (bookingId) {
      fetchPolicy(bookingId);
    }
  }, [bookingId, fetchPolicy]);

  useEffect(() => {
    let initStep = FlightShopStep.ExchangeLoadingContext;

    if (!isLoadingContext) {
      if (isExchangeable || isFtc) {
        initStep = FlightShopStep.ExchangeLanding;
      } else {
        initStep = FlightShopStep.ExchangeGatekeeper;
      }
    }

    setExchangeStep(initStep);
  }, [isExchangeable, isFtc, isLoadingContext, matchesMobile, setExchangeStep]);

  return (
    <div className={clsx("flight-exchange-root", { mobile: matchesMobile })}>
      {matchesMobile && (
        <ExchangeHeader backToTrips={backToTrips} setHeader={setHeader} />
      )}
      <ErrorBoundary
        backToTrips={backToTrips}
        errorIcon={clientContext?.assets?.error}
        sendUberEvent={postEvent}
        t={t}
      >
        <div className="flight-exchange-container">{ExchangeStepContent}</div>
      </ErrorBoundary>
    </div>
  );
};

export default FlightExchange;
