import { connect, ConnectedProps } from "react-redux";

import { SortOptionSelection } from "./component";
import { sortOptionSelector } from "../../../../../shop/reducer/selectors";
import { actions } from "../../../../../shop/actions";

import { IStoreState } from "../../../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => ({
  sortOption: sortOptionSelector(state),
});

const mapDispatchToProps = {
  setSortOption: actions.setSortOption,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type SortOptionSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedSortOptionSelection = connector(SortOptionSelection);
