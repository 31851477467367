import { ReactNode } from "react";
import { Box, Popover, Typography } from "@material-ui/core";
import clsx from "clsx";
import "./styles.scss";
import { ApacIconName } from "../ApacIcon";
import { ApacIconComponent } from "../ApacIconComponent";

export interface IApacAlertBannerProps {
  className?: string;
  title: ReactNode;
  description: string;
  toolTipCopy: string;
  onClick?: () => void;
}

export const ApacAlertBanner = (props: IApacAlertBannerProps) => {
  const { className, title, description, onClick } = props;

  return (
    <Box
      className={clsx(className, "apac-flight-combination-banner")}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <div className="apac-flight-combination-banner-titles">
        <Typography className="title" variant="h3">
          <ApacIconComponent
            name={ApacIconName.Info}
            className="info-icon"
            ariaLabel=""
          />
          {title}
        </Typography>
        <Typography className="subtitle" variant="body1">
          {description}
        </Typography>
      </div>
    </Box>
  );
};
