import { IOption } from "@hopper-b2b/ui";
import {
  FormControl,
  MenuItem,
  OutlinedInputProps,
  Select,
  Typography,
} from "@material-ui/core";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";
import clsx from "clsx";
import "./ApacSelect.styles.scss";

export interface IApacSelectProps {
  value: string;
  placeholder?: string;
  onChange: SelectInputProps["onChange"] | OutlinedInputProps["onChange"];
  className?: string;
  disabled?: boolean;
  options: IOption[];
}

export const ApacSelect = ({
  value,
  onChange,
  placeholder,
  className,
  disabled,
  options,
  ...rest
}: IApacSelectProps) => {
  return (
    <FormControl fullWidth>
      <Select
        className={clsx("apac-select", className)}
        value={value}
        disabled={disabled}
        onChange={onChange}
        displayEmpty={!!placeholder}
        disableUnderline
        {...rest}
      >
        {!!placeholder && (
          <MenuItem disabled value="">
            <Typography variant="body2">{placeholder}</Typography>
          </MenuItem>
        )}
        {options.map(({ label, value }) => (
          <MenuItem key={label} value={value}>
            <Typography variant="body1">{label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
