import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import {
  populateTripQueryParams,
  setSelectedFlight,
  setSelectedHotel,
  setSelectedCar,
} from "../../../TripsList/actions/actions";
import { ItineraryList } from "./component";
import {
  itinerariesToDisplaySelector,
  getSelectedFlight,
  getSelectedHotel,
  getSelectedCar,
} from "../../../TripsList/reducer";

const mapStateToProps = (state: IStoreState) => ({
  selectedFlight: getSelectedFlight(state),
  selectedHotel: getSelectedHotel(state),
  selectedCar: getSelectedCar(state),
  itinerariesToDisplay: itinerariesToDisplaySelector(state),
});

const mapDispatchToProps = {
  populateTripQueryParams,
  setSelectedFlight,
  setSelectedHotel,
  setSelectedCar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ItineraryListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedItineraryList = connector(withRouter(ItineraryList));
