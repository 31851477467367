import { useCallback, useEffect, useState } from "react";
import { Variant } from "@material-ui/core/styles/createTypography";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { debounce } from "lodash-es";

import { useI18nContext } from "@hopper-b2b/i18n";
import { PhoneNumberValidatorEnum } from "@hopper-b2b/types";
import {
  ActionLinks,
  B2BTextField,
  IActionLink,
  PhoneInputField,
  Slot,
} from "@hopper-b2b/ui";
import {
  emailRegex as defaultEmailRegex,
  phoneRegex as defaultPhoneRegex,
} from "@hopper-b2b/utilities";

import { IContactInfo } from "../../../../types/common";
import "./styles.scss";

export interface IContactInfoFormProps {
  className?: string;
  isMobile?: boolean;
  title?: string;
  subtitle?: string;
  contactInfo: IContactInfo | null | undefined;
  onContactInfoChange?: (contactInfo: IContactInfo) => void;
  disabled?: boolean;
  hideEmailAddress?: boolean;
  hidePhoneNumber?: boolean;
  onConfirmationEmailBlur?: () => void;
  onConfirmationEmailFocus?: () => void;
  actions?: IActionLink[];
  headerVariant?: Variant;
  header?: boolean;
  showEditAction?: boolean;
  onEdit?: () => void;
  inHotel?: boolean;
  disableRipple?: boolean;
  /*
    this allows the parent component to trigger validatePhone and validateEmail functions through a hook;
    updating this value to anything above 0 will trigger those function.
  */
  validate?: number;
  emailRegex?: RegExp;
  phoneRegex?: RegExp;
  checkPhoneMinLength?: boolean;
  minPhoneLength?: number;
}

const cleanPhoneNumber = (str: string) => str.replace(/\D/g, "");

export const ContactInfoForm = ({
  title,
  subtitle,
  contactInfo,
  onContactInfoChange,
  onConfirmationEmailFocus,
  className,
  isMobile,
  disabled,
  hideEmailAddress = false,
  hidePhoneNumber = false,
  actions,
  headerVariant = "h3",
  header = true,
  showEditAction = false,
  onEdit,
  validate,
  emailRegex = defaultEmailRegex,
  inHotel,
  disableRipple,
  phoneRegex = defaultPhoneRegex,
  checkPhoneMinLength,
  minPhoneLength,
}: IContactInfoFormProps) => {
  const { t, brand } = useI18nContext();

  const [countryCode, setCountryCode] = useState(
    contactInfo?.countryCode || brand.preferredAreaCode || ""
  );
  const [phone, setPhone] = useState(contactInfo?.phoneNumber || "");
  const [email, setEmail] = useState(contactInfo?.email || "");
  const [emailError, setEmailError] = useState(
    contactInfo && contactInfo.email
      ? emailRegex.test(contactInfo.email)
        ? ""
        : t("contactInfoEmailError")
      : ""
  );
  const [phoneError, setPhoneError] = useState(
    contactInfo && contactInfo.phoneNumber
      ? phoneRegex.test(contactInfo.phoneNumber)
        ? ""
        : t("contactInfoPhoneError")
      : ""
  );

  const validateEmail = debounce(
    ({
      email,
      validateEmpty = false,
    }: {
      email: string;
      validateEmpty?: boolean;
    }) => {
      if (emailRegex.test(email) || (email === "" && validateEmpty === false)) {
        setEmailError("");
      } else {
        setEmailError(t("contactInfoEmailError"));
      }
    },
    200
  );

  const onEmailChanged = useCallback(
    (val: string) => {
      validateEmail({ email: val });
      setEmail(val);
      onContactInfoChange?.({
        countryCode,
        phoneNumber: phone,
        email: val,
      });
    },
    [countryCode, onContactInfoChange, phone, validateEmail]
  );

  const validatePhone = debounce(
    ({
      phone,
      validateEmpty = false,
    }: {
      phone: string;
      validateEmpty?: boolean;
    }) => {
      if (
        (checkPhoneMinLength && phone.length < minPhoneLength) ||
        phoneRegex.test(phone) ||
        (!phone && validateEmpty === false)
      ) {
        setPhoneError("");
      } else {
        setPhoneError(t("contactInfoPhoneError"));
      }
    },
    200
  );

  const onPhoneNumberChanged = useCallback(
    (
      val: string,
      countryDialCode: string,
      customIsValid?: (number: string) => PhoneNumberValidatorEnum
    ) => {
      if (customIsValid) {
        switch (customIsValid(val)) {
          case PhoneNumberValidatorEnum.CUSTOM_ERROR:
            setPhoneError(t("invalidPhoneNumberFormat"));
            break;
          case PhoneNumberValidatorEnum.VALID:
            setPhoneError("");
            break;
          case PhoneNumberValidatorEnum.DEFAULT_ERROR:
            setPhoneError(t("contactInfoPhoneError"));
            break;
          case PhoneNumberValidatorEnum.NOT_VALIDATED:
            validatePhone({ phone: val });
            break;
        }
      } else {
        validatePhone({ phone: val });
      }
      setPhone(val);
      setCountryCode(countryDialCode);
      onContactInfoChange?.({
        countryCode: countryDialCode,
        phoneNumber: val,
        email,
      });
    },
    [email, onContactInfoChange, t, validatePhone]
  );

  const onPhoneBlur = useCallback(
    (val: string) => {
      const cleaned = cleanPhoneNumber(val);
      if (phoneRegex.test(cleaned) || cleaned === "") {
        if (cleaned !== phone) {
          onPhoneNumberChanged(cleaned, countryCode);
        } else {
          setPhoneError("");
        }
      } else {
        setPhoneError(t("contactInfoPhoneError"));
      }
    },
    [countryCode, onPhoneNumberChanged, phone, phoneRegex, t]
  );

  useEffect(() => {
    const contactInfoEmail = contactInfo?.email || "";
    validateEmail({ email: contactInfoEmail });
    setEmail(contactInfoEmail);
  }, [contactInfo?.email, validateEmail]);

  useEffect(() => {
    const contactInfoCountryCode = contactInfo?.countryCode || "";
    const contactInfoPhone = contactInfo?.phoneNumber || "";
    validatePhone({ phone: contactInfoPhone });
    setPhone(contactInfoPhone);
    setCountryCode(contactInfoCountryCode);
  }, [contactInfo?.countryCode, contactInfo?.phoneNumber, validatePhone]);

  useEffect(() => {
    if (validate !== undefined && validate > 0) {
      validatePhone({
        phone: contactInfo?.phoneNumber || "",
        validateEmpty: true,
      });
      validateEmail({ email: contactInfo?.email || "", validateEmpty: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate]);

  return (
    <Box
      className={clsx("contact-info-form-container", className, {
        mobile: isMobile,
        disabled: disabled,
        "has-error": !!emailError || !!phoneError,
        "has-actions": !!actions,
        "display-only": !onContactInfoChange,
        hotel: inHotel,
      })}
    >
      <Slot
        id="checkout-contact-info-form"
        phone={phone}
        countryCode={countryCode}
        email={email}
        onPhoneNumberChanged={onPhoneNumberChanged}
        onEmailChanged={onEmailChanged}
        emailError={emailError}
        phoneError={phoneError}
        setCountryCode={setCountryCode}
        disabled={disabled}
        showEditAction={showEditAction}
        onEdit={onEdit}
        onConfirmationEmailFocus={onConfirmationEmailFocus}
        onPhoneBlur={onPhoneBlur}
        component={
          <>
            {header ? (
              <Grid
                container
                spacing={2}
                alignItems="flex-start"
                className="header-contact-info"
              >
                <Grid item className="contact-info-form-description">
                  {title ? (
                    <Typography
                      className="title"
                      variant={headerVariant ?? (isMobile ? "caption" : "h3")}
                    >
                      {title}
                    </Typography>
                  ) : null}
                  {subtitle ? (
                    <Typography variant="body2">{subtitle}</Typography>
                  ) : null}
                </Grid>
                <Grid item xs />
                {showEditAction && (
                  <Grid item>
                    <Button
                      className="edit-action"
                      disableRipple={disableRipple}
                      variant="text"
                      color="primary"
                      onClick={onEdit}
                    >
                      {t("edit")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            ) : null}
            <Typography
              className={clsx("contact-info-form-required")}
              variant="body2"
            >
              {`${t("requiredField")}*`}
            </Typography>
            <Box className={clsx("contact-info-form", className)}>
              {!hidePhoneNumber && (
                <Grid item>
                  <PhoneInputField
                    label={`${t("phoneNumber")} *`}
                    defaultValue={phone}
                    countryCode={countryCode}
                    errorHelper={phoneError}
                    onBlur={onPhoneBlur}
                    onChange={onPhoneNumberChanged}
                    disabled={disabled}
                  />
                </Grid>
              )}
              {!hideEmailAddress && (
                <Grid item>
                  <B2BTextField
                    id="contact-email-input-field"
                    className="email-input-field"
                    label={t("email")}
                    required
                    value={email}
                    errorHelper={emailError}
                    onFocus={(_value) =>
                      onConfirmationEmailFocus && onConfirmationEmailFocus()
                    }
                    onChange={onEmailChanged}
                    disabled={disabled}
                    type="email"
                  />
                </Grid>
              )}
              {actions && (
                <Grid item>
                  <ActionLinks
                    className="action-links-contact-info"
                    actions={actions}
                  />
                </Grid>
              )}
            </Box>
          </>
        }
      />
    </Box>
  );
};
