import dayjs from "dayjs";

/**
 * Convert minutes of a day to time string in format: 12:30PM. or given format.
 */
export const dayMinutesToTime = (minutes: number, format?: string) => {
  const hours = Math.floor(minutes / 60) % 24;
  const mins = minutes % 60;
  const time = dayjs().startOf("day").add(hours, "hours").add(mins, "minutes");
  return time.format("h:mm A");
};
