import {
  DisruptionRefundFulfillRequest,
  DisruptionRefundFulfillResponseEnum,
  DisruptionRefundFulfillPollResponseEnum,
} from "@hopper-b2b/types";
import { axiosInstance } from "@hopper-b2b/api";
import {
  disruptionRefundFulfillPath,
  disruptionRefundPollPath,
} from "../paths";

const disruptionRefundPoll = async (
  req: { value: string },
  index = 0
): Promise<any> => {
  const delayTimes = [0, 2000, 4000, 6000, 8000, 10000, 20000, 30000];
  await new Promise((resolve) =>
    setTimeout(resolve, delayTimes[Math.min(index, 7)])
  );
  const response = await axiosInstance.post(disruptionRefundPollPath, req);
  // eslint-disable-next-line no-useless-catch
  try {
    if (
      response.data.DisruptionRefundFulfillPollResponse ===
        DisruptionRefundFulfillPollResponseEnum.RefundFulfillPollPending ||
      response.data.DisruptionRefundFulfillPollResponse ===
        DisruptionRefundFulfillPollResponseEnum.Pending
    ) {
      return disruptionRefundPoll(req, index++);
    } else if (
      response.data.DisruptionRefundFulfillPollResponse ===
        DisruptionRefundFulfillPollResponseEnum.RefundFulfillPollSuccess ||
      response.data.DisruptionRefundFulfillPollResponse ===
        DisruptionRefundFulfillPollResponseEnum.Success
    ) {
      return response;
    } else {
      throw new Error(
        "disruptionRefundPoll: " +
          response.data.DisruptionRefundFulfillPollResponse
      );
    }
  } catch (e) {
    throw e;
  }
};

// TODO: Update types from any
const disruptionRefundFulfill = async (
  req: DisruptionRefundFulfillRequest
): Promise<any> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const res = await axiosInstance.post(disruptionRefundFulfillPath, req);
    const { data } = res;
    if (
      data.DisruptionRefundFulfillResponse ===
        DisruptionRefundFulfillResponseEnum.RefundFulfillSuccess ||
      data.DisruptionRefundFulfillResponse ===
        DisruptionRefundFulfillResponseEnum.Success
    ) {
      const pollReq = { value: data.token.value };
      return disruptionRefundPoll(pollReq);
    } else {
      throw new Error(
        `disruptionRefundFulfill: ${data.DisruptionRefundFulfillResponse} ${data.message}`
      );
    }
  } catch (e) {
    // TODO: Add error handling
    throw e;
  }
};

export default disruptionRefundFulfill;
