import { RouteComponentProps } from "react-router";
import { Box } from "@material-ui/core";
import clsx from "clsx";

import { NoResults, ActionButton, Slot } from "@hopper-b2b/ui";
import { MyTripsFilter } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";

import { PATH_FLIGHTS } from "../../../../utils/paths";
import { NoTripResultsConnectorProps } from "./container";
import { useCallback, useContext } from "react";
import { ClientContext } from "../../../../App";
import styles from "./styles.module.scss";

interface INoTripResultsProps
  extends NoTripResultsConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
}

export const NoTripResults = ({
  hasTripsToDisplay,
  hasError,
  tripsFilter,
  history,
  isMobile,
}: INoTripResultsProps) => {
  const { t } = useI18nContext();
  const clientAssets = useContext(ClientContext);

  const showNoResults = hasError || !hasTripsToDisplay;

  const getNoResultsMessage = (tripsFilter: MyTripsFilter) => {
    switch (tripsFilter) {
      case MyTripsFilter.UPCOMING_TRIPS:
        return t("noTrips");
      case MyTripsFilter.PAST_TRIPS:
        return t("noBookings");
      case MyTripsFilter.PRIZE_FREEZES:
        return t("noFrozenPrices");
    }
    return;
  };

  const reload = useCallback(() => window.location.reload(), []);

  const redirectToFlights = useCallback(() => {
    history.push(PATH_FLIGHTS);
  }, []);

  if (!showNoResults) {
    return null;
  }

  return (
    <Box
      className={clsx(
        {
          mobile: isMobile,
          hidden: !(hasError || (!hasTripsToDisplay && !hasError)),
        },
        styles.noTripsResults,
        "noTripsResults"
      )}
    >
      {hasError && (
        <>
          <NoResults
            className="no-trips-container error"
            title={t("tripsListErrorTitle")}
            subtitle={t("tripsListErrorSubtitle")}
            iconSrc={clientAssets.assets.error}
          />
          <Box className="no-trips-buttons">
            <ActionButton
              className="retry"
              message={t("retry")}
              defaultStyle="h4r-primary"
              onClick={reload}
            />
          </Box>
        </>
      )}
      {!hasTripsToDisplay && !hasError && (
        <>
          <Slot
            className="no-trips-container"
            id="empty-trips-list"
            tripsFilter={tripsFilter}
            component={
              <NoResults
                className="no-trips-container"
                title={t("noTripsFound")}
                subtitle={getNoResultsMessage(tripsFilter)}
                iconSrc={clientAssets?.assets?.error}
              />
            }
          />
          {tripsFilter === MyTripsFilter.UPCOMING_TRIPS ||
            (tripsFilter === MyTripsFilter.PAST_TRIPS && (
              <Box className="no-trips-buttons">
                <ActionButton
                  className="book-a-trip"
                  message={t("bookAFlight")}
                  defaultStyle="h4r-secondary"
                  onClick={redirectToFlights}
                />
              </Box>
            ))}
        </>
      )}
    </Box>
  );
};
