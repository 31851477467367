import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import {
  setOpenModal,
  setSelectedFlight,
  setSelectedHotel,
} from "../../../../../TripsList/actions/actions";
import { ItinerariesModal } from "./component";
import {
  getOpenModal,
  getSelectedHotel,
} from "../../../../../TripsList/reducer";

const mapStateToProps = (state: IStoreState) => ({
  openModal: getOpenModal(state),
  selectedHotel: getSelectedHotel(state),
});

const mapDispatchToProps = {
  setOpenModal,
  setSelectedHotel,
  setSelectedFlight,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ItinerariesModalConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedItinerariesModal = connector(ItinerariesModal);
