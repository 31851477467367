import { PriceDropLineAndModal } from "../../../../components/PriceDropLineAndModal";
import { ApacActionButton } from "@commbank/ui";
import { PriceDropProtectionEnum } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { FarePricing } from "@hopper-b2b/types";
import {
  useDeviceTypes,
  useEnableCfar,
  useEnableDisruptionProtection,
} from "@hopper-b2b/utilities";
import { Box, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getPointsPriceFormatted } from "../../../../../../utils/getRewardsText";
import { CfarOffers } from "../../../cfar/CfarOffers";
import {
  getSelectedCfarOffer,
  isCfarOffersValid,
  getCfarOffers,
} from "../../../cfar/reducer";
import { AirDisruptionOffer } from "../../../disruption/components/AirDisruptionOffer";
import {
  getAirDisruptionOffers,
  getSelectedAirDisruptionOffer,
} from "../../../disruption/reducer";
import { getSelectedAccount } from "../../../rewards/reducer";
import { predictionSelector, selectedFarePricingSelector } from "../../reducer";
import styles from "./FintechCustomizePage.module.scss";
import { TravelInsuranceInfoSection } from "@commbank/ui";

export interface IFintechCustomizePage {
  isOneWay: boolean;
  goToNextStep: () => void;
}

/**
 * Desktop only
 */
export const FintechCustomizePage = ({ goToNextStep, isOneWay }) => {
  const { t, formatFiatCurrency } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const showFlightCfar = useEnableCfar();
  const showAirDisruption = useEnableDisruptionProtection();
  const isCfarValid = useSelector(isCfarOffersValid(true));
  const disruptionOffers = useSelector(getAirDisruptionOffers);
  const selectedRewardsAccount = useSelector(getSelectedAccount);
  const prediction = useSelector(predictionSelector);
  const selectedFarePricing = useSelector(selectedFarePricingSelector);
  const selectedCfarOffer = useSelector(getSelectedCfarOffer);
  const cfarOffers = useSelector(getCfarOffers);
  const selectedAirDisruptionOffer = useSelector(getSelectedAirDisruptionOffer);

  const cfarSelectionRequired = !!isCfarValid;
  const isAirDisruptionVisible =
    showAirDisruption && !!disruptionOffers?.length;

  const disableContinueButton =
    (selectedCfarOffer === undefined && cfarSelectionRequired) ||
    (selectedAirDisruptionOffer === undefined && isAirDisruptionVisible);

  const isCfarVisible = showFlightCfar && isCfarValid;

  const eligiblePriceDrop =
    prediction?.priceDropProtection?.PriceDropProtection ===
    PriceDropProtectionEnum.IsEligible;

  const pricingWithFintech = useMemo(() => {
    const totalPrice =
      selectedFarePricing.total.fiat.value +
      (selectedAirDisruptionOffer?.quotes?.[0]?.premiumAmount?.fiat.value ||
        0) +
      (selectedCfarOffer?.premiumAmount?.fiat.value || 0);

    return {
      ...selectedFarePricing,
      total: {
        fiat: {
          ...selectedFarePricing.total.fiat,
          value: totalPrice,
        },
      },
    } as FarePricing;
  }, [selectedFarePricing, selectedCfarOffer, selectedAirDisruptionOffer]);

  const rewardsPriceString = useMemo(
    () =>
      getPointsPriceFormatted({
        pricing: pricingWithFintech,
        selectedAccount: selectedRewardsAccount,
      }),
    [pricingWithFintech, selectedRewardsAccount]
  );

  return (
    <Box
      className={clsx(styles.fintechCustomizePage, { mobile: matchesMobile })}
    >
      <Grid container spacing={4}>
        <Grid item lg={8} md={12}>
          <TravelInsuranceInfoSection />
          {isAirDisruptionVisible && (
            <Box className={styles.productCard}>
              <AirDisruptionOffer hideContinueButton={!matchesMobile} />
            </Box>
          )}
          {isCfarVisible && cfarOffers?.length && (
            <Box className={styles.productCard}>
              <CfarOffers
                hideContinueButton={!matchesMobile}
                cfarCoverage={cfarOffers[0].coverageAmount}
              />
            </Box>
          )}
        </Grid>
        <Grid item lg={4} md={12}>
          <Box className={styles.checkoutContainer}>
            <Box className={styles["content-section"]}>
              <Typography variant="h6">{t("priceBreakdown")}</Typography>
              <div className={styles.divider} />

              <div className={styles.breakdownContainer}>
                <div className={styles.priceItem}>
                  <Typography variant="body2">
                    {t("flightShop.baseFare")}
                  </Typography>
                  <Typography variant="body2">
                    {formatFiatCurrency(pricingWithFintech.baseAmount?.fiat)}
                  </Typography>
                </div>
                <div className={styles.priceItem}>
                  <Typography variant="body2">
                    {t("flightShop.taxesAndFees")}
                  </Typography>
                  <Typography variant="body2">
                    {formatFiatCurrency(pricingWithFintech.taxAmount?.fiat)}
                  </Typography>
                </div>
                {!!selectedAirDisruptionOffer && (
                  <div className={styles.priceItem}>
                    <Typography variant="body2">
                      {t("flightsDisruptionGuaranteeHeader")}
                    </Typography>
                    <Typography variant="body2">
                      {formatFiatCurrency(
                        selectedAirDisruptionOffer.quotes?.[0]?.premiumAmount
                          ?.fiat
                      )}
                    </Typography>
                  </div>
                )}
                {!!selectedCfarOffer && (
                  <div className={styles.priceItem}>
                    <Typography variant="body2">
                      {t("cfarOffers.priceBreakdownLineItem")}
                    </Typography>
                    <Typography variant="body2">
                      {formatFiatCurrency(
                        selectedCfarOffer.premiumAmount?.fiat
                      )}
                    </Typography>
                  </div>
                )}
              </div>
              <div className={styles.divider} />

              <Box className={styles["total-price-container"]}>
                <div className={styles["price-wrapper"]}>
                  <Typography variant="body1" className={styles["total-price"]}>
                    {formatFiatCurrency(pricingWithFintech.total?.fiat)}
                  </Typography>
                  {rewardsPriceString && (
                    <Typography variant="body2">
                      {t("flightShop.orAwardPoints", {
                        points: rewardsPriceString,
                      })}
                    </Typography>
                  )}
                </div>
                <Typography variant="body2">
                  {isOneWay
                    ? `(${t("oneWayPerTraveler")})`
                    : `(${t("roundTripPerTraveler")})`}
                </Typography>
                <Typography
                  variant="body2"
                  className={styles["tax-description"]}
                >
                  {t("lodgingShopDetails.includingFees")}
                </Typography>
              </Box>
              <PriceDropLineAndModal
                isPDPEligible={eligiblePriceDrop}
                prediction={prediction}
              />
            </Box>
            <Box className={styles["button-section"]}>
              <ApacActionButton
                className={styles["continue-button"]}
                disabled={disableContinueButton}
                onClick={() => {
                  goToNextStep();
                }}
                message={t("flightShopReview.continueToCheckout")}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
