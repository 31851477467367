import {
  FirstDayOfWeek,
  FocusedInput,
  MonthType,
  OnDatesChangeProps,
  useDatepicker,
} from "@datepicker-react/hooks";
import { getLang } from "@hopper-b2b/i18n";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { firstWeekDay } from "../../component";
import { Month } from "../Month";
import "./styles.scss";

export interface IGroupViewProps {
  minAllowedDate: Date | null;
  maxAllowedDate: Date | null;
  setFocusedMonthIndex?: (index: number) => void;
  numberOfMonths: number;
  priceRangeTags?: JSX.Element;
  focusedInput: FocusedInput;
}

/* eslint-disable */
// temporarily disable linting here to prevent errors due to the placeholder function
export const GroupView = ({
  minAllowedDate,
  maxAllowedDate,
  setFocusedMonthIndex,
  numberOfMonths,
  priceRangeTags,
  focusedInput,
}: IGroupViewProps) => {
  // @ts-ignore: a placeholder handleDateChange is needed for using useDatepicker
  const handleDateChange = (props: OnDatesChangeProps) => {};
  /* eslint-enable */

  const { firstDayOfWeek, activeMonths } = useDatepicker({
    firstDayOfWeek: firstWeekDay(getLang()) as FirstDayOfWeek, // 0 is Sunday.
    startDate: minAllowedDate,
    endDate: maxAllowedDate,
    focusedInput,
    onDatesChange: handleDateChange,
    numberOfMonths,
  });

  return (
    <Box className={clsx("group-view-date-range-picker")}>
      <Box className="price-range-tags-section">{priceRangeTags}</Box>
      <Box className="months-container">
        {renderMonths(activeMonths, firstDayOfWeek, setFocusedMonthIndex)}
      </Box>
    </Box>
  );
};

const renderMonths = (
  months: MonthType[],
  firstDayOfWeek: FirstDayOfWeek,
  setFocusedMonthIndex?: (index: number) => void
) =>
  months.map((month: MonthType, index: number) => (
    <Month
      key={`${month.year}-${month.month}`}
      year={month.year}
      month={month.month}
      firstDayOfWeek={firstDayOfWeek}
      date={month.date}
      hideWeekdayLabels={true}
      rootClassName="group-view-month-root"
      headerClassName="group-view-month-label"
      bodyClassName="group-view-month-body"
      monthFormat="MMM"
      isMiniCalendar={true}
      onClick={() => setFocusedMonthIndex && setFocusedMonthIndex(index)}
    />
  ));
