export const HEADING = "Cancel Flight";

// Sabre bookings can be cancelled by our agents, and travelfusion bookings cannot
export const SUBHEADING_SABRE =
  "To cancel this flight, please contact our customer support team and someone will assist you.";
export const SUBHEADING_TRAVELFUSION =
  "This flight is managed by the airline. To cancel this flight, please visit the airlines website to manage your booking.";

// TODO: Add PRIMARY_ACTION_SABRE_URL
export const PRIMARY_ACTION_SABRE = "Contact Support";
export const PRIMARY_ACTION_SABRE_URL = "/PRIMARY_ACTION_SABRE_URL";
export const PRIMARY_ACTION_TRAVELFUSION = "Continue to the airlines website";
export const CANCEL = "Cancel";

export const mockItineraryData = {
  outboundFlights: [
    {
      departureDateTime: "2020-08-08T23:20:00",
      arrivalDateTime: "2020-08-09T12:40:00",
      origin: "Newark",
      originAirportCode: "EWR",
      destination: "Paris",
      destinationAirportCode: "ORY",
      airlineCode: "IB",
      airlineName: "Iberia Airlines",
      flightNumber: "IB2690",
      flightDuration: "4h",
      layoverDuration: "2h 30m",
    },
  ],
  returnFlights: [
    {
      departureDateTime: "2020-08-16T12:10:00",
      arrivalDateTime: "2020-08-16T20:40:00",
      origin: "Paris",
      originAirportCode: "ORY",
      destination: "Madrid",
      destinationAirportCode: "MAD",
      airlineCode: "IB",
      airlineName: "Iberia Airlines",
      flightNumber: "IB433",
      flightDuration: "10h",
      layoverDuration: "2h 30m",
    },
  ],
};
