import { ReactNode } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap, IconComponent, IconName } from "../../../index";
import "./styles.scss";

export interface PriceSummaryRowProps {
  chevronIcon?: ReactNode;
  priceString: string;
  description?: ReactNode;
  priceFreezeText?: string;
  iconSrc?: string;
  onClick?: () => void;
  roundTrip?: boolean;
  customExpandIcon?: JSX.Element;
  cashBackElement?: JSX.Element;
}

const SummaryRow = ({
  chevronIcon,
  customExpandIcon,
  description,
  iconSrc,
  onClick,
  priceFreezeText,
  priceString,
  roundTrip,
  cashBackElement,
}: PriceSummaryRowProps) => {
  const { t } = useI18nContext();
  return (
    <div className="price-summary-row">
      <div className="price-summary-row-left">
        <div className="price-summary-row-icon">
          {iconSrc ? (
            <img className="mobile-left-icon" src={iconSrc} />
          ) : (
            <IconComponent className="mobile-left-icon" name={IconName.Card} />
          )}
        </div>
        <div className="price-summary-row-value">
          <div className="price-text">
            <Typography className="content" variant="body1">
              {priceString}
            </Typography>
            <Typography className="trip-type" variant="body1">
              {roundTrip ? t("roundTripPriceSummary") : t("oneWayPriceSummary")}
            </Typography>
          </div>
          {description ? (
            <div className="description-text">{description}</div>
          ) : null}
          {cashBackElement ? cashBackElement : null}
          {priceFreezeText ? (
            <div className="price-freeze-text">{priceFreezeText}</div>
          ) : null}
        </div>
      </div>
      <div className="price-summary-row-right">
        {onClick
          ? customExpandIcon
            ? customExpandIcon
            : chevronIcon ?? (
                <FontAwesomeIcon
                  className="mobile-right-chevron"
                  icon={faChevronRight as IconProp}
                />
              )
          : null}
      </div>
    </div>
  );
};
export const PriceSummaryRow = (props: PriceSummaryRowProps) => {
  return props.onClick ? (
    <ButtonWrap className="price-summary-row-button" onClick={props.onClick}>
      <SummaryRow {...props} />
    </ButtonWrap>
  ) : (
    <SummaryRow {...props} />
  );
};

export default PriceSummaryRow;
