import {
  LodgingSelectionEnum,
  Lodgings,
  Suggestion,
} from "@b2bportal/lodging-api";
import { Autocomplete, AutocompleteProps } from "../Autocomplete";
import { fetchLocations } from "./fetchLocations";
import { ApacIconName } from "@commbank/ui";

const placeToIcon = {
  country: ApacIconName.WorldMap,
  geocode: ApacIconName.Map,
  lodging: ApacIconName.House,
  tourist_attraction: ApacIconName.Camera,
  restaurant: ApacIconName.Restaurant,
  premise: ApacIconName.Store,
};

export interface HotelLocationAutoCompleteProps
  extends Omit<
    AutocompleteProps<Suggestion>,
    "fetch" | "groupBy" | "getOptionLabel" | "sortOptions"
  > {
  defaultValue?: Suggestion;
  error?: string;
}

export const HotelLocationAutoComplete = ({
  className,
  id,
  defaultValue,
  label,
  onChange,
  onOpen,
  alwaysOpen,
  disabled,
  error,
}: HotelLocationAutoCompleteProps) => {
  const defaultInputValue = defaultValue ? getOptionLabel(defaultValue) : "";

  return (
    <Autocomplete
      className={className}
      id={id}
      defaultValue={defaultValue}
      defaultInputValue={defaultInputValue}
      label={label}
      onChange={onChange}
      fetch={fetchLocations}
      sortOptions={sortLocations}
      groupBy={groupLocations}
      getOptionLabel={getOptionLabel}
      getOptionLabelIcon={getOptionLabelIcon}
      onOpen={onOpen}
      alwaysOpen={alwaysOpen}
      disabled={disabled}
      error={error}
    />
  );
};

const groupLocations = (value?: Suggestion): LodgingSelectionEnum =>
  (value?.id as Lodgings)?.lodgingSelection?.LodgingSelection;

const sortLocations = (options?: Suggestion[]): Suggestion[] => {
  const groupEntries = {
    [LodgingSelectionEnum.Place]: [],
    [LodgingSelectionEnum.Market]: [],
    [LodgingSelectionEnum.Location]: [],
  };

  options?.forEach((suggestion: Suggestion) => {
    const group: LodgingSelectionEnum = groupLocations(suggestion);
    groupEntries[group].push(suggestion);
  });

  return [
    ...groupEntries[LodgingSelectionEnum.Place],
    ...groupEntries[LodgingSelectionEnum.Market],
    ...groupEntries[LodgingSelectionEnum.Location],
  ];
};

const getOptionLabel = (value?: Suggestion) => {
  return value?.label;
};

const getOptionLabelIcon = (value?: Suggestion): ApacIconName => {
  const { placeTypes = [] } =
    value?.id.Id === "Lodgings" &&
    value.id.lodgingSelection.LodgingSelection === LodgingSelectionEnum.Place
      ? value.id.lodgingSelection
      : {};

  for (const [placeKey, iconName] of Object.entries(placeToIcon)) {
    if (placeTypes.includes(placeKey)) {
      return iconName;
    }
  }

  return ApacIconName.Map;
};
