import { ApacIconComponent, ApacIconName } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, IconButton, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import "./HotelShopHeader.scss";

interface HotelShopHeaderProps {
  onBack: () => void;
  onEdit: () => void;
  searchState: {
    place: {
      label: string;
    };
    fromDate: string;
    untilDate: string;
    guests: {
      adults: number;
      children: number[];
    };
    rooms: number;
  };
}

export const HotelShopHeader = ({
  onBack,
  onEdit,
  searchState,
}: HotelShopHeaderProps): JSX.Element => {
  const { place, fromDate, untilDate, guests } = searchState;
  const totalGuests = (guests?.adults || 0) + (guests?.children?.length || 0);
  const { t } = useI18nContext();

  const formatDate = (date: string) => {
    return dayjs(date).format("ddd DD MMM");
  };

  return (
    <Box className="search-metadata-container">
      <Box className="search-back-button">
        <IconButton onClick={onBack}>
          <ApacIconComponent name={ApacIconName.ChevronLeft} />
        </IconButton>
      </Box>
      <Box className="search-details">
        <Box className="search-title">
          <Typography>{place?.label}</Typography>
        </Box>
        <Box className="search-metadata">
          <Typography>
            {t("commBank.hotelAvailability.header.dateDescription", {
              fromDate: formatDate(fromDate),
              untilDate: formatDate(untilDate),
            })}
          </Typography>
          <Typography>・</Typography>
          <Typography>
            {totalGuests === 1
              ? t("passengerInformation.guestCount_one")
              : t("passengerInformation.guestCount_other", {
                  count: totalGuests,
                })}
          </Typography>
        </Box>
      </Box>
      <Box className="search-edit-button">
        <IconButton onClick={onEdit}>
          <ApacIconComponent name={ApacIconName.EditPen} />
        </IconButton>
      </Box>
    </Box>
  );
};
