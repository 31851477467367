import { useMemo } from "react";
import { useFeatureFlag } from "@apac/feature-flags";
import { FeatureFlagsContextProps } from "@hopper-b2b/utilities";
import { FEATURE_FLAGS } from "../Root";

function useFeatureFlagsMap(): FeatureFlagsContextProps {
  const enablePriceWatch = useFeatureFlag(FEATURE_FLAGS.AIR_PRICE_WATCH);
  const enablePriceDrop = useFeatureFlag(FEATURE_FLAGS.AIR_PRICE_DROP);
  const enableCfar = useFeatureFlag(FEATURE_FLAGS.AIR_CFAR);
  const enableDisruptionProtection = useFeatureFlag(
    FEATURE_FLAGS.AIR_DISRUPTION
  );
  const enableAirShopV4 = true; //Default should use v4
  const enableHFv2 = useFeatureFlag(FEATURE_FLAGS.HFv2);

  const enableAirExchange = useFeatureFlag(FEATURE_FLAGS.AIR_EXCHANGE);

  return useMemo<FeatureFlagsContextProps>(
    () => ({
      enableAirExchange,
      enableCfar,
      enableHFv2,
      enablePriceWatch,
      enableDisruptionProtection,
      enableAirShopV4,
      enablePriceDrop,
    }),
    [
      enableAirExchange,
      enableCfar,
      enableHFv2,
      enablePriceWatch,
      enableDisruptionProtection,
      enableAirShopV4,
      enablePriceDrop,
    ]
  );
}

export default useFeatureFlagsMap;
