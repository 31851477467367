import { ApacIcon, ApacIconName, DateSelectButton } from "../..";
import { useI18nContext } from "@hopper-b2b/i18n";
import { IMonthBucket } from "@hopper-b2b/types";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { DateRangePicker, PickerType } from "../DateRangePicker";
import "./MonthAndDatePicker.styles.scss";
import { useState } from "react";
import { END_DATE, FocusedInput, START_DATE } from "@datepicker-react/hooks";

export enum MonthAndDatePickerType {
  Horizontal,
  Column,
  Group,
}

export interface IMonthAndDatePickerProps {
  viewType: MonthAndDatePickerType;
  header?: JSX.Element | string;
  // focusedMonthIndex must be given in order to make columnView focus on an arbitrary month
  focusedMonthIndex?: number;
  // setFocusedMonthIndex must be given for working with the groupView + columnView combination
  setFocusedMonthIndex?: (index: number) => void;
  setStartDate?: (date: Date | null) => void;
  setEndDate?: (date: Date | null) => void;
  startDate: Date | null;
  endDate: Date | null;
  className?: string;
  isOneWay?: boolean;
  returningError?: string;
  departingError?: string;
  showBucketLegend?: boolean;
  pickerType: PickerType;
  priceTags?: string[];
  months?: IMonthBucket[] | null;
  isHotel?: boolean;
  defaultFocusedInput?: FocusedInput;
}

export const priceBuckets = (priceTags: string[]) => {
  return !priceTags.length ? undefined : (
    <div className="price-tags-container">
      <div className="tag-wrapper">
        <Typography variant="body1" className="price">
          {priceTags[0]}
        </Typography>
        <Typography variant="body1" className="label label1">
          $
        </Typography>
      </div>
      <div className="tag-wrapper">
        <Typography variant="body1" className="price">
          {priceTags[1]}
        </Typography>
        <Typography variant="body1" className="label label2">
          $$
        </Typography>
      </div>
      <div className="tag-wrapper">
        <Typography variant="body1" className="price">
          {priceTags[2]}
        </Typography>
        <Typography variant="body1" className="label label3">
          $$$
        </Typography>
      </div>
      <div className="tag-wrapper">
        <Typography variant="body1" className="price">
          {priceTags[3]}
        </Typography>
        <Typography variant="body1" className="label label4">
          $$$$
        </Typography>
      </div>
    </div>
  );
};

export const MonthAndDatePicker = (props: IMonthAndDatePickerProps) => {
  const { t } = useI18nContext();
  const {
    pickerType,
    startDate,
    endDate,
    months,
    priceTags,
    setStartDate,
    setEndDate,
    viewType,
    header = true,
    focusedMonthIndex,
    setFocusedMonthIndex,
    className,
    isOneWay,
    returningError,
    departingError,
    showBucketLegend,
    isHotel,
    defaultFocusedInput = START_DATE,
  } = props;

  const { matchesMobile: isMobile } = useDeviceTypes();

  // EXPERIMENT FOR DATE INPUT
  // const handleDateChange = (startDate: Date, endDate: Date) => {
  //   const today = dayjs().startOf("day").toDate();
  //   const yearFromToday = dayjs().add(1, "year").startOf("day").toDate();

  //   // Do not update if it is invalid state
  //   if (startDate && (startDate < today || startDate > yearFromToday)) {
  //     return;
  //   }

  //   if (endDate && (endDate < today || endDate > yearFromToday)) {
  //     return;
  //   }

  //   if (isOneWay) {
  //     setStartDate(startDate);
  //   } else {
  //     if (!startDate || !endDate || startDate < endDate) {
  //       setStartDate(startDate);
  //       setEndDate(endDate);
  //     } else if (startDate > endDate) {
  //       setStartDate(endDate);
  //       setEndDate(startDate);
  //     }
  //   }
  // };

  const [focusedInput, setFocusedInput] =
    useState<FocusedInput>(defaultFocusedInput);

  return (
    <div className={clsx("month-and-date-picker-root", { mobile: isMobile })}>
      {header && !isMobile && (
        <Box className={clsx("calendar-header-container", "desktop")}>
          <div className="title-container">
            <ApacIcon name={ApacIconName.CBACalendar} />
            <div className="title-wrapper">
              <Typography className="title">
                {t("commBank.search.flights.travelDate")}
              </Typography>
              <Typography className="subtitle">
                {t("commBank.search.flights.whenToTravel")}
              </Typography>
            </div>
          </div>

          {/* TODO: decide if we want to show the legend */}
          {showBucketLegend && priceBuckets(priceTags)}
        </Box>
      )}
      {header && isMobile && (
        <Box
          className={clsx("calendar-header-container", {
            mobile: isMobile,
          })}
        >
          <div className="date-button-section">
            {/* <DateInput
              className="date-input"
              type="date"
              value={startDate}
              label={
                isHotel ? t("checkin") : t("commBank.search.flights.departing")
              }
              errorString={departingError}
              onChange={(e) => {
                const date = dayjs(e.target.value);

                if (date.isValid()) {
                  handleDateChange(date.toDate(), endDate);
                }
              }}
            />
            {!isOneWay && (
              <DateInput
                label={
                  isHotel
                    ? t("checkout")
                    : t("commBank.search.flights.returning")
                }
                errorString={returningError}
                value={endDate}
                onChange={(e) => {
                  const date = dayjs(e.target.value);

                  if (date.isValid()) {
                    handleDateChange(startDate, date.toDate());
                  }
                }}
              />
            )} */}

            <DateSelectButton
              className={clsx({
                focused: focusedInput === START_DATE,
              })}
              date={startDate}
              label={
                isHotel ? t("checkin") : t("commBank.search.flights.departing")
              }
              error={departingError}
              onClick={() => setFocusedInput("startDate")}
            />
            {!isOneWay && (
              <DateSelectButton
                className={clsx({
                  focused: focusedInput === END_DATE,
                })}
                date={endDate}
                label={
                  isHotel
                    ? t("checkout")
                    : t("commBank.search.flights.returning")
                }
                error={returningError}
                onClick={() => setFocusedInput("endDate")}
              />
            )}
          </div>

          {showBucketLegend && priceBuckets(priceTags)}
        </Box>
      )}
      <DateRangePicker
        className={clsx("apac-calendar-date-picker", className, {
          "range-selected": !!startDate && !!endDate,
          "show-bucket-legend": showBucketLegend,
        })}
        minAllowedDate={dayjs().toDate()}
        maxAllowedDate={dayjs().add(1, "year").toDate()}
        months={months}
        priceTags={priceTags}
        focusedInput={focusedInput}
        setFocusedInput={setFocusedInput}
        startDate={startDate}
        endDate={endDate}
        {...(viewType === MonthAndDatePickerType.Horizontal ||
        viewType === MonthAndDatePickerType.Column
          ? { setStartDate, setEndDate, pickerType }
          : undefined)}
        {...(viewType === MonthAndDatePickerType.Column
          ? { focusedMonthIndex, columnView: true }
          : undefined)}
        {...(viewType === MonthAndDatePickerType.Group
          ? { setFocusedMonthIndex, groupView: true }
          : undefined)}
        preventSameDateSelection={isHotel}
      />
    </div>
  );
};
