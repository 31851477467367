import { ShopFilter } from "@b2bportal/air-shopping-api";
import { createWatch, trackEvent } from "@hopper-b2b/api";
import {
  ADDED_WATCH,
  CallState,
  CreateWatchAlertRequest,
  Dealness,
  IShopParams,
  MessageMethodEnum,
  Prediction,
  SliceStopCountFilter,
} from "@hopper-b2b/types";
import dayjs from "dayjs";
import { call, put, select } from "redux-saga/effects";
import { fetchFlights } from "../../../api/v4/shop/fetchFlights";
import Logger from "../../../helpers/Logger";
import { IStoreState, flightApiConfigStoreKey } from "../../../reducers/types";
import { passengerCountSelector } from "../../search/reducer";
import { actions } from "../actions";
import { alertKeySelector, predictionSelector } from "../reducer/selectors";
import { setUpFlightShopParams } from "./fetchFlightsV4Saga";

export function* createWatchSaga(action: actions.ICreateWatch) {
  const { email, options } = action;
  try {
    const request: CreateWatchAlertRequest = {
      key: yield select(alertKeySelector),
      method: {
        email,
        MessageMethod: MessageMethodEnum.Email,
      },
      passengers: yield select(passengerCountSelector),
    };

    if (options) {
      request.key.value.filter = getFilter(options);
    }

    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    if (options?.noBasicFare || options?.nonstop) {
      yield fetchFlightsWithWatchOption(
        action.options,
        apiConfig,
        request.key.value.filter
      );
    }
    yield createWatch(request, apiConfig);
    yield put(actions.listWatches());
    yield put(actions.setCreateWatchCallState(CallState.Success));
    const prediction: Prediction | null = yield select(predictionSelector);
    trackEvent(
      {
        eventName: ADDED_WATCH,
        properties: {
          recommendation: prediction?.dealness
            ? prediction.dealness === Dealness.Wait
              ? "wait"
              : "buy"
            : null,
        },
      },
      apiConfig
    );
  } catch (e) {
    yield put(actions.setCreateWatchCallState(CallState.Failed));
    Logger.debug(e);
  }
}

function* fetchFlightsWithWatchOption(options, apiConfig, filter) {
  const {
    departureDate,
    returnDate,
    origin,
    destination,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    stopsOption,
  } = yield call(setUpFlightShopParams, options.history);
  const requestBody: IShopParams = {
    origin: { ...origin.id.code },
    destination: { ...destination.id.code },
    ...(returnDate && {
      returnDate: dayjs(returnDate).format("YYYY-MM-DD"),
    }),
    departureDate: dayjs(departureDate).format("YYYY-MM-DD"),
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    stopsOption: options?.nonstop ? SliceStopCountFilter.NONE : stopsOption,
    fareclassOptionFilter: null,
  };

  yield fetchFlights(requestBody, options?.isMobile, apiConfig, filter);
}

const getFilter = ({
  nonstop,
  noBasicFare,
}: {
  nonstop: boolean;
  noBasicFare: boolean;
}) => {
  if (nonstop && noBasicFare) {
    return ShopFilter.NonStopNoLCC;
  } else if (nonstop) {
    return ShopFilter.NonStop;
  } else if (noBasicFare) {
    return ShopFilter.NoLCC;
  } else {
    return ShopFilter.NoFilter;
  }
};
