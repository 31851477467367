import axios from "axios";

import { IApiConfig, IPerson, NETWORK_CALL_FAILED } from "@hopper-b2b/types";

import { passengersApiPrefix } from "../../paths";
import { trackEvent } from "@hopper-b2b/api";
const END_POINT = `${passengersApiPrefix}/list`;

export const fetchUserPassengers = (
  body: IListUserPassengersRequest,
  apiConfig?: IApiConfig
): Promise<IListUserPassengersResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(END_POINT, body);

      const responseBody: IPerson[] = res.data.value;
      resolve(responseBody);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IListUserPassengersRequest {}

export type IListUserPassengersResponse = Array<IPerson>;
