import { connect, ConnectedProps } from "react-redux";
import { TripCategory } from "@hopper-b2b/types";
import { PriceBreakdown } from "./PriceBreakdown";
import { IStoreState } from "../../../../reducers/types";
import {
  predictionSelector,
  tripDetailsSelector,
  selectedTripSelector,
} from "../../reducer/selectors";
import {
  getTripCategory,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
} from "../../../search/reducer";
import { getSelectedAccount } from "../../../rewards/reducer";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);
  const tripCategory = getTripCategory(state);
  const childrenCount = getChildrenCount(state);
  const infantsInSeatCount = getInfantsInSeatCount(state);
  const infantsOnLapCount = getInfantsOnLapCount(state);
  const selectedFareId = selectedTrip.returnFareId
    ? selectedTrip.returnFareId
    : selectedTrip.outgoingFareId;

  return {
    fareDetails: tripDetails?.fareDetails.find((f) => f.id === selectedFareId),
    prediction: predictionSelector(state),
    selectedRewardsAccount: getSelectedAccount(state),
    isOneWay: tripCategory === TripCategory.ONE_WAY,
    hasChildOrInfant: !!(
      childrenCount ||
      infantsInSeatCount ||
      infantsOnLapCount
    ),
  };
};

const connector = connect(mapStateToProps);

export type ConnectedPriceBreakdownProps = ConnectedProps<typeof connector>;

export const ConnectedPriceBreakdown = connector(PriceBreakdown);
