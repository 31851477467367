import { useAccounts } from "@commbank/accounts";
import { useFeatureFlag, useTrackingProperties } from "@apac/feature-flags";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FlightDetailsTimeline } from "@commbank/flights";
import ApacWalletApp, { useWallet } from "@commbank/wallet";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ApacTripsList } from "@hopper-b2b/trips";
import {
  FlightClientAssetProps,
  LAUNCHED_APPLICATION,
  LaunchedApplicationProperties,
} from "@hopper-b2b/types";
import { SlotProvider, Slots } from "@hopper-b2b/ui";
import {
  FeatureFlagsContextProps,
  getLandingScreen,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { useTheme } from "@material-ui/core";
import {
  Suspense,
  lazy,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Redirect } from "react-router-dom";
import { Route, Routes } from "react-router-dom-v5-compat";
import { FEATURE_FLAGS, SessionContext } from "../../Root";
import { lightModeIcons } from "../../assets/sharedIcons";
import { MyTripsContactSupportImage } from "../../trips/MyTripsContactSupportImage/component";
import { MyTripsFlightIcon } from "../../trips/MyTripsFlightIcon/component";
import { MyTripsGuestIcon } from "../../trips/MyTripsGuestIcon/component";
import { MyTripsHotelIcon } from "../../trips/MyTripsHotelIcon/component";
import { MyTripsLandingIcon } from "../../trips/MyTripsLandingIcon/component";
import { colors } from "../../utils/colors";
import {
  PATH_CUSTOMER_SUPPORT,
  PATH_FLIGHTS,
  PATH_HOME,
  PATH_HOTELS,
  PATH_PROFILE,
  PATH_TRAVELLERS_LIST,
  PATH_TRIPS,
  apiConfig,
} from "../../utils/urlPaths";
import { Maintenance } from "../Maintenance";
import { MobileLandingPage } from "../MobileLandingPage";
import { Funnel } from "../type";
import styles from "./Body.module.scss";
import "./flightSearch.styles.scss";
import "./flightSearchMobile.styles.scss";
import "./hotelSearchMobile.styles.scss";
import { ApacLoadingWithLogo } from "@commbank/ui";

const DesktopLandingPage = lazy(() => import("../DesktopLandingPage"));
const AirRouteComponent = lazy(() => import("./AirRouteComponent"));
const LodgingRouteComponent = lazy(() => import("./LodgingRouteComponent"));
const TripsApp = lazy(() => import("@hopper-b2b/trips"));
const TravellerListRouteComponent = lazy(
  () => import("./TravellerListRouteComponent")
);
const CustomerSupportRouteComponent = lazy(
  () => import("./CustomerSupport/CustomerSupportRouteComponent")
);

interface IBodyProps {
  adaInit: boolean;
  featureFlags: FeatureFlagsContextProps;
}

const myTripsSlots: Slots = {
  "mytrips-landing-page-icon": MyTripsLandingIcon,
  "mytrips-hotel-icon": MyTripsHotelIcon,
  "mytrips-flight-icon": MyTripsFlightIcon,
  "mytrips-traveler-icon": MyTripsGuestIcon,
  "mytrips-contact-support-image": MyTripsContactSupportImage,
  "mytrips-list": ApacTripsList,
  "flight-details-timeline": FlightDetailsTimeline,
};

export const Body = ({ featureFlags }: IBodyProps) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const { sessionInfo } = useContext(SessionContext);
  const { creditBalance, fetchAndSetWalletSummary } = useWallet();

  const [launchEventSent, setLaunchEventSent] = useState<
    "idle" | "pending" | "done"
  >("idle");

  const theme = useTheme();
  const { accounts } = useAccounts();
  const isInMaintenance = useFeatureFlag(FEATURE_FLAGS.MAINTENANCE);
  const flightsMaintenanceEnabled = useFeatureFlag(
    FEATURE_FLAGS.FLIGHTS_MAINTENANCE
  );
  const hotelsMaintenanceEnabled = useFeatureFlag(
    FEATURE_FLAGS.HOTELS_MAINTENANCE
  );
  const tripsMaintenanceEnabled = useFeatureFlag(
    FEATURE_FLAGS.TRIPS_MAINTENANCE
  );
  const enableRetainFilters = useFeatureFlag(FEATURE_FLAGS.RETAIN_FILTERS);

  const trackingProperties: LaunchedApplicationProperties =
    useTrackingProperties({
      landing_screen: getLandingScreen(),
      url: document.location.pathname,
      customer_credit_balance_aud: creditBalance?.value,
    });

  useEffect(() => {
    if (launchEventSent !== "idle") return;
    (async () => {
      setLaunchEventSent("pending");
      const creditBalance = await fetchAndSetWalletSummary();
      trackEvent({
        eventName: LAUNCHED_APPLICATION,
        properties: {
          ...trackingProperties,
          customer_credit_balance_aud: creditBalance?.value,
        },
      })
        .then(() => setLaunchEventSent("done"))
        .catch(() => {
          trackEvent({
            eventName: LAUNCHED_APPLICATION,
            properties: trackingProperties,
          });
          setLaunchEventSent("done");
        });
    })();
  }, [fetchAndSetWalletSummary, launchEventSent, trackingProperties]);

  const flightAppClientAssets: FlightClientAssetProps = useMemo(
    () => ({
      sessionInfo,
      featureFlag: {},
      assets: lightModeIcons,
    }),
    [sessionInfo]
  );

  const tripsAppClientAssets = useMemo(
    () => ({
      sessionInfo: sessionInfo,
      featureFlag: {},
      assets: lightModeIcons,
    }),
    [sessionInfo]
  );

  const renderMobileLandingPage = (currentFunnel: Funnel) => (
    <MobileLandingPage
      funnels={[Funnel.Hotels, Funnel.Flights]}
      funnelToFunnelInfo={{
        [Funnel.Flights]: {
          stringId: "commBank.mobileFunnelTabs.flights",
          path: PATH_FLIGHTS,
        },
        [Funnel.Hotels]: {
          stringId: "commBank.mobileFunnelTabs.hotels",
          path: PATH_HOTELS,
        },
      }}
      currentFunnel={currentFunnel}
    />
  );

  const renderDesktopLandingPage = (currentFunnel: Funnel) => (
    <Suspense fallback={<Loading />}>
      <DesktopLandingPage
        funnels={[Funnel.Hotels, Funnel.Flights]}
        funnelToFunnelInfo={{
          [Funnel.Flights]: {
            stringId: "commBank.search.flights",
            path: PATH_FLIGHTS,
          },
          [Funnel.Hotels]: {
            stringId: "commBank.search.hotels",
            path: PATH_HOTELS,
          },
        }}
        currentFunnel={currentFunnel}
        commbankCardAccounts={accounts}
        clientAssets={flightAppClientAssets}
        featureFlags={featureFlags}
      />
    </Suspense>
  );

  const reviewItineraryTitleCssContentStrings: React.CSSProperties = {
    ["--departureTitle" as any]: `"${t(
      "commBank.flightReview.departureTitle"
    )}"`,
    ["--returnTitle" as any]: `"${t("commBank.flightReview.returnTitle")}"`,
  };

  return (
    <main className={styles.main} style={reviewItineraryTitleCssContentStrings}>
      {isInMaintenance ? (
        <Maintenance />
      ) : (
        <Routes>
          {matchesMobile ? (
            <>
              <Route
                path={PATH_FLIGHTS}
                element={renderMobileLandingPage(Funnel.Flights)}
              />
              <Route
                path={PATH_HOTELS}
                element={renderMobileLandingPage(Funnel.Hotels)}
              />
              <Route
                path={PATH_FLIGHTS + "*"}
                element={
                  flightsMaintenanceEnabled ? (
                    <Maintenance />
                  ) : (
                    <Suspense fallback={<Loading />}>
                      <AirRouteComponent
                        commbankCardAccounts={accounts}
                        clientAssets={flightAppClientAssets}
                        featureFlags={featureFlags}
                      />
                    </Suspense>
                  )
                }
              />
            </>
          ) : (
            <>
              <Route
                path={PATH_FLIGHTS + "*"}
                element={renderDesktopLandingPage(Funnel.Flights)}
              />
              <Route
                path={PATH_HOTELS}
                element={renderDesktopLandingPage(Funnel.Hotels)}
              />
            </>
          )}
          <Route
            path={PATH_HOME}
            element={<Redirect to={`/${PATH_HOTELS}`} push={false} />}
          />
          <Route
            path={PATH_HOTELS + "*"}
            element={
              hotelsMaintenanceEnabled ? (
                <Maintenance />
              ) : (
                <Suspense fallback={<Loading />}>
                  <LodgingRouteComponent
                    featureFlags={{ enableRetainFilters }}
                  />
                </Suspense>
              )
            }
          />
          <Route
            path={PATH_TRIPS}
            element={
              tripsMaintenanceEnabled ? (
                <Maintenance />
              ) : (
                <Suspense fallback={<Loading />}>
                  <SlotProvider slots={myTripsSlots}>
                    <TripsApp
                      theme={theme}
                      apiConfig={apiConfig}
                      clientAssets={tripsAppClientAssets}
                      featureFlags={featureFlags}
                      colors={colors}
                      onSupportClick={() => {
                        // Add CBA Support
                        console.log("Placeholder for CBA support");
                      }}
                    />
                  </SlotProvider>
                </Suspense>
              )
            }
          />
          <Route
            path={PATH_PROFILE}
            element={
              <Suspense fallback={<Loading />}>
                <ApacWalletApp />
              </Suspense>
            }
          />
          <Route
            path={PATH_TRAVELLERS_LIST}
            element={
              <Suspense fallback={<Loading />}>
                <TravellerListRouteComponent />
              </Suspense>
            }
          />
          <Route
            path={PATH_CUSTOMER_SUPPORT}
            element={
              <Suspense fallback={<Loading />}>
                <CustomerSupportRouteComponent />
              </Suspense>
            }
          />
        </Routes>
      )}
    </main>
  );
};

const Loading = () => <ApacLoadingWithLogo hideLogo />;
