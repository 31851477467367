import {
  ApacDesktopPopupModal,
  ApacIcon,
  ApacIconName,
  ApacMobileFullScreenModal,
} from "@commbank/ui";
import { Prediction } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Typography } from "@material-ui/core";
import { useState } from "react";
import { PriceDropProtectionContent } from "../../modules/shop/components/PricePrediction";
import "./styles.scss";

export const PriceDropLineAndModal = ({
  isPDPEligible,
  prediction,
}: {
  isPDPEligible: boolean;
  prediction?: Prediction;
}) => {
  const { t } = useI18nContext();
  const { matchesMobile: isMobile } = useDeviceTypes();
  const [openPriceDropModal, setOpenPriceDropModal] = useState(false);

  return (
    isPDPEligible && (
      <div className="price-drop-line-and-modal-root">
        <div
          className="pdp-container"
          onClick={() => setOpenPriceDropModal(true)}
        >
          <ApacIcon name={ApacIconName.Protection} />
          <Typography className="pdp-modal-link-copy" variant="body1">
            {t("pricePrediction.freePriceDropProtection")}
          </Typography>
        </div>

        {!!prediction &&
          (isMobile ? (
            <ApacMobileFullScreenModal
              open={openPriceDropModal}
              onClose={() => setOpenPriceDropModal(false)}
            >
              <PriceDropProtectionContent
                onClose={() => setOpenPriceDropModal(false)}
                prediction={prediction}
              />
            </ApacMobileFullScreenModal>
          ) : (
            <ApacDesktopPopupModal
              open={openPriceDropModal}
              className="desktop-flight-shop-modal"
              contentClassName="modal-content"
              onClose={() => setOpenPriceDropModal(false)}
            >
              <PriceDropProtectionContent
                onClose={() => setOpenPriceDropModal(false)}
                prediction={prediction}
              />
            </ApacDesktopPopupModal>
          ))}
      </div>
    )
  );
};
