import { useId } from "react";
import { RoomInfo } from "@b2bportal/lodging-api";
import { Button, DialogTitle, Typography } from "@material-ui/core";
import { HotelDetailsDialog } from "../../../HotelDetailsDialog";
import styles from "./DetailsAndAmenitiesModal.module.scss";
import { useI18nContext } from "@hopper-b2b/i18n";
import { AmenitiesList } from "../../../AmenitiesList";
import { ApacIcon, ApacIconName } from "@commbank/ui";

interface Props extends RoomInfo {
  open: boolean;
  onClose(): void;
  continueRoomBooking?: () => void;
}

export function DetailsAndAmenitiesModal({
  open,
  description,
  amenities,
  onClose,
  continueRoomBooking,
}: Props) {
  const titleId = useId();
  const { t } = useI18nContext();

  return (
    <HotelDetailsDialog
      className="room-amenities-modal"
      aria-labelledby={titleId}
      open={open}
      onClose={onClose}
    >
      <DialogTitle className={styles.DialogTitle} disableTypography>
        <div>
          <div className="amenities-modal-title">
            <ApacIcon name={ApacIconName.CBAHome} />
            {t("roomAmenities")}
          </div>

          {amenities.length > 0 ? (
            <div className={styles.AmenitiesList}>
              <AmenitiesList amenities={amenities} />
            </div>
          ) : null}
        </div>

        <HotelDetailsDialog.CloseIconButton onClose={onClose} />
      </DialogTitle>
      <HotelDetailsDialog.DialogContent className={styles.DialogContent}>
        {description ? (
          <>
            <Typography className="amenities-modal-title" id={titleId}>
              {t("roomDescription")}
            </Typography>
            <Typography
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            ></Typography>
          </>
        ) : amenities.length === 0 ? (
          <Typography>{t("noRoomDetails")}</Typography>
        ) : null}
        {continueRoomBooking ? (
          <Button
            variant="contained"
            color="primary"
            onClick={continueRoomBooking}
          >
            {t("continueRoomBooking")}
          </Button>
        ) : null}
      </HotelDetailsDialog.DialogContent>
    </HotelDetailsDialog>
  );
}
