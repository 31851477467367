import { useI18nContext } from "@hopper-b2b/i18n";
import { Typography } from "@material-ui/core";
import { ApacActionLink } from "../ApacActionLink";
import { ApacIcon, ApacIconName } from "../ApacIcon";
import clsx from "clsx";
import "./styles.scss";

interface TravelWalletDropdownProps {
  openProfilePanel: boolean;
  setOpenProfilePanel: (boolean) => void;
}

export const TravelWalletDropdown = ({
  openProfilePanel,
  setOpenProfilePanel,
}: TravelWalletDropdownProps) => {
  const { t } = useI18nContext();

  return (
    <div className="traveler-wallet">
      <ApacIcon name={ApacIconName.TravelWallet} />

      <div className="right">
        <Typography className="title">{t("wallet.walletTitle")}</Typography>
        <div
          className="action-wrapper"
          onClick={() => setOpenProfilePanel((pre) => !pre)}
        >
          <ApacActionLink className="action-text" message={t("viewDetails")} />
          <ApacIcon
            className={clsx("arrow", { opened: openProfilePanel })}
            name={ApacIconName.ChevronDown}
          />
        </div>
      </div>
    </div>
  );
};
