import { Divider, Typography, TypographyProps } from "@material-ui/core";
import clsx from "clsx";
import { isString } from "lodash-es";
import { ReactNode, useState } from "react";

import { ImportantInfoList } from "../../ImportantInfoList";
import { BannerSeverity, NotificationBanner } from "../../NotificationBanner";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useI18nContext } from "@hopper-b2b/i18n";
import { DetailHalfSheet } from "../../DetailHalfSheet";
import { IconName } from "../../Icon";
import { IconComponent } from "../../IconComponent";
import "./styles.scss";

export interface IFlightCancelationInfoProps {
  ItinerarySummary?: ReactNode;
  actions?: ReactNode | ReactNode[];
  disclaimer?: string;
  infoItems: string[];
  subtitle?: ReactNode;
  subtitle2?: ReactNode;
  subtitleProps?: TypographyProps;
  tcHelpProps?: TypographyProps;
  tcHelpText?: ReactNode;
  title?: ReactNode;
  titleProps?: TypographyProps;
}

const defaultProps: Partial<IFlightCancelationInfoProps> = {
  ItinerarySummary: null,
  infoItems: [],
};

/**
 * @description Renders a title, optional notification, optional text,
 * itinerary as a row, optional cancelation info, and optional actions
 * as a left-justified column.
 * Can be used in a modal or any container element.
 * @param {IFlightCancelationInfoProps} props
 * @return {JSX.Element}
 */
const FlightCancelationInfo = (
  props: IFlightCancelationInfoProps
): JSX.Element => {
  const {
    ItinerarySummary,
    actions,
    disclaimer,
    infoItems,
    subtitleProps,
    tcHelpText,
    title,
    titleProps,
  } = props;
  const { t } = useI18nContext();
  let { subtitle, subtitle2 } = props;
  const updatedSubtitleProps: TypographyProps = subtitleProps ?? {};
  const updatedSubtitle2Props: TypographyProps = subtitleProps ?? {};
  const [showFtcInfo, setShowFtcInfo] = useState(false);

  // if subtitle is string[], format to show as separate sentences
  if (
    subtitle &&
    Array.isArray(subtitle) &&
    subtitle.every((s) => isString(s)) &&
    !subtitleProps?.dangerouslySetInnerHTML
  ) {
    updatedSubtitleProps.dangerouslySetInnerHTML = {
      __html: subtitle.join(" "),
    };
    subtitle = null;
  }

  if (
    subtitle2 &&
    Array.isArray(subtitle2) &&
    subtitle2.every((s) => isString(s)) &&
    !subtitleProps?.dangerouslySetInnerHTML
  ) {
    updatedSubtitle2Props.dangerouslySetInnerHTML = {
      __html: subtitle2.join(" "),
    };
    subtitle2 = null;
  }

  return (
    <div className="uber-flight-cancelation-info-container">
      <div className="uber-flight-cancelation-info-content">
        <Typography
          variant="h2"
          {...titleProps}
          className={clsx("modal-title", titleProps?.className)}
        >
          {title || ""}
        </Typography>
        {disclaimer && (
          <NotificationBanner
            html={disclaimer}
            severity={BannerSeverity.WARNING}
          />
        )}
        {(subtitle || updatedSubtitleProps) && (
          <Typography
            {...updatedSubtitleProps}
            className={clsx("subtitle", updatedSubtitleProps?.className)}
          >
            {subtitle}
          </Typography>
        )}
        {(subtitle2 || updatedSubtitle2Props) && (
          <Typography
            {...updatedSubtitle2Props}
            className={clsx("subtitle", updatedSubtitle2Props?.className)}
          >
            {subtitle2}
          </Typography>
        )}
        {tcHelpText && (
          <>
            <div className="tc-help-container">
              <IconComponent
                ariaLabel="ftc info icon"
                className="ftc-info-icon"
                name={IconName.InfoCircleFill}
              />
              <div
                className="tc-help-content"
                onClick={() => setShowFtcInfo(true)}
              >
                <Typography className="title bold" variant="h4">
                  <>{t("selfServe.ftcInfoCta")}</>
                </Typography>
                <FontAwesomeIcon
                  icon={faChevronRight as IconProp}
                  className="ftc-info-icon-cta"
                />
              </div>
            </div>
            <DetailHalfSheet
              onClose={() => setShowFtcInfo(false)}
              open={showFtcInfo}
              title=""
              description={t("selfServe.ftcInfo")}
              header={t("selfServe.ftcInfoTitle") || ""}
            />
          </>
        )}
        {ItinerarySummary}
        {infoItems.length ? (
          <div className="info-items">
            <Divider className="divider" />
            <ImportantInfoList
              titleProps={{ variant: "h6", className: "cancel-info-title" }}
              infoItems={infoItems}
              title={t("selfServe.cancelationInfoTitle") || ""}
            />
          </div>
        ) : null}
        <Divider className="divider" />
      </div>
      {actions && <div className="actions-container">{actions}</div>}
    </div>
  );
};

FlightCancelationInfo.defaultProps = defaultProps;

export default FlightCancelationInfo;
