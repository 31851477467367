import { AirExchangeAgentFulfillSegment } from "@b2bportal/air-exchange-api";
import { Airport } from "@b2bportal/air-shopping-api";
import { ICategorizedResponse, ITripTerminus } from "@hopper-b2b/types";

export type ITripTerminusCategory = ICategorizedResponse & {
  results: ITripTerminus[];
};

export enum ExchangeTypeEventEnum {
  DatesOnly = "dates_only",
  AirportAndDates = "airport_and_dates",
  AirportOnly = "airport_only",
  Same = "same",
}

export enum TripType {
  Outbound = "Outbound",
  Return = "Return",
}

export enum SliceType {
  departure = "departure",
  return = "return",
}

export interface IExchangeSubmitSlice {
  segments: Array<AirExchangeAgentFulfillSegment>;
}

export interface IPartialAirportMap {
  [airportCode: string]: Partial<Airport>;
}
export const formats = Object.freeze({
  DISPLAY_DATE: "ddd, MMM DD", // Sat, Jan 01
  SHORT_DATE: "MMM DD", // Sat, Jan 01
  REQUEST_DATE: "YYYY-MM-DD", // 2021-01-13
  SUMMARY_TIME: "h:mm A", // 5:30 PM
  VOID_BY_DATE: "ddd, MMM Do [at] h:mm A", // Sat, Jan 1st at 5:30 PM
});

export enum exchangeSteps {
  loadingPolicy = "loadingPolicy",
  loadingFlights = "loadingFlights",
  airportPicker = "airportPicker",
  datePicker = "datePicker",
  slicePicker = "slicePicker",
  flightShop = "flightShop",
  reviewItinerary = "reviewItinerary",
  chooseDeparture = "chooseDeparture",
  chooseReturn = "chooseReturn",
  review = "review",
  checkout = "checkout",
  submitting = "submitting",
  success = "success",
  error = "error",
}
