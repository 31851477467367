import { ApacIcon, ApacIconName } from "@commbank/ui";
import { AmenityStatus } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap } from "@hopper-b2b/ui";
import { useId, useState } from "react";
import { AmenitiesList } from "../AmenitiesList";
import { HotelDetailsDialog } from "../HotelDetailsDialog";
import styles from "./Amenities.module.scss";

export const MoreAmenitiesLink = ({
  className,
  linkText,
  amenities,
}: {
  className: string;
  linkText: string;
  amenities: AmenityStatus[];
}) => {
  const { t } = useI18nContext();
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonWrap
        className={className}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(true);
        }}
      >
        {linkText}
      </ButtonWrap>
      <Modal
        open={open}
        title={t("amenitiesHeader")}
        closeButtonTitle={t("hotelShop.closeDialogButtonLabel")}
        onClose={() => setOpen(false)}
      >
        <AmenitiesList className={styles.AmenitiesList} amenities={amenities} />
      </Modal>
    </>
  );
};

function Modal({
  open,
  title,
  closeButtonTitle,
  children,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  closeButtonTitle: string;
  children: React.ReactNode;
}) {
  const titleId = useId();

  return (
    <HotelDetailsDialog aria-labelledby={titleId} open={open} onClose={onClose}>
      <HotelDetailsDialog.TitleWithCloseButton
        titleId={titleId}
        onClose={onClose}
        className={styles.AmenitiesModalTitleContainer}
      >
        <div className="amenities-modal-title">
          <ApacIcon name={ApacIconName.CBAHome} />
          {title}
        </div>
      </HotelDetailsDialog.TitleWithCloseButton>
      <HotelDetailsDialog.DialogContent>
        {children}
      </HotelDetailsDialog.DialogContent>
      <HotelDetailsDialog.CloseButton
        title={closeButtonTitle}
        onClose={onClose}
      />
    </HotelDetailsDialog>
  );
}
