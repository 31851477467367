import {
  CallState,
  MessageMethodEnum,
  UpdateWatchAlertRequest,
} from "@hopper-b2b/types";
import { flightApiConfigStoreKey } from "../../../reducers/types";
import { IStoreState } from "../../../reducers/types";
import { put, select } from "redux-saga/effects";

import { updateWatch } from "@hopper-b2b/api";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import { WatchAlertView } from "@b2bportal/air-price-watch-api";

export function* updateWatchSaga(action: actions.IUpdateWatch) {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const request: UpdateWatchAlertRequest = {
      key: action.alertKey,
      method: {
        email: action.email,
        MessageMethod: MessageMethodEnum.Email,
      },
    };
    const watches: WatchAlertView[] = yield updateWatch(request, apiConfig);
    yield put(actions.setUpdateWatchCallState(CallState.Success));
    yield put(actions.setWatches(watches));
  } catch (e) {
    yield put(actions.setUpdateWatchCallState(CallState.Failed));
    Logger.debug(e);
  }
}
