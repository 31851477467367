import axios from "axios";

import { fetchEligibilityApiPrefix } from "../paths";
import {
  GetEligibilityRequest,
  GetEligibilityResponse,
  IApiConfig,
  NETWORK_CALL_FAILED,
} from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";

export const fetchPriceDropEligibility = (
  body: GetEligibilityRequest,
  apiConfig?: IApiConfig
): Promise<GetEligibilityResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(fetchEligibilityApiPrefix, body);

      resolve(res.data);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });

export default fetchPriceDropEligibility;
