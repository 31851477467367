import { axiosInstance } from "@hopper-b2b/api";
import { TripDetails, Uuid } from "@hopper-b2b/types";

import Logger from "../../../helpers/Logger";
import { fetchTripDetailsPath } from "../paths";

const fetchTripDetails = async (tripId: Uuid) => {
  try {
    const res = await axiosInstance.post<TripDetails>(fetchTripDetailsPath, {
      value: tripId,
    });

    if (res?.data) {
      return res.data;
    }

    throw res;
  } catch (err) {
    Logger.debug(err);

    throw err;
  }
};

export default fetchTripDetails;
