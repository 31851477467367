import { combineReducers } from "redux";

import { reducer as rewards } from "../modules/rewards/reducer";
import { reducer as tripsList } from "../pages/TripsList/reducer";
import { getApiConfigReducer as tripsApiConfig } from "@hopper-b2b/utilities";

export const tripsApiConfigStoreKey = "tripsApiConfig";

export const rootReducer = combineReducers({
  tripsList,
  rewards,
  tripsApiConfig: tripsApiConfig(tripsApiConfigStoreKey),
});
