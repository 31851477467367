import { LodgingData } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Divider, Typography } from "@material-ui/core";
import clsx from "clsx";
import { SECTION_ID } from "../../constants";
import { HotelDetailsSection } from "../HotelDetailsSection";
import { TripAdvisorReviewsBadge } from "../TripAdvisorReviewsBadge";
import styles from "./CustomerReviews.module.scss";
import { Comment, MoreReviews } from "./components";
import { ReviewsCategories } from "./components/ReviewsCategories";

const COMMENTS_TO_SHOW = 2;

type Props = Pick<
  LodgingData["tripAdvisorReviews"],
  "overallScore" | "reviewsCount" | "comments" | "categories"
>;

export function CustomerReviews({
  overallScore,
  reviewsCount,
  comments,
  categories,
}: Props) {
  const { t } = useI18nContext();
  const { matchesMobile: isMobile } = useDeviceTypes();

  if (reviewsCount === 0) {
    return null;
  }

  const firstComments = comments.slice(0, COMMENTS_TO_SHOW);

  return (
    <HotelDetailsSection id={SECTION_ID.reviews} title={t("customerReviews")}>
      <Typography variant="body2" className={styles.Summary}>
        <TripAdvisorReviewsBadge
          reviewsCount={reviewsCount}
          overAllScore={overallScore}
        />
      </Typography>

      <ReviewsCategories
        categories={categories}
        className={clsx(
          isMobile ? styles.ReviewsGridMobile : styles.ReviewsGrid
        )}
      />

      <Divider className={styles.Divider} />

      <div className={styles.Comments}>
        {firstComments.map(({ title, text, author, created, score }) => (
          <Comment
            key={title + author + created}
            title={title}
            text={text}
            author={author}
            created={created}
            score={score}
          />
        ))}
      </div>
      {comments.length > firstComments.length && (
        <MoreReviews
          comments={comments}
          overallScore={overallScore}
          reviewsCount={reviewsCount}
          categories={categories}
        />
      )}
    </HotelDetailsSection>
  );
}
