import clsx from "clsx";
import { TripAdvisorComment } from "@b2bportal/lodging-api";
import { TripAdvisorBadge } from "@hopper-b2b/ui";
import { Typography } from "@material-ui/core";
import styles from "./ModalComment.module.scss";

export function ModalComment({
  title,
  text,
  created,
  score,
  author,
}: TripAdvisorComment) {
  return (
    <article className="a-comment">
      <Typography className={styles.Title} variant="body2" component="h4">
        “{title}”
        <TripAdvisorBadge
          translateToTheLeft
          withTripAdvisorIcon
          scoreOutOfFive={score * 5}
        />
      </Typography>
      <Typography className={styles.Text}>{text}</Typography>
      <Typography className={styles.Text}>
        {author}, {created}
      </Typography>
    </article>
  );
}
