import { Typography, Box } from "@material-ui/core";
import dayjs from "dayjs";

import { IconComponent, IconName } from "@hopper-b2b/ui";
import { Person } from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { getGender } from "@hopper-b2b/utilities";

import "./styles.scss";

interface ITravelersModalContentProps {
  travelers: Person[];
}

/**
 * @deprecated Component to be implemented in the libs/self-serve folder
 */
export const TravelersModalContent = ({
  travelers,
}: ITravelersModalContentProps) => {
  const { t } = useI18nContext();
  const renderTravelers = () => (
    <Box className="travelers-container">
      {travelers.map((person) => (
        <Box key={person.id} className="traveler-details-container">
          <IconComponent className="traveler-icon" name={IconName.User} />
          <Box className="traveler-details">
            <Typography className="traveler-name" variant="h4">
              {`${person?.givenName} ${person?.surname}`}
            </Typography>
            <Typography className="traveler-birthdate" variant="body2">
              {dayjs(person?.dateOfBirth, "YYYY-MM-DD").format("MMMM DD, YYYY")}
            </Typography>
            <Typography className="traveler-gender" variant="body2">
              {t("person", { context: getGender(person?.gender) })}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );

  // TODO: Consider creating a generic trips modal with this format
  return (
    <Box className="travelers-modal-content">
      <Box className="modal-header">
        <Typography className="modal-title" variant="h2">
          {`${t("travelerSummaryLabel")} (${travelers.length})`}
        </Typography>
      </Box>
      <Box className="modal-body">{renderTravelers()}</Box>
    </Box>
  );
};
