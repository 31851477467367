import {
  AmenityStatus,
  TrackingProperties,
  type Lodging,
  type Suggestion,
  type WalletOfferApplicabilityResponse,
} from "@b2bportal/lodging-api";
import { actionTypes, actions } from "../actions";

export type PriceRange = {
  min: number;
  max: number;
  lowest: number;
  highest: number;
};

export type SortOption =
  | "mostRecommended"
  | "starRating"
  | "userRating"
  | "price";

export type LoadingLodgings = null | "loading-from-place" | "loading-from-map";

export type LodgingsFilterBoundaries = {
  amenities: Map<string, { amenity: AmenityStatus; count: number }>;
};

export interface ILodgingFilterState {
  starRating: number[];
  userRating: number;
  amenities: string[];
  filterAmenitiesAnd: boolean;
  freeCancellation: boolean;
  priceRange: PriceRange;
}

export interface ILodgingAvailabilityState {
  loading?: LoadingLodgings;
  fromDate?: string;
  untilDate?: string;
  place?: Suggestion;
  location?: string;
  lodgings?: Lodging[];
  guests?: { adults: number; children: number[] };
  rooms?: number;
  filters?: ILodgingFilterState;
  offers?: WalletOfferApplicabilityResponse;
  sort?: SortOption;
  trackingProperties?: TrackingProperties;
}

const calculatePriceRangeFilter = (
  lodgings: Lodging[],
  {
    min: currentMin,
    max: currentMax,
    lowest: currentLowest,
    highest: currentHighest,
  }: PriceRange
): PriceRange => {
  const filteredLodgings = lodgings.filter((l) => !!l.price);
  if (filteredLodgings.length === 0) {
    return {
      min: 0,
      max: 0,
      lowest: 0,
      highest: 0,
    };
  }
  const lodgingsSortedByPrice = filteredLodgings.sort(
    (a, b) =>
      a?.price?.nightlyPrice?.fiat.value - b?.price?.nightlyPrice?.fiat.value
  );
  const lowestPrice = Math.floor(
    lodgingsSortedByPrice[0]?.price?.nightlyPrice?.fiat.value
  );
  const highestPrice = Math.ceil(
    lodgingsSortedByPrice[lodgingsSortedByPrice.length - 1]?.price?.nightlyPrice
      ?.fiat.value
  );

  return {
    min: currentMin === currentLowest ? lowestPrice : currentMin,
    max: currentMax === currentHighest ? highestPrice : currentMax,
    lowest: lowestPrice,
    highest: highestPrice,
  };
};

export function reducer(
  state: ILodgingAvailabilityState = {},
  action: actions.LodgingAvailabilityActions
): ILodgingAvailabilityState {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case actionTypes.SET_AVAILABILITY_LODGINGS:
      return {
        ...state,
        ...action.stayValues,
        lodgings: action.lodgings,
        place: action.place,
        filters: {
          ...state.filters,
          priceRange: calculatePriceRangeFilter(
            [...state.lodgings, ...action.lodgings],
            state.filters.priceRange
          ),
        },
        loading: null,
        offers: action.offers,
        trackingProperties: action.trackingProperties,
      };
    case actionTypes.SET_SEARCH_PARAMS:
      return {
        ...state,
        ...action.stayValues,
      };
    case actionTypes.ADD_AVAILABILITY_LODGINGS:
      return {
        ...state,
        lodgings: [...state.lodgings, ...action.lodgings],
        filters: {
          ...state.filters,
          priceRange: calculatePriceRangeFilter(
            [...state.lodgings, ...action.lodgings],
            state.filters.priceRange
          ),
        },
        loading: null,
        offers: {
          // Set as the intial best overall lodging, might not be the best.
          bestOfferOverall: state.offers?.bestOfferOverall,
          offersByProductId: {
            ...state.offers?.offersByProductId,
            ...action.offers?.offersByProductId,
          },
          opaqueProductMap: {
            ...state.offers?.opaqueProductMap,
            ...action.offers?.opaqueProductMap,
          },
        },
      };
    case actionTypes.RESET_FILTERS:
      return {
        ...state,
        filters: {
          userRating: 0,
          starRating: [],
          amenities: [],
          freeCancellation: false,
          filterAmenitiesAnd: true,
          priceRange: {
            ...state.filters.priceRange,
            min: state.filters.priceRange.lowest || 0,
            max: state.filters.priceRange.highest || Number.MAX_SAFE_INTEGER,
          },
        },
      };
    case actionTypes.UPDATE_FILTERS_AND_SORT: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
          priceRange: {
            ...state.filters.priceRange,
            ...action.filters.priceRange,
          },
        },
        sort: action.sort || state.sort,
      };
    }
    case actionTypes.SET_SORT:
      return {
        ...state,
        sort: action.value,
      };

    default:
      return state;
  }
}

export * from "./selectors";
