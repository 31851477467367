import { CallState, LodgingShopSteps } from "@hopper-b2b/types";
import { actionTypes, actions } from "../actions";
import {
  Lodging,
  RoomInfoProducts,
  CheckInInstructions,
} from "@b2bportal/lodging-api";

export interface ILodgingShopState {
  selectedLodging?: Lodging;
  rooms: RoomInfoProducts[];
  checkInPolicies?: CheckInInstructions["policies"];
  checkInInstructions?: CheckInInstructions;
  selectedRoom?: RoomInfoProducts;
  selectedRoomRateId?: string;
  currentStep: LodgingShopSteps;
  selectedOfferOpaque?: string;
  shopCallState: CallState;
  roomsCallState: CallState;
}

const initialState: ILodgingShopState = {
  currentStep: LodgingShopSteps.LODGING_DETAILS,
  rooms: [],
  shopCallState: CallState.NotCalled,
  roomsCallState: CallState.NotCalled,
};

export function reducer(
  state: ILodgingShopState = initialState,
  action: actions.LodgingShopActions
): ILodgingShopState {
  switch (action.type) {
    case actionTypes.SET_SELECTED_LODGING:
      return {
        ...state,
        selectedLodging: action.lodging,
        selectedOfferOpaque: action.offerOpaque,
        shopCallState: CallState.Success,
      };
    case actionTypes.SET_CHECK_IN_POLICIES:
      return {
        ...state,
        checkInPolicies: action.checkInPolicies,
      };
    case actionTypes.SET_CHECK_IN_INSTRUCTIONS:
      return {
        ...state,
        checkInInstructions: action.checkInInstructions,
      };
    case actionTypes.SET_ROOMS:
      return {
        ...state,
        roomsCallState: CallState.Success,
        rooms: [...action.rooms],
      };
    case actionTypes.SET_SELECTED_ROOM:
      return {
        ...state,
        selectedRoom: action.room,
        selectedRoomRateId: action.room?.products?.[0]?.rateId.value,
      };
    case actionTypes.SET_SELECTED_RATE:
      return {
        ...state,
        selectedRoomRateId: action.rateId,
      };
    case actionTypes.SET_CURRENT_SHOP_STEP:
      return {
        ...state,
        currentStep: action.step,
      };

    case actionTypes.SET_SHOP_CALL_STATE:
      return {
        ...state,
        shopCallState: action.callState,
        roomsCallState: action.callState,
      };
    default:
      return state;
  }
}
