import { ApacLoadingWithLogo } from "@commbank/ui";
import {
  LogInWithCode,
  StartSessionRequestEnum,
  StartSessionResponseEnum,
} from "@b2bportal/commbank-au-auth-api";
import { B2B_PORTAL_AUTH_REDIRECT_TO } from "@hopper-b2b/types";
import * as queryStringParser from "query-string";
import { useCallback, useContext, useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom-v5-compat";
import { SessionContext } from "../../Root";
import { startSession } from "../../api/startSession";
import {
  PATH_ACCESS_DENIED,
  PATH_AUTH_INVALID_SESSION,
  PATH_INVALID_SESSION,
  PATH_START_SESSION,
  PATH_HOME,
} from "../../utils/urlPaths";
import UnauthorizedPage from "../UnauthorizedPage";
import "./styles.scss";
import { datadogRum } from "@datadog/browser-rum";

export const SESSION_TOKEN_PARAM = "token";
export const AUTH_CODE_PARAM = "code";
export const ERROR_PARAM = "error";
export const ERROR_DESCRIPTION_PARAM = "error_description";
export const LOGOUT_REDIRECT = "/";
export const CODE_VERIFIER = "code_verifier";
export const TRAVEL_PROFILE_ID = "travelProfileId";

export const logger = () => {
  return window.DD_LOGS ? window.DD_LOGS.logger : undefined;
};

export const StartSessionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setSessionInfo } = useContext(SessionContext);

  const navigateTo = useCallback(
    (path) => navigate(`/${path}`, { replace: true }),
    [navigate]
  );

  const authGenericCodeFlow = useCallback(async () => {
    const redirectToSessionStorageUrl = (url: string) => {
      navigate(url, { replace: true });
      sessionStorage.removeItem(B2B_PORTAL_AUTH_REDIRECT_TO);
    };

    const handleRedirect = () => {
      const redirectTo = sessionStorage.getItem(B2B_PORTAL_AUTH_REDIRECT_TO);
      if (redirectTo) redirectToSessionStorageUrl(redirectTo);
      else navigate(PATH_HOME, { replace: true });
    };

    try {
      const searchParams = new URLSearchParams(location?.search);
      const code = searchParams.get(AUTH_CODE_PARAM);
      const codeVerifier = sessionStorage.getItem(CODE_VERIFIER);
      const travelProfileId = sessionStorage.getItem(TRAVEL_PROFILE_ID);

      // if we receive an error from CBA we should show the access denied page
      const startSessionError = searchParams.get(ERROR_PARAM);
      if (startSessionError) {
        navigateTo(PATH_ACCESS_DENIED);
        return;
      }

      if (code && codeVerifier && travelProfileId) {
        logger()?.info(
          `reading travel profile id from storage: ${travelProfileId}`
        );
        const sessionInfo = await startSession({
          code,
          codeVerifier,
          travelProfileId,
          StartSessionRequest: StartSessionRequestEnum.LogInWithCode,
        } as { StartSessionRequest: "LogInWithCode" } & LogInWithCode);

        switch (sessionInfo.StartSessionResponse) {
          case StartSessionResponseEnum.StartSessionSuccess:
            // eslint-disable-next-line no-case-declarations
            const nameParts = sessionInfo?.userProfile?.displayName?.split(" ");
            if (!nameParts || nameParts.length < 1) {
              console.error(
                "start session successful but user name is invalids"
              );
              setSessionInfo(null);
              navigateTo(PATH_AUTH_INVALID_SESSION);
            }
            setSessionInfo({
              userInfo: {
                firstName: nameParts[0],
                lastName: nameParts[nameParts.length - 1],
                email: sessionInfo.userProfile.email,
              },
              isFirstSession: sessionInfo.isFirstSession,
              hopperUserId: sessionInfo.userProfile.hopperUserId,
              kustomerAccessToken: sessionInfo.kustomerAccessToken,
            });
            if (datadogRum) {
              datadogRum.setUser({
                id: sessionInfo.userProfile.hopperUserId,
              });
            }
            handleRedirect();
            break;
          case StartSessionResponseEnum.StartSessionFailed:
            navigateTo(PATH_ACCESS_DENIED);
            break;
          default:
            navigateTo(PATH_AUTH_INVALID_SESSION);
            break;
        }
      } else {
        setSessionInfo(null);
        navigateTo(PATH_ACCESS_DENIED);
      }
    } catch (err) {
      setSessionInfo(null);
      navigateTo(PATH_ACCESS_DENIED);
    }
  }, [navigate, navigateTo, location?.search, setSessionInfo]);

  // Fix previous sessions
  // const endPreviousSessions = useCallback(async () => {
  //   await endSession();
  // }, []);

  useEffect(() => {
    const queryString = queryStringParser.parse(location.search);
    if (queryString) {
      authGenericCodeFlow();
    } else {
      navigateTo(PATH_AUTH_INVALID_SESSION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="loading-full-screen">
      <ApacLoadingWithLogo />
    </div>
  );
};

export const LogoutComponent = () => {
  const navigate = useNavigate();
  const logout = async () => {
    try {
      // TODO: add logout api call
      //   const logoutResponse = await postLogout();
      //   if (logoutResponse.destinationUrl)
      //   window.location.replace(logoutResponse.destinationUrl);
      // history.push(LOGOUT_REDIRECT);
      navigate(LOGOUT_REDIRECT);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="loading-full-screen">
      <ApacLoadingWithLogo />
    </div>
  );
};

export const InvalidSessionComponent = () => <UnauthorizedPage />;

export const AuthModule = () => {
  return (
    <Routes>
      <Route
        path={`${PATH_START_SESSION}/*`}
        element={<StartSessionComponent />}
      />
      <Route
        path={`${PATH_INVALID_SESSION}/*`}
        element={<InvalidSessionComponent />}
      />
    </Routes>
  );
};
