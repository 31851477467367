import { CallState } from "@hopper-b2b/types";
import {
  DisruptionOffer,
  Product,
  DisruptionQuote,
} from "@b2bportal/air-disruption-api";
import { createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../reducers/types";
import { isEmpty } from "lodash-es";

const disruptionProductTypeSelector = (
  offer: DisruptionOffer,
  productType: Product | Product[]
) =>
  offer.quotes.some(
    (q: DisruptionQuote) =>
      q.productType === productType || productType.includes(q.productType)
  );

export const getFetchDisruptionOfferCallState = (state: IStoreState) =>
  state.flightDisruption?.disruptionOffersCallState;

export const getDisruptionOffers = (state: IStoreState) =>
  state.flightDisruption?.disruptionOffers;

export const getScheduleChangeOffer = createSelector(
  getDisruptionOffers,
  (offers?: DisruptionOffer[]): DisruptionOffer | undefined =>
    offers?.find((offer) =>
      disruptionProductTypeSelector(offer, [
        Product.ScheduleChangeDisruptionProtection,
      ])
    )
);

export const getMissedConnectionOffer = createSelector(
  getDisruptionOffers,
  (offers?: DisruptionOffer[]): DisruptionOffer | undefined =>
    offers?.find((offer) =>
      disruptionProductTypeSelector(
        offer,
        Product.MissedConnectionDisruptionProtection
      )
    )
);

export const getAirDisruptionOffers = createSelector(
  getDisruptionOffers,
  (offers?: DisruptionOffer[]): DisruptionOffer[] | undefined =>
    offers?.filter((offer) =>
      disruptionProductTypeSelector(offer, Product.AirDisruptionProtection)
    )
);

export const getSelectedAirDisruptionOffer = (state: IStoreState) =>
  state.flightDisruption?.selectedAirDisruptionOffer;

export const getSelectedDisruptionOffers = createSelector(
  getSelectedAirDisruptionOffer,
  (airDisruptionOffer?: DisruptionOffer) => [airDisruptionOffer].filter(Boolean)
);

export const getDisruptionTotal = (state: IStoreState) => {
  const disruptionOffers = getSelectedDisruptionOffers(state);
  let offerPrice = 0;
  if (disruptionOffers && disruptionOffers.length > 0) {
    disruptionOffers.forEach((offer) => {
      const price = offer.quotes[0].pricePerPax.fiat.value;
      offerPrice += price;
    });
  }

  return offerPrice;
};

export const isDisruptionOffersValid = createSelector(
  getFetchDisruptionOfferCallState,
  getDisruptionOffers,
  (callState: CallState, offers?: DisruptionOffer[]) =>
    callState === CallState.InProcess ||
    (callState === CallState.Success && !isEmpty(offers))
);

export const isScheduleChangeValid = (includeNotCalled: boolean) =>
  createSelector(
    getFetchDisruptionOfferCallState,
    getScheduleChangeOffer,
    (callState: CallState, offer?: DisruptionOffer) =>
      (includeNotCalled && callState === CallState.NotCalled) ||
      callState === CallState.InProcess ||
      (callState === CallState.Success && !!offer)
  );

export const isMissedConnectionValid = (includeNotCalled: boolean) =>
  createSelector(
    getFetchDisruptionOfferCallState,
    getMissedConnectionOffer,
    (callState: CallState, offer?: DisruptionOffer) =>
      (includeNotCalled && callState === CallState.NotCalled) ||
      callState === CallState.InProcess ||
      (callState === CallState.Success && !!offer)
  );

export const isAirDisruptionValid = (includeNotCalled: boolean) =>
  createSelector(
    getFetchDisruptionOfferCallState,
    getAirDisruptionOffers,
    (callState: CallState, offers?: DisruptionOffer[]) =>
      (includeNotCalled && callState === CallState.NotCalled) ||
      callState === CallState.InProcess ||
      (callState === CallState.Success && !!offers?.length)
  );
