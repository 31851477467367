import styles from "./TravelCreditsOffer.module.scss";
import { ApacDesktopPopupModal, ApacMobileFullScreenModal } from "../";
import { useState } from "react";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import clsx from "clsx";
import { TravelCreditsOfferContent } from "./TravelCreditsOfferContent";

export const TravelCreditsOfferModalLink = ({
  bestOfferPercentage,
  children,
  className,
}: {
  bestOfferPercentage: number;
  children?: React.ReactNode;
  className?: string;
}) => {
  const { matchesMobile: isMobile } = useDeviceTypes();
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <button
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setShowModal(true);
          return false;
        }}
        className={clsx(styles["modal-button"], className)}
      >
        {children}
      </button>
      {showModal ? (
        isMobile ? (
          <ApacMobileFullScreenModal
            open={showModal}
            onClose={() => setShowModal(false)}
          >
            <TravelCreditsOfferContent
              bestOfferPercentage={bestOfferPercentage}
              onClose={() => setShowModal(false)}
            />
          </ApacMobileFullScreenModal>
        ) : (
          <ApacDesktopPopupModal
            open={showModal}
            className="desktop-rewards-details-popup"
            onClose={() => {
              setShowModal(false);
            }}
          >
            <TravelCreditsOfferContent
              bestOfferPercentage={bestOfferPercentage}
              onClose={() => setShowModal(false)}
            />
          </ApacDesktopPopupModal>
        )
      ) : null}
    </>
  );
};
