import * as actionTypes from "./constants";

export const getFlightById = (bookingId: string): IGetFlightById => ({
  type: actionTypes.GET_FLIGHT_BY_ID,
  bookingId,
});

export interface IGetFlightById {
  type: actionTypes.GET_FLIGHT_BY_ID;
  bookingId: string;
}

export const getFlightSummaries = (
  bookingId: string,
  isMobile: boolean
): IGetFLightSummariesReq => ({
  type: actionTypes.GET_FLIGHT_SUMMARIES,
  bookingId,
  isMobile,
});

export interface IGetFLightSummariesReq {
  type: actionTypes.GET_FLIGHT_SUMMARIES;
  bookingId: string;
  isMobile: boolean;
}
