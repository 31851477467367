import { useCallback } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";

import { IconName, IconComponent } from "@hopper-b2b/ui";
import { ITripTerminus } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useHistory } from "react-router";
import {
  OriginAutocomplete,
  DestinationAutocomplete,
} from "../../../FlightSearchControl/components/TerminusAutocomplete";
import { LocationSelectionConnectorProps } from "./container";
import "./styles.scss";

export interface ILocationSelectionProps
  extends LocationSelectionConnectorProps {
  isOrigin: boolean;
  onComplete?: () => void;
}

export const LocationSelection = (props: ILocationSelectionProps) => {
  const { isOrigin, origin, destination, onComplete, populateSearchUrlParams } =
    props;

  const { t } = useI18nContext();
  const history = useHistory();

  const onOriginSelected = useCallback(
    (value: ITripTerminus | null) => {
      if (value !== null && value.id.code.code !== destination?.id.code.code) {
        populateSearchUrlParams({ origin: value }, history);
        onComplete();
      }
    },
    [destination?.id.code.code, history, onComplete, populateSearchUrlParams]
  );

  const onDestinationSelected = useCallback(
    (value: ITripTerminus | null) => {
      if (value !== null && value.id.code.code !== origin?.id.code.code) {
        populateSearchUrlParams({ destination: value }, history);
        onComplete();
      }
    },
    [history, onComplete, origin?.id.code.code, populateSearchUrlParams]
  );

  return (
    <Box className="mobile-flight-location-selection">
      <Typography className="location-selection-subtitle" variant="subtitle2">
        {isOrigin
          ? t("commBank.search.flights.departingSubtitle")
          : t("commBank.search.flights.returningSubtitle")}
      </Typography>
      <Box className="mobile-location-pickers">
        {isOrigin ? (
          <OriginAutocomplete
            className="origin-auto-complete b2b mobile"
            customIcon={
              <IconComponent
                name={IconName.B2BMapPin}
                className={clsx("origin-icon")}
              />
            }
            label={t("searchControl.whereFrom")}
            value={origin}
            setValue={onOriginSelected}
            loadingText={`${t("searching")}...`}
            noOptionsText={t("noOptions")}
            getOptionSelected={(
              option: ITripTerminus | null,
              value: ITripTerminus | null
            ) => {
              return (
                !!value &&
                !!option &&
                value.id.code.code === option.id.code.code
              );
            }}
            popperClassName="mobile"
            autoFocus
            keepOpen
          />
        ) : (
          <DestinationAutocomplete
            className="destination-auto-complete b2b mobile"
            customIcon={
              <IconComponent
                name={IconName.B2BAirplaneIcon}
                className={clsx("destination-icon")}
              />
            }
            label={t("searchControl.whereTo")}
            loadingText={`${t("searching")}...`}
            noOptionsText={t("noOptions")}
            value={destination}
            setValue={onDestinationSelected}
            getOptionSelected={(
              option: ITripTerminus | null,
              value: ITripTerminus | null
            ) => {
              return (
                !!value &&
                !!option &&
                value.id.code.code === option.id.code.code
              );
            }}
            popperClassName="mobile"
            autoFocus
            keepOpen
          />
        )}
      </Box>
    </Box>
  );
};
