import {
  FlightAlertKey,
  ShopFilter,
  SliceStopCountFilter,
  TripCategory,
} from "@hopper-b2b/types";
import { PATH_FLIGHTS_SHOP } from "@hopper-b2b/utilities";
import queryStringParser from "query-string";

type FlightAlertLinkGenerator = (key: FlightAlertKey) => {
  pathname: string;
  search: string;
};

export const flightAlertLinkGenerator: FlightAlertLinkGenerator = (key) => {
  let stopsOption = SliceStopCountFilter.ANY_NUMBER;
  let noLCC = false;
  switch (key.filter) {
    case ShopFilter.NoLCC:
      noLCC = true;
      break;
    case ShopFilter.NonStop:
      stopsOption = SliceStopCountFilter.NONE;
      break;
    case ShopFilter.NonStopNoLCC:
      stopsOption = SliceStopCountFilter.NONE;
      noLCC = true;
      break;
    default:
      break;
  }
  const newQuery = {
    tripCategory: key.returnDate
      ? TripCategory.ROUND_TRIP
      : TripCategory.ONE_WAY,
    origin: key.origin.code,
    destination: key.destination.code,
    departureDate: key.departureDate,
    returnDate: key.returnDate,
    isFromFlightWatch: true,
    stopsOption,
    noLCC,
  };

  return {
    pathname: `/${PATH_FLIGHTS_SHOP}`,
    search: queryStringParser.stringify(newQuery),
  };
};
