// Imported as UberMyTripsMobileCardNew
import { useMemo } from "react";
import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

import {
  PortalItineraryStatusEnum,
  TagColors,
  TagInfo,
  ItineraryEnum,
  ScheduleChangeSeverity,
} from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { getEnvVariables } from "@hopper-b2b/utilities";

import { ButtonWrap, IconComponent, IconName, StatusTag } from "../../../index";
import "./styles.scss";

export interface MyTripsMobileCardProps {
  className?: string;
  startDate?: string;
  endDate?: string;
  flightDetails?: BookedFlightItineraryWithDepartureTime;
  onClick?: () => void;
  origin?: string;
  destination?: string;
  roundTrip?: boolean;
  expiryDate?: string;
  priceFreezeIcon?: string;
  expired?: boolean;
  type: ItineraryEnum;
  chevronIcon?: string;
}

export const MyTripsMobileCard = ({
  className,
  startDate,
  endDate,
  flightDetails,
  onClick,
  origin,
  destination,
  roundTrip,
  expiryDate,
  priceFreezeIcon,
  expired,
  type,
  chevronIcon,
}: MyTripsMobileCardProps) => {
  const { t } = useI18nContext();

  const titleTag = useMemo((): TagInfo | undefined => {
    if (type === ItineraryEnum.Flight && flightDetails?.status) {
      switch (flightDetails.status) {
        case PortalItineraryStatusEnum.Confirmed:
          return {
            label: t("confirmed"),
            type: TagColors.GREEN,
          };
        case PortalItineraryStatusEnum.Pending:
          return {
            label: t("pending"),
            type: TagColors.GREY,
          };
        case PortalItineraryStatusEnum.Canceled:
          return {
            label: t("cancelled"),
            type: TagColors.RED,
          };
        case PortalItineraryStatusEnum.Modified:
          return {
            label: t("itineraryModified"),
            type:
              flightDetails.bookedItinerary.scheduleChange?.severity ===
              ScheduleChangeSeverity.Major
                ? TagColors.RED
                : TagColors.YELLOW,
          };
        default:
          return undefined;
      }
    }
    if (type === ItineraryEnum.AirPriceFreeze) {
      return {
        label: t("priceFreezeTitle"),
        type: TagColors.BLUE,
      };
    }
    if (type === ItineraryEnum.Watch) {
      return {
        label: t("priceWatchButton"),
        type: TagColors.BLACK,
      };
    }
    return undefined;
  }, [flightDetails, t, type]);

  return (
    <ButtonWrap
      className={clsx(
        "my-trips-mobile-card",
        "new",
        getEnvVariables("clientName"),
        className
      )}
      onClick={onClick}
    >
      {expiryDate ? (
        <div className="my-trips-mobile-card-left">
          <div className="my-trips-mobile-card-image-container">
            {priceFreezeIcon ? (
              <img src={priceFreezeIcon} />
            ) : (
              <IconComponent
                className="my-trips-mobile-card-image"
                name={IconName.B2bPriceFreeze}
              />
            )}
          </div>
        </div>
      ) : null}
      <div
        className={clsx("my-trips-mobile-card-right", {
          "no-price-freeze": !expiryDate,
        })}
      >
        <div className="my-trips-mobile-card-info">
          {titleTag ? (
            <StatusTag className="title-status-tag" tagInfo={titleTag} />
          ) : null}
          <div className="my-trips-mobile-card-location">{destination}</div>
          <div className="my-trips-mobile-card-dates">
            {roundTrip ? `${startDate} - ${endDate}` : startDate}
          </div>
          {expiryDate ? (
            <div
              className={clsx(
                "my-trips-mobile-card-details",
                "my-trips-mobile-card-expiry",
                { expired }
              )}
            >
              {expiryDate}
            </div>
          ) : (
            <div className="my-trips-mobile-card-details">
              {t("tripCategoryFromOrigin", {
                tripCategory: roundTrip ? t("roundTrip") : t("oneWay"),
                origin,
              })}
            </div>
          )}
        </div>
        {expired ? null : (
          <div className="my-trips-mobile-card-icon-container">
            {/* Position of status tag depends on tenant design preferences - this tag is hidden by default */}
            {titleTag ? (
              <StatusTag
                className="title-status-tag hidden"
                tagInfo={titleTag}
              />
            ) : null}
            {chevronIcon ? (
              <img alt="" src={chevronIcon} className="chevron-icon" />
            ) : (
              <FontAwesomeIcon
                className="chevron-icon"
                icon={faChevronRight as IconProp}
              />
            )}
          </div>
        )}
      </div>
    </ButtonWrap>
  );
};
