import { ItinerarySeats, PriceQuoteData } from "@b2bportal/air-booking-api";

import {
  CarsCancellationPolicy,
  TimeAndPlace,
  Vehicle,
} from "../../cars-module";
import {
  Assistance,
  CountryCode,
  Gender,
  HotelLoyaltyNumber,
} from "../../common";
import { PriceQuote, Reservation } from "../../hotels-module";
import { PriceQuoteScheduleRequest } from "../../hotels-module/interfaces";
import { TripPricingSummary } from "../../trips-module/itinerary";
import { ScheduleChange } from "../../trips-module";
import {
  PriceFreeze,
  Offer,
  PriceFreezeItinerary,
  ID,
  PriceFreezeTripDetails,
  PriceFreezeFrozenFare,
} from "./price-freeze";
import { Seats, GordianSeatSegment } from "./seats";

export interface Adult {
  PassengerType: PassengerTypeEnum;
}

export interface AdultPrivate {
  PassengerType: PassengerTypeEnum;
}

export interface AdultWithLapInfant {
  adult: AssociatedPassenger;
  infant: AssociatedPassenger;
}

export interface AgeChild {
  ageCode: string;
  PassengerType: PassengerTypeEnum;
}

export interface AgelessChild {
  PassengerType: PassengerTypeEnum;
}

export interface AgelessChildPrivate {
  PassengerType: PassengerTypeEnum;
}

export interface AgentLocator {
  unscopedValue: string;
}

export interface Airport {
  RegionType: RegionTypeEnum;
}

export interface AirportRegion {
  regionType: RegionType;
  code: string;
}

export interface Allowed {
  allowance: BaggageAllowance;
  charges: BaggageCharge[];
  BaggageInfo: BaggageInfoEnum;
}

export interface AlreadyDone {
  Reason: ReasonEnum;
}

export interface AmadeusBrand {
  brandId: string;
  brandName?: string;
}

export interface Amount {
  amount: number;
  currency: string;
}

export enum AssistanceEnum {
  WCOB,
  WCMP,
  WCHS,
  WCLB,
  STCR,
  WCHC,
  WCBD,
  WCBW,
  DEAF,
  WCHR,
  BLND,
}

export interface AssociatedContact {
  nameNumber: NameNumber;
  contact: Contact;
}

export interface AssociatedPassenger {
  nameNumber: NameNumber;
  type: PassengerType;
  person: Person;
}

export type Availability =
  | Available
  | AvailableAfter
  | AvailableUntil
  | Unavailable;

export enum AvailabilityEnum {
  Available,
  AvailableAfter,
  AvailableUntil,
  Unavailable,
}

export interface Available {
  Availability: AvailabilityEnum;
}

export interface AvailableAfter {
  time: string;
  Availability: AvailabilityEnum;
}

export interface AvailableUntil {
  time: string;
  Availability: AvailabilityEnum;
}

export interface BLND {
  Assistance: AssistanceEnum;
}

export interface BaggageAllowance {
  pieces: number;
  descriptions: string[];
  maximumMass?: Mass;
}

export interface BaggageCharge {
  amount: number;
  currency: string;
  firstPiece: number;
  lastPiece: number;
  descriptions: string[];
  maximumMass?: Mass;
}

export type BaggageInfo = Allowed | NotAllowed | Unknown;

export enum BaggageInfoEnum {
  Allowed,
  NotAllowed,
  Unknown,
}

export enum TransactionStoreIdEnum {
  PaymentFulfillmentService = "PaymentFulfillmentService",
  PaymentMachine = "PaymentMachine",
}
export interface PaymentFulfillmentService {
  value: string;
  ID: TransactionStoreIdEnum;
}
export interface PaymentMachine {
  token: PaymentSessionToken;
  ID: TransactionStoreIdEnum;
}

export type TransactionStoreId = PaymentFulfillmentService | PaymentMachine;

export interface BookedFlightItinerary {
  id: string;
  faresRules: FareRules;
  totalPayment: PaymentAmount;
  sellingPricing: TripPricingSummary;
  restrictions?: Restrictions;
  passengers: SeatedPassengers;
  payment?: Payment;
  travelItinerary: TravelItinerary;
  freeCancellation: Availability;
  scheduleChange?: ScheduleChange;
  transactionStoreId?: TransactionStoreId;
  seats: ItinerarySeats;
}

export interface BookingSessionClosed extends ErrorCode {
  message: string;
  PaymentError: PaymentErrorEnum.BookingSessionClosed;
}

export interface BookingSessionMissing extends ErrorCode {
  message: string;
  PaymentError: PaymentErrorEnum.BookingSessionMissing;
}

export interface BookingSessionRefused extends ErrorCode {
  message: string;
  PaymentError: PaymentErrorEnum.BookingSessionRefused;
}

export interface Cancelled {
  PostTicketingStatus: PostTicketingStatusEnum;
}

export interface CancelledSegmentStatus {
  FlightItinerarySegmentStatus: FlightItinerarySegmentStatusEnum;
}

export interface CannotResume extends ErrorCode {
  PaymentError: PaymentErrorEnum.CannotResume;
}

export interface CardDeclined extends ErrorCode {
  PaymentError: PaymentErrorEnum.CardDeclined;
}

export interface City {
  RegionType: RegionTypeEnum;
}

export interface Confirmed {
  FlightItinerarySegmentStatus: FlightItinerarySegmentStatusEnum;
}

export interface ConfirmedPendingNewChange {
  FlightItinerarySegmentStatus: FlightItinerarySegmentStatusEnum;
}

export type ConnectionResult = Invalid | Valid;

export interface Valid {
  token: PaymentSessionToken;
  ConnectionResult: ConnectionResultEnum;
}

export interface ConnectionResultType {
  ConnectionResult: ConnectionResultEnum;
}

export enum ConnectionResultEnum {
  Invalid,
  Valid,
}

export interface Contact {
  phoneNumber: string;
  emailAddress: string;
  givenName?: string;
  surname?: string;
}

export interface ContactAirline {
  Reason: ReasonEnum;
}

export interface ContactSupport {
  display: string;
  phone: string;
  displayPhone: string;
}

export interface CreditCard {
  id: string;
  token: string;
  firstName: string;
  lastName: string;
  cardType: string;
  numberDisplay: string;
  month: number;
  year: number;
  zip: string;
  country: string;
  alias?: string;
  createdAt: string;
  updatedAt?: string;
  scanned: string;
  last4?: string;
  bin?: string;
  Payment: PaymentEnum;
}

export type CustomerValidationData =
  | FlightCustomerValidationData
  | FreezeFlightCustomerValidationData;

export enum CustomerValidationDataEnum {
  FlightCustomerValidationData = "FlightCustomerValidationData",
  FreezeFlightCustomerValidationData = "FreezeFlightCustomerValidationData",
}

export interface DEAF {
  Assistance: AssistanceEnum;
}

export interface Declined {
  FlightItinerarySegmentStatus: FlightItinerarySegmentStatusEnum;
}

export interface DriverLicense {
  country: CountryCode;
  cityCode: string;
  street: string;
  postCode: string;
}

export interface ErrorCode {
  code?: string;
  cause?: string;
  message?: string;
  msg?: string;
  PaymentError: PaymentErrorEnum;
}

export interface ExternallyManaged {
  code: string;
  PenaltiesInfo: PenaltiesInfoEnum;
}

export interface FareBasis {
  code: string;
}

export interface FareBrand {
  sabreFareBrand?: SabreBrand;
  travelportFareBrand?: TravelportBrand;
  gdxFareBrand?: GdxBrand;
  amadeusFareBrand?: AmadeusBrand;
}

export interface FareRule {
  title: string;
  text: string;
}

export interface FareRuleIndex {
  fareBasisCode: FareBasis;
  segment: number;
}

export interface FareRules {
  rules: ItineraryFareRules[];
}

export interface FiatAmount {
  currency: string;
  amount: number;
  PaymentAmount: PaymentAmountEnum;
}

export interface FiatPrice {
  currencyCode: string;
  currencySymbol: string;
  value: number;
}

export interface FlightCustomerValidationData {
  passengerValidation: PassengersResponse;
  CustomerValidationData: CustomerValidationDataEnum.FlightCustomerValidationData;
}

export interface FreezeFlightCustomerValidationData {
  CustomerValidationData: CustomerValidationDataEnum.FreezeFlightCustomerValidationData;
}

export interface FlightItinerarySegment {
  status: FlightItinerarySegmentStatus;
  bookingClassCode: string;
  stops: number;
  segmentNumber: number;
  scheduledDeparture: string;
  scheduledArrival: string;
  equipmentType?: string;
  destination: Location;
  origin: Location;
  marketingAirline: SegmentAirlineInfo;
  operatingAirline?: SegmentAirlineInfo;
  fareBrand?: FareBrand;
}

export type FlightItinerarySegmentStatus =
  | Confirmed
  | Pending
  | Declined
  | UnMapped
  | CancelledSegmentStatus
  | ConfirmedPendingNewChange
  | UnMappedPersisted;

export enum FlightItinerarySegmentStatusEnum {
  Confirmed,
  Pending,
  Declined,
  UnMapped,
  Cancelled,
  ConfirmedPendingNewChange,
  UnMappedPersisted,
}

export interface FlightItinerarySlice {
  segments: FlightItinerarySegment[];
  restrictions?: Restrictions;
}

export interface FlightPaymentFinalizeResult {
  purchaseResult: PurchaseResult;
  PaymentFinalizeResult: PaymentFinalizeResultEnum.FlightPaymentFinalizeResult;
}

export interface PriceFreezeFlightPaymentFinalizeResult {
  priceFreeze: PriceFreeze;
  attachedEmail: string;
  frozenFlight: PriceFreezeTripDetails;
  frozenFare: PriceFreezeFrozenFare;
  PaymentFinalizeResult: PaymentFinalizeResultEnum.PriceFreezeFlightPaymentFinalizeResult;
}

export interface FlightPriceQuoteData {
  flightQuoteData: PriceQuoteData;
  PaymentPriceQuoteData: PaymentPriceQuoteDataEnum.FlightPriceQuoteData;
}

export interface PriceFreezePriceQuote {
  configurationId: string;
  offer: Offer;
  frozenPricing: FrozenPricing;
  totalAmount: Prices;
  perPaxAmount: Prices;
}

export interface FrozenPricing {
  singlePassenger: Prices;
  passengerQuantity: number;
}

export interface PriceFreezeFlightPriceQuoteData {
  quoteData: PriceFreezePriceQuote;
  PaymentPriceQuoteData: PaymentPriceQuoteDataEnum.PriceFreezeFlightPriceQuoteData;
}

export interface HotelPriceQuoteData {
  hotelQuoteData: PriceQuote;
  PaymentPriceQuoteData: PaymentPriceQuoteDataEnum.HotelPriceQuoteData;
}

export interface FlightPriceQuoteRequest {
  flightQuoteRequest: SchedulePriceQuoteRequest;
  quoteDescription: string;
  PaymentPriceQuoteRequest: PaymentPriceQuoteRequestEnum.FlightPriceQuoteRequest;
}

export interface PriceFreezeFlightPriceQuoteRequest {
  quoteRequest: PriceFreezeSchedulePriceQuoteRequest;
  PaymentPriceQuoteRequest: PaymentPriceQuoteRequestEnum.PriceFreezeFlightPriceQuoteRequest;
}

export interface FlightCreditSummary {
  pricingBeforeCredits: TripPricingSummary;
  credits: CreditSummary;
}

export interface PriceFreezeExerciseTripCredit {
  priceFreezeId: ID;
  frozenPaxCount: number;
  offerFrozenPerPaxPrice: Prices;
  offerPerPaxCap?: Prices;
  perFrozenPaxSavings: Prices;
  creditAmount: Prices;
}

export interface TripCredits {
  priceFreezeExerciseCredit?: PriceFreezeExerciseTripCredit;
}

export interface PriceFreezeExercisePersonCredit {
  creditAmount: Prices;
}

export interface PersonCredits {
  personId: string;
  priceFreezeExerciseCredit?: PriceFreezeExercisePersonCredit;
}

export interface CreditSummary {
  totalCredits: TripCredits;
  creditsByPerson: PersonCredits[];
}

export interface FraudAutoReject extends ErrorCode {
  PaymentError: PaymentErrorEnum.FraudAutoReject;
}

export interface FrequentFlyerNumber {
  value: string;
}

export interface GdxBrand {
  brandId: string;
  brandName?: string;
}

export interface GetPaymentFailed {
  errors: MachineError[];
  result: GetPaymentResultEnum;
}

export interface GetPaymentPending {
  result: GetPaymentResultEnum;
}

export enum GetPaymentResultEnum {
  Succeeded = "Succeeded",
  Pending = "Pending",
  Failed = "Failed",
}

export interface InfantNotIssued {
  TicketNumberType: TicketNumberTypeEnum;
}

export interface InstallmentPaymentFailed extends ErrorCode {
  PaymentError: PaymentErrorEnum.InstallmentPaymentFailed;
}

export interface Invalid extends ConnectionResultType {
  Invalid: InvalidEnum;
}

export enum InvalidEnum {
  Closed = "Closed",
  ExcessiveActivity = "ExcessiveActivity",
  Missing = "Missing",
}

export interface ConnectionResultType {
  ConnectionResult: ConnectionResultEnum;
}
export interface InvalidCustomer extends ErrorCode {
  code: string;
  message: string;
  PaymentError: PaymentErrorEnum.InvalidCustomer;
}

export interface LapInfantsUnsupported extends ErrorCode {
  code: PaymentErrorEnum.LapInfantsUnsupported;
  msg: string;
  PaymentError: PaymentErrorEnum.InvalidCustomer;
}

export interface InvalidPassengers {
  errors: PassengerError[];
  PassengersResponse: PassengersResponseEnum;
}

export interface ItineraryFareRules {
  fareRuleIndex: FareRuleIndex;
  rules: FareRule[];
}

export interface Known {
  changePenalty: Penalty;
  cancelPenalty: Penalty;
  PenaltiesInfo: PenaltiesInfoEnum;
}

export interface LapInfant {
  PassengerType: PassengerTypeEnum;
}

export interface LapInfantPrivate {
  PassengerType: PassengerTypeEnum;
}

export interface Location {
  locationCode: string;
  terminalName?: string;
  terminalCode?: string;
}

export type MachineError = PaymentError;

export interface Mass {
  value: number;
  unit: string;
}

export interface Modified {
  PostTicketingStatus: PostTicketingStatusEnum;
}

export interface MoveToQueue {
  ScheduleUserDenyFlow: ScheduleUserDenyFlowEnum;
}

export interface MultiProvider {
  agent: AgentLocator;
  children: SingleProvider[];
}

export interface MultiTravelItinerary {
  locators?: MultiProvider;
  travelItineraries: SingleTravelItinerary[];
  TravelItinerary: TravelItineraryEnum;
}

export interface NameNumber {
  value: string;
}

export interface Nationality {
  country: CountryCode;
}

export interface NoAvailability extends ErrorCode {
  PaymentError: PaymentErrorEnum.NoAvailability;
}

export interface NoPenalty {
  Penalty: PenaltyEnum;
}

export interface NotAllowed {
  BaggageInfo: BaggageInfoEnum;
}

export interface NotAllowed {
  Reason: ReasonEnum;
}

export interface NotPossible {
  Penalty: PenaltyEnum;
}

export interface OfferInvalid extends ErrorCode {
  message: string;
  PaymentError: PaymentErrorEnum.OfferInvalid;
}

export interface PassengerError {
  code: string;
  message: string;
}

export interface PassengerTripPricing {
  currency: string;
  baseWithoutMargin: Prices;
  taxes: Prices;
  passengerFee: Prices;
  additionalMargin?: Prices;
  person: Person;
  seats: Seat[];
  subtotal: Prices;
  total: Prices;
  passengerType: PassengerType;
  // TODO: the actual model has yet to be decided by the BE
  ancillaries?: any[];
}

export type PassengerType =
  | Adult
  | AgeChild
  | LapInfantPrivate
  | SeatedInfantPrivate
  | AgelessChild
  | SeatedInfant
  | AgelessChildPrivate
  | AdultPrivate
  | LapInfant;

export enum PassengerTypeEnum {
  Adult,
  AgeChild,
  LapInfantPrivate,
  SeatedInfantPrivate,
  AgelessChild,
  SeatedInfant,
  AgelessChildPrivate,
  AdultPrivate,
  LapInfant,
}

export interface PassengersOk {
  PassengersResponse: PassengersResponseEnum;
}

export type PassengersResponse = InvalidPassengers | PassengersOk;

export enum PassengersResponseEnum {
  InvalidPassengers = "InvalidPassengers",
  PassengersOk = "PassengersOk",
}

export interface Passport {
  countryOfIssue: CountryCode;
  number: string;
  expiration: string;
}

export enum TypeOfPaymentEnum {
  Rewards = "Rewards",
  Split = "Split",
  UserCard = "UserCard",
}
export interface SplitPaymentType {
  accountReferenceId?: string;
  paymentAmount: SplitAmount;
  paymentId: string;
  Payment: TypeOfPaymentEnum;
}
export interface UserCardPaymentType {
  accountReferenceId?: string;
  paymentAmount: FiatAmount;
  paymentId: string;
  Payment: TypeOfPaymentEnum;
}
export interface RewardsPaymentType {
  paymentAmount: RewardsAmount;
  Payment: TypeOfPaymentEnum;
}

export type PaymentType =
  | RewardsPaymentType
  | SplitPaymentType
  | UserCardPaymentType;

export type Payment = CreditCard;

export type PaymentAmount = RewardsAmount | FiatAmount | SplitAmount;

export enum PaymentAmountEnum {
  RewardsAmount = "RewardsAmount",
  FiatAmount = "FiatAmount",
  SplitAmount = "SplitAmount",
}

export enum PaymentEnum {
  CreditCard = "CreditCard",
}

export type PaymentError =
  | PollingTimeout
  | BookingSessionClosed
  | InvalidCustomer
  | LapInfantsUnsupported
  | BookingSessionMissing
  | ErrorCode
  | TicketingUnconfirmed
  | TicketingNotStarted
  | NoAvailability
  | InstallmentPaymentFailed
  | CardDeclined
  | TicketingUnknown
  | OfferInvalid
  | BookingSessionRefused
  | CannotResume
  | WithTransaction
  | FraudAutoReject
  | UpgradeRequired
  | UnsupportedPaymentKind
  | PriceUnavailableUnder25
  | QuoteError
  | BookError;

export interface PriceUnavailableUnder25 extends ErrorCode {
  message: string;
  PaymentError: PaymentErrorEnum.PriceUnavailableUnder25;
}

export interface QuoteError extends ErrorCode {
  msg: string;
  PaymentError: PaymentErrorEnum.QuoteError;
}

export interface BookError extends ErrorCode {
  msg: string;
  PaymentError: PaymentErrorEnum.BookError;
}

export enum PaymentErrorEnum {
  PollingTimeout = "PollingTimeout",
  BookingSessionClosed = "BookingSessionClosed",
  InvalidCustomer = "InvalidCustomer",
  InvalidPassengers = "InvalidPassengers",
  RedemptionFailure = "RedemptionFailure",
  BookingSessionMissing = "BookingSessionMissing",
  ErrorCode = "ErrorCode",
  TicketingUnconfirmed = "TicketingUnconfirmed",
  TicketingNotStarted = "TicketingNotStarted",
  NoAvailability = "NoAvailability",
  InstallmentPaymentFailed = "InstallmentPaymentFailed",
  CardDeclined = "CardDeclined",
  TicketingUnknown = "TicketingUnknown",
  OfferInvalid = "OfferInvalid",
  BookingSessionRefused = "BookingSessionRefused",
  CannotResume = "CannotResume",
  WithTransaction = "WithTransaction",
  FraudAutoReject = "FraudAutoReject",
  UpgradeRequired = "UpgradeRequired",
  UnsupportedPaymentKind = "UnsupportedPaymentKind",
  QuoteError = "QuoteError",
  BookError = "BookError",
  PriceUnavailableUnder25 = "PriceUnavailableUnder25",
  VccGenerationFailure = "VccGenerationFailure",
  LikelyFraud = "LikelyFraud",
  NoAdultPassenger = "NoAdultPassenger",
  IllegalLapInfantRedress = "IllegalLapInfantRedress",
  IllegalLapInfantKTN = "IllegalLapInfantKTN",
  LapInfantTooOld = "LapInfantTooOld",
  TooManyLapInfants = "TooManyLapInfants",
  NoContactInformation = "NoContactInformation",
  TooManyPassengers = "TooManyPassengers",
  MalformedRedressNumber = "MalformedRedressNumber",
  MalformedKnownTravelerNumber = "MalformedKnownTravelerNumber",
  LapInfantsUnsupported = "LapInfantsUnsupported",
  SeatedInfantsUnsupported = "SeatedInfantsUnsupported",
  ExpiredPassport = "ExpiredPassport",
  MissingPassport = "MissingPassport",
  MissingNationality = "MissingNationality",
  UnsupportedNameCharacters = "UnsupportedNameCharacters",
}

export type PaymentFinalizeSucceeded =
  | CarPaymentFinalizeResultSucceeded
  | HotelPaymentFinalizeResultSucceeded
  | FlightPaymentFinalizeResultSucceeded
  | PriceFreezeFlightPaymentFinalizeResultSucceeded;

export enum PaymentFinalizeResultEnum {
  FlightPaymentFinalizeResult = "FlightPaymentFinalizeResult",
  HotelPaymentFinalizeResult = "HotelPaymentFinalizeResult",
  GroundPaymentFinalizeResult = "GroundPaymentFinalizeResult",
  PriceFreezeFlightPaymentFinalizeResult = "PriceFreezeFlightPaymentFinalizeResult",
}
export type PaymentQuoteResult =
  | GetPaymentFailed
  | GetPaymentPending
  | PaymentQuoteValidationDataSucceeded;

export type PaymentFinalizeResult =
  | GetPaymentFailed
  | GetPaymentPending
  | PaymentFinalizeSucceeded;

export interface CarPaymentFinalizeResultSucceeded
  extends CarPaymentFinalizeResult {
  result: GetPaymentResultEnum;
}

export interface HotelPaymentFinalizeResultSucceeded
  extends HotelPaymentFinalizeResult {
  result: GetPaymentResultEnum;
}
export interface FlightPaymentFinalizeResultSucceeded
  extends FlightPaymentFinalizeResult {
  result: GetPaymentResultEnum;
}

export interface PriceFreezeFlightPaymentFinalizeResultSucceeded
  extends PriceFreezeFlightPaymentFinalizeResult {
  result: GetPaymentResultEnum;
}

export interface PaymentKind {
  display: string;
  sabre: string;
  spreedly: string;
}

export type PaymentPriceQuoteData =
  | FlightPriceQuoteData
  | PriceFreezeFlightPriceQuoteData
  | HotelPriceQuoteData
  | CarPriceQuoteData;

export enum PaymentPriceQuoteDataEnum {
  FlightPriceQuoteData = "FlightPriceQuoteData",
  PriceFreezeFlightPriceQuoteData = "PriceFreezeFlightPriceQuoteData",
  HotelPriceQuoteData = "HotelPriceQuoteData",
  CarPriceQuoteData = "GroundPriceQuoteData",
}

export type PaymentPriceQuoteRequest =
  | FlightPriceQuoteRequest
  | PriceFreezeFlightPriceQuoteRequest;

export enum PaymentPriceQuoteRequestEnum {
  FlightPriceQuoteRequest = "FlightPriceQuoteRequest",
  PriceFreezeFlightPriceQuoteRequest = "PriceFreezeFlightPriceQuoteRequest",
  HotelPriceQuoteRequest = "HotelPriceQuoteRequest",
  CarPriceQuoteRequest = "GroundPriceQuoteRequest",
}

export interface PaymentQuoteValidationDataSucceeded
  extends PaymentQuoteValidationData {
  result: GetPaymentResultEnum;
}

export interface PaymentQuoteValidationData {
  quote: PaymentPriceQuoteData;
  validation: CustomerValidationData;
  token: PaymentSessionToken;
  transactionId: TransactionId;
}

export interface PaymentSessionToken {
  value: string;
}

export interface PaymentSplitRequest {
  token: PaymentSessionToken;
  payment: PaymentType;
  ancillaries: PostQuoteAncillaries[];
}

export type PostQuoteAncillaries = Seats;

export enum PostQuoteAncillariesEnum {
  Seats = "Seats",
}

export enum PaymentSplitRequestEnum {
  PaymentCardRequest = "PaymentCardRequest",
  PaymentCardRewardsRequest = "PaymentCardRewardsRequest",
  PaymentRewardsRequest = "PaymentRewardsRequest",
}

export type PenaltiesInfo = ExternallyManaged | Known | Unknown;

export enum PenaltiesInfoEnum {
  ExternallyManaged = "ExternallyManaged",
  Known = "Known",
  Unknown = "Unknown",
}

export type Penalty = NoPenalty | NotPossible | Unknown | WithFee;

export enum PenaltyEnum {
  NoPenalty = "NoPenalty",
  NotPossible = "NotPossible",
  Unknown = "Unknown",
  WithFee = "WithFee",
}

export interface Pending {
  FlightItinerarySegmentStatus: FlightItinerarySegmentStatusEnum;
}

export interface Person {
  id: string;
  lastBookedWith?: string;
  givenName: string;
  middleName?: string;
  surname: string;
  gender: Gender;
  dateOfBirth: string;
  phoneNumber?: string;
  emailAddress?: string;
  redressNumber?: string;
  knownTravelerNumber?: string;
  assistance: Assistance[];
  createdAt: string;
  nationality?: Nationality;
  passport?: Passport;
  frequentFlyer: { [key: string]: FrequentFlyerNumber };
  hotelLoyalty: { [key: string]: HotelLoyaltyNumber };
  driverLicense?: DriverLicense;
  type?: string;
}

export interface PollingTimeout extends ErrorCode {
  PaymentError: PaymentErrorEnum.PollingTimeout;
}

export type PostTicketingStatus = Cancelled | Modified | Rejected | Ticketed;

export enum PostTicketingStatusEnum {
  Cancelled = "Cancelled",
  Modified = "Modified",
  Rejected = "Rejected",
  Ticketed = "Ticketed",
}

export interface PricedFlightItinerary {
  id: string;
  faresRules: FareRules;
  sellingPricing: TripPricingSummary;
  restrictions?: Restrictions;
  guaranteedTicketing?: boolean;
  bookingProviderName: string;
}

export interface Prices {
  fiat: FiatPrice;
  rewards: { [key: string]: RewardsPrice };
}

export interface PurchaseResult {
  itinerary: BookedFlightItinerary;
}

export interface PurchaseTimeDiscount {
  discount: Prices;
  lineItemName: string;
}

export type Reason = AlreadyDone | ContactAirline | ContactSupport | NotAllowed;

export enum ReasonEnum {
  AlreadyDone = "AlreadyDone",
  ContactAirline = "ContactAirline",
  ContactSupport = "ContactSupport",
  NotAllowed = "NotAllowed",
}

export interface ReferToAirline {
  ScheduleUserDenyFlow: ScheduleUserDenyFlowEnum;
}

export type RegionType = Airport | City;

export enum RegionTypeEnum {
  Airport = "Airport",
  City = "City",
}

export interface Rejected {
  PostTicketingStatus: PostTicketingStatusEnum;
}

export interface Restrictions {
  penaltiesInfo: PenaltiesInfo;
  baggageInfo: BaggageInfo;
  seatMapInfoAvailable: boolean;
}

export interface RewardsAmount {
  rewardsAccountId: string;
  fiatValue: Amount;
  rewardsPrice: RewardsPrice;
  PaymentAmount: PaymentAmountEnum;
}

export interface RewardsPrice {
  currency: string;
  value: number;
}

export interface Route {
  origin: AirportRegion;
  destination: AirportRegion;
}

export interface STCR {
  Assistance: AssistanceEnum;
}

export interface SabreBrand {
  brandId: string;
  brandName?: string;
}

export type PriceDropCandidateId = string;

export interface SchedulePriceQuoteRequest {
  tripId: string;
  seatedPassengers: string[];
  lapInfants: string[];
  fareId: string;
  ancillaryIds: Array<ProtectionId>;
  emailAddress: string;
  phoneNumber: string;
  delegatedTo?: string;
  priceDropCandidateId?: PriceDropCandidateId;
}

export interface PriceFreezeSchedulePriceQuoteRequest {
  offerId: ID;
  itinerary: PriceFreezeItinerary;
  passengers: { [key: string]: number };
  emailAddress: string;
  phoneNumber: string;
}

export type SchedulePriceQuoteResponse = ErrorCode | SchedulePriceQuoteSuccess;

export interface SchedulePriceQuoteSuccess {
  value: ConnectionResult;
}

export interface SchedulePaymentRequest {
  token: string;
  paymentId: string;
}

export type SchedulePaymentResponse = ErrorCode | SchedulePaymentSuccess;

export interface SchedulePaymentSuccess {
  value: ConnectionResult;
}

export type ScheduleUserDenyFlow = MoveToQueue | ReferToAirline;

export enum ScheduleUserDenyFlowEnum {
  MoveToQueue,
  ReferToAirline,
}

export interface Seat {
  amount: Prices;
  id: string;
}

export interface SeatSlice {
  segments: GordianSeatSegment[];
}

export interface SeatedInfant {
  PassengerType: PassengerTypeEnum;
}

export interface SeatedInfantPrivate {
  PassengerType: PassengerTypeEnum;
}

export interface SeatedPassengers {
  withLapInfants: AdultWithLapInfant[];
  alone: AssociatedPassenger[];
  contact: AssociatedContact;
}

export interface SeatsInfo {
  slices: SeatSlice[];
}

export interface SegmentAirlineInfo {
  code: string;
  flightNumber: number;
  locator?: string;
}

export interface SingleProvider {
  agent: AgentLocator;
}

export interface SingleTravelItinerary {
  locators?: SingleProvider;
  slices: FlightItinerarySlice[];
  ticketing: Ticketing[];
  postTicketingStatus?: PostTicketingStatus;
  TravelItinerary: TravelItineraryEnum;
}

export interface SplitAmount {
  fiatAmount: FiatAmount;
  rewardsAmount: RewardsAmount;
  PaymentAmount: PaymentAmountEnum;
}

export interface TicketNumber {
  number: string;
  TicketNumberType: TicketNumberTypeEnum;
}

export type TicketNumberType = InfantNotIssued | TicketNumber;

export enum TicketNumberTypeEnum {
  InfantNotIssued = "InfantNotIssued",
  TicketNumber = "TicketNumber",
}

export interface Ticketed {
  PostTicketingStatus: PostTicketingStatusEnum;
}

export interface Ticketing {
  eTicketNumber: TicketNumberType;
}

export interface TicketingNotStarted extends ErrorCode {
  PaymentError: PaymentErrorEnum.TicketingNotStarted;
}

export interface TicketingUnconfirmed extends ErrorCode {
  PaymentError: PaymentErrorEnum.TicketingUnconfirmed;
}

export interface TicketingUnknown extends ErrorCode {
  PaymentError: PaymentErrorEnum.TicketingUnknown;
}

export interface TotalTripPricing {
  currency: string;
  baseWithoutMargin: Prices;
  taxes: Prices;
  subtotal: Prices;
  total: Prices;
  passengerFee: Prices;
  additionalMargin?: Prices;
  discounts: PurchaseTimeDiscount[];
  tripFee: Prices;
  seats: Seat[];
  // TODO: the actual model has yet to be decided by the BE
  ancillaries?: any[];
}

export interface TransactionId {
  transactionId: string;
}

export type TravelItinerary = MultiTravelItinerary | SingleTravelItinerary;

export enum TravelItineraryEnum {
  MultiTravelItinerary = "MultiTravelItinerary",
  SingleTravelItinerary = "SingleTravelItinerary",
}

export interface TravelportBrand {
  brandId: string;
  brandName?: string;
  brandTier?: number;
}

export interface UnMapped {
  FlightItinerarySegmentStatus: FlightItinerarySegmentStatusEnum;
}

export interface UnMappedPersisted {
  FlightItinerarySegmentStatus: FlightItinerarySegmentStatusEnum;
}

export interface Unavailable {
  reason: Reason;
  Availability: AvailabilityEnum;
}

export interface Unknown {
  PenaltiesInfo: PenaltiesInfoEnum;
}

export interface Unknown {
  BaggageInfo: BaggageInfoEnum;
}

export interface Unknown {
  Penalty: PenaltyEnum;
}

export interface UnsupportedPaymentKind extends ErrorCode {
  suppliedSpreedlyType: string;
  supportedKinds: PaymentKind[];
  PaymentError: PaymentErrorEnum.UnsupportedPaymentKind;
}

export interface UpgradeRequired extends ErrorCode {
  message: string;
  PaymentError: PaymentErrorEnum.UpgradeRequired;
}

export interface WCBD {
  Assistance: AssistanceEnum;
}

export interface WCBW {
  Assistance: AssistanceEnum;
}

export interface WCHC {
  Assistance: AssistanceEnum;
}

export interface WCHR {
  Assistance: AssistanceEnum;
}

export interface WCHS {
  Assistance: AssistanceEnum;
}

export interface WCLB {
  Assistance: AssistanceEnum;
}

export interface WCMP {
  Assistance: AssistanceEnum;
}

export interface WCOB {
  Assistance: AssistanceEnum;
}

export interface WithFee {
  amount: number;
  currency: string;
  Penalty: PenaltyEnum;
}

export interface WithTransaction extends ErrorCode {
  transactionId: TransactionId;
  PaymentError: PaymentErrorEnum.WithTransaction;
}

export enum PricingErrorEnum {
  PricingDataMissing = "PricingDataMissing",
}

export interface PricingErrorCode {
  code: PricingErrorEnum;
  details?: string;
}

export interface TripPricingSummaryResponseFailed {
  errors: PricingErrorCode[];
}

export enum ProtectionIdEnum {
  InsuranceId = "InsuranceId",
  LuggageId = "LuggageId",
  ProtectionPolicyId = "ProtectionPolicyId",
}

export enum ProtectionPolicyIdEnum {
  CancelOrChangeId = "CancelOrChangeId",
  MissedConnectionId = "MissedConnectionId",
  ChfarId = "ChfarId",
  DelayId = "DelayId",
  CfarId = "CfarId",
}

export type ProtectionId = InsuranceId | LuggageId | ProtectionPolicyId;

export interface InsuranceId {
  productId: string;
  ProtectionId: ProtectionIdEnum.InsuranceId;
}

export interface LuggageId {
  productId: string;
  ProtectionId: ProtectionIdEnum.LuggageId;
}

export type ProtectionPolicyId =
  | CancelOrChangeId
  | CfarId
  | ChfarId
  | DelayId
  | MissedConnectionId;

export interface CancelOrChangeId {
  productId: string;
  policyId: string;
  ProtectionPolicyId: ProtectionPolicyIdEnum.CancelOrChangeId;
  ProtectionId: ProtectionIdEnum.ProtectionPolicyId;
}

export interface CfarId {
  productId: string;
  policyId: string;
  ProtectionPolicyId: ProtectionPolicyIdEnum.CfarId;
  ProtectionId: ProtectionIdEnum.ProtectionPolicyId;
}

export interface ChfarId {
  productId: string;
  policyId: string;
  ProtectionPolicyId: ProtectionPolicyIdEnum.ChfarId;
  ProtectionId: ProtectionIdEnum.ProtectionPolicyId;
}

export interface DelayId {
  productId: string;
  policyId: string;
  ProtectionPolicyId: ProtectionPolicyIdEnum.DelayId;
  ProtectionId: ProtectionIdEnum.ProtectionPolicyId;
}

export interface MissedConnectionId {
  productId: string;
  policyId: string;
  ProtectionPolicyId: ProtectionPolicyIdEnum.MissedConnectionId;
  ProtectionId: ProtectionIdEnum.ProtectionPolicyId;
}

export interface PassengersForFareRequest {
  tripId: string;
  seatedPassengers: string[];
  lapInfants: string[];
  fareId: string;
  priceFreezeId?: string;
  ancillaryIds: ProtectionId[];
}

export interface HotelCustomerValidationData {
  CustomerValidationData: CustomerValidationDataEnum;
}

export interface LocationBookDateTime {
  dateTime: string;
  location: ResponseLocation;
}

export interface ResponseLocation {
  code: string;
  vendorCodeRef: string;
  name: string;
  address: string;
  country: string;
  city: string;
  stateProv?: StateProv;
  phoneNumber?: PhoneNumber;
  coordinates?: Coordinates;
  airportCode?: string;
}

export interface PhoneNumber {
  countryCode: number;
  nationalNumber: number;
  extension?: string;
}

export interface StateProv {
  code: string;
  name: string;
}

export interface Coordinates {
  lat: number;
  lon: number;
}
export interface GroundPaymentFinalizeResult {
  booking: GroundBookingResult;
  PaymentFinalizeResult: PaymentFinalizeResultEnum;
}

export enum State {
  PendingConfirm = "PendingConfirm",
  CanceledWithErrors = "CanceledWithErrors",
  Canceled = "Canceled",
  Failure = "Failure",
  Confirmed = "Confirmed",
  PartiallyPaid = "PartiallyPaid",
  Received = "Received",
}

export interface Vendor {
  code: string;
  name: string;
  contactSupport?: string;
}

export interface AdditionalInfo {
  comments?: string;
  pickUpService?: string;
  dropOffService?: string;
  sessionId?: ClientToken;
}

export type ClientToken = string;

export interface Supplier {
  code: string;
  name: string;
  logo: string;
  contactSupport?: string;
}

export enum ExtraKind {
  General = "General",
  Other = "Other",
  ExtraDriver = "ExtraDriver",
  Insurance = "Insurance",
  BabyOnBoard = "BabyOnBoard",
  InsuranceExternal = "InsuranceExternal",
  Amenities = "Amenities",
}

export interface Remark {
  key: string;
  value: string;
}

export interface Price {
  kind: PriceKind;
  amount: FiatPrice;
}
export interface Links {
  kind: LinkKind;
  link: string;
}

export enum LinkKind {
  PostBookingKeyFactsURI = "PostBookingKeyFactsURI",
  PreBookingKeyFactsURI = "PreBookingKeyFactsURI",
  PostBookingPolicyWordingURI = "PostBookingPolicyWordingURI",
  PreBookingPolicyWordingURI = "PreBookingPolicyWordingURI",
  PostBookingCertificateURI = "PostBookingCertificateURI",
  PostBookingDIPDanniCertificate = "PostBookingDIPDanniCertificate",
  PreBookingDIPDanniCertificate = "PreBookingDIPDanniCertificate",
}

export enum PriceKind {
  Extra = "Extra",
  ExtraDaily = "ExtraDaily",
  ExtraOriginal = "ExtraOriginal",
  TotalWithExtra = "TotalWithExtra",
}

export interface ExtraInfo {
  id: string;
  remarks: Remark[];
  name: string;
  kind: ExtraKind;
  description: string;
  prePayable: boolean;
  isDefault: boolean;
  price: Price[];
  links: Links[];
  trackingProperties?: object;
  sellingPoints?: string[];
  status?: State;
  disclaimer?: string;
  contactSupport?: string;
}

export interface Conditional {
  hoursBeforePickUp: number;
  description?: string;
  RestrictionCancel: RestrictionCancelEnum;
}

export interface Free {
  description?: string;
  RestrictionCancel: RestrictionCancelEnum;
}

export interface Paid {
  RestrictionCancel: RestrictionCancelEnum;
}

export type RestrictionCancel = Conditional | Free | Paid;

export enum RestrictionCancelEnum {
  Conditional = "Conditional",
  Free = "Free",
  Paid = "Paid",
}

export interface VendorMsgSection {
  desc: string;
  details: VendorMsgSubSection[];
  linkMsg?: string;
}

export interface VendorMsgSubSection {
  title?: string;
  text: string;
  displayText: string;
}

export interface VendorMessages {
  bookingConditions?: VendorMsgSection;
  carRentalPolicy?: VendorMsgSection;
  importantInfo?: VendorMsgSection;
  pickUpDirections?: VendorMsgSection;
}

export interface CancellationInsurance {
  screenTitle?: string;
  optionalTitle?: string;
  optionalBody?: string;
  title: string;
  body: string;
  warnMsg: string;
  linkMsg: string;
}

export interface CancellationInfo {
  insuranceInfo: CancellationInsurance;
}

export interface TripSummary {
  providerManagedMsg?: string;
  getHelpMsg?: string;
  userBookingId?: string;
}

export interface InsuranceConfirmation {
  confirmationId: string;
  confirmationMsg: string;
  confirmationTitle: string;
}

export interface ConfirmationInfo {
  providerBookingId: string;
  supplierBookingId?: string;
  bookingChain: BookingChain[];
}

export interface BookingChain {
  bookingId: string;
  status: string;
}

export interface GroundBookingResult {
  groundBookingId: string;
  status: State;
  bookingId?: string;
  bookingDate: string;
  pickUp: LocationBookDateTime;
  dropOff: LocationBookDateTime;
  rentalRate: RentalRate;
  vendor: Vendor;
  vehicle: Vehicle;
  additionalInfo?: AdditionalInfo;
  supplier: Supplier;
  extraInfo: ExtraInfo[];
  driver: Person;
  trackingProperties?: object;
  showCSAT?: boolean;
  contactSupport?: string;
  cancellationRules?: RestrictionCancel;
  contactSupportInfo?: ContactSupport;
  vendorMessages?: VendorMessages;
  cancellationInfo?: CancellationInfo;
  tripSummary?: TripSummary;
  insuranceConfirmation?: InsuranceConfirmation;
  confirmationInfo?: ConfirmationInfo;
}

export interface CarPaymentFinalizeResult {
  booking: GroundBookingResult;
  PaymentFinalizeResult: PaymentFinalizeResultEnum.GroundPaymentFinalizeResult;
}

export interface HotelPaymentFinalizeResult {
  reservation: Reservation;
  PaymentFinalizeResult: PaymentFinalizeResultEnum.HotelPaymentFinalizeResult;
}

export interface CarPriceQuoteData {
  groundQuoteData: CarPriceQuote;
  PaymentPriceQuoteData: PaymentPriceQuoteDataEnum.CarPriceQuoteData;
}

export interface HotelPriceQuoteRequest {
  hotelQuoteRequest: PriceQuoteScheduleRequest;
  quoteDescription: string;
  PaymentPriceQuoteRequest: PaymentPriceQuoteRequestEnum;
}

export interface CarPriceQuoteScheduleRequest {
  opaquePriceRequest: string;
  driverId: string;
  phoneNumber: string;
  emailAddress: string;
  delegatedTo?: string;
}

export interface CarPriceQuoteRequest {
  groundQuoteRequest: CarPriceQuoteScheduleRequest;
  quoteDescription: string;
  PaymentPriceQuoteRequest: PaymentPriceQuoteRequestEnum;
}

export interface CarPriceQuote {
  vehAvail: VehicleAvail;
  priceChanged: boolean;
}

export interface VehicleAvail {
  vehicle: Vehicle;
  supplierRef: string;
  vendorRef: string;
  pickUp: TimeAndPlace;
  dropOff: TimeAndPlace;
  rentalRate: RentalRate;
  isCheapest: boolean;
  paymentMethod?: CreditCard;
  needsDriverDetails?: boolean;
}

export interface ExtraInfoAmountDetails {
  amount: FiatPrice;
  desc: string;
  extraKind: ExtraKind;
}

export interface ExtraInfoAmount {
  total: FiatPrice;
  details: ExtraInfoAmountDetails[];
}

export interface DiscountAmount {
  discount: FiatPrice;
  newTotalCharge: FiatPrice;
}

export enum DistanceUnit {
  Km = "Km",
  Mi = "Mi",
}
export interface Limited {
  qty?: number;
  distUnit?: DistanceUnit;
  DistKind: DistKindEnum;
}

export interface Unlimited {
  DistKind: DistKindEnum;
}
export type DistKind = Limited | Unlimited;

export enum DistKindEnum {
  Limited = "Limited",
  Unlimited = "Unlimited",
}

export interface FuelPolicy {
  kind: FuelPolicyKind;
  desc: string;
}

export enum FuelPolicyKind {
  FullToFull = "FullToFull",
  ReturnSame = "ReturnSame",
  Unknown = "Unknown",
  PrepayRefunds = "PrepayRefunds",
  FreeTank = "FreeTank",
  PrepayNoRefunds = "PrepayNoRefunds",
  MultipleOptionsAtDesk = "MultipleOptionsAtDesk",
}
export interface Extensions {
  distance: DistKind;
  cancellation: CarsCancellationPolicy;
  collisionDamageWaiver?: boolean;
  supportExtraInfo: boolean;
  fuelPolicy?: FuelPolicy;
  specialOffers: string[];
  nonRefundable?: NonRefundableInfo;
  disclosureRequired?: boolean;
}

export interface NonRefundableInfo {
  nonRefundableMsg: string;
  paymentScreenMsg: string;
  detailsScreenMsg: string;
}

export interface RentalRate {
  totalCharge: FiatPrice;
  dailyCharge?: FiatPrice;
  payNow: Prices;
  payAtDesk: FiatPrice;
  base: FiatPrice;
  feesSummary: FeesSummary;
  extensions: Extensions;
  userChargedMsg?: string;
  discount?: DiscountAmount;
  extraInfoSummary?: ExtraInfoAmount;
  noUnderAgeFee?: boolean;
}

export interface DiscountAmount {
  discount: FiatPrice;
  newTotalCharge: FiatPrice;
}

export interface ExtraInfoAmount {
  total: FiatPrice;
  details: ExtraInfoAmountDetails[];
}

export interface ExtraInfoAmountDetails {
  amount: FiatPrice;
  desc: string;
  extraKind: ExtraKind;
}

export interface FeesSummary {
  total: FiatPrice;
  fees: FiatPrice[];
}
