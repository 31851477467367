import { Dispatch } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../../reducers/types";
import { actions } from "../../../../../../search/actions";
import { MobileFlightSearchStep } from "../../../../../../search/reducer";
import {
  setIsSearchBarEditMode,
  setOpenCalendarModal,
} from "../../../../../actions/actions";
import { FlightFindMoreResults } from "./FlightFindMoreResults";

type IStateProps = object;

const mapStateToProps = (state: IStoreState): IStateProps => ({});

interface IDispatchProps {
  setMobileSearchProgress: (
    step: MobileFlightSearchStep
  ) => actions.ISetMobileSearchProgress;
  setOpenCalendarModal: (val: boolean) => void;
  setIsSearchBarEditMode: (val: boolean) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    setMobileSearchProgress: actions.setMobileSearchProgress,
    setOpenCalendarModal: (val: boolean) => {
      dispatch(setOpenCalendarModal(val));
    },
    setIsSearchBarEditMode: (val: boolean) => {
      dispatch(setIsSearchBarEditMode(val));
    },
  };
};

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps
): IStateProps &
  IDispatchProps & {
    onOpenFilter?: () => void;
    variant?: "find-more" | "no-flight";
    className?: string;
  } => {
  return {
    ...stateProps,
    ...dispatchProps,
    onOpenFilter: ownProps.onOpenFilter,
    variant: ownProps.variant,
    className: ownProps.className,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type FlightFindMoreResultsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightFindMoreResults = connector(
  withRouter(FlightFindMoreResults)
);
