import { useI18nContext } from "@hopper-b2b/i18n";
import { useSetBodyModalOpenClass } from "@hopper-b2b/utilities";
import { Box, Dialog, DialogProps, Slide } from "@material-ui/core";
import clsx from "clsx";
import { FC, PropsWithChildren, ReactNode, useEffect, useRef } from "react";
import { ApacIcon, ApacIconName } from "../ApacIcon";
import "./ApacDesktopPopupModal.styles.scss";

export type ApacDesktopPopupModalDirection = "up" | "down" | "left" | "right";

export interface IApacDesktopPopupModalProps {
  open: boolean;
  className?: string;
  contentClassName?: string;
  direction?: ApacDesktopPopupModalDirection;
  headerElement?: JSX.Element | string;
  hideXButton?: boolean;
  maxWidth?: DialogProps["maxWidth"];
  bottomButton?: JSX.Element;
  onClose?: () => void;
  invisibleBackdrop?: boolean;
  children?: ReactNode;
}

const defaultProps: Partial<IApacDesktopPopupModalProps> = {
  hideXButton: false,
  invisibleBackdrop: false,
};

/**
 * Modal width set to 528px by default, which is the new design's modal width in most cases.
 *
 * Width and padding can be set to `contentClassName`
 */
export const ApacDesktopPopupModal: FC<IApacDesktopPopupModalProps> = (
  props: PropsWithChildren<IApacDesktopPopupModalProps>
) => {
  const {
    bottomButton,
    children,
    className,
    contentClassName,
    direction,
    headerElement,
    hideXButton,
    invisibleBackdrop,
    maxWidth,
    onClose,
    open,
  } = props;

  const { t } = useI18nContext();
  useSetBodyModalOpenClass(open);

  const titleId = `${Math.trunc(Math.random() * 10 ** 10)}DialogTitle`;

  const closeRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (open)
      setTimeout(() => {
        closeRef.current?.focus();
      }, 10);
  }, [open]);

  return (
    <Dialog
      hideBackdrop={false}
      BackdropProps={{
        invisible: invisibleBackdrop,
      }}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      TransitionComponent={Slide}
      TransitionProps={{
        // See https://mui.com/material-ui/transitions/#performance-amp-seo
        unmountOnExit: true,
        /* eslint-disable */
        // @ts-ignore: Fix the type definition of TransitionProps to include direction.
        direction: direction ?? "up",
        /* eslint-enable */
      }}
      className={clsx("desktop-popover-card-root", className)}
      onClick={(event) => {
        event.stopPropagation();
      }}
      role="dialog"
      aria-labelledby={titleId}
      style={{ borderRadius: "0px" }}
    >
      {headerElement && (
        <div id={titleId} tabIndex={-1} className="desktop-popover-card-header">
          {headerElement}
        </div>
      )}
      {!hideXButton && (
        <Box
          className={clsx("desktop-popover-card-top-button", "right")}
          onClick={onClose}
          tabIndex={0}
        >
          <ApacIcon
            ariaLabel={t("close.imageAlt")}
            className={clsx("close-button-icon")}
            name={ApacIconName.Close}
          />
        </Box>
      )}
      <Box
        className={clsx(
          "desktop-popover-card-content-container",
          contentClassName
        )}
        role={!headerElement ? "dialog" : undefined}
        tabIndex={!headerElement ? -1 : undefined}
        id={!headerElement ? titleId : undefined}
      >
        {children}
      </Box>
      <Box className="desktop-popover-card-bottom-button">{bottomButton}</Box>
    </Dialog>
  );
};

ApacDesktopPopupModal.defaultProps = defaultProps;
