import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { TFunction } from "i18next";
import clsx from "clsx";

import { getPlusDaysOnSlice, formatInterval } from "@hopper-b2b/utilities";
import { FlightShopCardType, TripDetails } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  B2BFlightCardV2,
  FlightDetailsCard,
  MobileFlightDetailsModal,
} from "@hopper-b2b/ui";
import {
  Airline,
  FlightsFareSlice,
  Slice,
  SliceOutbound,
} from "@b2bportal/air-shopping-api";

import { disruptionFetchTripDetails } from "../../../../../../api/v1/disruption/disruptionFetchTripDetails";
import { setDisruptionRebookSelectedFlight } from "../../../../../TripsList/actions/actions";
import { useDispatch } from "react-redux";

interface IFlightComponentProps {
  flight: SliceOutbound;
  slice: Slice;
  handleFlightSelect: (props: {
    fareSliceId: string;
    fareId: string;
    tripId: string;
    tripDetails: TripDetails;
  }) => void;
  isSelected: boolean;
  airline: Airline;
  openFlightDetailModal: boolean;
  onTripDetailsChange: (tripDetails: TripDetails) => void;
  getFlightShopFareSlice: (fareSliceId: string) => FlightsFareSlice;
}

export const FlightComponent = ({
  flight,
  slice,
  handleFlightSelect,
  isSelected,
  airline,
  openFlightDetailModal,
  onTripDetailsChange,
  getFlightShopFareSlice,
}: IFlightComponentProps) => {
  const { t, formatCurrency } = useI18nContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const [tripDetails, setTripDetails] = useState<TripDetails>();

  const fares = flight.fares;

  const duration = formatInterval(slice.totalDurationMinutes);
  const airports = slice.segments
    .slice(0, slice.segments.length - 1)
    .map((s) => s.destination);

  // Flights don't have a cost when they have been disrupted
  const fiat = formatCurrency(0, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const layoverText =
    slice.segments.length > 1
      ? t("layoverWithLocation", {
          duration: formatInterval(
            slice.segments[0].stopoverDurationMinutes || 0
          ),
          location: slice.segments[0].destination,
        }) +
        (slice.segments.length > 2
          ? //Adding space before additional layover string
            ` ${t("additionalLayoverStops", {
              remainingStops: slice.segments.length - 2,
            })}`
          : "")
      : null;

  const plusDays = getPlusDaysOnSlice(slice) ? t("flightNextDay") : null;

  useEffect(() => {
    if (isSelected) {
      // Payload include fares[0].example.trip which is the tripId - this value is consistent amongst all fares
      disruptionFetchTripDetails(fares[0].example.trip).then((res) => {
        res.fareDetails = res.fareDetails
          .filter((fareDetail) => {
            const fareIds = fares.map((fare) => fare.example.fare);
            return fareIds.includes(fareDetail.id);
          })
          .map((fareDetail) => {
            // Set all pricing to 0
            fareDetail.paxPricings[0].pricing.total.fiat.value = 0;
            fareDetail.paxPricings[0].pricing.baseAmount.fiat.value = 0;
            fareDetail.paxPricings[0].pricing.taxAmount.fiat.value = 0;
            return fareDetail;
          });
        setTripDetails(res);
        onTripDetailsChange(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fares, isSelected]);

  const selectFlight = useCallback(
    (fareId: string) => {
      dispatch(setDisruptionRebookSelectedFlight(slice));
      const fareSliceId = fares.find(
        (f) => f.example.fare === fareId
      )?.fareSlice;

      handleFlightSelect({
        fareSliceId,
        tripId: fares[0].example.trip,
        fareId: fareId,
        tripDetails,
      });
    },
    [dispatch, fares, handleFlightSelect, slice, tripDetails]
  );

  const handleTripSelect = useCallback(
    () => selectFlight(fares[0].example.fare),
    [fares, selectFlight]
  );

  return (
    <>
      <div
        className={clsx(
          "flight-list-item flight-row mini b2b",
          isSelected && "selected"
        )}
        onClick={handleTripSelect}
      >
        <div className="flight-list-info-root">
          <div className="flight-card-wrapper">
            <B2BFlightCardV2
              duration={duration}
              tags={[]}
              currentPriceText={fiat}
              rewardText={""}
              originCode={slice.origin}
              destinationCode={slice.destination}
              departureTime={slice.departure}
              arrivalTime={slice.arrival}
              airports={airports}
              brandName={""}
              primaryCarrier={airline?.code}
              airlineName={airline?.displayName}
              cardType={FlightShopCardType.MINI}
              additionalInfo={{
                isExpanded: isSelected,
                plusDays: plusDays,
                layoverText: layoverText,
              }}
            />
            {tripDetails !== undefined && isSelected ? (
              <FlightDetailsCard
                className={"b2b"}
                cardType={FlightShopCardType.MINI}
                tripDetails={tripDetails}
                isOutgoing={true}
                getEmptyRestrictionsText={(
                  fareRating: number,
                  translate: TFunction<"translation", undefined, "translation">
                ): string => {
                  return "";
                }}
                isMobile
                selectedFareClassFilters={[0]}
                setExpandedFlight={(expandedFlight: string): void => {
                  return;
                }}
                onFareClick={(fareId: string) => selectFlight(fareId)}
                getFlightShopFareSlice={getFlightShopFareSlice}
              />
            ) : null}
          </div>
        </div>
      </div>

      {openFlightDetailModal && isSelected ? (
        <MobileFlightDetailsModal
          className="disruption-flight-shop-details-modal"
          departure={true}
          tripDetails={tripDetails}
          fareDetails={tripDetails.fareDetails[0]}
          openModal={openFlightDetailModal}
          onClose={() => {
            history.goBack();
          }}
          title={`${slice.origin} - ${slice.destination}`}
        />
      ) : null}
    </>
  );
};
