import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import {
  PassengerTypes,
  FiatPrice,
  TimeToLive,
  FareDetails,
  TripDetails,
  Offer,
  CurrentFareVersusCapEnum,
  Prices,
  RewardsPrice,
  PriceFreezeFrozenFare,
  TripCategory,
  FrozenPricePaxPricing,
  BookingDetails,
  CallState,
} from "@hopper-b2b/types";
import {
  removeTimezone,
  formatInterval,
  getTotalPassengerFromPaxPricing,
  getPriceText,
  getCurrencySymbol,
  getPriceString,
  combineTrackingProperties,
  getPlusDays,
} from "@hopper-b2b/utilities";
import { IFlightSummaryPanelProps } from "@hopper-b2b/ui";
import { useI18nCurrency } from "@hopper-b2b/i18n";

import { IStoreState } from "../../../../reducers/types";
import {
  allTripSummariesSelector,
  selectedTripDetailsSelector,
  selectedTripSelector,
  flightShopProgressSelector,
  tripDetailsByIdSelector,
} from "../../../shop/reducer/selectors";
import { initialState, FlightShopStep } from "../../../shop/reducer";
import { getSelectedAccountReferenceId } from "../../../rewards/reducer";
import * as textConstants from "./textConstants";
import {
  Airport,
  GetPriceFreezeResponseV2Enum,
  GetPriceFreezeSuccess,
} from "@b2bportal/price-freeze-api";

export const priceFreezeOfferSelector = (state: IStoreState) =>
  state.flightFreeze.priceFreezeOffer;

export const priceFreezeOfferCallStateSelector = (state: IStoreState) =>
  state.flightFreeze.priceFreezeOfferCallState;

export const setUpFlightFreezeParamsCallStateSelector = (state: IStoreState) =>
  state.flightFreeze.setUpFlightFreezeParamsCallState;

export const getDisplayPfRefundModalSelector = (state: IStoreState) =>
  state.flightFreeze.displayPriceFreezeRefundModal;

export const priceFreezeCallStateSelector = (state: IStoreState) =>
  state.flightFreeze.priceFreezeCallState;

export const priceFreezePassengerCountsSelector = (state: IStoreState) =>
  state.flightFreeze.counts;

export const priceFreezeQuoteDataSelector = (state: IStoreState) =>
  state.flightFreeze.quoteData;

export const priceFreezeOfferConfigurationIdSelector = createSelector(
  priceFreezeQuoteDataSelector,
  (quoteData) => quoteData?.configurationId ?? null
);

export const priceFreezeFrozenPricingSelector = createSelector(
  priceFreezeQuoteDataSelector,
  (quoteData) => quoteData?.frozenPricing ?? null
);

export const priceFreezeSinglePassengerPricesSelector = createSelector(
  priceFreezeFrozenPricingSelector,
  (frozenPricing): Prices | undefined => frozenPricing?.singlePassenger
);

export const priceFreezeSummaryFrozenPriceSelector = createSelector(
  selectedTripDetailsSelector,
  selectedTripSelector,
  priceFreezeSinglePassengerPricesSelector,
  (selectedTripDetails, selectedTrip, singlePassengerPrices): Prices | null => {
    if (singlePassengerPrices) {
      return singlePassengerPrices;
    } else if (selectedTripDetails) {
      const fare = selectedTrip.returnFareId
        ? selectedTrip.returnFareId
        : selectedTrip.outgoingFareId;
      const fareDetails = selectedTripDetails.fareDetails.find(
        (f) => f.id === fare
      );

      // note: returning paxPricings[0].pricing.total as a fallback option for the frozen price
      return fareDetails?.paxPricings[0].pricing.total ?? null;
    }

    return null;
  }
);

export const isTripSummariesEmptySelector = createSelector(
  allTripSummariesSelector,
  (allTripSummaries): boolean => {
    return allTripSummaries === initialState.tripSummariesById;
  }
);

// TODO: Address type updates here to align with new GetPriceFreezeResponseV2
export const currentPriceFreezeSelector = (state: IStoreState) =>
  state.flightFreeze.currentPriceFreeze?.priceFreeze;
export const currentPriceFreezeTripContextSelector = (state: IStoreState) =>
  state.flightFreeze.currentPriceFreeze?.tripContext;
export const currentPriceFreezePriceDropProtectionSelector = (
  state: IStoreState
) => state.flightFreeze.currentPriceFreeze?.priceDropProtection;

export const getRefundCallStateSelector = (state: IStoreState) =>
  state.flightFreeze.refundPriceFreezeCallState;

export const passengerCountSelector = createSelector(
  priceFreezePassengerCountsSelector,
  (counts) => {
    const {
      adultsCount,
      infantsInSeatCount,
      infantsOnLapCount,
      childrenCount,
    } = counts;
    const passengerObj = {};
    if (adultsCount > 0) passengerObj[PassengerTypes.Adult] = adultsCount;
    if (infantsInSeatCount > 0)
      passengerObj[PassengerTypes.InfantInSeat] = infantsInSeatCount;
    if (infantsOnLapCount > 0)
      passengerObj[PassengerTypes.InfantInLap] = infantsOnLapCount;
    if (childrenCount > 0) passengerObj[PassengerTypes.Child] = childrenCount;
    return passengerObj;
  }
);

export const priceFreezeOfferTotalSelector = createSelector(
  priceFreezeQuoteDataSelector,
  priceFreezeOfferSelector,
  (quoteData, offer): number | undefined => {
    // note: offer is immutable, so it doesn't respond to passenger count changes
    return quoteData?.totalAmount.fiat.value ?? offer?.totalAmount.fiat.value;
  }
);

export const priceFreezeOfferPerPaxSelector = createSelector(
  priceFreezeQuoteDataSelector,
  priceFreezeOfferSelector,
  (quoteData, offer): number | undefined => {
    return quoteData?.perPaxAmount.fiat.value ?? offer?.perPaxAmount.fiat.value;
  }
);

export const priceFreezeOfferRewardsPerPaxSelector = createSelector(
  priceFreezeQuoteDataSelector,
  priceFreezeOfferSelector,
  (quoteData, offer) => {
    return quoteData?.perPaxAmount.rewards ?? offer?.perPaxAmount.rewards;
  }
);

export const priceFreezeOfferDurationSelector = createSelector(
  priceFreezeOfferSelector,
  (offer): TimeToLive | undefined => {
    return offer?.timeToLive;
  }
);

export const priceFreezeOfferCurrencySelector = createSelector(
  priceFreezeQuoteDataSelector,
  priceFreezeOfferSelector,
  (quoteData, offer): Omit<FiatPrice, "value"> => {
    return {
      currencySymbol:
        quoteData?.perPaxAmount.fiat.currencySymbol ??
        offer?.perPaxAmount.fiat.currencySymbol ??
        "",
      currencyCode:
        quoteData?.perPaxAmount.fiat.currencyCode ??
        offer?.perPaxAmount.fiat.currencyCode ??
        "",
    };
  }
);

export const priceFreezeOfferCapSelector = createSelector(
  priceFreezeOfferSelector,
  (offer): string => {
    const currency = offer?.cap.value.currency;
    return getPriceString({
      price: offer?.cap.value.amount || 0,
      currencySymbol: currency ? getCurrencySymbol(currency) : undefined,
    });
  }
);

export const priceFreezeOfferTotalFiatSelector = createSelector(
  priceFreezeOfferCurrencySelector,
  priceFreezeOfferTotalSelector,
  ({ currencySymbol }, value): string => {
    const { formatCurrency } = useI18nCurrency();
    return formatCurrency(value || 0);
  }
);

export const priceFreezeOfferPerPaxFiatSelector = createSelector(
  priceFreezeOfferCurrencySelector,
  priceFreezeOfferPerPaxSelector,
  ({ currencySymbol }, value): string => {
    const { formatCurrency } = useI18nCurrency();
    return formatCurrency(value || 0);
  }
);

export interface IPriceFreezeOverview {
  expiresAt: string;
  tripDetails: TripDetails | null;
  fareDetails: FareDetails | null;
  airports: { [key: string]: Airport } | null;
  offer: Offer | null;
  currentTripPricePerPax: { fiat: string; rewards: string } | null;
  isRoundTrip: boolean;
  priceFreezeActiveStatus: boolean;
  totalPassengers: number;
  savingsAmount: string | null;
  chargedTripPricePerPax: { fiat: string; rewards: string } | null;
  frozenTripPricePerPax: { fiat: string; rewards: string } | null;
  capAmount: string | null;
  hasReachedFareCap: boolean;
  isCurrentPriceLowerThanFrozen: boolean;
  fareDetailsTrip: PriceFreezeFrozenFare | null;
  priceFreezeId?: string;
  confirmationNumber?: string;
  selectedFare: FrozenPricePaxPricing;
  bookingDetails?: BookingDetails;
}
export const getCurrentPriceFreezeTrackingProperties = (state: IStoreState) => {
  switch (state.flightFreeze.currentPriceFreeze?.GetPriceFreezeResponseV2) {
    case GetPriceFreezeResponseV2Enum.Success: {
      const priceFreezeView = state.flightFreeze
        .currentPriceFreeze as GetPriceFreezeSuccess;
      return combineTrackingProperties([
        priceFreezeView.priceFreeze.trackingProperties,
      ]);
    }
    case GetPriceFreezeResponseV2Enum.Failure: {
      return combineTrackingProperties([]);
    }
  }
};

export const getHasFetchedPriceFreeze = createSelector(
  priceFreezeCallStateSelector,
  (callState) => {
    return callState === CallState.Success;
  }
);

export const getCurrentPriceFreezeDataForOverviewPage = createSelector(
  currentPriceFreezeSelector,
  currentPriceFreezeTripContextSelector,
  getSelectedAccountReferenceId,
  (priceFreeze, tripContext): IPriceFreezeOverview => {
    if (priceFreeze && tripContext) {
      const getPrices = (amount: Prices | undefined) => {
        if (!amount)
          return {
            fiat: "",
            rewards: "",
          };

        return {
          fiat: getPriceText({
            price: amount?.fiat,
          }),
          rewards: "",
        };
      };

      const selectedFare =
        priceFreeze.frozenFare.paxPricings.find(
          (p: FrozenPricePaxPricing) => p.paxType === PassengerTypes.Adult
        ) || priceFreeze.frozenFare.paxPricings[0];

      //TODO: use perpax amount when avalaible on the endpoint
      //Current Trip price
      const currentTripPrice = selectedFare.pricing.currentAmount;
      const currentTripPricePerPax = getPrices(currentTripPrice);

      //Charged Trip Price
      const chargedTripPricePerPax = getPrices(
        selectedFare.pricing.chargeAmount
      );

      //Frozen Trip Price
      const frozenTripPrice = selectedFare.pricing.originalAmount;
      const frozenTripPricePerPax = getPrices(frozenTripPrice);
      const isCurrentPriceLowerThanFrozen =
        (currentTripPrice?.fiat.value ?? 0) < frozenTripPrice.fiat.value;

      //Total passenger from offer
      const offer = priceFreeze.priceFreeze.offer;
      const totalPassengers = getTotalPassengerFromPaxPricing(
        priceFreeze.frozenFare.paxPricings
      );

      return {
        expiresAt: priceFreeze.priceFreeze.expiresAt || "",
        tripDetails: priceFreeze.tripDetails,
        fareDetails:
          priceFreeze.tripDetails.fareDetails.find(
            (fare) => fare.id === priceFreeze.frozenFare.id
          ) ?? null,
        airports: tripContext.airports,
        offer,
        currentTripPricePerPax,
        totalPassengers,
        isRoundTrip: priceFreeze.tripDetails.slices.length > 1,
        priceFreezeActiveStatus: priceFreeze.status.Status === "IsActive",
        savingsAmount:
          selectedFare.pricing.savingsAmount.fiat.value !== 0
            ? getPriceText({
                price: selectedFare.pricing.savingsAmount.fiat,
              })
            : null,
        chargedTripPricePerPax,
        frozenTripPricePerPax,
        hasReachedFareCap:
          selectedFare.pricing.versusCap.CurrentFareVersusCap ===
          CurrentFareVersusCapEnum.FareOverCap,
        capAmount: getPriceText({
          price: {
            currencyCode: offer.cap.value.currency,
            currencySymbol: getCurrencySymbol(offer.cap.value.currency),
            value: offer.cap.value.amount,
          },
        }),
        isCurrentPriceLowerThanFrozen,
        fareDetailsTrip: priceFreeze.frozenFare,
        priceFreezeId: priceFreeze.priceFreeze.id,
        confirmationNumber: priceFreeze.priceFreeze.externalId,
        selectedFare,
        bookingDetails: priceFreeze.bookingDetails,
      };
    }
    return {
      expiresAt: "",
      tripDetails: null,
      fareDetails: null,
      airports: null,
      offer: null,
      currentTripPricePerPax: null,
      frozenTripPricePerPax: null,
      isRoundTrip: true,
      priceFreezeActiveStatus: true,
      totalPassengers: 1,
      savingsAmount: null,
      chargedTripPricePerPax: null,
      capAmount: null,
      hasReachedFareCap: false,
      isCurrentPriceLowerThanFrozen: false,
      fareDetailsTrip: null,
      selectedFare: {} as FrozenPricePaxPricing,
      bookingDetails: {} as BookingDetails,
    };
  }
);

export const getFrozenPriceFromPriceFreeze = createSelector(
  currentPriceFreezeSelector,
  currentPriceFreezeTripContextSelector,
  getSelectedAccountReferenceId,
  (
    priceFreeze,
    tripContext,
    selectedAccountReferenceId
  ): { fiat: FiatPrice; rewards: RewardsPrice | undefined } | null => {
    if (!priceFreeze) {
      return null;
    }
    if (!tripContext) {
      return null;
    }

    // note: taking the first one from list because all passenger types have the same price
    const selectedFare = priceFreeze.frozenFare.paxPricings[0];

    if (!selectedFare) {
      return null;
    }

    const tripFarePrices = selectedFare.pricing.originalAmount;

    return {
      fiat: tripFarePrices.fiat,
      rewards: selectedAccountReferenceId
        ? tripFarePrices.rewards[selectedAccountReferenceId]
        : undefined,
    };
  }
);

export const getTripCategoryFromPriceFreeze = createSelector(
  currentPriceFreezeSelector,
  currentPriceFreezeTripContextSelector,
  (priceFreeze, tripContext): TripCategory | null => {
    if (!priceFreeze) {
      return null;
    }
    if (!tripContext) {
      return null;
    }

    const slices = priceFreeze.tripDetails.slices;
    return slices.length > 1 ? TripCategory.ROUND_TRIP : TripCategory.ONE_WAY;
  }
);

// TODO: given that this logic is heavily duplicated in many components, they should be refactored to use the same selector
export const getFlightSummaryPanelPropsFromPriceFreeze = createSelector(
  currentPriceFreezeSelector,
  currentPriceFreezeTripContextSelector,
  getTripCategoryFromPriceFreeze,
  flightShopProgressSelector,
  (
    priceFreeze,
    tripContext,
    tripCategory,
    flightShopProgress
  ): IFlightSummaryPanelProps | null => {
    if (!priceFreeze) {
      return null;
    }
    if (!tripContext) {
      return null;
    }

    const { airports } = tripContext;
    const slices = priceFreeze.tripDetails.slices;

    const isChooseReturn = flightShopProgress === FlightShopStep.ChooseReturn;
    const tripSlice =
      tripCategory === TripCategory.ROUND_TRIP && isChooseReturn
        ? slices[1]
        : slices[0];

    const { type, description } = textConstants.getReviewCardHeaderWithType(
      !isChooseReturn,
      airports[tripSlice.destinationCode]
        ? airports[tripSlice.destinationCode].cityName
        : tripSlice.destinationName,
      removeTimezone(tripSlice.departureTime)
    );

    const flightSummary: IFlightSummaryPanelProps = {
      airlineCode: tripSlice.segmentDetails[0].airlineCode,
      formattedDepartureDescription: description,
      formattedDepartureTime: dayjs(
        removeTimezone(tripSlice.departureTime)
      ).format("h:mm A"),
      formattedArrivalTime: dayjs(removeTimezone(tripSlice.arrivalTime)).format(
        "h:mm A"
      ),
      airline: tripSlice.segmentDetails[0].airlineName,
      duration: formatInterval(tripSlice.totalDurationMinutes || 0),
      stopString: textConstants.getStopsString(tripSlice.stops),
      tripCategory: type,
      plusDays: getPlusDays(tripSlice),
    };

    return flightSummary;
  }
);

interface ISimilarFlightSelectedTripDetailsProperties {
  price_freeze_id: string;
  exercise_price_usd: number;
  frozen_price_pax_count: number;
  frozen_price_total_usd: number;
  price_freeze_actual_OTM_total_usd: number;
}

export const getSimilarFlightSelectedTripDetailsProperties = createSelector(
  tripDetailsByIdSelector,
  selectedTripSelector,
  currentPriceFreezeSelector,
  (
    tripDetails,
    selectedTrip,
    priceFreeze
  ): ISimilarFlightSelectedTripDetailsProperties | null => {
    if (selectedTrip?.tripId) {
      const fareId = selectedTrip?.returnFareId ?? selectedTrip?.outgoingFareId;
      const selectedFare = tripDetails[selectedTrip.tripId].fareDetails.find(
        (fareDetail) => fareDetail.id === fareId
      );

      if (selectedFare) {
        return {
          price_freeze_id: priceFreeze?.priceFreeze.id || "",
          exercise_price_usd:
            selectedFare.paxPricings[0].pricing.total?.fiat.value || 0,
          frozen_price_pax_count:
            priceFreeze?.frozenFare.paxPricings.length || 0,
          frozen_price_total_usd:
            priceFreeze?.frozenFare.totalPricing?.originalAmount.fiat.value ||
            0,
          price_freeze_actual_OTM_total_usd:
            priceFreeze?.frozenFare.totalPricing?.savingsAmount?.fiat?.value ||
            0,
        };
      }
    }
    return null;
  }
);
