import { MutableRefObject, useRef, useState } from "react";

const POLICY_TIMEOUT = 50000; // time before policy expires - 50 seconds
const POLL_INTERVAL = 2000; // time between polling requests - 2 seconds

type VoidFunction = () => void;

export type UsePollingTimingHandlers = {
  setPolling: (callback: VoidFunction) => void;
  clearPollInterval: VoidFunction;
  clearPolicyTimeout: VoidFunction;
  startPolicyTimeout: (callback?: VoidFunction) => void;
};

interface UsePollingTimingResponse {
  context: { pollIdRef: MutableRefObject<number>; timedOut: boolean };
  handlers: UsePollingTimingHandlers;
}

export const usePollingTiming = (): UsePollingTimingResponse => {
  const pollIdRef = useRef<number>();
  const timeoutIdRef = useRef<number>();

  const [timedOut, setTimedOut] = useState<boolean>(false);

  const clearPolicyTimeout = () => {
    const { current: timeoutId } = timeoutIdRef;

    if (timeoutId) {
      window.clearTimeout(timeoutId);

      timeoutIdRef.current = 0;
    }

    if (timedOut) {
      setTimedOut(false);
    }
  };

  const clearPollInterval = () => {
    const { current: pollId } = pollIdRef;

    if (pollId) {
      window.clearInterval(pollId);

      pollIdRef.current = 0;
    }
  };

  const startPolicyTimeout = (callback?: () => void) => {
    timeoutIdRef.current = window.setTimeout(() => {
      setTimedOut(true);
      callback?.();
    }, POLICY_TIMEOUT);
  };

  const setPolling = (callback) => {
    pollIdRef.current = window.setInterval(callback, POLL_INTERVAL);
  };

  return {
    context: { pollIdRef, timedOut },
    handlers: {
      setPolling,
      clearPollInterval,
      clearPolicyTimeout,
      startPolicyTimeout,
    },
  };
};
