import {
  Available,
  GetAirChfarOfferResponse,
  GetAirChfarOfferResponseEnum,
} from "@b2bportal/air-chfar-api";
import { fetchChfarOffer } from "@hopper-b2b/api";
import { CallState } from "@hopper-b2b/types";
import { put, select } from "redux-saga/effects";
import { flightApiConfigStoreKey, IStoreState } from "../../../reducers/types";
import { actions } from "../actions";

export function* fetchChfarOfferSaga(action: actions.IFetchChfarOffer) {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];

    const response: GetAirChfarOfferResponse = yield fetchChfarOffer(
      {
        fareId: action.fareId,
        tripId: action.tripId,
      },
      apiConfig
    );

    if (
      response.GetAirChfarOfferResponse ===
      GetAirChfarOfferResponseEnum.Available
    ) {
      const { offers } = response as Available;

      yield put(
        actions.setChfarOffers({
          offers,
        })
      );
    } else {
      yield put(actions.setChfarOfferFailed());
      console.warn("Chfar offer not available", response);
    }
  } catch (error) {
    yield put(actions.setChfarOfferFailed());
    console.error("Error fetching Chfar offer", error);
  }
}
