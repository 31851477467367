export const TERMS_AND_CONDITIONS_DESKTOP = "Hopper Terms & Conditions";
export const TERMS_AND_CONDITIONS_MOBILE = ["Hopper Terms", "& Conditions"];
export const TERMS_OF_USE_DESKTOP = "Site Terms of Use";
export const TERMS_OF_USE_MOBILE = ["Site Terms", "of Use"];
export const PRIVACY = "Privacy Policy";
export const FAQS = "FAQs";

const { origin: baseDomain } = window.location;

export const LEGAL = (name: string) => `${new Date().getFullYear()} ${name}`;
export const POWERED_BY = "Powered by";

export const TERMS_AND_CONDITIONS_LINK = `${baseDomain}/terms`;

export const FAQS_LINK =
  "https://help.hopper.com/en_us/categories/help-with-flights-Hywr1lPz_";
