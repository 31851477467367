import { ApacIcon, ApacIconName, ApacModalLink } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { PATH_HOME_SEARCH } from "../../../../utils";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FlightShopStep, flightShopProgressSelector } from "../../reducer";
import { FlightShopProgressHeaderConnectorProps } from "./container";
import { getPassengerCountsString } from "@commbank/utilities";
import { MobileFlightSearchStep } from "../../../search/reducer";
import { setMobileSearchProgress } from "../../../search/actions/actions";
import { PriceBreakdown } from "../PriceBreakdown";
import { useDispatch } from "react-redux";
import "./FlightShopProgressHeader.styles.scss";

export interface IFlightShopProgressHeaderProps
  extends FlightShopProgressHeaderConnectorProps {
  className?: string;
  isPriceFreezeEnabled: boolean;
}

export const FlightShopProgressHeader = ({
  isOneWay,
  origin,
  destination,
  departureDate,
  returnDate,
  className,
  isPriceFreezeEnabled,
  adultsCount,
  childrenCount,
  infantsOnLapCount,
  infantsInSeatCount,
}: IFlightShopProgressHeaderProps) => {
  const DATE_FORMAT = "ddd D MMM";
  const history = useHistory();
  const { t } = useI18nContext();
  const dispatch = useDispatch();
  const currentProgress = useSelector(flightShopProgressSelector);

  const goToSearch = useCallback(() => {
    dispatch(setMobileSearchProgress(MobileFlightSearchStep.LocationSearch));
    history.replace(PATH_HOME_SEARCH, { previousStep: currentProgress });
  }, [currentProgress, dispatch, history]);

  const handleGoBack = useCallback(() => {
    if (currentProgress === FlightShopStep.ChooseDeparture) {
      goToSearch();
    } else {
      history.goBack();
    }
  }, [currentProgress, goToSearch, history]);

  const renderCentre = () => {
    switch (currentProgress) {
      case FlightShopStep.CfarOffers:
      case FlightShopStep.ChfarOffer:
      case FlightShopStep.PricePrediction:
      case FlightShopStep.ChooseDeparture:
      case FlightShopStep.ChooseReturn:
      case FlightShopStep.ReviewItinerary:
      case FlightShopStep.DisruptionProtection: {
        return (
          <Box className="trip-info">
            <Typography className="origin-destination" variant="h6">
              {isOneWay ? t("oneWay") : t("roundTrip")} {origin?.id.code.code}
              <ApacIcon name={ApacIconName.AirPlane} />
              {destination?.id.code.code}
            </Typography>
            <Typography className="small-font" variant="body2">
              {`${dayjs(departureDate).format(DATE_FORMAT)}` +
                (returnDate
                  ? ` - ${dayjs(returnDate).format(DATE_FORMAT)}`
                  : "")}
            </Typography>
            <Typography
              className="flight-shop-passenger-counts"
              variant="subtitle1"
            >
              {getPassengerCountsString({
                adultsCount,
                childrenCount,
                infantsInSeatCount,
                infantsOnLapCount,
                t,
              })}
            </Typography>
          </Box>
        );
      }
      case FlightShopStep.FareDetails: {
        return (
          <Box className="locations-and-dates-section">
            <Typography className="fare-details-header">
              {t("fareDetails")}
            </Typography>
          </Box>
        );
      }
      default: {
        // FlightShopStep.FintechSelection
        return null;
      }
    }
  };

  const renderRightIcon = () => {
    switch (currentProgress) {
      case FlightShopStep.ReviewItinerary:
      case FlightShopStep.CfarOffers:
      case FlightShopStep.ChfarOffer:
      case FlightShopStep.DisruptionProtection: {
        return (
          <ApacModalLink
            content={({ onClose }) => <PriceBreakdown onClose={onClose} />}
          >
            <ApacIcon
              className="side-action-icon"
              name={ApacIconName.Statements}
            />
          </ApacModalLink>
        );
      }
      case FlightShopStep.PricePrediction:
      case FlightShopStep.FintechSelection:
      case FlightShopStep.FareDetails: {
        return null;
      }
      default: {
        // ChooseDeparture,
        // ChooseReturn,
        // FareDetails,
        return isPriceFreezeEnabled ? null : (
          <ApacIcon
            className="side-action-icon"
            name={ApacIconName.EditPen}
            onClick={goToSearch}
          />
        );
      }
    }
  };

  return (
    <Box className={clsx("mobile-flight-shop-progress-header-root", className)}>
      <Box className="header-content-container">
        <ApacIcon
          className="side-action-icon"
          name={ApacIconName.ChevronLeft}
          onClick={handleGoBack}
        />
        {renderCentre()}
        <Box className={"filter-button-section"}>{renderRightIcon()}</Box>
      </Box>
    </Box>
  );
};
