import {
  Branding,
  ClientName,
  FlightShopCardType,
  ChatAvailability,
  ContactSupportConfig,
  I18nNamespace,
} from "@hopper-b2b/types";

const contactSupport: ContactSupportConfig = {
  chatAvailability: ChatAvailability.All,
  displaySupportNumber: "1 800-093-0219",
  supportNumber: "18000930219",
};

export const branding: Branding = {
  contactSupport,
  calendarMonthFormat: "MMMM YYYY",
  merchantPayments: "",
  clientName: ClientName.COMMBANK_AU,
  currencies: [
    {
      locale: "en-AU",
      code: "AUD",
      symbol: "A$",
      // See https://hopper-jira.atlassian.net/wiki/spaces/IEP/pages/6205308931/Tenant-specific+currency+symbols
      symbolOverride: "AU$",
    },
  ],
  // Defaults to en-AU so the ultimate fallback language would be en-AU
  // for those "English but not en-AU" users
  default: "en-AU",
  // No need to add "en" here because falling back to the primary language is given
  supportedLanguages: [{ key: "en-AU", name: "English", label: "English" }],
  flightShopCardType: FlightShopCardType.MINI,
  adaChat: {
    answerIds: {
      delayOrMissedConnection: "6418b6f2d98bdd47409e0705",
    },
  },
  [I18nNamespace.translation]: {
    "en-AU": {
      portalName: "Travel Booking",
      urls: {
        termsAndConditions: "",
        privacyPolicy: "https://hopper.com/legal/privacy-policy",
        disruptionTerms:
          "https://www.CommbankAU.com/legal/en/document/?name=CommbankAU-travel---terms-and-conditions---flights-and-hotels&country=great-britain&lang=en",
        priceFreezeTerms:
          "https://www.CommbankAU.com/legal/en/document/?name=CommbankAU-travel---terms-and-conditions---flights-and-hotels&country=great-britain&lang=en",
      },
      preferredCountryCode: "AU",
      preferredAreaCode: "+61",
    },
  },
};
