import { TripSlice } from "@b2bportal/air-shopping-api";
import {
  AirlineCode,
  BaggageInfoEnum,
  CountryCode,
  DateTime,
  PassengerType,
  PenaltiesInfoEnum,
  Prices,
  Uuid,
} from "../../common";
import { Provider } from "../../common/provider";
import { AmenitiesAndUtas } from "../dulles/interfaces";
import { FareScore, FareShelf } from "./trip-summary";
import { MultiTicketTypeEnum } from "../../trips-module";

export interface ConfigurationId {
  timestamp: DateTime;
}

export interface FareIntent {
  flightType: string;
  notificationType: string;
}

export type Penalty = NoPenalty | NotPossible | PenaltyUnknown | WithFee;

export interface NoPenalty {
  Penalty: PenaltyEnum.NoPenalty;
}

export interface NotPossible {
  Penalty: PenaltyEnum.NotPossible;
}

export interface PenaltyUnknown {
  Penalty: PenaltyEnum.Unknown;
}

export interface WithFee {
  amount: number;
  currency: string;
  Penalty: PenaltyEnum.WithFee;
}

export enum PenaltyEnum {
  NoPenalty = "NoPenalty",
  NotPossible = "NotPossible",
  Unknown = "Unknown",
  WithFee = "WithFee",
}

export type PenaltiesInfo = ExternallyManaged | Known | PenaltiesInfoUnknown;

export interface ExternallyManaged {
  code: string;
  PenaltiesInfo: PenaltiesInfoEnum.ExternallyManaged;
}

export interface Known {
  changePenalty: Penalty;
  cancelPenalty: Penalty;
  PenaltiesInfo: PenaltiesInfoEnum.Known;
}

export interface PenaltiesInfoUnknown {
  PenaltiesInfo: PenaltiesInfoEnum.Unknown;
}

export interface BookingFeeDefinitions {
  origin: BookingFeeDefinition;
  destination?: BookingFeeDefinition;
}

export interface BookingFeeDefinition {
  kind: string;
  code: string;
  location: string;
}
export interface FeeTypeDefinition {
  type: string;
}

export enum FeeType {}

export interface BookingFee {
  definedFor: BookingFeeDefinitions;
  bookingFee: FeeTypeDefinition;
  definedBy: string;
  definedOn: DateTime;
}

export interface FarePricing {
  baseAmount: Prices;
  taxAmount: Prices;
  lastTicketDate?: string;
  additionalMargin?: Prices;
  total?: Prices;
  totalWithDiscount?: Prices;
}

export interface PassengerPricing {
  paxType: PassengerType;
  pricing: FarePricing;
  quantity: number;
}

export type Source = string;

export interface MorMargin {
  percent: number;
}

export interface AccountCode {
  code: string;
}

export interface FareDetails {
  id: string;
  timestamp: number;
  conversationId: string;
  paxPricings: PassengerPricing[];
  slices: FareSliceDetails[];
  penaltiesInfo?: PenaltiesInfo;
  refundable?: boolean;
  took?: number;
  fareScore?: FareScore;
  multiTicket: boolean;
  excludeINS?: boolean;
  multiTicketType: MultiTicketTypeEnum;
  restrictions: Restrictions;
}

export interface Restrictions {
  amenitiesAndUtas: AmenitiesAndUtas[];
}

export type BaggageInfo = Allowed | NotAllowed | BaggageInfoUnknown;

export interface Allowed {
  allowance: BaggageAllowance;
  charges: BaggageCharge[];
  BaggageInfo: BaggageInfoEnum.Allowed;
}

export interface NotAllowed {
  BaggageInfo: BaggageInfoEnum.NotAllowed;
}

export interface BaggageInfoUnknown {
  BaggageInfo: BaggageInfoEnum.Unknown;
}

export interface BaggageAllowance {
  descriptions: string[];
  pieces: number;
  maximumMass?: Mass;
}

export interface Mass {
  value: number;
  unit: string;
}

export interface BaggageCharge {
  firstPiece: number;
  amount: number;
  lastPiece: number;
  currency: string;
  descriptions: string[];
  maximumMass?: Mass;
}

export interface FareSegment {
  availableSeats: number;
  baggageInfo: BaggageInfo;
  bookingCode?: string;
  cabinClassName?: string;
  carrierCabinClass?: string;
  code?: string;
  connection?: boolean;
  outgoing: boolean;
  penaltiesInfo: PenaltiesInfo;
  privateFare?: boolean;
  ticketIndex?: number;
  brand?: FareBrand;
  codes: {
    [key: string]: string;
  };
  validatingCarrierCode?: string;
  isSelfTransferLayover?: boolean;
}

export interface FareSlice {
  segments: FareSegment[];
}

export interface FareSliceDetails {
  penaltiesInfo?: PenaltiesInfo;
  refundable?: boolean;
  paxPricings?: PassengerPricing[];
  took?: number;
  outgoing: boolean;
  fareShelf?: FareShelf;
  fareDetails: FareSlice;
  amenitiesUtas?: AmenitiesAndUtas;
  source?: Source;
  provider?: Provider;
  fareScore?: FareScore;
  morMargin?: MorMargin;
  accountCode?: AccountCode;
}

export interface SabreBrand {
  brandId: string;
  brandName?: string;
}

export interface TravelFusionFareBrand {
  brandId: string;
  brandName?: string;
}

export interface TravelportBrand {
  brandId: string;
  brandName?: string;
  brandTier?: number;
}

export interface GdxBrand {
  brandId: string;
  brandName?: string;
}

export interface AmadeusBrand {
  brandId: string;
  brandName?: string;
}

export interface FareBrand {
  travelFusionFareBrand: TravelFusionFareBrand;
  sabreFareBrand?: SabreBrand;
  travelportFareBrand?: TravelportBrand;
  gdxFareBrand?: GdxBrand;
  amadeusFareBrand?: AmadeusBrand;
}

export interface TripDetails {
  id: Uuid;
  slices: TripSlice[];
  validatingCarrierCode?: AirlineCode;
  fareDetails: FareDetails[];
  shopId?: Uuid;
  configurationId?: ConfigurationId;
  fareIntent?: FareIntent;
  pointOfSaleCountryCode: CountryCode;
  tripDescription: string;
}

export function getCancelPenalty(fare: FareDetails) {
  const penaltiesInfo = fare.penaltiesInfo;
  if (penaltiesInfo?.PenaltiesInfo === PenaltiesInfoEnum.Known) {
    return penaltiesInfo.cancelPenalty;
  }

  return undefined;
}

export function getChangePenalty(fare: FareDetails) {
  const penaltiesInfo = fare.penaltiesInfo;
  if (penaltiesInfo?.PenaltiesInfo === PenaltiesInfoEnum.Known) {
    return penaltiesInfo.changePenalty;
  }

  return undefined;
}
