import {
  IApiConfig,
  NETWORK_CALL_FAILED,
  RebookPollFulfillRequest,
  RebookPollFulfillResponseEnum,
  RebookScheduleFulfillRequest,
  RebookScheduleFulfillResponseEnum,
} from "@hopper-b2b/types";
import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import {
  disruptionRebookFulfillPath,
  disruptionRebookFulfillPollPath,
} from "../paths";

const disruptionRebookFulfillPoll = async (
  req: RebookPollFulfillRequest,
  index = 0
): Promise<any> => {
  // TODO: Update 'any' type to RebookPollFulfillResponse
  const delayTimes = [2000, 4000, 6000, 8000, 10000, 20000, 30000];

  await new Promise(async (resolve) =>
    setTimeout(resolve, delayTimes[Math.min(index, 7)])
  );
  const response = await axiosInstance.post(
    disruptionRebookFulfillPollPath,
    req
  );
  try {
    if (
      response.data.RebookPollFulfillResponse ===
        RebookPollFulfillResponseEnum.PollFulfillPending ||
      response.data.RebookPollFulfillResponse ===
        RebookPollFulfillResponseEnum.Pending
    ) {
      return await disruptionRebookFulfillPoll(req, index++);
    } else {
      return response;
    }
  } catch (e) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
    return e;
  }
};

const disruptionRebookFulfill = (
  req: RebookScheduleFulfillRequest,
  apiConfig?: IApiConfig
): Promise<any> => // TODO: update 'any' type to RebookScheduleFulfillResponse
  new Promise((resolve, reject) => {
    axiosInstance
      .post(disruptionRebookFulfillPath, req)
      .then((res) => {
        const { data } = res;
        if (
          data.RebookScheduleFulfillResponse ===
            RebookScheduleFulfillResponseEnum.ScheduleFulfillSuccess ||
          data.RebookScheduleFulfillResponse ===
            RebookScheduleFulfillResponseEnum.Success
        ) {
          resolve(disruptionRebookFulfillPoll(req));
        } else {
          reject({
            type: data.reason.DisruptionFailure,
            data,
          });
        }
      })
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });

export default disruptionRebookFulfill;
