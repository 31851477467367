import { Box, Typography } from "@material-ui/core";
import styles from "./DisruptionNotEligible.module.scss";
import { InfoBanner } from "@hopper-b2b/wallet";
import { ClientContext } from "../../../../../../trips/src/App";
import { useContext } from "react";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";

export const DisruptionNotEligible = () => {
  const { t } = useI18nContext();

  const clientContext = useContext(ClientContext);
  return (
    <Box className={styles.disruptionNotEligible}>
      <Typography color="textSecondary" className={styles.title}>
        {t("disruptionExercise.ineligible.title")}
      </Typography>
      <Box className={styles.content}>
        <Typography color="textSecondary" className={styles.subtitle}>
          {t("disruptionExercise.ineligible.subtitle")}
        </Typography>
        <InfoBanner
          icon={clientContext.assets?.infoWhite}
          text={<I18nMarkup tKey="disruptionExercise.ineligible.infoText" />}
        />
      </Box>
    </Box>
  );
};
