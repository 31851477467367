import { Button } from "@material-ui/core";
import clsx from "clsx";
import { DesktopPopupModal } from "@hopper-b2b/ui";
import { TripCategory } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import "./styles.scss";
import { useMemo } from "react";
import { MonthAndDatePicker, MonthAndDatePickerType } from "@commbank/ui";

interface IDesktopCalendarPickerProps {
  open: boolean;
  departureDate: Date | null;
  returnDate: Date | null;
  setDepartureDate: (date: Date | null) => void;
  setReturnDate: (date: Date | null) => void;
  closePopup: () => void;
  hasSelectedDates: boolean;
  tripCategory?: TripCategory;
}

export const DesktopCalendarPicker = (props: IDesktopCalendarPickerProps) => {
  const {
    open,
    closePopup,
    hasSelectedDates,
    setDepartureDate,
    setReturnDate,
    departureDate,
    returnDate,
    tripCategory,
  } = props;

  const { t } = useI18nContext();

  const headerTitle = useMemo(() => {
    tripCategory === TripCategory.ONE_WAY
      ? t("searchControl.calendarOneWay")
      : t("searchControl.calendarRoundTrip");
  }, [t, tripCategory]);

  return (
    <DesktopPopupModal
      open={open}
      onClose={closePopup}
      className={clsx(
        "desktop-calendar-picker-popup-root",
        "desktop-flight-calendar-picker-popup-root",
        "flights-module"
      )}
      contentClassName="desktop-calendar-picker-wrapper"
      invisibleBackdrop={false}
    >
      <MonthAndDatePicker
        viewType={MonthAndDatePickerType.Horizontal}
        startDate={departureDate}
        endDate={returnDate}
        setStartDate={setDepartureDate}
        setEndDate={setReturnDate}
        header={headerTitle}
        className="b2b-flights"
      />
      <Button
        onClick={() => closePopup()}
        disabled={!hasSelectedDates}
        className="select-dates-button"
        variant="contained"
      >
        {t("searchControl.calendarPicker")}
      </Button>
    </DesktopPopupModal>
  );
};
