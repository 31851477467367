import { put, select } from "redux-saga/effects";
import {
  TripPricingSummaryResponse,
  TripPricingSummarySuccess,
  TripPricingSummary,
  Failure,
} from "@b2bportal/air-booking-api";

import { actions } from "../../actions";
import { fetchTripPricing } from "../../../../api/v1/book/book-flow/tripPricing";
import { selectedTripSelector } from "../../../shop/reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import { pricingParamsSelector } from "../../reducer";
import { flightApiConfigStoreKey } from "../../../../reducers/types";

export function* tripPricingSaga(_: actions.IValidatePassengers) {
  try {
    const state: IStoreState = yield select();
    const selectedTrip = selectedTripSelector(state);
    const params = pricingParamsSelector(state);
    const apiConfig = state[flightApiConfigStoreKey];

    if (!selectedTrip || !params) {
      yield put(actions.setFetchTripPricingCallStateFailure([]));
    }

    const response: TripPricingSummaryResponse = yield fetchTripPricing(
      params,
      apiConfig
    );

    const pricingSummary = (response as TripPricingSummarySuccess).value;
    if (pricingSummary) {
      yield put(actions.setTripPricing(pricingSummary as TripPricingSummary));
      yield put(actions.setFetchTripPricingCallStateSuccess());
    } else {
      yield put(
        actions.setFetchTripPricingCallStateFailure(
          (response as Failure).errors
        )
      );
    }
  } catch (e) {
    yield put(actions.setFetchTripPricingCallStateFailure([]));
  }
}
