import dayjs from "dayjs";
import { useContext, useEffect, useMemo, useRef } from "react";
import { RouteComponentProps, StaticContext } from "react-router";

import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  PortalItineraryStatusEnum,
  ScheduleChangeSeverity,
  getFlightInfoDetails,
} from "@hopper-b2b/types";
import { MyTripsMobileCard } from "@hopper-b2b/ui";
import { InfoBanner } from "@hopper-b2b/wallet";

import { ClientContext } from "../../../../../../App";
import { scrollTopWithOffset } from "../../component";
import { DATE_FORMAT } from "../../constants";
import { StatusBanner } from "../StatusBanner";
import { DesktopFlightCard } from "./components/DesktopFlightCard";
import { FlightCardConnectorProps } from "./container";

import "./styles.scss";

interface IFlightCardProps
  extends FlightCardConnectorProps,
    // eslint-disable-next-line @typescript-eslint/ban-types
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {
  flight: BookedFlightItineraryWithDepartureTime;
  expandedCard: string;
  onExpandCard: (cardId: string) => void;
  isMobile?: boolean;
  isPastTrips: boolean;
}

export const FlightCard = ({
  flight,
  isMobile,
  airportMap,
  airlineMap,
  history,
  expandedCard,
  onExpandCard,
  setSelectedFlight,
  isPastTrips,
  populateTripQueryParams,
  setOpenModal,
  tripsFilter,
}: IFlightCardProps) => {
  const clientContext = useContext(ClientContext);
  const { t } = useI18nContext();
  const flightRef = useRef<HTMLDivElement | null>(null);
  const {
    bookedItinerary: { id: itineraryId, paymentBreakdown, scheduleChange },
    departureTime,
    status,
  } = flight;

  const hasMajorScheduleChange =
    scheduleChange?.severity === ScheduleChangeSeverity.Major;
  const hasMinorScheduleChange =
    scheduleChange?.severity === ScheduleChangeSeverity.Minor ||
    (status === PortalItineraryStatusEnum.Modified && !scheduleChange);
  const isCancel = status === PortalItineraryStatusEnum.Canceled;
  const confirmationCodeClassName = isCancel ? "warning" : "";
  const showInfoBanner = Boolean(!paymentBreakdown);

  const headerText = useMemo(() => {
    if (departureTime) {
      return `${t("flight")}  |  ${t("departsOn")} ${dayjs(
        departureTime
      ).format(DATE_FORMAT)}`;
    }

    return t("flight");
  }, [departureTime, t]);

  useEffect(() => {
    if (!isMobile && flightRef?.current && expandedCard === itineraryId) {
      scrollTopWithOffset(flightRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedCard]);

  return isMobile ? (
    <MyTripsMobileCard
      onClick={() => {
        setSelectedFlight(flight);
        populateTripQueryParams(history);
      }}
      banner={
        <StatusBanner
          isMobile
          flight={flight}
          hasMajorScheduleChange={hasMajorScheduleChange}
          hasMinorScheduleChange={hasMinorScheduleChange}
          setOpenModal={setOpenModal}
        />
      }
      confirmationCodeClassName={confirmationCodeClassName}
      confirmationLabel={`${t("confirmation")}:`}
      startLabel={`${t("departsOn")}:`}
      label={t("flight")}
      {...getFlightInfoDetails(
        flight,
        (date: string) => dayjs(date).format(DATE_FORMAT),
        airportMap,
        airlineMap
      )}
    />
  ) : (
    <div ref={flightRef} className="my-trips-desktop-card flight-card desktop">
      <div className="flight-card-header">{headerText}</div>
      {/* <Typography variant="body1" className="summary-label"></Typography> */}
      {showInfoBanner && (
        <InfoBanner
          className="desktop-flight-card-banner"
          icon={clientContext.assets?.infoWhite}
          text={t("infoBanner")}
        />
      )}
      <StatusBanner
        flight={flight}
        hasMajorScheduleChange={hasMajorScheduleChange}
        hasMinorScheduleChange={hasMinorScheduleChange}
        isMobile={isMobile}
        setOpenModal={setOpenModal}
      />
      <DesktopFlightCard
        isCancel={isCancel}
        flight={flight}
        isPastTrips={isPastTrips}
        isMobile={isMobile}
        confirmationCodeClassName={confirmationCodeClassName}
        expandedCard={expandedCard}
        onExpandCard={onExpandCard}
        airlineMap={airlineMap}
        airportMap={airportMap}
        setOpenModal={setOpenModal}
        tripsFilter={tripsFilter}
      />
    </div>
  );
};
