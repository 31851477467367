import {
  CaseClassValue,
  HotelCancelFulfillReq,
  HotelCancelFulfillRes,
  NETWORK_CALL_FAILED,
  SelfServeEvents,
} from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { confirmHotelCancelPath } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

const confirmHotelCancel = async (quoteId: CaseClassValue | string) => {
  try {
    let req: HotelCancelFulfillReq;

    if (typeof quoteId === "string") {
      req = { clientTokenId: quoteId };
    } else {
      req = { quoteId };
    }

    const res = await axiosInstance.post<HotelCancelFulfillRes>(
      confirmHotelCancelPath,
      req
    );

    trackEvent({
      eventName: SelfServeEvents.ResponseSuccess,
      properties: {
        product: "hotel",
      },
    });

    return res.data;
  } catch (err) {
    let errString = "";

    if (typeof err === "string") {
      errString = err;
    } else if (Array.isArray(err)) {
      errString = err.map((err) => `${err.code} - ${err.details}`).join(", ");
    }

    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        location: window.location.pathname,
      },
    });
    trackEvent({
      eventName: SelfServeEvents.ResponseFailure,
      properties: {
        product: "hotel",
        reason: errString,
      },
    });

    throw err;
  }
};

export default confirmHotelCancel;
