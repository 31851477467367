import axios from "axios";
import { transferToSimilarFlightsPrefix } from "../paths";
import {
  TransferRequest,
  TransferResponse,
  NETWORK_CALL_FAILED,
  IApiConfig,
} from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";

export const transferToSimilarFlights = (
  body: TransferRequest,
  apiConfig?: IApiConfig
): Promise<TransferResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(transferToSimilarFlightsPrefix, body);

      resolve(res.data);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });

export default transferToSimilarFlights;
