import { useI18nContext } from "@hopper-b2b/i18n";
import { IMonthBucket, TripCategory } from "@hopper-b2b/types";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { CalendarTrackingProperties } from "../../modules/search/CalendarPickerButton/CalendarPicker/CalendarPicker";
import { DatePickerButton } from "../../modules/search/DatePickerButton";
import styles from "./CalendarPickerButton.module.scss";
import { CalendarPicker } from "@commbank/ui";
import { FocusedInput, START_DATE } from "@datepicker-react/hooks";
import { useState } from "react";

export interface ICalendarPickerButtonProps {
  retDate: Date | null;
  startDateError?: string;
  depDate: Date | null;
  endDateError?: string;
  classes?: string[];
  onComplete?: () => void;
  disabled?: boolean;
  onClick?: () => void;
  tripCategory: TripCategory;
  openCalendar: boolean;
  setDepartureDate: (value: Date | null) => void;
  setReturnDate: (value: Date | null) => void;
  closeCalendar: () => void;
  trackingProperties?: CalendarTrackingProperties;
  startLabel?: string;
  endLabel?: string;
  months?: IMonthBucket[];
  headerTitle: string;
  headerSubtitle?: string;
  useDialog: boolean;
  isHotel?: boolean;
}

export const CalendarPickerButton = ({
  classes = [],
  retDate,
  startDateError,
  depDate,
  endDateError,
  tripCategory,
  disabled,
  onClick,
  onComplete,
  openCalendar,
  setDepartureDate,
  setReturnDate,
  closeCalendar,
  trackingProperties,
  startLabel,
  endLabel,
  months,
  headerTitle,
  headerSubtitle,
  useDialog,
  isHotel,
}: ICalendarPickerButtonProps) => {
  const { t } = useI18nContext();
  const { matchesMobile: isMobile } = useDeviceTypes();

  const startLabelStr = isMobile
    ? depDate
      ? undefined
      : startLabel || t?.("checkin")
    : startLabel || t?.("checkin");

  const endLabelStr = isMobile
    ? depDate
      ? undefined
      : endLabel || t?.("checkout")
    : endLabel || t?.("checkout");

  const [defaultFocusedInput, setDefaultFocusedInput] =
    useState<FocusedInput>(START_DATE);

  return (
    <DatePickerButton
      classes={[styles.buttonContainer, ...classes]}
      startDate={depDate}
      startDateError={startDateError}
      startLabel={startLabelStr}
      endDate={retDate}
      endDateError={endDateError}
      buttonClass={styles.button}
      endLabel={endLabelStr}
      disabled={isMobile}
      dateFormat={"D MMM YYYY"}
      hideEndDate={false}
      hideSeparator={true}
      onClick={onClick}
      setDefaultFocusedInput={setDefaultFocusedInput}
      renderCalendarPopup={(calendarProps) => (
        <CalendarPicker
          {...calendarProps}
          onComplete={onComplete}
          open={openCalendar}
          departureDate={depDate}
          returnDate={retDate}
          setDepartureDate={setDepartureDate}
          setReturnDate={setReturnDate}
          tripCategory={tripCategory}
          closePopup={closeCalendar}
          disabled={disabled}
          trackingProperties={trackingProperties}
          isMobile={isMobile}
          months={months}
          headerTitle={headerTitle}
          headerSubtitle={headerSubtitle}
          useDialog={useDialog}
          isHotel={isHotel}
          defaultFocusedInput={defaultFocusedInput}
        />
      )}
    />
  );
};
