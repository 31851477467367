// Added for handling the material-ui bug discussed in https://hopchat.slack.com/archives/C011BJV2B8F/p1615477397022400
export const removeStyleSheetByMetaName = (metaName: string) => {
  Array.from(document.querySelectorAll("style")).forEach((element) => {
    try {
      if (element.dataset["meta"] === metaName) {
        element.parentNode?.removeChild(element);
      }
    } catch (err) {
      console.log(`Failed to remove ${metaName} stylesheet.`);
    }
  });
};
