import { TripType } from "@b2bportal/air-shopping-api";

export enum DisruptionTrackingEvents {
  VIEWED_AIR_DISRUPTION = "viewed_air_disruption",
  VIEWED_DELAY_GUARANTEE = "viewed_delay_guarantee",
  VIEWED_MISSED_CONNECTION = "viewed_missed_connection",
  TAP_DELAYS_PROTECTION_LEARN_MORE = "tap_delays_protection_learn_more",
  TAP_MISSED_CONNECTION_LEARN_MORE = "tap_missed_connection_learn_more",
  TAP_DELAY_TERMS = "tap_delay_terms",
  TAP_MISSED_CONNECTION_TERMS = "tap_missed_connection_terms",
  ADDED_DELAY_GUARANTEE = "added_delay_guarantee",
  DECLINED_DELAY_GUARANTEE = "declined_delay_guarantee",
  ADDED_MISSED_CONNECTION = "added_missed_connection",
  DECLINED_MISSED_CONNECTION = "declined_missed_connection",
  ENTER_DISRUPTION_EXERCISE_FLOW = "enter_disruption_exercise_flow",
  DELAY_POLICY_EXERCISED = "b2b_delay_policy_exercised",
  MISSED_CONNECTION_POLICY_EXERCISED = "b2b_missed_connection_policy_exercised",
}

export enum ExerciseType {
  refund = "refund",
  rebook = "rebook",
}

export interface DelayPolicyExercisedProperties {
  exercise_type: ExerciseType;
  delay_protection_compensation_cost_usd: number;
  charged_currency: string;
  base_total_usd: number;
  base_total: number;
  pax_total: number;
  total_price: number;
  total_price_usd: number;
  disruption_rebook_costs?: number;
  disruption_rebook_costs_usd?: number;
  disruption_original_trip_cost?: number;
  disruption_original_trip_cost_usd?: number;
}

export interface MissedConnectionPolicyExercisedProperties {
  exercise_type: ExerciseType;
  missed_connection_refund_costs: number;
  rebooking_direction?: string;
  rebooking_tool?: boolean;
  charged_currency: string;
  trip_type: TripType;
  base_total_usd: number;
  base_total: number;
  pax_total: number;
  total_price: number;
  total_price_usd: number;
  disruption_rebook_costs?: number;
  disruption_rebook_costs_usd?: number;
  disruption_original_trip_cost?: number;
  disruption_original_trip_cost_usd?: number;
}
