import axios from "axios";
import { config } from "../../config";

import {
  DenyScheduleChangeRequest,
  DenyScheduleChangeResponse,
} from "@hopper-b2b/types";
import { flightItinerariesApiPrefix } from "../paths";

export const denyScheduleChange = async (
  req: DenyScheduleChangeRequest
): Promise<DenyScheduleChangeResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        `${flightItinerariesApiPrefix}/denyScheduleChange`,
        req,
        {
          baseURL: config.baseURL,
        }
      );
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};
