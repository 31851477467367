import {
  ApacDesktopPopupModal,
  ApacFintechPurchase,
  ApacIcon,
  ApacIconName,
} from "@commbank/ui";
import { Coverage, DisruptionOffer } from "@b2bportal/air-disruption-api";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  DisruptionTrackingEvents,
  FintechCoverageType,
} from "@hopper-b2b/types";
import { MobilePopoverCard } from "@hopper-b2b/ui";
import {
  HeaderPayload,
  useDeviceTypes,
  useUberBridge,
} from "@hopper-b2b/utilities";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClientContext } from "../../../../ApacFlightApp";
import { setSelectedAirDisruptionOffer } from "../../actions/actions";
import {
  getAirDisruptionOffers,
  getSelectedAirDisruptionOffer,
} from "../../reducer";
import { DisruptionHowItWorksContent } from "./DisruptionHowItWorksContent/DisruptionHowItWorksContent";
import "./styles.scss";

const OPEN_DISRUPTION_DETAILS = "open_disruption_details";

export type DisruptionContentProps = {
  goToNextStep?: () => void;
  hasDefaultSelection?: boolean;
  hideContinueButton?: boolean;
};

export const AirDisruptionOffer = ({
  goToNextStep,
  hasDefaultSelection,
  hideContinueButton,
}: DisruptionContentProps) => {
  const { setHeader } = useUberBridge();
  const { t, formatFiatCurrency } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const clientContext = useContext(ClientContext);

  const dispatch = useDispatch();

  const airDisruptionOffers = useSelector(getAirDisruptionOffers);
  const selectedOffer = useSelector(getSelectedAirDisruptionOffer);

  const [learnMoreOpen, setLearnMoreOpen] = useState<boolean>(false);

  const noCoverageSelected = selectedOffer === null;

  // Clear selection on load
  useEffect(() => {
    if (!hasDefaultSelection) {
      dispatch(setSelectedAirDisruptionOffer(selectedOffer));
    }
  }, [dispatch, hasDefaultSelection, selectedOffer]);

  useEffect(() => {
    trackEvent({
      eventName: DisruptionTrackingEvents.VIEWED_AIR_DISRUPTION,
      properties: undefined,
    });
  }, []);

  useEffect(() => {
    if (learnMoreOpen) {
      trackEvent({
        eventName: DisruptionTrackingEvents.TAP_DELAYS_PROTECTION_LEARN_MORE,
        properties: undefined,
      });
    }
  }, [learnMoreOpen]);

  const handleSelectLearnMore = useCallback(() => {
    setLearnMoreOpen(!learnMoreOpen);
  }, [learnMoreOpen]);

  useEffect(() => {
    setHeader({
      customButtons: [
        {
          text: t("learnMore"),
          action: {
            name: OPEN_DISRUPTION_DETAILS,
            callback: handleSelectLearnMore,
          },
        },
      ],
    } as HeaderPayload);
  }, [handleSelectLearnMore, setHeader, t]);

  const handleOfferSelection = useCallback(
    (offer: DisruptionOffer | null) => {
      if (offer) {
        dispatch(setSelectedAirDisruptionOffer(offer));
        trackEvent({
          eventName: DisruptionTrackingEvents.ADDED_DELAY_GUARANTEE,
          properties: offer.quotes[0].trackingProperties,
        });
      } else {
        dispatch(setSelectedAirDisruptionOffer(null));
        trackEvent({
          eventName: DisruptionTrackingEvents.DECLINED_DELAY_GUARANTEE,
          properties: {},
        });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (hasDefaultSelection && airDisruptionOffers) {
      handleOfferSelection(airDisruptionOffers[0]);
      dispatch(setSelectedAirDisruptionOffer(airDisruptionOffers[0]));
    }
  }, [
    dispatch,
    handleOfferSelection,
    hasDefaultSelection,
    airDisruptionOffers,
  ]);

  const getDescriptions = (coverages: Coverage[], t: any) => {
    return coverages.map((c) => {
      switch (c) {
        case Coverage.Cancellation:
          return t("disruptionPurchase.rebookDescription");
        case Coverage.Delay:
          return t("disruptionPurchase.refundDescription");
        // Missed Connection
        default:
          return t("missedConnectionInfoPopup.description");
      }
    });
  };

  const getHeader = (coverages: Coverage[], t: any) => {
    const titles = coverages.map((c) => t(`disruptionPurchase.${c}`));
    if (
      titles.includes(Coverage.Delay) &&
      titles.includes(Coverage.Cancellation)
    )
      return t("disruptionPurchase.optionDelayAndCancel");
    else {
      return t("disruptionPurchase.optionDelay");
    }
  };

  const benefitDescriptions = [
    t("disruptionPurchase.rebookDescription"),
    t("disruptionPurchase.refundDescription"),
    t("missedConnectionInfoPopup.description"),
  ];

  const getOptions = useCallback(
    (airDisruptionOffers: DisruptionOffer[]) => {
      const options = airDisruptionOffers.map((offer) => {
        const quote = offer.quotes[0];
        const airDisruptionOfferCost = formatFiatCurrency(
          quote?.pricePerPax?.fiat
        );
        return {
          value: getHeader(quote.coverages, t),
          title: getHeader(quote.coverages, t),
          cost: airDisruptionOfferCost,
          description: getDescriptions(quote.coverages, t),
          selected: selectedOffer === offer,
          onClick: () => handleOfferSelection(offer),
          ctaLabel: t("acceptCta"),
        };
      });
      options.push({
        value: FintechCoverageType.None,
        title: t("disruptionPurchase.noCoverage"),
        selected: noCoverageSelected,
        onClick: () => handleOfferSelection(null),
        ctaLabel: t("declineCta"),
        cost: "",
        description: [],
      });
      return options;
    },
    [
      formatFiatCurrency,
      handleOfferSelection,
      noCoverageSelected,
      selectedOffer,
      t,
    ]
  );

  return (
    <div className="disruption-purchase">
      <ApacFintechPurchase
        className="disruption"
        benefitDescriptions={benefitDescriptions}
        options={getOptions(airDisruptionOffers || [])}
        headerTitle={t("disruptionPurchase.productTitle")}
        headerSubtitle={t("flightDisruptionGuaranteeSubtitle") || ""}
        clientAssets={clientContext.assets}
        ctaLabel={
          selectedOffer && !noCoverageSelected
            ? t("acceptCta")
            : t("declineCta")
        }
        handleContinue={hideContinueButton ? undefined : goToNextStep}
        disabled={selectedOffer === undefined}
        selectedProduct={{
          selected: !!selectedOffer || noCoverageSelected,
          cost: selectedOffer
            ? formatFiatCurrency(selectedOffer.quotes[0].premiumAmount.fiat)
            : undefined,
        }}
        isMobile={matchesMobile}
        onLearnMoreClick={() => setLearnMoreOpen(true)}
      />
      {matchesMobile ? (
        <MobilePopoverCard
          open={learnMoreOpen}
          className="full-screen"
          contentClassName="full-screen-content-container"
          onClose={() => setLearnMoreOpen(false)}
          topRightButton={
            <ApacIcon
              name={ApacIconName.Close}
              onClick={() => setLearnMoreOpen(false)}
            />
          }
        >
          <DisruptionHowItWorksContent
            onClose={() => setLearnMoreOpen(false)}
          />
        </MobilePopoverCard>
      ) : (
        <ApacDesktopPopupModal
          className="desktop-flight-shop-modal"
          contentClassName="modal-content"
          open={learnMoreOpen}
          onClose={() => setLearnMoreOpen(false)}
        >
          <DisruptionHowItWorksContent
            onClose={() => setLearnMoreOpen(false)}
          />
        </ApacDesktopPopupModal>
      )}
    </div>
  );
};
