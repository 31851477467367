import {
  AirCancelEligibilityRequest,
  AirCancelEligibilityResponse,
  airCancelApi,
} from "@b2bportal/air-cancel-api";
import { axiosInstance } from "@hopper-b2b/api";

export const getFlightCancelEligibility = async (
  reservationId
): Promise<AirCancelEligibilityResponse> => {
  const body: AirCancelEligibilityRequest = {
    reservationId,
  };

  return new Promise((resolve, reject) => {
    try {
      airCancelApi(axiosInstance)
        .apiV0SelfserveFlightCancelEligibilityPost(body)
        .then((res) => resolve(res.data));
    } catch (e) {
      reject(e);
    }
  });
};
