import { put, select } from "redux-saga/effects";

import { fetchTripDetails as fetchDetails } from "../../../api/v1/shop/fetchTripDetails";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import { FlightShopType, TripDetails } from "@hopper-b2b/types";
import { flightApiConfigStoreKey } from "../../../reducers/types";
import { IStoreState } from "../../../reducers/types";
import { fetchChfarTripDetails } from "@hopper-b2b/api";

export function* fetchTripDetails(action: actions.IFetchTripDetails) {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const chfarId = state.flightShop.chfarId;
    const isChfarFlightShop =
      state.flightShop.flightShopType === FlightShopType.CHFAR_EXERCISE &&
      chfarId;

    const tripDetails: TripDetails = isChfarFlightShop
      ? yield fetchChfarTripDetails(action.tripId, chfarId, apiConfig)
      : yield fetchDetails(action.tripId, apiConfig);
    yield put(actions.setTripDetails(tripDetails));
  } catch (e) {
    Logger.debug(e);
  }
}
