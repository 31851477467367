import {
  AcceptScheduleChangeRequest,
  AcceptScheduleChangeResponse,
  NETWORK_CALL_FAILED,
} from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { acceptScheduleChangePath } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

const acceptScheduleChange = async (req: AcceptScheduleChangeRequest) => {
  try {
    const res = await axiosInstance.post<AcceptScheduleChangeResponse>(
      acceptScheduleChangePath,
      req
    );

    if (res.data) {
      return res.data;
    }

    throw res;
  } catch (err) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        location: window.location.pathname,
      },
    });
  }
};

export default acceptScheduleChange;
