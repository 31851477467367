import { PropsWithChildren, useId } from "react";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import styles from "./HotelDetailsSection.module.scss";
import { useDeviceTypes } from "@hopper-b2b/utilities";

interface Props extends PropsWithChildren {
  title?: string;
  id?: string;
  className?: string;
}

export function HotelDetailsSection({ children, title, id, className }: Props) {
  const headingId = useId();
  const { matchesMobile: isMobile } = useDeviceTypes();

  return (
    <section
      id={id}
      aria-labelledby={headingId}
      className={clsx(
        className,
        styles.HotelDetailsSection,
        isMobile && styles.mobile
      )}
    >
      {title ? (
        <Typography id={headingId} className={styles.Heading} variant="h2">
          {title}
        </Typography>
      ) : null}
      {children}
    </section>
  );
}
