import { trackEvent } from "@hopper-b2b/api";

import {
  ITrackingPropertiesRequest,
  updateTrackingPropertiesRequest,
} from "@hopper-b2b/utilities";
import {
  LodgingShopTrackingEvents,
  LodgingCheckoutTrackingEvents,
} from "@hopper-b2b/types";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getAvailabilityTrackingProperties } from "../modules/availability/reducer/selectors";
import {
  getHotelShopTrackingProperties,
  getSelectedRoomTrackingProperties,
  getSelectedRoomRateTrackingProperties,
  getSelectedLodging,
} from "../modules/shop/reducer/selectors";

export enum FlightShopTrackingEvents {
  air_entry = "air_entry",
}

export enum FlightCheckoutTrackingEvents {
  air_view_add_payment_method = "add_payment_method",
  air_select_rewards = "select_rewards",
  air_select_payment = "select_payment",
  air_view_payment_method = "view_payment_method",
  complete_buy_air = "complete_buy_air",
}

export type ApacTrackingEvents =
  | LodgingShopTrackingEvents
  | LodgingCheckoutTrackingEvents
  | FlightShopTrackingEvents
  | FlightCheckoutTrackingEvents;

interface BaseHotelProperties {
  market: string;
  country: string;
  hotel_advance: string;
}
interface BaseFlightsProperties {
  market: string;
  country: string;
}

export enum ProductType {
  Hotel = "hotel",
  Flight = "flight",
  Car = "car",
}

export const useTrackEvents = () => {
  const hotelAvailabilityTrackingProperties = useSelector(
    getAvailabilityTrackingProperties
  );
  const hotelShopTrackingProperties = useSelector(
    getHotelShopTrackingProperties
  );
  const selectedRoomTrackingProperties = useSelector(
    getSelectedRoomTrackingProperties
  );
  const selectedRoomRateTrackingProperties = useSelector(
    getSelectedRoomRateTrackingProperties
  );
  const selectedLodging = useSelector(getSelectedLodging);

  const buildTrackingProperties = useCallback(
    (
      eventType: ApacTrackingEvents,
      productType: ProductType
    ): ITrackingPropertiesRequest => {
      const trackingProperties = {
        properties: {
          ...(hotelAvailabilityTrackingProperties?.properties || {}),
          ...(hotelShopTrackingProperties?.trackingProperties?.properties ||
            {}),
          ...(selectedRoomTrackingProperties?.properties || {}),
        },
        encryptedProperties: [
          hotelAvailabilityTrackingProperties?.encryptedProperties,
          ...(hotelShopTrackingProperties?.trackingProperties
            ?.encryptedProperties || []),
          selectedRoomTrackingProperties?.encryptedProperties,
        ],
      };

      switch (eventType) {
        case LodgingCheckoutTrackingEvents.hotel_review_details:
        case LodgingCheckoutTrackingEvents.hotel_complete_buy:
        case LodgingCheckoutTrackingEvents.hotel_view_travelers:
        case LodgingCheckoutTrackingEvents.hotel_choose_traveler:
          Object.assign(
            trackingProperties?.properties,
            selectedRoomRateTrackingProperties?.properties
          );
          trackingProperties.encryptedProperties.push(
            selectedRoomRateTrackingProperties?.encryptedProperties
          );
          break;
        case LodgingShopTrackingEvents.hotel_viewed_bed_types:
        case LodgingShopTrackingEvents.hotel_tapped_room_selection_continue: {
          if (selectedLodging?.trackingPropertiesV2) {
            Object.assign(
              trackingProperties?.properties,
              selectedLodging.trackingPropertiesV2.properties || {}
            );
            trackingProperties.encryptedProperties.push(
              selectedLodging.trackingPropertiesV2.encryptedProperties
            );
          }
          break;
        }
      }

      let baseProperties: BaseHotelProperties | BaseFlightsProperties | object =
        {};

      switch (productType) {
        case ProductType.Hotel:
          // TODO: change this to get dynamically
          baseProperties = {
            market: "apac/australia",
            country: "australia",
            hotel_advance: "",
          };
          trackingProperties.properties = {
            ...baseProperties,
            ...trackingProperties.properties,
          };
          break;

        default:
          break;
      }

      return trackingProperties;
    },
    []
  );

  return useCallback(
    (
      eventType: ApacTrackingEvents,
      product_type: ProductType,
      properties?: { [key: string]: unknown } | object,
      encryptedProperties?: string[]
    ) => {
      const stateTrackinProperties = buildTrackingProperties(
        eventType,
        product_type
      );
      const updatedTrackingProperties = updateTrackingPropertiesRequest(
        stateTrackinProperties,
        [],
        properties
      );

      trackEvent({
        eventName: eventType,
        properties: updatedTrackingProperties.properties,
        encryptedProperties: [
          ...(encryptedProperties || []),
          ...updatedTrackingProperties.encryptedProperties,
        ].filter((p) => p?.length > 0),
      });
    },
    [buildTrackingProperties]
  );
};
