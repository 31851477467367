import { TripSlice } from "@b2bportal/air-shopping-api";

export enum ScheduleChangeState {
  ContactAirline,
  ContactSupport,
  Display,
  InProgress,
  Requested,
  Accepted,
  Failure,
}

export interface ScheduleChange {
  id: string;
  next: TripSlice[];
  severity: ScheduleChangeSeverity;
  created: string;
  expiry: string;
}

export enum ScheduleChangeUserActionEnum {
  Accept = "Accept",
  Deny = "Deny",
}

export enum ScheduleChangeSeverity {
  Major = "Major",
  Minor = "Minor",
  Unknown = "Unknown",
}

export interface AcceptScheduleChangeRequest {
  itineraryId: string;
}
export interface AcceptScheduleChangeResponse {
  success: boolean;
  itinerary: string;
  errorCodes?: string[];
}

export interface DenyScheduleChangeRequest {
  itineraryId: string;
  outboundPreferences: ScheduleChangeChoice[];
  returnPreferences: ScheduleChangeChoice[];
  additionalRemarks?: string;
}
export interface DenyScheduleChangeResponse {
  success: boolean;
  itinerary: string;
  errorCodes?: string[];
}

export interface ScheduleChangeChoice {
  departAfter: string;
  departBefore: string;
}

export enum TimeGroupingEnum {
  EARLY_MORNING = "EARLY_MORNING",
  MORNING = "MORNING",
  AFTERNOON = "AFTERNOON",
  EVENING = "EVENING",
}

export interface ITimeGrouping {
  grouping: TimeGroupingEnum;
  label: string;
  sublabel: string;
}
