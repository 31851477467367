import { FiatPrice, RewardsPrice, TimeToLive } from "@hopper-b2b/types";
import { getPriceText, getRewardText } from "@hopper-b2b/utilities";

export const getDurationText = (timeToLive: TimeToLive) => {
  const inDays = timeToLive.inDays;
  const inHours = timeToLive.inHours;
  const inSeconds = timeToLive.inSeconds;

  if (inDays) {
    return `${inDays} ${inDays > 1 ? "days" : "day"}`;
  } else if (inHours) {
    return `${inHours} ${inHours > 1 ? "hours" : "hour"}`;
  }
  // note: we are currently only offering price freezes as little as 12 hours, so it will never return "X second(s)"
  return `${inSeconds} ${inSeconds > 1 ? "seconds" : "second"}`;
};

export const LOADING_PRICE_FREEZE = "Loading Price Freeze";
export const PRICE_FREEZE_INFO_HEADER = "Freeze the price, book later";
export const PRICE_FREEZE_INFO_HEADER_TITLE_CASE =
  "<strong>Freeze the Price</strong>, Book Later";
export const PRICE_FREEZE_INFO_DETAILS_PREFIX = "Not ready to book? ";
export const PRICE_FREEZE_INFO_DETAILS = (
  price: string,
  rewards: string,
  timeToLive: TimeToLive | undefined,
  highlightedRewards?: boolean
) =>
  `Pay a fee of <strong>${price}</strong> or ${
    highlightedRewards ? `<strong>${rewards}</strong>` : rewards
  } per traveler to freeze the price of this trip for ${
    timeToLive ? getDurationText(timeToLive) : ""
  }.`;

export const PRICE_FREEZE_INFO_LOWEST_PRICE_DETAILS = (
  price: string,
  rewards: string,
  timeToLive: TimeToLive | undefined,
  highlightedRewards?: boolean
) =>
  `Pay a fee of <strong>${price}</strong> or ${
    highlightedRewards ? `<strong>${rewards}</strong>` : rewards
  } per traveler to freeze the current lowest price of this trip for ${
    timeToLive ? getDurationText(timeToLive) : ""
  }.`;

export const PRICE_FREEZE_INFO_BUTTON = "Freeze Price";
export const PRICE_FREEZE_INFO_BUTTON_LONGER = "Freeze This Price";
export const PRICE_FREEZE_INFO_LINK_LABEL = "View price freeze info";

export const PRICE_FREEZE_DETAILS_HEADER = `We selected the flights with the <strong style="display: inline-block;">current lowest price</strong> for you.`;
export const PRICE_FREEZE_DETAILS_SUBTITLE =
  "You can also choose your own set of flights and freeze the price when reviewing your itinerary.";
export const PRICE_FREEZE_DETAILS_FROZEN_PRICE_DURATION = (
  timeToLive: TimeToLive | undefined
) =>
  `Current price being frozen for <strong>${
    timeToLive ? getDurationText(timeToLive) : ""
  }</strong>:`;

export const PRICE_FREEZE_DETAILS_FROZEN_PRICE_VALUE = (
  frozenPrice:
    | { fiat: FiatPrice; rewards: RewardsPrice | undefined }
    | undefined
) =>
  frozenPrice
    ? `<strong>${getPriceText({
        price: frozenPrice.fiat,
      })}</strong>${
        frozenPrice.rewards
          ? ` or ${getRewardText({ reward: frozenPrice.rewards })}`
          : ""
      }`
    : "";

export const PRICE_FREEZE_DETAILS_BUTTON_TEXT = "Continue";
export const PRICE_FREEZE_DETAILS_BUTTON_TEXT_LONGER = "Continue to Checkout";
export const PRICE_FREEZE_DETAILS_BUTTON_LABEL = "Price freeze checkout";
export const PRICE_FREEZE_CHOOSE_ANOTHER_BUTTON_TEXT = "Choose Another Flight";
export const PRICE_FREEZE_CHOOSE_ANOTHER_BUTTON_LABEL = "Choose another flight";
export const PRICE_FREEZE_DETAILS_READ_TERMS_TEXT = "Read Terms and Conditions";
export const PRICE_FREEZE_DETAILS_READ_TERMS_LABEL =
  "Price freeze terms and conditions";
export const ROUND_TRIP = "Round-trip,";
export const PER_TRAVELER = "per traveler";
