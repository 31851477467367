import { connect, ConnectedProps } from "react-redux";
import { FlightSearch } from "./component";
import { resetState } from "../../actions/actions";
import { actions } from "../search/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  resetState,
  fetchSearchFromUrl: actions.fetchSearchFromUrl,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightSearch = connector(FlightSearch);
