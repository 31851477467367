import { TripPricingSummary } from "@b2bportal/air-booking-api";
import { useI18nContext, useI18nCurrency } from "@hopper-b2b/i18n";
import { FiatPrice, PersonId } from "@hopper-b2b/types";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { ApacActionButton } from "../../ApacActionButton";
import { ApacDesktopPopupModal } from "../../ApacDesktopPopupModal";
import { ApacMobileContinueButton } from "../../ApacMobileContinueButton";
import { ApacMobileFullScreenModal } from "../../ApacMobileFullScreenModal";
import { ApacRadio } from "../../ApacRadio";
import { CBASpinner } from "../../CBASpinner";
import "./InfantSeatPickerModal.styles.scss";

export interface IInfantSeatPickerModalProps {
  currentInfantToSelectSeat?: PersonId;
  isLoading: boolean;
  onClose: () => void;
  onContinue: (seatType: SeatType) => void;
  tripPricing: TripPricingSummary | null;
}

type SeatType = "OnLap" | "OwnSeat";

const getTotalPrice = (pricing: FiatPrice[]) =>
  pricing.reduce((sum, cur) => sum + cur.value, 0);

export const InfantSeatPickerModal = (props: IInfantSeatPickerModalProps) => {
  const {
    currentInfantToSelectSeat,
    isLoading,
    onClose,
    onContinue,
    tripPricing,
  } = props;

  const { t } = useI18nContext();
  const { matchesMobile: isMobile } = useDeviceTypes();
  const [infantSeatPricing, setInfantSeatPricing] = useState({
    lapSeatPricing: tripPricing?.pricingByPassenger?.find(
      (passengerPricing) => passengerPricing.passengerType === "INF"
    ),
    ownSeatPricing: tripPricing?.pricingByPassenger?.find(
      (passengerPricing) => passengerPricing.passengerType === "INS"
    ),
  });

  useEffect(() => {
    setInfantSeatPricing({
      lapSeatPricing: tripPricing?.pricingByPassenger?.find(
        (passengerPricing) =>
          passengerPricing.person.id === currentInfantToSelectSeat
      ),
      ownSeatPricing: tripPricing?.pricingByPassenger?.find(
        (passengerPricing) =>
          passengerPricing.person.id !== currentInfantToSelectSeat &&
          passengerPricing.baseWithoutMargin.fiat.value +
            passengerPricing.taxes.fiat.value !==
            0
      ),
    });
  }, [currentInfantToSelectSeat, tripPricing]);

  const totalPricing = {
    lapSeatTotal: infantSeatPricing?.lapSeatPricing
      ? getTotalPrice([
          infantSeatPricing.lapSeatPricing.baseWithoutMargin.fiat,
          infantSeatPricing.lapSeatPricing.taxes.fiat,
        ])
      : 0,
    ownSeatPricing: infantSeatPricing?.ownSeatPricing
      ? getTotalPrice([
          infantSeatPricing.ownSeatPricing.baseWithoutMargin.fiat,
          infantSeatPricing.ownSeatPricing.taxes.fiat,
        ])
      : 0,
  };

  const currencySymbol =
    tripPricing?.totalPricing?.total.fiat.currencySymbol || "$";

  const InfantSeatPickerModalContent = () => {
    const [seatType, setSeatType] = useState<SeatType>("OnLap");

    const handleOnContinue = () => {
      onContinue(seatType);
    };

    return isLoading ? (
      <div className="loading-container">
        <CBASpinner />
        <Typography className="subheading" variant="body2">
          {t("infantModal.loading")}
        </Typography>
      </div>
    ) : (
      <div className="modal-content-container">
        <div className="title-container">
          <Typography variant="h1" className="title">
            {t("infantModal.title")}
          </Typography>
          <Typography variant="body2">{`${t("infantModal.subtitle")} ${t(
            "infantModal.fareAvailableLater"
          )}`}</Typography>
        </div>

        <div className="rows-container">
          <SelectRow
            label={t("infantModal.onLap")}
            description={t("infantModal.perInfantDescription")}
            amount={totalPricing.lapSeatTotal}
            selected={seatType === "OnLap"}
            onClick={() => setSeatType("OnLap")}
            currencySymbol={currencySymbol}
          />
          <SelectRow
            label={t("infantModal.ownSeat")}
            description={t("infantModal.perInfantDescription")}
            amount={totalPricing.ownSeatPricing}
            selected={seatType === "OwnSeat"}
            onClick={() => setSeatType("OwnSeat")}
            currencySymbol={currencySymbol}
          />
        </div>

        {isMobile ? (
          <ApacMobileContinueButton handleContinue={handleOnContinue} />
        ) : (
          <div className="button-container">
            <ApacActionButton
              onClick={handleOnContinue}
              className="continue-button"
              message={t("save")}
            />
            <ApacActionButton
              onClick={onClose}
              variant="outlined"
              message={t("close.button")}
            />
          </div>
        )}
      </div>
    );
  };

  return isMobile ? (
    <ApacMobileFullScreenModal
      open={Boolean(currentInfantToSelectSeat)}
      onClose={onClose}
      className="infant-seat-lap-select-modal mobile"
      contentClassName="modal-content"
    >
      <InfantSeatPickerModalContent />
    </ApacMobileFullScreenModal>
  ) : (
    <ApacDesktopPopupModal
      open={Boolean(currentInfantToSelectSeat)}
      onClose={onClose}
      className="desktop infant-seat-lap-select-modal apac-desktop-generic-modal"
    >
      <InfantSeatPickerModalContent />
    </ApacDesktopPopupModal>
  );
};

interface ISelectRow {
  selected: boolean;
  label: string;
  amount: number;
  description: string;
  onClick: () => void;
  currencySymbol: string;
}

const SelectRow = ({
  selected,
  label,
  amount,
  description,
  onClick,
}: ISelectRow) => {
  const { t } = useI18nContext();
  const { formatCurrency } = useI18nCurrency();
  const price = amount === 0 ? t("free") : formatCurrency(amount);
  const suffix =
    amount === 0 ? t("passengerInformation.freeDescription") : description;

  return (
    <div className={clsx("select-row", { selected })} onClick={onClick}>
      <ApacRadio className={clsx("radio", { selected })} checked={selected} />
      <div
        className={clsx("select-row-text-container", { selected })}
        onClick={onClick}
      >
        <Typography variant="body1">{label}</Typography>
        {/* Hide the price, we are not able to get accurate price for infants or children before poll quote */}
        {/* <div className="line">
          <Typography variant="body1" className="price">
            {price}
          </Typography>
          <Typography variant="body2">{suffix}</Typography>
        </div> */}
      </div>
    </div>
  );
};
