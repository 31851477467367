import { IApiConfig } from "@hopper-b2b/types";

export const CBA_LANDING_PAGE_URL = "https://commbank.com.au/travelbooking";
export const PATH_HOME = "/";
export const PATH_SUFFIX_SEARCH = "search/";

export const PATH_FLIGHTS = "flights/";
export const PATH_FLIGHTS_SHOP = PATH_FLIGHTS + "shop/";
export const PATH_FLIGHTS_BOOK = PATH_FLIGHTS + "book/";
export const PATH_FLIGHTS_FREEZE = PATH_FLIGHTS + "freeze/";
export const PATH_FLIGHTS_FREEZE_PURCHASE = PATH_FLIGHTS_FREEZE + "purchase/";
export const PATH_FLIGHTS_FREEZE_OVERVIEW = PATH_FLIGHTS_FREEZE + "overview/";
export const PATH_FLIGHTS_SEARCH = PATH_FLIGHTS + PATH_SUFFIX_SEARCH;
export const PATH_FLIGHTS_BOOK_CONFIRMATION = PATH_FLIGHTS_BOOK + "confirm/";

export const PATH_HOTELS = "hotels/";
export const PATH_HOTELS_BOOK_AVAILABILITY = PATH_HOTELS + "availability/";
export const PATH_HOTELS_BOOK = PATH_HOTELS + "book/";
export const PATH_HOTELS_SEARCH = PATH_HOTELS + PATH_SUFFIX_SEARCH;
export const PATH_HOTELS_BOOK_CONFIRMATION = PATH_HOTELS_BOOK + "confirm/";

export const PATH_CARS = "cars/";
export const PATH_CARS_SEARCH = PATH_CARS + PATH_SUFFIX_SEARCH;
export const PATH_CARS_AVAILABILITY = PATH_CARS + "availability/";
export const PATH_CARS_BOOK = PATH_CARS + "book/";
export const PATH_CARS_BOOK_CONFIRMATION = PATH_CARS_BOOK + "confirm/";

export const PATH_TRIPS = "trips/";

export const PATH_PROFILE = "profile/";

export const PATH_TERMS = "terms/";

export const PATH_TRAVELLERS_LIST = "traveller/";

export const PATH_CUSTOMER_SUPPORT = "customer-support/";

export const PRICE_DROP_PROTECTION = PATH_TERMS + "price-drop-protection/";
export const BEST_PRICE_GUARANTEE = PATH_TERMS + "best-price-guarantee/";
export const PRICE_FREEZE = PATH_TERMS + "frozen-prices/";
export const PRICE_PREDICTION_PRICE_WATCH =
  PATH_TERMS + "price-prediction-price-watch/";

export const PATH_SITE_TERMS_OF_USE = PATH_HOME + "site-terms-of-use/";

export const PATH_AUTH = `auth/`;
export const PATH_AUTH_FTUX = `ftux/`;
export const PATH_START_SESSION = `startsession/`;
export const PATH_INVALID_SESSION = `invalidsession/`;
export const PATH_LOGOUT = `logout/`;
export const PATH_AUTH_START_SESSION = `${PATH_AUTH}${PATH_START_SESSION}`;
export const PATH_AUTH_INVALID_SESSION = `${PATH_AUTH}${PATH_INVALID_SESSION}`;
export const PATH_AUTH_LOGOUT = `${PATH_AUTH}${PATH_LOGOUT}`;

export const PATH_INACTIVE_ERROR = "inactive-error/";
export const PATH_ACCESS_DENIED = "access-denied/";
export const PATH_COMING_SOON = "coming-soon/";

export const PATH_LUXURY_HOTELS = PATH_HOME + "luxury-hotels/";
export const PATH_LUXURY_HOTELS_SEARCH =
  PATH_LUXURY_HOTELS + PATH_SUFFIX_SEARCH;

export const PATH_EXCHANGE = `exchange`;
export const PATH_AGENT_IMPERSONATION = "impersonate/user/";

export const PATH_GEN_AUTH = `/api/v0/auth/`;
export const PATH_GEN_AUTH_START_SESSION = `${PATH_GEN_AUTH}startSession`;

export const HIDDEN_FOOTER_UNPROTECTED_PATHS = [
  PATH_AUTH_FTUX,
  PATH_AUTH_START_SESSION,
  PATH_AUTH_INVALID_SESSION,
];

export const NO_FOOTER_PADDING_PATHS: string[] = [];

export const NO_FOOTER_PADDING_UNPROTECTED_PATHS: string[] = [
  PATH_AUTH_FTUX,
  PATH_AUTH_START_SESSION,
  PATH_AUTH_INVALID_SESSION,
];

export const DISPLAY_HOMEPAGE_TAKEOVER_PATHS = [
  PATH_FLIGHTS,
  PATH_HOTELS,
  PATH_CARS,
  PATH_HOME,
];

export const HIDDEN_FOOTER_SEARCH_MOBILE = [
  PATH_FLIGHTS_SEARCH,
  PATH_HOTELS_SEARCH,
  PATH_CARS_SEARCH,
  PATH_LUXURY_HOTELS_SEARCH,
  PATH_TRIPS,
  PATH_FLIGHTS_BOOK,
];

export const UNPROTECTED_PATHS = [
  PATH_AUTH,
  // PATH_TERMS,
  // PATH_TERMS_OF_SERVICE,
  // PATH_SITE_TERMS_OF_USE,
  // PRICE_DROP_PROTECTION,
  // BEST_PRICE_GUARANTEE,
  // PRICE_PREDICTION_PRICE_WATCH,
  // PRICE_FREEZE,
];

export const HIDDEN_HEADER_UNPROTECTED_PATHS = [
  PATH_AUTH,
  PATH_AUTH_FTUX,
  PATH_AUTH_START_SESSION,
  PATH_AUTH_INVALID_SESSION,
  "/mockCbaLogin",
];

export const HIDDEN_BANNER_UNPROTECTED_PATHS = [
  PATH_AUTH_FTUX,
  PATH_AUTH_START_SESSION,
  PATH_AUTH_INVALID_SESSION,
  ...UNPROTECTED_PATHS,
];

export const apiVersionPrefix = "/api/v0";

export const fetchUserInfoPath = `${apiVersionPrefix}/user/getInfo`;
export const analyticsApiPrefix = `${apiVersionPrefix}/tracking`;
export const rewardsApiPrefix = `${apiVersionPrefix}/rewards`;

export const experimentsApiPrefix = `${apiVersionPrefix}/experiments`;
export const takeoversApiPrefix = `${apiVersionPrefix}/takeovers`;

export const apiConfig: IApiConfig = {
  experimentsApiPrefix,
  rewardsApiPrefix,
  takeoversApiPrefix,
  analyticsApiPrefix,
  fetchUserInfoPath,
};
