import { useCallback } from "react";
import dayjs from "dayjs";
import clsx from "clsx";
import { ButtonWrap, Icon, IconName } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { removeTimezone } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import { AddOn, AddOnEnum } from "./types";
import "./styles.scss";

interface IAddOnsContent {
  onClick: () => void;
  label: string;
  date: string;
}

const AddOnsContent = ({ onClick, label, date }: IAddOnsContent) => {
  const { t } = useI18nContext();
  return (
    <div className={clsx("add-on-item", "cfar")}>
      <ButtonWrap
        className="cfar-modal-button"
        aria-label={t("cfarButtonAriaText")}
        onClick={onClick}
      >
        <Icon className="cfar-icon" name={IconName.CheckShieldBlue} />
        <label className="cfar-modal-button-text">{label}</label>
      </ButtonWrap>
      <div className="cfar-expiry-date">
        <p className="cfar-expiry-copy">{date}</p>
      </div>
    </div>
  );
};

interface IAddOnsSummary {
  addOns: AddOn[];
  className?: string;
  label?: string;
}

export const AddOnsSummary = ({ addOns, className, label }: IAddOnsSummary) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  // TODO: Date translations using i18n
  const getDateCopy = useCallback(
    (date: string, prefix: string): string => {
      if (!date) return "--";

      return `${t(prefix)} ${dayjs(removeTimezone(date)).format(
        "MMM DD"
      )}, ${dayjs(removeTimezone(date)).format("h:mm A")}`;
    },
    [t]
  );

  const getAddOnContentProps = useCallback(
    (addOn: AddOn) => {
      switch (addOn.type) {
        case AddOnEnum.Cfar:
          return {
            label: t("cancelForAnyReason"),
            date: getDateCopy(addOn?.expiryDate, "expiresOn"),
          };
        case AddOnEnum.DelayDisruption:
          return {
            label: t("delayDisruption"),
            date: getDateCopy(addOn?.createdDate, "CreatedOn"),
          };
        case AddOnEnum.MissedConnection:
          return {
            label: t("missedConnection"),
            date: getDateCopy(addOn?.createdDate, "CreatedOn"),
          };
        default:
          break;
      }
      return null;
    },
    [getDateCopy, t]
  );

  return (
    <div
      className={clsx("add-ons-summary-container", className, {
        mobile: matchesMobile,
      })}
    >
      <p className="add-ons-text">{label || t("addOnsTitle")}</p>
      <div className="add-ons-content">
        {addOns.map((addOn, index) => (
          <AddOnsContent
            key={`add-ons-content-${index}`}
            onClick={addOn.onClick}
            label={getAddOnContentProps(addOn)?.label}
            date={getAddOnContentProps(addOn)?.date}
          />
        ))}
      </div>
    </div>
  );
};
