import axios from "axios";

import { paymentMethodsApiPrefix } from "../../paths";
import {
  VerifyPaymentMethodRequest,
  PaymentVerifyResult,
  Payment,
  NETWORK_CALL_FAILED,
  IApiConfig,
} from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";

const END_POINT = `${paymentMethodsApiPrefix}/verify`;

export const verifyPaymentMethod = (
  req: VerifyPaymentMethodRequest,
  apiConfig?: IApiConfig
): Promise<PaymentVerifyResult> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(END_POINT, req);

      const responseBody: PaymentVerifyResult = res.data.value;
      resolve(responseBody);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });

export type IListPaymentMethodsResponse = Array<Payment>;
