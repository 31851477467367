import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { fetchPaymentMethod } from "../../../../api/v1/book/payment-methods/fetchPaymentMethod";
import {
  GetPaymentMethodSuccess,
  GetPaymentMethodResponse,
} from "@hopper-b2b/types";
import { flightApiConfigStoreKey } from "../../../../reducers/types";
import { IStoreState } from "../../../../reducers/types";

export function* fetchPaymentMethodSaga({
  paymentId,
}: actions.IFetchPaymentMethod) {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const response: GetPaymentMethodResponse = yield fetchPaymentMethod(
      {
        paymentId,
      },
      apiConfig
    );

    if ((response as GetPaymentMethodSuccess).value) {
      yield put(
        actions.setPaymentMethod({
          paymentMethod: (response as GetPaymentMethodSuccess).value,
        })
      );
    } else {
      yield put(actions.setPaymentMethodCallStateFailed());
    }
  } catch (e) {
    yield put(actions.setPaymentMethodCallStateFailed());
  }
}
