import axios from "axios";
import {
  EarnRequest,
  EarnResponse,
  IApiConfig,
  NETWORK_CALL_FAILED,
} from "@hopper-b2b/types";

import { trackEvent } from "@hopper-b2b/api";
import { rewardsApiPrefix } from "../paths";

const END_POINT = `${rewardsApiPrefix}/convert/productToEarn`;

export const productToEarn = (
  request: EarnRequest,
  apiConfig?: IApiConfig
): Promise<EarnResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(END_POINT, request);

      resolve(res.data);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });
