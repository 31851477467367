import { connect } from "react-redux";
import { FlightNumberFilterSelectionComponent } from "./component";
import { IStoreState } from "../../../../../../../../reducers/types";
import { actions } from "../../../../../../actions/index";
import {
  getFlightNumberFilter,
  getDepartureDate,
  IFlightNumberFilter,
} from "../../../../../../reducer";
import {
  IFlightNumbersByAirlineCode,
  IAirlineOptions,
} from "../../../../../../../shop/reducer";
import dayjs from "dayjs";

export interface IFlightNumberFilterSelectionBase {
  allAirlines: IAirlineOptions[];
  flightNumberFilter: IFlightNumberFilter[];
  flightNumbersByAirline: IFlightNumbersByAirlineCode;
  departureDateString: string;
  showDropdownContentOnly?: boolean;
  setFlightNumberFilter: (flightNumbers: IFlightNumberFilter[]) => void;
}

const mapStateToProps = (state: IStoreState) => {
  const departureDate = getDepartureDate(state);
  const dayjsDate = departureDate ? dayjs(departureDate) : null;
  const departureDateString = dayjsDate ? dayjsDate.format("ddd, MMM D") : "";

  return {
    flightNumberFilter: getFlightNumberFilter(state),
    departureDateString,
  };
};

const mapDispatchToProps = {
  setFlightNumberFilter: actions.setFlightNumberFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const FlightNumberFilterSelection = connector(
  FlightNumberFilterSelectionComponent as (
    props: IFlightNumberFilterSelectionBase
  ) => JSX.Element
);

export default FlightNumberFilterSelection;
