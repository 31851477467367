import {
  airCfarApi,
  ScheduleAirCfarExerciseQuoteResponse,
  ScheduleAirCfarExerciseQuoteResponseEnum,
  ScheduleAirCfarExerciseQuoteResponseFailure,
  ScheduleAirCfarExerciseQuoteResponseSuccess,
} from "@b2bportal/air-cfar-api";
import { trackEvent } from "@hopper-b2b/api";
import {
  CfarExerciseProgress,
  CfarExerciseProperties,
  CfarTrackingEvents,
} from "@hopper-b2b/types";
import {
  combineTrackingProperties,
  ITrackingProperties,
} from "@hopper-b2b/utilities";
import axios, { AxiosResponse } from "axios";
import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { getCfarExerciseTrackingProperties } from "../../reducer";

export function* fetchFlightCfarCancellationQuoteSaga(
  action: actions.IFetchFlightCfarCancellationQuote
) {
  yield put(actions.setCfarExerciseProgress(CfarExerciseProgress.Loading));
  const requestBody = {
    contractId: action.contractId,
    itineraryId: action.itineraryId,
  };
  try {
    const response: AxiosResponse<ScheduleAirCfarExerciseQuoteResponse> =
      yield airCfarApi(axios).apiV0CfarAirExerciseQuotePost(requestBody);
    if (
      (response.data as ScheduleAirCfarExerciseQuoteResponseFailure)
        ?.ScheduleAirCfarExerciseQuoteResponse ===
      ScheduleAirCfarExerciseQuoteResponseEnum.Failure
    ) {
      yield put(actions.setFetchFlightCfarCancellationQuoteCallStateFailed());
      return;
    }

    yield put(
      actions.setFlightCfarCancellationQuote({
        response: response.data as ScheduleAirCfarExerciseQuoteResponseSuccess,
      })
    );
    yield put(actions.setCfarExerciseProgress(CfarExerciseProgress.Review));

    const trackingProps = yield select(
      getCfarExerciseTrackingProperties
    ) as ITrackingProperties;
    const cfarTrackingProps = combineTrackingProperties([trackingProps]);

    yield trackEvent({
      eventName: CfarTrackingEvents.ENTER_CFAR_EXERCISE_FLOW,
      ...cfarTrackingProps,
    });
  } catch (e) {
    yield put(actions.setFetchFlightCfarCancellationQuoteCallStateFailed());
  }
}
