import React from "react";
import styles from "../LodgingCard.module.scss";
import { useI18nContext } from "@hopper-b2b/i18n";
import { LodgingPrice } from "@b2bportal/lodging-api";

interface PriceInfoProps {
  price: LodgingPrice;
  ctaText?: string;
  nights: number;
}

export const PriceInfo: React.FC<PriceInfoProps> = ({
  price,
  nights,
  ctaText,
}) => {
  const { t, formatFiatCurrency } = useI18nContext();

  return (
    <div className={styles.priceInfo}>
      <div className={styles.priceDetails}>
        <p className={styles.perNight}>
          {t("commBank.hotelAvailability.card.pricePerNight_singleRoom")}
        </p>
        <p className={styles.mainPrice}>
          {formatFiatCurrency(price.nightlyPrice.fiat, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })}
        </p>
        <p className={styles.totalPrice}>
          {Object.values(price.totalPrice.accountSpecific).length > 0
            ? t("commBank.totalPriceNights", {
                cashPriceWithSymbol: formatFiatCurrency(
                  price.totalPrice?.fiat,
                  {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }
                ),
                numberOfNights: nights,
                pointPrice: Object.values(
                  price.totalPrice.accountSpecific
                )?.[0]?.value?.toLocaleString(),
              })
            : t("commBank.priceForNights", {
                price: formatFiatCurrency(price.totalPrice?.fiat, {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }),
                count: nights,
              })}
        </p>
        <p className={styles.taxInfo}>{t("taxesAndFeesIncluded")}</p>
      </div>
      {ctaText && (
        <button className={styles.checkAvailability}>{ctaText}</button>
      )}
    </div>
  );
};
