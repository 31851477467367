import { useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AxiosResponse } from "axios";
import clsx from "clsx";

import {
  BookedFlightItineraryWithDepartureTime,
  TrackingProperties,
} from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  DisruptionExerciseProductType,
  DisruptionExerciseProgress,
  DisruptionRefundFulfillRequest,
  DisruptionRefundFulfillResponse,
  DisruptionTrackingEvents,
  ExerciseType,
  FiatPrice,
} from "@hopper-b2b/types";
import {
  ActionButton,
  B2BLoadingPopup,
  ErrorPopup,
  MobileFloatingButton,
  UberPriceSummaryRow,
} from "@hopper-b2b/ui";

import disruptionRefundFulfill from "../../../../../api/v1/disruption/disruptionRefundFulfill";
import { ClientContext } from "../../../../../App";
import { PATH_HOME } from "../../../../../utils/paths";
import { setDisruptionExerciseProgress } from "../../../../TripsList/actions/actions";
import {
  getDisruptionExerciseProductType,
  getSelectedFlight,
} from "../../../../TripsList/reducer/selectors";
import { getDisruptionExerciseTracking } from "../helpers";
import { trackEvent } from "@hopper-b2b/api";

export interface IDisruptionRefundProps {
  flight: BookedFlightItineraryWithDepartureTime;
  confirmation: boolean;
  contractId: string;
  itineraryId: string;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  reimbursementAmount: FiatPrice | null;
  errorModalPrimaryOnClick: () => void;
  errorModalSecondaryOnClick: (
    flightDetails: BookedFlightItineraryWithDepartureTime
  ) => void;
  disableFloatingButton?: boolean;
  onDone?: () => void;
}

export const DisruptionRefund = ({
  flight,
  confirmation,
  contractId,
  itineraryId,
  loading,
  setLoading,
  reimbursementAmount,
  errorModalPrimaryOnClick,
  errorModalSecondaryOnClick,
  disableFloatingButton,
  onDone,
}: IDisruptionRefundProps) => {
  const clientContext = useContext(ClientContext);
  const { t, formatFiatCurrency } = useI18nContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedFlight = useSelector(getSelectedFlight);

  const [refundFailed, setRefundFailed] = useState<boolean>(false);

  const disruptionProductType = useSelector(getDisruptionExerciseProductType);
  const productType = useMemo(() => {
    if (
      disruptionProductType === DisruptionExerciseProductType.ScheduleChange
    ) {
      return t("disruptionProductDelay");
    } else {
      return t("disruptionProductMissedConnection");
    }
  }, [disruptionProductType, t]);

  const refundFailure = useCallback(() => {
    setRefundFailed(true);
    setLoading(false);
  }, [setLoading]);

  const trackExerciseEvent = useCallback(
    (trackingProperties: TrackingProperties) => {
      const properties = getDisruptionExerciseTracking(
        selectedFlight,
        disruptionProductType,
        trackingProperties,
        reimbursementAmount
      );
      trackEvent({
        eventName:
          disruptionProductType ===
          DisruptionExerciseProductType.MissedConnection
            ? DisruptionTrackingEvents.MISSED_CONNECTION_POLICY_EXERCISED
            : DisruptionTrackingEvents.DELAY_POLICY_EXERCISED,
        properties: {
          ...properties,
          exercise_type: ExerciseType.refund,
        },
      });
    },
    [reimbursementAmount, disruptionProductType, selectedFlight]
  );

  const fetchDisruptionRefundFulfill = useCallback(() => {
    if (contractId) {
      const req: DisruptionRefundFulfillRequest = {
        contractId,
        itineraryId,
      };

      return disruptionRefundFulfill(req)
        .then((response: AxiosResponse<DisruptionRefundFulfillResponse>) => {
          setLoading(false);
          trackExerciseEvent(response.data?.trackingProperties?.properties);
          dispatch(
            setDisruptionExerciseProgress(
              DisruptionExerciseProgress.RefundConfirmation
            )
          );
        })
        .catch(() => {
          refundFailure();
        });
    }

    return;
  }, [
    contractId,
    dispatch,
    itineraryId,
    refundFailure,
    setLoading,
    trackExerciseEvent,
  ]);

  const onContinue = useCallback(() => {
    if (confirmation) {
      history.push(PATH_HOME);
      dispatch(
        setDisruptionExerciseProgress(DisruptionExerciseProgress.NotStarted)
      );
      onDone?.();
    } else {
      setLoading(true);
      fetchDisruptionRefundFulfill();
    }
  }, [
    confirmation,
    dispatch,
    fetchDisruptionRefundFulfill,
    history,
    onDone,
    setLoading,
  ]);

  const label = useMemo(
    () => flight?.bookedItinerary?.paymentBreakdown?.payments[0]?.label,
    [flight]
  );

  return (
    <div className={clsx("disruption-refund", { confirmation })}>
      <B2BLoadingPopup
        open={loading}
        fullScreen
        message={t("disruptionExercise.refundLoadingMessage")}
        popupSize={"mobile"}
      />
      <div className="disruption-refund-header">
        {confirmation
          ? t("disruptionExercise.refundConfirmationTitle")
          : t("disruptionExercise.refundReviewTitle")}
      </div>
      <div className="disruption-refund-description">
        {confirmation
          ? t("disruptionExercise.refundConfirmationDescription", {
              email: flight.emailAddress,
            })
          : t("disruptionExercise.refundReviewDescription", {
              productType,
            })}
      </div>
      {reimbursementAmount ? (
        <div className="disruption-refund-total">
          <UberPriceSummaryRow
            priceString={formatFiatCurrency(reimbursementAmount)}
            iconSrc={clientContext?.assets?.card}
            description={t("disruptionExercise.refundPaymentDetails", {
              label: label ? label : t("payment"),
            })}
          />
        </div>
      ) : null}
      {disableFloatingButton ? (
        <ActionButton
          className="disruption-refund-landing-page-button"
          onClick={onContinue}
          message={confirmation ? t("done") : t("confirm")}
        />
      ) : (
        <MobileFloatingButton
          className="disruption-refund-landing-page-button"
          onClick={onContinue}
          wrapperClassName="disruption-refund-landing-page-button-wrapper"
          wide
        >
          {confirmation ? t("done") : t("confirm")}
        </MobileFloatingButton>
      )}

      <ErrorPopup
        reverse
        open={refundFailed}
        title={t("disruptionExercise.error.title")}
        subtitle={t("disruptionExercise.error.subtitle")}
        primaryButtonText={t("disruptionExercise.error.primaryButton")}
        primaryOnClick={errorModalPrimaryOnClick}
        secondaryButtonText={t("disruptionExercise.error.secondaryButton")}
        secondaryOnClick={() => errorModalSecondaryOnClick(selectedFlight)}
      />
    </div>
  );
};
