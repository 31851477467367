import {
  IApiConfig,
  NETWORK_CALL_FAILED,
  TripDetails,
} from "@hopper-b2b/types";
import { trackEvent, axiosInstance } from "@hopper-b2b/api";
import { tripDetailsApiPrefix } from "../paths";

export const fetchTripDetails = async (
  tripId: string,
  apiConfig?: IApiConfig
): Promise<TripDetails> => {
  const req = { value: tripId };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axiosInstance.post(`${tripDetailsApiPrefix}`, req);
      resolve(res.data);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });
};
