import { takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { fetchDisruptionOffersSaga } from "./fetchDisruptionOffersSaga";

export function* watchGetDisruptionOffers() {
  yield takeLatest(
    actionTypes.FETCH_DISRUPTION_OFFERS,
    fetchDisruptionOffersSaga
  );
}
