import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { actions } from "../../../../actions";
import {
  getDestination,
  getOrigin,
  getNumTravelers,
  getDestinationCategoriesLoading,
  getOriginCategoriesLoading,
  getOriginCategories,
  getDestinationCategories,
  getRenderSearchInput,
  getCalendarVisited,
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getTripCategory,
} from "../../../../reducer";
import { LocationSearch } from "./component";

const mapDispatchToProps = {
  fetchOriginCategories: actions.fetchOriginCategories,
  fetchDestinationCategories: actions.fetchDestinationCategories,
  setRenderSearchInput: actions.setRenderSearchInput,
  populateSearchUrlParams: actions.populateSearchUrlParams,
};
const mapStateToProps = (state: IStoreState) => {
  const origin = getOrigin(state);
  const destination = getDestination(state);
  const readyToContinue = origin && destination;
  return {
    origin,
    destination,
    numTravelers: getNumTravelers(state),
    originCategories: getOriginCategories(state),
    destinationCategories: getDestinationCategories(state),
    readyToContinue,
    loading:
      !!getOriginCategoriesLoading(state) ||
      !!getDestinationCategoriesLoading(state),
    renderSearchInput: getRenderSearchInput(state),
    calendarVisited: getCalendarVisited(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
    tripCategory: getTripCategory(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type LocationSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedLocationSearch = connector(LocationSearch);
