import { useContext } from "react";
import clsx from "clsx";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  FlightSummaryInfo,
  FlightSummaryInfoWithKeys,
  MyTripsModalTypes,
} from "@hopper-b2b/types";
import {
  ButtonWrap,
  Checkbox,
  MobileFlightSummaryRowNew,
} from "@hopper-b2b/ui";
import { ClientContext } from "../../../../../App";

interface ChfarSelectRebookProps {
  departureSummary: FlightSummaryInfo;
  returnSummary?: FlightSummaryInfo;
  onClick: (type: MyTripsModalTypes) => void;
}

export const ChfarSelectRebook = ({
  departureSummary,
  returnSummary,
  onClick,
}: ChfarSelectRebookProps) => {
  const clientContext = useContext(ClientContext);
  const { t } = useI18nContext();

  return (
    <div className="chfar-page">
      <div className="chfar-page--title">
        {t("chfarExercise.title.selectRebookTitle")}
      </div>
      <div className="chfar-page--select-flight">
        <ButtonWrap
          onClick={() => onClick(MyTripsModalTypes.OutboundItinerary)}
        >
          <div className={clsx("flight-row")}>
            <div className="mobile-flight-summary">
              <MobileFlightSummaryRowNew
                flightSummaryInfo={departureSummary}
                iconSrc={clientContext.assets?.airplaneDepart}
              >
                <div className="flight-row--checkbox">
                  <Checkbox
                    selected={true}
                    iconSrc={clientContext?.assets?.checkmark}
                  />
                </div>
              </MobileFlightSummaryRowNew>
            </div>
          </div>
        </ButtonWrap>
        {returnSummary ? (
          <ButtonWrap
            onClick={() => onClick(MyTripsModalTypes.ReturnItinerary)}
          >
            <div className={clsx("flight-row")}>
              <div className="mobile-flight-summary">
                <MobileFlightSummaryRowNew
                  flightSummaryInfo={returnSummary}
                  iconSrc={clientContext.assets?.airplaneArrive}
                >
                  <div className="flight-row--checkbox">
                    <Checkbox
                      selected={true}
                      iconSrc={clientContext?.assets?.checkmark}
                    />
                  </div>
                </MobileFlightSummaryRowNew>
              </div>
            </div>
          </ButtonWrap>
        ) : null}
      </div>
    </div>
  );
};
