import { useI18nContext } from "@hopper-b2b/i18n";
import { FloatingBox } from "@hopper-b2b/ui";
import { Box, Collapse, Typography } from "@material-ui/core";
import { ApacActionButton } from "../ApacActionButton";
import "./ApacMobileContinueButton.styles.scss";
import { ReactNode } from "react";
import clsx from "clsx";
import { ErrorMessageWrapper } from "../ErrorMessageWrapper";

export interface ApacMobileContinueButtonProps {
  title?: string;
  disabled?: boolean;
  errorMessage?: string;
  handleContinue: () => void;
  children?: ReactNode;
  className?: string;
}

export const ApacMobileContinueButton = (
  props: ApacMobileContinueButtonProps
) => {
  const { t } = useI18nContext();

  const { disabled, handleContinue, errorMessage, title, children, className } =
    props;

  return (
    <FloatingBox
      className={clsx("mobile-continue-button-container", className)}
    >
      <Collapse in={!!errorMessage}>
        <ErrorMessageWrapper message={errorMessage} />
      </Collapse>
      <Box className="child">{children}</Box>
      <ApacActionButton
        className="mobile-continue-button"
        onClick={() => handleContinue()}
        message={title || t("continue")}
        disabled={disabled}
      />
    </FloatingBox>
  );
};
