import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import "./styles.scss";

export interface IPricePredictionCardProps {
  className?: string;
  title: string;
  content: string | JSX.Element;
  subtitle?: string;
  icon?: JSX.Element;
  onClick?: () => void;
  isForecastedPrice?: boolean;
}

export const PricePredictionCard = (
  props: IPricePredictionCardProps
): React.ReactElement => {
  const {
    className,
    title,
    content,
    subtitle,
    onClick,
    icon,
    isForecastedPrice = false,
  } = props;
  return (
    <Box className={clsx(className, "price-prediction-card")}>
      {title ? (
        <Typography className="title" variant="h4">
          {`${title}:`}
        </Typography>
      ) : null}
      <Box className="price-prediction-card-content">
        <Box className="content-wrapper">
          <div className="content-container">
            {content} {isForecastedPrice && icon}
          </div>
          <Typography className="subtitle" variant="caption" onClick={onClick}>
            {subtitle}
          </Typography>
        </Box>
        {!isForecastedPrice && icon}
      </Box>
    </Box>
  );
};
