import { ParentContextWithWallet } from "@checkout/index";
import { axiosInstance } from "@hopper-b2b/api";
import {
  GetWalletOffersResponseEnum,
  WalletVouchersResponse,
} from "@hopper-b2b/types";

export const getWalletOffersService = (context: ParentContextWithWallet) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise<WalletVouchersResponse>(async (resolve, reject) => {
    try {
      const path = "/api/v0/wallet/vouchers";

      const getOffersResponse: WalletVouchersResponse = (
        await axiosInstance.get(path)
      ).data;
      if (
        getOffersResponse.GetVouchersResponse ===
        GetWalletOffersResponseEnum.Success
      ) {
        resolve(getOffersResponse);
      } else {
        reject(null);
      }
    } catch (e) {
      reject(null);
    }
  });
