import { trackEvent } from "@hopper-b2b/api";
import { SelfServeEvents } from "@hopper-b2b/types";
import {
  useCancellationActions,
  CancelStep,
  getTrackingProps,
  UseCancellationActionsContext,
  UseCancellationActionsHandlers,
} from "./use-cancellation-actions";
import {
  Airline,
  BookedFlightItineraryWithDepartureTime,
} from "@b2bportal/air-booking-api";
import { toggleAdaChat } from "@hopper-b2b/utilities";

interface UseFlightCancellationParams {
  flight: BookedFlightItineraryWithDepartureTime;
  airlines: Record<string, Airline>;
  closeModal: (cancelSuccess?: boolean) => void;
}

interface UseFlightCancellationResponse {
  context: UseCancellationActionsContext;
  handlers: Pick<
    UseCancellationActionsHandlers,
    "getCancelInfo" | "setCancelStep" | "confirmCancellation"
  > & {
    startCancellationInfoTimer: () => void;
    startCancellationConfirmTimer: (isHFv2: boolean) => void;
    redirectToAirline: () => void;
    onConfirmFlightCancellation: () => void;
    onContactAirline: () => void;
    onContactSupport: () => void;
  };
}

export const useFlightCancellation = ({
  flight,
  airlines,
  closeModal,
}: UseFlightCancellationParams): UseFlightCancellationResponse => {
  const { context, handlers } = useCancellationActions({ flight });

  const { scenario, timedOut } = context;
  const { startPolicyTimeout, clearPolicyTimeout, setCancelStep } = handlers;

  const contactCustomerSupport = () => {
    clearPolicyTimeout();

    if ("NonCfar" in scenario) {
      trackEvent({
        eventName: SelfServeEvents.ClickSupport,
        properties: getTrackingProps(scenario),
      });
    }

    closeModal();
    toggleAdaChat();
  };

  /**
   * @description Opens airline's website in a new browser tab
   */
  const redirectToAirline = () => {
    clearPolicyTimeout();

    if (!scenario) return;

    let airlineWebLink;

    if (scenario && "airlines" in scenario) {
      const { airlines: scenarioAirlines } = scenario;
      const airlineCode = scenarioAirlines[0].code;
      if (airlines[airlineCode]) {
        airlineWebLink = airlines[airlineCode]?.webLinks.manageBooking;
      }
    }
    if (airlineWebLink) {
      trackEvent({
        eventName: SelfServeEvents.RedirectToAirline,
        properties: getTrackingProps(scenario),
      });

      window.open(airlineWebLink, "_blank");
      closeModal();
    }
  };

  const onConfirmFlightCancellation = () => {
    clearPolicyTimeout();

    if (timedOut) {
      handlers.getCancelInfo();
    } else {
      setCancelStep(CancelStep.ConfirmCancelation);
    }
  };

  const onContactAirline = () => {
    clearPolicyTimeout();
    redirectToAirline();
  };

  const onContactSupport = () => {
    clearPolicyTimeout();
    contactCustomerSupport();
  };

  const startCancellationConfirmTimer = (isHFv2: boolean) => {
    startPolicyTimeout(() => {
      setCancelStep(
        isHFv2 ? CancelStep.SlicePicker : CancelStep.CancelationInfo
      );
    });
  };

  return {
    context,
    handlers: {
      ...handlers,
      startCancellationInfoTimer: startPolicyTimeout,
      startCancellationConfirmTimer,
      redirectToAirline,
      onConfirmFlightCancellation,
      onContactAirline,
      onContactSupport,
    },
  };
};
