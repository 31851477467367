import { ActionLink } from "@hopper-b2b/ui";
import clsx from "clsx";
import { ReactNode, MouseEventHandler } from "react";
import { ApacIcon, ApacIconName } from "../ApacIcon";
import "./ApacActionLink.styles.scss";

export interface IApacActionLinkProps {
  message: ReactNode;
  onClick?: MouseEventHandler<Element>;
  className?: string;
  showArrow?: boolean;
  disabled?: boolean;
  showUnderline?: boolean;
}

/**
 * A simple component for blue clickable link text,
 * with showArrow for terms and conditions text needed in new design.
 */
export const ApacActionLink = ({
  message,
  className,
  onClick,
  showArrow = false,
  disabled,
  showUnderline,
  ...rest
}: IApacActionLinkProps) => {
  return (
    <div
      className={clsx("apac-action-link", className, {
        showUnderline,
      })}
      onClick={onClick}
    >
      <ActionLink
        className="link"
        content={message}
        disabled={disabled}
        {...rest}
      />
      {showArrow && <ApacIcon name={ApacIconName.LinkArrow} />}
    </div>
  );
};
