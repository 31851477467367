import { Example } from "@b2bportal/air-shopping-api";
import { IFlightListData, TripCategory } from "@hopper-b2b/types";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import { uniqBy } from "lodash-es";
import { useMemo, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";

import { getSelectedAccountReferenceId } from "../../../rewards/reducer";
import {
  getDepartureDate,
  getFareclassOptionFilter,
  getHasSetFareClassFilter,
  getReturnDate,
} from "../../../search/reducer";
import { actions as shopActions } from "../../../shop/actions";
import { SortOptionSelection } from "../../../shop/components/FlightShopHeader/components/SortOptionSelection";
import {
  FlightShopStep,
  flightShopProgressSelector,
  flightsSelector,
  getSortedAndFilteredFlights,
  selectedTripSelector,
  tripDetailsByIdSelector,
  tripSummariesErrorSelector,
  tripSummariesLoadingSelector,
} from "../../../shop/reducer";
import { getSliceFareDetails } from "../../../shop/utils/helpers";
import { FlightList } from "../../../shop/v3/components/FlightList";
import { getReturnSelection } from "../../reducer/selectors";
import { getFlightSummaries, getTripDetails } from "../../reducer/shop";
import { getItineraryTripType } from "../../selectors";
import {
  buildOutgoingShopSlice,
  buildReturnShopSlice,
  skipShopAction,
} from "../../utils";
import FlightComparatorRow from "./FlightComparatorRow";
import ShopBreadcrumbs from "./ShopBreadcrumbs";
import ShopSearchControl from "./ShopSearchControl";

import "./styles.scss";

export interface IDesktopFlightShopProps {
  backToTrips: () => void;
  bookingId: string;
  onSearch: () => void;
}

const defaultProps: Partial<IDesktopFlightShopProps> = {};

const DesktopFlightShop = (props: IDesktopFlightShopProps): JSX.Element => {
  const { backToTrips, bookingId, onSearch } = props;
  const { matchesMobile } = useDeviceTypes();
  const dispatch = useDispatch();

  const departureDate = useSelector(getDepartureDate);
  const fareFilters = useSelector(getFareclassOptionFilter);
  const fareFilterSet = useSelector(getHasSetFareClassFilter);
  const flights = useSelector(flightsSelector);
  const flightOptions = useSelector(getSortedAndFilteredFlights);
  const returnDate = useSelector(getReturnDate);
  const returnSelection = useSelector(getReturnSelection);
  const rewardsKey = useSelector(getSelectedAccountReferenceId);
  const selectedTrip = useSelector(selectedTripSelector);
  const shopStep = useSelector(flightShopProgressSelector);
  const tripDetailsMap = useSelector(tripDetailsByIdSelector);
  // const tripDetailsLoading = useSelector(tripDetailsLoadingSelector);
  const tripSummariesError = useSelector(tripSummariesErrorSelector);
  const tripSummariesLoading = useSelector(tripSummariesLoadingSelector);
  const tripType = useSelector(getItineraryTripType);

  const [fareTrips, setFareTrips] = useState<Example[]>([]);

  const onChooseDeparture = shopStep === FlightShopStep.ChooseDeparture;
  const onChooseReturn = shopStep === FlightShopStep.ChooseReturn;
  const isRoundTrip = tripType === TripCategory.ROUND_TRIP;

  const expandedFareDetails = useMemo(() => {
    const fetchedAllFareDetails = fareTrips.length
      ? fareTrips.every((fT) => Boolean(tripDetailsMap[fT.trip]))
      : false;

    return fetchedAllFareDetails
      ? getSliceFareDetails(tripDetailsMap, fareTrips)
      : null;
  }, [fareTrips, tripDetailsMap]);

  function onFareSelect(flight: IFlightListData, fareId: string) {
    if (onChooseDeparture) {
      const selectedSlice = buildOutgoingShopSlice(flight, fareId, flights);
      const skipChooseReturn = skipShopAction(returnSelection);

      batch(() => {
        const nextStep = skipChooseReturn
          ? FlightShopStep.ReviewItinerary
          : FlightShopStep.ChooseReturn;

        dispatch(
          shopActions.setChosenOutgoingSlice({
            ...selectedSlice,
            resetReturnIds: !skipChooseReturn,
          })
        );
        dispatch(shopActions.setFlightShopProgress(nextStep));
      });
    } else if (onChooseReturn) {
      const selectedReturn = buildReturnShopSlice(
        flight,
        fareId,
        flights.fareSlices,
        tripDetailsMap
      );

      if (selectedReturn) {
        batch(() => {
          dispatch(shopActions.setChosenReturnSlice(selectedReturn));
          dispatch(
            shopActions.setFlightShopProgress(FlightShopStep.ReviewItinerary)
          );
        });
      }
    }
  }

  function onFlightSelect(flightFares: Example[]) {
    uniqBy(flightFares, (trip) => trip.trip).map((fareTrip) =>
      dispatch(getTripDetails(fareTrip.trip))
    );

    setFareTrips(flightFares);
  }

  return (
    <Box className="desktop-flight-shop-container">
      <ShopBreadcrumbs backToTrips={backToTrips} />
      <ShopSearchControl disabled={false} onSearch={onSearch} />
      <FlightComparatorRow />
      <Box className="flight-list-controls-container">
        <SortOptionSelection />
      </Box>
      <FlightList
        disablePriceFreeze
        departureDate={departureDate}
        expandedFareDetails={expandedFareDetails}
        fareClassFilter={fareFilters}
        flights={flights}
        flightsToRender={flightOptions}
        handleFareSelect={onFareSelect}
        handleFlightSelect={onFlightSelect}
        hasAppliedFareClassFilter={fareFilterSet}
        hasAppliedNonFareclassFilter={false}
        hasFlightsError={tripSummariesError}
        isInChooseReturnStep={onChooseReturn}
        isRoundTrip={isRoundTrip}
        maxFlightPrice={10000}
        onErrorReload={() =>
          dispatch(getFlightSummaries(bookingId, matchesMobile))
        }
        returnDate={returnDate}
        rewardsKey={rewardsKey ?? undefined}
        selectedTrip={selectedTrip}
        setOpenCalendarModal={() => ""}
        tripSummariesLoading={tripSummariesLoading}
      />
    </Box>
  );
};

DesktopFlightShop.defaultProps = defaultProps;

export default DesktopFlightShop;
