import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { SelfServeEvents } from "@hopper-b2b/types";
import {
  ActionLink,
  DesktopPopupModal,
  GenericModalContent,
  Icon,
  IconName,
} from "@hopper-b2b/ui";
import { CurrencyFormatters } from "@hopper-b2b/utilities";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useHistory } from "react-router";
import { SlicePickerStep } from ".";
import { FlightSearchControl } from "../../../search";
import {
  FlightShopStep,
  flightShopProgressSelector,
} from "../../../shop/reducer";
import {
  getChangeFee,
  getIsLoadingContext,
  getMarketingAirline,
  getShowSlicePicker,
} from "../../reducer/selectors";
import { getBaseEventProps, getIsFtc } from "../../selectors";

// import "./styles.scss";

export interface IDesktopLandingPageProps {
  backToTrips: () => void;
  onSearch: () => void;
  setExchangeStep: (nextStep: FlightShopStep) => void;
}

const defaultProps: Partial<IDesktopLandingPageProps> = {};

const DesktopLandingPage = (props: IDesktopLandingPageProps): JSX.Element => {
  const { backToTrips, onSearch, setExchangeStep } = props;
  const history = useHistory();
  const { t } = useI18nContext();

  const airline = useSelector(getMarketingAirline);
  const baseEventProps = useSelector(getBaseEventProps);
  const changeFee = useSelector(getChangeFee);
  const flightShopStep = useSelector(flightShopProgressSelector);
  const isFTC = useSelector(getIsFtc);
  const isLoadingContext = useSelector(getIsLoadingContext);
  const showSlicePicker = useSelector(getShowSlicePicker);
  const shopStep = useSelector(flightShopProgressSelector);

  const [modalOpen, setModalOpen] = useState(true);

  const basePolicyTitleVars = {
    airline,
    amount: "",
    interpolation: {
      defaultVariables: {
        airline: t("exchangeable.landing.fallbackAirline"),
      },
    },
  };

  function checkForSlicePicker() {
    if (showSlicePicker) {
      setExchangeStep(FlightShopStep.ExchangeSlicePicker);
    } else {
      onSearch();
    }
  }

  useEffect(() => {
    setModalOpen(
      shopStep === FlightShopStep.ExchangeLoadingContext ||
        shopStep === FlightShopStep.ExchangeSlicePicker
    );
  }, [shopStep]);

  useEffect(() => {
    if (isFTC != null && baseEventProps != null) {
      const eventName = isFTC
        ? SelfServeEvents.ViewedFTCExchangeLanding
        : SelfServeEvents.ViewedExchangeLanding;

      trackEvent({
        eventName,
        properties: baseEventProps,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="desktop-landing-page-container">
      <Box className="go-back-container">
        <ActionLink
          className="b2b"
          content={
            <>
              <Icon
                aria-hidden
                className="go-back-chevron"
                name={IconName.NavigationForward}
              />
              <Typography className="action-link-text go-back-btn">
                {t("exchangeable.backToTrips")}
              </Typography>
            </>
          }
          onClick={backToTrips}
        />
      </Box>
      <Box className="exchange-policy-container">
        {flightShopStep === FlightShopStep.ExchangeLoadingContext ? (
          <Grid
            container
            className="policy-skeleton-container"
            direction="column"
            spacing={1}
          >
            <Grid item>
              <Skeleton
                className="policy-skeleton title-skeleton"
                height={32}
              />
            </Grid>
            <Grid item>
              <Skeleton className="subtitle-skeleton" height={24} />
            </Grid>
          </Grid>
        ) : (
          <>
            <Typography className="policy-title" variant="h3">
              {changeFee?.amount
                ? t("exchangeable.landing.title.fee", {
                    ...basePolicyTitleVars,
                    amount: CurrencyFormatters.get(changeFee.currency).format(
                      changeFee.amount
                    ),
                  })
                : t("exchangeable.landing.title.noFee", basePolicyTitleVars)}
            </Typography>
            <Typography className="policy-body" variant="body1">
              {t("exchangeable.landing.body")}
            </Typography>
          </>
        )}
      </Box>
      <Box className="flight-search-form-container">
        <DesktopPopupModal
          hideXButton
          className="exchange-flight-desktop-modal"
          invisibleBackdrop={false}
          onClose={() => setModalOpen(false)}
          open={modalOpen}
        >
          {shopStep === FlightShopStep.ExchangeLoadingContext && (
            <GenericModalContent
              image={<CircularProgress />}
              subtitle={
                <Typography variant="body1">
                  {t("exchangeable.loading.context")}
                </Typography>
              }
            />
          )}
          {shopStep === FlightShopStep.ExchangeSlicePicker && (
            <SlicePickerStep onContinue={onSearch} />
          )}
        </DesktopPopupModal>
        {isLoadingContext ? (
          <Grid
            container
            className="search-control-skeleton-container"
            spacing={2}
          >
            <Grid item xs={6}>
              <Skeleton className="search-control-skeleton" height={60} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton className="search-control-skeleton" height={60} />
            </Grid>
            <Grid item xs={9}>
              <Skeleton className="search-control-skeleton" height={60} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton className="search-control-skeleton" height={60} />
            </Grid>
          </Grid>
        ) : (
          <FlightSearchControl
            hideCategoryToggle
            hidePassengerPicker
            history={history}
            onSearch={checkForSlicePicker}
          />
        )}
      </Box>
    </Box>
  );
};

DesktopLandingPage.defaultProps = defaultProps;

export default DesktopLandingPage;
