import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { actions } from "../../../../actions";
import {
  getDestination,
  getOrigin,
  getDestinationCategoriesLoading,
  getOriginCategoriesLoading,
  getOriginCategories,
  getDestinationCategories,
} from "../../../../reducer";
import { LocationSelection } from "./component";

const mapDispatchToProps = {
  fetchOriginCategories: actions.fetchOriginCategories,
  fetchDestinationCategories: actions.fetchDestinationCategories,
  populateSearchUrlParams: actions.populateSearchUrlParams,
};
const mapStateToProps = (state: IStoreState) => {
  const origin = getOrigin(state);
  const destination = getDestination(state);
  return {
    origin,
    destination,
    originCategories: getOriginCategories(state),
    destinationCategories: getDestinationCategories(state),
    loading:
      !!getOriginCategoriesLoading(state) ||
      !!getDestinationCategoriesLoading(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type LocationSelectionConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedLocationSelection = connector(LocationSelection);
