import { takeEvery, takeLatest } from "redux-saga/effects";

import { actionTypes } from "../actions";
import { getExchangeContext } from "../reducer/policy";
import { getTripDetails, submitExchangeReq } from "../reducer/shop";
import { fetchExchangeContextSaga } from "./fetchExchangeContextSaga";
import { fetchFlightSummariesSaga } from "./fetchFlightSummariesSaga";
import fetchTripDetailsSaga from "./fetchTripDetailsSaga";
import submitExchangeReqSaga from "./submitExchangeReqSaga";

export function* watchFetchExchangeContext() {
  yield takeLatest(getExchangeContext.toString(), fetchExchangeContextSaga);
}

export function* watchFetchFlightSummaries() {
  yield takeLatest(actionTypes.GET_FLIGHT_SUMMARIES, fetchFlightSummariesSaga);
}

export function* watchFetchTripDetails() {
  yield takeEvery(getTripDetails.toString(), fetchTripDetailsSaga);
}

export function* watchSubmitExchangeReq() {
  yield takeLatest(submitExchangeReq.toString(), submitExchangeReqSaga);
}
