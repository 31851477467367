export enum Funnel {
  Flights = "flights",
  Hotels = "hotels",
}

export type FunnelToFunnelInfo = {
  [key in Funnel]: {
    stringId: string;
    path: string;
  };
};
