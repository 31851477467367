import { useI18nContext } from "@hopper-b2b/i18n";
import { tenantFlagsDisabled, useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Button, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import { isEqual } from "lodash-es";
import { useCallback } from "react";
import { noLCCFareclassOptionFilter } from "../../../../../search/reducer";
import {
  FARE_CLASSES,
  FARE_CLASS_DETAILS_I18N_KEY,
  FARE_CLASS_I18N_KEY,
  MOBILE_FARE_I18N_KEY,
} from "./constants";
import { FareClassOptionSelectionConnectorProps } from "./container";
import "./FareClassOptionSelection.styles.scss";

export const FareClassOptionSelection = ({
  fareClassOptionFilter,
  setFareClassOptions,
  setRerunPrediction,
}: FareClassOptionSelectionConnectorProps) => {
  const { t } = useI18nContext();

  const { matchesMobile, matchesMediumDesktopOnly } = useDeviceTypes();

  const hasSelectedFareClass = Object.values(fareClassOptionFilter).includes(
    true
  );
  const handleClearFilter = useCallback(() => {
    let rerunFlag = false;
    if (isEqual(fareClassOptionFilter, noLCCFareclassOptionFilter)) {
      rerunFlag = true;
    }
    for (const fare in fareClassOptionFilter) {
      fareClassOptionFilter[fare] = false;
    }
    if (rerunFlag) {
      setRerunPrediction();
    }
    setFareClassOptions(fareClassOptionFilter);
  }, [fareClassOptionFilter, setFareClassOptions, setRerunPrediction]);

  const handleSetOption = useCallback(
    (fareClass: string) => {
      // if (matchesMobile) {
      let rerunFlag = false;
      if (isEqual(fareClassOptionFilter, noLCCFareclassOptionFilter)) {
        rerunFlag = true;
      }
      for (const fare in fareClassOptionFilter) {
        fareClassOptionFilter[fare] = fare === fareClass;
      }
      if (rerunFlag) {
        setRerunPrediction();
      }
      // } else {
      //   fareClassOptionFilter[fareClass] = !fareClassOptionFilter[fareClass];
      // }
      setFareClassOptions(fareClassOptionFilter);
    },
    [fareClassOptionFilter, setFareClassOptions, setRerunPrediction]
  );

  return (
    <Box
      className={clsx("fare-class-filter-container", {
        mobile: matchesMobile,
        "medium-desktop": !matchesMediumDesktopOnly,
      })}
    >
      <Box
        key="all"
        className={clsx("fare-class-filter", {
          selected: !hasSelectedFareClass,
        })}
        onClick={handleClearFilter}
      >
        <Button variant="outlined">{t(MOBILE_FARE_I18N_KEY.all)}</Button>
      </Box>
      {Object.keys(FARE_CLASSES).map((fareClass) => (
        <Box
          className={clsx("fare-class-filter", {
            selected: fareClassOptionFilter[fareClass],
          })}
          id={`faretype-${fareClass}`}
          key={fareClass}
        >
          <Tooltip
            tabIndex={0}
            key={fareClass}
            classes={{ tooltip: "fare-type-tooltip" }}
            placement="top-start"
            arrow
            title={
              matchesMobile || tenantFlagsDisabled.FareClassTooltip ? (
                ""
              ) : (
                <>
                  <Typography variant="h6" className="fareclass-tooltip">
                    {t("fareClass.fares", {
                      fareClass: t(FARE_CLASS_I18N_KEY[fareClass]),
                    })}
                  </Typography>
                  <Typography variant="caption" className="fareclass-tooltip">
                    {t(FARE_CLASS_DETAILS_I18N_KEY[fareClass])}
                  </Typography>
                </>
              )
            }
            id={fareClass}
          >
            <Button
              variant="outlined"
              onClick={() => handleSetOption(fareClass)}
              aria-pressed={Boolean(fareClassOptionFilter[fareClass])}
              aria-describedby={fareClass}
            >
              {t(FARE_CLASS_I18N_KEY[fareClass])}
            </Button>
          </Tooltip>
        </Box>
      ))}
    </Box>
  );
};
