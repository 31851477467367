import { Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useI18nContext } from "@hopper-b2b/i18n";
import { getHotelStayDuration } from "@hopper-b2b/utilities";
import { HotelItinerary } from "@b2bportal/lodging-api";
import "./styles.scss";
import { Divider } from "@hopper-b2b/ui";

interface IHotelPaymentModalContentProps {
  hotel: HotelItinerary;
}

export const HotelPaymentModalContent = ({
  hotel,
}: IHotelPaymentModalContentProps) => {
  const { t, formatFiatCurrency } = useI18nContext();

  const { reservation, paymentBreakdown, productTotal, paymentTotal } = hotel;
  const { payNowTotal, rooms, taxes, feeBreakdown, tripTotal } =
    reservation.pricing;
  const total = productTotal.fiat.value ? productTotal.fiat : tripTotal.fiat;
  const paidAmount = paymentTotal.fiat.value
    ? paymentTotal.fiat
    : payNowTotal.fiat;
  const additionalFees = feeBreakdown?.total;
  const modalContent = paymentBreakdown.payments.reduce((labels, payment) => {
    switch (payment.PaymentLineItem) {
      case "ApplePay":
        break;
      case "UserCard":
        labels.cardLabel = payment.cardNumberDisplay;
        labels.cardValue = {
          value: payment.amount.amount,
          currencyCode: payment.amount.currency,
        };
        break;
      case "TravelWallet":
        switch (payment.TravelWallet) {
          case "Credit":
            labels.rewardLabel = t("voucherDiscountText");
            break;
          case "Offer":
            labels.rewardLabel = t("offerApplied");
            break;
        }
        labels.rewardValue = {
          value: payment.amount.amount,
          currencyCode: payment.amount.currency,
        };
        break;
    }
    return labels;
  }, {} as { cardLabel?: string; rewardLabel?: string; cardValue?: { value: number; currencyCode: string }; rewardValue?: { value: number; currencyCode: string } });

  return (
    <div className="hotel-payment-modal-content">
      <div className="hotel-payment-title-container modal-header">
        <Typography className="modal-title" variant="h4">
          {t("tripTotal")}
        </Typography>
      </div>
      <div className="hotel-payment-container modal-content">
        <div className="hotel-payment-details-container">
          <Typography
            className="payment-price-breakdown modal-subtitle"
            variant="h4"
          >
            {t("priceBreakdown")}
          </Typography>
          <div className="hotel-pricing-row">
            <Typography variant="body2">
              {t("priceLabelForNights", { count: getHotelStayDuration(hotel) })}
            </Typography>
            <Typography variant="body2" className="price">
              {formatFiatCurrency(rooms.fiat)}
            </Typography>
          </div>
          <div className="hotel-pricing-row">
            <Typography variant="body2">{t("taxesAndFees")}</Typography>
            <Typography variant="body2" className="price">
              {formatFiatCurrency(taxes.fiat)}
            </Typography>
          </div>

          {modalContent.rewardLabel ? (
            <div className="rewards-container">
              <Typography variant="body2">
                {modalContent.rewardLabel}
              </Typography>
              <Typography variant="body1" className="rewards-points price">
                - {formatFiatCurrency(modalContent.rewardValue)}
              </Typography>
            </div>
          ) : null}
          <div className="trip-total-row">
            <Typography variant="body1" className="trip-total-text">
              {t("total")}
            </Typography>
            <Typography variant="body1" className="trip-total-text">
              {formatFiatCurrency(total)}
            </Typography>
          </div>
          <Divider />
          <div className="hotel-pricing-row">
            <Typography variant="body2" className="price-bold-row">
              {t("paidAtBooking")}
            </Typography>
            <Typography variant="body2" className="price-bold-row">
              {formatFiatCurrency(paidAmount)}
            </Typography>
          </div>
          {modalContent.cardLabel && modalContent.cardValue ? (
            <div className="payment-card-container">
              <div className="payment-card-details-container">
                <FontAwesomeIcon
                  icon={faCreditCard as IconProp}
                  className="credit-card-icon"
                />
                <Typography variant="body2" className="payment-card-details">
                  {modalContent.cardLabel}
                </Typography>
              </div>
              <Typography variant="body1" className="payment-card-amount">
                {formatFiatCurrency(modalContent.cardValue)}
              </Typography>
            </div>
          ) : null}
          {additionalFees && additionalFees.value !== 0 && (
            <div className="hotel-pricing-row">
              <Typography variant="body2" className="price-bold-row">
                {t("hotelDueUponCheckIn")}
              </Typography>
              <Typography variant="body2" className="price-bold-row">
                {formatFiatCurrency(additionalFees)}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
