import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import "./styles.scss";

interface StepCrumbProps {
  title: string;
  status: "completed" | "selected" | "pending";
  onClick: () => void;
}

export const StepCrumb = ({ title, status, onClick }: StepCrumbProps) => {
  const { t } = useI18nContext();

  return (
    <Box className={clsx(status, "step-wrapper")} onClick={onClick}>
      <Typography className="title">{title}</Typography>
      <Typography className="subtitle">
        {t(`commBank.status.${status}`)}
      </Typography>
    </Box>
  );
};
