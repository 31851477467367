import { useSelector } from "react-redux";
import { IApiConfig } from "@hopper-b2b/types";

export const UPDATE_API_CONFIG = "UPDATE_API_CONFIG";
export type UPDATE_API_CONFIG = typeof UPDATE_API_CONFIG;

export interface IUPDATE_API_CONFIG {
  type: string;
  apiConfig?: IApiConfig;
}

export const updateApiConfigAction = (
  actionPrefix: string,
  apiConfig?: IApiConfig
): IUPDATE_API_CONFIG => ({
  type: `${actionPrefix}/${UPDATE_API_CONFIG}`,
  apiConfig,
});

export type ApiConfigActions = IUPDATE_API_CONFIG;

export const apiConfigInitialState: IApiConfig = {
  experimentsApiPrefix: "",
  rewardsApiPrefix: "",
  takeoversApiPrefix: "",
  analyticsApiPrefix: "",
  fetchUserInfoPath: "",
};

export function getApiConfigReducer(actionPrefix: string) {
  return function apiConfigReducer(
    state: IApiConfig = apiConfigInitialState,
    action: ApiConfigActions
  ): IApiConfig {
    switch (action.type) {
      case `${actionPrefix}/${UPDATE_API_CONFIG}`:
        return {
          ...state,
          ...action.apiConfig,
        };

      default:
        return state;
    }
  };
}

export const useApiConfigSelector = (key: string) =>
  useSelector((state: any) => state[key]);
