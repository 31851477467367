import { ApacActionButton } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { NoResults } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ClientContext } from "../../../../../../../ApacFlightApp";
import { PATH_HOME_SEARCH } from "../../../../../../../utils/urlPaths";
import { resetFilters } from "../../../../../../search/actions/actions";
import {
  MobileFlightSearchStep,
  getMaxPriceFilter,
} from "../../../../../../search/reducer";
import "./FlightFindMoreResults.styles.scss";
import { FlightFindMoreResultsConnectorProps } from "./container";

export interface IFlightFindMoreResultsProps
  extends FlightFindMoreResultsConnectorProps,
    RouteComponentProps {}

export const FlightFindMoreResults = (props: IFlightFindMoreResultsProps) => {
  const {
    className,
    setMobileSearchProgress,
    history,
    setIsSearchBarEditMode,
    onOpenFilter,
    variant,
  } = props;

  const { matchesMobile } = useDeviceTypes();
  const { t } = useI18nContext();
  const maxPrice = useSelector(getMaxPriceFilter);
  const dispatch = useDispatch();
  const clientContext = useContext(ClientContext);

  const buttons = [
    {
      title: t("flightShopAdjustDates"),
      isPrimary: true,
      onClick: async () => {
        if (matchesMobile) {
          setMobileSearchProgress(MobileFlightSearchStep.CalendarPicker);
          history.push(PATH_HOME_SEARCH);
        } else {
          await window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setIsSearchBarEditMode(true);
        }
      },
    },
    {
      title: t("resetFilters"),
      onClick: () => {
        dispatch(resetFilters(maxPrice));
        onOpenFilter && onOpenFilter();
      },
    },
  ];
  return (
    <Box
      className={clsx("find-more-results", className, {
        mobile: matchesMobile,
      })}
    >
      {variant === "no-flight" ? (
        <NoResults
          className="flight-list-no-results"
          title={t("noFlightsFound")}
          subtitle={t("flightShopFooterSubtitle")}
          iconSrc={
            clientContext?.assets?.error ? clientContext.assets.error : null
          }
        />
      ) : (
        <div>
          <Typography className="generic-shop-list-footer-title" variant="h3">
            {t("flightShopFooterTitle")}
          </Typography>
          <Typography
            className="generic-shop-list-footer-subtitle"
            variant="body2"
          >
            {t("flightShopFooterSubtitle")}
          </Typography>
        </div>
      )}
      <Box className={clsx("buttons", { center: variant === "no-flight" })}>
        {buttons.map((b) => (
          <ApacActionButton
            key={b.title}
            message={b.title}
            onClick={b.onClick}
            className={clsx("footer-button", {
              outlined: !b.isPrimary,
            })}
          />
        ))}
      </Box>
    </Box>
  );
};
