import { useI18nContext } from "@hopper-b2b/i18n";
import {
  FrequentFlyerNumber,
  FrequentFlyerProgramCode,
} from "@hopper-b2b/types";
import { specialCharacterRegex } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  IFlyerOption,
  IFrequentFlyer,
  IFrequentFlyerList,
  formattedFlyerOptions,
  getNextTrackingId,
} from "../utils";
import "./FrequentFlyerSection.styles.scss";
import { ApacTextField } from "../../../ApacTextField";
import { ApacActionLink } from "../../../ApacActionLink";

interface IFrequentFlyerSectionProps {
  className?: string;
  frequentFlyerList: IFrequentFlyerList;
  setFrequentFlyerList: Dispatch<SetStateAction<IFrequentFlyerList>>;
  isMobile?: boolean;
  closeButton?: JSX.Element;
}

export const FrequentFlyerSection = (props: IFrequentFlyerSectionProps) => {
  const { className, frequentFlyerList, setFrequentFlyerList, isMobile } =
    props;
  const { t } = useI18nContext();

  const [curFrequentFlyerList, setCurFrequentFlyerList] =
    useState<IFrequentFlyerList>(frequentFlyerList);
  const [frequentFlyerNumber, setFrequentFlyerNumber] =
    useState<FrequentFlyerNumber>("");
  const [frequentFlyerProgram, setFrequentFlyerProgram] =
    useState<IFlyerOption | null>(null);
  const [editingFlyerIndex, setEditingFlyerIndex] = useState<number | null>(
    null
  );
  const [newTrackingId, setNewTrackingId] = useState<number>(
    getNextTrackingId(frequentFlyerList)
  );
  const [isInputChanged, setIsInputChanged] = useState<boolean>(false);
  const [frequentFlyerError, setFrequentFlyerError] = useState(false);

  const cleanUpFields = () => {
    // clean fields
    setFrequentFlyerNumber("");
    setFrequentFlyerProgram(null);
  };

  const cleanUp = () => {
    // set focused to null
    setEditingFlyerIndex(null);
    cleanUpFields();
  };

  const handleOnEdit = (frequentFlyer: IFrequentFlyer) => {
    // populate edit fields
    setFrequentFlyerNumber(frequentFlyer.value);
    setFrequentFlyerProgram(
      formattedFlyerOptions.find(
        (option) => frequentFlyer.key === option.value
      ) || null
    );

    // set focusedTrackingId
    setEditingFlyerIndex(frequentFlyer.trackingId);

    // when flyer number & program are populated, and the current newTrackingId matches
    // with the entry in list, the newTrackingId needs to get updated as well
    const newEntry = curFrequentFlyerList.find(
      (frequentFlyer) => frequentFlyer.trackingId === newTrackingId
    );
    if (newEntry) {
      if (
        newEntry.key === frequentFlyerProgram?.value &&
        newEntry.value === frequentFlyerNumber
      ) {
        setNewTrackingId(getNextTrackingId(curFrequentFlyerList));
      }
    }
  };

  const handleOnRemove = (trackingId: number) => {
    setCurFrequentFlyerList((frequentFlyerList) => {
      return frequentFlyerList.filter((frequentFlyer) => {
        return frequentFlyer.trackingId !== trackingId;
      });
    });
  };

  const renderSavedFrequentFlyers = () => {
    return (
      <Box className="saved-frequent-flyers-section">
        {curFrequentFlyerList
          .filter((frequentFlyer) => {
            return (
              frequentFlyer.trackingId !== newTrackingId &&
              frequentFlyer.trackingId !== editingFlyerIndex
            );
          })
          .map((frequentFlyer) => {
            return (
              <FrequentFlyerRow
                key={frequentFlyer.key}
                className="saved-frequent-flyer"
                frequentFlyerNumber={frequentFlyer.value}
                // TODO: handle this logic differently once knowing where to get all airlines
                frequentFlyerProgramName={
                  (formattedFlyerOptions.find(
                    (option) => option.value === frequentFlyer.key
                  )?.label as JSX.Element) ?? <></>
                }
                onEdit={() => handleOnEdit(frequentFlyer)}
                onRemove={() => handleOnRemove(frequentFlyer.trackingId)}
                isMobile={isMobile}
              />
            );
          })}
      </Box>
    );
  };

  const getFilteredFlyerOptions = () => {
    const addedFlyerPrograms = new Set();
    curFrequentFlyerList.forEach((frequentFlyer) => {
      if (
        frequentFlyer.trackingId !== editingFlyerIndex &&
        frequentFlyer.trackingId !== newTrackingId &&
        !addedFlyerPrograms.has(frequentFlyer.key)
      ) {
        addedFlyerPrograms.add(frequentFlyer.key);
      }
    });

    return formattedFlyerOptions.filter((option) => {
      return !addedFlyerPrograms.has(option.value);
    });
  };

  const isButtonDisabled = !frequentFlyerNumber || !frequentFlyerProgram;

  const updateCurFrequentFlyerList = (
    programNumber: FrequentFlyerNumber,
    programCode: FrequentFlyerProgramCode,
    trackingId: number
  ) => {
    setCurFrequentFlyerList((frequentFlyerList) => {
      const newFrequentFlyerList = [...frequentFlyerList];
      const focusedIndex = newFrequentFlyerList.findIndex(
        (frequentFlyer) => frequentFlyer.trackingId === trackingId
      );

      newFrequentFlyerList[focusedIndex] = {
        ...newFrequentFlyerList[focusedIndex],
        key: programCode,
        value: programNumber,
      };

      return newFrequentFlyerList;
    });
  };

  const addToCurFrequentFlyerList = (
    programNumber: FrequentFlyerNumber,
    airlineCode: FrequentFlyerProgramCode,
    trackingId: number
  ) => {
    setCurFrequentFlyerList((frequentFlyerList) => {
      const newFrequentFlyerList = frequentFlyerList;
      newFrequentFlyerList.push({
        key: airlineCode,
        value: programNumber,
        trackingId: trackingId,
      });

      return newFrequentFlyerList;
    });
  };

  const handleAddAnother = () => {
    if (isButtonDisabled) {
      return null;
    }

    cleanUp();
    setNewTrackingId(getNextTrackingId(curFrequentFlyerList));
    return null;
  };

  const handleSaveFlyer = () => {
    // frequentFlyerProgram cannot be resolved as string when isButtonDisabled is used here; it's likely a compiler issue
    if (!frequentFlyerNumber || !frequentFlyerProgram) {
      return null;
    }

    if (editingFlyerIndex !== null) {
      // update existing entry
      updateCurFrequentFlyerList(
        frequentFlyerNumber,
        frequentFlyerProgram.value,
        editingFlyerIndex
      );
    }

    cleanUp();
    return null;
  };

  const renderEditFields = () => {
    return (
      <form className="flyer-edit-form">
        <Autocomplete
          fullWidth
          value={frequentFlyerProgram}
          defaultValue={null}
          className="frequent-flyer-autocomplete"
          renderOption={({ label }) => label}
          options={getFilteredFlyerOptions()}
          onChange={(_e, option: IFlyerOption) =>
            setFrequentFlyerProgram(option)
          }
          getOptionLabel={({ airline, program }) => `${airline} - ${program}`}
          renderInput={(params: any) => (
            <div className="input-wrapper">
              <Typography variant="h6" className="input-label">
                {t("frequentFlyerProgram")}
              </Typography>
              <ApacTextField
                {...params}
                placeholder={t("choose")}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable browser's autocomplete and autofill
                }}
                required={false}
              />
            </div>
          )}
          id="frequent-flyer-program-input"
        />
        <div className="input-wrapper">
          <Typography variant="h6" className="input-label">
            {t("frequentFlyerNumber")}
          </Typography>
          <div className="flyer-number-input-row">
            <ApacTextField
              required={false}
              className={clsx("traveler-info-field", "flyer-number", {
                error: !!frequentFlyerNumber && frequentFlyerError,
              })}
              value={frequentFlyerNumber}
              error={!!frequentFlyerNumber && frequentFlyerError}
              helperText={frequentFlyerError && t("noSpecialCharactersError")}
              onChange={({ target: { value } }) => {
                setFrequentFlyerNumber(value);
                if (value && specialCharacterRegex.test(value)) {
                  frequentFlyerError && setFrequentFlyerError(false);
                } else if (value && !specialCharacterRegex.test(value)) {
                  !frequentFlyerError && setFrequentFlyerError(true);
                }
              }}
            />
            <ApacActionLink
              disabled={!frequentFlyerNumber || !frequentFlyerProgram}
              message={t(
                editingFlyerIndex === null
                  ? "passengerInformation.addMembership"
                  : "passengerInformation.saveMembership"
              )}
              onClick={
                editingFlyerIndex === null ? handleAddAnother : handleSaveFlyer
              }
            />
          </div>
        </div>
      </form>
    );
  };

  useEffect(() => {
    setIsInputChanged(true);
  }, [frequentFlyerNumber, frequentFlyerProgram]);

  useEffect(() => {
    // on mobile, the focusedTrackingId should be treated as null when editFieldsModal isn't open
    const isFocusedTrackingIdNull = editingFlyerIndex === null || isMobile;

    // when both flyer number and program fields are populated, and there is no focused trackingId (not editting an existing entry)
    if (
      isInputChanged &&
      frequentFlyerNumber &&
      frequentFlyerProgram &&
      isFocusedTrackingIdNull
    ) {
      const indexWithNewTrackingId = curFrequentFlyerList.findIndex(
        (frequentFlyer) => frequentFlyer.trackingId === newTrackingId
      );

      // when there is an existing entry having newTrackingId
      if (indexWithNewTrackingId >= 0) {
        // update current entry
        updateCurFrequentFlyerList(
          frequentFlyerNumber,
          frequentFlyerProgram.value,
          newTrackingId
        );
      } else {
        // add new entry
        addToCurFrequentFlyerList(
          frequentFlyerNumber,
          frequentFlyerProgram.value,
          newTrackingId
        );
      }

      setIsInputChanged(false);
    }
  }, [
    isInputChanged,
    frequentFlyerNumber,
    frequentFlyerProgram,
    editingFlyerIndex,
    curFrequentFlyerList,
    newTrackingId,
    isMobile,
  ]);

  useEffect(() => {
    setFrequentFlyerList(curFrequentFlyerList);
  }, [curFrequentFlyerList, setFrequentFlyerList]);

  return (
    <Box
      className={clsx(
        "apac-frequent-flyer-container",
        { mobile: isMobile },
        className
      )}
    >
      {renderEditFields()}

      {renderSavedFrequentFlyers()}
    </Box>
  );
};

interface IFrequentFlyerRowProps {
  className?: string;
  frequentFlyerNumber: FrequentFlyerNumber;
  frequentFlyerProgramName: JSX.Element;
  onEdit?: () => void;
  onRemove?: () => void;
  isMobile?: boolean;
}

const FrequentFlyerRow = (props: IFrequentFlyerRowProps) => {
  const {
    className,
    frequentFlyerNumber,
    frequentFlyerProgramName,
    onEdit,
    onRemove,
    isMobile,
  } = props;

  const { t } = useI18nContext();

  return (
    <Box
      className={clsx(
        "apac-frequent-flyer-row-root",
        { mobile: isMobile },
        className
      )}
    >
      <Box className="frequent-flyer-row-container">
        <Box className="frequent-flyer-info-section">
          <Box className="frequent-flyer-info-text">
            {frequentFlyerProgramName}
            {` ***${frequentFlyerNumber.slice(-4)}`}
          </Box>
        </Box>
        <Box className="frequent-flyer-buttons-section">
          {onEdit && (
            <ApacActionLink
              className="edit-button"
              disabled={false}
              onClick={() => onEdit()}
              message={t("edit")}
            />
          )}
          {onRemove && (
            <>
              <Typography variant="body2">|</Typography>
              <ApacActionLink
                className="remove-button"
                disabled={false}
                onClick={() => onRemove()}
                message={t("delete")}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
