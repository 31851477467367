import { CipherText } from "@b2bportal/air-cfar-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { CfarExerciseProgress } from "@hopper-b2b/types";
import { ActionButton, MobileFloatingButton } from "@hopper-b2b/ui";
import {
  getEnvVariables,
  getHopperLocatorFromBookedFlightItinerary,
} from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  confirmFlightCfarCancellation,
  confirmFlightCfarCancellationV1,
  replaceTripQueryParams,
  resetCfarExerciseState,
  setCfarExerciseProgress,
  setSelectedFlight,
} from "../../../../TripsList/actions/actions";
import { getSelectedFlight } from "../../../../TripsList/reducer/selectors";

interface CfarProgressCtaProps {
  cfarExerciseProgress: CfarExerciseProgress;
  quoteId: CipherText | null;
  useCfarV1: boolean;
  disableFloatingButton?: boolean;
  done?: () => void;
}
export const CfarProgressCta = ({
  cfarExerciseProgress,
  quoteId,
  useCfarV1,
  disableFloatingButton = false,
  done,
}: CfarProgressCtaProps) => {
  const { t } = useI18nContext();
  const dispatch = useDispatch();
  const history = useHistory();

  const flight = useSelector(getSelectedFlight);

  const buttonText = useMemo(() => {
    switch (cfarExerciseProgress) {
      case CfarExerciseProgress.Review:
        return t("cfarExercise.cta.confirmCancellation");
      case CfarExerciseProgress.Completed:
        return t("cfarExercise.cta.completedCancellation");
      default:
        return t("cfarExercise.cta.cancelFlight");
    }
  }, [cfarExerciseProgress, t]);

  const handleClick = () => {
    switch (cfarExerciseProgress) {
      case CfarExerciseProgress.Review:
        confirmCfarExercise();
        return;
      case CfarExerciseProgress.Completed:
        concludeCfarExercise();
        done?.();
        return;
      default:
        done?.();
        return;
    }
  };

  const confirmCfarExercise = () => {
    if (useCfarV1) {
      dispatch(setCfarExerciseProgress(CfarExerciseProgress.Pending));
      dispatch(confirmFlightCfarCancellationV1({ quoteToken: quoteId }));
      dispatch(replaceTripQueryParams(history));
    } else {
      if (quoteId) {
        dispatch(setCfarExerciseProgress(CfarExerciseProgress.Pending));
        dispatch(confirmFlightCfarCancellation({ quoteToken: quoteId }));
        dispatch(replaceTripQueryParams(history));
      }
    }
  };

  const concludeCfarExercise = () => {
    dispatch(resetCfarExerciseState());
    dispatch(setSelectedFlight(null));
    dispatch(replaceTripQueryParams(history));
    if (getEnvVariables("clientName") === "uber") {
      const agentLocator = getHopperLocatorFromBookedFlightItinerary(flight);
      window.parent.postMessage(
        {
          type: "BOOKING_CANCELED",
          payload: { agentLocator },
        },
        import.meta.env["VITE_UBER_URL"]
      );
    }
  };

  return disableFloatingButton ? (
    <Box pb={2} style={{ display: "flex", justifyContent: "center" }}>
      <ActionButton
        className="cfar-cancel-flight-cta"
        message={buttonText}
        disabled={!quoteId}
        onClick={handleClick}
      />
    </Box>
  ) : (
    <MobileFloatingButton
      className="cfar-cancel-flight-cta"
      onClick={handleClick}
      wide
      disabled={!quoteId}
    >
      {buttonText}
    </MobileFloatingButton>
  );
};
