import { useCallback } from "react";
import { useI18nContext } from "./useI18nContext";
import { territoryCodes } from "./territoryCodes";

/**
 * Returns getCountryName function that returns a translated name of the country and
 * getAllCountryNames function that returns a list of translated name of countries
 *
 * The functions are basically a wrapper to `Intl.DisplayNames`. The hook may not look
 * necessary because each component can call `Intl.DisplayNames` directly, but it is here
 * because the hook was introduced to replace the 3rd party library we have been using.
 */
export function useI18nCountry() {
  const { language, languages } = useI18nContext();

  // Find the most suitable Intl.DisplayNames object based on the list of
  // supported languages, and ultimately fallback to "en"
  const getIntlDisplayNamesWithFallback = useCallback(() => {
    let intlDisplayNames: Intl.DisplayNames | undefined;
    // `new Intl.DisplayNames` can throw _if_ the `language` is not supported
    try {
      intlDisplayNames = intlDisplayNamesFor(language);
    } catch {
      // If ever the language was not supported by Intl.DisplayNames,
      // Look for the first supported language
      for (const lang of languages) {
        try {
          intlDisplayNames = intlDisplayNamesFor(lang);
        } catch {
          // noop, just move on to the next language
        }
        if (intlDisplayNames != null) {
          break;
        }
      }
    }
    // If we cannot find anything that works, fall back to English
    return intlDisplayNames != null
      ? intlDisplayNames
      : intlDisplayNamesFor("en");
  }, [language, languages]);

  const getCountryName = useCallback(
    (countryCode: string) => getIntlDisplayNamesWithFallback().of(countryCode),
    [getIntlDisplayNamesWithFallback]
  );

  const getAllCountryNames = useCallback(
    () => territoryNamesFor(getIntlDisplayNamesWithFallback()),
    [getIntlDisplayNamesWithFallback]
  );

  return { getCountryName, getAllCountryNames };
}

const territoryNamesCache: Record<string, Record<string, string>> = {};
const intlDisplayNamesCache: Record<string, Intl.DisplayNames> = {};

// Cache Intl.DisplayNames because Intl objects are fairly expensive to create
function intlDisplayNamesFor(language: string) {
  let intlDisplayNames = intlDisplayNamesCache[language];
  if (intlDisplayNames == null) {
    intlDisplayNames = new Intl.DisplayNames(language, { type: "region" });
    intlDisplayNamesCache[language] = intlDisplayNames;
  }
  return intlDisplayNames;
}

// Cache the name of all territories for each language
function territoryNamesFor(intlDisplayNames: Intl.DisplayNames) {
  const language = intlDisplayNames.resolvedOptions().locale;
  let territoryNames = territoryNamesCache[language];
  if (territoryNames == null) {
    territoryNames = territoryCodes.reduce((acc, countryCode) => {
      acc[countryCode] = intlDisplayNames.of(countryCode);
      return acc;
    }, {});
    territoryNamesCache[language] = territoryNames;
  }

  return territoryNames;
}
