import { put, select } from "redux-saga/effects";
import {
  CallState,
  GetEligibilityResponse,
  GetEligibilityRequestEnum,
} from "@hopper-b2b/types";

import Logger from "../../../../helpers/Logger";
import { actions } from "../../actions";
import { fetchPriceDropEligibility } from "../../../../api/v1/price-drop/fetchPriceDropEligibility";
import { flightApiConfigStoreKey } from "../../../../reducers/types";
import { IStoreState } from "../../../../reducers/types";

export function* fetchPriceDropEligibilitySaga(
  action: actions.IFetchPriceDropEligibility
) {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const priceDrop: GetEligibilityResponse = yield fetchPriceDropEligibility(
      {
        GetEligibilityRequest: GetEligibilityRequestEnum.ByCandidate,
        id: action.candidateId,
      },
      apiConfig
    );

    yield put(
      actions.setPriceDropProtection({
        priceDropProtection: priceDrop.eligibility,
        fetchPriceDropEligibilityCallState: CallState.Success,
      })
    );
  } catch (e) {
    yield put(
      actions.setPriceDropProtection({
        priceDropProtection: null,
        fetchPriceDropEligibilityCallState: CallState.Failed,
      })
    );
    Logger.debug(e);
  }
}
