import { I18nContextWrapper } from "@hopper-b2b/i18n";
import {
  IFlightSearchCriteria,
  IPassengerCounts,
  ITripTerminus,
  TripCategory,
} from "@hopper-b2b/types";
import {
  B2BButton,
  DesktopPopupModal,
  FlightCategoryToggle,
  Icon,
  IconName,
} from "@hopper-b2b/ui";
import { tenantFlagsEnabled } from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import H from "history";
import { i18n } from "i18next";
import { ChangeEvent, PureComponent } from "react";

import { PATH_SHOP } from "../../../../utils/urlPaths";
import { FlightSearchButton } from "../SearchButton";
import { CalendarPickerButton } from "./components";
import { PassengerCountPicker } from "./components/PassengerCountPicker";
import {
  DestinationAutocomplete,
  OriginAutocomplete,
} from "./components/TerminusAutocomplete";
import "./styles.scss";

export interface IFlightSearchControlProps extends Termini {
  hideCategoryToggle?: boolean;
  hidePassengerPicker?: boolean;
  onSearch?: () => void;
  tripCategory: TripCategory;
  numTravelers: number;
  filtersCount: number;
  departureDate: Date | null;
  returnDate: Date | null;
  adultsCount: number;
  childrenCount: number;
  infantsInSeatCount: number;
  infantsOnLapCount: number;
  setCalendar: () => void;
  fetchDepartureCalendar: () => void;
  populateSearchUrlParams: (
    params: IFlightSearchCriteria,
    history: H.History
  ) => void;
  history: H.History;
}

interface IFlightSearchControlState {
  openFlightSearchFiltersModal: boolean;
}

interface Termini {
  destination: ITripTerminus | null;
  origin: ITripTerminus | null;
}

interface IFlightSearchControlState {
  openPassengerCountPicker: boolean;
  openFlightSearchFiltersModal: boolean;
}

// @deprecated, remove after exchange redesign
export class FlightSearchControl extends PureComponent<
  IFlightSearchControlProps,
  IFlightSearchControlState
> {
  constructor(props: IFlightSearchControlProps) {
    super(props);
    this.state = {
      openPassengerCountPicker: false,
      openFlightSearchFiltersModal: false,
    };
  }

  componentDidMount() {
    const {
      history,
      destination,
      origin,
      tripCategory,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      populateSearchUrlParams,
    } = this.props;
    // Ensures URL params are set to current state on redirect to landing page - only important for page redirect not first page load, as tracks existing state
    if (origin && destination) {
      populateSearchUrlParams(
        {
          origin,
          destination,
          tripCategory,
          passengers: {
            adultsCount,
            childrenCount,
            infantsInSeatCount,
            infantsOnLapCount,
          },
        },
        history
      );
    }
  }

  componentDidUpdate(prevProps: IFlightSearchControlProps) {
    const {
      destination: prevDestination,
      origin: prevOrigin,
      tripCategory: prevTripCategory,
    } = prevProps;
    const {
      destination,
      origin,
      fetchDepartureCalendar,
      setCalendar,
      departureDate,
      tripCategory,
    } = this.props;

    if (
      tenantFlagsEnabled.FlightSearchCalendarPrediction &&
      ((origin &&
        destination &&
        this.hasTerminiChanged(
          { destination: prevDestination, origin: prevOrigin },
          { origin, destination }
        )) ||
        (tripCategory &&
          this.hasTripCategoryChanged(prevTripCategory, tripCategory)))
    ) {
      fetchDepartureCalendar();
    } else if (!departureDate) {
      setCalendar();
    }
  }

  hasTripCategoryChanged(
    prevTripCategory: TripCategory,
    tripCategory: TripCategory
  ): boolean {
    return prevTripCategory !== tripCategory;
  }

  hasTerminiChanged(
    { destination: prevDestination, origin: prevOrigin }: Termini,
    { destination, origin }: Termini
  ): boolean {
    const extractCode = (terminus: ITripTerminus | null) =>
      terminus && terminus.id && terminus.id.code ? terminus.id.code.code : "";

    const prevDestinationCode = extractCode(prevDestination);
    const prevOriginCode = extractCode(prevOrigin);
    const originCode = extractCode(origin);
    const destinationCode = extractCode(destination);

    return (
      prevDestinationCode !== destinationCode || prevOriginCode !== originCode
    );
  }

  handleInputChange =
    (valueSetter: (value: number) => void) =>
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      valueSetter(parseInt(event.target.value, 10));
    };

  handleTravelersSelected = () =>
    this.setState({ openPassengerCountPicker: true });

  handleTravelersChanged = (passengerCounts: IPassengerCounts) => {
    this.props.populateSearchUrlParams(
      { passengers: passengerCounts },
      this.props.history
    );
    this.setState({ openPassengerCountPicker: false });
  };

  closeFlightSearchFiltersModal = () =>
    this.setState({ openFlightSearchFiltersModal: false });

  updateTripCategory = (value: TripCategory) =>
    this.props.populateSearchUrlParams(
      { tripCategory: value },
      this.props.history
    );

  updateOrigin = (value: ITripTerminus | null) => {
    this.props.populateSearchUrlParams({ origin: value }, this.props.history);
  };

  updateDestination = (value: ITripTerminus | null) => {
    this.props.populateSearchUrlParams(
      { destination: value },
      this.props.history
    );
  };

  public render() {
    const {
      hideCategoryToggle,
      hidePassengerPicker,
      tripCategory,
      numTravelers,
      onSearch,
    } = this.props;
    const { openPassengerCountPicker } = this.state;

    const handleClosePassengerPicker = () => {
      this.setState({ openPassengerCountPicker: false });
    };

    return (
      <I18nContextWrapper>
        {({ t }: i18n) => {
          const numTravelerString = `${numTravelers} ${t(
            "searchFilter.pickerTraveler",
            { count: numTravelers }
          )}`;

          return (
            <Box className="flight-search">
              {hideCategoryToggle ? null : (
                <Box className="trip-type-and-traveler-pickers">
                  <FlightCategoryToggle
                    className={"flight-category-toggle b2b"}
                    category={tripCategory}
                    setTripCategory={this.updateTripCategory}
                    {...this.props}
                  />
                </Box>
              )}
              <Box className="location-pickers">
                <OriginAutocomplete
                  className="origin-auto-complete b2b"
                  customIcon={
                    <Icon
                      name={IconName.B2BMapPin}
                      ariaLabel=""
                      aria-hidden={true}
                    />
                  }
                  label={t("searchControl.whereFrom")}
                  loadingText={`${t("searching")}...`}
                  value={this.props.origin}
                  setValue={this.updateOrigin}
                  getOptionSelected={(
                    option: ITripTerminus | null,
                    value: ITripTerminus | null
                  ) => {
                    return (
                      !!value &&
                      !!option &&
                      value.id.code.code === option.id.code.code
                    );
                  }}
                />
                <DestinationAutocomplete
                  className="destination-auto-complete b2b"
                  customIcon={
                    <Icon
                      name={IconName.B2BMapPin}
                      ariaLabel=""
                      aria-hidden={true}
                    />
                  }
                  label={t("searchControl.whereTo")}
                  loadingText={`${t("searching")}...`}
                  value={this.props.destination}
                  setValue={this.updateDestination}
                  getOptionSelected={(
                    option: ITripTerminus | null,
                    value: ITripTerminus | null
                  ) => {
                    return (
                      !!value &&
                      !!option &&
                      value.id.code.code === option.id.code.code
                    );
                  }}
                />
              </Box>
              <Box className="date-filter-row">
                <CalendarPickerButton
                  classes={["date-pickers"]}
                  depDate={this.props.departureDate}
                  retDate={this.props.returnDate}
                />
                <Box className="filter-pickers">
                  {hidePassengerPicker ? null : (
                    <>
                      <B2BButton
                        aria-label={numTravelerString}
                        className="num-travelers-input b2b"
                        variant="traveler-selector"
                        onClick={() => this.handleTravelersSelected()}
                      >
                        <Box className="num-traveler-content">
                          <Icon
                            aria-hidden={true}
                            className="icon-start"
                            name={IconName.B2BUser}
                            ariaLabel=""
                          />
                          <Box className="text">{numTravelerString}</Box>
                          <Icon
                            aria-hidden={true}
                            className="icon-end"
                            name={IconName.B2BEditPencil}
                            ariaLabel=""
                          />
                        </Box>
                      </B2BButton>
                      <DesktopPopupModal
                        open={openPassengerCountPicker}
                        className="flight-desktop-passenger-count-picker-popup"
                        contentClassName="desktop-passenger-count-picker-popup-container"
                        onClose={handleClosePassengerPicker}
                        invisibleBackdrop={false}
                        headerElement={t("searchEditTravelersTitle")}
                      >
                        <PassengerCountPicker
                          minimumCount={1}
                          onClickApply={this.handleTravelersChanged}
                          className="b2b"
                          onClose={handleClosePassengerPicker}
                        />
                      </DesktopPopupModal>
                    </>
                  )}
                  <FlightSearchButton
                    className="flight-search-button b2b"
                    message={t("searchButton")}
                    onClick={(history: H.History) => {
                      if (onSearch) {
                        return onSearch();
                      }

                      history.push(PATH_SHOP);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          );
        }}
      </I18nContextWrapper>
    );
  }
}
