import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ReactNode, useMemo, useRef } from "react";
import {
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
} from "@b2bportal/air-booking-api";
import { CancelScenarioEnum, NonCfarEnum } from "@b2bportal/air-cancel-api";
import { getBookingSupportId, trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { BookingType, IOpenModal, SelfServeEvents } from "@hopper-b2b/types";
import {
  ActionLink,
  FlightCancelationInfo,
  GenericModalContent,
  SlicePickerModalContent,
} from "@hopper-b2b/ui";
import { ActionButton } from "@hopper-b2b/ui/core";
import styles from "./styles.module.scss";
import { ContactSupportModalContent } from "../ApacContactSupportModalContent/ContactSupportModalContent";
import {
  useFlightCancellation,
  CancelStep,
  getTrackingProps,
} from "../../hooks/flight-cancellation";
import { ItinerarySummaryRow as SummaryRow } from "./ItenerarySummaryRow";

export interface ICancelFlightModalContentProps {
  airlines: Record<string, Airline>;
  airports: Record<string, Airport>;
  flight: BookedFlightItineraryWithDepartureTime;
  getStatusTag: (isOutgoing: boolean) => ReactNode;
  isMobile?: boolean;
  onClose?: (cancelSuccess?: boolean) => void;
  setOpenModal: (newModal?: IOpenModal) => void;
}

/**
 * @description Dynamically render flight cancelation states
 * @param {ICancelFlightModalContentProps} props
 * @return {JSX.Element}
 */
const CancelFlightModalContent = (props: ICancelFlightModalContentProps) => {
  const { airlines, airports, flight, onClose, setOpenModal } = props;
  const { t } = useI18nContext();

  const locatorRef = useRef("");
  const numEligibleRef = useRef(0);

  /**
   * @description Updates the redux store to close this modal
   */
  const closeModal = (cancelSuccess = false) => {
    const closedModal = { type: null, selectedItinerary: null };
    if (onClose) onClose(cancelSuccess);

    setOpenModal(closedModal);
  };

  const {
    context: { timedOut, cancelStep, scenario, cancelInfoRef },
    handlers: {
      startCancellationInfoTimer,
      startCancellationConfirmTimer,
      setCancelStep,
      redirectToAirline,
      onConfirmFlightCancellation,
      onContactAirline,
      onContactSupport,
      confirmCancellation,
      getCancelInfo,
    },
  } = useFlightCancellation({
    flight,
    airlines,
    closeModal,
  });

  const ItinerarySummaryRow = (
    <SummaryRow {...props} locator={locatorRef.current} />
  );

  /**
   * @description Renders icon, title, subtitle, actions in a column
   * @return {ReactNode}
   */
  const renderCancellationError = () => {
    let PrimaryAction = null;
    let body: string[] | string = t("cancelFlightModal.error.subtitle");

    let title = t(
      `cancelFlightModal.error.${
        cancelStep === CancelStep.CancelationError
          ? "policyTitle"
          : "cancelTitle"
      }`
    );

    if (scenario) {
      switch (scenario.CancelScenario) {
        case CancelScenarioEnum.AirlineControl:
        case CancelScenarioEnum.Cfar:
          ({ body, title } = scenario.copy);
          break;
        case CancelScenarioEnum.BookingPending:
          title = t("cancelFlightModal.error.bookingPendingTitle");
          break;
        case CancelScenarioEnum.Canceled:
          title = t("cancelFlightModal.error.canceledTitle");
          break;
        case CancelScenarioEnum.Departed:
          PrimaryAction = (
            <ActionLink
              className="b2b"
              content={
                <>
                  {t("selfServe.visitAirline")}
                  <FontAwesomeIcon icon={faExternalLinkAlt as IconProp} />
                </>
              }
              onClick={redirectToAirline}
            />
          );
          body = t("cancelFlightModal.departedSubtitle");
          title = t("cancelFlightModal.departedTitle");
          break;
        default:
          PrimaryAction = (
            <ActionButton
              defaultStyle="h4r-primary"
              message={t("selfServe.tryAgainBtn")}
              onClick={() => getCancelInfo()}
            />
          );
      }
    }

    return (
      <GenericModalContent
        actions={PrimaryAction}
        className={clsx(
          styles.flightCancelErrorModal,
          "self-serve-cancel-flight-error-modal"
        )}
        subtitle={body}
        title={title}
        titleProps={{ variant: "h1" }}
      />
    );
  };

  /**
   * @description Renders success icon, title, subtitle, success actions in a column
   * @return {ReactNode}
   */
  const renderCancellationFlowComplete = () => {
    const { current: numEligible } = numEligibleRef;
    let newNumEligible = numEligible;

    // if numEligible was set - the booking was multi-ticket
    if (numEligible > 1) {
      newNumEligible = numEligible - 1;

      numEligibleRef.current = newNumEligible;
    }

    return (
      <GenericModalContent
        actions={
          <>
            {newNumEligible > 1 && (
              <ActionButton
                className="cancel-again-btn"
                defaultStyle="h4r-primary"
                message={t("cancelFlightModal.cancelAnotherBtn")}
                onClick={() => getCancelInfo()}
              />
            )}
            <ActionButton
              className="done-btn"
              defaultStyle={
                newNumEligible > 1 ? "h4r-secondary" : "h4r-primary"
              }
              message={t("done")}
              onClick={() => closeModal(true)}
            />
          </>
        }
        className={clsx(
          styles.flightCancelFlowComplete,
          "flight-cancel-flow-complete"
        )}
        content={
          <>
            <Typography className="subtitle">
              {t("cancelFlightModal.successSubtitle")}
            </Typography>
            {newNumEligible > 1 && (
              <Typography className="subtitle">
                {t("cancelFlightModal.successSubtitleMultiTicket")}
              </Typography>
            )}
          </>
        }
        title={t("cancelFlightModal.successTitle")}
      />
    );
  };

  /**
   * @description Renders title, subtitle, itinerary summary, important info, and
   * actions in a left-justified column.
   * @return {ReactNode}
   */
  const renderCancellationInfo = () => {
    if (!scenario || !("NonCfar" in scenario)) return;

    startCancellationInfoTimer();

    let PrimaryAction = null;
    let body, disclaimer, importantInfo, informativeSection, title;
    let policyName = scenario.NonCfar;
    if ("cancelCopy" in scenario) {
      ({ body, disclaimer, importantInfo, informativeSection, title } =
        scenario.cancelCopy);
    } else if (
      "subTicketPolicies" in scenario &&
      scenario.subTicketPolicies.length > 0
    ) {
      // For Multi-Provider cancellations, source the scenario from the sub-ticket policies
      // Since we can only select one flight leg at a time, use the first sub-ticket policy
      ({ body, disclaimer, importantInfo, informativeSection, title } =
        scenario.subTicketPolicies[0].cancelCopy);
      policyName = scenario.subTicketPolicies[0].policyName as NonCfarEnum;
    }

    switch (policyName) {
      case NonCfarEnum.ContactAirline:
      case NonCfarEnum.NonCancellable:
        PrimaryAction = (
          <ActionButton
            className="visit-airline-btn"
            defaultStyle="h4r-primary"
            message={t("selfServe.visitAirline")}
            onClick={onContactAirline}
          />
        );
        break;
      case NonCfarEnum.ContactCustomerService:
      case NonCfarEnum.RefundableComplex:
        PrimaryAction = (
          <ActionButton
            className="confirm-btn"
            defaultStyle="h4r-primary"
            message={t("contactSupport")}
            onClick={onContactSupport}
          />
        );
        break;
      case NonCfarEnum.AirlineRefund:
      case NonCfarEnum.Ftc:
      case NonCfarEnum.FtcWithPenalty:
      case NonCfarEnum.MultiProvider:
      case NonCfarEnum.MultiTicket:
      case NonCfarEnum.NonRefundable:
      case NonCfarEnum.PartialRefund:
      case NonCfarEnum.TicketedVoid:
      case NonCfarEnum.TicketlessVoid:
        PrimaryAction = (
          <ActionButton
            className="confirm-btn"
            defaultStyle="h4r-primary"
            message={
              timedOut
                ? t("cancelFlightModal.refreshPolicyBtn")
                : t("cancelFlightModal.cancelBtn")
            }
            onClick={onConfirmFlightCancellation}
          />
        );
        break;
    }

    return (
      <FlightCancelationInfo
        ItinerarySummary={ItinerarySummaryRow}
        actions={PrimaryAction}
        className={styles.flightCancelInfo}
        disclaimer={disclaimer}
        infoItems={importantInfo}
        subtitle={body}
        tcHelpText={informativeSection?.body}
        title={title}
      />
    );
  };

  /**
   * @description Renders just the summary and prompts the user to confirm the
   * cancelation
   * @return {ReactNode}
   */
  const renderConfirmCancellation = () => {
    const isHFv2 =
      scenario.CancelScenario === CancelScenarioEnum.NonCfar &&
      scenario.NonCfar === NonCfarEnum.MultiProvider;
    let body, title;

    if (!scenario) return;

    trackEvent({
      eventName: SelfServeEvents.ClickCancel,
      properties: getTrackingProps(scenario),
    });

    startCancellationConfirmTimer(isHFv2);

    if ("cancelConfirmationCopy" in scenario) {
      ({ body, title } = scenario.cancelConfirmationCopy);
    } else if (isHFv2) {
      title = t("cancelFlightModal.multiProviderTitle");
    }

    return (
      <FlightCancelationInfo
        ItinerarySummary={ItinerarySummaryRow}
        actions={
          <ActionButton
            className="confirm-cancelation-btn"
            defaultStyle="h4r-primary"
            fill="red"
            message={t("cancelFlightModal.confirmBtn")}
            onClick={confirmCancellation}
          />
        }
        className={styles.flightCancelConfirm}
        subtitle={body}
        title={title}
      />
    );
  };

  const renderContactCustomerService = () => {
    return (
      <ContactSupportModalContent
        bookingId={flight.bookedItinerary.id}
        bookingUuid={flight.bookedItinerary.id}
        bookingType={BookingType.Flight}
        className={clsx(
          styles.flightCancelCustomerSupport,
          "flight-cancel-customer-support"
        )}
        requestType="Cancel"
        getSupportId={getBookingSupportId}
        showHelpLink={false}
        subtitle={t("selfServe.supportSubtitle")}
        title={t("selfServe.supportTitle")}
      />
    );
  };

  /**
   * @description Show a spinner, title, and subtitle when loading cancel info
   * or processing a cancelation request.
   */
  const renderLoadingOrProcessing = () => {
    const loadingMessage = t(
      `cancelFlightModal.${
        cancelStep === CancelStep.LoadingOrProcessing
          ? "processingCancel"
          : "loadingContext"
      }`
    );

    return (
      <GenericModalContent
        className={clsx(styles.flightCancelLoading, "flight-cancel-loading")}
        image={<CircularProgress className="cancel-flight-policy-loading" />}
        subtitle={<Typography variant="subtitle2">{loadingMessage}</Typography>}
      />
    );
  };

  /**
   * @description Renders modal for a pending cancelation response
   * @return {ReactNode}
   */
  const renderCancellationPending = () => {
    return (
      <GenericModalContent
        actions={
          <ActionButton
            defaultStyle="h4r-primary"
            message={t("done")}
            onClick={() => closeModal(false)}
          />
        }
        className={clsx(styles.flightCancelPending, "flight-cancel-pending")}
        columnAlign="center"
        title={t("cancelFlightModal.pendingTitle")}
        subtitle={t("cancelFlightModal.pendingSubtitle")}
      />
    );
  };

  const renderSlicePicker = () => {
    const { current: multiTicketPolicy } = cancelInfoRef;
    // temp ignore until BE types are updated
    // eslint-disable-next-line
    // @ts-ignore
    const { cancellationEligibilities = [] } = multiTicketPolicy as unknown;
    const eligibilityMap: Record<string, string> = {};
    let eligibleSlices = 0;

    for (let i = 0; i < cancellationEligibilities.length; i += 1) {
      const {
        bookingReference,
        eligibility: { Eligibility },
      } = cancellationEligibilities[i];

      eligibilityMap[bookingReference] = Eligibility;

      // TODO replace string with enum
      if (Eligibility !== "Ineligible") {
        eligibleSlices += 1;
      }
    }

    numEligibleRef.current = eligibleSlices;

    return (
      <SlicePickerModalContent
        isRadio
        airlines={airlines}
        airports={airports}
        checkboxLabel={t("choose")}
        className={clsx(styles.flightCancelSlicePicker)}
        flight={flight}
        isSliceDisabled={(_, __, locator) =>
          !eligibilityMap[locator] || eligibilityMap[locator] === "Ineligible"
        }
        onContactSupport={() => {
          setCancelStep(CancelStep.ContactSupport);
        }}
        onContinue={(selected) => {
          const selectedLocator = selected[0].locator;

          locatorRef.current = selectedLocator;
          getCancelInfo(selectedLocator);
        }}
        subtitle={t("cancelFlightModal.slicePickerSubtitle")}
        title={t("cancelFlightModal.slicePickerTitle")}
      />
    );
  };

  const ModalContent = useMemo(() => {
    switch (cancelStep) {
      case CancelStep.CancelationError:
        return renderCancellationError();
      case CancelStep.CancelationPending:
        return renderCancellationPending();
      case CancelStep.CancelationFlowComplete:
        return renderCancellationFlowComplete();
      case CancelStep.CancelationInfo:
        return renderCancellationInfo();
      case CancelStep.ConfirmCancelation:
        return renderConfirmCancellation();
      case CancelStep.ContactSupport:
        return renderContactCustomerService();
      case CancelStep.LoadingOrProcessing:
        return renderLoadingOrProcessing();
      case CancelStep.SlicePicker:
        return renderSlicePicker();
      default:
        return null;
    }
  }, [cancelStep, timedOut]);

  return (
    <Box
      className={clsx(
        styles.cancelFlightContainer,
        "self-serve-cancel-flight-modal-content"
      )}
    >
      {ModalContent}
    </Box>
  );
};

export default CancelFlightModalContent;
