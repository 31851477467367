import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { scheduleQuote } from "../../../../api/v1/book/book-flow/scheduleQuote";
import {
  FlightPriceQuoteRequest,
  PriceFreezeFlightPriceQuoteRequest,
  PaymentPriceQuoteRequestEnum,
  InvalidEnum,
  ConnectionResultEnum,
  ConnectionResult,
  Valid,
} from "@hopper-b2b/types";
import {
  selectedTripSelector,
  tripDetailsSelector,
} from "../../../shop/reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import {
  getSession,
  priceQuoteParamsSelector,
  priceFreezePriceQuoteParamsSelector,
} from "../../reducer";
import { closeSession } from "../../../../api/v1/book/session/closeSession";
import { flightApiConfigStoreKey } from "../../../../../src/reducers/types";

export function* scheduleQuoteSaga({
  agentFee,
  pollQuoteOnly,
  isPriceFreezePurchase,
}: actions.IScheduleQuote) {
  try {
    const state: IStoreState = yield select();
    const session = getSession(state);
    const selectedTrip = selectedTripSelector(state);
    const apiConfig = state[flightApiConfigStoreKey];

    if (!selectedTrip) {
      yield put(
        actions.setScheduleQuoteCallStateFailed({
          Invalid: InvalidEnum.Missing,
          ConnectionResult: ConnectionResultEnum.Invalid,
        })
      );
    }
    if (session) {
      yield closeSession(session);
    }
    const tripDetails = tripDetailsSelector(state, selectedTrip.tripId!);

    let params: PriceFreezeFlightPriceQuoteRequest | FlightPriceQuoteRequest;
    if (isPriceFreezePurchase) {
      const quoteParams = priceFreezePriceQuoteParamsSelector(state);
      params = {
        quoteRequest: quoteParams,
        PaymentPriceQuoteRequest:
          PaymentPriceQuoteRequestEnum.PriceFreezeFlightPriceQuoteRequest,
      } as PriceFreezeFlightPriceQuoteRequest;
    } else {
      const quoteParams = priceQuoteParamsSelector(state);
      params = {
        PaymentPriceQuoteRequest:
          PaymentPriceQuoteRequestEnum.FlightPriceQuoteRequest,
        quoteDescription: tripDetails.tripDescription,
        flightQuoteRequest: quoteParams,
      } as FlightPriceQuoteRequest;
    }
    const result: ConnectionResult = yield scheduleQuote(params, apiConfig);
    if (result) {
      if ("Invalid" in result) {
        yield put(actions.setScheduleQuoteCallStateFailed(result));
      } else {
        yield put(actions.setScheduleQuoteCallStateSuccess());
        yield put(actions.setSession((result as Valid).token));
        yield put(actions.pollQuote(agentFee, pollQuoteOnly));
      }
    } else {
      yield put(
        actions.setScheduleQuoteCallStateFailed({
          Invalid: InvalidEnum.Missing,
          ConnectionResult: ConnectionResultEnum.Invalid,
        })
      );
    }
  } catch (e) {
    yield put(
      actions.setSchedulePriceQuoteCallStateFailed({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );
  }
}
