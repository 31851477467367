import { SupportContext, useDeviceTypes } from "@hopper-b2b/utilities";
import "./styles.scss";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Suspense, lazy, useCallback, useContext } from "react";
const CustomerSupportButtons = lazy(() =>
  import("@hopper-b2b/trips").then((module) => ({
    default: module.CustomerSupportButtons,
  }))
);

export const InactiveErrorPage = () => {
  const { matchesMobile: isMobile } = useDeviceTypes();
  const { t } = useI18nContext();

  const { generalSupportChat } = useContext(SupportContext);
  const openSupportChat = useCallback(() => {
    generalSupportChat?.();
  }, [generalSupportChat]);

  return (
    <div className={clsx("inactive-error-page-root", { mobile: isMobile })}>
      <Box className="error-container">
        <Typography variant="h2">{t("inactiveError.title")}</Typography>
        <Typography variant="body2">{t("inactiveError.subtitle")}</Typography>

        <Suspense fallback={<div>Loading...</div>}>
          <CustomerSupportButtons chatSupportAction={openSupportChat} />
        </Suspense>
      </Box>
    </div>
  );
};
