import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { deleteUserPassenger } from "../../../../api/v1/book/user-passengers/deleteUserPassenger";
import {
  getUserSelectedPassengerIds,
  getUserSelectedLapInfantIds,
} from "../../reducer";
import { CallState } from "@hopper-b2b/types";
import { IStoreState } from "../../../../reducers/types";
import {
  IListUserPassengersResponse,
  fetchUserPassengers,
} from "../../../../api/v1/book/user-passengers/fetchUserPassengers";

export function* deleteUserPassengerSaga(action: actions.IDeleteUserPassenger) {
  try {
    const state: IStoreState = yield select();

    yield deleteUserPassenger(action.payload);

    const selectedPassengerIds = getUserSelectedPassengerIds(state);
    const selectedLapInfantIds = getUserSelectedLapInfantIds(state);
    if (selectedPassengerIds.includes(action.payload.personId)) {
      const newSelectedPassengerIds = selectedPassengerIds.filter(
        (id) => id !== action.payload.personId
      );
      yield put(
        actions.setUserSelectedPassengerIds({
          userSelectedPassengerIds: newSelectedPassengerIds,
        })
      );
    } else if (selectedLapInfantIds.includes(action.payload.personId)) {
      const newSelectedLapInfantIds = selectedLapInfantIds.filter(
        (id) => id !== action.payload.personId
      );

      yield put(
        actions.setUserSelectedLapInfantIds({
          userSelectedLapInfantIds: newSelectedLapInfantIds,
        })
      );
    }

    const userPassengers: IListUserPassengersResponse =
      yield fetchUserPassengers({});

    yield put(
      actions.setUserPassengers({
        userPassengerCallState: CallState.Success,
        userPassengers: userPassengers,
      })
    );
  } catch (e) {
    yield put(actions.setUserPassengersCallStateFailed());
  }
}
