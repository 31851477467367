import {
  Lodging,
  Suggestion,
  TrackingProperties,
  WalletOfferApplicabilityResponse,
} from "@b2bportal/lodging-api";
import * as actionTypes from "./constants";
import type {
  PriceRange,
  SortOption,
  LoadingLodgings,
  ILodgingAvailabilityState,
  ILodgingFilterState,
} from "../reducer";

export interface ISetLoading {
  type: actionTypes.SET_LOADING;
  value: LoadingLodgings;
}
export const setLoading = (value: LoadingLodgings) => ({
  type: actionTypes.SET_LOADING,
  value,
});

export interface ISetAvailabilityLodgings {
  type: actionTypes.SET_AVAILABILITY_LODGINGS;
  lodgings: Lodging[];
  stayValues: Pick<
    ILodgingAvailabilityState,
    "guests" | "rooms" | "fromDate" | "untilDate"
  >;
  place: Suggestion;
  location?: number[];
  offers?: WalletOfferApplicabilityResponse;
  trackingProperties?: TrackingProperties;
}
export const setAvailabilityLodgings = (
  lodgings: Lodging[],
  stayValues: Pick<
    ILodgingAvailabilityState,
    "guests" | "rooms" | "fromDate" | "untilDate"
  >,
  place?: Suggestion,
  location?: number[],
  offers?: WalletOfferApplicabilityResponse,
  trackingProperties?: TrackingProperties
) => {
  return {
    type: actionTypes.SET_AVAILABILITY_LODGINGS,
    lodgings: lodgings,
    stayValues: stayValues,
    place: place,
    location: location,
    offers: offers,
    trackingProperties: trackingProperties,
  };
};

export interface ISetSearchParams {
  type: actionTypes.SET_SEARCH_PARAMS;
  stayValues: Pick<
    ILodgingAvailabilityState,
    "guests" | "rooms" | "fromDate" | "untilDate" | "location"
  >;
}
export const setSearchParams = (
  stayValues: Pick<
    ILodgingAvailabilityState,
    "guests" | "rooms" | "fromDate" | "untilDate" | "location"
  >
) => ({
  type: actionTypes.SET_SEARCH_PARAMS,
  stayValues: stayValues,
});

export interface IAddAvailabilityLodgings {
  type: actionTypes.ADD_AVAILABILITY_LODGINGS;
  lodgings: Lodging[];
  offers?: WalletOfferApplicabilityResponse;
}
export const addAvailabilityLodgings = (
  lodgings: Lodging[],
  offers?: WalletOfferApplicabilityResponse
) => ({
  type: actionTypes.ADD_AVAILABILITY_LODGINGS,
  lodgings: lodgings,
  offers: offers,
});
export interface IFetchPlace {
  type: actionTypes.FETCH_PLACE;
  query: string;
}
export const fetchPlace = (query: string) => ({
  type: actionTypes.FETCH_PLACE,
  query: query,
});

export interface IResetFilters {
  type: actionTypes.RESET_FILTERS;
  value: PriceRange;
}
export const resetFilters = () => ({
  type: actionTypes.RESET_FILTERS,
});
export interface IUpdateFilters
  extends Partial<Omit<ILodgingFilterState, "priceRange">> {
  priceRange?: Partial<PriceRange>;
}
export interface IUpdateFiltersAndSort {
  type: actionTypes.UPDATE_FILTERS_AND_SORT;
  filters: IUpdateFilters;
  sort?: SortOption;
}
export const updateFiltersAndSort = (
  filters: IUpdateFilters,
  sort?: SortOption
) => ({
  type: actionTypes.UPDATE_FILTERS_AND_SORT,
  filters,
  sort,
});

export interface ISetSort {
  type: actionTypes.SET_SORT;
  value: SortOption;
}
export const setSort = (value: SortOption) => ({
  type: actionTypes.SET_SORT,
  value,
});

export type LodgingAvailabilityActions =
  | ISetLoading
  | ISetAvailabilityLodgings
  | ISetSearchParams
  | IAddAvailabilityLodgings
  | IFetchPlace
  | IResetFilters
  | ISetSort
  | IUpdateFiltersAndSort;
