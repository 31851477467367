import {
  ExperimentState,
  ExperimentsType,
  IApiConfig,
  NETWORK_CALL_FAILED,
} from "@hopper-b2b/types";
import {
  EvaluatedFeatureFlag,
  EvaluteFeatureFlagsResponse,
} from "@b2bportal/commbank-au-feature-flags-api";
import { featureFlagApiPrefix } from "./paths";
import { trackEvent, axiosInstance } from "@hopper-b2b/api";

export const postFetchFeatureFlags = async (
  config?: IApiConfig
): Promise<ExperimentState> => {
  const featureFlagPrefix = featureFlagApiPrefix;
  const END_POINT = `${featureFlagPrefix}/evaluate`;

  try {
    const res = await axiosInstance.post<EvaluteFeatureFlagsResponse>(
      END_POINT,
      {}
    );

    const { evaluatedFeatureFlags: featureFlags, ...rest } = res.data;

    const experiments: ExperimentsType = featureFlags?.reduce(
      (map: ExperimentsType, featureFlag: EvaluatedFeatureFlag) => {
        map[featureFlag.featureFlagName] = featureFlag.variant;
        return map;
      },
      {}
    );

    return {
      experiments: experiments,
      ...rest,
    };
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      config
    );
    throw e;
  }
};
