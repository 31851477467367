import { useRef, useEffect } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { Typography } from "@material-ui/core";
import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";

import {
  getFlightInfoDetails,
  PortalItineraryStatusEnum,
  ScheduleChangeSeverity,
  ItineraryEnum,
} from "@hopper-b2b/types";
import { UberMyTripsMobileCard } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { DateTimeFormatStyle } from "@hopper-i18n/formatter";
import {
  customFormatDateTime,
  dateStringToNewDate,
} from "@hopper-b2b/utilities";

import { FlightCardConnectorProps } from "./container";
import { scrollTopWithOffset } from "../../component";
import "./styles.scss";

interface IFlightCardProps
  extends FlightCardConnectorProps,
    // eslint-disable-next-line @typescript-eslint/ban-types
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {
  flight: BookedFlightItineraryWithDepartureTime;
  expandedCard: string;
  onExpandCard: (cardId: string) => void;
  isMobile?: boolean;
}

export const FlightCard = ({
  flight,
  isMobile,
  airportMap,
  airlineMap,
  history,
  expandedCard,
  setSelectedFlight,
  populateTripQueryParams,
}: IFlightCardProps) => {
  const { t, brand, language: locale } = useI18nContext();

  const hasMajorScheduleChange =
    flight.bookedItinerary.scheduleChange?.severity ===
    ScheduleChangeSeverity.Major;
  const hasMinorScheduleChange =
    flight.bookedItinerary.scheduleChange?.severity ===
      ScheduleChangeSeverity.Minor ||
    (flight.status === PortalItineraryStatusEnum.Modified &&
      !flight.bookedItinerary.scheduleChange);
  const flightRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      !isMobile &&
      flightRef?.current &&
      expandedCard === flight.bookedItinerary.id
    ) {
      scrollTopWithOffset(flightRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedCard]);

  return isMobile ? (
    <UberMyTripsMobileCard
      type={ItineraryEnum.Flight}
      onClick={() => {
        setSelectedFlight(flight);
        populateTripQueryParams(history);
      }}
      flightDetails={flight}
      {...getFlightInfoDetails(
        flight,
        (date: string) =>
          customFormatDateTime(
            dateStringToNewDate(date),
            locale,
            DateTimeFormatStyle.ShortMonthDayShortWeekday,
            brand?.customDateTimeFormats?.tripsList
          ),
        airportMap,
        airlineMap
      )}
    />
  ) : (
    <div ref={flightRef}>
      <Typography variant="body1" className="summary-label">
        {customFormatDateTime(
          dateStringToNewDate(flight.departureTime),
          locale,
          DateTimeFormatStyle.ShortMonthDayShortWeekday,
          brand?.customDateTimeFormats?.tripsList
        )}
      </Typography>
    </div>
  );
};
