import {
  AirCfarExerciseRefundPaymentType,
  CipherText,
  QuoteSuccess,
} from "@b2bportal/air-cfar-api";
import { CfarExerciseProgress } from "@hopper-b2b/types";
import { Box, Radio, RadioGroup } from "@material-ui/core";
import { useContext, useEffect, useMemo } from "react";
import { ClientContext } from "../../../../../App";
import { CancellationInformation } from "./CancellationInformation";
import "./styles.scss";

interface CfarDetailsProps {
  cfarExerciseProgress: CfarExerciseProgress;
  selectedQuoteId: CipherText;
  cfarQuoteV1: QuoteSuccess;
  airlineNames: string[];
  onSelectQuoteId: (CipherText) => void;
}
export const CfarDetails = ({
  cfarExerciseProgress,
  selectedQuoteId,
  cfarQuoteV1,
  airlineNames,
  onSelectQuoteId,
}: CfarDetailsProps) => {
  const clientContext = useContext(ClientContext);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (cfarQuoteV1) {
      onSelectQuoteId({
        value: (event.target as HTMLInputElement).value,
      } as CipherText);
    } else {
      onSelectQuoteId((event.target as HTMLInputElement).value);
    }
  };
  const singleQuoteOption = useMemo(
    () => cfarQuoteV1.quotes.length === 1,
    [cfarQuoteV1]
  );

  useEffect(() => {
    // if we're only displaying a single outcome to the user,
    // automatically select the ID
    if (singleQuoteOption) {
      onSelectQuoteId({ value: cfarQuoteV1.quotes[0].quoteId } as CipherText);
    }
  }, [singleQuoteOption, onSelectQuoteId, cfarQuoteV1]);

  const refundPayments = cfarQuoteV1.quotes[0].refundPayments;

  return singleQuoteOption ? (
    <CancellationInformation
      cfarExerciseProgress={cfarExerciseProgress}
      refundPrice={cfarQuoteV1.quotes[0].refundTotal}
      isFtc={cfarQuoteV1.quotes[0].refundPayments.every(
        (payment) =>
          payment.type === AirCfarExerciseRefundPaymentType.AirlineFtc
      )}
      airlineNames={airlineNames}
      refundPayments={refundPayments}
    />
  ) : (
    <RadioGroup
      className="cfar-details-v1-option-container"
      value={selectedQuoteId?.value}
      onChange={handleChange}
    >
      {cfarQuoteV1?.quotes?.map((quote) => {
        return (
          <Box key={quote.quoteId} className="cfar-details-v1-option-row">
            <Radio
              value={quote.quoteId}
              iconSrc={clientContext?.assets?.checkmark}
            />
            <CancellationInformation
              cfarExerciseProgress={cfarExerciseProgress}
              refundPrice={quote.refundTotal}
              isFtc={quote.refundPayments.every(
                (payment) =>
                  payment.type === AirCfarExerciseRefundPaymentType.AirlineFtc
              )}
              airlineNames={airlineNames}
              refundPayments={refundPayments}
            />
          </Box>
        );
      })}
    </RadioGroup>
  );
};
