import React from "react";
import styles from "./LodgingCard.module.scss";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ImageCarousel } from "../../../../components/ImageCarousel";
import {
  Lodging,
  Amenity,
  LodgingMediaAssetEnum,
} from "@b2bportal/lodging-api";
import clsx from "clsx";
import { PriceInfo } from "./components/PriceInfo";
import { HotelDetails } from "./components/HotelDetails";
import { Link, useParams } from "react-router-dom-v5-compat";
import { useLodgingLink } from "../../hooks/useLodgingLink";
import { getTotalNights } from "../../reducer";
import { useSelector } from "react-redux";
import { useTrackLodgingCardEvents } from "./hooks/useTrackLodgingCardEvents";
import { useBestTravelCreditsPercentage } from "@commbank/accounts";
import { ApacIconComponent, ApacIconName } from "@commbank/ui";
import { TravelCreditsOfferModalLink } from "@commbank/ui";
import { useFeatureFlag } from "@apac/feature-flags";

interface LodgingCardProps {
  lodging: Lodging;
  className?: string;
  setOveredId?: (nextValue: string | null) => void;
  selectLocation?: (lodgingId: string | null) => void;
  index?: number;
}

const DISPLAY_HOTEL_CARD_BANNER_OFFER = "commbank-au-hotel-card-banner-offer";

export const LodgingCard: React.FC<LodgingCardProps> = ({
  lodging,
  setOveredId,
  selectLocation,
  index,
  className,
}) => {
  const { t } = useI18nContext();
  const { lodging: lodgingData, isFreeCancel, price, available } = lodging;
  const params = useParams();
  const location = params["location"];
  const lodgingLink = useLodgingLink(
    lodgingData.id,
    lodgingData.name,
    location
  );
  const displayBannerOffer = useFeatureFlag(DISPLAY_HOTEL_CARD_BANNER_OFFER);

  const features = [
    ...(isFreeCancel
      ? [t("commBank.hotelAvailability.card.featureFreeCancellation")]
      : []),
    ...(lodgingData.amenities.find((a) => a.amenity === Amenity.FreeBreakfast)
      ? [t("commBank.hotelAvailability.card.featureBreakfastFree")]
      : lodgingData.amenities.find((a) => a.amenity === Amenity.PaidBreakfast)
      ? [t("commBank.hotelAvailability.card.featureBreakfastAvailable")]
      : []),
  ];
  const nights = useSelector(getTotalNights);
  const bestOfferPercentage = useBestTravelCreditsPercentage();

  const carouselImagesUrls = lodgingData.media
    .filter((asset) => asset.LodgingMediaAsset !== LodgingMediaAssetEnum.Video)
    .map((asset) => asset.url);

  const { containerRef, onCarouselNavigate } = useTrackLodgingCardEvents(
    lodging,
    index,
    carouselImagesUrls.length
  );

  return (
    <Link
      id={lodgingData.id}
      ref={containerRef}
      to={lodgingLink}
      target="_blank"
      className={clsx(styles.lodgingCard, className)}
      onMouseEnter={() => setOveredId(lodgingData.id)}
      onMouseLeave={() => setOveredId(null)}
      onFocus={() => setOveredId(lodgingData.id)}
      onBlur={() => setOveredId(null)}
    >
      {displayBannerOffer && bestOfferPercentage > 0 && (
        <div className={styles.promoBanner}>
          <span>
            {t("commBank.earnBanner.checkoutOfferDescriptionTitle", {
              percent: bestOfferPercentage,
            })}
          </span>
          <TravelCreditsOfferModalLink
            bestOfferPercentage={bestOfferPercentage}
          >
            <ApacIconComponent name={ApacIconName.InfoOutlined} />
          </TravelCreditsOfferModalLink>
        </div>
      )}
      <div className={styles.lodgingCardGrid}>
        <div className={clsx(styles.imageCarouselContainer, {})}>
          <ImageCarousel
            carouselImagesUrls={carouselImagesUrls}
            className={clsx(styles.imageCarousel, {
              [styles.unavailable]: !available,
            })}
            onCarouselNavigate={onCarouselNavigate}
          />
        </div>
        <HotelDetails
          id={lodgingData.id}
          hotelName={lodgingData.name}
          rating={lodgingData.starRating}
          location={lodgingData.city}
          userRating={lodgingData.tripAdvisorReviews?.overallScore}
          reviewCount={lodgingData.tripAdvisorReviews?.reviewsCount}
          amenities={lodging.lodging.amenities}
          features={features}
          selectLocation={selectLocation}
        />
        <PriceInfo price={price} nights={nights} />
      </div>
    </Link>
  );
};
