export const SET_TRIP_CATEGORY = "flightSearch/SET_TRIP_CATEGORY";
export type SET_TRIP_CATEGORY = typeof SET_TRIP_CATEGORY;

export const SET_RENDER_SEARCH_INPUT = "flightSearch/SET_RENDER_SEARCH_INPUT";
export type SET_RENDER_SEARCH_INPUT = typeof SET_RENDER_SEARCH_INPUT;

export const SET_CALENDAR_VISITED = "flightSearch/SET_CALENDAR_VISITED";
export type SET_CALENDAR_VISITED = typeof SET_CALENDAR_VISITED;

export const SET_ORIGIN = "flightSearch/SET_ORIGIN";
export type SET_ORIGIN = typeof SET_ORIGIN;

export const SET_ORIGIN_CATEGORIES = "flightSearch/SET_ORIGIN_CATEGORIES";
export type SET_ORIGIN_CATEGORIES = typeof SET_ORIGIN_CATEGORIES;

export const FETCH_ORIGIN_CATEGORIES = "flightSearch/FETCH_ORIGIN_CATEGORIES";
export type FETCH_ORIGIN_CATEGORIES = typeof FETCH_ORIGIN_CATEGORIES;

export const SET_DESTINATION = "flightSearch/SET_DESTINATION";
export type SET_DESTINATION = typeof SET_DESTINATION;

export const SET_DESTINATION_CATEGORIES =
  "flightSearch/SET_DESTINATION_CATEGORIES";
export type SET_DESTINATION_CATEGORIES = typeof SET_DESTINATION_CATEGORIES;

export const FETCH_DESTINATION_CATEGORIES =
  "flightSearch/FETCH_DESTINATION_CATEGORIES";
export type FETCH_DESTINATION_CATEGORIES = typeof FETCH_DESTINATION_CATEGORIES;

export const SET_DEPARTURE_DATE = "flightSearch/SET_DEPARTURE_DATE";
export type SET_DEPARTURE_DATE = typeof SET_DEPARTURE_DATE;

export const SET_RETURN_DATE = "flightSearch/SET_RETURN_DATE";
export type SET_RETURN_DATE = typeof SET_RETURN_DATE;

export const FETCH_SEARCH_FROM_URL = "flightSearch/FETCH_SEARCH_FROM_URL";
export type FETCH_SEARCH_FROM_URL = typeof FETCH_SEARCH_FROM_URL;

export const POPULATE_SEARCH_URL_PARAMS =
  "flightSearch/POPULATE_SEARCH_URL_PARAMS";
export type POPULATE_SEARCH_URL_PARAMS = typeof POPULATE_SEARCH_URL_PARAMS;

export const FETCH_DEPARTURE_CALENDAR = "flightSearch/FETCH_DEPARTURE_CALENDAR";
export type FETCH_DEPARTURE_CALENDAR = typeof FETCH_DEPARTURE_CALENDAR;

export const SET_DEPARTURE_CALENDAR = "flightSearch/SET_DEPARTURE_CALENDAR";
export type SET_DEPARTURE_CALENDAR = typeof SET_DEPARTURE_CALENDAR;

// search filter actions
export const RESET_FARECLASS_OPTION_FILTER =
  "flightSearch/RESET_FARECLASS_OPTION_FILTER";
export type RESET_FARECLASS_OPTION_FILTER =
  typeof RESET_FARECLASS_OPTION_FILTER;

export const SET_STOPS_OPTION = "flightSearch/SET_STOPS_OPTION";
export type SET_STOPS_OPTION = typeof SET_STOPS_OPTION;

export const SET_MAX_PRICE_FILTER = "flightSearch/SET_MAX_PRICE_FILTER";
export type SET_MAX_PRICE_FILTER = typeof SET_MAX_PRICE_FILTER;

export const SET_FARECLASS_OPTION_FILTER =
  "flightSearch/SET_FARECLASS_OPTION_FILTER";
export type SET_FARECLASS_OPTION_FILTER = typeof SET_FARECLASS_OPTION_FILTER;

export const SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER =
  "flightSearch/SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER";
export type SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER =
  typeof SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER;

export const SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER =
  "flightSearch/SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER";
export type SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER =
  typeof SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER;

export const SET_RETURN_DEPARTURE_TIME_RANGE_FILTER =
  "flightSearch/SET_RETURN_DEPARTURE_TIME_RANGE_FILTER";
export type SET_RETURN_DEPARTURE_TIME_RANGE_FILTER =
  typeof SET_RETURN_DEPARTURE_TIME_RANGE_FILTER;

export const SET_RETURN_ARRIVAL_TIME_RANGE_FILTER =
  "flightSearch/SET_RETURN_ARRIVAL_TIME_RANGE_FILTER";
export type SET_RETURN_ARRIVAL_TIME_RANGE_FILTER =
  typeof SET_RETURN_ARRIVAL_TIME_RANGE_FILTER;

export const SET_AIRLINE_FILTER = "flightSearch/SET_AIRLINE_FILTER";
export type SET_AIRLINE_FILTER = typeof SET_AIRLINE_FILTER;

export const SET_AIRPORT_FILTER = "flightSearch/SET_AIRPORT_FILTER";
export type SET_AIRPORT_FILTER = typeof SET_AIRPORT_FILTER;

export const SET_FLIGHT_NUMBER_FILTER = "flightSearch/SET_FLIGHT_NUMBER_FILTER";
export type SET_FLIGHT_NUMBER_FILTER = typeof SET_FLIGHT_NUMBER_FILTER;

export const SET_PASSENGER_COUNTS = "flightSearch/SET_PASSENGER_COUNTS";
export type SET_PASSENGER_COUNTS = typeof SET_PASSENGER_COUNTS;

export const SET_AWAITING_REFETCH = "flightSearch/SET_AWAITING_REFETCH";
export type SET_AWAITING_REFETCH = typeof SET_AWAITING_REFETCH;

export const SET_MOBILE_SEARCH_PROGRESS =
  "flightSearch/SET_MOBILE_SEARCH_PROGRESS";
export type SET_MOBILE_SEARCH_PROGRESS = typeof SET_MOBILE_SEARCH_PROGRESS;

export const SET_OPEN_DATES_MODAL = "flightSearch/SET_OPEN_DATES_MODAL";
export type SET_OPEN_DATES_MODAL = typeof SET_OPEN_DATES_MODAL;

export const SET_OPEN_BALANCE_PERMISSIONS_MODAL =
  "flightSearch/SET_OPEN_BALANCE_PERMISSIONS_MODAL";
export type SET_OPEN_BALANCE_PERMISSIONS_MODAL =
  typeof SET_OPEN_BALANCE_PERMISSIONS_MODAL;

export const RESET_FILTERS = "flightSearch/RESET_FILTERS";
export type RESET_FILTERS = typeof RESET_FILTERS;
