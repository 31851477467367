import "./styles.scss";

import { IFlightGridFares, FareclassShelfBrandName } from "@hopper-b2b/types";

import { FlightGridRowContent, FlightGridRowSkeleton } from "./components";
export type FareclassOptionFilter = {
  [key in FareclassShelfBrandName]: boolean;
};

interface IFlightGridRowBaseProps {
  className?: string;
  type: "content" | "skeleton";
}

export interface IFlightGridRowContentProps extends IFlightGridRowBaseProps {
  airlineCode: string;
  airlineName: string;
  departureTime: string;
  arrivalTime: string;
  originCode: string;
  destinationCode: string;
  duration: string;
  layoverString: string;
  plusDays?: number;
  fares: IFlightGridFares;
  isRoundTrip: boolean;
  selectedMaxPrice?: number;
  fareClassFilter: FareclassOptionFilter;
  onClick?: () => void;
  onFareClick?: (fareId: string) => void;
  onAlgomerchInfoClick?: (label: string) => void;
  tagsLimit?: number;
  fareCardClassName?: string;
  algomerchModalOpen?: boolean;
  isExpanded?: boolean;
  type: "content";
  selectedFare: string;
  additionalAirlinesCount?: number;
}

export interface IFlightGridRowSkeletonProps extends IFlightGridRowBaseProps {
  type: "skeleton";
}

export type IFlightGridRowProps =
  | IFlightGridRowContentProps
  | IFlightGridRowSkeletonProps;

export const FlightGridRow = (props: IFlightGridRowProps) => {
  switch (props.type) {
    case "content":
      return <FlightGridRowContent {...props} />;
    case "skeleton":
      return <FlightGridRowSkeleton {...props} />;
    default:
      return null;
  }
};
