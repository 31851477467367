import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import clsx from "clsx";

import { HotelItinerary, HotelItinerarySummary } from "@b2bportal/lodging-api";
import { HotelItineraryState } from "@hopper-b2b/types";
import {
  BannerSeverity,
  IconComponent,
  IconName,
  MyTripsMobileCard,
  NotificationBanner,
} from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { getHotelDetails } from "@hopper-b2b/utilities";

import { fetchHotelReservation } from "../../../../api/v1/itinerary/fetchHotelReservation";
import { DesktopHotelCard } from "./components/DesktopHotelCard";
import { scrollTopWithOffset } from "../ItineraryList/component";
import { HotelCardConnectorProps } from "./container";
import "./styles.scss";

export const DATE_FORMAT = "ddd, MMM D";

interface IHotelCardProps
  extends HotelCardConnectorProps,
    RouteComponentProps<unknown, StaticContext, { prevPath?: string }> {
  isMobile?: boolean;
  hotel: HotelItinerarySummary;
  expandedCard: string;
  onExpandCard: (cardId: string) => void;
  reservationDetails?: HotelItinerary;
  fetchReservationError?: boolean;
}

export const HotelCard = ({
  history,
  hotel,
  isMobile,
  isPastTrips,
  populateTripQueryParams,
  setSelectedHotel,
  onExpandCard,
  expandedCard,
  setOpenModal,
  reservationDetails,
  fetchReservationError,
}: IHotelCardProps) => {
  const hotelRef = useRef<HTMLDivElement | null>(null);
  const { t, language: locale, brand } = useI18nContext();
  const [fetchHotelReservationError, setFetchHotelReservationError] = useState(
    fetchReservationError
  );

  useEffect(() => {
    if (
      !isMobile &&
      hotelRef?.current &&
      expandedCard === hotel.reservationBookingId
    ) {
      scrollTopWithOffset(hotelRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedCard]);

  const { ReservationState: reservationState } = hotel.state;

  const statusBanner = useMemo(() => {
    switch (reservationState) {
      case HotelItineraryState.CancelationFailure:
      case HotelItineraryState.Canceled:
      case "Cancelled":
        return (
          <NotificationBanner
            className="status-banner error"
            label={t("reservationCanceled")}
            severity={BannerSeverity.ERROR}
            icon={<IconComponent name={IconName.ErrorAlert} />}
          />
        );
      default:
        return undefined;
    }
  }, [reservationState, t]);

  const isCancel = [
    HotelItineraryState.Canceled,
    HotelItineraryState.CancelationFailure,
    "Cancelled",
  ].includes(reservationState);
  const confirmationCodeClassName = isCancel ? "warning" : "";
  const handleHotelCardSelect = useCallback(() => {
    fetchHotelReservation(hotel.reservationBookingId)
      .then((res) => {
        setSelectedHotel(res);
      })
      .catch((error) => {
        setFetchHotelReservationError(true);
      });
    populateTripQueryParams(history);
  }, [history, hotel, populateTripQueryParams, setSelectedHotel]);

  return (
    <div className={clsx({ mobile: isMobile }, "hotel-list")}>
      {isMobile ? (
        <MyTripsMobileCard
          onClick={handleHotelCardSelect}
          banner={statusBanner}
          confirmationCodeClassName={confirmationCodeClassName}
          confirmationLabel={`${t("confirmation")}:`}
          label={t("hotel")}
          icon={IconName.MoneyBag}
          {...getHotelDetails(hotel, locale, brand)}
        />
      ) : (
        <div ref={hotelRef}>
          <DesktopHotelCard
            banner={statusBanner}
            isPastTrips={isPastTrips}
            hotel={hotel}
            isCancel={isCancel}
            confirmationCodeClassName={confirmationCodeClassName}
            isMobile={isMobile}
            expandedCard={expandedCard}
            onExpandCard={onExpandCard}
            setOpenModal={setOpenModal}
            reservationDetails={reservationDetails}
            fetchReservationError={fetchHotelReservationError}
          />
        </div>
      )}
    </div>
  );
};
