import { TextField, MenuItem, InputAdornment } from "@material-ui/core";
import { useI18nContext } from "@hopper-b2b/i18n";
import styles from "./SortInput.module.scss";
import { type SortOption } from "../../reducer";
import clsx from "clsx";
import { Autocomplete } from "@material-ui/lab";
import { ApacIconComponent, ApacIconName } from "@commbank/ui";

interface Props {
  value: SortOption;
  className?: string;
  setSort(nextValue: SortOption): void;
}

export function SortInput({ value, className, setSort }: Props) {
  const { t } = useI18nContext();

  const options: { value: SortOption; label: string }[] = [
    {
      value: "mostRecommended",
      label: t("mostRecommended"),
    },
    {
      value: "starRating",
      label: t("starRatingHighestFirst"),
    },
    {
      value: "userRating",
      label: t("userRatingHighestFirst"),
    },
    {
      value: "price",
      label: t("priceLowestFirst"),
    },
  ];

  return (
    <TextField
      className={clsx(className, styles.Select)}
      variant="filled"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <ApacIconComponent name={ApacIconName.Sort} />
          </InputAdornment>
        ),
        classes: {
          adornedStart: styles.Prefix,
        },
      }}
      SelectProps={{
        IconComponent: () => null,
      }}
      value={value}
      onChange={(event) => setSort(event.target.value as SortOption)}
      select
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
