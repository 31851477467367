import { useCallback, useMemo } from "react";
import { HotelItinerary, HotelItinerarySummary } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  IOpenModal,
  ItineraryEnum,
  LodgingAddressExact,
  MyTripsModalTypes,
} from "@hopper-b2b/types";
import {
  ActionLink,
  ActionLinks,
  IActionLink,
  IconComponent,
  IconName,
  LoadingFailure,
  MyTripsCard,
  TripInfoDetails,
} from "@hopper-b2b/ui";
import {
  DATE_FORMAT,
  getGoogleStaticMapSrc,
  getHotelDetails,
  getTwoLineAddresses,
  useDeviceTypes,
} from "@hopper-b2b/utilities";

import { TravelersSummary } from "../../../../../../../TripsList/components/ItineraryList/components/TravelersSummary";
import { getLineItems } from "../../../../../../../TripsList/utils/helpers";
import { ConfirmationSummary } from "../../../../../../../TripsList/components/ItineraryList/components/ConfirmationSummary";
import { PaymentSummary } from "../../../../../../../TripsList/components/ItineraryList/components/PaymentSummary";
import { SummaryCard } from "../../../../../../../TripsList/components/ItineraryList/components/SummaryCard";
import {
  DESKTOP_OFFSET_SCROLL,
  MOBILE_OFFSET_SCROLL,
} from "../../../../../ItineraryList/constants";
import { HotelCancellation, HotelCardRoomTypeContent } from "../HotelInfoParts";

import clsx from "clsx";
import dayjs from "dayjs";

import "./styles.scss";

interface IDesktopHotelCardProps {
  expandedCard: string;
  hotel: HotelItinerarySummary;
  onExpandCard: (cardId: string) => void;
  setOpenModal: (modalType: IOpenModal) => void;
  banner?: JSX.Element;
  isMobile?: boolean;
  isCancel?: boolean;
  isPastTrips?: boolean;
  confirmationCodeClassName?: string;
  reservationDetails?: HotelItinerary;
  fetchReservationError?: boolean;
}

export const addHotelType = (hotel: HotelItinerarySummary) => ({
  ...hotel,
  type: ItineraryEnum.Hotel,
});

export const getPostalCode = (reservation: HotelItinerarySummary): string => {
  const { lodgingSummary } = reservation;
  const city = lodgingSummary?.city ? `${lodgingSummary.city},` : "";
  const state = lodgingSummary?.state?.code
    ? `${lodgingSummary.state.code},`
    : "";
  return `${city} ${state} ${lodgingSummary?.country || ""}`;
};

export const DesktopHotelCard = ({
  expandedCard,
  hotel,
  onExpandCard,
  setOpenModal,
  banner,
  isMobile,
  isCancel,
  isPastTrips = false,
  confirmationCodeClassName,
  reservationDetails,
  fetchReservationError = false,
}: IDesktopHotelCardProps) => {
  const { t, formatFiatCurrency, language: locale, brand } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const onOpenModal = useCallback(
    (type: MyTripsModalTypes) =>
      setOpenModal({
        type,
        selectedItinerary: addHotelType(hotel),
      }),
    [hotel, setOpenModal]
  );

  const hotelAddress = reservationDetails?.reservation?.lodgingData.address as
    | LodgingAddressExact
    | undefined;
  const hotelStreet = getTwoLineAddresses(hotelAddress).line1;
  const actions: IActionLink[] = [];

  if (!isCancel && !isPastTrips) {
    actions.push({
      content: t("hotelTripsLinks.changeReservation"),
      onClick: () => onOpenModal(MyTripsModalTypes.ChangeHotel),
    });

    actions.push({
      content: t("hotelTripsLinks.cancelReservation"),
      onClick: () => onOpenModal(MyTripsModalTypes.CancelHotel),
    });
  }

  actions.push(
    {
      content: t("tripReviewLinks.resendEmail"),
      onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
    },
    {
      content: t("tripReviewLinks.contactSupport"),
      onClick: () => onOpenModal(MyTripsModalTypes.ContactSupport),
    }
  );

  const coordinates = useMemo(() => {
    const lat =
      reservationDetails?.reservation?.lodgingData?.location?.coordinates?.lat;
    const lon =
      reservationDetails?.reservation?.lodgingData?.location?.coordinates?.lon;
    return `${lat},${lon}`;
  }, [reservationDetails]);

  const paymentLineItems = useMemo(() => {
    return getLineItems(t, reservationDetails?.paymentBreakdown) || undefined;
  }, [reservationDetails?.paymentBreakdown, t]);

  const openTravelerModal = useCallback(() => {
    onOpenModal(MyTripsModalTypes.TravelersModal);
  }, [onOpenModal]);

  const openPaymentBreakdownModal = useCallback(() => {
    onOpenModal(MyTripsModalTypes.PaymentModal);
  }, [onOpenModal]);

  const stayDuration = dayjs(hotel.checkOutDate).diff(
    dayjs(hotel.checkInDate),
    "days"
  );

  return (
    <div className="my-trips-desktop-card hotel-card desktop">
      <div className="hotel-card-icon-title">
        <h2 className="summary-label">
          {hotel.checkInDate ? (
            <div className="hotel-card-header">
              {`${t("hotel")}   |   ${dayjs(hotel.checkInDate).format(
                DATE_FORMAT
              )}-${dayjs(hotel.checkOutDate).format(DATE_FORMAT)}`}
            </div>
          ) : null}
        </h2>
      </div>
      <div
        id={hotel.reservationBookingId}
        key={hotel.reservationBookingId}
        className={clsx("hotel-trip-container", {
          mobile: isMobile,
          expanded: expandedCard === hotel.reservationBookingId,
        })}
      >
        {reservationDetails
          ? reservationDetails.reservation.isLuxuryCollection && (
              <IconComponent name={IconName.MoneyBag} />
            )
          : null}
        <MyTripsCard
          className="trip-card-title"
          banner={banner}
          content={
            <TripInfoDetails
              hideTitleTag={isCancel}
              confirmationCodeClassName={confirmationCodeClassName}
              titles={{
                ...getHotelDetails(hotel, locale, brand),
                confirmationLabel: t("confirmation"),
                startLabel: `${t("checkIn")}:`,
                endLabel: `${t("checkOut")}:`,
                postalCode: getPostalCode(hotel),
                streetAddress: hotelStreet,
                subtitle: t(stayDuration > 1 ? "night_other" : "night_one", {
                  count: stayDuration,
                }),
                phoneNum: reservationDetails
                  ? reservationDetails?.reservation?.lodgingData?.phone
                  : null,
              }}
            />
          }
          actions={<ActionLinks actions={actions} />}
          expandString={
            expandedCard === hotel.reservationBookingId
              ? t("viewLess")
              : t("viewDetails")
          }
          isExpanded={expandedCard === hotel.reservationBookingId}
          topRightAction={null}
          onExpandClick={() => {
            onExpandCard(hotel.reservationBookingId);
            setTimeout(() => {
              const OFFSET = matchesMobile
                ? MOBILE_OFFSET_SCROLL
                : DESKTOP_OFFSET_SCROLL;
              const cardTop =
                document
                  ?.getElementById(hotel.reservationBookingId)
                  ?.getBoundingClientRect().top || 0;
              window.scrollBy({
                top: (cardTop as number) - OFFSET,
                behavior: matchesMobile ? "auto" : "smooth",
              });
            }, 100);
          }}
        />
        {reservationDetails || fetchReservationError ? (
          <div className="hotel-desktop-expanded-container">
            {fetchReservationError ? (
              <LoadingFailure
                title={t("tripSearch.errorTitle")}
                message={t("tripSearch.errorMessage")}
                showImage
              />
            ) : (
              <>
                <div className="hotel-summary-container">
                  {coordinates ? (
                    <img
                      src={getGoogleStaticMapSrc(
                        coordinates,
                        "16",
                        "600x300",
                        "2",
                        true
                      )}
                      alt=""
                    />
                  ) : null}
                  <HotelCardRoomTypeContent
                    bookedRooms={reservationDetails.reservation.bookedRooms}
                    roomInfo={reservationDetails.reservation.roomInfo}
                    isLuxuryCollection={
                      reservationDetails.reservation.isLuxuryCollection
                    }
                  />
                  <HotelCancellation
                    hotelCfar={
                      //reservationDetails.ancillaries.cfar
                      undefined
                    }
                    cancellationPolicy={
                      reservationDetails.reservation.cancellationPolicy
                    }
                  />
                </div>
                <div
                  className={clsx("summary-info-container", {
                    // "two-column-view": hasAddOn,
                  })}
                >
                  <SummaryCard
                    className="travellers-summary"
                    action={
                      <ActionLink
                        className="details-link"
                        onClick={openTravelerModal}
                        content="Details"
                      />
                    }
                  >
                    <TravelersSummary
                      label={t("travelerInformationModal.guestInformation")}
                      travelers={reservationDetails.reservation.guests
                        .map((person) => person?.givenName)
                        .join(", ")}
                    />
                  </SummaryCard>
                  <SummaryCard
                    className="payment-summary"
                    action={
                      <ActionLink
                        className="details-link"
                        onClick={openPaymentBreakdownModal}
                        content="Details"
                      />
                    }
                  >
                    {reservationDetails ? (
                      <PaymentSummary
                        className="desktop-hotel-card-payment-summary"
                        tripTotalAmount={formatFiatCurrency(
                          reservationDetails?.productTotal?.fiat
                        )}
                        paymentLineItems={paymentLineItems}
                      />
                    ) : null}
                  </SummaryCard>
                  <SummaryCard className="confirmation-summary">
                    <ConfirmationSummary
                      confirmationCode={hotel.reservationId}
                    />
                  </SummaryCard>
                </div>
              </>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
