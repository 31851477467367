export const SET_MY_TRIPS_TYPE = "tripsList/SET_MY_TRIPS_TYPE";
export type SET_MY_TRIPS_TYPE = typeof SET_MY_TRIPS_TYPE;

export const SET_MY_TRIPS_FILTER = "tripsList/SET_MY_TRIPS_FILTER";
export type SET_MY_TRIPS_FILTER = typeof SET_MY_TRIPS_FILTER;

export const SET_SELECTED_FLIGHT = "tripsList/SET_SELECTED_FLIGHT";
export type SET_SELECTED_FLIGHT = typeof SET_SELECTED_FLIGHT;

export const FETCH_FLIGHTS = "tripsList/FETCH_FLIGHTS";
export type FETCH_FLIGHTS = typeof FETCH_FLIGHTS;

export const SET_FLIGHTS = "tripsList/SET_FLIGHTS";
export type SET_FLIGHTS = typeof SET_FLIGHTS;

export const SET_FLIGHT_CONTEXT = "tripsList/SET_FLIGHT_CONTEXT";
export type SET_FLIGHT_CONTEXT = typeof SET_FLIGHT_CONTEXT;

export const FETCH_FLIGHTS_FAILED = "tripsList/FETCH_FLIGHTS_FAILED";
export type FETCH_FLIGHTS_FAILED = typeof FETCH_FLIGHTS_FAILED;

export const SET_SELECTED_HOTEL = "tripsList/SET_SELECTED_HOTEL";
export type SET_SELECTED_HOTEL = typeof SET_SELECTED_HOTEL;

export const FETCH_HOTELS = "tripsList/FETCH_HOTELS";
export type FETCH_HOTELS = typeof FETCH_HOTELS;

export const SET_HOTELS = "tripsList/SET_HOTELS";
export type SET_HOTELS = typeof SET_HOTELS;

export const FETCH_HOTELS_FAILED = "tripsList/FETCH_HOTELS_FAILED";
export type FETCH_HOTELS_FAILED = typeof FETCH_HOTELS_FAILED;

export const SET_SELECTED_CAR = "tripsList/SET_SELECTED_CAR";
export type SET_SELECTED_CAR = typeof SET_SELECTED_CAR;

export const FETCH_CARS = "tripsList/FETCH_CARS";
export type FETCH_CARS = typeof FETCH_CARS;

export const SET_CARS = "tripsList/SET_CARS";
export type SET_CARS = typeof SET_CARS;

export const FETCH_CARS_FAILED = "tripsList/FETCH_CARS_FAILED";
export type FETCH_CARS_FAILED = typeof FETCH_CARS_FAILED;

export const UPDATE_WATCH = "tripsList/UPDATE_WATCH";
export type UPDATE_WATCH = typeof UPDATE_WATCH;

export const SET_UPDATE_WATCH_CALL_STATE =
  "tripsList/SET_UPDATE_WATCH_CALL_STATE";
export type SET_UPDATE_WATCH_CALL_STATE = typeof SET_UPDATE_WATCH_CALL_STATE;

export const DELETE_WATCH = "tripsList/DELETE_WATCH";
export type DELETE_WATCH = typeof DELETE_WATCH;

export const SET_DELETE_WATCH_CALL_STATE =
  "tripsList/SET_DELETE_WATCH_CALL_STATE";
export type SET_DELETE_WATCH_CALL_STATE = typeof SET_DELETE_WATCH_CALL_STATE;

export const LIST_WATCHES = "tripsList/LIST_WATCHES";
export type LIST_WATCHES = typeof LIST_WATCHES;

export const LIST_PRICE_FREEZE = "tripsList/LIST_PRICE_FREEZE";
export type LIST_PRICE_FREEZE = typeof LIST_PRICE_FREEZE;

export const SET_LIST_PRICE_FREEZE_CALL_STATE =
  "tripsList/SET_LIST_PRICE_FREEZE_CALL_STATE";
export type SET_LIST_PRICE_FREEZE_CALL_STATE =
  typeof SET_LIST_PRICE_FREEZE_CALL_STATE;

export const SET_PRICE_FREEZE = "tripsList/SET_PRICE_FREEZE";
export type SET_PRICE_FREEZE = typeof SET_PRICE_FREEZE;

export const SET_LIST_WATCHES_CALL_STATE =
  "tripsList/SET_LIST_WATCHES_CALL_STATE";
export type SET_LIST_WATCHES_CALL_STATE = typeof SET_LIST_WATCHES_CALL_STATE;

export const SET_WATCHES = "tripsList/SET_WATCHES";
export type SET_WATCHES = typeof SET_WATCHES;

export const POPULATE_TRIP_QUERY_PARAMS =
  "tripsList/POPULATE_TRIP_QUERY_PARAMS";
export type POPULATE_TRIP_QUERY_PARAMS = typeof POPULATE_TRIP_QUERY_PARAMS;

export const REPLACE_TRIP_QUERY_PARAMS = "tripsList/REPLACE_TRIP_QUERY_PARAMS";
export type REPLACE_TRIP_QUERY_PARAMS = typeof REPLACE_TRIP_QUERY_PARAMS;

export const SET_OPEN_MODAL = "tripsList/SET_OPEN_MODAL";
export type SET_OPEN_MODAL = typeof SET_OPEN_MODAL;

export const ACCEPT_SCHEDULE_CHANGE = "tripsList/ACCEPT_SCHEDULE_CHANGE";
export type ACCEPT_SCHEDULE_CHANGE = typeof ACCEPT_SCHEDULE_CHANGE;

export const SET_ACCEPT_SCHEDULE_CHANGE_CALL_STATE =
  "tripsList/SET_ACCEPT_SCHEDULE_CHANGE_CALL_STATE";
export type SET_ACCEPT_SCHEDULE_CHANGE_CALL_STATE =
  typeof SET_ACCEPT_SCHEDULE_CHANGE_CALL_STATE;

export const DENY_SCHEDULE_CHANGE = "tripsList/DENY_SCHEDULE_CHANGE";
export type DENY_SCHEDULE_CHANGE = typeof DENY_SCHEDULE_CHANGE;

export const SET_DENY_SCHEDULE_CHANGE_CALL_STATE =
  "tripsList/SET_DENY_SCHEDULE_CHANGE_CALL_STATE";
export type SET_DENY_SCHEDULE_CHANGE_CALL_STATE =
  typeof SET_DENY_SCHEDULE_CHANGE_CALL_STATE;

export const SET_CFAR_EXERCISE_PROGRESS = "SET_CFAR_EXERCISE_PROGRESS";
export type SET_CFAR_EXERCISE_PROGRESS = typeof SET_CFAR_EXERCISE_PROGRESS;

export const SET_CHFAR_EXERCISE_PROGRESS = "SET_CHFAR_EXERCISE_PROGRESS";
export type SET_CHFAR_EXERCISE_PROGRESS = typeof SET_CHFAR_EXERCISE_PROGRESS;

export const SET_DISRUPTION_EXERCISE_PROGRESS =
  "SET_DISRUPTION_EXERCISE_PROGRESS";
export type SET_DISRUPTION_EXERCISE_PROGRESS =
  typeof SET_DISRUPTION_EXERCISE_PROGRESS;

export const SET_DISRUPTION_EXERCISE_PRODUCT_TYPE =
  "SET_DISRUPTION_EXERCISE_PRODUCT_TYPE";
export type SET_DISRUPTION_EXERCISE_PRODUCT_TYPE =
  typeof SET_DISRUPTION_EXERCISE_PRODUCT_TYPE;

export const FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE =
  "FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE";
export type FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE =
  typeof FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE;

export const FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_V1 =
  "FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_V1";
export type FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_V1 =
  typeof FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_V1;

export const SET_FLIGHT_CFAR_CANCELLATION_QUOTE =
  "SET_FLIGHT_CFAR_CANCELLATION_QUOTE";
export type SET_FLIGHT_CFAR_CANCELLATION_QUOTE =
  typeof SET_FLIGHT_CFAR_CANCELLATION_QUOTE;

export const SET_FLIGHT_CFAR_CANCELLATION_QUOTE_V1 =
  "SET_FLIGHT_CFAR_CANCELLATION_QUOTE_V1";
export type SET_FLIGHT_CFAR_CANCELLATION_QUOTE_V1 =
  typeof SET_FLIGHT_CFAR_CANCELLATION_QUOTE_V1;

export const SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_CALL_STATE_FAILED =
  "SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_CALL_STATE_FAILED";
export type SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_CALL_STATE_FAILED =
  typeof SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_CALL_STATE_FAILED;

export const SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_ERROR =
  "SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_ERROR";
export type SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_ERROR =
  typeof SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_ERROR;

export const SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_CALL_STATE_NOT_CALLED =
  "SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_CALL_STATE_NOT_CALLED";
export type SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_CALL_STATE_NOT_CALLED =
  typeof SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_CALL_STATE_NOT_CALLED;

export const CONFIRM_FLIGHT_CFAR_CANCELLATION =
  "CONFIRM_FLIGHT_CFAR_CANCELLATION";
export type CONFIRM_FLIGHT_CFAR_CANCELLATION =
  typeof CONFIRM_FLIGHT_CFAR_CANCELLATION;

export const CONFIRM_FLIGHT_CFAR_CANCELLATION_V1 =
  "CONFIRM_FLIGHT_CFAR_CANCELLATION_V1";
export type CONFIRM_FLIGHT_CFAR_CANCELLATION_V1 =
  typeof CONFIRM_FLIGHT_CFAR_CANCELLATION_V1;

export const SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_RESPONSE =
  "SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_RESPONSE";
export type SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_RESPONSE =
  typeof SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_RESPONSE;

export const SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_V1_RESPONSE =
  "SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_V1_RESPONSE";
export type SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_V1_RESPONSE =
  typeof SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_V1_RESPONSE;

export const SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_FAILED =
  "SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_FAILED";
export type SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_FAILED =
  typeof SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_FAILED;

export const SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_NOT_CALLED =
  "SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_NOT_CALLED";
export type SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_NOT_CALLED =
  typeof SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_NOT_CALLED;

export const POLL_FULFILL_FLIGHT_CFAR_CANCELLATION =
  "POLL_FULFILL_FLIGHT_CFAR_CANCELLATION";
export type POLL_FULFILL_FLIGHT_CFAR_CANCELLATION =
  typeof POLL_FULFILL_FLIGHT_CFAR_CANCELLATION;

export const SET_POLL_FULFILL_FLIGHT_CFAR_CANCELLATION_RESPONSE =
  "SET_POLL_FULFILL_FLIGHT_CFAR_CANCELLATION_RESPONSE";
export type SET_POLL_FULFILL_FLIGHT_CFAR_CANCELLATION_RESPONSE =
  typeof SET_POLL_FULFILL_FLIGHT_CFAR_CANCELLATION_RESPONSE;

export const SET_POLL_FULFILL_FLIGHT_CFAR_CANCELLATION_V1_RESPONSE =
  "SET_POLL_FULFILL_FLIGHT_CFAR_CANCELLATION_V1_RESPONSE";
export type SET_POLL_FULFILL_FLIGHT_CFAR_CANCELLATION_V1_RESPONSE =
  typeof SET_POLL_FULFILL_FLIGHT_CFAR_CANCELLATION_V1_RESPONSE;

export const RESET_CFAR_EXERCISE_STATE = "RESET_CFAR_EXERCISE_STATE";
export type RESET_CFAR_EXERCISE_STATE = typeof RESET_CFAR_EXERCISE_STATE;

export const RESET_CHFAR_EXERCISE_STATE = "RESET_CHFAR_EXERCISE_STATE";
export type RESET_CHFAR_EXERCISE_STATE = typeof RESET_CHFAR_EXERCISE_STATE;

export const RESET_DISRUPTION_EXERCISE_STATE =
  "RESET_DISRUPTION_EXERCISE_STATE";
export type RESET_DISRUPTION_EXERCISE_STATE =
  typeof RESET_DISRUPTION_EXERCISE_STATE;

export const DISCARD_FLIGHT_CFAR_CANCELLATION_QUOTE =
  "DISCARD_FLIGHT_CFAR_CANCELLATION_QUOTE";
export type DISCARD_FLIGHT_CFAR_CANCELLATION_QUOTE =
  typeof DISCARD_FLIGHT_CFAR_CANCELLATION_QUOTE;

export const GET_WATCH_DETAILS = "tripsList/GET_WATCH_DETAILS";
export type GET_WATCH_DETAILS = typeof GET_WATCH_DETAILS;

export const SET_GET_WATCH_DETAILS_CALL_STATE =
  "tripsList/SET_GET_WATCH_DETAILS_CALL_STATE";
export type SET_GET_WATCH_DETAILS_CALL_STATE =
  typeof SET_GET_WATCH_DETAILS_CALL_STATE;

export const SET_SELECTED_WATCH = "tripsList/SET_SELECTED_WATCH";
export type SET_SELECTED_WATCH = typeof SET_SELECTED_WATCH;

export const SET_SELECTED_WATCH_DETAILS =
  "tripsList/SET_SELECTED_WATCH_DETAILS";
export type SET_SELECTED_WATCH_DETAILS = typeof SET_SELECTED_WATCH_DETAILS;

export const CREATE_WATCH = "tripsList/CREATE_WATCH";
export type CREATE_WATCH = typeof CREATE_WATCH;

export const SET_CREATE_WATCH_CALL_STATE =
  "tripsList/SET_CREATE_WATCH_CALL_STATE";
export type SET_CREATE_WATCH_CALL_STATE = typeof SET_CREATE_WATCH_CALL_STATE;

export const SET_CANCELLATION_COMPLETED = "SET_CANCELLATION_COMPLETED";
export type SET_CANCELLATION_COMPLETED = typeof SET_CANCELLATION_COMPLETED;

export const SET_DISRUPTION_REBOOK_SELECTED_FLIGHT =
  "tripsList/SET_DISRUPTION_REBOOK_SELECTED_FLIGHT";
export type SET_DISRUPTION_REBOOK_SELECTED_FLIGHT =
  typeof SET_DISRUPTION_REBOOK_SELECTED_FLIGHT;

export const SET_DISRUPTION_REBOOK_SELECTED_FARE_CLASS =
  "tripsList/SET_DISRUPTION_REBOOK_SELECTED_FARE_CLASS";
export type SET_DISRUPTION_REBOOK_SELECTED_FARE_CLASS =
  typeof SET_DISRUPTION_REBOOK_SELECTED_FARE_CLASS;

export const SET_DISRUPTION_FULFILL_ERROR =
  "tripsList/SET_DISRUPTION_FULFILL_ERROR";
export type SET_DISRUPTION_FULFILL_ERROR = typeof SET_DISRUPTION_FULFILL_ERROR;

export const SET_TRIP_SEARCH_RESULTS = "tripsList/SET_TRIP_SEARCH_RESULTS";
export type SET_TRIP_SEARCH_RESULTS = typeof SET_TRIP_SEARCH_RESULTS;

export const SET_CFAR_EXERCISE_QUOTE_TRACKING_PROPERTIES =
  "tripsList/SET_CFAR_EXERCISE_QUOTE_TRACKING_PROPERTIES";
export type SET_CFAR_EXERCISE_QUOTE_TRACKING_PROPERTIES =
  typeof SET_CFAR_EXERCISE_QUOTE_TRACKING_PROPERTIES;
