import { ChatPropertiesType } from "@b2bportal/chat-api";
import {
  createSupportConversationForProduct,
  SupportContextProvider,
} from "@hopper-b2b/self-serve";
import { TenantContextProvider, useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Grid } from "@material-ui/core";
import clsx from "clsx";
import { useContext, lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import styles from "./App.module.scss";
import { FEATURE_FLAGS, SessionContext } from "./Root";
import chatProperties from "./api/support/support";
import { lightModeIcons } from "./assets/sharedIcons";
import { ProtectedRoute } from "./components/Auth";
import { AuthModule } from "./components/AuthModule";
import { Body } from "./components/Body";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header";
import { InactiveErrorPage } from "./components/InactiveErrorPage";
import { MockCBALoginPage } from "./components/MockCBALogin";
import config from "./utils/config";
import {
  HIDDEN_HEADER_UNPROTECTED_PATHS,
  PATH_ACCESS_DENIED,
  PATH_AUTH,
  PATH_COMING_SOON,
  PATH_INACTIVE_ERROR,
  PATH_TERMS,
} from "./utils/urlPaths";
import AccessDenied from "./components/AccessDenied";
import ComingSoon from "./components/ComingSoon";
import { ApacLoadingIndicator, ApacAutoRedirect } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import useFeatureFlagMap from "./hooks/useFeatureFlagMap";
import { Privacy } from "./components/Privacy";
import { useLocation } from "react-router";
import { TRAVEL_PROFILE_ID } from "./components/AuthModule/component";
import { useFeatureFlag } from "@apac/feature-flags";

const TermsApp = lazy(() => import("./components/Body/TermsRouteComponent"));

export const logger = () => {
  return window.DD_LOGS ? window.DD_LOGS.logger : undefined;
};

export const App = () => {
  const { t } = useI18nContext();
  const featureFlags = useFeatureFlagMap();
  const { sessionInfo } = useContext(SessionContext);
  const { matchesMobile } = useDeviceTypes();
  const location = useLocation();
  const isComingSoonEnabled = useFeatureFlag(FEATURE_FLAGS.DESKTOP_COMING_SOON);

  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    const travelProfileId = searchParams.get(TRAVEL_PROFILE_ID);
    logger()?.info(`attempting to read travel profile id: ${travelProfileId}`);
    if (travelProfileId) {
      logger()?.info(`received travel profile id: ${travelProfileId}`);
      sessionStorage.setItem(TRAVEL_PROFILE_ID, travelProfileId);
    }
  }, []);

  useEffect(() => {
    if (!matchesMobile && isComingSoonEnabled) {
      window.location.pathname = PATH_COMING_SOON;
    }
  }, [isComingSoonEnabled]);

  return (
    <TenantContextProvider
      tenantContext={{
        icons: lightModeIcons,
      }}
    >
      <SupportContextProvider
        kustomerConfig={{
          apiKey: config.KUSTOMER_API_KEY,
          brandId: config.KUSTOMER_BRAND_ID,
          enabled: config.KUSTOMER_CHAT_SUPPORT === "enabled",
          hopperUserId: sessionInfo?.hopperUserId,
          kustomerAccessToken: sessionInfo?.kustomerAccessToken,
          tenant: "commbank-au",
          fetchChatProperties: chatProperties,
        }}
      >
        <Grid
          container
          direction="column"
          className={clsx(styles.container, "app-container")}
        >
          <Box className={styles.innerContainer}>
            {!HIDDEN_HEADER_UNPROTECTED_PATHS.includes(
              window.location.pathname
            ) && <Header />}
            <Grid item xs>
              <Routes>
                <Route path={"mockCbaLogin/*"} element={<MockCBALoginPage />} />
                <Route path="/privacy" Component={Privacy} />
                <Route
                  path="/faqs"
                  element={
                    <ApacAutoRedirect to="https://travelbooking-faqs.hopper.com" />
                  }
                />
                <Route
                  path={PATH_TERMS}
                  element={
                    <Suspense
                      fallback={
                        <ApacLoadingIndicator
                          className={styles.termsLoadingIndicator}
                          indicatorSize={"small"}
                          message={`${t("loading")} ${t(
                            "commBank.terms.termsAndConditions"
                          )}`}
                        />
                      }
                    >
                      <TermsApp featureFlags={featureFlags} />
                    </Suspense>
                  }
                />
                <Route path={`${PATH_AUTH}*`} element={<AuthModule />} />
                <Route
                  path={PATH_INACTIVE_ERROR}
                  element={<InactiveErrorPage />}
                />
                <Route path={PATH_COMING_SOON} element={<ComingSoon />} />
                <Route path={PATH_ACCESS_DENIED} element={<AccessDenied />} />
                <Route
                  path="/*"
                  element={
                    // TODO:  Add Protected Route
                    <ProtectedRoute>
                      <Body featureFlags={featureFlags} adaInit={false} />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </Grid>
            <Grid item>
              <Footer />
            </Grid>
          </Box>
        </Grid>
        {/*Add CBA Auth*/}
        {/* <AuthPrompt
            open={isLoginModalOpen}
            onClose={closeAuthModal}
            onLogin={onLogin}
            googleIdentityClientId={googleIdentityClientId}
          /> */}
      </SupportContextProvider>
    </TenantContextProvider>
  );
};
