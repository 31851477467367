import { MultiSelectDropdown } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";

import { AirlineFilterProps } from "./container";
import { IAirlineOptions } from "../../../../../../../shop/reducer";

interface IAirlineFilterProps extends AirlineFilterProps {
  allAirlines: IAirlineOptions[];
  showDropdownContentOnly?: boolean;
}

export const AirlineFilterSelection = (props: IAirlineFilterProps) => {
  const {
    airlineFilter,
    setAirlineFilter,
    allAirlines,
    showDropdownContentOnly,
  } = props;
  const { t } = useI18nContext();
  return (
    <MultiSelectDropdown
      currentValue={airlineFilter}
      dropdownLabel={t("searchFilter.airline")}
      options={allAirlines}
      setValue={setAirlineFilter}
      showDropdownContentOnly={showDropdownContentOnly}
      className="b2b-shop-filter"
      popoverClassName="b2b"
    />
  );
};
