import { Slice, TripSlice, TripSummary } from "@b2bportal/air-shopping-api";
import { formatInterval } from "@hopper-b2b/utilities";
import { IReturnSlicesByOutgoingId, ITripSummariesById } from "..";

export const createStopoverString = (trip: TripSlice) => {
  let layoverString = "";
  const stopoverSegments = trip.segmentDetails.filter(
    (s) => s.stopoverDurationMinutes && s.stopoverDurationMinutes > 0
  );
  stopoverSegments.forEach((segment, index) => {
    layoverString = segment.stopoverDurationMinutes
      ? `${layoverString}
       ${formatInterval(segment.stopoverDurationMinutes)}
      ${segment.destinationCode}`
      : "";

    if (index < stopoverSegments.length - 1) {
      layoverString = layoverString + " / ";
    }
  });
  return layoverString;
};

export const formatTripSummaries = (
  flightInfo: TripSummary[]
): {
  tripSummariesById: ITripSummariesById;
  returnFlightsByOutgoingId: IReturnSlicesByOutgoingId;
} => {
  const flights: ITripSummariesById = {};
  const outgoingReturnFlightMap: IReturnSlicesByOutgoingId = {};

  flightInfo.forEach((flight) => {
    const outgoingSliceId = flight.outgoingSlice.id;
    const returnSliceId = flight.returningSlice?.id;

    if (returnSliceId) {
      if (outgoingReturnFlightMap[outgoingSliceId]) {
        // add valid return flight to map
        outgoingReturnFlightMap[outgoingSliceId][returnSliceId] = flight.tripId;
      } else {
        // create map of valid return slices for a specific outgoing slice
        outgoingReturnFlightMap[outgoingSliceId] = {
          [returnSliceId]: flight.tripId,
        };
      }
    }

    // store all trip summaries
    flights[flight.tripId] = flight;
  });

  return {
    tripSummariesById: flights,
    returnFlightsByOutgoingId: outgoingReturnFlightMap,
  };
};
