import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionButton } from "@hopper-b2b/ui-core";
import { emailRegex } from "@hopper-b2b/utilities";
import { Box, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import { useState } from "react";
import CloseButtonIcon from "../../assets/icons/common/close-button.svg";
import { ActionLink } from "../ActionLink";
import { B2BSpinner } from "../B2BSpinner";
import { ExpandableCard } from "../ExpandableCard";
import { Icon, IconName } from "../Icon";
import "./styles.scss";

export enum IPriceWatchOptInState {
  NotWatching,
  Watching,
  InProcess,
  Failure,
}

export interface IPriceWatchOptInErrors {
  invalidEmail: string;
  createErrorTitle: string;
  createErrorSubtitle: string;
}
export interface IPriceWatchOptInTitles {
  header?: string;
  title: string;
  subtitle: string;
  watchedHeader?: string;
  watchedTitle: string;
  watchedSubtitle: string;
  description: string;
  buttonCopy: string;
}

export interface IPriceWatchOptInProps {
  className?: string;
  image: string;
  onClick: (email: string) => void;
  onClose?: () => void;
  optInState?: IPriceWatchOptInState;
  titles: IPriceWatchOptInTitles;
  errors: IPriceWatchOptInErrors;
  userEmail?: string;
  buttonClassName?: string;
  isMobile?: boolean;
  onTermsConditionClick?: () => void;
  ctaText: string;
  termsConditionCopy?: string;
}

export const PriceWatchOptIn = (props: IPriceWatchOptInProps) => {
  const {
    className,
    image,
    titles,
    errors,
    onClick,
    onClose,
    optInState,
    userEmail,
    buttonClassName,
    isMobile,
    onTermsConditionClick,
    ctaText,
    termsConditionCopy,
  } = props;
  const [email, setEmail] = useState(userEmail || "");
  const [emailError, setEmailError] = useState("");
  const [expandedKey, setExpandedKey] = React.useState("");
  const {
    header,
    title,
    subtitle,
    description,
    buttonCopy,
    watchedHeader,
    watchedTitle,
    watchedSubtitle,
  } = titles;
  const { invalidEmail, createErrorTitle, createErrorSubtitle } = errors;
  const { t } = useI18nContext();

  const isEmailValid = (val: string) => emailRegex.test(val);

  const onEmailBlur = (val: string) => {
    if (val && !isEmailValid(val)) {
      setEmailError(invalidEmail);
    } else {
      setEmailError("");
    }
  };

  const renderMobileTermsAndCondition = () => {
    return (
      <ExpandableCard
        className={clsx("price-watch-terms-condition", "b2b")}
        expandedCardKey={expandedKey}
        cardKey={"price-watch-terms-key"}
        handleCardKeyChange={() =>
          setExpandedKey(
            expandedKey === "price-watch-terms-key"
              ? ""
              : "price-watch-terms-key"
          )
        }
        scrollExpandedIntoView={true}
        content={{
          title: (
            <Typography className="cta-text" variant="h6">
              {ctaText}
            </Typography>
          ),
          body: termsConditionCopy ? (
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: termsConditionCopy,
              }}
            />
          ) : (
            <div></div>
          ),
        }}
      />
    );
  };

  const renderDesktopTermsAndCondition = () => {
    return (
      <ActionLink
        className="cta-button"
        onClick={onTermsConditionClick}
        content={
          <Typography className="cta-text" variant="h6">
            <span dangerouslySetInnerHTML={{ __html: ctaText }}></span>
          </Typography>
        }
        showTappableArea={true}
      />
    );
  };

  return (
    <Box className={clsx(className, "price-watch-opt-in")}>
      {optInState === IPriceWatchOptInState.Failure ? (
        <Box className="price-watch-opt-in-error">
          <Typography className="price-watch-opt-in-error-title" variant="h2">
            {createErrorTitle}
          </Typography>
          <Typography
            className="price-watch-opt-in-error-subtitle"
            variant="h5"
          >
            {createErrorSubtitle}
          </Typography>
          <ActionButton
            className={clsx("price-watch-retry-button", buttonClassName)}
            onClick={() => {
              onClick(email);
            }}
            disabled={false}
            message={`${t("retry")}`}
            defaultStyle="h4r-primary"
          />
          {onClose && (
            <ActionButton
              className={clsx("price-watch-cancel-button")}
              onClick={() => onClose()}
              disabled={false}
              message={`${t("cancel")}`}
              defaultStyle="h4r-secondary"
            />
          )}
        </Box>
      ) : (
        <>
          <Box className="top-content-container">
            <Box className="img-location-container">
              <Icon className="img-location-icon" name={IconName.B2BMapPin} />
              <Typography className="img-location-text" variant="caption">
                Banff, Alberta
              </Typography>
            </Box>
            {onClose && !isMobile && (
              <ActionLink
                className="watch-opt-in-popup-close-button"
                onClick={() => onClose()}
                content={
                  <img
                    src={CloseButtonIcon}
                    className="close-button-icon"
                    alt=""
                  />
                }
                label={`${t("close.button")}`}
                showTappableArea={true}
              />
            )}
            <img className="image" src={image} alt=""></img>

            {optInState === IPriceWatchOptInState.InProcess && <B2BSpinner />}
            {optInState === IPriceWatchOptInState.Watching && (
              <>
                <Icon className="watched-icon" name={IconName.B2BPriceWatch} />
                {watchedHeader && (
                  <Typography className="watched-header" variant="body1">
                    <span
                      dangerouslySetInnerHTML={{ __html: watchedHeader }}
                    ></span>
                  </Typography>
                )}
              </>
            )}
            {optInState === IPriceWatchOptInState.NotWatching && (
              <>
                <Icon className="icon" name={IconName.B2BPriceWatch} />
                {header && (
                  <Typography className="header" variant="body1">
                    <span dangerouslySetInnerHTML={{ __html: header }}></span>
                  </Typography>
                )}
              </>
            )}
          </Box>

          <Box className="bottom-content-container">
            {optInState === IPriceWatchOptInState.Watching && (
              <>
                <Typography className="watched-title" variant="h4">
                  <span
                    dangerouslySetInnerHTML={{ __html: watchedTitle }}
                  ></span>
                </Typography>
                <Typography className="subtitle" variant="body2">
                  <span
                    dangerouslySetInnerHTML={{ __html: watchedSubtitle }}
                  ></span>
                </Typography>
              </>
            )}
            {optInState === IPriceWatchOptInState.NotWatching && (
              <>
                <Typography className="title" variant="h4">
                  <span dangerouslySetInnerHTML={{ __html: title }}></span>
                </Typography>
                <Typography className="subtitle" variant="body2">
                  <span dangerouslySetInnerHTML={{ __html: subtitle }}></span>
                </Typography>

                <Box className={clsx("email-field", { error: !!emailError })}>
                  <Box className="email-input-container">
                    <Typography className="email-input-label" variant="caption">
                      {t("email")}
                    </Typography>
                    <TextField
                      className="email-input"
                      label=""
                      onBlur={() => onEmailBlur(email)}
                      error={!!emailError}
                      variant="standard"
                      placeholder={`${t("enterYourEmail")}`}
                      value={email}
                      autoFocus={false}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </Box>
                  <ActionButton
                    disabled={!email || !isEmailValid(email)}
                    defaultStyle="h4r-primary"
                    onClick={() => {
                      onClick(email);
                    }}
                    message={buttonCopy}
                    className={buttonClassName}
                  />
                </Box>
                {!!emailError && (
                  <Box className="error-field">
                    <Typography className="email-error-label" variant="caption">
                      {emailError}
                    </Typography>
                  </Box>
                )}
                <Typography className="info" variant="subtitle2">
                  <span
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></span>
                </Typography>
              </>
            )}
            {optInState !== IPriceWatchOptInState.InProcess &&
              (isMobile
                ? renderMobileTermsAndCondition()
                : renderDesktopTermsAndCondition())}
          </Box>
        </>
      )}
    </Box>
  );
};
