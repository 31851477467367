import {
  ReactElement,
  ReactNode,
  MouseEvent,
  useEffect,
  useState,
} from "react";
import { Box, InputAdornment, OutlinedInput, Button } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { removeStyleSheetByMetaName } from "@hopper-b2b/utilities";

import { StyledMenuItem } from "../StyledMenuItem";
import DropDownIcon from "../../assets/icons/common/drop-down-icon.svg";
import clsx from "clsx";
import "./styles.scss";

export interface IOption {
  label: string;
  value: any;
}

export interface ISelectInput {
  label: string | ReactElement;
  placeholder?: string;
  options?: IOption[];
  onChange?: (value: any) => void;
  value: any;
  className?: string;
  required?: boolean;
  errorLabel?: string;
  icon?: IconProp;
  onClick?: (event?: MouseEvent) => void;
  disabled?: boolean;
}

export const SelectInput = ({
  options,
  onChange,
  onClick,
  value,
  className,
  errorLabel,
  label,
  placeholder,
  icon,
  disabled,
}: ISelectInput) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(
    null
  );

  const togglePopover = (event: MouseEvent) => {
    if (onClick) {
      onClick(event);
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const isPopoverOpen = Boolean(anchorEl);

  useEffect(() => {
    removeStyleSheetByMetaName("PrivateNotchedOutline");
  }, []);

  return (
    <Box className="select-input-root" role="select-input-component">
      <FormControl className={clsx("select-input", className)}>
        {options && (
          <Select
            value={value}
            fullWidth={true}
            onChange={onChange}
            disabled={disabled}
            MenuProps={{
              anchorEl,
              anchorOrigin: { vertical: "bottom", horizontal: "left" },
              open: isPopoverOpen,
            }}
            input={
              <OutlinedInput
                className="input-root"
                fullWidth={true}
                name="select-input"
                label={label}
                placeholder={placeholder}
                startAdornment={
                  icon && (
                    <InputAdornment position="start">
                      <Box>
                        <FontAwesomeIcon
                          className="icon"
                          icon={icon as IconProp}
                        />
                      </Box>
                    </InputAdornment>
                  )
                }
              />
            }
            onClick={(event) => !disabled && togglePopover(event)}
          >
            {renderMenu(options)}
          </Select>
        )}
        {!options && (
          <Button className="select-menu-button" onClick={onClick}>
            <Box className="select-menu-button-content">
              <Box className="button-text-container">
                {!!icon && (
                  <Box className="button-icon">{icon as ReactNode}</Box>
                )}
                <Box className="button-text">{value}</Box>
              </Box>
              <Box className="expand-icon-container">
                <img src={DropDownIcon} alt="dropdown icon" />
              </Box>
            </Box>
          </Button>
        )}
      </FormControl>
      {typeof errorLabel !== "undefined" && (
        <Box>
          <span className="error-label">{errorLabel}</span>
        </Box>
      )}
    </Box>
  );
};

export const renderMenu = (options: IOption[]) =>
  options.map(({ label, value }) => (
    <StyledMenuItem key={label} value={value}>
      {label}
    </StyledMenuItem>
  ));
