import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { setTripsFilter, populateTripQueryParams } from "../../actions/actions";
import { getTripsFilter, getTripsCounts } from "../../reducer";
import { FilterTabs } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  tripsFilter: getTripsFilter(state),
  tripsCounts: getTripsCounts(state),
});

const mapDispatchToProps = { populateTripQueryParams, setTripsFilter };

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IFilterTabsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFilterTabs = connector(withRouter(FilterTabs));
