import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { PriceFreezeList } from "./component";
import {
  priceFreezeListSortedSelector,
  getPriceFreezeAirlinesMap,
  getPriceFreezeAirportMap,
} from "./../../reducer";

const mapStateToProps = (state: IStoreState) => ({
  priceFreezes: priceFreezeListSortedSelector(state),
  airlines: getPriceFreezeAirlinesMap(state),
  airports: getPriceFreezeAirportMap(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPriceFreezeList = withRouter(connector(PriceFreezeList));
