import { CallState, DeleteWatchAlertRequest } from "@hopper-b2b/types";
import { flightApiConfigStoreKey } from "../../../reducers/types";
import { IStoreState } from "../../../reducers/types";
import { put, select } from "redux-saga/effects";

import { deleteWatch } from "@hopper-b2b/api";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import { alertKeySelector } from "../reducer/selectors";

export function* deleteWatchSaga() {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const request: DeleteWatchAlertRequest = {
      key: yield select(alertKeySelector),
    };
    yield deleteWatch(request, apiConfig);
    yield put(actions.listWatches());
    yield put(actions.setDeleteWatchCallState(CallState.Success));
  } catch (e) {
    yield put(actions.setDeleteWatchCallState(CallState.Failed));
    Logger.debug(e);
  }
}
