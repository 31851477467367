import { AirCancelQuoteRequest } from "@b2bportal/air-cancel-api";
import { NETWORK_CALL_FAILED, Uuid } from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { getFlightCancelEligibilityPath } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

/**
 * @description The first of two endpoints in the self serve cancel flight flow
 * @param {Uuid} reservationId Contains the reservation id
 * @return {AirCancelQuoteResponse} The cancellation info for the itinerary passed in
 */
const getFlightCancelEligibility = async (reservationId: Uuid) => {
  try {
    const req: AirCancelQuoteRequest = {
      reservationId,
    };
    const res = await axiosInstance.post(getFlightCancelEligibilityPath, req);
    const { AirCancelEligibilityResponse: aceRes } = res.data;

    if (aceRes === "AirCancelEligibilitySuccess") {
      return res.data;
    }

    throw res;
  } catch (err) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });

    throw err;
  }
};

export default getFlightCancelEligibility;
