import { Box, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ActionLink } from "@hopper-b2b/ui";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  getNextTrackingId,
  IHotelLoyalty,
  IHotelLoyaltyList,
  formattedHotelLoyaltyOptions,
} from "@checkout/components/PassengerInformation/components/TravelerInfoForm/utils";
import { IHotelLoyaltyProgram } from "./hotelLoyaltyProgramList";
import { ActionButton } from "@hopper-b2b/ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MobilePopoverCard } from "@hopper-b2b/ui";
import { BackButton } from "@hopper-b2b/ui";
import { MobileFloatingButton } from "@hopper-b2b/ui";
import "./HotelLoyaltySection.styles.scss";

const MOBILE_HIDE_EDIT_FIELDS_THRESHOLD = 1;
const LOYALTY_NUMBER_ERROR = "Loyalty number is required";

export interface IHotelLoyaltySectionProps {
  className?: string;
  closeButton?: JSX.Element;
  hotelLoyaltyList: IHotelLoyaltyList;
  hotelLoyaltyTitle: string;
  hideSelectIcon?: boolean;
  getEditEndAdornment?: (
    field,
    value: string
  ) => { endAdornment: JSX.Element } | undefined;
  isMobile?: boolean;
  readOnly?: boolean;
  setHotelLoyaltyList: Dispatch<SetStateAction<IHotelLoyaltyList>>;
}

const sectionDefaultProps: Partial<IHotelLoyaltySectionProps> = {
  hideSelectIcon: false,
  className: "",
  closeButton: undefined,
  isMobile: false,
  readOnly: false,
};

export const HotelLoyaltySection = (
  props: IHotelLoyaltySectionProps
): JSX.Element => {
  const {
    className,
    closeButton,
    hotelLoyaltyList,
    hotelLoyaltyTitle,
    isMobile,
    getEditEndAdornment,
    hideSelectIcon,
    readOnly,
    setHotelLoyaltyList,
  } = props;
  const [acOpen, setAcOpen] = useState(false);
  const [currentHotelLoyaltyList, setCurrentHotelLoyaltyList] =
    useState<IHotelLoyaltyList>(hotelLoyaltyList);
  const [hotelLoyaltyNumber, setHotelLoyaltyNumber] = useState("");
  const [hotelLoyaltyProgram, setHotelLoyaltyProgram] =
    useState<IHotelLoyaltyProgram | null>(null);
  const [focusedTrackingId, setFocusedTrackingId] = useState<number | null>(
    null
  );
  const [newTrackingId, setNewTrackingId] = useState<number>(
    getNextTrackingId(hotelLoyaltyList)
  );
  const [isInputChanged, setIsInputChanged] = useState<boolean>(false);
  const [openEditFieldsModal, setOpenEditFieldsModal] =
    useState<boolean>(false);
  const [hotelLoyaltyError, setHotelLoyaltyError] = useState(false);

  const cleanUpFields = () => {
    // clean fields
    setHotelLoyaltyNumber("");
    setHotelLoyaltyProgram(null);
  };

  const cleanUp = () => {
    // set focused to null
    setFocusedTrackingId(null);
    cleanUpFields();
  };

  const handleOnEdit = (hotelLoyalty: IHotelLoyalty) => {
    // populate edit fields
    setHotelLoyaltyNumber(hotelLoyalty.value);
    setHotelLoyaltyProgram(
      formattedHotelLoyaltyOptions.find(
        (option) => hotelLoyalty.key === option.loyaltyProgramCode
      ) || null
    );

    // set focusedTrackingId
    setFocusedTrackingId(hotelLoyalty.trackingId);

    // when loyalty number & program are populated, and the current newTrackingId matches
    // with the entry in list, the newTrackingId needs to get updated as well
    const newEntry = currentHotelLoyaltyList.find(
      (loyalty) => loyalty.trackingId === newTrackingId
    );
    if (newEntry) {
      if (
        newEntry.key === hotelLoyaltyProgram?.loyaltyProgramCode &&
        newEntry.value === hotelLoyaltyNumber
      ) {
        setNewTrackingId(getNextTrackingId(currentHotelLoyaltyList));
      }
    }

    if (isMobile) {
      setOpenEditFieldsModal(true);
    }
  };

  const handleOnRemove = (trackingId: number) => {
    setCurrentHotelLoyaltyList((prevList) =>
      prevList.filter((hotelLoyalty) => hotelLoyalty.trackingId !== trackingId)
    );

    if (isMobile) {
      cleanUpFields();
      setOpenEditFieldsModal(false);
    }
  };

  const renderSavedLoyaltyPrograms = () => (
    <Box className="saved-hotel-loyalty-section">
      {currentHotelLoyaltyList
        .filter(
          (loyalty) =>
            loyalty.trackingId !== newTrackingId &&
            loyalty.trackingId !== focusedTrackingId
        )
        .map((hotelLoyalty) => (
          <HotelLoyaltyRow
            key={hotelLoyalty.key}
            className="saved-hotel-loyalty"
            hotelLoyaltyNumber={hotelLoyalty.value}
            hotelLoyaltyProgramName={
              <strong>
                {(formattedHotelLoyaltyOptions.find(
                  (option) => option.loyaltyProgramCode === hotelLoyalty.key
                )?.label as JSX.Element) ?? <></>}
              </strong>
            }
            onEdit={() => (readOnly ? undefined : handleOnEdit(hotelLoyalty))}
            onRemove={
              readOnly || isMobile
                ? undefined
                : () => handleOnRemove(hotelLoyalty.trackingId)
            }
            isMobile={isMobile}
          />
        ))}
    </Box>
  );

  const getFilteredHotelLoyaltyOptions = () => {
    const addedLoyaltyPrograms = new Set();
    currentHotelLoyaltyList.forEach((hotelLoyalty) => {
      if (
        hotelLoyalty.trackingId !== focusedTrackingId &&
        hotelLoyalty.trackingId !== newTrackingId &&
        !addedLoyaltyPrograms.has(hotelLoyalty.key)
      ) {
        addedLoyaltyPrograms.add(hotelLoyalty.key);
      }
    });

    return formattedHotelLoyaltyOptions.filter(
      (option) => !addedLoyaltyPrograms.has(option.loyaltyProgramCode)
    );
  };

  const isButtonDisabled = !hotelLoyaltyNumber || !hotelLoyaltyProgram;
  const isButtonDisabledAddAnotherMobile =
    (!hotelLoyaltyNumber || !hotelLoyaltyProgram) &&
    currentHotelLoyaltyList.length < MOBILE_HIDE_EDIT_FIELDS_THRESHOLD;

  const updateCurrentHotelLoyaltyList = (
    programNumber: string,
    programCode: string,
    trackingId: number
  ) => {
    setCurrentHotelLoyaltyList((prevList) => {
      const newHotelLoyaltyList = [...prevList];
      const focusedIndex = newHotelLoyaltyList.findIndex(
        (hotelLoyalty) => hotelLoyalty.trackingId === trackingId
      );

      newHotelLoyaltyList[focusedIndex] = {
        ...newHotelLoyaltyList[focusedIndex],
        key: programCode,
        value: programNumber,
      };

      return newHotelLoyaltyList;
    });
  };

  const addToCurrentHotelLoyaltyList = (
    loyaltyNumber: string,
    loyaltyProgramCode: string,
    trackingId: number
  ) => {
    setCurrentHotelLoyaltyList((prevList) => {
      const newHotelLoyaltyList = prevList;
      newHotelLoyaltyList.push({
        trackingId,
        key: loyaltyProgramCode,
        value: loyaltyNumber,
      });

      return newHotelLoyaltyList;
    });
  };

  const handleAddAnother = () => {
    if (isButtonDisabled) {
      return;
    }

    cleanUp();
    setNewTrackingId(getNextTrackingId(currentHotelLoyaltyList));
  };

  const handleAddAnotherMobile = () => {
    if (isButtonDisabledAddAnotherMobile) {
      return;
    }

    cleanUp();
    setNewTrackingId(getNextTrackingId(currentHotelLoyaltyList));
    setOpenEditFieldsModal(true);
  };

  const handleSaveHotelLoyalty = () => {
    if (!hotelLoyaltyNumber.trim() || !hotelLoyaltyProgram) {
      return;
    }

    if (focusedTrackingId !== null) {
      // update existing entry
      updateCurrentHotelLoyaltyList(
        hotelLoyaltyNumber,
        hotelLoyaltyProgram.loyaltyProgramCode,
        focusedTrackingId
      );
    }

    cleanUp();
  };

  const handleSaveHotelLoyaltyMobile = () => {
    handleSaveHotelLoyalty();
    setNewTrackingId(getNextTrackingId(currentHotelLoyaltyList));
    setOpenEditFieldsModal(false);
  };

  const renderButtons = () =>
    !readOnly && (
      <Box className="hotel-loyalty-buttons-section">
        {focusedTrackingId ? (
          <Box className="button-container save-details">
            <ActionButton
              className="traveler-info-button save-details"
              message="Save hotelloyalty details"
              disabled={isButtonDisabled}
              onClick={handleSaveHotelLoyalty}
              defaultStyle="h4r-primary"
            />
          </Box>
        ) : (
          <Box className="button-container add-another">
            <ActionButton
              className="traveler-info-button secondary add-another"
              message="Add Another Program"
              disabled={isButtonDisabled}
              onClick={handleAddAnother}
              defaultStyle="h4r-secondary"
            />
          </Box>
        )}
      </Box>
    );

  const renderButtonsMobile = () =>
    !readOnly && (
      <Box
        className={clsx("hotel-loyalty-buttons-section", {
          mobile: isMobile,
        })}
      >
        <Box className="button-container add-another">
          <ActionButton
            className="traveler-info-button secondary add-another"
            message="Add Another Program"
            disabled={isButtonDisabledAddAnotherMobile}
            onClick={handleAddAnotherMobile}
            defaultStyle="h4r-secondary"
          />
        </Box>
      </Box>
    );

  const renderEditFields = () => (
    <form className="traveler-info-form hotel-loyalty-section">
      <Autocomplete
        fullWidth
        className="hotel-loyalty-autocomplete"
        defaultValue={null}
        disableClearable={readOnly}
        disabled={readOnly}
        getOptionLabel={({ loyaltyProgramName }) => loyaltyProgramName}
        id="hotel-loyalty-program-input"
        onChange={(_e, option: IHotelLoyaltyProgram) =>
          setHotelLoyaltyProgram(option || null)
        }
        onClose={() => setAcOpen(false)}
        onOpen={() => !readOnly && setAcOpen(true)}
        open={acOpen}
        options={getFilteredHotelLoyaltyOptions()}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            className="traveler-info-field hotel-loyalty-program"
            disabled={readOnly}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
              id: "hotel-loyalty-program-input",
            }}
            label="Hotel loyalty program"
          />
        )}
        renderOption={({ label }) => label}
        value={hotelLoyaltyProgram}
      />
      <TextField
        className={clsx("traveler-info-field hotel-loyalty-number", {
          error: !!hotelLoyaltyNumber && hotelLoyaltyError,
        })}
        disabled={readOnly}
        error={!!hotelLoyaltyNumber && hotelLoyaltyError}
        helperText={hotelLoyaltyError && LOYALTY_NUMBER_ERROR}
        id="hotel-loyalty-number-input"
        label="Hotel loyalty number"
        InputProps={
          hideSelectIcon && getEditEndAdornment
            ? getEditEndAdornment(
                "", // TODO do we need IRequestTravelerEditFormEnum.hotelLoyaltyNumber,
                hotelLoyaltyNumber || ""
              )
            : undefined
        }
        onChange={({ target: { value } }) => {
          setHotelLoyaltyNumber(value);
          if (value.trim()) {
            setHotelLoyaltyError(false);
          } else {
            setHotelLoyaltyError(true);
          }
        }}
        value={hotelLoyaltyNumber}
      />
    </form>
  );

  const renderEditFieldsModal = () => {
    const handleGoBack = () => {
      // show goBack button when adding new entry; remove added entry when the user clicks on goBack
      if (focusedTrackingId === null) {
        handleOnRemove(newTrackingId);
      }
    };

    return (
      <MobilePopoverCard
        fullScreen
        open={openEditFieldsModal}
        className="hotel-loyalty-edit-fields-popup"
        onClose={() => setOpenEditFieldsModal(false)}
        topRightButton={closeButton}
        // show goBack button when adding new entry
        topLeftButton={
          focusedTrackingId === null ? (
            <BackButton
              className="hotel-loyalty-popup-go-back-button"
              onClick={handleGoBack}
            />
          ) : undefined
        }
      >
        <Box className="hotel-loyalty-edit-form-container">
          <Box className="hotel-loyalty-description">
            <Typography variant="subtitle1">{hotelLoyaltyTitle}</Typography>
          </Box>
          {renderEditFields()}
          {focusedTrackingId !== null && (
            <Box className="hotel-loyalty-remove-button-container">
              <ActionButton
                className="hotel-loyalty-remove-button"
                disabled={false}
                onClick={() => handleOnRemove(focusedTrackingId)}
                message={
                  <Box className="remove-button-content">
                    <span>Remove</span>
                  </Box>
                }
                defaultStyle="h4r-secondary"
              />
            </Box>
          )}
          <MobileFloatingButton
            className="mobile-hotel-loyalty-save-button"
            wrapperClassName="mobile-hotel-loyalty-save-button-container"
            disabled={isButtonDisabled}
            onClick={handleSaveHotelLoyaltyMobile}
          >
            Save Hotel Loyalty Details
          </MobileFloatingButton>
        </Box>
      </MobilePopoverCard>
    );
  };

  useEffect(() => {
    setIsInputChanged(true);
  }, [hotelLoyaltyNumber, hotelLoyaltyProgram]);

  useEffect(() => {
    // on mobile, the focusedTrackingId should be treated as null when editFieldsModal isn't open
    const isFocusedTrackingIdNull =
      focusedTrackingId === null || (isMobile && !openEditFieldsModal);

    // when both loyalty number and program fields are populated, and there is no focused trackingId (not editing an existing entry)
    if (
      isInputChanged &&
      hotelLoyaltyNumber &&
      hotelLoyaltyProgram &&
      isFocusedTrackingIdNull
    ) {
      const indexWithNewTrackingId = currentHotelLoyaltyList.findIndex(
        (hotelLoyalty) => hotelLoyalty.trackingId === newTrackingId
      );

      // when there is an existing entry having newTrackingId
      if (indexWithNewTrackingId >= 0) {
        // update current entry
        updateCurrentHotelLoyaltyList(
          hotelLoyaltyNumber,
          hotelLoyaltyProgram.loyaltyProgramCode,
          newTrackingId
        );
      } else {
        // add new entry
        addToCurrentHotelLoyaltyList(
          hotelLoyaltyNumber,
          hotelLoyaltyProgram.loyaltyProgramCode,
          newTrackingId
        );
      }

      setIsInputChanged(false);
    }
  }, [
    isInputChanged,
    hotelLoyaltyNumber,
    hotelLoyaltyProgram,
    focusedTrackingId,
    currentHotelLoyaltyList,
    newTrackingId,
    updateCurrentHotelLoyaltyList,
    addToCurrentHotelLoyaltyList,
  ]);

  useEffect(() => {
    setHotelLoyaltyList(currentHotelLoyaltyList);
  }, [currentHotelLoyaltyList]);

  // on mobile, the edit fields are supposed to be shown only when there are less than 1 existing entries
  const showEditFields =
    !isMobile ||
    currentHotelLoyaltyList.length < MOBILE_HIDE_EDIT_FIELDS_THRESHOLD ||
    // corner case when the current entry with newTrackingId is being edited
    (currentHotelLoyaltyList.length === 1 &&
      newTrackingId === currentHotelLoyaltyList[0].trackingId);

  return (
    <Box
      className={clsx(
        "hotel-loyalty-container",
        { mobile: isMobile },
        className
      )}
    >
      {renderSavedLoyaltyPrograms()}
      {showEditFields && renderEditFields()}
      {isMobile ? renderButtonsMobile() : renderButtons()}
      {renderEditFieldsModal()}
    </Box>
  );
};

HotelLoyaltySection.defaultProps = sectionDefaultProps;

interface IHotelLoyaltyRowProps {
  className?: string;
  hotelLoyaltyNumber: string;
  hotelLoyaltyProgramName: JSX.Element;
  isMobile?: boolean;
  onEdit?: () => void;
  onRemove?: () => void;
}

const rowDefaultProps: Partial<IHotelLoyaltyRowProps> = {
  className: "",
  isMobile: false,
  onEdit: undefined,
  onRemove: undefined,
};

const HotelLoyaltyRow = (props: IHotelLoyaltyRowProps) => {
  const {
    className,
    hotelLoyaltyNumber,
    hotelLoyaltyProgramName,
    isMobile,
    onEdit,
    onRemove,
  } = props;

  return (
    <Box
      className={clsx(
        "hotel-loyalty-row-root",
        { mobile: isMobile },
        className
      )}
    >
      <Box className="hotel-loyalty-row-container">
        <Box className="hotel-loyalty-info-section">
          <Box className="hotel-loyalty-info-text">
            {hotelLoyaltyProgramName}
            {hotelLoyaltyNumber}
          </Box>
        </Box>
        <Box className="hotel-loyalty-buttons-section">
          {onEdit && (
            <ActionLink
              className="edit-button"
              disabled={false}
              onClick={() => onEdit()}
              content={
                <Typography className="action-link-text">Edit</Typography>
              }
            />
          )}
          {onRemove && (
            <>
              <Typography className="action-link-text">|</Typography>
              <ActionLink
                className="remove-button"
                disabled={false}
                onClick={() => onRemove()}
                content={
                  <Typography className="action-link-text">Delete</Typography>
                }
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

HotelLoyaltyRow.defaultProps = rowDefaultProps;
