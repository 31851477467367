import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import {
  Airline,
  Amount,
  Flights,
  ShopFilter,
  Slice,
} from "@b2bportal/air-shopping-api";
import {
  AirlineCode,
  AirportCode,
  CancelCopy,
  CopyWithExtra,
  OfferWithSuggested,
  Platform,
  Prediction,
  Uuid,
} from "../..";
import { AgentLocator, AirportRegion } from "../apis/tysons/payment-machine";
import { AirlineMap, AirportMap } from "../trips-module/itinerary";

export enum ExchangeActionEnum {
  add = "Add",
  change = "Change",
  flown = "Flown",
  keep = "Keep",
  remove = "Remove",
}

export enum ExchangeForTypeEnum {
  oneWay = "OneWay",
  roundTrip = "RoundTrip",
}

export enum PassengerKey {
  adult = "ADT",
  adultPrivate = "JCB",
  agelessChild = "CNN",
  agelessChildPrivate = "JNN",
  lapInfant = "INF",
  lapInfantPrivate = "JNF",
  seatedInfant = "INS",
  seatedInfantPrivate = "JNS",
}

export type FareClasses = {
  basic: boolean;
  standard: boolean;
  enhanced: boolean;
  premium: boolean;
  luxury: boolean;
};

export type Maybe<T> = T | null;

export interface IExchangeType {
  ExchangeForType: ExchangeForTypeEnum;
  outboundSelection: ExchangeActionEnum;
  returnSelection?: ExchangeActionEnum;
}

export interface IExchangeFlightSummariesReq {
  /* Date in YYYY-MM-DD format */
  departureDate: string;
  exchangeType: IExchangeType;
  originalItineraryId: string;
  passengers: Partial<Record<PassengerKey, number>>;
  platform: Platform;
  /* Date in YYYY-MM-DD format */
  returnDate?: string;
  route: {
    destination: AirportRegion;
    origin: AirportRegion;
  };
  tripFilter: ShopFilter;
}

export interface IExchangeFlightSummariesRes {
  flights: Flights;
  prediction: Prediction;
  priceFreezeOffer?: OfferWithSuggested;
}

export interface IFlightBookingInfoRes {
  context: {
    airlines: AirlineMap;
    airports: AirportMap;
  };
  itinerary: BookedFlightItineraryWithDepartureTime;
}

export enum ExchangeModalState {
  ChangeAirports,
  ChangeDateTime,
  ChangeFailed,
  ChangeRequested,
  ContactAirline,
  ContactSupport,
  GoToExchange,
  IneligibleForExchange,
  ItineraryNotFound,
  LoadFailed,
  LoadingOrProcessing,
  SliceSelection,
  TicketedVoidable,
  TooManyAttemptsSupport,
}

export enum ExchangeScenario {
  airlineControl = "AirlineControl",
  bookingPending = "BookingPending",
  canceled = "Canceled",
  chfar = "Chfar",
  containsRemarks = "ContainsRemarks",
  departed = "Departed",
  ftc = "Ftc",
  nonChfar = "NonChfar",
}

export enum FTCScenario {
  available = "FtcAvailable",
  expired = "FtcExpired",
  redeemed = "FtcRedeemed",
}

export enum NonChfarScenario {
  contactAirline = "ContactAirline",
  contactCustomerService = "ContactCustomerService",
  exchangeable = "Exchangeable",
  nonExchangeable = "NonExchangeable",
  ticketedVoidable = "TicketedVoidable",
}

interface EnhancedAgentLocator extends AgentLocator {
  provider: string;
  userString: string;
}

export type ICancelledExchangePolicy = {
  ExchangeScenario: ExchangeScenario.canceled;
};

export type IFTCExchangePolicy = {
  ExchangeScenario: ExchangeScenario.ftc;
  Ftc: FTCScenario;
  agentLocator: EnhancedAgentLocator;
  /* Shows in header row on FlightSearch page */
  bannerCopy: CopyWithExtra;
  changeFee?: Amount;
  /* Shows on ConfirmFlightExchange page */
  exchangeConfirmationCopy: CopyWithExtra;
  /* Shows on FlightSearch page */
  exchangeCopy: CancelCopy;
  hasCfar: boolean;
  /* Shows on MyTrips page in popover */
  myTripsCopy: CancelCopy;
  originalChangeFee?: Amount;
  rebookDeadlineMillis: number;
  travelDeadlineMillis: number;
};

export type INonChfarContactAirlineRes = {
  ExchangeScenario: ExchangeScenario.nonChfar;
  NonChfar: NonChfarScenario.contactAirline;
  airlines: Airline[];
  /*  Shows in modal on FlightSearch page */
  exchangeCopy: CopyWithExtra;
};

export type INonChfarContactCustomerServiceRes = {
  ExchangeScenario: ExchangeScenario.nonChfar;
  NonChfar: NonChfarScenario.contactCustomerService;
  /* Shows in modal on FlightSearch page */
  customerServiceCopy: CopyWithExtra;
};

export type INonChfarExchangeableRes = {
  ExchangeScenario: ExchangeScenario.nonChfar;
  NonChfar: NonChfarScenario.exchangeable;
  agentLocator: EnhancedAgentLocator;
  /* On header row in ConfirmFlightExchange page */
  confirmationBannerCopy: CopyWithExtra;
  changeFee?: Amount;
  /* On header row in FlightSearch page */
  exchangeBannerCopy: CopyWithExtra;
  /* On ConfirmFlightExchange page */
  exchangeConfirmationCopy: CancelCopy;
  /* On FlightSearch page */
  exchangeCopy: CopyWithExtra;
};

export type INonChfarNonExchaneableRes = {
  ExchangeScenario: ExchangeScenario.nonChfar;
  NonChfar: NonChfarScenario.nonExchangeable;
  agentLocator: EnhancedAgentLocator;
  /* On FlightSearch page */
  exchangeCopy: CopyWithExtra;
};

export type INonChfarVoidableExchangeRes = {
  ExchangeScenario: ExchangeScenario.nonChfar;
  NonChfar: NonChfarScenario.ticketedVoidable;
  agentLocator: EnhancedAgentLocator;
  voidWindowEnd: number;
};

export type INonChfarExchangePolicy =
  | INonChfarContactAirlineRes
  | INonChfarContactCustomerServiceRes
  | INonChfarExchangeableRes
  | INonChfarNonExchaneableRes
  | INonChfarVoidableExchangeRes;

export type IFlightExchangePolicyRes =
  | ICancelledExchangePolicy
  | IFTCExchangePolicy
  | INonChfarExchangePolicy;

export interface ISubmitFlightExchangeReq {
  itinerary: {
    /* `Slice` list with required `segments` and optional other fields */
    slices: Array<Pick<Slice, "segments"> & Partial<Slice>>;
  };
  /* Original flight's itinerary Id */
  itineraryId: Uuid;
  /* Original flight's PNR */
  pnr: string;
  /* The updated booking price */
  totalPrice: string;
}

export type ISubmitFlightExchangeRes = {
  ExchangeSubmitResponse: "Success" | "Failure";
};

export type IFlightNumberMap = {
  [key: string]: string[];
};

export type ISelectOption<T = any> = {
  label: string;
  value: T;
};

export type IReshopFilterOpts = {
  airlineOpts: ISelectOption<AirlineCode>[];
  airportOpts: ISelectOption<AirportCode>[];
  flightNumberOpts: IFlightNumberMap;
  priceMax: { value: number };
  priceMin: { value: number };
};
