import { AccountPrice, Prices } from "@b2bportal/lodging-api";

export const getLowestRoomPrice = (productPrice: Prices | null) => {
  if (!productPrice) {
    return;
  }
  let lowestPoint: AccountPrice = undefined;
  for (const accountId in productPrice.accountSpecific) {
    const accountPrice = productPrice.accountSpecific[accountId];
    if (!lowestPoint || accountPrice.value < lowestPoint.value) {
      lowestPoint = accountPrice;
    }
  }

  return lowestPoint;
};
