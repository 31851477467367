import { put, select } from "redux-saga/effects";
import { WatchAlertView } from "@b2bportal/air-price-watch-api";
import { CallState } from "@hopper-b2b/types";
import { listWatches } from "@hopper-b2b/api";

import { flightApiConfigStoreKey } from "../../../reducers/types";
import { IStoreState } from "../../../reducers/types";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";

export function* fetchWatchesSaga() {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const watches: WatchAlertView[] = yield listWatches(apiConfig);
    yield put(actions.setListWatchesCallState(CallState.Success));
    yield put(actions.setWatches(watches));
  } catch (e) {
    yield put(actions.setListWatchesCallState(CallState.Failed));
    Logger.debug(e);
  }
}
