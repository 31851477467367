import { Box } from "@material-ui/core";

import { Person } from "@b2bportal/air-booking-api";
import { FiatPrice } from "@b2bportal/air-shopping-api";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { SelfServeEvents } from "@hopper-b2b/types";
import { Icon, IconName } from "@hopper-b2b/ui";
import { useUberBridge } from "@hopper-b2b/utilities";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { FlightShopReviewItinerary } from "../../../shop/components";
import { FlightShopStep } from "../../../shop/reducer";
import { getImportantInfo } from "../../reducer/selectors";
import {
  getBaseEventProps,
  getIsFtc,
  getPassengers,
  getSummaryItems,
} from "../../selectors";

export interface IReviewItineraryStep {
  isMobile: boolean;
  onSubmitReq: (totalPrice: FiatPrice) => void;
  setExchangeStep: (exchangeStep: FlightShopStep) => void;
}

const defaultProps: Partial<IReviewItineraryStep> = {};

const ReviewItineraryStep = (props: IReviewItineraryStep): JSX.Element => {
  const { isMobile, onSubmitReq, setExchangeStep } = props;
  const { t } = useI18nContext();
  const importantInfo = useSelector(getImportantInfo);
  const isFTC = useSelector(getIsFtc);
  const passengers = useSelector(getPassengers);
  const baseEventProps = useSelector(getBaseEventProps);
  const summaryItems = useSelector(getSummaryItems);
  const { postEvent } = useUberBridge();

  const passengersList = useMemo(() => {
    const { alone, withLapInfants } = passengers;
    const allPassengers: Person[] = alone.map((a) => a.person);

    for (let i = 0; i < withLapInfants.length; i += 1) {
      const { adult, infant } = withLapInfants[i];

      allPassengers.push(adult.person, infant.person);
    }

    return allPassengers;
  }, [passengers]);

  const totalCostFiat = useMemo<FiatPrice>(() => {
    const numPax = passengersList.length || 1;
    let currencyCode = "";
    let currencySymbol = "";
    const total = summaryItems.reduce((acc, item) => {
      ({ currencyCode, currencySymbol } = item.fiatPrice);

      return acc + item.fiatPrice.value;
    }, 0);
    const totalPerPax = total / numPax;

    return { currencyCode, currencySymbol, value: totalPerPax };
  }, [passengersList, summaryItems]);

  function onContinue() {
    if (isMobile) {
      onSubmitReq(totalCostFiat);
    } else {
      setExchangeStep(FlightShopStep.ExchangeConfirmReq);
    }
  }

  useEffect(() => {
    const eventName = isFTC
      ? SelfServeEvents.ViewedFTCExchangeReviewPage
      : SelfServeEvents.ViewedExchangeReviewPage;

    trackEvent({
      eventName,
      properties: baseEventProps,
    });
    postEvent(eventName, baseEventProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="exchange-review-itinerary-step">
      <FlightShopReviewItinerary
        isMobile={isMobile}
        importantInfo={importantInfo}
        onContinue={onContinue}
        passengers={passengersList}
        priceSummaryChevronIcon={
          <Icon
            ariaLabel="Open price breakdown"
            className="mobile-right-chevron"
            name={IconName.Chevron}
          />
        }
        primaryButtonText={t("exchangeable.reviewItinerary.submitRequest")}
        totalCost={totalCostFiat}
        viewPriceBreakdown={() =>
          setExchangeStep(FlightShopStep.ExchangePriceBreakdown)
        }
      />
    </Box>
  );
};

ReviewItineraryStep.defaultProps = defaultProps;

export default ReviewItineraryStep;
