import axios from "axios";
import { put } from "redux-saga/effects";
import {
  GetPriceFreezeResponseV2,
  priceFreezeApi,
} from "@b2bportal/price-freeze-api";
import { CallState, GetPriceFreezeRequestEnum } from "@hopper-b2b/types";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import { getEnvVariables } from "@hopper-b2b/utilities";

export function* fetchPriceFreezeSaga(action: actions.IFetchPriceFreeze) {
  try {
    const res: Promise<GetPriceFreezeResponseV2> = yield priceFreezeApi(
      axios
    ).apiV2PricefreezeGetPost({
      GetPriceFreezeRequest: GetPriceFreezeRequestEnum.ByPriceFreezeId,
      id: action.priceFreezeId,
    });
    const priceFreeze: GetPriceFreezeResponseV2 = res.data;
    yield put(
      actions.setPriceFreeze({
        priceFreeze,
        priceFreezeCallState: CallState.Success,
      })
    );
    fireUberStartSession();
  } catch (e) {
    // TODO: consume this state in ErrorModal
    yield put(actions.setPriceFreezeCallStateFailed());
    Logger.debug(e);
    fireUberStartSession();
  }
}

const fireUberStartSession = () => {
  // Following event is fired to indicate that the portal is loaded when coming from uber
  // Fired after:
  // - fetched price freeze from params
  // - rendered price freeze overview
  if (getEnvVariables("clientName") === "uber") {
    window.parent.postMessage(
      {
        type: "START_SESSION",
        payload: null,
      },
      import.meta.env["VITE_UBER_URL"]
    );
  }
};
