import { LodgingSelectionEnum, ShopResponseEnum } from "@b2bportal/lodging-api";
import { URL_PARAM_KEYS } from "@hopper-b2b/lodging-utils";
import { CallState } from "@hopper-b2b/types";
import { useDeviceTypes, useFeatureFlagsContext } from "@hopper-b2b/utilities";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom-v5-compat";
import { AvailabilityByLodgingQuery } from "../../../api/availability/fetchAvailability";
import { fetchShopByAvailabilityAPI } from "../../../api/shop/fetchShop";
import {
  setCheckInInstructions,
  setCheckInPolicies,
  setRooms,
  setSelectedLodging,
  setSelectedRoom,
  setShopCallState,
} from "../actions/actions";
import { setAvailabilityLodgings } from "../../availability/actions/actions";

export const useFetchRooms = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [search] = useSearchParams();
  const { matchesMobile: isMobile } = useDeviceTypes();
  const featureFlags = useFeatureFlagsContext();
  const from = search.get(URL_PARAM_KEYS.FROM_DATE);
  const until = search.get(URL_PARAM_KEYS.UNTIL_DATE);
  const numberOfRooms = featureFlags.showLodgingRooms
    ? Number(search.get(URL_PARAM_KEYS.ROOMS_COUNT)) || 1
    : 1;
  const adults = Number(search.get(URL_PARAM_KEYS.ADULTS_COUNT)) || 1;
  const childrenCount = Number(search.get(URL_PARAM_KEYS.CHILDREN_COUNT)) || 0;

  const roomQuery = useMemo(() => {
    const query: AvailabilityByLodgingQuery = {
      stayDates: {
        from,
        until,
      },
      guests: {
        adults,
        children: new Array(childrenCount).fill(1),
      },
      platform: isMobile ? "Mobile" : "Desktop",
      lodgingSelection: {
        lodgingIds: [params.id],
        preserveOrder: true,
        LodgingSelection: LodgingSelectionEnum.LodgingIds,
      },
      rooms: {
        numberOfRooms,
      },
    };
    return query;
  }, [isMobile, params.id, from, until, numberOfRooms, adults, childrenCount]);

  return useCallback(async () => {
    dispatch(setShopCallState(CallState.InProcess));
    try {
      const [lodging, offerOpaque, roomsPromise, availability] =
        await fetchShopByAvailabilityAPI(roomQuery, params.id);
      if (!lodging) {
        dispatch(setShopCallState(CallState.Failed));
        throw new Error("No lodging available");
      }
      dispatch(setSelectedLodging(lodging, offerOpaque));
      const roomsRes = await roomsPromise;

      const {
        guests,
        rooms: { numberOfRooms },
        stayDates: { from: fromDate, until: untilDate },
      } = roomQuery;
      const { lodgings, offers, trackingPropertiesV2 } = availability;

      dispatch(
        setAvailabilityLodgings(
          lodgings,
          { guests, rooms: numberOfRooms, fromDate, untilDate },
          null,
          null,
          offers,
          trackingPropertiesV2
        )
      );

      switch (roomsRes.ShopResponse) {
        case ShopResponseEnum.Available: {
          const updatedRoomInfoProducts = roomsRes.roomInfoProducts?.reduce(
            (acc, current) => {
              const existingIndex = acc.findIndex(
                (r) => r.roomInfo.roomId === current.roomInfo.roomId
              );
              if (existingIndex !== -1) {
                const existing = acc[existingIndex];
                const updatedExisting = {
                  ...existing,
                  products: [...existing.products, ...current.products],
                };
                acc[existingIndex] = updatedExisting;
              } else {
                acc.push(current);
              }
              return acc;
            },
            []
          );

          dispatch(setCheckInInstructions(roomsRes.checkInInstructions));
          dispatch(setCheckInPolicies(roomsRes.checkInInstructions?.policies));
          dispatch(setRooms(updatedRoomInfoProducts));
          dispatch(setSelectedRoom(undefined)); // Unset any previously selected room, new rates fetched

          break;
        }
        case ShopResponseEnum.Failure:
        case ShopResponseEnum.Unavailable:
          // TODO: Handle error states
          throw new Error(roomsRes.ShopResponse);
          break;
        default:
          // Handle default case if needed
          break;
      }
      return roomsRes;
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, params.id, roomQuery]);
};
