import { Box, Dialog } from "@material-ui/core";
import clsx from "clsx";
import { ComponentType } from "react";
import { LoadingIndicator } from "../LoadingIndicator";
import "./styles.scss";

export interface ILoadingPopupProps {
  classes?: string[];
  open: boolean;
  fullScreen?: boolean;
  message: string;
  indicator?: ComponentType;
  indicatorSize?: "small" | "medium" | "large";
  popupSize?: "small" | "regular";
  verticalAlignment?: "top" | "center" | "bottom";
  footer?: ComponentType<{ className: string }>;
  textAlign?: "left" | "center" | "right";
}

const LoadingPopup = ({
  classes,
  open,
  fullScreen,
  message,
  indicator,
  indicatorSize,
  popupSize = "regular",
  verticalAlignment,
  footer,
  textAlign,
}: ILoadingPopupProps) => {
  const Footer = footer;

  return (
    <Dialog
      className={clsx(classes, "loading-popup")}
      open={open}
      fullScreen={fullScreen}
    >
      <Box
        className={clsx(
          popupSize,
          { "full-screen": fullScreen },
          "loading-popup-content-wrapper"
        )}
      >
        <Box className={clsx("loading-popup-content", verticalAlignment)}>
          <LoadingIndicator
            indicator={indicator}
            message={message}
            indicatorSize={indicatorSize}
            textAlign={textAlign}
          />
        </Box>
        {!!Footer && <Footer className="loading-footer" />}
      </Box>
    </Dialog>
  );
};

export default LoadingPopup;
