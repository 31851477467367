import "./styles.scss";
import clsx from "clsx";

export interface IApacCheckboxButtonProps {
  checked: boolean;
  onChange?: () => void;
  className?: string;
  disabled?: boolean;
}

export const ApacCheckbox = ({
  className,
  onChange,
  disabled = false,
  checked,
}: IApacCheckboxButtonProps) => {
  return (
    <input
      className={clsx("apac-checkbox", className)}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      readOnly={!onChange}
      disabled={disabled}
    />
  );
};
