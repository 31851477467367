import { ButtonWrap } from "@hopper-b2b/ui";
import { useDeviceTypes, useTenantIcons } from "@hopper-b2b/utilities";
import { ReactNode, useCallback } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import clsx from "clsx";
import "./styles.scss";
import { Box, Typography } from "@material-ui/core";

export interface IShopHeader {
  className?: string;
  title?: string;
  handleBack?: () => void;
  header?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  stickyFooter?: ReactNode;
}

export const ShopPage = (props: IShopHeader) => {
  const {
    className,
    title,
    handleBack,
    header,
    children,
    footer,
    stickyFooter,
  } = props;

  const icons = useTenantIcons();
  const navigate = useNavigate();
  const { matchesMobile } = useDeviceTypes();

  const onBack = useCallback(() => {
    handleBack ? handleBack() : navigate(-1);
  }, [navigate, handleBack]);

  return (
    <Box
      className={clsx("lodging-shop-page", className, {
        mobile: matchesMobile,
      })}
    >
      {header ? (
        header
      ) : title ? (
        <Box className="lodging-shop-page-header">
          <ButtonWrap
            className="lodging-shop-page-header-back-button"
            onClick={onBack}
          >
            <img src={icons.chevronLeft} alt="Back button" />
          </ButtonWrap>
          <div className="title-wrapper">
            <Typography className="title-text">{title}</Typography>
          </div>
          <div className="right-button" />
        </Box>
      ) : null}
      <Box className="lodging-shop-page-content">{children}</Box>
      <Box className="lodging-shop-page-footer">{footer}</Box>
      <Box className="lodging-shop-page-sticky-footer">{stickyFooter}</Box>
    </Box>
  );
};
