import {
  AirCancelFulfillRequest,
  AirCancelFulfillResponse,
  airCancelApi,
} from "@b2bportal/air-cancel-api";
import { axiosInstance } from "@hopper-b2b/api";

export const cancelFulfill = async (
  quoteId
): Promise<AirCancelFulfillResponse> => {
  const body: AirCancelFulfillRequest = {
    quoteId,
  };

  return new Promise((resolve, reject) => {
    try {
      airCancelApi(axiosInstance)
        .apiV0SelfserveFlightCancelFulfillPost(body)
        .then((res) => resolve(res.data));
    } catch (e) {
      reject(e);
    }
  });
};
