import { CallState } from "@hopper-b2b/types";
import { DisruptionOffer } from "@b2bportal/air-disruption-api";
import { actions, actionTypes } from "../actions";

export interface IFlightDisruptionState {
  disruptionOffersCallState: CallState;
  disruptionOffers?: DisruptionOffer[];
  selectedAirDisruptionOffer?: DisruptionOffer;
}

export const initialState: IFlightDisruptionState = {
  disruptionOffersCallState: CallState.NotCalled,
  disruptionOffers: undefined,
};

export function reducer(
  state: IFlightDisruptionState = initialState,
  action: actions.FlightDisruptionActions
): IFlightDisruptionState {
  switch (action.type) {
    case actionTypes.FETCH_DISRUPTION_OFFERS:
      return {
        ...initialState,
        disruptionOffersCallState: CallState.InProcess,
      };

    case actionTypes.SET_DISRUPTION_OFFERS:
      return {
        ...state,
        disruptionOffers: action.offers,
        disruptionOffersCallState: CallState.Success,
        selectedAirDisruptionOffer: undefined,
      };

    case actionTypes.SET_DISRUPTION_OFFERS_FAILED:
      return {
        ...state,
        disruptionOffersCallState: CallState.Failed,
      };

    case actionTypes.SET_SELECTED_AIR_DISRUPTION_OFFER:
      return {
        ...state,
        selectedAirDisruptionOffer: action.offer,
      };

    default:
      return state;
  }
}

export * from "./selectors";
