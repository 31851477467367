// Docs: https://developers.google.com/maps/documentation/maps-static/start
const MAP_PREFIX = "https://maps.googleapis.com/maps/api/staticmap";

export const getGoogleStaticMapSrc = (
  coordinates: string,
  zoom: string,
  size: string,
  scale = "1",
  showMarker = false,
  markerColor = "red"
) => {
  const marker = showMarker
    ? `&markers=color:${markerColor}|${coordinates}`
    : "";
  return `${MAP_PREFIX}?center=${coordinates}&zoom=${zoom}&size=${size}&scale=${scale}${marker}&maptype=roadmap
  &key=${import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY}`;
};
