import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AirRestrictionStatus } from "@hopper-b2b/types";
import { Icon, IconName } from "@hopper-b2b/ui";
import { Box, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { IRestrictionProps } from "../../modules/shop/v3/components/FlightList/components/FareDetailsCard/FareDetailsCard";
import styles from "./Amenity.module.scss";

export type AmenityProps = {
  policy: IRestrictionProps;
  showDetail: boolean;
};

export const Amenity = ({ policy, showDetail }: AmenityProps) => {
  return (
    <Box my={2}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item className={clsx({ [styles["policy-icon"]]: showDetail })}>
          {restrictionIcon[policy.symbol]}
        </Grid>
        {showDetail ? (
          <Grid item xs>
            <Typography variant="h6">{policy.headline}</Typography>
            <Typography variant="body2">{policy.description}</Typography>
          </Grid>
        ) : (
          <Grid item xs>
            <Typography variant="body2">{policy.description}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const restrictionIcon: { [k in AirRestrictionStatus]: JSX.Element } = {
  [AirRestrictionStatus.INCLUDED]: (
    <FontAwesomeIcon
      className={clsx("icon", styles.green)}
      icon={faCheckCircle as IconProp}
    />
  ),
  [AirRestrictionStatus.PURCHASABLE]: (
    <Icon
      className={styles.icon}
      name={IconName.MoneyOutlineTransparentIcon}
      ariaLabel=""
      aria-hidden={true}
    />
  ),
  [AirRestrictionStatus.UNAVAILABLE]: (
    <Icon
      className={styles.icon}
      name={IconName.NotAllowedSign}
      ariaLabel=""
      aria-hidden={true}
    />
  ),
  [AirRestrictionStatus.GENERIC]: (
    <Icon
      className={styles.icon}
      name={IconName.Generic}
      ariaLabel=""
      aria-hidden={true}
    />
  ),
  [AirRestrictionStatus.UNKNOWN]: null,
};
