import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useI18nContext } from "@hopper-b2b/i18n";
import { MyTripsFilter } from "@hopper-b2b/types";
import { removeStyleSheetByMetaName } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { replaceTripQueryParams } from "../../../TripsList/actions/actions";
import { IFilterTabsConnectorProps } from "./container";
import "./styles.scss";

export interface IFilterTabsProps
  extends IFilterTabsConnectorProps,
    // eslint-disable-next-line @typescript-eslint/ban-types
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {
  mobile?: boolean;
}

export const FilterTabs = ({
  history,
  tripsFilter,
  tripsCounts,
  setTripsFilter,
  populateTripQueryParams,
  mobile = false,
}: IFilterTabsProps) => {
  const { t } = useI18nContext();
  const [currentTab, setCurrentTab] = useState(MyTripsFilter.UPCOMING_TRIPS);
  const [showRightTab, setShowRightTab] = useState(false);

  useEffect(() => {
    setTripsFilter(currentTab);
    replaceTripQueryParams(history);
  }, [currentTab, history, setTripsFilter, populateTripQueryParams]);

  // note: styleSheets with meta names starting with "Private" are being translated to css rules with the jss prefix on the production build;
  // they are loaded in whenever its respective components are mounted. (see https://hopper-jira.atlassian.net/wiki/spaces/ENGWEB/pages/2291728425/Observed+production-build+issues+caused+by+Material-UI+Private+classNames)
  useEffect(() => {
    removeStyleSheetByMetaName("PrivateTabIndicator");
  }, [tripsFilter]);

  return mobile ? (
    <Box className={clsx("filter-tabs", "apac", { mobile })}>
      {showRightTab && (
        <div className="arrow-button" onClick={() => setShowRightTab(false)}>
          <FontAwesomeIcon className="arrow-icon" icon={faChevronLeft} />
        </div>
      )}
      {!showRightTab && (
        <div
          className={clsx("tab-item", {
            selected: currentTab === MyTripsFilter.UPCOMING_TRIPS,
          })}
          onClick={() => setCurrentTab(MyTripsFilter.UPCOMING_TRIPS)}
        >
          <Typography className="tab-title" variant="body1">
            {t("upcomingTripsTitle")}
          </Typography>
        </div>
      )}
      <div
        className={clsx("tab-item", {
          selected: currentTab === MyTripsFilter.WATCHED_TRIPS,
        })}
        onClick={() => setCurrentTab(MyTripsFilter.WATCHED_TRIPS)}
      >
        <Typography className="tab-title" variant="body1">
          {t("watchedTripsTitle")}
        </Typography>
      </div>
      {showRightTab && (
        <div
          className={clsx("tab-item", {
            selected: currentTab === MyTripsFilter.PAST_TRIPS,
          })}
          onClick={() => setCurrentTab(MyTripsFilter.PAST_TRIPS)}
        >
          <Typography className="tab-title" variant="body1">
            {t("commBank.trips.pastTripsLabel")}
          </Typography>
        </div>
      )}
      {!showRightTab && (
        <div
          className="arrow-button right"
          onClick={() => setShowRightTab(true)}
        >
          <FontAwesomeIcon className="arrow-icon" icon={faChevronLeft} />
        </div>
      )}
    </Box>
  ) : (
    <Box className={clsx("filter-tabs", "apac", { mobile })}>
      <div
        className={clsx("tab-item", {
          selected: currentTab === MyTripsFilter.UPCOMING_TRIPS,
        })}
        onClick={() => setCurrentTab(MyTripsFilter.UPCOMING_TRIPS)}
      >
        <Typography className="tab-title" variant="body1">
          {t("upcomingTripsTitle")}
        </Typography>
      </div>
      <div
        className={clsx("tab-item", {
          selected: currentTab === MyTripsFilter.WATCHED_TRIPS,
        })}
        onClick={() => setCurrentTab(MyTripsFilter.WATCHED_TRIPS)}
      >
        <Typography className="tab-title" variant="body1">
          {t("watchedTripsTitle")}
        </Typography>
      </div>
      <div
        className={clsx("tab-item", {
          selected: currentTab === MyTripsFilter.PAST_TRIPS,
        })}
        onClick={() => setCurrentTab(MyTripsFilter.PAST_TRIPS)}
      >
        <Typography className="tab-title" variant="body1">
          {t("commBank.trips.pastTripsLabel")}
        </Typography>
      </div>
    </Box>
  );
};
