import { MultiTicketType } from "@b2bportal/air-booking-api";
import { TripCategory } from "@hopper-b2b/types";
import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { last } from "lodash-es";

import {
  getDepartureDate,
  getDestination,
  getOrigin,
  getReturnDate,
} from "../../search/reducer";
import { ExchangeTypeEventEnum } from "../types";
import {
  getChangeFee,
  getItinerary,
  getItineraryDepDate,
  getItineraryDest,
  getItineraryOrigin,
  getItineraryRetDate,
  getTravelItinerary,
} from "./itinerary";

export const getDatesChanged = createSelector(
  getDepartureDate,
  getReturnDate,
  getItineraryDepDate,
  getItineraryRetDate,
  (departureDate, returnDate, originalDep, originalRet): boolean => {
    const depDate = dayjs(departureDate);
    const retDate = dayjs(returnDate);
    const depDateChanged = departureDate
      ? !depDate.isSame(originalDep, "day")
      : false;
    const returnDateChanged =
      (originalRet && !returnDate) ||
      (!originalRet && returnDate) ||
      (returnDate && !retDate.isSame(originalRet, "day"));

    return !!(depDateChanged || returnDateChanged);
  }
);

export const getAirportsChanged = createSelector(
  getDestination,
  getOrigin,
  getItineraryOrigin,
  getItineraryDest,
  (dest, origin, originalOrigin, originalDest) => {
    const destCode = dest?.id?.code?.code;
    const originCode = origin?.id?.code?.code;
    const destChanged = destCode && destCode !== originalDest?.terminalCode;
    const originChanged =
      originCode && originCode !== originalOrigin?.terminalCode;

    return !!(destChanged || originChanged);
  }
);

export const getExchangeTypeEvent = createSelector(
  getDatesChanged,
  getAirportsChanged,
  (datesChanged, airportsChanged) => {
    if (datesChanged && airportsChanged)
      return ExchangeTypeEventEnum.AirportAndDates;

    if (datesChanged) return ExchangeTypeEventEnum.AirportOnly;

    if (airportsChanged) return ExchangeTypeEventEnum.DatesOnly;

    return ExchangeTypeEventEnum.Same;
  }
);

export const getNoResultsEventProps = createSelector(
  getExchangeTypeEvent,
  getItinerary,
  getTravelItinerary,
  getDepartureDate,
  getDestination,
  getOrigin,
  getReturnDate,
  (
    exchangeType,
    prevItinerary,
    travelItinerary,
    depDate,
    dest,
    origin,
    retDate
  ) => {
    const { locators, slices } = travelItinerary;
    const { segments: outboundSegments } = slices[0];
    const {
      marketingAirline,
      origin: itinOrigin,
      updatedDeparture,
    } = outboundSegments[0];
    const { destination: itinDest } = last(outboundSegments);
    const stops = outboundSegments.reduce((acc, seg) => acc + seg.stops, 0);
    const roundTripProps: Record<string, string | number> = {};

    if (slices.length > 1) {
      const { segments: returnSegments } = last(slices);
      const { updatedDeparture } = returnSegments[0];
      const stops = returnSegments.reduce((acc, seg) => acc + seg.stops, 0);

      roundTripProps.initial_return_date = updatedDeparture;
      roundTripProps.initial_return_stops = stops;
      roundTripProps.initial_trip_type = TripCategory.ROUND_TRIP;
      roundTripProps.return_segments = returnSegments.length;
      roundTripProps.searched_return_date = dayjs(retDate).toString();
      roundTripProps.searched_trip_type = TripCategory.ROUND_TRIP;
    }

    return {
      agent_locator: locators.agent.value,
      carrier: marketingAirline.code,
      exchange_shop: exchangeType,
      initial_departure_date: dayjs(updatedDeparture).toString(),
      initial_destination: itinDest.locationCode,
      initial_origin: itinOrigin.locationCode,
      initial_outbound_stops: stops,
      initial_trip_type: TripCategory.ONE_WAY,
      is_multi_ticket:
        prevItinerary.bookedItinerary.multiTicketType !==
        MultiTicketType.single,
      outbound_segments: outboundSegments.length,
      product: "flight",
      searched_departure_date: depDate,
      searched_destination: dest.id.code.code,
      searched_origin: origin.id.code.code,
      searched_trip_type: TripCategory.ONE_WAY,
      system_locator: locators.b2b,
      ...roundTripProps,
    };
  }
);

export const getBaseEventProps = createSelector(
  getChangeFee,
  getExchangeTypeEvent,
  (changeFee, exchangeType) => ({
    exchange_fee: changeFee.amount,
    exchange_type: exchangeType,
    url: window.location.pathname,
  })
);
