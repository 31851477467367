import { useCallback } from "react";
import dayjs from "dayjs";
import clsx from "clsx";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IPerson } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { getDateTimeWithFormat } from "@hopper-b2b/utilities";
import { IconComponent } from "../IconComponent";
import { IconName } from "../Icon";
import { Slot } from "../Slots";
import "./styles.scss";

export interface PassengerSummaryRowProps {
  passengers: IPerson[];
  iconSrc?: string;
  expandable?: boolean;
  review?: boolean;
  onEdit?: (passenger: IPerson) => void;
  hideIcon?: boolean;
  className?: string;
}

export const PassengerSummaryRow = ({
  passengers,
  iconSrc,
  expandable = false,
  review = false,
  onEdit,
  hideIcon,
  className,
}: PassengerSummaryRowProps) => {
  const { t } = useI18nContext();

  const getPassengerLabel = useCallback(
    (passenger: IPerson) => {
      const age = dayjs().diff(
        getDateTimeWithFormat(passenger.dateOfBirth, "YYYY-MM-DD"),
        "year"
      );
      if (age > 11) {
        return t("passengerInformation.adult");
      } else if (age >= 2) {
        return t("passengerInformation.child");
      } else {
        return t("passengerInformation.infant");
      }
    },
    [t]
  );

  const handleOnClick = useCallback(
    (passenger: IPerson) => () => {
      onEdit(passenger);
    },
    [onEdit]
  );

  return (
    <div
      className={clsx("passenger-summary-row", className)}
      data-dd-privacy="mask"
    >
      <div className="passenger-summary-row-left">
        {!hideIcon ? (
          <div className="passenger-summary-row-icon">
            {iconSrc ? (
              <img className="mobile-left-icon" src={iconSrc} alt="" />
            ) : (
              <IconComponent
                className="mobile-left-icon"
                name={IconName.Person}
              />
            )}
          </div>
        ) : null}
        <div className="passenger-summary-row-value">
          <p className="passenger-summary-row-title">
            {t("passengerInformationTitle")}
          </p>
          {review ? (
            <div className="passenger-summary-row-description">
              {t("passengerInformationSubtitle")}
            </div>
          ) : null}
          {passengers.map((passenger, index) => (
            <div
              className="passenger-summary-row-info-container"
              key={passenger.id}
            >
              <div className="passenger-summary-row-info">
                <div className="passenger-summary-row-info-content">
                  <p className="passenger-summary-row-type">
                    {getPassengerLabel(passenger)}
                  </p>
                  <p className="passenger-summary-row-name">
                    {`${passenger?.givenName} ${passenger?.surname}`}
                  </p>
                </div>
                {onEdit ? (
                  <div className="passenger-summary-row-edit-button-container">
                    <Slot
                      id="edit"
                      className="passenger-summary-row-edit"
                      onClick={handleOnClick(passenger)}
                    />
                  </div>
                ) : null}
              </div>
              {index !== passengers.length - 1 ? (
                <div className="passenger-divider" key={"divider_" + index} />
              ) : null}
            </div>
          ))}
        </div>
      </div>
      <div className="passenger-summary-row-right">
        {expandable ? (
          <FontAwesomeIcon
            className="mobile-right-chevron"
            icon={faChevronRight as IconProp}
          />
        ) : null}
      </div>
    </div>
  );
};

export default PassengerSummaryRow;
