import * as React from "react";

import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import "./styles.scss";
import { Box, InputAdornment, OutlinedInput } from "@material-ui/core";
import { removeStyleSheetByMetaName } from "@hopper-b2b/utilities";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

export interface IOutlinedTextInput {
  label?: string | React.ReactElement;
  value?: any;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  classes?: string[];
  required?: boolean;
  mask?: string;
  errorLabel?: string;
  icon?: IconProp;
  endIcon?: IconProp;
  customIcon?: JSX.Element;
  customEndIcon?: JSX.Element;
  onChange?: (value?: any) => void;
  onBlur?: (value?: any) => void;
  onFocus?: (value?: any) => void;
  onClick?: (event?: React.MouseEvent) => void;
  inputType?: string;
  role?: string;
  ariaLabel?: string;
  onKeyDown?: (event?: React.KeyboardEvent) => void;
  activePrefixString?: string;
  prefixString?: string;
  autoFocus?: boolean;
  showCloseIcon?: boolean;
  isMobile?: boolean;
}

export const OutlinedTextInput = ({
  onChange,
  onBlur,
  onFocus,
  onClick,
  disabled = false,
  fullWidth = true,
  value,
  classes,
  mask,
  errorLabel,
  label,
  placeholder,
  icon,
  endIcon,
  customIcon,
  customEndIcon,
  inputType,
  role,
  ariaLabel,
  onKeyDown,
  activePrefixString,
  prefixString,
  autoFocus,
  showCloseIcon = false,
  isMobile,
}: IOutlinedTextInput) => {
  if (!ariaLabel && typeof label === "string") {
    ariaLabel = label as string;
  }
  React.useEffect(() => {
    removeStyleSheetByMetaName("PrivateNotchedOutline");
  }, []);

  const getStartAdornment = () => {
    if (!icon || !customIcon || !prefixString) return undefined;
    return (
      <InputAdornment
        className={clsx("input-adornment-start")}
        position="start"
      >
        {(!!icon || !!customIcon) && (
          <Box className="adornment-icon-wrapper">
            {icon ? (
              <FontAwesomeIcon className="icon" icon={icon as IconProp} />
            ) : (
              customIcon
            )}
          </Box>
        )}
        {prefixString && (
          <Box
            className="prefix-string"
            dangerouslySetInnerHTML={{
              __html: (value ? activePrefixString : prefixString) || "",
            }}
          ></Box>
        )}
      </InputAdornment>
    );
  };

  const getEndAdornment = () => (
    <InputAdornment
      className={clsx("input-adornment-end")}
      position="end"
      onClick={() => {
        if (onChange) {
          onChange("");
        }
      }}
    >
      {value && showCloseIcon && (
        <Box>
          <FontAwesomeIcon className="icon" icon={faTimesCircle as IconProp} />
        </Box>
      )}
      {(!!endIcon || !!customEndIcon) && (
        <Box>
          {endIcon ? (
            <FontAwesomeIcon className="icon" icon={endIcon as IconProp} />
          ) : (
            customEndIcon
          )}
        </Box>
      )}
    </InputAdornment>
  );

  return (
    <Box
      className={clsx(
        "outlined-text-input-root",
        "outlined-text-input-component",
        isMobile && "mobile-outlined-text-input-root"
      )}
    >
      <FormControl
        className={clsx(
          "outlined-text-input",
          isMobile && "mobile-outlined-text-input",
          classes
        )}
      >
        <OutlinedInput
          role={role}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete="off"
          autoFocus={autoFocus}
          className="input-root"
          fullWidth={fullWidth}
          name={isMobile ? "mobile-outline-text-input" : "outlined-text-input"}
          aria-label={ariaLabel || "text-input"}
          label={label}
          onClick={onClick}
          onChange={(event: any) => {
            if (isMobile) {
              onChange && onChange(event.target.value);
            } else {
              onChange && onChange(event);
            }
          }}
          onBlur={onBlur}
          onFocus={(val) => {
            if (onFocus) {
              onFocus(val);
            }
          }}
          onKeyDown={onKeyDown}
          value={isMobile ? value : mask ? mask : value}
          type={inputType || "text"}
          startAdornment={getStartAdornment()}
          endAdornment={getEndAdornment()}
        />
      </FormControl>
      {typeof errorLabel !== "undefined" && (
        <Box className="error-label-container">
          <span className="error-label">{errorLabel}</span>
        </Box>
      )}
    </Box>
  );
};
