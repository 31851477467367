import { default as dayjs, Dayjs } from "dayjs";

export const URL_DATE_FORMAT = "YYYY-MM-DD";

export const toDate = (dateString?: string | Dayjs | Date): Dayjs =>
  dayjs(dateString);

export const formatDateForUrl = (
  date?: string | Dayjs | Date
): string | undefined =>
  date ? toDate(date).format(URL_DATE_FORMAT) : undefined;
