import { IStoreState } from "../../../../reducers/types";
import { put, select } from "redux-saga/effects";

import { fetchSeatMap } from "../../../../api/v1/book/book-flow/fetchSeatMap";
import Logger from "../../../../helpers/Logger";
import {
  IFetchSeatMap,
  setSeatMap,
  fetchSeatMapFailed,
  setCheapestSeat,
} from "../../actions/actions";
import { flightApiConfigStoreKey } from "../../../../reducers/types";
import { GetSeatMapResponse } from "@b2bportal/air-seats-api";

export function* fetchSeatMapSaga(action: IFetchSeatMap) {
  const { callback } = action;
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const seatMapResponse: GetSeatMapResponse = yield fetchSeatMap(
      {
        callback,
      },
      apiConfig
    );
    yield put(setSeatMap(seatMapResponse.html));
    yield put(setCheapestSeat(seatMapResponse.cheapestSeat));
  } catch (e) {
    Logger.debug(e);
    yield put(fetchSeatMapFailed());
  }
}
