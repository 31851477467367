import { ShopFilter } from "@hopper-b2b/types";

export const NONSTOP = "Nonstop";
export const NO_BASIC_FARES = "Exclude basic fares";
export const NONSTOP_NO_BASIC = "Nonstop, Exclude basic fares";
export const DATE_FORMAT = "ddd DD MMM";

export const getFilterLabel = (shopFilter: ShopFilter) => {
  switch (shopFilter) {
    case ShopFilter.NonStop:
      return NONSTOP;
    case ShopFilter.NonStopNoLCC:
      return NONSTOP_NO_BASIC;
    case ShopFilter.NoLCC:
      return NO_BASIC_FARES;
    default:
      return "";
  }
};
