import { useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import dayjs from "dayjs";
import { FiatPrice } from "@b2bportal/air-shopping-api";
import {
  ActionButton,
  B2BLoadingPopup,
  ErrorPopup,
  IconComponent,
  IconName,
  MobileFloatingButton,
} from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  DisruptionContractStatus,
  DisruptionExerciseProductType,
  DisruptionExerciseProgress,
  DisruptionProtectionType,
  DisruptionRefundPlanRequest,
  DisruptionRefundPlanResponse,
} from "@hopper-b2b/types";
import {
  getAirlineLocatorsFromBookedFlightItinerary,
  getHopperLocatorFromBookedFlightItinerary,
  toggleAdaChat,
  removeTimezone,
  useEnableDisruptionExerciseRebooking,
  getEnvVariables,
  useEnableDisruptionProtection,
} from "@hopper-b2b/utilities";

import { ClientContext } from "../../../../../App";
import {
  getDisruptionExerciseProductType,
  getSelectedFlight,
} from "../../../../TripsList/reducer";
import {
  populateTripQueryParams,
  resetDisruptionExerciseState,
  setDisruptionExerciseProgress,
} from "../../../../TripsList/actions/actions";
import disruptionRefundPlan from "../../../../../api/v1/disruption/disruptionRefundPlan";
import { useHistory } from "react-router";
import {
  PATH_DISRUPTION_EXERCISE,
  PATH_DISRUPTION_EXERCISE_REFUND,
} from "../../../../../utils/paths";
import { Box } from "@material-ui/core";
import { DisruptionNotEligible } from "../../../../TripsList/components/DisruptionNotEligible";

const enum DisruptionPlanError {
  Ineligible = "ineligible",
  Failure = "failure",
  AlreadyExercised = "AlreadyExercised",
  AlreadyExercisedDelay = "AlreadyExercisedDelay",
  AlreadyExercisedMissedConnection = "AlreadyExercisedMissedConnection",
}

export interface ILandingPageProps {
  contractId: string;
  airlineCodes: Array<string>;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setReimbursementAmount: (amount: FiatPrice) => void;
  disableFloatingButton?: boolean;
}

export const LandingPage = ({
  contractId,
  airlineCodes,
  loading,
  setLoading,
  setReimbursementAmount,
  disableFloatingButton = false,
}: ILandingPageProps) => {
  const clientContext = useContext(ClientContext);
  const { t, brand } = useI18nContext();
  const dispatch = useDispatch();
  const history = useHistory();

  const [rebookSelected, setRebookSelected] = useState<boolean>(true);
  const [refundPlanError, setRefundPlanError] = useState<string | null>(null);

  const isUber = getEnvVariables("clientName") === "uber";
  const isHopper = getEnvVariables("clientName") === "hopper";

  const disruptionProductType = useSelector(getDisruptionExerciseProductType);
  const selectedFlight = useSelector(getSelectedFlight);

  const disruptionProductName =
    disruptionProductType === DisruptionExerciseProductType.ScheduleChange
      ? DisruptionProtectionType.Delay
      : DisruptionProtectionType.MissedConnection;
  const disruptionProductStatus =
    selectedFlight?.ancillaries[disruptionProductName]?.status
      ?.DisruptionContractStatus;

  // hopper-web disruption feature flag
  const isDisruptionProtectionEnabled = useEnableDisruptionProtection();

  // uber disruption feature flag
  const disruptionRebookingEnabled = useEnableDisruptionExerciseRebooking();

  const isEnableDisruptionExerciseRebooking = isUber
    ? disruptionRebookingEnabled
    : isDisruptionProtectionEnabled;

  const refundIneligible = useCallback(
    (failure?: DisruptionPlanError) => {
      setLoading(false);
      setRefundPlanError(failure);
    },
    [setLoading]
  );

  // True if another purchased product has already been redeemed via self-serve refund or rebook
  const otherDisruptionProductAlreadyExercised = useCallback(() => {
    let otherDisruptionProductExercised = false;
    if (
      selectedFlight.ancillaries.delay &&
      selectedFlight.ancillaries.missedConnection
    ) {
      const otherDisruptionProduct =
        disruptionProductType === DisruptionExerciseProductType.ScheduleChange
          ? DisruptionProtectionType.MissedConnection
          : DisruptionProtectionType.Delay;

      const otherDisruptionProductStatus =
        selectedFlight?.ancillaries[otherDisruptionProduct].status
          .DisruptionContractStatus;

      otherDisruptionProductExercised =
        otherDisruptionProductStatus === DisruptionContractStatus.REDEEM ||
        otherDisruptionProductStatus ===
          DisruptionContractStatus.ALREADY_EXERCISED;
    }
    return otherDisruptionProductExercised;
  }, [disruptionProductType, selectedFlight]);

  const fetchDisruptionRefundPlan = useCallback(() => {
    if (contractId) {
      const req: DisruptionRefundPlanRequest = {
        contractId,
      };

      // TODO: Update typing of req
      return disruptionRefundPlan(req)
        .then((response: DisruptionRefundPlanResponse) => {
          setLoading(false);
          if (response.isEligible) {
            if (!otherDisruptionProductAlreadyExercised()) {
              setReimbursementAmount(response.reimbursementAmount?.fiat);
              if (isUber) {
                dispatch(
                  setDisruptionExerciseProgress(
                    DisruptionExerciseProgress.RefundReview
                  )
                );
                const newHistory = dispatch(
                  populateTripQueryParams(history)
                ).history;
                history.replace(
                  PATH_DISRUPTION_EXERCISE_REFUND + newHistory.location.search
                );
              } else {
                dispatch(populateTripQueryParams(history));
                dispatch(
                  setDisruptionExerciseProgress(
                    DisruptionExerciseProgress.RefundReview
                  )
                );
              }
            } else {
              refundIneligible(DisruptionPlanError.AlreadyExercised);
            }
          } else {
            // TODO: Investigate type errors
            refundIneligible(response.reason.DisruptionFailure);
          }
        })
        .catch(() => {
          refundIneligible();
        });
    }

    return;
  }, [
    contractId,
    dispatch,
    history,
    isUber,
    otherDisruptionProductAlreadyExercised,
    refundIneligible,
    setLoading,
    setReimbursementAmount,
  ]);

  const delayOrMissedConnectionAnswer = useMemo(
    () =>
      brand?.adaChat
        ? brand?.adaChat.answerIds.delayOrMissedConnection
        : undefined,
    [brand]
  );

  const openSupportChat = useCallback(() => {
    const airlineLocators =
      getAirlineLocatorsFromBookedFlightItinerary(selectedFlight);
    const hopperLocator =
      getHopperLocatorFromBookedFlightItinerary(selectedFlight);
    const formattedDepartureDate = dayjs(
      removeTimezone(selectedFlight?.departureTime)
    ).format("MMM D, YYYY h:mm A");
    toggleAdaChat(
      {
        departure_date: formattedDepartureDate,
        pnr_locator: hopperLocator,
        airline_locator: airlineLocators,
      },
      delayOrMissedConnectionAnswer ? delayOrMissedConnectionAnswer : null
    );
    dispatch(resetDisruptionExerciseState());
  }, [delayOrMissedConnectionAnswer, dispatch, selectedFlight]);

  const onContinue = useCallback(() => {
    const isRebookOnly =
      disruptionProductStatus === DisruptionContractStatus.REBOOK_ONLY_ELIGIBLE;

    if (rebookSelected) {
      if (isEnableDisruptionExerciseRebooking) {
        const canRedeem =
          disruptionProductStatus === DisruptionContractStatus.ELIGIBLE ||
          disruptionProductStatus === DisruptionContractStatus.REDEEM ||
          disruptionProductStatus ===
            DisruptionContractStatus.ANY_EXERCISE_ELIGIBLE ||
          disruptionProductStatus ===
            DisruptionContractStatus.REBOOK_ONLY_ELIGIBLE;

        const otherProductExercised = otherDisruptionProductAlreadyExercised()
          ? disruptionProductType ===
            DisruptionExerciseProductType.ScheduleChange
            ? DisruptionProtectionType.MissedConnection
            : DisruptionProtectionType.Delay
          : null;

        if (otherProductExercised) {
          refundIneligible(
            otherProductExercised === DisruptionProtectionType.MissedConnection
              ? DisruptionPlanError.AlreadyExercisedMissedConnection
              : DisruptionPlanError.AlreadyExercisedDelay
          );
          return;
        }

        if (canRedeem) {
          dispatch(
            setDisruptionExerciseProgress(
              DisruptionExerciseProgress.FlightSelect
            )
          );
          if (isUber) {
            const newHistory = dispatch(
              populateTripQueryParams(history)
            ).history;
            history.replace(
              PATH_DISRUPTION_EXERCISE + newHistory.location.search
            );
          } else {
            dispatch(populateTripQueryParams(history));
          }
        } else {
          const error =
            disruptionProductStatus === DisruptionContractStatus.PURCHASE ||
            disruptionProductStatus ===
              DisruptionContractStatus.EXERCISE_NOT_ELIGIBLE
              ? DisruptionPlanError.Ineligible
              : DisruptionPlanError.AlreadyExercised;
          refundIneligible(error);
        }
      } else {
        openSupportChat();
      }
    } else {
      if (isRebookOnly) {
        refundIneligible(DisruptionPlanError.Ineligible);
        return;
      }
      setLoading(true);
      fetchDisruptionRefundPlan();
    }
  }, [
    disruptionProductStatus,
    rebookSelected,
    isEnableDisruptionExerciseRebooking,
    otherDisruptionProductAlreadyExercised,
    disruptionProductType,
    refundIneligible,
    dispatch,
    isUber,
    history,
    openSupportChat,
    setLoading,
    fetchDisruptionRefundPlan,
  ]);

  const jetBlue = useMemo(
    () => airlineCodes.every((airline) => airline === "B6"),
    [airlineCodes]
  );

  const subtitle = useMemo(
    () =>
      disruptionProductType === DisruptionExerciseProductType.ScheduleChange
        ? jetBlue
          ? t("disruptionExercise.airlineAgreementDelaySubtitle", {
              airline: "Jetblue",
            })
          : t("disruptionExercise.delaySubtitle")
        : jetBlue
        ? t("disruptionExercise.airlineAgreementMissedConnectionSubtitle", {
            airline: "Jetblue",
          })
        : t("disruptionExercise.missedConnectionSubtitle"),
    [disruptionProductType, jetBlue, t]
  );

  const errorModalPrimaryOnClick = useCallback(() => {
    dispatch(resetDisruptionExerciseState());
  }, [dispatch]);

  const errorModalSecondaryOnClick = useCallback(() => {
    openSupportChat();
    dispatch(resetDisruptionExerciseState());
  }, [openSupportChat, dispatch]);

  const errorModalTitle = useMemo(() => {
    switch (refundPlanError) {
      case DisruptionPlanError.Ineligible:
        return t("disruptionExercise.error.ineligibleTitle");
      case DisruptionPlanError.AlreadyExercised:
        return t("disruptionExercise.error.alreadyExercisedTitle");
      case DisruptionPlanError.AlreadyExercisedDelay:
        return t("disruptionExercise.error.alreadyExercisedTitle");
      case DisruptionPlanError.AlreadyExercisedMissedConnection:
        return t("disruptionExercise.error.alreadyExercisedTitle");
      default:
        return t("disruptionExercise.error.title");
    }
  }, [refundPlanError, t]);

  const errorModalSubtitle = useMemo(() => {
    switch (refundPlanError) {
      case DisruptionPlanError.Ineligible:
        return t("disruptionExercise.error.ineligibleSubtitle");
      case DisruptionPlanError.AlreadyExercised:
        return t("disruptionExercise.error.alreadyExercisedSubtitle");
      case DisruptionPlanError.AlreadyExercisedDelay:
        return t("disruptionExercise.error.alreadyExercisedDelaySubtitle");
      case DisruptionPlanError.AlreadyExercisedMissedConnection:
        return t(
          "disruptionExercise.error.alreadyExercisedMissedConnectionSubtitle"
        );
      default:
        return t("disruptionExercise.error.subtitle");
    }
  }, [refundPlanError, t]);

  if (
    isHopper &&
    disruptionProductStatus === DisruptionContractStatus.EXERCISE_NOT_ELIGIBLE
  ) {
    return <DisruptionNotEligible />;
  }

  return (
    <div className="disruption-exercise-landing-page">
      <B2BLoadingPopup
        open={loading}
        fullScreen={true}
        message={t("disruptionExercise.loadingReview")}
        popupSize="mobile"
      />
      <div className="disruption-exercise-header">
        <h2 className="disruption-exercise-title">
          {disruptionProductType ===
          DisruptionExerciseProductType.ScheduleChange
            ? t("disruptionExercise.delayTitle")
            : t("disruptionExercise.missedConnectionTitle")}
        </h2>
        <p className="disruption-exercise-subtitle">{subtitle}</p>
      </div>
      <div className="disruption-exercise-options">
        <button
          className={clsx("disruption-exercise-option", {
            selected: rebookSelected,
          })}
          onClick={() => setRebookSelected(true)}
        >
          <div className="disruption-exercise-option-icon-container">
            {clientContext?.assets?.airplaneDepart ? (
              <img
                src={clientContext?.assets?.airplaneDepart}
                className="disruption-exercise-option-icon"
                alt="Airplane icon"
              />
            ) : (
              <IconComponent
                className="disruption-exercise-option-icon"
                name={IconName.B2BAirplaneIcon}
              />
            )}
          </div>
          <div className="disruption-exercise-option-content">
            <p className="disruption-exercise-option-title">
              {t("disruptionExercise.rebookTitle")}
            </p>
            <p className="disruption-exercise-option-subtitle">
              {t("disruptionExercise.rebookSubtitle")}
            </p>
          </div>
        </button>
        <button
          className={clsx("disruption-exercise-option", {
            selected: !rebookSelected,
          })}
          onClick={() => setRebookSelected(false)}
        >
          <div className="disruption-exercise-option-icon-container">
            <IconComponent
              className="disruption-exercise-option-icon"
              name={IconName.Refresh}
            />
          </div>
          <div className="disruption-exercise-option-content">
            <p className="disruption-exercise-option-title">
              {t("disruptionExercise.refundTitle")}
            </p>
            <p className="disruption-exercise-option-subtitle">
              {t("disruptionExercise.refundSubtitle")}
            </p>
          </div>
        </button>
      </div>
      {disableFloatingButton ? (
        <Box pb={2} style={{ display: "flex", justifyContent: "center" }}>
          <ActionButton
            className="cfar-cancel-flight-cta"
            message={t("continue")}
            onClick={onContinue}
          />
        </Box>
      ) : (
        <MobileFloatingButton
          className="disruption-exercise-landing-page-button"
          onClick={onContinue}
          wrapperClassName="disruption-exercise-landing-page-button-wrapper"
          wide
        >
          {t("continue")}
        </MobileFloatingButton>
      )}

      <ErrorPopup
        reverse
        open={refundPlanError ? true : false}
        title={errorModalTitle}
        subtitle={errorModalSubtitle}
        primaryButtonText={t("disruptionExercise.error.primaryButton")}
        primaryOnClick={errorModalPrimaryOnClick}
        secondaryButtonText={t("disruptionExercise.error.secondaryButton")}
        secondaryOnClick={errorModalSecondaryOnClick}
      />
    </div>
  );
};
