import { WatchAlertView } from "@b2bportal/air-price-watch-api";
import { trackEvent } from "@hopper-b2b/api";
import {
  FlightAlertKey,
  ItineraryEnum,
  ItineraryWithType,
  MyTripsModalTypes,
  SELECT_WATCHED_FLIGHT,
  ShopFilter,
  SliceStopCountFilter,
  TripCategory,
  getWatchDetails,
} from "@hopper-b2b/types";
import {
  ActionButton,
  ActionLinks,
  MyTripsCard,
  MyTripsMobileCard,
  TripInfoDetails,
} from "@hopper-b2b/ui";
import { PATH_FLIGHTS_SHOP } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import queryStringParser from "query-string";
import { Fragment } from "react";
import { RouteComponentProps } from "react-router";
import { PriceDropProtectionLink } from "./PriceDropProtectionLink";
import { WatchItineraryModal } from "./WatchItineraryModal";
import * as textConstants from "./constants";
import { WatchListConnectorProps } from "./container";
import "./styles.scss";

export interface IWatchListProps
  extends WatchListConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
}

export const addWatchType = (watch: WatchAlertView): ItineraryWithType => ({
  ...watch,
  type: ItineraryEnum.Watch,
});

export function WatchList({
  isMobile,
  watches,
  context,
  history,
  setOpenModal,
}: IWatchListProps) {
  // TO DO implement change e-mail modal

  const redirectToShop = (key: FlightAlertKey) => {
    let stopsOption = SliceStopCountFilter.ANY_NUMBER;
    let noLCC = false;
    switch (key.filter) {
      case ShopFilter.NoLCC:
        noLCC = true;
        break;
      case ShopFilter.NonStop:
        stopsOption = SliceStopCountFilter.NONE;
        break;
      case ShopFilter.NonStopNoLCC:
        stopsOption = SliceStopCountFilter.NONE;
        noLCC = true;
        break;
      default:
        break;
    }
    const newQuery = {
      tripCategory: key.returnDate
        ? TripCategory.ROUND_TRIP
        : TripCategory.ONE_WAY,
      origin: key.origin.code,
      destination: key.destination.code,
      departureDate: key.departureDate,
      returnDate: key.returnDate,
      isFromFlightWatch: true,
      stopsOption,
      noLCC,
    };
    history.push({
      pathname: PATH_FLIGHTS_SHOP,
      search: queryStringParser.stringify(newQuery),
    });
  };

  const onEmailChangeClick = (watch: WatchAlertView) => {
    setOpenModal({
      type: MyTripsModalTypes.ChangeEmail,
      selectedItinerary: addWatchType(watch),
    });
  };

  const onStopWatchingClick = (watch: WatchAlertView) => {
    setOpenModal({
      type: MyTripsModalTypes.StopWatching,
      selectedItinerary: addWatchType(watch),
    });
  };

  const getActionsForWatch = (watch: WatchAlertView) => [
    { content: "Change E-mail", onClick: () => onEmailChangeClick(watch) },
    {
      content: "Stop watching this route",
      onClick: () => onStopWatchingClick(watch),
    },
  ];

  return (
    <Box className="watch-list">
      {watches.map((watch: WatchAlertView, index: number) => {
        const watchDetails = getWatchDetails(
          watch,
          context,
          (date: string) => dayjs(date).format(textConstants.DATE_FORMAT),
          textConstants.getFilterLabel,
          textConstants.BOOK_NOW,
          textConstants.WAIT
        );
        return isMobile ? (
          <MyTripsMobileCard
            key={index}
            confirmationLabel={textConstants.CONFIRMATION}
            startLabel={`${textConstants.DEPARTS_ON}:`}
            label={textConstants.FLIGHT_WATCH}
            onClick={() => redirectToShop(watch.key.value)}
            {...watchDetails}
            actions={
              <>
                <PriceDropProtectionLink
                  priceDropProtection={watch.priceDropProtection}
                  isMobile={true}
                />
                <ActionLinks actions={getActionsForWatch(watch)} />
              </>
            }
          />
        ) : (
          <Fragment key={index}>
            <Typography variant="body1" className="summary-label">
              {`${textConstants.WATCH_HEADER} ${dayjs(
                watch.key.value.departureDate
              ).format("MMM D, YYYY")}`}
            </Typography>
            <MyTripsCard
              content={
                <TripInfoDetails
                  titles={{
                    confirmationLabel: textConstants.CONFIRMATION,
                    startLabel: `${textConstants.DEPARTURE}:`,
                    endLabel: `${textConstants.RETURN}:`,
                    ...watchDetails,
                  }}
                />
              }
              actions={<ActionLinks actions={getActionsForWatch(watch)} />}
              cta={
                <Box className="ctas-section">
                  <PriceDropProtectionLink
                    priceDropProtection={watch.priceDropProtection}
                    isMobile={false}
                  />
                  <ActionButton
                    message={textConstants.VIEW_FLIGHTS}
                    defaultStyle="h4r-primary"
                    ariaLabelText={`${textConstants.VIEW_FLIGHTS} for ${watchDetails.title}`}
                    onClick={() => {
                      trackEvent({
                        eventName: SELECT_WATCHED_FLIGHT,
                        properties: {},
                      });
                      redirectToShop(watch.key.value);
                    }}
                  />
                </Box>
              }
            />
          </Fragment>
        );
      })}
      <WatchItineraryModal isMobile={isMobile} />
    </Box>
  );
}
