import { FC, useCallback, useMemo } from "react";
import { Box, Chip, Button } from "@material-ui/core";
import { SliceStopCountFilter } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { AppliedFilterTagsProps } from "./container";
import "./styles.scss";

const AppliedFilterTag: FC<{
  filter: string;
  onDelete: (filter: string) => void;
}> = ({ filter, onDelete }) => {
  const { t } = useI18nContext();
  const handleDelete = useCallback(() => {
    onDelete(filter);
  }, [filter, onDelete]);

  const label = useMemo(() => {
    if (filter === "return" || filter === "departure") {
      return t(filter);
    }
    return t(`searchFilter.${filter}`);
  }, [filter, t]);

  const onKeyDownHandler = useCallback(
    (e) => {
      if (e.key === "Enter") {
        onDelete(filter);
      }
    },
    [filter, onDelete]
  );

  return (
    <Chip
      className={"applied-filter-chip"}
      label={label}
      aria-label={`Remove ${filter}`}
      onKeyDown={onKeyDownHandler}
      onDelete={handleDelete}
      color="secondary"
    />
  );
};

export const AppliedFilterTags = (props: AppliedFilterTagsProps) => {
  const { filters, resets, setRerunPrediction, appliedStopsFilter } = props;
  const appliedFilters = useMemo(
    () => Object.keys(filters).filter((key) => filters[key]),
    [filters]
  );

  const handleDelete = useCallback(
    (key: string) => {
      resets.reset[key]();
      if (key === "stops" && appliedStopsFilter === SliceStopCountFilter.NONE) {
        setRerunPrediction();
      }
    },
    [appliedStopsFilter, resets.reset, setRerunPrediction]
  );

  const onKeyDownHandler = useCallback(
    (e) => {
      if (e.key === "Enter") {
        if (appliedFilters.includes("stops")) {
          setRerunPrediction();
        }
        resets.resetAll();
      }
    },
    [appliedFilters, resets, setRerunPrediction]
  );
  const onResetFilters = useCallback(() => {
    if (appliedFilters.includes("stops")) {
      setRerunPrediction();
    }
    resets.resetAll();
  }, [appliedFilters, resets, setRerunPrediction]);

  const { t } = useI18nContext();

  if (appliedFilters.length <= 0) return null;
  return (
    <Box className={"applied-filter-tags"}>
      {appliedFilters.map((filter) => (
        <AppliedFilterTag
          key={filter}
          filter={filter}
          onDelete={handleDelete}
        />
      ))}
      {appliedFilters.length > 0 && (
        <Button onKeyDown={onKeyDownHandler} onClick={onResetFilters}>
          {t("resetFilters")}
        </Button>
      )}
    </Box>
  );
};
