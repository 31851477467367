import { I18nMarkup } from "@hopper-b2b/i18n";
import { Box } from "@material-ui/core";
import { TripAdvisorBadge } from "@hopper-b2b/ui";
import styles from "./TripAdvisorReviewsBadge.module.scss";

interface Props {
  overAllScore: number;
  reviewsCount: number;
}

export function TripAdvisorReviewsBadge({ overAllScore, reviewsCount }: Props) {
  const scoreOutOfFive = overAllScore * 5;
  return (
    <Box className={styles.container}>
      <TripAdvisorBadge
        translateToTheLeft
        withTripAdvisorIcon
        scoreOutOfFive={scoreOutOfFive}
      />
      {reviewsCount > 1 ? (
        <I18nMarkup
          tKey="tripAdvisorReviewCountNoParentheses_other"
          replacements={{
            reviewsCount,
          }}
        />
      ) : null}
    </Box>
  );
}
