import {
  Available,
  GetAirCfarOfferResponse,
  GetAirCfarOfferResponseEnum,
} from "@b2bportal/air-cfar-api";
import { fetchCfarOffers, trackEvent } from "@hopper-b2b/api";
import { AirOrchestratorCfarTrackingEvents } from "@hopper-b2b/types";
import { put, select } from "redux-saga/effects";
import { IStoreState, flightApiConfigStoreKey } from "../../../reducers/types";
import { actions } from "../actions";

export function* fetchCfarOffersSaga(action: actions.IFetchCfarOffers) {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];

    const response: GetAirCfarOfferResponse = yield fetchCfarOffers(
      {
        fareId: action.fareId,
        tripId: action.tripId,
      },
      apiConfig
    );

    if (
      response.GetAirCfarOfferResponse === GetAirCfarOfferResponseEnum.Available
    ) {
      const { offers, trackingProperties } = response as Available;

      yield put(
        actions.setCfarOffers({
          trackingProperties: trackingProperties,
          offers: offers,
        })
      );
    } else {
      yield put(actions.setCfarOffersFailed());
      console.warn("CFAR offers not available", response);

      const { trackingProperties } = response as any;
      const { encryptedProperties = [] } = trackingProperties;
      const updatedEncryptedProperties = Array.isArray(encryptedProperties)
        ? encryptedProperties
        : [encryptedProperties];

      yield trackEvent({
        ...trackingProperties,
        eventName: AirOrchestratorCfarTrackingEvents.SKIP_CFAR,
        encryptedProperties: updatedEncryptedProperties,
      });
    }
  } catch (error) {
    yield put(actions.setCfarOffersFailed());
    console.error("Error fetching CFAR offers", error);
  }
}
