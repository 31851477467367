import * as React from "react";
import Box from "@material-ui/core/Box";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import "./styles.scss";

export interface ITextInputProps {
  headerLabel: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  disabled?: boolean;
  fullWidth?: boolean;
  disableUnderline?: boolean;
  errorLabel?: string;
  maxLength?: number;
  minLength?: number;
  max?: number;
  min?: number;
  pattern?: string;
  placeholder?: string;
  className?: string;
}

const TextInput = ({
  headerLabel,
  value,
  onChange,
  maxLength,
  minLength,
  max,
  min,
  pattern,
  disabled,
  fullWidth,
  disableUnderline,
  errorLabel,
  placeholder,
  className,
}: ITextInputProps) => {
  const inputProps = {
    className: "input-box",
    maxLength,
    minLength,
    max,
    min,
    pattern,
  };

  return (
    <Box className="text-input-root">
      <Box className="header-label">{headerLabel}</Box>
      <TextField
        placeholder={placeholder}
        className={clsx("input-box-wrapper", className)}
        inputProps={inputProps}
        InputProps={{ disableUnderline: disableUnderline }}
        onChange={onChange}
        value={value}
        disabled={disabled}
        fullWidth={fullWidth}
      />
      {errorLabel && (
        <Box>
          <span className="error-label">{errorLabel}</span>
        </Box>
      )}
    </Box>
  );
};

export default TextInput;
