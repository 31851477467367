import * as H from "history";
import queryStringParser from "query-string";
import { select } from "redux-saga/effects";

import { IStoreState } from "../../../reducers/types";
import { PATH_HOME } from "../../../utils/paths";
import {
  IPopulateTripQueryParams,
  IReplaceTripQueryParams,
} from "../actions/actions";
import {
  getCfarExerciseProgress,
  getChfarExerciseProgress,
  getDisruptionExerciseProgress,
  getTripId,
  getTripsFilter,
} from "../reducer";

export interface ITripParsedQuery {
  cfarExerciseProgress: string;
  chfarExerciseProgress: string;
  contactSupport: boolean;
  disruptionExerciseProgress: string;
  tripId: string;
  tripsFilter: string;
}

export function* populateTripQueryParamsSaga({
  history,
  queryParams,
}: IPopulateTripQueryParams) {
  const state: IStoreState = yield select();
  populateTripQueryParametersFromState(state, history, queryParams);
}

export function* replaceTripQueryParamsSaga({
  history,
  queryParams,
}: IReplaceTripQueryParams) {
  const state: IStoreState = yield select();
  populateTripQueryParametersFromState(state, history, queryParams, true);
}

export const populateTripQueryParametersFromState = (
  state: IStoreState,
  history: H.History,
  queryParams?: {
    tripId?: string;
    tripsFilter?: string;
    cfarExerciseProgress?: string;
    chfarExerciseProgress?: string;
    disruptionExerciseProgress?: string;
  },
  replace = false
) => {
  const queryParamsTripId = queryParams?.tripId;
  const queryParamsTripsFilter = queryParams?.tripsFilter;
  const queryParamsCfarExerciseProgress = queryParams?.cfarExerciseProgress;
  const queryParamsChfarExerciseProgress = queryParams?.chfarExerciseProgress;
  const queryParamsDisruptionExerciseProgress =
    queryParams?.disruptionExerciseProgress;

  const {
    tripId,
    tripsFilter,
    cfarExerciseProgress,
    chfarExerciseProgress,
    disruptionExerciseProgress,
  } = getExistingStateVariables(state);
  const queryString = parseQueryString(history);

  if (stateDiffersFromQueryParams(state, queryString)) {
    const newQuery = {};
    if (queryParamsTripId || tripId) {
      newQuery["tripId"] = queryParamsTripId ?? tripId;
    }
    if (queryParamsTripsFilter || tripsFilter) {
      newQuery["tripsFilter"] = queryParamsTripsFilter ?? tripsFilter;
    }
    if (queryParamsCfarExerciseProgress || cfarExerciseProgress) {
      newQuery["cfarExerciseProgress"] =
        queryParamsCfarExerciseProgress ?? cfarExerciseProgress;
    }
    if (queryParamsChfarExerciseProgress || chfarExerciseProgress) {
      newQuery["chfarExerciseProgress"] =
        queryParamsChfarExerciseProgress ?? chfarExerciseProgress;
    }
    if (queryParamsDisruptionExerciseProgress || disruptionExerciseProgress) {
      newQuery["disruptionExerciseProgress"] =
        queryParamsDisruptionExerciseProgress ?? disruptionExerciseProgress;
    }

    const location = {
      pathname: PATH_HOME,
      search: queryStringParser.stringify(newQuery),
    };

    if (replace) {
      history.replace(location);
    } else {
      history.push(location);
    }
  }

  return queryString;
};

export const parseQueryString = (history: H.History): ITripParsedQuery =>
  parseQueryStringFromString(history?.location?.search || "");

export const parseQueryStringFromString = (
  queryString: string
): ITripParsedQuery => {
  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);
  return {
    tripsFilter: parsedQueryStringPrimitive.tripsFilter as unknown as string,
    tripId: parsedQueryStringPrimitive.tripId as string,
    cfarExerciseProgress:
      parsedQueryStringPrimitive.cfarExerciseProgress as string,
    chfarExerciseProgress:
      parsedQueryStringPrimitive.chfarExerciseProgress as string,
    disruptionExerciseProgress:
      parsedQueryStringPrimitive.disruptionExerciseProgress as string,

    // Specifically checking for `undefined` here because in
    // the URL, `...&contactSupport&...` gets parsed as `contactSupport: null`
    // and that's cleaner than `&contactSupport=true`
    contactSupport:
      parsedQueryStringPrimitive.contactSupport === undefined ? false : true,
  };
};

export function getExistingStateVariables(state: IStoreState) {
  return {
    tripsFilter: getTripsFilter(state),
    tripId: getTripId(state),
    cfarExerciseProgress: getCfarExerciseProgress(state),
    chfarExerciseProgress: getChfarExerciseProgress(state),
    disruptionExerciseProgress: getDisruptionExerciseProgress(state),
  };
}

export function stateDiffersFromQueryParams(
  state: IStoreState,
  parsedQueryString: ITripParsedQuery
) {
  const {
    tripsFilter,
    tripId,
    cfarExerciseProgress,
    chfarExerciseProgress,
    disruptionExerciseProgress,
  } = getExistingStateVariables(state);
  const diffTripsFilter =
    !parsedQueryString.tripsFilter ||
    parsedQueryString.tripsFilter !== tripsFilter;
  const diffTripId =
    !parsedQueryString.tripId || parsedQueryString.tripId !== tripId;

  const diffCfarExerciseProgress =
    !parsedQueryString.cfarExerciseProgress ||
    Number(parsedQueryString.cfarExerciseProgress) !== cfarExerciseProgress;

  const diffChfarExerciseProgress =
    !parsedQueryString.chfarExerciseProgress ||
    Number(parsedQueryString.chfarExerciseProgress) !== chfarExerciseProgress;

  const diffDisruptionExerciseProgress =
    !parsedQueryString.disruptionExerciseProgress ||
    Number(parsedQueryString.disruptionExerciseProgress) !==
      disruptionExerciseProgress;

  return !!(
    diffTripsFilter ||
    diffTripId ||
    diffCfarExerciseProgress ||
    diffChfarExerciseProgress ||
    diffDisruptionExerciseProgress
  );
}
