import {
  airBookingApi,
  FlightItineraryRequest,
  FlightItineraryResponse,
  FlightItineraryResponseSuccess,
} from "@b2bportal/air-booking-api";
import { axiosInstance } from "@hopper-b2b/api";

export const fetchFlights = async (
  req: FlightItineraryRequest
): Promise<FlightItineraryResponse> =>
  new Promise((resolve, reject) => {
    airBookingApi(axiosInstance)
      .apiV0ItineraryFlightPut(req)
      .then((res) => {
        resolve(
          (res.data as FlightItineraryResponseSuccess)
            .value as FlightItineraryResponse
        );
      })
      .catch((e) => {
        reject(e);
      });
  });
