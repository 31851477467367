import { CallState, getWatchDetails } from "@hopper-b2b/types";
import { Box, Typography } from "@material-ui/core";
import { Fragment, useMemo, useState } from "react";

import { WatchAlertView } from "@b2bportal/air-price-watch-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionButton, B2BSpinner, TextInput } from "@hopper-b2b/ui";
import { emailRegex } from "@hopper-b2b/utilities";
import clsx from "clsx";
import dayjs from "dayjs";
import * as textConstants from "../../../../constants";
import { DATE_FORMAT } from "../../../../../ItineraryList/constants";
import { FlightWatchCard } from "../../../FlightWatchCard/component";
import { ChangeEmailModalContentConnectorProps } from "./container";
import "./styles.scss";

interface IChangeEmailModalContentProps
  extends ChangeEmailModalContentConnectorProps {
  onClose: () => void;
  isMobile: boolean;
}

export const ChangeEmailModalContent = ({
  isMobile,
  openModal,
  context,
  updateWatch,
  updateWatchCallState,
  onClose,
}: IChangeEmailModalContentProps) => {
  const watch = openModal.selectedItinerary as WatchAlertView;
  const [email, setEmail] = useState(watch.method?.email || "");
  const [emailError, setEmailError] = useState("");
  const { t } = useI18nContext();

  const watchDetails = useMemo(() => {
    return getWatchDetails(
      watch,
      context,
      (date: string) => dayjs(date).format(DATE_FORMAT),
      textConstants.getFilterLabel,
      t("commBank.trips.watch.bookNowTagLabel"),
      t("commBank.trips.watch.waitTagLabel")
    );
  }, [watch, context, t]);
  const onEmailChanged = (val: string) => {
    setEmail(val);
    emailRegex.test(val)
      ? setEmailError("")
      : setEmailError("Enter a valid email address");
  };

  const getModalContent = () => {
    switch (updateWatchCallState) {
      case CallState.InProcess:
        return <B2BSpinner />;
      case CallState.Failed:
        return (
          <Fragment>
            <Typography className="change-email-title" variant="h2">
              {t("commBank.trips.watch.errorModalTitle")}
            </Typography>
            <Box className="change-email-buttons">
              <ActionButton
                className="change-email-button"
                onClick={() => {
                  onEmailChanged(email);
                  if (!emailError) {
                    updateWatch(watch.key, email);
                  }
                }}
                message={t("retry")}
                defaultStyle="h4r-primary"
              />
              <ActionButton
                className="cancel-button"
                onClick={() => onClose()}
                message={t("cancel")}
                defaultStyle="h4r-secondary"
              />
            </Box>
          </Fragment>
        );
      case CallState.Success:
        return (
          <Fragment>
            <Typography className="change-email-title" variant="h2">
              {t("commBank.trips.watch.changeEmailSuccessModalTitle")}
            </Typography>
            <Typography className="change-email-info" variant="body1">
              {t("commBank.trips.watch.changeEmailSuccessModalSubtitle", {
                email: email,
              })}
            </Typography>
            <Box className="change-email-buttons">
              <ActionButton
                className="cancel-button"
                onClick={() => onClose()}
                message={t("done")}
                defaultStyle="h4r-primary"
              />
            </Box>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <Typography className="change-email-title" variant="h2">
              {t("commBank.trips.watch.changeEmailConfirmationModalTitle")}
            </Typography>
            <FlightWatchCard
              priceDropProtection={watch.priceDropProtection}
              isMobile={isMobile}
              {...watchDetails}
            />
            <Typography className="change-email-info" variant="body2">
              {t("commBank.trips.watch.changeEmailConfirmationModalSubtitle")}
            </Typography>
            <TextInput
              role="input"
              className="change-email-input"
              ariaLabel={t("email")}
              label=""
              value={email}
              errorLabel={emailError}
              onChange={(event) => onEmailChanged(event.target.value)}
              headerLabel={t("email")}
              fullWidth
              disableUnderline
            />
            <Box className={clsx("change-email-buttons", { mobile: isMobile })}>
              <ActionButton
                className="change-email-button"
                onClick={() => {
                  onEmailChanged(email);
                  if (!emailError) {
                    updateWatch(watch.key, email);
                  }
                }}
                message={t("done")}
                defaultStyle="h4r-primary"
              />
              <ActionButton
                className="cancel-button"
                onClick={() => onClose()}
                message={t("cancel")}
                defaultStyle="h4r-secondary"
              />
            </Box>
          </Fragment>
        );
    }
  };
  return (
    <Box className="flight-watch-change-email-modal-content apac">
      {getModalContent()}
    </Box>
  );
};
