import { ExperimentState, ExperimentsType } from "@hopper-b2b/types";
import { useContext } from "react";
import { FeatureFlagsContext } from "../FeatureFlagsProvider";

export const useFeatureFlags = (): ExperimentState => {
  const ctx = useContext(FeatureFlagsContext);
  if (!ctx) throw new Error(`must be used within a FeatureFlagsProvider`);
  return ctx;
};

export const useTypedFeatureFlags = <
  ET extends ExperimentsType
>(): ExperimentState => {
  const ctx = useFeatureFlags();
  return {
    ...ctx,
    experiments: ctx.experiments as ET,
  } as ExperimentState;
};
