import { Box, Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import clsx from "clsx";
import Skeleton from "react-loading-skeleton";

import { HotelStarRating as HotelStarRatingType } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useTenantIcons } from "@hopper-b2b/utilities";

import { Icon, IconName } from "../../Icon";

import "../styles.scss";

export const starRatingIntValue: Record<HotelStarRatingType, number | null> = {
  NotAvailable: null,
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
};

export interface IHotelStarRatingProps {
  className: string;
  hideEmptyStars?: boolean;
  isSkeleton?: boolean;
  rating: HotelStarRatingType;
}

const defaultProps: Partial<IHotelStarRatingProps> = {
  hideEmptyStars: false,
  isSkeleton: false,
};

const HotelStarRating = (props: IHotelStarRatingProps): JSX.Element => {
  const { className, hideEmptyStars, isSkeleton, rating } = props;
  const { t } = useI18nContext();
  const icons = useTenantIcons();

  const EmptyStarIcon = hideEmptyStars ? null : (
    <Icon name={IconName.StarEmpty} />
  );
  const FilledStarIcon = icons.star ? (
    <img alt="filled star" src={icons.star} />
  ) : (
    <Icon name={IconName.StarFilled} />
  );
  const ratingValue = starRatingIntValue[rating];

  return (
    <Box className={clsx("hotel-star-rating", className)}>
      {isSkeleton ? (
        <Skeleton className="hotel-availability-info-skeleton star-rating" />
      ) : (
        <Box aria-hidden>
          {ratingValue ? (
            <>
              <Rating
                readOnly
                emptyIcon={EmptyStarIcon}
                icon={FilledStarIcon}
                value={ratingValue}
              />
              <Typography className="star-rating-text" variant="subtitle2">
                {t("nStarHotelRating", { numOfStars: ratingValue })}
              </Typography>
            </>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

HotelStarRating.defaultProps = defaultProps;

export default HotelStarRating;
