import { Box, Typography } from "@material-ui/core";
import { useMemo } from "react";

import { useI18nContext } from "@hopper-b2b/i18n";
import { Icon, IconName } from "@hopper-b2b/ui";
import { CurrencyFormatters } from "@hopper-b2b/utilities";
import "./styles.scss";

export interface IPrevPaymentLineItem {
  amount: number;
  currency: string;
  icon?: IconName;
  label: string;
}

export interface IPrevPaymentSectionProps {
  payments: IPrevPaymentLineItem[];
}

const defaultProps: Partial<IPrevPaymentSectionProps> = {};

const PrevPaymentSection = (props: IPrevPaymentSectionProps): JSX.Element => {
  const { t } = useI18nContext();
  const { payments } = props;
  const airfareCurrency = payments[0].currency;

  const total = useMemo(() => {
    const sum = payments.reduce((acc, pmnt) => acc + pmnt.amount, 0);

    return Math.max(sum, 0);
  }, [payments]);

  return (
    <Box className="prev-payment-section">
      <Box className="section-title-container">
        <Typography className="section-title" variant="subtitle1">
          {t("exchangeable.priceBreakdown.priorPayment")}
        </Typography>
      </Box>
      <Box className="prev-payment-section-body">
        {payments.map((payment) => {
          const { amount, currency, icon, label } = payment;

          return (
            <Box className="prev-payment-line" key={label}>
              {icon && <Icon className="payment-icon" name={icon} />}
              <Typography className="label" variant="subtitle2">
                {label}
              </Typography>
              <Typography className="value" variant="subtitle2">
                {CurrencyFormatters.get(currency).format(amount)}
              </Typography>
            </Box>
          );
        })}
        <Box className="prev-payment-line">
          <Typography className="label" variant="subtitle1">
            {t("exchangeable.priceBreakdown.total")}
          </Typography>
          <Typography className="value" variant="subtitle2">
            {CurrencyFormatters.get(airfareCurrency).format(total)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

PrevPaymentSection.defaultProps = defaultProps;

export default PrevPaymentSection;
