import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionButton, B2BSpinner } from "@hopper-b2b/ui";
import { Box, Typography } from "@material-ui/core";

import { PriceBreakdownStep } from "../PriceBreakdownStep";

import "./styles.scss";

export interface ICheckoutBreakdownProps {
  isSubmitting: boolean;
  onSubmit: () => void;
}

const defaultProps: Partial<ICheckoutBreakdownProps> = {};

const CheckoutBreakdown = (props: ICheckoutBreakdownProps): JSX.Element => {
  const { isSubmitting, onSubmit } = props;
  const { t } = useI18nContext();

  return (
    <Box className="checkout-breakdown-container">
      <Typography className="title">
        {t("exchangeable.priceBreakdown.title")}
      </Typography>
      <PriceBreakdownStep enablePricingToggle />
      <ActionButton
        className="submit-exchange-req-btn"
        disabled={isSubmitting}
        message={
          isSubmitting ? (
            <B2BSpinner className="processing-exchange-req-spinner" />
          ) : (
            t("exchangeable.submit.submitReq")
          )
        }
        onClick={onSubmit}
      />
    </Box>
  );
};

CheckoutBreakdown.defaultProps = defaultProps;

export default CheckoutBreakdown;
