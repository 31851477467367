import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Popover, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { MouseEvent, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import { trackEvent } from "@hopper-b2b/api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  ClientName,
  DisruptionExerciseProductType,
  DisruptionExerciseProgress,
  DisruptionTrackingEvents,
  MultiTravelItinerary,
  MyTripsFilter,
  MyTripsModalTypes,
  PortalItineraryStatusEnum,
  ScheduleChangeSeverity,
  SingleTravelItinerary,
  TagColors,
  TagInfo,
  TravelItineraryEnum,
  getFlightInfoDetails,
  getReturnSlice,
} from "@hopper-b2b/types";
import {
  ActionButton,
  ActionLinks,
  IActionLink,
  Icon,
  IconComponent,
  IconName,
  ItinerarySummary,
  ScheduleChangeBadge,
  StatusTag,
} from "@hopper-b2b/ui";
import {
  getEnvVariables,
  useEnableCfar,
  useEnableDisruptionProtection,
  useEnableMissedConnection,
} from "@hopper-b2b/utilities";
import { InfoBanner } from "@hopper-b2b/wallet";

import { ClientContext } from "../../../../../../App";
import {
  fetchFlightCfarCancellationQuote,
  fetchFlightCfarCancellationQuoteV1,
  populateTripQueryParams,
  setDisruptionExerciseProductType,
  setDisruptionExerciseProgress,
} from "../../../../../TripsList/actions/actions";
import { getSelectedFlight } from "../../../../reducer";
import { getLineItems } from "../../../../utils/helpers";
import { DATE_FORMAT } from "../../constants";
import { AddOnEnum, AddOnsSummary } from "../AddOnsSummary";
import { ConfirmationSummary } from "../ConfirmationSummary";
import { addFlightType } from "../FlightCard/helpers";
import { getConfirmationNumbers } from "../ItinerariesModal/components/ConfirmationModalContent/component";
import { MobileActionLinks } from "../MobileActionLinks";
import { PaymentSummary } from "../PaymentSummary";
import { RedeemFTCButton } from "../RedeemFTCButton";
import { StatusBanner } from "../StatusBanner";
import { SummaryCard } from "../SummaryCard";
import { TravelersSummary } from "../TravelersSummary";
import { MobileFlightItineraryDetailsConnectorProps } from "./container";

import "./styles.scss";

interface IMobileFlightItineraryDetailsProps
  extends RouteComponentProps,
    MobileFlightItineraryDetailsConnectorProps {}

export const MobileFlightItineraryDetails = ({
  flight,
  airportMap,
  airlineMap,
  setSelectedFlight,
  history,
  setOpenModal,
  tripsFilter,
}: IMobileFlightItineraryDetailsProps) => {
  const { t, formatFiatCurrency } = useI18nContext();
  const dispatch = useDispatch();
  const clientContext = useContext(ClientContext);

  const {
    ancillaries,
    bookedItinerary,
    status,
    travelCredit,
    travelCreditCopy,
  } = flight ?? {};
  const ftcPopoverRef = useRef<HTMLButtonElement>(null);
  const [ftcPopoverOpen, setFTCPopoverOpen] = useState(false);
  const isCancelled = status === PortalItineraryStatusEnum.Canceled;

  const isPastTrips = tripsFilter === MyTripsFilter.PAST_TRIPS;

  const showFlightCFAR = useEnableCfar();
  const showMissedConnection = useEnableMissedConnection();
  const showDisruption = useEnableDisruptionProtection();

  const hasCfar = showFlightCFAR && !!ancillaries?.cfar;
  const hasMissedConnection =
    showMissedConnection && !!ancillaries?.missedConnection;
  const hasDelayDisruption = showDisruption && !!ancillaries?.delay;
  // note: hasAddOn can be expanded in the future as we have more features added in
  const hasAddOn = hasCfar || hasMissedConnection || hasDelayDisruption;

  const hasFTC = !!travelCredit && !!travelCreditCopy;

  const selectedFlight = useSelector(getSelectedFlight);
  const showInfoBanner = !selectedFlight?.bookedItinerary?.paymentBreakdown;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!flight) {
    return <></>;
  }

  const closeFTCPopover = () => {
    setFTCPopoverOpen(false);
  };

  const goToExchange = (e?: MouseEvent) => {
    e?.stopPropagation();

    onOpenModal(MyTripsModalTypes.ExchangeFlight);
  };

  const openFTCPopover = () => {
    setFTCPopoverOpen(true);
  };

  const onOpenModal = (type: MyTripsModalTypes) =>
    setOpenModal({
      type,
      selectedItinerary: addFlightType(flight),
    });

  const isWithin24Hours = (flight: BookedFlightItineraryWithDepartureTime) => {
    const timeDiff = dayjs(flight.departureTime).diff(dayjs(), "h");
    return timeDiff <= 24;
  };

  const openCfarModal = () => {
    const contractId = ancillaries.cfar.id.policyId;
    const itineraryId = flight.bookedItinerary.id;

    const isUber = getEnvVariables("clientName") === ClientName.UBER;

    if (isUber) {
      dispatch(
        fetchFlightCfarCancellationQuote({
          contractId,
          itineraryId,
        })
      );
    } else {
      dispatch(
        fetchFlightCfarCancellationQuoteV1({
          itineraryId,
        })
      );
    }

    dispatch(populateTripQueryParams(history));
    onOpenModal(MyTripsModalTypes.CfarFlight);
  };

  const openMissedConnectionModal = () => {
    onOpenModal(MyTripsModalTypes.MissedConnection);
  };

  const openDelayDisruptionModal = () => {
    dispatch(setSelectedFlight(flight));
    dispatch(
      setDisruptionExerciseProductType(
        DisruptionExerciseProductType.ScheduleChange
      )
    );
    trackEvent({
      eventName: DisruptionTrackingEvents.ENTER_DISRUPTION_EXERCISE_FLOW,
      properties: null,
    });
    dispatch(
      setDisruptionExerciseProgress(DisruptionExerciseProgress.LandingPage)
    );
    populateTripQueryParams(history);
    // onOpenModal(MyTripsModalTypes.DelayDisruption);
  };

  const getActions = () => {
    const actions: IActionLink[] = [];

    if (!isCancelled && !isPastTrips) {
      actions.push({
        content: t("tripReviewLinks.changeFlight"),
        onClick: () => onOpenModal(MyTripsModalTypes.ContactSupport),
      });
      // note: replace cancel-flight with cancel-for-any-reason when CFAR is included
      if (hasCfar) {
        actions.push({
          linkClassName: "mobile-cfar-link",
          content: (
            <>
              <Icon className="cfar-icon" name={IconName.CheckShieldBlue} />
              {t("cancelForAnyReason")}
            </>
          ),

          onClick: openCfarModal,
        });
      } else {
        actions.push({
          content: t("tripReviewLinks.cancelFlight"),
          onClick: () => onOpenModal(MyTripsModalTypes.ContactSupport),
        });
      }
    }

    if (hasMissedConnection) {
      actions.push({
        content: t("missedConnection"),
        onClick: openMissedConnectionModal,
      });
    }

    if (hasDelayDisruption) {
      actions.push({
        content: t("delayDisruption"),
        onClick: openDelayDisruptionModal,
      });
    }

    if (!isPastTrips && isWithin24Hours(flight)) {
      // add Check-In action to top of list
      actions.unshift({
        content: (
          <>
            {t("tripReviewLinks.checkInForFlight")}
            <FontAwesomeIcon
              className="check-in-icon"
              icon={faEdit as IconProp}
            />
          </>
        ),
        onClick: () => onOpenModal(MyTripsModalTypes.CheckInFlight),
      });
    }

    // always show this option regardless of cancellation status
    actions.push(
      {
        content: t("tripReviewLinks.resendEmail"),
        onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
      },
      {
        content: t("tripReviewLinks.contactSupport"),
        onClick: () => onOpenModal(MyTripsModalTypes.ContactSupport),
      }
    );

    return actions;
  };

  const hasMajorScheduleChange =
    bookedItinerary.scheduleChange?.severity === ScheduleChangeSeverity.Major;

  const hasMinorScheduleChange =
    bookedItinerary.scheduleChange?.severity === ScheduleChangeSeverity.Minor ||
    (flight.status === PortalItineraryStatusEnum.Modified &&
      !bookedItinerary.scheduleChange);

  const flightInfoDetails = getFlightInfoDetails(
    flight,
    (date: string) => dayjs(date).format(DATE_FORMAT),
    airportMap,
    airlineMap
  );

  const { confirmationCode } = flightInfoDetails;

  const title = t("originToDestination", {
    origin: flightInfoDetails.origin,
    destination: flightInfoDetails.destination,
    interpolation: { escapeValue: false },
  });
  const subtitle = flightInfoDetails.roundTrip
    ? t("roundTripFlight")
    : t("oneWayFlight");

  const getFlightHeader = (flight: BookedFlightItineraryWithDepartureTime) => {
    switch (flight.bookedItinerary.travelItinerary.TravelItinerary) {
      case TravelItineraryEnum.SingleTravelItinerary: {
        const singleFirstSegment = (
          flight.bookedItinerary.travelItinerary as SingleTravelItinerary
        ).slices[0].segments[0];
        return t("originToDestination", {
          origin: singleFirstSegment.origin.locationCode,
          destination: singleFirstSegment.destination.locationCode,
          interpolation: { escapeValue: false },
        });
      }
      case TravelItineraryEnum.MultiTravelItinerary: {
        const multipleFirstSegment = (
          flight.bookedItinerary.travelItinerary as MultiTravelItinerary
        ).travelItineraries[0].slices[0].segments[0];
        return t("originToDestination", {
          origin: multipleFirstSegment.origin.locationCode,
          destination: multipleFirstSegment.destination.locationCode,
          interpolation: { escapeValue: false },
        });
      }
      default:
        return "";
    }
  };

  const getNonSkchStatusTag = (): TagInfo | undefined => {
    if (hasMajorScheduleChange || hasMinorScheduleChange) return undefined;

    switch (flight.status) {
      case PortalItineraryStatusEnum.Pending:
        return {
          label: t("pending"),
          type: TagColors.GREY,
          iconName: IconName.InfoCircle,
          tooltipCopy: t("tooltip.pending"),
        };
      case PortalItineraryStatusEnum.Canceled:
        return {
          label: t("cancelled"),
          type: TagColors.RED,
          iconName: undefined,
          tooltipCopy: "",
        };
      case PortalItineraryStatusEnum.Modified:
        return {
          label: t("itineraryModified"),
          type: TagColors.YELLOW,
          iconName: undefined,
          tooltipCopy: "",
        };
      default:
        return undefined;
    }
  };

  const sliceHasScheduleChange = (isOutgoing) => {
    if (bookedItinerary.scheduleChange) {
      const { next } = bookedItinerary.scheduleChange;
      // TODO pass sliceIdx in as a prop to support multi-city
      // itineraries (i.e. >2 slices)
      const sliceIdx = isOutgoing ? 0 : 1;
      const sliceChanged = next[sliceIdx].segments.some((segment) => {
        return (
          segment.scheduledArrival !== segment.updatedArrival ||
          segment.scheduledDeparture !== segment.updatedDeparture
        );
      });

      return sliceChanged;
    }

    return false;
  };

  const statusTagInfo = getNonSkchStatusTag();
  const returnSlice = getReturnSlice(flight.bookedItinerary);
  const outgoingSliceHasSkch = sliceHasScheduleChange(true);
  const returnSliceHasSkch = returnSlice && sliceHasScheduleChange(false);

  return (
    <Box className="mobile-trip-details">
      <Box className="mobile-trip-details-header">
        <FontAwesomeIcon
          icon={faChevronLeft as IconProp}
          onClick={() => {
            history.goBack();
            setSelectedFlight(null);
          }}
        />
        <Box className="header-info">
          <Typography variant="subtitle1">{getFlightHeader(flight)}</Typography>
          <Typography variant="caption">
            {getReturnSlice(flight.bookedItinerary)
              ? t("flightRoundTrip")
              : t("flightOneWay")}
          </Typography>
        </Box>
      </Box>
      {status === PortalItineraryStatusEnum.Canceled && hasFTC && (
        <>
          <ActionLinks
            actions={[
              {
                content: (
                  <>
                    {t("selfServe.howTcWorks")}
                    <IconComponent
                      ariaLabel="Info icon"
                      className="info-icon"
                      name={IconName.InfoCircle}
                    />
                  </>
                ),
                linkClassName: "how-ftc-works-link",
                linkRef: ftcPopoverRef,
                onClick: openFTCPopover,
              },
            ]}
          />
          <RedeemFTCButton
            isMobile
            onClick={goToExchange}
            travelCredit={travelCredit}
          />
        </>
      )}
      <Box className={clsx("mobile-trip-card", "mobile-trip-summary-card")}>
        {statusTagInfo && (
          <StatusTag className="title-status-tag" tagInfo={statusTagInfo} />
        )}
        <Typography variant="body1" className="trips-title">
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="caption" className="trips-subtitle">
            {subtitle}
          </Typography>
        )}
        {confirmationCode && (
          <Box className="info-container">
            <Typography className="label" variant="caption">
              {t("confirmation")}:
            </Typography>
            <Typography className="code" variant="caption">
              {confirmationCode}
            </Typography>
          </Box>
        )}
      </Box>
      <StatusBanner
        isMobile
        flight={flight}
        hasMajorScheduleChange={hasMajorScheduleChange}
        hasMinorScheduleChange={hasMinorScheduleChange}
        setOpenModal={() => onOpenModal(MyTripsModalTypes.ScheduleChange)}
      />
      <Box
        className={clsx("mobile-summary-card-section", {
          "has-add-on": hasAddOn,
        })}
      >
        {showInfoBanner && (
          <Box className="info-banner">
            <InfoBanner
              icon={clientContext.assets?.infoWhite}
              text={t("infoBanner")}
            />
          </Box>
        )}

        <SummaryCard
          className={clsx(
            "mobile-trip-card",
            "mobile-outgoing-itinerary-summary",
            { "major-change": hasMajorScheduleChange && outgoingSliceHasSkch }
          )}
          action={
            <FontAwesomeIcon
              className="mobile-right-chevron"
              onClick={() => onOpenModal(MyTripsModalTypes.OutboundItinerary)}
              icon={faChevronRight as IconProp}
            />
          }
        >
          <Box style={{ display: "flex", flexFlow: "column" }}>
            <ItinerarySummary
              isOutgoing
              airlineMap={airlineMap}
              airportMap={airportMap}
              flight={flight}
              hasScheduleChange={outgoingSliceHasSkch}
              statusTag={
                outgoingSliceHasSkch ? (
                  <ScheduleChangeBadge
                    hasMajorScheduleChange={hasMajorScheduleChange}
                    hasMinorScheduleChange={hasMinorScheduleChange}
                  />
                ) : statusTagInfo ? (
                  <StatusTag
                    className="title-status-tag"
                    tagInfo={statusTagInfo}
                  />
                ) : null
              }
            />
            {outgoingSliceHasSkch ? (
              <ActionButton
                fill="blue"
                message={"Review schedule change"}
                onClick={() => onOpenModal(MyTripsModalTypes.ScheduleChange)}
                size="small"
              />
            ) : null}
          </Box>
        </SummaryCard>
        {returnSlice && (
          <SummaryCard
            className={clsx("mobile-trip-card", {
              "major-change": hasMajorScheduleChange && returnSliceHasSkch,
            })}
            action={
              <FontAwesomeIcon
                className="mobile-right-chevron"
                onClick={() => onOpenModal(MyTripsModalTypes.ReturnItinerary)}
                icon={faChevronRight as IconProp}
              />
            }
          >
            <Box style={{ display: "flex", flexFlow: "column" }}>
              <ItinerarySummary
                airlineMap={airlineMap}
                airportMap={airportMap}
                flight={flight}
                hasScheduleChange={returnSliceHasSkch}
                statusTag={
                  returnSliceHasSkch ? (
                    <ScheduleChangeBadge
                      hasMajorScheduleChange={hasMajorScheduleChange}
                      hasMinorScheduleChange={hasMinorScheduleChange}
                    />
                  ) : statusTagInfo ? (
                    <StatusTag
                      className="title-status-tag"
                      tagInfo={statusTagInfo}
                    />
                  ) : null
                }
              />
              {returnSliceHasSkch ? (
                <ActionButton
                  fill="blue"
                  message={"Review schedule change"}
                  onClick={() => onOpenModal(MyTripsModalTypes.ScheduleChange)}
                  size="small"
                />
              ) : null}
            </Box>
          </SummaryCard>
        )}
        <SummaryCard
          className="mobile-trip-card"
          action={
            <FontAwesomeIcon
              className="mobile-right-chevron"
              onClick={() => onOpenModal(MyTripsModalTypes.TravelersModal)}
              icon={faChevronRight as IconProp}
            />
          }
        >
          <TravelersSummary
            travelers={flight.bookedItinerary.passengers.alone
              .map(({ person }) => person?.givenName)
              .join(", ")}
          />
        </SummaryCard>
        {hasAddOn && (
          <SummaryCard
            className={clsx("mobile-add-ons-summary", "mobile-trip-card")}
          >
            <AddOnsSummary
              addOns={[
                {
                  expiryDate: ancillaries?.cfar?.expired ?? "",
                  onClick: openCfarModal,
                  type: AddOnEnum.Cfar,
                },
                {
                  createdDate: ancillaries?.delay?.created ?? "",
                  onClick: openDelayDisruptionModal,
                  type: AddOnEnum.DelayDisruption,
                },
                {
                  expiryDate: ancillaries?.missedConnection?.created ?? "",
                  onClick: openMissedConnectionModal,
                  type: AddOnEnum.MissedConnection,
                },
              ]}
            />
          </SummaryCard>
        )}
        <SummaryCard
          className={clsx("mobile-payment-summary", "mobile-trip-card")}
          action={
            <FontAwesomeIcon
              className="mobile-right-chevron"
              onClick={() => onOpenModal(MyTripsModalTypes.PaymentModal)}
              icon={faChevronRight as IconProp}
            />
          }
        >
          <PaymentSummary
            tripTotalAmount={formatFiatCurrency(
              flight.bookedItinerary.sellingPricing.totalPricing.total.fiat
            )}
            paymentLineItems={getLineItems(
              t,
              flight.bookedItinerary.paymentBreakdown
            )}
          />
        </SummaryCard>
        <SummaryCard
          className="mobile-trip-card"
          action={
            <Box
              className="confirmation-mobile-action"
              onClick={() => onOpenModal(MyTripsModalTypes.ConfirmationModal)}
            >
              <Typography className="view-all-link">
                {t("viewAllConfirmation", {
                  count: getConfirmationNumbers({ flight, airlineMap })?.length,
                })}
              </Typography>
              <FontAwesomeIcon
                className="mobile-right-chevron"
                icon={faChevronRight as IconProp}
              />
            </Box>
          }
        >
          <ConfirmationSummary confirmationCode={confirmationCode || ""} />
        </SummaryCard>
      </Box>
      <MobileActionLinks actions={getActions()} />
      {status === PortalItineraryStatusEnum.Canceled && hasFTC && (
        <Popover
          anchorEl={ftcPopoverRef.current}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          className="how-ftc-works-popover"
          onClose={closeFTCPopover}
          open={ftcPopoverOpen}
        >
          <Typography className="subtitle">
            <I18nMarkup tKey={travelCreditCopy} />
          </Typography>
        </Popover>
      )}
    </Box>
  );
};
