import React, { useMemo } from "react";
import styles from "../LodgingCard.module.scss";
import { AmenityIcon } from "@hopper-b2b/ui";
import { AmenityStatus, HotelStarRating } from "@b2bportal/lodging-api";
import { ApacIconComponent, ApacIconName } from "@commbank/ui";
import { TripAdvisorIcon } from "@hopper-b2b/ui";
import { toStarRating } from "@hopper-b2b/utilities";
import {
  getSortedAmenities,
  getUniqueSortedAmenities,
} from "../../../utils/getUniqueSortedAmenities";
import { useI18nContext } from "@hopper-b2b/i18n";
import { MoreAmenitiesLink } from "../../../../shop/components/Amenities/MoreAmenitiesLink";

interface HotelDetailsProps {
  id: string;
  hotelName: string;
  rating: HotelStarRating;
  location: string;
  userRating: number;
  reviewCount: number;
  amenities: AmenityStatus[];
  features: string[];
  selectLocation?: (lodgingId: string | null) => void;
}

const MAX_AMENITY_ICONS = 6;

export const HotelDetails: React.FC<HotelDetailsProps> = ({
  id,
  hotelName,
  rating,
  location,
  reviewCount = 0,
  userRating = 0,
  amenities = [],
  features = [],
  selectLocation,
}) => {
  const { t } = useI18nContext();
  const starRating = toStarRating(rating);
  const [sortedAmenities, sortedTrimmedAmenities] = useMemo(
    () => [
      getSortedAmenities(amenities),
      getUniqueSortedAmenities(amenities, MAX_AMENITY_ICONS),
    ],
    [amenities]
  );

  return (
    <div className={styles.hotelDetails}>
      <header className={styles.header}>
        <h2 className={styles.hotelName}>{hotelName}</h2>
        {starRating && (
          <div className={styles.rating}>
            <ApacIconComponent name={ApacIconName.Star} />
            <span>{starRating}/5</span>
          </div>
        )}
      </header>
      <div className={styles.ratingAndLocation}>
        <div className={styles.location}>
          <ApacIconComponent name={ApacIconName.LocationOutlined} />
          <button
            className={styles.linkButton}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              selectLocation?.(id);
            }}
          >
            {location}
          </button>
        </div>

        {reviewCount > 0 ? (
          <div className={styles.userRating}>
            <TripAdvisorIcon className={styles.userRatingIcon} />
            <span className={styles.positiveText}>
              {Math.round(userRating * 50) / 10}/5
            </span>
            <span>({reviewCount > 999 ? "999+" : reviewCount})</span>
          </div>
        ) : null}
      </div>
      <div className={styles.amenities}>
        <div className={styles.amenityIcons}>
          {sortedTrimmedAmenities.map((a) => (
            <AmenityIcon
              key={a.amenity}
              amenity={a.amenity}
              alt={a.displayName}
            />
          ))}
        </div>
        <MoreAmenitiesLink
          className={styles.amenityLink}
          linkText={t("commBank.hotelAvailability.card.viewAmenitiesLinkText")}
          amenities={sortedAmenities}
        />
      </div>
      <div className={styles.features}>
        {features.map((feature, index) => (
          <div key={index} className={styles.feature}>
            <ApacIconComponent name={ApacIconName.ItemSuccess} />
            <span>{feature}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
