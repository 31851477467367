import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../../reducers/types";

import {
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getSelectedFareShelfRatings,
} from "../../../../../../search/reducer";
import {
  getFareSliceByFareId,
  getOutgoingFareSlice,
} from "../../../../../../shop/reducer";
import { FlightDetails } from "./component";

const mapStateToProps = (state: IStoreState, props) => {
  return {
    selectedFareClassFilters: getSelectedFareShelfRatings(state),
    selectedOutgoingFareSlice: getOutgoingFareSlice(state),
    getFareSliceByFareId: getFareSliceByFareId(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightDetailsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightDetails = connector(FlightDetails);
