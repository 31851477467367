import { Skeleton } from "@material-ui/lab";
import styles from "./LodgingCard.module.scss";
import clsx from "clsx";

export function LodgingCardSkeleton({ className = null }) {
  return (
    <div className={clsx(styles.lodgingCard, className)}>
      <div className={styles.lodgingCardGrid}>
        <div className={clsx(styles.imageCarouselContainer, {})}>
          <Skeleton
            variant="rect"
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
          />
        </div>
        <div className={styles.hotelDetails}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Skeleton variant="text" width="60%" height={32} />
            <Skeleton variant="text" width={20} />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Skeleton variant="text" width={100} />
            <Skeleton variant="text" width={50} />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Skeleton variant="text" width={140} />
            <Skeleton variant="text" width={50} />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Skeleton variant="text" width={80} />
            <Skeleton variant="text" width={40} />
          </div>
        </div>
        <div className={styles.priceInfo}>
          <div className={styles.priceDetails}>
            <Skeleton variant="text" width={50} height={14} />
            <Skeleton variant="text" width={60} height={32} />
            <Skeleton variant="text" width={120} />
            <Skeleton variant="text" width={140} />
            <Skeleton variant="text" width={80} />
          </div>
        </div>
      </div>
    </div>
  );
}
