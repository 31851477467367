export const SET_LOADING = "lodgingAvailability/SET_LOADING";
export type SET_LOADING = typeof SET_LOADING;

export const FETCH_PLACE = "lodgingAvailability/FETCH_PLACE";
export type FETCH_PLACE = typeof FETCH_PLACE;

export const SET_AVAILABILITY_LODGINGS =
  "lodgingAvailability/SET_AVAILABILITY_LODGINGS";
export type SET_AVAILABILITY_LODGINGS = typeof SET_AVAILABILITY_LODGINGS;

export const SET_SEARCH_PARAMS = "lodgingAvailability/SET_SEARCH_PARAMS";
export type SET_SEARCH_PARAMS = typeof SET_SEARCH_PARAMS;

export const ADD_AVAILABILITY_LODGINGS =
  "lodgingAvailability/ADD_AVAILABILITY_LODGINGS";
export type ADD_AVAILABILITY_LODGINGS = typeof ADD_AVAILABILITY_LODGINGS;

export const RESET_FILTERS = "lodgingAvailability/RESET_FILTERS";
export type RESET_FILTERS = typeof RESET_FILTERS;

export const UPDATE_FILTERS_AND_SORT =
  "lodgingAvailability/UPDATE_FILTERS_AND_SORT";
export type UPDATE_FILTERS_AND_SORT = typeof UPDATE_FILTERS_AND_SORT;

export const SET_SORT = "lodgingAvailability/SET_SORT";
export type SET_SORT = typeof SET_SORT;
