import { Airport } from "@b2bportal/air-shopping-api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { ClientName, FareDetails, TripDetails } from "@hopper-b2b/types";
import { getEnvVariables, getSliceIndex } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ActionLink } from "../ActionLink";
import { FlightDetailsSummary } from "../FlightDetailsSummary";
import { FlightDetailsSummaryExtended } from "../FlightDetailsSummaryExtended";
import { IconName } from "../Icon";
import { IconComponent } from "../IconComponent";
import { MobileFloatingButton } from "../MobileFloatingButton";
import { MobileFloatingSection } from "../MobileFloatingSection";
import { MobilePopoverCard } from "../MobilePopoverCard";
import { Slot } from "../Slots";
import "./styles.scss";

export interface IMobileFlightDetailsModalProps {
  departure: boolean;
  tripDetails: TripDetails;
  fareDetails: FareDetails | undefined;
  openModal: boolean;
  onClose: () => void;
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  onClick?: () => void;
  secondaryButtonOnClick?: () => void;
  buttonText?: string;
  secondaryButtonText?: string;
  className?: string;
  plusDays?: number;
  isMixedCabinClass?: boolean;
  airports?: { [key: string]: Airport };
  assets?: Record<string, any>;
  fullscreen?: boolean;
}

export const FlightDetailsModalButton = (props: {
  buttonText: string;
  onClick?: () => void;
}) => {
  const { buttonText, onClick } = props;
  if (!onClick) return null;
  return (
    <MobileFloatingButton
      className="mobile-flight-details-modal-button"
      onClick={onClick}
      wrapperClassName="b2b"
      wide
    >
      {buttonText}
    </MobileFloatingButton>
  );
};

export const MobileFlightDetailsModal = ({
  title = "Departure details",
  subtitle,
  airports,
  assets,
  buttonText,
  className,
  departure,
  fareDetails,
  isMixedCabinClass,
  onClick,
  onClose,
  openModal,
  plusDays,
  secondaryButtonOnClick,
  secondaryButtonText,
  tripDetails,
  fullscreen = true,
}: IMobileFlightDetailsModalProps) => {
  const { t } = useI18nContext();

  if (fareDetails === undefined) return null;
  const fareIndex = fareDetails.slices.findIndex((slice) =>
    departure ? slice.outgoing : !slice.outgoing
  );
  const fare = fareDetails.slices[fareIndex];
  const isUberClient = getEnvVariables("clientName") === ClientName.UBER;
  const isHopperClient = getEnvVariables("clientName") === ClientName.HOPPER;
  const isNubankClient = getEnvVariables("clientName") === ClientName.NUBANK;

  return tripDetails && fareDetails ? (
    <MobilePopoverCard
      className={clsx(
        "mobile-flight-details-modal-root",
        "full-screen",
        className
      )}
      contentClassName={clsx(
        "mobile-flight-details-modal-content",
        buttonText && onClick ? "has-continue" : null
      )}
      open={openModal}
      topLeftButton={
        isNubankClient ? (
          <ActionLink
            className="filter-close-button"
            content={
              <IconComponent
                ariaLabel={t("modalClose.ariaLabel")}
                className={clsx("close-button-icon")}
                name={IconName.Close}
              />
            }
            label={t("modalClose.label")}
            onClick={onClose}
          />
        ) : undefined
      }
      topRightButton={
        isUberClient || isHopperClient ? (
          <ActionLink
            className="filter-close-button"
            content={
              <IconComponent
                ariaLabel={t("modalClose.ariaLabel")}
                className={clsx("close-button-icon")}
                name={IconName.Close}
              />
            }
            label={t("modalClose.label")}
            onClick={onClose}
          />
        ) : undefined
      }
      onClose={onClose}
      headerElement={
        isUberClient ? (
          <Typography
            className="mobile-flight-details-modal-header"
            variant="h4"
          >
            {renderUberHeader(departure, tripDetails, airports)}
            <ActionLink
              className="filter-close-button"
              content={
                <IconComponent
                  ariaLabel={t("modalClose.ariaLabel")}
                  className={clsx("close-button-icon")}
                  name={IconName.Close}
                />
              }
              label={t("modalClose.label")}
              onClick={onClose}
            />
          </Typography>
        ) : undefined
      }
      fullScreen={fullscreen}
    >
      <Box className={clsx("mobile-flight-details-modal-container", className)}>
        {isUberClient ? null : (
          <Typography
            className="mobile-flight-details-modal-title"
            variant="h4"
          >
            {title}
          </Typography>
        )}
        {subtitle ? (
          <div className="mobile-flight-details-modal-subtitle">{subtitle}</div>
        ) : null}
        <Box className="flight-details-summary-container">
          {isUberClient || isNubankClient ? (
            <FlightDetailsSummaryExtended
              className={clsx("review-itinerary-flight-details", {
                isMixedCabinClass,
              })}
              segments={
                departure
                  ? tripDetails.slices[0].segmentDetails
                  : tripDetails.slices[tripDetails.slices.length - 1]
                      .segmentDetails
              }
              departureTime={
                departure
                  ? tripDetails.slices[0].departureTime
                  : tripDetails.slices[tripDetails.slices.length - 1]
                      .departureTime
              }
              planeInfo={
                fare?.amenitiesUtas?.amenities?.aircraft.info.displayText || ""
              }
              fareClass={fare?.fareShelf?.shortBrandName || ""}
              showTitle={false}
              plusDays={plusDays}
              fareSlice={fare}
              isMixedCabinClass={isMixedCabinClass}
              airports={airports}
              assets={assets}
            />
          ) : (
            <FlightDetailsSummary
              className="review-itinerary-flight-details"
              segments={
                departure
                  ? tripDetails.slices[0].segmentDetails
                  : tripDetails.slices[tripDetails.slices.length - 1]
                      .segmentDetails
              }
              departureTime={
                departure
                  ? tripDetails.slices[0].departureTime
                  : tripDetails.slices[tripDetails.slices.length - 1]
                      .departureTime
              }
              planeInfo={
                fare?.amenitiesUtas?.amenities?.aircraft.info.displayText || ""
              }
              fareClass={fare?.fareShelf?.shortBrandName || ""}
              showTitle={false}
              plusDays={plusDays}
              fareSlice={fare}
              isMixedCabinClass={isMixedCabinClass}
            />
          )}
          {isMixedCabinClass && (
            <Slot id="flight-review-mixed-cabin-tooltip" showDivider={false} />
          )}
        </Box>
        {buttonText && onClick ? (
          <MobileFloatingSection
            primaryButton={{
              children: buttonText,
              onClick: onClick,
            }}
            secondaryButton={
              secondaryButtonText
                ? {
                    children: secondaryButtonText,
                    onClick: secondaryButtonOnClick,
                    className: "mobile-flight-details-button-secondary",
                  }
                : undefined
            }
            className="mobile-flight-details-button-container"
          />
        ) : null}
      </Box>
    </MobilePopoverCard>
  ) : null;
};

const renderUberHeader = (
  departure: boolean,
  tripDetails: TripDetails,
  airports?: { [key: string]: Airport }
) => {
  if (!airports || !tripDetails) return undefined;
  const sliceIndex = getSliceIndex(departure, tripDetails);
  const locationName = airports[tripDetails.slices[sliceIndex]?.destinationCode]
    ? airports[tripDetails.slices[sliceIndex]?.destinationCode]?.cityName
    : tripDetails.slices[sliceIndex]?.destinationName
    ? tripDetails.slices[sliceIndex]?.destinationName
    : tripDetails.slices[sliceIndex]?.destinationCode;

  return (
    <I18nMarkup
      tKey={
        departure
          ? "flightsPageTitles.detailsOutbound"
          : "flightsPageTitles.detailsReturn"
      }
      replacements={
        departure
          ? { outboundCity: locationName }
          : { returnCity: locationName }
      }
    />
  );
};
