export type AddOn = ICfarAddOn;

export enum AddOnEnum {
  Cfar,
  DelayDisruption,
  MissedConnection,
}

interface IAddOn {
  type: AddOnEnum;
}

interface ICfarAddOn extends IAddOn {
  expiryDate?: string;
  createdDate?: string;
  onClick: () => void;
  type: AddOnEnum;
}
