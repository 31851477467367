export const FARECLASS_HEADER_TEXT = "Fareclass";
export const BASIC_FARES = "Basic Fares";
export const BASIC_FARE_FILTER_DESCRIPTION =
  "Cheaper, but with more restrictions and fees on seats, bags, etc.";
export const ALL = "All";
export const BASIC_TEXT = "Basic";
export const STANDARD_TEXT = "Standard";
export const ENHANCED_TEXT = "Enhanced";
export const PREMIUM_TEXT = "Premium";
export const LUXURY_TEXT = "Luxury";
export const CHOOSE_FARECLASS_TEXT = "choose a fareclass to search";
export const CHANGE_FARECLASS_TEXT = "change fareclass";
