import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { PredictionCopy } from "@b2bportal/air-shopping-api";
import { CallState, Dealness } from "@hopper-b2b/types";
import { PriceWatchEntryCard, UberMobilePricePrediction } from "@hopper-b2b/ui";
import { useEnablePriceWatch, useSessionContext } from "@hopper-b2b/utilities";
import { ClientContext } from "../../../../App";
import { ConnectedWatchDetailsModalContentProps } from "./container";
import "./styles.scss";

type WatchDetailsModalContentProps = ConnectedWatchDetailsModalContentProps;

export const WatchDetailsModalContent = ({
  createWatch,
  deleteWatch,
  selectedWatchDetails,
  selectedWatch,
  setCreateWatchCallState,
  setGetDetailsWatchCallState,
  setSelectedWatch,
}: WatchDetailsModalContentProps) => {
  const history = useHistory();
  const enablePriceWatch = useEnablePriceWatch();

  const resetCallState = useCallback(() => {
    setSelectedWatch(null);
    setGetDetailsWatchCallState(CallState.NotCalled);
  }, [setSelectedWatch, setGetDetailsWatchCallState]);

  const handleDeleteWatch = useCallback(() => {
    deleteWatch(selectedWatch);
  }, [deleteWatch, selectedWatch]);

  useEffect(() => {
    return () => resetCallState();
  }, [resetCallState]);

  const lowestPrice = useMemo(
    () =>
      selectedWatchDetails?.pricePrediction?.minPrice
        ? {
            fiat: {
              currencyCode:
                selectedWatchDetails.pricePrediction.minPrice?.amount?.currency,
              value:
                selectedWatchDetails.pricePrediction.minPrice?.amount?.amount,
              currencySymbol: "", //Not used
            },
            accountSpecific: {}, //Not used
          }
        : undefined,
    [selectedWatchDetails?.pricePrediction?.minPrice]
  );

  return (
    <UberMobilePricePrediction
      onClose={() => history.goBack()}
      className="trips-watch-details-modal-content"
      predictionDetails={{
        predictionCopy: selectedWatchDetails?.predictionCopy,
        dealness: selectedWatchDetails?.predictionCopy.dealness as Dealness,
        pricePrediction: selectedWatchDetails?.pricePrediction,
        lowestPrice,
      }}
    >
      {enablePriceWatch ? (
        <TripsPriceWatchEntryCard
          predictionCopy={selectedWatchDetails.predictionCopy}
          createWatch={createWatch}
          deleteWatch={handleDeleteWatch}
          setCreateWatchCallState={setCreateWatchCallState}
        />
      ) : null}
    </UberMobilePricePrediction>
  );
};

export const TripsPriceWatchEntryCard = (props: {
  predictionCopy?: PredictionCopy;
  createWatch: (email: string) => void;
  deleteWatch: () => void;
  setCreateWatchCallState: (callState: CallState) => void;
}) => {
  const { predictionCopy, createWatch, deleteWatch, setCreateWatchCallState } =
    props;
  const [watching, setWatching] = useState(true);
  const { assets } = useContext(ClientContext);
  const sessionContext = useSessionContext();
  const [email, setEmail] = useState(sessionContext.email || "");

  return (
    <PriceWatchEntryCard
      predictionCopy={predictionCopy}
      createWatch={createWatch}
      deleteWatch={deleteWatch}
      setCreateWatchCallState={setCreateWatchCallState}
      hasUnsupportedPredictionFilters={false}
      bellIcon={assets?.bell}
      email={email}
      onEmailChange={setEmail}
      watching={watching}
      onWatchingChange={setWatching}
    />
  );
};
