import { Box, Typography } from "@material-ui/core";
import { FiatAndPointPrice } from "../../reducer/selectors";
import { useI18nContext } from "@hopper-b2b/i18n";
import { numberWithCommas } from "../../../../util/utils";
import styles from "./PriceSummary.module.scss";

export const PriceSummary = ({
  title,
  price,
  nights,
}: {
  title: string;
  price: FiatAndPointPrice;
  nights: number;
}) => {
  const { t, formatFiatCurrency } = useI18nContext();

  return price?.fiat ? (
    <Box className={styles.priceSummary}>
      <Typography className={styles.priceSummaryTitle}>{title}</Typography>
      <Typography className={styles.fees}>
        {t("lodgingShopDetails.includingFees")}
      </Typography>
      <Box className={styles.total}>
        <Typography
          className={styles.totalPrice}
          dangerouslySetInnerHTML={{
            __html: price?.points
              ? t("commBank.totalPrice", {
                  cashPriceWithSymbol: formatFiatCurrency(price.fiat),
                  numberOfNights: nights,
                  pointPrice: numberWithCommas(price.points?.value),
                })
              : t("priceForNights", {
                  price: formatFiatCurrency(price.fiat),
                  count: nights,
                }),
          }}
        />
      </Box>
    </Box>
  ) : null;
};
