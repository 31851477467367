import { ChatPropertiesType } from "@b2bportal/chat-api";
import { createContext } from "react";

export interface ISupportContext {
  productSupportChat: (
    productId: string,
    productType: ChatPropertiesType,
    requestType: string
  ) => void;
  generalSupportChat: () => void;
  openChatWindow: () => void;
  unreadCount: number;
}

export const SupportContext = createContext<Partial<ISupportContext>>({});
