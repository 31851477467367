import { BrandNames } from "@hopper-b2b/types";
import { useI18nextContext } from "../I18nProvider";
import { setLang } from "../utils/getLang";
import { useI18nCurrency } from "./useI18nCurrency";
import {
  B2B_CURRENCY_PREFERENCE_KEY,
  B2B_LOCALE_PREFERENCE_KEY,
} from "../constants";
import { useCallback } from "react";

export function useI18nContext() {
  const ctx = useI18nextContext();
  if (ctx === undefined) throw new Error(`must be used within a I18nProvider`);
  const {
    language,
    options: { resources, fallbackLng },
  } = ctx;

  let brand = resources?.[language]?.["brand"] as BrandNames;

  const supported = brand?.supportedLanguages?.some((l) => l.key === language);
  if (!supported) {
    // Use the i18n fallback if tenant does not support this language
    const fallback = fallbackLng?.[0];
    brand = resources?.[fallback]?.["brand"] as BrandNames;
    setLang(fallback);
    window.location.reload();
  }

  const { formatCurrency, formatFiatCurrency, formatRewardsCurrency } =
    useI18nCurrency();

  const setLanguage = useCallback((language: string) => {
    localStorage.setItem(B2B_LOCALE_PREFERENCE_KEY, language);
    window.location.reload();
  }, []);

  const setCurrency = useCallback((currencyCode: string) => {
    localStorage.setItem(B2B_CURRENCY_PREFERENCE_KEY, currencyCode);
    window.location.reload();
  }, []);

  return {
    ...ctx,
    brand,
    formatCurrency,
    formatFiatCurrency,
    formatRewardsCurrency,
    setLanguage,
    setCurrency,
  };
}
