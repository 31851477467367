import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback } from "react";

import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { AirportMap, FareDetails, TripDetails } from "@hopper-b2b/types";
import { RemoteAirlineIcon } from "@hopper-b2b/ui";
import {
  getEmptyRestrictionsText,
  getEnvVariables,
  getSliceIndex,
} from "@hopper-b2b/utilities";

import {
  IRestrictionProps,
  Restriction,
} from "../FlightShopReviewDetails/component";
import "./styles.scss";

export interface IRestrictionsSectionProps {
  fareDetails: FareDetails | undefined;
  tripDetails: TripDetails;
  isOneWay: boolean;
  returnDate: Date | null;
  departureDate: Date | null;
  airports: AirportMap;
  departureRestrictions: IRestrictionProps[] | null;
  returnRestrictions: IRestrictionProps[] | null;
  isHackerFare: boolean;
}

export const RestrictionsSection = ({
  fareDetails,
  tripDetails,
  isOneWay,
  returnDate,
  departureDate,
  airports,
  departureRestrictions,
  returnRestrictions,
  isHackerFare,
}: IRestrictionsSectionProps) => {
  const { t: translate } = useI18nContext();
  const firstSliceIndex = getSliceIndex(true, tripDetails);
  const secondSliceIndex = getSliceIndex(false, tripDetails);

  const getReviewCardHeaderWithTypeLocation = airports[
    tripDetails.slices[firstSliceIndex].destinationCode
  ]
    ? `${
        airports[tripDetails.slices[firstSliceIndex].destinationCode].cityName
      } (${airports[tripDetails.slices[firstSliceIndex].destinationCode].code})`
    : tripDetails.slices[firstSliceIndex].destinationName;

  const getRestrictionsList = useCallback(
    (restrictions: IRestrictionProps[] | null, index: number) => {
      if (restrictions && restrictions?.length > 0) {
        return restrictions?.map((restriction) => (
          <Restriction
            key={restriction.name}
            symbol={restriction.symbol}
            name={restriction.name}
            description={
              <Typography className="description">
                {restriction.description}
              </Typography>
            }
          />
        ));
      } else {
        return (
          <div className="empty-restrictions">
            {getEmptyRestrictionsText(
              fareDetails?.slices[index]?.fareShelf?.rating,
              translate
            )}
          </div>
        );
      }
    },
    [fareDetails?.slices, translate]
  );

  if (isOneWay) {
    return (
      <Box className="restrictions-section">
        {fareDetails
          ? getRestrictionsList(departureRestrictions, firstSliceIndex)
          : null}
      </Box>
    );
  } else {
    const segment1 = tripDetails.slices[0].segmentDetails[0];
    const segment2 = tripDetails.slices[1].segmentDetails[0];
    return (
      <>
        <Box
          className={clsx("restrictions-section outbound-section", {
            "hacker-fare-restrictions-section": isHackerFare,
          })}
        >
          <Typography className="fare-slice-header" variant="body1">
            <I18nMarkup
              tKey={
                getEnvVariables("clientName") === "uber"
                  ? "flightShopReview.outboundCardHeader"
                  : "flightShopReview.departureCardHeader"
              }
              replacements={{
                location: getReviewCardHeaderWithTypeLocation,
                date: dayjs(departureDate).format("ddd, MMM D"),
              }}
            />
            <p className="fare-slice-header-airline">{segment1.airlineName}</p>
          </Typography>
          {isHackerFare && (
            <Box className="flight-info-details">
              <Box className="airline-details">
                <RemoteAirlineIcon
                  size="medium"
                  airlineCode={segment1.airlineCode}
                  altText={segment1.airlineName}
                />
              </Box>
              <Typography variant="body2" className="flight-info">
                {segment1.airlineName}
              </Typography>
            </Box>
          )}
          {fareDetails
            ? getRestrictionsList(departureRestrictions, firstSliceIndex)
            : null}
        </Box>
        <Box
          className={clsx("restrictions-section", {
            "hacker-fare-restrictions-section": isHackerFare,
          })}
        >
          <Typography className="fare-slice-header" variant="body1">
            <I18nMarkup
              tKey={"flightShopReview.returnCardHeader"}
              replacements={{
                location: airports[
                  tripDetails.slices[secondSliceIndex].destinationCode
                ]
                  ? airports[
                      tripDetails.slices[secondSliceIndex].destinationCode
                    ].cityName
                  : tripDetails.slices[secondSliceIndex].destinationName,
                date: dayjs(returnDate).format("ddd, MMM D"),
              }}
            />
            <p className="fare-slice-header-airline">{segment2.airlineName}</p>
          </Typography>
          {isHackerFare && (
            <Box className="flight-info-details">
              <Box className="airline-details">
                <RemoteAirlineIcon
                  size="medium"
                  airlineCode={segment2.airlineCode}
                  altText={segment2.airlineName}
                />
              </Box>
              <Typography variant="body2" className="flight-info">
                {segment2.airlineName}
              </Typography>
            </Box>
          )}
          {fareDetails
            ? getRestrictionsList(returnRestrictions, secondSliceIndex)
            : null}
        </Box>
      </>
    );
  }
};
