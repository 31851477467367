import { ParentState } from "../../../types/common";
import { State } from "xstate";
import { ParentContextWithWallet } from "./types";
import dayjs from "dayjs";
import { WalletVoucherStatusEnum } from "@hopper-b2b/types";

type StateType = State<ParentContextWithWallet>;
type StateWithoutValue = Pick<StateType, "context">;

export const getWalletOffersId = (state: StateWithoutValue) => {
  return [state.context[ParentState.wallet]?.selectedOffer.id].filter((o) => o);
};

export const getWalletOffersChoice = (state: StateWithoutValue) => {
  return [state.context[ParentState.wallet].selectedOffer].filter((o) => o);
};

export const getWalletOffer = (state: StateWithoutValue) => {
  return state.context[ParentState.wallet].selectedOffer;
};

export const getOfferStatus = (state: StateWithoutValue) => {
  return state.context[ParentState.wallet].offerStatus;
};

export const getIsWalletOfferApplied = (state: StateWithoutValue) =>
  state.context[ParentState.wallet].offerApplied;

export const getHasWalletOffersAvailable = (state: StateWithoutValue) =>
  state.context[ParentState.wallet].offers?.length > 0;

export const getWalletTrackingProperties = (state: StateWithoutValue) => {
  const offer = state.context[ParentState.wallet].selectedOffer;

  return {
    offer_id: offer?.id,
    offer_name: offer?.offerContent?.title,
    // program_id: offer.offerContent.title,
    offer_applicable: true,
    offer_status: state.context[ParentState.wallet].offerStatus,
    // offer_discount if percentage, is based off the cheapest hotel
    // these two won't be available until checkout
    // offer_discount: 50,
    // offer_discount_usd: 50,
    offer_expiration_days: dayjs(offer?.expiresOn).diff(dayjs(), "days"),
    ...(offer?.percentage
      ? {
          offer_discount_percentage_value: offer.percentage * 100,
          offer_discount_type: "percentage",
        }
      : { offer_discount_type: "cash" }),
    ...offer?.trackingPropertiesV2,
  };
};

export const getCheckoutOfferSelectionTrackingProperties =
  (state: StateWithoutValue) => (props: { offerActive: boolean }) => {
    const offer = state.context[ParentState.wallet].selectedOffer;
    return {
      offer_id: offer?.id,
      offer_name: offer?.offerContent?.title,
      // program_id: offer.offerContent.title,
      offer_applicable: true,
      offer_status: props.offerActive
        ? WalletVoucherStatusEnum.EXPLICITLY_ACCEPTED
        : WalletVoucherStatusEnum.EXPLICITLY_DECLINED,
      // offer_discount if percentage, is based off the cheapest hotel
      // these two won't be available until checkout
      // offer_discount: 50,
      // offer_discount_usd: 50,
      offer_expiration_days: dayjs(offer?.expiresOn).diff(dayjs(), "days"),
      ...(offer?.percentage
        ? {
            offer_discount_percentage_value: offer.percentage * 100,
            offer_discount_type: "percentage",
          }
        : { offer_discount_type: "cash" }),
      ...offer?.trackingPropertiesV2,
    };
  };

export const getApplicableOfferTrackingProperties =
  (state: StateWithoutValue) => (props?: { isActive?: boolean }) => {
    return {
      offer_status: state.context[ParentState.wallet].offerStatus,
      // deferred is opposite of the offer being active
      all_discounts_deferred: !props.isActive,
    };
  };
