import { ReactNode } from "react";
import clsx from "clsx";

import "./styles.scss";

interface ISummaryCardProps {
  children: ReactNode;
  action?: ReactNode;
  className?: string;
}

export const SummaryCard = ({
  children,
  action,
  className,
}: ISummaryCardProps) => (
  <div className={clsx("summary-card", className)}>
    {children}
    {action && <div className="summary-card-action">{action}</div>}
  </div>
);
