import { axiosInstance } from "@hopper-b2b/api";
import {
  ResponseEnum,
  WatchAlertViews,
  airPriceWatchApi,
} from "@b2bportal/air-price-watch-api";

export const listWatches = (): Promise<WatchAlertViews> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const res = await airPriceWatchApi(
        axiosInstance
      ).apiV0AlertWatchListPut();
      if (res.data.Response === ResponseEnum.Success) {
        resolve(res.data.value);
      } else {
        reject(res.data);
      }
    } catch (e) {
      reject(e);
    }
  });
};
