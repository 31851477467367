import { Product, TrackingProperties } from "@b2bportal/purchase-api";
import { ParentState } from "../../../types";

import {
  DisruptionQuoteRequestV1,
  DisruptionUpdateStateTypeV1,
  DisruptionUpdateStateWithoutValueV1,
} from "./types";
import { FlightSelectors } from "../Flight";
import { DisruptionOffer } from "@b2bportal/air-disruption-api";

export const getDisruptionCartUpdateParams = (
  state: DisruptionUpdateStateWithoutValueV1
): DisruptionQuoteRequestV1 => {
  const selectedTrip = FlightSelectors.getSelectedTripParent(state);
  const tripId = selectedTrip.tripId ?? "";
  const fareId = selectedTrip.returnFareId || selectedTrip.outgoingFareId || "";
  const quoteIds = getDisruptionQuoteIds(state);
  return {
    quoteIds,
    tripId,
    fareId,
  };
};

export const getDisruptionQuoteIds = (
  state: DisruptionUpdateStateTypeV1 | DisruptionUpdateStateWithoutValueV1
): Array<string> =>
  state.context[ParentState.disruptionV1].selectedOffers?.reduce(
    (quoteIds, { quotes }) => [...quoteIds, ...quotes.map((q) => q.quoteId)],
    [] as Array<string>
  ) || [];

// export const getDisruptionFulfillTrackingProperties = (
//   state: DisruptionUpdateStateType | DisruptionUpdateStateWithoutValue
// ): TrackingProperties => {
//   const offersShown =
//     state.context[ParentState.disruptionV1].offersResponse || []; // TODO(nfang)
//   const mappedOfferedProducts = offersShown?.map(
//     (p) => p?.quotes?.[0]?.productType
//   );

//   const quotedProducts =
//     cartQuoteSelectors.getQuoteBreakdown(state)?.products || [];
//   const quotedDisruptionProduct = quotedProducts.find(
//     (p) => p?.product?.type === Product.AirDisruption
//   )?.product?.value as DisruptionOffer | undefined;

//   const getProtectionChoice = (
//     type: DisruptionProductType
//   ): 0 | 1 | undefined => {
//     if (mappedOfferedProducts.includes(type)) {
//       if (
//         quotedDisruptionProduct?.quotes.find(
//           (quote) => quote.productType === type
//         )
//       ) {
//         // fulfilled
//         return 1;
//       }
//       // offered
//       return 0;
//     }
//     return undefined;
//   };

/**
 * choice options
 * 0 = offered, not attached
 * 1 = attached/fulfilled
 * none = not offered to user
 */
//   return {
//     properties: {
//       delay_protection_choice: getProtectionChoice(
//         DisruptionProductType.ScheduleChange
//       ),
//       missed_connection_choice: getProtectionChoice(
//         DisruptionProductType.MissedConnection
//       ),
//     },
//   };
// };

export const getPurchasedDisruptionProducts = (
  state: DisruptionUpdateStateTypeV1 | DisruptionUpdateStateWithoutValueV1
) => {
  const products = state.context[ParentState.cartFulfill].fulfilledProducts;
  const purchasedDisruptionProducts = products?.find(
    (p) => p.type === Product.AirDisruption
  );
  const contractIds = purchasedDisruptionProducts?.value?.contractIds;
  const cartQuoteDisruptionProducts =
    state.context[ParentState.cartQuote]?.quoteBreakdown?.products;
  const quotedDisruptionProductTypes = cartQuoteDisruptionProducts?.find(
    (product) => product?.product?.type === Product.AirDisruption
  )?.product?.value?.quotes;
  const purchasedDisruptionProductTypes = quotedDisruptionProductTypes?.filter(
    (product) => contractIds.includes(product.quoteId)
  );

  return purchasedDisruptionProductTypes || null;
};

export const getSelectedDisruptionOffer = (
  state: DisruptionUpdateStateTypeV1 | DisruptionUpdateStateWithoutValueV1
): DisruptionOffer => state.context[ParentState.disruptionV1].selectedOffers[0]; // For now, we only provide one disruption offer per trip
