export enum PriceFreezeTrackingEvents {
  FREEZE_PRICE_CLICK = "freeze_price_click",
  PRICE_FREEZE_REVIEW_DETAILS = "price_freeze_review_details",
  CHANGED_DURATION = "changed_duration",
  EDIT_TRAVELERS = "edit_travelers",
  SELECTED_PRICE_FREEZE_PURCHASE = "selected_price_freeze_purchase",
}

export enum AirPriceFreezeExerciseTrackingEvents {
  B2B_VIEWED_AIR_PRICE_FREEZE = "b2b_viewed_air_price_freeze",
  B2B_ENTERED_AIR_PRICE_FREEZE_EXERCISE_FLOW = "b2b_entered_air_price_freeze_exercise_flow",
}

export interface ChoosePriceFreezeProperties {
  price_freeze_entry: PriceFreezeEntryEnum;
}

export type FreezePriceClickProperties = ChoosePriceFreezeProperties;

export enum PriceFreezeEntryEnum {
  FLIGHT_LIST = "flight_list",
  FORECAST = "forecast",
  TRIP_SUMMARY = "trip_summary",
  CHECKOUT_REVIEW = "checkout_review",
  PRICE_PREDICTION = "price_prediction",
}

export enum EntryButtonEnum {
  FREEZE_PRICE = "freeze_price",
  INFO_MODAL = "info_modal",
  CLP_MODAL = "CLP_modal",
  HOLD_PRICE = "hold_price",
}

export enum PreviousFlightEnum {
  CLP_FLIGHT = "CLP_flight",
  CHOSEN_FLIGHT = "chosen_flight",
}

export interface PriceFreezeHistoryState {
  priceFreezeEntry?: PriceFreezeEntryEnum;
  entryButton?: EntryButtonEnum;
  previousFlight?: PreviousFlightEnum;
}

export interface PriceFreezeReviewDetailsProps {
  price_freeze_entry?: PriceFreezeEntryEnum;
  entry_button?: EntryButtonEnum;
  previous_flight?: PreviousFlightEnum;
  price_freeze_duration?: number;
  price_freeze_otm_cap_usd?: number;
  // note: in power-loader, we have `price_freeze_total_cost_usd: number; = Field(coalesce=['price_freeze_total_cost'])`
  price_freeze_total_cost?: number;
  // note: in power-loader, we have `price_freeze_cost_per_pax_usd: number; = Field(coalesce=['price_freeze_cost_per_pax'])`
  price_freeze_cost_per_pax?: number;
  frozen_price_total_usd?: number;
}
