import { type Lodging } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { LodgingAddress } from "@hopper-b2b/ui";
import { Box, Divider, Typography } from "@material-ui/core";
import { getAddressText } from "../../../../util/utils";
import { StarRating } from "../../../availability/components/LodgingCard/components";
import { TripAdvisorReviewsBadge } from "../TripAdvisorReviewsBadge";
import styles from "./HotelHeader.module.scss";
import { ApacIcon, ApacIconName } from "@commbank/ui";

interface Props {
  lodging: Lodging;
  isMobile: boolean;
}

export function HotelHeader({
  lodging: {
    lodging: { name, starRating, address, tripAdvisorReviews },
  },
  isMobile,
}: Props) {
  const { t } = useI18nContext();

  return (
    <Box
      className="hotel-title-header"
      mt="2rem"
      display="flex"
      gridGap="1rem"
      flexWrap="wrap"
      alignItems="center"
    >
      <Box className={styles.container}>
        <Box className={styles.content}>
          <StarRating className={styles.rating} starRating={starRating} />
          {tripAdvisorReviews ? (
            <TripAdvisorReviewsBadge
              overAllScore={tripAdvisorReviews?.overallScore}
              reviewsCount={tripAdvisorReviews?.reviewsCount}
            />
          ) : null}
        </Box>
        <Divider />
        <Typography
          className={styles.name}
          style={{ lineHeight: 1 }}
          variant={"h1"}
        >
          {name}
        </Typography>
        <LodgingAddress
          address={getAddressText(address)}
          icon={<ApacIcon name={ApacIconName.LocationOutlined} />}
        />
      </Box>
    </Box>
  );
}
