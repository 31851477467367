import {
  ApacActionButton,
  ApacIcon,
  ApacIconComponent,
  ApacIconName,
  CalendarPicker,
  InputWrapper,
  PassengerCountPicker,
} from "@commbank/ui";
import { Suggestion } from "@b2bportal/lodging-api";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateForUrl } from "@hopper-b2b/common-utils";
import { useI18nContext } from "@hopper-b2b/i18n";
import { URL_PARAM_KEYS } from "@hopper-b2b/lodging-utils";
import { GuestsSelection, TripCategory } from "@hopper-b2b/types";
import {
  ActionLink,
  Divider,
  Header,
  IconComponent,
  IconName,
  InputButton,
} from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom-v5-compat";
import { CalendarPickerButton } from "../../components/CalendarPickerButton";
import {
  PATH_AVAILABILITY_RELATIVE,
  PATH_HOME,
  PATH_HOTELS_ROOT,
  ProductType,
  placeLabelToUrl,
  useTrackEvents,
} from "../../util";
import { GuestPicker } from "./GuestPicker";
import { HotelLocationAutoComplete } from "./HotelLocationAutocomplete";
import "./styles.scss";
import { MobileHotelSearchStep } from "./types";
import { ILodgingAvailabilityState } from "../availability/reducer";
import { LodgingShopTrackingEvents } from "@hopper-b2b/types";

export const APAC_MAX_LODGING_TRAVELLERS = 16;
export const APAC_MAX_LODGING_CHILDREN = 8;
export const APAC_MAX_ROOMS_COUNT = 4;

export type HotelSearchControlProps = {
  initialAdultGuests: number;
  showRooms: boolean;
  onOpen?: () => void;
  location?: string;
  place?: Suggestion;
  fromDate: string;
  untilDate: string;
  guests: GuestsSelection;
  rooms: number;
  setSearchParams: (
    stayValues: Pick<
      ILodgingAvailabilityState,
      "guests" | "rooms" | "fromDate" | "untilDate" | "location"
    >
  ) => void;
};

export const HotelSearchControl = ({
  initialAdultGuests,
  showRooms,
  onOpen,

  place,
  fromDate,
  untilDate,
  guests,
  rooms,

  setSearchParams,
}: HotelSearchControlProps) => {
  const { matchesMobile: isMobile } = useDeviceTypes();
  const { t } = useI18nContext();

  const [currentSearchParams] = useSearchParams();

  const fromPrevSearch = !!currentSearchParams.get(URL_PARAM_KEYS.PREV_SEARCH);

  const [currentStep, setCurrentStep] = useState<MobileHotelSearchStep>(
    (currentSearchParams.get(URL_PARAM_KEYS.STEP) as MobileHotelSearchStep) ??
      MobileHotelSearchStep.LocationSearch
  );

  // Autocomplete State
  const [destination, setDestination] = useState<Suggestion>(place);

  const [destinationError, setDestinationError] = useState<string>();

  // Guest State
  const [guestCount, setGuestCount] = useState<GuestsSelection>(
    guests || {
      adults: initialAdultGuests,
      children: [],
      rooms: 1,
    }
  );

  // Calendar State
  const [openCalendar, setOpenCalendar] = useState(false);
  const destinationCode = useMemo(
    () =>
      destination?.label ? placeLabelToUrl(destination?.label) : undefined,
    [destination]
  );

  const [checkinDate, setCheckinDate] = useState<Date | undefined>(
    fromDate ? new Date(fromDate) : undefined
  );
  const [checkinDateError, setCheckinDateError] = useState<string>();
  const [checkoutDate, setCheckoutDate] = useState<Date | undefined>(
    untilDate ? new Date(untilDate) : undefined
  );
  const [checkoutDateError, setCheckoutDateError] = useState<string>();

  // Url
  const searchUrl = () => {
    const searchParams = new URLSearchParams({
      [URL_PARAM_KEYS.FROM_DATE]: formatDateForUrl(dayjs(checkinDate)),
      [URL_PARAM_KEYS.UNTIL_DATE]: formatDateForUrl(dayjs(checkoutDate)),
    });

    const guests = {
      [URL_PARAM_KEYS.ADULTS_COUNT]: guestCount?.adults?.toString() ?? "",
      [URL_PARAM_KEYS.CHILDREN_COUNT]: guestCount.children.length.toString(),
      [URL_PARAM_KEYS.ROOMS_COUNT]: guestCount?.rooms?.toString() ?? "",
    };

    Object.keys(guests).forEach((key) => {
      const value: string = guests[key];
      if (value && value !== "0") {
        switch (key) {
          case URL_PARAM_KEYS.ROOMS_COUNT:
            if (showRooms) {
              searchParams.set(key, value);
            }
            break;
          default:
            searchParams.set(key, value);
        }
      }
    });

    return `${PATH_HOME}${PATH_HOTELS_ROOT}${PATH_AVAILABILITY_RELATIVE}${destinationCode}?${searchParams.toString()}`;
  };

  const navigate = useNavigate();

  const handleSearch = () => {
    if (!checkinDate) {
      setCheckinDateError(t("commBank.hotelSearch.checkinRequired"));
    }
    if (!checkoutDate) {
      setCheckoutDateError(t("commBank.hotelSearch.checkoutRequired"));
    }
    if (!destination) {
      setDestinationError(t("commBank.hotelSearch.locationRequired"));
    }
    if (destination && checkinDate && checkoutDate) {
      setSearchParams({
        guests: guestCount,
        rooms: guestCount.rooms,
        fromDate: formatDateForUrl(dayjs(checkinDate)),
        untilDate: formatDateForUrl(dayjs(checkoutDate)),
        location: destination.label,
      });

      const { properties, encryptedProperties } = createTrackingProperties({
        dest: destination,
        checkin: checkinDate,
        checkout: checkoutDate,
        guest: guestCount,
      });
      const firstLoad = sessionStorage.getItem("firstLoad");
      const entryEventParams = {
        first_launch: Boolean(firstLoad),
      };
      trackEvent(
        LodgingShopTrackingEvents.hotel_entry,
        ProductType.Hotel,
        { ...properties, ...entryEventParams },
        encryptedProperties
      );

      navigate(searchUrl());
      if (isMobile) {
        setCurrentStep(MobileHotelSearchStep.Complete);
      }
    }
  };

  const handleCalendarClick = useCallback(() => {
    if (openCalendar) {
      setOpenCalendar(false);
      const { properties, encryptedProperties } = createTrackingProperties({
        dest: destination,
        checkin: checkinDate,
        checkout: checkoutDate,
        guest: guestCount,
      });
      trackEvent(
        LodgingShopTrackingEvents.hotel_tapped_select_dates,
        ProductType.Hotel,
        properties,
        encryptedProperties
      );
    } else {
      setOpenCalendar(true);
      const { properties, encryptedProperties } = createTrackingProperties({
        dest: destination,
        checkin: checkinDate,
        checkout: checkoutDate,
        guest: guestCount,
      });
      trackEvent(
        LodgingShopTrackingEvents.hotel_viewed_calendar,
        ProductType.Hotel,
        properties,
        encryptedProperties
      );
    }
  }, [openCalendar]);

  const handleGoBack = useCallback(() => {
    if (currentStep === MobileHotelSearchStep.LocationSearch && !destination) {
      navigate(`${PATH_HOME}${PATH_HOTELS_ROOT}`);
    } else {
      setCurrentStep(MobileHotelSearchStep.Home);
    }
  }, [setCurrentStep, navigate, currentStep, destination]);

  // Tracking
  const trackEvent = useTrackEvents();

  const createTrackingProperties = useCallback(
    ({
      checkin = checkinDate,
      checkout = checkoutDate,
      guest = guestCount,
      dest = destination,
    }) => ({
      properties: {
        adults_count: guest.adults,
        children_count: guest.children.length,
        check_in_date: checkin
          ? dayjs(checkin).format("YYYY-MM-DD")
          : undefined,
        check_out_date: checkout
          ? dayjs(checkout).format("YYYY-MM-DD")
          : undefined,
        los: dayjs(checkout).diff(dayjs(checkin), "day") || null,
        ...dest?.trackingPropertiesV2.properties,
      },
      encryptedProperties: dest?.trackingPropertiesV2?.encryptedProperties
        ? [dest.trackingPropertiesV2.encryptedProperties]
        : [],
    }),
    [checkinDate, checkoutDate, guestCount, destination]
  );

  useEffect(() => {
    trackEvent(
      LodgingShopTrackingEvents.hotel_viewed_search,
      ProductType.Hotel,
      {}
    );
    sessionStorage.setItem("firstLoad", "true");
  }, []);

  return isMobile ? (
    <Box className="mobile-hotel-search-root">
      <Box
        className={clsx({
          "date-range-picker":
            currentStep === MobileHotelSearchStep.CalendarPicker,
        })}
      >
        <Header
          center={<HeaderCenterSection currentStep={currentStep} />}
          left={
            <ActionLink
              className={clsx("mobile-hotel-search-header-go-back", {
                hidden: currentStep === MobileHotelSearchStep.Home,
              })}
              onClick={handleGoBack}
              content={<FontAwesomeIcon icon={faChevronLeft as IconProp} />}
            />
          }
          right={
            <ActionLink
              className={clsx("mobile-hotel-search-header-close", {
                hide: currentStep !== MobileHotelSearchStep.Home,
              })}
              onClick={() => {
                fromPrevSearch
                  ? navigate(searchUrl())
                  : navigate(`${PATH_HOME}${PATH_HOTELS_ROOT}`);
                setCurrentStep(MobileHotelSearchStep.Home);
              }}
              content={
                <IconComponent
                  ariaLabel="Close button icon"
                  className={clsx("close-button-icon")}
                  name={IconName.Close}
                />
              }
              label={t("close.button")}
            />
          }
          isMobile={true}
          fullWidth={true}
        />

        <Box className="mobile-hotel-search-selection-container">
          {currentStep === MobileHotelSearchStep.LocationSearch && (
            <div className="location-autocomplete-container">
              <HotelLocationAutoComplete
                className="location"
                id="destination"
                label={undefined}
                onChange={(value) => {
                  setDestination(value);
                  setDestinationError("");
                  if (value) {
                    setCurrentStep(MobileHotelSearchStep.Home);
                    const { properties, encryptedProperties } =
                      createTrackingProperties({ dest: value });
                    trackEvent(
                      LodgingShopTrackingEvents.tapped_search_suggestion,
                      ProductType.Hotel,
                      properties,
                      encryptedProperties
                    );
                  }
                }}
                icon={undefined}
                defaultValue={destination}
                alwaysOpen
              />
            </div>
          )}
          {/* TODO: add max room validation */}
          {currentStep === MobileHotelSearchStep.Home && (
            <>
              <Box className="mobile-hotel-search-selection">
                <Box className="mobile-location-picker">
                  <ApacIcon name={ApacIconName.CBAHome} />
                  <Typography className="mobile-location-subtitle">
                    {t("hotelSearchPage.searchSubtitle")}
                  </Typography>
                  <InputWrapper error={destinationError}>
                    <InputButton
                      className={!destination ? "empty" : "selected"}
                      prefixString={
                        destination
                          ? destination.label
                          : t?.("whereAreYouStaying")
                      }
                      customEndIcon={
                        <ApacIconComponent
                          name={ApacIconName.MagnifyingGlass}
                          className={clsx("origin-icon")}
                        />
                      }
                      onClick={() =>
                        setCurrentStep(MobileHotelSearchStep.LocationSearch)
                      }
                    />
                  </InputWrapper>
                </Box>

                <Divider />

                <PassengerCountPicker
                  key="constant"
                  showRooms={showRooms}
                  minimumCount={1}
                  onUpdateCounts={(counts: GuestsSelection) => {
                    setGuestCount(counts);
                    const { properties, encryptedProperties } =
                      createTrackingProperties({ guest: counts });
                    trackEvent(
                      LodgingShopTrackingEvents.hotel_tapped_guests,
                      ProductType.Hotel,
                      properties,
                      encryptedProperties
                    );
                  }}
                  counts={guestCount}
                  titles={{
                    modalTitle: undefined,
                    adultTitle: t("passengerCount.adultTitle"),
                    adultSubtitle: undefined,
                    childrenTitle: t("passengerCount.childrenTitle"),
                    childrenSubtitle: t("commBank.guestCount.childrenSubtitle"),
                    roomsTitle: t("passengerCount.roomsTitle"),
                    roomsSubtitle: t("commBank.guestCount.roomsSubtitle"),
                    travellersWarning: t("passengerCount.travellersWarning", {
                      maxTravellers: APAC_MAX_LODGING_TRAVELLERS,
                    }),
                    maxPassengerCount: t(
                      "passengerCount.errors.maxPassengerCount",
                      {
                        maxTravellers: APAC_MAX_LODGING_TRAVELLERS,
                      }
                    ),
                  }}
                  maximumCount={APAC_MAX_LODGING_TRAVELLERS}
                  maximumChildren={APAC_MAX_LODGING_CHILDREN}
                  maximumRooms={APAC_MAX_ROOMS_COUNT}
                  warningMessage={null}
                />
              </Box>
              <div className="continue-button-container">
                <button
                  className="mobile-autocomplete-continue-button"
                  onClick={() => {
                    if (destination) {
                      setDestinationError("");
                      setCurrentStep(MobileHotelSearchStep.CalendarPicker);
                      const { properties, encryptedProperties } =
                        createTrackingProperties({
                          dest: destination,
                          checkin: checkinDate,
                          checkout: checkoutDate,
                          guest: guestCount,
                        });
                      trackEvent(
                        LodgingShopTrackingEvents.hotel_viewed_calendar,
                        ProductType.Hotel,
                        properties,
                        encryptedProperties
                      );
                    } else {
                      console.log("error");
                      setDestinationError("Please select location");
                    }
                  }}
                >
                  <Typography>{t("flightsPageTitles.selectDate")}</Typography>
                </button>
              </div>
            </>
          )}
        </Box>

        {currentStep === MobileHotelSearchStep.CalendarPicker && (
          <CalendarPicker
            key={1}
            tripCategory={TripCategory.ROUND_TRIP}
            departureDate={checkinDate}
            returnDate={checkoutDate}
            setDepartureDate={(v) => {
              setCheckinDate(v);
              setCheckinDateError("");
              const { properties, encryptedProperties } =
                createTrackingProperties({
                  checkin: v,
                  checkout: checkoutDate,
                  guest: guestCount,
                  dest: destination,
                });
              trackEvent(
                LodgingShopTrackingEvents.hotel_selected_calendar_date,
                ProductType.Hotel,
                properties,
                encryptedProperties
              );
            }}
            setReturnDate={(v) => {
              setCheckoutDate(v);
              setCheckoutDateError("");
              const { properties, encryptedProperties } =
                createTrackingProperties({
                  checkin: checkinDate,
                  checkout: v,
                  guest: guestCount,
                  dest: destination,
                });
              trackEvent(
                LodgingShopTrackingEvents.hotel_selected_calendar_date,
                ProductType.Hotel,
                properties,
                encryptedProperties
              );
            }}
            isMobile
            isHotel
            onComplete={() => {
              handleSearch();
            }}
          />
        )}
      </Box>
    </Box>
  ) : (
    <Box className="hotel-search-control">
      <HotelLocationAutoComplete
        className="search-location"
        id="destination"
        label={t?.("whereAreYouStaying")}
        onChange={(value) => {
          setDestination(value);
          setDestinationError("");
          const { properties, encryptedProperties } = createTrackingProperties({
            dest: value,
          });
          trackEvent(
            LodgingShopTrackingEvents.tapped_search_suggestion,
            ProductType.Hotel,
            properties,
            encryptedProperties
          );
        }}
        defaultValue={destination}
        onOpen={onOpen}
        error={destinationError}
      />

      <CalendarPickerButton
        classes={["dates"]}
        depDate={checkinDate}
        startDateError={checkinDateError}
        retDate={checkoutDate}
        endDateError={checkoutDateError}
        tripCategory={TripCategory.ROUND_TRIP}
        onClick={handleCalendarClick}
        openCalendar={openCalendar}
        setDepartureDate={(v) => {
          setCheckinDate(v);
          setCheckinDateError("");
          const { properties, encryptedProperties } = createTrackingProperties({
            checkin: v,
            checkout: checkoutDate,
            guest: guestCount,
            dest: destination,
          });
          trackEvent(
            LodgingShopTrackingEvents.hotel_selected_calendar_date,
            ProductType.Hotel,
            properties,
            encryptedProperties
          );
        }}
        setReturnDate={(v) => {
          setCheckoutDate(v);
          setCheckoutDateError("");
          const { properties, encryptedProperties } = createTrackingProperties({
            checkin: checkinDate,
            checkout: v,
            guest: guestCount,
            dest: destination,
          });
          trackEvent(
            LodgingShopTrackingEvents.hotel_selected_calendar_date,
            ProductType.Hotel,
            properties,
            encryptedProperties
          );
        }}
        closeCalendar={() => setOpenCalendar(false)}
        isHotel
        headerTitle={t?.("commBank.hotelCalendarPicker.title")}
        headerSubtitle={t?.("commBank.hotelCalendarPicker.subtitle")}
        useDialog
      />

      <div className="search-line-right">
        <GuestPicker
          className="guests"
          initialGuestCount={guestCount}
          updateGuestCount={(counts) => {
            setGuestCount(counts);
            const { properties, encryptedProperties } =
              createTrackingProperties({ guest: counts });
            trackEvent(
              LodgingShopTrackingEvents.hotel_tapped_guests,
              ProductType.Hotel,
              properties,
              encryptedProperties
            );
          }}
          showRooms={showRooms}
          useDialog
        />
        <ApacActionButton
          className="search-button"
          onClick={handleSearch}
          message={t?.("searchButton")}
        />
      </div>
    </Box>
  );
};

interface IHeaderCenterSectionProps {
  currentStep: string;
}

const HeaderCenterSection = (props: IHeaderCenterSectionProps) => {
  const { currentStep } = props;
  const { t } = useI18nContext();

  const getHeader = () => {
    switch (currentStep) {
      case MobileHotelSearchStep.LocationSearch:
        return t("hotelPageTitles.mobileSelectLocation");
      case MobileHotelSearchStep.CalendarPicker:
        return t("mobileSearchChooseDate");
      default:
        return t("hotelPageTitles.searchHotels");
    }
  };

  return (
    <Box className="header-center-section">
      <Typography>{getHeader()}</Typography>
    </Box>
  );
};
