import { postFetchFeatureFlags } from "../api";
import { ExperimentState, IApiConfig } from "@hopper-b2b/types";

export const initFeatureFlagsState = async (
  shouldFetchExperiments = false,
  apiConfig: IApiConfig,
  defaultInitState: ExperimentState
): Promise<ExperimentState> => {
  const fetchExperiments: () => Promise<ExperimentState> =
    shouldFetchExperiments
      ? () => postFetchFeatureFlags(apiConfig)
      : () => Promise.resolve(defaultInitState);

  return fetchExperiments();
};
