import { AirChfarOffer } from "@b2bportal/air-chfar-api";
import { CallState } from "@hopper-b2b/types";
import { actions, actionTypes } from "../actions";

export interface IFlightChfarState {
  chfarOfferCallState: CallState;
  chfarOffers?: AirChfarOffer[];
  selectedChfarOffer?: AirChfarOffer;
}

export const initialState: IFlightChfarState = {
  chfarOfferCallState: CallState.NotCalled,
  chfarOffers: undefined,
  selectedChfarOffer: undefined,
};

export function reducer(
  state: IFlightChfarState = initialState,
  action: actions.FlightChfarActions
): IFlightChfarState {
  switch (action.type) {
    case actionTypes.FETCH_CHFAR_OFFER:
      return {
        ...initialState,
        chfarOfferCallState: CallState.InProcess,
      };

    case actionTypes.SET_CHFAR_OFFER:
      return {
        ...state,
        chfarOffers: action.offers,
        chfarOfferCallState: CallState.Success,
        selectedChfarOffer: undefined,
      };

    case actionTypes.SET_CHFAR_OFFER_FAILED:
      return {
        ...state,
        chfarOfferCallState: CallState.Failed,
      };

    case actionTypes.SET_SELECTED_CHFAR_OFFER:
      return {
        ...state,
        selectedChfarOffer: action.selectedChfarOffer,
      };

    default:
      return state;
  }
}

export * from "./selectors";
