import { useEffect, Fragment } from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import queryStringParser from "query-string";
import dayjs from "dayjs";
import { Airline, Airport } from "@b2bportal/price-freeze-api";
import {
  PriceFreezeView,
  PriceFreezeStatusEnum,
  TripDetails,
  PRICE_FREEZE_ID_QUERY_PARAM,
  VIEWED_FROZEN_PRICES,
  ViewedFrozenPricesTrips,
  COMPLETE_BOOKING_PF,
  CompleteBookinPF,
} from "@hopper-b2b/types";
import {
  ActionButton,
  BannerSeverity,
  Icon,
  IconName,
  MyTripsCard,
  MyTripsMobileCard,
  NotificationBanner,
  TripInfoDetails,
} from "@hopper-b2b/ui";
import { useI18nCurrency } from "@hopper-b2b/i18n";
import { Divider } from "@material-ui/core";
import { trackEvent } from "@hopper-b2b/api";
import { PriceFreezeListConnectorProps } from "./container";
import { PATH_PRICE_FREEZE_OVERVIEW } from "../../../../utils/paths";
import * as textConstants from "./constants";
import "./styles.scss";

export interface IPriceFreezeListProps
  extends PriceFreezeListConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
}

export const getPiceFreezeCardDetails = (
  tripDetails: TripDetails,
  formatDate: (date: string) => string,
  airports: { [key: string]: Airport | undefined },
  airlines: { [key: string]: Airline | undefined }
) => {
  const departureSlice = tripDetails.slices[0];
  const returnSlice = tripDetails.slices[1];
  const originCode = departureSlice.originCode;
  const destinationCode = departureSlice.destinationCode;
  const airlineCode = departureSlice.segmentDetails[0].airlineCode;
  const airlineName =
    airlines[departureSlice.segmentDetails[0].marketingAirline.code]
      ?.displayName ?? airlineCode;
  return {
    title: `${airports[originCode]?.cityName || originCode} to ${
      airports[destinationCode]?.cityName || destinationCode
    }`,
    startDate: formatDate(departureSlice.departureTime),
    endDate: returnSlice ? formatDate(returnSlice.departureTime) : "",
    subtitle: returnSlice
      ? textConstants.ROUND_TRIP
      : textConstants.ONE_WAY_TRIP,
    airlineCode,
    airlineName,
    flightNumber:
      airlineCode +
        " " +
        departureSlice.segmentDetails[0].flightNumber.toString() ?? "",
  };
};

export function PriceFreezeList({
  isMobile,
  priceFreezes,
  airlines,
  airports,
  history,
}: IPriceFreezeListProps) {
  const { formatFiatCurrency } = useI18nCurrency();
  useEffect(() => {
    if (priceFreezes.length) {
      const activePF = priceFreezes.filter(
        (pf) => pf.status.Status === PriceFreezeStatusEnum.IsActive
      ).length;
      const expiredPF = priceFreezes.length - activePF;
      trackEvent({
        eventName: VIEWED_FROZEN_PRICES,
        properties: {
          price_freeze_active: activePF,
          price_freeze_expired: expiredPF,
        } as ViewedFrozenPricesTrips,
      });
    }
  }, [priceFreezes]);
  const formatExpiredDate = (date: string): string => {
    return `${textConstants.EXPIRES_ON} ${dayjs(date).format(
      "ddd MMM DD"
    )} at ${dayjs(date).format("hh:mm A")}`;
  };
  const renderExpirationBanner = (date: string) => {
    return (
      <NotificationBanner
        className="status-banner"
        label={textConstants.formatBanner(dayjs(date).format("ddd, MMM DD"))}
        severity={BannerSeverity.ERROR}
        icon={<Icon name={IconName.ErrorAlert} />}
      />
    );
  };

  const onFreezeAgain = (_pf: PriceFreezeView) => {
    // TODO: add redirect logic see: https://hopper-jira.atlassian.net/browse/CFTC-133
    return;
  };

  const onCompleteBooking = (id: string) => {
    trackEvent({
      eventName: COMPLETE_BOOKING_PF,
      properties: {
        price_freeze_id: id,
      } as CompleteBookinPF,
    });
    history.push({
      pathname: PATH_PRICE_FREEZE_OVERVIEW,
      state: {
        fromTrips: true,
      },
      search: queryStringParser.stringify({
        [PRICE_FREEZE_ID_QUERY_PARAM]: id,
      }),
    });
  };

  return (
    <Box className="price-freeze-list">
      {priceFreezes.map((pf: PriceFreezeView) => {
        const tripDetails = getPiceFreezeCardDetails(
          pf.tripDetails,
          (date: string) => dayjs(date).format(textConstants.DATE_FORMAT),
          airports,
          airlines
        );
        const isExpired = pf.status.Status === PriceFreezeStatusEnum.IsExpired;
        const banner = isExpired
          ? renderExpirationBanner(pf.priceFreeze.expiresAt)
          : undefined;
        const confirmationCodeClassName = isExpired ? "warning" : "";

        return isMobile ? (
          <MyTripsMobileCard
            key={pf.priceFreeze.id}
            confirmationLabel={textConstants.CONFIRMATION}
            startLabel={`${textConstants.DEPARTS_ON}`}
            label={textConstants.FROZEN_HEADER_MOBILE}
            confirmationCode={pf.priceFreeze.externalId.value}
            confirmationCodeClassName={confirmationCodeClassName}
            banner={banner}
            onClick={() => {
              isExpired
                ? onFreezeAgain(pf)
                : onCompleteBooking(pf.priceFreeze.id);
            }}
            actions={
              !isExpired ? (
                <Typography variant="subtitle1" className="expire-label">
                  {formatExpiredDate(pf.priceFreeze.expiresAt)}
                </Typography>
              ) : undefined
            }
            shouldShowCtaIcon={!isExpired} // show the arrow icon only if it's NOT expired
            {...tripDetails}
          />
        ) : (
          <Fragment key={pf.priceFreeze.id}>
            <Typography variant="body1" className="summary-label">
              {`${textConstants.FROZEN_HEADER}${dayjs(
                pf.tripDetails.slices[0].departureTime
              ).format("ddd, MMM D")}`}
            </Typography>
            <MyTripsCard
              actions={null}
              banner={banner}
              content={
                <TripInfoDetails
                  confirmationCodeClassName={confirmationCodeClassName}
                  titles={{
                    confirmationLabel: textConstants.CONFIRMATION,
                    startLabel: `${textConstants.DEPARTURE}:`,
                    endLabel: `${textConstants.RETURN}:`,
                    confirmationCode: pf.priceFreeze.externalId.value,
                    ...tripDetails,
                  }}
                />
              }
              cta={
                <Box className="ctas-section">
                  {!isExpired && (
                    <Typography variant="subtitle1" className="expire-label">
                      {formatExpiredDate(pf.priceFreeze.expiresAt)}
                    </Typography>
                  )}
                  {
                    // TODO: get rid of !isExpired check when we're ready, see https://hopper-jira.atlassian.net/browse/CFTC-133
                    !isExpired && (
                      <ActionButton
                        message={
                          !isExpired
                            ? textConstants.COMPLETE_BOOKING
                            : // TODO: this value might change once https://hopper-jira.atlassian.net/browse/CFTC-133
                              textConstants.freezeAgain(
                                formatFiatCurrency(
                                  pf.priceFreeze.offer.perPaxAmount.fiat
                                )
                              )
                        }
                        defaultStyle="h4r-primary"
                        onClick={() => {
                          isExpired
                            ? onFreezeAgain(pf)
                            : onCompleteBooking(pf.priceFreeze.id);
                        }}
                      />
                    )
                  }
                </Box>
              }
            />
            <Divider className="itinerary-card-divider" />
          </Fragment>
        );
      })}
    </Box>
  );
}
