import {
  AirRestrictionStatus,
  DullesAmenityCanExist,
  DullesUta,
  FareSliceDetails,
} from "@hopper-b2b/types";
import { IRestrictionProps } from "@hopper-b2b/ui";
import { camelCaseToTitleCase, capitalize } from "@hopper-b2b/utilities";

export const getFareAmenities = (fareSlice: FareSliceDetails) => {
  const policies: IRestrictionProps[] = [];
  policies.push(
    ...(fareSlice.amenitiesUtas?.utas.utas.map((uta: DullesUta) => {
      const restriction: IRestrictionProps = {
        // TODO: the assessment field should probably be an enum
        symbol:
          uta.assessment === "benefit"
            ? AirRestrictionStatus.INCLUDED
            : uta.assessment === "fee"
            ? AirRestrictionStatus.PURCHASABLE
            : AirRestrictionStatus.UNAVAILABLE,
        // TODO: probably need a different type for category
        description: `${uta.description}`,
        headline: uta.headline,
      };

      return restriction;
    }) ?? [])
  );

  if (fareSlice.amenitiesUtas?.amenities) {
    policies.push(
      ...Object.keys(fareSlice.amenitiesUtas.amenities)
        .filter((key) => {
          const amenitity: DullesAmenityCanExist = (
            fareSlice.amenitiesUtas?.amenities as any
          )?.[key];
          return !!amenitity.info.displayText;
        })
        .map((key) => {
          const amenitity: DullesAmenityCanExist = (
            fareSlice.amenitiesUtas?.amenities as any
          )?.[key];
          const symbol = amenitity.exists
            ? AirRestrictionStatus.INCLUDED
            : AirRestrictionStatus.GENERIC;
          return {
            symbol: symbol,
            description: `${capitalize(camelCaseToTitleCase(key))} - ${
              amenitity.info.displayText
            }`,
          };
        })
    );
  }
  return policies;
};
