import {
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Collapse, Typography } from "@material-ui/core";
import { useState } from "react";
import "./styles.scss";
import clsx from "clsx";

interface ExpandableSectionProps {
  title: string | JSX.Element;
  children: React.ReactNode;
  className?: string;
}

export const ExpandableSection = ({
  title,
  children,
  className,
}: ExpandableSectionProps) => {
  const [expanded, setExpandedCard] = useState(false);
  return (
    <Box className={clsx("expandable-section-container", "apac", className)}>
      <Box
        className="expandable-section-title-container"
        onClick={() => setExpandedCard(!expanded)}
      >
        <Typography variant="h4" className="expandable-section-title">
          {title}
        </Typography>
        <FontAwesomeIcon
          className="right-chevron"
          icon={expanded ? faChevronUp : faChevronDown}
        />
      </Box>
      <Collapse in={expanded} orientation="vertical">
        {children}
      </Collapse>
    </Box>
  );
};
