import { put } from "redux-saga/effects";

import fetchTripDetails from "../../../api/v0/exchange/fetchTripDetails";
import Logger from "../../../helpers/Logger";
import { actions as shopActions } from "../../shop/actions";
import { getTripDetails } from "../reducer/shop";

function* fetchTripDetailsSaga(action: ReturnType<typeof getTripDetails>) {
  try {
    const tripDetails = yield fetchTripDetails(action.payload);

    yield put(shopActions.setTripDetails(tripDetails));
  } catch (err) {
    Logger.debug(err);

    throw err;
  }
}

export default fetchTripDetailsSaga;
