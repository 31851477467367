import {
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
} from "@b2bportal/air-booking-api";
import {
  AirCfarExerciseScheduleFulfillResponse,
  PollFulfillAirCfarExerciseQuoteResponse,
  QuoteSuccess,
  ScheduleAirCfarExerciseQuoteResponseSuccess,
  ScheduleFulfillAirCfarExerciseQuoteResponse,
  AirCfarExerciseFailureEnum,
} from "@b2bportal/air-cfar-api";
import { HotelItinerary } from "@b2bportal/lodging-api";
import {
  CarReservation,
  CarItineraryResponseSuccess,
  CallState,
  MyTripsFilter,
  IOpenModal,
  PriceFreezeView,
  CfarExerciseProgress,
  DisruptionExerciseProgress,
  DisruptionExerciseProductType,
  ChfarExerciseProgress,
  DisruptionFulfillError,
} from "@hopper-b2b/types";
import { actions, actionTypes } from "../actions";
import {
  GetWatchAlertDetailsResponse,
  TripContext,
  WatchAlertView,
} from "@b2bportal/air-price-watch-api";
import { Slice } from "@b2bportal/air-shopping-api";
import {
  ITrackingProperties,
  ITrackingPropertiesRequest,
} from "@hopper-b2b/utilities";

export interface ITripsListState extends IWatchState {
  tripsFilter: MyTripsFilter;
  itineraries: { [key: string]: BookedFlightItineraryWithDepartureTime[] };
  hotelItineraries: { [key: string]: HotelItinerary[] };
  carItineraries: { [key: string]: CarReservation[] };
  airports: { [key: string]: Airport | undefined };
  priceFreezeAirports: { [key: string]: Airport | undefined };
  airlines: { [key: string]: Airline | undefined };
  priceFreezeAirlines: { [key: string]: Airline | undefined };
  selectedFlight: BookedFlightItineraryWithDepartureTime | null;
  selectedHotel: HotelItinerary | null;
  selectedCar: CarReservation | null;
  fetchFlightsCallState: CallState;
  fetchHotelsCallState: CallState;
  fetchCarsCallState: CallState;
  acceptScheduleChangeCallState: CallState;
  denyScheduleChangeCallState: CallState;
  openModal: IOpenModal;
  listPriceFreezeCallState: CallState;
  priceFreezes: Array<PriceFreezeView>;
  tripSearchResults:
    | BookedFlightItineraryWithDepartureTime
    | HotelItinerary
    | null;

  cfarExerciseProgress: CfarExerciseProgress;
  chfarExerciseProgress: ChfarExerciseProgress;
  disruptionExerciseProgress: DisruptionExerciseProgress;
  disruptionExerciseProductType: DisruptionExerciseProductType | null;
  cfarCancellationQuote: ScheduleAirCfarExerciseQuoteResponseSuccess | null;
  confirmFlightCfarCancellationResponse: ScheduleFulfillAirCfarExerciseQuoteResponse | null;
  pollFulfillFlightCfarCancellationResponse: PollFulfillAirCfarExerciseQuoteResponse | null;
  cfarCancellationQuoteV1: QuoteSuccess | null;
  confirmFlightCfarCancellationV1Response: AirCfarExerciseScheduleFulfillResponse | null;
  pollFulfillFlightCfarCancellationV1Response: PollFulfillAirCfarExerciseQuoteResponse | null;
  fetchFlightCfarCancellationQuoteCallState: CallState;
  confirmFlightCfarCancellationCallState: CallState;
  cancellationCompleted: boolean | null;
  disruptionRebookSelectedFlight: Slice | null;
  disruptionFulfillError: DisruptionFulfillError | null;
  cfarCancellationQuoteError?: AirCfarExerciseFailureEnum;
  cfarExerciseQuoteTrackingProperties?: ITrackingPropertiesRequest;
}

export interface IWatchState {
  watches: WatchAlertView[];
  selectedWatch: WatchAlertView | null;
  selectedWatchDetails: GetWatchAlertDetailsResponse | null;
  watchDetailsCallState: CallState;
  watchContext: TripContext | null;
  updateWatchCallState: CallState;
  listWatchCallState: CallState;
  deleteWatchCallState: CallState;
  createWatchCallState: CallState;
}

const initialState: ITripsListState = {
  tripsFilter: MyTripsFilter.UPCOMING_TRIPS,
  watchContext: null,
  selectedFlight: null,
  selectedHotel: null,
  selectedCar: null,
  itineraries: {},
  hotelItineraries: {},
  carItineraries: {},
  airports: {},
  priceFreezeAirports: {},
  airlines: {},
  priceFreezeAirlines: {},
  watches: [],
  selectedWatch: null,
  selectedWatchDetails: null,
  watchDetailsCallState: CallState.NotCalled,
  deleteWatchCallState: CallState.NotCalled,
  updateWatchCallState: CallState.NotCalled,
  listWatchCallState: CallState.NotCalled,
  createWatchCallState: CallState.NotCalled,
  listPriceFreezeCallState: CallState.NotCalled,
  fetchFlightsCallState: CallState.NotCalled,
  fetchHotelsCallState: CallState.NotCalled,
  fetchCarsCallState: CallState.NotCalled,
  acceptScheduleChangeCallState: CallState.NotCalled,
  denyScheduleChangeCallState: CallState.NotCalled,
  openModal: { type: null, selectedItinerary: null },
  priceFreezes: [],
  tripSearchResults: null,
  cfarExerciseProgress: CfarExerciseProgress.NotStarted,
  chfarExerciseProgress: ChfarExerciseProgress.NotStarted,
  disruptionExerciseProgress: DisruptionExerciseProgress.NotStarted,
  disruptionExerciseProductType: null,
  cfarCancellationQuote: null,
  cfarCancellationQuoteV1: null,
  confirmFlightCfarCancellationV1Response: null,
  pollFulfillFlightCfarCancellationV1Response: null,
  confirmFlightCfarCancellationResponse: null,
  pollFulfillFlightCfarCancellationResponse: null,
  fetchFlightCfarCancellationQuoteCallState: CallState.NotCalled,
  confirmFlightCfarCancellationCallState: CallState.NotCalled,
  cancellationCompleted: null,
  disruptionRebookSelectedFlight: null,
  disruptionFulfillError: null,
  cfarCancellationQuoteError: undefined,
};

export function reducer(
  state: ITripsListState = initialState,
  action: actions.TripsListActions
): ITripsListState {
  switch (action.type) {
    case actionTypes.SET_MY_TRIPS_FILTER: {
      const { tripsFilter } = action;
      return { ...state, tripsFilter };
    }
    case actionTypes.FETCH_FLIGHTS:
      return { ...state, fetchFlightsCallState: CallState.InProcess };

    case actionTypes.FETCH_FLIGHTS_FAILED:
      return { ...state, fetchFlightsCallState: CallState.Failed };

    case actionTypes.SET_FLIGHTS: {
      const { response } = action;
      return {
        ...state,
        fetchFlightsCallState: CallState.Success,
        ...response,
      };
    }
    case actionTypes.FETCH_HOTELS:
      return { ...state, fetchHotelsCallState: CallState.InProcess };

    case actionTypes.FETCH_HOTELS_FAILED:
      return { ...state, fetchHotelsCallState: CallState.Failed };

    case actionTypes.SET_HOTELS: {
      const { response: hotelResponse } = action;
      return {
        ...state,
        fetchHotelsCallState: CallState.Success,
        hotelItineraries: hotelResponse.itineraries,
      };
    }
    case actionTypes.FETCH_CARS:
      return { ...state, fetchCarsCallState: CallState.InProcess };

    case actionTypes.FETCH_CARS_FAILED:
      return { ...state, fetchCarsCallState: CallState.Failed };

    case actionTypes.SET_CARS: {
      const { response: carResponse } = action;
      return {
        ...state,
        fetchCarsCallState: CallState.Success,
        carItineraries: (carResponse as CarItineraryResponseSuccess)
          .reservations,
      };
    }
    case actionTypes.UPDATE_WATCH:
      return {
        ...state,
        updateWatchCallState: CallState.InProcess,
      };

    case actionTypes.SET_UPDATE_WATCH_CALL_STATE:
      return {
        ...state,
        updateWatchCallState: action.callState,
      };

    case actionTypes.DELETE_WATCH:
      return {
        ...state,
        deleteWatchCallState: CallState.InProcess,
      };

    case actionTypes.SET_DELETE_WATCH_CALL_STATE:
      return {
        ...state,
        deleteWatchCallState: action.callState,
      };

    case actionTypes.ACCEPT_SCHEDULE_CHANGE:
      return {
        ...state,
        acceptScheduleChangeCallState: CallState.InProcess,
      };

    case actionTypes.DENY_SCHEDULE_CHANGE:
      return {
        ...state,
        denyScheduleChangeCallState: CallState.InProcess,
      };

    case actionTypes.SET_ACCEPT_SCHEDULE_CHANGE_CALL_STATE:
      return {
        ...state,
        acceptScheduleChangeCallState: action.callState,
      };

    case actionTypes.SET_DENY_SCHEDULE_CHANGE_CALL_STATE:
      return {
        ...state,
        denyScheduleChangeCallState: action.callState,
      };

    case actionTypes.LIST_WATCHES:
      return {
        ...state,
        listWatchCallState: CallState.InProcess,
      };

    case actionTypes.LIST_PRICE_FREEZE:
      return {
        ...state,
        listPriceFreezeCallState: CallState.InProcess,
      };

    case actionTypes.SET_LIST_PRICE_FREEZE_CALL_STATE:
      return {
        ...state,
        listPriceFreezeCallState: action.callState,
      };

    case actionTypes.SET_PRICE_FREEZE:
      return {
        ...state,
        priceFreezes: action.priceFreezes || [],
        priceFreezeAirlines: action.tripContext.airlines,
        priceFreezeAirports: action.tripContext.airports,
      };

    case actionTypes.SET_LIST_WATCHES_CALL_STATE:
      return {
        ...state,
        listWatchCallState: action.callState,
      };

    case actionTypes.SET_WATCHES:
      return {
        ...state,
        watchContext: action.watches.tripContext,
        watches: action.watches.watches || [],
      };

    case actionTypes.SET_SELECTED_FLIGHT:
      return {
        ...state,
        selectedFlight: action.selectedFlight,
      };

    case actionTypes.SET_OPEN_MODAL:
      return {
        ...state,
        openModal: action.openModal,
      };

    case actionTypes.SET_SELECTED_HOTEL:
      return {
        ...state,
        selectedHotel: action.selectedHotel,
      };

    case actionTypes.SET_SELECTED_CAR:
      return {
        ...state,
        selectedCar: action.selectedCar,
      };

    case actionTypes.SET_CFAR_EXERCISE_PROGRESS:
      return {
        ...state,
        cfarExerciseProgress: action.cfarExerciseProgress,
      };

    case actionTypes.SET_CHFAR_EXERCISE_PROGRESS:
      return {
        ...state,
        chfarExerciseProgress: action.chfarExerciseProgress,
      };

    case actionTypes.SET_DISRUPTION_EXERCISE_PROGRESS:
      return {
        ...state,
        disruptionExerciseProgress: action.disruptionExerciseProgress,
      };

    case actionTypes.SET_DISRUPTION_EXERCISE_PRODUCT_TYPE:
      return {
        ...state,
        disruptionExerciseProductType: action.disruptionExerciseProductType,
      };

    case actionTypes.SET_FLIGHT_CFAR_CANCELLATION_QUOTE:
      return {
        ...state,
        fetchFlightCfarCancellationQuoteCallState: CallState.Success,
        cfarCancellationQuote: action.response,
      };

    case actionTypes.SET_FLIGHT_CFAR_CANCELLATION_QUOTE_V1:
      return {
        ...state,
        fetchFlightCfarCancellationQuoteCallState: CallState.Success,
        cfarCancellationQuoteV1: action.response,
      };

    case actionTypes.SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_CALL_STATE_FAILED:
      return {
        ...state,
        fetchFlightCfarCancellationQuoteCallState: CallState.Failed,
        cfarCancellationQuote: null,
        cfarCancellationQuoteV1: null,
      };

    case actionTypes.CONFIRM_FLIGHT_CFAR_CANCELLATION:
      return {
        ...state,
        confirmFlightCfarCancellationResponse: null,
        confirmFlightCfarCancellationV1Response: null,
        confirmFlightCfarCancellationCallState: CallState.InProcess,
      };

    case actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_RESPONSE:
      return {
        ...state,
        confirmFlightCfarCancellationResponse: action.response,
        confirmFlightCfarCancellationCallState: CallState.Success,
      };

    case actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_V1_RESPONSE:
      return {
        ...state,
        confirmFlightCfarCancellationV1Response: action.response,
        confirmFlightCfarCancellationCallState: CallState.Success,
      };

    case actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_FAILED:
      return {
        ...state,
        confirmFlightCfarCancellationCallState: CallState.Failed,
      };

    case actionTypes.CONFIRM_FLIGHT_CFAR_CANCELLATION_V1:
      return {
        ...state,
        confirmFlightCfarCancellationResponse: null,
        confirmFlightCfarCancellationV1Response: null,
        confirmFlightCfarCancellationCallState: CallState.InProcess,
      };

    case actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_NOT_CALLED:
      return {
        ...state,
        confirmFlightCfarCancellationCallState: CallState.NotCalled,
      };

    case actionTypes.SET_POLL_FULFILL_FLIGHT_CFAR_CANCELLATION_RESPONSE:
      return {
        ...state,
        pollFulfillFlightCfarCancellationResponse: action.response,
      };

    case actionTypes.RESET_CFAR_EXERCISE_STATE:
      return {
        ...state,
        cfarExerciseProgress: CfarExerciseProgress.NotStarted,
        cfarCancellationQuote: null,
        cfarCancellationQuoteV1: null,
        confirmFlightCfarCancellationResponse: null,
        confirmFlightCfarCancellationV1Response: null,
        pollFulfillFlightCfarCancellationResponse: null,
        pollFulfillFlightCfarCancellationV1Response: null,
        fetchFlightCfarCancellationQuoteCallState: CallState.NotCalled,
        confirmFlightCfarCancellationCallState: CallState.NotCalled,
      };

    case actionTypes.RESET_CHFAR_EXERCISE_STATE:
      return {
        ...state,
        chfarExerciseProgress: ChfarExerciseProgress.NotStarted,
      };

    case actionTypes.RESET_DISRUPTION_EXERCISE_STATE:
      return {
        ...state,
        disruptionExerciseProgress: DisruptionExerciseProgress.NotStarted,
      };

    case actionTypes.GET_WATCH_DETAILS:
      return {
        ...state,
        watchDetailsCallState: CallState.InProcess,
      };

    case actionTypes.SET_GET_WATCH_DETAILS_CALL_STATE:
      return {
        ...state,
        watchDetailsCallState: action.callState,
      };
    case actionTypes.SET_SELECTED_WATCH:
      return {
        ...state,
        selectedWatch: action.watch,
      };
    case actionTypes.SET_SELECTED_WATCH_DETAILS:
      return {
        ...state,
        selectedWatchDetails: action.watchDetails,
      };
    case actionTypes.SET_CREATE_WATCH_CALL_STATE:
      return {
        ...state,
        createWatchCallState: action.callState,
      };
    case actionTypes.SET_CANCELLATION_COMPLETED:
      return {
        ...state,
        cancellationCompleted: action.cancellationStatus,
      };

    case actionTypes.SET_DISRUPTION_REBOOK_SELECTED_FLIGHT:
      return {
        ...state,
        disruptionRebookSelectedFlight: action.disruptionRebookSelectedFlight,
      };

    case actionTypes.SET_DISRUPTION_FULFILL_ERROR:
      return {
        ...state,
        disruptionFulfillError: action.disruptionFulfillError,
      };

    case actionTypes.SET_TRIP_SEARCH_RESULTS:
      return {
        ...state,
        tripSearchResults: action.tripSearchResults,
      };
    case actionTypes.SET_FLIGHT_CONTEXT: {
      const newState: ITripsListState = {
        ...state,
        airlines: {
          ...action.context.airlines,
        },
        airports: {
          ...action.context.airports,
        },
      };

      return newState;
    }

    case actionTypes.SET_FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_ERROR:
      return {
        ...state,
        cfarCancellationQuoteError: action.cfarCancellationQuoteError,
      };

    case actionTypes.SET_CFAR_EXERCISE_QUOTE_TRACKING_PROPERTIES:
      return {
        ...state,
        cfarExerciseQuoteTrackingProperties: action.properties,
      };
    default:
      return state;
  }
}

export * from "./selectors";
