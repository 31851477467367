import { MouseEvent, useState } from "react";
import { Box, Typography, Dialog, Link } from "@material-ui/core";
import {
  PriceDropProtection as IPriceDropProtection,
  PriceDropProtectionEnum,
  PRICE_DROP_PROTECTION_BODY,
} from "@hopper-b2b/types";
import {
  Icon,
  IconName,
  PriceDropProtection,
  MobilePopoverCard,
  ActionLink,
  CloseButtonIcon,
  PriceDropProtectionImage,
  MobilePriceDropProtectionImage,
} from "@hopper-b2b/ui";
import { PATH_PRICE_DROP_PROTECTION } from "../../../../../utils/paths";
import * as textConstants from "./constants";
import "./styles.scss";

interface IPriceDropProtectionLinkProps {
  priceDropProtection: IPriceDropProtection;
  isMobile: boolean;
}

export const PriceDropProtectionLink = (
  props: IPriceDropProtectionLinkProps
) => {
  const { priceDropProtection, isMobile } = props;
  const [priceDropProtectionModalOpen, setPriceDropProtectionModalOpen] =
    useState<boolean>(false);

  const renderClosePDPButton = () => {
    return (
      <ActionLink
        className="price-drop-protection-close-button"
        onClick={() => setPriceDropProtectionModalOpen(false)}
        content={
          <img
            src={CloseButtonIcon}
            className="close-button-icon"
            alt="close button icon"
          />
        }
        label="Close"
        showTappableArea={true}
      />
    );
  };

  switch (priceDropProtection.PriceDropProtection) {
    case PriceDropProtectionEnum.IsEligible:
      return (
        <>
          <Link
            className="watch-list-price-drop-protection-link"
            onClick={(event: MouseEvent) => {
              event.stopPropagation();
              setPriceDropProtectionModalOpen(true);
            }}
          >
            <Typography variant="h6" className="price-drop-protection-text">
              {textConstants.PRICE_DROP_LINK_LABEL}
              <Icon name={IconName.InfoCircle} />
            </Typography>
          </Link>
          {isMobile ? (
            <MobilePopoverCard
              topRightButton={renderClosePDPButton()}
              open={priceDropProtectionModalOpen}
              className="mobile-watch-list-price-drop-protection-popup"
              contentClassName="mobile-watch-list-flight-price-drop-protection-content-wrapper"
              onClose={() => setPriceDropProtectionModalOpen(false)}
            >
              <PriceDropProtection
                className="watch-list-price-drop-protection-card"
                image={MobilePriceDropProtectionImage}
                title={textConstants.PDP_TITLE}
                subtitle={textConstants.getSubtitle(priceDropProtection)}
                header={textConstants.PDP_HEADER}
                ctaText={textConstants.PDP_CTA_TEXT}
                isMobile={true}
                mobileTermsConditionCopy={PRICE_DROP_PROTECTION_BODY}
              />
            </MobilePopoverCard>
          ) : (
            <Dialog
              open={priceDropProtectionModalOpen}
              className="desktop-watch-list-price-drop-protection-popup"
              onClose={() => setPriceDropProtectionModalOpen(false)}
            >
              <Box className="watch-list-price-drop-protection-wrapper">
                <PriceDropProtection
                  className="watch-list-price-drop-protection-card"
                  image={PriceDropProtectionImage}
                  title={textConstants.PDP_TITLE}
                  onClick={() =>
                    window.open(PATH_PRICE_DROP_PROTECTION, "_blank")
                  }
                  onClose={() => setPriceDropProtectionModalOpen(false)}
                  subtitle={textConstants.getSubtitle(priceDropProtection)}
                  header={textConstants.PDP_HEADER}
                  ctaText={textConstants.PDP_CTA_TEXT}
                />
              </Box>
            </Dialog>
          )}
        </>
      );
    default:
      return null;
  }
};
