import { ReactElement, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { Airport } from "@b2bportal/air-shopping-api";
import clsx from "clsx";
import dayjs from "dayjs";

import { FlightItinerarySlice, TripSegment } from "@hopper-b2b/types";
import { formatInterval, removeTimezone } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import { FareSliceDetails } from "@hopper-b2b/types";
import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";

import { AirlineIcon } from "../AirlineIcon";
import { IconComponent } from "../IconComponent";
import { IconName } from "../Icon";
import "./styles.scss";

export interface IFlightDetailsSummaryExtendedProps {
  className?: string;
  departureTime: string;
  onClick?: () => void;
  fareClass: string;
  segments: TripSegment[];
  airports?: { [key: string]: Airport };
  plusDays?: number;
  fareSlice?: FareSliceDetails;
  isMixedCabinClass?: boolean;
  tripSlice?: FlightItinerarySlice;
  assets?: Record<string, any>;
}

const renderMixedCabinClass = (
  idx: number,
  fareClass: string,
  fareSlice?: FareSliceDetails,
  tripSlice?: FlightItinerarySlice
) => {
  const segment = fareSlice
    ? fareSlice.fareDetails.segments[idx]
    : tripSlice
    ? tripSlice.segments[idx]
    : undefined;
  if (segment) {
    if (segment.cabinClassName && segment.cabinClassName.includes("Economy")) {
      return segment.cabinClassName;
    } else {
      return fareClass;
    }
  } else {
    return fareClass;
  }
};

export const FlightDetailsSummaryExtended = ({
  className,
  fareClass,
  segments,
  departureTime,
  plusDays,
  fareSlice,
  isMixedCabinClass,
  tripSlice,
  airports,
  assets,
}: IFlightDetailsSummaryExtendedProps): ReactElement => {
  const { t, language: locale } = useI18nContext();
  const content = useMemo(() => {
    return {
      operatedBy: (segment: TripSegment) =>
        t("operatedBy", {
          airline: segment.operatingAirline.name,
        }),
      layoverWithLocation: (segment: TripSegment) =>
        t("layoverWithLocation", {
          duration: formatInterval(segment.stopoverDurationMinutes || 0),
          location: segment.destinationCode,
        }),
      flightNextDay: t("flightNextDay"),
    };
  }, [t]);

  const renderTripSlice = () => {
    return (
      <Box className="trip-slice">
        {segments.map((segment: TripSegment, index: number) => {
          const hasDifferentOperatingAirline =
            segment.operatingAirline?.code !== segment.marketingAirline?.code;
          const hasStopoverAfter = segments.length - 1 > index;
          const hasStopoverBefore = index > 0;
          const layoverDuration = formatInterval(
            dayjs(segment.arrivalTime).diff(
              dayjs(segment.departureTime),
              "minutes"
            )
          );

          const formattedDepartureStringLong = formatDateTime(
            new Date(removeTimezone(departureTime)),
            locale,
            DateTimeFormatStyle.ShortMonthDayShortWeekday
          );

          const formattedArrivalStringLong = formatDateTime(
            new Date(removeTimezone(segment.arrivalTime)),
            locale,
            DateTimeFormatStyle.ShortMonthDayShortWeekday
          );

          const formattedDepartureStringShort = formatDateTime(
            new Date(removeTimezone(segment.departureTime)),
            locale,
            DateTimeFormatStyle.MonthDay
          );

          const formattedArrivalStringShort = formatDateTime(
            new Date(removeTimezone(segment.arrivalTime)),
            locale,
            DateTimeFormatStyle.MonthDay
          );

          const formattedDepartureTimeString = formatDateTime(
            new Date(removeTimezone(segment.departureTime)),
            locale,
            DateTimeFormatStyle.ShortTime
          );

          const formattedArrivalTimeString = formatDateTime(
            new Date(removeTimezone(segment.arrivalTime)),
            locale,
            DateTimeFormatStyle.ShortTime
          );

          return (
            <Box
              className="trip-slice-container"
              key={`trip-segment-${segment.flightNumber}-${index}`}
            >
              {hasStopoverBefore ? null : (
                <div className="flight-details-outbound-date">
                  {assets && assets["airplane"] ? (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img
                      className="flight-details-outbound-logo"
                      src={
                        assets["flightDetailsModalOutbound"] ||
                        assets["airplane"]
                      }
                    />
                  ) : (
                    <IconComponent
                      className="flight-details-outbound-logo"
                      name={IconName.B2BAirplaneIcon}
                    />
                  )}
                  {formattedDepartureStringLong}
                </div>
              )}
              <Box
                className={`flight-details-time-info trip-segment-${segment.flightNumber}`}
              >
                <Box className="flight-time-info-wrapper">
                  <div className="time-info-line"></div>
                  <div className={"flight-time-info-container"}>
                    <div className="time-details flight-details-departure">
                      <div className="flight-details-departure-info">
                        <div className="flight-details-departure-location">
                          <p className="flight-details-departure-location-name">
                            {airports
                              ? airports[segment.originCode]?.cityName
                              : ""}
                          </p>
                          <p className="flight-details-departure-location-code">
                            {segment.originCode}
                          </p>
                        </div>
                        <div className="flight-details-departure-time">
                          <p className="flight-details-departure-time-time">
                            {formattedDepartureTimeString}
                          </p>
                          <p className="flight-details-departure-time-date">
                            {formattedDepartureStringShort}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flight-details-info">
                      <div className="flight-details-info-airline">
                        <div className="flight-details-info-airline-container">
                          <div className="airline-details airline-icon-container">
                            <AirlineIcon
                              airlineCode={segment.airlineCode}
                              altText={segment.airlineName}
                            />
                          </div>
                          <div className="flight-info airline-info">
                            {`${segment.airlineName} / ${segment.airlineCode}
                  ${segment.flightNumber}`}
                          </div>
                        </div>
                        <div className="fare-details">
                          {isMixedCabinClass
                            ? (fareSlice || tripSlice) &&
                              renderMixedCabinClass(
                                index,
                                fareClass,
                                fareSlice,
                                tripSlice
                              )
                            : fareClass}
                        </div>
                        {hasDifferentOperatingAirline && (
                          <Typography className="operating-airline-info">
                            {content.operatedBy(segment)}
                          </Typography>
                        )}
                      </div>
                      <div
                        className={clsx("flight-details-info-duration", {
                          sm: layoverDuration.length < 6,
                          med: layoverDuration.length === 6,
                          lg: layoverDuration.length > 6,
                        })}
                      >
                        {layoverDuration}
                      </div>
                    </div>
                    <div className="time-details flight-details-arrival">
                      <div className="flight-details-arrival-info">
                        <div className="flight-details-arrival-location">
                          <p className="flight-details-arrival-location-name">
                            {airports
                              ? airports[segment.destinationCode]?.cityName
                              : ""}
                          </p>
                          <p className="flight-details-arrival-location-code">
                            {segment.destinationCode}
                          </p>
                        </div>
                        <div className="flight-details-arrival-time">
                          <p className="flight-details-arrival-time-time">
                            {formattedArrivalTimeString}
                          </p>
                          <p className="flight-details-arrival-time-date">
                            {formattedArrivalStringShort}
                          </p>
                        </div>
                      </div>
                    </div>
                    <span className="trip-timing-line" />
                  </div>
                </Box>
              </Box>
              {hasStopoverAfter ? null : (
                <div
                  className={clsx("flight-details-inbound-date", {
                    nextDay: !!plusDays,
                  })}
                >
                  {assets && assets["locationMarker"] ? (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img
                      className="flight-details-inbound-logo"
                      src={
                        assets["flightDetailsModalInbound"] ||
                        assets["locationMarker"]
                      }
                    />
                  ) : (
                    <IconComponent
                      className="flight-details-inbound-logo"
                      name={IconName.B2BMapPin}
                    />
                  )}
                  {formattedArrivalStringLong}
                </div>
              )}
              {hasStopoverAfter && (
                <div className="stopover-container">
                  {assets && assets["flightDetailsModalStopover"] ? (
                    <img
                      className="stopover-icon"
                      src={assets["flightDetailsModalStopover"]}
                      alt=""
                    />
                  ) : (
                    <IconComponent
                      className="stopover-icon"
                      name={IconName.FilledClock}
                    />
                  )}
                  <Typography variant="subtitle2" className="stopover-details">
                    {content.layoverWithLocation(segment)}
                  </Typography>
                </div>
              )}
            </Box>
          );
        })}

        {!!plusDays && (
          <div className="next-day-container">
            <IconComponent className="alert-icon" name={IconName.CircleAlert} />
            <Typography variant="body2" className="plus-days">
              {content.flightNextDay}
            </Typography>
          </div>
        )}
      </Box>
    );
  };

  return (
    <Box className={clsx("flight-details-summary-mini", className)}>
      <Box className="flight-details-summary-mini-container">
        {renderTripSlice()}
      </Box>
    </Box>
  );
};

export default FlightDetailsSummaryExtended;
