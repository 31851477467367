import { Airport } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { FareDetails, TripDetails } from "@hopper-b2b/types";
import {
  formatInterval,
  getEmptyRestrictionsText,
  getIsMixedClass,
  getPlusDays,
  getSliceIndex,
  removeTimezone,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { FlightDetailsTimeline } from "../../../../../../../../components/FlightDetailsTimeline";
import { FareDetailsCard } from "../../FareDetailsCard";
import "./DesktopFlightCardDetails.styles.scss";
import { DesktopFlightCardDetailsConnectorProps } from "./container";

export interface IDesktopFlightCardDetailsProps
  extends DesktopFlightCardDetailsConnectorProps {
  isOutgoing: boolean;
  isRoundTrip: boolean;
  selectedFareId: string;
  onFareClick: (sliceId: string, fare?: FareDetails) => void;
  onAlgomerchClick?: (label: string) => void;
  tripDetails: TripDetails;
  rewardsKey: string | undefined;
  departureDate: Date | undefined | null;
  returnDate: Date | undefined | null;
  airports: { [key: string]: Airport };
  openFlightDetailModal: boolean;
  clickedFareId: string;
  setClickedFareId: (fareId: string) => void;
  setExpandedFlight: (fareId: string) => void;
  expanded?: boolean;
}

export const DesktopFlightCardDetails = (
  props: IDesktopFlightCardDetailsProps
) => {
  const {
    tripDetails,
    isOutgoing,
    isRoundTrip,
    selectedFareId,
    onFareClick,
    airports,
    selectedFareClassFilters,
    clickedFareId,
    getFareSliceByFareId,
    onAlgomerchClick,
  } = props;

  const { matchesMobile } = useDeviceTypes();
  const { t } = useI18nContext();
  const [isMixedCabinClass, setIsMixedCabinClass] = useState(false);
  const [fareDetails, setFareDetails] = useState<FareDetails | undefined>(
    undefined
  );

  const tripSlice = isOutgoing ? tripDetails.slices[0] : tripDetails.slices[1];

  const plusDays = useMemo(() => {
    if (tripSlice) {
      return getPlusDays(tripSlice);
    } else {
      return 0;
    }
  }, [tripSlice]);

  const fareSlice = useMemo(() => {
    return fareDetails
      ? fareDetails.slices[getSliceIndex(isOutgoing, fareDetails)]
      : null;
  }, [fareDetails, isOutgoing]);

  useEffect(() => {
    if (selectedFareId || clickedFareId) {
      setFareDetails(
        tripDetails.fareDetails.find(
          (f) => f.id === selectedFareId || f.id === clickedFareId
        )
      );
    }
  }, [selectedFareId, clickedFareId, tripDetails.fareDetails]);

  useEffect(() => {
    if (fareDetails) {
      isOutgoing
        ? setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[0]))
        : setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[1]));
    }
  }, [fareDetails, isOutgoing]);

  return (
    <div className="desktop-flight-card-details-container">
      <Box className="flight-details-flights-container">
        <div className="summary-title">
          <Typography variant="h3">
            {t("flightShop.cardDetailsTitle", {
              fromCity: `${airports[tripSlice.originCode].cityName} (${
                tripSlice.originCode
              })`,
              toCity: `${airports[tripSlice.destinationCode].cityName} (${
                tripSlice.destinationCode
              })`,
              date: dayjs(removeTimezone(tripSlice.departureTime)).format(
                "ddd DD MMM YY"
              ),
              duration: formatInterval(tripSlice.totalDurationMinutes),
            })}
          </Typography>
          {isRoundTrip && (
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: t("flightShop.cardDetailsSubtitle", {
                  flightType: t(isOutgoing ? "outbound" : "return"),
                  city: airports[tripSlice.destinationCode].cityName,
                  airportCode: tripSlice.destinationCode,
                  date: dayjs(removeTimezone(tripSlice.departureTime)).format(
                    "ddd, MMM D"
                  ),
                }),
              }}
            />
          )}
        </div>

        <div className="segments-container">
          <FlightDetailsTimeline
            className="review-itinerary-flight-details"
            segments={tripSlice.segmentDetails}
            departureTime={tripSlice.departureTime}
            planeInfo={
              fareSlice?.amenitiesUtas?.amenities?.aircraft.info.displayText
            }
            fareClass={fareSlice?.fareShelf?.shortBrandName || ""}
            showTitle={false}
            plusDays={plusDays}
            fareSlice={fareSlice}
            isMixedCabinClass={isMixedCabinClass}
          />
        </div>
      </Box>

      <div className="flight-details-fares-container">
        <FareDetailsCard
          isMobile={matchesMobile}
          fareDetails={tripDetails.fareDetails}
          isOutgoing={isOutgoing}
          getEmptyRestrictionsText={getEmptyRestrictionsText}
          onAlgomerchClick={onAlgomerchClick}
          selectedFareClassFilters={selectedFareClassFilters}
          getFlightShopFareSlice={getFareSliceByFareId}
          onClick={(fareDetails) => onFareClick(tripSlice.id, fareDetails)}
        />
      </div>
    </div>
  );
};
