import { Box, Typography } from "@material-ui/core";

import { useI18nContext } from "@hopper-b2b/i18n";

export interface IContactSupportProps {
  // generic prop to be filled later if needed
  [prop: string]: unknown;
}

const defaultProps: Partial<IContactSupportProps> = {};

const ContactSupport = (_props: IContactSupportProps): JSX.Element => {
  const { brand, t } = useI18nContext();
  const { displaySupportNumber, supportNumber } = brand.contactSupport;

  return (
    <Box className="mobile-contact-support-popup-content">
      <Typography variant="h2" className="content-header">
        {t("customerSupport")}
      </Typography>
      <Typography variant="h4" className="content-text">
        {t("contactSupportSubtitle")}
      </Typography>
      <Typography variant="h2" className="content-phone">
        <a href={`tel:${supportNumber}`}>{displaySupportNumber}</a>
      </Typography>
    </Box>
  );
};

ContactSupport.defaultProps = defaultProps;

export default ContactSupport;
