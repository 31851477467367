import { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  BackButton,
  IconComponent,
  IconName,
  ActionLink,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import { ClientName, TripCategory } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  getEnvVariables,
  tenantFlagsEnabled,
  useEnableDarkMode,
} from "@hopper-b2b/utilities";
import { getDarkModePreferred } from "@hopper-b2b/themes";
import { MobileFlightSearchConnectorProps } from "./container";
import "./styles.scss";
import { CalendarPicker } from "@commbank/ui";

export interface ICalendarModalProps
  extends MobileFlightSearchConnectorProps,
    RouteComponentProps {
  open: boolean;
  onClose: () => void;
  onGoBack: () => void;
  departureDate: Date | null;
  returnDate: Date | null;
  setDepartureDate: (value: Date | null) => void;
  setReturnDate: (value: Date | null) => void;
  handleSearch: () => void;
  originCode: string;
  destinationCode: string;
}

export const CalendarModal = (props: ICalendarModalProps) => {
  const {
    originCode,
    destinationCode,
    fetchDepartureCalendar,
    setCalendar,
    open,
    onClose,
    onGoBack,
    calendarVisited,
    setCalendarVisited,
    populateSearchUrlParams,
    hasSelectedDates,
    trackingProperties,
    handleSearch,
    tripCategory,
    departureDate,
    returnDate,
    setDepartureDate,
    setReturnDate,
  } = props;

  const isUberClient = getEnvVariables("clientName") === ClientName.UBER;

  const focusedMonthIndex = departureDate
    ? dayjs(departureDate).diff(dayjs(), "month")
    : 0;

  useEffect(() => {
    if (
      tenantFlagsEnabled.FlightSearchCalendarPrediction &&
      originCode &&
      destinationCode
    ) {
      fetchDepartureCalendar();
    } else {
      setCalendar();
    }
  }, [originCode, destinationCode, fetchDepartureCalendar, setCalendar]);

  const darkModeAllowed = useEnableDarkMode();
  const isDarkMode = darkModeAllowed && getDarkModePreferred();

  return (
    <MobilePopoverCard
      open={open}
      onClose={onClose}
      fullScreen={true}
      className={clsx("calendar-modal-popover", isDarkMode && "dark-mode")}
      contentClassName="calendar-modal-wrapper"
      topLeftButton={
        isUberClient ? null : (
          <BackButton
            className="flight-payment-card-popup-back-button"
            onClick={onGoBack}
          />
        )
      }
      topRightButton={
        <ActionLink
          className="calendar-modal-header-close-button"
          onClick={onClose}
          content={
            <IconComponent
              ariaLabel="Close button icon"
              className={clsx("close-button-icon")}
              name={IconName.Close}
            />
          }
          label="Close"
        />
      }
    >
      <div className={clsx("calendar-modal-root", "date-range-picker")}>
        <HeaderCenterSection
          originCode={originCode}
          destinationCode={destinationCode}
          tripCategory={tripCategory}
        />
        <CalendarPicker
          tripCategory={tripCategory}
          departureDate={departureDate}
          returnDate={returnDate}
          setDepartureDate={setDepartureDate}
          setReturnDate={setReturnDate}
          focusedMonthIndex={focusedMonthIndex}
          isHistoryPushDisabled={true}
          onComplete={handleSearch}
          isMobile
          modal
          calendarVisited={calendarVisited}
          setCalendarVisited={setCalendarVisited}
          populateSearchUrlParams={populateSearchUrlParams}
          disabled={!hasSelectedDates}
          trackingProperties={trackingProperties}
        />
      </div>
    </MobilePopoverCard>
  );
};

interface IHeaderCenterSectionProps {
  originCode?: string;
  destinationCode?: string;
  tripCategory: TripCategory;
}

const HeaderCenterSection = (props: IHeaderCenterSectionProps) => {
  const { t } = useI18nContext();
  const { originCode, destinationCode } = props;
  return (
    <div className="header-center-section">
      {originCode && destinationCode && (
        <div className="calendar-modal-header-container">
          <h3>{t("calendarModal.updateDate")}</h3>
        </div>
      )}
      {(!originCode || !destinationCode) && (
        <div className="calendar-modal-header-container">
          <span>{t("flights")}</span>
        </div>
      )}
    </div>
  );
};
