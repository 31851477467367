import dayjs from "dayjs";

export const getReviewCardHeaderWithType = (
  isDeparture: boolean,
  location: string,
  date: string,
  airlineCode?: string
) => ({
  type: isDeparture ? "Outbound" : "Return",
  description: `to ${location}${
    airlineCode ? ` (${airlineCode})` : ""
  } on ${dayjs(date).format("ddd, MMM D")}`,
});

export const getStopsString = (stops: number) =>
  stops === 0 ? "Nonstop" : stops > 1 ? `${stops} stops` : "1 stop";
