/* eslint-disable prefer-const */
import { put, putResolve, select } from "redux-saga/effects";
import * as H from "history";
import { actions } from "../../actions";
import { fetchTripDetails as fetchDetails } from "../../../../api/v1/shop/fetchTripDetails";
import { getPriceFreeze } from "../../../../api/v1/price-freeze/getPriceFreeze";
import { actions as shopActions } from "../../../shop/actions";
import { actions as freezeActions } from "../../../freeze/actions";
import {
  getExistingStateVariables,
  IFlightBookParsedQuery,
  parseQueryString,
} from "../populateBookQueryParamsSaga";
import {
  TripCategory,
  GetPriceFreezeRequestEnum,
  GetPriceFreezeResponse,
  CallState,
  TripDetails,
  FlightBookType,
} from "@hopper-b2b/types";
import Logger from "../../../../helpers/Logger";
import { IStoreState } from "../../../../reducers/types";
import { PATH_HOME } from "../../../../utils/urlPaths";
import { flightApiConfigStoreKey } from "../../../../reducers/types";

const shouldRedirect = ({
  outgoingFareId,
  tripCategory,
  returnFareId,
  tripId,
  tripDetails,
}: {
  tripId: string;
  outgoingFareId: string;
  returnFareId: string;
  tripCategory: TripCategory;
  tripDetails: TripDetails;
}) =>
  !tripDetails ||
  !outgoingFareId ||
  !tripId ||
  (tripCategory === TripCategory.ROUND_TRIP && !returnFareId);

export function* setUpFlightBookParamsSaga({
  history,
  flightBookType,
}: actions.ISetupFlightBookParams) {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];

    let { tripDetails, tripCategory } = getExistingStateVariables(state);

    const parsedQueryString: IFlightBookParsedQuery = parseQueryString(history);

    let {
      priceFreezeId,
      tripId,
      outgoingFareId,
      returnFareId,
      tripCategory: currentTripCategory,
      priceDropCandidateId,
    } = parsedQueryString;

    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_PURCHASE:
        yield putResolve(
          actions.setFlightBookType(FlightBookType.PRICE_FREEZE_PURCHASE)
        );

        if (
          tripId &&
          (!tripDetails || !tripCategory || tripDetails.id !== tripId)
        ) {
          tripDetails = yield fetchDetails(tripId, apiConfig);
        }
        if (priceDropCandidateId) {
          yield put(actions.fetchPriceDropEligibility(priceDropCandidateId));
        }
        break;
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT:
      default:
        if (tripId) {
          yield putResolve(actions.setFlightBookType(FlightBookType.DEFAULT));

          if (!tripDetails || !tripCategory || tripDetails.id !== tripId) {
            tripDetails = yield fetchDetails(tripId);
          }
          if (priceDropCandidateId) {
            yield put(actions.fetchPriceDropEligibility(priceDropCandidateId));
          }
        } else if (priceFreezeId) {
          yield putResolve(
            actions.setFlightBookType(FlightBookType.PRICE_FREEZE_EXERCISE)
          );

          const priceFreeze: GetPriceFreezeResponse = yield getPriceFreeze(
            {
              GetPriceFreezeRequest: GetPriceFreezeRequestEnum.ByPriceFreezeId,
              id: priceFreezeId,
            },
            apiConfig
          );

          const { tripDetails: priceFreezeTripDetails, frozenFare } =
            priceFreeze.priceFreeze;
          const { slices, id } = priceFreezeTripDetails;

          tripDetails = priceFreezeTripDetails;
          currentTripCategory =
            slices.length > 1 ? TripCategory.ROUND_TRIP : TripCategory.ONE_WAY;

          tripId = id;
          outgoingFareId = frozenFare.id;
          if (slices.length > 1) {
            returnFareId = frozenFare.id;
          }

          yield putResolve(
            freezeActions.setPriceFreeze({
              priceFreeze,
              priceFreezeCallState: CallState.Success,
            })
          );
        }
        break;
    }

    yield setUpTripDetails({
      tripId,
      outgoingFareId,
      returnFareId,
      tripCategory: currentTripCategory,
      tripDetails,
      history,
    });
  } catch (e) {
    Logger.debug(e);
    yield put(actions.setUpFlightBookParamsFailed());
  }
}

function* setUpTripDetails(args: {
  tripId: string;
  outgoingFareId: string;
  returnFareId: string;
  tripCategory: TripCategory;
  tripDetails: TripDetails;
  history: H.History;
}) {
  const {
    tripId,
    outgoingFareId,
    returnFareId,
    tripCategory,
    tripDetails,
    history,
  } = args;
  const state: IStoreState = yield select();
  // If we are missing the data we need from both the state and the query params
  // we should redirect the user home.
  // TODO: Remove mock when auth is ready
  if (
    shouldRedirect({
      tripId,
      outgoingFareId,
      returnFareId,
      tripCategory,
      tripDetails,
    })
  ) {
    history.push(PATH_HOME);
    return;
  }
  // Trip Details must be populated last as the UI waits to display until trip is set
  yield putResolve(
    shopActions.setChosenOutgoingSlice({
      outgoingSliceId: tripDetails.slices[0].id,
      outgoingFareId,
      outgoingFareRating: undefined,
      tripId,
    })
  );
  if (tripCategory === TripCategory.ROUND_TRIP) {
    yield putResolve(
      shopActions.setChosenReturnSlice({
        returnSliceId: tripDetails.slices[1].id,
        returnFareId,
        returnFareRating: tripDetails.fareDetails.find(
          (f) => f.id === returnFareId
        )?.slices[1].fareShelf?.rating,
        tripId,
      })
    );
  }
  yield put(shopActions.setTripDetails(tripDetails));
  yield put(actions.setUpFlightBookParamsSuccess());
}
