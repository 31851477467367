import { FarePricing, RewardsPrice } from "@hopper-b2b/types";
import { getPricesWithComma, getRewardsString } from "@hopper-b2b/utilities";
import { CommbankCardAccount } from "@b2bportal/commbank-au-accounts-api";
import { FiatPrice } from "@b2bportal/air-shopping-api";

export interface RewardsPriceProps {
  pricing: FarePricing;
  selectedAccount: CommbankCardAccount;
}

export const getRewardsText = (props: RewardsPriceProps) => {
  const price = getRewardsPrice(props);
  return price ? getRewardsString(price) : "";
};

export const getRewardsPrice = ({
  pricing,
  selectedAccount,
}: RewardsPriceProps): RewardsPrice => {
  const rewardsPricing =
    pricing?.total?.rewards || pricing?.total?.accountSpecific;
  const rewardsPrice = rewardsPricing?.[selectedAccount.cardAccountId];

  if (rewardsPrice) {
    return {
      currency: rewardsPrice.currency,
      value: rewardsPrice.value,
    };
  } else if (selectedAccount?.pointsExchangeRate?.rate && pricing.total.fiat) {
    return {
      currency: selectedAccount.awardsBalance?.currency,
      value: Math.ceil(
        pricing.total.fiat.value / selectedAccount.pointsExchangeRate.rate
      ),
    };
  } else {
    return null;
  }
};

export const getPointsPriceFormatted = (props: RewardsPriceProps) => {
  const price = getRewardsPrice(props);
  return price ? getPricesWithComma(price.value) : "";
};

export const getPointsPriceFormattedFromFiat = (
  fiatPrice: FiatPrice,
  selectedAccount: CommbankCardAccount
) => {
  const rate = selectedAccount?.pointsExchangeRate?.rate;
  if (rate) {
    return getPricesWithComma(Math.ceil(fiatPrice.value / rate));
  }

  return "";
};
