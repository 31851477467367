import {
  CarItineraryResponse,
  CarItineraryResponseEnum,
  CarItineraryResponseSuccess,
  MyTripsFilter,
  MyTripsModalTypes,
} from "@hopper-b2b/types";
import { call, put } from "redux-saga/effects";
import { fetchCars } from "../../../api/v1/itinerary/fetchCars";
import { actions } from "../actions";
import { addGroundType } from "../components/ItineraryList/components/FlightCard/helpers";
import { setUpMyTripsParams } from "./setUpTripsParams";

export function* fetchCarsSaga(action: actions.IFetchCars) {
  try {
    const { contactSupport, tripId } = yield call(setUpMyTripsParams, action);
    const carItinerariesResponse: CarItineraryResponse = yield fetchCars();

    if (
      carItinerariesResponse.CarItineraryResponse ===
      CarItineraryResponseEnum.Success
    ) {
      const resp = carItinerariesResponse as CarItineraryResponseSuccess;
      const {
        future = [],
        present = [],
        past = [],
        canceled = [],
      } = resp.reservations;
      let isSelectedCarInPastTrips = false;
      const upcoming = [...future, ...present];
      const previous = [...canceled, ...past];
      let selectedCar =
        future &&
        upcoming.find((car) => car.bookResult.groundBookingId === tripId);
      if (!selectedCar) {
        const pastSelectedCar = previous.find(
          (car) => car.bookResult.groundBookingId === tripId
        );
        selectedCar = pastSelectedCar;
        isSelectedCarInPastTrips = !!pastSelectedCar;
      }

      if (isSelectedCarInPastTrips) {
        yield put(actions.setTripsFilter(MyTripsFilter.PAST_TRIPS));
        yield put(actions.populateTripQueryParams(action.history, { tripId }));
      }
      if (selectedCar) {
        yield put(actions.setSelectedCar(selectedCar));

        if (contactSupport) {
          yield put(
            actions.setOpenModal({
              selectedItinerary: addGroundType(selectedCar),
              type: MyTripsModalTypes.ContactSupport,
            })
          );
        }
      }
    } else {
      throw Error("Car Itineraries call failed");
    }
    yield put(actions.setCars(carItinerariesResponse));
  } catch (e) {
    yield put(actions.fetchCarsFailed());
    console.error(e);
  }
}
