import { AirCfarOffer } from "@b2bportal/air-cfar-api";
import { TrackingProperties } from "@b2bportal/air-shopping-api";
import { CallState } from "@hopper-b2b/types";
import {
  combineTrackingProperties,
  ITrackingPropertiesRequest,
} from "@hopper-b2b/utilities";
import { createSelector } from "@reduxjs/toolkit";
import { isEmpty } from "lodash-es";
import { IStoreState } from "../../../../reducers/types";

export const getCfarOffers = (state: IStoreState): AirCfarOffer[] | undefined =>
  state.flightCfar.cfarOffers;

const _getCfarOffersTrackingProperties = (
  state: IStoreState
): TrackingProperties | undefined =>
  state.flightCfar.cfarOffersTrackingProperties;

export const getCfarOffersCallState = (state: IStoreState): CallState =>
  state.flightCfar.cfarOffersCallState;

export const getSelectedCfarOffer = (
  state: IStoreState
): AirCfarOffer | undefined => state.flightCfar.selectedCfarOffer;

export const getCfarOfferQuoteId = (state: IStoreState) =>
  state.flightCfar.selectedCfarOffer?.quoteId as string;

export const isCfarOffersLoading = createSelector(
  getCfarOffersCallState,
  (cfarOfferCallState: CallState) => cfarOfferCallState === CallState.InProcess
);

export const isCfarOffersValid = (includeNotCalled: boolean) =>
  createSelector(
    getCfarOffersCallState,
    getCfarOffers,
    (callState: CallState, offers?: AirCfarOffer[]) =>
      (includeNotCalled && callState === CallState.NotCalled) ||
      callState === CallState.InProcess ||
      (callState === CallState.Success && !isEmpty(offers))
  );

export const getCfarOffersTrackingProperties = createSelector(
  _getCfarOffersTrackingProperties,
  (
    cfarOffersTrackingProperties: TrackingProperties
  ): ITrackingPropertiesRequest =>
    combineTrackingProperties([cfarOffersTrackingProperties || {}])
);

export const getSelectedCfarOfferTrackingProperties = createSelector(
  getSelectedCfarOffer,
  (cfarOffer?: AirCfarOffer): ITrackingPropertiesRequest =>
    combineTrackingProperties([cfarOffer?.trackingProperties || {}])
);

export const getCfarTotal = (state: IStoreState) => {
  const cfarOffer = getSelectedCfarOffer(state);
  let offerPrice = 0;
  if (cfarOffer && cfarOffer?.premiumAmount?.fiat?.value) {
    offerPrice += cfarOffer?.premiumAmount.fiat.value;
  }
  return offerPrice;
};
