import clsx from "clsx";
import { useCallback, useState } from "react";
import { ButtonWrap } from "../ButtonWrap";
import { DetailHalfSheet } from "../DetailHalfSheet";
import "./styles.scss";
import { FintechProductType } from "@hopper-b2b/types";

export interface ILearnMore {
  label: string | JSX.Element;
  description: string | JSX.Element;
  productType?: FintechProductType;
  descriptionAdditional?: string;
  header?: string;
  title?: string;
  className?: string;
  onOpen?: () => void;
}

export const LearnMore = ({
  label,
  description,
  productType,
  descriptionAdditional,
  header,
  title,
  className,
  onOpen,
}: ILearnMore) => {
  const [openLearnMore, setOpenLearnMore] = useState<boolean>(false);

  const onOpenLearnMore = useCallback(() => {
    setOpenLearnMore(true);
    onOpen?.();
  }, [onOpen]);

  const onCloseLearnMore = useCallback(
    () => setOpenLearnMore(false),
    [setOpenLearnMore]
  );

  return (
    <div className={clsx("learn-more", className)}>
      <ButtonWrap onClick={onOpenLearnMore} className="learn-more-label">
        {label}
      </ButtonWrap>
      <DetailHalfSheet
        productType={productType}
        open={openLearnMore}
        onClose={onCloseLearnMore}
        header={header}
        title={title}
        description={description}
        descriptionAdditional={descriptionAdditional}
      />
    </div>
  );
};
