interface AdaWindowInfo {
  hasActiveChatter: boolean;
  hasClosedChat: boolean;
  isChatOpen: boolean;
  isDrawerOpen: boolean;
}

export interface AdaUserMetaFields {
  phone_number?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  user_token?: string;
  tenant?: string;
  env?: string;
}

export interface DisruptionExerciseFields {
  pnr_locator: string; // booking confirmation from Hopper/Uber
  airline_locator: string;
  departure_date: string;
}

export type AdaChatMetaFields =
  | AdaUserMetaFields
  | DisruptionExerciseFields
  | Record<string, string>
  | Record<string, never>;

interface AdaChatConfig {
  meta?: Partial<AdaChatMetaFields>;
}

interface AdaResetProps {
  greeting?: string;
}

interface AdaSettings {
  adaReadyCallback: (params: { isRolledOut: boolean }) => void;
  align?: string;
  domain?: string;
  metaFields: AdaChatMetaFields;
  toggleCallback?: (isDrawerOpen: boolean) => void;
  testMode?: boolean;
}

interface AdaEmbed {
  toggle: () => Promise<undefined>;
  setMetaFields: (metaFields: AdaChatMetaFields) => void;
  getInfo: () => Promise<AdaWindowInfo>;
  reset: (resetParams?: AdaResetProps) => Promise<void>;
}

declare global {
  interface Window {
    adaSettings: AdaSettings;
    adaEmbed: AdaEmbed;
  }
}

export function initializeAdaChat({
  config,
  onLoad,
}: {
  config?: AdaChatConfig;
  onLoad?: () => void;
}) {
  window.adaSettings = {
    adaReadyCallback: () => {
      onLoad && onLoad();
    },
    metaFields: { ...(config?.meta || {}) },
  };

  const script = document.createElement("script");
  script.src = "https://static.ada.support/embed2.js";
  script.setAttribute("id", "__ada");
  script.setAttribute("data-handle", import.meta.env.VITE_ADA_DATA_HANDLE);
  script.setAttribute("async", "true");
  window.document.body.appendChild(script);
}

// setMetaFields - Can only be invoked when the chat is open
export function updateMetaFields(metaFields: AdaChatMetaFields) {
  window.adaEmbed?.setMetaFields?.(metaFields);
}

export async function toggleAdaChat(
  metaFields?: AdaChatMetaFields,
  answerId?: string
) {
  if (answerId) {
    await window.adaEmbed?.reset({ greeting: answerId });
  }
  await window.adaEmbed?.toggle();
  if (metaFields) {
    updateMetaFields(metaFields);
  }
}

export function getIsAdaChatOpen() {
  return window.adaEmbed
    .getInfo()
    .then((windowInfo) => windowInfo.isChatOpen)
    .catch((error) => {
      console.log(`adaEmbed getInfo error: ${error}`);
      return false;
    });
}

export const openAdaChat = async () => {
  const isChatOpen = await getIsAdaChatOpen();
  if (isChatOpen) return;
  toggleAdaChat();
};

export function toggleAdaChatDisplay(showChatBtn = true) {
  const chatBtnIFrameEl = document.getElementById("ada-button-frame");

  if (chatBtnIFrameEl) {
    const newDisplayRule = showChatBtn ? "block" : "none";

    chatBtnIFrameEl.style.display = newDisplayRule;
  }
}
