import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { DATE_FORMAT, TIME_FORMAT } from "../ItineraryList/constants";
import { useMemo } from "react";
import "./styles.scss";
import clsx from "clsx";

interface ItineraryDateProps {
  label: string;
  date: Date | string;
  boldTime?: boolean;
  className?: string;
  hideTime?: boolean;
}

export const ItineraryDate = ({
  label,
  date,
  boldTime,
  className,
  hideTime,
}: ItineraryDateProps) => {
  const formattedDate = useMemo(() => {
    return dayjs(date).format(DATE_FORMAT);
  }, [date]);
  const formattedTime = useMemo(() => {
    return dayjs(date).format(TIME_FORMAT);
  }, [date]);
  return (
    <Box className={clsx("itinerary-date", "apac", className)}>
      <Typography className="label">{label}</Typography>
      <Typography className="date">{formattedDate}</Typography>
      {!hideTime && (
        <Typography className={clsx("time", { bold: boldTime ?? true })}>
          {formattedTime}
        </Typography>
      )}
    </Box>
  );
};
