import { connect, ConnectedProps } from "react-redux";

import { withRouter } from "react-router-dom";
import { ScheduleChangeStateModal } from "./component";
import { fetchFlights } from "../../../../../../../../actions/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  fetchFlights,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ScheduleChangeStateModalContentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedScheduleChangeStateModal = connector(
  withRouter(ScheduleChangeStateModal)
);
