import clsx from "clsx";
import { SwitchButton } from "../../index";
import { IconName } from "../Icon";
import { IconComponent } from "../IconComponent";
import "./styles.scss";
import { ReactElement } from "react";

export interface IPriceWatchCardToggleProps {
  className?: string;
  title: string;
  description: string;
  unsupportedFilterCopy?: string;
  isWatching?: boolean;
  onClick: () => void;
  iconSrc?: string;
}

export const PriceWatchCardToggle = ({
  className,
  title,
  description,
  unsupportedFilterCopy,
  onClick,
  iconSrc,
  isWatching,
}: IPriceWatchCardToggleProps): ReactElement => {
  return (
    <div className={clsx(className, "price-watch-card-toggle")}>
      <div className="price-watch-card-icon-container">
        {iconSrc ? (
          <img src={iconSrc} className="price-watch-card-icon" alt="" />
        ) : (
          <IconComponent
            className="price-watch-card-icon"
            name={IconName.BellAlert}
          />
        )}
      </div>
      <div className="price-watch-card-copy-container">
        <h6 className="price-watch-header">{title}</h6>
        <span className="title">{description}</span>
      </div>
      <SwitchButton onClick={onClick} checked={isWatching} />
      {unsupportedFilterCopy && (
        <span className="unsupported-filter-title">
          {unsupportedFilterCopy}
        </span>
      )}
    </div>
  );
};
