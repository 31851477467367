import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { History } from "history";
import clsx from "clsx";
import "./styles.scss";

export interface IActionButtonWithRouteProps {
  buttonClassName?: string;
  onClick: (history: History) => void;
  disabled: boolean;
  message: JSX.Element | string;
  history: History;
  className?: string;
  mobile?: boolean;
}

const ActionButtonWithRoute = ({
  className,
  buttonClassName,
  onClick,
  disabled,
  history,
  message,
  mobile,
}: IActionButtonWithRouteProps) => {
  const handleClick = () => onClick(history);

  return (
    <Box className={clsx("action-button", className)}>
      <Button
        {...{ disabled }}
        className={clsx(buttonClassName, { mobile: mobile })}
        onClick={handleClick}
        variant="contained"
      >
        {message}
      </Button>
    </Box>
  );
};

export default ActionButtonWithRoute;
