import { ApacDesktopPopupModal, BlackHeaderBanner } from "@commbank/ui";
import ApacWalletApp from "@commbank/wallet";
import { Flights, Prediction } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Slot } from "@hopper-b2b/ui";
import {
  getIsMixedClass,
  useDeviceTypes,
  useEnableCfar,
  useEnableDisruptionProtection,
  useEnableWallet,
  useIsSessionAuthenticated,
} from "@hopper-b2b/utilities";
import { Box, Dialog, Slide } from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getCfarOffers } from "../../../cfar/reducer";
import { getAirDisruptionOffers } from "../../../disruption/reducer";
import { DesktopFlightSearchControl } from "../../../search/components/DesktopFlightSearchControl";
import { WalletOfferPage } from "../../../wallet";
import { FlightShopHeader } from "../../components";
import { DesktopFlightStepBar } from "../../components/DesktopFlightStepBar";
import { FintechCustomizePage } from "../../components/FintechCustomizePage";
import { FlightShopReviewItinerary } from "../../components/FlightShopReviewItinerary";
import { DesktopPricePredictionSection } from "../../components/PricePrediction";
import { useGoToCheckout, useUpdateFlightShopStep } from "../../hooks";
import {
  FlightShopStep,
  isInChooseDepartureStepSelector,
  isInChooseReturnStepSelector,
  isInFintechSelectionStepSelector,
  isInReviewStepSelector,
  isInWalletOfferStepSelector,
  selectedFareDetailsSelector,
  selectedTripDetailsSelector,
} from "../../reducer";
import "./DesktopFlightShop.styles.scss";
import { FlightList } from "./FlightList";
import { IFlightListProps } from "./FlightList/FlightList";
import { FlightReviewCard } from "../../../../components/FlightReviewCard";
import { FlightsScoreExplained } from "../../components/PricePrediction/FlightsScoreExplained";

type DesktopFlightShopProps = {
  isPriceFreezeEnabled: boolean;
  tripSummariesLoading: boolean;
  flights: Flights | null;
  prediction: Prediction | null;
} & Omit<IFlightListProps, "isInChooseReturnStep">;

export const DesktopFlightShop = ({
  isRoundTrip,
  isPriceFreezeEnabled,
  tripSummariesLoading,
  flights,
  flightsToRender,
  rewardsKey,
  handleFareSelect,
  fareClassFilter,
  handleFlightSelect,
  expandedFareDetails,
  departureDate,
  returnDate,
  hasFlightsError,
  selectedTrip,
  maxFlightPrice,
  hasAppliedFareClassFilter,
  hasAppliedNonFareclassFilter,
  setOpenCalendarModal,
  prediction,
}: DesktopFlightShopProps) => {
  const { t } = useI18nContext();

  const [openProfilePanel, setOpenProfilePanel] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openFlightScoreModal, setOpenFlightScoreModal] = useState(false);
  const { matchesMediumDesktopOnly } = useDeviceTypes();

  const isSessionAuthenticated = useIsSessionAuthenticated();

  const showFlightCfar = useEnableCfar();
  const showAirDisruption = useEnableDisruptionProtection();
  const cfarOffers = useSelector(getCfarOffers);
  const disruptionOffers = useSelector(getAirDisruptionOffers);

  const isInChooseDepartureStep = useSelector(isInChooseDepartureStepSelector);
  const isInChooseReturnStep = useSelector(isInChooseReturnStepSelector);
  const isInReviewStep = useSelector(isInReviewStepSelector);
  const isInFintechSelectionStep = useSelector(
    isInFintechSelectionStepSelector
  );
  const isInWalletOfferStep = useSelector(isInWalletOfferStepSelector);
  const tripDetails = useSelector(selectedTripDetailsSelector);
  const fareDetails = useSelector(selectedFareDetailsSelector);

  const showFlightWallet = useEnableWallet();

  const updateFlightStep = useUpdateFlightShopStep();
  const goToCheckout = useGoToCheckout();

  const handleReviewItineraryContinue = useCallback(() => {
    if (
      (showFlightCfar && !!cfarOffers?.length) ||
      (showAirDisruption && !!disruptionOffers?.length)
    ) {
      updateFlightStep(FlightShopStep.FintechSelection);
    } else {
      goToCheckout();
    }
  }, [
    showFlightCfar,
    cfarOffers,
    showAirDisruption,
    disruptionOffers,
    updateFlightStep,
    goToCheckout,
  ]);

  const showWalletStep =
    showFlightWallet && isSessionAuthenticated && isInWalletOfferStep;

  // TODO: fix, add next step controller similar to mobile
  const goToNextStepFromFintech = useCallback(
    (replace?: boolean) => {
      if (showFlightWallet && isSessionAuthenticated) {
        updateFlightStep(FlightShopStep.WalletOffers, replace);
      } else {
        goToCheckout(replace);
      }
    },
    [goToCheckout, isSessionAuthenticated, showFlightWallet, updateFlightStep]
  );

  const showFlightShopSearchControls =
    !isInReviewStep && !isInFintechSelectionStep && !isInWalletOfferStep;

  const showFlightShopPricePrediction = useMemo(() => {
    return isInChooseDepartureStep && prediction;
  }, [isInChooseDepartureStep, prediction]);

  const ProfilePanelSlide = React.forwardRef((props, ref) => {
    return <Slide direction="left" ref={ref} {...props} />;
  });

  const hasResults = flightsToRender && flightsToRender.length > 0;

  return (
    <Box className="desktop-flight-shop-container">
      <Box className={clsx("header-container", { review: isInReviewStep })}>
        <DesktopFlightStepBar
          openProfilePanel={openProfilePanel}
          setOpenProfilePanel={setOpenProfilePanel}
        />
        {showFlightShopSearchControls && (
          <DesktopFlightSearchControl isSearchBar />
        )}
        {isInReviewStep && (
          <BlackHeaderBanner
            title={t("flightsPageTitles.reviewItinerary")}
            subtitle={t("reviewItinerarySubtitle")}
          />
        )}
        {isInFintechSelectionStep && (
          <BlackHeaderBanner
            title={t("commBank.flightShopProgress.protection")}
            subtitle={t("commBank.protectionSubtitle")}
          />
        )}
      </Box>

      {openProfilePanel && (
        <Dialog
          open={openProfilePanel}
          className="profile-side-paper-dialog"
          fullScreen
          TransitionComponent={ProfilePanelSlide}
          onClose={() => setOpenProfilePanel(false)}
          BackdropProps={{
            style: {
              marginTop: "150px",
            },
          }}
          PaperProps={{
            square: true,
            style: {
              position: "absolute",
              right: 0,
              bottom: 0,
              width: 571,
            },
          }}
        >
          <div className="profile-side-paper-container">
            <ApacWalletApp isSidePanel />
          </div>
        </Dialog>
      )}

      <Box
        className={clsx("result-content-container", {
          "medium-screen": matchesMediumDesktopOnly,
        })}
      >
        {hasResults && showFlightShopPricePrediction && (
          <DesktopPricePredictionSection
            isRoundTrip={isRoundTrip}
            prediction={prediction}
          />
        )}
        {isInChooseReturnStep && !!tripDetails?.slices?.length && (
          <Box className={clsx("flight-outbound-review-card")}>
            <FlightReviewCard
              tripSlice={tripDetails.slices[0]}
              fareDetailsSlice={fareDetails.slices[0]}
              airports={flights.airports}
              showAction
              isMixedCabinClass={getIsMixedClass(fareDetails.slices[0])}
            />
          </Box>
        )}
        {(isInChooseDepartureStep || isInChooseReturnStep) && (
          <FlightShopHeader
            isMobile={false}
            openFilterModal={openFilterModal}
            setOpenFilterModal={setOpenFilterModal}
          />
        )}
        {tripSummariesLoading ? <Slot id="flight-search-loader" /> : null}
        {!tripSummariesLoading &&
          (!!isInChooseDepartureStep || !!isInChooseReturnStep) && (
            <FlightList
              flightsToRender={flightsToRender}
              tripSummariesLoading={tripSummariesLoading}
              flights={flights}
              rewardsKey={rewardsKey}
              handleFareSelect={handleFareSelect}
              fareClassFilter={fareClassFilter}
              handleFlightSelect={handleFlightSelect}
              expandedFareDetails={expandedFareDetails}
              departureDate={departureDate}
              returnDate={returnDate}
              isInChooseReturnStep={isInChooseReturnStep}
              hasFlightsError={hasFlightsError}
              selectedTrip={selectedTrip}
              maxFlightPrice={maxFlightPrice}
              hasAppliedFareClassFilter={hasAppliedFareClassFilter}
              hasAppliedNonFareclassFilter={hasAppliedNonFareclassFilter}
              setOpenCalendarModal={setOpenCalendarModal}
              isRoundTrip={isRoundTrip}
              disablePriceFreeze={!isPriceFreezeEnabled}
              onOpenFilter={() => setOpenFilterModal(true)}
              handleClickAlgomerch={() =>
                setOpenFlightScoreModal((pre) => !pre)
              }
            />
          )}
        {isInReviewStep && (
          <FlightShopReviewItinerary
            isMobile={false}
            onContinue={handleReviewItineraryContinue}
          />
        )}
        {isInFintechSelectionStep && (
          <FintechCustomizePage
            goToNextStep={goToNextStepFromFintech}
            prediction={prediction}
            isOneWay={!isRoundTrip}
          />
        )}
        {showWalletStep ? (
          <WalletOfferPage goToNextStep={goToCheckout} />
        ) : null}
      </Box>

      <ApacDesktopPopupModal
        open={openFlightScoreModal}
        onClose={() => setOpenFlightScoreModal(false)}
        className="desktop-flights-score-modal"
        contentClassName="modal-content"
      >
        <FlightsScoreExplained onClose={() => setOpenFlightScoreModal(false)} />
      </ApacDesktopPopupModal>
    </Box>
  );
};
