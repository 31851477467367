import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { listPaymentMethods } from "../../../../api/v1/book/payment-methods/listPaymentMethods";
import { Payment } from "@hopper-b2b/types";
import { IStoreState } from "../../../../reducers/types";
import { flightApiConfigStoreKey } from "../../../../reducers/types";

export function* listPaymentMethodsSaga(_: actions.IListPaymentMethods) {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const paymentMethods: Payment[] = yield listPaymentMethods(apiConfig);
    yield put(
      actions.setPaymentMethods({
        paymentMethods,
      })
    );
  } catch (e) {
    yield put(actions.setPaymentMethodsCallStateFailed());
  }
}
