export enum ChfarTrackingEvents {
  VIEWED_CHFAR = "b2b_air_viewed_chfar",
  ADDED_CHFAR = "b2b_air_added_chfar",
  DECLINED_CHFAR = "b2b_air_declined_chfar",
  TAP_LEARN_MORE = "b2b_air_tap_chfar_learn_more",
  TAP_TERMS_CONDITIONS = "b2b_air_tap_chfar_terms",
  ENTER_CHFAR_EXERCISE_FLOW = "b2b_air_enter_chfar_exercise_flow",
  CHFAR_POLICY_EXERCISE_STARTED = "b2b_air_chfar_policy_exercise_started",
  CHFAR_POLICY_EXERCISED = "b2b_air_chfar_policy_exercised",
  VIEWED_CHFAR_EXERCISE_ERROR = "b2b_air_viewed_chfar_exercise_error",
}

export type ChfarOfferProperties = {
  chfar_quote_id: string;
  chfar_coverage_percentage: string;
  chfar_coverage_amount: number;
  chfar_premium_amount: number;
  chfar_currency_code: string;
};

export enum ChfarTrackingPageSelected {
  OFFER = "offer",
  EXERCISE = "exercise",
}
