import {
  CheckoutProps,
  FlightClientAssetProps,
  FlightModuleProps,
  IApiConfig,
  SessionInfo,
  TenantCallbacks,
} from "@hopper-b2b/types";
import {
  FeatureFlagsContext,
  FeatureFlagsContextProps,
  UserSourceProvider,
  updateApiConfigAction,
} from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import {
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";
import { createContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./ApacFlightApp.scss";
import ErrorBoundary from "./components/ErrorBoundary";
import { FlightsReduxProvider } from "./components/FlightsReduxProvider";
import { FlightsCheckout } from "./modules/checkout";
import { FlightExchange } from "./modules/exchange";
import { PriceFreezePurchase } from "./modules/freeze/checkout";
import { PriceFreezeOverviewPage } from "./modules/freeze/overviewPage";
import {
  setAgentEmail,
  setIsFirstLaunch,
  setRewardsAccounts,
  setSelectedAccountReferenceId,
} from "./modules/rewards/actions/actions";

import { FlightSearch } from "./modules/search";
import { FlightShopV3 } from "./modules/shop/v3";
import { flightApiConfigStoreKey } from "./reducers/types";
import { PAWTUCKET_FLIGHTS_MODULE_ID } from "./utils/moduleIds";
import {
  PATH_BOOK_RELATIVE,
  PATH_EXCHANGE_RELATIVE,
  PATH_HOME,
  PATH_HOME_RELATIVE,
  PATH_PRICE_FREEZE_OVERVIEW_RELATIVE,
  PATH_PRICE_FREEZE_PURCHASE_RELATIVE,
  PATH_SEARCH_RELATIVE,
  PATH_SHOP_RELATIVE,
} from "./utils/urlPaths";
import { Navigate, Route, Routes } from "react-router-dom-v5-compat";
import { useHistory } from "react-router";
import clsx from "clsx";
import { CommbankCardAccount } from "@b2bportal/commbank-au-accounts-api";

const generateClassName = createGenerateClassName({
  productionPrefix: PAWTUCKET_FLIGHTS_MODULE_ID,
  seed: PAWTUCKET_FLIGHTS_MODULE_ID,
});

interface FlightsClientContextType extends FlightClientAssetProps {
  isAgentPortal: boolean;
}

export const ClientContext = createContext<Partial<FlightsClientContextType>>(
  {}
);

interface FlightsAppProps extends FlightModuleProps {
  featureFlags?: FeatureFlagsContextProps;
  commbankCardAccounts: CommbankCardAccount[];
}

const ApacFlightApp = ({
  commbankCardAccounts,
  clientAssets,
  isAgentPortal,
  apiConfig,
  checkoutProps,
  tenantCallbacks,
  priceFreezePurchaseProps,
  featureFlags,
}: FlightsAppProps) => {
  const sessionInfo = clientAssets?.sessionInfo;

  return (
    <FlightsReduxProvider>
      <UserSourceProvider>
        <ClientContext.Provider
          value={{ ...clientAssets, isAgentPortal, sessionInfo }}
        >
          <FeatureFlagsContext.Provider value={featureFlags ?? {}}>
            <div className={clsx("FlightApp")}>
              <StylesProvider generateClassName={generateClassName}>
                <Body
                  commbankCardAccounts={commbankCardAccounts}
                  sessionInfo={sessionInfo}
                  apiConfig={apiConfig}
                  checkoutProps={checkoutProps}
                  tenantCallbacks={tenantCallbacks}
                  priceFreezePurchaseProps={priceFreezePurchaseProps}
                  clientAssets={clientAssets}
                />
              </StylesProvider>
            </div>
          </FeatureFlagsContext.Provider>
        </ClientContext.Provider>
      </UserSourceProvider>
    </FlightsReduxProvider>
  );
};

type BodyProps = {
  sessionInfo?: SessionInfo;
  apiConfig?: IApiConfig;
  checkoutProps?: CheckoutProps;
  tenantCallbacks?: TenantCallbacks;
  clientAssets?: FlightClientAssetProps;
  priceFreezePurchaseProps?: CheckoutProps;
  commbankCardAccounts: CommbankCardAccount[];
};

export const Body = ({
  sessionInfo,
  apiConfig,
  checkoutProps,
  commbankCardAccounts,
  priceFreezePurchaseProps,
  tenantCallbacks,
  clientAssets,
}: BodyProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionInfo) {
      dispatch(setIsFirstLaunch(sessionInfo.isFirstSession));
      if (sessionInfo.isDelegatedSession) {
        dispatch(setAgentEmail(sessionInfo.isDelegatedSession));
      }
    }
  }, [sessionInfo, dispatch]);

  useEffect(() => {
    if (commbankCardAccounts) {
      dispatch(setRewardsAccounts(commbankCardAccounts));
      if (commbankCardAccounts[0]) {
        dispatch(
          setSelectedAccountReferenceId(commbankCardAccounts[0].cardAccountId)
        );
      }
    }
  }, [commbankCardAccounts, dispatch]);

  useEffect(() => {
    dispatch(updateApiConfigAction(flightApiConfigStoreKey, apiConfig));
  }, [apiConfig, dispatch]);

  return (
    <Box className="main-section">
      <ErrorBoundary history={history}>
        <Routes>
          <Route path={PATH_HOME_RELATIVE} element={<FlightSearch />}></Route>
          <Route path={PATH_SEARCH_RELATIVE} element={<FlightSearch />}></Route>
          <Route
            path={PATH_SHOP_RELATIVE}
            element={<FlightShopV3 tenantCallbacks={tenantCallbacks} />}
          ></Route>
          <Route
            path={PATH_BOOK_RELATIVE}
            element={
              <FlightsCheckout clientAssets={clientAssets} {...checkoutProps} />
            }
          ></Route>
          <Route
            path={PATH_PRICE_FREEZE_PURCHASE_RELATIVE}
            element={<PriceFreezePurchase clientAssets={clientAssets} />}
          ></Route>
          <Route
            path={PATH_PRICE_FREEZE_OVERVIEW_RELATIVE}
            element={<PriceFreezeOverviewPage />}
          ></Route>
          <Route
            path={PATH_EXCHANGE_RELATIVE}
            element={<FlightExchange />}
          ></Route>
          <Route path={"*"} element={<Navigate to={PATH_HOME} />}></Route>
        </Routes>
      </ErrorBoundary>
    </Box>
  );
};

export default ApacFlightApp;
