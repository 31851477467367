import { ApacActionLink } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { FareSliceDetails } from "@hopper-b2b/types";
import { IFareDetailsCardProps } from "@hopper-b2b/ui";
import { Box, Typography } from "@material-ui/core";
import { useState } from "react";
import { IRestrictionProps } from "../../modules/shop/v3/components/FlightList/components/FareDetailsCard/FareDetailsCard";
import { Amenity } from "./Amenity";
import styles from "./Amenity.module.scss";

export type AmenityGroupProps = {
  restrictions: IRestrictionProps[];
  fareSlice: FareSliceDetails;
  anchorEl?: any;
  fareIndex?: number;
} & Pick<IFareDetailsCardProps, "getEmptyRestrictionsText">;

export const AmenityGroup = ({
  restrictions,
  fareSlice,
  anchorEl,
  fareIndex,
  getEmptyRestrictionsText,
}: AmenityGroupProps) => {
  const { t } = useI18nContext();

  const [isFolded, setIsFolded] = useState(true);

  const onClick = () => {
    if (isFolded) {
      setIsFolded(false);
    } else {
      setIsFolded(true);
    }
  };

  const policies = () => (isFolded ? restrictions.slice(0, 4) : restrictions);

  return (
    <div className="fare-details-card-policy-item">
      {restrictions.length === 0 && (
        <Box my={2}>
          <Typography variant="body1">
            {getEmptyRestrictionsText(fareSlice.fareShelf?.rating, t)}
          </Typography>
        </Box>
      )}

      {policies().map((policy, index) => (
        <Amenity key={index} policy={policy} showDetail={false} />
      ))}

      {restrictions.length > 4 && (
        <ApacActionLink
          className={styles.link}
          noUnderline
          aria-owns={
            anchorEl && anchorEl.id === `trip-fare-details-${fareIndex}`
              ? "mouse-over-popover"
              : undefined
          }
          message={
            isFolded
              ? `+${t?.("more", { count: restrictions.length - 4 })}`
              : t("showLess")
          }
          id={`trip-fare-details-${fareIndex}`}
          onClick={onClick}
        />
      )}
    </div>
  );
};
