import {
  CartHotelCancelFulfillPollRes,
  CaseClassValue,
  NETWORK_CALL_FAILED,
} from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { pollHotelCancelFulfillPath } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

const pollHotelCancelFulfill = async (fulfillmentSessionId: CaseClassValue) => {
  try {
    const res = await axiosInstance.put<CartHotelCancelFulfillPollRes>(
      pollHotelCancelFulfillPath,
      { fulfillmentSessionId }
    );

    return res.data;
  } catch (err) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        location: window.location.pathname,
      },
    });
  }
};

export default pollHotelCancelFulfill;
