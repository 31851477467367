export enum URL_PARAM_KEYS {
  FROM_DATE = "fromDate",
  UNTIL_DATE = "untilDate",
  ADULTS_COUNT = "adultsCount",
  CHILDREN_COUNT = "childrenCount",
  CHILDREN_AGES = "childrenAges",
  ROOMS_COUNT = "roomsCount",
  STAR_RATING = "starRating",
  USER_RATING = "userRating",
  AMENITIES = "amenities",
  FILTER_AMENITIES_AND = "filterAmenitiesAnd",
  FREE_CANCELLATION = "freeCancellation",
  PRICE_RANGE_MIN = "priceMin",
  PRICE_RANGE_MAX = "priceMax",
  LAT_LNG = "latlng",
  ZOOM = "zoom",
  BOUNDS = "bounds",
  SORT_BY = "sortBy",
  VIEW = "view",
  LODGING_IDS = "lodgingIds",
  PREV_SEARCH = "prevSearch",
  STEP = "step",
}
