import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { IconComponent, IconName } from "@hopper-b2b/ui";
import styles from "./StarRating.module.scss";
import isEqual from "lodash-es/isEqual";
import { useI18nContext } from "@hopper-b2b/i18n";

interface Props {
  value: number[];
  onChange(nextValue: number[]): void;
}

export function StarRating({ value, onChange }: Props) {
  const { t } = useI18nContext();

  const options = [
    { toggleValue: [], stars: 0, content: t("any") },
    {
      toggleValue: [2, 3, 4, 5],
      stars: 2,
      ariaLabel: t("starsAndUp", { stars: 2 }),
    },
    {
      toggleValue: [3, 4, 5],
      stars: 3,
      ariaLabel: t("starsAndUp", { stars: 3 }),
    },
    { toggleValue: [4, 5], stars: 4, ariaLabel: t("starsAndUp", { stars: 4 }) },
    { toggleValue: [5], stars: 5, ariaLabel: t("starsAndUp", { stars: 5 }) },
  ];

  return (
    <div className={styles.StarRating}>
      <ToggleButtonGroup
        value={value}
        onChange={(_event, nextValue: number[]) => {
          if (nextValue != null) onChange(nextValue);
        }}
        aria-label={t("starRating")}
        size="large"
        exclusive
      >
        {options.map(({ toggleValue, stars, content, ariaLabel }) => (
          <ToggleButton
            key={stars}
            selected={isEqual(value, toggleValue)}
            value={toggleValue}
            aria-label={ariaLabel}
          >
            {content ? content : <Rating value={stars} />}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
}

function Rating({ value }: { value: number }) {
  return (
    <span className={styles.Rating}>
      <span>+{value}</span>
      <IconComponent name={IconName.StarFilled} />
    </span>
  );
}
