import {
  CommbankCardAccount,
  GetAccountResponse,
  CriteriaEnum,
  ProductCriteria,
  Awards,
  Points,
  TravelCredits,
  AwardsEnum,
} from "@b2bportal/commbank-au-accounts-api";
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useMemo,
  useState,
} from "react";
import { fetchAccounts } from "./api";

export interface AccountsState {
  accounts: CommbankCardAccount[];
  firstName: string;
  lastName: string;
  offerTrackingProperties?: {
    has_cba_offer: string;
  };
  refetchAccounts: () => Promise<GetAccountResponse>;
}

const defaultInitState: AccountsState = {
  accounts: [],
  firstName: "",
  lastName: "",
  refetchAccounts: () => fetchAccounts(),
};

export const AccountsContext = createContext<AccountsState>(defaultInitState);

export type AccountsProviderProps = {
  isLoggedIn: boolean;
  cardArtUrl: string;
  initState?: AccountsState;
};

export const AccountsProvider: FC<
  AccountsProviderProps & PropsWithChildren
> = ({
  cardArtUrl = "",
  isLoggedIn = false,
  initState = defaultInitState,
  children,
}) => {
  const [accounts, setAccounts] = useState<CommbankCardAccount[]>(
    initState.accounts
  );
  const [offerTrackingProperties, setOfferTrackingProperties] =
    useState(undefined);
  const [firstName, setFirstName] = useState(initState.firstName);
  const [lastName, setLastName] = useState(initState.lastName);

  const refetchAccounts = useCallback(() => {
    return fetchAccounts().then((result) => {
      setAccounts(result.accounts || []);
      setFirstName(result.firstName);
      setLastName(result.lastName);
      const offerAccounts = result.accounts.filter(
        (a) => a.eligibleOffers.length > 0
      );
      if (offerAccounts.length > 0) {
        const offerTrackingProps = offerAccounts[0].eligibleOffers
          .map((offer) => {
            const productCriteria = offer.applicability.criterias.find(
              (c) => c.Criteria === CriteriaEnum.ProductCriteria
            );
            let offerValueString = "";
            switch (offer.awards.Awards) {
              case AwardsEnum.TravelCredits:
                // eslint-disable-next-line no-case-declarations
                const travelCreditOffer = offer.awards as TravelCredits;
                offerValueString = `${travelCreditOffer.percentage}_${travelCreditOffer.TravelCredits}_${travelCreditOffer.Awards}`;
                break;
              case AwardsEnum.Points:
                // eslint-disable-next-line no-case-declarations
                const pointsOffer = offer.awards as Points;
                offerValueString = `${pointsOffer.multipler}_${pointsOffer.Points}_${travelCreditOffer.Awards}`;
                break;
            }
            let trackingString = `CBA_Yello`;
            if (productCriteria) {
              const criteria = productCriteria as ProductCriteria;
              trackingString += `_${criteria.ProductCriteria}`;
            }
            trackingString += `_${offerValueString}`;
            return trackingString;
          })
          .join(",");
        setOfferTrackingProperties({
          has_cba_offer: offerTrackingProps,
        });
      }
      return result;
    });
  }, []);

  const value: AccountsState = useMemo(
    () => ({
      firstName,
      lastName,
      accounts,
      cardArtUrl,
      offerTrackingProperties,
      refetchAccounts,
    }),
    [
      firstName,
      lastName,
      cardArtUrl,
      accounts,
      offerTrackingProperties,
      refetchAccounts,
    ]
  );

  return <AccountsContext.Provider value={value} children={children} />;
};
