import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ReactNode, useMemo } from "react";

import {
  Kind as AncillaryKindEnum,
  BookedFlightItineraryWithDepartureTime,
  PaymentLineItemEnum,
  Seat,
} from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { getCurrencySymbol } from "@hopper-b2b/utilities";

import { getLineItems } from "../../../../../TripsList/utils/helpers";
import "./styles.scss";
import { ExpandableSection } from "../../../ExpandableSection/component";

interface IFlightPaymentSummaryProps {
  flight: BookedFlightItineraryWithDepartureTime;
}

interface ISeatPrice {
  value: number;
  currencySymbol: string;
}

export const FlightPaymentSummary = ({
  flight,
}: IFlightPaymentSummaryProps) => {
  const { t, formatFiatCurrency, formatCurrency, formatRewardsCurrency } =
    useI18nContext();
  const { bookedItinerary } = flight;
  const { sellingPricing } = bookedItinerary;
  const { pricingByPassenger, totalPricing } = sellingPricing;

  const seatPricingByPassenger: { [key in string]: ISeatPrice } = {};

  if (bookedItinerary.seats && bookedItinerary.seats.seats.length > 0) {
    const populateSeatPricingByPassengerId = (id: string) => {
      const fortWorthSeats = bookedItinerary.seats.seats.filter(
        (s: Seat) => s.personId === id
      );
      if (fortWorthSeats.length > 0) {
        seatPricingByPassenger[id] = {
          value: fortWorthSeats.reduce(
            (acc: number, seat: Seat) => acc + seat.price,
            0
          ),
          currencySymbol: getCurrencySymbol(fortWorthSeats[0].currency),
        };
      }
    };
    bookedItinerary.passengers.alone.forEach((p) => {
      populateSeatPricingByPassengerId(p.person.id);
    });
    bookedItinerary.passengers.withLapInfants.forEach((p) => {
      populateSeatPricingByPassengerId(p.adult.person.id);
    });
  }

  const paymentContent = useMemo(() => {
    return getLineItems(t, bookedItinerary.paymentBreakdown) || undefined;
  }, [bookedItinerary.paymentBreakdown, t]);

  const renderPricingRow = ({
    label,
    price,
    className,
  }: {
    label: ReactNode;
    price: string;
    className?: string;
  }) => {
    return (
      <div className={clsx("pricing-row", className)}>
        <Typography className="label" variant="body2">
          {label}
        </Typography>
        <Typography className="price" variant="body2">
          {price}
        </Typography>
      </div>
    );
  };

  const renderPaymentForTravelers = () => {
    return (
      <div className="payment-travelers-container">
        {pricingByPassenger.map(
          ({
            additionalMargin,
            baseWithoutMargin,
            person,
            taxes,
            passengerFee,
            ancillaries,
            total,
          }) => {
            const ancillaryListByKind = ancillaries.reduce(
              (ancillaryObj, ancillary) => {
                return {
                  ...ancillaryObj,
                  [ancillary.kind]: ancillary,
                };
              },
              {}
            );

            const cfar = ancillaryListByKind[AncillaryKindEnum.Cfar];
            const chfar = ancillaryListByKind[AncillaryKindEnum.Chfar];
            const delayProtection =
              ancillaryListByKind[AncillaryKindEnum.Delay];
            const missedConnectionProtection =
              ancillaryListByKind[AncillaryKindEnum.MissedConnection];

            return (
              <ExpandableSection
                key={person.id}
                className="payment-details-container"
                title={
                  <Box className="payment-traveler-header">
                    <Typography
                      className="payment-traveler-name"
                      variant="h4"
                      data-dd-privacy="mask"
                    >
                      {`${person?.givenName} ${person?.surname}`}
                    </Typography>
                    <Typography
                      className="payment-traveler-name"
                      variant="h4"
                      data-dd-privacy="mask"
                    >
                      {formatFiatCurrency({
                        value: total.fiat.value,
                        currencyCode: passengerFee.fiat.currencyCode,
                      })}
                    </Typography>
                  </Box>
                }
              >
                <div className="payment-details-content">
                  {renderPricingRow({
                    label: t("baseFare"),
                    price: formatFiatCurrency(baseWithoutMargin.fiat),
                  })}
                  {renderPricingRow({
                    label: t("taxesAndFees"),
                    price: formatFiatCurrency({
                      value:
                        (additionalMargin ? additionalMargin.fiat.value : 0) +
                        passengerFee.fiat.value +
                        taxes.fiat.value,
                      currencyCode: passengerFee.fiat.currencyCode,
                    }),
                  })}
                  {seatPricingByPassenger[person?.id] &&
                    renderPricingRow({
                      label: t("seatSelection"),
                      price: formatFiatCurrency({
                        value: seatPricingByPassenger[person?.id]?.value,
                        currencyCode: passengerFee.fiat.currencyCode,
                      }),
                    })}
                  {!!cfar &&
                    renderPricingRow({
                      className: "cfar",
                      label: t("cancelForAnyReason"),
                      price: formatFiatCurrency(cfar.premium.fiat),
                    })}
                  {!!chfar &&
                    renderPricingRow({
                      label: t("changeForAnyReason"),
                      price: formatFiatCurrency(chfar.premium.fiat),
                    })}
                  {!!delayProtection &&
                    renderPricingRow({
                      label: t("ScheduleChangeDisruptionProtection"),
                      price: formatFiatCurrency(delayProtection.premium.fiat),
                    })}
                  {!!missedConnectionProtection &&
                    renderPricingRow({
                      label: t("MissedConnectionDisruptionProtection"),
                      price: formatFiatCurrency(
                        missedConnectionProtection.premium.fiat
                      ),
                    })}
                </div>
              </ExpandableSection>
            );
          }
        )}
      </div>
    );
  };

  const renderTripTotal = () => (
    <div className="trip-total-row">
      <Typography variant="body1" className="trip-total-text">
        {t("flightsTripPricingModalSubheader")}
      </Typography>
      <div className="trip-total-currency">
        <Typography variant="body1" className="trip-total-text">
          {formatFiatCurrency(totalPricing.total.fiat)}
        </Typography>
      </div>
    </div>
  );

  const renderPayment = () => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (paymentContent?.length <= 0) {
      return null;
    }
    return (
      <div className="payment-card-container">
        {paymentContent.map((item, idx) => {
          return (
            item && (
              <div
                key={idx}
                className={clsx("payment-card-details-container", item?.type)}
              >
                <Typography variant="body2" className="payment-title">
                  {t("commBank.trips.paymentMethodTitle")}
                </Typography>
                <div className="payment-card-amount-container">
                  <span className="payment-card-amount">
                    {item?.value
                      ? item.PaymentLineItem === PaymentLineItemEnum.UserCard
                        ? formatCurrency(item.value, {
                            currency: item.currency,
                          })
                        : item.value // How to format non card payments?
                      : null}
                  </span>
                  <span className="payment-card-details" data-dd-privacy="mask">
                    {item?.label}
                  </span>
                </div>
              </div>
            )
          );
        })}
      </div>
    );
  };

  return (
    <div className="flight-payment-summary-content apac">
      {renderPaymentForTravelers()}
      {renderTripTotal()}
      {renderPayment()}
    </div>
  );
};
