import { AmenityStatus, Amenity } from "@b2bportal/lodging-api";

const AMENITY_MAP = {
  [Amenity.AccessibleBathroom]: Amenity.Accessibility,
  [Amenity.AirportTransportationAvailableGeneral]:
    Amenity.AirportTransportation,
  [Amenity.BarOrLounge]: Amenity.Bar,
  [Amenity.BrailleOrRaisedSignage]: Amenity.Accessibility,
  [Amenity.BusinessFacilities]: Amenity.BusinessCenter,
  [Amenity.Childcare]: Amenity.KidFriendly,
  [Amenity.CoffeeTea]: Amenity.CoffeeMaker,
  [Amenity.ConferenceSpace]: Amenity.BusinessCenter,
  [Amenity.DryCleaningLaundryService]: Amenity.LaundryFacilities,
  [Amenity.FitnessCenter]: Amenity.FitnessFacilities,
  [Amenity.FreeAirportTransportation]: Amenity.AirportTransportation,
  [Amenity.FreeSelfParking]: Amenity.Parking,
  [Amenity.FullServiceSpa]: Amenity.Spa,
  [Amenity.IndoorPool]: Amenity.Pool,
  [Amenity.InRoomAccessibility]: Amenity.Accessibility,
  [Amenity.Laundry]: Amenity.LaundryFacilities,
  [Amenity.MeetingRooms]: Amenity.BusinessCenter,
  [Amenity.NumberOfBarsOrLounges]: Amenity.Bar,
  [Amenity.NumberOfRestaurants]: Amenity.Restaurant,
  [Amenity.OutdoorPool]: Amenity.Pool,
  [Amenity.OutdoorSeasonalPool]: Amenity.Pool,
  [Amenity.PaidBreakfast]: Amenity.FreeBreakfast,
  [Amenity.PaidSelfParking]: Amenity.Parking,
  [Amenity.PaidValetParking]: Amenity.Parking,
  [Amenity.ParkingAvailableGeneral]: Amenity.Parking,
  [Amenity.PetsAllowed]: Amenity.PetFriendly,
  [Amenity.PetsAllowedNoFees]: Amenity.PetFriendly,
  [Amenity.PetsOnlyCats]: Amenity.PetFriendly,
  [Amenity.PetsOnlyDogs]: Amenity.PetFriendly,
  [Amenity.PetsOnlyDogsAndCats]: Amenity.PetFriendly,
  [Amenity.PoolGeneral]: Amenity.Pool,
  [Amenity.PoolsideBar]: Amenity.Bar,
  [Amenity.RollInShower]: Amenity.Accessibility,
  [Amenity.RoomWifi]: Amenity.FreeWifi,
  [Amenity.RoundTheClockCheckIn]: Amenity.TwentyFourHourFrontDesk,
  [Amenity.SomeAccessibilityFeaturesAvailableGeneral]: Amenity.Accessibility,
  [Amenity.SpaTreatmentRoom]: Amenity.Spa,
  [Amenity.SteamRoom]: Amenity.Spa,
  [Amenity.SwimmingPool]: Amenity.Pool,
  [Amenity.TwentyFourHourFitnessFacilities]: Amenity.FitnessFacilities,
  [Amenity.WheelchairAccessibleParking]: Amenity.Accessibility,
  [Amenity.WheelchairAccessiblePathOfTravel]: Amenity.Accessibility,
};
const ORDERED_AMENITIES: Amenity[] = [
  Amenity.Pool,
  Amenity.FreeWifi,
  Amenity.PetFriendly,
  Amenity.AirportTransportation,
  Amenity.Parking,
  Amenity.Spa,
  Amenity.Restaurant,
  Amenity.FitnessFacilities,
  Amenity.FreeBreakfast,
  Amenity.BusinessCenter,
  Amenity.Bar,
  Amenity.LaundryFacilities,
  Amenity.TwentyFourHourFrontDesk,
  Amenity.Accessibility,
  Amenity.AdultsOnly,
  Amenity.KidFriendly,
  Amenity.HairDryer,
  Amenity.CoffeeMaker,
  Amenity.Heating,
  Amenity.AirConditioning,
  Amenity.Beach,
  Amenity.OutdoorSpace,
  Amenity.BarbecueGrill,
  Amenity.ComputerStation,
  Amenity.Entertainment,
  Amenity.HomeEssentials,
  Amenity.HotTub,
  Amenity.KitchenEssentials,
  Amenity.HomeInfo,
];

export const getSortedAmenities = (
  amenities: AmenityStatus[],
  limit = Infinity
): AmenityStatus[] => {
  return amenities
    .map((amenity) => {
      return {
        ...amenity,
        priority: ORDERED_AMENITIES.indexOf(amenity.amenity),
      };
    })
    .sort((a, b) => a.priority - b.priority)
    .slice(0, limit);
};

export const getUniqueSortedAmenities = (
  amenities: AmenityStatus[],
  limit = Infinity
): AmenityStatus[] => {
  return amenities
    .map((amenity) => {
      const name = AMENITY_MAP[amenity.amenity] || amenity.amenity;
      return {
        ...amenity,
        amenity: name,
        priority: ORDERED_AMENITIES.indexOf(name),
      };
    })
    .filter((a) => a.priority !== -1)
    .sort((a, b) => a.priority - b.priority)
    .reduce((list: AmenityStatus[], amenity) => {
      if (list[list.length - 1]?.amenity === amenity.amenity) {
        list[list.length - 1] = {
          ...list[list.length - 1],
          displayName: [
            list[list.length - 1].displayName,
            amenity.displayName,
          ].join(", "),
        };
      } else {
        list.push(amenity);
      }
      return list;
    }, [])
    .slice(0, limit);
};
