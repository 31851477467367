import { connect } from "react-redux";
import { ITripTerminus } from "@hopper-b2b/types";
import { IStoreState } from "../../../../../../reducers/types";
import {
  getOriginCategories,
  getOriginCategoriesLoading,
  getDestinationCategories,
  getDestinationCategoriesLoading,
} from "../../../../reducer";
import { actions } from "../../../../actions";
import {
  B2BSearchAutocomplete,
  IB2BSearchAutocompleteProps,
} from "@hopper-b2b/ui";

export const mapOriginDispatchToProps = {
  fetchValueCategories: actions.fetchOriginCategories,
};

export const mapOriginStateToProps = (state: IStoreState) => {
  const loading = !!getOriginCategoriesLoading(state);

  return {
    valueCategories: getOriginCategories(state),
    noTabIndex: true,
    loading,
  };
};

export const ConnectedOriginAutocomplete = connect(
  mapOriginStateToProps,
  mapOriginDispatchToProps
)(
  B2BSearchAutocomplete as (
    props: IB2BSearchAutocompleteProps<ITripTerminus | null>
  ) => JSX.Element
);

export const mapDestinationDispatchToProps = {
  fetchValueCategories: actions.fetchDestinationCategories,
};

export const mapDestinationStateToProps = (state: IStoreState) => {
  const loading = !!getDestinationCategoriesLoading(state);

  return {
    valueCategories: getDestinationCategories(state),
    noTabIndex: true,
    loading,
  };
};

export const ConnectedDestinationAutocomplete = connect(
  mapDestinationStateToProps,
  mapDestinationDispatchToProps
)(
  B2BSearchAutocomplete as (
    props: IB2BSearchAutocompleteProps<ITripTerminus | null>
  ) => JSX.Element
);
