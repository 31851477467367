import { ExchangeActionEnum } from "@b2bportal/air-exchange-api";
import { TripCategory } from "@hopper-b2b/types";
import { Box } from "@material-ui/core";
import { batch, useDispatch, useSelector } from "react-redux";
import { actions as searchActions } from "../../../search/actions";
import {
  getDepartureDate,
  getDestination,
  getOrigin,
  getReturnDate,
} from "../../../search/reducer";
import { setOutboundSelection, setReturnSelection } from "../../reducer/search";
import { getOutboundChanged, getReturnChanged } from "../../reducer/selectors";
import { getItineraryTripType } from "../../selectors";
import "./styles.scss";
import { CalendarPicker } from "@commbank/ui";

export interface IMobileCalendarPickerProps {
  onSearch: () => void;
}

const defaultProps: Partial<IMobileCalendarPickerProps> = {};

const MobileCalendarPicker = (
  props: IMobileCalendarPickerProps
): JSX.Element => {
  const { onSearch } = props;
  const dispatch = useDispatch();

  const departureDate = useSelector(getDepartureDate);
  const destination = useSelector(getDestination);
  const origin = useSelector(getOrigin);
  const outboundChanged = useSelector(getOutboundChanged);
  const returnChanged = useSelector(getReturnChanged);
  const returnDate = useSelector(getReturnDate);
  const tripType = useSelector(getItineraryTripType);

  const handleOnComplete = () => {
    batch(() => {
      dispatch(
        setOutboundSelection(
          outboundChanged ? ExchangeActionEnum.Change : ExchangeActionEnum.Keep
        )
      );
      if (tripType === TripCategory.ROUND_TRIP) {
        dispatch(
          setReturnSelection(
            returnChanged ? ExchangeActionEnum.Change : ExchangeActionEnum.Keep
          )
        );
      }
    });

    onSearch();
  };

  const onDepDateChange = (newDepDate: Date) => {
    dispatch(searchActions.setDepartureDate(newDepDate));
  };

  const onRetDateChange = (newRetDate: Date) => {
    dispatch(searchActions.setReturnDate(newRetDate));
  };

  return (
    <Box className="calendar-picker-container date-range-picker">
      <CalendarPicker
        isMobile
        calendarVisited
        isHistoryPushDisabled
        departureDate={departureDate}
        disabled={false}
        onComplete={handleOnComplete}
        populateSearchUrlParams={undefined}
        returnDate={returnDate}
        setCalendarVisited={() => undefined}
        setDepartureDate={onDepDateChange}
        setReturnDate={onRetDateChange}
        trackingProperties={{
          destination: destination.id.code.code,
          origin: origin.id.code.code,
          tripCategory: tripType,
        }}
        tripCategory={tripType}
      />
    </Box>
  );
};

MobileCalendarPicker.defaultProps = defaultProps;

export default MobileCalendarPicker;
