import { ConnectedProps, connect } from "react-redux";
import {
  setLoading,
  resetFilters,
  setAvailabilityLodgings,
  addAvailabilityLodgings,
  setSort,
  setSearchParams,
} from "../availability/actions/actions";
import { Shop } from "./Shop";
import {
  getLoading,
  getFilters,
  getFromDate,
  getGuests,
  getIsOverFiltered,
  getLodgings,
  getLodgingsFilterBoundaries,
  getPlace,
  getRooms,
  getUntilDate,
  getSort,
  ILodgingAvailabilityState,
} from "../availability/reducer";

const mapDispatchToProps = {
  setLoading,
  setAvailabilityLodgings,
  addAvailabilityLodgings,
  resetFilters,
  setSort,
  setSearchParams,
};
const mapStateToProps = (state: {
  lodgingAvailability: ILodgingAvailabilityState;
}) => {
  const lodgings = getLodgings(state);

  return {
    loading: getLoading(state),
    isOverFiltered: getIsOverFiltered(
      state.lodgingAvailability.lodgings,
      lodgings
    ),
    place: getPlace(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    lodgings,
    guests: getGuests(state),
    rooms: getRooms(state),
    filters: getFilters(state),
    filterBoundaries: getLodgingsFilterBoundaries(state),
    sort: getSort(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ConnectedLodgingShop = connector(Shop);

export type ShopProps = ConnectedProps<typeof connector>;
