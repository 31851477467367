const config = {
  ENV: import.meta.env.MODE,
  spreedlyKey:
    import.meta.env.VITE_SPREEDLY_ENVIRONMENT_KEY ||
    "MbskPSZ9MMH7vnuAItHyGdqotHe",
  googleMapsApiKey: import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_VERSION: import.meta.env.VITE_REACT_APP_VERSION,
  MOCK: import.meta.env.VITE_MOCK === "true" ?? false,
  SIFT_ACCOUNT: import.meta.env.VITE_SIFT_ACCOUNT,
  RECAPTCHA_KEY: import.meta.env.VITE_RECAPTCHA_KEY,
  googleIdentityClientId: import.meta.env.VITE_GOOGLE_IDENTITY_CLIENT_ID,
  KUSTOMER_CHAT_SUPPORT: import.meta.env.VITE_KUSTOMER_CHAT_SUPPORT,
  KUSTOMER_API_KEY: import.meta.env.VITE_KUSTOMER_API_KEY,
  KUSTOMER_BRAND_ID: import.meta.env.VITE_KUSTOMER_BRAND_ID,
  CBA_CARD_ART_URL: "https://www.commbank.com.au/content/cf/cards/",
};

export default config;
