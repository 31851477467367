import { CallState } from "@hopper-b2b/types";
import { Dispatch } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { getSelectedAccountReferenceId as getSelectedAccountId } from "../../../rewards/reducer";
import { hasUnsupportedPredictionFilters } from "../../../search/reducer";
import { actions as shopActions } from "../../../shop/actions";
import { CreateWatchOptions } from "../../actions/actions";
import {
  createWatchCallStateSelector,
  isWatchingSelector,
  listWatchCallStateSelector,
  predictionLoadingSelector,
} from "../../reducer/selectors";
import { DesktopPricePredictionSection } from "./DesktopPricePredictionSection";

const mapStateToProps = (state: IStoreState): IStateProps => {
  return {
    createWatchCallState: createWatchCallStateSelector(state),
    listWatchCallState: listWatchCallStateSelector(state),
    isWatching: isWatchingSelector(state),
    predictionLoading: predictionLoadingSelector(state),
    rewardsKey: getSelectedAccountId(state),
    hasUnsupportedPredictionFilters: hasUnsupportedPredictionFilters(state),
  };
};

interface IStateProps {
  createWatchCallState: CallState;
  listWatchCallState: CallState;
  isWatching: boolean;
  predictionLoading: boolean | null;
  rewardsKey: string;
  hasUnsupportedPredictionFilters: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    createWatch: (email: string, options?: CreateWatchOptions) =>
      dispatch(shopActions.createWatch(email, options)),
    deleteWatch: () => dispatch(shopActions.deleteWatch()),
    setCreateWatchCallState: (callState: CallState) =>
      dispatch(shopActions.setCreateWatchCallState(callState)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopPricePredictionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopPricePredictionSection = connector(
  DesktopPricePredictionSection
);
