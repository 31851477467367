export const SET_SELECTED_LODGING = "lodgingShop/SET_SELECTED_LODGING";
export type SET_SELECTED_LODGING = typeof SET_SELECTED_LODGING;

export const SET_SELECTED_ROOM = "lodgingShop/SET_SELECTED_ROOM";
export type SET_SELECTED_ROOM = typeof SET_SELECTED_ROOM;

export const SET_CHECK_IN_POLICIES = "lodgingShop/SET_CHECK_IN_POLICIES";
export type SET_CHECK_IN_POLICIES = typeof SET_CHECK_IN_POLICIES;

export const SET_CHECK_IN_INSTRUCTIONS =
  "lodgingShop/SET_CHECK_IN_INSTRUCTIONS";
export type SET_CHECK_IN_INSTRUCTIONS = typeof SET_CHECK_IN_INSTRUCTIONS;

export const SET_ROOMS = "lodgingShop/SET_ROOMS";
export type SET_ROOMS = typeof SET_ROOMS;

export const SET_SELECTED_RATE = "lodgingShop/SET_SELECTED_RATE";
export type SET_SELECTED_RATE = typeof SET_SELECTED_RATE;

export const SET_CURRENT_SHOP_STEP =
  "lodgingAvailability/SET_CURRENT_SHOP_STEP";
export type SET_CURRENT_SHOP_STEP = typeof SET_CURRENT_SHOP_STEP;

export const SET_SHOP_CALL_STATE = "lodgingAvailability/SET_SHOP_CALL_STATE";
export type SET_SHOP_CALL_STATE = typeof SET_SHOP_CALL_STATE;
