import axios from "axios";
import { config } from "../../config";

import {
  AcceptScheduleChangeRequest,
  AcceptScheduleChangeResponse,
} from "@hopper-b2b/types";
import { flightItinerariesApiPrefix } from "../paths";

export const acceptScheduleChange = async (
  req: AcceptScheduleChangeRequest
): Promise<AcceptScheduleChangeResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        `${flightItinerariesApiPrefix}/acceptScheduleChange`,
        req,
        {
          baseURL: config.baseURL,
        }
      );
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};
