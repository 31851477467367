import { getDepartureSlice } from "@hopper-b2b/types";
import { Divider } from "@material-ui/core";
import dayjs from "dayjs";
import {
  AddOnEnum,
  AddOnsSummary,
} from "../../../../../TripsList/components/ItineraryList/components/AddOnsSummary";
import { ExpandableSection } from "../../../ExpandableSection/component";
import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";

interface FlightAddOnsProps {
  flight: BookedFlightItineraryWithDepartureTime;
  addOnFlags: {
    hasCfar: boolean;
    hasMissedConnection: boolean;
    hasDelayDisruption: boolean;
  };
  actions: {
    openCfarModal: () => void;
    openDelayDisruptionModal: () => void;
    openMissedConnectionModal: () => void;
  };
}

export const FlightAddOns: React.FC<FlightAddOnsProps> = ({
  flight,
  addOnFlags: { hasCfar, hasMissedConnection, hasDelayDisruption },
  actions: {
    openCfarModal,
    openDelayDisruptionModal,
    openMissedConnectionModal,
  },
}) => {
  const { ancillaries, bookedItinerary } = flight;
  const departureSlice = getDepartureSlice(bookedItinerary);
  const hasAddOn = hasCfar || hasMissedConnection || hasDelayDisruption;

  const getTimeInDepartureTimezone = (time: string) => {
    const timezone = departureSlice?.segments?.[0]?.originTimeZone;
    if (!time || !timezone) return "";

    return dayjs(time).tz(timezone).format("YYYY-MM-DDTHH:mm:ss.SSS");
  };

  if (!hasAddOn) {
    return null;
  }

  return (
    <>
      <ExpandableSection className="flight-expandable-section" title="Add-ons">
        <AddOnsSummary
          label=""
          addOns={[
            ...(hasCfar
              ? [
                  {
                    expiryDate: getTimeInDepartureTimezone(
                      ancillaries?.cfar?.expired
                    ),
                    onClick: openCfarModal,
                    type: AddOnEnum.Cfar,
                  },
                ]
              : []),
            ...(hasDelayDisruption
              ? [
                  {
                    createdDate: getTimeInDepartureTimezone(
                      ancillaries?.delay?.created
                    ),
                    onClick: openDelayDisruptionModal,
                    type: AddOnEnum.DelayDisruption,
                  },
                ]
              : []),
            ...(hasMissedConnection
              ? [
                  {
                    expiryDate: getTimeInDepartureTimezone(
                      ancillaries?.missedConnection?.created
                    ),
                    onClick: openMissedConnectionModal,
                    type: AddOnEnum.MissedConnection,
                  },
                ]
              : []),
          ]}
        />
      </ExpandableSection>
      <Divider />
    </>
  );
};
