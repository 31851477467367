import { disruptionRefundPlanPath } from "../paths";
import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import {
  DisruptionExerciseRefundPlanRequest,
  DisruptionExerciseRefundPlanResponse,
  NETWORK_CALL_FAILED,
} from "@hopper-b2b/types";

// TODO: Update types from any
const disruptionRefundPlan = async (
  req: DisruptionExerciseRefundPlanRequest
): Promise<any> => {
  try {
    const res = await axiosInstance.post<DisruptionExerciseRefundPlanResponse>(
      disruptionRefundPlanPath,
      req
    );
    const { data } = res;
    return data;
  } catch (e) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
    throw e;
  }
};

export default disruptionRefundPlan;
