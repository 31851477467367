import common_translations from "../common.json";
import app from "./app.json";
import checkout from "./checkout.json";
import exchange from "./exchange.json";
import fintech from "./fintech.json";
import flights from "./flights.json";
import hotels from "./hotels.json";
import trips from "./trips.json";
import ui from "./ui.json";
import wallet from "./wallet.json";
import nubank from "./nubank.translation.json";

const bundle = {
  ...common_translations,
  ...app,
  ...flights,
  ...hotels,
  ...ui,
  ...checkout,
  ...trips,
  ...fintech,
  ...exchange,
  ...wallet,
};
export default bundle;

export const pt_nubankTranslations = nubank;
