import { connect, ConnectedProps } from "react-redux";

import { WatchDetailsModalContent } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  setCreateWatchCallState,
  setGetDetailsWatchCallState,
  setSelectedWatch,
  createWatch,
  deleteWatch,
} from "../../../TripsList/actions/actions";
import {
  getSelectedWatch,
  getSelectedWatchDetails,
} from "../../../TripsList/reducer";

const mapStateToProps = (state: IStoreState) => ({
  selectedWatch: getSelectedWatch(state),
  selectedWatchDetails: getSelectedWatchDetails(state),
});

const mapDispatchToProps = {
  createWatch,
  deleteWatch,
  setCreateWatchCallState,
  setGetDetailsWatchCallState,
  setSelectedWatch,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ConnectedWatchDetailsModalContentProps = ConnectedProps<
  typeof connector
>;

export const ConnectedWatchDetailsModalContent = connector(
  WatchDetailsModalContent
);
