import React from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { IconButton, Typography } from "@material-ui/core";
import { ApacActionLink, ApacIconComponent, ApacIconName } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";

interface FiltersAlertProps {
  open: boolean;
  filteredItemsCount: number;
  handlers: {
    onClose: () => void;
    onClearFilters: () => void;
  };
}

export const FiltersAlert: React.FC<FiltersAlertProps> = ({
  open,
  filteredItemsCount,
  handlers: { onClose, onClearFilters },
}) => {
  const { t } = useI18nContext();

  if (!open) {
    return null;
  }

  return (
    <span className={clsx(styles["filters-alert"])}>
      <IconButton
        id="close-snackbar"
        className={clsx(styles["close-button"])}
        onClick={onClose}
      >
        <ApacIconComponent name={ApacIconName.CircularClose} />
      </IconButton>

      <Typography variant="body1" className={clsx(styles.text)}>
        {t("commBank.hotelAvailability.filtersAlert.description", {
          count: filteredItemsCount,
        })}
      </Typography>
      <ApacActionLink
        showUnderline
        message={t("commBank.hotelAvailability.filtersAlert.clearFiltersLabel")}
        className={clsx(styles.link)}
        onClick={onClearFilters}
      />
    </span>
  );
};
