import {
  AirExchangeShopRequest,
  AirExchangeShopResponse,
  AirExchangeShopResponseEnum,
  AirExchangeShopSuccess,
} from "@b2bportal/air-exchange-api";
import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import {
  IApiConfig,
  NETWORK_CALL_FAILED,
  VIEWED_FLIGHT_SHOP_LOADING,
} from "@hopper-b2b/types";
import dayjs from "dayjs";

import Logger from "../../../helpers/Logger";
import { fetchExchangeSummariesPath } from "../paths";

export default async (
  shopReq: AirExchangeShopRequest,
  apiConfig?: IApiConfig
): Promise<AirExchangeShopSuccess> => {
  try {
    const reqStartTime = dayjs();
    const res = await axiosInstance.post<AirExchangeShopResponse>(
      fetchExchangeSummariesPath,
      shopReq
    );

    trackEvent({
      eventName: VIEWED_FLIGHT_SHOP_LOADING,
      properties: {
        runtime: dayjs().diff(reqStartTime, "seconds", true),
      },
    });

    if (
      res.data.AirExchangeShopResponse ===
      AirExchangeShopResponseEnum.AirExchangeShopSuccess
    ) {
      return res.data;
    }

    throw res.data;
  } catch (err) {
    Logger.debug(err);

    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      apiConfig
    );

    throw err;
  }
};
