import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useI18nContext } from "@hopper-b2b/i18n";
import { GuestsSelection, IPassengerCounts } from "@hopper-b2b/types";
import { ActionLink } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import {
  Box,
  Collapse,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ApacActionButton } from "../ApacActionButton";
import { ApacActionLink } from "../ApacActionLink";
import { ApacDesktopPopupModal } from "../ApacDesktopPopupModal";
import { ApacIcon, ApacIconName } from "../ApacIcon";
import { ApacIconComponent } from "../ApacIconComponent";
import { ApacMobileFullScreenModal } from "../ApacMobileFullScreenModal";
import "./PassengerCountPicker.styles.scss";
import { passengerSelectValidation } from "./passengerSelectValidation";

export const MINIMUM_ADULTS_COUNT = 1;
export const MAXIMUM_COUNT = 6; // Default maximum count
export const MAXIMUM_ROOM_COUNT = 4;
export const MAXIMUM_CHILDREN_COUNT = 6;

const MAX_CHILD_AGE = 17;
export const DEFAULT_CHILD_AGE = MAX_CHILD_AGE;

export type PassengerCountPickerType = IPassengerCounts | GuestsSelection;

const isPassengerCounts = (counts: PassengerCountPickerType) => {
  return "adultsCount" in counts ? true : false;
};

const getAdultsCount = (counts: PassengerCountPickerType) => {
  return (
    (counts as GuestsSelection)?.adults ||
    (counts as IPassengerCounts)?.adultsCount ||
    0
  );
};

const getChildrenCount = (counts: PassengerCountPickerType) => {
  if ("childrenCount" in counts) {
    return counts.childrenCount;
  } else {
    return counts.children.length;
  }
};

const getInfantsInSeatCount = (counts: PassengerCountPickerType) => {
  return (counts as IPassengerCounts)?.infantsInSeatCount || 0;
};

const getInfantsOnLapCount = (counts: PassengerCountPickerType) => {
  return (counts as IPassengerCounts)?.infantsOnLapCount || 0;
};
const getRoomsCount = (counts: PassengerCountPickerType) => {
  return (counts as GuestsSelection)?.rooms || 0;
};
const getChildrenAges = (counts: PassengerCountPickerType) => {
  return "children" in counts ? counts.children ?? [] : [];
};

export interface IPassengerCountPickerProps {
  onClickApply?: (passengerCounts: IPassengerCounts) => void;
  onClickApplyGuest?: (passengerCounts: GuestsSelection) => void;
  onUpdateCounts?: (passengerCounts: PassengerCountPickerType) => void;
  counts: PassengerCountPickerType;
  minimumCount?: number;
  maximumCount?: number;
  maximumChildren?: number;
  titles?: PassengerCountPickerTitles;
  unlimited?: boolean;
  className?: string;
  applyButtonText?: string;
  showRooms?: boolean;
  hideChildrenSubtitle?: boolean;
  onClose?: () => void;
  modalSubtitle?: string;
  captureChildrenAges?: boolean;
  showApplyButton?: boolean;
  isFlight?: boolean;
}

export type PassengerCountPickerTitles = {
  modalTitle?: string;
  modalSubtitle?: string;
  adultTitle?: string;
  adultSubtitle?: string;
  childrenTitle: string;
  childrenSubtitle: string;
  childrenAgePlaceholderKey?: string;
  infantSeatTitle?: string;
  infantSeatSubtitle?: string;
  infantLapTitle?: string;
  infantLapSubtitle?: string;
  roomsTitle?: string;
  roomsSubtitle?: string;
  showApplyButton?: boolean;

  // Error Messages
  travellingWithChildrenMessage?: string;
  travellingWithChildrenTitle?: string;
  travellingWithChildrenCta?: string;

  maxPassengerCount?: string;
  maxChildrenCount?: string;
  minAdultPerInfant?: string;
};

export const PassengerCountPicker = (props: IPassengerCountPickerProps) => {
  const { t } = useI18nContext();
  const { matchesMobile: isMobile } = useDeviceTypes();

  const {
    onClickApply,
    onClickApplyGuest,
    onUpdateCounts,
    counts,
    minimumCount = MINIMUM_ADULTS_COUNT,
    maximumCount = MAXIMUM_COUNT,
    unlimited,
    maximumChildren = MAXIMUM_CHILDREN_COUNT,

    titles = {
      modalTitle: t("passengerCount.modalTitle"),
      modalSubtitle: props.modalSubtitle || null,
      adultTitle: t("passengerCount.adultTitle"),
      adultSubtitle: "",
      childrenTitle: t("passengerCount.childrenTitle"),
      childrenSubtitle: props.hideChildrenSubtitle
        ? ""
        : t("passengerCount.childrenSubtitle"),
      childrenAgeMessage: t("passengerCount.childrenAgeMessage"),
      childrenAgePlaceholderKey: "passengerCount.childrenAgePlaceholder",
      infantLapTitle: t("passengerCount.infantLapTitle"),
      infantLapSubtitle: t("passengerCount.infantLapSubtitle"),
      infantSeatTitle: t("passengerCount.infantSeatTitle"),
      infantSeatSubtitle: t("passengerCount.infantSeatSubtitle"),
      roomsTitle: props.showRooms ? t("passengerCount.roomsTitle") : "",
      travellingWithChildrenTitle: t(
        "passengerCount.travellingWithChildrenTitle"
      ),
      travellingWithChildrenCta: t("passengerCount.travellingWithChildrenCta"),
    },

    className,
    applyButtonText = t("passengerCount.apply"),
    onClose,
    captureChildrenAges = false,
    showApplyButton,
    isFlight,
  } = props;

  const [adultsCount, setAdultsCount] = useState(getAdultsCount(counts));
  const [childrenCount, setChildrenCount] = useState(getChildrenCount(counts));
  const [infantsInSeatCount, setInfantsInSeatCount] = useState(
    getInfantsInSeatCount(counts)
  );
  const [infantsOnLapCount, setInfantsOnLapCount] = useState(
    getInfantsOnLapCount(counts)
  );
  const [roomsCount, setRoomsCount] = useState(getRoomsCount(counts));
  const [childrenAges, setChildrenAges] = useState(getChildrenAges(counts));
  const [openLearnMoreModal, setOpenLearnMoreModal] = useState(false);

  useEffect(() => {
    if (!onUpdateCounts) return;
    if (isFlight) {
      onUpdateCounts({
        adultsCount,
        childrenCount,
        infantsInSeatCount,
        infantsOnLapCount,
      });
    } else {
      onUpdateCounts({
        adults: adultsCount,
        children: captureChildrenAges
          ? childrenAges.slice(0, childrenCount)
          : Array(childrenCount).fill(DEFAULT_CHILD_AGE),
        rooms: roomsCount,
      });
    }
  }, [
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    childrenAges.length,
    roomsCount,
  ]);

  const totalChildrenCount = useMemo(
    () => childrenCount + infantsOnLapCount + infantsInSeatCount,
    [infantsOnLapCount, infantsInSeatCount, childrenCount]
  );

  const maximumReached =
    !unlimited &&
    adultsCount + childrenCount + infantsInSeatCount >= maximumCount;
  const maximumExceeded =
    !unlimited &&
    adultsCount + childrenCount + infantsInSeatCount > maximumCount;

  const maximumChildrenReached =
    totalChildrenCount >= maximumChildren || maximumReached;

  const missingChildrenAges =
    captureChildrenAges &&
    (childrenAges.length < childrenCount ||
      childrenAges.some(
        (age) => !Number.isInteger(age) || age < 0 || age > MAX_CHILD_AGE
      ));

  const moreInfantsThanAdults =
    infantsOnLapCount + infantsInSeatCount > adultsCount;

  const applyDisabled =
    !passengerSelectValidation(
      {
        adultsCount,
        childrenCount,
        infantsInSeatCount,
        infantsOnLapCount,
      },
      maximumCount,
      maximumChildren,
      unlimited
    ) || missingChildrenAges;

  const handleApplyClick = useCallback(() => {
    if ("adultsCount" in counts) {
      // Flight passengers

      onUpdateCounts &&
        onUpdateCounts({
          adultsCount,
          childrenCount,
          infantsInSeatCount,
          infantsOnLapCount,
        });
    } else {
      // hotels guests
      onUpdateCounts &&
        onUpdateCounts({
          adults: adultsCount,
          children: captureChildrenAges
            ? childrenAges.slice(0, childrenCount)
            : Array(childrenCount).fill(DEFAULT_CHILD_AGE),
          rooms: roomsCount,
        });
    }

    onClickApply &&
      onClickApply({
        adultsCount,
        childrenCount,
        infantsInSeatCount,
        infantsOnLapCount,
      });

    onClickApplyGuest &&
      onClickApplyGuest({
        adults: adultsCount,
        children: captureChildrenAges
          ? childrenAges.slice(0, childrenCount)
          : Array(childrenCount).fill(DEFAULT_CHILD_AGE),
        rooms: roomsCount,
      });
  }, [
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    onClickApplyGuest,
    captureChildrenAges,
    childrenAges,
    roomsCount,
  ]);

  return (
    <Box className={clsx("passenger-count-picker-root", className)}>
      <Box className="passenger-count-picker-container">
        <Box
          id="passenger-count-picker"
          className="passenger-count-picker-title"
        >
          <div className="passenger-picker-title-container">
            <ApacIcon
              className="passenger-picker-icon"
              name={ApacIconName.CBAFamily}
            />
            <div className="right">
              <Typography variant={isMobile ? "h3" : "h1"}>
                {t("flightsPageTitles.travellers")}
              </Typography>
              <Typography variant={isMobile ? "subtitle2" : "subtitle1"}>
                {titles.modalSubtitle ||
                  t("flightsPageTitles.travellersWarning", {
                    maxTravellers: maximumCount,
                  })}
              </Typography>
            </div>
          </div>
        </Box>
        <Box mt={3} className="passenger-count-picker-types-section">
          {renderCountPick({
            title: titles.adultTitle,
            subtitle: titles.adultSubtitle,
            className: "adults",
            minimumCount,
            maximumReached,
            count: adultsCount,
            onCountChange: (countAction) => {
              const newCount =
                typeof countAction === "function"
                  ? countAction(adultsCount)
                  : countAction;
              setAdultsCount(newCount);
            },
          })}
          {renderCountPick({
            title: titles.childrenTitle,
            subtitle: titles.childrenSubtitle,
            className: "children",
            maximumReached: maximumChildrenReached,
            count: childrenCount,
            onCountChange: setChildrenCount,
          })}
          {captureChildrenAges ? (
            <ChildrenAgePickers
              childCount={childrenCount}
              placeholderKey={titles.childrenAgePlaceholderKey}
              childrenAges={childrenAges}
              onChildAgesChange={setChildrenAges}
            />
          ) : null}
          {/* HIDE ROOM PICKER FOR NOW */}
          {/* {titles.roomsTitle &&
            renderCountPick({
              title: titles.roomsTitle,
              subtitle: titles.roomsSubtitle,
              className: "rooms",
              minimumCount,
              maximumReached: roomsCount === MAXIMUM_ROOM_COUNT,
              count: roomsCount,
              onCountChange: setRoomsCount,
            })} */}
          {isPassengerCounts(counts) &&
            titles.infantSeatTitle &&
            renderCountPick({
              title: titles.infantSeatTitle,
              subtitle: titles.infantSeatSubtitle,
              className: "infants-in-seat",
              count: infantsInSeatCount,
              onCountChange: setInfantsInSeatCount,
              maximumReached:
                maximumChildrenReached ||
                infantsOnLapCount + infantsInSeatCount >= adultsCount,
            })}
          {isPassengerCounts(counts) &&
            titles.infantLapTitle &&
            renderCountPick({
              title: titles.infantLapTitle,
              subtitle: titles.infantLapSubtitle,
              className: "infants-on-lap",
              count: infantsOnLapCount,
              onCountChange: setInfantsOnLapCount,
              maximumReached:
                maximumChildrenReached ||
                infantsOnLapCount + infantsInSeatCount >= adultsCount,
            })}
        </Box>

        <div className="error-messages-container">
          {/* Max Passengers */}
          <Collapse in={titles.maxPassengerCount && maximumExceeded}>
            <Box className="row-wrapper error">
              <ApacIconComponent name={ApacIconName.WarnOutlined} />
              <Typography variant="body1">
                {titles.maxPassengerCount}
              </Typography>
            </Box>
          </Collapse>
          {/* Max Children */}
          <Collapse
            in={titles.maxChildrenCount && totalChildrenCount > maximumChildren}
          >
            <Box className="row-wrapper error">
              <ApacIconComponent name={ApacIconName.WarnOutlined} />
              <Typography variant="body1">{titles.maxChildrenCount}</Typography>
            </Box>
          </Collapse>
          {/* Max infants */}
          <Collapse
            in={
              titles.minAdultPerInfant &&
              infantsOnLapCount + infantsInSeatCount > adultsCount
            }
          >
            <Box className="row-wrapper error">
              <ApacIconComponent name={ApacIconName.WarnOutlined} />
              <Typography variant="body1">
                {titles.minAdultPerInfant}
              </Typography>
            </Box>
          </Collapse>
          {isFlight && totalChildrenCount > 0 && (
            <ApacActionLink
              message={
                <Box className="row-wrapper learn-more">
                  <ApacIconComponent name={ApacIconName.Info} />
                  <Typography>
                    {t("passengerCount.travellingWithChildrenCta")}
                  </Typography>
                </Box>
              }
              onClick={() => setOpenLearnMoreModal(true)}
            />
          )}
        </div>

        <div className="passenger-count-button-container">
          {showApplyButton ? (
            <Box className="passenger-count-buttons">
              <ApacActionButton
                message={applyButtonText}
                onClick={handleApplyClick}
                disabled={applyDisabled}
              />
              <ApacActionButton
                variant="outlined"
                message={t("passengerCount.closeButtonLabel")}
                onClick={onClose}
              />
            </Box>
          ) : null}
        </div>
      </Box>

      {isFlight &&
        (!isMobile ? (
          <ApacDesktopPopupModal
            className="desktop-flight-shop-modal"
            contentClassName="modal-content"
            open={openLearnMoreModal}
            onClose={() => setOpenLearnMoreModal(false)}
          >
            {renderChildLearnMore(t, () => setOpenLearnMoreModal(false))}
          </ApacDesktopPopupModal>
        ) : (
          <ApacMobileFullScreenModal
            className="mobile-flight-shop-modal"
            open={openLearnMoreModal}
            onClose={() => setOpenLearnMoreModal(false)}
          >
            {renderChildLearnMore(t, () => setOpenLearnMoreModal(false))}
          </ApacMobileFullScreenModal>
        ))}
    </Box>
  );
};

const renderChildLearnMore = (t, onClose) => {
  const body = t("passengerCount.travellingWithChildrenMessage");
  return (
    <div className="travel-with-child-learn-more">
      <Typography variant="h1">
        {t("passengerCount.travellingWithChildrenTitle")}
      </Typography>

      <div dangerouslySetInnerHTML={{ __html: body }} />

      <ApacActionButton onClick={onClose} message={t("close.button")} />
    </div>
  );
};

const renderCountPick = (props: {
  title: string;
  subtitle?: string;
  className: string;
  minimumCount?: number;
  maximumReached?: boolean;
  count: number;
  onCountChange: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const {
    title,
    subtitle,
    className,
    minimumCount,
    count,
    maximumReached = false,
    onCountChange,
  } = props;

  const minusDisabled = count <= (minimumCount ?? 0);

  return (
    <Box className={clsx("passenger-count-picker-type", className)}>
      <Box className="passenger-type-description-container">
        <Box className="passenger-type-description">
          <Box className="passenger-type-title">{title}</Box>
          {subtitle && (
            <Box className="passenger-type-subtitle">{subtitle}</Box>
          )}
        </Box>
      </Box>
      <Box className="passenger-count-picker-container">
        <ActionLink
          label={`Decrease ${title} ${subtitle || ""} Traveler`}
          className={clsx("passenger-count-picker-button", "minus")}
          disabled={minusDisabled}
          onClick={() => {
            if (!minusDisabled) {
              onCountChange && onCountChange((count) => count - 1);
            }
          }}
          content={
            <Box
              className={clsx("button-content-wrapper", {
                disabled: minusDisabled,
              })}
            >
              <FontAwesomeIcon
                icon={faMinus as IconProp}
                className={clsx("icon", "minus", minusDisabled && "disabled")}
              />
            </Box>
          }
        />
        <Box className="passenger-count-number-container">
          <Box className="passenger-count-number">
            {count}
            <span
              className="passenger-count-alert"
              aria-live="polite"
              aria-atomic={true}
            >
              {subtitle ? `${count} ${title} ${subtitle}` : `${count} ${title}`}
            </span>
          </Box>
        </Box>
        <ActionLink
          label={`Increase ${title} ${subtitle || ""} Traveler`}
          className={clsx("passenger-count-picker-button", "plus")}
          onClick={() => {
            onCountChange && onCountChange((count) => count + 1);
          }}
          disabled={maximumReached}
          content={
            <Box
              className={clsx("button-content-wrapper", {
                disabled: maximumReached,
              })}
            >
              <FontAwesomeIcon
                icon={faPlus as IconProp}
                className={clsx("icon", "plus")}
              />
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

const ChildrenAgePickers = ({
  placeholderKey,
  childCount,
  childrenAges,
  onChildAgesChange,
}: {
  placeholderKey?: string;
  childCount: number;
  childrenAges: number[];
  onChildAgesChange: (childrenAges: number[]) => void;
}) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const captureChildAge = useCallback(
    (childIndex: number) => (event: React.ChangeEvent<{ value: unknown }>) => {
      const newChildrenAges = [...childrenAges];
      newChildrenAges[childIndex] = parseInt(event.target.value as string);
      onChildAgesChange(newChildrenAges);
    },
    [childrenAges, onChildAgesChange]
  );
  if (childCount <= 0) return null;
  return (
    <Box className="passenger-count-picker-child-age-container">
      <Box className="passenger-count-picker-child-age-picker-container">
        {Array.from({ length: childCount }).map((_, childIndex) => (
          <FormControl
            variant="outlined"
            className="passenger-count-picker-child-age-picker"
            key={childIndex}
          >
            <Select
              id="demo-simple-select-outlined"
              value={childrenAges[childIndex] ?? ""}
              displayEmpty
              onChange={captureChildAge(childIndex)}
              native={matchesMobile}
            >
              {placeholderKey ? (
                matchesMobile ? (
                  <option key="-" value="" disabled>
                    {t(placeholderKey, { index: childIndex + 1 })}
                  </option>
                ) : (
                  <MenuItem key="-" value="" disabled>
                    {t(placeholderKey, { index: childIndex + 1 })}
                  </MenuItem>
                )
              ) : null}
              {Array.from({ length: MAX_CHILD_AGE + 1 }).map((_, ageIndex) =>
                matchesMobile ? (
                  <option key={ageIndex} value={ageIndex}>
                    {ageIndex}
                  </option>
                ) : (
                  <MenuItem key={ageIndex} value={ageIndex}>
                    {ageIndex}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        ))}
      </Box>
    </Box>
  );
};
