import { tenantFlagsDisabled } from "@hopper-b2b/utilities";
import {
  ShopFilter,
  SliceStopCountFilter,
  NETWORK_CALL_FAILED,
  VIEWED_FLIGHT_SHOP_LOADING,
  Platform,
  IApiConfig,
  NO_AVAILABILITY_AIR,
} from "@hopper-b2b/types";
import { trackEvent, axiosInstance } from "@hopper-b2b/api";
import dayjs from "dayjs";
import { IShopParams } from "../../../modules/shop/actions/actions";
import {
  ShopSummaryRequest,
  ShopSummaryResponseV4Response,
  ShopSummaryResponseV4Success,
  airShoppingApi,
} from "@b2bportal/air-shopping-api";

export const fetchFlights = async (
  shopParams: IShopParams,
  isMobile: boolean | undefined,
  apiConfig?: IApiConfig,
  shopFilter?: ShopFilter
): Promise<ShopSummaryResponseV4Response> => {
  return new Promise(async (resolve, reject) => {
    const {
      origin,
      destination,
      departureDate,
      returnDate,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      fareclassOptionFilter,
    } = shopParams;
    const passengerObj = {};

    if (adultsCount > 0) passengerObj["ADT"] = adultsCount;
    if (infantsInSeatCount > 0) passengerObj["INS"] = infantsInSeatCount;
    if (infantsOnLapCount > 0) passengerObj["INF"] = infantsOnLapCount;
    if (childrenCount > 0) passengerObj["CNN"] = childrenCount;

    let tripFilter = ShopFilter.NoFilter;
    if (shopFilter) {
      tripFilter = shopFilter;
    } else {
      const filterOutBasicFares =
        !fareclassOptionFilter.basic &&
        fareclassOptionFilter.luxury &&
        fareclassOptionFilter.enhanced &&
        fareclassOptionFilter.premium &&
        fareclassOptionFilter.standard;
      if (fareclassOptionFilter && filterOutBasicFares) {
        if (stopsOption === SliceStopCountFilter.NONE) {
          tripFilter = ShopFilter.NonStopNoLCC;
        } else {
          tripFilter = ShopFilter.NoLCC;
        }
      } else if (stopsOption === SliceStopCountFilter.NONE) {
        tripFilter = ShopFilter.NonStop;
      }
    }

    const request: ShopSummaryRequest = {
      passengers: passengerObj,
      departureDate,
      ...(returnDate && { returnDate }),
      route: {
        origin,
        destination,
      },
      tripFilter: shopFilter || tripFilter,
      platform: isMobile ? Platform.Mobile : Platform.Desktop,
    };

    try {
      const startTime = dayjs();
      const res = await airShoppingApi(axiosInstance).apiV4ShopSummaryPost(
        request
      );
      trackEvent(
        {
          eventName: VIEWED_FLIGHT_SHOP_LOADING,
          properties: {
            runtime: dayjs().diff(startTime, "seconds", true),
          },
        },
        apiConfig
      );

      if (
        (res.data as ShopSummaryResponseV4Success)?.value?.flights?.outbound
          .length === 0
      ) {
        trackEvent(
          {
            eventName: NO_AVAILABILITY_AIR,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
      }

      if (tenantFlagsDisabled.FlightShopPrediction) {
        (res.data as ShopSummaryResponseV4Success).value.prediction = undefined;
      }
      resolve(res.data as ShopSummaryResponseV4Response);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });
};
