import {
  AirCancelFulfillmentPollResponse,
  AirCancelFulfillmentPollResponseEnum,
} from "@b2bportal/air-cancel-api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { pollNonCartFlightCancelFulfillPath } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

const pollNonCartFlightCancelFulfill = async (
  fcsId: string
): Promise<AirCancelFulfillmentPollResponseEnum | undefined> => {
  try {
    const req = {
      flightCancelSessionId: fcsId,
    };
    const res = await axiosInstance.put<AirCancelFulfillmentPollResponse>(
      pollNonCartFlightCancelFulfillPath,
      req
    );

    return res.data.AirCancelFulfillmentPollResponse;
  } catch (err) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
  }
};

export default pollNonCartFlightCancelFulfill;
