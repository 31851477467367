import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import {
  GetHotelReservationRequest,
  HotelItinerary,
  lodgingApi,
} from "@b2bportal/lodging-api";

export const fetchHotelReservation = async (
  bookingId: string,
  headerToken?: string
): Promise<HotelItinerary> =>
  new Promise((resolve, reject) => {
    const body: GetHotelReservationRequest = {
      reservationBookingId: bookingId,
    };
    lodgingApi(axiosInstance)
      .apiV0TripsHotelGetReservationPut(
        body,
        headerToken && {
          headers: {
            "Trip-Access-Token": headerToken,
          },
        }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        trackEvent({
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        });
        reject(e);
      });
  });
