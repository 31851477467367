import { toDate, formatDateForUrl } from "../../../../util";

import {
  ILodgingAvailabilityState,
  ILodgingFilterState,
  LodgingsFilterBoundaries,
  SortOption,
} from "../index";
import { AmenityStatus, Lodging } from "@b2bportal/lodging-api";
import { toStarRating } from "@hopper-b2b/utilities";
import { URL_PARAM_KEYS } from "@hopper-b2b/lodging-utils";
import dayjs from "dayjs";

const filterAmenities = (lodgings: Lodging[], filters: ILodgingFilterState) => {
  return lodgings.filter((lodging) => {
    let passes = 0;
    filters.amenities.forEach((am) => {
      if (lodging.lodging.amenities.some((a) => a.amenity === am)) {
        passes++;
      }
    });
    return filters.filterAmenitiesAnd
      ? passes === filters.amenities.length
      : passes > 0;
  });
};
const filterLodgings = (state: ILodgingAvailabilityState): Lodging[] => {
  if (state) {
    const { filters, lodgings } = state;

    const filteredLodgings = lodgings.filter((lodging) => {
      const pass = [null, null, null, null];
      if (filters.freeCancellation) {
        pass[0] = lodging.isFreeCancel;
      }
      if (filters.starRating.length) {
        pass[1] = filters.starRating.includes(
          toStarRating(lodging.lodging.starRating)
        );
      }
      if (filters.priceRange) {
        const price = lodging.price?.nightlyPrice?.fiat?.value;
        pass[2] =
          price >= filters.priceRange.min && price <= filters.priceRange.max;
      }
      if (filters.userRating > 0) {
        pass[3] = lodging.lodging.score >= filters.userRating;
      }
      return !pass.includes(false);
    });

    if (filters.amenities.length) {
      return filterAmenities(filteredLodgings, filters);
    }

    return filteredLodgings;
  }
  return [];
};

const sortLodgings = (
  lodgings: Lodging[],
  sortOption: SortOption
): Lodging[] => {
  const sortedLodgings = [...lodgings];
  switch (sortOption) {
    case "starRating":
      return sortedLodgings.sort(
        (a, b) =>
          toStarRating(b.lodging.starRating) -
          toStarRating(a.lodging.starRating)
      );
    case "userRating":
      return sortedLodgings.sort(
        (a, b) => b.lodging.score * 5 - a.lodging.score * 5
      );
    case "price":
      return sortedLodgings.sort(
        (a, b) => a.price.totalPrice.fiat.value - b.price.totalPrice.fiat.value
      );
    case "mostRecommended":
    default:
      return sortedLodgings;
  }
};

export const getLodgingsFilterBoundaries = (
  state: State,
  filtered = true
): LodgingsFilterBoundaries => {
  const amenities: Map<string, { amenity: AmenityStatus; count: number }> =
    new Map();
  if (state.lodgingAvailability.lodgings) {
    const lodgings = filtered
      ? filterLodgings(state.lodgingAvailability)
      : state.lodgingAvailability.lodgings;
    lodgings.forEach((lodge) => {
      // Amenities
      lodge.lodging.amenities.forEach((am) =>
        amenities.set(am.amenity, {
          amenity: am,
          count: amenities.get(am.amenity)?.count + 1 || 1,
        })
      );
    });
  }
  return {
    amenities,
  };
};

type State = { lodgingAvailability: ILodgingAvailabilityState };

export const getUntilDate = ({ lodgingAvailability }: State) =>
  lodgingAvailability?.untilDate;
export const getFromDate = ({ lodgingAvailability }: State) =>
  lodgingAvailability?.fromDate;
export const getPlace = ({ lodgingAvailability }: State) =>
  lodgingAvailability.place;

export const getLodgings = ({ lodgingAvailability }: State) =>
  sortLodgings(filterLodgings(lodgingAvailability), lodgingAvailability?.sort);

export const getIsOverFiltered = (
  lodgings: Lodging[],
  filteredLodgings: Lodging[]
) => filteredLodgings?.length === 0 && lodgings?.length > 0;

export const getFilteredOutCount = (
  lodgings: Lodging[],
  filteredLodgings: Lodging[]
) => lodgings?.length - filteredLodgings?.length;

export const getGuests = ({ lodgingAvailability }: State) =>
  lodgingAvailability.guests;
export const getRooms = ({ lodgingAvailability }: State) =>
  lodgingAvailability.rooms;
export const getFilters = ({ lodgingAvailability }: State) =>
  lodgingAvailability?.filters;
export const getLoading = ({ lodgingAvailability }: State) =>
  lodgingAvailability.loading;
export const getSort = ({ lodgingAvailability }: State) =>
  lodgingAvailability.sort;
export const getLocation = ({ lodgingAvailability }: State) =>
  lodgingAvailability.location;
// TODO remove getSearchParams once urlMiddleware supports react-router
export const getSearchParams = ({
  lodgingAvailability: {
    fromDate,
    untilDate,
    guests: { adults, children },
    //  rooms,
  },
}: State) => {
  let queryParams = "";
  const paramsValues = {
    [URL_PARAM_KEYS.FROM_DATE]: fromDate,
    [URL_PARAM_KEYS.UNTIL_DATE]: untilDate,
    [URL_PARAM_KEYS.ADULTS_COUNT]: adults,
    // TODO this should be enabled is showRooms is true
    // [URL_PARAM_KEYS.ROOMS_COUNT]: rooms,
    [URL_PARAM_KEYS.CHILDREN_COUNT]: children.length,
  };
  Object.entries(paramsValues).forEach(([paramKey, value]) => {
    const ampersand = queryParams.length > 0 ? "&" : "";
    if (value) {
      queryParams = `${queryParams}${ampersand}${paramKey}=${value}`;
    }
  });
  return queryParams;
};

export const getAvailabilityTrackingProperties = ({
  lodgingAvailability,
}: State) => lodgingAvailability?.trackingProperties;

export const getTotalNights = ({ lodgingAvailability }: State) => {
  if (lodgingAvailability.untilDate && lodgingAvailability.fromDate) {
    const untilDate = toDate(lodgingAvailability.untilDate);
    const fromDate = toDate(lodgingAvailability.fromDate);
    return untilDate.diff(fromDate, "days");
  }
};

export const getLodgingAvailabilityTrackingProperties = ({
  lodgingAvailability,
}: State): { [key: string]: number | string } => {
  const advance = dayjs(lodgingAvailability.fromDate).diff(dayjs(), "day");
  const check_in_date = formatDateForUrl(lodgingAvailability.untilDate);
  const check_out_date = formatDateForUrl(lodgingAvailability.fromDate);
  const los = getTotalNights({ lodgingAvailability });
  const sort_order = lodgingAvailability.sort;
  const number_of_filters_applied = Object.values(
    lodgingAvailability.filters
  ).filter(Boolean).length;
  const number_of_properties = lodgingAvailability.lodgings?.length || 0;
  return {
    advance,
    check_in_date,
    check_out_date,
    los,
    number_of_filters_applied,
    number_of_properties,
    sort_order,
  };
};
