import {
  HotelChangeQuoteReq,
  HotelChangeQuoteRes,
  LodgingChangeQuoteResEnum,
  NETWORK_CALL_FAILED,
  Uuid,
} from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { getHotelChangeQuotePath } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

/**
 * @description get change policy info for a booking
 * @param {HotelChangeQuoteReq} req Contains the reservation id
 * @return {HotelChangeQuoteSuccessRes} The cancellation info for the reservation passed in
 */
const getHotelChangeQuote = async (reservationId: Uuid) => {
  try {
    const req: HotelChangeQuoteReq = {
      reservationId,
    };
    const res = await axiosInstance.post<HotelChangeQuoteRes>(
      getHotelChangeQuotePath,
      req
    );

    if (res.data) {
      const { LodgingChangeQuoteResponse } = res.data;

      if (LodgingChangeQuoteResponse === LodgingChangeQuoteResEnum.Success) {
        return res.data;
      }
    }

    throw res.data;
  } catch (err) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        location: window.location.pathname,
      },
    });

    throw err;
  }
};
export default getHotelChangeQuote;
