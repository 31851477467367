import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import dayjs from "dayjs";
import { useCallback, useContext } from "react";

import { ClientContext } from "../../../../../App";
import { openAdaChat } from "@hopper-b2b/utilities";

interface ChfarDetailsProps {
  flight: BookedFlightItineraryWithDepartureTime;
  openTermsHandler: () => void;
}
export const ChfarDetails = ({
  flight,
  openTermsHandler,
}: ChfarDetailsProps) => {
  const clientContext = useContext(ClientContext);
  const { t } = useI18nContext();

  const sendToContactSupport = useCallback(() => {
    openAdaChat();
  }, []);

  return (
    <>
      <div className="chfar-page">
        <div className="chfar-page--title">
          {t("chfarExercise.title.detailsTitle")}
        </div>
        <div className="chfar-page--description chfar-section">
          <I18nMarkup
            tKey={"chfarExercise.description"}
            replacements={{
              departureDate: flight
                ? dayjs(flight.departureTime).format("dddd, hh:mm a z")
                : null,
            }}
          />
          <ol>
            <li>
              {t("chfarExercise.changeConditions1")}
              <span onClick={sendToContactSupport}>
                <I18nMarkup tKey={"chfarExercise.customerSupport"} />
              </span>
            </li>
            <I18nMarkup tKey={"chfarExercise.changeConditions2"} />
            <I18nMarkup tKey={"chfarExercise.changeConditions3"} />
            <I18nMarkup tKey={"chfarExercise.changeConditions4"} />
            <I18nMarkup tKey={"chfarExercise.changeConditions5"} />
          </ol>
        </div>
      </div>

      <div
        className="price-freeze-tos icon-card-container"
        onClick={openTermsHandler}
      >
        <div className="price-freeze-tos-icon icon-card-icon">
          <img
            className="mobile-left-icon"
            src={clientContext?.assets?.info}
            alt="info-icon"
          />
        </div>
        <div className="price-freeze-tos-info icon-card-details">
          <div className="price-freeze-tos-title">
            {t("priceFreezePurchase.termsOfService.cta")}
          </div>
          <div className="price-freeze-tos-chevron">
            <img
              className="mobile-left-icon"
              src={clientContext?.assets?.["chevron"]}
              alt="left-arrow-icon"
            />
          </div>
        </div>
      </div>
    </>
  );
};
