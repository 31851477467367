import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Typography } from "@material-ui/core";
import styles from "./EmptyState.module.scss";
import { ApacActionButton } from "@commbank/ui";

interface Props {
  locationName: string;
  resetFilters?: () => void;
  adjustDates?: () => void;
}

export function EmptyState({ resetFilters, adjustDates, locationName }: Props) {
  const { t } = useI18nContext();

  return (
    <div className={styles.EmptyState}>
      <Typography variant="h4" className={styles.EmptyStateTitle}>
        {t("emptyStateTitle", {
          location: locationName,
        })}
      </Typography>
      <Typography variant="body1" className={styles.EmptyStateDescription}>
        {resetFilters
          ? t("emptyStateDescription")
          : t("emptyStateDescriptionSearch")}
      </Typography>
      <Box className={styles.EmptyStateButtons}>
        <ApacActionButton
          className={styles.EmptyStateActionButton}
          onClick={adjustDates}
          message={t("emptyStateAdjustDatesLabel")}
        />
        {resetFilters && (
          <ApacActionButton
            variant="outlined"
            className={styles.EmptyStateActionButton}
            onClick={resetFilters}
            message={t("clearAllFilters")}
          />
        )}
      </Box>
    </div>
  );
}
