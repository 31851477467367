import {
  ChfarExerciseProgress,
  FintechProductType,
  FlightShopQueryParams,
  FlightSummaryInfo,
  FlightSummaryInfoWithKeys,
  FLIGHT_SHOP_TYPE,
  getFlightSummaryInfo,
  getReturnSlice,
  MyTripsModalTypes,
  SliceStopCountFilter,
  TripCategory,
  ChfarTrackingEvents,
  ChfarTrackingPageSelected,
  FlightShopType,
  CHFAR_ID_QUERY_PARAM,
  FlightSearchQueryParams,
  PassengerTypes,
} from "@hopper-b2b/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAirlinesMap,
  getAirportMap,
  getChfarExerciseProgress,
} from "../../../TripsList/reducer";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useHistory } from "react-router";
import {
  B2BLoadingPopup,
  MobileFloatingButton,
  TermsAndConditions,
} from "@hopper-b2b/ui";
import { Box } from "@material-ui/core";
import "./styles.scss";
import {
  BookedFlightItineraryWithDepartureTime,
  SingleTravelItinerary,
} from "@b2bportal/air-booking-api";
import {
  populateTripQueryParams,
  resetChfarExerciseState,
  setChfarExerciseProgress,
  setOpenModal,
} from "../../../TripsList/actions/actions";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { ChfarDetails, ChfarSelectRebook } from "./components";
import { PATH_FLIGHTS_SEARCH } from "../../../../utils/paths";
import { addFlightType } from "../../../TripsList/components/ItineraryList/components/FlightCard/helpers";
import { trackEvent } from "@hopper-b2b/api";
import { useUberBridge } from "@hopper-b2b/utilities";

dayjs.extend(timezone);
dayjs.extend(utc);

export const ChfarExercise = ({
  flight,
}: {
  flight: BookedFlightItineraryWithDepartureTime;
}) => {
  const { t } = useI18nContext();
  const { setHeader } = useUberBridge();
  const history = useHistory();
  const dispatch = useDispatch();

  const airportMap = useSelector(getAirportMap);
  const airlinesMap = useSelector(getAirlinesMap);
  const [openTerms, setOpenTerms] = useState(false);

  const chfarExerciseProgress = useSelector(getChfarExerciseProgress);

  const loading =
    chfarExerciseProgress === ChfarExerciseProgress.Pending ||
    chfarExerciseProgress === ChfarExerciseProgress.Loading;

  const mapTitleKeys = ({
    titleKeys,
    ...summary
  }: FlightSummaryInfoWithKeys): FlightSummaryInfo => {
    const title = t("originToDestination", titleKeys);
    return {
      title,
      ...summary,
    };
  };

  useEffect(() => {
    return () => {
      dispatch(resetChfarExerciseState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!loading) {
      trackEvent({
        eventName: ChfarTrackingEvents.ENTER_CHFAR_EXERCISE_FLOW,
        properties: { page_selected: ChfarTrackingPageSelected.EXERCISE },
      });
    }
  }, [loading]);

  useEffect(() => {
    setHeader({
      title: t("chfarExercise.header"),
    });
  }, [setHeader, t]);

  const chfarExerciseFlightShop = useCallback(() => {
    // We only support single travel booking currently
    // TODO: handle multi travel itinerary
    const itinerarySlices = (
      flight?.bookedItinerary.travelItinerary as SingleTravelItinerary
    ).slices;

    const chfarId = flight.ancillaries.chfar?.id.productId;
    if (!!chfarId && itinerarySlices.length) {
      const DATE_FORMAT = "YYYY-MM-DD";
      const isRoundTrip = itinerarySlices.length > 1;

      // First slice, first segment
      const firstSlice = itinerarySlices.at(0);
      const origin = firstSlice?.segments.at(0)?.origin.locationCode;
      // First slice, last segment
      const destination = firstSlice?.segments.at(-1)?.destination.locationCode;

      const lastSlice = itinerarySlices.at(-1);

      const departureDate = dayjs(
        firstSlice?.segments.at(0)?.zonedUpdatedDeparture
      ).format(DATE_FORMAT);

      const returnDate = isRoundTrip
        ? dayjs(lastSlice?.segments.at(0)?.zonedUpdatedDeparture).format(
            DATE_FORMAT
          )
        : undefined;

      const tripCategory = isRoundTrip
        ? TripCategory.ROUND_TRIP
        : TripCategory.ONE_WAY;

      const passengers = flight?.bookedItinerary.passengers;

      const passengerSearchQueryParams = passengers.alone.reduce(
        (passengerCountMap, passenger) => {
          if (passenger.type === PassengerTypes.Adult) {
            passengerCountMap[FlightSearchQueryParams.ADULT_COUNT] =
              passengerCountMap[FlightSearchQueryParams.ADULT_COUNT] + 1;
          } else if (passenger.type === PassengerTypes.InfantInSeat) {
            passengerCountMap[FlightSearchQueryParams.INFANT_IN_SEAT_COUNT] =
              passengerCountMap[FlightSearchQueryParams.INFANT_IN_SEAT_COUNT] +
              1;
          } else if (/^C..$/.test(passenger.type)) {
            passengerCountMap[FlightSearchQueryParams.CHILD_COUNT] =
              passengerCountMap[FlightSearchQueryParams.CHILD_COUNT] + 1;
          }
          return passengerCountMap;
        },
        {
          [FlightSearchQueryParams.ADULT_COUNT]:
            passengers.withLapInfants.length ?? 0,
          [FlightSearchQueryParams.CHILD_COUNT]: 0,
          [FlightSearchQueryParams.INFANT_IN_SEAT_COUNT]: 0,
          [FlightSearchQueryParams.INFANT_IN_LAP_COUNT]:
            passengers.withLapInfants.length ?? 0,
        }
      );

      const flightShopQueryObject = {
        [FlightShopQueryParams.ORIGIN]: origin ?? "",
        [FlightShopQueryParams.DESTINATION]: destination ?? "",
        [FlightShopQueryParams.DEPARTURE_DATE]: departureDate,
        ...(isRoundTrip && returnDate
          ? {
              [FlightShopQueryParams.RETURN_DATE]: returnDate,
            }
          : {}),
        [FlightShopQueryParams.TRIP_CATEGORY]: tripCategory,
        [FlightShopQueryParams.STOP_OPTIONS]: SliceStopCountFilter.ANY_NUMBER,
        [FLIGHT_SHOP_TYPE]: FlightShopType.CHFAR_EXERCISE,
        [CHFAR_ID_QUERY_PARAM]: chfarId,
        ...passengerSearchQueryParams,
      };

      trackEvent({
        eventName: ChfarTrackingEvents.CHFAR_POLICY_EXERCISE_STARTED,
        properties: {
          page_selected: ChfarTrackingPageSelected.EXERCISE,
          chfar: flight.ancillaries.chfar,
          chfarId,
        },
      });

      const shopQuery = new URLSearchParams(flightShopQueryObject).toString();
      history.push(`${PATH_FLIGHTS_SEARCH}?${shopQuery}`);
    }
  }, [flight, history]);

  const loadingMessage = () => {
    switch (chfarExerciseProgress) {
      case ChfarExerciseProgress.Loading:
        return t("cfarExercise.loadingMessage");
      case ChfarExerciseProgress.Pending:
      default:
        return t("cfarExercise.pendingMessage");
    }
  };

  const handleClick = useCallback(() => {
    switch (chfarExerciseProgress) {
      case ChfarExerciseProgress.Started:
        dispatch(
          setChfarExerciseProgress(ChfarExerciseProgress.FlightRebookSelect)
        );
        dispatch(populateTripQueryParams(history));
        break;
      case ChfarExerciseProgress.FlightRebookSelect:
        chfarExerciseFlightShop();
        break;
      default:
        break;
    }
  }, [chfarExerciseFlightShop, chfarExerciseProgress, dispatch, history]);

  const openModal = (type: MyTripsModalTypes) => {
    dispatch(setOpenModal({ type, selectedItinerary: addFlightType(flight) }));
  };

  const ctaText = useMemo(() => {
    switch (chfarExerciseProgress) {
      case ChfarExerciseProgress.Started:
        return t("chfarExercise.cta.continue");
      case ChfarExerciseProgress.FlightRebookSelect:
        return t("chfarExercise.cta.searchFlights");
      default:
        return t("chfarExercise.cta.continue");
    }
  }, [chfarExerciseProgress, t]);

  const handleOpenTerms = () => {
    trackEvent({
      eventName: ChfarTrackingEvents.TAP_TERMS_CONDITIONS,
      properties: { page_selected: ChfarTrackingPageSelected.EXERCISE },
    });
    setOpenTerms(true);
  };

  return (
    <>
      <Box>
        {loading ? (
          <B2BLoadingPopup
            open
            fullScreen={true}
            message={loadingMessage()}
            popupSize="mobile"
          />
        ) : (
          <Box className="mobile-chfar-exercise">
            {chfarExerciseProgress === ChfarExerciseProgress.Started ? (
              <ChfarDetails
                flight={flight}
                openTermsHandler={handleOpenTerms}
              />
            ) : chfarExerciseProgress ===
              ChfarExerciseProgress.FlightRebookSelect ? (
              <ChfarSelectRebook
                departureSummary={mapTitleKeys(
                  getFlightSummaryInfo(true, flight, airportMap, airlinesMap)
                )}
                returnSummary={
                  getReturnSlice(flight.bookedItinerary)
                    ? mapTitleKeys(
                        getFlightSummaryInfo(
                          false,
                          flight,
                          airportMap,
                          airlinesMap
                        )
                      )
                    : undefined
                }
                onClick={openModal}
              />
            ) : null}
            <MobileFloatingButton
              className="chfar-cta-button"
              onClick={handleClick}
              wide
            >
              {ctaText}
            </MobileFloatingButton>
          </Box>
        )}
      </Box>
      <TermsAndConditions
        productType={FintechProductType.Chfar}
        open={openTerms}
        onClose={() => setOpenTerms(false)}
      />
    </>
  );
};
