import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import styles from "./UserRating.module.scss";
import { useI18nContext } from "@hopper-b2b/i18n";

interface Props {
  value: number;
  onChange(nextValue: number): void;
}

export function UserRating({ value, onChange }: Props) {
  const { t } = useI18nContext();

  const options = [
    {
      toggleValue: 0,
      content: t("any"),
    },
    {
      toggleValue: 0.7,
      content: t("veryGood"),
    },
    {
      toggleValue: 0.9,
      content: t("excellent"),
    },
  ];

  return (
    <div className={styles.UserRating}>
      <ToggleButtonGroup
        value={value}
        onChange={(_event, nextValue: number) => {
          if (nextValue != null) onChange(nextValue);
        }}
        aria-label={t("userRating")}
        size="large"
        exclusive
      >
        {options.map(({ toggleValue, content }) => (
          <ToggleButton
            key={toggleValue}
            selected={value === toggleValue}
            value={toggleValue}
          >
            {content}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
}
