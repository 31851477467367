import { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import { HotelItinerary } from "@b2bportal/lodging-api";
import {
  FlightItinerarySegmentStatusEnum,
  getDepartureSlice,
  getReturnSlice,
  getTripSegmentsFromItinerarySegments,
  ITimeGrouping,
  TagColors,
  TimeGroupingEnum,
  VIEWED_MAJOR_SCHEDULE_CHANGE,
  SCHEDULE_CHANGE_DECISION,
  ScheduleChangeUserActionEnum,
  ScheduleChangeChoice,
} from "@hopper-b2b/types";
import { Box, Divider, Typography } from "@material-ui/core";
import {
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
  ScheduleChange,
} from "@b2bportal/air-booking-api";

import "./styles.scss";
import {
  FlightDetailsSummary,
  ScheduleChangeForm,
  StatusTag,
  MixedCabinToolTip,
} from "@hopper-b2b/ui";
import { ScheduleChangeStateModal } from "./components/ScheduleChangeRequestedModal";
import { ScheduleChangeModalContentConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import { trackEvent } from "@hopper-b2b/api";

import { DATE_FORMAT } from "../../../../constants";
import { getIsMixedClass } from "../../../../../../utils/helpers";
import { useI18nContext, I18nMarkup } from "@hopper-b2b/i18n";
import dayjs from "dayjs";

export interface IScheduleChangeModalContentProps
  extends ScheduleChangeModalContentConnectorProps,
    RouteComponentProps {
  flight: BookedFlightItineraryWithDepartureTime;
  airportMap: { [key: string]: Airport | undefined };
  airlineMap: { [key: string]: Airline | undefined };
  isMobile: boolean;
  setSelectedFlight: (
    selectedFlight: BookedFlightItineraryWithDepartureTime | null
  ) => void;
  setSelectedHotel: (selectedHotel: HotelItinerary | null) => void;
}

export enum ScheduleChangeState {
  Display,
  InProgress,
  Requested,
  Accepted,
  Failure,
}

/**
 * @deprecated Component to be implemented in the libs/self-serve folder
 */
export const ScheduleChangeModalContent = (
  props: IScheduleChangeModalContentProps
) => {
  const {
    flight,
    airportMap,
    airlineMap,
    isMobile,
    setOpenModal,
    setSelectedFlight,
    setSelectedHotel,
    acceptScheduleChange,
    denyScheduleChange,
    history,
    acceptState,
    denyState,
    viewedMajorScheduleChangeProperties,
  } = props;
  const { t } = useI18nContext();

  const TIMES: ITimeGrouping[] = [
    {
      grouping: TimeGroupingEnum.EARLY_MORNING,
      label: t("times.earlyMorning"),
      sublabel: "12:00am - 4:59am",
    },
    {
      grouping: TimeGroupingEnum.AFTERNOON,
      label: t("times.afternoon"),
      sublabel: "12:00pm - 5:59pm",
    },
    {
      grouping: TimeGroupingEnum.MORNING,
      label: t("times.morning"),
      sublabel: "5:00am - 11:59am",
    },
    {
      grouping: TimeGroupingEnum.EVENING,
      label: t("times.evening"),
      sublabel: "6:00pm - 11:59pm",
    },
  ];

  const [scheduleChangeState, setScheduleChangeState] = useState(
    ScheduleChangeState.Display
  );
  const [action, setAction] = useState<ScheduleChangeUserActionEnum | null>(
    null
  );

  const scheduleChange = flight.bookedItinerary.scheduleChange;
  const outboundChanged = isUpdated(flight, true);
  const returnChanged = isUpdated(flight, false);
  const [isOutgoingMixedClass, setIsOutgoingMixedClass] = useState(false);
  const [isReturnMixedClass, setIsReturnMixedClass] = useState(false);
  const departureSlice = getDepartureSlice(flight.bookedItinerary);
  const returnSlice = getReturnSlice(flight.bookedItinerary);
  useEffect(() => {
    trackEvent({
      eventName: VIEWED_MAJOR_SCHEDULE_CHANGE,
      properties: {
        ...viewedMajorScheduleChangeProperties,
      },
    });
  }, []);

  const onClose = () => {
    setSelectedHotel(null);
    setSelectedFlight(flight);
    setOpenModal({ type: null, selectedItinerary: null });
  };

  const handleScheduleChangeAction = (
    action: ScheduleChangeUserActionEnum | null,
    outboundTimes: TimeGroupingEnum[],
    inboundTimes: TimeGroupingEnum[]
  ) => {
    if (action) {
      setAction(action);
      switch (action) {
        case ScheduleChangeUserActionEnum.Accept:
          setScheduleChangeState(ScheduleChangeState.InProgress);
          trackEvent({
            eventName: SCHEDULE_CHANGE_DECISION,
            properties: {
              ...viewedMajorScheduleChangeProperties,
              decision_outcome: "accept",
            },
          });
          acceptScheduleChange(history, flight.bookedItinerary.id);
          break;
        case ScheduleChangeUserActionEnum.Deny:
          setScheduleChangeState(ScheduleChangeState.InProgress);
          trackEvent({
            eventName: SCHEDULE_CHANGE_DECISION,
            properties: {
              ...viewedMajorScheduleChangeProperties,
              decision_outcome: "deny",
            },
          });
          denyScheduleChange(
            {
              itineraryId: flight.bookedItinerary.id,
              outboundPreferences: outboundTimes.map((val) =>
                getTimeChoice(val, scheduleChange!, false)
              ),
              returnPreferences: inboundTimes.map((val) =>
                getTimeChoice(val, scheduleChange!, true)
              ),
              additionalRemarks: "",
            },
            history
          );
      }
    }
  };

  const getTimeChoice = (
    val: TimeGroupingEnum,
    scheduleChange: ScheduleChange,
    isReturn: boolean
  ): ScheduleChangeChoice => {
    console.log(scheduleChange);
    const dep = isReturn
      ? scheduleChange.next[1]?.segments[0]?.scheduledDeparture.split("T")[0]
      : scheduleChange.next[0]?.segments[0]?.scheduledDeparture.split("T")[0];
    const arr = isReturn
      ? scheduleChange.next[1]?.segments[0]?.scheduledArrival.split("T")[0]
      : scheduleChange.next[0]?.segments[0]?.scheduledArrival.split("T")[0];
    switch (val) {
      case TimeGroupingEnum.EARLY_MORNING:
        return {
          departAfter: `${dep}T00:00:00.000Z`,
          departBefore: `${arr}T04:59:00.000Z`,
        };
      case TimeGroupingEnum.AFTERNOON:
        return {
          departAfter: `${dep}T12:00:00.000Z`,
          departBefore: `${arr}T17:59:00.000Z`,
        };
      case TimeGroupingEnum.MORNING:
        return {
          departAfter: `${dep}T05:00:00.000Z`,
          departBefore: `${arr}T11:59:00.000Z`,
        };
      case TimeGroupingEnum.EVENING:
        return {
          departAfter: `${dep}T18:00:00.000Z`,
          departBefore: `${arr}T23:59:00.000Z`,
        };
    }
  };

  useEffect(() => {
    if (departureSlice) {
      setIsOutgoingMixedClass(getIsMixedClass(departureSlice));
    }
    if (returnSlice) {
      setIsReturnMixedClass(getIsMixedClass(returnSlice));
    }
  }, [flight]);

  const getItineraryDetailsHeader = (
    scheduleChange: ScheduleChange,
    isOutgoing: boolean,
    airportMap: { [key: string]: Airport | undefined }
  ) => {
    const slice =
      !isOutgoing && scheduleChange.next.length > 0
        ? scheduleChange.next[1]
        : scheduleChange.next[0];
    return t("itineraryDetailsHeaderSimple", {
      destination: airportMap[slice!.segments[0].origin.locationCode]?.cityName,
      date: dayjs(slice!.segments[0].scheduledDeparture).format(DATE_FORMAT),
    });
  };

  const SCHEDULE_CHANGE_FORM_TITLES = {
    headingCopy: (outboundChanged: boolean, returnChanged: boolean) => {
      if (outboundChanged && returnChanged) {
        return (
          <span
            dangerouslySetInnerHTML={{ __html: t("headingOutboundAndReturn") }}
          ></span>
        );
      } else {
        return (
          <>
            <I18nMarkup
              tKey={"scheduleChangeFormTitles.headingOutboundOrReturn"}
              replacements={{
                flightType: outboundChanged ? t("outbound") : t("return"),
              }}
            />
            :
          </>
        );
      }
    },
    subheadingCopy: t("scheduleChangeFormTitles.subheading"),
    acceptButtonTitle: t("scheduleChangeFormTitles.acceptButtonTitle"),
    acceptDetails: t("scheduleChangeFormTitles.acceptDetails"),
    denyButtonTitle: t("scheduleChangeFormTitles.denyButtonTitle"),
    denyDetails: t("scheduleChangeFormTitles.denyDetails"),
    timePreferenceCopy: (isOutbound: boolean) => {
      return (
        <I18nMarkup
          tKey={"scheduleChangeFormTitles.timePreference"}
          replacements={{
            flightType: isOutbound ? t("outbound") : t("return"),
          }}
        />
      );
    },
  };

  return (
    <Box
      className={clsx("schedule-change-modal", {
        mobile: isMobile,
        "schedule-change-state":
          scheduleChangeState !== ScheduleChangeState.Display,
      })}
    >
      {scheduleChangeState === ScheduleChangeState.Display ? (
        <Fragment>
          <Typography className="schedule-change-modal-header" variant="h2">
            {SCHEDULE_CHANGE_FORM_TITLES.headingCopy(
              outboundChanged,
              returnChanged
            )}
          </Typography>
          <Typography
            className="schedule-change-modal-description"
            variant="body1"
          >
            {t("scheduleChange.subtitle")}
          </Typography>
          <Typography
            className="view-previous-itinerary"
            variant="body1"
            color="primary"
            onClick={() => onClose()}
          >
            {t("viewPreviousItinerary")}
          </Typography>
          <Typography className="review-new-itinerary" variant="subtitle1">
            {t("reviewNewItinerary")}:
          </Typography>
          <Box className="flight-summary-container">
            <Box className="outbound-itinerary">
              <Box className="slice-info-title">
                {outboundChanged && (
                  <StatusTag
                    className="slice-info-update-tag"
                    tagInfo={{ label: "Updated", type: TagColors.GREEN }}
                  />
                )}
                <Typography variant="subtitle2">
                  <span className="direction-label">{t("outbound")} </span>
                  {getItineraryDetailsHeader(
                    flight.bookedItinerary.scheduleChange!,
                    true,
                    airportMap
                  )}
                  {isOutgoingMixedClass && <MixedCabinToolTip />}
                </Typography>
              </Box>
              <FlightDetailsSummary
                className="outbound-details"
                {...getFlightDetailsProps(
                  flight.bookedItinerary.scheduleChange!,
                  true,
                  airportMap,
                  airlineMap
                )}
                isMixedCabinClass={isOutgoingMixedClass}
              />
            </Box>
            {scheduleChange && scheduleChange.next.length > 1 && (
              <Box className="return-itinerary">
                <Box className="slice-info-title">
                  {returnChanged && (
                    <StatusTag
                      className="slice-info-update-tag"
                      tagInfo={{ label: "Updated", type: TagColors.GREEN }}
                    />
                  )}
                  <Typography variant="subtitle2">
                    <span className="direction-label">{t("return")} </span>
                    {getItineraryDetailsHeader(
                      flight.bookedItinerary.scheduleChange!,
                      false,
                      airportMap
                    )}
                    {isReturnMixedClass && <MixedCabinToolTip />}
                  </Typography>
                </Box>
                <FlightDetailsSummary
                  className="return-details"
                  {...getFlightDetailsProps(
                    flight.bookedItinerary.scheduleChange!,
                    false,
                    airportMap,
                    airlineMap
                  )}
                  isMixedCabinClass={isReturnMixedClass}
                />
              </Box>
            )}
          </Box>
          <Divider className={"schedule-change-modal-divider"} />
          <ScheduleChangeForm
            timeGroupings={TIMES}
            titles={{
              ...SCHEDULE_CHANGE_FORM_TITLES,
              headingCopy: SCHEDULE_CHANGE_FORM_TITLES.headingCopy(
                outboundChanged,
                returnChanged
              ),
              outboundTimePreferenceCopy:
                SCHEDULE_CHANGE_FORM_TITLES.timePreferenceCopy(true),
              returnTimePreferenceCopy:
                SCHEDULE_CHANGE_FORM_TITLES.timePreferenceCopy(false),
            }}
            outboundChanged={outboundChanged}
            returnChanged={returnChanged}
            isMobile={!!isMobile}
            onClick={handleScheduleChangeAction}
          />
        </Fragment>
      ) : (
        <ScheduleChangeStateModal
          onClose={onClose}
          acceptCallState={acceptState}
          denyCallState={denyState}
          action={action}
          setScheduleChangeState={setScheduleChangeState}
          scheduleChangeState={scheduleChangeState}
        />
      )}
    </Box>
  );
};

const isUpdated = (
  flight: BookedFlightItineraryWithDepartureTime,
  isOutgoing: boolean
): boolean => {
  const slice = isOutgoing
    ? getDepartureSlice(flight.bookedItinerary)
    : getReturnSlice(flight.bookedItinerary);
  return (
    !!slice &&
    slice.segments.some(
      (s) =>
        s.status ===
          FlightItinerarySegmentStatusEnum.ConfirmedPendingNewChange ||
        s.status === FlightItinerarySegmentStatusEnum.UnMapped ||
        s.status === FlightItinerarySegmentStatusEnum.UnMappedPersisted
    )
  );
};

const getFlightDetailsProps = (
  scheduleChange: ScheduleChange,
  isOutgoing: boolean,
  airportMap: { [key: string]: Airport | undefined },
  airlineMap: { [key: string]: Airline | undefined }
) => {
  const slice =
    !isOutgoing && scheduleChange.next.length > 1
      ? scheduleChange.next[1]
      : scheduleChange.next[0];
  return {
    tripSlice: slice,
    segments: getTripSegmentsFromItinerarySegments(
      slice!.segments,
      airportMap,
      airlineMap
    ),
    departureTime: slice!.segments[0].scheduledDeparture || "",
    planeInfo: slice.segments[0].equipmentType,
    fareClass:
      slice.segments[0].cabinClassName || slice.segments[0].bookingClassCode,
    plusDays: slice!.segments
      .filter((s) => s.plusDays && s.plusDays > 0)
      .reduce((total, segment) => total + (segment.plusDays ?? 0), 0),
  };
};
