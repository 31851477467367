import {
  BookedFlightItineraryWithDepartureTime,
  FlightItinerarySegmentStatus,
  PortalItineraryStatus,
  TravelCredit,
} from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  IOpenModal,
  ItineraryEnum,
  MyTripsModalTypes,
  ScheduleChangeSeverity,
} from "@hopper-b2b/types";
import { ActionButton, BannerSeverity, Slot } from "@hopper-b2b/ui";
import { CurrencyFormatters } from "@hopper-b2b/utilities";
import clsx from "clsx";
import dayjs from "dayjs";

interface IStatusBannerProps {
  flight: BookedFlightItineraryWithDepartureTime;
  hasMajorScheduleChange: boolean;
  hasMinorScheduleChange: boolean;
  isMobile: boolean;
  setOpenModal: (modal: IOpenModal) => void;
}

/**
 * Wrapper that renders different `mytrips-notification-banner` slots
 * and passes in the appropriate props based on the flight's status
 */
export const StatusBanner = ({
  flight,
  hasMajorScheduleChange,
  hasMinorScheduleChange,
  isMobile,
  setOpenModal,
}: IStatusBannerProps) => {
  const { t } = useI18nContext();
  const { bookedItinerary, status, travelCredit } = flight;
  const { scheduleChange } = bookedItinerary;
  const unknownScheduleChange =
    scheduleChange?.severity === ScheduleChangeSeverity.Unknown;

  const modifiedStatusCheck = (status: FlightItinerarySegmentStatus) =>
    status === FlightItinerarySegmentStatus.ConfirmedPendingNewChange ||
    status === FlightItinerarySegmentStatus.UnMapped ||
    status === FlightItinerarySegmentStatus.UnMappedPersisted;

  const getCancelledNotificationLabel = (ftc?: TravelCredit) => {
    if (ftc) {
      const formattedCredit = CurrencyFormatters.get(
        ftc.credit.currency
      ).format(ftc.credit.amount);

      return t("cancelledItineraryCredit", {
        interpolation: { escapeValue: false },
        currency: "", // We format the currency using the configured global format and pass it to the translation instead of passig both the currency and the amount
        credit: formattedCredit,
      });
    }
    return t("cancelledItineraryPeriod");
  };

  const getMajorScheduleChangeBanner = () => {
    const expDate = dayjs(scheduleChange.expiry).format("ddd, MMM D, h:mm A");
    const [outboundSlice, returnSlice] = scheduleChange.next;
    const outboundChanged = !!outboundSlice?.segments?.find((s) =>
      modifiedStatusCheck(s.status)
    );
    const returnChanged = returnSlice
      ? !!returnSlice.segments?.find((s) => modifiedStatusCheck(s.status))
      : false;
    let context = "";

    if (outboundChanged && !returnChanged) {
      context = "outbound";
    } else if (!outboundChanged && returnChanged) {
      context = "return";
    }

    return (
      <Slot
        id="mytrips-notification-banner"
        className={clsx("trips-notification-banner", { mobile: isMobile })}
        cta={
          <ActionButton
            fill="blue"
            onClick={() =>
              setOpenModal({
                type: MyTripsModalTypes.ScheduleChange,
                selectedItinerary: { ...flight, type: ItineraryEnum.Flight },
              })
            }
            message={t("scheduleChange.reviewScheduleChange")}
            size="small"
          />
        }
        html={t("scheduleChange.majorLabel", { context, expDate })}
        severity={BannerSeverity.ERROR}
      />
    );
  };

  const getMinorScheduleChangeBanner = () => {
    return (
      <Slot
        id="mytrips-notification-banner"
        className={clsx("trips-notification-banner", { mobile: isMobile })}
        html={t("scheduleChange.minorLabel")}
        severity={BannerSeverity.WARNING}
      />
    );
  };

  if (status === PortalItineraryStatus.Canceled) {
    const label = getCancelledNotificationLabel(travelCredit);

    return (
      <Slot
        id="mytrips-notification-banner"
        className={clsx("trips-notification-banner", { mobile: isMobile })}
        label={label}
        severity={BannerSeverity.ERROR}
      />
    );
  }

  if (hasMajorScheduleChange && !unknownScheduleChange) {
    return getMajorScheduleChangeBanner();
  }

  if (hasMinorScheduleChange && !unknownScheduleChange) {
    return getMinorScheduleChangeBanner();
  }

  return null;
};
