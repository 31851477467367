import { useEffect, DependencyList } from "react";

export function useScrollToWhenVisible(selector: string, deps: DependencyList) {
  useEffect(() => {
    if (selector) {
      let waitTime = 10;
      let timeoutId;
      const scrollAfterWait = () => {
        timeoutId = setTimeout(() => {
          const anchorEl = document.querySelector(selector);
          if (anchorEl) {
            anchorEl.scrollIntoView({ behavior: "smooth", block: "start" });
          } else {
            waitTime *= 5;
            if (waitTime < 500) {
              scrollAfterWait();
            }
          }
        }, waitTime);
      };
      scrollAfterWait();
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector, ...deps]);
}
