import {
  FiatPrice,
  WalletCreditTransaction,
} from "@b2bportal/commbank-au-wallet-api";
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useMemo,
  useState,
} from "react";
import { fetchWalletSummary } from "../api";

export interface WalletState {
  creditBalance: FiatPrice;
  creditHistory: WalletCreditTransaction[];
  isLoadingWallet: boolean;

  fetchAndSetWalletSummary: () => Promise<FiatPrice>;
}

const initValue: WalletState = {
  creditBalance: undefined,
  creditHistory: [],
  isLoadingWallet: false,

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchAndSetWalletSummary: () => undefined,
};

export const WalletContext = createContext<WalletState>(initValue);

export type WalletProviderProps = {
  initState?: WalletState;
};

export const WalletProvider: FC<WalletProviderProps & PropsWithChildren> = ({
  initState = initValue,
  children,
}) => {
  const [isLoadingWallet, setIsLoadingWallet] = useState(false);
  const [creditBalance, setCreditBalance] = useState<FiatPrice>(
    initState.creditBalance
  );
  const [creditHistory, setCreditHistory] = useState<WalletCreditTransaction[]>(
    initState.creditHistory
  );

  const fetchAndSetWalletSummary = useCallback(async () => {
    setIsLoadingWallet(true);
    const summary = await fetchWalletSummary();
    setCreditBalance(summary.balance);
    setCreditHistory(summary.transactions);
    setIsLoadingWallet(false);
    return summary.balance;
  }, []);

  const value: WalletState = useMemo(
    () => ({
      creditBalance,
      creditHistory,
      isLoadingWallet,
      fetchAndSetWalletSummary,
    }),
    [creditBalance, creditHistory, isLoadingWallet, fetchAndSetWalletSummary]
  );

  return <WalletContext.Provider value={value} children={children} />;
};
