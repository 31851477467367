import { useI18nContext } from "@hopper-b2b/i18n";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { useState } from "react";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { ApacIcon, ApacIconName } from "@commbank/ui";
import "./ApacWalletApp.styles.scss";
import { MyCardsTab, OffersTab, TravelCreditsTab } from ".";

export enum WalletTab {
  card,
  credit,
  offer,
}

export type ApacWalletAppProps = {
  isSidePanel?: boolean;
};

export const ApacWalletApp = ({ isSidePanel }: ApacWalletAppProps) => {
  const { t } = useI18nContext();
  const { matchesMobile: isMobile } = useDeviceTypes();

  const [currentTab, setCurrentTab] = useState(WalletTab.card);
  const [showRightTab, setShowRightTab] = useState(false);

  return (
    <div
      className={clsx("wallet-page-container", {
        mobile: isMobile,
        isSidePanel,
      })}
    >
      <Box className="header-section">
        <ApacIcon name={ApacIconName.TravelWallet} />
        <div className="description">
          <Typography variant={isMobile ? "h2" : "h1"}>
            {t("wallet.walletTitle")}
          </Typography>
          <Typography variant="body2">{t("wallet.walletSubtitle")}</Typography>
        </div>
      </Box>

      {isMobile ? (
        <Box className="tab-container">
          {showRightTab && (
            <div
              className="arrow-button"
              onClick={() => setShowRightTab(false)}
            >
              <ApacIcon name={ApacIconName.ChevronLeft} />
            </div>
          )}
          {!showRightTab && (
            <div
              className={clsx("tab-item", {
                selected: currentTab === WalletTab.card,
              })}
              onClick={() => setCurrentTab(WalletTab.card)}
            >
              <Typography className="tab-title" variant="body1">
                {t("wallet.myCards")}
              </Typography>
            </div>
          )}
          <div
            className={clsx("tab-item", {
              selected: currentTab === WalletTab.credit,
            })}
            onClick={() => setCurrentTab(WalletTab.credit)}
          >
            <Typography className="tab-title" variant="body1">
              {t("wallet.travelCredits")}
            </Typography>
          </div>
          {showRightTab && (
            <div
              className={clsx("tab-item", {
                selected: currentTab === WalletTab.offer,
              })}
              onClick={() => setCurrentTab(WalletTab.offer)}
            >
              <Typography className="tab-title" variant="body1">
                {t("wallet.offers")}
              </Typography>
            </div>
          )}
          {!showRightTab && (
            <div
              className="arrow-button right"
              onClick={() => setShowRightTab(true)}
            >
              <ApacIcon name={ApacIconName.ChevronLeft} />
            </div>
          )}
        </Box>
      ) : (
        <Box className="tab-container">
          <div
            className={clsx("tab-item", {
              selected: currentTab === WalletTab.card,
            })}
            onClick={() => setCurrentTab(WalletTab.card)}
          >
            <Typography className="tab-title" variant="body1">
              {t("wallet.myCards")}
            </Typography>
          </div>
          <div
            className={clsx("tab-item", {
              selected: currentTab === WalletTab.credit,
            })}
            onClick={() => setCurrentTab(WalletTab.credit)}
          >
            <Typography className="tab-title" variant="body1">
              {t("wallet.travelCredits")}
            </Typography>
          </div>
          <div
            className={clsx("tab-item", {
              selected: currentTab === WalletTab.offer,
            })}
            onClick={() => setCurrentTab(WalletTab.offer)}
          >
            <Typography className="tab-title" variant="body1">
              {t("wallet.offers")}
            </Typography>
          </div>
        </Box>
      )}

      {currentTab === WalletTab.card && (
        <MyCardsTab isSidePanel={isSidePanel} />
      )}
      {currentTab === WalletTab.credit && (
        <TravelCreditsTab isSidePanel={isSidePanel} />
      )}
      {currentTab === WalletTab.offer && (
        <OffersTab isSidePanel={isSidePanel} />
      )}
    </div>
  );
};
