export const GET_PRICE_FREEZE_OFFER = "flightFreeze/GET_PRICE_FREEZE_OFFER";
export type GET_PRICE_FREEZE_OFFER = typeof GET_PRICE_FREEZE_OFFER;

export const SET_PRICE_FREEZE_OFFER = "flightFreeze/SET_PRICE_FREEZE_OFFER";
export type SET_PRICE_FREEZE_OFFER = typeof SET_PRICE_FREEZE_OFFER;

export const SET_PRICE_FREEZE_OFFER_CALL_STATE_FAILED =
  "flightFreeze/SET_PRICE_FREEZE_OFFER_CALL_STATE_FAILED";
export type SET_PRICE_FREEZE_OFFER_CALL_STATE_FAILED =
  typeof SET_PRICE_FREEZE_OFFER_CALL_STATE_FAILED;

export const SET_UP_FLIGHT_FREEZE_PARAMS =
  "flightFreeze/SET_UP_FLIGHT_FREEZE_PARAMS";
export type SET_UP_FLIGHT_FREEZE_PARAMS = typeof SET_UP_FLIGHT_FREEZE_PARAMS;

export const SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_FAILED =
  "flightFreeze/SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_FAILED";
export type SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_FAILED =
  typeof SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_FAILED;

export const SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_SUCCEEDED =
  "flightFreeze/SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_SUCCEEDED";
export type SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_SUCCEEDED =
  typeof SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_SUCCEEDED;

export const SET_PASSENGER_COUNTS = "flightFreeze/SET_PASSENGER_COUNTS";
export type SET_PASSENGER_COUNTS = typeof SET_PASSENGER_COUNTS;

export const FETCH_PRICE_FREEZE = "flightFreeze/FETCH_PRICE_FREEZE";
export type FETCH_PRICE_FREEZE = typeof FETCH_PRICE_FREEZE;

export const GET_PRICE_FREEZE = "flightFreeze/GET_PRICE_FREEZE";
export type GET_PRICE_FREEZE = typeof GET_PRICE_FREEZE;

export const SET_PRICE_FREEZE = "flightFreeze/SET_PRICE_FREEZE";
export type SET_PRICE_FREEZE = typeof SET_PRICE_FREEZE;

export const SET_PRICE_FREEZE_CALL_STATE_FAILED =
  "flightFreeze/SET_PRICE_FREEZE_CALL_STATE_FAILED";
export type SET_PRICE_FREEZE_CALL_STATE_FAILED =
  typeof SET_PRICE_FREEZE_CALL_STATE_FAILED;

export const SET_PRICE_FREEZE_QUOTE_DATA =
  "flightFreeze/SET_PRICE_FREEZE_QUOTE_DATA";
export type SET_PRICE_FREEZE_QUOTE_DATA = typeof SET_PRICE_FREEZE_QUOTE_DATA;

export const REFUND_PRICE_FREEZE = "flightFreeze/REFUND_PRICE_FREEZE";
export type REFUND_PRICE_FREEZE = typeof REFUND_PRICE_FREEZE;

export const SET_REFUND_PRICE_FREEZE = "flightFreeze/SET_REFUND_PRICE_FREEZE";
export type SET_REFUND_PRICE_FREEZE = typeof SET_REFUND_PRICE_FREEZE;

export const DISPLAY_PF_REFUND_MODAL_FLOW =
  "flightFreeze/DISPLAY_PF_REFUND_MODAL_FLOW";
export type DISPLAY_PF_REFUND_MODAL_FLOW = typeof DISPLAY_PF_REFUND_MODAL_FLOW;

export const RESET_PRICE_FREEZE_PURCHASE =
  "flightFreeze/RESET_PRICE_FREEZE_PURCHASE";
export type RESET_PRICE_FREEZE_PURCHASE = typeof RESET_PRICE_FREEZE_PURCHASE;
