import {
  AirCancelFulfillResponse,
  AirCancelFulfillResponseEnum,
} from "@b2bportal/air-cancel-api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { confirmNonCartFlightCancelPath } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

/**
 * @description The second endpoint in the self serve cancel flight flow
 * @param {} fcsId The flight cancellation session ID
 */
const confirmNonCartFlightCancel = async (fcsId) => {
  try {
    const req = {
      flightCancelSessionId: fcsId,
    };
    const res = await axiosInstance.post<AirCancelFulfillResponse>(
      confirmNonCartFlightCancelPath,
      req
    );
    const { data } = res;

    if (
      data.AirCancelFulfillResponse ===
      AirCancelFulfillResponseEnum.AirCancelFulfillFailure
    ) {
      throw data;
    }

    return data;
  } catch (e) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });

    throw e;
  }
};

export default confirmNonCartFlightCancel;
