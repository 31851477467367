import { FiatPrice, MultiTicketType } from "@b2bportal/air-shopping-api";
import { FlightShopCardType } from "../common";
import {
  Double,
  JsObject,
  AirportRegion,
  Currency,
  IdEnum,
  IIdBase,
} from "../common/interfaces";
import { AlgomerchTag, TagType } from "../flights/shopping";

export type OpaqueProperties = string;

export type OpaqueValue = any;

export enum BookingCategory {
  FLIGHT = "FLIGHT",
  HOTEL = "HOTEL",
}

export enum TripCategory {
  ONE_WAY = "one_way",
  ROUND_TRIP = "round_trip",
}
export type Id = IIdFlight | IIdLodgings;

export interface IIdLodgings {
  // Server returns this object as opaque base64 encoded string.
  // The real type of the decoded object is AppLodgingSelection.
  lodgingSelection: OpaqueValue;
  Id: IdEnum.Lodgings;
}

export interface IIdFlight extends IIdBase {
  code: AirportRegion;
  Id: IdEnum.Flight;
}

export type Query =
  | IQueryOrigin
  | IQueryDestination
  | IQueryHotelQuery
  | IQueryLodgingQuery;

interface IQueryBase {
  Query: QueryEnum;
}

export enum QueryEnum {
  Origin = "Origin",
  Destination = "Destination",
  HotelQuery = "HotelQuery",
  LodgingQuery = "LodgingQuery",
}

export interface IQueryOrigin extends IQueryBase {
  destination?: Id;
  query: LocationQuery;
  Query: QueryEnum.Origin;
}

export interface IQueryDestination extends IQueryBase {
  origin?: Id;
  query: LocationQuery;
  Query: QueryEnum.Destination;
}

export interface IQueryHotelQuery extends IQueryBase {
  query: LocationQuery;
  Query: QueryEnum.HotelQuery;
}

export interface IQueryLodgingQuery extends IQueryBase {
  query: LocationQuery;
  sessionToken?: string;
  Query: QueryEnum.LodgingQuery;
}

export type LocationQuery =
  | ILocationQueryLabel
  | ILocationQueryCoordinate
  | ILocationQueryLodgingFeaturedMarkets
  | ILocationQueryEmpty;

interface ILocationQueryBase {
  LocationQuery: LocationQueryEnum;
}

export enum LocationQueryEnum {
  Label = "Label",
  Coordinate = "Coordinate",
  LodgingFeaturedMarkets = "LodgingFeaturedMarkets",
  Empty = "Empty",
}

export interface ILocationQueryLabel extends ILocationQueryBase {
  l: string;
  LocationQuery: LocationQueryEnum.Label;
}

export interface ILocationQueryCoordinate extends ILocationQueryBase {
  lat: Double;
  lon: Double;
  LocationQuery: LocationQueryEnum.Coordinate;
}

export interface ILocationQueryLodgingFeaturedMarkets
  extends ILocationQueryBase {
  LocationQuery: LocationQueryEnum.LodgingFeaturedMarkets;
}

export interface ILocationQueryEmpty extends ILocationQueryBase {
  LocationQuery: LocationQueryEnum.Empty;
}

export interface IResult {
  id: Id;
  label: string;
  trackingProperties?: JsObject;
  opaqueProperties?: OpaqueProperties;
  subLabel?: string;
}

export interface IDetailedServerError {
  errorCode: string;
  title: string;
  message: string;
  severity: Severity;
}

export type Severity = ISeverityFatal | ISeverityWarning;

export interface ISeverityBase {
  Severity: SeverityEnum;
}

export enum SeverityEnum {
  Fatal = "Fatal",
  Warning = "Warning",
}

export interface ISeverityFatal extends ISeverityBase {
  Severity: SeverityEnum.Fatal;
}

export interface ISeverityWarning extends ISeverityBase {
  Severity: SeverityEnum.Warning;
}

export enum ScalingFactor {
  X1 = "1x",
  X1Point5 = "1.5x",
  X2 = "2x",
  X3 = "3x",
  X4 = "4x",
}

export const EMAIL_FOR_SIGN_IN = "emailForSignIn";

export const EMAIL_LINK_URL = "emailLinkUrl";

export enum Locale {
  EN_US = "en_US",
}

export enum DateTimeZone {
  AMERICA_TORONTO = "America/Toronto",
}

export interface IUserPreferences {
  locale: Locale;
  currency: Currency;
  timeZone?: DateTimeZone;
}

export interface IPassengerCounts {
  adultsCount: number;
  childrenCount: number;
  infantsInSeatCount: number;
  infantsOnLapCount: number;
}

export enum FlightRatingsEnum {
  basic,
  standard,
  enhanced,
  premium,
  luxury,
}

export type ITripTerminus = IResult & {
  id: IIdFlight;
};

export enum FlightSortOption {
  arrivalTime = "arrivalTime",
  departureTime = "departureTime",
  duration = "duration",
  fareScore = "fareScore",
  price = "price",
  stops = "stops",
}

export interface ISortOptions {
  label: string;
  value: FlightSortOption;
}

export interface IFlightSearchCriteria {
  tripCategory?: TripCategory | null;
  origin?: ITripTerminus | null;
  destination?: ITripTerminus | null;
  passengers?: IPassengerCounts | null;
  departureDate?: Date | null;
  returnDate?: Date | null;
}

type ITags = {
  value: AlgomerchTag;
  type: TagType;
}[];

export interface IFlightCardProps {
  duration: string;
  currentPriceText: string;
  originCode: string;
  destinationCode: string;
  departureTime: string;
  arrivalTime: string;
  airports: string[];
  brandName: string;
  primaryCarrier: string;
  airlineName: string;
  additionalInfo?: {
    isExpanded: boolean;
    layoverDuration?: string;
    layoverText?: string;
    plusDays?: string;
  };
  onClickTag?: (label: string) => void;
  tags: ITags;
  tagsLimit?: number;
  cardType?: FlightShopCardType;
  rewardText?: string;
  fiat?: FiatPrice;
  fareMultiTicketType?: MultiTicketType;
  isRoundTrip?: boolean;
}
