import { Person } from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { PersonId, UserInfo } from "@hopper-b2b/types";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ApacActionLink } from "../../ApacActionLink";
import { ApacDesktopPopupModal } from "../../ApacDesktopPopupModal";
import { ApacIcon, ApacIconName } from "../../ApacIcon";
import { ApacMobileFullScreenModal } from "../../ApacMobileFullScreenModal";
import { TravelerTypeTitles } from "../PassengerInfo";
import { TravelerInfoForm } from "../TravelerInfoForm";
import "./TravelerEditModal.styles.scss";

export interface TravelerEditModalProps {
  titles: TravelerTypeTitles;
  isMobile?: boolean;
  userInfo?: UserInfo;
  hideFrequentFlyerSection?: boolean;
  hideNationalityField?: boolean;
  hideAdditionalInfoSection?: boolean;
  hideGenderField?: boolean;
  showDriverLicenseSection?: boolean; // defaulting to false to not show section
  fullScreenWithBanner?: boolean;
  currentPassenger?: Person;
  openPassengerFormModal: boolean;
  buttonClassName?: string;
  passportOnly?: boolean;
  showPassport?: boolean;
  handleGoBack?: () => void;
  handleCloseForm: (id?: string) => void;
  handleUpdatePassenger: (passenger: Person) => void;
  handleDeletePassenger: (travelerId: PersonId) => void;
}

export const TravelerEditModal = (props: TravelerEditModalProps) => {
  const {
    titles,
    isMobile,
    userInfo,
    hideFrequentFlyerSection,
    hideNationalityField,
    hideAdditionalInfoSection,
    hideGenderField,
    showDriverLicenseSection = false, // defaulting to false to not show section
    buttonClassName,
    fullScreenWithBanner,
    passportOnly,
    currentPassenger,
    openPassengerFormModal,
    handleGoBack,
    handleCloseForm,
    handleUpdatePassenger,
    handleDeletePassenger,
    showPassport,
    ...rest
  } = props;
  const { t } = useI18nContext();

  const renderModalContent = () => {
    const isEdit = !!currentPassenger?.id;

    return (
      <div
        className={clsx("traveler-edit-modal-container", { mobile: isMobile })}
      >
        {!passportOnly ? (
          <Box className="modal-header">
            <div className="left">
              <ApacIcon name={ApacIconName.CBAPerson} />
              <Typography variant="h1" className="traveler-info-form-title">
                {t(
                  isEdit
                    ? "passengerInformation.editPassenger"
                    : "passengerInformation.addNewPassenger"
                )}
              </Typography>
            </div>
            {isEdit && (
              <ApacActionLink
                className="traveler-remove-button"
                message={t("commBank.hotelBook.deleteTravelerButtonLabel")}
                onClick={() => handleDeletePassenger(currentPassenger?.id)}
              />
            )}
          </Box>
        ) : (
          <div className="passport-modal-header">
            <ApacIcon name={ApacIconName.CBAPerson} />
            <div className="right">
              <Typography variant="h1">
                {t("passportInfo.validationTitle")}
              </Typography>
              <Typography variant="body1">
                {t("passportInfo.subtitle")}
              </Typography>
            </div>
          </div>
        )}
        <TravelerInfoForm
          className="traveler-edit-form"
          titles={titles}
          showPassport={showPassport}
          hideFrequentFlyerSection={hideFrequentFlyerSection}
          hideNationalityField={hideNationalityField}
          hideAdditionalInfoSection={hideAdditionalInfoSection}
          hideGenderField={hideGenderField}
          showDriverLicenseSection={showDriverLicenseSection}
          traveler={currentPassenger}
          handleUpdatePassenger={handleUpdatePassenger}
          handleRemovePassenger={(id) => handleDeletePassenger(id)}
          isMobile={isMobile}
          buttonClassName={buttonClassName}
          handleClose={handleCloseForm}
          passportOnly={passportOnly}
          {...rest}
        />
      </div>
    );
  };

  return isMobile ? (
    <ApacMobileFullScreenModal
      open={openPassengerFormModal}
      onClose={() => handleCloseForm(currentPassenger?.id)}
    >
      {renderModalContent()}
    </ApacMobileFullScreenModal>
  ) : (
    <ApacDesktopPopupModal
      className="desktop-flight-shop-modal"
      contentClassName="modal-content"
      open={openPassengerFormModal}
      onClose={() => handleCloseForm(currentPassenger?.id)}
    >
      {renderModalContent()}
    </ApacDesktopPopupModal>
  );
};
