import { NonCfarEnum } from "@b2bportal/air-cancel-api";

export const eventsToListen = {
  HOST_THEME_CHANGED: "HOST_THEME_CHANGED",
  HOST_LOCALE_CHANGED: "HOST_LOCALE_CHANGED",
  HOST_ROUTE_CHANGED: "ROUTE_CHANGED",
};

export interface RouteChangeEventData {
  referrer: string;
  destination: string;
}

export const eventsToDispatch = {
  ROUTE_CHANGED: "ROUTE_CHANGED",
};

/**
 * @description Maps self serve NonCfars to more intuitive strings for event
 * tracking in amplitude.
 * @param {NonCfarEnum} nonCfar
 * @return {string}
 */
export const getTrackingNonCfar = (nonCfar: NonCfarEnum): string => {
  switch (nonCfar) {
    case NonCfarEnum.AirlineRefund:
      return "Fully Refundable";
    case NonCfarEnum.ContactAirline:
      return "TRVK";
    case NonCfarEnum.ContactCustomerService:
      return "Unsupported Eligibility";
    case NonCfarEnum.Ftc:
      return "FTC";
    case NonCfarEnum.FtcWithPenalty:
      return "FTC with Penalty";
    case NonCfarEnum.PartialRefund:
      return "Partially Refundable";
    case NonCfarEnum.NonRefundable:
      return "Non Refundable";
    case NonCfarEnum.RefundableComplex:
      return "Refundable Complex";
    case NonCfarEnum.TicketedVoid:
      return "Void-Ticketed";
    case NonCfarEnum.TicketlessVoid:
      return "Void-Ticketless";
    default:
      return nonCfar;
  }
};

const dispatchEvent = (eventName: string, data: any) =>
  window.dispatchEvent(new CustomEvent(eventName, { detail: data }));

export default dispatchEvent;
