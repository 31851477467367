import clsx from "clsx";
import bunny from "../../assets/gifs/hopper/running_bunny.gif";
import bunny_white from "../../assets/gifs/hopper/running_bunny_white.gif";

export interface ILoadingBunnyProps {
  classes?: string[];
  light?: boolean;
}

const LoadingBunny = ({ classes, light }: ILoadingBunnyProps) => (
  <img
    className={clsx(classes)}
    src={light ? bunny_white : bunny}
    alt="loading..."
  />
);

export default LoadingBunny;
