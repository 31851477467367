export const FETCH_DISRUPTION_OFFERS =
  "flightDisruption/FETCH_DISRUPTION_OFFERS";
export type FETCH_DISRUPTION_OFFERS = typeof FETCH_DISRUPTION_OFFERS;

export const SET_DISRUPTION_OFFERS = "flightDisruption/SET_DISRUPTION_OFFERS";
export type SET_DISRUPTION_OFFERS = typeof SET_DISRUPTION_OFFERS;

export const SET_DISRUPTION_OFFERS_FAILED =
  "flightDisruption/SET_DISRUPTION_OFFERS_FAILED";
export type SET_DISRUPTION_OFFERS_FAILED = typeof SET_DISRUPTION_OFFERS_FAILED;

export const SET_SELECTED_SCHEDULE_CHANGE_OFFER =
  "flightDisruption/SET_SELECTED_SCHEDULE_CHANGE_OFFER";
export type SET_SELECTED_SCHEDULE_CHANGE_OFFER =
  typeof SET_SELECTED_SCHEDULE_CHANGE_OFFER;

export const SET_SELECTED_MISSED_CONNECTION_OFFER =
  "flightDisruption/SET_SELECTED_MISSED_CONNECTION_OFFER";
export type SET_SELECTED_MISSED_CONNECTION_OFFER =
  typeof SET_SELECTED_MISSED_CONNECTION_OFFER;

export const SET_SELECTED_AIR_DISRUPTION_OFFER =
  "flightDisruption/SET_SELECTED_AIR_DISRUPTION_OFFER";
export type SET_SELECTED_AIR_DISRUPTION_OFFER =
  typeof SET_SELECTED_AIR_DISRUPTION_OFFER;
