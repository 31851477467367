import { useCallback, useMemo, useState } from "react";
import { Typography } from "@material-ui/core";
import {
  MobilePopoverCard,
  ActionLink,
  AccordionCollection,
  IconComponent,
  IconName,
  MobileFloatingSection,
} from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { getEnvVariables } from "@hopper-b2b/utilities";

import {
  StopsOptionSelection,
  MaxPriceFilterSelection,
  AirlineFilterSelection,
  DepartureArrivalSelectionDropdown,
  AirportFilterSelection,
} from "../index";
import FlightNumberFilterSelection from "../FlightNumberFilterSelection/";
import { SortOptionSelection } from "../../../../../../../shop/components/FlightShopHeader/components/SortOptionSelection";
import "./styles.scss";
import clsx from "clsx";
import { IShopFilterSelector } from "../../../../../../../shop/reducer";
import { ClientName } from "@hopper-b2b/types";

export interface IMobileFlightShopSearchFilterProps {
  hideAirportFilter?: boolean;
  flightShopFilters: IShopFilterSelector;
  filtersOpen?: boolean;
  isReturn?: boolean;
  setFiltersOpen?: (filtersOpen: boolean) => void;
  setRerunPrediction?: () => void;
}
export const MobileFlightShopSearchFilter = (
  props: IMobileFlightShopSearchFilterProps
) => {
  const { setFiltersOpen, filtersOpen, isReturn, setRerunPrediction } = props;

  const { t } = useI18nContext();

  const [openSearchFilter, setOpenSearchFilter] = useState<boolean>(false);

  const [nonstopFilterSelected, setNonstopFilterSelected] =
    useState<boolean>(false);

  const handleCloseFilter = useCallback(() => {
    if (nonstopFilterSelected && setRerunPrediction) {
      setRerunPrediction();
    }
    if (setFiltersOpen) {
      setFiltersOpen(false);
    } else {
      setOpenSearchFilter(false);
    }
  }, [nonstopFilterSelected, setFiltersOpen, setRerunPrediction]);

  const isUberClient = getEnvVariables("clientName") === ClientName.UBER;

  const { hideAirportFilter, flightShopFilters } = props;
  const {
    priceMax,
    priceMin,
    airlineOptions,
    airportOptions,
    flightNumbersByAirline,
  } = flightShopFilters;
  const accordionContents = [
    // Sort by
    {
      title: (
        <div className="header-container">{t("searchFilter.sortHeader")}</div>
      ),
      body: (
        <div className="mobile-filter-option-selection">
          <SortOptionSelection showDropdownContentOnly={true} />
        </div>
      ),
    },
    // StopsOption
    {
      title: (
        <div className="header-container">{t("searchFilter.stopHeader")}</div>
      ),
      body: (
        <div className="mobile-filter-option-selection">
          <StopsOptionSelection
            showDropdownContentOnly={true}
            setNonstopFilterSelected={setNonstopFilterSelected}
          />
        </div>
      ),
    },
    // MaxPrice
    {
      title: <div className="header-container">{t("searchFilter.price")}</div>,
      body: (
        <div className="mobile-filter-option-selection">
          <MaxPriceFilterSelection
            showDropdownContentOnly={true}
            maximumPrice={priceMax.value}
            minimumPrice={priceMin.value}
          />
        </div>
      ),
    },
    // Airline
    {
      title: (
        <div className="header-container">{t("searchFilter.airline")}</div>
      ),
      body: (
        <div className="mobile-filter-option-selection">
          <AirlineFilterSelection
            showDropdownContentOnly={true}
            allAirlines={airlineOptions}
          />
        </div>
      ),
    },
    // Departure
    {
      title: (
        <div className="header-container">
          {t("searchFilter.outboundFlight")}
        </div>
      ),
      body: (
        <div className="mobile-filter-option-selection">
          <DepartureArrivalSelectionDropdown
            showDropdownContentOnly={true}
            showDepartureSelectionOnly={true}
          />
        </div>
      ),
    },
    // Arrival
    ...(isReturn
      ? [
          {
            title: (
              <div className="header-container">
                {t("searchFilter.returnFlight")}
              </div>
            ),
            body: (
              <div className="mobile-filter-option-selection">
                <DepartureArrivalSelectionDropdown
                  showDropdownContentOnly={true}
                  showArrivalSelectionOnly={true}
                />
              </div>
            ),
          },
        ]
      : []),
    // Airport
    ...(hideAirportFilter
      ? [
          {
            title: (
              <div className="header-container">
                {t("searchFilter.airport")}
              </div>
            ),
            body: (
              <div className="mobile-filter-option-selection">
                <AirportFilterSelection
                  showDropdownContentOnly={true}
                  allAirports={airportOptions}
                />
              </div>
            ),
          },
        ]
      : []),
    // Flight Number
    {
      title: (
        <div className="header-container">{t("searchFilter.flightNumber")}</div>
      ),
      body: (
        <div className="mobile-filter-option-selection">
          <FlightNumberFilterSelection
            showDropdownContentOnly={true}
            allAirlines={airlineOptions}
            flightNumbersByAirline={flightNumbersByAirline}
          />
        </div>
      ),
    },
  ];

  const renderHeader = useMemo(() => {
    return isUberClient
      ? {
          headerElement: (
            <Typography className="custom-mobile-search-header">
              {t("searchFilter.sortAndFilter")}
              <ActionLink
                className="filter-close-button"
                content={
                  <IconComponent
                    ariaLabel="Close button icon"
                    className={clsx("close-button-icon")}
                    name={IconName.Close}
                  />
                }
                label="Close"
                onClick={handleCloseFilter}
              />
            </Typography>
          ),
        }
      : {
          topRightButton: (
            <ActionLink
              className="filter-close-button"
              content={
                <IconComponent
                  ariaLabel="Close button icon"
                  className={clsx("close-button-icon")}
                  name={IconName.Close}
                />
              }
              label="Close"
              onClick={handleCloseFilter}
            />
          ),
        };
  }, [handleCloseFilter, isUberClient, t]);

  return (
    <div className="mobile-flight-shop-search-filter-root">
      <div className="mobile-flight-shop-search-filter-container">
        <MobilePopoverCard
          open={filtersOpen ? filtersOpen : openSearchFilter}
          onClose={handleCloseFilter}
          fullScreen={true}
          className={"mobile-flight-shop-search-filter full-screen"}
          contentClassName={"mobile-flight-shop-search-filter-wrapper"}
          bottomButton={
            <MobileFloatingSection
              className="mobile-flight-shop-filter-button"
              primaryButton={{
                children: t("searchFilter.apply"),
                onClick: () => {
                  handleCloseFilter();
                },
              }}
            />
          }
          {...renderHeader}
        >
          <div className="mobile-flight-shop-search-filter-content-container">
            <AccordionCollection
              className="flight-shop-search-filter-accordions-container"
              accordionContents={accordionContents}
            />
          </div>
        </MobilePopoverCard>
      </div>
    </div>
  );
};
