import { Grid } from "@material-ui/core";
import { IShopFilterSelector } from "../../../../../../../shop/reducer";
import FlightNumberFilterSelection from "../FlightNumberFilterSelection/";
import {
  AirlineFilterSelection,
  AirportFilterSelection,
  DepartureArrivalSelectionDropdown,
  MaxPriceFilterSelection,
  StopsOptionSelection,
} from "../index";

export interface IDesktopFlightShopSearchFilterProps {
  hideAirportFilter?: boolean;
  flightShopFilters: IShopFilterSelector;
}

export const DesktopFlightShopSearchFilter = ({
  hideAirportFilter,
  flightShopFilters,
}: IDesktopFlightShopSearchFilterProps) => {
  const {
    priceMax,
    priceMin,
    airlineOptions,
    airportOptions,
    flightNumbersByAirline,
  } = flightShopFilters;
  return (
    <Grid container spacing={2}>
      <Grid item>
        <StopsOptionSelection />
      </Grid>
      <Grid item>
        <MaxPriceFilterSelection
          maximumPrice={priceMax.value}
          minimumPrice={priceMin.value}
        />
      </Grid>
      <Grid item>
        <AirlineFilterSelection allAirlines={airlineOptions} />
      </Grid>
      <Grid item>
        <DepartureArrivalSelectionDropdown />
      </Grid>
      {!hideAirportFilter && (
        <Grid item>
          <AirportFilterSelection allAirports={airportOptions} />
        </Grid>
      )}
      <Grid item>
        <FlightNumberFilterSelection
          allAirlines={airlineOptions}
          flightNumbersByAirline={flightNumbersByAirline}
        />
      </Grid>
    </Grid>
  );
};
