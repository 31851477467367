export const BASE_PATH_HOME = "/";
export const PATH_HOME = "/trips/";
export const PATH_FLIGHTS = BASE_PATH_HOME + "flights/";
export const PATH_FLIGHTS_SEARCH = PATH_FLIGHTS + "search/";
export const PATH_FLIGHTS_SHOP = PATH_FLIGHTS + "shop/";
export const PATH_HOTELS = BASE_PATH_HOME + "hotels/";
export const PATH_CARS = BASE_PATH_HOME + "cars/";
export const PATH_TERMS = `${BASE_PATH_HOME}terms/`;
export const PATH_TERMS_CFAR = `${BASE_PATH_HOME}terms/cancel-for-any-reason/`;
export const PATH_PRICE_DROP_PROTECTION = `${PATH_TERMS}price-drop-protection/`;
export const PATH_FREEZE = PATH_FLIGHTS + "freeze/";
export const PATH_PRICE_FREEZE_OVERVIEW = `${PATH_FREEZE}overview/`;

export const PATH_EXCHANGE_FLIGHTS = "/flights/exchange";
// TODO: Add CONTACT_SUPPORT_URL
export const CONTACT_SUPPORT_URL = "/CONTACT_SUPPORT_URL";

// Disruption paths
export const BASE_PATH_DISRUPTION = PATH_HOME + "disruption/";
export const PATH_DISRUPTION_EXERCISE = BASE_PATH_DISRUPTION + "exercise/";
export const PATH_DISRUPTION_EXERCISE_REFUND =
  PATH_DISRUPTION_EXERCISE + "refund/";
export const PATH_DISRUPTION_EXERCISE_SEARCH =
  PATH_DISRUPTION_EXERCISE + "search/";
export const PATH_DISRUPTION_EXERCISE_CONTACT =
  PATH_DISRUPTION_EXERCISE + "contact/";
export const PATH_DISRUPTION_EXERCISE_REBOOK_REVIEW =
  PATH_DISRUPTION_EXERCISE + "rebook/review/";
export const PATH_DISRUPTION_EXERCISE_REBOOK_CONFIRMATION =
  PATH_DISRUPTION_EXERCISE + "rebook/confirmation/";
