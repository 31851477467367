import { useEffect, useRef } from "react";

// source: https://github.com/kripod/react-hooks/blob/master/packages/state-hooks/src/usePrevious.ts
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
