import { FlightsFareSlice } from "@b2bportal/air-shopping-api";
import {
  AirRestrictionStatus,
  FareDetails,
  FareId,
  TripDetails,
} from "@hopper-b2b/types";
import {
  getFilteredFareDetails,
  getSortedFareDetails,
} from "@hopper-b2b/utilities";
import { TFunction } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { FareCardDetails } from "./components";
import "./styles.scss";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ApacMobileContinueButton } from "@commbank/ui";

/**
 * Fare Details Card
 * @function FareDetailsCard
 *
 */

export interface IRestrictionProps {
  symbol: AirRestrictionStatus;
  description: string;
  key?: string;
  headline?: string;
}

export interface IFareNotice {
  id: string;
  message: string;
  tooltipCopy: string;
}

export interface IFareDetailsCardProps {
  className?: string;
  onClick?: (fareDetails: FareDetails) => void;
  onAlgomerchClick?: (label: string) => void;
  fareDetails: FareDetails[];
  selectedFare?: FareId;
  isOutgoing: boolean;
  isMobile?: boolean;
  fareNotice?: IFareNotice[];
  getEmptyRestrictionsText: (
    fareRating: number | undefined,
    translate: TFunction
  ) => string;
  rewardsKey?: string;
  selectedFareClassFilters: Array<number>;
  selectedOutgoingFareSlice?: FlightsFareSlice;
  getFlightShopFareSlice: (fareId: string) => FlightsFareSlice;
  tripDetails?: TripDetails;
}

export const FareDetailsCard = ({
  onClick,
  selectedFare,
  fareDetails, // the length of this is the amt of fares we have
  isOutgoing,
  onAlgomerchClick,
  isMobile = true,
  fareNotice,
  selectedFareClassFilters,
  selectedOutgoingFareSlice,
  getEmptyRestrictionsText = () => "",
  rewardsKey = "fake-account-reference-ID-1",
  getFlightShopFareSlice,
  tripDetails,
}: IFareDetailsCardProps) => {
  const { t } = useI18nContext();
  const [filteredFares, setFilterFares] = useState<FareDetails[]>(fareDetails);
  const [selectedFareId, setSelectedFareId] = useState<string | null>(
    selectedFare
  );

  const onFareSelected = (fareId: string) => {
    setSelectedFareId(fareId);
  };
  const getSelectedFareId = useCallback(() => {
    const fareExists = filteredFares?.some((f) => f.id === selectedFare);
    const firstFareId = filteredFares[0]?.id;
    if (!firstFareId) return undefined;
    if (isOutgoing) {
      if (fareExists) {
        setSelectedFareId(selectedFare);
        return selectedFare;
      } else {
        setSelectedFareId(firstFareId);
        return firstFareId;
      }
    } else {
      // check if outgoing is refundable, try to find matching return fare
      if (selectedOutgoingFareSlice?.fareBrandName?.includes("Refundable")) {
        const matchedFare = filteredFares.find(
          (fare) =>
            fare.slices[1]?.fareShelf?.brandName ===
            selectedOutgoingFareSlice?.fareBrandName
        );
        if (matchedFare) {
          setSelectedFareId(matchedFare.id);
          return matchedFare.id;
        } else {
          setSelectedFareId(firstFareId);
          return firstFareId;
        }
      } else {
        if (fareExists) {
          setSelectedFareId(selectedFare);
          return selectedFare;
        } else {
          setSelectedFareId(firstFareId);
          return firstFareId;
        }
      }
    }
  }, [
    filteredFares,
    isOutgoing,
    selectedFare,
    selectedOutgoingFareSlice?.fareBrandName,
  ]);

  useEffect(() => {
    if (selectedFare && fareDetails) {
      const onlyFilteredFares = getFilteredFareDetails({
        fareDetails,
        selectedFareClassFilters,
        isOutgoing,
        // Don't filter fares based on fare clicked for mobile, only desktop
        selectedFareId: isMobile ? undefined : getSelectedFareId(),
      });
      const filteredAndSortedFares = getSortedFareDetails(onlyFilteredFares);
      setFilterFares(filteredAndSortedFares);
    }
    // ! Don't add getSelectedFareId to dependency list below
    // ! as this useEffect sets filteredFares and getSelectedFareId depends on it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fareDetails,
    isOutgoing,
    selectedFare,
    selectedFareClassFilters,
    isMobile,
  ]);
  return (
    <div className="fare-details-card">
      {filteredFares?.map((fare, index) => (
        <FareCardDetails
          key={fare.id}
          fare={fare}
          index={index}
          anchorEl={null}
          isLast={index === filteredFares.length - 1}
          isSelected={fare.id === selectedFareId}
          isOutgoing={isOutgoing}
          fareDetails={fareDetails}
          fareNotice={fareNotice}
          onClick={isMobile ? () => onFareSelected(fare.id) : onClick}
          onAlgomerchClick={onAlgomerchClick}
          getEmptyRestrictionsText={getEmptyRestrictionsText}
          getFlightShopFareSlice={getFlightShopFareSlice}
          rewardsKey={rewardsKey}
          tripDetails={tripDetails}
          isMobile={isMobile}
        />
      ))}
      {isMobile && (
        <ApacMobileContinueButton
          title={selectedFareId ? t("continue") : t("selectAnOption")}
          disabled={!selectedFareId}
          handleContinue={() =>
            onClick(fareDetails.find((f) => f.id === selectedFareId))
          }
        />
      )}
    </div>
  );
};

export default FareDetailsCard;
