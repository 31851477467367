import { ApacActionButton, ApacIcon, ApacIconName } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Typography } from "@material-ui/core";
import "./CombinationModalContent.scss";

interface CombinationModalContentProps {
  onClose: () => void;
}

export const CombinationModalContent = ({
  onClose,
}: CombinationModalContentProps): JSX.Element => {
  const { t } = useI18nContext();
  return (
    <Box className="combination-flight-content">
      <ApacIcon name={ApacIconName.CBATransfer} />
      <Typography variant="h2" className="combination-flight- modal-title">
        {t("combinationFlightWarning")}
      </Typography>
      <Typography variant="body1" className="combination-flight-modal-text">
        {t("combinationFlightTooltip")}
      </Typography>
      <ApacActionButton
        className="rounded"
        message={t("close.button")}
        onClick={onClose}
      />
    </Box>
  );
};
