import clsx from "clsx";
import { ApacIcon, ApacIconName } from "../ApacIcon";
import "./CBASpinner.styles.scss";

export const CBASpinner = ({
  className,
  variant,
}: {
  className?: string;
  variant?: "small" | "normal";
}) => {
  return (
    <div
      className={clsx("cba-spinner", className, { small: variant === "small" })}
    >
      <ApacIcon name={ApacIconName.Spinner} />
    </div>
  );
};
