import Airplane from "./client/airplane.svg";
import AirplaneArrive from "./client/airplane-arrive.svg";
import AirplaneDepart from "./client/airplane-depart.svg";
import AirplaneFintech from "./client/airplane-fintech.svg";
import Avatar from "./client/avatar.svg";
import Bed from "./client/bed.svg";
import Bell from "./client/bell.svg";
import BunnyWithSuitcase from "./client/bunny-suitcase.svg";
import BlackLock from "./client/black-lock.svg";
import Calendar from "./client/calendar.svg";
import Card from "./client/card.svg";
import Cfar from "./client/cfar.svg";
import Check from "./client/check.svg";
import CheckmarkFilled from "./client/checkmark-filled.svg";
import CheckmarkSimple from "./client/checkmark-simple.svg";
import Chevron from "./client/chevron.svg";
import ChevronLeft from "./client/chevron-left.svg";
import Clock from "./client/clock.svg";
import Close from "./client/close.svg";
import ErrorIcon from "./client/error.svg";
import Filter from "./client/filter.svg";
import Flights from "./client/flights.svg";
import FlightDelay from "./client/flight-delay.svg";
import Hotels from "./client/hotels.svg";
import GrayGuest from "./client/gray-guest.svg";
import GrayShieldX from "./client/gray-sheild-x.svg";
import Info from "./client/info.svg";
import InfoQuestion from "./client/info-question.svg";
import InfoWhite from "./client/info-white.svg";
import LocationMarker from "./client/location-marker.svg";
import Lock from "./client/lock.svg";
import MissedConnection from "./client/missed-connection.svg";
import OneWay from "./client/oneway.svg";
import Passenger from "./client/passenger.svg";
import PriceFreeze from "./client/price-freeze.svg";
import RoundTrip from "./client/roundtrip.svg";
import Shield from "./client/shield.svg";
import Star from "./client/star.svg";
import CarrotCoin from "./client/carrot-coin.svg";
import Wallet from "./client/wallet.svg";
import GreenShieldCheck from "./client/green-shield-check.svg";
import Copy from "./client/copy.svg";
import { IIconSet } from "@hopper-b2b/utilities";

export const lightModeIcons: IIconSet = {
  airplane: Airplane,
  airplaneArrive: AirplaneArrive,
  airplaneDepart: AirplaneDepart,
  airplaneFintech: AirplaneFintech,
  avatar: Avatar,
  bunnyWithSuitcase: BunnyWithSuitcase,
  bed: Bed,
  bell: Bell,
  calendar: Calendar,
  card: Card,
  clock: Clock,
  error: ErrorIcon,
  cfar: Cfar,
  check: Check,
  checkmarkFilled: CheckmarkFilled,
  checkmarkSimple: CheckmarkSimple,
  chevron: Chevron,
  chevronLeft: ChevronLeft,
  close: Close,
  filter: Filter,
  flightDelay: FlightDelay,
  flights: Flights,
  grayGuest: GrayGuest,
  grayShieldX: GrayShieldX,
  hotels: Hotels,
  info: Info,
  infoQuestion: InfoQuestion,
  infoWhite: InfoWhite,
  locationMarker: LocationMarker,
  lock: Lock,
  blackLock: BlackLock,
  oneway: OneWay,
  roundtrip: RoundTrip,
  passenger: Passenger,
  priceFreeze: PriceFreeze,
  missedConnection: MissedConnection,
  shield: Shield,
  star: Star,
  carrotCoin: CarrotCoin,
  wallet: Wallet,
  greenShieldCheck: GreenShieldCheck,
  copy: Copy,
};
