import { ParentContextWithCardPayment, PaymentErrorModalTypes } from "../types";
import { listPaymentMethods } from "@hopper-b2b/api";
import { CreditCardListSuccess } from "@b2bportal/card-api";
import { CardPaymentSelectors } from "../";

export const listPaymentMethodsService = (
  context: ParentContextWithCardPayment,
  _event: unknown
) =>
  new Promise((resolve, reject) => {
    const req = CardPaymentSelectors.getPaymentListRequest({
      context,
    });

    listPaymentMethods(req)
      .then((paymentMethods) => {
        resolve((paymentMethods as CreditCardListSuccess).value);
      })
      .catch((e) => {
        reject({
          type: PaymentErrorModalTypes.ListPaymentMethods,
          data: e,
        });
      });
  });
