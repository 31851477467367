import { CBASpinner } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { FlightShopType, HalfSheetHashType } from "@hopper-b2b/types";
import { useEnableWallet, useSessionContext } from "@hopper-b2b/utilities";
import { SignupBanner } from "@hopper-b2b/wallet";
import { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getDepartureDate } from "../../../../../../../../search/reducer/selectors";
import { fetchSelectedTripPriceFreezeOffer } from "../../../../../../../actions/actions";
import { DesktopInfiniteFlightsProps } from "../DesktopInfiniteFlights";
import { FlightComponent } from "../FlightComponent";
import { flightShopTypeSelector } from "./../../../../../../../../../../src/modules/shop/reducer";
import "./styles.scss";

type MobileInfiniteFlightsProps = DesktopInfiniteFlightsProps;

export const MobileInfiniteFlights = (props: MobileInfiniteFlightsProps) => {
  const {
    disablePriceFreeze,
    faresToShow,
    flights,
    expandedFlight,
    setFetchMoreData,
    setExpandedFlight,
    hasMore,
  } = props;

  const { t } = useI18nContext();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isLoggedIn } = useSessionContext();

  const hasVouchersEnabled = useEnableWallet();

  const [openFlightDetailModal, setOpenFlightDetailModal] = useState(false);
  const [clickedFareId, setClickedFareId] = useState(expandedFlight);

  const departureDate = useSelector(getDepartureDate);
  const flightShopType = useSelector(flightShopTypeSelector);

  useEffect(() => {
    if (location.hash === `#${HalfSheetHashType.FLIGHT_DETAILS}`) {
      setOpenFlightDetailModal(true);
    } else {
      setOpenFlightDetailModal(false);
    }
  }, [location]);

  useEffect(() => {
    const fareId = clickedFareId || expandedFlight;
    if (
      props.expandedFareDetails?.id &&
      fareId &&
      !disablePriceFreeze &&
      flightShopType !== FlightShopType.CHFAR_EXERCISE
    ) {
      dispatch(
        fetchSelectedTripPriceFreezeOffer(
          fareId, // fareId
          props.expandedFareDetails?.id, // tripId
          departureDate
        )
      );
    }
  }, [
    clickedFareId,
    departureDate,
    disablePriceFreeze,
    dispatch,
    expandedFlight,
    flightShopType,
    props.expandedFareDetails?.id,
  ]);

  const handleNext = useCallback(() => {
    if (setFetchMoreData) {
      setFetchMoreData();
    }
  }, [setFetchMoreData]);

  useEffect(() => {
    setClickedFareId(expandedFlight);
  }, [expandedFlight]);

  return (
    <InfiniteScroll
      dataLength={faresToShow.length}
      next={handleNext}
      hasMore={hasMore}
      loader={
        <div className="loading-flights">
          <CBASpinner variant="small" />
        </div>
      }
      scrollableTarget="flight-scroll-box"
    >
      <ul className="infinite-flight-list">
        {faresToShow.map(({ fare, flight }, index) => {
          const flightSliceId = flight.slice;
          const flightSlice = flights?.slices[flightSliceId];
          if (!flightSlice) {
            return null;
          }

          const fareId = fare.example?.fare || fare.id;
          return (
            <li key={`flight-${fareId}`}>
              {index === 3 && hasVouchersEnabled && !isLoggedIn && (
                <SignupBanner message={t?.("walletBannerSignIn")} />
              )}
              <FlightComponent
                className={`flight-${fareId}`}
                {...props}
                slice={flightSlice}
                index={index}
                flight={flight}
                selectedFare={fare}
                openFlightDetailModal={openFlightDetailModal}
                setOpenFlightDetailModal={setOpenFlightDetailModal}
                clickedFareId={clickedFareId}
                setClickedFareId={setClickedFareId}
                setExpandedFlight={setExpandedFlight}
              />
            </li>
          );
        })}
      </ul>
    </InfiniteScroll>
  );
};
