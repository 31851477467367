import { ApacIcon, ApacIconName } from "@commbank/ui";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import { useEffect } from "react";
import "./PriceWatchCard.styles.scss";

export interface IPriceWatchCardProps {
  onWatchClick: () => void;
  isWatching?: boolean;
  onPriceDropClick?: () => void;
  className?: string;
}

export const PriceWatchCard = (
  props: IPriceWatchCardProps
): React.ReactElement => {
  const { t } = useI18nContext();
  const { matchesMobile: isMobile } = useDeviceTypes();
  const { className, onPriceDropClick, onWatchClick, isWatching } = props;

  useEffect(() => {
    const priceDropActionText = document?.getElementById(
      "price-drop-text-clickable"
    );
    if (priceDropActionText) {
      priceDropActionText.addEventListener("click", onPriceDropClick);
    }

    return () => {
      priceDropActionText &&
        priceDropActionText.removeEventListener("click", onPriceDropClick);
    };
  }, [onPriceDropClick]);

  return (
    <Box
      className={clsx(className, "apac-price-watch-card", {
        mobile: isMobile,
        desktop: !isMobile,
      })}
    >
      {!!onPriceDropClick && (
        <div className="price-drop-container">
          <div className="drop-content-wrapper">
            <ApacIcon name={ApacIconName.Protection} />
            <I18nMarkup tKey="pricePrediction.priceDropIncluded" />
          </div>
        </div>
      )}

      <div className="price-watch-container">
        <Typography variant="body2">
          {t(
            isWatching
              ? "pricePrediction.isWatching"
              : "pricePrediction.stillNotReady"
          )}{" "}
          <span className="action-text" onClick={onWatchClick}>
            {isWatching
              ? t("priceWatch.watching")
              : t("priceWatch.titles.buttonCopy")}
          </span>
        </Typography>
      </div>
    </Box>
  );
};
