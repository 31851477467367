import { GenericDropdown } from "@hopper-b2b/ui";
import { IconComponent, IconName } from "@hopper-b2b/ui";
import clsx from "clsx";
import {
  SortOption,
  type ILodgingFilterState,
  type PriceRange as PriceRangeType,
} from "../../reducer";
import { StarRating } from "../StarRating";
import { UserRating } from "../UserRating";
import { PriceRange } from "../PriceRange";
import styles from "./QuickFilters.module.scss";
import { Typography, Badge, Box } from "@material-ui/core";
import { useI18nContext } from "@hopper-b2b/i18n";
import { SortInput } from "../SortInput";
import { ApacIcon, ApacIconName } from "@commbank/ui";
import { FreeCancellation } from "../FreeCancellation";
import countActiveFilters from "../../utils/countActiveFilters";

interface Props {
  filters: ILodgingFilterState;
  sort: SortOption;
  openFiltersModal(): void;
  showMap(): void;
  onStarRatingChange(nextValue: number[]): void;
  onUserRatingChange(nextValue: number): void;
  onFilterPriceRangeChange(
    nextValue: Pick<PriceRangeType, "min" | "max">
  ): void;
  onFreeCancellationChange(nextValue: boolean): void;
  isMobileView?: boolean;
  setSort(nextValue: SortOption): void;
}

function QuickFilters({
  filters,
  sort,
  openFiltersModal,
  onStarRatingChange,
  onUserRatingChange,
  onFilterPriceRangeChange,
  onFreeCancellationChange,
  showMap,
  isMobileView,
  setSort,
}: Props) {
  const { t } = useI18nContext();

  const activeFilters = countActiveFilters(filters);
  return (
    <Box
      className={clsx("QuickFilters", "filters-selector", styles.QuickFilters)}
    >
      <button onClick={openFiltersModal}>
        <Badge
          badgeContent={activeFilters}
          color="primary"
          onClick={openFiltersModal}
          overlap="rectangular"
        >
          <IconComponent
            className="filter-icon"
            name={IconName.FilterMenuThick}
          />
        </Badge>
        {t("filter")}
      </button>

      <SortInput className={styles.Sort} value={sort} setSort={setSort} />

      {isMobileView ? (
        <button onClick={showMap}>
          <ApacIcon name={ApacIconName.LocationOutlined} />
          {t("commBank.hotelAvailability.viewMapButton")}
        </button>
      ) : (
        <Box className={styles.QuickFilterButtonContainer}>
          <GenericDropdown
            popoverClassName={styles.Dropdown}
            buttonClassName={styles.QuickFilterButton}
            dropdownLabel={t("starRating")}
            dropdownContent={
              <StarRating
                value={filters.starRating}
                onChange={onStarRatingChange}
              />
            }
            dropdownIcon={
              <ApacIcon
                name={ApacIconName.ChevronDown}
                className={styles.DropdownIcon}
              />
            }
          />
          <GenericDropdown
            popoverClassName={styles.Dropdown}
            buttonClassName={styles.QuickFilterButton}
            dropdownLabel={t("price")}
            dropdownContent={
              <>
                <Typography>{t("priceRange")}</Typography>
                <PriceRange
                  min={filters.priceRange.min}
                  max={filters.priceRange.max}
                  lowest={filters.priceRange.lowest}
                  highest={filters.priceRange.highest}
                  onFilterPriceRangeChange={onFilterPriceRangeChange}
                />
              </>
            }
            dropdownIcon={
              <ApacIcon
                name={ApacIconName.ChevronDown}
                className={styles.DropdownIcon}
              />
            }
          />
          <GenericDropdown
            popoverClassName={styles.Dropdown}
            buttonClassName={styles.QuickFilterButton}
            dropdownLabel={t("userRating")}
            dropdownContent={
              <UserRating
                value={filters.userRating}
                onChange={onUserRatingChange}
              />
            }
            dropdownIcon={
              <ApacIcon
                name={ApacIconName.ChevronDown}
                className={styles.DropdownIcon}
              />
            }
          />

          <GenericDropdown
            popoverClassName={styles.Dropdown}
            buttonClassName={styles.QuickFilterButton}
            dropdownLabel={t("freeCancellation")}
            dropdownContent={
              <FreeCancellation
                freeCancellation={filters.freeCancellation}
                onFreeCancellationChange={onFreeCancellationChange}
              />
            }
            dropdownIcon={
              <ApacIcon
                name={ApacIconName.ChevronDown}
                className={styles.DropdownIcon}
              />
            }
          />
        </Box>
      )}
    </Box>
  );
}

export default QuickFilters;
