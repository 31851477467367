import { ReactNode, useState, SyntheticEvent } from "react";
import {
  ApacActionLink,
  IApacActionLinkProps,
  ApacDesktopPopupModal,
  ApacIcon,
  ApacIconName,
  CBASpinner,
  useScrollToWhenVisible,
} from "../../";
import { MobilePopoverCard } from "@hopper-b2b/ui";
import { Box, Typography } from "@material-ui/core";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import "./ApacModalLink.styles.scss";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";

export interface IApacModalLinkProps {
  className?: string;
  modalClassName?: string;
  children?: ReactNode;
  iframeTitle?: string;
  content?:
    | JSX.Element
    | ((props: { onClose: () => void }) => JSX.Element)
    | string;
  scrollSelector?: string;
  url?: string;
  linkProps?: Partial<IApacActionLinkProps>;
}

/**
 * A component for clickable link text which opens a modal when clicked,
 * the modal can display inline content, or display and iframe with a url
 */
export const ApacModalLink = ({
  className,
  modalClassName,
  iframeTitle,
  children,
  content,
  url,
  linkProps = {},
  scrollSelector,
}: IApacModalLinkProps) => {
  const [showModal, setShowModal] = useState(false);
  const [iframeLoaded, setIFrameLoaded] = useState(false);
  const { matchesMobile: isMobile } = useDeviceTypes();
  const { t } = useI18nContext();
  useScrollToWhenVisible(scrollSelector, [showModal]);

  const closeModal = (e?: SyntheticEvent) => {
    e?.stopPropagation();
    setIFrameLoaded(false);
    setShowModal(false);
  };

  const modalContent = url ? (
    <Box className="modal-iframe-container">
      <Box className="modal-title-bar">{iframeTitle || ""}</Box>
      <iframe
        title={iframeTitle}
        src={url}
        onLoad={() => setIFrameLoaded(true)}
      />
      {!iframeLoaded && (
        <Box className="iframe-loading-message">
          <CBASpinner />
          <Typography variant="h2">{t("loading")}</Typography>
        </Box>
      )}
    </Box>
  ) : (
    <Box className="modal-content-container">
      {typeof content === "function"
        ? content({ onClose: closeModal })
        : content}
    </Box>
  );

  return (
    <>
      <ApacActionLink
        showUnderline
        message={children}
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
        {...linkProps}
        className={clsx(className, linkProps.className, "apac-modal-link")}
      />
      {isMobile ? (
        <MobilePopoverCard
          open={showModal}
          fullScreen
          className={`mobile-modal-link-popup ${modalClassName}`}
          onClose={closeModal}
          topRightButton={
            <ApacIcon name={ApacIconName.Close} onClick={closeModal} />
          }
        >
          {modalContent}
        </MobilePopoverCard>
      ) : (
        <ApacDesktopPopupModal
          open={showModal}
          className={`desktop-modal-link-popup ${modalClassName}`}
          onClose={closeModal}
        >
          {modalContent}
        </ApacDesktopPopupModal>
      )}
    </>
  );
};
