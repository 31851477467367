import { PriceDropProtection } from "./shop-summary";

export interface ByCandidate {
  id: string;
  GetEligibilityRequest: GetEligibilityRequestEnum.ByCandidate;
}

export type GetEligibilityRequest = ByCandidate;

export enum GetEligibilityRequestEnum {
  ByCandidate = "ByCandidate",
}

export interface GetEligibilityResponse {
  eligibility: PriceDropProtection;
}
