import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./styles.scss";

export interface CheckboxProps {
  onClick?: () => void;
  selected: boolean;
  ariaLabel?: string;
  className?: string;
  iconSrc?: string;
  disabled?: boolean;
}
export const Checkbox = ({
  onClick,
  selected,
  ariaLabel,
  className,
  iconSrc,
  disabled = false,
}: CheckboxProps) => {
  return (
    <div
      className={clsx("checkbox-container", className, disabled && "disabled")}
    >
      <div className="checkbox-border-wrapper">
        {selected && (
          <div className="checkbox">
            {iconSrc ? (
              <img alt="check icon" src={iconSrc} />
            ) : (
              <FontAwesomeIcon icon={faCheck as IconProp} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
