import {
  AirCfarExerciseRefundPaymentType,
  CipherText,
  QuoteSuccess,
} from "@b2bportal/air-cfar-api";
import { CfarExerciseProgress } from "@hopper-b2b/types";
import { Box, Radio, RadioGroup } from "@material-ui/core";
import { useContext, useEffect, useMemo } from "react";
import { ClientContext } from "../../../../../App";
import { CfarDetails } from "./CfarDetails";
import "./styles.scss";

interface CfarDetailsV1Props {
  cfarExerciseProgress: CfarExerciseProgress;
  selectedQuoteId: CipherText;
  cfarQuoteV1: QuoteSuccess;
  airlineNames: string[];
  onSelectQuoteId: (CipherText) => void;
}
export const CfarDetailsV1 = ({
  cfarExerciseProgress,
  selectedQuoteId,
  cfarQuoteV1,
  airlineNames,
  onSelectQuoteId,
}: CfarDetailsV1Props) => {
  const clientContext = useContext(ClientContext);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSelectQuoteId((event.target as HTMLInputElement).value);
  };
  const singleQuoteOption = useMemo(
    () => cfarQuoteV1.quotes.length === 1,
    [cfarQuoteV1]
  );

  useEffect(() => {
    if (singleQuoteOption) {
      onSelectQuoteId(cfarQuoteV1.quotes[0].quoteId);
    }
  }, [singleQuoteOption]);

  return singleQuoteOption ? (
    <CfarDetails
      cfarExerciseProgress={cfarExerciseProgress}
      refundPrice={cfarQuoteV1.quotes[0].refundTotal}
      isFtc={cfarQuoteV1.quotes[0].refundPayments.every(
        (payment) =>
          payment.type === AirCfarExerciseRefundPaymentType.AirlineFtc
      )}
      airlineNames={airlineNames}
    />
  ) : (
    <RadioGroup
      className="cfar-details-v1-option-container"
      value={selectedQuoteId?.value}
      onChange={handleChange}
    >
      {cfarQuoteV1?.quotes?.map((quote) => {
        return (
          <Box key={quote.quoteId} className="cfar-details-v1-option-row">
            <Radio
              value={quote.quoteId}
              iconSrc={clientContext?.assets?.checkmark}
            />
            <CfarDetails
              cfarExerciseProgress={cfarExerciseProgress}
              refundPrice={quote.refundTotal}
              isFtc={quote.refundPayments.every(
                (payment) =>
                  payment.type === AirCfarExerciseRefundPaymentType.AirlineFtc
              )}
              airlineNames={airlineNames}
            />
          </Box>
        );
      })}
    </RadioGroup>
  );
};
