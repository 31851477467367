export enum CfarTrackingEvents {
  VIEWED_CFAR = "viewed_cfar",
  ADDED_CFAR = "added_cfar",
  DECLINED_CFAR = "declined_cfar",
  TAP_LEARN_MORE = "tap_cfar_learn_more",
  TAP_TERMS_CONDITIONS = "tap_cfar_terms",
  ENTER_CFAR_EXERCISE_FLOW = "enter_cfar_exercise_flow",
  CFAR_POLICY_EXERCISED = "cfar_policy_exercised",
  VIEWED_CFAR_EXERCISE_ERROR = "viewed_cfar_exercise_error",
}

export type CfarOfferProperties = {
  cfar_quote_id: string;
  cfar_coverage_percentage: string;
  cfar_coverage_total: number;
  cfar_coverage_total_usd: number;
  cfar_premium_total: number;
  cfar_premium_total_usd: number;
};
export interface CfarOfferPropertiesWithPerPax extends CfarOfferProperties {
  cfar_coverage_per_pax?: number;
  cfar_coverage_per_pax_usd?: number;
  cfar_premium_per_pax: number;
  cfar_premium_per_pax_usd: number;
}

export interface CfarExerciseProperties extends CfarOfferProperties {
  cfar_outcome: CfarTrackingOutcome;
}

export enum CfarTrackingPageSelected {
  OFFER = "offer",
  EXERCISE = "exercise",
}

export enum CfarTrackingOutcome {
  CFAR_CASH = "cfar_cash",
  CFAR_FTC = "cfar_ftc",
}

export const AirOrchestratorCfarTrackingEvents = {
  VIEW_CFAR: "view_cfar",
  SKIP_CFAR: "skip_cfar",
  CHOOSE_CFAR: "choose_cfar",
  CFAR_EXERCISE_QUOTE: "cfar_exercise_quote",
  CFAR_EXERCISE_QUOTE_FAIL: "cfar_exercise_quote_fail",
  CFAR_EXERCISE_COMPLETE: "cfar_exercise_complete",
  CFAR_EXERCISE_COMPLETE_FAIL: "cfar_exercise_complete_fail",
  COMPLETE_BUY: "complete_buy",
};
