import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import styles from "./LodgingAddress.module.scss";
import { IconComponent } from "../IconComponent";
import { IconName } from "../Icon";
import { ReactElement } from "react";

interface Props {
  address: string;
  className?: string;
  icon?: ReactElement;
}

export function LodgingAddress({ address, className, icon }: Props) {
  return (
    <div
      className={clsx(className, styles.Container, "lodging-details-address")}
    >
      {icon ? icon : <IconComponent name={IconName.MapPinRound} />}
      <Typography color="textSecondary" variant="body2">
        {address}
      </Typography>
    </div>
  );
}
