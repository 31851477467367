import {
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
} from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ClientName,
  getDepartureSlice,
  getItinerarySummaryProps,
  getRestrictionInfo,
  getReturnSlice,
  Person,
} from "@hopper-b2b/types";
import {
  FlightDetailsSummary,
  MixedCabinToolTip,
  Restriction,
  SelectedSeatsConfirmation,
  FlightDetailsSummaryExtended,
} from "@hopper-b2b/ui";
import {
  getEnvVariables,
  useEnableCfar,
  useEnableChfar,
} from "@hopper-b2b/utilities";
import { Divider, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { Fragment, useContext, useEffect, useState } from "react";
import { getIsMixedClass } from "../../../../../../utils/helpers";
import { DATE_FORMAT } from "../../../../constants";
import { getSeatSegments } from "../../../FlightCard/components/DesktopFlightCard/component";
import { TripsStatusTag } from "../../../TripsStatusTag";
import "./styles.scss";
import { ClientContext } from "../../../../../../../../App";

export interface IItinerarySummaryModalContentProps {
  departure: boolean;
  flight: BookedFlightItineraryWithDepartureTime;
  airportMap: { [key: string]: Airport | undefined };
  airlineMap: { [key: string]: Airline | undefined };
}

export const ItinerarySummaryModalContent = (
  props: IItinerarySummaryModalContentProps
) => {
  const { t, formatFiatCurrency } = useI18nContext();
  const clientAssets = useContext(ClientContext);
  const { flight, airportMap, airlineMap, departure } = props;
  const departureSlice = getDepartureSlice(flight.bookedItinerary);
  const returnSlice = getReturnSlice(flight.bookedItinerary);
  type IPassengerMap = { [key: string]: Person };
  const passengerMap: IPassengerMap = {};
  flight.bookedItinerary.passengers.alone.reduce((map, p) => {
    map[p.person.id] = p.person;
    return map;
  }, passengerMap);
  flight.bookedItinerary.passengers.withLapInfants.reduce((map, p) => {
    map[p.adult.person.id] = p.adult.person;
    return map;
  }, passengerMap);
  const [isMixedCabinClass, setIsMixedCabinClass] = useState(false);

  const showFlightCFAR = useEnableCfar();
  const showFlightChfar = useEnableChfar();
  const isNubankClient = getEnvVariables("clientName") === ClientName.NUBANK;

  const cfarSelected = showFlightCFAR && !!flight?.ancillaries?.cfar;
  const chfarSelected = showFlightChfar && !!flight?.ancillaries?.chfar;

  useEffect(() => {
    if (departure) {
      setIsMixedCabinClass(getIsMixedClass(departureSlice));
    } else if (!departure && returnSlice) {
      setIsMixedCabinClass(getIsMixedClass(returnSlice));
    } else {
      return;
    }
  }, [flight]);

  const slice = departure
    ? getDepartureSlice(flight.bookedItinerary)
    : getReturnSlice(flight.bookedItinerary);
  const lastIndexOfSegments = slice!.segments.length - 1;

  const getItineraryDetailsHeader = (airportMap: {
    [key: string]: Airport | undefined;
  }) => {
    return t("itineraryDetailsHeader", {
      airport:
        airportMap[
          slice!.segments[lastIndexOfSegments].destination.locationCode
        ]?.cityName,
      destination:
        slice!.segments[lastIndexOfSegments].destination.locationCode,
      date: dayjs(slice!.segments[0].scheduledDeparture).format(DATE_FORMAT),
    });
  };

  return isNubankClient ? (
    <>
      <h4 className="mobile-flight-details-modal-title">
        {departure
          ? t("myTripsOutboundCardHeader", {
              location:
                airportMap[
                  slice!.segments[lastIndexOfSegments].destination.locationCode
                ]?.cityName,
            })
          : t("myTripsReturnCardHeader", {
              location:
                airportMap[
                  slice!.segments[lastIndexOfSegments].destination.locationCode
                ]?.cityName,
            })}
      </h4>
      <div className="itinerary-summary-modal">
        <FlightDetailsSummaryExtended
          {...getItinerarySummaryProps(
            flight,
            departure,
            airportMap,
            airlineMap
          )}
          assets={clientAssets?.assets}
          airports={airportMap}
        />
      </div>
    </>
  ) : (
    <div className="itinerary-summary-modal">
      <TripsStatusTag
        flight={flight}
        isOutgoing={departure}
        scheduleChange={flight.bookedItinerary.scheduleChange}
        airportMap={airportMap}
        className={"mobile-status-tag"}
      />
      <Typography variant="subtitle1" className="itinerary-details-header">
        <span className="direction-label">{t("outbound")} </span>
        {getItineraryDetailsHeader(airportMap)}
      </Typography>
      <FlightDetailsSummary
        showTitle={false}
        {...getItinerarySummaryProps(flight, departure, airportMap, airlineMap)}
        isMixedCabinClass={isMixedCabinClass}
      />
      {isMixedCabinClass && <MixedCabinToolTip showDivider={false} />}
      <Divider className="divider" />
      <Typography className="fare-details-header" variant="subtitle1">
        {t("fareDetails")}
      </Typography>

      <div className="trip-itinerary-restrictions">
        {getRestrictionInfo(
          flight,
          t,
          formatFiatCurrency,
          cfarSelected,
          chfarSelected
        ).map((restriction, index) => (
          <Restriction
            key={`restriction-${index}`}
            name={restriction.name}
            descriptionString={restriction.description}
            symbol={restriction.symbol}
          />
        ))}
      </div>
      {flight.bookedItinerary.seats &&
        flight.bookedItinerary.seats.seats.length > 0 && (
          <Fragment>
            <Typography className="seat-selection-header" variant="subtitle1">
              {t("seatSelection")}
            </Typography>
            <div className="itinerary-modal-seat-selection">
              <SelectedSeatsConfirmation
                outboundSeatSegments={getSeatSegments(
                  flight.bookedItinerary.seats,
                  departure ? departureSlice.segments : returnSlice!.segments,
                  passengerMap,
                  departure
                )}
                outboundOriginCode={
                  departureSlice.segments[0].origin.locationCode
                }
                returnOriginCode={returnSlice?.segments[0].origin.locationCode}
                airports={airportMap}
              />
            </div>
          </Fragment>
        )}
    </div>
  );
};
