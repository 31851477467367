import { nameRegex } from "@commbank/utilities";
import { Assistance, Gender, Person } from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { INationality, LocalDate, PersonId } from "@hopper-b2b/types";
import {
  generateUserId,
  getGender,
  parseDayMonthAndYear,
  specialCharacterRegex,
} from "@hopper-b2b/utilities";
import { Box, Grid, OutlinedInputProps, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { ApacActionButton } from "../../ApacActionButton";
import { ApacSelect } from "../../ApacSelect";
import { ApacTextField } from "../../ApacTextField";
import { InputWrapper } from "../../InputWrapper";
import { TravelerTypeTitles } from "../PassengerInfo";
import { AdditionalInformationSection } from "./AdditionalInformationSection";
import { FrequentFlyerSection } from "./FrequentFlyerSection";
import { HotelLoyaltySection } from "./HotelLoyaltySection";
import { PassportSection } from "./PassportSection";
import "./TravelerInfoForm.styles.scss";
import {
  IFrequentFlyerList,
  IHotelLoyaltyList,
  genderOptions,
  getFrequentFlyerList,
  getFrequentFlyerMap,
  getHotelLoyaltyList,
  getHotelLoyaltyMap,
} from "./utils";
import { ApacAccordion } from "../../ApacAccordion";

export const DISPLAY_DATE_FORMAT = "DD/MM/YYYY";
export const API_DATE_FORMAT = "YYYY-MM-DD";

export const formatAPIDateToDisplay = (date: string) => {
  if (dayjs(date, API_DATE_FORMAT).isValid()) {
    return dayjs(date, API_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
  } else {
    return null;
  }
};

export const formatDisplayDateToAPI = (date: string) => {
  if (dayjs(date, DISPLAY_DATE_FORMAT).isValid()) {
    return dayjs(date, DISPLAY_DATE_FORMAT).format(API_DATE_FORMAT);
  } else {
    return undefined;
  }
};

export const dateInput = ({
  value,
  onChange,
  wrapperLabel,
  wrapperError,
}: {
  value: string;
  wrapperLabel: string | JSX.Element;
  wrapperError: string | JSX.Element;
  onChange: OutlinedInputProps["onChange"];
}) => {
  return (
    <div className="date-container">
      <InputWrapper label={wrapperLabel} error={wrapperError}>
        <ApacTextField
          className={clsx("traveler-info-field", { error: !!wrapperError })}
          required={true}
          value={value}
          onChange={onChange}
          type="tel"
          InputProps={{
            pattern: "[0-9]*",
          }}
          placeholder={DISPLAY_DATE_FORMAT}
        />
      </InputWrapper>
      {/* hide the icon before we add calendar date selector */}
      {/* <ApacIcon className="calendar-icon" name={ApacIconName.Calendar} /> */}
    </div>
  );
};

export interface ITravelerInfoFormProps {
  className?: string;
  traveler?: Person;
  titles: TravelerTypeTitles;
  handleUpdatePassenger: (
    traveler: Person,
    onUpdate?: boolean,
    singleTravelerWorkflow?: boolean
  ) => void;
  singleTravelerWorkflow?: boolean;
  handleRemovePassenger: (travelerId: PersonId) => void;
  isMobile?: boolean;
  closeButton?: JSX.Element;
  showPassport?: boolean;
  hideFrequentFlyerSection?: boolean;
  hideHotelLoyaltySection?: boolean;
  hideNationalityField?: boolean;
  hideAdditionalInfoSection?: boolean;
  hideGenderField?: boolean;
  buttonClassName?: string;
  hideHeader?: boolean;
  passportOnly?: boolean;
  handleClose?: (id?: string) => void;
}

export const TravelerInfoForm = (props: ITravelerInfoFormProps) => {
  const {
    className,
    traveler,
    titles,
    handleUpdatePassenger,
    singleTravelerWorkflow,
    isMobile,
    closeButton,
    showPassport = false,
    hideFrequentFlyerSection = false,
    hideHotelLoyaltySection = true,
    hideGenderField = false,
    hideAdditionalInfoSection = false,
    buttonClassName,
    hideHeader = false,
    handleClose,
    passportOnly,
  } = props;

  const { t, brand } = useI18nContext();
  // Traveler Info
  const [firstName, setFirstName] = useState(traveler?.givenName || "");
  const [middleName, setMiddleName] = useState(traveler?.middleName || "");
  const [lastName, setLastName] = useState(traveler?.surname || "");
  const [birthdate, setBirthdate] = useState<LocalDate>(
    traveler && formatAPIDateToDisplay(traveler?.dateOfBirth)
  );
  const [firstNameError, setFirstNameError] = useState<string>();
  const [middleNameError, setMiddleNameError] = useState<string>();
  const [lastNameError, setLastNameError] = useState<string>();
  const [birthdateError, setBirthdateError] = useState<string>();
  const [genderError, setGenderError] = useState<string>();
  const [passportExpiryError, setPassportExpiryError] = useState<string>();
  const [issueCountryError, setIssueCountryError] = useState<string>();
  const [passportNumberError, setPassportNumberError] = useState<string>();
  const [gender, setGender] = useState<Gender | null>(traveler?.gender ?? null);
  // nationality is required by backend api
  const [nationality, setNationality] = useState<INationality>(
    traveler?.nationality || { country: brand.preferredCountryCode }
  );

  // Passport
  const [passport, setPassport] = useState({
    // material selector doesn't take undefined or null
    countryOfIssue: traveler?.passport?.countryOfIssue || "",
    number: traveler?.passport?.number || undefined,
    expiration: formatAPIDateToDisplay(traveler?.passport?.expiration),
  });

  // Frequent Flyer Details
  const [frequentFlyerList, setFrequentFlyerList] =
    useState<IFrequentFlyerList>(
      traveler?.frequentFlyer
        ? getFrequentFlyerList(traveler.frequentFlyer)
        : []
    );

  const [hotelLoyaltyList, setHotelLoyaltyList] = useState<IHotelLoyaltyList>(
    traveler?.hotelLoyalty ? getHotelLoyaltyList(traveler.hotelLoyalty) : []
  );

  // Additional Info
  const [assistance, setAssistance] = useState<Assistance[]>(
    traveler?.assistance ?? []
  );
  const [redressNumber, setRedressNumber] = useState(traveler?.redressNumber);
  const [knownTravelerNumber, setKnownTravelerNumber] = useState(
    traveler?.knownTravelerNumber
  );

  const isNameValid = (name?: string) =>
    name && nameRegex.test(name) && name.length >= 2;

  const handleSaveTraveler = useCallback(() => {
    const verifyPassport = () => {
      let valid = true;

      if (!passport?.number) {
        setPassportNumberError(t("travellerForm.passportRequired"));
        valid = false;
      } else {
        if (/\s/.test(passport.number)) {
          setPassportNumberError(t("noWhitespaceError"));
          valid = false;
        } else if (!specialCharacterRegex.test(passport.number)) {
          setPassportNumberError(t("noSpecialCharactersError"));
          valid = false;
        }
      }

      const { expiration } = passport;
      if (!expiration) {
        setPassportExpiryError(t("travellerForm.passportExpirationRequired"));
        valid = false;
      } else {
        const dateDayjs = dayjs(expiration, DISPLAY_DATE_FORMAT, true);
        if (!dateDayjs.isValid() || dateDayjs.isBefore(dayjs())) {
          setPassportExpiryError(t("enterAValidExpiry"));
          valid = false;
        }
      }

      const { countryOfIssue } = passport;
      if (!countryOfIssue) {
        setIssueCountryError(t("travellerForm.issueCountryRequired"));
        valid = false;
      }

      return valid;
    };

    const verifyInput = () => {
      if (passportOnly) {
        // passport input/verification modal for international flight if a user hasn't input passport yet.
        return verifyPassport();
      }

      let valid = true;

      // passport saving is either empty or all
      if (
        showPassport &&
        (!!passport.number ||
          !!passport.expiration ||
          !!passport.countryOfIssue)
      ) {
        valid &&= verifyPassport();
      }

      if (!isNameValid(firstName)) {
        valid = false;
        if (!firstName) {
          setFirstNameError(t("travellerForm.firstNameRequired"));
        } else if (firstName.length < 2) {
          setFirstNameError(t("firstNameMinCharacterError"));
        } else {
          setFirstNameError(t("noSpecialCharactersError"));
        }
      }

      if (!isNameValid(lastName)) {
        valid = false;
        if (!lastName) {
          setLastNameError(t("travellerForm.lastNameRequired"));
        } else if (lastName.length < 2) {
          setLastNameError(t("lastNameMinCharacterError"));
        } else {
          setLastNameError(t("noSpecialCharactersError"));
        }
      }

      if (!!middleName && !nameRegex.test(middleName)) {
        valid = false;
        setMiddleNameError(t("noSpecialCharactersError"));
      }

      if (birthdate) {
        const dateDayjs = dayjs(birthdate, DISPLAY_DATE_FORMAT, true);
        if (!dateDayjs.isValid() || dateDayjs.isAfter(dayjs())) {
          setBirthdateError(t("enterAValidBirthDate"));
          valid = false;
        }
      } else {
        setBirthdateError(t("enterAValidBirthDate"));
        valid = false;
      }

      if (!gender) {
        setGenderError(t("travellerForm.genderRequired"));
        valid = false;
      }

      return valid;
    };

    if (!verifyInput()) {
      return;
    }

    const updatedTraveler: Person = {
      ...traveler,
      givenName: firstName,
      surname: lastName,
      middleName: middleName || undefined,
      dateOfBirth: formatDisplayDateToAPI(birthdate),
      nationality: nationality,
      passport: passport.number
        ? {
            ...passport,
            expiration: formatDisplayDateToAPI(passport.expiration),
          }
        : undefined,
      gender,
      frequentFlyer: getFrequentFlyerMap(frequentFlyerList),
      hotelLoyalty: getHotelLoyaltyMap(hotelLoyaltyList),
      assistance: assistance,
      id: traveler?.id || generateUserId(),
      createdAt: traveler?.createdAt || dayjs().toISOString(),
    };

    if (knownTravelerNumber) {
      updatedTraveler.knownTravelerNumber = knownTravelerNumber;
    }

    updatedTraveler.redressNumber =
      redressNumber && redressNumber.length > 0 ? redressNumber : undefined;

    handleUpdatePassenger(updatedTraveler, singleTravelerWorkflow);

    return null;
  }, [
    traveler,
    firstName,
    lastName,
    middleName,
    birthdate,
    nationality,
    passport,
    gender,
    frequentFlyerList,
    hotelLoyaltyList,
    assistance,
    knownTravelerNumber,
    redressNumber,
    handleUpdatePassenger,
    singleTravelerWorkflow,
    t,
    passportOnly,
    showPassport,
  ]);

  const onBirthdateChanged = (birthdate: string) => {
    setBirthdate(formatInputDate(birthdate));
    setBirthdateError(null);
  };

  const formatInputDate = (date: string) => {
    const { month, day, year, strLength } = parseDayMonthAndYear(date);
    return (
      day +
      (strLength > 2 ? "/" : "") +
      month +
      (strLength > 4 ? "/" : "") +
      year
    );
  };

  const getGenderOptions = () => {
    return [...genderOptions].map((option) => {
      const gender = getGender(option.value);
      option.label = t("person", { context: gender });
      return option;
    });
  };

  return (
    <div
      className={clsx(
        "apac-traveler-info-form-root",
        { mobile: isMobile },
        className,
        buttonClassName
      )}
    >
      <div
        className={clsx("traveler-info-form-container", {
          edit: traveler?.id,
        })}
      >
        {!passportOnly && (
          <div className={clsx("traveler-info-form-section")}>
            {!hideHeader && (
              <div className="traveler-info-description">
                <Typography variant="h3">
                  {titles.travelerSectionTitle ||
                    t("passengerInformation.travelerSectionTitle")}
                </Typography>
                <Typography variant="body2">
                  {titles.travelerSectionSubtitle}
                </Typography>
              </div>
            )}
            <form className="traveler-info-form">
              <InputWrapper label={t("firstName")} error={firstNameError}>
                <ApacTextField
                  aria-label={t("firstName")}
                  aria-live="assertive"
                  className={clsx("traveler-info-field", "first-name", {
                    error: firstNameError,
                  })}
                  required={true}
                  value={firstName}
                  InputProps={{
                    minLength: 2,
                  }}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                    setFirstNameError(null);
                  }}
                />
              </InputWrapper>
              <InputWrapper
                label={t("middleNameOptional")}
                error={middleNameError}
              >
                <ApacTextField
                  aria-label={t("middleNameOptional")}
                  aria-live="assertive"
                  className={clsx("traveler-info-field", "middle-name", {
                    error: middleNameError,
                  })}
                  required={true}
                  value={middleName}
                  InputProps={{
                    minLength: 2,
                  }}
                  onChange={(event) => {
                    setMiddleName(event.target.value);
                    setMiddleNameError(null);
                  }}
                />
              </InputWrapper>
              <InputWrapper label={t("lastName")} error={lastNameError}>
                <ApacTextField
                  className={clsx("traveler-info-field", "last-name", {
                    error: lastNameError,
                  })}
                  required={true}
                  value={lastName}
                  InputProps={{
                    minLength: 2,
                  }}
                  onChange={(event) => {
                    setLastName(event.target.value);
                    setLastNameError(null);
                  }}
                  id="last-name-input"
                />
              </InputWrapper>

              <Grid container spacing={3} className="form-grid-container">
                <Grid item md={6} xs={12}>
                  {dateInput({
                    value: birthdate,
                    onChange: (event) => onBirthdateChanged(event.target.value),
                    wrapperLabel: t("travellerForm.dateOfBirth"),
                    wrapperError: birthdateError,
                  })}
                </Grid>
                <Grid item md={6} xs={12}>
                  {!hideGenderField && (
                    <InputWrapper
                      label={t("travellerForm.genderOnPassport")}
                      error={genderError}
                    >
                      <ApacSelect
                        className={clsx("gender", "traveler-select", {
                          error: !!genderError,
                        })}
                        value={gender || ""}
                        options={getGenderOptions()}
                        onChange={(event) => {
                          setGender(event.target.value as Gender);
                          setGenderError(null);
                        }}
                        placeholder={t("choose")}
                      />
                    </InputWrapper>
                  )}
                </Grid>
              </Grid>
            </form>
          </div>
        )}

        {(showPassport || traveler?.passport) && (
          <div className="traveler-info-form-section section-container">
            <ApacAccordion
              header={
                <Box className="traveler-info-description">
                  <Typography variant="h3">
                    {titles?.passportSectionTitle ||
                      t("passengerInformation.passportSectionTitle")}
                  </Typography>
                </Box>
              }
              initialOpen={passportOnly || showPassport}
            >
              <PassportSection
                isMobile={isMobile}
                titles={
                  passportOnly
                    ? {
                        ...titles,
                        passportSectionTitle:
                          traveler.givenName + " " + traveler.surname,
                      }
                    : titles
                }
                passport={passport}
                setPassport={(v) => {
                  setPassport(v);
                  setPassportExpiryError(null);
                  setPassportNumberError(null);
                }}
                nationality={nationality}
                setNationality={setNationality}
                issueCountryError={issueCountryError}
                passportExpiryError={passportExpiryError}
                passportNumberError={passportNumberError}
              />
            </ApacAccordion>
          </div>
        )}

        {!hideFrequentFlyerSection && !passportOnly && (
          <div className="traveler-info-form-section section-container">
            <ApacAccordion
              header={
                <Box className="traveler-info-description">
                  <Typography variant="h3">
                    {titles.frequentFlyerTitle}
                  </Typography>
                </Box>
              }
            >
              <FrequentFlyerSection
                frequentFlyerList={frequentFlyerList}
                setFrequentFlyerList={setFrequentFlyerList}
                isMobile={isMobile}
                closeButton={closeButton}
              />
            </ApacAccordion>
          </div>
        )}

        {!hideAdditionalInfoSection && !passportOnly && (
          <div className="traveler-info-form-section section-container">
            <ApacAccordion
              header={
                <Box className="traveler-info-description">
                  <Typography variant="h3">
                    {titles.additionalInfoTitle}
                  </Typography>
                </Box>
              }
            >
              <AdditionalInformationSection
                assistance={assistance}
                setAssistance={setAssistance}
                redressNumber={redressNumber}
                setRedressNumber={setRedressNumber}
                knownTravelerNumber={knownTravelerNumber}
                setKnownTravelerNumber={setKnownTravelerNumber}
              />
            </ApacAccordion>
          </div>
        )}

        {!hideHotelLoyaltySection && !passportOnly && (
          <ApacAccordion
            header={
              <Box className="traveler-info-description section-title">
                <Typography variant="h4">Hotel Loyalty</Typography>
              </Box>
            }
          >
            <HotelLoyaltySection
              className="section-container"
              hotelLoyaltyTitle="Hotel Loyalty"
              hotelLoyaltyList={hotelLoyaltyList}
              setHotelLoyaltyList={setHotelLoyaltyList}
            />
          </ApacAccordion>
        )}

        {!isMobile ? (
          <div className="traveller-modal-button-container">
            <ApacActionButton
              className="save-traveller-button"
              message={t("passengerInformation.saveTraveler")}
              onClick={handleSaveTraveler}
            />
            <ApacActionButton
              message={t("cancel")}
              variant="outlined"
              buttonClassName="cancel"
              onClick={() => handleClose(traveler?.id)}
            />
          </div>
        ) : (
          <div className="mobile-traveller-button-container">
            <ApacActionButton
              className="save-traveller-button"
              message={t("passengerInformation.saveTraveler")}
              onClick={handleSaveTraveler}
            />
          </div>
        )}
      </div>
    </div>
  );
};
