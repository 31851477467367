import { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import "./styles.scss";
import CloseButtonIcon from "../../assets/icons/common/close-button.svg";
import { ActionLink } from "../ActionLink";
import { Icon, IconName } from "../Icon";
import { ExpandableCard } from "../ExpandableCard";

export interface IPriceDropProtectionProps {
  className?: string;
  image: string;
  onClose?: () => void;
  title: string;
  subtitle: string;
  header: string;
  onClick?: () => void;
  ctaText: string;
  isMobile?: boolean;
  mobileTermsConditionCopy?: string;
  icon?: JSX.Element;
}

export const PriceDropProtection = (props: IPriceDropProtectionProps) => {
  const {
    className,
    image,
    onClose,
    title,
    subtitle,
    header,
    ctaText,
    onClick,
    isMobile,
    mobileTermsConditionCopy,
    icon,
  } = props;
  const [expandedKey, setExpandedKey] = useState("");

  const renderMobileTermsAndCondition = () => {
    return (
      <ExpandableCard
        className={clsx("pdp-terms-condition", "b2b")}
        expandedCardKey={expandedKey}
        cardKey={"pdp-terms-key"}
        handleCardKeyChange={() =>
          setExpandedKey(expandedKey === "pdp-terms-key" ? "" : "pdp-terms-key")
        }
        scrollExpandedIntoView={true}
        content={{
          title: (
            <Typography className="cta-text" variant="h6">
              {ctaText}
            </Typography>
          ),
          body: (
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: mobileTermsConditionCopy || "",
              }}
            />
          ),
        }}
      />
    );
  };

  const renderDesktopTermsAndCondition = () => {
    return (
      <ActionLink
        className="cta-button"
        onClick={onClick}
        content={
          <Typography className="cta-text" variant="h6">
            <span dangerouslySetInnerHTML={{ __html: ctaText }}></span>
          </Typography>
        }
        showTappableArea={true}
      />
    );
  };

  return (
    <Box className={clsx(className, "price-drop-protection-opt-in")}>
      <Box
        className="top-content-container"
        style={{ backgroundImage: `url(${image})` }}
      >
        <Box className="img-location-container">
          <Icon className="img-location-icon" name={IconName.B2BMapPin} />
          <Typography className="img-location-text" variant="caption">
            Thailand
          </Typography>
        </Box>
        {onClose && (
          <ActionLink
            className="price-drop-protection-close-button"
            onClick={onClose}
            content={
              <img
                src={CloseButtonIcon}
                className="close-button-icon"
                alt="close button icon"
              />
            }
            label="Close"
            showTappableArea={true}
          />
        )}
        {icon ? (
          icon
        ) : (
          <Icon className="icon" name={IconName.PriceDropProtectionIcon} />
        )}

        <Typography
          className="card-header"
          variant="h4"
          dangerouslySetInnerHTML={{ __html: header }}
        />
      </Box>

      <Box className="bottom-content-container">
        <Typography className="title" variant="h4">
          <span dangerouslySetInnerHTML={{ __html: title }}></span>
        </Typography>
        <Typography className="subtitle" variant="body2">
          <span dangerouslySetInnerHTML={{ __html: subtitle }}></span>
        </Typography>

        {isMobile
          ? renderMobileTermsAndCondition()
          : renderDesktopTermsAndCondition()}
      </Box>
    </Box>
  );
};
