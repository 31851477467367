import { Example } from "@b2bportal/air-shopping-api";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ITripTerminus, RegionType } from "@hopper-b2b/types";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import { uniqBy } from "lodash-es";
import { useContext, useMemo, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";

import { ClientContext } from "../../../../ApacFlightApp";
import { getSelectedAccountReferenceId } from "../../../rewards/reducer";
import { FlightShopSearchFilter } from "../../../search/components/FlightShopSearchControlV3/components";
import { AppliedFilterTags } from "../../../search/components/FlightShopSearchControlV3/components/AppliedFilterTags";
import {
  getFareclassOptionFilter,
  getHasSetFareClassFilter,
} from "../../../search/reducer";
import { actions as shopActions } from "../../../shop/actions";
import { FlightShopHeader } from "../../../shop/components";
import { FareClassOptionSelection } from "../../../shop/components/FlightShopHeader/components/FareClassOptionSelection";
import { FlightShopProgressHeader } from "../../../shop/components/FlightShopProgressHeader";
import {
  FlightShopStep,
  flightShopProgressSelector,
  flightsSelector,
  getSortedAndFilteredFlights,
  hasSetNonFareclassFiltersSelectorV2,
  maxFlightPriceSelectorV2,
  selectedTripSelector,
  tripDetailsByIdSelector,
  tripDetailsLoadingSelector,
  tripSummariesErrorSelector,
} from "../../../shop/reducer";
import { getSliceFareDetails } from "../../../shop/utils/helpers";
import { FlightList } from "../../../shop/v3/components/FlightList";
import { IFlightListData } from "../../../shop/v3/components/FlightList/FlightList";
import { getFlightSummaries } from "../../actions/actions";
import { getReturnSelection } from "../../reducer/selectors";
import { getTripDetails } from "../../reducer/shop";
import {
  buildOutgoingShopSlice,
  buildReturnShopSlice,
  skipShopAction,
} from "../../utils";

import "./styles.scss";

interface IFlightExchangeShopProps {
  bookingId: string;
  departureDate: Date;
  isRoundTrip: boolean;
  onShopDone: () => void;
  origin: ITripTerminus | null;
  returnDate?: Date;
  setOpenCalendarModal: () => void;
}

export const FlightExchangeShop = ({
  bookingId,
  departureDate,
  isRoundTrip,
  onShopDone,
  origin,
  returnDate,
  setOpenCalendarModal,
}: IFlightExchangeShopProps) => {
  const clientContext = useContext(ClientContext);
  const { matchesMobile } = useDeviceTypes();
  const dispatch = useDispatch();

  const [fareTrips, setFareTrips] = useState<Example[]>([]);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const fareFilters = useSelector(getFareclassOptionFilter);
  const fareFilterSet = useSelector(getHasSetFareClassFilter);
  const flights = useSelector(flightsSelector);
  const maxPrice = useSelector(maxFlightPriceSelectorV2);
  const nonFareFilterSet = useSelector(hasSetNonFareclassFiltersSelectorV2);
  const flightOptions = useSelector(getSortedAndFilteredFlights);
  const returnSelection = useSelector(getReturnSelection);
  const rewardsKey = useSelector(getSelectedAccountReferenceId);
  const selectedTrip = useSelector(selectedTripSelector);
  const shopStep = useSelector(flightShopProgressSelector);
  const tripDetailsMap = useSelector(tripDetailsByIdSelector);
  const tripDetailsLoading = useSelector(tripDetailsLoadingSelector);
  const tripSummariesError = useSelector(tripSummariesErrorSelector);

  const onChooseDeparture = shopStep === FlightShopStep.ChooseDeparture;
  const onChooseReturn = shopStep === FlightShopStep.ChooseReturn;

  const expandedFareDetails = useMemo(() => {
    const fetchedAllFareDetails = fareTrips.length
      ? fareTrips.every((fareTrip) => !!tripDetailsMap[fareTrip.trip])
      : false;

    return fetchedAllFareDetails
      ? getSliceFareDetails(tripDetailsMap, fareTrips)
      : null;
  }, [fareTrips, tripDetailsMap]);

  // when the "Select Outbound/Return" button is clicked
  const onFareSelect = (flight: IFlightListData, fareId: string) => {
    if (onChooseDeparture) {
      const selectedSlice = buildOutgoingShopSlice(flight, fareId, flights);
      const skipChooseReturn = skipShopAction(returnSelection);

      batch(() => {
        dispatch(
          shopActions.setChosenOutgoingSlice({
            ...selectedSlice,
            resetReturnIds: !skipChooseReturn,
          })
        );

        if (skipChooseReturn) {
          onShopDone();
        } else {
          dispatch(
            shopActions.setFlightShopProgress(FlightShopStep.ChooseReturn)
          );
        }
      });
    } else if (onChooseReturn) {
      const selectedReturn = buildReturnShopSlice(
        flight,
        fareId,
        flights.fareSlices,
        tripDetailsMap
      );

      if (selectedReturn) {
        dispatch(shopActions.setChosenReturnSlice(selectedReturn));
        onShopDone();
      }
    }
  };

  // when a shop flight is expanded, fetch details about it's various fares
  const onFlightSelect = (flightFares: Example[]) => {
    uniqBy(flightFares, (trip) => trip.trip).map((fareTrip) =>
      dispatch(getTripDetails(fareTrip.trip))
    );

    setFareTrips(flightFares);
  };

  return (
    <>
      <Box className="progress-header-menu-wrapper">
        <FlightShopProgressHeader
          inExchange
          assets={clientContext?.assets || undefined}
          backIcon={faArrowLeft}
          className="mobile-shop-header"
          isFilterHidden={false}
          isTripEditHidden={false}
          onFiltersClick={() => setFiltersOpen(true)}
          onShopParamsChange={() =>
            dispatch(getFlightSummaries(bookingId, matchesMobile))
          }
        />
      </Box>
      {onChooseDeparture || onChooseReturn ? (
        <>
          <FlightShopSearchFilter
            filtersOpen={filtersOpen}
            hideAirportFilter={
              origin?.id.code.regionType === RegionType.Airport
            }
            isReturn={onChooseReturn}
            setFiltersOpen={setFiltersOpen}
          />
          <AppliedFilterTags />
          <FareClassOptionSelection />
          {tripDetailsLoading || tripSummariesError ? null : (
            <FlightShopHeader isMobile />
          )}
          <FlightList
            disablePriceFreeze
            departureDate={departureDate}
            expandedFareDetails={expandedFareDetails}
            fareClassFilter={fareFilters}
            flights={flights}
            flightsToRender={flightOptions}
            handleFareSelect={onFareSelect}
            handleFlightSelect={onFlightSelect}
            hasAppliedFareClassFilter={fareFilterSet}
            hasAppliedNonFareclassFilter={nonFareFilterSet}
            hasFlightsError={tripSummariesError}
            isInChooseReturnStep={onChooseReturn}
            isRoundTrip={isRoundTrip}
            maxFlightPrice={maxPrice}
            onErrorReload={() =>
              dispatch(getFlightSummaries(bookingId, matchesMobile))
            }
            returnDate={returnDate}
            rewardsKey={rewardsKey ?? undefined}
            selectedTrip={selectedTrip}
            setOpenCalendarModal={setOpenCalendarModal}
            tripSummariesLoading={false}
          />
        </>
      ) : null}
    </>
  );
};

export default FlightExchangeShop;
