import { connect } from "react-redux";
import { DesktopCalendarPicker } from "./component";
import { IStoreState } from "../../../../../../reducers/types";
import { hasSelectedDates } from "../../../../reducer";

const mapDispatchToProps = {};
const mapStateToProps = (state: IStoreState) => {
  return {
    hasSelectedDates: hasSelectedDates(state),
  };
};

export const ConnectedDesktopCalendarPicker = connect(
  mapStateToProps,
  mapDispatchToProps
)(DesktopCalendarPicker);
