import {
  ApacActionButton,
  ApacDesktopPopupModal,
  ApacIcon,
  ApacIconName,
} from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { MobilePopoverCard } from "@hopper-b2b/ui";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { CreditTransactions } from "../CreditTransactions";
import { BalanceSection } from "../TravelCreditsTab";
import {
  FiatPrice,
  WalletCreditTransaction,
} from "@b2bportal/commbank-au-wallet-api";
import "./AllCreditActivityModal.styles.scss";

export type AllCreditActivityModalProps = {
  isMobile: boolean;
  open: boolean;
  name: string;
  creditBalance: FiatPrice;
  creditHistory: WalletCreditTransaction[];
  onClose: () => void;
  isLoading?: boolean;
};

export const AllCreditActivityModal = (props: AllCreditActivityModalProps) => {
  const {
    isMobile,
    open,
    onClose,
    name,
    creditBalance,
    isLoading,
    creditHistory,
  } = props;

  const { t } = useI18nContext();

  const renderActivityContent = () => (
    <div className="modal-content">
      <Box className="header-section">
        <ApacIcon name={ApacIconName.CBACash} />
        <BalanceSection name={name} creditBalance={creditBalance} />
      </Box>

      <Box className="transaction-history-container">
        <Typography className="transaction-title" variant="h6">
          {t("wallet.transactionSectionTitle")}
        </Typography>
        <CreditTransactions
          transactions={creditHistory}
          isLoading={isLoading}
          showDivider
        />
      </Box>

      <ApacActionButton
        className="close-button"
        message={t("close.button")}
        onClick={onClose}
      />
    </div>
  );

  return isMobile ? (
    <MobilePopoverCard
      open={open}
      className={clsx("wallet-travel-credit-all-activity-modal", "mobile")}
      fullScreen
      onClose={onClose}
      topRightButton={<ApacIcon name={ApacIconName.Close} onClick={onClose} />}
    >
      {renderActivityContent()}
    </MobilePopoverCard>
  ) : (
    <ApacDesktopPopupModal
      className="wallet-travel-credit-all-activity-modal desktop"
      maxWidth="xl"
      open={open}
      onClose={onClose}
      fullScreen={true}
    >
      {renderActivityContent()}
    </ApacDesktopPopupModal>
  );
};
