import { Typography, Box, Divider } from "@material-ui/core";
import { Seat } from "@b2bportal/air-booking-api";
import clsx from "clsx";

import {
  AgentFeeValue,
  DisruptionOfferQuote,
  DisruptionProductType,
  FlightSummaryInfo,
  TagColors,
} from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";

import { B2BAgentFeeInput } from "../B2BAgentFeeInput";
import { ButtonWrap } from "../ButtonWrap";
import { IconComponent } from "../IconComponent";
import { InfoCard } from "../InfoCard";
import { MobileFlightSummaryRowNew } from "../MobileFlightSummaryRowNew";
import { StatusTag } from "../StatusTag";
import { IconName } from "../Icon";
import { FlightSummaryPanel } from "../FlightSummaryPanel";
import { AirlineIcon } from "../AirlineIcon";
import "./styles.scss";
import { Slot } from "../Slots";

export interface IFlightConfirmationProps {
  className?: string;
  statusLabel?: string;
  title: string;
  subtitle: string | JSX.Element;
  nextHeader: string;
  outgoing?: ConfirmedFlightSummary;
  returning?: ConfirmedFlightSummary;
  outgoingFlightSummaryInfo?: FlightSummaryInfo;
  returningFlightSummaryInfo?: FlightSummaryInfo;
  infoCardTitles?: InfoCardTitles;
  onHotelsClick?: () => void;
  onCarsClick?: () => void;
  isMobile?: boolean;
  pdpButtonProps?: IPDPButtonProps;
  monitoringDuration?: number;
  isAgentPortal?: boolean;
  agentFeeOnClick?: (value: AgentFeeValue) => void;
  seats?: Seat[];
  assets?: Record<string, any>;
  hasPurchasedAirCfar?: boolean;
  hasPurchasedDisruption?: boolean;
  hasPurchasedAirChfar?: boolean;
  purchasedDisruptionProducts?: DisruptionOfferQuote[] | undefined;
  headerImage?: string;
  bannerCopy?: string;
  earnString?: string;
  expandIcon?: IconName;
  openFlightDetailsModal?: (type: string | null) => void;
  getCustomDate?: (date: string) => string | JSX.Element;
  getCustomTime?: (departure: string, arrival: string) => string | JSX.Element;
}

export interface IPDPButtonProps {
  isPDPEligible?: boolean;
  onPDPClick?: () => void;
  pdpButtonAriaLabelledBy?: string;
}

export interface InfoCardTitles {
  hotelsTitle: string;
  hotelsDescription: string;
  carsTitle: string;
  carsDescription: string;
}

export interface ConfirmedFlightSummary {
  className?: string;
  airlineCode: string;
  formattedDepartureDescription: string; // e.g.: 'to Vancouver (YVR) on Mon, Nov 17'
  formattedDepartureTime: string; // e.g.: '6:30 AM'
  formattedArrivalTime: string; // e.g.: '6:30 PM'
  airline: string;
  duration: string;
  stopString: string;
  tripCategory: string;
  plusDays?: number;
  isMobile?: boolean;
}

interface IAdditionalProductProps {
  title: string;
  subtitle: string;
}

const AdditionalProduct = ({ title, subtitle }: IAdditionalProductProps) => (
  <div className="additional-selection-wrapper">
    <IconComponent name={IconName.CheckCircle} />
    <div className="additional-selection-text">
      <p className="additional-selection-title">{title}</p>
      <p className="additional-selection-subtitle">{subtitle}</p>
    </div>
  </div>
);

interface IAdditionalPurchasesProps {
  hasPurchasedSeats: boolean;
  hasPurchasedAirCfar: boolean;
  hasPurchasedAirChfar: boolean;
  hasPurchasedDisruption: boolean;
  purchasedDisruptionProducts: DisruptionOfferQuote[];
}

const AdditionalPurchases = ({
  hasPurchasedSeats,
  hasPurchasedAirCfar,
  hasPurchasedAirChfar,
  hasPurchasedDisruption,
  purchasedDisruptionProducts,
}: IAdditionalPurchasesProps) => {
  const { t } = useI18nContext();
  return (
    <div className="additional-purchases-container">
      <p className="additional-purchases-header">
        {t("bookingConfirmation.youAlsoPurchasedHeader")}:
      </p>
      {hasPurchasedSeats ? (
        <AdditionalProduct
          title={t("bookingConfirmation.seatsTitle")}
          subtitle={t("bookingConfirmation.seatsSubtitle")}
        />
      ) : null}
      {hasPurchasedAirCfar ? (
        <AdditionalProduct
          title={t("bookingConfirmation.airCfarTitle")}
          subtitle={t("bookingConfirmation.airCfarSubtitle")}
        />
      ) : null}
      {hasPurchasedAirChfar ? (
        <AdditionalProduct
          title={t("bookingConfirmation.airChfarTitle")}
          subtitle={t("bookingConfirmation.airChfarSubtitle")}
        />
      ) : null}
      {hasPurchasedDisruption && purchasedDisruptionProducts ? (
        <>
          {purchasedDisruptionProducts.map((product) => {
            if (product.productType === DisruptionProductType.ScheduleChange) {
              return (
                <AdditionalProduct
                  title={t("bookingConfirmation.disruptionScheduleChangeTitle")}
                  subtitle={t(
                    "bookingConfirmation.disruptionScheduleChangeSubtitle"
                  )}
                />
              );
            } else {
              return (
                <AdditionalProduct
                  title={t(
                    "bookingConfirmation.disruptionMissedConnectionTitle"
                  )}
                  subtitle={t(
                    "bookingConfirmation.disruptionMissedConnectionSubtitle"
                  )}
                />
              );
            }
          })}
        </>
      ) : null}
    </div>
  );
};

export const FlightConfirmation = (
  props: IFlightConfirmationProps
): JSX.Element => {
  const {
    className,
    statusLabel,
    title,
    subtitle,
    nextHeader,
    infoCardTitles,
    onHotelsClick,
    onCarsClick,
    outgoing,
    returning,
    outgoingFlightSummaryInfo,
    returningFlightSummaryInfo,
    isMobile,
    pdpButtonProps,
    monitoringDuration,
    isAgentPortal,
    agentFeeOnClick,
    seats,
    assets,
    hasPurchasedAirCfar,
    hasPurchasedDisruption,
    hasPurchasedAirChfar,
    purchasedDisruptionProducts,
    headerImage,
    bannerCopy,
    earnString,
    expandIcon,
    openFlightDetailsModal,
    getCustomDate,
    getCustomTime,
  } = props;
  const { t } = useI18nContext();
  const { isPDPEligible, onPDPClick, pdpButtonAriaLabelledBy } =
    pdpButtonProps ?? {};

  const hasPurchasedSeats = seats && seats.length > 0;

  const hasPurchasedAdditionalProducts =
    hasPurchasedSeats || hasPurchasedAirCfar || hasPurchasedDisruption;

  return (
    <div
      className={clsx(className, "flight-confirmation-root", {
        mobile: isMobile,
      })}
    >
      <div className="flight-confirmation-container">
        <div className="flight-confirmation-header">
          <div className="flight-confirmation-banner-container">
            {headerImage ? (
              <img
                className="flight-confirmation-header-image"
                src={headerImage}
                alt=""
              />
            ) : (
              <div className="check-mark-icon">
                <IconComponent
                  ariaLabel="Checkmark"
                  name={IconName.Checkmark}
                />
              </div>
            )}
            {bannerCopy ? (
              <Typography variant="h4" className="flight-confirmation-banner">
                {bannerCopy}
              </Typography>
            ) : null}
          </div>
          <div className="confirmation-title-section">
            <h2 className="flight-confirmation-title">
              {earnString && (
                <>
                  <span dangerouslySetInnerHTML={{ __html: earnString }}></span>{" "}
                </>
              )}
              {title}
            </h2>
            <p className="flight-confirmation-subtitle">{subtitle}</p>
          </div>
        </div>

        {statusLabel ? (
          <div className="flight-confirmation-status">
            <StatusTag
              className="flight-confirmation-status-tag"
              tagInfo={{
                label: statusLabel,
                type: TagColors.GREEN,
              }}
            />
          </div>
        ) : null}
        <Slot id="flight-confirmation-confirmation-numbers-container" />
        <Divider className="flight-confirmation-divider flight-details-top" />
        <div className="flight-content-container flight-confirmation-details">
          {outgoing ? (
            <Box className={clsx("flight-details", { "one-way": !returning })}>
              <FlightSummaryPanel
                {...outgoing}
                className={clsx("outgoing-flight")}
              />
              {returning && (
                <FlightSummaryPanel
                  {...returning}
                  className={clsx("returning-flight")}
                />
              )}
            </Box>
          ) : (
            <div className="flight-confirmation-flights">
              <MobileFlightSummaryRowNew
                flightSummaryInfo={outgoingFlightSummaryInfo}
                iconSrc={assets ? assets["airplaneDepart"] : null}
                customIcon={
                  assets ? null : (
                    <AirlineIcon
                      airlineCode={outgoingFlightSummaryInfo?.airlineCode}
                      size="small"
                    />
                  )
                }
                renderAirlineIcon={!!assets}
                onClick={
                  openFlightDetailsModal
                    ? () => openFlightDetailsModal("departure")
                    : null
                }
                customExpandIcon={expandIcon}
                customDate={
                  getCustomDate
                    ? getCustomDate(outgoingFlightSummaryInfo.departure)
                    : null
                }
                customTime={
                  getCustomTime
                    ? getCustomTime(
                        outgoingFlightSummaryInfo.departure,
                        outgoingFlightSummaryInfo.arrival
                      )
                    : null
                }
              />
              <Divider className="flight-confirmation-divider flight-details-middle" />
              {returningFlightSummaryInfo ? (
                <MobileFlightSummaryRowNew
                  flightSummaryInfo={returningFlightSummaryInfo}
                  iconSrc={assets ? assets["airplaneArrive"] : null}
                  customIcon={
                    assets ? null : (
                      <AirlineIcon
                        airlineCode={returningFlightSummaryInfo?.airlineCode}
                        size="small"
                      />
                    )
                  }
                  renderAirlineIcon={!!assets}
                  onClick={
                    openFlightDetailsModal
                      ? () => openFlightDetailsModal("return")
                      : null
                  }
                  customExpandIcon={expandIcon}
                  customDate={
                    getCustomDate
                      ? getCustomDate(returningFlightSummaryInfo.departure)
                      : null
                  }
                  customTime={
                    getCustomTime
                      ? getCustomTime(
                          returningFlightSummaryInfo.departure,
                          returningFlightSummaryInfo.arrival
                        )
                      : null
                  }
                />
              ) : null}
            </div>
          )}
          {pdpButtonProps && isPDPEligible && (
            <Box className="pdp-container">
              <Typography className="subtitle" variant="subtitle2">
                <>
                  {t("bookingConfirmation.flightPriceMonitoring", {
                    duration: monitoringDuration,
                  })}{" "}
                  <ButtonWrap
                    onClick={onPDPClick}
                    className="pdp-modal-link"
                    aria-labelledby={pdpButtonAriaLabelledBy}
                  >
                    <strong>{t("priceForecast.recommendedInfoLabel")} </strong>
                    <span className="pdp-modal-icon">
                      <IconComponent name={IconName.InfoCircle} />
                    </span>
                  </ButtonWrap>
                </>
              </Typography>
            </Box>
          )}
        </div>
        <Divider className="flight-confirmation-divider flight-details-bottom" />
        {hasPurchasedAdditionalProducts ? (
          <AdditionalPurchases
            hasPurchasedSeats={hasPurchasedSeats}
            hasPurchasedAirCfar={hasPurchasedAirCfar}
            hasPurchasedAirChfar={hasPurchasedAirChfar}
            hasPurchasedDisruption={hasPurchasedDisruption}
            purchasedDisruptionProducts={purchasedDisruptionProducts}
          />
        ) : null}
        {!isAgentPortal && infoCardTitles ? (
          <Box className="what-is-next-section">
            <Typography variant="h6" className="next-header">
              {nextHeader}
            </Typography>
            <Box className="info-cards">
              <InfoCard
                title={infoCardTitles.hotelsTitle}
                subtitle={infoCardTitles.hotelsDescription}
                onClick={onHotelsClick}
              />
              <InfoCard
                title={infoCardTitles.carsTitle}
                subtitle={infoCardTitles.carsDescription}
                onClick={onCarsClick}
              />
            </Box>
          </Box>
        ) : null}
        {isAgentPortal && agentFeeOnClick && (
          <Box className="agent-fee-section">
            <B2BAgentFeeInput onClick={agentFeeOnClick} isMobile={isMobile} />
          </Box>
        )}
      </div>
    </div>
  );
};
