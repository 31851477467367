import { IconComponent, IconName } from "@hopper-b2b/ui";
import { Chip } from "@material-ui/core";
import styles from "./HotelLoyaltyChip.module.scss";
import { useI18nContext } from "@hopper-b2b/i18n";

export type HotelLoyaltyChipProps = {
  loyaltyCategory?: string;
};

export const HotelLoyaltyChip = ({
  loyaltyCategory,
}: HotelLoyaltyChipProps): React.ReactElement | null => {
  const { t } = useI18nContext();

  if (!loyaltyCategory) {
    return null;
  }

  return (
    <Chip
      className={styles.hotelLoyaltyChip}
      icon={<IconComponent className={styles.icon} name={IconName.StarEmpty} />}
      label={t("earnHotelLoyalty")}
    />
  );
};
