import {
  BookedFlightItineraryWithDepartureTime,
  PaymentLineItemEnum,
} from "@b2bportal/air-booking-api";
import { HotelItinerarySummary } from "@b2bportal/lodging-api";
import {
  CarReservation,
  IPaymentAmountInfo,
  ItineraryEnum,
  ItineraryWithType,
  PaymentTypeEnum,
  SummaryLineItemBaseEnum,
} from "@hopper-b2b/types";

export const addFlightType = (
  flight: BookedFlightItineraryWithDepartureTime
): ItineraryWithType => ({ ...flight, type: ItineraryEnum.Flight });

export const addGroundType = (
  reservation: CarReservation
): ItineraryWithType => ({ ...reservation, type: ItineraryEnum.Car });

export const addHotelType = (
  reservation: HotelItinerarySummary
): ItineraryWithType => ({ ...reservation, type: ItineraryEnum.Hotel });

export interface ILabel {
  cardLabel: string | null;
  rewardLabel: string | null;
}
export interface IValues {
  cardValue: number | null;
  rewardValue: number | null;
}

export interface ILineItem {
  PaymentLineItem: PaymentLineItemEnum;
  label: string;
  value: number;
  currency: string;
  type?: SummaryLineItemBaseEnum;
}

export const getLabels = (paymentAmountInfo: IPaymentAmountInfo) => {
  const labels: ILabel = {
    cardLabel: null,
    rewardLabel: null,
  };
  switch (paymentAmountInfo?.PaymentAmountInfo) {
    case PaymentTypeEnum.FiatAmountInfo:
      labels.cardLabel = paymentAmountInfo.numberDisplay;
      break;
    case PaymentTypeEnum.SplitAmountInfo:
      labels.cardLabel = paymentAmountInfo.fiatInfo.numberDisplay;
      labels.rewardLabel = paymentAmountInfo.rewardsInfo.accountDisplayName;
      break;
    case PaymentTypeEnum.RewardsAmountInfo:
      labels.rewardLabel = paymentAmountInfo.accountDisplayName;
      break;
  }
  return labels;
};

export const getValues = (paymentAmountInfo: IPaymentAmountInfo) => {
  const values: IValues = {
    cardValue: null,
    rewardValue: null,
  };
  switch (paymentAmountInfo?.PaymentAmountInfo) {
    case PaymentTypeEnum.FiatAmountInfo:
      values.cardValue = paymentAmountInfo.amount.amount;
      break;
    case PaymentTypeEnum.SplitAmountInfo:
      values.cardValue = paymentAmountInfo.fiatInfo.amount.amount;
      values.rewardValue =
        paymentAmountInfo.rewardsInfo.amount.rewardsPrice.value;
      break;
    case PaymentTypeEnum.RewardsAmountInfo:
      values.rewardValue = paymentAmountInfo.amount.rewardsPrice.value;
      break;
  }
  return values;
};
