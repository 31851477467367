import { ApacIconComponent, ApacIconName } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import "./styles.scss";

export const ComingSoon = () => {
  const { t } = useI18nContext();
  return (
    <div className={clsx("cba-coming-soon-content")}>
      <ApacIconComponent
        className="coming-soon-icon"
        name={ApacIconName.CBACalendar}
      />
      <Typography variant="h2">{t("commBank.comingSoon")}</Typography>
      <Typography variant="body1">
        {t("commBank.comingSoonSubtitle")}
      </Typography>
    </div>
  );
};

export default ComingSoon;
