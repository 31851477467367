import { Passport } from "@b2bportal/air-booking-api";
import { useI18nContext, useI18nCountry } from "@hopper-b2b/i18n";
import { INationality } from "@hopper-b2b/types";
import { parseDayMonthAndYear } from "@hopper-b2b/utilities";
import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ApacSelect } from "../../../ApacSelect";
import { ApacTextField } from "../../../ApacTextField";
import { InputWrapper } from "../../../InputWrapper";
import { dateInput } from "../TravelerInfoForm";
import "./PassportSection.styles.scss";
import { useMemo } from "react";
import emojiFlags from "emoji-flags";

interface PassportSectionProps {
  isMobile: boolean;
  className?: string;
  titles?: { passportSectionTitle?: string; passportSectionSubtitle?: string };
  passport: Passport;
  setPassport: React.Dispatch<React.SetStateAction<Passport>>;
  nationality: INationality;
  setNationality: React.Dispatch<React.SetStateAction<INationality>>;
  issueCountryError?: string;
  passportExpiryError?: string;
  passportNumberError?: string;
}

export const PassportSection = (props: PassportSectionProps) => {
  const {
    titles,
    passport,
    nationality,
    setPassport,
    setNationality,
    issueCountryError,
    passportExpiryError,
    passportNumberError,
  } = props;

  const { t } = useI18nContext();
  const { getAllCountryNames } = useI18nCountry();

  const onPassportExpiryChanged = (passportExpiry: string) => {
    setPassport({
      ...passport,
      expiration: formatInputDate(passportExpiry),
    });
  };

  const formatInputDate = (date: string) => {
    const { month, day, year, strLength } = parseDayMonthAndYear(date);
    return (
      day +
      (strLength > 2 ? "/" : "") +
      month +
      (strLength > 4 ? "/" : "") +
      year
    );
  };

  const countryOptions = useMemo(
    () =>
      Array.from(Object.entries(getAllCountryNames())).map(([code, label]) => {
        return {
          label,
          value: code,
          icon: (
            <span className="checkout-country-option-flag-icon">
              {emojiFlags.countryCode(code).emoji}
            </span>
          ),
        };
      }),
    [getAllCountryNames]
  );

  return (
    <div className="passport-section-container">
      <div className="traveler-info-description">
        <Typography variant="body2">
          {titles?.passportSectionSubtitle ||
            t("passengerInformation.passportSectionFlightSubtitle")}
        </Typography>
      </div>
      <form className={clsx("passport-info-form", "traveler-info-form")}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <InputWrapper label={t("nationality")} error="">
              <ApacSelect
                className="traveler-select"
                value={nationality?.country}
                options={countryOptions}
                onChange={(event) => {
                  setNationality({ country: event.target.value });
                }}
                placeholder={t("choose")}
              />
            </InputWrapper>
          </Grid>
          <Grid item md={6} xs={12}>
            <InputWrapper label={t("countryOfIssue")} error={issueCountryError}>
              <ApacSelect
                className="traveler-select"
                value={passport.countryOfIssue}
                options={countryOptions}
                onChange={(event) => {
                  setPassport({
                    ...passport,
                    countryOfIssue: event.target.value,
                  });
                }}
                placeholder={t("choose")}
              />
            </InputWrapper>
          </Grid>
          <Grid item md={6} xs={12}>
            <InputWrapper
              label={t("passportNumber")}
              error={passportNumberError}
            >
              <ApacTextField
                className={clsx("traveler-info-field", {
                  error: !!passportNumberError,
                })}
                value={passport.number}
                onChange={(event) => {
                  setPassport({
                    ...passport,
                    number: event.target.value,
                  });
                }}
                id="passport-number-input"
                InputProps={{ id: "passport-number-input" }}
              />
            </InputWrapper>
          </Grid>
          <Grid item md={6} xs={12}>
            {dateInput({
              value: passport.expiration,
              wrapperLabel: t("passengerInformation.dateOfExpiry"),
              wrapperError: passportExpiryError,
              onChange: (event) => onPassportExpiryChanged(event.target.value),
            })}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
