import { useI18nContext } from "@hopper-b2b/i18n";
import {
  AlgomerchTag,
  IFlightGridFareInfo,
  mapI18nAlgomerchText,
} from "@hopper-b2b/types";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { Slot } from "../Slots";
import { Tag } from "../Tag";
import styles from "./FareCard.module.scss";

export interface IFareCardBaseProps {
  className?: string;
  type: "content" | "skeleton";
}

export interface IFareCardContentProps extends IFareCardBaseProps {
  fare?: IFlightGridFareInfo;
  isRoundTrip: boolean;
  reward?: string;
  tagsLimit?: number;
  disabled?: boolean;
  onClick?: () => void;
  algomerchModalOpen?: boolean;
  onAlgomerchInfoClick?: (label: string) => void;
  isExpanded?: boolean;
  type: "content";
  selectedFare: string;
}

export interface IFareCardSkeletonProps extends IFareCardBaseProps {
  type: "skeleton";
}

export const FareCardContent = ({
  fare,
  isRoundTrip,
  tagsLimit,
  className,
  disabled,
  onClick,
  onAlgomerchInfoClick,
  algomerchModalOpen,
  isExpanded,
  selectedFare,
}: IFareCardContentProps) => {
  const { t, formatFiatCurrency, brand } = useI18nContext();

  const tagSeparator = ", ";
  const title = isRoundTrip
    ? t("flightRoundTripPricing")
    : t("flightOneWayPricing");

  const id = `fare-card${Math.trunc(Math.random() * 10 ** 10)}`;
  const fareClass = fare?.fareName;
  const reward = fare?.reward;

  const formattedPrice = formatFiatCurrency(
    fare?.fiatPrice || { value: 0, currencyCode: brand?.currency?.code },
    {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }
  );

  const tagText = useMemo(() => {
    const filteredTags = fare?.tags
      ?.filter((tag: AlgomerchTag) => tag !== AlgomerchTag.Fastest)
      .map((tag: AlgomerchTag) => t(mapI18nAlgomerchText[tag]))
      .slice(0, tagsLimit ? tagsLimit : fare.tags.length);
    return filteredTags?.join(tagSeparator);
  }, [fare?.tags, tagsLimit, t]);

  const handleAlgomerchInfoClick = useCallback(() => {
    if (tagText && onAlgomerchInfoClick) {
      onAlgomerchInfoClick(tagText);
    }
  }, [onAlgomerchInfoClick, tagText]);

  return (
    <Button
      id={id}
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      className={clsx(className, styles.card, {
        activeFare: selectedFare === fare.fareId && isExpanded,
      })}
      aria-expanded={isExpanded}
    >
      <Grid
        container
        direction="column"
        spacing={2}
        wrap="nowrap"
        style={{ height: "100%" }}
      >
        <Grid item xs />

        <Grid item>
          <Slot id="fare-card-header" fareId={fare.fareId} />
        </Grid>

        <Grid item>
          <Typography variant="body1">{title}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">{formattedPrice}</Typography>
          {reward ? (
            <Typography variant="h5" className="grid-fare-reward-price">
              {reward}
            </Typography>
          ) : null}
        </Grid>
        <Grid item>
          <Typography variant="body1">{fareClass}</Typography>
        </Grid>

        {tagText ? (
          <Grid item className="tag-container">
            <Box display="flex" justifyContent="center">
              <Tag
                className={styles.tag}
                label={tagText}
                expanded={algomerchModalOpen}
                onClick={handleAlgomerchInfoClick}
              />
            </Box>
          </Grid>
        ) : null}

        <Grid item xs />
      </Grid>
    </Button>
  );
};

export const FareCardSkeleton = ({ className }: IFareCardSkeletonProps) => {
  return (
    <Box className={clsx("fare-card", className)}>
      <Skeleton className="title" />

      <Box className="price-and-reward-section-skeleton">
        <Skeleton className="price" />
        <Skeleton className="reward" />
      </Box>

      <Box className="fare-tag-container">
        <Skeleton className={clsx("fare-card-tag", className)} />
        <Skeleton className="fare-class" />
      </Box>
    </Box>
  );
};

export type IFareCardProps = IFareCardContentProps | IFareCardSkeletonProps;

export const FareCard = (props: IFareCardProps) => {
  switch (props.type) {
    case "content":
      return <FareCardContent {...props} />;
    case "skeleton":
      return <FareCardSkeleton {...props} />;
    default:
      return null;
  }
};
