import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ITimeGrouping,
  ScheduleChangeUserActionEnum,
  TimeGroupingEnum,
} from "@hopper-b2b/types";
import { ActionButton } from "@hopper-b2b/ui-core";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import "./styles.scss";

interface IScheduleChangeFormTitles {
  headingCopy: string | JSX.Element;
  subheadingCopy?: string;
  acceptButtonTitle: string;
  acceptDetails: string;
  denyButtonTitle: string;
  denyDetails: string;
  outboundTimePreferenceCopy: string | JSX.Element;
  returnTimePreferenceCopy: string | JSX.Element;
}
export interface IScheduleChangeFormProps {
  isMobile?: boolean;
  outboundChanged?: boolean;
  returnChanged?: boolean;
  titles: IScheduleChangeFormTitles;
  timeGroupings: ITimeGrouping[];
  onClick: (
    scheduleChangeUserAction: ScheduleChangeUserActionEnum | null,
    outboundTimes: TimeGroupingEnum[],
    inboundTimes: TimeGroupingEnum[]
  ) => void;
}

const ScheduleChangeTimePreference = ({
  timeGroupings,
  times,
  setTimes,
}: {
  times: TimeGroupingEnum[];
  setTimes: (times: TimeGroupingEnum[]) => void;
  timeGroupings: ITimeGrouping[];
}): React.ReactElement => (
  <>
    {timeGroupings.map(({ grouping, label, sublabel }) => {
      const selectTime = () => {
        const addTime = () => setTimes([...times, grouping]);
        const removeTime = () => setTimes(times.filter((t) => t !== grouping));

        times.includes(grouping) ? removeTime() : addTime();
      };

      return (
        <Grid className="time-preference-option" item md={3} sm={12}>
          <FormControlLabel
            key={grouping}
            className="time-preference-checkbox-container"
            control={
              <Checkbox
                className="time-preference-checkbox"
                checked={times.includes(grouping)}
                onChange={selectTime}
                name={label}
              />
            }
            label={
              <>
                <Typography className="time-preference-label" variant="body1">
                  {label}
                </Typography>
                <Typography
                  className="time-preference-sublabel"
                  variant="body2"
                >
                  {sublabel}
                </Typography>
              </>
            }
          />
        </Grid>
      );
    })}
  </>
);

export const ScheduleChangeForm = ({
  isMobile,
  outboundChanged,
  returnChanged,
  titles: {
    headingCopy,
    subheadingCopy,
    acceptButtonTitle,
    acceptDetails,
    denyButtonTitle,
    denyDetails,
  },
  timeGroupings,
  onClick,
}: IScheduleChangeFormProps): React.ReactElement => {
  const { t } = useI18nContext();
  const [scheduleChangeUserAction, setScheduleChangeUserAction] =
    React.useState<ScheduleChangeUserActionEnum | null>(
      ScheduleChangeUserActionEnum.Accept
    );
  const [outboundTimes, setOutboundTimes] = React.useState<TimeGroupingEnum[]>(
    []
  );
  const [returnTimes, setReturnTimes] = React.useState<TimeGroupingEnum[]>([]);

  const hasAccepted =
    scheduleChangeUserAction === ScheduleChangeUserActionEnum.Accept;
  const hasRejected =
    scheduleChangeUserAction === ScheduleChangeUserActionEnum.Deny;

  const invalidDenyOutbound = outboundChanged && !outboundTimes.length;
  const invalidDenyReturn = returnChanged && !returnTimes.length;

  const continueDisabled =
    hasRejected && (invalidDenyOutbound || invalidDenyReturn);

  return (
    <Box className={clsx("schedule-change-form", { mobile: isMobile })}>
      <Typography gutterBottom variant="h2" className="heading-copy">
        {headingCopy}
      </Typography>
      {subheadingCopy && (
        <Typography variant="body2">{subheadingCopy}</Typography>
      )}

      <Grid
        container
        className={"schedule-change-form-radios"}
        direction="row"
        spacing={3}
      >
        <Grid item md={6} sm={12}>
          <Link
            component="button"
            role="radio"
            aria-checked={hasAccepted}
            aria-label={acceptButtonTitle}
            className={clsx("radio-container", { selected: hasAccepted })}
            onClick={() =>
              setScheduleChangeUserAction(ScheduleChangeUserActionEnum.Accept)
            }
          >
            <Radio
              checked={hasAccepted}
              aria-selected={hasAccepted}
              onChange={() =>
                setScheduleChangeUserAction(ScheduleChangeUserActionEnum.Accept)
              }
            />
            <Box className={"radio-details"}>
              <Typography variant="h6">{acceptButtonTitle}</Typography>
              <Typography variant="body2">{acceptDetails}</Typography>
            </Box>
          </Link>
        </Grid>
        <Grid item md={6} sm={12}>
          <Link
            component="button"
            role="radio"
            aria-checked={hasRejected}
            aria-label={denyButtonTitle}
            className={clsx("radio-container", { selected: hasRejected })}
            onClick={() =>
              setScheduleChangeUserAction(ScheduleChangeUserActionEnum.Deny)
            }
          >
            <Radio
              checked={hasRejected}
              aria-selected={hasRejected}
              onChange={() =>
                setScheduleChangeUserAction(ScheduleChangeUserActionEnum.Deny)
              }
            />
            <Box className={"radio-details"}>
              <Typography variant="h6">{denyButtonTitle}</Typography>
              <Typography variant="body2">{denyDetails}</Typography>
            </Box>
          </Link>
        </Grid>
      </Grid>
      {hasRejected && (
        <Box className="deny-schedule-change-form">
          {outboundChanged && (
            <Box className="time-preference-selection">
              <Typography className="time-preference-row-label" variant="h2">
                {t("scheduleChangeFormTitles.timePreference", {
                  flightType: "outbound",
                })}
              </Typography>
              <Grid
                container
                className="time-preference-row"
                direction="row"
                spacing={2}
              >
                <ScheduleChangeTimePreference
                  timeGroupings={timeGroupings}
                  times={outboundTimes}
                  setTimes={setOutboundTimes}
                />
              </Grid>
            </Box>
          )}
          {returnChanged && (
            <Box className={"time-preference-selection"}>
              <Typography className="time-preference-row-label" variant="h2">
                {t("scheduleChangeFormTitles.timePreference", {
                  flightType: "return",
                })}
              </Typography>
              <Grid
                container
                className="time-preference-row"
                direction="row"
                spacing={2}
              >
                <ScheduleChangeTimePreference
                  timeGroupings={timeGroupings}
                  times={returnTimes}
                  setTimes={setReturnTimes}
                />
              </Grid>
            </Box>
          )}
        </Box>
      )}
      <ActionButton
        defaultStyle="h4r-primary"
        disabled={continueDisabled}
        message="Continue"
        onClick={() =>
          onClick(scheduleChangeUserAction, outboundTimes, returnTimes)
        }
      />
    </Box>
  );
};
