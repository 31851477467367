import { Box, Dialog, Typography } from "@material-ui/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import clsx from "clsx";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { ActionLink } from "../ActionLink";
import { LoadingBunny } from "../LoadingBunny";
import { Spinner } from "../Spinner";
import "./styles.scss";
import { ClientName } from "@hopper-b2b/types";

export interface IB2BLoadingPopupProps {
  open: boolean;
  message?: string;
  secondaryMessage?: string;
  loadingIcon?: React.ReactNode;
  className?: string;
  popupSize?: "mobile" | "desktop";
  onClose?: () => void;
  fullScreen?: boolean;
  image?: string;
  averageLoadingTime?: number;
}

const B2BLoadingPopup = ({
  open,
  message,
  secondaryMessage,
  loadingIcon,
  className,
  popupSize = "desktop",
  image,
  onClose,
  fullScreen,
}: IB2BLoadingPopupProps) => {
  const renderSpinner = [
    ClientName.UBER,
    ClientName.NUBANK,
    ClientName.HYATT,
    ClientName.MARRIOTT,
  ].includes(getEnvVariables("clientName") as ClientName);

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      className={clsx(popupSize, className, "b2b-loading-popup")}
    >
      <Box className={clsx("b2b-loading-popup-content-wrapper")}>
        <Box className="b2b-loading-popup-content">
          {loadingIcon ? (
            loadingIcon
          ) : renderSpinner ? (
            <Spinner />
          ) : (
            <LoadingBunny classes={["b2bLoadingBunny"]} />
          )}
          <Typography variant="h2" className="loading-title">
            <span className="font-regular">
              {message ? message : "Searching... "}
            </span>
          </Typography>
          {secondaryMessage && (
            <Typography variant="body2" className="loading-subtitle">
              {secondaryMessage}
            </Typography>
          )}
          {onClose && (
            <ActionLink
              label={`Return to search`}
              className={"b2b-loading-popup-close"}
              onClick={() => onClose()}
              content={
                <Box className="button-content-wrapper">
                  <FontAwesomeIcon
                    icon={faTimes as IconProp}
                    className={clsx("b2b-loading-popup-close-icon")}
                  />
                </Box>
              }
            />
          )}
        </Box>
        {image && (
          <Box className="b2b-loading-popup-right-content">
            <img alt="searching hero" src={image} />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default B2BLoadingPopup;
