import { useEffect, useRef, MutableRefObject } from "react";

export function useClickOutside<T extends Node>(
  callback: (e: MouseEvent) => void,
  existingRef?: MutableRefObject<T>
) {
  const ref = useRef<T>(null);

  useEffect(() => {
    const attachedRef = existingRef || ref;
    const onDocumentClick = (e: MouseEvent) => {
      if (
        attachedRef.current &&
        !attachedRef.current.contains(e.target as Node)
      ) {
        callback(e);
      }
    };
    document.addEventListener("click", onDocumentClick);
    return () => {
      document.removeEventListener("click", onDocumentClick);
    };
  }, [callback]);
  return existingRef || ref;
}
