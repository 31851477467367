import { AgentFeeValue } from "@hopper-b2b/types";
import { ActionButton } from "@hopper-b2b/ui-core";
import { twoDecimalFormatter } from "@hopper-b2b/utilities";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import { useMemo, useState } from "react";
import { IOption, SelectInput } from "../SelectInput";
import { DEFAULT_AGENT_FEE, FEE_CHARGED_IN_REWARDS } from "./constants";

import "./styles.scss";

const defaultGetLabelText = (value: number | undefined = DEFAULT_AGENT_FEE) =>
  `Charge $${twoDecimalFormatter(
    value
  )} booking fee? If no, specify a reason and click confirm`;
const defaultGetSubtitleText = (
  value: number | undefined = DEFAULT_AGENT_FEE
) => `${FEE_CHARGED_IN_REWARDS} (${(value * 100).toLocaleString()} miles).`;
const DEFAULT_SELECT_INPUT_LABEL = "If No, select a reason";
const YES_OPTION = "Yes";
const NO_OPTION = "No";
const CHARGE_BUTTON = "Confirm";

const defaultOptions: IOption[] = [
  { value: "Premium cardholder", label: "Premium cardholder" },
  { value: "Poor cust. experience", label: "Poor cust. experience" },
  { value: "Multi-city request", label: "Multi-city request" },
  { value: "More than 6 passengers", label: "More than 6 passengers" },
  { value: "Portal issue - name/email", label: "Portal issue - name/email" },
  {
    value: "Portal issue - payment issue",
    label: "Portal issue - payment issue",
  },
  {
    value: "Portal issue - no confirmation",
    label: "Portal issue - no confirmation",
  },
  {
    value: "Portal error (add Kustomer Notes)",
    label: "Portal error (add Kustomer Notes)",
  },
  { value: "Spanish-speaker", label: "Spanish-speaker" },
  { value: "ADA disclosure", label: "ADA disclosure" },
  { value: "Medical / bereavement", label: "Medical / bereavement" },
  { value: "Fraud block cleared", label: "Fraud block cleared" },
  { value: "Other (add Kustomer Notes)", label: "Other (add Kustomer Notes)" },
];

export interface IB2BTextFieldProps {
  onChange?: (value: AgentFeeValue) => void;
  defaultValue?: AgentFeeValue;
  className?: string;
  getLabelText?: (value: number | undefined) => string;
  getSubtitleText?: (value: number | undefined) => string;
  selectInputLabel?: string;
  options?: IOption[];
  onClick: (value: AgentFeeValue) => void;
  isMobile?: boolean;
}

export const B2BAgentFeeInput = (props: IB2BTextFieldProps): JSX.Element => {
  const {
    getLabelText = defaultGetLabelText,
    getSubtitleText = defaultGetSubtitleText,
    selectInputLabel = DEFAULT_SELECT_INPUT_LABEL,
    defaultValue,
    onChange,
    className,
    options = defaultOptions,
    onClick,
    isMobile,
  } = props;
  const feeLabel = useMemo(() => getLabelText(), []);
  const feeSubtitle = useMemo(() => getSubtitleText(), []);
  const [clicked, setClicked] = useState(false);
  const [value, setValue] = useState(
    defaultValue || { addFee: true, reason: "" }
  );

  const handleOnClick = () => {
    setClicked(true);
    onClick(value);
  };

  const reasonOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setValue({ ...value, reason: event.target.value });
    if (onChange) {
      onChange({ ...value, reason: event.target.value });
    }
  };

  const addFeeOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    event.target.value === YES_OPTION
      ? setValue({ reason: "", addFee: true })
      : setValue({ ...value, addFee: false });
    if (onChange) {
      onChange({ ...value, addFee: event.target.value === YES_OPTION });
    }
  };

  return (
    <Box className={clsx(className, "b2b-agent-fee-container")}>
      <Typography variant="h6" className="agent-fee-header">
        {feeLabel}
      </Typography>
      <Typography variant="subtitle1">{feeSubtitle}</Typography>
      <Box className="radio-button-container">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="add-fee"
            name="addFee"
            value={value.addFee ? YES_OPTION : NO_OPTION}
            onChange={addFeeOnChange}
            row
          >
            <FormControlLabel
              value={YES_OPTION}
              label={YES_OPTION}
              className="radio-option-label"
              control={<Radio />}
            />
            <FormControlLabel
              value={NO_OPTION}
              label={NO_OPTION}
              className="radio-option-label"
              control={<Radio />}
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <SelectInput
        label={selectInputLabel}
        value={value.reason}
        options={options}
        onChange={reasonOnChange}
        disabled={value.addFee}
        className="b2b-agent-fee-reason-select"
      />
      <ActionButton
        onClick={handleOnClick}
        disabled={clicked || (!value.addFee && !value.reason)}
        message={CHARGE_BUTTON}
        className={clsx("b2b-agent-fee-charge-button", { mobile: isMobile })}
      />
    </Box>
  );
};
