import { CircularProgress, MenuItem, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ReactNode, useEffect, useMemo, useState } from "react";

import { EmailType, ProductOpaqueValue } from "@b2bportal/email-api";
import { LodgingCollection } from "@b2bportal/lodging-api";
import { resendEmail, trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  BookingType,
  CallState,
  CONFIRM_RESEND_EMAIL,
  ItineraryEnum,
  ItineraryWithType,
  ResendEmailProperties,
  VIEWED_RESEND_EMAIL,
} from "@hopper-b2b/types";
import { B2BTextField, GenericModalContent, Slot } from "@hopper-b2b/ui";
import { ActionButton } from "@hopper-b2b/ui/core";
import { emailRegex } from "@hopper-b2b/utilities";

import styles from "./styles.module.scss";

enum EmailChoice {
  Cancellation = "Cancellation",
  Confirmation = "Confirmation",
}

interface IResendEmailModalContentProps {
  errorImage?: ReactNode;
  isCancelled?: boolean;
  isMobile?: boolean;
  itinerary: ItineraryWithType;
  onClose: () => void;
  successImage?: ReactNode;
}

export const ResendEmailModalContent = (
  props: IResendEmailModalContentProps
) => {
  const {
    errorImage,
    isCancelled = false,
    isMobile,
    itinerary,
    onClose,
    successImage,
  } = props;
  const { t } = useI18nContext();
  const [email, setEmail] = useState("");
  const [emailChoice, setEmailChoice] = useState(
    isCancelled ? EmailChoice.Cancellation : EmailChoice.Confirmation
  );
  const [reqState, setReqState] = useState(CallState.NotCalled);

  const ModalContent = useMemo(() => {
    switch (reqState) {
      case CallState.NotCalled:
        return renderResendForm();
      case CallState.InProcess:
        return renderResendProcessing();
      case CallState.Failed:
        return renderResendFailed();
      case CallState.Success:
        return renderResendSuccess();
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, emailChoice, reqState]);

  /**
   * @description Forward request to BE to handle sending the email
   */
  async function resendConfirmation() {
    const { type } = itinerary;
    const product: ProductOpaqueValue = {
      type: BookingType.Flight,
      value: "",
    };
    const sendCancellationEmail = emailChoice === EmailChoice.Cancellation;
    let emailType;

    switch (type) {
      case ItineraryEnum.Car: {
        emailType = sendCancellationEmail
          ? EmailType.ground_cancellation
          : EmailType.ground_booking_confirmation;
        product.type = BookingType.Ground;
        product.value = itinerary.bookResult.groundBookingId;
        break;
      }
      case ItineraryEnum.Flight: {
        emailType = sendCancellationEmail
          ? EmailType.flight_cancellation
          : EmailType.flight_whats_next;
        product.type = BookingType.Flight;
        product.value = itinerary.bookedItinerary.id;
        break;
      }
      case ItineraryEnum.Hotel: {
        const { lodgingCollection } = itinerary.reservation;
        const isLuxury = lodgingCollection === LodgingCollection.Premier;

        if (isLuxury) {
          emailType = sendCancellationEmail
            ? EmailType.premier_hotel_cancellation
            : EmailType.premier_hotel_booking_confirmation;
        } else {
          emailType = sendCancellationEmail
            ? EmailType.hotel_cancellation
            : EmailType.hotel_booking_confirmation;
        }

        product.type = BookingType.Lodging;
        product.value = { value: itinerary.reservation.reservationBookingId };
        break;
      }
      default:
    }

    if (email && emailType) {
      setReqState(CallState.InProcess);
      trackEvent({
        eventName: CONFIRM_RESEND_EMAIL,
        properties: {
          screen: itinerary.type,
        } as ResendEmailProperties,
      });

      try {
        await resendEmail({
          emailType,
          product,
          emailAddress: email,
        });

        setReqState(CallState.Success);
      } catch (err) {
        setReqState(CallState.Failed);
      }
    }
  }

  function renderResendForm() {
    return (
      <div
        className={clsx(
          styles.resendEmailFormContainer,
          "resend-email-form-container"
        )}
      >
        <div className={clsx(styles.modalHeader, "modal-header")}>
          <Typography
            className={clsx(styles.modalTitle, "modal-title")}
            variant="h2"
          >
            {t("resendEmailModal.title")}
          </Typography>
          <Typography
            className={clsx(styles.modalSubtitel, "modal-subtitle")}
            variant="subtitle1"
          >
            {t("resendEmailModal.instructions")}
          </Typography>
        </div>
        <div className={clsx(styles.modalBody, "modal-body")}>
          <div className={clsx(styles.resendEmailForm, "resend-email-form")}>
            {/* no need to display select if itinerary hasn't been canceled yet */}
            {isCancelled ? (
              <B2BTextField
                select
                InputProps={{
                  classes: {
                    root: clsx(
                      styles.emailAddressInputRoot,
                      "email-input-root"
                    ),
                  },
                  disableUnderline: true,
                }}
                SelectProps={{
                  classes: {
                    icon: clsx(styles.selectIcon, "select-icon"),
                  },
                }}
                className={clsx(styles.emailTypeSelect, "email-type-select")}
                label={t("resendEmailModal.emailType.label")}
                onChange={(value) => setEmailChoice(value as EmailChoice)}
                value={emailChoice}
              >
                <MenuItem value={EmailChoice.Cancellation}>
                  {t("resendEmailModal.emailType.cancellation")}
                </MenuItem>
                <MenuItem value={EmailChoice.Confirmation}>
                  {t("resendEmailModal.emailType.confirmation")}
                </MenuItem>
              </B2BTextField>
            ) : null}
            <B2BTextField
              InputProps={{
                classes: {
                  root: clsx(styles.emailAddressInputRoot, "email-input-root"),
                },
                disableUnderline: true,
              }}
              className={clsx(styles.emailAddressInput, "email-address-input")}
              label={t("email") || ""}
              onChange={setEmail}
              type="email"
              value={email}
              variant="filled"
            />
          </div>
        </div>
        <div className={clsx(styles.modalFooter, "modal-footer")}>
          <ActionButton
            className={clsx(
              styles.modalButton,
              styles.secondaryButton,
              "modal-button secondary-button cancel"
            )}
            defaultStyle="h4r-secondary"
            message={t("resendEmailModal.cancel")}
            onClick={onClose}
          />
          <ActionButton
            className={clsx(
              styles.modalButton,
              styles.primaryButton,
              "modal-button primary-button submit"
            )}
            defaultStyle="h4r-primary"
            disabled={!emailRegex.test(email)}
            message={t("resendEmailModal.submit")}
            onClick={resendConfirmation}
          />
        </div>
      </div>
    );
  }

  function renderResendProcessing() {
    return (
      <Slot
        id="trips-modal-processing"
        component={
          <GenericModalContent
            className={clsx(styles.resendProcessing, "resend-processing")}
            image={<CircularProgress />}
            subtitle={t("resendEmailModal.processing")}
          />
        }
      />
    );
  }

  function renderResendFailed() {
    return (
      <Slot
        id="trips-error-modal-content"
        subtitle={t("resendEmailModal.failure.subtitle")}
        title={t("resendEmailModal.failure.title")}
        primaryOnClick={onClose}
        primaryButtonText={t("resendEmailModal.cancel")}
        secondaryOnClick={() => setReqState(CallState.NotCalled)}
        secondaryButtonText={t("tryAgain")}
        onBack={onClose}
        className="warn"
        component={
          <GenericModalContent
            actionsClassName={clsx(styles.modalFooter, "modal-footer")}
            className={clsx(
              styles.resendResultContainer,
              "resend-result-container error"
            )}
            actions={
              <>
                <ActionButton
                  className={clsx(
                    styles.modalButton,
                    styles.secondaryButton,
                    "modal-button secondary-button"
                  )}
                  defaultStyle="h4r-secondary"
                  message={t("resendEmailModal.cancel") || ""}
                  onClick={onClose}
                />
                <ActionButton
                  className={clsx(
                    styles.modalButton,
                    styles.primaryButton,
                    "modal-button primary-button"
                  )}
                  defaultStyle="h4r-primary"
                  message={t("tryAgain") || ""}
                  onClick={() => setReqState(CallState.NotCalled)}
                />
              </>
            }
            image={
              typeof errorImage === "string" ? (
                <img
                  alt="error icon"
                  className={clsx(styles.errorImage, "icon error-icon")}
                  src={errorImage}
                />
              ) : (
                errorImage
              )
            }
            subtitle={t("resendEmailModal.failure.subtitle")}
            title={t("resendEmailModal.failure.title")}
          />
        }
      />
    );
  }

  function renderResendSuccess() {
    return (
      <GenericModalContent
        actionsClassName={styles.modalFooter}
        className={clsx(
          styles.resendResultContainer,
          "resend-result-container success"
        )}
        image={
          typeof successImage === "string" ? (
            <img
              alt="success icon"
              className={clsx(styles.errorImage, "icon success-icon")}
              src={successImage}
            />
          ) : (
            successImage
          )
        }
        subtitle={t("resendEmailModal.success.subtitle")}
        title={t("resendEmailModal.success.title")}
      />
    );
  }

  useEffect(() => {
    trackEvent({
      eventName: VIEWED_RESEND_EMAIL,
      properties: {
        screen: itinerary.type,
      } as ResendEmailProperties,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={clsx(
        styles.resendEmailModalContent,
        { [styles.mobile]: isMobile, mobile: isMobile },
        "resend-email-modal-content"
      )}
    >
      {ModalContent}
    </div>
  );
};
