export const emailRegex =
  // eslint-disable-next-line no-useless-escape
  import.meta.env.VITE_EMAIL_REGEX
    ? new RegExp(import.meta.env.VITE_EMAIL_REGEX)
    : // eslint-disable-next-line no-useless-escape
      /^[a-zA-Z0-9]+(?:\.?[a-zA-Z0-9]+)*(?:[_\-]*(?:[a-zA-Z0-9]+\.[a-zA-Z0-9]+)*[a-zA-Z0-9]*)*@[a-zA-Z0-9]+(?:(?:\-*[a-zA-Z0-9]+)*\.[a-zA-Z0-9]+)+$/;
// First part of phone regex uses {2,3} since some phone numbers are starting with a 0 and the google-libphonenumber trims this leading 0
// As an example, a uruguayan phone number can look like (+598)0xxxxxxxx and be parsed to (+598) xxxxxxxx,
// which has 8 number and didn't pass this regex before the change
export const phoneRegex =
  /^[+]?[(]?[0-9]{2,3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/;
export const nameRegex =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð -]+$/;
export const specialCharacterRegex = /^[A-Za-z0-9]+$/;
export const cpfRegex = /^[-0-9.]+$/;
