import { createSelector } from "@reduxjs/toolkit";
import { ILodgingAppState } from "../../../../reducer";
import { groupBy, uniq } from "lodash-es";
import {
  AccountPrice,
  Lodging,
  LodgingMediaAssetEnum,
  MediaCategory,
  NonResizableImage,
  Prices,
  ResizableImage,
  RoomInfoProducts,
} from "@b2bportal/lodging-api";
import { FiatPrice } from "@hopper-b2b/types";
import { getLodgingShopTrackingProperties } from "@checkout/states/Products/Lodging/selectors";
import { getLodgings } from "../../../availability/reducer";

export const getShopCallState = (state: ILodgingAppState) =>
  state.lodgingShop?.shopCallState;

export const getSelectedLodging = (state: ILodgingAppState) =>
  state.lodgingShop?.selectedLodging;

export const getSelectedLodgingId = (state: ILodgingAppState) =>
  state.lodgingShop?.selectedLodging?.lodging.id;

export const getLodgingInListIndex = (
  lodgingId: string,
  lodgings: Lodging[]
): number => {
  let lodging_list_index = lodgings.findIndex(
    (l) => l?.lodging.id === lodgingId
  );
  lodging_list_index = lodging_list_index !== -1 ? lodging_list_index : null;
  return lodging_list_index;
};

export type FiatAndPointPrice = { fiat: FiatPrice; points: AccountPrice };

const getLowestRoomPrice = (room: RoomInfoProducts): Prices => {
  return (room?.products || []).reduce((lowestPrice, product) => {
    return product.total.fiat.value < lowestPrice.fiat.value
      ? product.total
      : lowestPrice;
  }, room?.products?.[0]?.total);
};

const getAccountLowestPoints = (price: Prices): AccountPrice => {
  return Object.values(price.accountSpecific).reduce(
    (lowest: AccountPrice, current: AccountPrice) => {
      return lowest?.value < current.value ? lowest : current;
    },
    undefined
  );
};

export const getSelectedRoomFiatAndPointPrice = (
  state: ILodgingAppState
): FiatAndPointPrice => {
  const { selectedRoom } = state.lodgingShop || {};
  const selectedRate = getSelectedRoomRate(state);
  const price = selectedRate?.total || getLowestRoomPrice(selectedRoom);
  return price
    ? { fiat: price.fiat, points: getAccountLowestPoints(price) }
    : undefined;
};

export const getSelectedLodgingLowestPrice = (
  state: ILodgingAppState
): FiatAndPointPrice => {
  const lowestPrice = (state.lodgingShop?.rooms || []).reduce(
    (lowest: Prices, room: RoomInfoProducts) => {
      const roomLowestPrice = getLowestRoomPrice(room);
      return lowest?.fiat?.value < roomLowestPrice.fiat.value
        ? lowest
        : roomLowestPrice;
    },
    undefined
  );

  return lowestPrice && state.lodgingShop
    ? { fiat: lowestPrice.fiat, points: getAccountLowestPoints(lowestPrice) }
    : undefined;
};

export const getRooms = (state: ILodgingAppState) => state.lodgingShop?.rooms;

export const getRoomsCallState = (state: ILodgingAppState) =>
  state.lodgingShop?.roomsCallState;

export const getSelectedRoom = (state: ILodgingAppState) =>
  state.lodgingShop?.selectedRoom;

export const getSelectedRoomId = (state: ILodgingAppState) =>
  state.lodgingShop?.selectedRoom?.roomInfo?.roomId;

export const getSelectedRoomRateId = (state: ILodgingAppState) =>
  state.lodgingShop?.selectedRoomRateId;

export const getSelectedRoomRate = createSelector(
  getSelectedRoom,
  getSelectedRoomRateId,
  (selectedRoom, selectedRateId) => {
    return selectedRoom?.products?.find(
      (p) => p.rateId.value === selectedRateId
    );
  }
);

// Start of Hotel Hero Gallery Selectors ==================>
export const getSelectedLodgingMedia = createSelector(
  getSelectedLodging,
  (selectedLodging) => selectedLodging?.lodging?.media
);

export const getSelectedLodgingMediaImages = createSelector(
  getSelectedLodging,
  (selectedLodging): Array<NonResizableImage | ResizableImage> =>
    selectedLodging?.lodging?.media.filter(
      (mediaAsset) =>
        mediaAsset.LodgingMediaAsset !== LodgingMediaAssetEnum.Video
    ) || []
);

export const getSelectedLodgingMediaCategories = createSelector(
  getSelectedLodgingMediaImages,
  (images) => uniq(images.map((i) => i.category))
);

export const getSelectedLodgingGroupedMedia = createSelector(
  getSelectedLodgingMediaImages,
  (images) => {
    // Handle categories that don't map to MediaCategory
    const mappedMedia = images.map((m) =>
      MediaCategory[m?.category]
        ? m
        : { ...m, category: MediaCategory.Uncategorized }
    );
    return groupBy(mappedMedia, (media) => media.category);
  }
);
// End of Hotel Hero Gallery Selectors ==================>

export const getCheckInPolicies = (state: ILodgingAppState) =>
  state.lodgingShop?.checkInPolicies;

export const getCheckInInstructions = (state: ILodgingAppState) =>
  state.lodgingShop?.checkInInstructions;

export const getCurrentStep = (state: ILodgingAppState) =>
  state.lodgingShop?.currentStep;

export const getHasSelectedRoom = createSelector(
  getSelectedLodging,
  getSelectedRoom,
  getSelectedRoomRateId,
  (lodging, room, rate) => {
    return !!lodging && !!room && !!rate;
  }
);

export const getSelectedLodgingCoordinatesForStaticMap = createSelector(
  getSelectedLodging,
  (lodging) => {
    const coordinates = lodging?.lodging?.location?.coordinates;
    if (coordinates) {
      return `${coordinates.lat},${coordinates.lon}`;
    }
    return null;
  }
);

export const getSelectedLodgingAmenities = createSelector(
  getSelectedLodging,
  (lodging) => lodging?.lodging?.amenities
);

export const getSelectedLodgingOfferOpaque = (state: ILodgingAppState) =>
  state.lodgingShop?.selectedOfferOpaque;

export const getSelectedLodgingInListIndex = createSelector(
  getSelectedLodgingId,
  getLodgings,
  getLodgingInListIndex
);

export const getHotelShopTrackingProperties = createSelector(
  getSelectedLodging,
  getSelectedLodgingInListIndex,
  getLodgingShopTrackingProperties,
  (lodging, index, trackingProperties) => {
    return { lodging, index, trackingProperties };
  }
);

export const getSelectedRoomTrackingProperties = createSelector(
  getSelectedRoom,
  (selectedRoom) => selectedRoom?.trackingPropertiesV2
);

export const getSelectedRoomRateTrackingProperties = createSelector(
  getSelectedRoomRate,
  (selectedRoomRate) => selectedRoomRate?.trackingPropertiesV2
);
