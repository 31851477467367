import clsx from "clsx";
import { ReactElement, useCallback } from "react";
import { Lodging } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import SelectedCard from "./SelectedCard";
interface IMapMarker {
  lodging: Lodging;
  lat: number;
  lng: number;
  selected: boolean;
  isOveredInLodgingList: boolean;
  onClick?: (id: string) => void;
}

export const ClusterMarker = ({
  cluster,
  lat,
  lng,
  expansionZoom,
  isOveredInLodgingList,
  onClick,
}) => {
  const onClusterClick = useCallback(() => {
    onClick(lat, lng, expansionZoom);
  }, [expansionZoom, lat, lng, onClick]);
  return (
    <button
      type="button"
      className={clsx("cluster-marker", {
        isOveredInLodgingList,
      })}
      onClick={onClusterClick}
    >
      {cluster.properties.point_count} <span>stays</span>
    </button>
  );
};

export const MapMarker = ({
  lodging,
  selected = false,
  isOveredInLodgingList = false,
  onClick,
}: IMapMarker): ReactElement => {
  const onSelect = useCallback(() => {
    onClick(lodging.lodging.id);
  }, [lodging.lodging.id, onClick]);

  const { formatFiatCurrency } = useI18nContext();

  const price = formatFiatCurrency(lodging.price.totalPrice.fiat, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const onClose = useCallback(
    () => onClick(lodging.lodging.id),
    [onClick, lodging]
  );

  return (
    <>
      <button
        type="button"
        className={clsx("marker", {
          selected,
          isOveredInLodgingList,
          unavailable: price.length === 0,
        })}
        onClick={onSelect}
      >
        {price}
      </button>
      {selected ? <SelectedCard lodging={lodging} onClose={onClose} /> : null}
    </>
  );
};
