import { AlgomerchTag, FlightTags, TripDetails } from "@hopper-b2b/types";

import { ITripDetailsByTripId } from "../reducer";
import { FareScore, Tags } from "@b2bportal/air-shopping-api";

export const getSliceFareDetails = (
  tripDetailsById: ITripDetailsByTripId,
  fareTrips: any
): TripDetails | null => {
  if (!fareTrips.length) return null;
  let sliceDetails: TripDetails | null = null;
  sliceDetails = { ...tripDetailsById[fareTrips[0].trip] } as TripDetails;
  sliceDetails.fareDetails = [];
  fareTrips.forEach((fareTrip: any) => {
    const fareDetailByFareId = tripDetailsById[fareTrip.trip].fareDetails.find(
      (fareDetail) => fareDetail.id === fareTrip.fare
    );
    if (fareDetailByFareId && sliceDetails)
      sliceDetails.fareDetails.push(fareDetailByFareId);
  });
  return sliceDetails as TripDetails;
};

export function apacGetTags(fareScore: FareScore | FlightTags) {
  const tags: AlgomerchTag[] = [];
  if (fareScore.isBest) {
    tags.push(AlgomerchTag.BestFlight);
  }
  if (fareScore.isCheapest) {
    tags.push(AlgomerchTag.Cheapest);
  }
  if (fareScore.isFastest) {
    tags.push(AlgomerchTag.Fastest);
  }
  if (fareScore.isBestQuality) {
    tags.push(AlgomerchTag.BestQuality);
  }
  return tags;
}
