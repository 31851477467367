import { WalletOffers } from "@hopper-b2b/wallet";
import {
  getWalletBestOffers,
  getWalletOffers,
  hasSelectedCreditOffer,
  getTotalWalletSavings,
  getSelectedOffer,
  getWalletCreditOffer,
  getWalletOffersCallState,
  getHasWalletOffers,
} from "../reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedCreditOffer,
  setSelectedWalletOffer,
  setWalletOffers,
  setWalletOffersFailed,
} from "../actions/actions";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useEffect, useMemo } from "react";
import { getSelectedLodgingOfferOpaque } from "../../shop/reducer/selectors";
import {
  ProductSearchRequestEnum,
  hopperWalletApi,
} from "@b2bportal/hopper-wallet-api";
import { CallState } from "@hopper-b2b/types";
import axios from "axios";
import { useGoToNextStep } from "../../shop/hooks";
import { ShopPage } from "../../shop/components/ShopPage/component";

export const WalletOfferPage = () => {
  const { t } = useI18nContext();
  const dispatch = useDispatch();
  const goToNextStep = useGoToNextStep();

  const lodgingQpaque = useSelector(getSelectedLodgingOfferOpaque);
  const hasWalletOffers = useSelector(getHasWalletOffers);

  useEffect(() => {
    const fetchOffers = async () => {
      const request = {
        productSearchRequest: {
          opaqueProductChoice: lodgingQpaque,
          ProductSearchRequest: ProductSearchRequestEnum.LodgingSearchRequest,
        },
      };
      const res = await hopperWalletApi(axios).apiV0WalletApplicablePost(
        request
      );
      const offersRes = res.data.offerChoiceResponse;
      dispatch(
        setWalletOffers({
          offers: offersRes.offers,
          bestOffer: offersRes.bestOfferOverall,
          creditOffer: offersRes.creditOffer,
        })
      );
    };

    fetchOffers().catch((e) => dispatch(setWalletOffersFailed()));
  }, [dispatch, lodgingQpaque]);

  const callState = useSelector(getWalletOffersCallState);

  const offers = useSelector(getWalletOffers);
  const selectedOffer = useSelector(getSelectedOffer);
  const bestOffer = useSelector(getWalletBestOffers);
  const useCreditOffer = useSelector(hasSelectedCreditOffer);
  const creditOffer = useSelector(getWalletCreditOffer);
  const walletSavings = useSelector(getTotalWalletSavings);

  useEffect(() => {
    if (
      (!hasWalletOffers && callState === CallState.Success) ||
      callState === CallState.Failed
    ) {
      goToNextStep(true);
    }
  }, [hasWalletOffers, callState, goToNextStep]);

  return hasWalletOffers ? (
    <ShopPage title={t("walletSavings")}>
      <WalletOffers
        offers={offers}
        headerSubtitle={""}
        ctaLabel={t("walletOffers.nextStepButton")}
        handleContinue={() => goToNextStep()}
        setSelectedWalletOffer={(id) => {
          const offer = id ? offers.find((o) => o.id === id) : undefined;
          dispatch(setSelectedWalletOffer(offer));
        }}
        setSelectedCreditOffer={(bool) => {
          dispatch(setSelectedCreditOffer(bool));
        }}
        creditOffer={creditOffer}
        bestOffer={bestOffer}
        useCreditOffer={useCreditOffer}
        totalSavings={walletSavings}
        selectedOffer={selectedOffer}
      />
    </ShopPage>
  ) : (
    //TODO add loading screen after mockup is done.
    <>"loading"</>
  );
};
