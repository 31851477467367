import "./styles.scss";
import clsx from "clsx";

export interface IApacButtonProps {
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  message: string;
}

export const ApacButton = ({
  className,
  onClick,
  message,
  disabled = false,
}: IApacButtonProps) => {
  return (
    <button
      className={clsx("apac-button", className)}
      onClick={onClick}
      disabled={disabled}
    >
      {message}
    </button>
  );
};
