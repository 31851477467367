import {
  StepCrumb,
  TravelWalletDropdown,
  TravelCreditsOfferModalLink,
} from "@commbank/ui";
import { TravelCredits } from "@b2bportal/commbank-au-accounts-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  PATH_HOME,
  PATH_HOTELS_BOOK_AVAILABILITY,
  PATH_HOTELS_ROOT,
  PATH_HOTELS_SHOP,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom-v5-compat";
import { useLodgingLink } from "../../modules/availability/hooks/useLodgingLink";
import {
  getLocation,
  getSearchParams,
} from "../../modules/availability/reducer";
import { getSelectedLodging } from "../../modules/shop/reducer/selectors";
import { PATH_AVAILABILITY_RELATIVE } from "../../util";
import { CheckoutQueryParams, ParentState } from "@checkout/types";
import "./styles.scss";
import { useAccounts } from "@commbank/accounts";
import clsx from "clsx";

export enum HotelStep {
  ShopHotel,
  ChooseRoom,
  Payment,
}

export interface HotelStepBarProps {
  openProfilePanel: boolean;
  setOpenProfilePanel: (boolean) => void;
}

export const HotelStepBar = ({
  openProfilePanel,
  setOpenProfilePanel,
}: HotelStepBarProps) => {
  const { t } = useI18nContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { matchesMobile } = useDeviceTypes();
  const checkoutState = search.get(CheckoutQueryParams.checkoutState);
  const step = useMemo(() => {
    if (location.pathname.includes(PATH_HOTELS_BOOK_AVAILABILITY)) {
      return HotelStep.ShopHotel;
    } else if (location.pathname.includes(PATH_HOTELS_SHOP)) {
      return HotelStep.ChooseRoom;
    } else if (
      location.pathname.includes(PATH_HOTELS_ROOT + "book") &&
      checkoutState !== ParentState.bookingConfirmation
    ) {
      return HotelStep.Payment;
    } else {
      return undefined;
    }
  }, [location.pathname, checkoutState]);
  const accounts = useAccounts();
  const bestOfferPercentage = useMemo(() => {
    const accountWithBestOffer = accounts.accounts
      .filter(
        (account) =>
          account.eligibleOffers?.length > 0 &&
          // only travel credits are percentage based skip Points
          account.eligibleOffers.every(
            (offer) => offer.awards.Awards === "TravelCredits"
          )
      )
      .reduce((best, current) => {
        const currentBest = best?.eligibleOffers[0]?.awards;
        const potentialBest = current.eligibleOffers[0]?.awards;
        if (!currentBest && !!potentialBest) {
          return current;
        } else if (!!currentBest && !potentialBest) {
          return best;
        } else {
          return (currentBest as TravelCredits).percentage >=
            (potentialBest as TravelCredits).percentage
            ? current
            : best;
        }
      }, null);
    return accountWithBestOffer?.eligibleOffers[0]?.awards
      ? (accountWithBestOffer?.eligibleOffers[0]?.awards as TravelCredits)
          ?.percentage
      : null;
  }, [accounts]);

  const availabilityLocation = useSelector(getLocation);
  const selectedLodging = useSelector(getSelectedLodging);
  const searchParams = useSelector(getSearchParams);
  const lodgingLink = useLodgingLink(
    selectedLodging?.lodging?.id,
    selectedLodging?.lodging?.name,
    availabilityLocation
  );

  if (step === undefined || matchesMobile) {
    return;
  }
  const isPayment = step === HotelStep.Payment;
  const getStatus = (barStep: HotelStep) => {
    return barStep < step
      ? "completed"
      : barStep > step
      ? "pending"
      : "selected";
  };

  const searchUrl = () => {
    if (!availabilityLocation || !searchParams.toString()) {
      return `${PATH_HOME}${PATH_HOTELS_ROOT}`;
    } else {
      return `${PATH_HOME}${PATH_HOTELS_ROOT}${PATH_AVAILABILITY_RELATIVE}${availabilityLocation}?${searchParams.toString()}`;
    }
  };

  const shopUrl = () => {
    if (
      !availabilityLocation ||
      !selectedLodging?.lodging?.id ||
      !selectedLodging?.lodging?.name
    ) {
      return `${PATH_HOME}${PATH_HOTELS_ROOT}`;
    } else {
      return lodgingLink;
    }
  };

  const navigateToStep = (step: HotelStep) => {
    if (step === HotelStep.ShopHotel) {
      navigate(searchUrl());
    } else if (step === HotelStep.ChooseRoom) {
      navigate(shopUrl());
    }
  };

  return (
    <div className="desktop-hotel-shop-step-bar">
      <Box className="desktop-hotel-shop-step-bar-container">
        <Box className="steps-container">
          <StepCrumb
            key={HotelStep.ShopHotel}
            title={t("commBank.hotelShopProgress.shopHotel")}
            status={getStatus(HotelStep.ShopHotel)}
            onClick={() => navigateToStep(HotelStep.ShopHotel)}
          />
          <StepCrumb
            key={HotelStep.ChooseRoom}
            title={t("commBank.hotelShopProgress.chooseRoom")}
            status={getStatus(HotelStep.ChooseRoom)}
            onClick={() => navigateToStep(HotelStep.ChooseRoom)}
          />
          <StepCrumb
            key={HotelStep.Payment}
            title={t("commBank.hotelShopProgress.payment")}
            status={getStatus(HotelStep.Payment)}
            onClick={() => navigateToStep(HotelStep.Payment)}
          />
        </Box>
        {!isPayment && (
          <TravelWalletDropdown
            openProfilePanel={openProfilePanel}
            setOpenProfilePanel={setOpenProfilePanel}
          />
        )}
      </Box>
      {bestOfferPercentage && isPayment && (
        <div className="checkout-rewards-banner">
          <div className="earn-message-container">
            <div className={clsx("earn-message", { mobile: matchesMobile })}>
              {t("commBank.earnBanner.checkoutOfferTitle", {
                percent: bestOfferPercentage,
              })}
              <TravelCreditsOfferModalLink
                className="rewards-banner-learn-mode-button"
                bestOfferPercentage={bestOfferPercentage}
              >
                {t("commBank.earnBanner.learnMoreLinkLabel")}
              </TravelCreditsOfferModalLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
