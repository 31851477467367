import React from "react";
import { Box, Typography } from "@material-ui/core";

export interface IMarkdownSummaryRowProps {
  fiat: string;
  label: string;
  rewards?: string;
  total?: string;
}

const MarkdownSummaryRow = (props: IMarkdownSummaryRowProps): JSX.Element => {
  const { fiat, label, rewards, total } = props;

  return (
    <Box className="summary-item-wrapper">
      <Box className="summary-line-section left markdown">
        <Typography className="label" component="p" variant="subtitle1">
          {label}
        </Typography>
      </Box>
      <Box className="summary-line-section right markdown">
        {total ? (
          <Typography
            className="value fiat rewards"
            component="p"
            variant="subtitle2"
          >
            {`${total} (${fiat} & ${rewards})`}
          </Typography>
        ) : (
          <>
            {fiat ? (
              <Typography
                className="value fiat"
                component="p"
                variant="subtitle2"
              >
                {fiat}
              </Typography>
            ) : null}
            {rewards ? (
              <Typography
                className="value rewards"
                component="p"
                variant="subtitle2"
              >
                {fiat && (
                  <Typography className="separator" variant="subtitle2">
                    /
                  </Typography>
                )}
                {rewards}
              </Typography>
            ) : null}
          </>
        )}
      </Box>
    </Box>
  );
};

export default MarkdownSummaryRow;
