import { FiatPrice, WalletOffer } from "@b2bportal/hopper-wallet-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ActionButton,
  ButtonWrap,
  MobileFloatingSection,
  SadBunny,
  SwitchButton,
} from "@hopper-b2b/ui";
import { useTenantIcons } from "@hopper-b2b/utilities";
import { Box, Card, Grid, Hidden, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useMemo, useState } from "react";
import FlightIcon from "../../../assets/flight.svg";
import NoCashIcon from "../../../assets/no-cash.svg";
import VoucherIcon from "../../../assets/voucher.svg";

import styles from "./WalletOffers.module.scss";

import BottomImage from "./Btm.svg";

const WALLET_OFFER_NONE_ID = "waller-offer-none";
export interface IWalletOffers {
  offers?: WalletOffer[];
  creditOffer?: WalletOffer;
  headerTitle?: string;
  headerSubtitle: string | JSX.Element;
  ctaLabel: string;
  handleContinue: () => void;
  className?: string;
  loading?: boolean;
  bestOffer?: WalletOffer;
  selectedOffer?: WalletOffer;
  setSelectedWalletOffer: (id?: string) => void;
  setSelectedCreditOffer: (bool: boolean) => void;
  useCreditOffer: boolean;
  totalSavings: FiatPrice;
}
export const WalletOffers = ({
  offers = [],
  headerTitle,
  headerSubtitle,
  ctaLabel,
  handleContinue,
  className,
  creditOffer,
  bestOffer,
  setSelectedWalletOffer,
  setSelectedCreditOffer,
  useCreditOffer,
  totalSavings,
  selectedOffer,
}: IWalletOffers) => {
  const { t, formatFiatCurrency } = useI18nContext();

  const [selectedOfferId, setSelectedOfferId] = useState(
    selectedOffer?.id ? selectedOffer?.id : WALLET_OFFER_NONE_ID
  );
  const onOfferSelect = useCallback(
    (id: string) => {
      setSelectedOfferId(id);
      setSelectedWalletOffer(id !== WALLET_OFFER_NONE_ID ? id : undefined);
    },
    [setSelectedWalletOffer]
  );

  const toggleSwitch = useCallback(() => {
    setSelectedCreditOffer(!useCreditOffer);
  }, [useCreditOffer, setSelectedCreditOffer]);

  const tenantIcons = useTenantIcons();

  const offerValueInPositive = (fiat: FiatPrice): FiatPrice => {
    return {
      ...fiat,
      value: fiat.value * -1,
    };
  };

  const breakdownSection = useMemo(() => {
    return creditOffer ? (
      <Box mb={1} mx={2} className={styles.carrotCashSwitch}>
        <div className={styles.carrotCashSwitchContent}>
          <img src={tenantIcons.walletIcon} alt="" />
          {t("walletOffers.carrotCashOfferAmount", {
            amount: String(
              formatFiatCurrency(offerValueInPositive(creditOffer.amount.fiat))
            ),
            currencyName: "Wallet Balance",
          })}{" "}
        </div>
        <div className={styles.carrotCashSwitchButton}>
          <SwitchButton
            checked={useCreditOffer}
            onClick={toggleSwitch}
          ></SwitchButton>
        </div>
      </Box>
    ) : null;
  }, [
    creditOffer,
    formatFiatCurrency,
    t,
    tenantIcons.walletIcon,
    toggleSwitch,
    useCreditOffer,
  ]);

  //TODO: make a component out of this
  const offerOption = useCallback(
    (
      title: string,
      description: string,
      image: string,
      disclaimers: string[],
      id: string
    ) => {
      return (
        <ButtonWrap
          className={styles.walletOfferCard}
          onClick={() => onOfferSelect(id)}
        >
          {id === bestOffer?.id ? (
            <div className={styles.walletOfferSuggested}>
              {tenantIcons.logoSmall ? (
                <img src={tenantIcons.logoSmall} alt="" />
              ) : null}
              {t("walletOffers.suggestedBy", { tenant: "Hopper" })}
            </div>
          ) : null}
          <div className={styles.walletOfferOption}>
            {image ? (
              <div className={styles.walletOfferImage}>
                <img src={image} alt="" />
              </div>
            ) : null}
            <div className={styles.walletOfferContent}>
              <label htmlFor={id}>{title}</label>
              <div>{description}</div>
            </div>
            <div>
              <input
                type="radio"
                id={id}
                name="wallet-offer"
                value={id}
                checked={id === selectedOfferId}
                tabIndex={-1}
              ></input>
            </div>
          </div>
          {disclaimers.length ? (
            <div className={styles.wallerOfferDisclaimerContainer}>
              {disclaimers.map((disclaimer) => {
                return (
                  <div className={styles.wallerOfferDisclaimer}>
                    <div>
                      <img src={tenantIcons.info} alt="" />
                    </div>
                    <div>{disclaimer}</div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </ButtonWrap>
      );
    },
    [
      bestOffer?.id,
      tenantIcons.logoSmall,
      tenantIcons.info,
      t,
      selectedOfferId,
      onOfferSelect,
    ]
  );

  const title = headerTitle ? headerTitle : t("walletOffers.title");
  return (
    <>
      <div className={styles.walletOffersBannerWrapper}>
        <img className={styles.headerImage} src={BottomImage} alt="" />
      </div>
      <Grid container className={clsx(styles.walletOffersContainer, className)}>
        <Grid item xs={12}>
          <div className={styles.header}>
            <div className={styles.headerContent}>
              <h2 className={styles.title}>{title}</h2>{" "}
              {headerSubtitle ? (
                <div className={styles.subtitle}>
                  <p>{headerSubtitle}</p>
                </div>
              ) : null}
            </div>
          </div>
        </Grid>
        <Grid container sm={12} className={styles.offerDataBlock}>
          <Grid item sm={12} md={8} lg={8}>
            {offers.length ? (
              <div className={styles.offers}>
                {offers?.map((offer: WalletOffer) =>
                  offerOption(
                    offer.offerContent.walletContent.title,
                    "",
                    FlightIcon,
                    offer.offerContent.walletContent.items,
                    offer.id
                  )
                )}
                {offerOption(
                  t("walletOffers.useNextTime"),
                  "",
                  NoCashIcon,
                  [t("walletOffers.useNextTimeDisclaimer")],
                  WALLET_OFFER_NONE_ID
                )}
              </div>
            ) : (
              <Card className={styles.noPromotionContainer}>
                <Grid container>
                  <Grid item sm={4}>
                    <img src={SadBunny} className={styles.sadBunny} alt="" />
                  </Grid>
                  <Grid item sm={8} className={styles.noPromotion}>
                    <img
                      src={VoucherIcon}
                      className={styles.voucherIcon}
                      alt=""
                    />
                    <Typography variant="h4" color="textSecondary">
                      {t("walletOffers.noPromoHeader")}
                    </Typography>
                    <Typography variant="body1" className={styles.subtext}>
                      {t("walletOffers.noPromoText")}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>

          <Grid item sm={12} md={4} lg={4}>
            <Hidden smDown>
              <div className={styles.breakdownContainer}>
                {breakdownSection}
                <ActionButton
                  className={clsx("primary-button-wrapper")}
                  buttonClassName={clsx("primary-button")}
                  message={ctaLabel}
                  onClick={handleContinue}
                />
              </div>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
      <Hidden mdUp>
        <MobileFloatingSection
          className=""
          primaryButton={{
            children: ctaLabel,
            onClick: handleContinue,
          }}
          description={breakdownSection}
        />
      </Hidden>
    </>
  );
};
