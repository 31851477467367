import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  CallState,
  MyTripsFilter,
  PriceFreezeStatusEnum,
  PriceFreezeView,
} from "@hopper-b2b/types";
import { IStoreState } from "../../../../reducers/types";
import {
  getWatches,
  itinerariesToDisplaySelector,
  getListWatchesCallState,
  getFetchFlightsCallState,
  getFetchHotelsCallState,
  getFetchCarsCallState,
} from "../../../TripsList/reducer/selectors";

const sortByDate = (a?: string, b?: string) => {
  const aDiff = dayjs(a).diff(dayjs());
  const bDiff = dayjs(b).diff(dayjs());
  return aDiff - bDiff;
};

const sortByActive = (a: PriceFreezeView, b: PriceFreezeView) => {
  if (a.status.Status === PriceFreezeStatusEnum.IsActive) {
    if (b.status.Status === PriceFreezeStatusEnum.IsActive) {
      return sortByDate(
        a.tripDetails.slices[0].departureTime,
        b.tripDetails.slices[0].departureTime
      );
    } else {
      return -1;
    }
  } else {
    if (b.status.Status === PriceFreezeStatusEnum.IsActive) {
      return 1;
    }
    return sortByDate(
      a.tripDetails.slices[0].departureTime,
      b.tripDetails.slices[0].departureTime
    );
  }
};

export const getTripsFilter = (state: IStoreState) =>
  state.tripsList.tripsFilter;

export const getPriceFreezes = (state: IStoreState) =>
  state.tripsList.priceFreezes;

export const priceFreezeListSortedSelector = createSelector(
  [getTripsFilter, getPriceFreezes],
  (tripsFilter, pf) => {
    if (Array.isArray(pf)) {
      switch (tripsFilter) {
        case MyTripsFilter.UPCOMING_TRIPS:
        case MyTripsFilter.FLIGHTS:
          return pf
            .filter((priceFreeze) =>
              dayjs().isBefore(priceFreeze.priceFreeze.expiresAt)
            )
            .sort((a, b) =>
              sortByDate(
                a.tripDetails.slices[0].departureTime,
                b.tripDetails.slices[0].departureTime
              )
            );
        case MyTripsFilter.PAST_TRIPS:
          return pf
            .filter((priceFreeze) =>
              dayjs().isAfter(priceFreeze.priceFreeze.expiresAt)
            )
            .sort((a, b) =>
              sortByDate(
                a.tripDetails.slices[0].departureTime,
                b.tripDetails.slices[0].departureTime
              )
            );
        case MyTripsFilter.PRIZE_FREEZES:
          return pf.sort((a, b) => sortByActive(a, b));
      }
    }
    return [];
  }
);

export const hasFetchTripsError = createSelector(
  [
    getTripsFilter,
    getListWatchesCallState,
    getFetchFlightsCallState,
    getFetchHotelsCallState,
    getFetchCarsCallState,
  ],
  (
    tripsFilter,
    listWatchCallState,
    fetchFlightsCallState,
    fetchHotelsCallState,
    fetchCarsCallState
  ) => {
    switch (tripsFilter) {
      case MyTripsFilter.PAST_TRIPS:
      case MyTripsFilter.UPCOMING_TRIPS:
      case MyTripsFilter.FLIGHTS:
      case MyTripsFilter.HOTELS:
        return (
          fetchFlightsCallState === CallState.Failed ||
          fetchHotelsCallState === CallState.Failed ||
          fetchCarsCallState === CallState.Failed
        );
      case MyTripsFilter.WATCHED_TRIPS:
        return listWatchCallState === CallState.Failed;
      default:
        return false;
    }
  }
);

export const hasTripsToDisplay = createSelector(
  [getTripsFilter, getWatches, itinerariesToDisplaySelector, getPriceFreezes],
  (tripsFilter, watches, itineraries, pf) => {
    switch (tripsFilter) {
      case MyTripsFilter.UPCOMING_TRIPS:
      case MyTripsFilter.PAST_TRIPS:
      case MyTripsFilter.FLIGHTS:
      case MyTripsFilter.HOTELS:
        return itineraries.length > 0 || pf.length > 0;
      case MyTripsFilter.WATCHED_TRIPS:
        return watches.length > 0;
      case MyTripsFilter.PRIZE_FREEZES:
        return pf.length > 0;
      default:
        return false;
    }
  }
);
