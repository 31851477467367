import { LodgingAddress, type Suggestion } from "@b2bportal/lodging-api";
import { RoomProductCorporateTravelAddOn } from "../addons/corporate-travel";
import {
  PaymentPriceQuoteRequestEnum,
  PaymentSessionToken,
  Person,
} from "../apis";
import { AdditionalInfo } from "../apis/tysons/payment-machine";
import {
  Coordinates,
  FiatAmount,
  FiatPrice,
  LocationDescriptor,
  Prices,
  Uuid,
} from "../common";
import { HotelItineraryState } from "../trips-module";

export enum AmenityEnum {
  PetFriendly = "PetFriendly",
  Tennis = "Tennis",
  Beach = "Beach",
  Entertainment = "Entertainment",
  KitchenEssentials = "KitchenEssentials",
  CoffeeTea = "CoffeeTea",
  HomeInfo = "HomeInfo",
  Library = "Library",
  RoundTheClockCheckIn = "RoundTheClockCheckIn",
  Accessibility = "Accessibility",
  Laundry = "Laundry",
  Parking = "Parking",
  RoomWifi = "RoomWifi",
  FreeWifi = "FreeWifi",
  HairDryer = "HairDryer",
  Golf = "Golf",
  Childcare = "Childcare",
  Workspace = "Workspace",
  Restaurant = "Restaurant",
  Pool = "Pool",
  CoffeeMaker = "CoffeeMaker",
  HotTub = "HotTub",
  Bar = "Bar",
  FitnessCenter = "FitnessCenter",
  FreeBreakfast = "FreeBreakfast",
  Nightclub = "Nightclub",
  BeachEssentials = "BeachEssentials",
  AirportTransportation = "AirportTransportation",
  WaterPark = "WaterPark",
  BusinessFacilities = "BusinessFacilities",
  HomeEssentials = "HomeEssentials",
  Spa = "Spa",
  Arcade = "Arcade",
  BarbecueGrill = "BarbecueGrill",
  KidFriendly = "KidFriendly",
  Casino = "Casino",
  OutdoorSpace = "OutdoorSpace",
  AirConditioning = "AirConditioning",
  Heating = "Heating",
}

export interface AmenityStatus {
  amenity: AmenityEnum;
  displayName: string;
  details?: string;
}

export interface Approximate extends ILodgingLocationBase {
  LodgingLocation: LodgingLocationEnum.Approximate;
}

export type AvailabilityRequest = FollowUpSearch | InitialSearch;

export interface IAvailabilityRequestBase {
  AvailabilityRequest: AvailabilityRequestEnum;
}

export enum AvailabilityRequestEnum {
  FollowUpSearch = "FollowUpSearch",
  InitialSearch = "InitialSearch",
}

export type AvailabilityResponse = Initial | More;

export interface IAvailabilityResponseBase {
  AvailabilityResponse: AvailabilityResponseEnum;
  trackingProperties?: any;
}

export enum AvailabilityResponseEnum {
  Initial = "Initial",
  More = "More",
}

export enum CategoryEnum {
  Dining = "Dining",
  Exterior = "Exterior",
  PointOfInterest = "PointOfInterest",
  Pool = "Pool",
  View = "View",
  Fitness = "Fitness",
  Lobby = "Lobby",
  Interior = "Interior",
  Uncategorized = "Uncategorized",
  GuestRoom = "GuestRoom",
  Spa = "Spa",
  Amenity = "Amenity",
  Bar = "Bar",
  Primary = "Primary",
  SportsFacility = "SportsFacility",
}

export type CountryCode = string;

export interface DateRange {
  from: string;
  until: string;
}

export interface DiscountHunting {
  webPrice: Prices;
  savingsAmount: Prices;
}

// manually changed its name from "Exact" to "LodgingAddressExact" to avoid duplication
export interface LodgingAddressExact extends ILodgingAddressBase {
  line1: string;
  line2?: string;
  LodgingAddress: LodgingAddressEnum.Exact;
}

export interface Exact extends ILodgingLocationBase {
  placeTypes: PlaceTypeEnum[];
  LodgingLocation: LodgingLocationEnum.Exact;
}

export interface FollowUpSearch extends IAvailabilityRequestBase {
  moreToken: string;
  progressiveConfig: ProgressiveConfig;
  AvailabilityRequest: AvailabilityRequestEnum.FollowUpSearch;
}

export interface GuestsSelection {
  adults: number;
  children: number[];
  rooms: number;
}

export enum HotelStarRatingEnum {
  NotAvailable = "NotAvailable",
  Three = "Three",
  One = "One",
  Two = "Two",
  Five = "Five",
  Four = "Four",
}

export interface Initial extends IAvailabilityResponseBase {
  dates: DateRange;
  lodgings: Lodging[];
  nextPageToken?: string;
  AvailabilityResponse: AvailabilityResponseEnum.Initial;
}

export interface InitialSearch extends IAvailabilityRequestBase {
  lodgingSelection: LodgingSelection;
  stayDates: DateRange;
  guests: Omit<GuestsSelection, "rooms">;
  progressiveConfig: ProgressiveConfig;
  platform: Platform;
  AvailabilityRequest: AvailabilityRequestEnum.InitialSearch;
}

export interface Location extends ILodgingSelectionBase {
  descriptor: LocationDescriptor;
  center?: Coordinates;
  LodgingSelection: LodgingSelectionEnum.Location;
}

export interface Lodging {
  lodging: LodgingData;
  description: string;
  price?: LodgingPrice;
  trackingProperties?: any;
  available?: boolean;
}

export interface ILodgingAddressBase {
  LodgingAddress: LodgingAddressEnum;
}

export enum LodgingAddressEnum {
  Exact = "Exact",
  Obfuscated = "Obfuscated",
}

export interface LodgingComparePrice {
  source: string;
  nightlyPrice: Prices;
}

export interface IState {
  code: string;
  name: string;
}

export interface LodgingData {
  id: LodgingId;
  name: string;
  address: LodgingAddress;
  city: string;
  country: CountryCode;
  state?: IState;
  description: string;
  phone?: string;
  smsPhone?: string;
  starRating: HotelStarRatingEnum;
  score: LodgingRating;
  media: LodgingMediaAsset[];
  amenities: AmenityStatus[];
  tripAdvisorReviews?: TripAdvisorReviews;
  location: LodgingLocation;
}

export type LodgingId = string;

export interface LodgingIds extends ILodgingSelectionBase {
  lodgingIds: LodgingId[];
  preserveOrder?: boolean;
  LodgingSelection: LodgingSelectionEnum.LodgingIds;
}

export type LodgingLocation = Approximate | Exact;

export interface ILodgingLocationBase {
  coordinates: Coordinates;
  LodgingLocation: LodgingLocationEnum;
}

export enum LodgingLocationEnum {
  Approximate = "Approximate",
  Exact = "Exact",
}

export type LodgingMediaAsset = NonResizableImage | ResizableImage | Video;

export interface ILodgingMediaAssetBase {
  url: string;
  LodgingMediaAsset: LodgingMediaAssetEnum;
}

export enum LodgingMediaAssetEnum {
  NonResizableImage = "NonResizableImage",
  ResizableImage = "ResizableImage",
  Video = "Video",
}

export interface LodgingPrice {
  totalPrice: Prices;
  nightlyPrice: Prices;
  comparePrices: LodgingComparePrice[];
  opaqueShopRequest: OpaqueValue;
  providerName: string;
  discountHunting?: DiscountHunting;
}

export type LodgingRating = number;

export type LodgingSelection = Location | LodgingIds | Market | Place;

export type ILodgingSelectionBase = {
  LodgingSelection: LodgingSelectionEnum;
};

export enum LodgingSelectionEnum {
  Location = "Location",
  LodgingIds = "LodgingIds",
  Market = "Market",
  Place = "Place",
}

export interface Market extends ILodgingSelectionBase {
  groupingKey: WatchGroupingKey;
  LodgingSelection: LodgingSelectionEnum.Market;
}

export interface MarketGroupingKey extends IWatchGroupingKeyBase {
  id: string;
  name: string;
  fullName: string;
  airport: string;
  dateRange: DateRange;
  WatchGroupingKey: WatchGroupingKeyEnum.MarketGroupingKey;
}

export interface More extends IAvailabilityResponseBase {
  lodgings: Lodging[];
  nextPageToken?: string;
  AvailabilityResponse: AvailabilityResponseEnum.More;
}

export interface NonResizableImage extends ILodgingMediaAssetBase {
  category?: CategoryEnum;
  LodgingMediaAsset: LodgingMediaAssetEnum.NonResizableImage;
}

export interface Obfuscated extends ILodgingAddressBase {
  city: string;
  stateOrProvince: StateOrProvince;
  LodgingAddress: LodgingAddressEnum.Obfuscated;
}

export type OpaqueValue = string;

export interface Place extends ILodgingSelectionBase {
  placeId: string;
  searchTerm: string;
  placeTypes: string[];
  LodgingSelection: LodgingSelectionEnum.Place;
}

export enum PlaceTypeEnum {
  Lodging = "Lodging",
}

export type Platform = PlatformEnum.Desktop | PlatformEnum.Mobile;

export enum PlatformEnum {
  Desktop = "Desktop",
  Mobile = "Mobile",
}

export interface ProgressiveConfig {
  pageSize?: number;
}

export interface ResizableImage extends ILodgingMediaAssetBase {
  category?: CategoryEnum;
  LodgingMediaAsset: LodgingMediaAssetEnum.ResizableImage;
}

export interface StateOrProvince {
  code?: string;
  name?: string;
}

export interface TripAdvisorCategory {
  categoryName: string;
  score: LodgingRating;
}

export interface TripAdvisorComment {
  text: string;
  author: string;
  created: string;
  score: LodgingRating;
  title: string;
}

export interface TripAdvisorReviews {
  reviewsCount: number;
  overallScore: LodgingRating;
  categories: TripAdvisorCategory[];
  comments: TripAdvisorComment[];
}

export interface Video extends ILodgingMediaAssetBase {
  thumbnail: string;
  LodgingMediaAsset: LodgingMediaAssetEnum.Video;
}

export type WatchGroupingKey = MarketGroupingKey;

export interface IWatchGroupingKeyBase {
  WatchGroupingKey: WatchGroupingKeyEnum;
}

export enum WatchGroupingKeyEnum {
  MarketGroupingKey,
}

/* Shop */

export interface ShopRequest {
  opaqueRequest: string;
}

export type ShopResponse =
  | ShopResponseEnum.Available
  | ShopResponseEnum.Failure
  | ShopResponseEnum.Unavailable;

export interface IShopResponseBase {
  ShopResponse: ShopResponse;
}

export enum ShopResponseEnum {
  Available = "Available",
  Failure = "Failure",
  Unavailable = "Unavailable",
}

export interface IShopResponseAvailable {
  lodgingId: string;
  lodgingPriceChange?: PriceChange;
  roomInfoProducts: RoomInfoProducts[];
  ShopResponse: ShopResponseEnum.Available;
}

export interface IShopResponseFailure {
  ShopResponse: ShopResponseEnum.Failure;
}

export interface IShopResponseUnavailable {
  ShopResponse: ShopResponseEnum.Unavailable;
}

export enum BedTypeEnum {
  Murphy = "Murphy",
  Single = "Single",
  RunOfTheHouse = "RunOfTheHouse",
  Double = "Double",
  King = "King",
  Dorm = "Dorm",
  Twin = "Twin",
  Water = "Water",
  Queen = "Queen",
  Sofa = "Sofa",
  Full = "Full",
  Tatami = "Tatami",
  Futon = "Futon",
}

export interface BedTypeList {
  bedTypes: BedTypeWithDescription[];
  description: string;
}

export interface BedTypeWithDescription {
  bedType: BedTypeEnum;
  description: string;
}

export enum PriceChangeDirectionEnum {
  Decrease = "Decrease",
  Increase = "Increase",
}

export interface AmenityStatus {
  amenity: AmenityEnum;
  displayName: string;
  details?: string;
}

type RoomType =
  | RoomTypeEnum.Bedroom
  | RoomTypeEnum.CommonArea
  | RoomTypeEnum.LivingRoom
  | RoomTypeEnum.Loft;

export enum RoomTypeEnum {
  Bedroom = "Bedroom",
  CommonArea = "CommonArea",
  LivingRoom = "LivingRoom",
  Loft = "Loft",
}

export interface Room {
  roomType: RoomType;
  bedTypes: BedTypeList;
}

export type CancellationPolicy = Cfar | NonRefundable | Refundable;

export interface Discount {
  value: Prices;
  description: string;
}

export interface Fees {
  hotelFees?: FiatPrice;
  resortFees?: FiatPrice;
  cityTaxes?: FiatPrice;
  total?: FiatPrice;
}

type PriceChangeDirection =
  | PriceChangeDirectionEnum.Decrease
  | PriceChangeDirectionEnum.Increase;

export interface PriceChange {
  direction: PriceChangeDirection;
  priceChangePercentage: number;
}

export interface ProviderOverrideText {
  taxRecoveryChargesAndServiceFees?: string;
}

export interface RoomInfo {
  roomId: string;
  name?: string;
  description?: string;
  maxAdults?: number;
  maxChildren?: number;
  beds: BedTypeList;
  amenities: AmenityStatus[];
  sizeSqFt?: number;
  media: LodgingMediaAsset[];
}

export interface RoomInfoProducts {
  roomInfo: RoomInfo;
  products: RoomProduct[];
  trackingProperties?: any;
}

export interface RoomProduct {
  providerOverrideText: ProviderOverrideText;
  bedTypes: Rooms;
  baseRate: Prices;
  sellRate: Prices;
  taxBreakdown: Taxes;
  feeBreakdown: Fees;
  discounts: Discount[];
  total: Prices;
  tripTotal: Prices;
  perNightBaseRate: Prices;
  perNightSellRate: Prices;
  perNightTotal: Prices;
  opaqueQuoteRequest: string;
  cancellationPolicy: CancellationPolicy;
  supplierRateCategory?: SupplierRateCategory;
  roomName?: string;
  rateId?: { value: string };
  trackingProperties?: any;
  // AddOn used by the Corporate Travel team
  corporateTravel?: RoomProductCorporateTravelAddOn;
  loyaltyProgramCode?: string;
}

export interface Rooms {
  rooms: Room[];
  occupancy?: number;
}

export type SupplierRateCategory =
  | SupplierRateCategoryEnum.Getaroom_Mobile
  | SupplierRateCategoryEnum.HotelPlanner_unpubStrict
  | SupplierRateCategoryEnum.Scrape_Agoda
  | SupplierRateCategoryEnum.Roomer_minprice
  | SupplierRateCategoryEnum.Roomer_minprice_special_deal
  | SupplierRateCategoryEnum.Hotelbeds_Unknown
  | SupplierRateCategoryEnum.Bookohotel_Unknown
  | SupplierRateCategoryEnum.Booking_DealOfTheDay
  | SupplierRateCategoryEnum.Booking_LastMinuteDeal
  | SupplierRateCategoryEnum.Priceline_MemberDeal
  | SupplierRateCategoryEnum.Roomer_net_special_deal
  | SupplierRateCategoryEnum.Hotelbeds_Offer
  | SupplierRateCategoryEnum.Budapest_FencedEverWatchedBookedFlight
  | SupplierRateCategoryEnum.HotelPlanner_Public
  | SupplierRateCategoryEnum.Priceline_Booking
  | SupplierRateCategoryEnum.Hotelbeds_Select
  | SupplierRateCategoryEnum.W2m_Public
  | SupplierRateCategoryEnum.HotelPlanner_unpubMobile
  | SupplierRateCategoryEnum.Vrbo_PublicMobile
  | SupplierRateCategoryEnum.Expedia_PublicWeb
  | SupplierRateCategoryEnum.Scrape_Booking
  | SupplierRateCategoryEnum.Snaptravel_Restricted
  | SupplierRateCategoryEnum.Getaroom_Net_Public
  | SupplierRateCategoryEnum.Dhisco_Commission
  | SupplierRateCategoryEnum.Getaroom_Net_ClosedUserGroup
  | SupplierRateCategoryEnum.Olympiaeu_Public
  | SupplierRateCategoryEnum.Hotelbeds_Special
  | SupplierRateCategoryEnum.Scrape_Priceline
  | SupplierRateCategoryEnum.Budapest_Private
  | SupplierRateCategoryEnum.Expedia_ClosedUserGroup
  | SupplierRateCategoryEnum.Hotelbeds_Regular
  | SupplierRateCategoryEnum.Expedia_PublicMobile
  | SupplierRateCategoryEnum.HotelPlanner_unpubLight
  | SupplierRateCategoryEnum.HotelPlanner_packageReg
  | SupplierRateCategoryEnum.Priceline_ClosedUserGroup
  | SupplierRateCategoryEnum.Snaptravel_Public
  | SupplierRateCategoryEnum.Getaroom_Public
  | SupplierRateCategoryEnum.Getaroom_ClosedUserGroup
  | SupplierRateCategoryEnum.Getaroom_Net_Mobile
  | SupplierRateCategoryEnum.Booking_SecretDeal
  | SupplierRateCategoryEnum.Budapest_Public
  | SupplierRateCategoryEnum.Priceline_Flexible
  | SupplierRateCategoryEnum.Booking_Unknown
  | SupplierRateCategoryEnum.Dhisco_Net
  | SupplierRateCategoryEnum.Priceline_AdditionalRetail
  | SupplierRateCategoryEnum.HotelPlanner_packageStrict
  | SupplierRateCategoryEnum.Getaroom_Tonight
  | SupplierRateCategoryEnum.Scrape_Undetermined
  | SupplierRateCategoryEnum.Roomer_net
  | SupplierRateCategoryEnum.Priceline_Flexbenchmark
  | SupplierRateCategoryEnum.Scrape_Hotelbeds
  | SupplierRateCategoryEnum.Scrape_Expedia
  | SupplierRateCategoryEnum.Scrape_Snaptravel
  | SupplierRateCategoryEnum.Priceline_Private
  | SupplierRateCategoryEnum.Booking_SalesCampaign
  | SupplierRateCategoryEnum.Booking_Public
  | SupplierRateCategoryEnum.Priceline_ClosedUserGroup2
  | SupplierRateCategoryEnum.Priceline_Agoda
  | SupplierRateCategoryEnum.Priceline_Public
  | SupplierRateCategoryEnum.Hotelbeds_Package
  | SupplierRateCategoryEnum.HotelPlanner_unpubReg
  | SupplierRateCategoryEnum.Booking_SmartDeal
  | SupplierRateCategoryEnum.Getaroom_Net_Tonight
  | SupplierRateCategoryEnum.Agoda_Unknown
  | SupplierRateCategoryEnum.Booking_Mobile
  | SupplierRateCategoryEnum.Scrape_Getaroom
  | SupplierRateCategoryEnum.Priceline_MobileExclusive
  | SupplierRateCategoryEnum.Expedia_ExposedPackage
  | SupplierRateCategoryEnum.Booking_FlashDeal
  | SupplierRateCategoryEnum.Scrape_Hotelplanner;

export enum SupplierRateCategoryEnum {
  Hotelbeds_Regular = "Hotelbeds_Regular",
  Priceline_Flexible = "Priceline_Flexible",
  Priceline_Public = "Priceline_Public",
  Getaroom_ClosedUserGroup = "Getaroom_ClosedUserGroup",
  Priceline_Private = "Priceline_Private",
  Priceline_Agoda = "Priceline_Agoda",
  Booking_LastMinuteDeal = "Booking_LastMinuteDeal",
  Olympiaeu_Public = "Olympiaeu_Public",
  Bookohotel_Unknown = "Bookohotel_Unknown",
  Roomer_net_special_deal = "Roomer_net_special_deal",
  Booking_SalesCampaign = "Booking_SalesCampaign",
  Budapest_Private = "Budapest_Private",
  Getaroom_Tonight = "Getaroom_Tonight",
  Roomer_net = "Roomer_net",
  Booking_Mobile = "Booking_Mobile",
  Agoda_Unknown = "Agoda_Unknown",
  Hotelbeds_Select = "Hotelbeds_Select",
  Scrape_Undetermined = "Scrape_Undetermined",
  HotelPlanner_unpubLight = "HotelPlanner_unpubLight",
  Scrape_Agoda = "Scrape_Agoda",
  Expedia_ClosedUserGroup = "Expedia_ClosedUserGroup",
  Priceline_MemberDeal = "Priceline_MemberDeal",
  Snaptravel_Public = "Snaptravel_Public",
  Priceline_ClosedUserGroup = "Priceline_ClosedUserGroup",
  Roomer_minprice_special_deal = "Roomer_minprice_special_deal",
  Booking_SecretDeal = "Booking_SecretDeal",
  Scrape_Expedia = "Scrape_Expedia",
  Getaroom_Net_Public = "Getaroom_Net_Public",
  Vrbo_PublicMobile = "Vrbo_PublicMobile",
  Getaroom_Net_Mobile = "Getaroom_Net_Mobile",
  Budapest_FencedEverWatchedBookedFlight = "Budapest_FencedEverWatchedBookedFlight",
  Getaroom_Mobile = "Getaroom_Mobile",
  Expedia_PublicMobile = "Expedia_PublicMobile",
  HotelPlanner_packageStrict = "HotelPlanner_packageStrict",
  Priceline_ClosedUserGroup2 = "Priceline_ClosedUserGroup2",
  HotelPlanner_unpubStrict = "HotelPlanner_unpubStrict",
  Scrape_Booking = "Scrape_Booking",
  Priceline_AdditionalRetail = "Priceline_AdditionalRetail",
  Getaroom_Public = "Getaroom_Public",
  Priceline_Flexbenchmark = "Priceline_Flexbenchmark",
  HotelPlanner_packageReg = "HotelPlanner_packageReg",
  Expedia_PublicWeb = "Expedia_PublicWeb",
  Hotelbeds_Unknown = "Hotelbeds_Unknown",
  Priceline_Booking = "Priceline_Booking",
  Booking_Public = "Booking_Public",
  Scrape_Getaroom = "Scrape_Getaroom",
  Roomer_minprice = "Roomer_minprice",
  Priceline_MobileExclusive = "Priceline_MobileExclusive",
  Hotelbeds_Special = "Hotelbeds_Special",
  Scrape_Snaptravel = "Scrape_Snaptravel",
  Snaptravel_Restricted = "Snaptravel_Restricted",
  HotelPlanner_unpubReg = "HotelPlanner_unpubReg",
  Getaroom_Net_Tonight = "Getaroom_Net_Tonight",
  Dhisco_Net = "Dhisco_Net",
  Booking_Unknown = "Booking_Unknown",
  HotelPlanner_unpubMobile = "HotelPlanner_unpubMobile",
  Expedia_ExposedPackage = "Expedia_ExposedPackage",
  Budapest_Public = "Budapest_Public",
  Booking_SmartDeal = "Booking_SmartDeal",
  Dhisco_Commission = "Dhisco_Commission",
  Hotelbeds_Offer = "Hotelbeds_Offer",
  HotelPlanner_Public = "HotelPlanner_Public",
  Booking_FlashDeal = "Booking_FlashDeal",
  Hotelbeds_Package = "Hotelbeds_Package",
  Scrape_Hotelbeds = "Scrape_Hotelbeds",
  Booking_DealOfTheDay = "Booking_DealOfTheDay",
  W2m_Public = "W2m_Public",
  Scrape_Priceline = "Scrape_Priceline",
  Getaroom_Net_ClosedUserGroup = "Getaroom_Net_ClosedUserGroup",
  Scrape_Hotelplanner = "Scrape_Hotelplanner",
}

export enum CancellationPolicyEnum {
  Cfar = "Cfar",
  NonRefundable = "NonRefundable",
  Refundable = "Refundable",
}

export interface Taxes {
  taxRecoveryCharges?: Prices;
  salesTax?: Prices;
  propertyFee?: Prices;
}

/* Book */
export type IconType =
  | IconTypeEnum.Expiration
  | IconTypeEnum.Unavailable
  | IconTypeEnum.Info
  | IconTypeEnum.Money
  | IconTypeEnum.Check;

export enum IconTypeEnum {
  Expiration = "Expiration",
  Unavailable = "Unavailable",
  Info = "Info",
  Money = "Money",
  Check = "Check",
}

export interface Info {
  IconType: IconTypeEnum;
}

export interface PriceQuoteScheduleRequest {
  token: string;
  quoteRequest: string;
  personId: string;
  phoneNumber: string;
  emailAddress: string;
  delegatedTo?: string;
}

export interface HotelQuoteScheduleRequest {
  token: string;
  quoteRequest: string;
  personId: string;
  phoneNumber: string;
  emailAddress: string;
  dates?: DateRange;
  id?: LodgingId;
  name?: string;
  city?: string;
  country?: string;
  starRating?: HotelStarRatingEnum;
  providerName?: string;
  bedTypes?: BedTypeList;
  delegatedTo?: string;
}

export interface HotelPriceQuoteScheduleRequest {
  hotelQuoteRequest: HotelQuoteScheduleRequest;
  quoteDescription: string;
  PaymentPriceQuoteRequest: PaymentPriceQuoteRequestEnum;
}

export interface ScheduleResponseSuccess {}

export interface ScheduleResponseFailed {
  error: string;
}

export type ScheduleResponse = ScheduleResponseSuccess | ScheduleResponseFailed;

export interface Cfar {
  primaryText: string;
  coveragePercentage: number;
  perNightRefund?: Prices;
  taxExclusiveRefund: Prices;
  bookingAmount: Prices;
  refundAmount: Prices;
  CancellationPolicy: CancellationPolicyEnum.Cfar;
}

export interface NonRefundable {
  primaryText: string;
  secondaryText?: string;
  CancellationPolicy: CancellationPolicyEnum.NonRefundable;
}

export interface Refundable {
  primaryText: string;
  secondaryText?: string;
  CancellationPolicy: CancellationPolicyEnum.Refundable;
}

export interface Check {
  IconType: IconTypeEnum;
}

export interface CheckInInstructions {
  checkInTime?: CheckInTime;
  checkOutTime?: string;
  minimumAge?: number;
  acceptedCards: string[];
  policies: CheckInPolicy[];
}

export interface CheckInPolicy {
  title: string;
  text: string;
}

export type CheckInTime = CheckInTimeEnum.Anytime | CheckInTimeEnum.Restricted;

export enum CheckInTimeEnum {
  Anytime = "Anytime",
  Restricted = "Restricted",
}

export type Severity = SeverityEnum.Fatal | SeverityEnum.Warning;

export enum SeverityEnum {
  Fatal = "Fatal",
  Warning = "Warning",
}

export interface DetailedServerError {
  errorCode: string;
  severity: Severity;
}

export interface HotelPricingDiscount {
  value: Prices;
  description: string;
}

export interface HotelPricing {
  currency: string;
  rooms: Prices;
  taxes: Prices;
  taxBreakdown: Taxes;
  subtotal: Prices;
  discounts: HotelPricingDiscount[];
  grandTotal: FiatAmount;
  tripTotal: Prices;
  feeBreakdown: Fees;
}

export interface PriceQuote {
  pricing: HotelPricing;
  priceChange?: PriceChange;
  cancellationPolicy: CancellationPolicy;
  checkInInstructions: CheckInInstructions;
}

export interface SlimCreditCard {
  paymentId: string;
  createdAt: string;
  numberDisplay?: string;
  cardType: string;
  SlimPayment: SlimPaymentEnum;
}

export type SlimPayment = SlimCreditCard;

export enum SlimPaymentEnum {
  SlimCreditCard = "SlimCreditCard",
}

export enum HotelBookErrorType {
  NoAvailability = "NoAvailability",
  Unknown = "Unknown",
  CheckInMinimumAgeNotMet = "CheckInMinimumAgeNotMet",
  PaymentError = "PaymentError",
  LackOfInventory = "LackOfInventory",
  RateNotAvailable = "RateNotAvailable",
  Inactivity = "Inactivity",
  RedemptionFailure = "RedemptionFailure",
}

export interface HotelBookErrorCode {
  code: HotelBookErrorType | string;
  message?: string;
}

export interface HotelBookPollFailed {
  purchaseResult: {
    errors: HotelBookErrorCode[];
    Response: HotelBookPollGetResultEnum.Failed;
  };
}

export interface HotelBookPollPending {
  purchaseResult: {
    Response: HotelBookPollGetResultEnum.Pending;
  };
}

export interface HotelBookPollSuccess {
  purchaseResult: {
    value: Reservation;
    Response: HotelBookPollGetResultEnum.Success;
  };
}

export interface HotelPriceQuotePollFailed {
  priceQuote: {
    errors: HotelBookErrorCode[];
    Response: HotelBookPollGetResultEnum.Failed;
  };
}

export interface HotelPriceQuotePollPending {
  priceQuote: {
    Response: HotelBookPollGetResultEnum.Pending;
  };
}

export interface HotelPriceQuotePollSuccess {
  priceQuote: {
    value: PriceQuote;
    Response: HotelBookPollGetResultEnum.Success;
  };
}

export enum HotelBookPollGetResultEnum {
  Success = "Success",
  Pending = "Pending",
  Failed = "Failed",
}

export type HotelBookPollResult =
  | HotelBookPollFailed
  | HotelBookPollPending
  | HotelBookPollSuccess;

export type HotelPriceQuotePollResult =
  | HotelPriceQuotePollFailed
  | HotelPriceQuotePollSuccess
  | HotelPriceQuotePollPending;

export interface Reservation {
  additionalInfo?: AdditionalInfo;
  reservationBookingId: Uuid;
  reservationId: string;
  lodgingData: LodgingData;
  checkInDate: string;
  checkOutDate: string;
  createdAt: string;
  guests: Person[];
  pricing: HotelPricing;
  payment: SlimPayment;
  paymentSchedule?: string;
  paymentCurrency: string;
  providerOverrideText: ProviderOverrideText;
  bedTypes: Rooms;
  policies: CheckInPolicy[];
  cancellationPolicy: CancellationPolicy;
  roomInfo?: RoomInfo;
  state: ReservationState;
}

export interface ReservationState {
  ReservationState: HotelItineraryState;
}

export type SessionRequest =
  | SessionResponseEnum.Open
  | SessionResponseEnum.Close;

export enum SessionRequestEnum {
  Open = "Open",
  Close = "Close",
}

export type SessionResponse =
  | SessionResponseEnum.Open
  | SessionResponseEnum.Close;

export enum SessionResponseEnum {
  Open = "Open",
  Close = "Close",
}

export type SessionServiceState = "Valid" | InvalidSession;

export interface InvalidSession {
  code?: string;
  message?: number;
}

export interface TokenValue {
  token?: string;
  state: SessionServiceState;
}

export interface OpenSessionReponse {
  SessionResponse: SessionResponse;
  token: TokenValue;
}

export interface HotelBookPollRequest {
  token: PaymentSessionToken;
}

export interface HotelPriceQuotePollRequest {
  token: PaymentSessionToken;
}

export enum CodeEnum {
  ExcessiveActivity = "ExcessiveActivity",
  Unauthorized = "Unauthorized",
}

export const HotelStarRating = {
  Five: "Five",
  Four: "Four",
  NotAvailable: "NotAvailable",
  One: "One",
  Three: "Three",
  Two: "Two",
} as const;

export type HotelStarRating =
  (typeof HotelStarRating)[keyof typeof HotelStarRating];

export interface LodgingSummary {
  /**
   * lodging city
   * @type {string}
   * @memberof LodgingSummary
   */
  city: string;
  /**
   * lodging name
   * @type {string}
   * @memberof LodgingSummary
   */
  name: string;
  state: StateOrProvince;
  /**
   * 2 character country code
   * @type {string}
   * @memberof LodgingSummary
   */
  country: string;
  id: string;
  starRating: HotelStarRating;
}

export const SECTION_ID = {
  amenities: "amenities",
  about: "about",
  reviews: "reviews",
  rooms: "rooms",
} as const;

export type SearchSubmit = {
  nextDestination?: Suggestion;
  nextFromDate: string;
  nextUntilDate: string;
  nextAdultsCount: number;
  nextChildrenCount: number;
  nextChildrenAges: number[];
  nextRoomsCount: number;
};

export interface SearchLodgingProps {
  initialDestination?: Suggestion;
  initialCheckinDate: string;
  initialCheckoutDate: string;
  initialGuestCount?: GuestsSelection;
  onSearch: (value: SearchSubmit) => void;
  enableRooms?: boolean;
}

export type PriceRange = {
  min: number;
  max: number;
  lowest: number;
  highest: number;
};

export interface ILodgingFilterState {
  starRating: number[];
  userRating: number;
  amenities: string[];
  filterAmenitiesAnd: boolean;
  freeCancellation: boolean;
  priceRange: PriceRange;
}

export type QuickFiltersProps = {
  filters: ILodgingFilterState;
  openFiltersModal(): void;
  onStarRatingChange(nextValue: number[]): void;
  onUserRatingChange(nextValue: number): void;
  onFilterPriceRangeChange(nextValue: Pick<PriceRange, "min" | "max">): void;
  openLocationModal?: boolean;
  openCalendarModal?: boolean;
  setOpenLocationModal?: (val: boolean) => void;
  setOpenCalendarModal?: (val: boolean) => void;
};

export type LodgingSearchProps = SearchLodgingProps & QuickFiltersProps;

export type LodgingsFilterBoundaries = {
  amenities: Map<string, { amenity: AmenityStatus; count: number }>;
};
