import { AirCfarOffer, TrackingProperties } from "@b2bportal/air-cfar-api";
import * as actionTypes from "./constants";

export interface ISetCfarOffers {
  type: actionTypes.SET_CFAR_OFFERS;
  offers?: AirCfarOffer[];
  trackingProperties?: TrackingProperties;
}

export const setCfarOffers = (args: {
  offers?: AirCfarOffer[];
  trackingProperties?: TrackingProperties;
}): ISetCfarOffers => ({
  type: actionTypes.SET_CFAR_OFFERS,
  ...args,
});

export interface IFetchCfarOffers {
  type: actionTypes.FETCH_CFAR_OFFERS;
  tripId: string;
  fareId: string;
}

export const fetchCfarOffers = (
  tripId: string,
  fareId: string
): IFetchCfarOffers => ({
  type: actionTypes.FETCH_CFAR_OFFERS,
  tripId,
  fareId,
});

export interface ISetCfarOffersFailed {
  type: actionTypes.SET_CFAR_OFFERS_FAILED;
}

export const setCfarOffersFailed = (): ISetCfarOffersFailed => ({
  type: actionTypes.SET_CFAR_OFFERS_FAILED,
});

export interface ISetSelectedCfarOffer {
  type: actionTypes.SET_SELECTED_CFAR_OFFER;
  selectedCfarOffer?: AirCfarOffer;
}

export const setSelectedCfarOffer = (
  offer?: AirCfarOffer
): ISetSelectedCfarOffer => ({
  type: actionTypes.SET_SELECTED_CFAR_OFFER,
  selectedCfarOffer: offer,
});

export type FlightCfarActions =
  | ISetCfarOffers
  | IFetchCfarOffers
  | ISetCfarOffersFailed
  | ISetSelectedCfarOffer;
