import { useMemo } from "react";

/**
 * Returns a string containing plain text format by removing HTML tags
 * @param {string} strToSanitize - String to be sanitized
 * @returns {string} - Sanitized plain text string
 */
const sanitizeHMTLTags = (strToSanitize: string) => {
  try {
    const html = new DOMParser().parseFromString(strToSanitize, "text/html");
    return html.body.textContent || "";
  } catch (error) {
    console.error("Error parsing HTML string:", error);
    return "";
  }
};

export function useTrimmedString(
  text: string,
  options?: {
    limit?: number;
    sanitizeTrimmedHMTL?: boolean;
  }
) {
  const { limit = 300, sanitizeTrimmedHMTL = false } = options || {};
  return useMemo(() => {
    if (!text) return { trimmedString: null, showMore: false };

    if (text.length <= limit) {
      return {
        trimmedString: text,
        showMore: false,
      };
    }

    let trimmedString = text.substring(0, limit);
    if (sanitizeTrimmedHMTL) {
      trimmedString = sanitizeHMTLTags(trimmedString);
    }
    return {
      trimmedString: `${trimmedString}...`,
      showMore: true,
    };
  }, [text, limit, sanitizeTrimmedHMTL]);
}
