import { SingleFlightItineraryResponse } from "@b2bportal/air-booking-api";
import { axiosInstance } from "@hopper-b2b/api";

import Logger from "../../../helpers/Logger";
import { fetchFlightByIdPath } from "../paths";

export const fetchFlightById = async (
  itineraryId: string,
  referenceDateTime: string
): Promise<SingleFlightItineraryResponse> => {
  try {
    const res = await axiosInstance.put<SingleFlightItineraryResponse>(
      fetchFlightByIdPath,
      {
        itineraryId,
        referenceDateTime,
      }
    );

    if (res.data.itinerary) {
      return res.data;
    }
  } catch (err) {
    Logger.debug(err);

    throw err;
  }
};
