import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { B2B_PORTAL_AUTH_REDIRECT_TO } from "@hopper-b2b/types";
import {
  GetSessionInfoResponse,
  GetSessionInfoResponseEnum,
} from "@b2bportal/commbank-au-auth-api";
import {
  PATH_AUTH_INVALID_SESSION,
  PATH_INACTIVE_ERROR,
} from "../../../utils/urlPaths";
import { SessionContext } from "../../../Root";
import { fetchSession } from "../../../api/fetchSession";
import { AccountsContext } from "@commbank/accounts";
import { datadogRum } from "@datadog/browser-rum";

const ProtectedRoute = (props: PropsWithChildren) => {
  const { children } = props;
  const [authenticated, setAuthenticated] = useState(false);
  const { sessionInfo, setSessionInfo } = useContext(SessionContext);
  const navigate = useNavigate();
  const { refetchAccounts } = useContext(AccountsContext);

  useEffect(() => {
    const userInfoCall = async () => {
      try {
        const response: GetSessionInfoResponse = await fetchSession();
        switch (response.GetSessionInfoResponse) {
          case GetSessionInfoResponseEnum.Session:
            setSessionInfo({
              userInfo: {
                firstName: response.sessionInfo.displayName.split(" ")[0],
                lastName: response.sessionInfo.displayName.split(" ")[1],
                email: response.sessionInfo.email,
              },
              isFirstSession: false,
              kustomerAccessToken: response.kustomerAccessToken,
              hopperUserId: response.sessionInfo.hopperUserId,
            });
            setAuthenticated(true);
            if (datadogRum) {
              datadogRum.setUser({
                id: response.sessionInfo.hopperUserId,
              });
            }
            break;
          case GetSessionInfoResponseEnum.GetSessionFailed: {
            const redirectUrl = `${window.location.pathname}${window.location.search}`;
            if (!redirectUrl.includes("/auth/")) {
              sessionStorage.setItem(B2B_PORTAL_AUTH_REDIRECT_TO, redirectUrl);
            }
            // TODO: before deploying to prod, this should go back to nubank app
            navigate(PATH_AUTH_INVALID_SESSION);
            break;
          }
        }
      } catch (err) {
        const redirectUrl = `${window.location.pathname}${window.location.search}`;
        if (!redirectUrl.includes("/auth/")) {
          sessionStorage.setItem(B2B_PORTAL_AUTH_REDIRECT_TO, redirectUrl);
        }
        // TODO: before deploying to prod, this should go back to commbank app
        navigate(PATH_AUTH_INVALID_SESSION);
      }
    };
    // userInfo validates both CBA and Hopper sessions, returning the CBA User Details if both are valid.
    if (!authenticated) userInfoCall();
  }, [authenticated, navigate, sessionInfo, setSessionInfo]);

  useEffect(() => {
    if (authenticated) {
      (async () => {
        try {
          const { accounts } = await refetchAccounts();

          // inactive account route to corresponding error page
          if (!accounts.length || !accounts[0].cardAccountId) {
            navigate(PATH_INACTIVE_ERROR);
          }
        } catch (error) {
          navigate(PATH_INACTIVE_ERROR);
        }
      })();
    }
  }, [authenticated, navigate, refetchAccounts]);

  return authenticated ? <>{children}</> : null;
};

export default ProtectedRoute;
