import { default as dayjs } from "dayjs";
import { useCallback, useState, useRef, useEffect } from "react";
import { type GuestsSelection, TripCategory } from "@hopper-b2b/types";
import { type Suggestion } from "@b2bportal/lodging-api";
import { formatDateForUrl } from "../../../../util";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  HotelLocationAutoComplete,
  CalendarPickerButton,
  GuestPicker,
} from "../../../search";
import { Box, Button, Divider, Link, Typography } from "@material-ui/core";
import { ApacIcon, ApacIconName } from "@commbank/ui";
import clsx from "clsx";

interface SearchLodgingProps {
  initialDestination?: Suggestion;
  initialCheckinDate: string;
  initialCheckoutDate: string;
  initialGuestCount?: GuestsSelection;
  forceCalendarPicker?: boolean;
  onCalendarClosed: () => void;
  onSearch: (value: SearchSubmit) => void;
}

type FormSubmission = {
  nextDestination: Suggestion;
  nextCheckinDate: string;
  nextCheckoutDate: string;
  nextGuestCount: GuestsSelection;
};

export type SearchSubmit = {
  nextDestination?: Suggestion;
  nextFromDate: string;
  nextUntilDate: string;
  nextAdultsCount: number;
  nextChildrenCount: number;
  nextRoomsCount: number;
};

export const SearchLodging = ({
  initialDestination,
  initialCheckinDate,
  initialCheckoutDate,
  initialGuestCount,
  forceCalendarPicker,
  onCalendarClosed,
  onSearch,
}: SearchLodgingProps) => {
  const { t } = useI18nContext();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [destination, setDestination] =
    useState<Suggestion>(initialDestination);
  const [checkinDate, setCheckinDate] = useState<string>(
    dayjs(initialCheckinDate).toDate().toString()
  );
  const [checkoutDate, setCheckoutDate] = useState<string | undefined>(
    dayjs(initialCheckoutDate).toDate().toString()
  );
  const prevCheckinDate = useRef(checkinDate);
  const prevCheckoutDate = useRef(checkoutDate);

  const [guestCount, setGuestCount] =
    useState<GuestsSelection>(initialGuestCount);

  const handleCheckinDate = useCallback(
    (date: Date) => {
      if (date) {
        setCheckinDate(date.toString());
        if (checkoutDate) {
          setCheckoutDate(undefined);
        }
      }
    },
    [checkoutDate]
  );

  useEffect(() => {
    if (forceCalendarPicker) {
      setEditMode(true);
      setOpenCalendar(true);
    }
  }, [forceCalendarPicker]);

  useEffect(() => {
    if (!openCalendar) {
      onCalendarClosed();
    }
  }, [openCalendar]);
  const handleCheckoutDate = useCallback((date: Date) => {
    if (date) {
      setCheckoutDate(date.toString());
    }
  }, []);

  const handleSubmit = useCallback(
    ({
      nextDestination = destination,
      nextCheckinDate = checkinDate,
      nextCheckoutDate = checkoutDate,
      nextGuestCount = guestCount,
    }: Partial<FormSubmission> = {}) => {
      if (!nextCheckinDate || !nextCheckoutDate || !nextGuestCount) {
        return;
      }

      onSearch({
        nextDestination: nextDestination,
        nextFromDate: formatDateForUrl(nextCheckinDate),
        nextUntilDate: formatDateForUrl(nextCheckoutDate),
        nextAdultsCount: nextGuestCount.adults,
        nextChildrenCount: nextGuestCount.children.length,
        nextRoomsCount: nextGuestCount.rooms,
      });
    },
    [checkinDate, checkoutDate, guestCount, destination, onSearch]
  );

  const handleOnComplete = () => {
    // handleSubmit();
    setOpenCalendar(false);
  };

  const onCalendarCLick = () => {
    /**
     * Changing date will update the state before clicking on done;
     * So we save the previous values when the calendar open in order
     * to reset them if the user closes the calendar.
     */
    prevCheckinDate.current = checkinDate;
    prevCheckoutDate.current = checkoutDate;
    setOpenCalendar(true);
  };

  const handleCloseCalendar = () => {
    setCheckinDate(prevCheckinDate.current);
    setCheckoutDate(prevCheckoutDate.current);
    setOpenCalendar(false);
  };

  return (
    <Box className="search-details-container">
      <ApacIcon className="home-icon" name={ApacIconName.CBAHome} />
      {editMode ? (
        <Box className="search-form">
          <HotelLocationAutoComplete
            // This is a hacky way to reset the defaultValue
            key={
              initialDestination ? "destination-loaded" : "loading-destination"
            }
            className="search-location"
            id="availability-destination"
            label={t?.("whereAreYouStaying")}
            onChange={(nextDestination) => {
              if (nextDestination) {
                setDestination(nextDestination);
              }
            }}
            defaultValue={initialDestination}
          />
          <CalendarPickerButton
            depDate={checkinDate ? new Date(checkinDate) : null}
            retDate={checkoutDate ? new Date(checkoutDate) : null}
            classes={["dates"]}
            tripCategory={TripCategory.ROUND_TRIP}
            onComplete={handleOnComplete}
            onClick={onCalendarCLick}
            openCalendar={openCalendar}
            setDepartureDate={handleCheckinDate}
            setReturnDate={handleCheckoutDate}
            closeCalendar={handleCloseCalendar}
            startLabel={t?.("checkin")}
            endLabel={t?.("checkout")}
            headerTitle={t?.("commBank.hotelCalendarPicker.title")}
            headerSubtitle={t?.("commBank.hotelCalendarPicker.subtitle")}
            useDialog
            isHotel
          />
          <GuestPicker
            className="guests"
            initialGuestCount={initialGuestCount}
            updateGuestCount={(nextGuestCount) => {
              setGuestCount(nextGuestCount);
            }}
            useDialog
          />
          <Button
            className="search-button"
            variant="outlined"
            onClick={() => handleSubmit()}
          >
            {t("commBank.hotelAvailability.header.searchButtonLabel")}
          </Button>
        </Box>
      ) : (
        <Box className="search-details-wrapper">
          <Box className="search-location">{initialDestination?.label}</Box>
          <Box className="date-pickers">
            <Typography color="textSecondary">
              {dayjs(checkinDate).format("ddd D MMM")} -{" "}
              {dayjs(checkoutDate).format("ddd D MMM")}
            </Typography>
          </Box>
          <Divider className="divider" orientation="vertical" flexItem />
          <Box>
            <Typography color="textSecondary" variant="body1">
              {guestCount.children.length > 0
                ? t(
                    "commBank.hotelAvailability.header.travelersDescriptionNoRoom",
                    {
                      adults: t("commBank.hotelAvailability.header.adults", {
                        count: guestCount.adults,
                      }),
                      children: t(
                        "commBank.hotelAvailability.header.children",
                        {
                          count: guestCount.children.length,
                        }
                      ),
                    }
                  )
                : t("commBank.hotelAvailability.header.adults", {
                    count: guestCount.adults,
                  })}
            </Typography>
          </Box>
          <Divider className="divider" orientation="vertical" flexItem />
        </Box>
      )}
      <Link
        className={clsx("edit-search-details", { expanded: editMode })}
        onClick={() => setEditMode(!editMode)}
      >
        <Typography color="inherit" variant="body1">
          {editMode ? "Hide" : "Edit"}
        </Typography>
      </Link>
    </Box>
  );
};
