import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../../../reducers/types";
import { deleteWatch } from "../../../../../../../TripsList/actions/actions";
import {
  getDeleteWatchCallState,
  getOpenModal,
  getWatchContext,
} from "../../../../../../../TripsList/reducer";
import { StopWatchingModalContent } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  openModal: getOpenModal(state),
  deleteWatchCallState: getDeleteWatchCallState(state),
  context: getWatchContext(state),
});

const mapDispatchToProps = {
  deleteWatch,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type StopWatchingModalContentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedStopWatchingModalContent = connector(
  StopWatchingModalContent
);
