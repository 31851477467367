import { PriceDropLineAndModal } from "../../../../components/PriceDropLineAndModal";
import {
  ApacActionButton,
  ApacLoadingModal,
  ApacMobileContinueButton,
} from "@commbank/ui";
import { Person } from "@b2bportal/air-booking-api";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  PRICE_DROP_VIEWED,
  PriceDropProtectionEnum,
  VIEWED_TRIP_SUMMARY,
} from "@hopper-b2b/types";
import { getIsMixedClass } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { getPointsPriceFormatted } from "../../../../../../utils/getRewardsText";
import {
  useGoToCheckout,
  useGoToNextStep,
} from "../../hooks/flightShopNavigationHooks";
import "./FlightShopReviewItinerary.styles.scss";
import { ItineraryReviewContent } from "./components/ItineraryReviewContent";
import { FlightShopReviewItineraryConnectorProps } from "./container";

export interface IFlightShopReviewItineraryProps
  extends FlightShopReviewItineraryConnectorProps {
  importantInfo?: string[];
  isMobile: boolean;
  showDivider?: boolean;
  onContinue?: () => void;
  passengers?: Person[];
  priceSummaryChevronIcon?: ReactNode;
  primaryButtonText?: string;
  viewPriceBreakdown?: () => void;
}

//TODO: This needs broken up into logical sense, Here be Dragons!
export const FlightShopReviewItinerary = (
  props: IFlightShopReviewItineraryProps
) => {
  const {
    tripDetailsLoading,
    isOneWay,
    departureDate,
    returnDate,
    tripDetails,
    fareDetails,
    selectedFarePricing: pricing,
    selectedRewardsAccount,
    isMobile,
    isMultiTicket,
    airports,
    prediction,
    viewedTripSummaryProperties,
    onContinue,
    priceDropViewedProperties,
  } = props;

  const history = useHistory();
  const { t, formatFiatCurrency } = useI18nContext();

  const [isOutgoingMixedClass, setIsOutgoingMixedClass] = useState(false);
  const [isReturnMixedClass, setIsReturnMixedClass] = useState(false);

  useEffect(() => {
    trackEvent({
      eventName: VIEWED_TRIP_SUMMARY,
      properties: {
        ...viewedTripSummaryProperties,
      },
    });
  }, [pricing, viewedTripSummaryProperties]);

  const goToNextStep = useGoToNextStep();
  const goToCheckout = useGoToCheckout();

  const isPDPEligible =
    prediction?.priceDropProtection?.PriceDropProtection ===
    PriceDropProtectionEnum.IsEligible;

  useEffect(() => {
    if (isPDPEligible) {
      trackEvent({
        eventName: PRICE_DROP_VIEWED,
        properties: {
          ...priceDropViewedProperties,
          page: "trip_summary",
        },
      });
    }
  }, [isPDPEligible, priceDropViewedProperties]);

  useEffect(() => {
    if (fareDetails) {
      setIsOutgoingMixedClass(getIsMixedClass(fareDetails.slices[0]));
      !isOneWay &&
        setIsReturnMixedClass(getIsMixedClass(fareDetails.slices[1]));
    }
  }, [fareDetails, isOneWay]);

  const rewardsPriceString = useMemo(
    () =>
      getPointsPriceFormatted({
        pricing,
        selectedAccount: selectedRewardsAccount,
      }),
    [pricing, selectedRewardsAccount]
  );

  if (!tripDetails || !fareDetails || !fareDetails?.paxPricings) {
    history.push(history.location);
    return null;
  }

  const renderSummaryContent = () => (
    <Box className="summary-info-section">
      <Box className="price-container">
        <div className="price-wrapper">
          <Typography variant="body1" className="total-price">
            {formatFiatCurrency(pricing?.total?.fiat)}
          </Typography>
          {rewardsPriceString && (
            <Typography variant="body2">
              {t("flightShop.orAwardPoints", {
                points: rewardsPriceString,
              })}
            </Typography>
          )}
        </div>
        <div className="description-wrapper">
          <Typography variant="body2">
            {isOneWay
              ? `(${t("oneWayPerTraveler")}, ${t(
                  "lodgingShopDetails.includingFees"
                )})`
              : `(${t("roundTripPerTraveler")}, ${t(
                  "lodgingShopDetails.includingFees"
                )})`}
          </Typography>
        </div>
      </Box>

      <PriceDropLineAndModal
        isPDPEligible={isPDPEligible}
        prediction={prediction}
      />
    </Box>
  );

  return (
    <Box className={clsx("flight-shop-review-itinerary-root")}>
      {tripDetailsLoading ? (
        <ApacLoadingModal open={true} />
      ) : (
        <div
          container
          spacing={4}
          className={clsx("itinerary-content-container", {
            mobile: isMobile,
          })}
        >
          <Box className="itinerary-details-container">
            <ItineraryReviewContent
              isMultiTicket={isMultiTicket}
              isOneWay={isOneWay}
              departureDate={departureDate}
              returnDate={returnDate}
              fareDetails={fareDetails}
              tripDetails={tripDetails}
              airports={airports}
              isOutgoingMixedClass={isOutgoingMixedClass}
              isReturnMixedClass={isReturnMixedClass}
            />
          </Box>

          {isMobile ? (
            <ApacMobileContinueButton
              className="itinerary-summary-container"
              handleContinue={goToNextStep}
            >
              {renderSummaryContent()}
            </ApacMobileContinueButton>
          ) : (
            <Box className="itinerary-summary-container">
              {renderSummaryContent()}
              <Box className="summary-button-section">
                <ApacActionButton
                  className="continue-button"
                  onClick={() => {
                    if (onContinue) {
                      onContinue();
                    } else {
                      goToCheckout();
                    }
                  }}
                  message={t("continue")}
                />
              </Box>
            </Box>
          )}
        </div>
      )}
    </Box>
  );
};
