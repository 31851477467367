import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import {
  commbankAuAuthApi,
  PrepareLoginResponse,
} from "@b2bportal/commbank-au-auth-api";
import { trackEvent, axiosInstance } from "@hopper-b2b/api";

export const loginPrepare = async (
  config?: IApiConfig
): Promise<PrepareLoginResponse> => {
  try {
    const res = await commbankAuAuthApi(
      axiosInstance
    ).apiV0AuthLoginPrepareGet();
    return res.data;
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      config
    );
    throw e;
  }
};
