import { State } from "xstate";
import {
  emailRegex,
  parsePhoneNumber,
  phoneRegex,
} from "@hopper-b2b/utilities";
import { ParentState } from "@checkout/types";
import { PartialParentWithContact } from "./context";

type ContactStateType = State<PartialParentWithContact>;
type ContactStateWithoutValue = Pick<ContactStateType, "context">;

export const getContactVisited = ({ context }: ContactStateWithoutValue) =>
  context[ParentState.contactInformation]?.visited;

export const getEmailAddress = ({ context }: ContactStateWithoutValue) =>
  context[ParentState.contactInformation]?.contactInfo?.email;

export const getContactInformation = ({
  context,
}: ContactStateType | ContactStateWithoutValue) => {
  return {
    countryCode:
      context[ParentState.contactInformation].contactInfo?.countryCode || "",
    phoneNumber:
      context[ParentState.contactInformation].contactInfo?.phoneNumber || "",
    email: context[ParentState.contactInformation].contactInfo?.email || "",
  };
};

export const getFormattedPhoneNumber = ({
  context,
}: ContactStateType | ContactStateWithoutValue) => {
  const { countryCode: ctxCountryCode, phoneNumber: ctxPhoneNumber } =
    getContactInformation({ context });

  if (!ctxCountryCode || !ctxPhoneNumber) return "";

  // Remove any local prefixes/suffixes such as leading zeros
  const { countryCode, nationalNumber } = parsePhoneNumber(
    `${ctxCountryCode}${ctxPhoneNumber}`
  );
  return `+${countryCode}${nationalNumber}`;
};

export const getContactInformationValidated = ({
  context,
}: ContactStateType | ContactStateWithoutValue) => {
  const contactInfo = context[ParentState.contactInformation].contactInfo;
  return (
    contactInfo?.email &&
    contactInfo.phoneNumber &&
    emailRegex.test(contactInfo.email) &&
    phoneRegex.test(contactInfo.phoneNumber)
  );
};
