import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ComponentType } from "react";
import "./ApacLoadingIndicator.styles.scss";
import { CBASpinner } from "../CBASpinner";

export interface ILoadingIndicatorProps {
  className?: string;
  message: string;
  indicator?: ComponentType;
  indicatorProps?: any;
  indicatorSize?: "small" | "medium" | "large";
  popupSize?: "small" | "regular";
  footer?: ComponentType<{ className: string }>;
  textAlign?: "left" | "center" | "right";
}

const LoadingIndicator = ({
  className,
  message,
  indicator = CBASpinner,
  indicatorProps,
  indicatorSize,
  footer,
  textAlign,
}: ILoadingIndicatorProps) => {
  const Footer = footer;
  const Indicator = indicator;
  const indicatorClasses = indicatorSize
    ? ["loading-indicator", indicatorSize]
    : ["loading-indicator"];

  return (
    <Box className={clsx(className, "apac-loading-indicator-content-wrapper")}>
      <Box
        className={clsx({
          "apac-loading-indicator-content": true,
          "text-align-left": textAlign === "left",
          "text-align-center": textAlign === "center",
          "text-align-right": textAlign === "right",
        })}
      >
        <Indicator classes={indicatorClasses} {...indicatorProps} />
        <Typography variant="body1">{message}</Typography>
      </Box>
      {!!Footer && <Footer className="loading-indicator-footer" />}
    </Box>
  );
};

export default LoadingIndicator;
