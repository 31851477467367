import { IApiConfig } from "@hopper-b2b/types";
import { IFlightBookState } from "../modules/book/reducer";
import { IFlightCfarState } from "../modules/cfar/reducer";
import { IFlightChfarState } from "../modules/chfar/reducer";
import { IFlightDisruptionState } from "../modules/disruption/reducer";
import { IFlightExchangeState } from "../modules/exchange/reducer";
import { IFlightFreezeState } from "../modules/freeze/reducer";
import { IFlightRewardsState } from "../modules/rewards/reducer";
import { IFlightSearchState } from "../modules/search/reducer";
import { IFlightShopState } from "../modules/shop/reducer";
import { IFlightWalletState } from "../modules/wallet/reducer";

export const RESET_STATE = "RESET_STATE";
export type RESET_STATE = typeof RESET_STATE;

export const flightApiConfigStoreKey = "flightApiConfig";

export interface IStoreState {
  flightSearch: IFlightSearchState;
  flightShop: IFlightShopState;
  flightBook: IFlightBookState;
  flightRewards: IFlightRewardsState;
  flightFreeze: IFlightFreezeState;
  flightDisruption: IFlightDisruptionState;
  flightCfar: IFlightCfarState;
  flightApiConfig: IApiConfig;
  flightChfar: IFlightChfarState;
  flightExchange: IFlightExchangeState;
  flightWallet: IFlightWalletState;
}
