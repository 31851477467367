import {
  AirCancelQuoteRequest,
  AirCancelQuoteResponse,
  AirCancelQuoteResponseEnum,
} from "@b2bportal/air-cancel-api";
import { NETWORK_CALL_FAILED, Uuid } from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { getFlightCancelQuotePath } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

/**
 * @description The first of two endpoints in the self serve cancel flight flow
 * @param {Uuid} reservationId The flight itinerary id
 * @return {AirCancelQuoteResponse} The cancellation info for the itinerary passed in
 */
const getFlightCancelQuote = async (
  reservationId: Uuid
): Promise<AirCancelQuoteResponse> => {
  try {
    const req: AirCancelQuoteRequest = {
      reservationId,
    };
    const res = await axiosInstance.post(getFlightCancelQuotePath, req);
    const { data } = res;

    if (
      data.AirCancelQuoteResponse ===
      AirCancelQuoteResponseEnum.AirCancelQuoteFailure
    ) {
      throw data;
    }

    return data;
  } catch (err) {
    if (!(err as AirCancelQuoteResponse).AirCancelQuoteResponse) {
      trackEvent({
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      });
    }

    throw err;
  }
};

export default getFlightCancelQuote;
