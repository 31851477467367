import {
  ApacActionButton,
  ApacActionLink,
  ApacDesktopPopupModal,
  ApacIcon,
  ApacIconName,
  CalendarPicker,
  InputWrapper,
  PassengerCountPicker,
} from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  IPassengerCounts,
  ITripTerminus,
  TripCategory,
} from "@hopper-b2b/types";
import { ActionLink } from "@hopper-b2b/ui";
import { tenantFlagsEnabled } from "@hopper-b2b/utilities";
import { Box, Button, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { PATH_SHOP } from "../../../../utils/urlPaths";
import { useFetchFlights } from "../../../shop/hooks/useFetchFlights";
import "./DesktopFlightSearchControl.styles.scss";
import { DateSelectButton } from "./components/DateSelectButton";
import {
  DestinationAutocomplete,
  OriginAutocomplete,
} from "./components/TerminusAutocomplete";
import { DesktopFlightSearchControlConnectorProps } from "./container";
import { getPassengerCountsString } from "@commbank/utilities";
import { setPassengerCounts } from "../../actions/actions";
import { useDispatch } from "react-redux";
import { END_DATE, FocusedInput, START_DATE } from "@datepicker-react/hooks";

export const APAC_MAX_PASSENGER_COUNT = 9;
export const APAC_MAX_CHILDREN_COUNT = 6;

export type DesktopFlightSearchControlProps =
  DesktopFlightSearchControlConnectorProps & {
    isSearchBar?: boolean;
  };

export const DesktopFlightSearchControl = ({
  origin,
  destination,
  departureDate,
  returnDate,
  maxPrice,
  populateSearchUrlParams,
  fetchDepartureCalendar,
  setCalendar,
  setDepartureDate,
  setReturnDate,
  passengerCounts,
  tripCategory,
  setCalendarVisited,
  calendarVisited,
  trackingProperties,
  isSearchBar,
  flightShopCalendarOpen,
  setFlightShopCalendarOpen,
  isSearchBarEditMode,
  setIsSearchBarEditMode,
  setOriginCategories,
  setDestinationCategories,
  resetFilters,
  months,
  priceTags,
}: DesktopFlightSearchControlProps) => {
  const [originFocused, setOriginFocused] = useState(false);
  const [destinationFocused, setDestinationFocused] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openPassengerCountPicker, setOpenPassengerCountPicker] =
    useState(false);
  const [searchChanged, setSearchChanged] = useState(false);

  const [leavingFromError, setLeavingFromError] = useState<string>(null);
  const [goingToError, setGoingToError] = useState<string>(null);
  const [departingDateError, setDepartingDateError] = useState<string>(null);
  const [returningDateError, setReturningDateError] = useState<string>(null);

  const { t } = useI18nContext();
  const history = useHistory();
  const { search } = useLocation();

  const fetchFlights = useFetchFlights();

  const onPassengerUpdate = useCallback(
    (passengerCounts: IPassengerCounts) => {
      populateSearchUrlParams({ passengers: passengerCounts }, history);
    },
    [history, populateSearchUrlParams]
  );

  const updateTripCategory = (value: TripCategory, searchChanged = false) => {
    populateSearchUrlParams({ tripCategory: value }, history);
    if (searchChanged) {
      setSearchChanged(true);
    }
  };

  useEffect(() => {
    if (
      tenantFlagsEnabled.FlightSearchCalendarPrediction &&
      origin?.id &&
      destination?.id
    ) {
      fetchDepartureCalendar();
    } else {
      setCalendar();
    }
  }, [fetchDepartureCalendar, setCalendar, origin?.id, destination?.id]);

  // Dont make a callback, should only run once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const populateParams = () => {
    const searchParams = new URLSearchParams(history.location.search);
    const isFromFlightWatch = searchParams.get("isFromFlightWatch") === "true";
    if (isFromFlightWatch) {
      fetchFlights();
    } else if (origin && destination) {
      populateSearchUrlParams(
        {
          origin,
          destination,
          tripCategory,
          departureDate,
          returnDate,
          passengers: passengerCounts,
        },
        history
      );
    }
  };

  useEffect(() => {
    // Ensures URL params are set to current state on redirect to landing page
    // - only important for page redirect not first page load, as tracks existing state
    populateParams();
  }, []);

  const onOriginSelected = useCallback(
    (value: ITripTerminus | null) => {
      if (value !== null && value.id.code.code !== destination?.id.code.code) {
        populateSearchUrlParams({ origin: value }, history);
      }
    },
    [destination, history, populateSearchUrlParams]
  );

  const onDestinationSelected = useCallback(
    (value: ITripTerminus | null) => {
      if (value !== null && value.id.code.code !== origin?.id.code.code) {
        populateSearchUrlParams({ destination: value }, history);
      }
    },
    [history, origin, populateSearchUrlParams]
  );

  const onClickSearch = () => {
    if (verifyInput()) {
      resetFilters(maxPrice);
      history.push(`${PATH_SHOP}${search}`);
    }
  };

  /**
   * Verify input, return is ready to search or not. set error message if input invalid.
   */
  const verifyInput = () => {
    let isReady = true;
    //verify input
    if (!origin) {
      isReady = false;
      setLeavingFromError(t("commBank.search.flights.leavingFromError"));
    }
    if (!destination) {
      isReady = false;
      setGoingToError(t("commBank.search.flights.goingToError"));
    }
    if (!departureDate) {
      isReady = false;
      setDepartingDateError(t("commBank.search.flights.departingDateError"));
    }
    if (!isOneWay && !returnDate) {
      isReady = false;
      setReturningDateError(t("commBank.search.flights.returningDateError"));
    }

    return isReady;
  };

  const onOriginFocused = () => {
    setOriginFocused(true);
    setLeavingFromError(null);
  };

  const onDestinationFocused = () => {
    setDestinationFocused(true);
    setGoingToError(null);
  };

  const onOriginBlur = () => {
    setOriginFocused(false);
    setOriginCategories([]);
  };

  const onDestinationBlur = () => {
    setDestinationFocused(false);
    setDestinationCategories([]);
  };

  const handleOpenCalendar = () => {
    setOpenCalendar(true);
    setDepartingDateError(null);
    setReturningDateError(null);
  };

  const getOptionSelected = (
    option: ITripTerminus | null,
    value: ITripTerminus | null
  ) => {
    return !!value && !!option && value.id.code === option.id.code;
  };

  const isOneWay = useMemo(
    () => tripCategory === TripCategory.ONE_WAY,
    [tripCategory]
  );

  const [defaultFocusedInput, setDefaultFocusedInput] =
    useState<FocusedInput>(START_DATE);

  const dispatch = useDispatch();

  const guestPickerModal = (isSearchBar?: boolean) => (
    <ApacDesktopPopupModal
      open={openPassengerCountPicker}
      onClose={() => {
        setOpenPassengerCountPicker(false);
      }}
      className="desktop-flight-shop-modal passenger-picker-modal"
      contentClassName="modal-content"
    >
      <PassengerCountPicker
        minimumCount={1}
        maximumCount={APAC_MAX_PASSENGER_COUNT}
        maximumChildren={APAC_MAX_CHILDREN_COUNT}
        onClickApply={(v) => {
          onPassengerUpdate(v);
          dispatch(setPassengerCounts(v));
          setOpenPassengerCountPicker(false);
          isSearchBar && setSearchChanged(true);
        }}
        counts={passengerCounts}
        titles={{
          modalSubtitle: t("flightsPageTitles.travellersWarning", {
            maxTravellers: APAC_MAX_PASSENGER_COUNT,
          }),
          adultTitle: t("passengerCount.adultTitle"),
          childrenTitle: t("passengersCounterTitles.childrenTitle"),
          childrenSubtitle: t("passengersCounterTitles.childrenSubtitle"),
          infantLapTitle: t("passengersCounterTitles.infantLapTitle"),
          infantLapSubtitle: t("passengersCounterTitles.infantLapSubtitle"),
          infantSeatTitle: t("passengersCounterTitles.infantSeatTitle"),
          infantSeatSubtitle: t("passengersCounterTitles.infantSeatSubtitle"),

          maxPassengerCount: t("passengerCount.errors.maxPassengerCount", {
            maxTravellers: APAC_MAX_PASSENGER_COUNT,
          }),
          maxChildrenCount: t("passengerCount.errors.maxChildrenCount", {
            maximumChildren: APAC_MAX_CHILDREN_COUNT,
          }),
          minAdultPerInfant: t("passengerCount.errors.minAdultPerInfant"),
        }}
        showApplyButton
        isFlight
        onClose={() => setOpenPassengerCountPicker(false)}
      />
    </ApacDesktopPopupModal>
  );

  return isSearchBar ? (
    <Box className="desktop-flight-search-control-bar-container">
      <div className="bar-content-container">
        {isSearchBarEditMode ? (
          <Box className="search-edit">
            <Box className="trip-category-container">
              <div className="left">
                <ApacIcon name={ApacIconName.CBAPlane} />
                <Button
                  className={clsx("trip-category-button", "round-trip", {
                    selected: !isOneWay,
                  })}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      updateTripCategory(TripCategory.ROUND_TRIP, true);
                    }
                  }}
                  onClick={() =>
                    updateTripCategory(TripCategory.ROUND_TRIP, true)
                  }
                >
                  {t("roundTripPriceSummary")}
                </Button>
                <Button
                  className={clsx("trip-category-button", "round-trip", {
                    selected: isOneWay,
                  })}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      updateTripCategory(TripCategory.ONE_WAY, true);
                    }
                  }}
                  onClick={() => updateTripCategory(TripCategory.ONE_WAY, true)}
                >
                  {t("oneWayPriceSummary")}
                </Button>
              </div>

              <ActionLink
                className="hide-button"
                content={t("hide")}
                onClick={() => {
                  setIsSearchBarEditMode(false);
                  if (searchChanged) {
                    fetchFlights();
                    setSearchChanged(false);
                  }
                }}
              />
            </Box>

            <Box className="location-date-picker-container">
              <Box className="location-picker">
                <Typography className="picker-label">
                  {t("commBank.search.flights.leavingFrom")}
                </Typography>
                <InputWrapper error={leavingFromError}>
                  <OriginAutocomplete
                    className={clsx("origin-auto-complete", {
                      error: leavingFromError,
                    })}
                    label={t("commBank.search.flights.hint")}
                    value={origin}
                    hideFloatingLabel={!!origin || originFocused}
                    popupIcon={<ApacIcon name={ApacIconName.MagnifyingGlass} />}
                    hideIcon
                    customClearIcon={undefined}
                    onFocus={onOriginFocused}
                    onBlur={onOriginBlur}
                    setValue={(v) => {
                      onOriginSelected(v);
                      setSearchChanged(true);
                    }}
                    loadingText={`${t("searching")}...`}
                    noOptionsText={<div>{t("noOptions")}</div>}
                    getOptionSelected={getOptionSelected}
                  />
                </InputWrapper>
              </Box>
              <Box className="location-picker">
                <Typography className="picker-label">
                  {t("commBank.search.flights.leavingFrom")}
                </Typography>
                <InputWrapper error={goingToError}>
                  <DestinationAutocomplete
                    className={clsx("destination-auto-complete", {
                      error: goingToError,
                    })}
                    label={t("commBank.search.flights.hint")}
                    value={destination}
                    loadingText={`${t("searching")}...`}
                    noOptionsText={<div>{t("noOptions")}</div>}
                    hideFloatingLabel={!!destination || destinationFocused}
                    popupIcon={<ApacIcon name={ApacIconName.MagnifyingGlass} />}
                    hideIcon
                    setValue={(v) => {
                      onDestinationSelected(v);
                      setSearchChanged(true);
                    }}
                    onFocus={onDestinationFocused}
                    onBlur={onDestinationBlur}
                    getOptionSelected={getOptionSelected}
                  />
                </InputWrapper>
              </Box>

              <DateSelectButton
                date={departureDate}
                label={t("commBank.search.flights.departing")}
                onClick={() => {
                  setFlightShopCalendarOpen(true);
                  setDefaultFocusedInput(START_DATE);
                  handleOpenCalendar();
                }}
                error={departingDateError}
              />
              {!isOneWay && (
                <DateSelectButton
                  date={returnDate}
                  label={t("commBank.search.flights.returning")}
                  onClick={() => {
                    setFlightShopCalendarOpen(true);
                    setDefaultFocusedInput(END_DATE);
                    handleOpenCalendar();
                  }}
                  error={returningDateError}
                />
              )}
            </Box>

            <Box className="search-line-container">
              <Box
                className="passenger-picker-container"
                onClick={() => setOpenPassengerCountPicker(true)}
              >
                <ApacActionLink
                  message={getPassengerCountsString({ ...passengerCounts, t })}
                />
                <ApacIcon name={ApacIconName.EditPen} />
              </Box>
              <ApacActionButton
                className="search-button"
                message={t("searchButton")}
                onClick={() => {
                  if (verifyInput()) {
                    resetFilters(maxPrice);
                    fetchFlights();
                    setIsSearchBarEditMode(false);
                    setSearchChanged(false);
                  }
                }}
              />

              {guestPickerModal(true)}
            </Box>
          </Box>
        ) : (
          <Box className="search-view">
            <ApacIcon name={ApacIconName.CBAPlane} className="cba-plane" />
            <Typography className="trip-category">
              {isOneWay ? t("oneWayPriceSummary") : t("roundTripPriceSummary")}
            </Typography>
            <div className="stop-container">
              <div className="stop-wrapper">
                <Typography>{origin?.label}</Typography>
                <Typography className="subtitle">
                  {dayjs(departureDate).format("ddd DD MMM YYYY")}
                </Typography>
              </div>
              <ApacIcon name={ApacIconName.AirPlane} />

              <div className="stop-wrapper">
                <Typography>{destination?.label}</Typography>
                <Typography
                  className={clsx("subtitle", { invisible: isOneWay })}
                >
                  {dayjs(returnDate).format("ddd DD MMM YYYY")}
                </Typography>
              </div>
            </div>

            <div className="passenger-counts-container">
              {getPassengerCountsString({ ...passengerCounts, t })}
            </div>

            <ActionLink
              className="edit-button"
              content={t("edit")}
              onClick={() => setIsSearchBarEditMode(true)}
            />
          </Box>
        )}
        <CalendarPicker
          open={flightShopCalendarOpen}
          tripCategory={tripCategory}
          departureDate={departureDate}
          returnDate={returnDate}
          setDepartureDate={(v) => {
            setDepartureDate(v);
            setSearchChanged(true);
          }}
          setReturnDate={(v) => {
            setReturnDate(v);
            setSearchChanged(true);
          }}
          months={months}
          priceTags={priceTags}
          calendarVisited={calendarVisited}
          setCalendarVisited={setCalendarVisited}
          populateSearchUrlParams={(params, history) => {
            populateSearchUrlParams(params, history as any);
          }}
          closePopup={() => setFlightShopCalendarOpen(false)}
          trackingProperties={trackingProperties}
          defaultFocusedInput={defaultFocusedInput}
        />
      </div>
    </Box>
  ) : (
    <Box className="desktop-flight-search-root">
      <Box className="trip-category-container">
        <ApacIcon name={ApacIconName.CBAPlane} />
        <Button
          className={clsx("trip-category-button", "round-trip", {
            selected: !isOneWay,
          })}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              updateTripCategory(TripCategory.ROUND_TRIP);
            }
          }}
          onClick={() => updateTripCategory(TripCategory.ROUND_TRIP)}
        >
          {t("roundTripPriceSummary")}
        </Button>
        <Button
          className={clsx("trip-category-button", "round-trip", {
            selected: isOneWay,
          })}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              updateTripCategory(TripCategory.ONE_WAY);
            }
          }}
          onClick={() => updateTripCategory(TripCategory.ONE_WAY)}
        >
          {t("oneWayPriceSummary")}
        </Button>
      </Box>

      <Box className="location-date-picker-container">
        <Box className="location-picker">
          <Typography className="picker-label">
            {t("commBank.search.flights.leavingFrom")}
          </Typography>
          <InputWrapper error={leavingFromError}>
            <OriginAutocomplete
              className={clsx("origin-auto-complete", {
                error: leavingFromError,
              })}
              label={t("commBank.search.flights.hint")}
              value={origin}
              hideFloatingLabel={!!origin || originFocused}
              popupIcon={<ApacIcon name={ApacIconName.MagnifyingGlass} />}
              hideIcon
              customClearIcon={undefined}
              onFocus={onOriginFocused}
              onBlur={onOriginBlur}
              setValue={onOriginSelected}
              loadingText={`${t("searching")}...`}
              noOptionsText={<div>{t("noOptions")}</div>}
              getOptionSelected={getOptionSelected}
            />
          </InputWrapper>
        </Box>
        <Box className="location-picker">
          <Typography className="picker-label">
            {t("commBank.search.flights.goingTo")}
          </Typography>
          <InputWrapper error={goingToError}>
            <DestinationAutocomplete
              className={clsx("destination-auto-complete", {
                error: goingToError,
              })}
              label={t("commBank.search.flights.hint")}
              value={destination}
              loadingText={`${t("searching")}...`}
              noOptionsText={<div>{t("noOptions")}</div>}
              hideFloatingLabel={!!destination || destinationFocused}
              popupIcon={<ApacIcon name={ApacIconName.MagnifyingGlass} />}
              hideIcon
              setValue={onDestinationSelected}
              onFocus={onDestinationFocused}
              onBlur={onDestinationBlur}
              getOptionSelected={getOptionSelected}
            />
          </InputWrapper>
        </Box>
      </Box>

      <Box className="search-line-container">
        <Box className="date-picker-container">
          <DateSelectButton
            date={departureDate}
            label={t("commBank.search.flights.departing")}
            onClick={() => {
              handleOpenCalendar();
              setDefaultFocusedInput(START_DATE);
            }}
            error={departingDateError}
          />
          {!isOneWay && (
            <DateSelectButton
              date={returnDate}
              label={t("commBank.search.flights.returning")}
              onClick={() => {
                handleOpenCalendar();
                setDefaultFocusedInput(END_DATE);
              }}
              error={returningDateError}
            />
          )}
          <CalendarPicker
            open={openCalendar}
            tripCategory={tripCategory}
            departureDate={departureDate}
            returnDate={returnDate}
            setDepartureDate={setDepartureDate}
            setReturnDate={setReturnDate}
            calendarVisited={calendarVisited}
            setCalendarVisited={setCalendarVisited}
            populateSearchUrlParams={(params, history) => {
              populateSearchUrlParams(params, history as any);
            }}
            closePopup={() => setOpenCalendar(false)}
            months={months}
            priceTags={priceTags}
            trackingProperties={trackingProperties}
            defaultFocusedInput={defaultFocusedInput}
          />
        </Box>

        <Box className="right">
          <Box
            className="passenger-picker-container"
            onClick={() => setOpenPassengerCountPicker(true)}
          >
            <ApacActionLink
              message={getPassengerCountsString({ ...passengerCounts, t })}
            />
            <ApacIcon name={ApacIconName.EditPen} />
          </Box>
          <ApacActionButton
            className="search-button"
            message={t("searchButton")}
            onClick={onClickSearch}
          />
        </Box>
        {guestPickerModal()}
      </Box>
    </Box>
  );
};
