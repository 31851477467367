import * as React from "react";
import clsx from "clsx";
import "./styles.scss";
import { Box, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

export interface IInputButtonProps {
  value?: any;
  activePrefixString?: string;
  prefixString?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  errorLabel?: string;
  showCloseIcon?: boolean;
  autoFocus?: boolean;
  icon?: IconProp;
  customIcon?: JSX.Element;
  endIcon?: IconProp;
  customEndIcon?: JSX.Element;
  onFocus?: (event?: React.FocusEvent) => void;
  onChange?: (value?: any) => void;
  onClick?: (event?: React.MouseEvent) => void;
  onBlur?: (event: React.FocusEvent) => void;
  className?: string;
  label?: string;
}

export const InputButton = ({
  activePrefixString,
  prefixString,
  value,
  className,
  errorLabel,
  showCloseIcon = true,
  icon,
  endIcon,
  autoFocus,
  onFocus,
  onClick,
  onBlur,
  onChange,
  customEndIcon,
  customIcon,
  label,
}: IInputButtonProps) => {
  return (
    <Box className="input-button-root" role="input-button-component">
      <Button
        className={clsx("input-button", className, label && "with-label")}
        value={value}
        onChange={(event: any) =>
          onChange ? onChange(event.target.value) : {}
        }
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus={autoFocus}
        name="input-button"
      >
        <div className={clsx("input-button-left")}>
          {(!!icon || !!customIcon) && (
            <Box className="icon-wrapper">
              {icon ? (
                <FontAwesomeIcon className="icon" icon={icon as IconProp} />
              ) : (
                customIcon
              )}
            </Box>
          )}
          <Box
            className="prefix-string"
            dangerouslySetInnerHTML={{
              __html: (value ? activePrefixString : prefixString) || "",
            }}
          ></Box>
          {label && <div className={clsx("label")}>{label}</div>}
        </div>
        <div className={clsx("input-button-right")}>
          {value && showCloseIcon && (
            <Box>
              <FontAwesomeIcon
                className="icon"
                icon={faTimesCircle as IconProp}
              />
            </Box>
          )}
          {(!!endIcon || !!customEndIcon) && (
            <Box>
              {endIcon ? (
                <FontAwesomeIcon className="icon" icon={endIcon as IconProp} />
              ) : (
                customEndIcon
              )}
            </Box>
          )}
        </div>
      </Button>
      {typeof errorLabel !== "undefined" && (
        <Box>
          <span className="error-label">{errorLabel}</span>
        </Box>
      )}
    </Box>
  );
};
