import { useI18nContext } from "@hopper-b2b/i18n";
import { Checkbox, FormControlLabel } from "@material-ui/core";

interface Props {
  freeCancellation: boolean;
  onFreeCancellationChange(value: boolean): void;
}

export function FreeCancellation({
  freeCancellation,
  onFreeCancellationChange,
}: Props) {
  const { t } = useI18nContext();

  return (
    <FormControlLabel
      label={t("freeCancellation")}
      control={
        <Checkbox
          color="primary"
          checked={freeCancellation}
          onChange={(_event, nextValue) => onFreeCancellationChange(nextValue)}
        />
      }
    />
  );
}
