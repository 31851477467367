import { Typography } from "@material-ui/core";
import "./BlackHeaderBanner.style.scss";
import clsx from "clsx";

export interface BlackHeaderBannerProps {
  title: string;
  subtitle: string;
  className?: string;
}

export const BlackHeaderBanner = ({
  title,
  subtitle,
  className,
}: BlackHeaderBannerProps) => {
  return (
    <div className={clsx("black-header-banner-container", className)}>
      <Typography variant="h1">{title}</Typography>
      <Typography variant="body1">{subtitle}</Typography>
    </div>
  );
};
