import { ConnectedProps, connect } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import {
  setIsSearchBarEditMode,
  setOpenCalendarModal,
} from "../../../shop/actions/actions";
import {
  getIsSearchBarEditMode,
  openCalendarModalSelector,
} from "../../../shop/reducer";
import {
  fetchDepartureCalendar,
  populateSearchUrlParams,
  setCalendar,
  setCalendarVisited,
  setDepartureDate,
  setDestinationCategories,
  setOriginCategories,
  setReturnDate,
  resetFilters,
} from "../../actions/actions";
import {
  getActiveFiltersCount,
  getAdultsCount,
  getCalendarVisited,
  getChildrenCount,
  getDepartureDate,
  getDepartureMonths,
  getDestination,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getMaxPriceFilter,
  getNumTravelers,
  getOrigin,
  getPriceRangeLegends,
  getReturnDate,
  getTripCategory,
  hasSelectedDates,
  viewCalendarEventProperties,
} from "../../reducer";
import { DesktopFlightSearchControl } from "./DesktopFlightSearchControl";

const mapDispatchToProps = {
  fetchDepartureCalendar,
  setCalendar,
  populateSearchUrlParams,
  setDepartureDate,
  setReturnDate,
  setCalendarVisited,
  setFlightShopCalendarOpen: setOpenCalendarModal,
  setIsSearchBarEditMode,
  setOriginCategories,
  setDestinationCategories,
  resetFilters,
};

const mapStateToProps = (state: IStoreState) => {
  const departureDate = getDepartureDate(state);
  const returnDate = getReturnDate(state);

  return {
    tripCategory: getTripCategory(state),
    origin: getOrigin(state),
    destination: getDestination(state),
    numTravelers: getNumTravelers(state),
    filtersCount: getActiveFiltersCount(state),
    departureDate: departureDate ? new Date(departureDate) : null,
    returnDate: returnDate ? new Date(returnDate) : null,
    passengerCounts: {
      adultsCount: getAdultsCount(state),
      childrenCount: getChildrenCount(state),
      infantsInSeatCount: getInfantsInSeatCount(state),
      infantsOnLapCount: getInfantsOnLapCount(state),
    },
    calendarVisited: getCalendarVisited(state),
    hasSelectedDates: hasSelectedDates(state),
    trackingProperties: viewCalendarEventProperties(state),
    flightShopCalendarOpen: openCalendarModalSelector(state),
    isSearchBarEditMode: getIsSearchBarEditMode(state),
    maxPrice: getMaxPriceFilter(state),
    months: getDepartureMonths(state),
    priceTags: getPriceRangeLegends(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ConnectedDesktopFlightSearchControl = connector(
  DesktopFlightSearchControl
);

export type DesktopFlightSearchControlConnectorProps = ConnectedProps<
  typeof connector
>;
