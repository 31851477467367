import { ProductType, useTrackEvents } from "../../../../util/trackEvent";
import {
  ApacAccordion,
  ApacActionButton,
  ApacActionLink,
  ApacIconComponent,
  ApacIconName,
} from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { LodgingShopTrackingEvents } from "@hopper-b2b/types";
import { IconComponent, IconName } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { useCallback, useId, useMemo, useState } from "react";
import {
  SortOption,
  type ILodgingFilterState,
  type LodgingsFilterBoundaries,
  type PriceRange as PriceRangeType,
} from "../../reducer";
import countActiveFilters from "../../utils/countActiveFilters";
import { Amenities } from "../Amenities";
import { FreeCancellation } from "../FreeCancellation";
import { PriceRange } from "../PriceRange";
import { StarRating } from "../StarRating";
import { UserRating } from "../UserRating";
import styles from "./FiltersModal.module.scss";

interface FiltersModalProps {
  open: boolean;
  filters: ILodgingFilterState;
  filterBoundaries: LodgingsFilterBoundaries;
  closeFiltersModal: () => void;
  onResetFilters: () => void;
  sort: SortOption;
  updateFiltersAndSort: (
    filters: Partial<ILodgingFilterState>,
    sort: SortOption
  ) => void;
}

export function FiltersModal(props: FiltersModalProps) {
  const {
    open,
    filters: currentFilters,
    filterBoundaries,
    closeFiltersModal,
    onResetFilters,
    updateFiltersAndSort,
    sort: initialSort,
  } = props;
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const titleId = useId();
  const [filterUpdates, setFilterUpdates] = useState<
    Partial<ILodgingFilterState>
  >({});
  const [sort, setSort] = useState<SortOption>(initialSort);
  const filters = useMemo(
    () => ({
      ...currentFilters,
      ...filterUpdates,
      priceRange: {
        ...currentFilters.priceRange,
        ...filterUpdates.priceRange,
      },
    }),
    [currentFilters, filterUpdates]
  );
  const {
    priceRange,
    starRating,
    userRating,
    freeCancellation,
    amenities,
    filterAmenitiesAnd,
  } = filters;
  const options: { value: SortOption; label: string }[] = [
    {
      value: "mostRecommended",
      label: t("mostRecommended"),
    },
    {
      value: "starRating",
      label: t("starRatingHighestFirst"),
    },
    {
      value: "userRating",
      label: t("userRatingHighestFirst"),
    },
    {
      value: "price",
      label: t("priceLowestFirst"),
    },
  ];

  const handleAmenitiesFiltersChange = useCallback(
    (amenities: string[]) => {
      setFilterUpdates((filters) => ({ ...filters, amenities }));
    },
    [setFilterUpdates]
  );

  const handleAmenitiesTypeChange = useCallback(
    (filterAmenitiesAnd: boolean) => {
      setFilterUpdates((filters) => ({ ...filters, filterAmenitiesAnd }));
    },
    [setFilterUpdates]
  );

  const onUserRatingChange = useCallback(
    (userRating: number) =>
      setFilterUpdates((filters) => ({ ...filters, userRating })),
    [setFilterUpdates]
  );

  const onAmenitiesTypeChange = useCallback(
    (filterAmenitiesAnd: boolean) =>
      setFilterUpdates((filters) => ({ ...filters, filterAmenitiesAnd })),
    [setFilterUpdates]
  );

  const onAmenitiesChange = useCallback(
    (amenities: string[]) =>
      setFilterUpdates((filters) => ({ ...filters, amenities })),
    [setFilterUpdates]
  );

  const onStarRatingChange = useCallback(
    (starRating: number[]) =>
      setFilterUpdates((filters) => ({ ...filters, starRating })),
    [setFilterUpdates]
  );

  const onFilterPriceRangeChange = useCallback(
    ({ min, max }: Partial<PriceRangeType>) =>
      setFilterUpdates((filters) => ({
        ...filters,
        priceRange: {
          ...currentFilters.priceRange,
          min,
          max,
        },
      })),
    [setFilterUpdates, currentFilters.priceRange]
  );

  const onFreeCancellationChange = useCallback(
    (freeCancellation: boolean) =>
      setFilterUpdates((filters) => ({ ...filters, freeCancellation })),
    [setFilterUpdates]
  );

  const trackEvent = useTrackEvents();

  const applyFilters = () => {
    updateFiltersAndSort(filterUpdates, sort);
    setFilterUpdates({});

    trackEvent(
      LodgingShopTrackingEvents.hotel_applied_filters,
      ProductType.Hotel,
      {
        filters,
        sort,
      }
    );
    closeFiltersModal();
  };

  const clearAllFilters = () => {
    onResetFilters();
    setFilterUpdates({});
    closeFiltersModal();
  };

  return (
    <Dialog
      maxWidth={"sm"}
      scroll="paper"
      aria-labelledby={titleId}
      fullScreen={matchesMobile}
      open={open}
      onClose={closeFiltersModal}
      fullWidth
    >
      {matchesMobile ? (
        <DialogTitle disableTypography className={styles.dialogTitleMobile}>
          <div>
            <IconButton onClick={closeFiltersModal}>
              <ApacIconComponent
                className={styles.backIconButton}
                name={ApacIconName.ChevronLeft}
              />
            </IconButton>
          </div>
          <div>
            <Typography component="h3" variant="h3" id={titleId}>
              {t("hotelShop.filters")}
            </Typography>
          </div>
          <div>
            <Button
              className={styles.resetButton}
              variant="text"
              onClick={clearAllFilters}
            >
              {t("flightShop.clearAll")}
            </Button>
          </div>
        </DialogTitle>
      ) : (
        <DialogTitle disableTypography className={styles.dialogTitle}>
          <Typography component="h3" variant="h3" id={titleId}>
            {t("hotelShop.filters")}
          </Typography>
          <IconButton onClick={closeFiltersModal}>
            <ApacIconComponent name={ApacIconName.Close} />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent className={styles.dialogContent}>
        <div className={styles.filterSection}>
          {/* Sort By */}
          <ApacAccordion
            initialOpen={true}
            header={
              <Box className={styles.filterHeader}>
                <ApacIconComponent
                  className={styles.filterIcon}
                  name={ApacIconName.Sort}
                />

                <Typography
                  className={styles.priceRange + " " + styles.filterLabel}
                  component="h3"
                  variant="h2"
                >
                  {t("sortBy")}
                </Typography>
              </Box>
            }
          >
            <Button
              className={styles.resetButton}
              variant="text"
              onClick={() => {
                setSort("mostRecommended");
              }}
            >
              {t("reset")}
            </Button>
            <RadioGroup
              value={sort}
              onChange={(e) => {
                setSort(e.target.value as SortOption);
              }}
            >
              {options.map(({ label, value }) => {
                return (
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={<Radio />}
                    label={label}
                  />
                );
              })}
            </RadioGroup>
          </ApacAccordion>
        </div>
        <Divider />
        <div className={styles.filterSection}>
          <ApacAccordion
            header={
              <Box className={styles.filterHeader}>
                <ApacIconComponent
                  className={styles.filterIcon}
                  name={ApacIconName.Star}
                />

                <Typography
                  className={styles.filterLabel}
                  component="h3"
                  variant="h2"
                >
                  {t("starRating")}
                </Typography>
              </Box>
            }
          >
            <Button
              className={styles.resetButton}
              variant="text"
              onClick={() => {
                onStarRatingChange([]);
              }}
            >
              {t("reset")}
            </Button>
            <StarRating value={starRating} onChange={onStarRatingChange} />
          </ApacAccordion>
        </div>
        <Divider />

        <div className={styles.filterSection}>
          <ApacAccordion
            header={
              <Box className={styles.filterHeader}>
                <IconComponent
                  className={styles.filterIcon}
                  name={IconName.B2BUser}
                />

                <Typography
                  className={styles.filterLabel}
                  component="h3"
                  variant="h2"
                >
                  {t("userRating")}
                </Typography>
              </Box>
            }
          >
            <Button
              className={styles.resetButton}
              variant="text"
              onClick={() => {
                onUserRatingChange(0);
              }}
            >
              {t("reset")}
            </Button>
            <UserRating value={userRating} onChange={onUserRatingChange} />
          </ApacAccordion>
        </div>
        <Divider />

        <div className={styles.filterSection}>
          <ApacAccordion
            header={
              <Box className={styles.filterHeader}>
                <ApacIconComponent
                  className={styles.filterIcon}
                  name={ApacIconName.Money}
                />

                <Typography
                  className={styles.filterLabel}
                  component="h3"
                  variant="h2"
                >
                  {t("freeCancellation")}
                </Typography>
              </Box>
            }
          >
            <Box>
              <Button
                className={styles.resetButton}
                variant="text"
                onClick={() => {
                  onFreeCancellationChange(false);
                }}
              >
                {t("reset")}
              </Button>
            </Box>

            <FreeCancellation
              freeCancellation={freeCancellation}
              onFreeCancellationChange={onFreeCancellationChange}
            />
          </ApacAccordion>
        </div>

        <Divider />

        <div className={styles.filterSection}>
          <ApacAccordion
            header={
              <Box className={styles.filterHeader}>
                <IconComponent
                  className={styles.filterIcon}
                  name={IconName.Hotel}
                />

                <Typography
                  className={styles.filterLabel}
                  component="h3"
                  variant="h2"
                >
                  {t("amenities")}
                </Typography>
              </Box>
            }
          >
            <Box>
              <Button
                className={styles.resetButton}
                variant="text"
                onClick={() => {
                  onAmenitiesChange([]);
                  onAmenitiesTypeChange(true);
                }}
              >
                {t("reset")}
              </Button>
            </Box>
            <Amenities
              amenities={filterBoundaries.amenities}
              andFilter={filterAmenitiesAnd}
              appliedFilters={amenities}
              onChange={handleAmenitiesFiltersChange}
              onTypeChange={handleAmenitiesTypeChange}
            />
          </ApacAccordion>
        </div>
        <Divider />

        <div className={styles.filterSection}>
          {/* Price per night */}
          <ApacAccordion
            header={
              <Box className={styles.filterHeader}>
                <ApacIconComponent
                  className={styles.filterIcon}
                  name={ApacIconName.Money}
                />

                <Typography
                  className={styles.priceRange + " " + styles.filterLabel}
                  component="h3"
                  variant="h2"
                >
                  {t("hotelShop.priceRange")}
                </Typography>
              </Box>
            }
          >
            <Button
              className={styles.resetButton}
              variant="text"
              onClick={() => {
                onFilterPriceRangeChange({
                  min: priceRange.lowest || 0,
                  max: priceRange.highest,
                });
              }}
            >
              {t("reset")}
            </Button>
            <PriceRange
              min={priceRange.min || 0}
              max={priceRange.max}
              lowest={priceRange.lowest || 0}
              highest={priceRange.highest}
              onFilterPriceRangeChange={onFilterPriceRangeChange}
            />
          </ApacAccordion>
        </div>
      </DialogContent>
      <Divider />
      {matchesMobile ? (
        <DialogActions>
          <ApacActionButton
            fullWidth
            onClick={applyFilters}
            message={`${t?.("applyAllFilters")} (${countActiveFilters(
              filters
            )})`}
          />
        </DialogActions>
      ) : (
        <DialogActions className={styles.actionBar}>
          <ApacActionButton
            onClick={applyFilters}
            message={`${t?.("applyAllFilters")} (${countActiveFilters(
              filters
            )})`}
          />
          <ApacActionButton
            variant="outlined"
            onClick={closeFiltersModal}
            message={t("cancel")}
          />

          <div className={styles.rightHandSide}>
            <ApacActionLink
              message={t("clearAllFilters")}
              onClick={clearAllFilters}
            />
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
}
