import { ExchangeActionEnum } from "@b2bportal/air-exchange-api";
import { Airport } from "@b2bportal/air-shopping-api";
import {
  ExchangeForTypeEnum,
  IPassengerCounts,
  ITripTerminus,
  IdEnum,
  PassengerTypeEnum,
  RegionType,
  TripCategory,
  getDepartureSlice,
  getReturnSlice,
} from "@hopper-b2b/types";
import { last } from "lodash-es";
import { put } from "redux-saga/effects";

import { fetchFlightById } from "../../../api/v0/exchange/fetchFlightById";
import fetchFlightExchangePolicy from "../../../api/v0/exchange/fetchFlightExchangePolicy";
import Logger from "../../../helpers/Logger";
import { actions as searchActions } from "../../search/actions";
import { setFlightShopProgress } from "../../shop/actions/actions";
import { FlightShopStep } from "../../shop/reducer";
import { resetExchange } from "../reducer";
import {
  getExchangeContext,
  setPolicy,
  setPrevItinerary,
} from "../reducer/policy";
import { setExchangeFor, setReturnSelection } from "../reducer/search";

function buildTripTerminus(airport: Airport): ITripTerminus {
  return {
    label: `${airport.servedCityName} (${airport.code})`,
    id: {
      Id: IdEnum.Flight,
      code: {
        code: airport.code || "",
        regionType: RegionType.Airport,
      },
    },
  };
}

export function* fetchExchangeContextSaga(
  action: ReturnType<typeof getExchangeContext>
) {
  try {
    yield put(resetExchange());
    yield put(searchActions.setOriginCategories([]));
    yield put(searchActions.setDestinationCategories([]));

    const exchangePolicy = yield fetchFlightExchangePolicy(action.payload);
    const flightDetails = yield fetchFlightById(
      action.payload,
      new Date().toISOString()
    );

    const {
      context: { airports },
      itinerary,
    } = flightDetails;
    const departureSlice = getDepartureSlice(itinerary.bookedItinerary);
    const returnSlice = getReturnSlice(itinerary.bookedItinerary);
    const { origin, scheduledDeparture, updatedDeparture } =
      departureSlice.segments[0];
    const originAirport = airports[origin.locationCode];
    let destAirport;

    yield put(
      searchActions.setDepartureDate(
        new Date(updatedDeparture || scheduledDeparture)
      )
    );
    yield put(setPolicy(exchangePolicy));

    if (returnSlice) {
      const { origin, scheduledDeparture, updatedDeparture } =
        returnSlice.segments[0];

      destAirport = airports[origin.locationCode];

      // setting trip category clears return date so this must be done first
      yield put(searchActions.setTripCategory(TripCategory.ROUND_TRIP));
      yield put(
        searchActions.setReturnDate(
          new Date(updatedDeparture || scheduledDeparture)
        )
      );
      yield put(setReturnSelection(ExchangeActionEnum.Keep));
    } else {
      const dest = last(departureSlice.segments)?.destination;

      destAirport = airports[dest?.locationCode || ""];

      yield put(searchActions.setTripCategory(TripCategory.ONE_WAY));
      yield put(setExchangeFor(ExchangeForTypeEnum.oneWay));
    }

    yield put(searchActions.setOrigin(buildTripTerminus(originAirport)));
    yield put(searchActions.setDestination(buildTripTerminus(destAirport)));

    const { alone, withLapInfants } = itinerary.bookedItinerary.passengers;
    const pCount: IPassengerCounts = {
      adultsCount: 0,
      childrenCount: 0,
      infantsInSeatCount: 0,
      infantsOnLapCount: withLapInfants.length,
    };

    for (let i = 0; i < alone.length; i += 1) {
      const { type } = alone[i];

      if (type === PassengerTypeEnum.Adult) {
        pCount.adultsCount += 1;
      } else if (type === PassengerTypeEnum.Child) {
        pCount.childrenCount += 1;
      } else if (type === PassengerTypeEnum.SeatedInfant) {
        pCount.infantsInSeatCount += 1;
      }
    }

    yield put(searchActions.setPassengerCounts(pCount));
    yield put(setPrevItinerary(flightDetails));
  } catch (err) {
    Logger.debug(err);

    yield put(setFlightShopProgress(FlightShopStep.ExchangeError));
  }
}
