import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  CfarTrackingEvents,
  CfarTrackingPageSelected,
  FintechProductOption,
} from "@hopper-b2b/types";
import { Box, Collapse, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useState } from "react";
import { ApacActionLink } from "../ApacActionLink";
import { ApacTermsLink } from "../ApacTermsLink";
import { ApacFintechPurchaseCard } from "../ApacFintechPurchaseCard";
import { ApacIcon, ApacIconName } from "../ApacIcon";
import { ApacIconComponent } from "../ApacIconComponent";
import { ApacMobileContinueButton } from "../ApacMobileContinueButton";
import "./ApacFintechPurchase.styles.scss";

export interface IApacFintechPurchaseProps {
  options: FintechProductOption[];
  benefitDescriptions?: string[];
  headerTitle?: string;
  headerIcon?: ApacIconName;
  headerSubtitle?: string;
  extraDescription?: string;
  optionsSelectionTitle?: string;
  ctaLabel: string;
  handleContinue?: () => void;
  onLearnMoreClick?: () => void;
  className?: string;
  clientAssets?: Record<string, any>;
  selectedProduct?: {
    selected: boolean;
    cost: string;
  };
  modal?: boolean;
  disabled?: boolean;
  loading?: boolean;
  isHalfSheet?: boolean;
  isMobile?: boolean;
  termsScrollSelector?: string;
}
export const ApacFintechPurchase = (props: IApacFintechPurchaseProps) => {
  const {
    options,
    headerTitle,
    headerIcon,
    headerSubtitle,
    extraDescription,
    optionsSelectionTitle,
    handleContinue,
    onLearnMoreClick,
    className,
    clientAssets,
    benefitDescriptions,
    selectedProduct,
    modal = false,
    disabled = false,
    loading = false,
    isHalfSheet = false,
    isMobile = true,
    termsScrollSelector = "#fintech-info",
  } = props;

  const { t } = useI18nContext();
  const [editProduct, setEditProduct] = useState(!selectedProduct?.selected);

  const handleClickLearnMore = useCallback(() => {
    onLearnMoreClick();
    trackEvent({
      eventName: CfarTrackingEvents.TAP_LEARN_MORE,
      properties: { page_selected: CfarTrackingPageSelected.OFFER },
    });
  }, [onLearnMoreClick]);

  const productContent = () => (
    <div className="product-content-container">
      <div className="fintech-purchase-description">
        <div>
          {!!headerSubtitle && (
            <Typography
              className="product-subtitle-content"
              variant="body2"
              dangerouslySetInnerHTML={{ __html: headerSubtitle }}
            />
          )}
          {benefitDescriptions ? (
            <div className="fintech-purchase-benefit-list">
              {benefitDescriptions.map((description) => (
                <div className="fintech-purchase-benefit" key={description}>
                  <span className="benefit-icon">
                    <ApacIcon name={ApacIconName.ItemSuccess} />
                  </span>
                  <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </div>
              ))}
            </div>
          ) : null}
          {!!extraDescription && (
            <Typography
              className="product-subtitle-content"
              variant="body2"
              dangerouslySetInnerHTML={{ __html: extraDescription }}
            />
          )}
          <div className="fintech-purchase-learn-more">
            <ApacActionLink
              message={t("howItWorks")}
              onClick={() => handleClickLearnMore()}
            />
          </div>
          <div className="fintech-purchase-view-terms">
            <ApacTermsLink
              showUnderline={false}
              scrollSelector={termsScrollSelector}
            >
              {t("commBank.terms.viewTerms")}
            </ApacTermsLink>
          </div>
        </div>
      </div>
      <div className="fintech-purchase-card-section">
        {!!optionsSelectionTitle && (
          <Typography className="options-selection-section-title" variant="h3">
            {optionsSelectionTitle}
          </Typography>
        )}
        {options.map((option: FintechProductOption) => (
          <ApacFintechPurchaseCard
            isHalfSheet={isHalfSheet}
            key={option.value}
            option={{
              ...option,
              onClick: () => {
                setEditProduct(false);
                option.onClick();
              },
            }}
            className={className}
            clientAssets={clientAssets}
            loading={loading}
            onSelect={() => setEditProduct(false)}
          />
        ))}
      </div>
    </div>
  );

  return (
    <div
      className={clsx("fintech-purchase", className, {
        modal: modal || isHalfSheet,
        "half-sheet": isHalfSheet,
        mobile: isMobile,
      })}
    >
      <div className="fintech-purchase-content">
        {headerTitle && (
          <div className="fintech-purchase-title">
            <ApacIconComponent
              name={headerIcon || ApacIconName.CommbankLifeSaver}
            />
            <div className="fintech-purchase-header-title">
              <h2>{headerTitle}</h2>
            </div>
          </div>
        )}

        {isMobile ? (
          productContent()
        ) : (
          <div className="collapse-container">
            <Collapse in={editProduct} timeout={600}>
              {productContent()}
            </Collapse>
            <Box className="selected-product-container">
              {selectedProduct?.cost ? (
                <div className="line-item">
                  <ApacIcon name={ApacIconName.ItemSuccess} />
                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: t("addedCostPerTraveler", {
                        cost: selectedProduct.cost,
                      }),
                    }}
                  />
                </div>
              ) : (
                <div className="line-item">
                  <ApacIcon name={ApacIconName.ItemRemove} />
                  <Typography variant="body1">
                    {t("disruptionPurchase.noCoverage")}
                  </Typography>
                </div>
              )}

              <ApacActionLink
                message={t("editProtection")}
                onClick={() => setEditProduct(true)}
              />
            </Box>
          </div>
        )}
      </div>
      {!!handleContinue && (
        <ApacMobileContinueButton
          disabled={disabled}
          handleContinue={handleContinue}
        />
      )}
    </div>
  );
};
