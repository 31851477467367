import { CBASpinner } from "@commbank/ui";
import {
  WalletCreditTransaction,
  WalletCreditTransactionEnum,
} from "@b2bportal/commbank-au-wallet-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useMemo } from "react";
import { getTime } from "./TravelCreditsTab";
import "./TravelCreditsTab.styles.scss";

export type GroupedTransaction = {
  year: string;
  days: {
    date: string;
    transactions: WalletCreditTransaction[];
  }[];
}[];

export const groupTransaction = (
  transactions: WalletCreditTransaction[]
): GroupedTransaction => {
  if (!transactions?.length) {
    return [];
  }

  const groupedTransactionsObject = transactions
    .sort((a, b) => getTime(b).valueOf() - getTime(a).valueOf())
    .reduce((acc, curr) => {
      const date = getTime(curr);
      const year = date.year().toString();
      const day = date.format("D MMM YY");

      if (!acc[year]) {
        acc[year] = {};
      }

      if (!acc[year][day]) {
        acc[year][day] = [];
      }

      acc[year][day].push(curr);

      return acc;
    }, {} as { [year: string]: { [date: string]: WalletCreditTransaction[] } });

  const groupedTransactionsArray = Object.entries(groupedTransactionsObject)
    .sort((a, b) => dayjs(b[0]).valueOf() - dayjs(a[0]).valueOf())
    .map(([year, daysData]) => ({
      year,
      days: Object.entries(daysData)
        .sort((a, b) => dayjs(b[0]).valueOf() - dayjs(a[0]).valueOf())
        .map(([date, transactions]) => ({
          date,
          transactions,
        })),
    }));

  return groupedTransactionsArray;
};

export const CreditTransactions = ({
  transactions,
  isLoading,
  showDivider,
}: {
  transactions: WalletCreditTransaction[];
  isLoading: boolean;
  showDivider?: boolean;
}) => {
  const { t, formatFiatCurrency } = useI18nContext();

  const currentYear = useMemo(
    () => dayjs().format("YYYY"),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transactions]
  );

  const groupedTransactions = useMemo(() => {
    return groupTransaction(transactions);
  }, [transactions]);

  if (isLoading) {
    return (
      <div className="loader-container">
        <CBASpinner />
      </div>
    );
  }

  const getTransactionTitle = (transaction: WalletCreditTransaction, t) => {
    switch (transaction.WalletCreditTransaction) {
      case WalletCreditTransactionEnum.Earn:
        return transaction.program;
      case WalletCreditTransactionEnum.Refund:
        return transaction.program || t("wallet.creditRefund");
      case WalletCreditTransactionEnum.Redemption:
        return t("wallet.creditRedemption");
      case WalletCreditTransactionEnum.Cancellation:
        return t("wallet.creditCancellation");
      case WalletCreditTransactionEnum.Expiration:
        return t("wallet.creditExpiration");
    }
  };

  return (
    <Box className="transactions-container">
      {groupedTransactions.map(({ year, days }) => (
        <div className="year" key={year}>
          {currentYear !== year && <Typography variant="h2">{year}</Typography>}

          {days.map(({ date, transactions }) => (
            <div className={clsx("date", { showDivider })} key={date}>
              <Typography variant="h6">{date}</Typography>
              {transactions.map((transaction) => {
                const isCreation =
                  transaction.WalletCreditTransaction ===
                    WalletCreditTransactionEnum.Earn ||
                  transaction.WalletCreditTransaction ===
                    WalletCreditTransactionEnum.Refund;

                return (
                  <div
                    className="transaction-item"
                    key={`${getTime(transaction)}${
                      transaction.WalletCreditTransaction
                    }${transaction.amount.value}`}
                  >
                    <Typography variant="body1">
                      {getTransactionTitle(transaction, t)}
                    </Typography>

                    <div className="item-right">
                      <Typography
                        variant="body1"
                        className={clsx({ creation: isCreation })}
                      >
                        {`${isCreation ? "+" : "-"} ${formatFiatCurrency(
                          transaction.amount
                        )}`}
                      </Typography>
                      {transaction.WalletCreditTransaction ===
                        WalletCreditTransactionEnum.Earn &&
                        !!transaction.expiresOn && (
                          <Typography variant="body2">
                            {t("wallet.expiresOn", {
                              date: dayjs(transaction.expiresOn).format(
                                "DD/MM/YY"
                              ),
                              interpolation: { escapeValue: false },
                            })}
                          </Typography>
                        )}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      ))}
      {!transactions?.length && (
        <Typography>{t("wallet.noActivityMessage")}</Typography>
      )}
    </Box>
  );
};
