import { Prices, TripSlice } from "@b2bportal/air-shopping-api";
import { PassengerTypes } from "../../common";
import { FareDetails } from "./index";
import { Amount, PassengerType } from "./payment-machine";
import {
  OfferWithSuggested,
  PredictionResultResponse,
  PriceDropProtection,
  TripSummary,
} from "./shop-summary";
import { TripDetails } from "./trip-details";
import { TripSummaryContext } from "./trip-summary";

export interface Cap {
  value: Amount;
}

export type ID = string;

export interface Offer {
  id: ID;
  perPaxAmount: Prices;
  cap: Cap;
  timeToLive: TimeToLive;
  totalAmount: Prices;
  passengers: { [key: string]: number };
  seatedPassengers: { [key: string]: number };
}

export interface Passengers {
  [PassengerTypes.Adult]?: number;
  [PassengerTypes.Child]?: number;
  [PassengerTypes.InfantInLap]?: number;
  [PassengerTypes.InfantInSeat]?: number;
}

export interface TimeToLive {
  inDays?: number;
  inHours?: number;
  inSeconds: number;
}

export interface PriceFreeze {
  id: ID;
  externalId: string;
  offer: Offer;
  expiresAt: string;
}
export interface PriceFreezeTripDetails {
  fareDetails: FareDetails;
  slices: TripSlice[];
}

export interface FetchOfferRequest {
  offerId: ID;
}

export interface FetchOfferResponse {
  offer: Offer;
}

export type PriceFreezeItinerary = MultiItinerary | SingleItinerary;

export interface MultiItinerary {
  tripIds: string[];
  Itinerary: PriceFreezeItineraryEnum.MultiItinerary;
}

export interface SingleItinerary {
  tripId: string;
  fareId: string;
  Itinerary: PriceFreezeItineraryEnum.SingleItinerary;
}

export enum PriceFreezeItineraryEnum {
  MultiItinerary = "MultiItinerary",
  SingleItinerary = "SingleItinerary",
}

export interface ListPriceFreezeRequest {
  ListPriceFreezeRequest: CurrentPriceFreezesEnum;
}

export enum CurrentPriceFreezesEnum {
  CurrentPriceFreezes = "CurrentPriceFreezes",
}

export interface ListPriceFreezeResponse {
  priceFreezes: PriceFreezeView[];
}

export interface FarePricing {
  baseAmount: Prices;
  taxAmount: Prices;
  additionalMargin?: Prices;
  totalAmount: Prices;
}

export interface PassengerPricing {
  paxType: PassengerType;
  pricing: FarePricing;
  quantity: number;
}

export interface PriceFreezeViewFlightFare {
  id: string;
  paxPricings: PassengerPricing[];
  totalPricing?: FarePricing;
}

export interface PriceFreezeViewFlight {
  slices: TripSlice[];
  fareDetails: PriceFreezeViewFlightFare[];
}

export type RefreezeOffer =
  | CurrentOfferActive
  | NoOfferAvailable
  | PreviousTripUnavailable
  | RefreezeTrip;

export interface CurrentOfferActive {
  RefreezeOffer: RefreezeOfferEnum.CurrentOfferActive;
}

export interface NoOfferAvailable {
  RefreezeOffer: RefreezeOfferEnum.NoOfferAvailable;
}

type PredictionResult = PredictionResultResponse;

export interface PreviousTripUnavailable {
  tripSummaries: TripSummary[];
  prediction: PredictionResult;
  priceFreezeOffer: OfferWithSuggested;
  RefreezeOffer: RefreezeOfferEnum.PreviousTripUnavailable;
}

export interface RefreezeTrip {
  tripSummaries: TripSummary[];
  prediction: PredictionResult;
  priceFreezeOffer: OfferWithSuggested;
  RefreezeOffer: RefreezeOfferEnum.RefreezeTrip;
}

export enum RefreezeOfferEnum {
  CurrentOfferActive = "CurrentOfferActive",
  NoOfferAvailable = "NoOfferAvailable",
  PreviousTripUnavailable = "PreviousTripUnavailable",
  RefreezeTrip = "RefreezeTrip",
}

export interface BookingDetails {
  tripId: string;
  fareId: string;
}

export interface PriceFreezeView {
  priceFreeze: PriceFreeze;
  status: {
    Status: PriceFreezeStatusEnum;
  };
  tripDetails: TripDetails;
  frozenFlight: PriceFreezeViewFlight;
  frozenFare: PriceFreezeFrozenFare;
  refreezeOffer?: RefreezeOffer;
  bookingDetails: BookingDetails;
}

export interface PriceFreezeFrozenFare {
  id: string;
  paxPricings: FrozenPricePaxPricing[];
  totalPricing?: FrozenFarePricing;
}

export interface FrozenPricePaxPricing {
  paxType: PassengerTypes;
  pricing: FrozenFarePricing;
  quantity: number;
}

export enum CurrentFareVersusCapEnum {
  FareUnderCap = "FareUnderCap",
  FareOverCap = "FareOverCap",
}

export interface FareOverCap {
  CurrentFareVersusCap: CurrentFareVersusCapEnum.FareOverCap;
}

export interface FareUnderCap {
  CurrentFareVersusCap: CurrentFareVersusCapEnum.FareUnderCap;
}

export type CurrentFareVersusCap = FareOverCap | FareUnderCap;

export interface PriceFreezeDefaultFareDetails {
  baseAmount: Prices;
  taxAmount: Prices;
  totalAmount: Prices;
}

export interface FrozenFarePricing {
  originalAmount: Prices;
  currentAmount: Prices;
  increaseAmount: Prices;
  versusCap: CurrentFareVersusCap;
  savingsAmount: Prices;
  chargeAmount: Prices;
}

export enum PriceFreezeStatusEnum {
  IsActive = "IsActive",
  IsExpired = "IsExpired",
}

export interface GetPriceFreezeResponse {
  priceFreeze: PriceFreezeView;
  tripContext: TripSummaryContext;
  priceDropProtection?: PriceDropProtection;
}

export type GetPriceFreezeRequest = ByPriceFreezeId;

export interface ByPriceFreezeId {
  id: ID;
  GetPriceFreezeRequest: GetPriceFreezeRequestEnum;
}

export enum GetPriceFreezeRequestEnum {
  ByPriceFreezeId = "ByPriceFreezeId",
}

export interface RefundPriceFreezeRequest {
  id: ID;
}

export type RefundPriceFreezeResponse =
  | RefundPriceFreezeSuccessEnum
  | RefundFailureEnum;

export enum RefundPriceFreezeSuccessEnum {
  Success = "Success",
}

export enum RefundFailureEnum {
  NotFound = "NotFound",
  AlreadyExchanged = "AlreadyExchanged",
  Unknown = "Unknown",
  AlreadyExercised = "AlreadyExercised",
  AlreadyRefunded = "AlreadyRefunded",
}
