import { useCallback, useMemo, useState } from "react";
import { sortAmenities } from "../../../../util/utils";
import { Checkbox, FormControlLabel, Button } from "@material-ui/core";
import { useI18nContext } from "@hopper-b2b/i18n";
import styles from "./Amenities.module.scss";
import { type LodgingsFilterBoundaries } from "../../reducer";
import { Amenity } from "@b2bportal/lodging-api";
import { AmenityStatus } from "@hopper-b2b/types";

const MAX_AMENITY_FILTERS = 10;

export function Amenities({
  amenities,
  andFilter,
  appliedFilters,
  onChange,
  onTypeChange,
}: {
  amenities: LodgingsFilterBoundaries["amenities"];
  andFilter: boolean;
  appliedFilters: string[];
  onChange: (amenities: string[]) => void;
  onTypeChange: (value: boolean) => void;
}) {
  const { t } = useI18nContext();
  const [showMore, setShowMore] = useState(false);
  const sortedAmenities: AmenityStatus[] = useMemo(() => {
    const arr = [];
    amenities.forEach((item) => arr.push(item.amenity));
    return sortAmenities(arr).sort((a, b) => {
      const aApp = appliedFilters.includes(a.amenity);
      const bApp = appliedFilters.includes(b.amenity);
      return aApp && bApp ? 0 : aApp ? -1 : 1;
    });
  }, [amenities, appliedFilters]);

  const onSelectAmenity = useCallback(
    (e) => {
      const value = e.nativeEvent.target.value;
      if (appliedFilters.includes(value)) {
        onChange(appliedFilters.filter((item) => item !== value));
      } else {
        onChange([...appliedFilters, e.nativeEvent.target.value]);
      }
    },
    [appliedFilters, onChange]
  );

  const handleShowMoreClick = useCallback(() => {
    setShowMore(!showMore);
  }, [showMore]);

  return (
    <>
      <FormControlLabel
        label={t("includesAllSelectedAmenities")}
        control={
          <Checkbox
            color="primary"
            checked={andFilter}
            onChange={(_event, nextValue) => onTypeChange(nextValue)}
          />
        }
      />

      <div className={styles.AmenitiesGrid}>
        {sortedAmenities?.map((am: AmenityStatus, index) => {
          if (
            !showMore &&
            index > Math.max(MAX_AMENITY_FILTERS, appliedFilters.length - 1)
          ) {
            return null;
          }

          return (
            <FormControlLabel
              key={`amenityFilter-${index}`}
              label={am.displayName}
              control={
                <Checkbox
                  color="primary"
                  value={am.amenity}
                  onChange={onSelectAmenity}
                  checked={appliedFilters.includes(am.amenity)}
                />
              }
            />
          );
        })}
      </div>

      <Button
        className={styles.labelButton}
        variant="text"
        onClick={handleShowMoreClick}
      >
        {!showMore ? t("showMore") : t("showLess")}
      </Button>
    </>
  );
}
