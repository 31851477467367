import { Typography } from "@material-ui/core";

interface PhoneNumberLinkProps {
  label: string;
  phoneNumber: string;
}

export const PhoneNumberLink = ({
  label,
  phoneNumber,
}: PhoneNumberLinkProps) => (
  <Typography variant="body2">
    <>
      {`${label}: `}
      <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
    </>
  </Typography>
);
