import clsx from "clsx";
import "./styles.scss";

export interface IDivider {
  className?: string;
}

export const Divider = (props: IDivider) => {
  const { className } = props;
  return <hr className={clsx(className, "divider")} />;
};
