import axios from "axios";
import {
  GetPriceFreezeRequest,
  NETWORK_CALL_FAILED,
  IApiConfig,
} from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";
import {
  GetPriceFreezeResponseV2,
  priceFreezeApi,
} from "@b2bportal/price-freeze-api";

export const getPriceFreeze = (
  body: GetPriceFreezeRequest,
  apiConfig?: IApiConfig
): Promise<GetPriceFreezeResponseV2> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await priceFreezeApi(axios).apiV2PricefreezeGetPost(body);
      resolve(res.data);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });

export default getPriceFreeze;
