import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import {
  getPriceFreezeAirlinesMap,
  getPriceFreezeAirportMap,
} from "../../../TripsList/reducer";
import { priceFreezeListSortedSelector } from "../../reducer/selectors";
import { PriceFreezeList } from "./component";
import { ComponentClass } from "react";

const mapStateToProps = (state: IStoreState) => ({
  priceFreezes: priceFreezeListSortedSelector(state),
  airlines: getPriceFreezeAirlinesMap(state),
  airports: getPriceFreezeAirportMap(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPriceFreezeList: ComponentClass = withRouter(
  connector(PriceFreezeList)
);
