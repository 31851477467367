import { useI18nContext } from "@hopper-b2b/i18n";
import { AirportMap, FareDetails, TripDetails } from "@hopper-b2b/types";
import { ApacAlertBanner, ApacModalLink } from "@commbank/ui";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { useMemo } from "react";
import { FlightReviewCard } from "../../../../../components";
import "./ItineraryReviewContent.scss";
import { CombinationModalContent } from "../../../v3/components/FlightList/components/FareDetailsCard/components/CombinationModalContent";

type ItineraryReviewContentProps = {
  isMultiTicket: boolean;
  isOneWay: boolean;
  departureDate: Date | null;
  returnDate: Date | null;
  fareDetails: FareDetails;
  tripDetails: TripDetails;
  airports: AirportMap;
  isOutgoingMixedClass: boolean;
  isReturnMixedClass: boolean;
};

export const ItineraryReviewContent = ({
  isMultiTicket,
  isOneWay,
  departureDate,
  returnDate,
  fareDetails,
  tripDetails,
  airports,
  isOutgoingMixedClass,
  isReturnMixedClass,
}: ItineraryReviewContentProps) => {
  const { t } = useI18nContext();

  const showReturnFlightSummary = useMemo(
    () => !isOneWay && returnDate && fareDetails,
    [fareDetails, isOneWay, returnDate]
  );

  return (
    <Box className="itinerary-cards-section review-itinerary-cards-section">
      {isMultiTicket && (
        <ApacAlertBanner
          className={clsx("review-itinerary-flight-combo-banner", "b2b")}
          title={
            <ApacModalLink
              className="review-itinerary-flight-combo-banner"
              content={CombinationModalContent}
            >
              {t("combinationFlightWarning")}
            </ApacModalLink>
          }
          toolTipCopy={t("combinationFlightTooltip")}
        />
      )}

      {departureDate && fareDetails && (
        <Box className={clsx("flight-shop-review-itinerary-card", "departure")}>
          <FlightReviewCard
            tripSlice={tripDetails.slices[0]}
            fareDetailsSlice={fareDetails.slices[0]}
            airports={airports}
            showAction
            showCardTitle
            isMixedCabinClass={isOutgoingMixedClass}
          />
        </Box>
      )}
      {showReturnFlightSummary && (
        <Box className={clsx("flight-shop-review-itinerary-card", "return")}>
          <FlightReviewCard
            tripSlice={tripDetails.slices[1]}
            fareDetailsSlice={fareDetails.slices[1]}
            airports={airports}
            showAction
            showCardTitle
            isMixedCabinClass={isReturnMixedClass}
          />
        </Box>
      )}
    </Box>
  );
};
