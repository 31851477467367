import { assign } from "xstate";
import { setContextWithKey, getObjectKeysAsObject } from "../../../helpers";
import { ParentState } from "../../../types/common";
import { SetContactInfoEvent } from "./events";
import { PartialParentWithContact } from "./context";

export const actions = {
  setContactVisited: assign((ctx: PartialParentWithContact, _event) =>
    setContextWithKey(ctx, `${ParentState.contactInformation}.visited`, true)
  ),
  setContactInfo: assign(
    (ctx: PartialParentWithContact, event: SetContactInfoEvent) =>
      setContextWithKey(
        ctx,
        `${ParentState.contactInformation}.contactInfo`,
        event.contactInfo
      )
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);
