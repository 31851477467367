import {
  ApacActionButton,
  ApacActionLink,
  ApacCheckbox,
  ApacGenericSlider,
  ApacIcon,
  ApacIconName,
  ApacRadio,
} from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ITripTerminus, SliceStopCountFilter } from "@hopper-b2b/types";
import { Box, Collapse, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { TIME_RANGE_MAX } from "../../modules/search/reducer";
import { FlightShopStep } from "../../modules/shop/reducer";
import { dayMinutesToTime } from "../../utils/timeHelper";
import "./FlightSearchFilterContent.styles.scss";
import { FlightSearchFilterContentConnectorProps } from "./container";
import { sortOptions, stopsOptions } from "./filterOptionsData";

export type FlightSearchFilterContentProps =
  FlightSearchFilterContentConnectorProps & {
    onClose: () => void;
    isOneWay: boolean;
    currentStep: FlightShopStep;
    isMobile: boolean;
    className?: string;
    sliceOrigin: ITripTerminus;
    sliceDestination: ITripTerminus;
  };

/**
 * This is filter for each slice. Since each slice will be selected separately.
 */
export const FlightSearchFilterContent = (
  props: FlightSearchFilterContentProps
) => {
  const {
    sortOption,
    airlineFilter,
    stopsOptionFilter,
    getAirportFilter,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    maxPriceFilter,
    flightNumberFilter,
    setSortOption,
    setStopsOption,
    setRerunPrediction,
    setMaxPriceFilter,
    setOutboundDepartureTimeRange,
    setOutboundArrivalTimeRange,
    setReturnDepartureTimeRange,
    setReturnArrivalTimeRange,
    setAirportFilter,
    setAirlineFilter,
    setFlightNumberFilter,
    flightShopFilters,
    onClose,
    isOneWay,
    currentStep,
    isMobile,
    className,
    sliceOrigin,
    sliceDestination,
  } = props;
  const { t, formatFiatCurrency, brand } = useI18nContext();
  const currencyCode = brand.currencies?.[0]?.code;
  const [openStatus, setOpenStatus] = useState({
    sortby: true,
    airline: false,
    stops: false,
    airport: false,
    departing: false,
    returning: false,
    price: false,
    flightNumber: false,
  });
  const [localFilters, setLocalFilters] = useState({
    sortby: sortOption,
    stops: stopsOptionFilter,
    price: maxPriceFilter,

    // using array value will point variable directly to original one in initialFilterOptions and change(pollute) it.
    // reset filter by assiging initialFilterOptions again will fail.
    airline: [...airlineFilter],
    airport: [...getAirportFilter],
    flightNumber: [...flightNumberFilter],

    // following value onchange event replace whole object, won't pollute initialFilterOptions, but also copy to be safe
    outboundDepartureTimeRange: { ...outboundDepartureTimeRange },
    outboundArrivalTimeRange: { ...outboundArrivalTimeRange },
    returnDepartureTimeRange: { ...returnDepartureTimeRange },
    returnArrivalTimeRange: { ...returnArrivalTimeRange },
  });

  const filterHeader = (
    icon: ApacIconName,
    title: string,
    isOpen: boolean,
    onClick: () => void
  ) => {
    return (
      <div className="category-header">
        <div className="left">
          <ApacIcon className="sort-icon title-icon" name={icon} />
          <Typography className="filter-label" variant="body1">
            {title}
          </Typography>
        </div>
        <ApacIcon
          className={clsx("right-arrow", { open: isOpen })}
          name={ApacIconName.ChevronDown}
          onClick={onClick}
        />
      </div>
    );
  };

  const singleSelectOptions = (
    options: { label: string; value: any }[],
    checkedValue: any,
    onChange: (v) => void
  ) => {
    return (
      <Box className="options-group">
        {options.map(({ label, value }, index) => (
          <div
            className="filter-option-item"
            key={label}
            onClick={() => onChange(value)}
          >
            <ApacRadio checked={value === checkedValue} />
            <Typography className="filter-label" variant="body1">
              {label}
            </Typography>
          </div>
        ))}
      </Box>
    );
  };

  const multiSelectOptions = (
    options: { label: string; value: any }[],
    checkedValues: any[],
    onChange: (v) => void
  ) => {
    return (
      <Box className="options-group">
        {options.map(({ label, value }, index) => (
          <div
            className="filter-option-item"
            key={label}
            onClick={() => onChange(value)}
          >
            <ApacCheckbox checked={checkedValues.includes(value)} />
            <Typography className="filter-label" variant="body1">
              {label}
            </Typography>
          </div>
        ))}
      </Box>
    );
  };

  const multiSelectFilter = ({
    icon,
    title,
    filterKey,
    optionsKey,
  }: {
    icon: ApacIconName;
    title: string;
    filterKey: string;
    optionsKey: string;
  }) => {
    const localValues = localFilters[filterKey];
    const combinedTitle =
      title +
      (localValues.length
        ? t("flightShop.selected", {
            numberOfOptions: localValues.length,
          })
        : "");
    const onTitleClick = () =>
      setOpenStatus((pre) => ({ ...pre, [filterKey]: !pre[filterKey] }));

    return (
      <div>
        {filterHeader(icon, combinedTitle, openStatus[filterKey], onTitleClick)}

        <Collapse in={openStatus[filterKey]}>
          <div className="action-text-group">
            <ApacActionLink
              message={t("flightShop.selectAll")}
              onClick={() => {
                setLocalFilters({
                  ...localFilters,
                  [filterKey]: flightShopFilters[optionsKey].map(
                    (item) => item.value
                  ),
                });
              }}
            />
            <ApacActionLink
              message={t("flightShop.clearAll")}
              onClick={() => {
                setLocalFilters({ ...localFilters, [filterKey]: [] });
              }}
            />
          </div>
          {multiSelectOptions(
            flightShopFilters[optionsKey],
            localFilters[filterKey],
            (v) => {
              let current = localFilters[filterKey];

              // flip a selection: add option to selected list if not yet, otherwise remove
              if (current.includes(v)) {
                current = current.filter((item) => item !== v);
              } else {
                current.push(v);
              }

              setLocalFilters({ ...localFilters, [filterKey]: current });
            }
          )}
        </Collapse>
      </div>
    );
  };

  const timeRangeFilter = ({
    icon,
    title,
    filterKey,
    optionsKeyDepart,
    optionsKeyArrive,
  }: {
    icon: ApacIconName;
    title: string;
    filterKey: string;
    optionsKeyDepart: string;
    optionsKeyArrive: string;
  }) => {
    const timeRangeAndSlider = (isDeparture: boolean) => {
      return (
        <div className="time-range-container">
          <div className="time-range-title">
            <ApacIcon name={ApacIconName.FlightDepart} />
            <Typography className="filter-label">
              {t(
                isDeparture
                  ? "searchFilter.departureLabel"
                  : "searchFilter.arrivalLabel"
              )}
            </Typography>
          </div>

          <Box className="from-to-time-container">
            <div className="time-item">
              <Typography variant="h6">{t("flightShop.from")}</Typography>
              <div className="time-label-wrapper">
                <Typography>
                  {getTimeLabel(
                    localFilters[
                      isDeparture ? optionsKeyDepart : optionsKeyArrive
                    ].min
                  )}
                </Typography>
                <ApacIcon name={ApacIconName.Clock} />
              </div>
            </div>
            <div className="time-item">
              <Typography variant="h6">{t("flightShop.to")}</Typography>
              <div className="time-label-wrapper">
                <Typography>
                  {getTimeLabel(
                    localFilters[
                      isDeparture ? optionsKeyDepart : optionsKeyArrive
                    ].max
                  )}
                </Typography>
                <ApacIcon name={ApacIconName.Clock} />
              </div>
            </div>
          </Box>
          <ApacGenericSlider
            className={clsx("time-slider", {
              depatrue: isDeparture,
              arrival: !isDeparture,
            })}
            onChange={(min, max) =>
              handleTimeChange(
                isDeparture ? optionsKeyDepart : optionsKeyArrive,
                {
                  min,
                  max,
                }
              )
            }
            sliderType={"doubleThumb"}
            step={30}
            chosenMin={
              localFilters[isDeparture ? optionsKeyDepart : optionsKeyArrive]
                .min
            }
            chosenMax={
              localFilters[isDeparture ? optionsKeyDepart : optionsKeyArrive]
                .max
            }
            sliderMin={0}
            sliderMax={TIME_RANGE_MAX}
            minDistance={120}
          />
        </div>
      );
    };

    return (
      <div>
        {filterHeader(icon, title, openStatus[filterKey], () =>
          setOpenStatus((pre) => ({ ...pre, [filterKey]: !pre[filterKey] }))
        )}

        <Collapse in={openStatus[filterKey]}>
          <div className="collapse-content-container">
            <ApacActionLink
              className="reset"
              message={t("flightShop.reset")}
              onClick={() => {
                setLocalFilters({
                  ...localFilters,
                  [optionsKeyDepart]: { min: 0, max: TIME_RANGE_MAX },
                  [optionsKeyArrive]: { min: 0, max: TIME_RANGE_MAX },
                });
              }}
            />

            <Typography className="filter-label">
              {t("seats.cityToCity", {
                originCity: sliceOrigin.label.split(",")[0],
                destinationCity: sliceDestination.label.split(",")[0],
              })}
            </Typography>
            {timeRangeAndSlider(true)}
            {timeRangeAndSlider(false)}
          </div>
        </Collapse>
      </div>
    );
  };

  const handleTimeChange = (key: string, { min, max }) => {
    setLocalFilters({ ...localFilters, [key]: { min, max } });
  };

  const getTimeLabel = (minute: number, labelIndex?: number) => {
    return (
      <Typography className="time-label">{dayMinutesToTime(minute)}</Typography>
    );
  };

  const hasFlightNumberChanged = () => {
    if (flightNumberFilter.length !== localFilters.flightNumber.length) {
      return true;
    }
    const localNumbers = localFilters.flightNumber.map(
      (flight) => flight.flightNumber
    );
    const stateNumbers = flightNumberFilter.map(
      (flight) => flight.flightNumber
    );

    const equal = localNumbers.every((cur) => stateNumbers.includes(cur));
    return !equal;
  };

  /**
   * save local filter values to state.
   */
  const handleApplyFilters = () => {
    setSortOption(localFilters.sortby);
    setStopsOption(localFilters.stops);
    setMaxPriceFilter(localFilters.price);
    setAirportFilter(localFilters.airport);

    setOutboundDepartureTimeRange(localFilters.outboundDepartureTimeRange);
    setOutboundArrivalTimeRange(localFilters.outboundArrivalTimeRange);
    setReturnDepartureTimeRange(localFilters.returnDepartureTimeRange);
    setReturnArrivalTimeRange(localFilters.returnArrivalTimeRange);

    setAirlineFilter(localFilters.airline);
    // flightNumber filter has higher priority than airline filter, it will reset airline filter if not contains any of them.
    if (hasFlightNumberChanged()) {
      setFlightNumberFilter(localFilters.flightNumber);
    }

    if (
      (stopsOptionFilter === SliceStopCountFilter.NONE) !==
      (localFilters.stops === SliceStopCountFilter.NONE)
    ) {
      setRerunPrediction();
    }

    onClose();
  };

  return (
    <div
      className={clsx("filter-and-sort-content-container", className, {
        mobile: isMobile,
      })}
    >
      <Box className="sorter-container filter-container">
        {filterHeader(
          ApacIconName.Sort,
          t("searchFilter.sortHeader"),
          openStatus.sortby,
          () => setOpenStatus((pre) => ({ ...pre, sortby: !pre.sortby }))
        )}
        <Collapse in={openStatus.sortby}>
          <div>
            <ApacActionLink
              message={t("flightShop.reset")}
              onClick={() => {
                setLocalFilters({ ...localFilters, sortby: "fareScore" });
              }}
            />
            {singleSelectOptions(sortOptions(t), localFilters.sortby, (v) =>
              setLocalFilters({ ...localFilters, sortby: v })
            )}
          </div>
        </Collapse>
      </Box>

      <Box className="airline-container filter-container">
        {multiSelectFilter({
          icon: ApacIconName.PlaneDepart,
          title: t("Airlines"),
          filterKey: "airline",
          optionsKey: "airlineOptions",
        })}
      </Box>

      <Box className="stops-container filter-container">
        {filterHeader(ApacIconName.Stops, t("stops"), openStatus.stops, () =>
          setOpenStatus((pre) => ({ ...pre, stops: !pre.stops }))
        )}
        <Collapse in={openStatus.stops}>
          <div>
            <ApacActionLink
              message={t("flightShop.reset")}
              onClick={() => {
                setLocalFilters({
                  ...localFilters,
                  stops: SliceStopCountFilter.ANY_NUMBER,
                });
              }}
            />
            {singleSelectOptions(stopsOptions(t), localFilters.stops, (v) => {
              setLocalFilters({ ...localFilters, stops: v });
            })}
          </div>
        </Collapse>
      </Box>

      <Box className="airport-container filter-container">
        {multiSelectFilter({
          icon: ApacIconName.LocationOutlined,
          title: t("searchFilter.airport"),
          filterKey: "airport",
          optionsKey: "airportOptions",
        })}
      </Box>

      {(isOneWay || currentStep === FlightShopStep.ChooseDeparture) && (
        <Box className="outbound-container filter-container">
          {timeRangeFilter({
            icon: ApacIconName.Clock,
            title: t("commBank.search.flights.departing"),
            filterKey: "departing",
            optionsKeyDepart: "outboundDepartureTimeRange",
            optionsKeyArrive: "outboundArrivalTimeRange",
          })}
        </Box>
      )}

      {!isOneWay && currentStep === FlightShopStep.ChooseReturn && (
        <Box className="return-container filter-container">
          {timeRangeFilter({
            icon: ApacIconName.Clock,
            title: t("commBank.search.flights.returning"),
            filterKey: "returning",
            optionsKeyDepart: "returnDepartureTimeRange",
            optionsKeyArrive: "returnArrivalTimeRange",
          })}
        </Box>
      )}

      <Box className="price-container filter-container">
        {filterHeader(
          ApacIconName.Money,
          t("searchFilter.priceHeader"), // TODO: selected price ranged
          openStatus.price,
          () => setOpenStatus((pre) => ({ ...pre, price: !pre.price }))
        )}

        <Collapse in={openStatus.price}>
          <div className="collapse-content-container">
            <ApacActionLink
              className="reset"
              message={t("flightShop.reset")}
              onClick={() => {
                setLocalFilters({
                  ...localFilters,
                  price: flightShopFilters.priceMax.value,
                });
              }}
            />

            <div className="price-range-container">
              <Box className="price-label-container">
                <Typography className="filter-label" variant="body1">
                  {t("searchFilter.maxPrice")}
                </Typography>
                <div className="price-box">
                  <Typography>
                    {formatFiatCurrency({
                      value: localFilters.price,
                      currencyCode,
                    }).slice(0, -3)}
                  </Typography>
                </div>
              </Box>

              <ApacGenericSlider
                className="price-slider"
                onChange={(value) => {
                  setLocalFilters({ ...localFilters, price: value });
                }}
                sliderType={"singleThumb"}
                step={100}
                chosenMin={localFilters.price}
                sliderMin={0}
                sliderMax={flightShopFilters.priceMax.value}
              />
            </div>
          </div>
        </Collapse>
      </Box>
      <Box className="flight-number-filter-container filter-container">
        {multiSelectFilter({
          icon: ApacIconName.PlaneDepart,
          title: t("flightDetails"),
          filterKey: "flightNumber",
          optionsKey: "flightNumberOptions",
        })}
      </Box>

      <Box className="footer">
        <ApacActionButton
          onClick={handleApplyFilters}
          message={t("searchFilter.apply")}
        />
        <ApacActionButton
          variant="outlined"
          onClick={onClose}
          message={t("cancel")}
        />
      </Box>
    </div>
  );
};
