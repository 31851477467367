import { TagInfo } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Typography } from "@material-ui/core";
import { HotelStarRating } from "@b2bportal/lodging-api";
import { AirlineIcon } from "../AirlineIcon";
import { StatusTag } from "../StatusTag";
import clsx from "clsx";

import "./styles.scss";
import { StarRating } from "../StarRating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
export interface TripInfoCopy {
  title?: string;
  confirmationLabel: string;
  confirmationCode?: string;
  startLabel: string;
  startDate: string;
  endLabel: string;
  endDate?: string;
  subtitle?: string;
  airlineCode?: string;
  airlineName?: string;
  flightNumber?: string;
  streetAddress?: string;
  postalCode?: string;
  phoneNum?: string;
  titleTag?: TagInfo;
  subtitleTag?: TagInfo;
  origin?: string;
  destination?: string;
  roundTrip?: boolean;
  starRating?: HotelStarRating;
}
export interface TripInfoDetailsProps {
  className?: string;
  titles: TripInfoCopy;
  hideTitleTag?: boolean;
  confirmationCodeClassName?: string;
}

export const TripInfoDetails = ({
  className,
  confirmationCodeClassName,
  titles,
  hideTitleTag,
}: TripInfoDetailsProps) => {
  const { t } = useI18nContext();

  const {
    title,
    confirmationLabel,
    confirmationCode,
    subtitle,
    startLabel,
    startDate,
    endLabel,
    endDate,
    airlineCode,
    airlineName,
    flightNumber,
    titleTag,
    subtitleTag,
    streetAddress,
    postalCode,
    phoneNum,
    origin,
    destination,
    roundTrip,
    starRating,
  } = titles;

  return (
    <Box className={clsx("trip-info-details", className)}>
      <Box className={clsx("details-container", "confirmation")}>
        <Typography className="info-container title" variant="subtitle2">
          {title
            ? title
            : t("originToDestination", {
                origin,
                destination,
                interpolation: { escapeValue: false },
              })}
          {titleTag && !hideTitleTag && <StatusTag tagInfo={titleTag} />}
        </Typography>
        {confirmationCode || starRating ? (
          <Box className="info-container">
            {starRating ? (
              <StarRating
                starRating={starRating}
                className="star-rating"
                textVariant="caption"
              />
            ) : null}
            <Typography className="label" variant="caption">
              {confirmationLabel}:
            </Typography>
            <Typography
              className={clsx("code", confirmationCodeClassName)}
              variant="caption"
              onClick={(event) => {
                /**
                 * Users might (double) click to select the code.
                 */
                event.stopPropagation();
              }}
            >
              {confirmationCode}
            </Typography>
          </Box>
        ) : null}
      </Box>
      <Box className={clsx("details-container", "dates")}>
        <Typography className="info-container title" variant="subtitle2">
          {subtitle || (roundTrip ? t("roundTripFlight") : t("oneWayFlight"))}
          {subtitleTag && <StatusTag tagInfo={subtitleTag} />}
        </Typography>
        <Box className="info-container">
          <Typography className="label" variant="caption">
            {startLabel}
          </Typography>
          <Typography className="date" variant="caption">
            {startDate}
          </Typography>
          {endDate && (
            <>
              <span className="date-separator">|</span>
              <Typography className="label" variant="caption">
                {endLabel}
              </Typography>
              <Typography className="date" variant="caption">
                {endDate}
              </Typography>
            </>
          )}
        </Box>
      </Box>
      {airlineCode && airlineName && flightNumber && (
        <Box className={clsx("details-container", "airline-info")}>
          <AirlineIcon
            className="airline-code-icon"
            airlineCode={airlineCode}
            altText={airlineName}
          />
          <Box className="info-container">
            <Typography className="info-container title" variant="subtitle2">
              {airlineName}
            </Typography>
            <Typography classes={{ root: "flight-number" }} variant="caption">
              {flightNumber}
            </Typography>
          </Box>
        </Box>
      )}
      {(streetAddress || postalCode || phoneNum) && (
        <Box className={clsx("details-container", "address")}>
          <FontAwesomeIcon className="location-icon" icon={faLocationDot} />
          {streetAddress ? (
            <Typography variant="body1">{streetAddress}</Typography>
          ) : null}
          {postalCode ? (
            <Typography variant="caption">{postalCode}</Typography>
          ) : null}
          {phoneNum ? (
            <Typography variant="caption">{phoneNum}</Typography>
          ) : null}
        </Box>
      )}
    </Box>
  );
};
