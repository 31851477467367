import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import {
  HotelSearchControl,
  HotelSearchControlProps,
} from "./HotelSearchControl";
import "./styles.scss";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useDocumentTitle } from "../../../../hooks/src/useDocumentTitle";
import {
  ILodgingAvailabilityState,
  getFilters,
  getFromDate,
  getGuests,
  getIsOverFiltered,
  getLoading,
  getLocation,
  getLodgings,
  getLodgingsFilterBoundaries,
  getPlace,
  getRooms,
  getSort,
  getUntilDate,
} from "../availability/reducer";

import { connect } from "react-redux";
import { setLoading, setSearchParams } from "../availability/actions/actions";
import {
  LodgingSelectionEnum,
  PlaceIdEnum,
  Suggestion,
} from "@b2bportal/lodging-api";

type IHotelSearchProps = HotelSearchControlProps;

const mapDispatchToProps = {
  setLoading,
  setSearchParams,
};

const mapStateToProps = (state: {
  lodgingAvailability: ILodgingAvailabilityState;
}) => {
  const lodgings = getLodgings(state);

  const place: Suggestion | undefined =
    getPlace(state) ?? getLocation(state)
      ? {
          id: {
            Id: PlaceIdEnum.Lodgings,
            lodgingSelection: {
              placeId: "",
              searchTerm: "",
              placeTypes: ["locality", "political", "geocode"],
              LodgingSelection: LodgingSelectionEnum.Place,
            },
          },
          label: getLocation(state),
          trackingPropertiesV2: {},
        }
      : undefined;

  return {
    loading: getLoading(state),
    isOverFiltered: getIsOverFiltered(
      state.lodgingAvailability.lodgings,
      lodgings
    ),
    place,
    location: getLocation(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    lodgings,
    guests: { ...getGuests(state), rooms: getRooms(state) || 1 },
    rooms: getRooms(state) || 1,
    filters: getFilters(state),
    filterBoundaries: getLodgingsFilterBoundaries(state),
    sort: getSort(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const HotelSearch = (props: IHotelSearchProps) => {
  const { matchesMobile } = useDeviceTypes();
  const { t } = useI18nContext();

  useDocumentTitle(t("browserTabTitles.searchHotel"));

  return (
    <Box className={clsx("hotel-search-root", { mobile: matchesMobile })}>
      <HotelSearchControl {...props} />
    </Box>
  );
};

export const ConnectedHotelSearch = connector(HotelSearch);
