import { Amenity } from "@b2bportal/lodging-api";
import { AmenityIconCollection } from "./resources";
import "./styles.scss";

export interface IAmenityIconProps {
  amenity: Amenity;
  alt?: string;
  className?: string;
}

export const AmenityIcon = ({ amenity, alt, className }: IAmenityIconProps) => {
  const CorrespondingAmenityIcon = AmenityIconCollection[amenity];
  return (
    <span className="amenity-icon">
      <CorrespondingAmenityIcon alt={alt} className={className} />
    </span>
  );
};
