import { FC, PropsWithChildren, useMemo, useCallback } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { Tag } from "../../Tag";
import { FareCard } from "../../FareCard";
import { FlightIconCard } from "../../FlightIconCard";
import {
  AlgomerchTag,
  IFlightGridFareInfo,
  mapI18nAlgomerchText,
} from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";

import {
  FareclassOptionFilter,
  IFlightGridRowContentProps,
} from "../component";

const FlightGridRowItem: FC<
  PropsWithChildren<{
    className?: string;
    fare: IFlightGridFareInfo | null;
    fareKey: string;
    index: number;
    selectedMaxPrice?: number;
    fareClassFilter: FareclassOptionFilter;
    tagsLimit?: number;
    isRoundTrip: boolean;
    isExpanded?: boolean;
    algomerchModalOpen?: boolean;
    onAlgomerchInfoClick?: (label: string) => void;
    onFareClick?: (fareId: string) => void;
    selectedFare: string;
  }>
> = ({
  className,
  fare,
  fareKey,
  index,
  selectedMaxPrice,
  fareClassFilter,
  isRoundTrip,
  isExpanded,
  tagsLimit,
  algomerchModalOpen,
  onAlgomerchInfoClick,
  onFareClick,
  selectedFare,
}) => {
  const isOverMaxPrice = selectedMaxPrice
    ? fare && fare.rawPrice > selectedMaxPrice
    : false;
  const hasAppliedFareClassFilters = useMemo(
    () => fareClassFilter && Object.values(fareClassFilter).includes(true),
    [fareClassFilter]
  );
  const isFilteredFareClass = useMemo(
    () =>
      fareClassFilter &&
      hasAppliedFareClassFilters &&
      !(fareClassFilter as any)[fareKey],
    [fareClassFilter, fareKey, hasAppliedFareClassFilters]
  );

  const handleFareClick = useCallback(
    () => (onFareClick && fare ? onFareClick(fare.fareId) : null),
    [fare, onFareClick]
  );

  if (!fare) {
    return <Box className="missing-fare" key={index} />;
  }
  return (
    <FareCard
      className={className}
      key={fare.fareId}
      fare={fare}
      selectedFare={selectedFare}
      isRoundTrip={isRoundTrip}
      tagsLimit={tagsLimit}
      disabled={isOverMaxPrice || isFilteredFareClass}
      onAlgomerchInfoClick={onAlgomerchInfoClick}
      algomerchModalOpen={algomerchModalOpen}
      onClick={handleFareClick}
      type="content"
      isExpanded={isExpanded}
    />
  );
};

// TODO add parameters for localization
export const FlightGridRowContent = ({
  airlineCode,
  airlineName,
  departureTime,
  arrivalTime,
  originCode,
  destinationCode,
  duration,
  layoverString,
  plusDays,
  fares,
  isRoundTrip,
  className,
  onClick,
  onFareClick,
  fareClassFilter,
  selectedMaxPrice,
  onAlgomerchInfoClick,
  tagsLimit,
  fareCardClassName,
  algomerchModalOpen,
  isExpanded,
  selectedFare,
}: IFlightGridRowContentProps) => {
  const { t } = useI18nContext();
  const orderedFares = ["basic", "standard", "enhanced", "premium", "luxury"];

  const isFastestFlight = orderedFares.some((fareKey) =>
    fares[fareKey]?.tags?.includes(AlgomerchTag.Fastest)
  );
  const fastestTagLabel = t(mapI18nAlgomerchText[AlgomerchTag.Fastest]);

  return (
    <Box className={clsx("flight-grid-row", className)} onClick={onClick}>
      {isFastestFlight && (
        <Box className="fastest-tag-container">
          <Tag
            className={clsx("fare-card-tag", fareCardClassName)}
            label={fastestTagLabel}
            onClick={() => {
              onAlgomerchInfoClick && onAlgomerchInfoClick(fastestTagLabel);
            }}
          />
        </Box>
      )}
      <FlightIconCard
        departureTime={departureTime}
        arrivalTime={arrivalTime}
        airlineCode={airlineCode}
        airlineName={airlineName}
        originCode={originCode}
        destinationCode={destinationCode}
        duration={duration}
        layoverString={layoverString}
        plusDays={plusDays}
        type="content"
      />
      <Box className="flight-fares">
        {orderedFares.map((fareKey, index) => {
          return (
            <FlightGridRowItem
              key={`fare-${index}-${fareKey}`}
              className={fareCardClassName}
              fareKey={fareKey}
              fare={fares[fareKey]}
              index={index}
              onFareClick={onFareClick}
              selectedMaxPrice={selectedMaxPrice}
              fareClassFilter={fareClassFilter}
              tagsLimit={tagsLimit}
              isRoundTrip={isRoundTrip}
              isExpanded={isExpanded}
              algomerchModalOpen={algomerchModalOpen}
              onAlgomerchInfoClick={onAlgomerchInfoClick}
              selectedFare={selectedFare}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default FlightGridRowContent;
