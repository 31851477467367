import { useAccounts } from "@commbank/accounts";
import {
  ApacActionButton,
  ApacActionLink,
  ApacButton,
  ApacDesktopPopupModal,
  ApacIcon,
  ApacIconName,
  ApacTermsLink,
} from "@commbank/ui";
import {
  Offer,
  Points,
  TravelCredits,
} from "@b2bportal/commbank-au-accounts-api";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import { MobilePopoverCard } from "@hopper-b2b/ui";
import {
  PATH_HOME,
  PATH_HOTELS_ROOT,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import "./OffersTab.styles.scss";
import { TabFooter } from "../TabFooter";

export type OffersTabProps = {
  isSidePanel?: boolean;
};

export const OffersTab = ({ isSidePanel }: OffersTabProps) => {
  const { t } = useI18nContext();
  const { matchesMobile: isMobile } = useDeviceTypes();
  const { accounts } = useAccounts();
  const navigate = useNavigate();
  const [selectedOfferDetails, setSelectedOfferDetails] = useState<Offer>(null);

  const offers = useMemo(() => {
    const allOffers = accounts
      .filter((account) => account.eligibleOffers.length > 0)
      .flatMap((account) => account.eligibleOffers);

    // unique by offer id
    const offerIdUniqueOffers = Object.values(
      allOffers.reduce((uinqueOffers, offer: Offer) => {
        uinqueOffers[offer.offerId] = offer;
        return uinqueOffers;
      }, {} as { [offerId: string]: Offer })
    );

    // unique by offer value
    // TODO: this is temporary solution as we see duplication even after removing duplicated offer id
    // CBA should provide a criteria for dedup
    const getOfferKey = (offer: Offer) => {
      const awardKey = JSON.stringify(offer.awards);
      const productCriteriaKey = JSON.stringify(
        offer.applicability.criterias
          .filter((criteria) => criteria.Criteria === "ProductCriteria")
          .map((criteria) => (criteria as any).ProductCriteria)
          .sort()
      );
      return `${awardKey}-${productCriteriaKey}`;
    };

    if (offerIdUniqueOffers.length > 1) {
      return Object.values(
        offerIdUniqueOffers.reduce((uniqueOffers, offer: Offer) => {
          const key = getOfferKey(offer);
          if (!uniqueOffers[key]) {
            uniqueOffers[key] = offer;
          }
          return uniqueOffers;
        }, {} as { [key: string]: Offer })
      );
    }
  }, [accounts]);

  const getOfferTitle = (offer: Offer) => {
    return offer.awards.Awards === "TravelCredits"
      ? t("wallet.offersTab.cashBackOffersTitle", {
          percent: (offer.awards as TravelCredits).percentage,
        })
      : offer.awards.Awards === "Points"
      ? t("wallet.offersTab.pointsOfferTitle", {
          points: (offer.awards as Points).multipler,
        })
      : null;
  };

  const renderOffer = (offer: Offer, index: number) => {
    return (
      <div className="offer-card" key={offer.offerId + index}>
        <div
          className={clsx("offer-image", {
            points: offer.awards.Awards === "Points",
            credits: offer.awards.Awards === "TravelCredits",
          })}
        />
        <Typography className="offer-title" variant="body2">
          {getOfferTitle(offer)}
        </Typography>
        <div className="actions-container">
          <ApacButton
            message={t("wallet.offersTab.searchButtonLabel")}
            className="search-button"
            onClick={() => {
              navigate({
                pathname: `${PATH_HOME}${PATH_HOTELS_ROOT}`,
              });
            }}
          />
          <ApacActionLink
            message={t("wallet.offersTab.viewOfferDetailsLinkLabel")}
            className="view-details-link"
            onClick={() => setSelectedOfferDetails(offer)}
          />
        </div>
      </div>
    );
  };

  const renderOfferDetailsContent = (offer: Offer) => {
    return (
      <Box className="offer-details-modal-content">
        <Box className="header-image" />
        <Typography variant="h2">{getOfferTitle(offer)}</Typography>
        <Typography variant="body1">
          {/* TODO what should we show in case of Points based offer as description? */}
          {offer.awards.Awards === "TravelCredits" ? (
            <Trans
              i18nKey="wallet.offersTab.percentageOfferDescription"
              values={{
                percent: (offer.awards as TravelCredits).percentage,
              }}
              components={[
                <ApacTermsLink
                  className="offer-details-terms-link"
                  scrollSelector="#travel-credits"
                />,
              ]}
            />
          ) : null}
        </Typography>
        <ApacActionButton
          onClick={() => {
            setSelectedOfferDetails(null);
          }}
          message={t("close.button")}
        />
      </Box>
    );
  };

  return (
    <div
      className={clsx("offers-tab-container", {
        mobile: isMobile || isSidePanel,
      })}
    >
      <div className={clsx("offers-list", { mobile: isMobile || isSidePanel })}>
        {offers?.length ? (
          offers.map((offer, index) => {
            return renderOffer(offer, index);
          })
        ) : (
          <Typography className="no-offer-message" variant="body2">
            {t("wallet.offersTab.noOfferMessage")}
          </Typography>
        )}
      </div>

      <TabFooter showFaq />

      {isMobile ? (
        <MobilePopoverCard
          open={!!selectedOfferDetails}
          fullScreen
          className="mobile-rewards-details-popup"
          onClose={() => setSelectedOfferDetails(null)}
          topRightButton={
            <ApacIcon
              name={ApacIconName.Close}
              onClick={() => setSelectedOfferDetails(null)}
            />
          }
        >
          {selectedOfferDetails
            ? renderOfferDetailsContent(selectedOfferDetails)
            : null}
        </MobilePopoverCard>
      ) : (
        <ApacDesktopPopupModal
          open={!!selectedOfferDetails}
          className="desktop-rewards-details-popup"
          onClose={() => {
            setSelectedOfferDetails(null);
          }}
        >
          {selectedOfferDetails
            ? renderOfferDetailsContent(selectedOfferDetails)
            : null}
        </ApacDesktopPopupModal>
      )}
    </div>
  );
};
