import { useI18nContext } from "@hopper-b2b/i18n";
import { TripCategory } from "@hopper-b2b/types";
import { ActionLink, Icon, IconName } from "@hopper-b2b/ui";
import { Breadcrumbs, BreadcrumbsProps } from "@material-ui/core";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";

import { getTripCategory } from "../../../search/reducer";
import { setFlightShopProgress } from "../../../shop/actions/actions";
import {
  FlightShopStep,
  flightShopProgressSelector,
  selectedTripSelector,
} from "../../../shop/reducer";
import { getExchangeType } from "../../reducer/selectors";
import { getIsFtc } from "../../selectors";
import { skipShopAction } from "../../utils";

import "./styles.scss";

export interface IShopBreadcrumbsProps extends BreadcrumbsProps {
  backToTrips: () => void;
}

const defaultProps: Partial<IShopBreadcrumbsProps> = {};

const ShopBreadcrumbs = (props: IShopBreadcrumbsProps): JSX.Element => {
  const { backToTrips, className, ...muiProps } = props;
  const dispatch = useDispatch();
  const { t } = useI18nContext();

  const { outboundSelection, returnSelection } = useSelector(getExchangeType);
  const isFtc = useSelector(getIsFtc);
  const shopStep = useSelector(flightShopProgressSelector);
  const { outgoingSliceId, returnSliceId } = useSelector(selectedTripSelector);
  const tripCategory = useSelector(getTripCategory);

  const isOneWay = tripCategory === TripCategory.ONE_WAY;
  const returnDisabled = isOneWay || !outgoingSliceId;
  const reviewDisabled = isOneWay
    ? Boolean(!outgoingSliceId)
    : Boolean(!outgoingSliceId && !returnSliceId);
  const skipChooseDeparture = skipShopAction(outboundSelection);
  const skipChooseReturn = skipShopAction(returnSelection);

  return (
    <Breadcrumbs
      aria-label="Flight exchange breadcrumbs"
      className={clsx("breadcrumbs-container", className)}
      separator={
        <Icon className="separator" name={IconName.NavigationForward} />
      }
      {...muiProps}
    >
      <ActionLink
        className="breadcrumb b2b"
        content={t("exchangeable.shop.breadcrumbs.trips")}
        onClick={backToTrips}
      />
      {!skipChooseDeparture && (
        <ActionLink
          className={clsx("breadcrumb b2b", {
            current: shopStep === FlightShopStep.ChooseDeparture,
          })}
          content={t("exchangeable.shop.breadcrumbs.chooseDeparture")}
          onClick={() =>
            dispatch(setFlightShopProgress(FlightShopStep.ChooseDeparture))
          }
        />
      )}
      {!(skipChooseReturn || isOneWay) && (
        <ActionLink
          className={clsx("breadcrumb b2b", {
            current: shopStep === FlightShopStep.ChooseReturn,
          })}
          content={t("exchangeable.shop.breadcrumbs.chooseReturn")}
          disabled={returnDisabled}
          onClick={() =>
            dispatch(setFlightShopProgress(FlightShopStep.ChooseReturn))
          }
        />
      )}
      {isFtc && (
        <ActionLink
          className={clsx("breadcrumb b2b", {
            current: shopStep === FlightShopStep.ReviewItinerary,
          })}
          content={t("exchangeable.shop.breadcrumbs.reviewItinerary")}
          disabled={reviewDisabled}
          onClick={() =>
            dispatch(setFlightShopProgress(FlightShopStep.ReviewItinerary))
          }
        />
      )}
    </Breadcrumbs>
  );
};

ShopBreadcrumbs.defaultProps = defaultProps;

export default ShopBreadcrumbs;
