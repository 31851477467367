import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { HotelItineraryDetails } from "./component";
import {
  populateTripQueryParams,
  setOpenModal,
  setSelectedHotel,
} from "../../../TripsList/actions/actions";
import { getSelectedHotel, getTripsFilter } from "../../../TripsList/reducer";
import { IStoreState } from "../../../../reducers/types";
import { MyTripsFilter } from "@hopper-b2b/types";

const mapStateToProps = (state: IStoreState) => ({
  hotel: getSelectedHotel(state),
  isPastTrips: getTripsFilter(state) === MyTripsFilter.PAST_TRIPS,
});

const mapDispatchToProps = {
  setOpenModal,
  setSelectedHotel,
  populateTripQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelItineraryDetailsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedHotelItineraryDetails = connector(
  withRouter(HotelItineraryDetails)
);
