import { useI18nContext } from "@hopper-b2b/i18n";
import {
  PassengerCountPicker,
  IPassengerCountPickerProps,
} from "@hopper-b2b/ui";

// @deprecated, remove after exchange redesign
export const FlightPassengerCountPicker = (
  props: IPassengerCountPickerProps
) => {
  const { t } = useI18nContext();
  const MAXIMUM_COUNT = 6;

  return (
    <PassengerCountPicker
      titles={{
        modalTitle: t("passengerCount.modalTitle"),
        adultTitle: t("passengerCount.adultTitle"),
        adultSubtitle: t("passengerCount.adultSubtitle"),
        childrenTitle: t("passengerCount.childrenTitle"),
        childrenSubtitle: t("passengerCount.childrenSubtitle"),
        infantLapTitle: t("passengerCount.infantLapTitle"),
        infantLapSubtitle: t("passengerCount.infantLapSubtitle"),
        infantSeatTitle: t("passengerCount.infantSeatTitle"),
        infantSeatSubtitle: t("passengerCount.infantSeatSubtitle"),
      }}
      warningMessage={t("passengerCount.warningMessage", {
        maximumCount: MAXIMUM_COUNT,
      })}
      applyButtonText={t("passengerCount.apply")}
      {...props}
    />
  );
};
