import { LodgingSummary } from "@hopper-b2b/types";
import { Box } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";

import "../styles.scss";

export interface IHotelAddressProps {
  isSkeleton?: boolean;
  lodgingData: LodgingSummary;
  showFullAddress?: boolean;
}

const defaultProps: Partial<IHotelAddressProps> = {
  isSkeleton: false,
  showFullAddress: false,
};

const HotelAddress = (props: IHotelAddressProps): JSX.Element => {
  const { isSkeleton, lodgingData, showFullAddress } = props;
  const {
    city,
    country,
    state: { code, name },
  } = lodgingData;
  const state = code || name || "";
  const addressParts = [city];

  if (showFullAddress) {
    if (state) {
      addressParts.push(state);
    }

    addressParts.push(country);
  }

  const addressText = addressParts.join(", ");

  return (
    <Box className="hotel-address">
      {isSkeleton ? (
        <Skeleton className="hotel-availability-info-skeleton address" />
      ) : (
        <Box className="hotel-address">
          <span className="address-text">{addressText}</span>
        </Box>
      )}
    </Box>
  );
};

HotelAddress.defaultProps = defaultProps;

export default HotelAddress;
