export const apiVersionPrefix = "/api/v0";

export const tripDetailsApiPrefix = `${apiVersionPrefix}/tripDetail`;
export const watchApiPrefix = `${apiVersionPrefix}/alert/watch`;
export const tripSummariesApiPrefix = `${apiVersionPrefix}/shopSummary`;
export const passengersApiPrefix = `${apiVersionPrefix}/passengers`;
export const paymentMethodsApiPrefix = `${apiVersionPrefix}/paymentMethods`;
export const rewardsApiPrefix = `${apiVersionPrefix}/rewards`;
export const passengerValidationApiPrefix = `${apiVersionPrefix}/passengerValidation`;
export const paymentApiPrefix = `${apiVersionPrefix}/payment`;
export const analyticsApiPrefix = `${apiVersionPrefix}/tracking`;
export const agentPortalChargeBookingFeeApi = `${apiVersionPrefix}/agentPortal/chargeBookingFee`;
export const experimentsApiPrefix = `${apiVersionPrefix}/experiments`;

export const analyticsEventApi = `${analyticsApiPrefix}/event`;

export const fetchUserInfoPath = `${apiVersionPrefix}/userInfo`;

export const priceFreezePath = `${apiVersionPrefix}/pricefreeze`;
export const priceFreezeOfferPath = `${priceFreezePath}/offer`;
export const seatApiPrefix = `${apiVersionPrefix}/seats`;
export const seatMapsApiPrefix = `${seatApiPrefix}/seatMap`;
export const priceFreezeRefund = `${priceFreezePath}/refund`;
export const similarFlightApiPrefix = `${priceFreezePath}/similarflight`;
export const transferToSimilarFlightsPrefix = `${similarFlightApiPrefix}/transfer`;

export const priceDropApiPrefix = `${apiVersionPrefix}/pricedrop`;
export const fetchEligibilityApiPrefix = `${priceDropApiPrefix}/eligibility/get`;

export const ancillariesApiPrefix = `${apiVersionPrefix}/ancillaries`;
export const cfarOfferApiPrefix = `${ancillariesApiPrefix}/offer`;

export const disruptionOfferApiPrefix = `${apiVersionPrefix}/disruption`;
