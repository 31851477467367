import { Person, TripPricingSummary } from "@b2bportal/air-booking-api";
import { trackEvent } from "@hopper-b2b/api";
import {
  CheckoutComponentProps,
  ITravelerSelectWorkflowProps,
} from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  IPerson,
  LodgingCheckoutTrackingEvents,
  PersonId,
  UserInfo,
} from "@hopper-b2b/types";
import { getDateTimeWithFormat } from "@hopper-b2b/utilities";
import { Box, Collapse, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ApacActionButton } from "../ApacActionButton";
import { ApacActionLink } from "../ApacActionLink";
import { ApacIcon, ApacIconName } from "../ApacIcon";
import { ApacLoadingIndicator } from "../ApacLoadingIndicator";
import { ApacMobileContinueButton } from "../ApacMobileContinueButton";
import { InfantSeatPickerModal } from "./InfantSeatPickerModal";
import "./PassengerInfo.styles.scss";
import { TravelerEditModal } from "./TravelerEditModal";
import { API_DATE_FORMAT } from "./TravelerInfoForm/TravelerInfoForm";
import { TravelerSelectRow } from "./TravelerSelectRow";
import { getPassengerCountsString } from "@commbank/utilities";
import { ApacIconComponent } from "../ApacIconComponent";

export enum TravelerSelectStep {
  Main,
  TravelerInfoForm,
  TravelerSelect,
}

export type TravelerTypeTitles = {
  selectOrAddTraveler?: string;
  selectOrAddTravelerSubtitle: string;
  travelerSectionTitle?: string;
  travelerSectionSubtitle: string;
  adultTitle?: string;
  childTitle?: string;
  infantTitle?: string;
  infantSeatTitle?: string;
  infantLapTitle?: string;
  frequentFlyerTitle?: string;
  passportSectionTitle?: string;
  passportSectionSubtitle?: string;
  additionalInfoTitle?: string;
};

export enum TravelerType {
  ADULT,
  CHILD,
  INFANT,
}
interface PassengerSearchCounts {
  adultsCount: number;
  childrenCount: number;
  infantsInSeatCount: number;
  infantsOnLapCount: number;
}

interface PassengerInfoProps
  extends CheckoutComponentProps,
    ITravelerSelectWorkflowProps {
  isLoading: boolean;
  loadingMessage: string;
  userPassengers: IPerson[];
  userInfo?: UserInfo;
  isMobile?: boolean;
  isHotel?: boolean;
  disabled?: boolean;
  travelerWorkflowTitles: TravelerTypeTitles;
  currentInfantId?: PersonId;
  handleInfantPickerModalContinue: (seatType: "OwnSeat" | "OnLap") => void;
  handleInfantPickerModalClose: () => void;
  tripPricing: TripPricingSummary | null;
  isInfantModalLoading: boolean;
  ErrorBanner?: string | JSX.Element;
  hasError?: boolean;
  passportOnly?: boolean;
  travelerPickCompleted?: boolean;
  handleReEditPassenger: () => void;
  passengerSearchCounts?: PassengerSearchCounts;
  onLoad: () => void;
}

export const PassengerInfo = (props: PassengerInfoProps) => {
  const {
    isLoading,
    key,
    className,
    loadingMessage,
    userPassengers,
    userInfo,
    disabled,
    isMobile,
    isHotel,
    travelerWorkflowTitles: titles,
    selectedPassengerIds,
    currentPassenger,
    openPassengerFormModal,
    handleCloseForm,
    handleContinue,
    handleDeletePassenger,
    handleEditClick,
    handleGoBack,
    handleSelectPassenger,
    handleUpdatePassenger,
    handleClickAddTraveler,
    currentInfantId,
    handleInfantPickerModalContinue,
    handleInfantPickerModalClose,
    tripPricing,
    isInfantModalLoading,
    ErrorBanner,
    hideFrequentFlyerSection,
    hideAdditionalInfoSection,
    showDriverLicenseSection,
    fullScreenWithBanner,
    passportOnly,
    hasError,
    travelerPickCompleted,
    handleReEditPassenger,
    onLoad,
    // passengerSearchCounts // Removed from here as it does not keep possible undefined state.
  } = props;
  const { t } = useI18nContext();

  const travelerTypeTitles = {
    adult: t("passengerInformation.adult"),
    child: t("passengerInformation.child"),
    infant: t("passengerInformation.infant"),
  };

  const getTravelerType = (
    passenger: Person
  ): { type: TravelerType; title: string } => {
    const age = dayjs().diff(
      getDateTimeWithFormat(passenger.dateOfBirth, API_DATE_FORMAT),
      "year"
    );

    if (age > 11) {
      return { type: TravelerType.ADULT, title: travelerTypeTitles.adult };
    } else if (age >= 2) {
      return { type: TravelerType.CHILD, title: travelerTypeTitles.child };
    } else {
      return { type: TravelerType.INFANT, title: travelerTypeTitles.infant };
    }
  };

  useEffect(() => {
    onLoad?.();
  }, [onLoad]);

  const handleClickEdit = useCallback(
    (passenger: Person) => {
      // Open form and edit passenger
      handleEditClick(passenger);
      trackEvent({
        eventName: LodgingCheckoutTrackingEvents.hotel_edit_guest_information,
        properties: {},
      });
    },
    [handleEditClick]
  );

  const handleClickSelect = useCallback(
    (travelerId: PersonId) => {
      if (!isHotel) {
        setShowErrorMessage(false);
      }
      handleSelectPassenger(travelerId, isHotel);
    },
    [handleSelectPassenger, isHotel]
  );

  // Generate a list of unique passengers
  const uniqueUserPassengers = useMemo(() => {
    const uniqueIndexByPassenger: Record<string, number> = {};

    userPassengers.forEach((passenger, index) => {
      const uniqueKey = `${passenger.givenName}${passenger.surname}${passenger.dateOfBirth}`;

      if (!uniqueIndexByPassenger[uniqueKey]) {
        uniqueIndexByPassenger[uniqueKey] = index;
      }
    });

    // Generate array of passengers from unique index
    return Object.values(uniqueIndexByPassenger).map(
      (index) => userPassengers[index]
    );
  }, [userPassengers]);

  // Passengers by age categories
  const passengersByAge = useMemo(() => {
    const showSelectedEntriesOnly = travelerPickCompleted;

    const adults: Array<{ passenger: Person; index: number }> = [];
    const children: Array<{ passenger: Person; index: number }> = [];
    const infants: Array<{ passenger: Person; index: number }> = [];

    uniqueUserPassengers.forEach((passenger, index) => {
      const type = getTravelerType(passenger).type;
      if (
        !showSelectedEntriesOnly ||
        selectedPassengerIds.includes(passenger.id)
      ) {
        switch (type) {
          case TravelerType.ADULT:
            adults.push({ passenger, index });
            break;
          case TravelerType.CHILD:
            children.push({ passenger, index });
            break;
          default:
            infants.push({ passenger, index });
            break;
        }
      }
    });

    return { adults, children, infants };
  }, [getTravelerType, selectedPassengerIds, uniqueUserPassengers]);

  const selectedPassengersByAge = useMemo(() => {
    return {
      adults: passengersByAge.adults.filter((p) =>
        selectedPassengerIds.includes(p.passenger.id)
      ),
      children: passengersByAge.children.filter((p) =>
        selectedPassengerIds.includes(p.passenger.id)
      ),
      infants: passengersByAge.infants.filter((p) =>
        selectedPassengerIds.includes(p.passenger.id)
      ),
    };
  }, [selectedPassengerIds, userPassengers]);

  const renderTravelerEntriesSection = ({
    showSelectButton,
  }: {
    showSelectButton?: boolean;
  }) => {
    // On mobile, the main page should only show selected passengers

    const { adults, children, infants } = passengersByAge;

    const {
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
    } = props.passengerSearchCounts ?? {
      adultsCount: 1,
      childrenCount: 0,
      infantsInSeatCount: 0,
      infantsOnLapCount: 0,
    };

    const infantsCount = infantsInSeatCount + infantsOnLapCount;

    return (
      <div className="traveler-selection-content">
        {adults.length > 0 ? (
          <>
            <Typography className="traveler-type" variant="h3">
              {titles.adultTitle || t("passengerInformation.adultsTitle")}
            </Typography>
            {adults.map((p, index) =>
              renderTravelerRow(
                p.passenger,
                showSelectButton,
                !isHotel &&
                  selectedPassengersByAge.adults.length >= adultsCount,
                index
              )
            )}
          </>
        ) : null}

        {!isHotel && childrenCount > 0 ? (
          <>
            <Typography className="traveler-type" variant="h3">
              {titles.childTitle || t("passengerInformation.childrenTitle")}
            </Typography>
            {children.length > 0 ? (
              children.map((p, index) =>
                renderTravelerRow(
                  p.passenger,
                  showSelectButton,
                  !isHotel &&
                    selectedPassengersByAge.children.length >= childrenCount,
                  index
                )
              )
            ) : (
              <div className="no-traveller-container">
                <Typography variant="body2">
                  {t("passengerInformation.noTravellerInCategory")}
                </Typography>
              </div>
            )}
          </>
        ) : null}
        {!isHotel && infantsCount > 0 ? (
          <>
            <Typography className="traveler-type" variant="h3">
              {titles.infantTitle || t("passengerInformation.infantsTitle")}
            </Typography>
            {infants.length > 0 ? (
              infants.map((p, index) =>
                renderTravelerRow(
                  p.passenger,
                  showSelectButton,
                  !isHotel &&
                    selectedPassengersByAge.infants.length >= infantsCount,
                  index
                )
              )
            ) : (
              <div className="no-traveller-container">
                <Typography variant="body2">
                  {t("passengerInformation.noTravellerInCategory")}
                </Typography>
              </div>
            )}
          </>
        ) : null}
      </div>
    );
  };

  const renderTravelerRow = (
    passenger: Person,
    showSelectButton: boolean,
    isDisabled: boolean,
    index: number
  ) => {
    const fullName = `${passenger.givenName} ${passenger.middleName || ""} ${
      passenger.surname
    }`.replace("  ", " ");
    const isSelected = selectedPassengerIds.includes(passenger.id);
    return (
      <TravelerSelectRow
        isHotel={isHotel}
        isMobile={isMobile}
        key={passenger.id + index}
        disabled={disabled || (isDisabled && !isSelected)}
        fullName={fullName}
        travelerType={getTravelerType(passenger).title}
        isSelected={isSelected}
        onClickEdit={() => {
          handleClickEdit(passenger);
        }}
        onClickSelect={
          showSelectButton
            ? () => {
                handleClickSelect(passenger.id);
              }
            : undefined
        }
        isReadOnly={travelerPickCompleted}
      />
    );
  };

  const travellerDetailsModal = () => {
    return (
      <TravelerEditModal
        titles={titles}
        isMobile={isMobile}
        userInfo={userInfo}
        showPassport={!isHotel}
        hideFrequentFlyerSection={hideFrequentFlyerSection || isHotel}
        hideAdditionalInfoSection={hideAdditionalInfoSection || isHotel}
        showDriverLicenseSection={showDriverLicenseSection || isHotel}
        fullScreenWithBanner={fullScreenWithBanner}
        currentPassenger={currentPassenger}
        openPassengerFormModal={openPassengerFormModal}
        passportOnly={passportOnly}
        handleGoBack={handleGoBack}
        handleCloseForm={handleCloseForm}
        handleUpdatePassenger={handleUpdatePassenger}
        handleDeletePassenger={handleDeletePassenger}
        userPassengers={userPassengers}
      />
    );
  };

  const hasIncompleteSelection = useMemo(() => {
    if (isHotel || !props.passengerSearchCounts) return false;
    const { adults, children, infants } = selectedPassengersByAge;

    const {
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
    } = props.passengerSearchCounts;

    if (
      adultsCount !== adults.length ||
      childrenCount !== children.length ||
      infantsInSeatCount + infantsOnLapCount !== infants.length
    ) {
      return true;
    }
  }, [isHotel, props.passengerSearchCounts, selectedPassengersByAge]);

  const selectionMessage = useMemo(() => {
    if (isHotel) {
      return "";
    }
    const countString = getPassengerCountsString({
      ...props.passengerSearchCounts,
      infantsCount:
        props.passengerSearchCounts.infantsInSeatCount +
        props.passengerSearchCounts.infantsOnLapCount,
      t,
    });
    return t("passengerInformation.chooseNumber", {
      number: countString,
    });
  }, [isHotel, props.passengerSearchCounts, t]);

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const isContinueDisabled = useMemo(() => {
    if (!isHotel && props.passengerSearchCounts && hasIncompleteSelection) {
      return true;
    }

    return disabled || selectedPassengerIds?.length < 1 || hasError;
  }, [
    disabled,
    hasError,
    isHotel,
    props.passengerSearchCounts,
    passengersByAge,
    selectedPassengerIds,
    selectedPassengerIds.length,
  ]);

  return (
    <div
      key={key}
      className={clsx("passenger-info-container", className, {
        mobile: isMobile,
      })}
    >
      {isLoading ? (
        <ApacLoadingIndicator
          className={"flight-book-passenger-selection-loading-indicator"}
          indicatorSize={"small"}
          message={loadingMessage}
        />
      ) : (
        <div className="traveler-selection-container">
          <div className="traveler-selection-header">
            <ApacIcon name={ApacIconName.CBAFamily} />

            <div className="header-content">
              <Typography variant="h2" className="traveler-selection-title">
                {titles.selectOrAddTraveler ||
                  t("passengerInformation.selectOrAddTraveler")}
              </Typography>
              {!isHotel && (
                <Typography
                  variant="body2"
                  className="traveler-selection-instructions"
                >
                  {selectionMessage}
                </Typography>
              )}

              <Typography variant="body2">
                {titles.selectOrAddTravelerSubtitle}
              </Typography>
              {isMobile && (
                <ApacActionButton
                  variant="outlined"
                  message={t("passengerInformation.addNewPassenger")}
                  onClick={handleClickAddTraveler}
                  disabled={disabled}
                />
              )}
            </div>
          </div>

          {ErrorBanner ? ErrorBanner : null}
          {renderTravelerEntriesSection({
            showSelectedEntriesOnly: travelerPickCompleted,
            showSelectButton: handleSelectPassenger ? true : false,
          })}
          {
            <Collapse in={!isMobile && showErrorMessage}>
              <Box className="row-wrapper error">
                <ApacIconComponent name={ApacIconName.WarnOutlined} />
                <Typography variant="body1">{selectionMessage}</Typography>
              </Box>
            </Collapse>
          }

          {isMobile ? (
            <div
              onClick={() => {
                if (!isHotel && hasIncompleteSelection) {
                  setShowErrorMessage(true);
                }
              }}
            >
              <ApacMobileContinueButton
                disabled={isContinueDisabled}
                errorMessage={
                  showErrorMessage && hasIncompleteSelection && selectionMessage
                }
                handleContinue={handleContinue}
              />
            </div>
          ) : (
            <Box className="row-wrapper">
              {travelerPickCompleted ? (
                <ApacActionLink
                  className="desktop-traveler-selection-continue-button edit"
                  onClick={() => handleReEditPassenger()}
                  message={t("passengerInformation.editTravellerInfo")}
                />
              ) : (
                <div
                  onClick={() => {
                    if (!isHotel && hasIncompleteSelection) {
                      setShowErrorMessage(true);
                    }
                  }}
                >
                  <ApacActionButton
                    className="desktop-traveler-selection-continue-button"
                    onClick={() => handleContinue()}
                    message={t("saveAndContinue")}
                    disabled={isContinueDisabled}
                  />
                </div>
              )}
              {!isMobile && !travelerPickCompleted && (
                <>
                  <ApacActionButton
                    variant="outlined"
                    message={t("passengerInformation.addNewPassenger")}
                    onClick={handleClickAddTraveler}
                    disabled={disabled}
                  />
                </>
              )}
            </Box>
          )}

          {travellerDetailsModal()}
        </div>
      )}

      <InfantSeatPickerModal
        currentInfantToSelectSeat={currentInfantId}
        isLoading={isInfantModalLoading}
        onClose={handleInfantPickerModalClose}
        onContinue={handleInfantPickerModalContinue}
        tripPricing={tripPricing}
      />
    </div>
  );
};
