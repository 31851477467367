import React from "react";
import { ReactComponent as CommbankAuLifeSaverIcon } from "../../assets/icons/commbank-au/lifesaver.svg";
import { ReactComponent as MagnifyingGlass } from "../../assets/icons/commbank-au/magnify-glass.svg";
import { ReactComponent as CBAFamily } from "../../assets/icons/commbank-au/CBAFamily.svg";
import { ReactComponent as CBAHome } from "../../assets/icons/commbank-au/CBAHome.svg";
import { ReactComponent as CBAPlane } from "../../assets/icons/commbank-au/CBAPlane.svg";
import { ReactComponent as AirPlaneIcon } from "../../assets/icons/commbank-au/airplane.svg";
import { ReactComponent as YellowEye } from "../../assets/icons/commbank-au/yellow-eye.svg";
import { ReactComponent as YellowGuard } from "../../assets/icons/commbank-au/yellow-guard.svg";
import { ReactComponent as YellowWave } from "../../assets/icons/commbank-au/yellow-wave.svg";
import { ReactComponent as StarIcon } from "../../assets/icons/commbank-au/star.svg";
import { ReactComponent as TripAdvisorLogoIcon } from "../../assets/icons/commbank-au/tripadvisor-logo.svg";
import { ReactComponent as EditPenIcon } from "../../assets/icons/commbank-au/edit-pen.svg";
import { ReactComponent as LocationOutlinedIcon } from "../../assets/icons/commbank-au/location-outlined.svg";
import { ReactComponent as RadioCheckedIcon } from "../../assets/icons/commbank-au/radio-checked.svg";
import { ReactComponent as RadioUncheckedIcon } from "../../assets/icons/commbank-au/radio-unchecked.svg";
import { ReactComponent as CirclePlusIcon } from "../../assets/icons/commbank-au/circle-plus.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/commbank-au/info.svg";
import { ReactComponent as InfoOutlinedIcon } from "../../assets/icons/commbank-au/info-outlined.svg";
import { ReactComponent as WarnOutlinedIcon } from "../../assets/icons/commbank-au/warn-outlined.svg";
import { ReactComponent as WarnIcon } from "../../assets/icons/commbank-au/warn.svg";
import { ReactComponent as ChevronLeftIcon } from "../../assets/icons/commbank-au/chevron-left.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/icons/commbank-au/chevron-down.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/commbank-au/calendar.svg";
import { ReactComponent as TravelWalletIcon } from "../../assets/icons/commbank-au/travel-wallet.svg";
import { ReactComponent as CBACalendarIcon } from "../../assets/icons/commbank-au/cba-calendar.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/commbank-au/mobile-icon.svg";
import { ReactComponent as CircularCloseIcon } from "../../assets/icons/commbank-au/circle-close.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/commbank-au/close.svg";
import { ReactComponent as FilterIcon } from "../../assets/icons/commbank-au/filter.svg";
import { ReactComponent as SavingsIcon } from "../../assets/icons/commbank-au/savings.svg";
import { ReactComponent as LinkArrowIcon } from "../../assets/icons/commbank-au/link-arrow.svg";
import { ReactComponent as MoneyIcon } from "../../assets/icons/commbank-au/money.svg";
import { ReactComponent as PlaneDepartIcon } from "../../assets/icons/commbank-au/plane-depart.svg";
import { ReactComponent as FlightDepartIcon } from "../../assets/icons/commbank-au/flight-depart.svg";
import { ReactComponent as FlightArriveIcon } from "../../assets/icons/commbank-au/flight-arrive.svg";
import { ReactComponent as PredictionDownIcon } from "../../assets/icons/commbank-au/prediction-down.svg";
import { ReactComponent as PredictionGreenIcon } from "../../assets/icons/commbank-au/prediction-green.svg";
import { ReactComponent as PredictionRedIcon } from "../../assets/icons/commbank-au/prediction-red.svg";
import { ReactComponent as PredictionUpIcon } from "../../assets/icons/commbank-au/prediction-up.svg";
import { ReactComponent as PredictionYellowIcon } from "../../assets/icons/commbank-au/prediction-yellow.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/commbank-au/clock.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/commbank-au/lock.svg";
import { ReactComponent as StopsIcon } from "../../assets/icons/commbank-au/stops.svg";
import { ReactComponent as CheckedIcon } from "../../assets/icons/commbank-au/checkbox-checked.svg";
import { ReactComponent as UncheckedIcon } from "../../assets/icons/commbank-au/checkbox-unchecked.svg";
import { ReactComponent as HourglassIcon } from "../../assets/icons/commbank-au/hourglass.svg";
import { ReactComponent as AdultIcon } from "../../assets/icons/commbank-au/adult-icon.svg";
import { ReactComponent as ChildIcon } from "../../assets/icons/commbank-au/child-icon.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/commbank-au/email-icon.svg";
import { ReactComponent as ProtectionIcon } from "../../assets/icons/commbank-au/protection.svg";
import { ReactComponent as TicketIcon } from "../../assets/icons/commbank-au/ticket.svg";
import { ReactComponent as ItemSuccessIcon } from "../../assets/icons/commbank-au/item-success.svg";
import { ReactComponent as ItemRemoveIcon } from "../../assets/icons/commbank-au/item-remove.svg";
import { ReactComponent as SortIcon } from "../../assets/icons/commbank-au/sort.svg";
import { ReactComponent as CBAPerson } from "../../assets/icons/commbank-au/cba-person.svg";
import { ReactComponent as CBAPersonChild } from "../../assets/icons/commbank-au/cba-person-child.svg";
import { ReactComponent as DoubleTreeIcon } from "../../assets/icons/commbank-au/double-trees.svg";
import { ReactComponent as SofaSeatIcon } from "../../assets/icons/commbank-au/sofa-seat.svg";
import { ReactComponent as SeatIcon } from "../../assets/icons/commbank-au/seat.svg";
import { ReactComponent as LuggageIcon } from "../../assets/icons/commbank-au/luggage.svg";
import { ReactComponent as DocumentsIcon } from "../../assets/icons/commbank-au/documents.svg";
import { ReactComponent as CertificateIcon } from "../../assets/icons/commbank-au/certificate.svg";
import { ReactComponent as NoteSignIcon } from "../../assets/icons/commbank-au/note-sign.svg";
import { ReactComponent as MessagePopIcon } from "../../assets/icons/commbank-au/message-pop.svg";
import { ReactComponent as CBACashIcon } from "../../assets/icons/commbank-au/cba-cash.svg";
import { ReactComponent as CBATravelIcon } from "../../assets/icons/commbank-au/cba-travel.svg";
import { ReactComponent as ClientLogoIcon } from "../../assets/commBank-logo.svg";
import { ReactComponent as HopperLogoDesktopIcon } from "../../assets/hopper-logo-desktop.svg";
import { ReactComponent as HopperLogoMobileIcon } from "../../assets/hopper-logo-mobile.svg";
import { ReactComponent as House } from "../../assets/icons/commbank-au/house.svg";
import { ReactComponent as Map } from "../../assets/icons/commbank-au/map.svg";
import { ReactComponent as Camera } from "../../assets/icons/commbank-au/camera.svg";
import { ReactComponent as Restaurant } from "../../assets/icons/commbank-au/restaurant.svg";
import { ReactComponent as Store } from "../../assets/icons/commbank-au/store.svg";
import { ReactComponent as WorldMap } from "../../assets/icons/commbank-au/world-map.svg";
import { ReactComponent as MoneyInIcon } from "../../assets/icons/commbank-au/money-in.svg";
import { ReactComponent as InsightsIcon } from "../../assets/icons/commbank-au/insights.svg";
import { ReactComponent as StatementsIcon } from "../../assets/icons/commbank-au/statements.svg";
import { ReactComponent as ReviewIcon } from "../../assets/icons/commbank-au/review.svg";

import { ApacIconName } from "../ApacIcon/ApacIcon";
import { SvgrComponent } from "../typings";
import "./styles.scss";
import clsx from "clsx";

type ApacIconComponentMap = {
  [name in ApacIconName]: SvgrComponent;
};

export const iconComponentMap: ApacIconComponentMap = {
  [ApacIconName.CommbankLifeSaver]: CommbankAuLifeSaverIcon,
  [ApacIconName.MagnifyingGlass]: MagnifyingGlass,
  [ApacIconName.CBAFamily]: CBAFamily,
  [ApacIconName.CBAHome]: CBAHome,
  [ApacIconName.CBAPlane]: CBAPlane,
  [ApacIconName.AirPlane]: AirPlaneIcon,
  [ApacIconName.YellowEye]: YellowEye,
  [ApacIconName.YellowGuard]: YellowGuard,
  [ApacIconName.YellowWave]: YellowWave,
  [ApacIconName.Star]: StarIcon,
  [ApacIconName.TripAdvisorLogo]: TripAdvisorLogoIcon,
  [ApacIconName.EditPen]: EditPenIcon,
  [ApacIconName.LocationOutlined]: LocationOutlinedIcon,
  [ApacIconName.RadioChecked]: RadioCheckedIcon,
  [ApacIconName.RadioUnchecked]: RadioUncheckedIcon,
  [ApacIconName.CirclePlus]: CirclePlusIcon,
  [ApacIconName.Info]: InfoIcon,
  [ApacIconName.InfoOutlined]: InfoOutlinedIcon,
  [ApacIconName.WarnOutlined]: WarnOutlinedIcon,
  [ApacIconName.Warn]: WarnIcon,
  [ApacIconName.ChevronLeft]: ChevronLeftIcon,
  [ApacIconName.ChevronDown]: ChevronDownIcon,
  [ApacIconName.Calendar]: CalendarIcon,
  [ApacIconName.CBACalendar]: CBACalendarIcon,
  // the spinner svg doesn't work. Use png in ApacIcon instead.
  [ApacIconName.Spinner]: undefined,
  [ApacIconName.TravelWallet]: TravelWalletIcon,
  [ApacIconName.Phone]: PhoneIcon,
  [ApacIconName.CircularClose]: CircularCloseIcon,
  [ApacIconName.Close]: CloseIcon,
  [ApacIconName.Filter]: FilterIcon,
  [ApacIconName.Savings]: SavingsIcon,
  [ApacIconName.LinkArrow]: LinkArrowIcon,
  [ApacIconName.Money]: MoneyIcon,
  [ApacIconName.PlaneDepart]: PlaneDepartIcon,
  [ApacIconName.FlightDepart]: FlightDepartIcon,
  [ApacIconName.FlightArrive]: FlightArriveIcon,
  [ApacIconName.PredictionDown]: PredictionDownIcon,
  [ApacIconName.PredictionGreen]: PredictionGreenIcon,
  [ApacIconName.PredictionRed]: PredictionRedIcon,
  [ApacIconName.PredictionUp]: PredictionUpIcon,
  [ApacIconName.PredictionYellow]: PredictionYellowIcon,
  [ApacIconName.Clock]: ClockIcon,
  [ApacIconName.Lock]: LockIcon,
  [ApacIconName.Stops]: StopsIcon,
  [ApacIconName.Checked]: CheckedIcon,
  [ApacIconName.Unchecked]: UncheckedIcon,
  [ApacIconName.Hourglass]: HourglassIcon,
  [ApacIconName.Adult]: AdultIcon,
  [ApacIconName.Child]: ChildIcon,
  [ApacIconName.Email]: EmailIcon,
  [ApacIconName.Protection]: ProtectionIcon,
  [ApacIconName.Ticket]: TicketIcon,
  [ApacIconName.ItemSuccess]: ItemSuccessIcon,
  [ApacIconName.ItemRemove]: ItemRemoveIcon,
  [ApacIconName.Sort]: SortIcon,
  [ApacIconName.CBAPerson]: CBAPerson,
  [ApacIconName.CBAPersonChild]: CBAPersonChild,
  [ApacIconName.DoubleTree]: DoubleTreeIcon,
  [ApacIconName.SofaSeat]: SofaSeatIcon,
  [ApacIconName.Seat]: SeatIcon,
  [ApacIconName.Luggage]: LuggageIcon,
  [ApacIconName.Documents]: DocumentsIcon,
  [ApacIconName.Certificate]: CertificateIcon,
  [ApacIconName.NoteSign]: NoteSignIcon,
  [ApacIconName.MessagePop]: MessagePopIcon,
  [ApacIconName.CBACash]: CBACashIcon,
  [ApacIconName.CBATravel]: CBATravelIcon,
  [ApacIconName.ClientLogo]: ClientLogoIcon,
  [ApacIconName.HopperLogoDesktop]: HopperLogoDesktopIcon,
  [ApacIconName.HopperLogoMobile]: HopperLogoMobileIcon,
  [ApacIconName.House]: House,
  [ApacIconName.Map]: Map,
  [ApacIconName.Camera]: Camera,
  [ApacIconName.Store]: Store,
  [ApacIconName.Restaurant]: Restaurant,
  [ApacIconName.WorldMap]: WorldMap,
  [ApacIconName.MoneyIn]: MoneyInIcon,
  [ApacIconName.Insights]: InsightsIcon,
  [ApacIconName.Statements]: StatementsIcon,
  [ApacIconName.Review]: ReviewIcon,
};

export interface ApacIconComponentProps {
  name: ApacIconName;
  className?: string;
  ariaLabel?: string | null;
}

export const ApacIconComponent = ({
  name,
  ariaLabel,
  ...props
}: ApacIconComponentProps): React.ReactElement => {
  const ApacIconComponent = iconComponentMap[name] as SvgrComponent;
  return (
    <ApacIconComponent
      {...props}
      className={clsx("apac-icon-component", props.className)}
      aria-label={ariaLabel ?? name}
    />
  );
};
