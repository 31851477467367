import {
  airCfarApi,
  ScheduleAirCfarExerciseQuoteResponseSuccess,
} from "@b2bportal/air-cfar-api";
import axios from "axios";
import { select } from "redux-saga/effects";
import { getCfarCancellationQuote } from "../../reducer";

export function* discardFlightCfarCancellationQuoteSaga() {
  try {
    const { quoteId: quoteToken }: ScheduleAirCfarExerciseQuoteResponseSuccess =
      yield select(getCfarCancellationQuote);

    if (quoteToken) {
      yield airCfarApi(axios).apiV0CfarAirExerciseQuoteDiscardPost(quoteToken);
    }
  } catch (e) {
    return;
  }
}
