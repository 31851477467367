import { createSelector } from "@reduxjs/toolkit";
import { CommbankCardAccount } from "@b2bportal/commbank-au-accounts-api";
import {
  cardsToShowWithPriorityOnRewardsBanner,
  cardsToNotShowBannerOn,
} from "@hopper-b2b/types";
import { IStoreState } from "../../../../reducers/types";

// TODO: Move these states and selectors to the @commbank/rewards library
// These states can be shared across all funnels.
//

export const getIsFirstLaunch = (state: IStoreState) =>
  state.flightRewards.isFirstLaunch;

export const getAgentEmail = (state: IStoreState) =>
  state.flightRewards.agentEmail;

export const getRewardsAccounts = (state: IStoreState) =>
  state.flightRewards.rewardsAccounts;

export const getUserSelectedAccountReferenceId = (state: IStoreState) =>
  state.flightRewards.selectedAccountId;

export const getFetchRewardsAccountsCallState = (state: IStoreState) =>
  state.flightRewards.fetchRewardsAccountsCallState;

// sorted by rewards cash equivalent value (descending)
export const getSortedRewardsAccounts = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): CommbankCardAccount[] => {
    if (rewardsAccounts) {
      rewardsAccounts.sort((a, b) => {
        return (b?.awardsBalance?.value || 0) - (a?.awardsBalance?.value || 0);
      });
    } else {
      return [];
    }
  }
);

export const getRewardsAccountWithLargestValue = createSelector(
  getSortedRewardsAccounts,
  (sortedRewardsAccounts): CommbankCardAccount | undefined => {
    return sortedRewardsAccounts[0];
  }
);

// the rewards account selection doesn't have a default state where none of the available options is selected
export const getDefaultSelectedAccountReferenceId = createSelector(
  getSortedRewardsAccounts,
  (rewardsAccounts): string | null => {
    // return the id with largest value
    if (rewardsAccounts && rewardsAccounts.length > 0) {
      return rewardsAccounts[0].cardAccountId;
    }

    return null;
  }
);

// this is the primary selector used outside the component
// either returns the user selected account id, or the default from above if not present
export const getSelectedAccountReferenceId = createSelector(
  getUserSelectedAccountReferenceId,
  getDefaultSelectedAccountReferenceId,
  (
    userSelectedAccountReferenceId,
    defaultSelectedAccountReferenceId
  ): string | null => {
    return userSelectedAccountReferenceId ?? defaultSelectedAccountReferenceId;
  }
);

export const getSelectedAccount = createSelector(
  getRewardsAccounts,
  getSelectedAccountReferenceId,
  (rewardsAccounts, referenceId): CommbankCardAccount | undefined => {
    return rewardsAccounts.find(
      (account) => account.cardAccountId === referenceId
    );
  }
);

export const getSortedRewardsAccountWithLargestEarnForBanner = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): CommbankCardAccount[] => {
    const earnMultiplier = (account: CommbankCardAccount) =>
      account.earn.flightsMultiplier ?? account.earn.hotelsMultiplier ?? -1;
    return rewardsAccounts.sort((prev, current) => {
      if (
        cardsToNotShowBannerOn.includes(current.cardDisplayName.toLowerCase())
      )
        return -1;

      if (earnMultiplier(current) === earnMultiplier(prev)) {
        return cardsToShowWithPriorityOnRewardsBanner.includes(
          current.cardDisplayName.toLowerCase()
        )
          ? 1
          : -1;
      }
      return earnMultiplier(current) - earnMultiplier(prev);
    });
  }
);

export const getRewardsAccountWithLargestEarnForBanner = createSelector(
  getSortedRewardsAccountWithLargestEarnForBanner,
  (sortedRewardsAccount): CommbankCardAccount => {
    return sortedRewardsAccount[0];
  }
);

export const getRewardsUserProperties = createSelector(
  getSelectedAccount,
  (
    rewardsAccount
  ): { product: string | undefined; currency: string | undefined } => {
    return {
      product: rewardsAccount?.cardDisplayName,
      currency: rewardsAccount?.awardsBalance?.currency,
    };
  }
);
