import { useI18nContext } from "@hopper-b2b/i18n";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Dialog, CircularProgress, Typography } from "@material-ui/core";
import clsx from "clsx";
import "./CustomerSupportButtons.styles.scss";
import { useState } from "react";
import {
  ActionButton,
  MobilePopoverCard,
  ActionLink,
  IconComponent,
  IconName,
} from "@hopper-b2b/ui";
import { PhoneNumberLink } from "./PhoneNumberLink";

export const DOMESTIC_PHONE_NUMBER = "(+61) 1300 851 809";
export const INTERNATIONAL_PHONE_NUMBER = "(+61) 3 7043 9744";
export const FAQS_LINK = "https://travelbooking-faqs.hopper.com/";

interface CustomerSupportButtonsProps {
  supportId?: string;
  chatSupportAction?: () => void;
}

export const CustomerSupportButtons = ({
  supportId,
  chatSupportAction,
}: CustomerSupportButtonsProps): JSX.Element => {
  const { t } = useI18nContext();
  const { matchesMobile: isMobile } = useDeviceTypes();

  const [showDialog, setShowDialog] = useState<"speak" | "faq">(null);
  const [faqsLoaded, setFAQsLoaded] = useState(false);

  const renderSpeakModalContent = () => (
    <div className="speak-with-us-content">
      <Typography variant="h2">
        {t("customerSupportPage.landingPage.speakDialogTitle")}
      </Typography>

      <div className="content">
        <Typography variant="body2">
          {t("customerSupportPage.landingPage.speakDialogCopy")}
        </Typography>

        <Typography className="support-id" variant="body1">{`${t(
          "customerSupportPage.landingPage.supportId"
        )}: ${supportId}`}</Typography>

        <PhoneNumberLink
          phoneNumber={DOMESTIC_PHONE_NUMBER}
          label={t("customerSupportPage.landingPage.phoneDomesticCopy")}
        />
        <PhoneNumberLink
          phoneNumber={INTERNATIONAL_PHONE_NUMBER}
          label={t("customerSupportPage.landingPage.phoneInternationalCopy")}
        />
      </div>

      <ActionButton
        className="apac-action-button"
        message={t("close.button")}
        onClick={() => {
          setShowDialog(null);
        }}
      />
    </div>
  );

  const renderFAQContent = () => (
    <Box className="faq-modal-content">
      <Box className="faq-modal-heading">
        <Typography variant="h1">
          {t("customerSupportPage.landingPage.faqButton")}
        </Typography>
        <ActionLink
          className="filter-close-button"
          content={
            <IconComponent
              ariaLabel="Close button icon"
              className={"close-button-icon"}
              name={IconName.Close}
            />
          }
          label={t("close")}
          onClick={() => {
            setShowDialog(null);
            setFAQsLoaded(false);
          }}
        />
      </Box>
      <Box className="faq-container">
        <iframe
          title="FAQs"
          src={FAQS_LINK}
          onLoad={() => setFAQsLoaded(true)}
        />
        {!faqsLoaded && (
          <Box className="loading-spinner-container">
            <CircularProgress color="primary" />
          </Box>
        )}
      </Box>
    </Box>
  );

  const renderModalContent = () =>
    showDialog === "faq" ? renderFAQContent() : renderSpeakModalContent();

  return (
    <Box
      className={clsx("customer-support-buttons-root", { mobile: isMobile })}
    >
      {/* FAQs */}
      <ActionButton
        className="apac-action-button"
        onClick={() => setShowDialog("faq")}
        message={t("customerSupportPage.landingPage.faqButton")}
      />

      {/* Chat Dialog */}
      <ActionButton
        className="apac-action-button outlined"
        variant="outlined"
        onClick={() => {
          chatSupportAction();
        }}
        message={t("customerSupportPage.landingPage.chatButton")}
      />

      {/* Speak Dialog */}
      {supportId && (
        <ActionButton
          className="apac-action-button outlined"
          variant="outlined"
          onClick={() => setShowDialog("speak")}
          message={t("customerSupportPage.landingPage.speakButton")}
        />
      )}

      {isMobile ? (
        <MobilePopoverCard
          open={showDialog !== null}
          onClose={() => {
            setShowDialog(null);
            setFAQsLoaded(false);
          }}
        >
          {renderModalContent()}
        </MobilePopoverCard>
      ) : (
        <Dialog
          className="desktop-flight-shop-modal"
          open={showDialog !== null}
          onClose={() => {
            setShowDialog(null);
            setFAQsLoaded(false);
          }}
        >
          {renderModalContent()}
        </Dialog>
      )}
    </Box>
  );
};
