import { MouseEvent } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ActionButton } from "@hopper-b2b/ui";
import { TravelCredit } from "@hopper-b2b/types";
import { useI18nContext, I18nMarkup } from "@hopper-b2b/i18n";
import { CurrencyFormatters } from "@hopper-b2b/utilities";

import "./styles.scss";

export interface IRedeemFTCButtonProps {
  isMobile: boolean;
  onClick: (e?: MouseEvent) => void;
  travelCredit: TravelCredit;
}

const defaultProps: Partial<IRedeemFTCButtonProps> = {
  isMobile: false,
};

const RedeemFTCButton = (props: IRedeemFTCButtonProps): JSX.Element => {
  const { t } = useI18nContext();
  const { isMobile, onClick, travelCredit } = props;

  const creditValue = CurrencyFormatters.get(
    travelCredit?.credit?.currency
  ).format(travelCredit?.credit?.amount ?? 0);

  return (
    <Box className={clsx("book-with-credit-container", { mobile: isMobile })}>
      <ActionButton
        className="book-with-credit-btn"
        message={t("bookWithCredit")}
        onClick={onClick}
      />
      <Typography className="tc-worth" variant="caption">
        <I18nMarkup tKey={"redeemFtcButton"} replacements={{ creditValue }} />
      </Typography>
    </Box>
  );
};

RedeemFTCButton.defaultProps = defaultProps;

export default RedeemFTCButton;
