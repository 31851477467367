import { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "./styles.scss";
import clsx from "clsx";

export interface IDotsIndicatorProps {
  goLeft: boolean;
  setGoLeft: (goLeft: boolean) => void;
  goRight: boolean;
  setGoRight: (goRight: boolean) => void;
  smallVersion?: boolean;
  customDotWidth?: number;
  customDotMargin?: number;
}

const DEFAULT_DOT_SIZE = 6;
const DEFAULT_DOT_SIZE_SMALL = 4;
const DEFAULT_SPACING_SIZE = 7;
const DEFAULT_SPACING_SIZE_SMALL = 5;
// dots number must be odd
const DEFAULT_DOTS_NUMBER = 5;
const DEFAULT_DOTS_NUMBER_SMALL = 3;

export const DotsIndicator = ({
  goLeft,
  setGoLeft,
  goRight,
  setGoRight,
  smallVersion,
  customDotWidth,
  customDotMargin,
}: IDotsIndicatorProps) => {
  const [isMoving, setIsMoving] = useState(false);
  useEffect(() => {
    // the isMoving field is used to prevent the moving effect from taking place twice at the same time
    if ((goLeft || goRight) && !isMoving) {
      setIsMoving(true);
      setTimeout(() => {
        goLeft && setGoLeft(false);
        goRight && setGoRight(false);
        setIsMoving(false);
        // the duration of slide animation is set to 0.2s (see styles.scss)
      }, 200);
    }
  }, [goLeft, goRight, isMoving, setGoLeft, setGoRight]);

  return (
    <Box className={clsx("slider-dots-root", { small: smallVersion })}>
      <Dots
        goLeft={goLeft}
        goRight={goRight}
        smallVersion={smallVersion}
        customDotWidth={customDotWidth}
        customDotMargin={customDotMargin}
      />
    </Box>
  );
};

type DotProps = {
  dotWidth: number;
  dotMarginRight: number;
};

const Dot = withStyles({
  root: {
    height: ({ dotWidth }: DotProps) => `${dotWidth}px`,
    width: ({ dotWidth }: DotProps) => `${dotWidth}px`,
    marginRight: ({ dotMarginRight }: DotProps) => `${dotMarginRight}px`,
  },
})(Box);

type DotsWrapperProps = {
  dotsWrapperWidth: number;
};

const DotsWrapper = withStyles({
  root: {
    width: ({ dotsWrapperWidth }: DotsWrapperProps) => `${dotsWrapperWidth}px`,
    left: ({ dotsWrapperWidth }: DotsWrapperProps) =>
      `calc(50% - ${dotsWrapperWidth / 2}px)`,
  },
})(Box);

type DotsContainerProps = {
  dotsWrapperWidth: number;
  dotWidth: number;
};

const DotsContainer = withStyles({
  root: {
    width: ({ dotsWrapperWidth }: DotsContainerProps) =>
      `${dotsWrapperWidth}px`,
  },
})(Box);

type DotsProps = {
  goLeft: boolean;
  goRight: boolean;
  smallVersion: boolean;
  customDotWidth?: number;
  customDotMargin?: number;
};

function Dots({
  goLeft,
  goRight,
  smallVersion,
  customDotWidth,
  customDotMargin,
}: DotsProps) {
  const dotWidth = customDotWidth
    ? customDotWidth
    : smallVersion
    ? DEFAULT_DOT_SIZE_SMALL
    : DEFAULT_DOT_SIZE;
  const dotMarginRight = customDotMargin
    ? customDotMargin
    : smallVersion
    ? DEFAULT_SPACING_SIZE_SMALL
    : DEFAULT_SPACING_SIZE;
  const numberOfDots = smallVersion
    ? DEFAULT_DOTS_NUMBER_SMALL
    : DEFAULT_DOTS_NUMBER;

  const dotsArray: JSX.Element[] = [];
  for (let i = 0; i < numberOfDots; i++) {
    dotsArray.push(
      <Dot
        key={i + 1}
        className={clsx(
          "dot",
          { "left-most": i === 0 },
          { "right-most": i === numberOfDots - 1 },
          { focused: i === Math.floor(numberOfDots / 2) },
          { "focused-left": i === Math.floor(numberOfDots / 2) - 1 },
          { "focused-right": i === Math.floor(numberOfDots / 2) + 1 },
          { "go-left": goLeft },
          { "go-right": goRight }
        )}
        dotWidth={dotWidth}
        dotMarginRight={dotMarginRight}
      />
    );
  }
  dotsArray.unshift(
    <Dot
      key={0}
      className={clsx("left-hidden", "dot", { "go-right": goRight })}
      dotWidth={dotWidth}
      dotMarginRight={dotMarginRight}
    />
  );
  dotsArray.push(
    <Dot
      key={numberOfDots + 1}
      className={clsx("right-hidden", "dot", { "go-left": goLeft })}
      dotWidth={dotWidth}
      dotMarginRight={dotMarginRight}
    />
  );

  const dotsWrapperWidth =
    (numberOfDots + 2) * dotWidth + (numberOfDots + 1) * dotMarginRight;

  return (
    <DotsContainer
      className={clsx("dots-container")}
      dotWidth={dotWidth}
      dotsWrapperWidth={dotsWrapperWidth}
    >
      <DotsWrapper
        className={clsx(
          "dots-wrapper",
          { "go-left": goLeft },
          { "go-right": goRight },
          { small: smallVersion }
        )}
        dotsWrapperWidth={dotsWrapperWidth}
      >
        {dotsArray}
      </DotsWrapper>
    </DotsContainer>
  );
}
