import { ITripTerminus, TripDetails } from "@hopper-b2b/types";
import {
  getIsMixedClass,
  useEnableCfar,
  useEnableChfar,
  useEnableDisruptionProtection,
  useEnableWallet,
  useHideReviewItitinerary,
  useIsSessionAuthenticated,
} from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ClientContext } from "../../../../ApacFlightApp";
import { CfarOffers } from "../../../cfar/CfarOffers";
import { ChfarOffer } from "../../../chfar/ChOffer";
import { AirDisruptionOffer } from "../../../disruption/components/AirDisruptionOffer";
import { WalletOfferPage } from "../../../wallet";
import { FlightShopHeader } from "../../components";
import { FlightShopProgressHeader } from "../../components/FlightShopProgressHeader";
import { FlightShopReviewItinerary } from "../../components/FlightShopReviewItinerary";
import { MobileFareDetails } from "../../components/MobileFareDetails";
import { MobilePricePredictionSection } from "../../components/MobilePricePrediction";
import { useGoToNextStep } from "../../hooks/flightShopNavigationHooks";
import {
  getViewedForecastProperties,
  isInCfarOffersStepSelector,
  isInChfarOfferStepSelector,
  isInChooseDepartureStepSelector,
  isInChooseReturnStepSelector,
  isInDisruptionStepSelector,
  isInFareDetailsStepSelector,
  isInPredictionStepSelector,
  isInReviewStepSelector,
  isInWalletOfferStepSelector,
  selectedFareDetailsSelector,
  selectedTripDetailsSelector,
} from "../../reducer";
import { FlightList } from "./FlightList";
import { IFlightListProps } from "./FlightList/FlightList";
import "./ApacMobileFlightShop.styles.scss";
import { useI18nContext } from "@hopper-b2b/i18n";
import { FlightReviewCard } from "../../../../components/FlightReviewCard";
import {
  ApacMobileFullScreenModal,
  TravelInsuranceInfoSection,
} from "@commbank/ui";
import { getCfarOffers } from "../../../cfar/reducer";
import { FlightsScoreExplained } from "../../components/PricePrediction/FlightsScoreExplained";

type ApacMobileFlightShopProps = {
  isPriceFreezeEnabled: boolean;
  tripDetails: TripDetails;

  origin: ITripTerminus | null;
} & Omit<IFlightListProps, "isInChooseReturnStep">;

export const ApacMobileFlightShop = ({
  isPriceFreezeEnabled,
  origin,
  flightsToRender,
  tripSummariesLoading,
  flights,
  rewardsKey,
  handleFareSelect,
  fareClassFilter,
  handleFlightSelect,
  expandedFareDetails,
  departureDate,
  returnDate,
  hasFlightsError,
  selectedTrip,
  maxFlightPrice,
  hasAppliedFareClassFilter,
  hasAppliedNonFareclassFilter,
  setOpenCalendarModal,
  isRoundTrip,
}: ApacMobileFlightShopProps) => {
  const ref = useRef(null);
  const history = useHistory();
  const { t } = useI18nContext();

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openFlightScoreModal, setOpenFlightScoreModal] = useState(false);

  const hideReviewItinerary = useHideReviewItitinerary();

  const clientContext = useContext(ClientContext);

  const goToNextStep = useGoToNextStep();

  const isSessionAuthenticated = useIsSessionAuthenticated();

  const showDisruption = useEnableDisruptionProtection();
  const showFlightCfar = useEnableCfar();
  const showFlightChfar = useEnableChfar();
  const showFlightWallet = useEnableWallet();

  const isInChooseDepartureStep = useSelector(isInChooseDepartureStepSelector);
  const isInChooseReturnStep = useSelector(isInChooseReturnStepSelector);
  const isInReviewStep = useSelector(isInReviewStepSelector);
  const isInPredictionStep = useSelector(isInPredictionStepSelector);
  const isInFareDetailsStep = useSelector(isInFareDetailsStepSelector);
  const isInDisruptionProtectionStep = useSelector(isInDisruptionStepSelector);
  const isInCfarOffersStep = useSelector(isInCfarOffersStepSelector);
  const isInChfarOfferStep = useSelector(isInChfarOfferStepSelector);
  const isInWalletOfferStep = useSelector(isInWalletOfferStepSelector);
  const viewedForecastProperties = useSelector(getViewedForecastProperties);
  const tripDetails = useSelector(selectedTripDetailsSelector);
  const fareDetails = useSelector(selectedFareDetailsSelector);
  const cfarOffers = useSelector(getCfarOffers);

  const headerClassName = useMemo(() => {
    if (isInPredictionStep) return "mobile-prediction-header";
    if (isInReviewStep) return "mobile-review-itinerary-header";
    if (isInDisruptionProtectionStep) return "mobile-disruption-header";
    if (isInCfarOffersStep) return "mobile-cfar-header";
    if (isInChfarOfferStep) return "mobile-chfar-header";

    return "mobile-shop-header";
  }, [
    isInPredictionStep,
    isInReviewStep,
    isInDisruptionProtectionStep,
    isInCfarOffersStep,
    isInChfarOfferStep,
  ]);

  const scrollToTopOfContainer = () => {
    if (ref && ref.current) {
      ref.current.scrollIntoView();
    }
  };

  useEffect(scrollToTopOfContainer, [
    isInChooseDepartureStep,
    isInChooseReturnStep,
    isInReviewStep,
  ]);

  const handleGoToNextStep = useCallback(
    (replace?: boolean) => {
      goToNextStep(replace);
      scrollToTopOfContainer();
    },
    [goToNextStep]
  );
  const showWalletStep =
    showFlightWallet && isSessionAuthenticated && isInWalletOfferStep;

  const hasResults = flightsToRender && flightsToRender.length > 0;

  return (
    <div className="apac-flight-shop-body-container">
      <Box
        className={clsx("progress-header-menu-wrapper", {
          "in-review": isInReviewStep,
          "flights-marketplace":
            isInDisruptionProtectionStep || isInCfarOffersStep,
        })}
      >
        <FlightShopProgressHeader
          className={headerClassName}
          isPriceFreezeEnabled={isPriceFreezeEnabled}
          assets={clientContext?.assets || undefined}
        />
        {!hideReviewItinerary && isInReviewStep && (
          <div className={clsx("mobile-header-banner-container")}>
            <Typography variant="h3">
              {t("flightsPageTitles.reviewItinerary")}
            </Typography>
            <Typography className="small-font" variant="body2">
              {t("reviewItinerarySubtitle")}
            </Typography>
          </div>
        )}
      </Box>
      <Box className={clsx("flight-shop-content")} ref={ref}>
        {isInChooseReturnStep && !!tripDetails?.slices?.length && (
          <FlightReviewCard
            tripSlice={tripDetails.slices[0]}
            fareDetailsSlice={fareDetails.slices[0]}
            airports={flights.airports}
            isMixedCabinClass={getIsMixedClass(fareDetails.slices[0])}
            className="flight-outbound-review-card"
          />
        )}
        {isInChooseDepartureStep || isInChooseReturnStep ? (
          <>
            <Box className="mobile-flight-shop-top-section">
              {isInChooseDepartureStep ? (
                <Box>
                  {hasResults && (
                    <MobilePricePredictionSection
                      filtersOpen={false}
                      setFiltersOpen={setOpenFilterModal}
                      history={history}
                      viewedForecastProperties={viewedForecastProperties}
                    />
                  )}
                </Box>
              ) : null}
              {!!flights && (
                <FlightShopHeader
                  isMobile={true}
                  openFilterModal={openFilterModal}
                  setOpenFilterModal={setOpenFilterModal}
                />
              )}
            </Box>
            <FlightList
              flightsToRender={flightsToRender}
              tripSummariesLoading={tripSummariesLoading}
              flights={flights}
              rewardsKey={rewardsKey}
              handleFareSelect={handleFareSelect}
              fareClassFilter={fareClassFilter}
              handleFlightSelect={handleFlightSelect}
              expandedFareDetails={expandedFareDetails}
              departureDate={departureDate}
              returnDate={returnDate}
              isInChooseReturnStep={isInChooseReturnStep}
              hasFlightsError={hasFlightsError}
              selectedTrip={selectedTrip}
              maxFlightPrice={maxFlightPrice}
              hasAppliedFareClassFilter={hasAppliedFareClassFilter}
              hasAppliedNonFareclassFilter={hasAppliedNonFareclassFilter}
              setOpenCalendarModal={setOpenCalendarModal}
              isRoundTrip={isRoundTrip}
              disablePriceFreeze={!isPriceFreezeEnabled}
              handleClickAlgomerch={() => {
                setOpenFlightScoreModal((prev) => {
                  return !prev;
                });
              }}
            />
          </>
        ) : null}
        {!hideReviewItinerary && isInReviewStep ? (
          <FlightShopReviewItinerary isMobile />
        ) : null}
        {isInFareDetailsStep ? <MobileFareDetails /> : null}
        {showFlightCfar && isInCfarOffersStep && cfarOffers?.length ? (
          <>
            <TravelInsuranceInfoSection />
            <CfarOffers
              goToNextStep={handleGoToNextStep}
              cfarCoverage={cfarOffers[0].coverageAmount}
            />
          </>
        ) : null}
        {showFlightChfar && isInChfarOfferStep ? (
          <ChfarOffer goToNextStep={handleGoToNextStep} />
        ) : null}
        {showWalletStep ? (
          <WalletOfferPage goToNextStep={handleGoToNextStep} />
        ) : null}
        {showDisruption && isInDisruptionProtectionStep ? (
          <AirDisruptionOffer goToNextStep={handleGoToNextStep} />
        ) : null}
      </Box>
      <ApacMobileFullScreenModal
        open={openFlightScoreModal}
        onClose={() => setOpenFlightScoreModal(false)}
      >
        <FlightsScoreExplained onClose={() => setOpenFlightScoreModal(false)} />
      </ApacMobileFullScreenModal>
    </div>
  );
};
