import * as React from "react";
import { Box, Popover } from "@material-ui/core";
import clsx from "clsx";

import { IconName } from "../Icon";
import { B2BButton } from "../B2BButton";
import { ButtonWrap } from "../ButtonWrap";
import CloseButtonIcon from "../../assets/icons/common/close-button.svg";
import "./styles.scss";
import { IconComponent } from "../IconComponent";
import { Slot } from "../Slots";

export interface IGenericOptions {
  buttonId?: string;
  buttonClassName?: string;
  popoverClassName?: string;
  dropdownLabel: string | React.ReactElement;
  dropdownContent: string | React.ReactElement;
  dropdownIcon?: React.ReactElement;
  borderless?: boolean;
  ariaLabel?: string;
}

export const GenericDropdown = ({
  buttonId,
  buttonClassName,
  popoverClassName,
  dropdownLabel,
  dropdownContent,
  dropdownIcon,
  ariaLabel,
  borderless = false,
}: IGenericOptions) => {
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & Element) | null
  >(null);

  const togglePopover = (event: React.MouseEvent) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <B2BButton
        id={buttonId}
        aria-label={ariaLabel || ""}
        className={clsx(
          "generic-dropdown-button",
          { borderless: borderless },
          buttonClassName
        )}
        variant="b2b-shop-filter"
        onClick={togglePopover}
      >
        <Box className="generic-dropdown-content-container">
          <Box className="text">{dropdownLabel}</Box>
          {dropdownIcon ? (
            dropdownIcon
          ) : (
            <Slot
              id="dropdown-icon"
              className="icon-end"
              name="dropdown-icon-end"
              component={
                <IconComponent
                  aria-hidden={true}
                  className="icon-end"
                  name={IconName.Dropdown}
                  ariaLabel=""
                />
              }
            />
          )}
        </Box>
      </B2BButton>
      <Popover
        className={clsx("generic-dropdown-popover", popoverClassName)}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        role="dialog"
        aria-label={`${ariaLabel}`}
      >
        <Box className="popover-content">
          <div className="close-button-container">
            <ButtonWrap
              className="close-button"
              aria-label="Close"
              onClick={handleClose}
            >
              <Slot
                id="dropdown-icon"
                className="close-icon"
                name="dropdown-icon-close"
                component={
                  <img
                    src={CloseButtonIcon}
                    className="close-icon"
                    alt="close button icon"
                  />
                }
              />
            </ButtonWrap>
          </div>
          {dropdownContent}
        </Box>
      </Popover>
    </>
  );
};
