import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ActionButton,
  ActionLink,
  IconComponent,
  IconName,
} from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import {
  Dialog,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import { PropsWithChildren } from "react";
import styles from "./HotelDetailsDialog.module.scss";
import clsx from "clsx";

export function HotelDetailsDialog({ children, ...dialogProps }: DialogProps) {
  const { matchesMobile } = useDeviceTypes();

  return (
    <Dialog
      maxWidth={"sm"}
      scroll="paper"
      fullScreen={matchesMobile}
      fullWidth
      PaperProps={{ square: true }}
      {...dialogProps}
    >
      {children}
    </Dialog>
  );
}

interface DialogTitleWithCloseButtonProps
  extends PropsWithChildren,
    CloseIconButtonProps {
  titleId: string;
  className?: string;
}

function TitleWithCloseButton({
  children,
  titleId,
  onClose,
  className,
}: DialogTitleWithCloseButtonProps) {
  return (
    <DialogTitle
      className={clsx(styles.dialogTitle, className)}
      disableTypography
    >
      <Typography component="h2" variant="h2" id={titleId}>
        {children}
      </Typography>
      <CloseIconButton onClose={onClose} />
    </DialogTitle>
  );
}

interface CloseButtonProps {
  title: string;
  onClose: () => void;
}

function CloseButton({ onClose, title }: CloseButtonProps) {
  return (
    <ActionButton
      onClick={onClose}
      message={title}
      className={styles.closeButton}
    />
  );
}

function StyledDialogContent({
  children,
  dividers = true,
  ...dialogContentProps
}: DialogContentProps) {
  return (
    <DialogContent className={styles.dialogContent} {...dialogContentProps}>
      {dividers ? <Divider className={styles.divider} /> : null}
      {children}
    </DialogContent>
  );
}

interface CloseIconButtonProps {
  onClose: () => void;
}

function CloseIconButton({ onClose }: CloseIconButtonProps) {
  const { t } = useI18nContext();

  return (
    <ActionLink
      className={styles.closeIconButton}
      content={
        <IconComponent ariaLabel={t("closeModal")} name={IconName.Close} />
      }
      onClick={onClose}
    />
  );
}

HotelDetailsDialog.TitleWithCloseButton = TitleWithCloseButton;
HotelDetailsDialog.CloseIconButton = CloseIconButton;
HotelDetailsDialog.CloseButton = CloseButton;
HotelDetailsDialog.DialogContent = StyledDialogContent;
