import { useSearchParams } from "react-router-dom-v5-compat";
import { URL_PARAM_KEYS } from "@hopper-b2b/lodging-utils";
import {
  PATH_AVAILABILITY_RELATIVE,
  PATH_HOME,
  PATH_HOTELS_ROOT,
  placeLabelToUrl,
} from "../../../util";

export function useAvailabilityLink(placeLabel: string) {
  const [search] = useSearchParams();
  const urlFriendlyName = placeLabelToUrl(placeLabel);

  const generateQueryParams = () => {
    let queryParams = "";
    [
      URL_PARAM_KEYS.FROM_DATE,
      URL_PARAM_KEYS.UNTIL_DATE,
      URL_PARAM_KEYS.ADULTS_COUNT,
      URL_PARAM_KEYS.ROOMS_COUNT,
      URL_PARAM_KEYS.CHILDREN_COUNT,
    ].forEach((paramKey) => {
      const value = search.get(paramKey);
      const ampersand = queryParams.length > 0 ? "&" : "";
      if (value) {
        queryParams = `${queryParams}${ampersand}${paramKey}=${value}`;
      }
    });
    return queryParams;
  };

  return `${PATH_HOME}${PATH_HOTELS_ROOT}${PATH_AVAILABILITY_RELATIVE}${urlFriendlyName}?${generateQueryParams()}`;
}
