import { put, putResolve, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { updateUserPassenger } from "../../../../api/v1/book/user-passengers/updateUserPassenger";
import {
  fetchUserPassengers,
  IListUserPassengersResponse,
} from "../../../../api/v1/book/user-passengers/fetchUserPassengers";
import {
  ADD_PASSPORT,
  ADD_TRAVELER,
  CallState,
  Person,
} from "@hopper-b2b/types";
import {
  getUserPassengers,
  getUserSelectedPassengerIds,
  getUserSelectedLapInfantIds,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { trackEvent } from "@hopper-b2b/api";
import { flightApiConfigStoreKey } from "../../../../reducers/types";

export function* updateUserPassengerSaga(action: actions.IUpdateUserPassenger) {
  const state: IStoreState = yield select();
  const apiConfig = state[flightApiConfigStoreKey];
  try {
    // Check if passport field is valid or filter it
    // & filter driverLicense field
    const { person } = action.payload;
    const { passport, driverLicense, ...restOfPayload } = person;
    const personToUpdate: Person = { ...restOfPayload };
    if (passport?.countryOfIssue?.length === 2) {
      personToUpdate.passport = passport;
    }
    yield updateUserPassenger({ person: personToUpdate }, apiConfig);

    const userPassengers: IListUserPassengersResponse =
      yield fetchUserPassengers({}, apiConfig);

    const travelerIds = getUserSelectedPassengerIds(state);
    const lapInfantIds = getUserSelectedLapInfantIds(state);
    const currentTravelers = getUserPassengers(state);

    if (currentTravelers.length < userPassengers.length) {
      trackEvent(
        {
          eventName: ADD_TRAVELER,
          properties: {},
        },
        apiConfig
      );
    }

    yield putResolve(
      actions.setUserPassengers({
        userPassengerCallState: CallState.Success,
        userPassengers: userPassengers,
      })
    );

    if (action.updatePassport) {
      trackEvent(
        {
          eventName: ADD_PASSPORT,
          properties: { success: true },
        },
        apiConfig
      );
      // if keepBookingErrors = true, don't resetBookingErrors
      // - to allow all passports to be updated before closing modal
      // - modal open is dependent on getPriceQuoteErrors
      if (!action.keepBookingErrors) {
        yield put(actions.resetBookingErrors());
      }
    }
    if (action.autoSelect) {
      yield put(
        actions.setUserSelectedPassengerIds({
          userSelectedPassengerIds: [...travelerIds, action.payload.person.id],
        })
      );
      yield put(
        actions.validatePassengers(
          [...travelerIds, action.payload.person.id],
          lapInfantIds
        )
      );
    }
  } catch (e) {
    if (action.updatePassport) {
      trackEvent(
        {
          eventName: ADD_PASSPORT,
          properties: { success: false },
        },
        apiConfig
      );
    }
    yield put(actions.setUserPassengersCallStateFailed());
  }
}
