import { SliceStopCountFilter } from "@hopper-b2b/types";
import { SortOption } from "../../modules/shop/reducer";

export interface ISortOptions {
  label: string;
  shortLabel: string;
  value: SortOption;
}

export const sortOptions = (t: (key: string) => string): ISortOptions[] => {
  return [
    {
      label: t("sortOption.recommended"),
      shortLabel: t("sortOption.recommended"),
      value: "fareScore",
    },
    {
      label: t("sortOption.price"),
      shortLabel: t("sortOption.priceShortened"),
      value: "price",
    },
    {
      label: t("sortOption.depart"),
      shortLabel: t("depart"),
      value: "departureTime",
    },
    {
      label: t("sortOption.arrive"),
      shortLabel: t("sortOption.arriveShortened"),
      value: "arrivalTime",
    },
    {
      label: t("sortOption.stops"),
      shortLabel: t("sortOption.stopsShortened"),
      value: "stops",
    },
    {
      label: t("sortOption.duration"),
      shortLabel: t("sortOption.durationShortened"),
      value: "duration",
    },
  ];
};

interface IStopsOption {
  label: string;
  value: SliceStopCountFilter;
}

export const stopsOptions = (t: (key: string) => string): IStopsOption[] => [
  {
    label: t("searchFilter.anyStop"),
    value: SliceStopCountFilter.ANY_NUMBER,
  },
  {
    label: t("searchFilter.noStops"),
    value: SliceStopCountFilter.NONE,
  },
  {
    label: t("searchFilter.oneOrFewerStops"),
    value: SliceStopCountFilter.ONE_OR_LESS,
  },
  {
    label: t("searchFilter.twoOrFewerStops"),
    value: SliceStopCountFilter.TWO_OR_LESS,
  },
];
