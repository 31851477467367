import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getAvailabilityTrackingProperties } from "../../availability/reducer";
import {
  getRooms,
  getSelectedLodging,
  getSelectedRoom,
  getSelectedRoomTrackingProperties,
  getSelectedRoomRateTrackingProperties,
} from "../reducer/selectors";

export const useRoomSelectedTrackingProperties = () => {
  const rooms = useSelector(getRooms);
  const selectedRoom = useSelector(getSelectedRoom);
  const trackingData = useSelector(getAvailabilityTrackingProperties);
  const roomTrackingData = useSelector(getSelectedRoomTrackingProperties);
  const roomRateTrackingData = useSelector(
    getSelectedRoomRateTrackingProperties
  );
  const selectedLodging = useSelector(getSelectedLodging);
  const lodging = selectedLodging ? selectedLodging.lodging : undefined;

  return useMemo(
    () => ({
      properties: {
        ...(roomTrackingData?.properties || {}),
        ...(roomRateTrackingData?.properties || {}),
        ...(trackingData?.properties || {}),
        ...(selectedLodging?.trackingPropertiesV2?.properties || {}),
        im_lodging_name: lodging?.name,
        lodging_id: lodging?.id,
        lodging_star_rating: lodging?.starRating,
        lodging_trip_advisor_rating: lodging?.tripAdvisorReviews?.overallScore,
        number_of_photos: lodging?.media.length,
        breakfast_included_shown:
          selectedRoom?.roomInfo?.amenities?.filter(
            (a) => a.amenity === "FreeBreakfast"
          )?.length > 0,
        selectable_rates_count: selectedRoom?.products?.length,
        room_index_in_shop: rooms.findIndex((r) => r === selectedRoom) || 0,
      },
      encryptedProperties: [
        trackingData?.encryptedProperties,
        selectedLodging?.trackingPropertiesV2?.encryptedProperties,
        roomRateTrackingData?.encryptedProperties,
        roomTrackingData?.encryptedProperties,
      ].filter((p) => !!p),
    }),
    [
      lodging,
      roomRateTrackingData,
      rooms,
      roomTrackingData,
      selectedLodging?.trackingPropertiesV2,
      selectedRoom,
      trackingData,
    ]
  );
};
