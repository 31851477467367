import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { chargeAgentBookingFee } from "../../../../api/v1/book/book-flow/chargeAgentBookingFee";
import {
  CHARGE_BOOKING_AGENT_FEE,
  ChargeBookingAgentFeeProperties,
} from "@hopper-b2b/types";
import Logger from "../../../../helpers/Logger";
import { IStoreState } from "../../../../reducers/types";
import {
  getCompleteBuyAirProperties,
  getFinalizedItinerary,
} from "../../reducer";
import { trackEvent } from "@hopper-b2b/api";
import { flightApiConfigStoreKey } from "../../../../reducers/types";
import { AGENT_FEE } from "../../index";

const getAgentUsername = (identifier: string) => {
  const regex = /(.*)@(.*)/gm;
  const subst = "$1";
  return identifier.replace(regex, subst);
};

export function* chargeAgentBookingFeeSaga({
  agentEmail,
}: actions.IChargeAgentBookingFee) {
  const state: IStoreState = yield select();
  const apiConfig = state[flightApiConfigStoreKey];
  const completeBuyAirProperties = getCompleteBuyAirProperties(state);

  try {
    const finalizedItinerary = getFinalizedItinerary(state);
    const agentUsername = getAgentUsername(agentEmail);

    const requestBody = {
      transactionStoreId: finalizedItinerary?.transactionStoreId,
      initiator: {
        id: agentUsername,
        Initiator: "Automation",
      },
    };
    const response = yield chargeAgentBookingFee(requestBody, apiConfig);
    if (response?.ChargeBookingFeeResponse === "Success") {
      yield put(actions.chargeAgentBookingFeeSuccess());
      const properties: ChargeBookingAgentFeeProperties = {
        ...completeBuyAirProperties,
        agent_booking_fee_amount_usd: AGENT_FEE,
        success: true,
      };
      trackEvent(
        {
          eventName: CHARGE_BOOKING_AGENT_FEE,
          properties,
        },
        apiConfig
      );
    } else {
      throw Error(response);
    }
  } catch (e) {
    yield put(actions.chargeAgentBookingFeeFailed());
    Logger.debug(e);
    const properties: ChargeBookingAgentFeeProperties = {
      ...completeBuyAirProperties,
      agent_booking_fee_amount_usd: 0,
      success: false,
    };
    trackEvent(
      {
        eventName: CHARGE_BOOKING_AGENT_FEE,
        properties,
      },
      apiConfig
    );
  }
}
