import { useAccounts } from "@commbank/accounts";
import { ApacActionLink } from "@commbank/ui";
import {
  WalletCreditTransaction,
  WalletCreditTransactionEnum,
} from "@b2bportal/commbank-au-wallet-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useWallet } from "../../context";
import { AllCreditActivityModal } from "./AllCreditActivityModal";
import { CreditTransactions } from "./CreditTransactions";
import "./TravelCreditsTab.styles.scss";
import { FiatPrice } from "@b2bportal/hopper-wallet-api";
import { TabFooter } from "../TabFooter";

export type TravelCreditsTabProps = {
  isSidePanel?: boolean;
};

export const getTime = (transaction: WalletCreditTransaction) => {
  let time;
  switch (transaction.WalletCreditTransaction) {
    case WalletCreditTransactionEnum.Cancellation:
      time = transaction.cancelledOn;
      break;
    case WalletCreditTransactionEnum.Earn:
      time = transaction.earnedOn;
      break;
    case WalletCreditTransactionEnum.Expiration:
      time = transaction.expiredOn;
      break;
    case WalletCreditTransactionEnum.Redemption:
      time = transaction.redeemedOn;
      break;
    case WalletCreditTransactionEnum.Refund:
      time = transaction.refundedOn;
      break;
  }
  if (time) {
    return dayjs(time);
  } else {
    console.error("no timestamp found for transaction: ", transaction);
    return dayjs();
  }
};

export const BalanceSection = ({
  name,
  creditBalance,
}: {
  name: string;
  creditBalance: FiatPrice;
}) => {
  const { t, formatFiatCurrency } = useI18nContext();

  return (
    <Box className="balance-section">
      <Typography variant="body2">
        {t("wallet.balanceTitle", { name })}
      </Typography>
      {creditBalance?.value !== undefined && (
        <Typography variant="h2">
          {formatFiatCurrency(creditBalance)}
        </Typography>
      )}
      <Typography variant="body2">{t("wallet.balanceSubtitle")}</Typography>
    </Box>
  );
};

export const TravelCreditsTab = ({ isSidePanel }: TravelCreditsTabProps) => {
  const { t } = useI18nContext();
  const { matchesMobile: isMobile } = useDeviceTypes();

  const [openAllActivity, setOpenAllActivity] = useState(false);

  const {
    creditBalance,
    creditHistory,
    isLoadingWallet,
    fetchAndSetWalletSummary,
  } = useWallet();

  const { firstName } = useAccounts();

  const recentTransactions = useMemo(() => {
    return creditHistory
      .sort((a, b) => getTime(b).valueOf() - getTime(a).valueOf())
      .slice(0, 4);
  }, [creditHistory, creditHistory.length]);

  useEffect(() => {
    fetchAndSetWalletSummary();
  }, [fetchAndSetWalletSummary]);

  return (
    <div
      className={clsx("travel-credits-tab-container", {
        mobile: isMobile,
        isSidePanel,
      })}
    >
      <div className="balance-and-history-container">
        <BalanceSection name={firstName} creditBalance={creditBalance} />
        <div className="row-divider" />
        <CreditTransactions
          transactions={recentTransactions}
          isLoading={isLoadingWallet}
        />
        <ApacActionLink
          message={t("wallet.viewAllActivity")}
          onClick={() => setOpenAllActivity(true)}
        />
      </div>

      <TabFooter showTerms />

      <AllCreditActivityModal
        isMobile={isMobile}
        open={openAllActivity}
        onClose={() => setOpenAllActivity(false)}
        name={firstName}
        creditBalance={creditBalance}
        creditHistory={creditHistory}
        isLoading={isLoadingWallet}
      />
    </div>
  );
};
