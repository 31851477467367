import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import "./styles.scss";
import { Dealness } from "@b2bportal/air-shopping-api";

export interface PriceForecastItem {
  dealness: Dealness;
  title: string;
}
export interface IPriceForecastProps {
  className?: string;
  items: PriceForecastItem[];
  dealness: Dealness;
  isMobile?: boolean;
}

export const PriceForecast = (props: IPriceForecastProps) => {
  const { className, items, dealness, isMobile } = props;
  return (
    <Box
      className={clsx(className, "price-forecast", { mobile: isMobile })}
      aria-hidden={true}
    >
      {items.map((item) => (
        <Box key={item.dealness} className="price-forecast-item">
          <Typography
            className={clsx("price-forecast-label", {
              dealness: dealness === item.dealness,
            })}
            variant="subtitle2"
          >
            {item.title}
            {dealness === item.dealness && (
              <Box
                className={clsx(
                  "dealness-indicator",
                  (dealness as string).toLowerCase()
                )}
              ></Box>
            )}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
