import clsx from "clsx";
import { Spinner } from "../Spinner/component";
import "./styles.scss";

type DefaultStyle = "b2b-light" | "b2b-dark";

export interface IB2BSpinnerProps {
  className?: string;
  classes?: string[];
  defaultStyle?: DefaultStyle;
}

export const B2BSpinner = ({
  defaultStyle,
  className,
  classes,
}: IB2BSpinnerProps) => {
  return (
    <div className={clsx("b2b-spinner", defaultStyle, className, classes)}>
      <Spinner />
    </div>
  );
};
