import { FlightDetailsTimeline } from "../../../../../../../components/FlightDetailsTimeline";
import { ApacIcon, ApacIconName } from "@commbank/ui";
import { Airport } from "@b2bportal/air-shopping-api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  FareDetails,
  FlightShopCardType,
  TripDetails,
} from "@hopper-b2b/types";
import {
  FlightDetailsCard,
  MixedCabinToolTip,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import {
  formatInterval,
  getEmptyRestrictionsText,
  getIsMixedClass,
  getPlusDays,
  getSliceIndex,
  removeTimezone,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { Collapse, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { matchPath } from "react-router";
import { PATH_EXCHANGE } from "../../../../../../../utils/urlPaths";
import { FareDetailsCard } from "../FareDetailsCard";
import { FlightDetailsConnectorProps } from "./container";
import "./styles.scss";

export interface IFlightDetailsProps extends FlightDetailsConnectorProps {
  isOutgoing: boolean;
  selectedFareId: string;
  onFareClick: (sliceId: string, fare?: FareDetails) => void;
  onAlgomerchClick?: (label: string) => void;
  tripDetails: TripDetails;
  rewardsKey: string | undefined;
  departureDate: Date | undefined | null;
  returnDate: Date | undefined | null;
  airports: { [key: string]: Airport };
  openFlightDetailModal: boolean;
  clickedFareId: string;
  setExpandedFlight: (fareId: string) => void;
}

export const FlightDetails = ({
  tripDetails,
  isOutgoing,
  selectedFareId,
  onFareClick,
  onAlgomerchClick,
  rewardsKey,
  departureDate,
  returnDate,
  airports,
  selectedFareClassFilters,
  selectedOutgoingFareSlice,
  setExpandedFlight,
  clickedFareId,
  getFareSliceByFareId,
}: IFlightDetailsProps) => {
  const { matchesMobile } = useDeviceTypes();
  const { t, brand } = useI18nContext();
  const [detailsExpanded, setDetailsExpanded] = useState(true);
  const [isMixedCabinClass, setIsMixedCabinClass] = useState(false);
  const [fareDetails, setFareDetails] = useState<FareDetails | undefined>(
    undefined
  );
  const inExchange = matchPath(window.location.pathname, PATH_EXCHANGE);
  const tripSlice = isOutgoing ? tripDetails.slices[0] : tripDetails.slices[1];
  const plusDays = useMemo(() => {
    if (tripSlice) {
      return getPlusDays(tripSlice);
    } else {
      return 0;
    }
  }, [tripSlice]);
  const fareSlice = useMemo(() => {
    return fareDetails
      ? fareDetails.slices[getSliceIndex(isOutgoing, fareDetails)]
      : null;
  }, [fareDetails, isOutgoing]);

  useEffect(() => {
    if (selectedFareId || clickedFareId) {
      setFareDetails(
        tripDetails.fareDetails.find(
          (f) => f.id === selectedFareId || f.id === clickedFareId
        )
      );
    }
  }, [selectedFareId, clickedFareId, tripDetails.fareDetails]);

  useEffect(() => {
    if (fareDetails) {
      isOutgoing
        ? setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[0]))
        : setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[1]));
    }
  }, [fareDetails, isOutgoing]);

  // const priceFreezePurchaseQueryString = useMemo(() => {
  //   const urlParams: Record<string, string> = {
  //     tripId: tripDetails.id,
  //     fareId: clickedFareId,
  //   };

  //   // Add passenger query params
  //   if (adultsCount) {
  //     urlParams.adultsCount = `${adultsCount}`;
  //   }
  //   if (childrenCount) {
  //     urlParams.childrenCount = `${childrenCount}`;
  //   }
  //   if (infantsInSeatCount) {
  //     urlParams.infantsInSeatCount = `${infantsInSeatCount}`;
  //   }
  //   if (infantsOnLapCount) {
  //     urlParams.infantsOnLapCount = `${infantsOnLapCount}`;
  //   }

  //   return new URLSearchParams(urlParams).toString();
  // }, [
  //   adultsCount,
  //   childrenCount,
  //   infantsInSeatCount,
  //   infantsOnLapCount,
  //   clickedFareId,
  //   tripDetails.id,
  // ]);

  // TODO(isabela): integrate when price freeze is implemented
  // const handleMobileModalPriceFreezeEntry = useCallback(() => {
  //   trackEvent({
  //     eventName: PriceFreezeTrackingEvents.FREEZE_PRICE_CLICK,
  //     properties: {
  //       price_freeze_entry: PriceFreezeEntryEnum.FLIGHT_LIST,
  //     } as FreezePriceClickProperties,
  //   });
  //   history.push(
  //     `${PATH_PRICE_FREEZE_PURCHASE}?${priceFreezePurchaseQueryString}`,
  //     {
  //       priceFreezeEntry: PriceFreezeEntryEnum.FLIGHT_LIST,
  //       entryButton: EntryButtonEnum.FREEZE_PRICE,
  //       previousFlight: PreviousFlightEnum.CHOSEN_FLIGHT,
  //     } as PriceFreezeHistoryState
  //   );
  // }, [history, priceFreezePurchaseQueryString]);

  const handleMobileFareClick = useCallback(
    (fareId: string) => {
      onFareClick(
        tripSlice.id,
        tripDetails.fareDetails.find((f) => f.id === fareId)
      );
    },
    [onFareClick, tripSlice.id, tripDetails.fareDetails]
  );

  if (!tripDetails) return null;

  const hackerFareNotice = tripDetails.fareDetails.map((fare) => {
    if (fare.multiTicket) {
      return {
        id: fare.id,
        message: t("combinationFlightWarning"),
        tooltipCopy: t("combinationFlightTooltip"),
      };
    } else {
      return null;
    }
  });

  const renderHeader = (
    isOutgoing: boolean,
    location: string,
    date?: Date | null
  ) => {
    if (!location || !date) return undefined;
    return (
      <Typography variant="h5" className="flight-details-header">
        <I18nMarkup
          tKey={
            isOutgoing
              ? "flightShopReview.outboundCardHeader"
              : "flightShopReview.returnCardHeader"
          }
          replacements={{
            location,
            date: dayjs(date).format("ddd, MMM D"),
          }}
        />
        {isMixedCabinClass && <MixedCabinToolTip />}
      </Typography>
    );
  };

  const firstSliceIndex = getSliceIndex(true, tripDetails);
  const secondSliceIndex = getSliceIndex(false, tripDetails);

  return !matchesMobile ? (
    <FlightDetailsCard
      className={"b2b"}
      inExchange={Boolean(inExchange)}
      selectedFareId={selectedFareId}
      selectedFareClassFilters={selectedFareClassFilters}
      tripDetails={tripDetails}
      isOutgoing={isOutgoing}
      getEmptyRestrictionsText={getEmptyRestrictionsText}
      onFareClick={(fareId: string) =>
        matchesMobile
          ? handleMobileFareClick(fareId)
          : onFareClick(
              tripSlice.id,
              tripDetails.fareDetails.find((f) => f.id === fareId)
            )
      }
      fareNotice={hackerFareNotice}
      onAlgomerchClick={onAlgomerchClick}
      rewardsKey={rewardsKey}
      isMobile={matchesMobile}
      plusDays={plusDays}
      header={
        isOutgoing
          ? renderHeader(
              isOutgoing,
              airports[tripDetails.slices[firstSliceIndex].destinationCode]
                ? airports[tripDetails.slices[firstSliceIndex].destinationCode]
                    .cityName
                : tripDetails.slices[firstSliceIndex].destinationName,
              departureDate
            )
          : renderHeader(
              isOutgoing,
              airports[tripDetails.slices[secondSliceIndex].destinationCode]
                ? airports[tripDetails.slices[secondSliceIndex].destinationCode]
                    .cityName
                : tripDetails.slices[secondSliceIndex].destinationName,
              returnDate
            )
      }
      isMixedCabinClass={isMixedCabinClass}
      cardType={brand.flightShopCardType || FlightShopCardType.REGULAR}
      selectedOutgoingFareSlice={selectedOutgoingFareSlice}
      setExpandedFlight={setExpandedFlight}
      getFlightShopFareSlice={getFareSliceByFareId}
    />
  ) : (
    <MobilePopoverCard open fullScreen>
      <div className="apac-flight-details-container">
        <div className="flight-details-header">
          <ApacIcon
            className="header-back-button"
            name={ApacIconName.ChevronLeft}
            onClick={() => setExpandedFlight(null)}
          />
          <Typography className="title" variant="h3">
            {t("flightDetails")}
          </Typography>
        </div>

        <div className="flight-details-summary-header-container">
          <div
            className="flight-details-summary-header"
            onClick={() => setDetailsExpanded(!detailsExpanded)}
          >
            <Typography variant="h3">
              {t("flightShop.cardDetailsTitle", {
                fromCity: `${airports[tripSlice.originCode].cityName} (${
                  tripSlice.originCode
                })`,
                toCity: `${airports[tripSlice.destinationCode].cityName} (${
                  tripSlice.destinationCode
                })`,
                date: dayjs(removeTimezone(tripSlice.departureTime)).format(
                  "ddd DD MMM YY"
                ),
                duration: formatInterval(tripSlice.totalDurationMinutes),
              })}
            </Typography>
            <ApacIcon
              className={clsx("arrow", { "details-expanded": detailsExpanded })}
              name={ApacIconName.ChevronDown}
            />
          </div>

          <Collapse in={detailsExpanded}>
            <FlightDetailsTimeline
              className="review-itinerary-flight-details"
              segments={tripSlice.segmentDetails}
              departureTime={tripSlice.departureTime}
              planeInfo={
                fareSlice?.amenitiesUtas?.amenities?.aircraft.info.displayText
              }
              fareClass={fareSlice?.fareShelf?.shortBrandName || ""}
              showTitle={false}
              plusDays={plusDays}
              fareSlice={fareSlice}
              isMixedCabinClass={isMixedCabinClass}
            />
          </Collapse>
        </div>

        <div className="flight-details-fares-container">
          <FareDetailsCard
            fareDetails={tripDetails.fareDetails}
            isOutgoing={isOutgoing}
            getEmptyRestrictionsText={getEmptyRestrictionsText}
            selectedFareClassFilters={selectedFareClassFilters}
            getFlightShopFareSlice={getFareSliceByFareId}
            onAlgomerchClick={onAlgomerchClick}
            onClick={(fareDetails) => {
              if (fareDetails) {
                handleMobileFareClick(fareDetails.id);
              }
            }}
          />
        </div>
      </div>
    </MobilePopoverCard>
  );
};
