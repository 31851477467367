import { ApacGenericSlider } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { type PriceRange as PriceRangeType } from "../../reducer";
import "./PriceRange.styles.scss";

interface Props {
  min: number;
  max: number;
  lowest: number;
  highest: number;
  onFilterPriceRangeChange: (
    nextValue: Pick<PriceRangeType, "min" | "max">
  ) => void;
}

const optionsToHideFractionDigits = {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
};

export function PriceRange({
  min,
  max,
  lowest,
  highest,
  onFilterPriceRangeChange,
}: Props) {
  const { t, formatFiatCurrency, brand } = useI18nContext();

  return (
    <div className="hotel-price-range-filter-container">
      <Box className="from-to-value-container">
        <div className="value-item">
          <Typography variant="h6">{t("flightShop.from")}</Typography>
          <div className="value-label-wrapper">
            <Typography>
              {formatFiatCurrency(
                { value: min, currencyCode: brand.currency.code },
                optionsToHideFractionDigits
              )}
            </Typography>
          </div>
        </div>
        <div className="value-item">
          <Typography variant="h6">{t("flightShop.to")}</Typography>
          <div className="value-label-wrapper">
            <Typography>
              {formatFiatCurrency(
                { value: max, currencyCode: brand.currency.code },
                optionsToHideFractionDigits
              )}
            </Typography>
          </div>
        </div>
      </Box>
      <ApacGenericSlider
        className="price-range-slider"
        aria-label={t("priceRange")}
        onChange={(min, max) => {
          onFilterPriceRangeChange({ min, max });
        }}
        sliderType={"doubleThumb"}
        step={10}
        chosenMin={min}
        chosenMax={max}
        sliderMin={lowest}
        sliderMax={highest}
        minDistance={50}
      />
    </div>
  );
}
