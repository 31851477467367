export const FETCH_CFAR_OFFERS = "flightCfar/FETCH_CFAR_OFFERS";
export type FETCH_CFAR_OFFERS = typeof FETCH_CFAR_OFFERS;

export const SET_CFAR_OFFERS = "flightCfar/SET_CFAR_OFFERS";
export type SET_CFAR_OFFERS = typeof SET_CFAR_OFFERS;

export const SET_CFAR_OFFERS_FAILED = "flightCfar/SET_CFAR_OFFERS_FAILED";
export type SET_CFAR_OFFERS_FAILED = typeof SET_CFAR_OFFERS_FAILED;

export const SET_SELECTED_CFAR_OFFER = "flightCfar/SET_SELECTED_CFAR_OFFER";
export type SET_SELECTED_CFAR_OFFER = typeof SET_SELECTED_CFAR_OFFER;
