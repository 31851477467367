import {
  airChfarApi,
  AirChfarTripDetailsResponseEnum,
  TripDetailsV2,
} from "@b2bportal/air-chfar-api";
import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import axios from "axios";
import { trackEvent } from "../../tracking/trackEvent";

export const fetchChfarTripDetails = async (
  tripId: string,
  chfarId: string,
  apiConfig?: IApiConfig
): Promise<TripDetailsV2> => {
  try {
    const res = await airChfarApi(axios).apiV0ChfarAirTripDetailsPost({
      contractId: chfarId,
      tripId: { value: tripId },
    });
    if (
      res.data.AirChfarTripDetailsResponse ===
      AirChfarTripDetailsResponseEnum.Success
    ) {
      if (res.data.value != null) {
        return res.data.value;
      } else {
        throw "apiV0ChfarAirTripDetailsPost returned no data";
      }
    } else {
      throw res.data;
    }
  } catch (error) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      apiConfig
    );
    throw error;
  }
};
