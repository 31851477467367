export type AppVerifyResultResponse =
  | PaymentMethodErrorCode
  | AppVerifyResultSuccess;

export interface AppVerifyResultSuccess {
  value: PaymentVerifyResult;
}

export interface CardDeclined {
  PaymentVerifyResult: PaymentVerifyResultEnum;
}

export interface CreditCard {
  id: string;
  token: string;
  firstName: string;
  lastName: string;
  cardType: string;
  numberDisplay: string;
  month: number;
  year: number;
  zip: string;
  country: string;
  alias?: string;
  createdAt: string;
  updatedAt?: string;
  scanned: string;
  last4?: string;
  bin?: string;
  Payment: PaymentEnum;
}

export interface DeletePaymentMethodRequest {
  paymentId: string;
}

export type DeletePaymentMethodResponse =
  | PaymentMethodErrorCode
  | DeletePaymentMethodSuccess;

export interface DeletePaymentMethodSuccess {
  value: object;
}

export interface PaymentMethodErrorCode {
  code: string;
  details?: string;
}

export interface ExcessiveUserActivity {
  PaymentVerifyResult: PaymentVerifyResultEnum;
}

export interface GenericError {
  PaymentVerifyResult: PaymentVerifyResultEnum;
}

export interface GetPaymentMethodRequest {
  paymentId: string;
}

export type GetPaymentMethodResponse =
  | PaymentMethodErrorCode
  | GetPaymentMethodSuccess;

export interface GetPaymentMethodSuccess {
  value?: Payment;
}

export type ListPaymentMethodResponse =
  | PaymentMethodErrorCode
  | ListPaymentMethodSuccess;

export interface ListPaymentMethodSuccess {
  value: Payment[];
}

export type Payment = CreditCard;

export enum PaymentEnum {
  CreditCard = "CreditCard",
}

export type PaymentVerifyResult =
  | CardDeclined
  | ExcessiveUserActivity
  | GenericError
  | Success;

export enum PaymentVerifyResultEnum {
  CardDeclined = "CardDeclined",
  ExcessiveUserActivity = "ExcessiveUserActivity",
  GenericError = "GenericError",
  Success = "Success",
}

export interface Success {
  payment: Payment;
  PaymentVerifyResult: PaymentVerifyResultEnum;
}

export interface VerifyPaymentMethodRequest {
  token: string;
}
