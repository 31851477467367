import {
  ExperimentState,
  ExperimentsType,
  IApiConfig,
  TrackingPropertiesType,
} from "@hopper-b2b/types";
import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import { initFeatureFlagsState } from "./utils/initFeatureFlagsState";

export const HIDE_CC = "ctr-marketplace-hidecreditcard";

const defaultInitState: ExperimentState = {
  experiments: {},
};

export const FeatureFlagsContext =
  createContext<ExperimentState>(defaultInitState);

type FeatureFlagsProviderProps = {
  apiConfig: IApiConfig;
  initState?: ExperimentState;
  isLoggedIn?: boolean;
};

export const FeatureFlagsProvider: FC<
  FeatureFlagsProviderProps & PropsWithChildren
> = ({
  apiConfig,
  initState = defaultInitState,
  isLoggedIn = false,
  children,
}) => {
  const [experiments, setExperiments] = useState<ExperimentsType>(
    initState.experiments
  );
  const [trackingProperties, setTrackingProperties] =
    useState<TrackingPropertiesType>(initState.trackingProperties ?? {});

  const shouldFetchFeatureFlags = useMemo(
    (): boolean => !initState?.experiments?.size && isLoggedIn,
    [initState?.experiments?.size, isLoggedIn]
  );

  const value: ExperimentState = useMemo(
    () => ({ experiments, trackingProperties }),
    [experiments, trackingProperties]
  );

  useEffect(() => {
    initFeatureFlagsState(
      shouldFetchFeatureFlags,
      apiConfig,
      defaultInitState
    ).then((newState: ExperimentState) => {
      setExperiments((currentState) => ({
        ...currentState,
        ...newState.experiments,
      }));
      setTrackingProperties((currentState) => ({
        ...currentState,
        ...newState.trackingProperties,
      }));
    });
  }, [apiConfig, shouldFetchFeatureFlags, isLoggedIn]);

  return <FeatureFlagsContext.Provider value={value} children={children} />;
};
