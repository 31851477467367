import { ActionButton } from "@hopper-b2b/ui-core";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ActionLink } from "../ActionLink";
import { ConfirmedFlightSummary } from "../FlightConfirmation";
import {
  FlightSummaryPanel,
  IFlightSummaryPanelProps,
} from "../FlightSummaryPanel";
import "./styles.scss";

interface IFrozenPriceDetailsProps {
  duration: string;
  price: string;
  details: string;
}

interface ButtonProps {
  content: string;
  ariaLabelText?: string;
  buttonStyle?: "h4r-primary" | "h4r-secondary";
  onClick: () => void;
}

export interface IPriceFreezeEntryDetailsProps {
  className?: string;
  header?: string;
  subtitle?: string;
  departureFlight: IFlightSummaryPanelProps;
  returnFlight?: IFlightSummaryPanelProps;
  frozenPrice: IFrozenPriceDetailsProps;
  buttonsProps: ButtonProps[];
  termsProps: ButtonProps;
  isMobile?: boolean;
}
export interface Entry extends ConfirmedFlightSummary {
  fareClass: string;
}

export interface IPriceFreezeOfferEntries {
  departureFlight?: Entry | null;
  returnFlight?: Entry | null;
}

export const PriceFreezeEntryDetails = (
  props: IPriceFreezeEntryDetailsProps
) => {
  const {
    className,
    header,
    subtitle,
    departureFlight,
    returnFlight,
    frozenPrice,
    buttonsProps,
    termsProps,
    isMobile,
  } = props;

  return (
    <Box
      className={clsx("price-freeze-entry-details-root", className, {
        mobile: isMobile,
      })}
    >
      <Box className="price-freeze-entry-details-container">
        {(header || subtitle) && (
          <Box className="header-section">
            {header && (
              <Typography
                className="header-text"
                variant="body1"
                dangerouslySetInnerHTML={{ __html: header }}
              />
            )}
            {subtitle && (
              <Typography
                className="subtitle-text"
                variant="subtitle2"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            )}
          </Box>
        )}
        <Box className="flights-and-frozen-price-section">
          <Box className="frozen-price-section">
            <Box className="frozen-price-details">
              <Typography
                className="duration"
                variant="subtitle1"
                dangerouslySetInnerHTML={{ __html: frozenPrice.duration }}
              />
              <Typography
                className="price"
                variant="h4"
                dangerouslySetInnerHTML={{ __html: frozenPrice.price }}
              />
              <Typography
                className="details"
                variant="body2"
                dangerouslySetInnerHTML={{ __html: frozenPrice.details }}
              />
            </Box>
          </Box>
          <Box className="flights-summary-section">
            <FlightSummaryPanel
              className={clsx("price-freeze-flight-summary", "departure")}
              {...departureFlight}
              isMobile={isMobile}
            />
            {returnFlight && (
              <FlightSummaryPanel
                className={clsx("price-freeze-flight-summary", "return")}
                {...returnFlight}
                isMobile={isMobile}
              />
            )}
          </Box>
        </Box>
        <Box className="bottom-section">
          <Box className="buttons-section">
            {buttonsProps.map((buttonProps, index) => (
              <ActionButton
                key={index}
                className="price-freeze-entry-button"
                onClick={buttonProps.onClick}
                disabled={false}
                message={buttonProps.content}
                defaultStyle={buttonProps.buttonStyle ?? "h4r-primary"}
                ariaLabelText={buttonProps.ariaLabelText}
              />
            ))}
          </Box>
          <ActionLink
            className="price-freeze-terms-link"
            onClick={termsProps.onClick}
            content={
              <Typography variant="inherit" className="terms">
                {termsProps.content}
              </Typography>
            }
            label={termsProps.ariaLabelText}
          />
        </Box>
      </Box>
    </Box>
  );
};
