import { all } from "redux-saga/effects";
import { sagas as trips } from "../pages/TripsList";
import { sagas as rewards } from "../modules/rewards";

export function* rootSaga() {
  yield all([
    trips.watchFetchCarsSaga(),
    trips.watchFetchFlightsSaga(),
    trips.watchFetchHotelsSaga(),
    trips.watchListWatches(),
    trips.watchListPriceFreeze(),
    trips.watchPopulateTripQueryParams(),
    trips.watchReplaceTripQueryParams(),
    trips.watchUpdateWatch(),
    trips.watchDeleteWatch(),
    trips.watchAcceptScheduleChange(),
    trips.watchDenyScheduleChange(),
    trips.watchFetchFlightCfarCancellationQuoteSaga(),
    trips.watchConfirmFlightCfarCancellationSaga(),
    trips.watchDiscardFlightCfarCancellationQuoteSaga(),
    trips.watchFetchFlightCfarCancellationQuoteV1Saga(),
    trips.watchConfirmFlightCfarCancellationV1Saga(),
    trips.watchGetWatchDetailsSaga(),
    trips.watchCreateWatchSaga(),
    rewards.watchFetchRewardsAccounts(),
  ]);
}
