import { Box, Typography, TypographyVariant } from "@material-ui/core";
import { ReactElement } from "react";
import clsx from "clsx";
import { ReactComponent as ErrorState } from "../../assets/icons/common/warning-icon-orange.svg";
import "./styles.scss";

/**
 * Flight Summary Row
 * @function NoResults
 * @param classes
 *
 */
export interface INoResultsProps {
  className?: string;
  title: string | JSX.Element;
  subtitle: string;
  hideIcon?: boolean;
  iconSrc?: string;
  subtitleVariant?: TypographyVariant;
}

export const NoResults = ({
  className,
  title,
  subtitle,
  hideIcon,
  iconSrc,
  subtitleVariant,
}: INoResultsProps): ReactElement => {
  const renderIcon = () =>
    iconSrc ? (
      <img src={iconSrc} alt="Error" />
    ) : (
      <ErrorState className="no-results-icon" />
    );

  return (
    <Box className={clsx("no-results", className)}>
      {!hideIcon ? renderIcon() : null}
      <Typography className="no-results-title" variant="body1">
        {title}
      </Typography>
      <Typography
        className="no-results-subtitle"
        variant={subtitleVariant || "subtitle2"}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};
export default NoResults;
