import { useContext } from "react";
import { useActor, useSelector } from "@xstate/react";
import { State, Interpreter, EventObject, Sender, ActorRef } from "xstate";
import { CheckoutStateContext } from "../context/CheckoutProvider";

type Selector<IReturnType, IContext> = (state: State<IContext>) => IReturnType;

export function useCheckoutStateSelector<IReturnType, IContext>(
  selector: Selector<IReturnType, IContext>
) {
  const ctx = useContext(CheckoutStateContext);
  if (ctx === undefined) {
    throw new Error(`must be used within a CheckoutStateProvider`);
  }
  return useSelector(ctx as Interpreter<any, any, any>, selector);
}

export function useCheckoutState<IEvent extends EventObject, IContext>(): [
  State<IContext>,
  Sender<IEvent>
] {
  const ctx = useContext(CheckoutStateContext);
  if (ctx === undefined) {
    throw new Error(`must be used within a CheckoutStateProvider`);
  }
  return useActor(ctx as Interpreter<any, any, any>);
}

export function useCheckoutSend<IEvent extends EventObject>(): Sender<IEvent> {
  const [, send] = useCheckoutState();
  return send;
}

function getChildActorRef<IContext, IEvent extends EventObject>(
  parentState: State<IContext, IEvent>,
  machineId: string
): ActorRef<IEvent> {
  const childMachineRef = parentState.context[machineId].ref;
  if (!childMachineRef) {
    throw new Error(`child machine not initialized`);
  }
  return childMachineRef;
}

export function useChildMachineState<IEvent extends EventObject, IContext>(
  machineId: string
): [State<IContext>, Sender<IEvent>] {
  const [parentState] = useCheckoutState();
  const childMachineRef = getChildActorRef(parentState, machineId);
  return useActor(childMachineRef);
}

export function useChildMachineSelector<T, IContext>(
  machineId: string,
  selector: Selector<T, IContext>,
  compare?: (a: T, b: T) => boolean
) {
  const [parentState] = useCheckoutState();
  const childMachineRef = getChildActorRef(parentState, machineId);

  return useSelector(childMachineRef, selector, compare);
}
