import { useI18nContext } from "@hopper-b2b/i18n";
import { RadioDropdown } from "@hopper-b2b/ui";
import { SortOption } from "../../../../reducer";
import { SortOptionSelectionConnectorProps } from "./container";

export interface ISortOptionSelectionProps
  extends SortOptionSelectionConnectorProps {
  showDropdownContentOnly?: boolean;
}

export interface ISortOptions {
  label: string;
  shortLabel: string;
  // value - Does not need i18n, used in the radio group only
  value: SortOption;
}

const sortOptions: ISortOptions[] = [
  {
    label: "sortOption.recommended",
    shortLabel: "sortOption.recommended",
    value: "fareScore",
  },
  {
    label: "sortOption.price",
    shortLabel: "sortOption.priceShortened",
    value: "price",
  },
  {
    label: "sortOption.depart",
    shortLabel: "depart",
    value: "departureTime",
  },
  {
    label: "sortOption.arrive",
    shortLabel: "sortOption.arriveShortened",
    value: "arrivalTime",
  },
  {
    label: "sortOption.stops",
    shortLabel: "sortOption.stopsShortened",
    value: "stops",
  },
  {
    label: "sortOption.duration",
    shortLabel: "sortOption.durationShortened",
    value: "duration",
  },
];

export const getSortLabelKey = (sortOption): string =>
  sortOptions.find((option) => option.value === sortOption)?.shortLabel || "";

export const SortOptionSelection = (props: ISortOptionSelectionProps) => {
  const { sortOption, setSortOption, showDropdownContentOnly } = props;

  const { t } = useI18nContext();

  return (
    <RadioDropdown
      dropdownLabel={t(getSortLabelKey(sortOption))}
      options={sortOptions.map((option) => {
        return {
          value: option.value,
          label: t(option.label),
        };
      })}
      setOption={(option) => setSortOption(option as SortOption)}
      selected={sortOption}
      groupAriaLabel={"Choose a Sort Option"}
      buttonAriaLabel={"Change Sort Option"}
      popoverClassName="b2b"
      showDropdownContentOnly={showDropdownContentOnly}
    />
  );
};
