import { Tab, Typography } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import clsx from "clsx";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import { HotelItinerary } from "@b2bportal/lodging-api";
import {
  GetTripAccessTokenResponse,
  GetTripAccessTokenResponseEnum,
  Product,
} from "@b2bportal/purchase-api";
import { useExperiment } from "@hopper-b2b/experiments";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ItineraryEnum } from "@hopper-b2b/types";
import { ActionButton, LoadingFailure } from "@hopper-b2b/ui";
import { useEnableAirTripSearch } from "@hopper-b2b/utilities";
import { ClientContext } from "../../../../App";
import { fetchFlightReservation } from "../../../../api/v1/itinerary/fetchFlightReservation";
import { fetchHotelReservation } from "../../../../api/v1/itinerary/fetchHotelReservation";
import { fetchTripToken } from "../../../../api/v1/itinerary/fetchTripToken";
import {
  setFlightContext,
  setTripSearchResults,
} from "../../../TripsList/actions/actions";
import { ItineraryList } from "../ItineraryList";

import "./styles.scss";

export interface ITripSearchProps {
  isMobile: boolean;
  tripSearchResults:
    | HotelItinerary
    | BookedFlightItineraryWithDepartureTime
    | null;
}

export const TripSearch = ({
  isMobile,
  tripSearchResults,
}: ITripSearchProps) => {
  const tenantContext = useContext(ClientContext);
  const dispatch = useDispatch();
  const isAirEnabled = useExperiment("hopper-web-air");
  const isLodgingEnabled = useExperiment("hopper-web-lodging");
  const { t } = useI18nContext();
  const isEnableAirTripSearch = useEnableAirTripSearch();
  const lastNameInput = useRef(null);
  const reservationCodeInput = useRef(null);
  const [renderSearchResults, setRenderSearchResults] = useState(false);
  const [error, setError] = useState<GetTripAccessTokenResponseEnum | null>(
    null
  );

  const isAnomymousUser = true;
  const HOTELS_TAB_VALUE = "hotel-tab";
  const FLIGHTS_TAB_VALUE = "flights-tab";

  const initialTab = useMemo(
    () => (isLodgingEnabled ? HOTELS_TAB_VALUE : FLIGHTS_TAB_VALUE),
    [isLodgingEnabled]
  );

  const [activeTab, setActiveTab] = useState(initialTab);

  const onLookupTrip = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();

      setError(null);
      fetchTripToken(
        lastNameInput.current?.value,
        reservationCodeInput.current?.value,
        activeTab === HOTELS_TAB_VALUE ? Product.Hotel : Product.Flight
      )
        .then((res: GetTripAccessTokenResponse) => {
          if (
            res.GetTripAccessTokenResponse ===
            GetTripAccessTokenResponseEnum.TripFound
          ) {
            if (activeTab === HOTELS_TAB_VALUE) {
              fetchHotelReservation(res.id, res.tripAccessToken.value)
                .then((res: HotelItinerary) => {
                  dispatch(
                    setTripSearchResults({ ...res, type: ItineraryEnum.Hotel })
                  );
                  setRenderSearchResults(true);
                })
                .catch((error) => {
                  setError(error.name);
                  setRenderSearchResults(false);
                });
            } else {
              fetchFlightReservation(res.id, res.tripAccessToken.value)
                .then((res) => {
                  res.itinerary.type = ItineraryEnum.Flight;
                  dispatch(setFlightContext(res.context));
                  dispatch(setTripSearchResults(res.itinerary));
                  setRenderSearchResults(true);
                })
                .catch((error) => {
                  setError(error.name);
                  setRenderSearchResults(false);
                });
            }
          } else {
            setError(res.GetTripAccessTokenResponse);
            setRenderSearchResults(false);
          }
        })
        .catch((error) => {
          setError(error.name);
        });
    },
    [activeTab, dispatch]
  );

  const renderLookupForm = useCallback(() => {
    return (
      <form className="lookup-container" onSubmit={onLookupTrip}>
        <Typography variant="h2">{t("tripSearch.findBookingTitle")}</Typography>
        <div className="lookup-inputs">
          <input
            type="text"
            ref={lastNameInput}
            placeholder={t("tripSearch.lastName")}
          />
          <input
            type="text"
            ref={reservationCodeInput}
            placeholder={t("tripSearch.confirmationCode")}
          />
        </div>
        <ActionButton
          className="find-booking-button"
          message={t("tripSearch.findBooking")}
          submit
        />
      </form>
    );
  }, [onLookupTrip, t]);

  const tabs = useMemo(
    () =>
      [
        isLodgingEnabled
          ? {
              value: HOTELS_TAB_VALUE,
              label: (
                <div className="tab-title">
                  {t("hotels")}
                  <img src={tenantContext.assets?.hotels} alt={t("hotels")} />
                </div>
              ),
              panel: <>{renderLookupForm()}</>,
            }
          : undefined,
        isEnableAirTripSearch || isAirEnabled
          ? {
              value: FLIGHTS_TAB_VALUE,
              label: (
                <div className="tab-title">
                  {t("flights")}
                  <img src={tenantContext.assets?.flights} alt={t("flights")} />
                </div>
              ),
              panel: <>{renderLookupForm()}</>,
            }
          : undefined,
      ].filter(Boolean),
    [
      isAirEnabled,
      isEnableAirTripSearch,
      isLodgingEnabled,
      renderLookupForm,
      t,
      tenantContext.assets?.flights,
      tenantContext.assets?.hotels,
    ]
  );

  const handleTabChange = useCallback(
    (_e: React.ChangeEvent<unknown>, newValue: string) => {
      setActiveTab(newValue);
    },
    []
  );

  return isAnomymousUser ? (
    <div
      className={clsx("trip-search-container", "apac", { mobile: isMobile })}
    >
      <Typography variant="h1">{t("tripSearch.findBooking")}</Typography>
      <TabContext value={activeTab}>
        <TabList
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
          className="trip-header-tabs"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              className="trip-header-tab"
            />
          ))}
        </TabList>
        {tabs.map((tab) => (
          <TabPanel
            key={tab.value}
            className={"trip-tab-panel"}
            value={tab.value}
          >
            {tab.panel}
          </TabPanel>
        ))}
      </TabContext>
      {tripSearchResults && renderSearchResults ? (
        <div className="trip-search-results">
          <h2 className="trip-search-results-title">
            {t("tripSearch.searchResultsHeader")}{" "}
            <span className="trip-search-results-id">
              "{reservationCodeInput.current?.value}"
            </span>
          </h2>
          <ItineraryList
            isMobile={isMobile}
            tripSearchResults={tripSearchResults}
          />
        </div>
      ) : null}
      {error ? <ErrorEmptyState error={error} /> : null}
    </div>
  ) : null;
};

export interface IErrorEmptyStateProps {
  error: GetTripAccessTokenResponseEnum | null;
}

export const ErrorEmptyState = ({ error }: IErrorEmptyStateProps) => {
  const { t } = useI18nContext();

  const title = useMemo(() => {
    if (
      error === GetTripAccessTokenResponseEnum.TripNotFound ||
      error === GetTripAccessTokenResponseEnum.DeletedUser
    ) {
      return t("tripSearch.emptyStateTitle");
    } else {
      return t("tripSearch.errorTitle");
    }
  }, [error, t]);

  const message = useMemo(() => {
    if (error === GetTripAccessTokenResponseEnum.TripNotFound) {
      return t("tripSearch.emptyStateMessage");
    } else if (error === GetTripAccessTokenResponseEnum.DeletedUser) {
      return t("tripSearch.deletedUserMessage");
    } else {
      return t("tripSearch.errorMessage");
    }
  }, [error, t]);

  return <LoadingFailure title={title} message={message} showImage />;
};
