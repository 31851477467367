import {
  AlertKey,
  ShopFilter,
  WatchAlertView,
  WatchAlertViews,
} from "@b2bportal/air-price-watch-api";
import { AirportRegion, TagColors } from "../../common";
import { TripSummaryContext } from "./trip-summary";

export interface CreateWatchAlertRequest {
  key: AlertKey;
  method: MessageMethod;
  passengers: { [key: string]: number };
}

export type CreateWatchAlertResponse = ErrorCode | CreateWatchAlertSuccess;

export interface CreateWatchAlertSuccess {
  value: any;
}

export interface DeleteWatchAlertRequest {
  key: AlertKey;
}

export type DeleteWatchAlertResponse = ErrorCode | DeleteWatchAlertSuccess;

export interface DeleteWatchAlertSuccess {
  value: any;
}

export interface ErrorCode {
  code: string;
  details?: string;
}

export interface FlightAlertKey {
  filter: ShopFilter;
  origin: AirportRegion;
  destination: AirportRegion;
  departureDate: string;
  returnDate?: string;
}

export type MessageMethod = Email;

export interface Email {
  email: string;
  MessageMethod: MessageMethodEnum;
}

export enum MessageMethodEnum {
  Email = "Email",
}

export interface UpdateWatchAlertRequest {
  key: AlertKey;
  method: MessageMethod;
}

export type UpdateWatchAlertResponse = ErrorCode | UpdateWatchAlertSuccess;

export interface UpdateWatchAlertSuccess {
  value: WatchAlertViews;
}

export const getWatchDetails = (
  watch: WatchAlertView,
  context: TripSummaryContext | null,
  formatDate: (date: string) => string,
  getFilterLabel: (filter: ShopFilter) => string,
  buyString: string,
  waitString: string
) => {
  const showFilterLabel = [
    ShopFilter.NonStop,
    ShopFilter.NonStopNoLCC,
    ShopFilter.NoLCC,
  ].some((filter) => filter === watch.key.value.filter);
  return {
    titleTag: {
      label: watch.recommendation === "Buy" ? buyString : waitString,
      type: watch.recommendation === "Buy" ? TagColors.GREEN : TagColors.GREY,
    },
    subtitleTag: showFilterLabel
      ? {
          label: getFilterLabel(watch.key.value.filter),
          type: TagColors.GREY,
        }
      : undefined,
    title: `${
      context?.airports[watch.key.value.origin.code]?.cityName ||
      watch.key.value.origin.code
    } to ${
      context?.airports[watch.key.value.destination.code]?.cityName ||
      watch.key.value.destination.code
    }`,
    startDate: formatDate(watch.key.value.departureDate),
    endDate: watch.key.value.returnDate
      ? formatDate(watch.key.value.returnDate)
      : undefined,
    subtitle: watch.key.value.returnDate
      ? "Round-trip Flight"
      : "One-way Flight",
  };
};
