import { Box, CircularProgress, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ChatPropertiesType } from "@b2bportal/chat-api";
import { HotelItinerarySummary } from "@b2bportal/lodging-api";
import {
  confirmHotelCancel,
  confirmNonCartHotelCancel,
  getBookingSupportId,
  getHotelCancelQuote,
  getHotelCancelQuoteAny,
  pollHotelCancelFulfill,
  trackEvent,
} from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  BookingType,
  CancelModalFlowStepEnum,
  CancelScenarioEnum,
  CaseClassValue,
  ClientName,
  HotelCancelFulfillPollResEnum,
  HotelCancelFulfillRes,
  HotelCancelInfoRes,
  HotelItineraryWithType,
  IOpenModal,
  LodgingCancelQuoteResEnum,
  LodgingCancelScenario,
  SelfServeEvents,
} from "@hopper-b2b/types";
import {
  ContactSupportModalContent,
  GenericModalContent,
  HappyBunny,
  HotelSummaryPanel,
  ImportantInfoList,
  LostConnectionBunny,
  Slot,
} from "@hopper-b2b/ui";
import { ActionButton } from "@hopper-b2b/ui/core";
import {
  exhaustiveTypeCheck,
  getEnvVariables,
  getHotelItinerarySummaryWithType,
  toggleAdaChat,
} from "@hopper-b2b/utilities";

import "./styles.scss";

export interface ICancelHotelModalContentProps {
  hotel: HotelItineraryWithType;
  isMobile?: boolean;
  setOpenModal: (newModal?: IOpenModal) => void;
  onSupportClick?: (
    productId?: string,
    productType?: ChatPropertiesType,
    requestType?: string
  ) => void;
}

const MAX_TRY_AGAINS = 1;
const POLL_INTERVAL = 2000;

const defaultProps: Partial<ICancelHotelModalContentProps> = {
  isMobile: false,
};

const CancelHotelModalContent = (
  props: ICancelHotelModalContentProps
): JSX.Element => {
  const { hotel, isMobile, setOpenModal } = props;
  const { t } = useI18nContext();

  const loadingMsgRef = useRef(t("cancelHotelModal.loadingContext"));
  const pollIdRef = useRef<number>();

  const [activeStep, setActiveStep] = useState(CancelModalFlowStepEnum.loading);
  const [cancelScenario, setCancelScenario] = useState<LodgingCancelScenario>();
  const [fulfillId, setFulfillId] = useState<CaseClassValue | string>();
  const [numAttempts, setNumAttempts] = useState(0);

  const tenant = getEnvVariables("clientName");
  const isHopperWeb = tenant === ClientName.HOPPER;

  const { lodgingSummary } = getHotelItinerarySummaryWithType(
    hotel
  ) as HotelItinerarySummary;

  const confirmCancel = useCallback(async () => {
    loadingMsgRef.current = t("cancelHotelModal.processingCancel");
    setActiveStep(CancelModalFlowStepEnum.loading);

    try {
      let res: HotelCancelFulfillRes;

      trackEvent({
        eventName: SelfServeEvents.ConfirmCancelation,
        properties: {
          product: "hotel",
          lob: "hotel",
        },
        encryptedProperties: [],
      });

      if (fulfillId) {
        // fulfillId should be set by this point but TS doesn't know that
        if (typeof fulfillId === "string") {
          res = await confirmNonCartHotelCancel(fulfillId);
        } else {
          res = await confirmHotelCancel(fulfillId);
        }

        if ("fulfillmentSessionId" in res) {
          pollCancelFulfillment(res.fulfillmentSessionId);
        } else {
          setActiveStep(CancelModalFlowStepEnum.success);
        }
      }
    } catch (err) {
      setActiveStep(CancelModalFlowStepEnum.tryAgain);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillId, t]);

  const getCancelPolicy = useCallback(async () => {
    const { reservationBookingId } = hotel.reservation;

    loadingMsgRef.current = t("cancelHotelModal.loadingContext");
    setActiveStep(CancelModalFlowStepEnum.loading);

    try {
      let cancelPolicy: HotelCancelInfoRes;

      if (isHopperWeb) {
        cancelPolicy = await getHotelCancelQuoteAny(reservationBookingId);
      } else {
        cancelPolicy = await getHotelCancelQuote(reservationBookingId);
      }

      const { LodgingCancelQuoteResponse, cancelScenario } = cancelPolicy;

      setCancelScenario(cancelScenario);

      switch (LodgingCancelQuoteResponse) {
        case LodgingCancelQuoteResEnum.Success:
        case LodgingCancelQuoteResEnum.CartSuccess:
          setFulfillId(cancelPolicy.quoteId);
          break;
        case LodgingCancelQuoteResEnum.NonCartSuccess:
          setFulfillId(cancelPolicy.clientTokenId);
          break;
      }

      switch (cancelScenario.LodgingCancelScenario) {
        case CancelScenarioEnum.CancellationFailure:
        case CancelScenarioEnum.Pending:
          setActiveStep(CancelModalFlowStepEnum.pending);
          break;
        case CancelScenarioEnum.ContactCustomerService:
        case CancelScenarioEnum.FullyRefundableComplex:
        case CancelScenarioEnum.OutOfPolicy:
        case CancelScenarioEnum.PartiallyRefundableComplex:
        case CancelScenarioEnum.PastCheckInDate:
          setActiveStep(CancelModalFlowStepEnum.support);
          break;
        case CancelScenarioEnum.Canceled:
          setActiveStep(CancelModalFlowStepEnum.cancelled);
          break;
        case CancelScenarioEnum.CfarRefundable:
        case CancelScenarioEnum.ChFarRefundable:
        case CancelScenarioEnum.HopperRefundable:
        case CancelScenarioEnum.LfarRefundable:
          setActiveStep(CancelModalFlowStepEnum.appService);
          break;
        case CancelScenarioEnum.FullyRefundable:
        case CancelScenarioEnum.NonRefundable:
        case CancelScenarioEnum.PartialRefund:
          setActiveStep(CancelModalFlowStepEnum.policy);
          break;
        default:
          setActiveStep(CancelModalFlowStepEnum.support);
      }
    } catch (err) {
      setActiveStep(CancelModalFlowStepEnum.error);
    }
  }, [hotel, isHopperWeb, t]);

  function clearPollInterval() {
    const { current: pollId } = pollIdRef;

    if (pollId) {
      window.clearInterval(pollId);
      pollIdRef.current = 0;
    }
  }

  const closeModal = useCallback(() => {
    setOpenModal({ type: null, selectedItinerary: null });
  }, [setOpenModal]);

  const contactCustomerSupport = useCallback(() => {
    closeModal();
    props.onSupportClick
      ? props.onSupportClick(
          hotel.reservation?.reservationBookingId,
          ChatPropertiesType.Hotel,
          "General"
        )
      : toggleAdaChat();
  }, [closeModal, hotel.reservation?.reservationBookingId, props]);

  /**
   * Cart orders have to be explicitly polled to verify they've been fulfilled
   */
  function pollCancelFulfillment(fulfillmentSessionId: CaseClassValue) {
    pollIdRef.current = window.setInterval(async () => {
      const res = await pollHotelCancelFulfill(fulfillmentSessionId);

      switch (res?.LodgingCancelFulfillmentPollResponse) {
        case HotelCancelFulfillPollResEnum.Pending:
          return;
        case HotelCancelFulfillPollResEnum.Aborted:
          clearPollInterval();
          // something went awry, contact support
          setActiveStep(CancelModalFlowStepEnum.support);
          return;
        case HotelCancelFulfillPollResEnum.Success:
          clearPollInterval();
          setActiveStep(CancelModalFlowStepEnum.success);
          return;
        default:
          exhaustiveTypeCheck(res?.LodgingCancelFulfillmentPollResponse);
      }
    }, POLL_INTERVAL);
  }

  function renderAppService() {
    const {
      cancelCopy: {
        body: [subtitle1, subtitle2],
        title,
      },
    } = cancelScenario;
    const ContactSupportBtn = (
      <ActionButton
        className="policy-contact-support-btn"
        defaultStyle="h4r-primary"
        message={t("contactSupport")}
        onClick={() => setActiveStep(CancelModalFlowStepEnum.support)}
      />
    );

    return (
      <GenericModalContent
        actions={ContactSupportBtn}
        className="hotel-app-service"
        columnAlign="center"
        content={
          <Box className="hotel-app-service-container">
            <Typography
              className="subtitle"
              dangerouslySetInnerHTML={{ __html: subtitle1 }}
              variant="subtitle2"
            />
            <Typography
              className="subtitle"
              dangerouslySetInnerHTML={{ __html: subtitle2 }}
              variant="subtitle2"
            />
          </Box>
        }
        image={
          <img alt="happy-bunny" className="success-icon" src={HappyBunny} />
        }
        title={title}
      />
    );
  }

  const tryAgainHandler = useCallback(() => {
    setNumAttempts(numAttempts + 1);
    getCancelPolicy();
  }, [getCancelPolicy, numAttempts]);

  function renderCancellationError() {
    const ContactSupportCTA = (
      <ActionButton
        className="contact-support-btn"
        defaultStyle="h4r-primary"
        message={t("selfServe.contactSupport")}
        onClick={() => setActiveStep(CancelModalFlowStepEnum.support)}
      />
    );
    const fetchPolicyFailed = activeStep === CancelModalFlowStepEnum.error;
    const alreadyCancelled = activeStep === CancelModalFlowStepEnum.cancelled;
    const showSupportBtn = alreadyCancelled || numAttempts >= MAX_TRY_AGAINS;
    let title = t("cancelHotelModal.error.cancelTitle");
    let subtitle = t("cancelHotelModal.error.subtitle");

    if (fetchPolicyFailed) {
      title = t("cancelHotelModal.error.policyTitle");
    } else if (alreadyCancelled) {
      title = t("cancelHotelModal.error.alreadyCanceled.title");
      subtitle = t("cancelHotelModal.error.alreadyCanceled.subtitle");
    }

    return (
      <Slot
        id="exchange-error-screen"
        primaryButtonText={t("contactSupport")}
        primaryOnClick={contactCustomerSupport}
        secondaryButtonText={t("tryAgain")}
        secondaryOnClick={tryAgainHandler}
        subtitle={subtitle}
        title={title}
        modal
        className="error"
        header={t("exchangeHeaderTitle")}
        component={
          <GenericModalContent
            actions={
              <>
                {showSupportBtn && ContactSupportCTA}
                <ActionButton
                  className="try-again-btn"
                  defaultStyle={
                    showSupportBtn ? "h4r-secondary" : "h4r-primary"
                  }
                  message={t("selfServe.tryAgainBtn")}
                  onClick={tryAgainHandler}
                />
              </>
            }
            className="hotel-cancel-error"
            image={
              <img
                alt="error-bunny"
                className="failure-icon"
                src={LostConnectionBunny}
              />
            }
            subtitle={subtitle}
            title={title}
          />
        }
      />
    );
  }

  function renderCancellationSuccess() {
    return (
      <Slot
        id="hotel-cancellation-confirmation-screen"
        className="hotel-cancel-success"
        subtitle={[
          t("cancelHotelModal.successSubtitle1"),
          t("cancelHotelModal.successSubtitle2"),
        ]}
        title={t("cancelHotelModal.successTitle")}
        primaryOnClick={closeModal}
        hotel={hotel}
        component={
          <GenericModalContent
            actions={
              <ActionButton
                className="close-btn h4r-primary blue"
                defaultStyle="h4r-primary"
                message={t("done")}
                onClick={closeModal}
              />
            }
            className="hotel-cancel-success"
            image={
              <img
                alt="happy-bunny"
                className="success-icon"
                src={HappyBunny}
              />
            }
            subtitle={[
              t("cancelHotelModal.successSubtitle1"),
              t("cancelHotelModal.successSubtitle2"),
            ]}
            title={t("cancelHotelModal.successTitle")}
            header={t("selfServe.cancellationConfirmation")}
          />
        }
      />
    );
  }

  function renderConfirmCancel() {
    const { cancelConfirmationCopy: copy } = cancelScenario;
    const { checkInDate, checkOutDate } = hotel.reservation;
    return (
      <GenericModalContent
        actions={
          <ActionButton
            className="confirm-cancel-btn h4r-primary red"
            defaultStyle="h4r-primary"
            message={t("cancelHotelModal.confirmBtn")}
            onClick={confirmCancel}
          />
        }
        className="hotel-cancel-confirm"
        columnAlign="flex-start"
        content={
          <Box className="cancel-confirm-content">
            <HotelSummaryPanel
              hideImage
              hideStarRating
              checkIn={checkInDate}
              checkOut={checkOutDate}
              isMobile={isMobile}
              lodging={lodgingSummary}
            />
            {copy.importantInfo.length ? (
              <ImportantInfoList
                infoItems={copy.importantInfo}
                title={t("cancelHotelModal.importantInfoTitle")}
              />
            ) : null}
          </Box>
        }
        subtitle={copy.body}
        title={copy.title}
      />
    );
  }

  function renderCancellationPending() {
    return (
      <GenericModalContent
        actions={
          <ActionButton
            className="h4r-primary blue"
            defaultStyle="h4r-primary"
            message={t("modalClose.label")}
            onClick={closeModal}
          />
        }
        className="hotel-cancel-pending"
        subtitle={t("cancelHotelModal.pendingSubtitle")}
        title={t("cancelHotelModal.pendingTitle")}
      />
    );
  }

  function renderLoading() {
    return (
      <Slot
        id="hotel-cancellation-loader"
        className="hotel-cancel-loading"
        loading={activeStep === CancelModalFlowStepEnum.loading}
        loadingSteps={[{ title: loadingMsgRef.current }]}
        averageLoadingTime={
          getEnvVariables("hotelCancellationLoadingTime") as number
        }
        component={
          <GenericModalContent
            className="hotel-cancel-loading"
            image={<CircularProgress />}
            subtitle={
              <Typography variant="subtitle2">
                {loadingMsgRef.current}
              </Typography>
            }
          />
        }
      />
    );
  }

  function renderPolicyDetails() {
    const { LodgingCancelScenario: scenario, cancelCopy: copy } =
      cancelScenario;
    const { checkInDate, checkOutDate } = hotel.reservation;
    const isNonRefundable = scenario === CancelScenarioEnum.NonRefundable;

    const BackToTripsBtn = (
      <ActionButton
        className="close-btn h4r-primary blue"
        defaultStyle="h4r-primary"
        message={t("backToTrips")}
        onClick={closeModal}
      />
    );
    let PrimaryAction = (
      <ActionButton
        className="cancel-btn h4r-primary blue"
        defaultStyle="h4r-primary"
        message={t("cancelHotelModal.cancelBtn")}
        onClick={
          tenant === ClientName.NUBANK
            ? confirmCancel
            : () => setActiveStep(CancelModalFlowStepEnum.confirm)
        }
      />
    );

    if (isNonRefundable) {
      PrimaryAction = BackToTripsBtn;
    }

    return (
      <GenericModalContent
        actions={PrimaryAction}
        className="hotel-cancel-policy"
        columnAlign="flex-start"
        content={
          isNonRefundable ? null : (
            <Box className="hotel-cancel-policy-container">
              <Slot
                id="hotel-cancellation-summary-panel"
                lodging={lodgingSummary}
                checkIn={checkInDate}
                checkOut={checkOutDate}
                onClick={confirmCancel}
                component={
                  <HotelSummaryPanel
                    hideImage
                    hideStarRating
                    checkIn={checkInDate}
                    checkOut={checkOutDate}
                    isMobile={isMobile}
                    lodging={lodgingSummary}
                  />
                }
              />
              {copy?.importantInfo.length ? (
                <ImportantInfoList
                  infoItems={copy.importantInfo}
                  title={t("cancelHotelModal.importantInfoTitle")}
                  titleProps={{ variant: "h3" }}
                />
              ) : null}
            </Box>
          )
        }
        subtitle={copy.body}
        title={copy.title}
        header={t("cancelHotelModal.cancelHeader")}
      />
    );
  }

  const renderContactSupport = () => (
    <Slot
      id="mytrips-contact-support-modal-content"
      handleCloseContactSupportModal={closeModal}
      handleOpenChat={contactCustomerSupport}
      hotelCancellation
      component={
        <ContactSupportModalContent
          bookingId={hotel.reservation.reservationId}
          bookingUuid={hotel.reservation.reservationBookingId}
          bookingType={BookingType.Lodging}
          className="hotel-cancel-contact-support"
          requestType="Cancel"
          getSupportId={getBookingSupportId}
          showHelpLink={false}
          subtitle={t("selfServe.supportSubtitle")}
          title={t("selfServe.supportTitle")}
        />
      }
    />
  );

  const ModalContent = useMemo(() => {
    switch (activeStep) {
      case CancelModalFlowStepEnum.appService:
        return renderAppService();
      case CancelModalFlowStepEnum.confirm:
        return renderConfirmCancel();
      case CancelModalFlowStepEnum.loading:
        return renderLoading();
      case CancelModalFlowStepEnum.pending:
        return renderCancellationPending();
      case CancelModalFlowStepEnum.policy:
        return renderPolicyDetails();
      case CancelModalFlowStepEnum.success:
        return renderCancellationSuccess();
      case CancelModalFlowStepEnum.support:
        return renderContactSupport();
      case CancelModalFlowStepEnum.cancelled:
      case CancelModalFlowStepEnum.error:
      case CancelModalFlowStepEnum.tryAgain:
        return renderCancellationError();
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, hotel, t]);

  useEffect(() => {
    if (hotel) {
      trackEvent({
        eventName: SelfServeEvents.ViewedCancelModal,
        properties: {
          product: "hotel",
          lob: "hotel",
        },
        encryptedProperties: [],
      });

      getCancelPolicy();
    }

    return () => {
      clearPollInterval();
    };
  }, [hotel, getCancelPolicy]);

  return (
    <Box
      className={clsx("cancel-hotel-modal-content", {
        mobile: isMobile,
      })}
    >
      {ModalContent}
    </Box>
  );
};

CancelHotelModalContent.defaultProps = defaultProps;

export default CancelHotelModalContent;
