import { Box } from "@material-ui/core";
import dayjs from "dayjs";
import * as React from "react";

import {
  Airport,
  BookedFlightItineraryWithDepartureTime,
  FlightItinerarySegment,
  ScheduleChange,
} from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  FlightItinerarySegmentStatusEnum,
  ScheduleChangeSeverity,
  TagColors,
  TagInfo,
  getDepartureSlice,
  getReturnSlice,
} from "@hopper-b2b/types";
import { ActionLink, IconName, StatusTag } from "@hopper-b2b/ui";

const TIME_FORMAT = "h:mm A";

export interface ITripsStatusTagProps {
  flight: BookedFlightItineraryWithDepartureTime;
  isOutgoing?: boolean;
  scheduleChange?: ScheduleChange;
  airportMap?: { [key: string]: Airport | undefined };
  className?: string;
  hasMajorScheduleChange?: boolean;
  onClick?: () => void;
}

/**
 * @deprecated Component to be implemented in the libs/self-serve folder and owned by CX Exp team
 */
export const TripsStatusTag = ({
  flight,
  isOutgoing,
  scheduleChange,
  airportMap,
  className,
  hasMajorScheduleChange,
  onClick,
}: ITripsStatusTagProps): React.ReactElement => {
  const { t } = useI18nContext();

  const getMinorScheduleChangeTooltip = (
    sliceIndex: number,
    segment?: FlightItinerarySegment,
    airportMap?: { [key: string]: Airport | undefined }
  ) => {
    if (segment) {
      const origin =
        airportMap && airportMap[segment.origin.locationCode]
          ? airportMap[segment.origin.locationCode]?.cityName
          : segment.origin.locationCode;
      const destination =
        airportMap && airportMap[segment.destination.locationCode]
          ? airportMap[segment.destination.locationCode]?.cityName
          : segment.destination.locationCode;
      return t("scheduleChange.minorTooltipModification", {
        flightType: sliceIndex > 0 ? "return" : "outbound",
        origin,
        destination,
        departure: dayjs(segment.scheduledDeparture).format(TIME_FORMAT),
        return: dayjs(segment.scheduledArrival).format(TIME_FORMAT),
      });
    } else {
      return t("scheduleChange.minorTooltipCancellation", {
        flightType: sliceIndex > 0 ? "return" : "outbound",
      });
    }
  };

  const getStatusTag = (
    flight: BookedFlightItineraryWithDepartureTime,
    isOutgoing: boolean,
    scheduleChange?: ScheduleChange,
    airportMap?: { [key: string]: Airport | undefined }
  ): TagInfo | undefined => {
    const hasUnknownScheduleChange =
      scheduleChange?.severity === ScheduleChangeSeverity.Unknown;
    const slice = isOutgoing
      ? getDepartureSlice(flight.bookedItinerary)
      : getReturnSlice(flight.bookedItinerary);
    const sliceIndex = isOutgoing ? 0 : 1;
    if (!slice?.segments) return undefined;

    for (let i = 0; i < slice!.segments.length; i++) {
      const segment = slice!.segments[i];
      switch (segment.status) {
        case FlightItinerarySegmentStatusEnum.Canceled:
          return {
            label: t("cancelled"),
            type: TagColors.RED,
          };
        case FlightItinerarySegmentStatusEnum.UnMapped:
        case FlightItinerarySegmentStatusEnum.UnMappedPersisted:
        case FlightItinerarySegmentStatusEnum.ConfirmedPendingNewChange: {
          const slices = scheduleChange?.next ?? [];
          const segmentHasMajorChange =
            scheduleChange?.severity === ScheduleChangeSeverity.Major &&
            (!slices[sliceIndex] ||
              slices[sliceIndex].segments[i].status ===
                FlightItinerarySegmentStatusEnum.UnMapped ||
              slices[sliceIndex].segments[i].status ===
                FlightItinerarySegmentStatusEnum.ConfirmedPendingNewChange);
          const segmentHasMinorChange =
            scheduleChange?.severity === ScheduleChangeSeverity.Minor &&
            (!slices[sliceIndex] ||
              slices[sliceIndex].segments[i].status ===
                FlightItinerarySegmentStatusEnum.UnMapped ||
              slices[sliceIndex].segments[i].status ===
                FlightItinerarySegmentStatusEnum.ConfirmedPendingNewChange);

          // TODO: currently only returns status tag if there is a segmentMajorChange or segmentMajorChange (meaning we are getting the scheduleChange data back) but there is currently an issue with minor change and the scheduleChange object returned so this may change when unknown/automatic minor changes (like exchanges) are sorted out
          return !hasUnknownScheduleChange &&
            (segmentHasMajorChange || segmentHasMinorChange)
            ? {
                label: t("itineraryModified"),
                type: segmentHasMajorChange ? TagColors.RED : TagColors.YELLOW,
                iconName: segmentHasMinorChange
                  ? IconName.InfoCircle
                  : IconName.InfoCircle,

                tooltipCopy: segmentHasMinorChange
                  ? getMinorScheduleChangeTooltip(
                      sliceIndex,
                      slices[sliceIndex]
                        ? slices[sliceIndex].segments[i]
                        : undefined,
                      airportMap
                    )
                  : t("tooltip.modified"),
              }
            : undefined;
        }
        case FlightItinerarySegmentStatusEnum.Pending:
          return {
            label: t("pending"),
            type: TagColors.GREY,
            iconName: IconName.InfoCircle,
            tooltipCopy: t("tooltip.pending"),
          };
        default:
          continue;
      }
    }
    return undefined;
  };

  const renderViewScheduleLink = () => (
    <ActionLink
      className="view-update-link"
      onClick={onClick}
      content={t("scheduleChange.reviewScheduleChange")}
    />
  );
  const statusTag = getStatusTag(
    flight,
    isOutgoing,
    scheduleChange,
    airportMap
  );

  if (statusTag) {
    return (
      <Box className={className}>
        <StatusTag tagInfo={statusTag} />
        {hasMajorScheduleChange && renderViewScheduleLink()}
      </Box>
    );
  }
};
