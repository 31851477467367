import { Person } from "@b2bportal/air-booking-api";
import { TravelerType } from "../ui/src/lib/PassengerInfo/PassengerInfo";
import dayjs from "dayjs";
import { getDateTimeWithFormat } from "@hopper-b2b/utilities";

export const getPassengerCountsString = ({
  adultsCount,
  childrenCount,
  infantsInSeatCount,
  infantsOnLapCount,
  infantsCount,
  t,
  joinChar = ",",
}: {
  t: (string, interpolations?: Record<string, string | number>) => string;
  adultsCount: number;
  childrenCount: number;
  infantsInSeatCount?: number;
  infantsOnLapCount?: number;
  infantsCount?: number;
  joinChar?: string;
}) => {
  const counts = [
    `${
      adultsCount === 1
        ? t("passengerInformation.adult_singular", {
            number: adultsCount,
          })
        : t("passengerInformation.adult_plural", {
            number: adultsCount,
          })
    }`,
  ];
  if (childrenCount) {
    counts.push(
      childrenCount === 1
        ? t("passengerInformation.child_singular", {
            number: childrenCount,
          })
        : t("passengerInformation.child_plural", {
            number: childrenCount,
          })
    );
  }
  const infantsNumber = infantsCount || infantsOnLapCount + infantsInSeatCount;
  if (infantsNumber) {
    counts.push(
      infantsNumber === 1
        ? t("passengerInformation.infant_singular", {
            number: infantsNumber,
          })
        : t("passengerInformation.infant_plural", {
            number: infantsNumber,
          })
    );
  }
  return counts.join(`${joinChar} `);
};

export const API_DATE_FORMAT = "YYYY-MM-DD";

export const isTravelerAdult = (dateOfBirth: string): boolean => {
  return (
    dayjs().diff(getDateTimeWithFormat(dateOfBirth, API_DATE_FORMAT), "year") >
    11
  );
};

export const getTravelerType = (passenger: Person): TravelerType => {
  const age = dayjs().diff(
    getDateTimeWithFormat(passenger.dateOfBirth, API_DATE_FORMAT),
    "year"
  );

  if (age > 11) {
    return TravelerType.ADULT;
  } else if (age >= 2) {
    return TravelerType.CHILD;
  } else {
    return TravelerType.INFANT;
  }
};

export const getSelectedPassengerCount = (passengers: Person[]) => {
  const counts = {
    adultsCount: 0,
    childrenCount: 0,
    infantsCount: 0,
  };

  passengers.forEach((passenger, index) => {
    const type = getTravelerType(passenger);

    switch (type) {
      case TravelerType.ADULT:
        counts.adultsCount++;
        break;
      case TravelerType.CHILD:
        counts.childrenCount++;
        break;
      default:
        counts.infantsCount++;
        break;
    }
  });

  return counts;
};
