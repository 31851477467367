import { MobilePopoverCard } from "@hopper-b2b/ui";
import clsx from "clsx";
import { ReactNode } from "react";
import { ApacIcon, ApacIconName } from "../ApacIcon";
import "./ApacMobileFullScreenModal.styles.scss";

export type ApacMobileFullScreenModalProps = {
  open: boolean;
  onClose?: () => void;
  className?: string;
  children?: ReactNode;
};

export const ApacMobileFullScreenModal = ({
  open,
  onClose,
  className,
  children,
}: ApacMobileFullScreenModalProps) => {
  return (
    <MobilePopoverCard
      open={open}
      fullScreen
      className={clsx("apac-mobile-full-screen-modal", className)}
      onClose={onClose}
      topRightButton={
        !!onClose && (
          <div onClick={onClose} className="close-button-container">
            <ApacIcon name={ApacIconName.Close} />
          </div>
        )
      }
    >
      {children}
    </MobilePopoverCard>
  );
};
