import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Divider, Typography } from "@material-ui/core";
import { ApacIcon, ApacIconName } from "../ApacIcon";
import { CBASpinner } from "../CBASpinner";
import styles from "./ApacLoadingWithLogo.module.scss";

export type ApacLoadingWithLogoProps = {
  title?: string;
  hideLogo?: boolean;
};

export const ApacLoadingWithLogo = ({
  title,
  hideLogo,
}: ApacLoadingWithLogoProps) => {
  const { t, brand } = useI18nContext();

  return (
    <Box className={styles["apac-loading-with-logo-container"]}>
      {!hideLogo && (
        <Box className={styles["loading-logo-group"]}>
          <ApacIcon name={ApacIconName.HopperLogoDesktop} />
          <Divider className={styles["divider"]} orientation="vertical" />
          <ApacIcon name={ApacIconName.ClientLogo} />
        </Box>
      )}
      {!hideLogo && (
        <Typography className={styles["header-title"]} variant="h2">
          {brand.portalName}
        </Typography>
      )}
      <CBASpinner />
      <Typography variant="body1">
        {title || `${t("loading")} ${brand.portalName}`}
      </Typography>
    </Box>
  );
};
