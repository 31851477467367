import {
  AirPriceFreezeContext,
  Checkout,
  PassengerMap,
} from "@hopper-b2b/checkout";
import { CheckoutProps, PassengerTypes } from "@hopper-b2b/types";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { PriceFreezePurchaseConnectorProps } from "./container";
import { parsePhoneNumber } from "@hopper-b2b/utilities";

interface FlightsCheckoutProps
  extends CheckoutProps,
    PriceFreezePurchaseConnectorProps {}

export const PriceFreezePurchase = (props: FlightsCheckoutProps) => {
  const {
    actions,
    Component,
    context,
    getInitialContext,
    guards,
    onCleanUp,
    onLoad,
    services,
    stateMachine,
    sessionInfo,
    clientAssets,
    onStateValueChange,
    onPathnameChange,
    defaultPriceFreezeOffer,
    airports,
  } = props;

  const queryString = useLocation().search;
  const queryParams = new URLSearchParams(queryString);
  const tripId = queryParams.get("tripId") ?? undefined;
  const fareId = queryParams.get("fareId") ?? undefined;
  const adultsCount = queryParams.get("adultsCount") ?? undefined;
  const childrenCount = queryParams.get("childrenCount") ?? undefined;
  const infantsInSeatCount = queryParams.get("infantsInSeatCount") ?? undefined;
  const infantsOnLapCount = queryParams.get("infantsOnLapCount") ?? undefined;

  const initialPassengerMap: PassengerMap = useMemo(
    () => ({
      [PassengerTypes.Adult]: adultsCount ? parseInt(adultsCount, 10) : 1,
      [PassengerTypes.Child]: childrenCount ? parseInt(childrenCount, 10) : 0,
      [PassengerTypes.InfantInSeat]: infantsInSeatCount
        ? parseInt(infantsInSeatCount, 10)
        : 0,
      [PassengerTypes.InfantInLap]: infantsOnLapCount
        ? parseInt(infantsOnLapCount, 10)
        : 0,
    }),
    [adultsCount, childrenCount, infantsInSeatCount, infantsOnLapCount]
  );

  const initialContactInfo = useMemo(() => {
    const sessionUserInfo = sessionInfo?.userInfoResponse;
    const phoneNumber = sessionUserInfo.mobileNumber;

    const parsedPhone = parsePhoneNumber(phoneNumber);
    return {
      countryCode: `+${parsedPhone.countryCode}` || "",
      phoneNumber: parsedPhone.nationalNumber || "",
      email: sessionUserInfo?.email || "",
    };
  }, [sessionInfo?.userInfoResponse]);

  const contextToInitialize: AirPriceFreezeContext = useMemo(() => {
    return {
      ...(context as AirPriceFreezeContext),
      sessionInfo: {
        ...(context?.sessionInfo || {}),
        ...sessionInfo,
      },
      contactInformation: initialContactInfo,
      flightShop: {
        tripId,
        fareId,
        airports,
      },
      defaultPriceFreezeOffer,
      passengerMap: initialPassengerMap,
    };
  }, [
    context,
    sessionInfo,
    initialContactInfo,
    tripId,
    fareId,
    airports,
    defaultPriceFreezeOffer,
    initialPassengerMap,
  ]);

  return (
    <Checkout
      actions={actions}
      Component={Component}
      context={contextToInitialize}
      guards={guards}
      onCleanUp={onCleanUp}
      onLoad={onLoad}
      services={services}
      stateMachine={stateMachine}
      getInitialContext={getInitialContext}
      clientAssets={clientAssets}
      onStateValueChange={onStateValueChange}
      onPathnameChange={onPathnameChange}
    />
  );
};
