import { RoomInfo } from "@b2bportal/lodging-api";
import { Box, Typography } from "@material-ui/core";

import clsx from "clsx";
import "../styles.scss";

export interface IHotelRoomNameProps {
  hideImage?: boolean;
  image: string;
  isMobile?: boolean;
  roomInfo: RoomInfo;
}

const defaultProps: Partial<IHotelRoomNameProps> = {
  hideImage: false,
  isMobile: false,
};

const HotelRoomName = (props: IHotelRoomNameProps): JSX.Element => {
  const { hideImage, image, isMobile, roomInfo } = props;
  const roomName = roomInfo.name || roomInfo.beds.description;

  return (
    <Box className={clsx("hotel-room-name", { mobile: isMobile })}>
      {image && !hideImage ? (
        <img alt="hotel-room" className="hotel-room-image" src={image} />
      ) : null}
      <Typography className="hotel-room-name-text">{roomName}</Typography>
    </Box>
  );
};

HotelRoomName.defaultProps = defaultProps;

export default HotelRoomName;
