import styles from "./TravelCreditsOffer.module.scss";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import { Box, Typography } from "@material-ui/core";
import { ApacActionButton, ApacTermsLink } from "../";

export const TravelCreditsOfferContent = ({
  bestOfferPercentage,
  onClose,
}: {
  bestOfferPercentage: number;
  onClose?: () => void;
}) => {
  const { t } = useI18nContext();
  return (
    <div className={styles["offer-details-modal-content"]}>
      <Box className={styles["header-image"]} />
      <Typography variant="h2">
        {t("commBank.earnBanner.checkoutOfferDescriptionTitle", {
          percent: bestOfferPercentage,
        })}
      </Typography>
      <Typography variant="body1">
        {bestOfferPercentage ? (
          <Trans
            i18nKey={t("commBank.earnBanner.hasOfferDescription", {
              percent: bestOfferPercentage,
            })}
            components={[
              <ApacTermsLink
                className={styles["offer-details-terms-link"]}
                scrollSelector="#travel-credits"
              />,
            ]}
          />
        ) : (
          <Trans
            i18nKey={t("commBank.earnBanner.noOfferDescription")}
            components={[
              <ApacTermsLink
                className={styles["offer-details-terms-link"]}
                scrollSelector="#best-price-guarantee"
              />,
            ]}
          />
        )}
      </Typography>
      {onClose && (
        <ApacActionButton onClick={onClose} message={t("close.button")} />
      )}
    </div>
  );
};
