import {
  Dealness,
  IsEligible,
  Prediction,
  PriceDropProtectionEnum,
} from "@b2bportal/air-shopping-api";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  CallState,
  PRICE_DROP_VIEWED,
  TripCategory,
  VIEWED_FORECAST,
  ViewedForecastProperties,
} from "@hopper-b2b/types";
import {
  IPriceWatchOptInState,
  PriceForecast,
  PricePredictionCard,
} from "@hopper-b2b/ui";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { getLowestPriceInRewards } from "../../constants";
import { PriceWatchCard } from "../PriceWatchCard";
import "./MobilePricePredictionSection.styles.scss";
import { MobilePricePredictionConnectorProps } from "./container";
import {
  ApacIcon,
  ApacIconName,
  ApacMobileFullScreenModal,
  CBASpinner,
} from "@commbank/ui";
import { PriceDropProtectionContent } from "../PricePrediction/PriceDropProtectionContent/PriceDropProtectionContent";
import { PricePredictionDetails } from "../PricePrediction/PricePredictionDetails";
import { PriceWatchDetails } from "../PricePrediction/PriceWatchDetails";
import { useWallet } from "@commbank/wallet";

export interface IMobilePricePredictionProps
  extends MobilePricePredictionConnectorProps {
  useLockPriceLanguage?: boolean;
}

export interface IMobilePricePredictionSectionProps
  extends IMobilePricePredictionProps {
  highlightPriceFreezeBox?: boolean;
  useDetailsPrefixPriceFreeze?: boolean;
  showFreezeIconPriceFreeze?: boolean;
  useChevronButtonPriceFreeze?: boolean;
  useLockPriceLanguage?: boolean;
  viewedForecastProperties?: ViewedForecastProperties;
}

export const getPriceDropMessageParams = (
  prediction: Prediction,
  formatCurrency: (value: number, options?: Intl.NumberFormatOptions) => string
): { day: number; amount: string } | null => {
  const eligibleContent = prediction?.priceDropProtection as IsEligible;
  const seconds = eligibleContent?.monitoringDuration?.inSeconds;
  if (!seconds) return null;

  return {
    day: Math.round(seconds / (24 * 60 * 60)),
    amount: formatCurrency(eligibleContent.maximumRefund.amount.amount, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }),
  };
};

export const getRecommendationTitle = (
  prediction: Prediction,
  t: (key: string) => string
) => {
  if (!prediction) return undefined;

  if (prediction.dealness === Dealness.fair) {
    return t("pricePrediction.fairPriceRecommendationTitle");
  } else {
    return prediction.predictionCopy?.recommendationTitle.join(" ");
  }
};

export const MobilePricePredictionSection = ({
  prediction,
  refreshPrediction,
  history,
  tripCategory,
  isWatching,
  fetchTripSummaries,
  deleteWatch,
  predictionLoading,
  createWatchCallState,
  listWatchCallState,
  createWatch,
  setCreateWatchCallState,
  viewedForecastProperties,
  selectedRewardsAccountId,
}: IMobilePricePredictionSectionProps) => {
  const [watchModalOpen, setWatchModalOpen] = useState(false);
  const [priceDropProtectionModalOpen, setPriceDropProtectionModalOpen] =
    useState(false);
  const [hasViewedForecast, setHasViewedForecast] = useState(false);
  const [pricePredictionModalOpen, setPricePredictionModalOpen] =
    useState(false);

  const { t, formatFiatCurrency } = useI18nContext();
  const { creditBalance } = useWallet();

  const isOneway = tripCategory === TripCategory.ONE_WAY;

  const getOptInState = () => {
    if (
      createWatchCallState === CallState.InProcess ||
      listWatchCallState === CallState.InProcess
    ) {
      return IPriceWatchOptInState.InProcess;
    } else if (isWatching) {
      return IPriceWatchOptInState.Watching;
    } else if (createWatchCallState === CallState.Failed) {
      return IPriceWatchOptInState.Failure;
    } else {
      return IPriceWatchOptInState.NotWatching;
    }
  };

  const isPDPEligible =
    prediction?.priceDropProtection?.PriceDropProtection ===
    PriceDropProtectionEnum.IsEligible;

  useEffect(() => {
    if (refreshPrediction) {
      fetchTripSummaries(history, true, creditBalance);
    }
    // removes medallia from the fixed bottom that was covering pricing
    if (document && document.getElementById("nebula_div_btn")) {
      document!.getElementById("nebula_div_btn")!.style.display = "none";
    }
    return () => {
      if (document && document.getElementById("nebula_div_btn")) {
        document!.getElementById("nebula_div_btn")!.style.display = "unset";
      }
    };
  }, []);

  useEffect(() => {
    if (prediction && isPDPEligible) {
      trackEvent({
        eventName: PRICE_DROP_VIEWED,
        properties: {
          page: "flight_list",
        },
      });
    }
  }, [prediction, isPDPEligible]);

  useEffect(() => {
    if (prediction && !hasViewedForecast && viewedForecastProperties) {
      trackEvent({
        eventName: VIEWED_FORECAST,
        properties: { ...viewedForecastProperties },
      });
      setHasViewedForecast(true);
    }
  }, [hasViewedForecast, prediction, viewedForecastProperties]);

  const PRICE_FORECAST_TITLES = [
    { title: t("priceForecast.greatTitle"), dealness: Dealness.great },
    { title: t("priceForecast.goodTitle"), dealness: Dealness.good },
    { title: t("pricePrediction.neutralScaleTitle"), dealness: Dealness.fair },
    { title: t("priceForecast.waitTitle"), dealness: Dealness.wait },
  ];

  return (
    <>
      {prediction && isPDPEligible && (
        <ApacMobileFullScreenModal
          open={priceDropProtectionModalOpen}
          onClose={() => setPriceDropProtectionModalOpen(false)}
        >
          <PriceDropProtectionContent
            onClose={() => setPriceDropProtectionModalOpen(false)}
            prediction={prediction}
          />
        </ApacMobileFullScreenModal>
      )}
      {prediction && (
        <ApacMobileFullScreenModal
          open={pricePredictionModalOpen}
          onClose={() => setPricePredictionModalOpen(false)}
        >
          <PricePredictionDetails
            onClose={() => setPricePredictionModalOpen(false)}
            onClickWatch={() => setWatchModalOpen(true)}
            isOneway={isOneway}
            prediction={prediction}
          />
        </ApacMobileFullScreenModal>
      )}

      {predictionLoading ? (
        <Box className={clsx("mobile-price-prediction-container", "section")}>
          <Box className="prediction-loading">
            <CBASpinner />
          </Box>
        </Box>
      ) : prediction && prediction.predictionCopy ? (
        <>
          <Box className={clsx("mobile-price-prediction-container", "section")}>
            <PricePredictionCard
              className={clsx("current-price")}
              title={""}
              subtitle={""}
              content={
                <Box>
                  <Typography className="current-lowest">
                    {t("pricePrediction.currentPrice")}
                  </Typography>
                  {!!prediction?.lowestPrice && (
                    <Typography className="lowest-price-content">
                      <span className="lowest-price-cash bold-text">
                        {formatFiatCurrency(prediction?.lowestPrice?.fiat)}
                      </span>
                      {selectedRewardsAccountId &&
                      prediction?.lowestPrice.accountSpecific[
                        selectedRewardsAccountId
                      ] ? (
                        <span>
                          {" or "}
                          <span className="bold-text">
                            {getLowestPriceInRewards(
                              prediction?.lowestPrice,
                              selectedRewardsAccountId
                            )}
                          </span>
                          {isOneway
                            ? t("pricePrediction.lowestPriceOneWaySuffix")
                            : t("pricePrediction.lowestPriceRoundTripSuffix")}
                        </span>
                      ) : (
                        ``
                      )}
                    </Typography>
                  )}

                  <Typography className="current-subtitle">
                    {getRecommendationTitle(prediction, t)}
                    <ApacIcon
                      name={ApacIconName.InfoOutlined}
                      onClick={() => {
                        setPricePredictionModalOpen(true);
                      }}
                    />
                  </Typography>
                </Box>
              }
            />
            <PriceForecast
              items={PRICE_FORECAST_TITLES}
              dealness={prediction.dealness}
            />
            <PriceWatchCard
              onPriceDropClick={
                isPDPEligible
                  ? () => setPriceDropProtectionModalOpen(true)
                  : undefined
              }
              onWatchClick={() =>
                isWatching ? deleteWatch() : setWatchModalOpen(true)
              }
              isWatching={isWatching}
            />
          </Box>

          <ApacMobileFullScreenModal
            open={watchModalOpen}
            onClose={() => setWatchModalOpen(false)}
          >
            <PriceWatchDetails
              onClose={(completed) => {
                setWatchModalOpen(false);
                setCreateWatchCallState(CallState.NotCalled);
                // Close price prediction if watch was successful
                if (completed) {
                  setPricePredictionModalOpen(false);
                }
              }}
              onWatchThisTrip={(email, options) => createWatch(email, options)}
              optInState={getOptInState()}
            />
          </ApacMobileFullScreenModal>
        </>
      ) : undefined}
    </>
  );
};
