import { ApacActionButton } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  AccountPrice,
  FiatPrice,
  GuestsSelection,
  TripCategory,
} from "@hopper-b2b/types";
import { Box, Divider, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CalendarPickerButton } from "../../../../components/CalendarPickerButton";
import { formatDateForUrl } from "../../../../util";
import { getTotalNights } from "../../../availability/reducer";
import { GuestPicker } from "../../../search/GuestPicker";
import {
  FiatAndPointPrice,
  getRooms,
  getSelectedRoomId,
  getSelectedRoomRateId,
} from "../../reducer/selectors";
import { getLowestRoomPrice } from "../../utils/getLowestPointsPrice";
import styles from "./SearchControlsSection.module.scss";

interface Props {
  rooms: number;
  initialCheckinDate: string;
  initialCheckoutDate: string;
  initialGuestCount: GuestsSelection;
  onDatesChanged: (checkinDate: string, checkoutDateDate: string) => void;
  onGuestCountChanged: (guestCount: GuestsSelection) => void;
  onChooseRoomClicked: () => void;
  totalPriceString: (pricing: FiatAndPointPrice) => string;
  lowestPrice: {
    fiat: FiatPrice;
    points: AccountPrice;
  };
}

export function SearchControlsSection({
  rooms,
  initialCheckinDate,
  initialCheckoutDate,
  initialGuestCount,
  onDatesChanged,
  onGuestCountChanged,
  onChooseRoomClicked,
  totalPriceString,
  lowestPrice,
}: Props) {
  const { t, formatFiatCurrency } = useI18nContext();

  const [openCalendar, setOpenCalendar] = useState(false);
  const [checkinDate, setCheckinDate] = useState<string>(
    dayjs(initialCheckinDate).toDate().toString()
  );
  const [checkoutDate, setCheckoutDate] = useState<string | undefined>(
    dayjs(initialCheckoutDate).toDate().toString()
  );
  const prevCheckinDate = useRef(checkinDate);
  const prevCheckoutDate = useRef(checkoutDate);
  const nights = useSelector(getTotalNights);

  const handleOnComplete = () => {
    onDatesChanged(
      formatDateForUrl(checkinDate),
      formatDateForUrl(checkoutDate)
    );
    setOpenCalendar(false);
  };

  const onCalendarCLick = () => {
    /**
     * Changing date will update the state before clicking on done;
     * So we save the previous values when the calendar open in order
     * to reset them if the user closes the calendar.
     */
    prevCheckinDate.current = checkinDate;
    prevCheckoutDate.current = checkoutDate;
    setOpenCalendar(true);
  };

  const handleCheckinDate = useCallback(
    (date: Date) => {
      if (date) {
        setCheckinDate(date.toString());
        if (checkoutDate) {
          setCheckoutDate(undefined);
        }
      }
    },
    [checkoutDate]
  );
  const handleCheckoutDate = useCallback((date: Date) => {
    if (date) {
      setCheckoutDate(date.toString());
    }
  }, []);

  const handleCloseCalendar = () => {
    setCheckinDate(prevCheckinDate.current);
    setCheckoutDate(prevCheckoutDate.current);
    setOpenCalendar(false);
  };

  const roomList = useSelector(getRooms);
  const roomId = useSelector(getSelectedRoomId);
  const rateId = useSelector(getSelectedRoomRateId);

  const selectedRoomPrice = useMemo(() => {
    if (!roomList || !roomId || !rateId) {
      return undefined;
    } else {
      const room = roomList.find((r) => r?.roomInfo?.roomId === roomId);
      if (room) {
        const total = room.products.find(
          (r) => r.rateId?.value === rateId
        )?.total;

        return { fiat: total?.fiat, points: getLowestRoomPrice(total) };
      } else {
        return undefined;
      }
    }
  }, [roomList, roomId, rateId]);

  const roomPrice = useMemo(() => {
    return selectedRoomPrice ? selectedRoomPrice : lowestPrice;
  }, [selectedRoomPrice, lowestPrice]);

  return (
    <Box className={styles.container}>
      <Box>
        {roomPrice ? (
          <Typography variant="h3">
            <Box className="per-night">
              {t("hotelShop.startingPriceLabel", {
                price: formatFiatCurrency({
                  ...roomPrice.fiat,
                  value: roomPrice?.fiat?.value / nights / rooms,
                }),
              })}
            </Box>
            <Box>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: totalPriceString(roomPrice),
                }}
              />
              <Box className={styles.taxesAndFeesLabel}>
                {t("lodgingShopDetails.includingFees")}
              </Box>
            </Box>
          </Typography>
        ) : null}
      </Box>
      <Divider />
      <CalendarPickerButton
        depDate={checkinDate ? new Date(checkinDate) : null}
        retDate={checkoutDate ? new Date(checkoutDate) : null}
        classes={["dates"]}
        tripCategory={TripCategory.ROUND_TRIP}
        onComplete={handleOnComplete}
        onClick={onCalendarCLick}
        openCalendar={openCalendar}
        setDepartureDate={handleCheckinDate}
        setReturnDate={handleCheckoutDate}
        closeCalendar={handleCloseCalendar}
        startLabel={t?.("checkin")}
        endLabel={t?.("checkout")}
        headerTitle={t?.("commBank.hotelCalendarPicker.title")}
        headerSubtitle={t?.("commBank.hotelCalendarPicker.subtitle")}
        useDialog
      />
      <GuestPicker
        className={styles.guestPicker}
        initialGuestCount={initialGuestCount}
        updateGuestCount={(nextGuestCount) => {
          onGuestCountChanged(nextGuestCount);
        }}
        useDialog
      />
      <ApacActionButton
        className={clsx(styles.chooseRoomButton, "outlined")}
        onClick={onChooseRoomClicked}
        message={t("hotelShop.chooseRoomButtonLabel")}
      />
    </Box>
  );
}
