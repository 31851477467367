import axios from "axios";
import { IApiConfig, NETWORK_CALL_FAILED, PersonId } from "@hopper-b2b/types";

import { passengersApiPrefix } from "../../paths";
import { trackEvent } from "@hopper-b2b/api";
const END_POINT = `${passengersApiPrefix}/delete`;

export const deleteUserPassenger = (
  body: IDeleteUserPassengerRequest,
  apiConfig?: IApiConfig
): Promise<IDeleteUserPassengerResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(END_POINT, body);
      const responseBody: IDeleteUserPassengerResponse = res.data;
      resolve(responseBody);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });

export interface IDeleteUserPassengerRequest {
  personId: PersonId;
}

export type IDeleteUserPassengerResponse = Record<string, never>;
