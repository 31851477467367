import {
  Airline,
  BookedFlightItineraryWithDepartureTime,
  Kind,
} from "@b2bportal/air-booking-api";
import {
  AirCfarExerciseFailureEnum,
  PollFulfillAirCfarExerciseQuoteResponseEnum,
} from "@b2bportal/air-cfar-api";
import { HotelItinerary, HotelItinerarySummary } from "@b2bportal/lodging-api";
import {
  CallState,
  CarReservation,
  FlightItinerarySlice,
  getDepartureSlice,
  getReturnSlice,
  Itinerary,
  ItineraryEnum,
  ItineraryWithType,
  MyTripsFilter,
  MyTripsProperties,
  UserActionScheduleChangeSuccessProperties,
  ViewedContactModalProperties,
  Watch,
  MyTripsModalTypes,
  CfarExerciseProgress,
} from "@hopper-b2b/types";
import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { IStoreState } from "../../../../reducers/types";
import { AirCancelFulfillResponseEnum } from "@b2bportal/air-cancel-api";
import { setOpenModal, resetCfarExerciseState } from "../../actions/actions";

const sortByDate = (a?: string, b?: string) => {
  const aDiff = dayjs(a).diff(dayjs());
  const bDiff = dayjs(b).diff(dayjs());
  return aDiff - bDiff;
};

const sortByDescendingDate = (a?: string, b?: string) => {
  const aDiff = dayjs(a).diff(dayjs());
  const bDiff = dayjs(b).diff(dayjs());
  return bDiff - aDiff;
};

export const getTripsLoading = (state: IStoreState) =>
  state.tripsList.fetchFlightsCallState === CallState.InProcess ||
  state.tripsList.fetchHotelsCallState === CallState.InProcess ||
  state.tripsList.fetchCarsCallState === CallState.InProcess ||
  state.tripsList.listPriceFreezeCallState === CallState.InProcess ||
  state.tripsList.listWatchCallState === CallState.InProcess;

export const getListWatchesCallState = (state: IStoreState) =>
  state.tripsList.listWatchCallState;

export const getFetchFlightsCallState = (state: IStoreState) =>
  state.tripsList.fetchFlightsCallState;

export const getFetchHotelsCallState = (state: IStoreState) =>
  state.tripsList.fetchHotelsCallState;

export const getFetchCarsCallState = (state: IStoreState) =>
  state.tripsList.fetchCarsCallState;
export const getAcceptScheduleChangeCallState = (state: IStoreState) =>
  state.tripsList.acceptScheduleChangeCallState;

export const getDenyScheduleChangeCallState = (state: IStoreState) =>
  state.tripsList.denyScheduleChangeCallState;

export const getAirlinesMap = (state: IStoreState) => state.tripsList.airlines;

export const getPriceFreezeAirlinesMap = (state: IStoreState) =>
  state.tripsList.priceFreezeAirlines;

export const getAirportMap = (state: IStoreState) => state.tripsList.airports;

export const getPriceFreezeAirportMap = (state: IStoreState) =>
  state.tripsList.priceFreezeAirports;

export const getSelectedFlight = (state: IStoreState) =>
  state.tripsList.selectedFlight;

export const getTripsFilter = (state: IStoreState) =>
  state.tripsList.tripsFilter;

export const getFlights = (state: IStoreState) => state.tripsList.itineraries;

export const getFlightSellingPrice = (state: IStoreState) =>
  state.tripsList.selectedFlight?.bookedItinerary.sellingPricing;

export const getFlightAncillaries = (state: IStoreState) =>
  state.tripsList.selectedFlight?.ancillaries;

export const getSelectedHotel = (state: IStoreState): HotelItinerary | null =>
  state.tripsList.selectedHotel;

export const getTripSearchResults = (state: IStoreState) =>
  state.tripsList.tripSearchResults;

export const getHotels = (state: IStoreState) =>
  state.tripsList.hotelItineraries;

export const getSelectedCar = (state: IStoreState) =>
  state.tripsList.selectedCar;

export const getCars = (state: IStoreState) => state.tripsList.carItineraries;

export const getWatches = (state: IStoreState) => state.tripsList.watches;

export const getPriceFreezes = (state: IStoreState) =>
  state.tripsList.priceFreezes;

export const getWatchContext = (state: IStoreState) =>
  state.tripsList.watchContext;

export const getWatchDetailsCallState = (state: IStoreState) =>
  state.tripsList.watchDetailsCallState;

export const getSelectedWatch = (state: IStoreState) =>
  state.tripsList.selectedWatch;

export const getSelectedWatchDetails = (state: IStoreState) =>
  state.tripsList.selectedWatchDetails;

export const getCfarExerciseProgress = (state: IStoreState) =>
  state.tripsList.cfarExerciseProgress;

export const getChfarExerciseProgress = (state: IStoreState) =>
  state.tripsList.chfarExerciseProgress;

export const getDisruptionExerciseProgress = (state: IStoreState) =>
  state.tripsList.disruptionExerciseProgress;

export const getDisruptionExerciseProductType = (state: IStoreState) =>
  state.tripsList.disruptionExerciseProductType;

export const getDisruptionRebookSelectedFlight = (state: IStoreState) =>
  state.tripsList.disruptionRebookSelectedFlight;

export const getDisruptionFulfillError = (state: IStoreState) =>
  state.tripsList.disruptionFulfillError;

export const getCfarCancellationQuote = (state: IStoreState) =>
  state.tripsList.cfarCancellationQuote;

export const getCfarCancellationQuoteV1 = (state: IStoreState) =>
  state.tripsList.cfarCancellationQuoteV1;

export const getCfarPolicyId = (state: IStoreState) => {
  const ancillaries = getFlightAncillaries(state);
  return ancillaries?.cfar?.id.policyId;
};

export const getCfarPremiums = (state: IStoreState) => {
  const pricing = getFlightSellingPrice(state);
  const totalPremium = pricing?.totalPricing.ancillaries.find(
    (item) => item.kind === Kind.Cfar
  )?.premium;
  const perPaxPremium = pricing?.pricingByPassenger
    .find((pax) => pax.passengerType === "ADT")
    ?.ancillaries.find((item) => item.kind === Kind.Cfar)?.premium;
  return {
    totalPremium,
    perPaxPremium,
  };
};

export const getCfarCoverage = createSelector(
  [getFlightAncillaries, getCfarCancellationQuote],
  (ancillaries, cfarQuote) => {
    const cfarCoveragePercentage =
      ancillaries?.cfar?.policyData?.cashCoveragePercentage;
    const cfarCoverageAmount = Math.abs(cfarQuote?.cfarRefund.fiat.value ?? 0);
    return { cfarCoverageAmount, cfarCoveragePercentage };
  }
);

export const getCfarExerciseTrackingProperties = (state: IStoreState) =>
  state.tripsList.cfarCancellationQuote?.trackingProperties;

export const getFetchFlightCfarInfoCallState = (state: IStoreState) =>
  state.tripsList.fetchFlightCfarCancellationQuoteCallState;

export const getConfirmFlightCfarCancellationCallState = (state: IStoreState) =>
  state.tripsList.confirmFlightCfarCancellationCallState;

export const getFlightCfarExerciseError = (state: IStoreState) => {
  if (
    state.tripsList.pollFulfillFlightCfarCancellationResponse
      ?.PollFulfillAirCfarExerciseQuoteResponse ===
      PollFulfillAirCfarExerciseQuoteResponseEnum.Failure ||
    state.tripsList.confirmFlightCfarCancellationCallState ===
      CallState.Failed ||
    state.tripsList.fetchFlightCfarCancellationQuoteCallState ===
      CallState.Failed
  ) {
    return true;
  }
  return false;
};

export const getCfarExerciseQuoteTrackingProperties = (state: IStoreState) =>
  state.tripsList.cfarExerciseQuoteTrackingProperties;

export const getCfarExerciseTreackingProperties = (state: IStoreState) =>
  state.tripsList.cfarExerciseQuoteTrackingProperties;

export const getOpenModal = (state: IStoreState) => state.tripsList.openModal;

export const getCfarExerciseQuoteError = (state: IStoreState) =>
  state.tripsList.cfarCancellationQuoteError;

export const getCancellationCompleted = (state: IStoreState) =>
  state.tripsList.cancellationCompleted;

export const getTripId = createSelector(
  [getSelectedFlight, getSelectedHotel, getSelectedCar, getSelectedWatch],
  (selectedFlight, selectedHotel, selectedCar, selectedWatch) => {
    if (selectedFlight) {
      return selectedFlight.bookedItinerary.id;
    }
    if (selectedHotel) {
      return selectedHotel.reservation.reservationBookingId;
    }
    if (selectedCar) {
      return selectedCar.bookResult.groundBookingId;
    }
    if (selectedWatch) {
      return selectedWatch.id;
    }
    return "";
  }
);

const getDepartureDateFromFlight = (
  flight: BookedFlightItineraryWithDepartureTime
) => getDepartureSlice(flight.bookedItinerary).segments[0].scheduledDeparture;

const getReturnDateFromFlight = (
  flight: BookedFlightItineraryWithDepartureTime
) => getReturnSlice(flight.bookedItinerary)?.segments[0].scheduledArrival || "";

const getDateFromHotel = (hotel: HotelItinerarySummary) => hotel.checkInDate;

const getDateFromCar = (car: CarReservation) => car.bookResult.pickUp.dateTime;

const getDropOffDateFromCar = (car: CarReservation) =>
  car.bookResult.dropOff.dateTime;

const getDateFromWatch = (watch: Watch) => watch.key.value.departureDate;

const getInitialDateForItinerary = (itinerary: ItineraryWithType) => {
  switch (itinerary.type) {
    case ItineraryEnum.Flight:
      return getDepartureDateFromFlight(
        itinerary as BookedFlightItineraryWithDepartureTime
      );
    case ItineraryEnum.Hotel:
      return getDateFromHotel(itinerary as HotelItinerary);
    case ItineraryEnum.Car:
      return getDateFromCar(itinerary as CarReservation);
    case ItineraryEnum.Watch:
      return getDateFromWatch(itinerary);
    default:
      return undefined;
  }
};

const sortItinerariesByDate = (
  a: ItineraryWithType,
  b: ItineraryWithType,
  isPast?: boolean
) => {
  let aDate = getInitialDateForItinerary(a);
  let bDate = getInitialDateForItinerary(b);

  const getDepDiff = (dateStringX?: string, dateStringY?: string) =>
    isPast
      ? sortByDescendingDate(dateStringX, dateStringY)
      : sortByDate(dateStringX, dateStringY);

  const depDiff = getDepDiff(aDate, bDate);
  if (depDiff === 0) {
    if (a.type === ItineraryEnum.Flight && b.type === ItineraryEnum.Flight) {
      const aReturn = getReturnDateFromFlight(
        a as BookedFlightItineraryWithDepartureTime
      );
      const bReturn = getReturnDateFromFlight(
        b as BookedFlightItineraryWithDepartureTime
      );
      aDate = aReturn || aDate;
      bDate = bReturn || bDate;
      return getDepDiff(aDate, bDate);
    }
    if (a.type === ItineraryEnum.Car && b.type === ItineraryEnum.Car) {
      const aReturn = getDropOffDateFromCar(a as CarReservation);
      const bReturn = getDropOffDateFromCar(b as CarReservation);
      aDate = aReturn || aDate;
      bDate = bReturn || bDate;
      return getDepDiff(aDate, bDate);
    }
  }
  return depDiff;
};

const addTypeToItinerary = (
  itineraries: Itinerary[],
  type: ItineraryEnum
): ItineraryWithType[] =>
  itineraries.map((itinerary) => ({ ...itinerary, type }));

export const itinerariesToDisplaySelector = createSelector(
  [getTripsFilter, getHotels, getFlights, getCars, getWatches],
  (tripsFilter, hotels, flights, cars, watches) => {
    const {
      Past: pastHotels = [],
      Future: futureHotels = [],
      Present: presentHotels = [],
      Canceled: canceledHotels = [],
    } = hotels;
    const {
      past: pastFlights = [],
      future: futureFlights = [],
      present: presentFlights = [],
      canceled: canceledFlights = [],
    } = flights;
    const {
      past: pastCars = [],
      future: futureCars = [],
      present: presentCars = [],
      canceled: canceledCars = [],
    } = cars;

    const canceledPastFlights = canceledFlights.filter((flight) => {
      const dateDiff = dayjs(
        getReturnDateFromFlight(flight) || getDepartureDateFromFlight(flight)
      ).diff(dayjs());
      return dateDiff <= 0;
    });

    const canceledFutureFlights = canceledFlights.filter((flight) => {
      const dateDiff = dayjs(
        getReturnDateFromFlight(flight) || getDepartureDateFromFlight(flight)
      ).diff(dayjs());
      return dateDiff > 0;
    });

    const canceledPastCars = canceledCars.filter((car) => {
      const dateDiff = dayjs(
        car.bookResult.pickUp.dateTime || car.bookResult.dropOff.dateTime
      ).diff(dayjs());
      return dateDiff <= 0;
    });

    const canceledFutureCars = canceledCars.filter((car) => {
      const dateDiff = dayjs(
        car.bookResult.pickUp.dateTime || car.bookResult.dropOff.dateTime
      ).diff(dayjs());
      return dateDiff > 0;
    });

    if (tripsFilter === MyTripsFilter.UPCOMING_TRIPS) {
      return [
        ...addTypeToItinerary(canceledFutureCars, ItineraryEnum.Car),
        ...addTypeToItinerary(futureCars, ItineraryEnum.Car),
        ...addTypeToItinerary(futureFlights, ItineraryEnum.Flight),
        ...addTypeToItinerary(futureHotels, ItineraryEnum.Hotel),
        ...addTypeToItinerary(presentCars, ItineraryEnum.Car),
        ...addTypeToItinerary(presentFlights, ItineraryEnum.Flight),
        ...addTypeToItinerary(presentHotels, ItineraryEnum.Hotel),
        ...addTypeToItinerary(watches, ItineraryEnum.Watch),
      ].sort((a, b) => sortItinerariesByDate(a, b));
    } else if (tripsFilter === MyTripsFilter.PAST_TRIPS) {
      return [
        ...addTypeToItinerary(canceledFutureFlights, ItineraryEnum.Flight),
        ...addTypeToItinerary(canceledPastCars, ItineraryEnum.Car),
        ...addTypeToItinerary(canceledPastFlights, ItineraryEnum.Flight),
        ...addTypeToItinerary(canceledHotels, ItineraryEnum.Hotel),
        ...addTypeToItinerary(pastCars, ItineraryEnum.Car),
        ...addTypeToItinerary(pastFlights, ItineraryEnum.Flight),
        ...addTypeToItinerary(pastHotels, ItineraryEnum.Hotel),
      ].sort((a, b) => sortItinerariesByDate(a, b, true));
    } else if (tripsFilter === MyTripsFilter.WATCHED_TRIPS) {
      return [...addTypeToItinerary(watches, ItineraryEnum.Watch)];
    } else if (tripsFilter === MyTripsFilter.FLIGHTS) {
      return [
        ...addTypeToItinerary(futureFlights, ItineraryEnum.Flight),
        ...addTypeToItinerary(presentFlights, ItineraryEnum.Flight),
      ].sort((a, b) => sortItinerariesByDate(a, b));
    } else if (tripsFilter === MyTripsFilter.HOTELS) {
      return [
        ...addTypeToItinerary(futureHotels, ItineraryEnum.Hotel),
        ...addTypeToItinerary(presentHotels, ItineraryEnum.Hotel),
      ].sort((a, b) => sortItinerariesByDate(a, b));
    } else {
      return [];
    }
  }
);

export const getTripsCounts = createSelector(
  [getHotels, getFlights, getCars, getWatches, getPriceFreezes],
  (hotels, flights, cars, watches, priceFreezes) => {
    const {
      past: pastHotels = [],
      future: futureHotels = [],
      present: presentHotels = [],
      canceled: canceledHotels = [],
    } = hotels;
    const {
      past: pastFlights = [],
      future: futureFlights = [],
      present: presentFlights = [],
      canceled: canceledFlights = [],
    } = flights;
    const {
      past: pastCars = [],
      future: futureCars = [],
      present: presentCars = [],
      canceled: canceledCars = [],
    } = cars;
    const canceledPastFlights = canceledFlights.filter((flight) => {
      const dateDiff = dayjs(
        getReturnDateFromFlight(flight) || getDepartureDateFromFlight(flight)
      ).diff(dayjs());
      return dateDiff <= 0;
    });

    const canceledFutureFlights = canceledFlights.filter((flight) => {
      const dateDiff = dayjs(
        getReturnDateFromFlight(flight) || getDepartureDateFromFlight(flight)
      ).diff(dayjs());
      return dateDiff > 0;
    });
    const canceledPastCars = canceledCars.filter((car) => {
      const dateDiff = dayjs(
        car.bookResult.pickUp.dateTime || car.bookResult.dropOff.dateTime
      ).diff(dayjs());
      return dateDiff <= 0;
    });

    const canceledFutureCars = canceledCars.filter((car) => {
      const dateDiff = dayjs(
        car.bookResult.pickUp.dateTime || car.bookResult.dropOff.dateTime
      ).diff(dayjs());
      return dateDiff > 0;
    });
    const totalUpcomingTrips =
      futureHotels.length +
      futureFlights.length +
      futureCars.length +
      presentHotels.length +
      presentFlights.length +
      presentCars.length +
      canceledFutureFlights.length +
      canceledFutureCars.length;
    const totalPastTrips =
      pastHotels.length +
      pastFlights.length +
      pastCars.length +
      canceledPastFlights.length +
      canceledHotels.length +
      canceledPastCars.length;

    return {
      [MyTripsFilter.UPCOMING_TRIPS]: totalUpcomingTrips,
      [MyTripsFilter.PAST_TRIPS]: totalPastTrips,
      [MyTripsFilter.WATCHED_TRIPS]: watches?.length || 0,
      [MyTripsFilter.PRIZE_FREEZES]: priceFreezes.length,
    };
  }
);

export const watchesSelector = createSelector(
  [getTripsFilter, getWatches],
  (tripsFilter, watches) => {
    if (tripsFilter === MyTripsFilter.WATCHED_TRIPS && Array.isArray(watches)) {
      return watches.sort((a, b) =>
        sortByDate(a.key.value.departureDate, b.key.value.departureDate)
      );
    } else {
      return [];
    }
  }
);

export const priceFreezeListSortedSelector = createSelector(
  [getTripsFilter, getPriceFreezes],
  (tripsFilter, pf) => {
    if (tripsFilter === MyTripsFilter.PRIZE_FREEZES && Array.isArray(pf)) {
      return pf.sort((a, b) =>
        sortByDate(
          a.tripDetails.slices[0].departureTime,
          b.tripDetails.slices[0].departureTime
        )
      );
    } else {
      return [];
    }
  }
);

export const hasTripsToDisplay = createSelector(
  [getTripsFilter, getWatches, itinerariesToDisplaySelector, getPriceFreezes],
  (tripsFilter, watches, itineraries, pf) => {
    switch (tripsFilter) {
      case MyTripsFilter.UPCOMING_TRIPS:
      case MyTripsFilter.PAST_TRIPS:
      case MyTripsFilter.FLIGHTS:
      case MyTripsFilter.HOTELS:
        return itineraries.length > 0;
      case MyTripsFilter.WATCHED_TRIPS:
        return watches.length > 0;
      case MyTripsFilter.PRIZE_FREEZES:
        return pf.length > 0;
      default:
        return false;
    }
  }
);

export const getDeleteWatchCallState = (state: IStoreState) =>
  state.tripsList.deleteWatchCallState;

export const getUpdateWatchCallState = (state: IStoreState) =>
  state.tripsList.updateWatchCallState;

export const hasFetchTripsError = createSelector(
  [
    getTripsFilter,
    getListWatchesCallState,
    getFetchFlightsCallState,
    getFetchHotelsCallState,
    getFetchCarsCallState,
  ],
  (
    tripsFilter,
    listWatchCallState,
    fetchFlightsCallState,
    fetchHotelsCallState,
    fetchCarsCallState
  ) => {
    switch (tripsFilter) {
      case MyTripsFilter.PAST_TRIPS:
      case MyTripsFilter.UPCOMING_TRIPS:
        return (
          fetchFlightsCallState === CallState.Failed ||
          fetchHotelsCallState === CallState.Failed ||
          fetchCarsCallState === CallState.Failed
        );
      case MyTripsFilter.WATCHED_TRIPS:
        return listWatchCallState === CallState.Failed;
      default:
        return false;
    }
  }
);

export const getViewedCarContactModalProperties = (
  car: CarReservation,
  contactReason?: ViewedContactModalProperties["contact_reason"]
): ViewedContactModalProperties => {
  return {
    agent_locator: car?.bookResult.confirmationInfo?.providerBookingId || "",
    agent_locator_provider: "",
    days_until_departure: car
      ? `${dayjs().diff(dayjs(car?.bookResult.pickUp.dateTime), "days")} days`
      : "",
    booking_date: car
      ? dayjs(car?.bookResult.pickUp.dateTime).toISOString()
      : "",
    contact_reason: contactReason || "cancel_car",
    contact_provider_ground:
      car?.bookResult.contactSupportInfo?.displayPhone || "",
  };
};

export const getViewedHotelContactModalProperties = (
  hotel: HotelItinerary,
  contactReason?: ViewedContactModalProperties["contact_reason"]
): ViewedContactModalProperties => {
  return {
    agent_locator: hotel?.reservation.reservationId || "",
    agent_locator_provider: "",
    days_until_departure: hotel
      ? `${dayjs().diff(dayjs(hotel?.reservation.checkInDate), "days")} days`
      : "",
    booking_date: hotel
      ? dayjs(hotel?.reservation.checkInDate).toISOString()
      : "",
    contact_reason: contactReason || "cancel_hotel",
    contact_provider_hotel: hotel?.reservation.lodgingData.phone || "",
  };
};

export const getViewedCancelFlightContactModalProperties = (
  flight: BookedFlightItineraryWithDepartureTime
): ViewedContactModalProperties => {
  const slice: FlightItinerarySlice | null = flight
    ? getDepartureSlice(flight.bookedItinerary)
    : null;
  return {
    agent_locator:
      flight?.bookedItinerary.travelItinerary.locators?.agent.unscopedValue ||
      "",
    agent_locator_provider:
      flight?.bookedItinerary.travelItinerary.locators?.agent.unscopedValue ||
      "",
    days_until_departure: flight
      ? `${dayjs().diff(dayjs(flight.departureTime), "days")} days`
      : "",
    booking_date: flight?.departureTime || "",
    contact_reason: "cancel_flight",
    contact_provider_air:
      slice && slice?.segments.length > 0
        ? slice?.segments[0].marketingAirline.code
        : "",
    has_skch: flight?.bookedItinerary.scheduleChange?.id || "false",
    skch_severity: flight?.bookedItinerary.scheduleChange?.severity,
  };
};

export const getViewedChangeFlightContactModalProperties = (
  flight: BookedFlightItineraryWithDepartureTime,
  airlineMap: { [key: string]: Airline | undefined }
): ViewedContactModalProperties => {
  const getAirlineWebLink = (
    flight: BookedFlightItineraryWithDepartureTime,
    airlineMap: { [key: string]: Airline | undefined }
  ) => {
    const airline = getDepartureSlice(flight.bookedItinerary).segments[0]
      .marketingAirline.code;
    if (airlineMap?.[airline]) {
      return airlineMap[airline]!.webLinks.manageBooking;
    } else {
      return "";
    }
  };
  const redirectUrl = flight ? getAirlineWebLink(flight!, airlineMap) : "";
  return {
    agent_locator:
      flight?.bookedItinerary.travelItinerary.locators?.agent.unscopedValue ||
      "",
    agent_locator_provider:
      flight?.bookedItinerary.travelItinerary.locators?.agent.unscopedValue ||
      "",
    days_until_departure: flight
      ? `${dayjs().diff(dayjs(flight.departureTime), "days")} days`
      : "",
    booking_date: flight?.departureTime || "",
    contact_reason: "change_flight",
    contact_provider_air: redirectUrl,
    has_skch: flight?.bookedItinerary.scheduleChange?.id || "false",
    skch_severity: flight?.bookedItinerary.scheduleChange?.severity,
  };
};

export const getViewedMajorScheduleChangeProperties = createSelector(
  getSelectedFlight,
  (flight): MyTripsProperties => {
    return {
      agent_locator:
        flight?.bookedItinerary.travelItinerary.locators?.agent.unscopedValue ||
        "",
      agent_locator_provider:
        flight?.bookedItinerary.travelItinerary.locators?.agent.unscopedValue ||
        "",
      days_until_departure: flight
        ? `${dayjs().diff(dayjs(flight.departureTime), "days")} days`
        : "",
      booking_date: flight?.departureTime || "",
      has_skch: flight?.bookedItinerary.scheduleChange?.id || "false",
      skch_severity: flight?.bookedItinerary.scheduleChange?.severity,
    };
  }
);

export const getUserActionScheduleChangeSuccessProperties = createSelector(
  getSelectedFlight,
  (flight): UserActionScheduleChangeSuccessProperties => {
    return {
      action: "",
      user_action: "",
      severity: flight?.bookedItinerary.scheduleChange?.severity || "",
      system_locator_provider: "",
      agent_locator_provider: "",
      agent_locator:
        flight?.bookedItinerary.travelItinerary.locators?.agent.unscopedValue ||
        "",
      system_locator:
        flight?.bookedItinerary.travelItinerary.locators?.agent.unscopedValue ||
        "",
      days_until_departure: flight
        ? `${dayjs(flight?.departureTime).diff(dayjs(), "day")}`
        : "",
      connection_time_valid: "",
      is_continuous: "",
      has_reprotection: "",
      provider: "",
      pcc: "",
      booking_date: "",
      itinerary_id: "",
      validating_carrier: "",
    };
  }
);
