import { ApacLoadingWithLogo } from "@commbank/ui";
import { PrepareLoginResponseEnum } from "@b2bportal/commbank-au-auth-api";
import Box from "@material-ui/core/Box";
import { useEffect, useState } from "react";
import { loginPrepare } from "../../api/loginPrepare";
import { CODE_VERIFIER, TRAVEL_PROFILE_ID } from "../AuthModule/component";
import "./styles.scss";
import { CBA_LANDING_PAGE_URL } from "../../utils/urlPaths";

const UnauthorizedPage = () => {
  const [loadingLoginPrepare, setLoadingLoginPrepare] = useState(false);
  const handleUnauthorized = async () => {
    setLoadingLoginPrepare(true);
    const response = await loginPrepare();
    setLoadingLoginPrepare(false);
    switch (response.PrepareLoginResponse) {
      case PrepareLoginResponseEnum.PrepareLoginSuccess:
        sessionStorage.setItem(CODE_VERIFIER, response.codeVerifier);
        window.location.replace(response.loginUrl);
        break;
      case PrepareLoginResponseEnum.PrepareLoginFailed:
        window.location.replace(CBA_LANDING_PAGE_URL);
        throw Error("LogIn Failed");
    }
  };

  useEffect(() => {
    // CBA only wants to initiate login flow when travel profile Id is present
    const travelProfileId = sessionStorage.getItem(TRAVEL_PROFILE_ID);
    if (travelProfileId && !loadingLoginPrepare) {
      handleUnauthorized();
    } else {
      // redirect to cba landing page
      window.location.replace(CBA_LANDING_PAGE_URL);
    }
  }, []);

  return (
    <Box className="unauthorized-page-container">
      <ApacLoadingWithLogo />
    </Box>
  );
};

export default UnauthorizedPage;
