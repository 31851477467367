import {
  useEnableCfar,
  useEnableChfar,
  useEnableDisruptionProtection,
} from "@hopper-b2b/utilities";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { fetchCfarOffers } from "../../cfar/actions/actions";
import { fetchChfarOffer } from "../../chfar/actions/actions";
import { fetchDisruptionOffers } from "../../disruption/actions/actions";

export const useFetchFintechProducts = () => {
  const dispatch = useDispatch();

  const enableDisruption = useEnableDisruptionProtection();
  const showFlightCfar = useEnableCfar();
  const showFlightChFar = useEnableChfar();

  return useCallback(
    (tripId: string, fareId: string) => {
      if (enableDisruption) {
        dispatch(fetchDisruptionOffers());
      }
      if (showFlightCfar) {
        tripId && fareId && dispatch(fetchCfarOffers(tripId, fareId));
      }
      if (showFlightChFar) {
        tripId && fareId && dispatch(fetchChfarOffer(tripId, fareId));
      }
    },
    [dispatch, enableDisruption, showFlightCfar, showFlightChFar]
  );
};
