import {
  GetTripAccessTokenRequest,
  GetTripAccessTokenResponse,
  Product,
} from "@b2bportal/purchase-api";
import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";

export const fetchTripToken = async (
  lastName: string,
  confirmationId: string,
  productType: Product
): Promise<GetTripAccessTokenResponse> => {
  const body: GetTripAccessTokenRequest = {
    key: {
      lastName: lastName,
      customerConfirmationId: {
        id: confirmationId,
        product: productType,
      },
      TripKey: "ByLastNameAndConfirmationId",
    },
  };
  try {
    const res = await axiosInstance.put("/api/v0/myTrip/accessToken", body);
    const { data } = res;
    return data;
  } catch (e) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
    throw e;
  }
};
