import { useDispatch, useSelector } from "react-redux";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  Fare,
  FlightsFareSlice,
  Slice,
  Trip,
} from "@b2bportal/air-shopping-api";
import { Airline } from "@b2bportal/air-booking-api";

import {
  ActionButton,
  Divider,
  ErrorBanner,
  IconName,
  MobileFlightDetailsModal,
  MobileFlightSummaryRowNew,
  MobileFloatingButton,
  PassengerSummaryRow,
} from "@hopper-b2b/ui";
import {
  DisruptionExerciseProgress,
  FlightSummaryInfo,
  TripDetails,
  IPerson,
} from "@hopper-b2b/types";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { getEnvVariables, getPlusDaysOnSlice } from "@hopper-b2b/utilities";

import { ClientContext } from "../../../../../App";
import {
  getAirportMap,
  getSelectedFlight,
} from "../../../../TripsList/reducer";
import {
  populateTripQueryParams,
  setDisruptionExerciseProgress,
} from "../../../../TripsList/actions/actions";
import { useHistory } from "react-router";
import { PATH_DISRUPTION_EXERCISE_REBOOK_CONFIRMATION } from "../../../../../utils/paths";

export interface IDisruptionRebookReviewProps {
  selectedRebookFlight?: {
    fare?: Fare;
    trip?: Trip;
    tripSlice?: Slice;
    fareSlice?: FlightsFareSlice;
    tripDetails?: TripDetails;
  };
  airlineMap: {
    [key: string]: Airline;
  };
  disableFloatingButton?: boolean;
}

export const DisruptionRebookReview = ({
  selectedRebookFlight,
  airlineMap,
  disableFloatingButton,
}: IDisruptionRebookReviewProps) => {
  const { t } = useI18nContext();
  const history = useHistory();

  const dispatch = useDispatch();
  const clientContext = useContext(ClientContext);
  const airportMap = useSelector(getAirportMap);
  const selectedFlight = useSelector(getSelectedFlight);

  const [openitineraryModal, setOpenItineraryModal] = useState<boolean>(false);
  const [selectedFareClass, setSelectedFareClass] = useState<number>(null);
  const [selectedFareDetails, setSelectedFareDetails] = useState(null);

  const isUber = getEnvVariables("clientName") === "uber";

  useEffect(() => {
    if (selectedRebookFlight) {
      const selectedFlightFareId = selectedRebookFlight.fare?.id;
      const selectedFlightFareDetails =
        selectedRebookFlight.tripDetails?.fareDetails?.find(
          (fareDetail) => fareDetail.id === selectedFlightFareId
        );
      setSelectedFareDetails(selectedFlightFareDetails);
      setSelectedFareClass(
        selectedFlightFareDetails?.slices[0]?.fareShelf?.rating
      );
    }
  }, [selectedRebookFlight]);

  const flightSummaryInfo = useMemo((): FlightSummaryInfo | null => {
    if (selectedRebookFlight?.trip) {
      const tripSlice = selectedRebookFlight?.tripSlice;
      const flightSegment = tripSlice.segments[0];

      const originLabel = airportMap
        ? airportMap[tripSlice.origin]?.cityName
        : tripSlice.origin;
      const destinationLabel = airportMap
        ? airportMap[tripSlice.destination]?.cityName
        : tripSlice.destination;

      return {
        originCity: originLabel,
        destinationCity: destinationLabel,
        departure: tripSlice.departure,
        arrival: tripSlice.arrival,
        airlineCode: flightSegment.marketingAirline,
        airlineName: airlineMap[flightSegment?.marketingAirline]?.displayName,
        isDeparture: true,
        title: t("originToDestination", {
          origin: originLabel,
          destination: destinationLabel,
          interpolation: { escapeValue: false },
        }),
        stops: tripSlice.stops,
      };
    }
    return null;
  }, [selectedRebookFlight, airportMap, airlineMap, t]);

  const passengers = selectedFlight.bookedItinerary.passengers.alone.map(
    (ap) => {
      ap.person.type = ap.type;
      return ap.person;
    }
  );

  const onContinue = useCallback(() => {
    if (isUber) {
      // TODO: Fire the book call?
      // fire quote and fulfill
      dispatch(
        setDisruptionExerciseProgress(
          DisruptionExerciseProgress.ConfirmationPage
        )
      );
      const newHistory = dispatch(populateTripQueryParams(history)).history;
      history.replace(
        PATH_DISRUPTION_EXERCISE_REBOOK_CONFIRMATION +
          newHistory.location.search
      );
    } else {
      dispatch(
        setDisruptionExerciseProgress(
          DisruptionExerciseProgress.ConfirmationPage
        )
      );
      dispatch(populateTripQueryParams(history));
    }
  }, [dispatch, history, isUber]);

  // TODO: Choose outbound or return, not always first slice
  const originalFareClass =
    selectedFlight?.bookedItinerary?.travelItinerary?.slices[0].fareShelf
      .rating;
  const showAlert = originalFareClass > selectedFareClass;

  return (
    <>
      <div className="disruption-rebook-review">
        <div className="disruption-rebook-review-header">
          <div className="disruption-rebook-review-title">
            {t("disruptionExercise.rebookReviewTitle")}
          </div>
          <div className="disruption-rebook-review-subtitle">
            {t("disruptionExercise.rebookReviewSubtitle")}
          </div>
        </div>
        {showAlert ? (
          <ErrorBanner
            className="disruption-rebook-review-alert"
            iconSrc={IconName.WarningAlert}
            subtitle={t("disruptionExercise.rebookReviewAlert")}
          />
        ) : null}
        <div className="disruption-rebook-review-content">
          <div className="disruption-rebook-review-flight-summaries">
            <MobileFlightSummaryRowNew
              flightSummaryInfo={flightSummaryInfo}
              iconSrc={clientContext.assets?.airplaneDepart}
              onClick={() => setOpenItineraryModal(true)}
            />
          </div>
          <Divider className="disruption-rebook-review-divider" />
          <div className="disruption-rebook-review-passenger-info-container">
            <PassengerSummaryRow
              passengers={passengers as IPerson[]}
              iconSrc={clientContext?.assets?.passenger}
              className="disruption-rebook-review-passengers"
            />
          </div>
          <Divider className="disruption-rebook-review-divider" />
        </div>
        {disableFloatingButton ? (
          <ActionButton
            className="disruption-rebook-review-button"
            onClick={onContinue}
            message={t("disruptionExercise.rebookReviewCta")}
          />
        ) : (
          <MobileFloatingButton
            className="disruption-rebook-review-button"
            onClick={onContinue}
            wrapperClassName="disruption-rebook-button-wrapper"
            wide
          >
            {t("disruptionExercise.rebookReviewCta")}
          </MobileFloatingButton>
        )}
      </div>
      {selectedFareDetails ? (
        <MobileFlightDetailsModal
          departure={true}
          title={
            <I18nMarkup
              tKey={"flightShopReview.outboundCardHeader"}
              replacements={{
                location: selectedRebookFlight?.tripSlice?.destination,
                date: dayjs(selectedRebookFlight?.tripSlice?.departure).format(
                  "ddd, MMM D"
                ),
              }}
            />
          }
          plusDays={getPlusDaysOnSlice(selectedRebookFlight?.tripSlice)}
          // isMixedCabinClass={getIsMixedClass(selectedRebookFlight?.fareSlice)}
          isMixedCabinClass={false}
          className={clsx(
            "b2b",
            "b2b-secondary",
            "mobile-itinerary-details-modal",
            "disruption-rebook-review-modal"
          )}
          airports={airportMap}
          onClose={() => setOpenItineraryModal(false)}
          tripDetails={selectedRebookFlight?.tripDetails}
          fareDetails={selectedFareDetails}
          assets={clientContext.assets}
          openModal={openitineraryModal}
        />
      ) : null}
    </>
  );
};

const getIsMixedClass = (slice: FlightsFareSlice): boolean => {
  if (
    !slice.fareShelf ||
    slice.fareShelf.value < 3 ||
    slice.segments.length <= 1
  ) {
    return false;
  }
  // TODO: fix conditions
  // return slice.fareDetails.segments.some(
  //   (segment) =>
  //     segment.cabinClassName &&
  //     (slice.fareShelf?.shortBrandName === "Premium" &&
  //     !slice.fareShelf?.brandName.includes("First")
  //       ? segment.cabinClassName === "Economy"
  //       : segment.cabinClassName.includes("Economy"))
  // );
};
