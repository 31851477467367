import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useI18nContext } from "@hopper-b2b/i18n";
import { TagInfo } from "@hopper-b2b/types";
import clsx from "clsx";
import { StatusTag } from "../StatusTag";
import "./styles.scss";

export interface MyTripsMobileCardProps {
  className?: string;
  label: string;
  confirmationLabel?: string;
  confirmationCode?: string;
  confirmationCodeClassName?: string;
  startLabel?: string;
  startDate: string;
  title?: string;
  titleTag?: TagInfo;
  subtitleTag?: TagInfo;
  subtitle?: string;
  banner?: JSX.Element;
  actions?: JSX.Element;
  onClick?: () => void;
  shouldShowCtaIcon?: boolean;
  origin?: string;
  destination?: string;
  roundTrip?: boolean;
}

const defaultProps: Partial<MyTripsMobileCardProps> = {
  shouldShowCtaIcon: true,
};

export const MyTripsMobileCard = ({
  className,
  label,
  title,
  titleTag,
  startLabel,
  startDate,
  subtitle,
  subtitleTag,
  confirmationLabel,
  confirmationCode,
  confirmationCodeClassName,
  actions,
  banner,
  onClick,
  shouldShowCtaIcon,
  origin,
  destination,
  roundTrip,
}: MyTripsMobileCardProps) => {
  const { t } = useI18nContext();
  return (
    <div className={clsx("my-trips-mobile-card", className)}>
      <div className="summary-label-container">
        <h2 className="label">{label}</h2>
        <span className="date-separator">|</span>
        {startLabel ? <h2 className="label">{startLabel}</h2> : null}
        <p className="label">{startDate}</p>
      </div>
      {banner ? <div className="banner">{banner}</div> : null}
      <div className="trip-info-container" onClick={onClick}>
        {titleTag ? <StatusTag tagInfo={titleTag} /> : null}
        <h2 className="trips-title">
          {title
            ? title
            : t("originToDestination", {
                origin,
                destination,
                interpolation: { escapeValue: false },
              })}
        </h2>
        <div className="trips-subtitle">
          {subtitle || roundTrip !== null
            ? subtitle
              ? subtitle
              : roundTrip
              ? t("roundTripFlight")
              : t("oneWayFlight")
            : null}
        </div>

        {confirmationCode ? (
          <div className="info-container confirmation-code">
            <p className="label">{confirmationLabel}</p>
            <p className={clsx("code", confirmationCodeClassName)}>
              {confirmationCode}
            </p>
          </div>
        ) : null}
        {subtitleTag ? <StatusTag tagInfo={subtitleTag} /> : null}
        {actions ? <div className="actions">{actions}</div> : null}
        {shouldShowCtaIcon && (
          <FontAwesomeIcon className="cta" icon={faChevronRight as IconProp} />
        )}
      </div>
    </div>
  );
};

MyTripsMobileCard.defaultPropts = defaultProps;
