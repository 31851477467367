import { useMemo } from "react";
import { PriceDropLineAndModal } from "../../../../components/PriceDropLineAndModal";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Typography } from "@material-ui/core";
import {
  ApacActionButton,
  ApacIcon,
  ApacIconName,
  ApacAlertBanner,
} from "@commbank/ui";
import { PriceDropProtectionEnum } from "@hopper-b2b/types";
import { getPointsPriceFormatted } from "../../../../../../utils/getRewardsText";
import styles from "./PriceBreakdown.module.scss";

export const PriceBreakdown = ({
  selectedRewardsAccount,
  prediction,
  isOneWay,
  fareDetails,
  hasChildOrInfant,
  onClose,
}) => {
  const { t, formatFiatCurrency } = useI18nContext();
  const isPDPEligible =
    prediction?.priceDropProtection?.PriceDropProtection ===
    PriceDropProtectionEnum.IsEligible;
  const { pricing } = fareDetails?.paxPricings?.[0] || {};
  const rewardsPriceString = useMemo(
    () =>
      getPointsPriceFormatted({
        pricing,
        selectedAccount: selectedRewardsAccount,
      }),
    [pricing, selectedRewardsAccount]
  );
  return (
    <Box className={styles.container}>
      <div className="modal-title-container">
        <ApacIcon className="title-icon" name={ApacIconName.Insights} />
        <Typography variant="h2" className="modal-title-text">
          {t("flightsPageTitles.fareDetails")}
        </Typography>
      </div>

      <div className={styles.description}>
        <Typography variant="h3">{t("checkoutBreakdown")}</Typography>

        <Typography variant="body2">
          {isOneWay ? t("oneWayPerTraveler") : t("roundTripPerTraveler")}
          {` (${t("lodgingShopDetails.includingFees")})`}
        </Typography>

        <PriceDropLineAndModal
          isPDPEligible={isPDPEligible}
          prediction={prediction}
        />
      </div>

      <div className={styles.fareBreakdown}>
        <div className={styles.item}>
          <div>
            <ApacIcon name={ApacIconName.Savings} />
            <Typography variant="body2">{t("flightShop.baseFare")}</Typography>
          </div>

          <Typography variant="body2">
            {formatFiatCurrency(pricing?.baseAmount?.fiat)}
          </Typography>
        </div>
        <div className={styles.item}>
          <div>
            <ApacIcon name={ApacIconName.MoneyIn} />
            <Typography variant="body2">
              {t("flightShop.taxesAndFees")}
            </Typography>
          </div>
          <Typography variant="body2">
            {formatFiatCurrency(pricing?.taxAmount?.fiat)}
          </Typography>
        </div>
      </div>

      <div className={styles.fareTotal}>
        <Typography variant="h6" className="total-price">
          {t("total")}
        </Typography>
        <div className={styles.priceWrapper}>
          <Typography variant="h6" className="total-price">
            {formatFiatCurrency(pricing?.total?.fiat)}
          </Typography>
          {rewardsPriceString && (
            <Typography variant="body2">
              {t("flightShop.orAwardPoints", {
                points: rewardsPriceString,
              })}
            </Typography>
          )}
        </div>
      </div>

      {hasChildOrInfant && (
        <ApacAlertBanner
          title={t("fareBreakdownChildPriceUnknown.alertTitle")}
          description={t("fareBreakdownChildPriceUnknown.alertBody")}
          toolTipCopy={t("fareBreakdownChildPriceUnknown.alertBody")}
        />
      )}

      <div className="footer">
        <ApacActionButton
          className="close-button"
          onClick={onClose}
          message={t("close.button")}
        />
      </div>
    </Box>
  );
};
