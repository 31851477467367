import { Typography, Box, Button } from "@material-ui/core";
import { uniqWith, isEqual } from "lodash-es";
import clsx from "clsx";
import {
  Airline,
  BookedFlightItineraryWithDepartureTime,
} from "@b2bportal/air-booking-api";

import {
  MultiTravelItinerary,
  SingleTravelItinerary,
  TravelItineraryEnum,
  MultiTicketTypeEnum,
} from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  IconName,
  BannerSeverity,
  NotificationBanner,
  IconComponent,
} from "@hopper-b2b/ui";
import { openExternalLink } from "@hopper-b2b/utilities";

import { copyTextToClipboardPromise } from "../ConfirmationModalContent/helpers";
import { CheckInFlightModalContentConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./constants";
import { useContext } from "react";
import { ClientContext } from "../../../../../../../../App";

interface ICheckInFlightModalContentProps
  extends CheckInFlightModalContentConnectorProps {
  flight: BookedFlightItineraryWithDepartureTime;
}

export interface IConfirmationNumber {
  label: string;
  locator: string;
  redirectUrl: string;
}

const onCopyAndGo = async (locator: string, redirectUrl: string) => {
  await copyTextToClipboardPromise(locator);
  openExternalLink(redirectUrl);
};

const filterDuplicates = (confirmationNumbers: IConfirmationNumber[]) =>
  uniqWith(confirmationNumbers, isEqual);

export const getConfirmationNumbers = ({
  flight,
  airlineMap,
}: {
  flight: BookedFlightItineraryWithDepartureTime;
  airlineMap: { [key: string]: Airline | undefined };
}): IConfirmationNumber[] => {
  const { bookedItinerary } = flight;
  const { travelItinerary } = bookedItinerary;

  const confirmationNumbers: IConfirmationNumber[] = [];

  if (
    travelItinerary.TravelItinerary ===
    TravelItineraryEnum.SingleTravelItinerary
  ) {
    const singleTravelItinerary = travelItinerary as SingleTravelItinerary;
    singleTravelItinerary.slices.forEach((slice, sliceIndex) => {
      slice.segments.forEach((segment) => {
        const confirmationNumber = textConstants.getConfirmationNumber({
          segment,
          airlineMap,
          isReturn: sliceIndex !== 0,
        });
        if (confirmationNumber) {
          confirmationNumbers.push(confirmationNumber);
        }
      });
    });
  }
  if (
    travelItinerary.TravelItinerary === TravelItineraryEnum.MultiTravelItinerary
  ) {
    const multipleTravelItinerary = travelItinerary as MultiTravelItinerary;

    if (multipleTravelItinerary.locators?.children) {
      multipleTravelItinerary.travelItineraries.forEach((itinerary) => {
        itinerary.slices.forEach((slice, sliceIndex) => {
          slice.segments.forEach((segment) => {
            const confirmationNumber = textConstants.getConfirmationNumber({
              segment,
              airlineMap,
              isReturn: sliceIndex !== 0,
            });
            if (confirmationNumber) {
              confirmationNumbers.push(confirmationNumber);
            }
          });
        });
      });
    }
  }
  return confirmationNumbers;
};

export const CheckInFlightModalContent = ({
  flight,
  airlineMap,
}: ICheckInFlightModalContentProps) => {
  const { t } = useI18nContext();
  const clientContext = useContext(ClientContext);
  const confirmationNumbers = filterDuplicates(
    getConfirmationNumbers({ flight, airlineMap })
  );
  const { bookedItinerary } = flight;
  const renderConfirmationNumbers = () => {
    return (
      <Box className="checkin-flight-container">
        {confirmationNumbers.map(({ label, locator, redirectUrl }, index) => (
          <Box
            key={`${locator}-${index}`}
            className={clsx("checkin-flight-details-container", {
              "container-border": index !== confirmationNumbers.length - 1,
            })}
          >
            <Box className="checkin-flight-details">
              <Typography className="checkin-flight-label" variant="body2">
                {label}
              </Typography>
              <Typography variant="body2" className="checkin-flight-locator">
                {locator}
              </Typography>
            </Box>
            <Button
              className="checkin-flight-copy-container"
              onClick={() => onCopyAndGo(locator, redirectUrl)}
              aria-label={t("copyAndGo")}
            >
              <Typography variant="body2" className="copy-text">
                {t("copyAndGo")}
              </Typography>
              {clientContext?.assets?.externalLink ? (
                <img
                  src={clientContext?.assets?.externalLink}
                  className="copy-icon"
                  alt=""
                />
              ) : (
                <IconComponent
                  className="copy-icon"
                  name={IconName.ExternalLinkIcon}
                />
              )}
            </Button>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box className="checkin-flight-modal-content">
      <Box className="checkin-flight-title-container">
        <Typography variant="h4" className={clsx("checkin-flight-title")}>
          {t("checkInModal.title")}
        </Typography>
        <Typography variant="body2" className="checkin-flight-text">
          {t("checkInModal.subtitle")}
        </Typography>
        {bookedItinerary.multiTicketType === MultiTicketTypeEnum.HackerFare && (
          <NotificationBanner
            className="status-banner"
            strongLabel={t("checkInModal.notificationHeader")}
            label={t("checkInModal.notificationLabel")}
            severity={BannerSeverity.WARNING}
          />
        )}
      </Box>
      {renderConfirmationNumbers()}
    </Box>
  );
};
