import {
  ErrorCode,
  PriceQuoteData,
  TripPricingSummary,
} from "@b2bportal/air-booking-api";
import { GordianSeat, SeatMapCallback } from "@b2bportal/air-seats-api";
import {
  CallState,
  CreditCard,
  Invalid,
  IPerson,
  PassengerError,
  Payment,
  PaymentVerifyResultEnum,
  PersonId,
  PaymentSplitRequest,
  FiatPrice,
  RewardsPrice,
  PaymentError,
  PaymentSessionToken,
  FlightPaymentFinalizeResult,
  PriceFreezeFlightPaymentFinalizeResult,
  Prices,
  SELECT_TRAVELERS,
  SELECT_REWARDS,
  SELECT_PAYMENT,
  FlightBookType,
  PriceDropProtection,
} from "@hopper-b2b/types";

import * as actionTypes from "./constants";
import * as H from "history";
import { IUpdateUserPassengerRequest } from "../../../api/v1/book/user-passengers/updateUserPassenger";
import { IDeleteUserPassengerRequest } from "../../../api/v1/book/user-passengers/deleteUserPassenger";
import { trackEvent } from "@hopper-b2b/api";

export const fetchUserPassengers = (): IFetchUserPassengers => ({
  type: actionTypes.FETCH_USER_PASSENGERS,
});

export interface IFetchUserPassengers {
  type: actionTypes.FETCH_USER_PASSENGERS;
}

export const updateUserPassenger = (
  payload: IUpdateUserPassengerArgs,
  autoSelect?: boolean,
  updatePassport?: boolean,
  keepBookingErrors?: boolean
): IUpdateUserPassenger => ({
  type: actionTypes.UPDATE_USER_PASSENGER,
  payload,
  autoSelect,
  updatePassport,
  keepBookingErrors,
});

export interface IUpdateUserPassenger {
  type: actionTypes.UPDATE_USER_PASSENGER;
  payload: IUpdateUserPassengerArgs;
  autoSelect?: boolean;
  updatePassport?: boolean;
  keepBookingErrors?: boolean;
}

export type IUpdateUserPassengerArgs = IUpdateUserPassengerRequest;

export const deleteUserPassenger = (
  payload: IDeleteUserPassengerArgs
): IDeleteUserPassenger => ({
  type: actionTypes.DELETE_USER_PASSENGER,
  payload,
});

export interface IDeleteUserPassenger {
  type: actionTypes.DELETE_USER_PASSENGER;
  payload: IDeleteUserPassengerArgs;
}

export type IDeleteUserPassengerArgs = IDeleteUserPassengerRequest;

export const setInfantOnlyError = (
  infantOnlyError: boolean
): ISetInfantOnlyError => {
  return {
    type: actionTypes.SET_INFANT_ONLY_ERROR,
    infantOnlyError,
  };
};

export interface ISetInfantOnlyError {
  type: actionTypes.SET_INFANT_ONLY_ERROR;
  infantOnlyError: boolean;
}

export const setUserPassengers = (args: {
  userPassengerCallState: CallState;
  userPassengers: IPerson[];
}): ISetUserPassengers => ({
  type: actionTypes.SET_USER_PASSENGERS,
  ...args,
});

export interface ISetUserPassengers {
  type: actionTypes.SET_USER_PASSENGERS;
  userPassengerCallState: CallState;
  userPassengers: IPerson[];
}

export const setUserSelectedPassengerIds = (args: {
  userSelectedPassengerIds: PersonId[];
}): ISetUserSelectedPassengerIds => {
  trackEvent({
    eventName: SELECT_TRAVELERS,
    properties: { num_travelers: args.userSelectedPassengerIds.length },
  });
  return {
    type: actionTypes.SET_USER_SELECTED_PASSENGER_IDS,
    ...args,
  };
};

export interface ISetUserSelectedPassengerIds {
  type: actionTypes.SET_USER_SELECTED_PASSENGER_IDS;
  userSelectedPassengerIds: PersonId[];
}

export interface ISetUserSelectedLapInfantIds {
  type: actionTypes.SET_USER_SELECTED_LAP_INFANT_IDS;
  userSelectedLapInfantIds: PersonId[];
}

export const setUserSelectedLapInfantIds = (args: {
  userSelectedLapInfantIds: PersonId[];
}): ISetUserSelectedLapInfantIds => ({
  type: actionTypes.SET_USER_SELECTED_LAP_INFANT_IDS,
  ...args,
});

export const setUserPassengersCallStateFailed =
  (): ISetUserPassengersCallStateFailed => ({
    type: actionTypes.SET_USER_PASSENGERS_CALL_STATE_FAILED,
  });

export interface ISetUserPassengersCallStateFailed {
  type: actionTypes.SET_USER_PASSENGERS_CALL_STATE_FAILED;
}

export const acknowledgeUserPassengerFailure =
  (): IAcknowledgeUpdateUserPassengerFailure => ({
    type: actionTypes.ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE,
  });

export interface IAcknowledgeUpdateUserPassengerFailure {
  type: actionTypes.ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE;
}

export interface ISetContactInfo {
  type: actionTypes.SET_CONTACT_INFO;
  email: string;
  phoneNumber: string;
}

export const setContactInfo = (
  email: string,
  phoneNumber: string
): ISetContactInfo => ({
  type: actionTypes.SET_CONTACT_INFO,
  email,
  phoneNumber,
});

export const fetchPaymentMethod = (paymentId: string): IFetchPaymentMethod => ({
  type: actionTypes.FETCH_PAYMENT_METHOD,
  paymentId,
});

export interface IFetchPaymentMethod {
  type: actionTypes.FETCH_PAYMENT_METHOD;
  paymentId: string;
}

export const listPaymentMethods = (): IListPaymentMethods => ({
  type: actionTypes.LIST_PAYMENT_METHODS,
});

export interface IListPaymentMethods {
  type: actionTypes.LIST_PAYMENT_METHODS;
}

export const verifyPaymentMethod = (
  payload: IVerifyPaymentMethodArgs,
  cardAccountId: string
): IVerifyPaymentMethod => ({
  type: actionTypes.VERIFY_PAYMENT_METHOD,
  payload,
  cardAccountId,
});

export interface IVerifyPaymentMethod {
  type: actionTypes.VERIFY_PAYMENT_METHOD;
  payload: IVerifyPaymentMethodArgs;
  cardAccountId: string;
}

export interface IVerifyPaymentMethodArgs {
  token: string;
}

export const setPaymentMethods = (args: {
  paymentMethods: Payment[];
}): ISetPaymentMethods => ({
  type: actionTypes.SET_PAYMENT_METHODS,
  ...args,
});

export interface ISetPaymentMethods {
  type: actionTypes.SET_PAYMENT_METHODS;
  paymentMethods: Payment[];
}

export const setPaymentMethod = (args: {
  paymentMethod?: CreditCard;
}): ISetPaymentMethod => ({
  type: actionTypes.SET_PAYMENT_METHOD,
  ...args,
});

export interface ISetPaymentMethod {
  type: actionTypes.SET_PAYMENT_METHOD;
  paymentMethod?: CreditCard;
}

export const resetPaymentCardSelectedAccounts =
  (): IResetPaymentCardSelectedAccounts => {
    return {
      type: actionTypes.RESET_PAYMENT_CARD_SELECTED_ACCOUNTS,
    };
  };

export interface IResetPaymentCardSelectedAccounts {
  type: actionTypes.RESET_PAYMENT_CARD_SELECTED_ACCOUNTS;
}

export const setSelectedPaymentMethodId = (args: {
  paymentMethodId?: string;
  cardAccountId?: string;
}): ISetSelectedPaymentMethodId => {
  trackEvent({
    eventName: SELECT_PAYMENT,
    properties: {},
  });
  return {
    type: actionTypes.SET_SELECTED_PAYMENT_METHOD_ID,
    ...args,
  };
};

export interface ISetSelectedPaymentMethodId {
  type: actionTypes.SET_SELECTED_PAYMENT_METHOD_ID;
  paymentMethodId?: string;
  cardAccountId?: string;
}

export interface ISetPaymentMethods {
  type: actionTypes.SET_PAYMENT_METHODS;
  paymentMethods: Payment[];
}

export const deletePaymentMethod = (args: {
  paymentId: string;
}): IDeletePaymentMethod => ({
  type: actionTypes.DELETE_PAYMENT_METHOD,
  ...args,
});

export interface IDeletePaymentMethod {
  type: actionTypes.DELETE_PAYMENT_METHOD;
  paymentId: string;
}

export const deletePaymentMethodCallStateFailed =
  (): IDeletePaymentMethodCallStateFailed => ({
    type: actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_FAILED,
  });

export interface IDeletePaymentMethodCallStateFailed {
  type: actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_FAILED;
}

export const deletePaymentMethodCallStateSuccess =
  (): IDeletePaymentMethodCallStateSuccess => ({
    type: actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS,
  });

export interface IDeletePaymentMethodCallStateSuccess {
  type: actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS;
}

export const setPaymentMethodsCallStateFailed =
  (): ISetPaymentMethodsCallStateFailed => ({
    type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED,
  });

export interface ISetPaymentMethodsCallStateFailed {
  type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED;
}

export const setPaymentMethodCallStateFailed =
  (): ISetPaymentMethodCallStateFailed => ({
    type: actionTypes.SET_PAYMENT_METHOD_CALL_STATE_FAILED,
  });

export interface ISetPaymentMethodCallStateFailed {
  type: actionTypes.SET_PAYMENT_METHOD_CALL_STATE_FAILED;
}

export const verifyPaymentMethodCallStateFailed = (
  result: PaymentVerifyResultEnum
): IVerifyPaymentMethodCallStateFailed => ({
  type: actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED,
  result,
});

export interface IVerifyPaymentMethodCallStateFailed {
  type: actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED;
  result: PaymentVerifyResultEnum;
}

export const verifyPaymentMethodCallStateSuccess =
  (): IVerifyPaymentMethodCallStateSuccess => ({
    type: actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS,
  });

export interface IVerifyPaymentMethodCallStateSuccess {
  type: actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS;
}

export const setSession = (payload: PaymentSessionToken): ISetSession => ({
  type: actionTypes.SET_SESSION,
  payload,
});

export interface ISetSession {
  type: actionTypes.SET_SESSION;
  payload: PaymentSessionToken;
}

export const closeSession = (): ICloseSession => ({
  type: actionTypes.CLOSE_SESSION,
});

export interface ICloseSession {
  type: actionTypes.CLOSE_SESSION;
}

export const checkSession = (): ICheckSession => ({
  type: actionTypes.CHECK_SESSION,
});

export interface ICheckSession {
  type: actionTypes.CHECK_SESSION;
}

export const checkSessionCallStateSuccess =
  (): ICheckSessionCallStateSuccess => ({
    type: actionTypes.CHECK_SESSION_CALL_STATE_SUCCESS,
  });

export interface ICheckSessionCallStateSuccess {
  type: actionTypes.CHECK_SESSION_CALL_STATE_SUCCESS;
}

export const checkSessionCallStateFailed =
  (): ICheckSessionCallStateFailed => ({
    type: actionTypes.CHECK_SESSION_CALL_STATE_FAILED,
  });

export interface ICheckSessionCallStateFailed {
  type: actionTypes.CHECK_SESSION_CALL_STATE_FAILED;
}

export interface ICloseSessionCallStateSuccess {
  type: actionTypes.CLOSE_SESSION_CALL_STATE_SUCCESS;
}
export const closeSessionCallStateSuccess =
  (): ICloseSessionCallStateSuccess => ({
    type: actionTypes.CLOSE_SESSION_CALL_STATE_SUCCESS,
  });

export interface ICloseSessionCallStateFailed {
  type: actionTypes.CLOSE_SESSION_CALL_STATE_FAILED;
}

export const closeSessionCallStateFailed =
  (): ICloseSessionCallStateFailed => ({
    type: actionTypes.CLOSE_SESSION_CALL_STATE_FAILED,
  });

export const scheduleInitialPriceQuote = (
  payload: ISchedulePriceQuoteRequestParams
): ISchedulePriceQuote => ({
  type: actionTypes.SCHEDULE_PRICE_QUOTE,
  isInitialRequest: true,
  payload,
});

export const scheduleFollowUpPriceQuote = (
  payload: ISchedulePriceQuoteRequestParams,
  history: H.History
): ISchedulePriceQuote => ({
  type: actionTypes.SCHEDULE_PRICE_QUOTE,
  isInitialRequest: false,
  payload,
  history,
});

export const scheduleQuote = (args: {
  agentFee?: number;
  pollQuoteOnly?: boolean;
  isPriceFreezePurchase?: boolean;
}): IScheduleQuote => ({
  type: actionTypes.SCHEDULE_QUOTE,
  ...args,
});

export interface IScheduleQuote {
  type: actionTypes.SCHEDULE_QUOTE;
  agentFee?: number;
  pollQuoteOnly?: boolean;
  isPriceFreezePurchase?: boolean;
}

export const setScheduleQuoteCallStateSuccess =
  (): ISetScheduleQuoteCallStateSuccess => ({
    type: actionTypes.SCHEDULE_QUOTE_CALL_STATE_SUCCESS,
  });

export interface ISetScheduleQuoteCallStateSuccess {
  type: actionTypes.SCHEDULE_QUOTE_CALL_STATE_SUCCESS;
}

export const setScheduleQuoteCallStateFailed = (
  invalid: Invalid
): ISetScheduleQuoteCallStateFailure => ({
  type: actionTypes.SCHEDULE_QUOTE_CALL_STATE_FAILED,
  invalid,
});

export interface ISetScheduleQuoteCallStateFailure {
  type: actionTypes.SCHEDULE_QUOTE_CALL_STATE_FAILED;
  invalid: Invalid;
}

export const pollQuote = (
  agentFee?: number,
  pollQuoteOnly?: boolean,
  isPriceFreezePurchase?: boolean
): IPollQuote => ({
  type: actionTypes.POLL_QUOTE,
  agentFee,
  pollQuoteOnly,
  isPriceFreezePurchase,
});

export interface IPollQuote {
  type: actionTypes.POLL_QUOTE;
  agentFee?: number;
  pollQuoteOnly?: boolean;
  isPriceFreezePurchase?: boolean;
}

export const setPollQuoteCallStateSuccess = (): IPollQuoteCallStateSuccess => ({
  type: actionTypes.SET_POLL_QUOTE_CALL_STATE_SUCCESS,
});

export interface IPollQuoteCallStateSuccess {
  type: actionTypes.SET_POLL_QUOTE_CALL_STATE_SUCCESS;
}

export const setPollQuoteCallStateFailure = (
  errors: PaymentError[]
): ISetPollQuoteCallStateFailure => ({
  type: actionTypes.SET_POLL_QUOTE_CALL_STATE_FAILED,
  errors,
});

export interface ISetPollQuoteCallStateFailure {
  type: actionTypes.SET_POLL_QUOTE_CALL_STATE_FAILED;
  errors: PaymentError[];
}

export const setQuote = (priceQuote: PriceQuoteData | null): ISetQuote => ({
  type: actionTypes.SET_QUOTE,
  priceQuote,
});

export interface ISetQuote {
  type: actionTypes.SET_QUOTE;
  priceQuote: PriceQuoteData | null;
}

// this is the total rewards cost of the trip we get from the BE after price quote completes
// this should only be set when the selected account is tiered
// this is beacause seats rewards pricing is only calculated on the FE and the total cost (seats + flight)
// for tiered accounts will differ based on the tiered calculation logic
export const setQuotedRewardsTotal = (
  quotedRewardsTotal: RewardsPrice | null
): ISetQuotedRewardsTotal => ({
  type: actionTypes.SET_QUOTE_REWARDS_TOTAL,
  quotedRewardsTotal,
});

export interface ISetQuotedRewardsTotal {
  type: actionTypes.SET_QUOTE_REWARDS_TOTAL;
  quotedRewardsTotal: RewardsPrice | null;
}

export interface ISchedulePriceQuote {
  type: actionTypes.SCHEDULE_PRICE_QUOTE;
  payload: ISchedulePriceQuoteRequestParams;
  isInitialRequest: boolean;
  history?: H.History;
}

export interface ISchedulePriceQuoteRequestParams {
  tripId: string;
  seatedPassengers: string[];
  lapInfants: string[];
  fareId: string;
}

export const setSchedulePriceQuoteCallStateSuccess =
  (): ISetSchedulePriceQuoteCallStateSuccess => ({
    type: actionTypes.SCHEDULE_PRICE_QUOTE_CALL_STATE_SUCCESS,
  });

export interface ISetSchedulePriceQuoteCallStateSuccess {
  type: actionTypes.SCHEDULE_PRICE_QUOTE_CALL_STATE_SUCCESS;
}

export const setSchedulePriceQuoteCallStateFailed = (
  invalid: Invalid
): ISetSchedulePriceQuoteCallStateFailure => ({
  type: actionTypes.SCHEDULE_PRICE_QUOTE_CALL_STATE_FAILED,
  invalid,
});

export interface ISetSchedulePriceQuoteCallStateFailure {
  type: actionTypes.SCHEDULE_PRICE_QUOTE_CALL_STATE_FAILED;
  invalid: Invalid;
}

export const pollPriceQuote = (): IPollPriceQuote => ({
  type: actionTypes.POLL_PRICE_QUOTE,
});

export interface IPollPriceQuote {
  type: actionTypes.POLL_PRICE_QUOTE;
}

export const setPollPriceQuoteCallStateSuccess =
  (): IPollPriceQuoteCallStateSuccess => ({
    type: actionTypes.SET_POLL_PRICE_QUOTE_CALL_STATE_SUCCESS,
  });

export interface IPollPriceQuoteCallStateSuccess {
  type: actionTypes.SET_POLL_PRICE_QUOTE_CALL_STATE_SUCCESS;
}

export const setPollPriceQuoteCallStateFailure = (
  errors: ErrorCode[]
): ISetPollPriceQuoteCallStateFailure => ({
  type: actionTypes.SET_POLL_PRICE_QUOTE_CALL_STATE_FAILED,
  errors,
});

export interface ISetPollPriceQuoteCallStateFailure {
  type: actionTypes.SET_POLL_PRICE_QUOTE_CALL_STATE_FAILED;
  errors: ErrorCode[];
}

export const setPriceQuote = (priceQuote: PriceQuoteData): ISetPriceQuote => ({
  type: actionTypes.SET_PRICE_QUOTE,
  priceQuote,
});
export interface ISetPriceQuote {
  type: actionTypes.SET_PRICE_QUOTE;
  priceQuote: PriceQuoteData;
}

export const validatePassengers = (
  seatedPassengerIds: string[],
  lapInfantsIds: string[]
): IValidatePassengers => ({
  type: actionTypes.VALIDATE_PASSENGERS,
  seatedPassengerIds,
  lapInfantsIds,
});

export interface IValidatePassengers {
  type: actionTypes.VALIDATE_PASSENGERS;
  seatedPassengerIds: string[];
  lapInfantsIds: string[];
}

export const setPassengerValidationCallStateSuccess =
  (): IPassengerValidationCallStateSuccess => ({
    type: actionTypes.PASSENGER_VALIDATION_CALL_STATE_SUCCESS,
  });

export interface IPassengerValidationCallStateSuccess {
  type: actionTypes.PASSENGER_VALIDATION_CALL_STATE_SUCCESS;
}

export const setPassengerValidationCallStateFailure =
  (): IPassengerValidationCallStateFailure => ({
    type: actionTypes.PASSENGER_VALIDATION_CALL_STATE_FAILED,
  });

export interface IPassengerValidationCallStateFailure {
  type: actionTypes.PASSENGER_VALIDATION_CALL_STATE_FAILED;
}

export const fetchTripPricing = (): IFetchTripPricing => ({
  type: actionTypes.FETCH_TRIP_PRICING,
});

export interface IFetchTripPricing {
  type: actionTypes.FETCH_TRIP_PRICING;
}

export const setFetchTripPricingCallStateSuccess =
  (): IFetchTripPricingCallStateSuccess => ({
    type: actionTypes.FETCH_TRIP_PRICING_CALL_STATE_SUCCESS,
  });

export interface IFetchTripPricingCallStateSuccess {
  type: actionTypes.FETCH_TRIP_PRICING_CALL_STATE_SUCCESS;
}

export const setFetchTripPricingCallStateFailure = (
  errors: Array<ErrorCode>
): IFetchTripPricingCallStateFailure => ({
  type: actionTypes.FETCH_TRIP_PRICING_CALL_STATE_FAILURE,
  errors,
});

export interface IFetchTripPricingCallStateFailure {
  type: actionTypes.FETCH_TRIP_PRICING_CALL_STATE_FAILURE;
  errors: ErrorCode[];
}

export const setTripPricing = (
  tripPricing: TripPricingSummary
): ISetTripPricing => ({
  type: actionTypes.SET_TRIP_PRICING,
  tripPricing,
});

export interface ISetTripPricing {
  type: actionTypes.SET_TRIP_PRICING;
  tripPricing: TripPricingSummary;
}

export const setPassengersValid = (): ISetPassengersValid => ({
  type: actionTypes.SET_PASSENGERS_VALID,
});

export interface ISetPassengersValid {
  type: actionTypes.SET_PASSENGERS_VALID;
}

export const setPassengersInvalid = (
  errors: PassengerError[]
): ISetPassengersInvalid => ({
  type: actionTypes.SET_PASSENGERS_INVALID,
  errors,
});

export interface ISetPassengersInvalid {
  type: actionTypes.SET_PASSENGERS_INVALID;
  errors: PassengerError[];
}

export const schedulePayment = (
  payload: PaymentSplitRequest,
  agentFee?: number
): ISetSchedulePayment => ({
  type: actionTypes.SCHEDULE_PAYMENT,
  payload,
  agentFee,
});

export interface ISetSchedulePayment {
  type: actionTypes.SCHEDULE_PAYMENT;
  payload: PaymentSplitRequest;
  agentFee?: number;
}

export const setSchedulePaymentSuccess = (): ISetSchedulePaymentSuccess => ({
  type: actionTypes.SET_SCHEDULE_PAYMENT_SUCCESS,
});

export interface ISetSchedulePaymentSuccess {
  type: actionTypes.SET_SCHEDULE_PAYMENT_SUCCESS;
}

export const setSchedulePaymentFailed = (
  invalid: Invalid
): ISetSchedulePaymentFailed => ({
  type: actionTypes.SET_SCHEDULE_PAYMENT_FAILED,
  invalid,
});

export interface ISetSchedulePaymentFailed {
  type: actionTypes.SET_SCHEDULE_PAYMENT_FAILED;
  invalid: Invalid;
}

export const redoSearch = (): IRedoSearch => ({
  type: actionTypes.REDO_SEARCH,
});

export interface IRedoSearch {
  type: actionTypes.REDO_SEARCH;
}

export const acknowledgePriceDifference = (): IAcknowledgePriceDifference => ({
  type: actionTypes.ACKNOWLEDGE_PRICE_DIFFERENCE,
});

export interface IAcknowledgePriceDifference {
  type: actionTypes.ACKNOWLEDGE_PRICE_DIFFERENCE;
}

export interface IPopulateFlightBookQueryParams {
  type: actionTypes.POPULATE_FLIGHT_BOOK_QUERY_PARAMS;
  history: H.History;
  pathname?: string;
  preserveQuery?: boolean;
}

export const populateFlightBookQueryParams = (args: {
  history: H.History;
  pathname?: string;
  preserveQuery?: boolean;
  replace?: boolean;
}): IPopulateFlightBookQueryParams => ({
  type: actionTypes.POPULATE_FLIGHT_BOOK_QUERY_PARAMS,
  ...args,
});

export interface ISetFlightBookQueryParams {
  type: actionTypes.SET_FLIGHT_BOOK_QUERY_PARAMS;
  history: H.History;
}

export const setFlightBookQueryParams = (
  history: H.History
): ISetFlightBookQueryParams => ({
  type: actionTypes.SET_FLIGHT_BOOK_QUERY_PARAMS,
  history,
});

export interface ISetSelectedRewardsAccountReferenceId {
  type: actionTypes.SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID;
  rewardsAccountReferenceId: string | null;
}

export const setSelectedRewardsAccountReferenceId = (
  rewardsAccountReferenceId: string | null
): ISetSelectedRewardsAccountReferenceId => {
  trackEvent({
    eventName: SELECT_REWARDS,
    properties: {},
  });
  return {
    type: actionTypes.SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID,
    rewardsAccountReferenceId,
  };
};

export interface ISetSelectedRewardsPaymentTotal {
  type: actionTypes.SET_SELECTED_REWARDS_PAYMENT_TOTAL;
  accountReferenceId: string;
  rewardsPaymentTotal: RewardsPrice | null;
  rewardsPaymentInFiatCurrency: FiatPrice | null;
  total?: Prices;
}

export const setSelectedRewardsPaymentTotal = (
  accountReferenceId: string,
  rewardsPaymentTotal: RewardsPrice | null,
  rewardsPaymentInFiatCurrency: FiatPrice | null,
  total?: Prices
): ISetSelectedRewardsPaymentTotal => ({
  type: actionTypes.SET_SELECTED_REWARDS_PAYMENT_TOTAL,
  accountReferenceId,
  rewardsPaymentTotal,
  rewardsPaymentInFiatCurrency,
  total,
});

export const setRewardsConversionFailed = (): IRewardsConversionFailed => ({
  type: actionTypes.REWARDS_CONVERSION_FAILED,
});

export interface IRewardsConversionFailed {
  type: actionTypes.REWARDS_CONVERSION_FAILED;
}

export const pollFinalized = (agentFee?: number): IPollFinalized => ({
  type: actionTypes.POLL_FINALIZED,
  agentFee,
});

export interface IPollFinalized {
  type: actionTypes.POLL_FINALIZED;
  agentFee?: number;
}

export const setPollFinalizedCallStatePending =
  (): IPollFinalizedCallStatePending => ({
    type: actionTypes.POLL_FINALIZED_CALL_STATE_PENDING,
  });

export interface IPollFinalizedCallStatePending {
  type: actionTypes.POLL_FINALIZED_CALL_STATE_PENDING;
}

export const setPollFinalizedCallStateSuccess =
  (): IPollFinalizedCallStateSuccess => ({
    type: actionTypes.POLL_FINALIZED_CALL_STATE_SUCCESS,
  });

export interface IPollFinalizedCallStateSuccess {
  type: actionTypes.POLL_FINALIZED_CALL_STATE_SUCCESS;
}

export const setPollFinalizedCallStateFailure = (
  errors: PaymentError[]
): ISetPollFinalizedCallStateFailure => ({
  type: actionTypes.POLL_FINALIZED_CALL_STATE_FAILED,
  errors,
});

export interface ISetPollFinalizedCallStateFailure {
  type: actionTypes.POLL_FINALIZED_CALL_STATE_FAILED;
  errors: PaymentError[];
}

export const setFinalized = (
  finalizeResult:
    | FlightPaymentFinalizeResult
    | PriceFreezeFlightPaymentFinalizeResult
): ISetFinalized => ({
  type: actionTypes.SET_FINALIZED,
  finalizeResult,
});

export interface ISetFinalized {
  type: actionTypes.SET_FINALIZED;
  finalizeResult:
    | FlightPaymentFinalizeResult
    | PriceFreezeFlightPaymentFinalizeResult;
}

export const resetFinalized = (): IResetFinalized => ({
  type: actionTypes.RESET_FINALIZED,
});

export interface IResetFinalized {
  type: actionTypes.RESET_FINALIZED;
}

export interface IFetchProductToEarn {
  type: actionTypes.FETCH_PRODUCT_TO_EARN;
}

export const fetchProductToEarn = (): IFetchProductToEarn => ({
  type: actionTypes.FETCH_PRODUCT_TO_EARN,
});

export interface ISetProductEarnValue {
  type: actionTypes.SET_PRODUCT_EARN_VALUE;
  productEarnValue: number;
}

export const setEarnValue = (
  productEarnValue: number
): ISetProductEarnValue => ({
  type: actionTypes.SET_PRODUCT_EARN_VALUE,
  productEarnValue,
});

export interface ISetupFlightBookParams {
  type: actionTypes.SET_UP_FLIGHT_BOOK_PARAMS;
  history: H.History;
  flightBookType?: FlightBookType;
}

export const setUpFlightBookParams = (args: {
  history: H.History;
  flightBookType?: FlightBookType;
}): ISetupFlightBookParams => ({
  type: actionTypes.SET_UP_FLIGHT_BOOK_PARAMS,
  ...args,
});

export interface ISetUpFlightBookParamsFailed {
  type: actionTypes.SET_UP_FLIGHT_BOOK_PARAMS_FAILED;
}

export const setUpFlightBookParamsFailed =
  (): ISetUpFlightBookParamsFailed => ({
    type: actionTypes.SET_UP_FLIGHT_BOOK_PARAMS_FAILED,
  });

export interface ISetUpFlightBookParamsSuccess {
  type: actionTypes.SET_UP_FLIGHT_BOOK_PARAMS_SUCCESS;
}

export const setUpFlightBookParamsSuccess =
  (): ISetUpFlightBookParamsSuccess => ({
    type: actionTypes.SET_UP_FLIGHT_BOOK_PARAMS_SUCCESS,
  });

export interface IResetBookingErrors {
  type: actionTypes.RESET_BOOKING_ERRORS;
}

export const resetBookingErrors = (): IResetBookingErrors => ({
  type: actionTypes.RESET_BOOKING_ERRORS,
});

export interface IChargeAgentBookingFee {
  type: actionTypes.CHARGE_AGENT_BOOKING_FEE;
  agentEmail: string;
}

export const chargeAgentBookingFee = (
  agentEmail: string
): IChargeAgentBookingFee => ({
  type: actionTypes.CHARGE_AGENT_BOOKING_FEE,
  agentEmail,
});

export interface IChargeAgentBookingFeeFailed {
  type: actionTypes.CHARGE_AGENT_BOOKING_FEE_FAILED;
}

export const chargeAgentBookingFeeFailed =
  (): IChargeAgentBookingFeeFailed => ({
    type: actionTypes.CHARGE_AGENT_BOOKING_FEE_FAILED,
  });

export interface IChargeAgentBookingFeeSuccess {
  type: actionTypes.CHARGE_AGENT_BOOKING_FEE_SUCCESS;
}

export const chargeAgentBookingFeeSuccess =
  (): IChargeAgentBookingFeeSuccess => ({
    type: actionTypes.CHARGE_AGENT_BOOKING_FEE_SUCCESS,
  });

export interface IFetchSeatMap {
  type: actionTypes.FETCH_SEAT_MAP;
  callback: SeatMapCallback;
}

export const fetchSeatMap = (callback: SeatMapCallback): IFetchSeatMap => ({
  type: actionTypes.FETCH_SEAT_MAP,
  callback,
});

export interface ISetSeatMap {
  type: actionTypes.SET_SEAT_MAP;
  seatMapHtml: string | null;
}

export const setSeatMap = (seatMapHtml: string | null): ISetSeatMap => ({
  type: actionTypes.SET_SEAT_MAP,
  seatMapHtml,
});

export interface ISetCheapestSeat {
  type: actionTypes.SET_CHEAPEST_SEAT;
  cheapestSeat: Prices;
}

export const setCheapestSeat = (cheapestSeat: Prices): ISetCheapestSeat => ({
  type: actionTypes.SET_CHEAPEST_SEAT,
  cheapestSeat,
});

export interface IFetchSeatMapFailed {
  type: actionTypes.FETCH_SEAT_MAP_FAILED;
}
export const fetchSeatMapFailed = () => ({
  type: actionTypes.FETCH_SEAT_MAP_FAILED,
});

export interface ISetSkipSeatSelection {
  type: actionTypes.SET_SKIP_SEAT_SELECTION;
  skipSelection: boolean;
}

export const setSkipSeatSelection = (
  skipSelection: boolean
): ISetSkipSeatSelection => ({
  type: actionTypes.SET_SKIP_SEAT_SELECTION,
  skipSelection,
});
export interface ISetSelectedSeats {
  type: actionTypes.SET_SELECTED_SEATS;
  selectedSeats: GordianSeat[];
}
export const setSelectedSeats = (
  selectedSeats: GordianSeat[]
): ISetSelectedSeats => ({
  type: actionTypes.SET_SELECTED_SEATS,
  selectedSeats,
});

export interface IFetchPriceDropEligibility {
  type: actionTypes.FETCH_PRICE_DROP_ELIGIBILITY;
  candidateId: string;
}
export const fetchPriceDropEligibility = (
  candidateId: string
): IFetchPriceDropEligibility => ({
  type: actionTypes.FETCH_PRICE_DROP_ELIGIBILITY,
  candidateId,
});

export interface ISetPriceDropProtection {
  type: actionTypes.SET_PRICE_DROP_PROTECTION;
  priceDropProtection: PriceDropProtection | null;
  fetchPriceDropEligibilityCallState: CallState;
}
export const setPriceDropProtection = (args: {
  priceDropProtection: PriceDropProtection | null;
  fetchPriceDropEligibilityCallState: CallState;
}): ISetPriceDropProtection => ({
  type: actionTypes.SET_PRICE_DROP_PROTECTION,
  ...args,
});

export interface ISetIsSimilarFlightsEnabled {
  type: actionTypes.SET_IS_SIMILAR_FLIGHTS_ENABLED;
  isSimilarFlightsEnabled: boolean;
}

export const setIsSimilarFlightsEnabled = (
  isSimilarFlightsEnabled: boolean
): ISetIsSimilarFlightsEnabled => ({
  type: actionTypes.SET_IS_SIMILAR_FLIGHTS_ENABLED,
  isSimilarFlightsEnabled,
});

export interface ISetFlightBookType {
  type: actionTypes.SET_FLIGHT_BOOK_TYPE;
  flightBookType: FlightBookType;
}

export const setFlightBookType = (
  flightBookType: FlightBookType
): ISetFlightBookType => ({
  type: actionTypes.SET_FLIGHT_BOOK_TYPE,
  flightBookType,
});

export type FlightBookActions =
  | IFetchUserPassengers
  | IUpdateUserPassenger
  | IDeleteUserPassenger
  | ISetInfantOnlyError
  | ISetUserPassengers
  | ISetUserSelectedPassengerIds
  | ISetUserSelectedLapInfantIds
  | ISetUserPassengersCallStateFailed
  | IAcknowledgeUpdateUserPassengerFailure
  | ISetContactInfo
  | IFetchPaymentMethod
  | IListPaymentMethods
  | IDeletePaymentMethod
  | ISetPaymentMethod
  | ISetPaymentMethods
  | IResetPaymentCardSelectedAccounts
  | ISetSelectedPaymentMethodId
  | IVerifyPaymentMethod
  | IVerifyPaymentMethodCallStateSuccess
  | IVerifyPaymentMethodCallStateFailed
  | IDeletePaymentMethodCallStateSuccess
  | IDeletePaymentMethodCallStateFailed
  | ISetPaymentMethodsCallStateFailed
  | ISetPaymentMethodCallStateFailed
  | ISetSession
  | ICheckSession
  | ICloseSession
  | ICloseSessionCallStateSuccess
  | ICloseSessionCallStateFailed
  | ICheckSessionCallStateSuccess
  | ICheckSessionCallStateFailed
  | IScheduleQuote
  | ISetScheduleQuoteCallStateSuccess
  | ISetScheduleQuoteCallStateFailure
  | IPollQuote
  | IPollQuoteCallStateSuccess
  | ISetPollQuoteCallStateFailure
  | ISetQuote
  | ISchedulePriceQuote
  | ISetSchedulePriceQuoteCallStateSuccess
  | ISetSchedulePriceQuoteCallStateFailure
  | IPollPriceQuote
  | IPollPriceQuoteCallStateSuccess
  | ISetPollPriceQuoteCallStateFailure
  | ISetPriceQuote
  | IValidatePassengers
  | IPassengerValidationCallStateSuccess
  | IPassengerValidationCallStateFailure
  | ISetPassengersValid
  | ISetPassengersInvalid
  | ISetSchedulePayment
  | ISetSchedulePaymentFailed
  | ISetSchedulePaymentSuccess
  | IRedoSearch
  | IAcknowledgePriceDifference
  | IPopulateFlightBookQueryParams
  | ISetFlightBookQueryParams
  | ISetUpFlightBookParamsFailed
  | ISetUpFlightBookParamsSuccess
  | ISetSelectedRewardsAccountReferenceId
  | IFetchProductToEarn
  | ISetProductEarnValue
  | IFetchTripPricingCallStateFailure
  | IFetchTripPricing
  | ISetTripPricing
  | IFetchTripPricingCallStateSuccess
  | IPollFinalized
  | ISetPollFinalizedCallStateFailure
  | IPollFinalizedCallStateSuccess
  | IPollFinalizedCallStatePending
  | ISetFinalized
  | ISetSelectedRewardsPaymentTotal
  | ISetupFlightBookParams
  | IResetBookingErrors
  | IRewardsConversionFailed
  | IChargeAgentBookingFee
  | IChargeAgentBookingFeeFailed
  | IChargeAgentBookingFeeSuccess
  | IFetchSeatMap
  | ISetSeatMap
  | ISetCheapestSeat
  | IFetchSeatMapFailed
  | ISetSkipSeatSelection
  | ISetSelectedSeats
  | IFetchPriceDropEligibility
  | ISetPriceDropProtection
  | ISetFlightBookType
  | ISetQuotedRewardsTotal
  | ISetIsSimilarFlightsEnabled
  | IResetFinalized;
