import { Fragment, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import * as textConstants from "./constants";
import { CallState } from "@hopper-b2b/types";

import "./styles.scss";
import { ActionButton, B2BSpinner, OutlinedTextInput } from "@hopper-b2b/ui";
import { emailRegex } from "@hopper-b2b/utilities";
import { ChangeEmailModalContentConnectorProps } from "./container";
import { WatchAlertView } from "@b2bportal/air-price-watch-api";

interface IChangeEmailModalContentProps
  extends ChangeEmailModalContentConnectorProps {
  onClose: () => void;
}

export const ChangeEmailModalContent = ({
  openModal,
  updateWatch,
  updateWatchCallState,
  setUpdateWatchCallState,
  onClose,
}: IChangeEmailModalContentProps) => {
  const watch = openModal.selectedItinerary as WatchAlertView;
  const [email, setEmail] = useState(watch.method?.email || "");
  const [emailError, setEmailError] = useState("");

  const onEmailBlur = (val: string) => {
    emailRegex.test(val)
      ? setEmailError("")
      : setEmailError("Enter a valid email address");
  };
  const onEmailChanged = (val: string) => {
    setEmail(val);
  };

  const getModalContent = () => {
    switch (updateWatchCallState) {
      case CallState.InProcess:
        return <B2BSpinner />;
      case CallState.Failed:
        return (
          <Fragment>
            <Typography className="change-email-title" variant="h2">
              {textConstants.CHANGE_EMAIL_FAILURE_TITLE}
            </Typography>
            <Typography className="change-email-subtitle" variant="h5">
              {textConstants.CHANGE_EMAIL_FAILURE_SUBTITLE}
            </Typography>
            <Box className="change-email-buttons">
              <ActionButton
                className="change-email-button"
                onClick={() => {
                  setUpdateWatchCallState(CallState.NotCalled);
                }}
                message={textConstants.RETRY_CTA}
                defaultStyle="h4r-primary"
              />
              <ActionButton
                className="cancel-button"
                onClick={() => onClose()}
                message={textConstants.CANCEL_CTA}
                defaultStyle="h4r-secondary"
              />
            </Box>
          </Fragment>
        );
      case CallState.Success:
        return (
          <Fragment>
            <Typography className="change-email-title" variant="h2">
              {`${textConstants.CHANGE_EMAIL_SUCCESS} ${email}`}
            </Typography>
            <Box className="change-email-buttons">
              <ActionButton
                className="cancel-button"
                onClick={() => onClose()}
                message={textConstants.DONE_CTA}
                defaultStyle="h4r-primary"
              />
            </Box>
            <Typography className="change-email-info" variant="body1">
              {textConstants.CHANGE_EMAIL_INFO}
            </Typography>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <Typography className="change-email-title" variant="h2">
              {textConstants.CHANGE_EMAIL_TITLE}
            </Typography>
            <Typography className="change-email-subtitle" variant="h5">
              {textConstants.CHANGE_EMAIL_SUBTITLE}
            </Typography>
            <OutlinedTextInput
              role="input"
              ariaLabel="Email"
              label="Email"
              placeholder="Email"
              value={email}
              errorLabel={emailError}
              onBlur={(event) => onEmailBlur(event.target.value)}
              onChange={(event) => onEmailChanged(event.target.value)}
            />
            <Box className="change-email-buttons">
              <ActionButton
                className="change-email-button"
                onClick={() => {
                  updateWatch(watch.key, email);
                }}
                message={textConstants.CHANGE_EMAIL_CTA}
                defaultStyle="h4r-primary"
              />
              <ActionButton
                className="cancel-button"
                onClick={() => onClose()}
                message={textConstants.CANCEL_CTA}
                defaultStyle="h4r-secondary"
              />
            </Box>
            <Typography className="change-email-info" variant="body2">
              {textConstants.CHANGE_EMAIL_INFO}
            </Typography>
          </Fragment>
        );
    }
  };
  return <Box className="change-email-modal-content">{getModalContent()}</Box>;
};
