import { LodgingAddress, LodgingAddressEnum } from "@b2bportal/lodging-api";
import { AmenityStatus } from "@hopper-b2b/types";
import { integerWithCommas } from "@commbank/utilities";

const AmenitiesOrdered = [
  "pool",
  "swimmingpool",
  "freebreakfast",
  "petfriendly",
  "petsallowed",
  "petsallowednofees",
  "roundtheclockcheckin",
  "twentyfourhourfrontdesk",
  "spa",
  "fullservicespa",
  "fitnesscenter",
  "fitnessfacilities",
  "twentyfourhourfitnessfacilities",
  "roomwifi",
  "freewifi",
  "accessibility",
  "inroomaccessibility",
  "laundry",
  "laundryfacilities",
  "airconditioning",
  "parking",
  "freeselfparking",
  "paidselfparking",
  "paidvaletparking",
];
export const orderedAmenitiesLength = AmenitiesOrdered.length;
export const mappedOrderedAmenities = AmenitiesOrdered.reduce(
  (obj, cur, i) => ({ [cur]: i, ...obj }),
  {} as Record<string, number>
);

const getSortIndex = (amenity: string) => {
  const i = AmenitiesOrdered.indexOf(amenity.toLowerCase());
  return i >= 0 ? i : AmenitiesOrdered.length;
};

export const sortAmenities = (amenities: AmenityStatus[]) => {
  return [...amenities].sort(
    (a, b) => getSortIndex(a.amenity) - getSortIndex(b.amenity)
  );
};

export const scrollTopWithOffset = (element: HTMLDivElement) => {
  /**
   * TODO this is not tenant friendly;
   * They don't all have the same header height neither a sticker header.
   * Plus we should rely on SECTION_ID and update the anchor.
   */
  const offset = 74; //header (64) + padding below header (10px)
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

export const mapPrefix = "https://maps.googleapis.com/maps/api/staticmap";

export const getMapSrc = (
  coordinates: string,
  zoom: string,
  size: string,
  scale = "1"
) => {
  return `${mapPrefix}?center=${coordinates}&zoom=${zoom}&size=${size}&scale=${scale}&maptype=roadmap
  &key=${import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY}`;
};

export const getAddressText = (address: LodgingAddress): string | null => {
  switch (address.LodgingAddress) {
    case LodgingAddressEnum.Exact:
      return address.line1;
    case LodgingAddressEnum.Obfuscated:
      return address?.city;
    default:
      return null;
  }
};

export const transformListToListOfPairs = <T>(originalList: T[]) => {
  return originalList.reduce((result, _value, index, array) => {
    if (index % 2 === 0) {
      result.push(array.slice(index, index + 2));
    }

    return result;
  }, [] as T[][]);
};

export function numberWithCommas(x) {
  return (
    integerWithCommas(Math.floor(x)) +
    (x % 1 ? "." + Math.round((x % 1) * 100) : "")
  );
}
