import {
  ApacActionButton,
  ApacAlertBanner,
  ApacIcon,
  ApacIconName,
  ApacModalLink,
} from "@commbank/ui";
import { useI18nContext, useI18nCurrency } from "@hopper-b2b/i18n";
import {
  AlgomerchTag,
  FareDetails,
  FareScore,
  FlightTags,
  MultiTicketTypeEnum,
  TripDetails,
  mapI18nAlgomerchText,
} from "@hopper-b2b/types";
import { ActionLink, IFareDetailsCardProps, Tag } from "@hopper-b2b/ui";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { apacGetTags } from "../../../../../../utils/helpers";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { getPointsPriceFormatted } from "../../../../../../../../../../utils/getRewardsText";
import { AmenityGroup } from "../../../../../../../../components/FlightAmenity/AmenityGroup";
import { getSelectedAccount } from "../../../../../../../rewards/reducer";
import { getFareAmenities } from "../../../../../../utils/fareAmenities";
import "./FareCardDetails.scss";
import { CombinationModalContent } from "./CombinationModalContent";

export type FareCardDetailsProps = Pick<
  IFareDetailsCardProps,
  | "isOutgoing"
  | "fareDetails"
  | "fareNotice"
  | "onClick"
  | "onAlgomerchClick"
  | "getEmptyRestrictionsText"
  | "getFlightShopFareSlice"
> & {
  fare: FareDetails;
  isSelected: boolean;
  index: number;
  anchorEl: any;
  isLast: boolean;
  tripDetails?: TripDetails;
  isMobile?: boolean;
};

export const FareCardDetails = (props: FareCardDetailsProps) => {
  const {
    fare: currentFareDetails,
    index,
    anchorEl,
    isLast,
    isOutgoing,
    fareDetails,
    fareNotice,
    onClick,
    onAlgomerchClick,
    isSelected,
    getEmptyRestrictionsText,
    getFlightShopFareSlice,
    isMobile = true,
  } = props;

  const { t } = useI18nContext();
  const { formatFiatCurrency } = useI18nCurrency();
  const selected = useMemo(() => isSelected, [isSelected]);
  const selectedAccount = useSelector(getSelectedAccount);

  const fareIndex = currentFareDetails.slices.findIndex((slice) =>
    isOutgoing ? slice.outgoing : !slice.outgoing
  );
  const fareSlice = currentFareDetails.slices[fareIndex];

  const shopFareSlice = getFlightShopFareSlice(currentFareDetails.id);

  const amenities = useMemo(() => {
    return getFareAmenities(fareSlice);
  }, [fareSlice]);

  const getTagText = useCallback(
    (fareScore: FareScore | FlightTags) => {
      const tagLimit = 2;
      const tagSeperator = ", ";
      const tags = apacGetTags(fareScore);
      const filteredTags = tags
        .map((tag: AlgomerchTag) => t(mapI18nAlgomerchText[tag]))
        .slice(0, tagLimit);
      return filteredTags.join(tagSeperator);
    },
    [t]
  );

  const tagText = shopFareSlice ? getTagText(shopFareSlice.tags) : "";

  const paxPricing = currentFareDetails.paxPricings[0].pricing;
  const totalPriceString = formatFiatCurrency(
    {
      value: paxPricing.baseAmount.fiat.value + paxPricing.taxAmount.fiat.value,
      currencyCode: paxPricing.baseAmount.fiat.currencyCode,
    },
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }
  );

  const showFareNotice = !!fareDetails.find(
    (f) => f.multiTicketType === MultiTicketTypeEnum.HackerFare
  );

  return (
    <Box
      className={clsx("fare-card-container", {
        selected,
        last: isLast,
      })}
    >
      {tagText && (
        <Grid item>
          <Button className="algomerch-button-tag" size="small">
            <Tag
              className="algomerch-tag"
              label={tagText}
              onClick={() => onAlgomerchClick?.(tagText)}
            >
              <ApacIcon name={ApacIconName.InfoOutlined} />
            </Tag>
          </Button>
        </Grid>
      )}

      <Box
        className="fare-card"
        onClick={isMobile ? () => onClick(currentFareDetails) : undefined}
      >
        {isMobile && (
          <input
            type="radio"
            name="locale"
            id={currentFareDetails.id}
            value={currentFareDetails.id}
            checked={selected}
            defaultChecked={selected}
            onChange={() => onClick(currentFareDetails)}
          />
        )}
        <Box className="fare-card-details">
          <Typography className="fare-details-brand-name">
            {fareSlice.fareShelf?.shortBrandName}
            {isMobile && (
              <span className="available-seats">
                {t("availableSeats", {
                  numberOfSeats:
                    fareSlice.fareDetails.segments[0].availableSeats,
                })}
              </span>
            )}
          </Typography>
          <Typography className="fare-details-pricing">
            {totalPriceString}
            {!!selectedAccount?.pointsExchangeRate && (
              <span className="rewards-pricing">
                {t("flightShop.orAwardPoints", {
                  points: getPointsPriceFormatted({
                    pricing: paxPricing,
                    selectedAccount,
                  }),
                })}
              </span>
            )}
          </Typography>
          {showFareNotice && (
            <ApacModalLink
              className="review-itinerary-flight-combo-banner"
              content={CombinationModalContent}
            >
              {t("combinationFlightWarning")}
            </ApacModalLink>
          )}
          {amenities && (
            <AmenityGroup
              anchorEl={anchorEl}
              restrictions={amenities}
              fareSlice={fareSlice}
              fareIndex={index}
              getEmptyRestrictionsText={getEmptyRestrictionsText}
            />
          )}
        </Box>

        {!isMobile && (
          <Box className="fare-select-button-container">
            <Typography className="available-seats" variant="body1">
              {t("flightShop.availableSeats", {
                numberOfSeats: fareSlice.fareDetails.segments[0].availableSeats,
              })}
            </Typography>
            <ApacActionButton
              className="select-button"
              message={t("flightShop.selectFare")}
              onClick={() => onClick(currentFareDetails)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
