// Getters
export const FETCH_REWARDS_ACCOUNTS = "flightRewards/FETCH_REWARDS_ACCOUNTS";
export type FETCH_REWARDS_ACCOUNTS = typeof FETCH_REWARDS_ACCOUNTS;

// Setters
export const SET_REWARDS_ACCOUNTS = "flightRewards/SET_REWARDS_ACCOUNTS";
export type SET_REWARDS_ACCOUNTS = typeof SET_REWARDS_ACCOUNTS;

export const SET_SELECTED_ACCOUNT_REFERENCE_ID =
  "flightRewards/SET_SELECTED_ACCOUNT_REFERENCE_ID";
export type SET_SELECTED_ACCOUNT_REFERENCE_ID =
  typeof SET_SELECTED_ACCOUNT_REFERENCE_ID;

export const SET_FETCH_REWARDS_ACCOUNTS_CALL_STATE_FAILED =
  "flightRewards/SET_FETCH_REWARDS_ACCOUNTS_CALL_STATE_FAILED";
export type SET_FETCH_REWARDS_ACCOUNTS_CALL_STATE_FAILED =
  typeof SET_FETCH_REWARDS_ACCOUNTS_CALL_STATE_FAILED;

export const SET_IS_FIRST_LAUNCH = "flightRewards/SET_IS_FIRST_LAUNCH";
export type SET_IS_FIRST_LAUNCH = typeof SET_IS_FIRST_LAUNCH;

export const SET_AGENT_EMAIL = "flightRewards/SET_AGENT_EMAIL";
export type SET_AGENT_EMAIL = typeof SET_AGENT_EMAIL;
