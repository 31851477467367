import { Fragment, type ReactNode, useContext } from "react";
import { Provider, ReactReduxContext } from "react-redux";
import { store } from "../../store";

export const FlightsReduxProvider = ({ children }: { children: ReactNode }) => {
  // This FlightsReduxProvider is exported from the flights-module now.
  // It allows applications to render the redux provider inside of the application (optional)
  // This way they can create connected components, and access state from within the application.
  const reactRedux = useContext(ReactReduxContext);

  // Check if we're already within a react-redux provider and turn Provider into a Fragment if so.
  if (reactRedux) {
    return <Fragment children={children} />;
  }

  return <Provider store={store}>{children}</Provider>;
};
