import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  useEnableWallet,
  useIsSessionAuthenticated,
} from "@hopper-b2b/utilities";
import { fetchWalletOffers } from "../../wallet/actions/actions";

export const useFetchWalletOffers = () => {
  const dispatch = useDispatch();

  const enableWallet = useEnableWallet();
  const authenticated = useIsSessionAuthenticated();
  return useCallback(
    (tripId: string, fareId: string) => {
      if (enableWallet && authenticated) {
        dispatch(fetchWalletOffers(tripId, fareId));
      }
    },
    [authenticated, dispatch, enableWallet]
  );
};
