import {
  BookedFlightItinerary,
  FlightItinerarySlice,
  TravelItinerary,
} from "@b2bportal/air-booking-api";
import {
  Copy,
  ExchangeScenario,
  ExchangeScenarioEnum,
  VoidScenarioEnum,
} from "@b2bportal/air-exchange-api";
import { Amount, TravelItineraryEnum, TripCategory } from "@hopper-b2b/types";
import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { IStoreState } from "../../../reducers/types";
import {
  getDepartureDate,
  getDestination,
  getOrigin,
  getReturnDate,
} from "../../search/reducer";
import {
  getAirlines,
  getExchangePolicy,
  getExchangeScenario,
  getIsFtc,
  getItinerary,
  getItineraryDepDate,
  getItineraryDest,
  getItineraryOrigin,
  getItineraryRetDate,
  getItineraryTripType,
  getPolicyStore,
  getTravelItinerary,
} from "../selectors";
import { getPolicyAttr } from "../utils";
import { FetchState } from "./policy";

const getSearchState = (state: IStoreState) => state.flightExchange.search;

export const getIsLoadingContext = (state: IStoreState) => {
  const { exchangePolicy, itinerary, itineraryFetchState, policyFetchState } =
    getPolicyStore(state);
  const doneFetchingItinerary =
    itineraryFetchState === FetchState.Done && Boolean(itinerary);
  const doneFetchingPolicy =
    policyFetchState === FetchState.Done && Boolean(exchangePolicy);

  return !doneFetchingItinerary || !doneFetchingPolicy;
};

export const getMarketingAirline = createSelector(
  getAirlines,
  getTravelItinerary,
  (airlines, tItinerary) => {
    let airline: string;

    if (tItinerary) {
      const { marketingAirline } = tItinerary.slices[0].segments[0];

      airline = airlines[marketingAirline.code].displayName;
    }

    return airline;
  }
);

export const getChangeFee = (state: IStoreState) => {
  const { exchangePolicy } = getPolicyStore(state);

  if (exchangePolicy?.ExchangeScenario === ExchangeScenarioEnum.Exchangeable) {
    return exchangePolicy.changeFee;
  }

  return null;
};

export const getIsSameDepDate = createSelector(
  getDepartureDate,
  getItineraryDepDate,
  (depDate, itinDepDate) => dayjs(depDate).isSame(itinDepDate, "day")
);

export const getIsSameRetDate = createSelector(
  getReturnDate,
  getItineraryRetDate,
  (retDate, itinRetDate) =>
    itinRetDate ? dayjs(retDate).isSame(itinRetDate, "day") : false
);

export const getIsSameDest = createSelector(
  getDestination,
  getItineraryDest,
  (selDest, itinDest) => selDest?.id.code.code === itinDest?.locationCode
);

export const getIsSameOrigin = createSelector(
  getOrigin,
  getItineraryOrigin,
  (selOrigin, itinOrigin) =>
    selOrigin?.id.code.code === itinOrigin?.locationCode
);

export const getOutboundChanged = createSelector(
  getIsSameOrigin,
  getIsSameDest,
  getIsSameDepDate,
  (sameOrigin, sameDest, sameDepDate) =>
    !(sameOrigin && sameDest && sameDepDate)
);

export const getReturnChanged = createSelector(
  getIsSameOrigin,
  getIsSameDest,
  getIsSameRetDate,
  (sameOrigin, sameDest, sameRetDate) =>
    !(sameOrigin && sameDest && sameRetDate)
);

export const getShowSlicePicker = createSelector(
  getIsFtc,
  getItineraryTripType,
  getOutboundChanged,
  getReturnChanged,
  (isFTC, itinTripType, outboundChanged, returnChanged) => {
    const isRoundTrip = itinTripType === TripCategory.ROUND_TRIP;

    if (!isFTC && isRoundTrip) {
      // if choice of outbound or return slice isn't clear
      return !outboundChanged || !returnChanged;
    }

    return false;
  }
);

export const getExchangeForType = (state: IStoreState) => {
  const { exchangeFor, outboundSelection, returnSelection } =
    getSearchState(state);

  return {
    exchangeFor,
    outboundSelection,
    returnSelection,
  };
};

export const getOutboundSelection = (state: IStoreState) =>
  getSearchState(state).outboundSelection;

export const getReturnSelection = (state: IStoreState) =>
  getSearchState(state).returnSelection;

export const getPolicyHasCfar = (state: IStoreState) => {
  const policy = getPolicyStore(state);
  if (policy && "hasCfar" in policy) return policy["hasCfar"];

  return null;
};

export const getOriginalExchangeFee = (state: IStoreState): Amount => {
  const ftc = getItinerary(state)?.travelCredit;
  const policy = getPolicyStore(state)?.exchangePolicy;
  const ogChangeFee = getPolicyAttr("originalChangeFee", policy);

  return {
    amount: ogChangeFee?.amount ?? 0,
    currency: ogChangeFee?.currency ?? ftc?.credit.currency,
  };
};

export const getVoidPolicy = (state: IStoreState) => {
  return getPolicyStore(state)?.voidPolicy || null;
};

export const getGatekeeperCopy = createSelector(getExchangePolicy, (policy) => {
  const body: string[] = ["This booking is not exchangeable at this time."];
  let title = "Error";

  switch (policy?.ExchangeScenario) {
    case ExchangeScenarioEnum.AirlineControl:
      return policy.copy;
    case ExchangeScenarioEnum.BookingPending:
      title = "Booking Pending";
      body.push("This booking is pending and cannot be exchanged.");
      break;
    case ExchangeScenarioEnum.Canceled:
      title = "Flight Canceled";
      body.push("This flight is canceled and cannot be exchanged,");
      break;
    case ExchangeScenarioEnum.CancellationPending:
      title = "Cancellation Pending";
      body.push("This flight is pending cancellation and cannot be exchanged.");
      break;
    case ExchangeScenarioEnum.ContainsRemarks:
      title = "Contains Remarks";
      body.push("This flight contains remarks and cannot be exchanged.");
      break;
    case ExchangeScenarioEnum.Departed:
      title = "Flight Departed";
      body.push("This flight has already departed and cannot be exchanged.");
      break;
    case ExchangeScenarioEnum.ContactAirline:
    case ExchangeScenarioEnum.NonExchangeable:
      return policy.exchangeCopy;
    case ExchangeScenarioEnum.ContactCustomerService:
      return policy.customerServiceCopy;
    default:
  }

  return { body, title } as Copy;
});

export const getScenarioCopy = createSelector(
  getExchangeScenario,
  getVoidPolicy,
  (exchangeScenario, voidScenario) => {
    if (!exchangeScenario) return null;

    const { ExchangeScenario: exScenario } = exchangeScenario;
    const { VoidScenario: vScenario } = voidScenario;

    if (exScenario === ExchangeScenarioEnum.Ftc) {
      return exchangeScenario;
    } else if (
      (vScenario === VoidScenarioEnum.PreTicketVoidable ||
        vScenario === VoidScenarioEnum.TicketedVoid) &&
      "exchangeableScenario" in exchangeScenario
    ) {
      const { exchangeableScenario } = exchangeScenario;

      return exchangeableScenario;
    } else if (exScenario === ExchangeScenarioEnum.Exchangeable) {
      return exchangeScenario;
    } else {
      return null;
    }
  }
);

export const getConfirmPageCopy = createSelector(
  getScenarioCopy,
  (scenarioCopy: ExchangeScenario) => {
    if (!scenarioCopy) return null;

    if ("exchangeConfirmationCopy" in scenarioCopy) {
      return scenarioCopy.exchangeConfirmationCopy;
    }
  }
);

export const getImportantInfo = (state: IStoreState) =>
  getConfirmPageCopy(state)?.importantInfo;

export const getDepartureSliceFromTravelItinerary = (
  travelItinerary: TravelItinerary
): FlightItinerarySlice => {
  switch (travelItinerary?.TravelItinerary) {
    case TravelItineraryEnum.SingleTravelItinerary:
      return travelItinerary.slices[0];
    case TravelItineraryEnum.MultiTravelItinerary:
      return travelItinerary.travelItineraries[0]?.slices[0];
  }
};

//  TODO show changed itinerary times if a minor schedule change exists
export const getDepartureSlice = (
  bookedItinerary: BookedFlightItinerary
): FlightItinerarySlice => {
  return getDepartureSliceFromTravelItinerary(bookedItinerary.travelItinerary);
};

export const getReturnSliceFromTravelItinerary = (
  travelItinerary: TravelItinerary
): FlightItinerarySlice | undefined => {
  switch (travelItinerary?.TravelItinerary) {
    case TravelItineraryEnum.SingleTravelItinerary:
      return travelItinerary.slices[1];
    case TravelItineraryEnum.MultiTravelItinerary:
      return travelItinerary.travelItineraries[1]?.slices[0];
  }
};

export const getReturnSlice = (
  bookedItinerary: BookedFlightItinerary
): FlightItinerarySlice | undefined => {
  return getReturnSliceFromTravelItinerary(bookedItinerary.travelItinerary);
};

export const getExchangeType = (state: IStoreState) =>
  state.flightExchange.search;

export const getExchangeFee = (state: IStoreState) => {
  const policy = getPolicyStore(state).exchangePolicy;

  return getPolicyAttr("changeFee", policy);
};
