import { Prices } from "@b2bportal/air-shopping-api";
import { HotelItinerary } from "@b2bportal/lodging-api";
import { DateTime } from "../../index";

export interface CountryCode {
  code: string;
}

export interface DriverLicense {
  country: CountryCode;
  cityCode: string;
  street: string;
  postCode: string;
}

export interface FrequentFlyerNumber {
  value: string;
}

export interface HotelItineraryRequest {
  states: HotelItineraryState[];
  referenceDateTime: string;
}

export const HotelAncillaryEnum = {
  CfarContract: "CfarContract",
  Chfar: "Chfar",
  Insurance: "Insurance",
  LfarContract: "LfarContract",
  Weather: "Weather",
} as const;

export type HotelAncillaryEnum =
  (typeof HotelAncillaryEnum)[keyof typeof HotelAncillaryEnum];

export interface CfarContract {
  premium: Prices;
  HotelAncillary: HotelAncillaryEnum;
}

export interface Chfar {
  premium: Prices;
  HotelAncillary: HotelAncillaryEnum;
}

export interface Insurance {
  premium: Prices;
  HotelAncillary: HotelAncillaryEnum;
}

export interface LfarContract {
  premium: Prices;
  HotelAncillary: HotelAncillaryEnum;
}

export interface Weather {
  premium: Prices;
  HotelAncillary: HotelAncillaryEnum;
}

export type HotelAncillary =
  | ({ HotelAncillary: "CfarContract" } & CfarContract)
  | ({ HotelAncillary: "Chfar" } & Chfar)
  | ({ HotelAncillary: "Insurance" } & Insurance)
  | ({ HotelAncillary: "LfarContract" } & LfarContract)
  | ({ HotelAncillary: "Weather" } & Weather);

export enum HotelItineraryState {
  Canceled = "Canceled",
  Past = "Past",
  Future = "Future",
  Invalid = "Invalid",
  CancelationFailure = "CancelationFailure",
  Present = "Present",
}

export enum ContractStateEnum {
  Unspecified = "Unspecified",
  Pending = "Pending",
  Purchase = "Purchase",
  Cancel = "Cancel",
  Exercise = "Exercise",
  Amend = "Amend",
}

export interface HotelCfarContractState {
  ContractState: ContractStateEnum;
}

export interface CfarContractPremium {
  amount: Prices;
}

export interface CfarContractCoverage {
  amount: Prices;
  percentage: number;
}

export interface HotelCfarContract {
  id: string;
  purchased: DateTime;
  expired: DateTime;
  premium: CfarContractPremium;
  coverage: CfarContractCoverage;
  state?: HotelCfarContractState;
  purchasedTz?: string;
  expiredTz?: string;
}

// TODO: Import following types from lodging api
export interface Available {
  startTime: string;
  endTime: string;
  EffectiveRange: EffectiveRangeEnum;
}

export interface NotAvailable {
  EffectiveRange: EffectiveRangeEnum;
}

export type EffectiveRange =
  | ({ EffectiveRange: "Available" } & Available)
  | ({ EffectiveRange: "NotAvailable" } & NotAvailable);

export const EffectiveRangeEnum = {
  Available: "Available",
  NotAvailable: "NotAvailable",
} as const;

export type EffectiveRangeEnum =
  (typeof EffectiveRangeEnum)[keyof typeof EffectiveRangeEnum];

export interface CfarPolicy {
  perNightRefund?: Prices;
  taxExclusiveRefund: Prices;
  CancellationPolicy: CancellationPolicyEnum;
  coveragePercentage: number;
  bookingAmount: Prices;
  refundAmount: Prices;
  primaryText: string;
}

export interface NonRefundablePolicy {
  secondaryText?: string;
  effectiveRange: EffectiveRange;
  CancellationPolicy: CancellationPolicyEnum;
  primaryText: string;
  cfarInfoBannerText?: string;
}

export interface RefundablePolicy {
  secondaryText?: string;
  effectiveRange: EffectiveRange;
  CancellationPolicy: CancellationPolicyEnum;
  primaryText: string;
  cfarInfoBannerText?: string;
}

export type CancellationPolicy =
  | ({ CancellationPolicy: "Cfar" } & CfarPolicy)
  | ({ CancellationPolicy: "NonRefundable" } & NonRefundablePolicy)
  | ({ CancellationPolicy: "Refundable" } & RefundablePolicy);

export const CancellationPolicyEnum = {
  Cfar: "Cfar",
  NonRefundable: "NonRefundable",
  Refundable: "Refundable",
} as const;

export type CancellationPolicyEnum =
  (typeof CancellationPolicyEnum)[keyof typeof CancellationPolicyEnum];
