import { Typography } from "@material-ui/core";
import {
  PATH_AUTH_INVALID_SESSION,
  PATH_INVALID_SESSION,
  PATH_HOME,
} from "../../utils/urlPaths";
import "./styles.scss";
import { startSession } from "../../api/startSession";
import {
  StartSessionRequestEnum,
  LogInWithProfile,
  StartSessionSuccess,
} from "@b2bportal/commbank-au-auth-api";
import { useNavigate } from "react-router-dom-v5-compat";
import { useContext, useState } from "react";
import { SessionContext } from "../../Root";
import { B2BSpinner } from "@hopper-b2b/ui";
import { datadogRum } from "@datadog/browser-rum";

const prodTravelProfileIds = [
  // Mock User
  {
    name: "Mock User",
    value: "693dd6b6-61d3-46d5-bcdc-81fad7c27933",
  },
];

const preprodTravelProfileIds = [
  // Mock User
  {
    name: "Mock User",
    value: "693dd6b6-61d3-46d5-bcdc-81fad7c27933",
  },
  // Hopper x CBA test accounts
  {
    name: "Non Yello User",
    value: "0d351f49-f4f6-4a37-b57c-d2d80e3e1a5a",
  },
  {
    name: "Homeowner User",
    value: "ad30ae11-2a2f-4c9b-aaf8-610b1c382f4a",
  },
  {
    name: "EveryDayPlus User",
    value: "b60417d7-6639-439f-9f19-d409a10a2308",
  },
  {
    name: "EveryDay User",
    value: "108293ae-3dbe-4a34-9c58-93ab7bb32e7f",
  },
  // Collinson test accounts
  {
    name: "Collinson Test Data Set1 (THIS IS COLLINSON-EXCLUSIVE, DO NOT USE)",
    value: "a0388484-1d5f-467f-a89e-bef056515a71",
  },
  {
    name: "Collinson Test Data Set2 (THIS IS COLLINSON-EXCLUSIVE, DO NOT USE)",
    value: "bc5d9baf-becf-4ba1-8a9a-cbe83af506ef",
  },
];

export const travelProfileIds =
  window.location.host === "cba.portal.hopper.com" ||
  window.location.host === "travelbooking.hopper.com"
    ? prodTravelProfileIds
    : preprodTravelProfileIds;

export const MockCBALoginPage = () => {
  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [userId, setUserId] = useState(travelProfileIds[0].value);
  const { setSessionInfo } = useContext(SessionContext);

  const onLogin = async () => {
    let sessionInfo = null;
    // USE MOCK B2B FLOW
    // TODO enable login with different profile Ids
    setIsLoggingIn(true);
    try {
      sessionInfo = await startSession({
        travelProfileId: userId,
        StartSessionRequest: StartSessionRequestEnum.LogInWithProfile,
      } as { StartSessionRequest: "LogInWithProfile" } & LogInWithProfile);
      if (sessionInfo) {
        // eslint-disable-next-line no-case-declarations
        const info = sessionInfo as StartSessionSuccess;
        const nameParts = info?.userProfile?.displayName?.split(" ");
        if (!nameParts || nameParts.length < 1) {
          navigate(PATH_AUTH_INVALID_SESSION, { replace: true });
        }
        setSessionInfo({
          userInfo: {
            firstName: nameParts[0],
            lastName: nameParts[nameParts.length - 1],
            email: info.userProfile.email,
          },
          isFirstSession: info.isFirstSession,
          kustomerAccessToken: info.kustomerAccessToken,
          hopperUserId: info.userProfile.hopperUserId,
        });
        if (datadogRum) {
          datadogRum.setUser({
            id: info.userProfile.hopperUserId,
          });
        }
        navigate(PATH_HOME, { replace: true });
        setIsLoggingIn(false);
      } else {
        window.location.pathname = PATH_INVALID_SESSION;
        setIsLoggingIn(false);
      }
    } catch (e) {
      setIsLoggingIn(false);
      setHasError(true);
    }
  };

  return hasError ? (
    <div className="error-page">
      <h1>Something went wrong</h1>
      <button onClick={() => setHasError(false)}>Try Again</button>
    </div>
  ) : (
    <div className="mock-cba-login-page">
      {!isLoggingIn ? (
        <>
          <div className="user-select-container">
            <label for="users">Select a user:</label>
            <select
              value={userId}
              name="users"
              id="users"
              onChange={(event) => setUserId(event.target.value)}
            >
              {travelProfileIds.map((user) => {
                return <option value={user.value}>{user.name}</option>;
              })}
            </select>
          </div>
          <div>
            <button onClick={onLogin}>
              <Typography variant="h2">Log In</Typography>
            </button>
          </div>
        </>
      ) : (
        <B2BSpinner />
      )}
    </div>
  );
};
