import {
  DenyScheduleChangeRequest,
  DenyScheduleChangeResponse,
  NETWORK_CALL_FAILED,
} from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { denyScheduleChangePath } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

const denyScheduleChange = async (req: DenyScheduleChangeRequest) => {
  try {
    const res = await axiosInstance.post<DenyScheduleChangeResponse>(
      denyScheduleChangePath,
      req
    );

    if (res.data) {
      return res.data;
    }

    throw res;
  } catch (err) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        location: window.location.pathname,
      },
    });
  }
};

export default denyScheduleChange;
