import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Route, Switch, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import {
  Airline,
  BookedFlightItineraryWithDepartureTime,
} from "@b2bportal/air-booking-api";
import {
  Fare,
  FlightsFareSlice,
  Slice,
  Trip,
  TripSlice,
} from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { B2BLoadingPopup } from "@hopper-b2b/ui";
import { IContactInfo } from "@hopper-b2b/checkout";
import {
  DisruptionExerciseProductType,
  DisruptionExerciseProgress,
  DisruptionProtectionType,
  FiatPrice,
  TripDetails,
} from "@hopper-b2b/types";
import {
  getAirlineLocatorsFromBookedFlightItinerary,
  getHopperLocatorFromBookedFlightItinerary,
  parsePhoneNumber,
  removeTimezone,
  toggleAdaChat,
} from "@hopper-b2b/utilities";

import { ClientContext } from "../../../../App";
import {
  populateTripQueryParams,
  resetDisruptionExerciseState,
  setDisruptionExerciseProgress,
} from "../../../TripsList/actions/actions";
import {
  getDisruptionExerciseProductType,
  getDisruptionExerciseProgress,
} from "../../../TripsList/reducer/selectors";
import {
  PATH_DISRUPTION_EXERCISE,
  PATH_DISRUPTION_EXERCISE_CONTACT,
  PATH_DISRUPTION_EXERCISE_REBOOK_CONFIRMATION,
  PATH_DISRUPTION_EXERCISE_REBOOK_REVIEW,
  PATH_DISRUPTION_EXERCISE_REFUND,
  PATH_DISRUPTION_EXERCISE_SEARCH,
} from "../../../../utils/paths";
import { LandingPage } from "./components/LandingPage";
import { FlightSelect } from "./components/FlightSelect";
import { DisruptionRefund } from "./components/DisruptionRefund";
import { DisruptionFlightSearch } from "./components/DisruptionFlightSearch";
import { DisruptionRebookReview } from "./components/DisruptionRebookReview";
import { DisruptionRebookConfirmation } from "./components/DisruptionRebookConfirmation";
import { DisruptionContactInfoForm } from "./components/DisruptionContactInfoForm";
import "./styles.scss";

export interface IDisruptionExerciseProps {
  flight: BookedFlightItineraryWithDepartureTime;
}

export const DisruptionExercise = ({ flight }: IDisruptionExerciseProps) => {
  const { t, brand } = useI18nContext();
  const dispatch = useDispatch();
  const clientContext = useContext(ClientContext);
  const [selectedFlight, setSelectedFlight] = useState<{
    fare: Fare;
    trip: Trip;
    tripSlice: Slice;
    fareSlice: FlightsFareSlice;
    tripDetails: TripDetails;
  }>(null);
  const [refundLoading, setRefundLoading] = useState<boolean>(false);
  const [reimbursementAmount, setReimbursementAmount] =
    useState<FiatPrice | null>(null);
  const [flightSearch, setFlightSearch] = useState<{
    origin: string;
    destination: string;
    tripSlice: TripSlice;
  }>({ origin: "", destination: "", tripSlice: null });
  const [contactInfo, setContactInfo] = useState<IContactInfo>({
    email: "",
    phoneNumber: "",
    countryCode: "",
  });
  const [flightResultAirlines, setFlightResultAirlines] = useState<{
    [key: string]: Airline;
  }>(null);
  const disruptionExerciseProgress = useSelector(getDisruptionExerciseProgress);
  const disruptionProductType = useSelector(getDisruptionExerciseProductType);
  const history = useHistory();

  useEffect(() => {
    if (
      clientContext &&
      !contactInfo.phoneNumber &&
      !contactInfo.email &&
      !contactInfo.countryCode
    ) {
      const parsedPhone = parsePhoneNumber(
        clientContext?.sessionInfo?.userInfoResponse?.mobileNumber
      );
      const initialContactInfo = {
        countryCode: contactInfo.countryCode
          ? contactInfo.countryCode
          : `+${parsedPhone.countryCode || brand.preferredAreaCode}`,
        phoneNumber: contactInfo.phoneNumber
          ? contactInfo.phoneNumber
          : parsedPhone.nationalNumber,
        email: contactInfo.email
          ? contactInfo.email
          : clientContext?.sessionInfo?.userInfoResponse?.email || "",
      };
      setContactInfo(initialContactInfo);
    }
  }, [brand, clientContext, contactInfo]);

  useEffect(() => {
    return () => {
      dispatch(resetDisruptionExerciseState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loading =
    disruptionExerciseProgress === DisruptionExerciseProgress.Pending;

  const contractId = useMemo(() => {
    return flight.ancillaries[
      disruptionProductType === DisruptionExerciseProductType.ScheduleChange
        ? DisruptionProtectionType.Delay
        : DisruptionProtectionType.MissedConnection
    ]?.id?.policyId;
  }, [disruptionProductType, flight.ancillaries]);

  const airlineCodes = useMemo(() => {
    if (flight?.bookedItinerary?.travelItinerary?.slices) {
      return flight?.bookedItinerary?.travelItinerary?.slices.flatMap(
        (slice) => {
          return slice?.segments?.map((segment) => {
            return segment?.operatingAirline?.code;
          });
        }
      );
    }
    return [];
  }, [flight]);

  const onFlightSelectContinue = useCallback(
    (props: { origin: string; destination: string; tripSlice: TripSlice }) => {
      setFlightSearch(props);
      dispatch(
        setDisruptionExerciseProgress(DisruptionExerciseProgress.FlightSearch)
      );
      const newHistory = dispatch(populateTripQueryParams(history)).history;
      history.replace(
        PATH_DISRUPTION_EXERCISE_SEARCH + newHistory.location.search
      );
    },
    [dispatch, history]
  );

  const handleFlightSelect = useCallback(
    (props: {
      fare: Fare;
      trip: Trip;
      tripSlice: Slice;
      fareSlice: FlightsFareSlice;
      tripDetails: TripDetails;
    }) => {
      if (selectedFlight?.fare.id === props.fare.id) {
        setSelectedFlight(null);
      } else {
        setSelectedFlight({ ...props });
      }
    },
    [selectedFlight]
  );

  const delayOrMissedConnectionAnswer = useMemo(
    () =>
      brand?.adaChat
        ? brand?.adaChat.answerIds.delayOrMissedConnection
        : undefined,
    [brand]
  );

  const openSupportChat = useCallback(
    (flightDetails: BookedFlightItineraryWithDepartureTime) => {
      const airlineLocators =
        getAirlineLocatorsFromBookedFlightItinerary(flightDetails);
      const hopperLocator =
        getHopperLocatorFromBookedFlightItinerary(flightDetails);
      const formattedDepartureDate = dayjs(
        removeTimezone(flightDetails?.departureTime)
      ).format("MMM D, YYYY h:mm A");

      toggleAdaChat(
        {
          departure_date: formattedDepartureDate,
          pnr_locator: hopperLocator,
          airline_locator: airlineLocators,
        },
        delayOrMissedConnectionAnswer ? delayOrMissedConnectionAnswer : null
      );
    },
    [delayOrMissedConnectionAnswer]
  );

  const errorModalPrimaryOnClick = useCallback(() => {
    dispatch(resetDisruptionExerciseState());
  }, [dispatch]);

  const errorModalSecondaryOnClick = useCallback(
    (flightDetails: BookedFlightItineraryWithDepartureTime) => {
      openSupportChat(flightDetails);
      dispatch(resetDisruptionExerciseState());
    },
    [dispatch, openSupportChat]
  );

  const updateContactInfo = useCallback((changeInfo: IContactInfo) => {
    const updatedContactInfo = {
      email: changeInfo.email,
      phoneNumber: changeInfo.phoneNumber,
      countryCode: changeInfo.countryCode,
    };
    setContactInfo(updatedContactInfo);
  }, []);

  return (
    <div className="disruption-exercise">
      <B2BLoadingPopup
        open={loading}
        fullScreen={true}
        message={t("disruptionExercise.loadingMessage")}
        popupSize="mobile"
      />
      <Switch>
        <Route exact path={PATH_DISRUPTION_EXERCISE}>
          <FlightSelect
            contractId={contractId}
            flight={flight}
            onFlightSelectContinue={onFlightSelectContinue}
          />
        </Route>
        <Route exact path={PATH_DISRUPTION_EXERCISE_SEARCH}>
          <DisruptionFlightSearch
            {...flightSearch}
            contractId={contractId}
            handleFlightSelect={handleFlightSelect}
            setFlightResultAirlines={setFlightResultAirlines}
          />
        </Route>
        <Route exact path={PATH_DISRUPTION_EXERCISE_CONTACT}>
          <DisruptionContactInfoForm
            contactInfo={contactInfo}
            updateContactInfo={updateContactInfo}
          />
        </Route>
        <Route exact path={PATH_DISRUPTION_EXERCISE_REFUND}>
          <DisruptionRefund
            flight={flight}
            confirmation={
              disruptionExerciseProgress ===
              DisruptionExerciseProgress.RefundConfirmation
            }
            contractId={contractId}
            itineraryId={flight.bookedItinerary.id}
            loading={refundLoading}
            setLoading={setRefundLoading}
            reimbursementAmount={reimbursementAmount}
            errorModalPrimaryOnClick={errorModalPrimaryOnClick}
            errorModalSecondaryOnClick={errorModalSecondaryOnClick}
          />
        </Route>
        <Route exact path={PATH_DISRUPTION_EXERCISE}>
          <FlightSelect
            contractId={contractId}
            flight={flight}
            onFlightSelectContinue={onFlightSelectContinue}
          />
        </Route>
        <Route exact path={PATH_DISRUPTION_EXERCISE_REBOOK_REVIEW}>
          <DisruptionRebookReview
            selectedRebookFlight={selectedFlight}
            airlineMap={flightResultAirlines}
          />
        </Route>
        <Route exact path={PATH_DISRUPTION_EXERCISE_REBOOK_CONFIRMATION}>
          <DisruptionRebookConfirmation
            flight={flight}
            contractId={contractId}
            selectedFlight={selectedFlight}
            errorModalPrimaryOnClick={errorModalPrimaryOnClick}
            errorModalSecondaryOnClick={errorModalSecondaryOnClick}
            contactInfo={contactInfo}
          />
        </Route>
        <LandingPage
          contractId={contractId}
          airlineCodes={airlineCodes}
          loading={refundLoading}
          setLoading={setRefundLoading}
          setReimbursementAmount={setReimbursementAmount}
        />
      </Switch>
    </div>
  );
};
