import { axiosInstance } from "@hopper-b2b/api";
import {
  type AvailabilityResponse,
  lodgingApi,
  type Place,
  type LodgingIds,
  AvailabilityRequestEnum,
  LodgingCollection,
  DateRange,
  RoomsSelection,
  GuestsSelection,
  Platform,
  Location,
} from "@b2bportal/lodging-api";
import { IApiConfig } from "@hopper-b2b/types";

interface AvailabilityQuery {
  stayDates: DateRange;
  guests: GuestsSelection;
  rooms: RoomsSelection;
  platform: Platform;
}

export interface AvailabilityByPlaceQuery extends AvailabilityQuery {
  lodgingSelection: { LodgingSelection: "Place" } & Place;
}

export interface AvailabilityByLodgingQuery extends AvailabilityQuery {
  lodgingSelection: { LodgingSelection: "LodgingIds" } & LodgingIds;
}

export interface AvailabilityByLocationQuery extends AvailabilityQuery {
  lodgingSelection: { LodgingSelection: "Location" } & Location;
}

export const fetchAvailabilityByPlaceAPI = async (
  query: AvailabilityByPlaceQuery,
  apiConfig?: IApiConfig
): Promise<AvailabilityResponse> => {
  if (!query.stayDates?.from || !query.stayDates?.until) {
    throw new Error("stayDates.from and stayDates.until are required");
  }
  const body = {
    progressiveConfig: {},
    excludeNonLuxuryLodgings: false,
    AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
    lodgingCollection: LodgingCollection.NoCollection,
    ...query,
  };
  return new Promise((resolve, reject) => {
    try {
      lodgingApi(axiosInstance)
        .apiV0HotelsAvailabilityPost(body)
        .then((res) => resolve(res.data));
    } catch (e) {
      reject(e);
    }
  });
};

export const fetchAvailabilityNextPage = async (
  token: string
): Promise<AvailabilityResponse> => {
  const body = {
    progressiveConfig: {},
    excludeNonLuxuryLodgings: false,
    AvailabilityRequest: AvailabilityRequestEnum.FollowUpSearch,
    moreToken: token,
    lodgingCollection: LodgingCollection.NoCollection,
  };
  return new Promise((resolve, reject) => {
    try {
      lodgingApi(axiosInstance)
        .apiV0HotelsAvailabilityPost(body)
        .then((res) => resolve(res.data));
    } catch (e) {
      reject(e);
    }
  });
};

export const fetchAvailabilityByLodgingId = async (
  query: AvailabilityByLodgingQuery,
  apiConfig?: IApiConfig
): Promise<AvailabilityResponse> => {
  if (!query.stayDates?.from || !query.stayDates?.until) {
    throw new Error("stayDates.from and stayDates.until are required");
  }
  const body = {
    progressiveConfig: {},
    excludeNonLuxuryLodgings: false,
    AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
    lodgingCollection: LodgingCollection.NoCollection,
    ...query,
  };
  return new Promise((resolve, reject) => {
    try {
      lodgingApi(axiosInstance)
        .apiV0HotelsAvailabilityPost(body)
        .then((res) => resolve(res.data));
    } catch (e) {
      reject(e);
    }
  });
};

export const fetchAvailabilityByLocation = async (
  query: AvailabilityByLocationQuery
): Promise<AvailabilityResponse> => {
  if (!query.stayDates?.from || !query.stayDates?.until) {
    throw new Error("stayDates.from and stayDates.until are required");
  }
  const body = {
    progressiveConfig: {},
    excludeNonLuxuryLodgings: false,
    AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
    lodgingCollection: LodgingCollection.NoCollection,
    ...query,
  };
  return new Promise((resolve, reject) => {
    try {
      lodgingApi(axiosInstance)
        .apiV0HotelsAvailabilityPost(body)
        .then((res) => resolve(res.data));
    } catch (e) {
      reject(e);
    }
  });
};
