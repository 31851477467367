import { putResolve, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { closeSession } from "../../../../api/v1/book/session/closeSession";
import { IStoreState } from "../../../../reducers/types";
import { getSession } from "../../reducer";
import { flightApiConfigStoreKey } from "../../../../reducers/types";

export function* closeSessionSaga() {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const session = getSession(state);
    if (!session) {
      throw new Error("Session token is not present.");
    }
    yield closeSession(session, apiConfig);
    yield putResolve(actions.closeSessionCallStateSuccess());
  } catch (e) {
    yield putResolve(actions.closeSessionCallStateFailed());
  }
}
