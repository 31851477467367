import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../../../reducers/types";
import {
  getAcceptScheduleChangeCallState,
  getDenyScheduleChangeCallState,
  getViewedMajorScheduleChangeProperties,
  getAirlinesMap,
  getAirportMap,
} from "../../../../../../reducer";
import {
  setOpenModal,
  acceptScheduleChange,
  denyScheduleChange,
} from "../../../../../../actions/actions";

import { ScheduleChangeModalContent } from "./component";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state: IStoreState) => ({
  airlineMap: getAirlinesMap(state),
  airportMap: getAirportMap(state),
  acceptState: getAcceptScheduleChangeCallState(state),
  denyState: getDenyScheduleChangeCallState(state),
  viewedMajorScheduleChangeProperties:
    getViewedMajorScheduleChangeProperties(state),
});

const mapDispatchToProps = {
  setOpenModal,
  acceptScheduleChange,
  denyScheduleChange,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ScheduleChangeModalContentConnectorProps = ConnectedProps<
  typeof connector
>;

/**
 * @deprecated Component to be implemented in the libs/self-serve folder
 */
export const ConnectedScheduleChangeModalContent = connector(
  withRouter(ScheduleChangeModalContent)
);
