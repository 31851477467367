import { Checkout, ParentState } from "@hopper-b2b/checkout";
import { CheckoutProps } from "@hopper-b2b/types";
import { useFeatureFlagsContext } from "@hopper-b2b/utilities";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  getFromDate,
  getGuests,
  getLocation,
  getUntilDate,
} from "../availability/reducer/selectors";
import {
  getSelectedLodging,
  getSelectedRoom,
  getSelectedRoomRateId,
} from "../shop/reducer/selectors";
import { getSelectedCreditOffer, getSelectedOffer } from "../wallet/reducer";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useDocumentTitle } from "../../../../hooks/src/useDocumentTitle";

export const LodgingCheckout = ({
  actions,
  clientAssets,
  Component,
  context,
  getInitialContext,
  guards,
  onCleanUp,
  onLoad,
  onPathnameChange,
  onStateValueChange,
  services,
  sessionInfo,
  stateMachine,
  validateContext,
}: CheckoutProps) => {
  const featureFlags = useFeatureFlagsContext();

  const fromDate = useSelector(getFromDate);
  const untilDate = useSelector(getUntilDate);
  const guests = useSelector(getGuests);

  const selectedLodging = useSelector(getSelectedLodging);
  const selectedRoom = useSelector(getSelectedRoom);
  const selectedRoomRateId = useSelector(getSelectedRoomRateId);
  const location = useSelector(getLocation);

  const selectedOffer = useSelector(getSelectedOffer);
  const selectedCreditOffer = useSelector(getSelectedCreditOffer);

  const { t } = useI18nContext();
  useDocumentTitle(t("paymentStepTitle"));

  const contextToInitialize = useMemo(
    () => ({
      ...context,
      featureFlags,
      sessionInfo: {
        ...(context?.sessionInfo || {}),
        ...sessionInfo,
      },
      lodgingShop: {
        selectedLodging: selectedLodging,
        selectedRoom: selectedRoom,
        selectedRoomRateId: selectedRoomRateId,
        fromDate,
        untilDate,
        guests,
        location,
      },
      [ParentState.wallet]: {
        offer: selectedOffer,
        creditOffer: selectedCreditOffer,
      },
    }),
    [
      context,
      featureFlags,
      fromDate,
      selectedCreditOffer,
      selectedLodging,
      selectedOffer,
      selectedRoom,
      selectedRoomRateId,
      sessionInfo,
      untilDate,
    ]
  );

  return (
    <Checkout
      actions={actions}
      Component={Component}
      context={contextToInitialize}
      guards={guards}
      onCleanUp={onCleanUp}
      onLoad={onLoad}
      services={services}
      stateMachine={stateMachine}
      getInitialContext={getInitialContext}
      clientAssets={clientAssets}
      onStateValueChange={onStateValueChange}
      onPathnameChange={onPathnameChange}
      validateContext={validateContext}
    />
  );
};
