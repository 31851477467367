import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import clsx from "clsx";
import { ReactElement, useEffect, useState } from "react";
import { GenericDropdown } from "../GenericDropdown";
import "./styles.scss";

export interface IMultiSelectDropdownOption {
  label: string;
  value: string;
}

export interface IMultiSelectDropdownProps {
  options: IMultiSelectDropdownOption[];
  dropdownLabel: string;
  setValue: (value: string[]) => void;
  currentValue: string[];
  showDropdownContentOnly?: boolean;
  className?: string;
  popoverClassName?: string;
}

export const MultiSelectDropdown = ({
  dropdownLabel,
  setValue,
  currentValue: currentValueProp,
  options,
  showDropdownContentOnly,
  className,
  popoverClassName,
}: IMultiSelectDropdownProps): ReactElement => {
  const [currentValue, setCurrentValue] = useState<string[]>(
    currentValueProp || []
  );

  const handleChange = (newValue: string) => {
    if (isChecked(newValue)) {
      const selectedAfterRemove = currentValue.filter(
        (value) => value !== newValue
      );
      setCurrentValue(selectedAfterRemove);
      setValue(selectedAfterRemove);
    } else {
      const newSelected = [...currentValue, newValue];
      setCurrentValue(newSelected);
      setValue(newSelected);
    }
  };

  const isChecked = (option: string): boolean => {
    return currentValue.some(
      (currentOption: string) => option === currentOption
    );
  };

  useEffect(() => {
    setCurrentValue(currentValueProp);
  }, [currentValueProp]);

  const renderOptions = () => {
    return options.map((option: IMultiSelectDropdownOption) => (
      <FormControlLabel
        key={option.value}
        control={
          <Checkbox
            checked={isChecked(option.value)}
            onChange={() => handleChange(option.value)}
            value={option}
          />
        }
        labelPlacement={"end"}
        label={option.label}
      />
    ));
  };

  const renderDropdownContent = () => {
    return (
      <Box className={"multiselect-dropdown-multiselect-container"}>
        <FormControl component="fieldset">
          <FormGroup>{renderOptions()}</FormGroup>
        </FormControl>
      </Box>
    );
  };

  return (
    <>
      {!showDropdownContentOnly && (
        <GenericDropdown
          buttonClassName={clsx("b2b-shop-filter", className)}
          popoverClassName={clsx("multiselect-popover", popoverClassName)}
          ariaLabel={"Multiselect Dropdown"}
          dropdownLabel={dropdownLabel}
          dropdownContent={renderDropdownContent()}
        />
      )}
      {!!showDropdownContentOnly && renderDropdownContent()}
    </>
  );
};
