import { FlightItinerarySlice } from "@b2bportal/air-booking-api";
import { ExchangeActionEnum } from "@b2bportal/air-exchange-api";
import {
  Airline,
  ChoiceButton,
  FlightDuration,
  FlightTiming,
  StopSummary,
} from "@hopper-b2b/ui";
import { formatInterval } from "@hopper-b2b/utilities";
import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { last } from "lodash-es";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { getAirlines, getAirports } from "../../selectors";

import "./styles.scss";

export interface ISlicePickerOptionProps {
  disabled?: boolean;
  isToggled: boolean;
  onToggle: (state: ExchangeActionEnum) => void;
  slice: FlightItinerarySlice;
}

const defaultProps: Partial<ISlicePickerOptionProps> = {
  disabled: false,
};

const SlicePickerOption = (props: ISlicePickerOptionProps): JSX.Element => {
  const {
    disabled,
    isToggled,
    onToggle,
    slice: { segments },
  } = props;
  const {
    marketingAirline: { code: airline },
    origin,
    scheduledDeparture,
    updatedDeparture,
  } = segments[0];
  const {
    destination: dest,
    scheduledArrival,
    updatedArrival,
  } = last(segments);
  const airlines = useSelector(getAirlines);
  const airports = useSelector(getAirports);
  const arrival = updatedArrival || scheduledArrival;
  const departure = updatedDeparture || scheduledDeparture;

  const { duration, stops } = useMemo(() => {
    const stops: string[] = [];
    let duration = 0;

    for (let i = 0; i < segments.length; i += 1) {
      const {
        destination,
        scheduledArrival,
        scheduledDeparture,
        updatedArrival,
        updatedDeparture,
      } = segments[i];
      const segArrival = updatedArrival || scheduledArrival;
      const segDeparture = updatedDeparture || scheduledDeparture;

      if (i < segments.length - 1) {
        // ignore last segment's destination since it's not a layover
        stops.push(destination.locationCode);
      }

      duration += dayjs(segArrival).diff(segDeparture, "minutes");
    }

    return { duration, stops };
  }, [segments]);

  return (
    <Grid container className="exchange-slice-option">
      <Grid item className="flight-card-left">
        <Airline iconOnly code={airline} name={airlines[airline].displayName} />
      </Grid>
      <Grid item xs className="flight-card-center">
        <Typography className="slice-cities-summary primary-text">
          {`${
            airports[origin.locationCode].cityName ?? origin.locationCode
          } to ${airports[dest.locationCode].cityName ?? dest.locationCode}`}
        </Typography>
        <Typography className="slice-timing-summary secondary-text">
          <FlightTiming arrivalTime={arrival} departureTime={departure} />
        </Typography>
        <div className="slice-duration-stops-summary secondary-text">
          <FlightDuration duration={formatInterval(duration)} />
          <div className="stops-container">
            {" ("}
            <StopSummary hideAirports airports={stops} />
            {")"}
          </div>
        </div>
      </Grid>
      <Grid item className="flight-card-right">
        <ChoiceButton
          ariaLabel={`${isToggled ? "Keep" : "Change"} flight`}
          className={clsx("square-checkbox", {
            checked: isToggled,
          })}
          disabled={disabled}
          isChosen={isToggled}
          message=""
          onClick={() =>
            onToggle(
              isToggled ? ExchangeActionEnum.Keep : ExchangeActionEnum.Change
            )
          }
        />
      </Grid>
    </Grid>
  );
};

SlicePickerOption.defaultProps = defaultProps;

export default SlicePickerOption;
