import {
  PaymentBreakdown,
  PaymentLineItem,
  PaymentLineItemEnum,
  PaymentLineItemUserCard,
  PaymentLineItemNequiAccount,
  PaymentLineItemTravelWalletEnum,
  TravelWalletOffer,
  WalletOfferType,
  PaymentLineItemUberCredit,
  PaymentLineItemRewards,
} from "@b2bportal/air-booking-api";
import {
  FlightItinerarySlice,
  SummaryLineItemBaseEnum,
} from "@hopper-b2b/types";
import { MultiProvider } from "@b2bportal/air-cancel-api";

import { ILineItem } from "../components/ItineraryList/components/FlightCard/helpers";

// mixedCabin is true if the following conditions are met:
// - fareShelf rating of 3 or above AND one of the below:
// - the fareShelf shortBrandName is PREMIUM ECONOMY AND one of the segment has a cabinClassName of ECONOMY
// - the shortBrandName is FIRST or BUSINESS (not premium economy) AND one of the segment includes the word "Economy" (PREMIUM ECONOMY/ECONOMY)
// Mote: fareShelf checking using the shortBrandName instead of the cabinClassName because the cabinClassName mapping is currently incorrect in same cases (we were getting back a cabinClassName of FIRST instead of PREMIUM ECONOMY)

export const getIsMixedClass = (slice: FlightItinerarySlice): boolean => {
  if (
    !slice.fareShelf ||
    slice.fareShelf.rating < 3 ||
    slice.segments.length <= 1
  ) {
    return false;
  }
  const lookupCabinClass = slice.segments[0].cabinClassName;
  // if the cabin class name is only a letter
  // we should just compare whether all cabin classes are the same
  if (lookupCabinClass && lookupCabinClass.length === 1) {
    return slice.segments.every(
      (segment) => segment.cabinClassName === lookupCabinClass
    );
  } else {
    return slice.segments.some(
      (segment) =>
        segment.cabinClassName &&
        (slice.fareShelf?.shortBrandName === "Premium" &&
        !slice.fareShelf?.brandName.includes("First")
          ? segment.cabinClassName === "Economy"
          : segment.cabinClassName.includes("Economy"))
    );
  }
};

export const getLineItems = (
  t: any,
  paymentBreakdown?: PaymentBreakdown
): Array<ILineItem> => {
  const toLineItem = (item: PaymentLineItem): ILineItem | undefined => {
    switch (item.PaymentLineItem) {
      case PaymentLineItemEnum.UserCard: {
        const userCard = item as PaymentLineItemUserCard;
        const lineItem: ILineItem = {
          PaymentLineItem: PaymentLineItemEnum.UserCard,
          label: userCard.cardNumberDisplay,
          value: userCard.amount.amount,
          currency: userCard.amount.currency,
          type: SummaryLineItemBaseEnum.PAYMENT,
        };
        return lineItem;
      }
      case PaymentLineItemEnum.Rewards: {
        const userCard = item as PaymentLineItemRewards;
        const lineItem: ILineItem = {
          PaymentLineItem: PaymentLineItemEnum.Rewards,
          label: userCard.accountDisplayName,
          value: userCard.amount.rewardsPrice.value,
          currency: userCard.amount.rewardsPrice.currency,
          type: SummaryLineItemBaseEnum.PAYMENT,
        };
        return lineItem;
      }
      case PaymentLineItemEnum.NequiAccount: {
        const nequiAccount = item as PaymentLineItemNequiAccount;
        const lineItem: ILineItem = {
          PaymentLineItem: PaymentLineItemEnum.NequiAccount,
          label: nequiAccount.pocketName,
          value: nequiAccount.amount.amount,
          currency: nequiAccount.amount.currency,
        };
        return lineItem;
      }
      case PaymentLineItemEnum.UberCredit: {
        const { label, amount, PaymentLineItem } =
          item as PaymentLineItemUberCredit;
        const lineItem: ILineItem = {
          PaymentLineItem,
          label,
          value: amount.amount,
          currency: amount.currency,
        };
        return lineItem;
      }
      case PaymentLineItemEnum.TravelWallet: {
        switch (item.TravelWallet) {
          case PaymentLineItemTravelWalletEnum.TravelWalletCredit: {
            const lineItem: ILineItem = {
              PaymentLineItem: PaymentLineItemEnum.TravelWallet,
              label: t("creditPaymentText"),
              value: item.amount.amount,
              type: SummaryLineItemBaseEnum.CREDIT_PAYMENT,
              currency: item.amount.currency,
            };
            return lineItem;
          }
          case PaymentLineItemTravelWalletEnum.TravelWalletOffer:
          default: {
            const offer = item as TravelWalletOffer;
            switch (offer.offerType) {
              case WalletOfferType.Promotion: {
                const lineItem: ILineItem = {
                  PaymentLineItem: PaymentLineItemEnum.TravelWallet,
                  label: t("promotionDiscountText"),
                  value: item.amount.amount,
                  type: SummaryLineItemBaseEnum.PROMOTION_DISCOUNT,
                  currency: item.amount.currency,
                };
                return lineItem;
              }
              case WalletOfferType.Voucher:
              default: {
                const lineItem: ILineItem = {
                  PaymentLineItem: PaymentLineItemEnum.TravelWallet,
                  label: t("voucherDiscountText"),
                  value: item.amount.amount,
                  type: SummaryLineItemBaseEnum.VOUCHER_DISCOUNT,
                  currency: item.amount.currency,
                };
                return lineItem;
              }
            }
          }
        }
      }
      default: {
        return undefined;
      }
    }
  };

  return paymentBreakdown?.payments
    ? paymentBreakdown.payments
        .map((payment) => {
          const item = toLineItem(payment);
          if (item?.value && item.value >= 0) {
            return item;
          } else {
            return undefined;
          }
        })
        .map((i) => i)
    : [];
};

export const getMultiProviderAirlineMap = (scenario: MultiProvider) => {
  const { subTicketPolicies } = scenario;
  const bookingAirlines = subTicketPolicies.flatMap((stp) => stp.airlines);
  // build an airline map of airlines in multi-provider ticket policies
  const airlines = bookingAirlines.reduce((acc, airline) => {
    acc[airline.code] = {
      ...airline,
      displayName: airline.name,
    };

    return acc;
  }, {});

  return airlines;
};
