import clsx from "clsx";

import { Icon, IconName } from "@hopper-b2b/ui";
import { Box } from "@material-ui/core";
import { useI18nContext, Trans } from "@hopper-b2b/i18n";
import { Link } from "@material-ui/core";
import { useDeviceTypes } from "@hopper-b2b/utilities";

import "./TravelInsuranceInfoSection.styles.scss";
import { FIND_OUT_MORE_LINK } from "./url";

export const TravelInsuranceInfoSection = () => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  return (
    <>
      <Box className={clsx("info-banner-root", { mobile: matchesMobile })}>
        <div className={"icon-container"}>
          <Icon name={IconName.InfoCircleFill} />
        </div>
        <div className={"content-container"}>
          <div className={"content-title"}>
            {t("cfarOffers.infoBanner.title")}
          </div>
          <Trans
            i18nKey={"cfarOffers.infoBanner.travelInsuranceDescription"}
            components={[
              <Link
                style={{
                  color: "var(--utilityBlue)",
                  textDecoration: "underline",
                }}
                href={FIND_OUT_MORE_LINK}
                target="_blank"
              />,
            ]}
          />
        </div>
      </Box>
    </>
  );
};
