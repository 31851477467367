import { OutlinedInputProps, TextField } from "@material-ui/core";
import clsx from "clsx";
import "./ApacTextField.styles.scss";

export interface IApacTextFieldProps {
  value: string | number;
  placeholder?: string;
  onChange?: OutlinedInputProps["onChange"];
  className?: string;
  disabled?: boolean;
  type?: React.InputHTMLAttributes<unknown>["type"];
}

export const ApacTextField = ({
  value,
  onChange,
  placeholder,
  className,
  type,
  disabled,
  ...rest
}: IApacTextFieldProps) => {
  return (
    <TextField
      type={type}
      className={clsx(className, "apac-textfield")}
      value={value}
      required
      size="small"
      placeholder={placeholder}
      variant="outlined"
      onChange={onChange}
      disabled={disabled}
      {...rest}
    />
  );
};
