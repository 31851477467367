import { useSelector } from "react-redux";

import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom-v5-compat";

import {
  useDeviceTypes,
  useEnableWallet,
  useIsSessionAuthenticated,
} from "@hopper-b2b/utilities";
import {
  GuestsSelection,
  LodgingShopQueryParams,
  LodgingShopSteps,
} from "@hopper-b2b/types";
import { useCallback, useMemo } from "react";
import { getCurrentStep } from "../reducer/selectors";
import { CHECKOUT_PATH } from "../../checkout/constants";
import { URL_PARAM_KEYS } from "@hopper-b2b/lodging-utils";

export const useGoToShopLodgingDetailsStep = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [search] = useSearchParams();
  return useCallback(
    (
      replace = false,
      checkinDate: string = undefined,
      checkoutDate: string = undefined,
      guestCount: GuestsSelection = undefined
    ) => {
      const updatedStepSearch = new URLSearchParams(search);
      updatedStepSearch.set(
        LodgingShopQueryParams.SHOP_STEP,
        LodgingShopSteps.LODGING_DETAILS
      );
      if (checkinDate && checkoutDate) {
        updatedStepSearch.set(URL_PARAM_KEYS.FROM_DATE, checkinDate);
        updatedStepSearch.set(URL_PARAM_KEYS.UNTIL_DATE, checkoutDate);
      }
      if (guestCount) {
        updatedStepSearch.set(
          URL_PARAM_KEYS.ADULTS_COUNT,
          guestCount.adults.toString()
        );
        updatedStepSearch.set(
          URL_PARAM_KEYS.CHILDREN_COUNT,
          guestCount.children.length.toString()
        );
        updatedStepSearch.set(
          URL_PARAM_KEYS.ROOMS_COUNT,
          guestCount.rooms.toString()
        );
      }

      navigate(
        { pathname: location.pathname, search: updatedStepSearch.toString() },
        { replace }
      );
    },
    [location.pathname, navigate, search]
  );
};

export const useGoToNextStep = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [search] = useSearchParams();

  const isAuthenticated = useIsSessionAuthenticated();
  const enableWallet = useEnableWallet();

  const currentStep = useSelector(getCurrentStep);

  const { matchesMobile: isMobile } = useDeviceTypes();

  const maybeAddRoomSelectionStep = useMemo(() => {
    return isMobile ? [LodgingShopSteps.ROOM_SELECTION] : [];
  }, [isMobile]);

  const maybeAddCfarStep = useMemo(() => {
    //TODO
    return [];
  }, []);

  const maybeAddFintechSelectionStep = useMemo(() => {
    //TODO
    return [];
  }, []);

  const maybeAddWalletStep = useMemo(() => {
    return enableWallet && isAuthenticated
      ? [LodgingShopSteps.WALLET_OFFERS_SELECTION]
      : [];
  }, [enableWallet, isAuthenticated]);

  const steps = useMemo(() => {
    return [
      LodgingShopSteps.LODGING_DETAILS,
      ...maybeAddRoomSelectionStep,
      ...maybeAddFintechSelectionStep,
      ...maybeAddCfarStep,
      ...maybeAddWalletStep,
    ];
  }, [
    maybeAddCfarStep,
    maybeAddRoomSelectionStep,
    maybeAddFintechSelectionStep,
    maybeAddWalletStep,
  ]);

  const currentStepIndex = steps.findIndex((s) => s === currentStep);
  const nextStepIndex = currentStepIndex + 1;

  return useCallback(
    (replace = false) => {
      if (nextStepIndex < steps.length) {
        const updatedStepSearch = new URLSearchParams(search);
        updatedStepSearch.set(
          LodgingShopQueryParams.SHOP_STEP,
          steps[nextStepIndex]
        );

        navigate(
          { pathname: location.pathname, search: updatedStepSearch.toString() },
          { replace }
        );
      } else {
        navigate(CHECKOUT_PATH, { replace });
      }
    },
    [nextStepIndex, steps, search, navigate, location.pathname]
  );
};
