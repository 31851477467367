import { Component } from "react";
import * as H from "history";
import { PATH_HOME_SEARCH } from "../../utils/urlPaths";

interface ErrorBoundaryProps {
  history: H.History<H.History.LocationState>;
  children: JSX.Element;
}

class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
    this.reset = this.reset.bind(this);
  }

  static getDerivedStateFromError(error: any) {
    console.error(error);
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Log error
    console.error(error, errorInfo);
  }

  reset() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      console.warn("ERROR: ErrorBoundary hit");
      // Render custom fallback UI below and remove redirect
      this.props.history.push(PATH_HOME_SEARCH);
      this.reset();
      return null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
