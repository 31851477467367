import { ApacIconComponent, ApacIconName } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import "./styles.scss";

export const AccessDenied = () => {
  const { t } = useI18nContext();
  return (
    <div className={clsx("cba-access-denied-content")}>
      <ApacIconComponent className="error-icon" name={ApacIconName.Info} />
      <Typography variant="h2">{t("commBank.accessDenied")}</Typography>
      <Typography variant="body1">
        {t("commBank.accessDeniedSubtitle")}
      </Typography>
    </div>
  );
};

export default AccessDenied;
