import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import {
  AirExchangeAirline,
  AirExchangeEligibilityQuoteSuccess,
  Copy,
  ExchangeScenarioEnum,
  FtcEnum,
  VoidScenarioEnum,
} from "@b2bportal/air-exchange-api";
import { fetchFlightExchangePolicy, trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { IConfirmationNumber, SelfServeEvents } from "@hopper-b2b/types";
import {
  DetailHalfSheet,
  ErrorPopup,
  MobilePopoverCard,
  Slot,
  Spinner,
} from "@hopper-b2b/ui";
import { toggleAdaChat, uberOpenExternalLink } from "@hopper-b2b/utilities";
import "./styles.scss";

export enum ExchangeModalState {
  ContactAirline,
  ContactSupport,
  IneligibleForExchange,
  ItineraryNotFound,
  LoadFailed,
  LoadingOrProcessing,
  TicketedVoidable,
  TooManyAttemptsSupport,
}

export interface IExchangeFlightModalContentProps extends RouteComponentProps {
  flight: BookedFlightItineraryWithDepartureTime;
  onClose: () => void;
  open: boolean;
  openSelfServeCancel: () => void;
  confirmationNumbers?: IConfirmationNumber[];
}

const defaultProps: Partial<IExchangeFlightModalContentProps> = {};

const ExchangeFlightModalContent = (
  props: IExchangeFlightModalContentProps
): JSX.Element => {
  const { flight, onClose, open, openSelfServeCancel, confirmationNumbers } =
    props;
  const history = useHistory();
  const { t } = useI18nContext();
  const [exchangePolicy, setExchangePolicy] =
    useState<AirExchangeEligibilityQuoteSuccess>();
  const modalDisclaimerRef = useRef("");
  const modalSubtitleRef = useRef("");
  const modalTitleRef = useRef("");
  const [modalState, setModalState] = useState(
    ExchangeModalState.LoadingOrProcessing
  );

  const contactCustomerSupport = useCallback(() => {
    trackEvent({
      eventName: SelfServeEvents.ClickSupport,
      properties: {},
    });

    onClose();
    toggleAdaChat();
  }, [onClose]);

  const goToExchange = useCallback(() => {
    onClose();
    history.push({
      pathname: "/flights/exchange",
      search: `?bookingId=${flight.bookedItinerary.id}`,
    });
  }, [onClose, flight.bookedItinerary.id, history]);

  const setScenarioCopy = useCallback((copy: Copy) => {
    const { body = [], disclaimer = "", title = "" } = copy;

    modalDisclaimerRef.current = disclaimer;
    modalSubtitleRef.current = body.join(" ");
    modalTitleRef.current = title;
  }, []);

  useEffect(() => {
    if (!exchangePolicy) return;

    const { exchangeScenario, voidScenario } = exchangePolicy;

    if (
      voidScenario?.VoidScenario === VoidScenarioEnum.PreTicketVoidable ||
      voidScenario?.VoidScenario === VoidScenarioEnum.TicketedVoid
    ) {
      modalSubtitleRef.current = t("flightCancelledSubtitle");
      modalTitleRef.current = t("flightCancelledTitle");
      return setModalState(ExchangeModalState.TicketedVoidable);
    }

    const { ExchangeScenario: scenario } = exchangeScenario;

    switch (scenario) {
      case ExchangeScenarioEnum.AirlineControl:
      case ExchangeScenarioEnum.ContactAirline:
      case ExchangeScenarioEnum.NonExchangeable:
        if ("copy" in exchangeScenario) {
          setScenarioCopy(exchangeScenario.copy);
        } else if ("exchangeCopy" in exchangeScenario) {
          setScenarioCopy(exchangeScenario.exchangeCopy);
        }

        setModalState(ExchangeModalState.ContactAirline);
        break;
      case ExchangeScenarioEnum.Canceled:
      case ExchangeScenarioEnum.CancellationPending:
        modalSubtitleRef.current = t("notExchangeable.canceled.subtitle");
        modalTitleRef.current = t("notExchangeable.canceled.title");

        setModalState(ExchangeModalState.IneligibleForExchange);
        break;
      case ExchangeScenarioEnum.Ftc: {
        if (exchangeScenario.Ftc === FtcEnum.FtcAvailable) {
          return goToExchange();
        } else {
          return setModalState(ExchangeModalState.ContactSupport);
        }
      }
      case ExchangeScenarioEnum.BookingPending:
      case ExchangeScenarioEnum.ContainsRemarks:
      case ExchangeScenarioEnum.ContactCustomerService:
      case ExchangeScenarioEnum.Departed:
        if ("customerServiceCopy" in exchangeScenario) {
          setScenarioCopy(exchangeScenario?.customerServiceCopy);
        } else if (scenario === ExchangeScenarioEnum.Departed) {
          modalSubtitleRef.current = t("notExchangeable.departed.subtitle");
          modalTitleRef.current = t("notExchangeable.departed.title");
        }

        setModalState(ExchangeModalState.ContactSupport);
        break;
      case ExchangeScenarioEnum.Exchangeable:
        return goToExchange();
      default:
        setModalState(ExchangeModalState.LoadFailed);
    }
  }, [exchangePolicy, goToExchange, setScenarioCopy, t]);

  const getVoidableSubtitle = useCallback(
    (voidEnd?: number) => {
      const VOID_BY_DATE_FMT = "ddd, MMM Do [at] h:mm A"; // Sat, Jan 1st at 5:30 PM
      if (voidEnd) {
        const endDate = dayjs(voidEnd).format(VOID_BY_DATE_FMT);
        return t("voidableSubtitleEndDate", { endDate });
      }
      return t("voidableSubtitleDefault");
    },
    [t]
  );

  const getExchangePolicy = useCallback(async () => {
    try {
      const { id: reservationId } = flight.bookedItinerary;
      if (!reservationId) throw new Error();
      setModalState(ExchangeModalState.LoadingOrProcessing);

      const policyRes: AirExchangeEligibilityQuoteSuccess =
        await fetchFlightExchangePolicy(reservationId);

      setExchangePolicy(policyRes);
    } catch (e) {
      setModalState(ExchangeModalState.LoadFailed);
    }
  }, [flight]);

  useEffect(() => {
    if (open) {
      getExchangePolicy();
    }
  }, [getExchangePolicy, open]);

  let airline1: AirExchangeAirline;
  let airline2: AirExchangeAirline;

  const redirectToAirline = useCallback(() => {
    onClose();
    uberOpenExternalLink(
      airline1?.webLinks?.changeCancelPolicy ||
        airline1?.webLinks?.homePage ||
        ""
    );
  }, []);

  if (!open) return null;

  let voidScenario, exchangeScenario;
  let voidWindowEnd = 0;

  if (exchangePolicy) {
    voidScenario = exchangePolicy.voidScenario;
    exchangeScenario = exchangePolicy.exchangeScenario;
  }

  if (exchangeScenario && "airlines" in exchangeScenario) {
    [airline1, airline2] = exchangeScenario.airlines;
  }

  if (voidScenario && "voidWindowEnd" in voidScenario) {
    voidWindowEnd = voidScenario.voidWindowEnd;
  }

  return (
    <Box className="flight-exchange-modal-content">
      {modalState === ExchangeModalState.LoadingOrProcessing && (
        <Slot
          id="exchange-loader"
          open
          className="loading-policy-popover"
          message={t("tripsLoading")}
          component={
            <MobilePopoverCard
              centered
              fullScreen
              open
              className="loading-policy-popover"
              contentClassName="loading-policy-popover-content"
              onClose={onClose}
            >
              <Spinner />
              <Typography className="subtitle" variant="h3">
                {t("tripsLoading")}
              </Typography>
            </MobilePopoverCard>
          }
        />
      )}
      {modalState === ExchangeModalState.ContactAirline && (
        <Slot
          id="exchange-contact-airline-screen"
          title={modalTitleRef.current}
          subtitle={modalSubtitleRef.current}
          primaryButtonText={t("selfServe.continueToAirline", {
            airline: airline1?.name,
          })}
          primaryOnClick={redirectToAirline}
          modal
          onBack={onClose}
          confirmationNumbers={confirmationNumbers}
          contactAirline
          component={
            <ErrorPopup
              open
              onClose={onClose}
              primaryButtonText={t("selfServe.continueToAirline", {
                airline: airline1?.name,
              })}
              primaryOnClick={redirectToAirline}
              secondaryButtonText={
                airline2
                  ? t("selfServe.continueToAirline", {
                      airline: airline2?.name || "",
                    }) || ""
                  : undefined
              }
              secondaryOnClick={
                airline2
                  ? () => {
                      onClose();
                      uberOpenExternalLink(
                        airline2?.webLinks?.changeCancelPolicy ||
                          airline2?.webLinks?.homePage ||
                          ""
                      );
                    }
                  : undefined
              }
              subtitle={modalSubtitleRef.current}
              title={modalTitleRef.current}
            />
          }
        />
      )}
      {modalState === ExchangeModalState.ContactSupport && (
        <Slot
          id="exchange-error-screen"
          primaryButtonText={t("contactSupport")}
          primaryOnClick={contactCustomerSupport}
          subtitle={modalSubtitleRef.current}
          title={modalTitleRef.current}
          modal
          onBack={onClose}
          header={t("exchangeHeaderTitle")}
          component={
            <ErrorPopup
              open
              onClose={onClose}
              primaryButtonText={t("contactSupport")}
              primaryOnClick={contactCustomerSupport}
              subtitle={modalSubtitleRef.current}
              title={modalTitleRef.current}
            />
          }
        />
      )}
      {modalState === ExchangeModalState.IneligibleForExchange && (
        <Slot
          id="exchange-error-screen"
          primaryButtonText={t("close")}
          primaryOnClick={onClose}
          subtitle={modalSubtitleRef.current}
          title={modalTitleRef.current}
          modal
          onBack={onClose}
          component={
            <ErrorPopup
              open
              onClose={onClose}
              primaryButtonText={t("close")}
              primaryOnClick={onClose}
              subtitle={modalSubtitleRef.current}
              title={modalTitleRef.current}
            />
          }
        />
      )}
      {modalState === ExchangeModalState.ItineraryNotFound && (
        <DetailHalfSheet
          open
          description={t("itineraryNotFoundSubtitle") || ""}
          onClose={onClose}
          title={t("itineraryNotFoundTitle") || ""}
        />
      )}
      {modalState === ExchangeModalState.LoadFailed && (
        <Slot
          id="exchange-error-screen"
          secondaryButtonText={t("tryAgain")}
          secondaryOnClick={getExchangePolicy}
          primaryButtonText={t("contactSupport")}
          primaryOnClick={contactCustomerSupport}
          subtitle={t("tripsLoadFailedSubtitle")}
          title={t("tripsLoadFailedTitle")}
          modal
          onBack={onClose}
          className="error"
          component={
            <ErrorPopup
              open
              onClose={onClose}
              primaryButtonText={t("tryAgain")}
              primaryOnClick={getExchangePolicy}
              secondaryButtonText={t("contactSupport")}
              secondaryOnClick={contactCustomerSupport}
              subtitle={t("tripsLoadFailedSubtitle")}
              title={t("tripsLoadFailedTitle")}
            />
          }
        />
      )}
      {modalState === ExchangeModalState.TicketedVoidable && (
        <Slot
          id="exchange-error-screen"
          title={t("voidable")}
          subtitle={getVoidableSubtitle(voidWindowEnd)}
          primaryButtonText={t("selfServe.openCancellationInfo")}
          primaryOnClick={openSelfServeCancel}
          secondaryButtonText={t("selfServe.continueToExchange")}
          secondaryOnClick={goToExchange}
          modal
          onBack={onClose}
          component={
            <ErrorPopup
              open
              onClose={onClose}
              primaryButtonText={t("selfServe.openCancellationInfo")}
              primaryOnClick={openSelfServeCancel}
              secondaryButtonText={t("selfServe.continueToExchange")}
              secondaryOnClick={goToExchange}
              subtitle={getVoidableSubtitle(voidWindowEnd)}
              title={t("voidable")}
            />
          }
        />
      )}
    </Box>
  );
};

ExchangeFlightModalContent.defaultProps = defaultProps;

export default ExchangeFlightModalContent;
