import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import clsx from "clsx";

import { Airline } from "@b2bportal/air-booking-api";
import {
  Fare,
  Flights,
  FlightsFareSlice,
  Slice,
  Trip,
  TripSlice,
} from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  DisruptionExerciseProgress,
  DisruptionFetchOfferResponseEnum,
  DisruptionRebookShopRequest,
  DisruptionRebookShopResponse,
  HalfSheetHashType,
  TripDetails,
} from "@hopper-b2b/types";
import {
  ActionButton,
  B2BLoadingPopup,
  ErrorPopup,
  MiniFlightSummaryPanel,
  MobileFloatingButton,
  NoResults,
} from "@hopper-b2b/ui";
import { getEnvVariables, toggleAdaChat } from "@hopper-b2b/utilities";

import disruptionRebookShop from "../../../../../api/v1/disruption/disruptionRebookShopFetch";
import {
  populateTripQueryParams,
  setDisruptionExerciseProgress,
} from "../../../../TripsList/actions/actions";
import { PATH_DISRUPTION_EXERCISE_CONTACT } from "../../../../../utils/paths";
import { FlightComponent } from "./DisruptionFlightShop/FlightComponent";
import { LocationAndDate } from "./DisruptionFlightShop/LocationAndDate";
import { ClientContext } from "../../../../../App";
import { resetDisruptionExerciseState } from "../../../../TripsList/actions/actions";

export interface IDisruptionFlightSearchProps {
  contractId: string;
  origin: string;
  destination: string;
  tripSlice: TripSlice;
  disableFloatingButton?: boolean;
  handleFlightSelect: (props: {
    fare: Fare;
    trip: Trip;
    tripSlice: Slice;
    fareSlice: FlightsFareSlice;
    tripDetails: TripDetails;
  }) => void;
  setFlightResultAirlines: (airlines: { [key: string]: Airline }) => void;
}

export const DisruptionFlightSearch = ({
  contractId,
  origin,
  destination,
  tripSlice,
  handleFlightSelect,
  setFlightResultAirlines,
  disableFloatingButton,
}: IDisruptionFlightSearchProps) => {
  const [flights, setFlights] = useState<Flights>();
  const [selectedFlight, setSelectedFlight] = useState<{
    fare: Fare;
    trip: Trip;
    tripSlice: Slice;
    fareSlice: FlightsFareSlice;
    tripDetails: TripDetails;
  }>({
    fare: undefined,
    trip: undefined,
    tripSlice: undefined,
    fareSlice: undefined,
    tripDetails: undefined,
  });
  const [showTodayFlights, setShowTodayFlights] = useState<boolean>(true);
  const [openFlightDetailModal, setOpenFlightDetailModal] =
    useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const { t } = useI18nContext();
  const clientContext = useContext(ClientContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const today = dayjs(tripSlice?.departureTime);
  const tomorrow = today.add(1, "day");

  const isUber = getEnvVariables("clientName") === "uber";

  const fetchDisruptionRebookShop = () => {
    if (contractId && origin && destination) {
      const req: DisruptionRebookShopRequest = {
        contractId,
        origin,
        destination,
      };
      disruptionRebookShop(req).then(
        (response: DisruptionRebookShopResponse) => {
          if (
            response.DisruptionRebookShopResponse ===
              DisruptionFetchOfferResponseEnum.RebookShopSuccess ||
            response.DisruptionRebookShopResponse ===
              DisruptionFetchOfferResponseEnum.Success
          ) {
            setFlights(response.flights);
            setFlightResultAirlines(response.flights.airlines);
          } else {
            setShowErrorModal(true);
          }
        }
      );
    }
  };

  useEffect(() => {
    fetchDisruptionRebookShop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const flightsToShow = useMemo(() => {
    if (flights) {
      return flights.outbound
        .filter((flight) =>
          dayjs(flights.slices[flight.slice].departure).isSame(
            showTodayFlights ? today : tomorrow,
            "day"
          )
        )
        .sort(
          (flightA, flightB) =>
            flights.slices[flightA.slice].totalDurationMinutes -
            flights.slices[flightB.slice].totalDurationMinutes
        );
    }
    return [];
  }, [flights, showTodayFlights, today, tomorrow]);

  useEffect(() => {
    if (location.hash === `#${HalfSheetHashType.FLIGHT_DETAILS}`) {
      setOpenFlightDetailModal(true);
    } else {
      setOpenFlightDetailModal(false);
    }
  }, [location]);

  const handleDateChange = useCallback((isToday: boolean) => {
    setShowTodayFlights(isToday);
  }, []);

  const handleContactSupport = useCallback(() => {
    toggleAdaChat();
    dispatch(resetDisruptionExerciseState());
  }, [dispatch]);

  const handleTryAgain = useCallback(() => {
    dispatch(resetDisruptionExerciseState());
  }, [dispatch]);

  // TODO: Update disruption exercise progress
  const onContinue = useCallback(() => {
    if (isUber) {
      const newHistory = dispatch(populateTripQueryParams(history)).history;
      history.replace(
        PATH_DISRUPTION_EXERCISE_CONTACT + newHistory.location.search
      );
      handleFlightSelect(selectedFlight);
    } else {
      dispatch(populateTripQueryParams(history));
      handleFlightSelect(selectedFlight);
      dispatch(
        setDisruptionExerciseProgress(DisruptionExerciseProgress.ContactInfo)
      );
    }
  }, [dispatch, handleFlightSelect, history, isUber, selectedFlight]);

  const onFlightSelect = useCallback(
    (props: {
      fareSliceId: string;
      fareId: string;
      tripId: string;
      tripDetails: TripDetails;
    }) => {
      const fareSlice = flights.fareSlices[props.fareSliceId];
      setSelectedFlight({
        fare: flights.fares[props.fareId],
        trip: flights.trips[props.tripId],
        tripSlice: flights.slices[flights.trips[props.tripId].outbound],
        fareSlice,
        tripDetails: props.tripDetails,
      });
    },
    [flights]
  );

  const getFlightShopFareSlice = useCallback(
    (fareSliceId: string) => {
      return flights.fareSlices[fareSliceId];
    },
    [flights?.fareSlices]
  );

  return flights === undefined ? (
    <B2BLoadingPopup
      open
      fullScreen={true}
      message={t("disruptionExercise.loadingFlightSearch")}
      popupSize="mobile"
    />
  ) : showErrorModal ? (
    <ErrorPopup
      open={showErrorModal}
      title={t("disruptionExercise.error.title")}
      subtitle={t("disruptionExercise.error.shopErrorSubtitle")}
      primaryButtonText={t("tryAgain")}
      primaryOnClick={handleTryAgain}
      secondaryButtonText={t("contactSupport")}
      secondaryOnClick={handleContactSupport}
    />
  ) : (
    <>
      <LocationAndDate
        origin={flights.airports[origin]?.cityName || origin}
        destination={flights.airports[destination]?.cityName || destination}
        tripSlice={tripSlice}
      />
      <div className="disruption-buttons-dates">
        <button
          className={clsx("disruption-button-day", {
            selected: showTodayFlights,
          })}
          onClick={() => handleDateChange(true)}
        >
          {t("disruptionExercise.flightSearchToday")}
        </button>
        <button
          className={clsx("disruption-button-day", {
            selected: !showTodayFlights,
          })}
          onClick={() => handleDateChange(false)}
        >
          {t("disruptionExercise.flightSearchTomorrow")}
        </button>
      </div>
      <hr />
      <h3>{t("originalFlight")}</h3>
      <div className="disruption-original-flight-summary">
        <MiniFlightSummaryPanel
          airports={flights?.airports || {}}
          tripSlice={tripSlice}
        />
      </div>
      <div className="disruption-flight-search-text">
        <h3 className="select-new-outbound">{t("selectNewOutbound")}</h3>
        <p className="no-cost-text">{t("rebookFlightNoCost")}</p>
      </div>
      <div className="disruption-flight-search">
        <div className="flight-list mobile">
          {flightsToShow?.length > 0 ? (
            flightsToShow?.map((flight) => {
              const slice = flights.slices[flight.slice];
              return (
                <FlightComponent
                  key={flight.slice}
                  flight={flight}
                  slice={slice}
                  handleFlightSelect={onFlightSelect}
                  isSelected={selectedFlight.fareSlice?.slice === flight.slice}
                  airline={flights.airlines[slice.marketingAirline]}
                  openFlightDetailModal={openFlightDetailModal}
                  onTripDetailsChange={(tripDetails: TripDetails) => {
                    // Auto-selects first fare onClick of a new trip card
                    const defaultFare =
                      flights.fares[tripDetails.fareDetails[0].id];
                    setSelectedFlight({
                      ...selectedFlight,
                      fare: defaultFare,
                      tripDetails,
                    });
                  }}
                  getFlightShopFareSlice={getFlightShopFareSlice}
                />
              );
            })
          ) : (
            <div className="disruption-no-trips-wrapper">
              <NoResults
                className="no-trips-container"
                title={t("noFlightsFound")}
                subtitle={t("tripsLoadFailedSubtitle")}
                iconSrc={
                  clientContext?.assets?.error
                    ? clientContext.assets.error
                    : null
                }
              />
              <ActionButton
                className=""
                message={t("contactSupport")}
                onClick={handleContactSupport}
              />
            </div>
          )}
        </div>
      </div>
      {selectedFlight?.fareSlice?.id ? (
        <MobileFloatingButton
          wrapperClassName={clsx("disruption-flight-selection-continue", {
            "adjust-width": disableFloatingButton,
          })}
          onClick={onContinue}
        >
          {t("continue")}
        </MobileFloatingButton>
      ) : null}
    </>
  );
};
