import { ResendEmailRequest, ResendEmailResponse } from "@b2bportal/email-api";

import { axiosInstance } from "../AxiosInterceptor";
import { resendEmailEndpoint } from "../paths";

const resendEmail = async (req: ResendEmailRequest) => {
  try {
    await axiosInstance.post<ResendEmailResponse>(resendEmailEndpoint, req);

    return;
  } catch (err) {
    console.error("Error while resending email: ", err);

    throw err;
  }
};

export default resendEmail;
