import { HeroImageGallery } from "@hopper-b2b/ui";
import { useSelector } from "react-redux";

import { useI18nContext } from "@hopper-b2b/i18n";
import { getImageUrlsArray, useDeviceTypes } from "@hopper-b2b/utilities";
import clsx from "clsx";
import { useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { ImageCarousel } from "../../../../components/ImageCarousel";
import {
  getSelectedLodging,
  getSelectedLodgingMedia,
} from "../../reducer/selectors";
import { ModalGallery } from "./components/ModalGallery";
import "./styles.scss";

export const ImageGallery = ({
  viewRoomOnClick,
}: {
  viewRoomOnClick: () => void;
}) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const [showExpandedGallery, setShowExpandedGallery] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const selectedLodging = useSelector(getSelectedLodging);
  const lodgingMedia = useSelector(getSelectedLodgingMedia);

  if (!selectedLodging) return null;

  return (
    <div className="lodging-hero-image-container">
      {/* Hero Section */}
      {matchesMobile ? (
        <MobileHeroSection
          onImageClick={() => {
            setCurrentImageIndex(0); //TODO: look into if this is needed from a product/design side
            setShowExpandedGallery(true);
          }}
        />
      ) : (
        <HeroImageGallery
          media={lodgingMedia}
          maxHeroImages={3}
          className="lodging-desktop-hero-gallery"
          onImageClick={(index) => {
            setCurrentImageIndex(index);
            setShowExpandedGallery(true);
          }}
          onSeeMoreClick={(index) => {
            setCurrentImageIndex(index);
            setShowExpandedGallery(true);
          }}
          seeMoreLabel={t("openGalleryButton")}
        />
      )}

      {/* Modal Image Gallery Grid */}
      <ModalGallery
        open={showExpandedGallery}
        onClose={() => setShowExpandedGallery(false)}
        viewRoomOnClick={() => {
          setShowExpandedGallery(false);
          viewRoomOnClick();
        }}
      />
    </div>
  );
};

const MobileHeroSection = ({ onImageClick }: { onImageClick: () => void }) => {
  const lodgingMedia = useSelector(getSelectedLodgingMedia);
  const loading = false; //TODO: pull from redux
  const imageUrlsArray = useMemo(() => {
    return lodgingMedia ? getImageUrlsArray(lodgingMedia) : [];
  }, [lodgingMedia]);

  if (!lodgingMedia || loading) {
    return (
      // TODO: Adjust styling in loading ticket
      <div className={clsx("availability-carousel", { skeleton: loading })}>
        <Skeleton
          className={clsx("lodging-availability-info-skeleton", "carousel")}
        />
      </div>
    );
  }

  return (
    <ImageCarousel
      carouselImagesUrls={imageUrlsArray}
      className="lodging-mobile-carousel"
    />
  );
};
