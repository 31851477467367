import { ChangeEvent, useCallback, useEffect, useMemo } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { Tab, Tabs, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useExperiment } from "@hopper-b2b/experiments";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ClientName, MyTripsFilter } from "@hopper-b2b/types";
import {
  getEnvVariables,
  removeStyleSheetByMetaName,
  useEnablePriceFreeze,
} from "@hopper-b2b/utilities";
import { IFilterTabsConnectorProps } from "./container";
import "./styles.scss";

export interface IFilterTabsProps
  extends IFilterTabsConnectorProps,
    // eslint-disable-next-line @typescript-eslint/ban-types
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {
  mobile?: boolean;
}

export const FilterTabs = ({
  history,
  tripsFilter,
  tripsCounts,
  setTripsFilter,
  populateTripQueryParams,
  mobile = false,
}: IFilterTabsProps) => {
  const { t } = useI18nContext();
  const showFlightPriceFreeze = useEnablePriceFreeze();
  const isCommbank = getEnvVariables("clientName") === ClientName.COMMBANK_AU;
  const isHopper = getEnvVariables("clientName") === ClientName.HOPPER;
  const isLodgingEnabled = useExperiment("hopper-web-lodging") || isCommbank;
  const areWatchesEnabled = isCommbank;

  const tabs = useMemo(() => {
    const links = [
      { label: t("upcomingTripsTitle"), value: MyTripsFilter.UPCOMING_TRIPS },
      { label: t("pastTripsTitle"), value: MyTripsFilter.PAST_TRIPS },
    ];

    if (isLodgingEnabled) {
      if (!isHopper) {
        links.push({ label: t("hotels"), value: MyTripsFilter.HOTELS });
      }
    }

    if (areWatchesEnabled) {
      links.push({
        label: t("watchedTripsTitle"),
        value: MyTripsFilter.WATCHED_TRIPS,
      });
    }

    if (showFlightPriceFreeze) {
      // When price freeze enabled, insert Price Freeze filter at position 1
      links.splice(1, 0, {
        label: t("priceFreezeTitle"),
        value: MyTripsFilter.PRIZE_FREEZES,
      });
    }

    return links;
  }, [areWatchesEnabled, isHopper, isLodgingEnabled, showFlightPriceFreeze, t]);

  const handleChange = useCallback(
    (_event: ChangeEvent<unknown>, value: MyTripsFilter) => {
      setTripsFilter(value);
      populateTripQueryParams(history);
    },
    [history, populateTripQueryParams, setTripsFilter]
  );

  // note: styleSheets with meta names starting with "Private" are being translated to css rules with the jss prefix on the production build;
  // they are loaded in whenever its respective components are mounted. (see https://hopper-jira.atlassian.net/wiki/spaces/ENGWEB/pages/2291728425/Observed+production-build+issues+caused+by+Material-UI+Private+classNames)
  useEffect(() => {
    removeStyleSheetByMetaName("PrivateTabIndicator");
  }, [tripsFilter]);

  return (
    <Tabs
      value={tripsFilter}
      onChange={handleChange}
      aria-controls="trips-tabs-panel"
      className={clsx("filter-tabs-container", { mobile })}
      TabIndicatorProps={{
        // note: PrivateTabIndicator style sheet is loaded in when TabIndicator component is mounted
        onLoad: () => removeStyleSheetByMetaName("PrivateTabIndicator"),
      }}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.label}
          id={tab.label}
          value={tab.value}
          className="tab"
          tabIndex={tab.label === tripsFilter ? 0 : -1}
          label={
            <Typography
              className={clsx("label", tab.label === tripsFilter && "selected")}
            >
              {`${tab.label}${
                tripsCounts[tab.label] !== undefined
                  ? ` (${tripsCounts[tab.label]})`
                  : ""
              }`}
            </Typography>
          }
        />
      ))}
    </Tabs>
  );
};
