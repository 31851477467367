import {
  ApacActionButton,
  ApacIconComponent,
  ApacIconName,
  DEFAULT_CHILD_AGE,
} from "@commbank/ui";
import {
  LocationDescriptorEnum,
  LodgingSelectionEnum,
  Lodgings,
  PlaceIdEnum,
  Suggestion,
} from "@b2bportal/lodging-api";
import { fetchPlace } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { URL_PARAM_KEYS } from "@hopper-b2b/lodging-utils";
import { LodgingShopTrackingEvents } from "@hopper-b2b/types";
import { IndeterminateLoader } from "@hopper-b2b/ui";
import {
  useDeviceTypes,
  useEnableWallet,
  usePrevious,
  useSessionContext,
} from "@hopper-b2b/utilities";
import { SignupBanner } from "@hopper-b2b/wallet";
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useEffect, useId, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom-v5-compat";
import { useDocumentTitle } from "../../../../hooks/src/useDocumentTitle";
import {
  AvailabilityByLocationQuery,
  AvailabilityByPlaceQuery,
  fetchAvailabilityByLocation,
  fetchAvailabilityByPlaceAPI,
  fetchAvailabilityNextPage,
} from "../../api/availability/fetchAvailability";
import {
  PATH_HOME,
  PATH_HOTELS_ROOT,
  PATH_SEARCH_RELATIVE,
  urlToPlaceQuery,
} from "../../util";
import { ProductType, useTrackEvents } from "../../util/trackEvent";
import { MobileHotelSearchStep } from "../search/types";
import "./Availability.scss";
import { EmptyState } from "./components/EmptyState";
import { FiltersModal } from "./components/FiltersModal";
import { LodgingCard, LodgingCardSkeleton } from "./components/LodgingCardNew";
import LodgingMap, { DEFAULT_ZOOM } from "./components/LodgingMap";
import QuickFilters from "./components/QuickFilters";
import { SearchLodging, type SearchSubmit } from "./components/Search";
import { type AvailabilityProps } from "./container";
import { HotelShopHeader } from "../../components/HotelShopHeader/HotelShopHeader";
import { FiltersAlert } from "./components/FiltersAlert";
import { useLodgingFilters } from "./hooks/useLodgingFilters";
import { useSearchParam } from "@commbank/hooks";

export const Availability = ({
  loading,
  isOverFiltered,
  place,
  fromDate,
  untilDate,
  filteredOutCount,
  lodgings,
  guests,
  rooms,
  filters,
  filterBoundaries,
  sort,
  setLoading,
  setAvailabilityLodgings,
  setSearchParams,
  addAvailabilityLodgings,
  resetFilters,
  updateFiltersAndSort,
  trackingProperties,
}: AvailabilityProps) => {
  const scrollableTargetId = useId();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { isLoggedIn } = useSessionContext();
  const wasLoggedIn = usePrevious(isLoggedIn);
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const navigate = useNavigate();

  const [mapLoading, setMapLoading] = useState(true);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const [centroid, setCentroid] = useState({
    lat: Number(searchParams.get(URL_PARAM_KEYS.LAT_LNG)?.split(",")[0]) || 0,
    lng: Number(searchParams.get(URL_PARAM_KEYS.LAT_LNG)?.split(",")[1]) || 0,
  });
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [mapBounds, setMapBounds] = useState<
    [number, number, number, number] | []
  >([]);
  const [selectedId, setSelectedId] = useSearchParam("selectedId");
  const [overedId, setOveredId] = useState<string | null>(null);
  const [afterFirstSearch, setAfterFirstSearch] = useState(false);
  const [showMapOnly, setShowMapOnly] = useState(false);

  const { filterContext, filterHandlers } = useLodgingFilters({
    context: { place, afterFirstSearch, filteredOutCount },
    handlers: { resetFilters, updateFiltersAndSort },
  });

  useDocumentTitle(t("commBank.hotelShopProgress.shopHotel"));

  const trackEvent = useTrackEvents();

  const onInfiniteScroll = useCallback(() => {
    if (nextPage) {
      fetchAvailabilityNextPage(nextPage).then((res) => {
        setNextPage(res.nextPageToken);
        if (res.centroid.lat && res.centroid.lon) {
          setCentroid({
            lat: parseFloat(res.centroid.lat.toString()),
            lng: parseFloat(res.centroid.lon.toString()),
          });
        }
        addAvailabilityLodgings(res.lodgings, res.offers);
      });
    }
  }, [addAvailabilityLodgings, nextPage]);

  // generate values based on form submit data or default to redux store data
  const generateQueryStayValues = useCallback(
    (nextValues?: Omit<SearchSubmit, "nextDestination">) => {
      if (nextValues) {
        const {
          nextFromDate,
          nextUntilDate,
          nextAdultsCount,
          nextChildrenCount,
          nextRoomsCount,
        } = nextValues;

        return {
          stayDates: {
            from: nextFromDate,
            until: nextUntilDate,
          },
          guests: {
            adults: nextAdultsCount,
            children:
              nextChildrenCount > 0 ? new Array(nextChildrenCount).fill(1) : [],
          },
          rooms: { numberOfRooms: nextRoomsCount },
        };
      }

      return {
        stayDates: {
          from: fromDate,
          until: untilDate,
        },
        guests: guests,
        rooms: { numberOfRooms: rooms },
      };
    },
    [fromDate, guests, rooms, untilDate]
  );

  const formatQueryStayValuesForStore = useCallback(
    ({
      guests,
      rooms,
      stayDates,
    }: ReturnType<typeof generateQueryStayValues>) => {
      return {
        guests,
        rooms: rooms.numberOfRooms,
        fromDate: stayDates.from,
        untilDate: stayDates.until,
      };
    },
    []
  );

  const getAvailability = useCallback(
    async (place: Suggestion, queryStayValues = generateQueryStayValues()) => {
      const lodgingSelection = (place?.id as Lodgings).lodgingSelection;
      const query: AvailabilityByPlaceQuery = {
        ...queryStayValues,
        platform: matchesMobile ? "Mobile" : "Desktop",
        lodgingSelection:
          lodgingSelection?.LodgingSelection === "Place"
            ? {
                placeId: lodgingSelection?.placeId,
                searchTerm: lodgingSelection?.searchTerm,
                placeTypes: lodgingSelection?.placeTypes,
                LodgingSelection: lodgingSelection?.LodgingSelection,
              }
            : undefined,
      };

      setLoading("loading-from-place");

      await fetchAvailabilityByPlaceAPI(query)
        .then((res) => {
          setAvailabilityLodgings(
            res.lodgings,
            formatQueryStayValuesForStore(queryStayValues),
            place,
            null,
            res.offers,
            res.trackingPropertiesV2
          );
          setCentroid({
            lat: parseFloat(res.centroid.lat.toString()),
            lng: parseFloat(res.centroid.lon.toString()),
          });
          if (res.nextPageToken) {
            setNextPage(res.nextPageToken);
          }
        })
        .catch((e) => console.error("fetchAvailabilityByPlaceAPI catch:", e));
    },
    [
      generateQueryStayValues,
      matchesMobile,
      setLoading,
      setAvailabilityLodgings,
      formatQueryStayValuesForStore,
    ]
  );

  const setPlaceFromLabel = useCallback(
    (placeName: string, queryStayValues = generateQueryStayValues()) => {
      const query = urlToPlaceQuery(placeName);
      fetchPlace(query).then((res) => {
        if (res !== undefined) {
          getAvailability(res, queryStayValues);
        }
      });
      setAfterFirstSearch(true);
    },
    [generateQueryStayValues, getAvailability]
  );

  const [showDatesAdjustModal, setShowDatesAdjustModal] = useState(false);

  const onFetchSearchArea = useCallback(
    (bounds = mapBounds, queryStayValues = generateQueryStayValues()) => {
      if (!bounds.length) {
        return;
      }
      const query: AvailabilityByLocationQuery = {
        ...queryStayValues,
        platform: matchesMobile ? "Mobile" : "Desktop",
        lodgingSelection: {
          descriptor: {
            northEast: { lat: bounds[3], lon: bounds[2] },
            southWest: { lat: bounds[1], lon: bounds[0] },
            LocationDescriptor: LocationDescriptorEnum.BoundingBox,
          },
          LodgingSelection: LodgingSelectionEnum.Location,
        },
      };
      setLoading("loading-from-map");
      fetchAvailabilityByLocation(query)
        .then((res) => {
          const lat = parseFloat(res.centroid?.lat.toString());
          const lng = parseFloat(res.centroid?.lon.toString());
          setNextPage(res.nextPageToken);
          setAvailabilityLodgings(
            res.lodgings,
            formatQueryStayValuesForStore(queryStayValues),
            null,
            [lat, lng, zoom],
            res.offers,
            res.trackingPropertiesV2
          );
        })
        .catch((e) => console.error("fetchAvailabilityByLocation catch:", e));
    },
    [
      mapBounds,
      generateQueryStayValues,
      matchesMobile,
      setLoading,
      setAvailabilityLodgings,
      formatQueryStayValuesForStore,
      zoom,
    ]
  );

  useEffect(() => {
    setSearchParams({
      guests: {
        adults: Number(searchParams.get(URL_PARAM_KEYS.ADULTS_COUNT)) || 1,
        // Use number of children and fake age of 1 to populate children array
        children:
          Number(searchParams.get(URL_PARAM_KEYS.CHILDREN_COUNT)) > 0
            ? new Array(
                Number(searchParams.get(URL_PARAM_KEYS.CHILDREN_COUNT))
              ).fill(DEFAULT_CHILD_AGE)
            : [],
      },
      rooms: Number(searchParams.get(URL_PARAM_KEYS.ROOMS_COUNT)) || 1,
      fromDate: searchParams.get(URL_PARAM_KEYS.FROM_DATE),
      untilDate: searchParams.get(URL_PARAM_KEYS.UNTIL_DATE),
      location: params["location"],
    });
    // Only run on first page load to set the search params
    // based on URL params (which were set from the user or from /search page)
  }, []);

  useEffect(() => {
    // Do not allow search if no stay dates are set
    if (!fromDate || !untilDate) return;

    // Only run before the user makes another search on the page
    if (!afterFirstSearch) {
      // Initial Search
      if (params.placeId) {
        const place: Suggestion = {
          id: {
            Id: PlaceIdEnum[params.placeId],
            lodgingSelection: {
              placeId: params.placeId,
              searchTerm: "",
              placeTypes: ["locality", "political", "geocode"],
              LodgingSelection: LodgingSelectionEnum.Place,
            },
          },
          label: params.location,
          trackingPropertiesV2: {},
        };
        getAvailability(place);
        setAfterFirstSearch(true);
      } else if (params["location"] !== "location") {
        setPlaceFromLabel(params["location"]);
      } else if (
        !mapLoading &&
        params.location === "location" &&
        lodgings.length === 0
      ) {
        const [lat, lng, z] = searchParams
          .get(URL_PARAM_KEYS.LAT_LNG)
          .split(",");
        setCentroid({
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        });
        setZoom(parseInt(z));
        onFetchSearchArea(mapBounds);
      }
    }
  }, [
    fromDate,
    untilDate,
    afterFirstSearch,
    getAvailability,
    lodgings.length,
    mapBounds,
    mapLoading,
    onFetchSearchArea,
    params,
    params.location,
    params.placeId,
    place,
    searchParams,
    setPlaceFromLabel,
  ]);

  useEffect(() => {
    /**
     * Only run when the user just logged in or logout within the page.
     * Otherwise the effect would trigger when loading the page as a logged in user.
     */
    if (wasLoggedIn != null && isLoggedIn !== wasLoggedIn && afterFirstSearch) {
      if (params.location === "location") {
        onFetchSearchArea(mapBounds);
      } else {
        getAvailability(place);
      }
    }
  }, [
    afterFirstSearch,
    getAvailability,
    isLoggedIn,
    wasLoggedIn,
    mapBounds,
    onFetchSearchArea,
    params.location,
    place,
  ]);

  useEffect(() => {
    if (lodgings?.length > 0) {
      trackEvent(
        LodgingShopTrackingEvents.hotel_loaded_list_page,
        ProductType.Hotel,
        trackingProperties?.properties,
        trackingProperties?.encryptedProperties
          ? [trackingProperties.encryptedProperties]
          : undefined
      );

      if (!matchesMobile) {
        trackEvent(
          LodgingShopTrackingEvents.hotel_viewed_map,
          ProductType.Hotel,
          trackingProperties?.properties,
          trackingProperties?.encryptedProperties
            ? [trackingProperties.encryptedProperties]
            : undefined
        );
      }
    }
  }, [place, matchesMobile, lodgings?.length, trackingProperties]);

  useEffect(() => {
    /**
     * If the selected property is no longer in the results
     * Clear the selected property. This ensures the map is updated correctly
     */
    if (selectedId) {
      const selected = lodgings.find(
        (item) => item?.lodging?.id === selectedId
      );
      if (!selected) {
        setSelectedId(null);
      }
    }
  }, [lodgings, selectedId, setSelectedId]);

  const onSearch = useCallback(
    ({ nextDestination, ...othersSearchValues }: SearchSubmit) => {
      const queryStayValues = generateQueryStayValues(othersSearchValues);
      if (nextDestination) {
        getAvailability(nextDestination, queryStayValues);
      } else {
        place?.label
          ? setPlaceFromLabel(place.label, queryStayValues)
          : onFetchSearchArea(mapBounds, queryStayValues);
      }

      trackEvent(
        LodgingShopTrackingEvents.hotel_updated_list_page,
        ProductType.Hotel,
        { ...othersSearchValues, ...(trackingProperties?.properties || {}) }
          .trackingProperties?.encryptedProperties
          ? [trackingProperties.encryptedProperties]
          : undefined
      );
    },
    [
      generateQueryStayValues,
      getAvailability,
      place?.label,
      setPlaceFromLabel,
      onFetchSearchArea,
      mapBounds,
    ]
  );

  const onMarkerClick = useCallback(
    (id: string) => {
      if (selectedId === id) {
        setSelectedId(null);
        return;
      }
      setSelectedId(id);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    },
    [selectedId]
  );

  const focusMapOnLocation = useCallback(
    (id: string) => {
      const lodging = lodgings.find((l) => l.lodging.id === id);
      if (lodging?.lodging?.location?.coordinates) {
        setCentroid({
          lat: lodging?.lodging.location.coordinates.lat,
          lng: lodging?.lodging.location.coordinates.lon,
        });
        if (matchesMobile && !showMapOnly) {
          setShowMapOnly(true);
        }
      }
    },
    [lodgings, matchesMobile, showMapOnly]
  );

  const hasVouchersEnabled = useEnableWallet();

  const onMapChange = useCallback(({ zoom, bounds }) => {
    setZoom(zoom);
    if (bounds) {
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      setMapBounds([sw.lng(), sw.lat(), ne.lng(), ne.lat()]);
    }
  }, []);

  const handleMapLoaded = useCallback(() => {
    setMapLoading(false);
  }, []);

  const toggleShowMapOnly = useCallback(() => {
    setShowMapOnly((showMapOnly) => {
      if (showMapOnly) {
        return false;
      } else {
        trackEvent(
          LodgingShopTrackingEvents.hotel_viewed_map,
          ProductType.Hotel,
          trackingProperties?.properties,
          trackingProperties?.encryptedProperties
            ? [trackingProperties.encryptedProperties]
            : undefined
        );
        return true;
      }
    });
  }, [trackEvent, setShowMapOnly]);

  return (
    <>
      <Box
        className={clsx("Availability", {
          mobile: matchesMobile,
        })}
      >
        {showMapOnly ? (
          <Box
            className={clsx("Availability-map", {
              "full-screen-map": showMapOnly,
            })}
          >
            <Box className="view-map-header">
              <Box className="view-map-close-button-holder">
                <IconButton onClick={toggleShowMapOnly}>
                  <ApacIconComponent name={ApacIconName.ChevronLeft} />
                </IconButton>
              </Box>
              <Box className="view-map-title">
                {t("commBank.hotelAvailability.viewMapTitle")}
              </Box>
              <Box></Box>
            </Box>
            <Box className="map-container">
              <LodgingMap
                loading={loading}
                lodgings={lodgings}
                centroid={centroid}
                zoom={zoom}
                mapBounds={mapBounds}
                selectedId={selectedId}
                overedId={overedId}
                onSearchArea={onFetchSearchArea}
                onSelected={onMarkerClick}
                onMapChange={onMapChange}
                onMapLoaded={handleMapLoaded}
              />
            </Box>
          </Box>
        ) : (
          <>
            <Box
              /**
               * TODO we should use disabled prop or design a loading states for each input
               * inert spread: https://github.com/facebook/react/issues/17157#issuecomment-1572230721
               */
              {...{ inert: loading ? "" : undefined }}
              className="Availability-heading"
            >
              {matchesMobile ? (
                <>
                  <HotelShopHeader
                    searchState={{
                      place,
                      fromDate,
                      untilDate,
                      guests,
                      rooms,
                    }}
                    onBack={() => {
                      navigate(
                        `${PATH_HOME}${PATH_HOTELS_ROOT}${PATH_SEARCH_RELATIVE}?${URL_PARAM_KEYS.STEP}=${MobileHotelSearchStep.CalendarPicker}`
                      );
                    }}
                    onEdit={() => {
                      navigate(
                        `${PATH_HOME}${PATH_HOTELS_ROOT}${PATH_SEARCH_RELATIVE}?${URL_PARAM_KEYS.PREV_SEARCH}=true`
                      );
                    }}
                  />

                  <Box className="search-filters">
                    <QuickFilters
                      {...filterHandlers}
                      isMobileView
                      filters={filters}
                      sort={sort}
                      showMap={toggleShowMapOnly}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <SearchLodging
                    // Set the key so that the component is re-rendered and
                    // the states are re-initialized when these props values change.
                    key={`fromDate-${fromDate}
                    -untilDate-${untilDate}
                    -guests.adults-${guests.adults}
                    -guests.children-${guests.children}
                    -rooms-${rooms}`}
                    initialDestination={place}
                    initialCheckinDate={fromDate}
                    initialCheckoutDate={untilDate}
                    initialGuestCount={{
                      adults: guests.adults,
                      children: guests.children,
                      rooms: rooms,
                    }}
                    forceCalendarPicker={showDatesAdjustModal}
                    onCalendarClosed={() => setShowDatesAdjustModal(false)}
                    onSearch={onSearch}
                  />
                  <QuickFilters
                    {...filterHandlers}
                    filters={filters}
                    sort={sort}
                    showMap={toggleShowMapOnly}
                  />
                </>
              )}
            </Box>

            <Box id={scrollableTargetId} className="Availability-lodgings">
              {loading ? (
                <Box className="Availability-loader">
                  <IndeterminateLoader />
                </Box>
              ) : null}
              {hasVouchersEnabled && !isLoggedIn ? (
                <SignupBanner
                  message="Save on thousands of hotels when you're signed in" //TODO: i18n
                />
              ) : null}
              {loading ? (
                <Box className="Availability-lodgings-grid">
                  {[1, 2].map((skeletonNumber) => (
                    <LodgingCardSkeleton
                      className="lodging-card"
                      key={skeletonNumber}
                    />
                  ))}
                </Box>
              ) : lodgings?.length > 0 ? (
                <>
                  <FiltersAlert
                    open={filterContext.showFilterSnackbar}
                    filteredItemsCount={filteredOutCount}
                    handlers={{
                      onClose: filterHandlers.closeFiltersAlert,
                      onClearFilters: resetFilters,
                    }}
                  />
                  <InfiniteScroll
                    className="Availability-lodgings-grid"
                    key={matchesMobile ? "is-mobile" : "is-desktop"}
                    scrollableTarget={scrollableTargetId}
                    dataLength={lodgings.length}
                    hasMore={Boolean(nextPage)}
                    next={onInfiniteScroll}
                    loader={
                      <div className="infinite-scroll-loader">
                        <CircularProgress
                          color="primary"
                          size={32}
                          aria-label={t("loadingMoreHotels")}
                        />
                      </div>
                    }
                  >
                    {lodgings.map((item, index) => (
                      <LodgingCard
                        key={item.lodging.id}
                        lodging={item}
                        className="lodging-card"
                        setOveredId={setOveredId}
                        selectLocation={focusMapOnLocation}
                        index={index}
                      />
                    ))}
                  </InfiniteScroll>
                  {lodgings.length > 0 && !nextPage && (
                    <Box className="find-more">
                      <Typography variant="h3" className="title">
                        {t("hotelShop.findMoreHotels")}
                      </Typography>
                      <Typography variant="body2">
                        {t("hotelShop.adjustSearch")}
                      </Typography>

                      <Box className="buttons">
                        <ApacActionButton
                          message={t("emptyStateAdjustDatesLabel")}
                          onClick={() => {
                            setShowDatesAdjustModal(true);
                          }}
                        />

                        <ApacActionButton
                          variant="outlined"
                          message={t("resetFilters")}
                          onClick={() => {
                            resetFilters();
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                </>
              ) : (
                <EmptyState
                  resetFilters={isOverFiltered ? resetFilters : null}
                  adjustDates={() => {
                    if (matchesMobile) {
                      navigate(
                        `${PATH_HOME}${PATH_HOTELS_ROOT}${PATH_SEARCH_RELATIVE}?${URL_PARAM_KEYS.STEP}=${MobileHotelSearchStep.CalendarPicker}`
                      );
                    } else {
                      setShowDatesAdjustModal(true);
                    }
                  }}
                  locationName={params["location"]}
                />
              )}
            </Box>
            {!matchesMobile ? (
              <Box
                className={clsx("Availability-map", {
                  "full-screen-map": showMapOnly,
                })}
              >
                <Box className="map-container">
                  <LodgingMap
                    loading={loading}
                    lodgings={lodgings}
                    centroid={centroid}
                    zoom={zoom}
                    mapBounds={mapBounds}
                    selectedId={selectedId}
                    overedId={overedId}
                    onSearchArea={onFetchSearchArea}
                    onSelected={onMarkerClick}
                    onMapChange={onMapChange}
                    onMapLoaded={handleMapLoaded}
                  />
                </Box>
              </Box>
            ) : null}
          </>
        )}
      </Box>
      <FiltersModal
        open={filterContext.showFiltersModal}
        sort={sort}
        filters={filters}
        filterBoundaries={filterBoundaries}
        closeFiltersModal={filterHandlers.closeFiltersModal}
        onResetFilters={resetFilters}
        updateFiltersAndSort={updateFiltersAndSort}
      />
    </>
  );
};
