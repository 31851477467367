import {
  RESET_FILTERS,
  SET_AVAILABILITY_LODGINGS,
  SET_SORT,
  UPDATE_FILTERS_AND_SORT,
  SET_SEARCH_PARAMS,
} from "../modules/availability/actions/constants";
import { URL_PARAM_KEYS } from "@hopper-b2b/lodging-utils";
import { placeLabelToUrl } from "../util";
import {
  type SortOption,
  type ILodgingFilterState,
} from "../modules/availability/reducer";
import { type LodgingAvailabilityActions } from "../modules/availability/actions/actions";
import { type ILodgingAppState } from "../reducer";

function initPriceRange(urlMin: number, urlMax: number) {
  const min = isNaN(urlMin) ? 0 : urlMin;
  const max = isNaN(urlMax) || urlMax === 0 ? Infinity : urlMax;
  return {
    min,
    max,
    lowest: 0,
    highest: Infinity,
  };
}

export const readFiltersFromURL = (): ILodgingFilterState => {
  const searchParams = new URLSearchParams(document.location.search);

  const userRating = Number(searchParams.get(URL_PARAM_KEYS.USER_RATING));

  return {
    starRating:
      searchParams
        .get(URL_PARAM_KEYS.STAR_RATING)
        ?.split(",")
        .map((v) => Number(v)) || [],
    userRating: isNaN(userRating) ? 0 : userRating,
    amenities: searchParams.get(URL_PARAM_KEYS.AMENITIES)?.split(",") || [],
    filterAmenitiesAnd:
      searchParams.get(URL_PARAM_KEYS.FILTER_AMENITIES_AND) !== null
        ? false
        : true,
    freeCancellation:
      searchParams.get(URL_PARAM_KEYS.FREE_CANCELLATION) !== null,
    priceRange: initPriceRange(
      Number(searchParams.get(URL_PARAM_KEYS.PRICE_RANGE_MIN)),
      Number(searchParams.get(URL_PARAM_KEYS.PRICE_RANGE_MAX))
    ),
  };
};

const VALID_SORT_OPTIONS = ["starRating", "userRating", "price"];

export const readSortOptionFromUrl = (): SortOption => {
  const searchParams = new URLSearchParams(document.location.search);
  const sortBy = searchParams.get(URL_PARAM_KEYS.SORT_BY);
  return VALID_SORT_OPTIONS.includes(sortBy)
    ? (sortBy as SortOption)
    : "mostRecommended";
};

const urlMiddleWare =
  (store) => (next) => (action: LodgingAvailabilityActions) => {
    const { lodgingAvailability }: ILodgingAppState = store.getState();
    const searchParams = new URLSearchParams(document.location.search);
    let isPush = false;
    let pathName = window.location.pathname;

    // Determining the prefix is required for H4B since it's the portal is served
    // from /app
    // TODO: Figure out a way to pull this from react-router's basename property.
    const pathIndexOfHotels = pathName.indexOf("/hotels");
    const prefix =
      pathIndexOfHotels === 0 ? "" : pathName.substr(0, pathIndexOfHotels);

    switch (action.type) {
      case SET_AVAILABILITY_LODGINGS:
        searchParams.set(URL_PARAM_KEYS.FROM_DATE, action.stayValues.fromDate);
        searchParams.set(
          URL_PARAM_KEYS.UNTIL_DATE,
          action.stayValues.untilDate
        );
        searchParams.set(
          URL_PARAM_KEYS.ADULTS_COUNT,
          action.stayValues.guests.adults.toString()
        );
        if (action.stayValues.guests.children.length > 0) {
          searchParams.set(
            URL_PARAM_KEYS.CHILDREN_COUNT,
            action.stayValues.guests.children.length.toString()
          );
        } else {
          searchParams.delete(URL_PARAM_KEYS.CHILDREN_COUNT);
        }
        if (action.stayValues.rooms > 1) {
          searchParams.set(
            URL_PARAM_KEYS.ROOMS_COUNT,
            action.stayValues.rooms.toString()
          );
        }

        isPush = true;
        if (action.location) {
          pathName = `${prefix}/hotels/availability/location`;
          searchParams.set(URL_PARAM_KEYS.LAT_LNG, action.location.toString());
        } else if (action.place) {
          pathName =
            `${prefix}/hotels/availability/` +
            placeLabelToUrl(action.place?.label);
          searchParams.delete(URL_PARAM_KEYS.LAT_LNG);
        }
        break;
      case SET_SEARCH_PARAMS:
        searchParams.set(URL_PARAM_KEYS.FROM_DATE, action.stayValues.fromDate);
        searchParams.set(
          URL_PARAM_KEYS.UNTIL_DATE,
          action.stayValues.untilDate
        );
        searchParams.set(
          URL_PARAM_KEYS.ADULTS_COUNT,
          action.stayValues.guests.adults.toString()
        );
        if (action.stayValues.guests.children.length > 0) {
          searchParams.set(
            URL_PARAM_KEYS.CHILDREN_COUNT,
            action.stayValues.guests.children.length.toString()
          );
        } else {
          searchParams.delete(URL_PARAM_KEYS.CHILDREN_COUNT);
        }
        if (action.stayValues.rooms > 1) {
          searchParams.set(
            URL_PARAM_KEYS.ROOMS_COUNT,
            action.stayValues.rooms.toString()
          );
        }
        break;
      //***Filters***//
      case UPDATE_FILTERS_AND_SORT: {
        const { filters = {}, sort } = action;
        const filtersToParam = {
          starRating: URL_PARAM_KEYS.STAR_RATING,
          userRating: URL_PARAM_KEYS.USER_RATING,
          amenities: URL_PARAM_KEYS.AMENITIES,
          filterAmenitiesAnd: URL_PARAM_KEYS.FILTER_AMENITIES_AND,
          freeCancellation: URL_PARAM_KEYS.FREE_CANCELLATION,
        };
        Object.entries(filtersToParam).forEach(
          ([prop, param]: [string, string]) => {
            if (prop in filters /* .hasOwnProperty(prop) */) {
              searchParams.set(param, filters[prop].toString());
            }
          }
        );

        if (
          filters.priceRange &&
          filters.priceRange.min >
            lodgingAvailability.filters.priceRange.lowest &&
          filters.priceRange.max <
            lodgingAvailability.filters.priceRange.highest
        ) {
          searchParams.set(
            URL_PARAM_KEYS.PRICE_RANGE_MIN,
            filters.priceRange.min.toString()
          );
          searchParams.set(
            URL_PARAM_KEYS.PRICE_RANGE_MAX,
            filters.priceRange.max.toString()
          );
        }
        if (VALID_SORT_OPTIONS.includes(sort)) {
          searchParams.set(URL_PARAM_KEYS.SORT_BY, sort);
        }
        break;
      }
      case RESET_FILTERS:
        [
          URL_PARAM_KEYS.STAR_RATING,
          URL_PARAM_KEYS.USER_RATING,
          URL_PARAM_KEYS.AMENITIES,
          URL_PARAM_KEYS.FILTER_AMENITIES_AND,
          URL_PARAM_KEYS.PRICE_RANGE_MIN,
          URL_PARAM_KEYS.PRICE_RANGE_MAX,
          URL_PARAM_KEYS.FREE_CANCELLATION,
        ].forEach((param) => searchParams.delete(param));
        break;
      case SET_SORT:
        if (VALID_SORT_OPTIONS.includes(action.value)) {
          searchParams.set(URL_PARAM_KEYS.SORT_BY, action.value);
        } else {
          searchParams.delete(URL_PARAM_KEYS.SORT_BY);
        }
        break;
    }

    const url = pathName.concat(
      window.location.hash,
      "?",
      searchParams.toString()
    );
    /**
     * TODO We should revisit this to rely on react-router to update the URL
     * Maybe via https://www.npmjs.com/package/connected-react-router/v/6.9.3
     * See https://hopchat.slack.com/archives/C02L3HB260L/p1694112851162299
     */
    if (isPush) {
      window.history.pushState(null, null, url);
    } else {
      window.history.replaceState(null, null, url);
    }

    return next(action);
  };

export default urlMiddleWare;
