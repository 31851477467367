import { useI18nContext } from "@hopper-b2b/i18n";
import "./styles.scss";
import { Box, Button, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom-v5-compat";
import clsx from "clsx";
import {
  CommBankAuIconComponent,
  CommBankAuIconName,
} from "../../assets/commBankAuIcons";
import { PATH_SUFFIX_SEARCH } from "../../utils/urlPaths";
import { Funnel, FunnelToFunnelInfo } from "../type";
import { useEffect } from "react";
import { FEATURE_FLAGS } from "../../Root";
import { useFeatureFlag } from "@apac/feature-flags";
import { Maintenance } from "../Maintenance";
import useHomepageSearchCopy from "../../hooks/useHomepageSearchCopy";

export type MobileLandingPageProps = MobileFunnelTabsProps;

export const MobileLandingPage = (props: MobileLandingPageProps) => {
  const { funnels, funnelToFunnelInfo, currentFunnel } = props;

  return (
    <Box className="mobile-landing-page-root">
      <MobileFunnelTabs
        funnels={funnels}
        funnelToFunnelInfo={funnelToFunnelInfo}
        currentFunnel={currentFunnel}
      />
    </Box>
  );
};

export interface MobileFunnelTabsProps {
  /* List of funnels to show (should NOT contain duplicates) */
  funnels: Funnel[];
  /* Map from funnel enum to funnel identifier for i18n */
  funnelToFunnelInfo: FunnelToFunnelInfo;
  currentFunnel?: Funnel;
}

export const MobileFunnelTabs = (props: MobileFunnelTabsProps) => {
  const { funnels, funnelToFunnelInfo, currentFunnel } = props;
  const { title, subtitle } = useHomepageSearchCopy(
    funnelToFunnelInfo,
    currentFunnel
  );
  const navigate = useNavigate();

  const { t, brand } = useI18nContext();

  useEffect(() => {
    document.title = `${brand.portalName} | ${t(
      currentFunnel === Funnel.Flights
        ? "browserTabTitles.searchFlight"
        : "browserTabTitles.searchHotel"
    )}`;

    return () => {
      document.title = brand.portalName;
    };
  }, [currentFunnel]);

  const SearchBarForFunnelTab = (props: { funnel: Funnel }) => {
    const { funnel } = props;

    return (
      <Button
        variant="contained"
        className={clsx("search-bar-funnel", `search-bar-funnel-${funnel}`)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            navigate(
              "/" + funnelToFunnelInfo[funnel].path + PATH_SUFFIX_SEARCH
            );
          }
        }}
        onClick={(e) => {
          navigate("/" + funnelToFunnelInfo[funnel].path + PATH_SUFFIX_SEARCH);
        }}
        disableTouchRipple
        disableRipple
      >
        {t(`${funnelToFunnelInfo[funnel].stringId}.searchBoxMessage`)}
        <CommBankAuIconComponent name={CommBankAuIconName.Magnifier} />
      </Button>
    );
  };

  const flightsMaintenanceEnabled = useFeatureFlag(
    FEATURE_FLAGS.FLIGHTS_MAINTENANCE
  );
  const hotelsMaintenanceEnabled = useFeatureFlag(
    FEATURE_FLAGS.HOTELS_MAINTENANCE
  );

  return (
    <Box className="funnel-tabs-container">
      {funnels.map((funnel) => {
        return (
          <Button
            key={funnel}
            variant="contained"
            className={clsx("funnel-tab", `funnel-tab-${funnel}`, {
              selected: funnel === currentFunnel,
            })}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigate("/" + funnelToFunnelInfo[funnel].path);
              }
            }}
            onClick={() => navigate("/" + funnelToFunnelInfo[funnel].path)}
          >
            {t(`${funnelToFunnelInfo[funnel].stringId}.tabTitle`)}
          </Button>
        );
      })}
      {flightsMaintenanceEnabled && currentFunnel === Funnel.Flights ? (
        <Maintenance />
      ) : hotelsMaintenanceEnabled && currentFunnel === Funnel.Hotels ? (
        <Maintenance />
      ) : (
        <>
          <Box className="search-title-container">
            <Typography variant="h4" className="search-title">
              {title}
            </Typography>
            <Typography variant="h5" className="search-subtitle">
              {subtitle}
            </Typography>
          </Box>
          <SearchBarForFunnelTab funnel={currentFunnel} />
        </>
      )}
    </Box>
  );
};
