import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { MyTripsFilter } from "@hopper-b2b/types";
import { IStoreState } from "../../../../reducers/types";
import {
  setSelectedHotel,
  populateTripQueryParams,
  setOpenModal,
} from "../../actions/actions";
import { getTripsFilter } from "../../reducer";
import { HotelCard } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  isPastTrips: getTripsFilter(state) === MyTripsFilter.PAST_TRIPS,
});

const mapDispatchToProps = {
  setSelectedHotel,
  populateTripQueryParams,
  setOpenModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelCardConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedHotelCard = connector(withRouter(HotelCard));
