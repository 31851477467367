import { put, select } from "redux-saga/effects";
import { CallState } from "@hopper-b2b/types";

import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import { refundPriceFreezeApi } from "../../../api/v1/price-freeze/priceFreezeRefund";
import { flightApiConfigStoreKey } from "../../../reducers/types";
import { IStoreState } from "../../../reducers/types";

export function* refundPriceFreezeSaga(action: actions.IRefundPriceFreeze) {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    yield refundPriceFreezeApi(
      {
        id: action.frozenPriceId,
      },
      apiConfig
    );

    //TODO: check for error states when https://hopper-jira.atlassian.net/browse/CFTC-31
    yield put(
      actions.setRefundPriceFreeze({
        refundPriceFreezeCallState: CallState.Success,
      })
    );
  } catch (e) {
    yield put(
      actions.setRefundPriceFreeze({
        refundPriceFreezeCallState: CallState.Failed,
      })
    );
    Logger.debug(e);
  }
}
