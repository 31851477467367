import { useI18nContext } from "@hopper-b2b/i18n";
import "./styles.scss";

interface IConfirmationSummary {
  className?: string;
  confirmationLabel?: string;
  confirmationCode: string;
}

export const ConfirmationSummary = ({
  confirmationLabel,
  confirmationCode,
}: IConfirmationSummary) => {
  const { t } = useI18nContext();

  return (
    <div className="confirmation-summary-container">
      <p className="confirmation-text">
        {confirmationLabel || t("confirmation")}
      </p>
      <h2 className="confirmation-number">{confirmationCode}</h2>
    </div>
  );
};
