import { useSelector, useDispatch } from "react-redux";
import { useI18nContext } from "@hopper-b2b/i18n";
import { CallState } from "@hopper-b2b/types";
import { LoadingPopup, B2BSpinner, ErrorPopup } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { getWatchDetailsCallState } from "../../../../../TripsList/reducer";
import { setGetDetailsWatchCallState } from "../../../../../TripsList/actions/actions";

export const WatchModals = () => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const dispatch = useDispatch();

  const watchDetailsCallState = useSelector(getWatchDetailsCallState);
  const resetCallState = () => {
    dispatch(setGetDetailsWatchCallState(CallState.NotCalled));
  };
  return (
    <>
      {watchDetailsCallState === CallState.InProcess ? (
        <LoadingPopup
          open
          indicator={B2BSpinner}
          message={t("fetchWatchDetails")}
          classes={[
            "trips-loading-modal",
            ...(matchesMobile ? ["mobile"] : []),
          ]}
        />
      ) : null}
      {watchDetailsCallState === CallState.Failed ? (
        <ErrorPopup
          open
          title={t("fetchWatchDetailsErrorTitle")}
          subtitle={t("fetchWatchDetailsErrorSubtitle")}
          primaryButtonText={t("close.button")}
          primaryOnClick={resetCallState}
        />
      ) : null}
    </>
  );
};
