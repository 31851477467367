import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { fetchFlightsV4 } from "../actions/actions";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { useWallet } from "@commbank/wallet";

export const useFetchFlights = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { matchesMobile } = useDeviceTypes();

  const { creditBalance } = useWallet();

  return useCallback(
    () => dispatch(fetchFlightsV4(history, matchesMobile, creditBalance)),
    [creditBalance, dispatch, history, matchesMobile]
  );
};
