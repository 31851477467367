import { Box, Slider } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import "./styles.scss";

interface IGenericSliderProps {
  className?: string;
  onChange: (min: number, max: number) => void;
  sliderLabel?: React.ReactNode;
  sliderType: "singleThumb" | "doubleThumb" | "normal";
  step?: number;
  chosenMin: number;
  chosenMax: number;
  sliderMin: number;
  sliderMax: number;
  getLabel: (value: number) => string;
  updateLabelsOnChange?: boolean;
}

const GenericSlider = (props: IGenericSliderProps) => {
  const {
    className,
    onChange,
    sliderLabel,
    sliderType,
    step,
    chosenMin,
    chosenMax,
    sliderMin,
    sliderMax,
    getLabel,
    updateLabelsOnChange = false,
  } = props;

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    value: number | number[]
  ): void => {
    if (event.isTrusted && typeof value === "number") {
      onChange(value, sliderMax);
    } else {
      const [min, max] = value as [number, number];
      onChange(min, max);
    }
  };
  return (
    <Box className={clsx("generic-slider-root", className)}>
      {sliderLabel && <Box className="generic-slider-label">{sliderLabel}</Box>}
      <Slider
        role="slider"
        className="generic-slider"
        step={step ?? (sliderMax - sliderMin) / 50}
        min={sliderMin}
        max={sliderMax}
        // There is something backwards happening with chosenMax and chosenMax
        // I believe it's tied to sliderType and how the track is inverted or not
        marks={[
          {
            value: sliderMin,
            label: getLabel(updateLabelsOnChange ? chosenMax : sliderMin),
          },
          {
            value: sliderMax,
            label: getLabel(updateLabelsOnChange ? chosenMin : sliderMax),
          },
        ]}
        value={
          sliderType === "singleThumb" ? chosenMin : [chosenMin, chosenMax]
        }
        onChange={handleChange}
        valueLabelDisplay="on"
        valueLabelFormat={getLabel}
        track={sliderType === "singleThumb" ? "inverted" : "normal"}
      />
    </Box>
  );
};

export default GenericSlider;
