import { useRef, useEffect, useMemo, useCallback } from "react";
import { TrackingProperties } from "@b2bportal/lodging-api";
import { useInView } from "react-intersection-observer";
import { ProductType, useTrackEvents } from "../../../../../util/trackEvent";
import { getAvailabilityTrackingProperties } from "../../../reducer";
import { useSelector } from "react-redux";
import { LodgingShopTrackingEvents } from "@hopper-b2b/types";

export const useTrackLodgingCardEvents = (
  currentLodging,
  lodgingListIndex: number,
  numberOfPhotos: number
) => {
  const trackEvent = useTrackEvents();
  const viewedRef = useRef(false);
  const { ref: containerRef, inView } = useInView();
  const { lodging, isFreeCancel } = currentLodging;
  const hotelAvailabilityTrackingProperties = useSelector(
    getAvailabilityTrackingProperties
  );

  const trackingProperties = useMemo(
    (): TrackingProperties => ({
      properties: {
        ...hotelAvailabilityTrackingProperties.properties,
        lodging_list_index: lodgingListIndex,

        im_lodging_name: lodging.name,
        lodging_id: lodging.id,
        lodging_star_rating: lodging.starRating,
        lodging_trip_advisor_rating: lodging.tripAdvisorReviews?.overallScore,
        number_of_photos: numberOfPhotos,
        free_cancellation_show: isFreeCancel,
      },
      encryptedProperties:
        hotelAvailabilityTrackingProperties.encryptedProperties,
    }),
    [
      lodging,
      numberOfPhotos,
      isFreeCancel,
      hotelAvailabilityTrackingProperties,
      lodgingListIndex,
    ]
  );

  useEffect(() => {
    // track when the card is displayed
    if (inView && !viewedRef.current) {
      viewedRef.current = true;
      const trackingProps = {
        ...trackingProperties.properties,
        ...currentLodging.trackingPropertiesV2.properties,
      };
      trackEvent(
        LodgingShopTrackingEvents.hotel_viewed_lodging_in_list,
        ProductType.Hotel,
        trackingProps,
        [
          currentLodging.trackingPropertiesV2.encryptedProperties,
          trackingProperties.encryptedProperties,
        ]
      );
    }
  }, [trackEvent, currentLodging, trackingProperties, inView]);

  const onCarouselNavigate = useCallback(() => {
    trackEvent(
      LodgingShopTrackingEvents.hotel_swiped_carousel,
      ProductType.Hotel,
      trackingProperties.properties,
      [trackingProperties.encryptedProperties]
    );
  }, [trackEvent, trackingProperties]);

  return {
    containerRef,
    onCarouselNavigate,
  };
};
