import {
  EndSessionResponse,
  commbankAuAuthApi,
} from "@b2bportal/commbank-au-auth-api";
import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";

export const endSession = async (
  config?: IApiConfig
): Promise<EndSessionResponse> => {
  try {
    const res = await commbankAuAuthApi(axiosInstance).apiV0AuthSessionEndPost({
      EndSessionRequest: "LogOut",
    });
    return res.data;
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      config
    );
    throw e;
  }
};
