import { TripsClientAssetProps, TripsModuleProps } from "@hopper-b2b/types";
import { Slot } from "@hopper-b2b/ui";
import {
  FeatureFlagsContext,
  FeatureFlagsContextProps,
  UserSourceProvider,
  useDeviceTypes,
  useWindowEventListener,
} from "@hopper-b2b/utilities";
import {
  StylesProvider,
  ThemeProvider,
  createGenerateClassName,
} from "@material-ui/core";
import { createContext, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import { store } from "./store";
import { eventsToListen } from "./utils/events";
import { PATH_HOME } from "./utils/paths";

import "./App.scss";
import { TripsList } from "./pages/TripsList";

const generateClassName = createGenerateClassName({
  productionPrefix: "ptTripsModule",
  seed: "ptTripsModule",
});

export const ClientContext = createContext<Partial<TripsClientAssetProps>>({});

type TripsAppProps = TripsModuleProps & {
  featureFlags?: FeatureFlagsContextProps;
};

const App = ({
  clientAssets,
  isAgentPortal,
  theme,
  apiConfig,
  featureFlags,
  onSupportClick,
}: TripsAppProps) => {
  const { matchesMobile } = useDeviceTypes();

  const [activeTheme, setActiveTheme] = useState(theme);
  const [sessionInfo] = useState(clientAssets.sessionInfo);

  useEffect(() => {
    document.title = "My Trips and Support";
    return () => {
      document.title = "Travel Portal";
    };
  }, []);

  const handleThemeChanged = (e: CustomEvent) => {
    setActiveTheme(e.detail);
  };
  useWindowEventListener(eventsToListen.HOST_THEME_CHANGED, handleThemeChanged);
  return (
    <Provider store={store}>
      <UserSourceProvider>
        <ClientContext.Provider
          value={{ ...clientAssets, isAgentPortal, sessionInfo }}
        >
          <FeatureFlagsContext.Provider value={featureFlags ?? {}}>
            <div className="App">
              <StylesProvider generateClassName={generateClassName}>
                <ThemeProvider theme={activeTheme}>
                  <Route path={PATH_HOME}>
                    <Slot
                      id="mytrips-list"
                      isMobile={matchesMobile}
                      apiConfig={apiConfig}
                      onSupportClick={onSupportClick}
                      component={
                        <TripsList
                          isMobile={matchesMobile}
                          apiConfig={apiConfig}
                          onSupportClick={onSupportClick}
                        />
                      }
                    />
                  </Route>
                </ThemeProvider>
              </StylesProvider>
            </div>
          </FeatureFlagsContext.Provider>
        </ClientContext.Provider>
      </UserSourceProvider>
    </Provider>
  );
};

export default App;
