import { GetPriceFreezeResponseV2 } from "@b2bportal/price-freeze-api";
import {
  CallState,
  Offer,
  IPassengerCounts,
  PriceFreezePriceQuote,
} from "@hopper-b2b/types";
import { actions, actionTypes } from "../actions";

export enum Progress {
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
}

export const MINIMUM_ADULT_COUNT = 1;

export interface IFlightFreezeState {
  priceFreezeOfferCallState: CallState;
  setUpFlightFreezeParamsCallState: CallState;
  priceFreezeOffer: Offer | null;
  counts: IPassengerCounts;
  currentPriceFreeze: GetPriceFreezeResponseV2 | null;
  priceFreezeCallState: CallState;
  refundPriceFreezeCallState: CallState;
  displayPriceFreezeRefundModal: boolean;
  quoteData: PriceFreezePriceQuote | null;
}

export const initialState: IFlightFreezeState = {
  priceFreezeOfferCallState: CallState.NotCalled,
  setUpFlightFreezeParamsCallState: CallState.NotCalled,
  priceFreezeOffer: null,
  counts: {
    adultsCount: MINIMUM_ADULT_COUNT,
    childrenCount: 0,
    infantsInSeatCount: 0,
    infantsOnLapCount: 0,
  },
  currentPriceFreeze: null,
  priceFreezeCallState: CallState.NotCalled,
  refundPriceFreezeCallState: CallState.NotCalled,
  displayPriceFreezeRefundModal: false,
  quoteData: null,
};

export function reducer(
  state: IFlightFreezeState = initialState,
  action: actions.FlightFreezeActions
): IFlightFreezeState {
  switch (action.type) {
    case actionTypes.GET_PRICE_FREEZE_OFFER:
      return {
        ...state,
        priceFreezeOfferCallState: CallState.InProcess,
      };

    case actionTypes.SET_PRICE_FREEZE_OFFER:
      return {
        ...state,
        priceFreezeOffer: action.offer,
        priceFreezeOfferCallState: action.priceFreezeOfferCallState,
      };

    case actionTypes.SET_PRICE_FREEZE_OFFER_CALL_STATE_FAILED:
      return {
        ...state,
        priceFreezeOfferCallState: CallState.Failed,
      };

    case actionTypes.SET_PASSENGER_COUNTS:
      return {
        ...state,
        counts: action.counts,
      };

    case actionTypes.FETCH_PRICE_FREEZE:
      return {
        ...state,
        priceFreezeCallState: CallState.InProcess,
      };

    case actionTypes.SET_PRICE_FREEZE:
      return {
        ...state,
        currentPriceFreeze: action.priceFreeze,
        priceFreezeCallState: action.priceFreezeCallState,
      };

    case actionTypes.SET_PRICE_FREEZE_CALL_STATE_FAILED:
      return {
        ...state,
        priceFreezeCallState: CallState.Failed,
      };

    case actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS:
      return {
        ...state,
        setUpFlightFreezeParamsCallState: CallState.InProcess,
        priceFreezeOfferCallState: CallState.InProcess,
      };

    case actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_SUCCEEDED:
      return {
        ...state,
        setUpFlightFreezeParamsCallState: CallState.Success,
      };

    case actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_FAILED:
      return {
        ...state,
        setUpFlightFreezeParamsCallState: CallState.Failed,
      };

    case actionTypes.REFUND_PRICE_FREEZE:
      return {
        ...state,
        refundPriceFreezeCallState: CallState.InProcess,
      };

    case actionTypes.SET_REFUND_PRICE_FREEZE:
      return {
        ...state,
        refundPriceFreezeCallState: action.refundPriceFreezeCallState,
      };

    case actionTypes.DISPLAY_PF_REFUND_MODAL_FLOW:
      return {
        ...state,
        displayPriceFreezeRefundModal: action.value,
      };

    case actionTypes.SET_PRICE_FREEZE_QUOTE_DATA:
      return {
        ...state,
        quoteData: action.quoteData,
      };

    case actionTypes.RESET_PRICE_FREEZE_PURCHASE:
      return {
        ...state,
        priceFreezeOfferCallState: initialState.priceFreezeOfferCallState,
        setUpFlightFreezeParamsCallState:
          initialState.setUpFlightFreezeParamsCallState,
        priceFreezeOffer: initialState.priceFreezeOffer,
        quoteData: initialState.quoteData,
      };

    default:
      return state;
  }
}

export * from "./selectors";
