import { Airline, FlightItinerarySegment } from "@b2bportal/air-booking-api";
import { IConfirmationNumber } from "./component";

export const HOPPER_WEBSITE = "https://www.hopper.com/";
export const HOPPER_TRAVEL = "Hopper Travel";

export interface IConfirmationModalLabels {
  confirmationCode: string;
  return: string;
  outbound: string;
  originToDestination: (origin: string, destination: string) => string;
}

export interface IGetConfirmationNumberProps {
  segment: FlightItinerarySegment;
  airlineMap: {
    [key: string]: Airline | undefined;
  };
  isReturn?: boolean;
  labels?: IConfirmationModalLabels;
}

export const getConfirmationNumber = ({
  segment,
  airlineMap,
  isReturn,
  labels,
}: IGetConfirmationNumberProps): IConfirmationNumber | false => {
  const selectedAirline = airlineMap[segment.marketingAirline.code];
  if (!selectedAirline) return false;
  const returnLabel = labels?.return;
  const outboundLabel = labels?.outbound;
  const originToDestinationLabel = labels?.originToDestination;

  const label =
    returnLabel && outboundLabel && originToDestinationLabel
      ? `${selectedAirline.displayName} ${
          isReturn ? returnLabel : outboundLabel
        }: ${
          originToDestinationLabel &&
          originToDestinationLabel(
            segment.origin.locationCode,
            segment.destination.locationCode
          )
        }`
      : selectedAirline.displayName;

  return {
    label: label,
    locator: segment.marketingAirline.locator || "",
    redirectUrl: selectedAirline.webLinks.manageBooking || "",
  };
};
