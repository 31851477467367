import {
  chatApi,
  ChatPropertiesRequest,
  ChatPropertiesType,
} from "@b2bportal/chat-api";
import { axiosInstance } from "@hopper-b2b/api";

export const chatProperties = async (
  chatPropertiesType: ChatPropertiesType,
  productId: string
) => {
  const request: ChatPropertiesRequest = {
    chatPropertiesType,
    productId,
  };
  const response = await chatApi(axiosInstance).apiV0SupportChatPropertiesPut(
    request
  );
  return response.data.chatProperties;
};
export default chatProperties;
