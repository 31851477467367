export const placeLabelToUrl = (label?: string) => {
  return label ? encodeURIComponent(label) : "";
};

export const placeLabelToCity = (label?: string) => {
  return label ? label.split(",")[0] : "";
};

export const urlToPlaceQuery = (url?: string) => {
  return url ? decodeURIComponent(url) : "";
};
