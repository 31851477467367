import { useId, useState } from "react";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { ButtonWrap } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useTrimmedString } from "../../hooks/useTrimmedString";
import styles from "./TextWithReadMore.module.scss";
import { HotelDetailsDialog } from "../HotelDetailsDialog";

interface Props {
  className?: string;
  readMoreClassName?: string;
  text?: string;
  modalTitle: string;
}

export function TextWithReadMore({
  text,
  modalTitle,
  className,
  readMoreClassName,
}: Props) {
  const { t } = useI18nContext();
  const [open, setOpen] = useState(false);
  const { trimmedString, showMore } = useTrimmedString(text, {
    sanitizeTrimmedHMTL: true,
  });

  if (!trimmedString) {
    return null;
  }

  return (
    <>
      <Typography
        className={className}
        dangerouslySetInnerHTML={{ __html: trimmedString }}
      />
      {showMore ? (
        <>
          <ButtonWrap
            className={clsx(readMoreClassName, styles.ReadMore)}
            onClick={() => setOpen(true)}
          >
            {t("readMore")}
          </ButtonWrap>
          <Modal
            open={open}
            title={modalTitle}
            closeButtonTitle={t("hotelShop.closeDialogButtonLabel")}
            text={text}
            onClose={() => setOpen(false)}
          />
        </>
      ) : null}
    </>
  );
}

function Modal({
  open,
  text,
  title,
  closeButtonTitle,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
  text: string;
  title: string;
  closeButtonTitle: string;
}) {
  const titleId = useId();

  return (
    <HotelDetailsDialog aria-labelledby={titleId} open={open} onClose={onClose}>
      <HotelDetailsDialog.TitleWithCloseButton
        titleId={titleId}
        onClose={onClose}
      >
        {title}
      </HotelDetailsDialog.TitleWithCloseButton>
      <HotelDetailsDialog.DialogContent>
        <Typography
          className={styles.FullText}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </HotelDetailsDialog.DialogContent>
      <HotelDetailsDialog.CloseButton
        title={closeButtonTitle}
        onClose={onClose}
      />
    </HotelDetailsDialog>
  );
}
