import React from "react";
import CommbankLifeSaverIcon from "../../assets/icons/commbank-au/lifesaver.svg";
import MagnifyingGlass from "../../assets/icons/commbank-au/magnify-glass.svg";
import CBAFamily from "../../assets/icons/commbank-au/CBAFamily.svg";
import CBAHome from "../../assets/icons/commbank-au/CBAHome.svg";
import CBAPlane from "../../assets/icons/commbank-au/CBAPlane.svg";
import AirPlane from "../../assets/icons/commbank-au/airplane.svg";
import YellowEye from "../../assets/icons/commbank-au/yellow-eye.svg";
import YellowGuard from "../../assets/icons/commbank-au/yellow-guard.svg";
import YellowWave from "../../assets/icons/commbank-au/yellow-wave.svg";
import StarIcon from "../../assets/icons/commbank-au/star.svg";
import TripAdvisorLogoIcon from "../../assets/icons/commbank-au/tripadvisor-logo.svg";
import EditPenIcon from "../../assets/icons/commbank-au/edit-pen.svg";
import LocationOutlinedIcon from "../../assets/icons/commbank-au/location-outlined.svg";
import RadioCheckedIcon from "../../assets/icons/commbank-au/radio-checked.svg";
import RadioUncheckedIcon from "../../assets/icons/commbank-au/radio-unchecked.svg";
import CirclePlusIcon from "../../assets/icons/commbank-au/circle-plus.svg";
import InfoIcon from "../../assets/icons/commbank-au/info.svg";
import InfoOutlinedIcon from "../../assets/icons/commbank-au/info-outlined.svg";
import WarnOutlinedIcon from "../../assets/icons/commbank-au/warn-outlined.svg";
import WarnIcon from "../../assets/icons/commbank-au/warn.svg";
import ChevronLeftIcon from "../../assets/icons/commbank-au/chevron-left.svg";
import ChevronDownIcon from "../../assets/icons/commbank-au/chevron-down.svg";
import CalendarIcon from "../../assets/icons/commbank-au/calendar.svg";
import CBACalendarIcon from "../../assets/icons/commbank-au/cba-calendar.svg";
import SpinnerIcon from "../../assets/icons/commbank-au/spinner.png";
import TravelWalletIcon from "../../assets/icons/commbank-au/travel-wallet.svg";
import PhoneIcon from "../../assets/icons/commbank-au/mobile-icon.svg";
import CircularCloseIcon from "../../assets/icons/commbank-au/circle-close.svg";
import CloseIcon from "../../assets/icons/commbank-au/close.svg";
import FilterIcon from "../../assets/icons/commbank-au/filter.svg";
import SavingsIcon from "../../assets/icons/commbank-au/savings.svg";
import LinkArrowIcon from "../../assets/icons/commbank-au/link-arrow.svg";
import MoneyIcon from "../../assets/icons/commbank-au/money.svg";
import PlaneDepartIcon from "../../assets/icons/commbank-au/plane-depart.svg";
import FlightDepartIcon from "../../assets/icons/commbank-au/flight-depart.svg";
import FlightArriveIcon from "../../assets/icons/commbank-au/flight-arrive.svg";
import PredictionDownIcon from "../../assets/icons/commbank-au/prediction-down.svg";
import PredictionGreenIcon from "../../assets/icons/commbank-au/prediction-green.svg";
import PredictionRedIcon from "../../assets/icons/commbank-au/prediction-red.svg";
import PredictionUpIcon from "../../assets/icons/commbank-au/prediction-up.svg";
import PredictionYellowIcon from "../../assets/icons/commbank-au/prediction-yellow.svg";
import ClockIcon from "../../assets/icons/commbank-au/clock.svg";
import LockIcon from "../../assets/icons/commbank-au/lock.svg";
import StopsIcon from "../../assets/icons/commbank-au/stops.svg";
import CheckedIcon from "../../assets/icons/commbank-au/checkbox-checked.svg";
import UncheckedIcon from "../../assets/icons/commbank-au/checkbox-unchecked.svg";
import HourglassIcon from "../../assets/icons/commbank-au/hourglass.svg";
import AdultIcon from "../../assets/icons/commbank-au/adult-icon.svg";
import ChildIcon from "../../assets/icons/commbank-au/child-icon.svg";
import EmailIcon from "../../assets/icons/commbank-au/email-icon.svg";
import ProtectionIcon from "../../assets/icons/commbank-au/protection.svg";
import TicketIcon from "../../assets/icons/commbank-au/ticket.svg";
import ItemSuccessIcon from "../../assets/icons/commbank-au/item-success.svg";
import ItemRemoveIcon from "../../assets/icons/commbank-au/item-remove.svg";
import SortIcon from "../../assets/icons/commbank-au/sort.svg";
import CBAPerson from "../../assets/icons/commbank-au/cba-person.svg";
import DoubleTreeIcon from "../../assets/icons/commbank-au/double-trees.svg";
import SofaSeatIcon from "../../assets/icons/commbank-au/sofa-seat.svg";
import SeatIcon from "../../assets/icons/commbank-au/seat.svg";
import LuggageIcon from "../../assets/icons/commbank-au/luggage.svg";
import DocumentsIcon from "../../assets/icons/commbank-au/documents.svg";
import CertificateIcon from "../../assets/icons/commbank-au/certificate.svg";
import NoteSignIcon from "../../assets/icons/commbank-au/note-sign.svg";
import MessagePopIcon from "../../assets/icons/commbank-au/message-pop.svg";
import CBACashIcon from "../../assets/icons/commbank-au/cba-cash.svg";
import CBATravelIcon from "../../assets/icons/commbank-au/cba-travel.svg";
import CBAPersonChildIcon from "../../assets/icons/commbank-au/cba-person-child.svg";
import ClientLogoIcon from "../../assets/commBank-logo.svg";
import HopperLogoDesktopIcon from "../../assets/hopper-logo-desktop.svg";
import HopperLogoMobileIcon from "../../assets/hopper-logo-mobile.svg";
import House from "../../assets/icons/commbank-au/house.svg";
import Map from "../../assets/icons/commbank-au/map.svg";
import Camera from "../../assets/icons/commbank-au/camera.svg";
import Restaurant from "../../assets/icons/commbank-au/restaurant.svg";
import Store from "../../assets/icons/commbank-au/store.svg";
import WorldMap from "../../assets/icons/commbank-au/world-map.svg";
import MoneyInIcon from "../../assets/icons/commbank-au/money-in.svg";
import InsightsIcon from "../../assets/icons/commbank-au/insights.svg";
import StatementsIcon from "../../assets/icons/commbank-au/statements.svg";
import ReviewIcon from "../../assets/icons/commbank-au/review.svg";
import TransferIcon from "../../assets/icons/commbank-au/cba-transfer.svg";

// icon with CBA prefix means design with yellow color, only for CBA.
export enum ApacIconName {
  CommbankLifeSaver = "life-saver",
  MagnifyingGlass = "magnifying-glass",
  CBAFamily = "cba-family",
  CBAPerson = "cba-person",
  CBAPersonChild = "cba-person-child",
  CBAHome = "cba-home",
  CBAPlane = "cba-plane",
  CBATransfer = "cba-transfer",
  AirPlane = "airplane",
  YellowEye = "yellow-eye",
  YellowGuard = "yellow-guard",
  YellowWave = "yellow-wave",
  Star = "star",
  TripAdvisorLogo = "tripadvisor-logo",
  EditPen = "edit-pen",
  LocationOutlined = "location-outlined",
  RadioChecked = "radio-checked",
  RadioUnchecked = "radio-unchecked",
  CirclePlus = "circle-plus",
  Info = "info",
  InfoOutlined = "info-outlined",
  WarnOutlined = "warn-outlined",
  Warn = "warn",
  ChevronLeft = "chevron-left",
  ChevronDown = "chevron-Down",
  Calendar = "calendar",
  CBACalendar = "cba-calendar",
  Spinner = "spinner",
  TravelWallet = "travel-wallet",
  Close = "Close",
  CircularClose = "CircularClose",
  Filter = "Filter",
  Phone = "phone",
  Savings = "savings",
  LinkArrow = "link-arrow",
  Money = "money",
  PlaneDepart = "plane-depart",
  FlightDepart = "flight-depart",
  FlightArrive = "flight-arrive",
  PredictionDown = "prediction-down",
  PredictionGreen = "prediction-green",
  PredictionRed = "prediction-red",
  PredictionUp = "prediction-up",
  PredictionYellow = "prediction-yellow",
  Clock = "clock",
  Lock = "lock",
  Stops = "stops",
  Checked = "Checked",
  Unchecked = "Unchecked",
  Hourglass = "Hourglass",
  Adult = "Adult",
  Child = "Child",
  Email = "Email",
  Protection = "Protection",
  Ticket = "Ticket",
  ItemSuccess = "ItemSuccess",
  ItemRemove = "ItemRemove",
  Sort = "Sort",
  DoubleTree = "DoubleTree",
  SofaSeat = "SofaSeat",
  Seat = "Seat",
  Luggage = "Luggage",
  Documents = "Documents",
  Certificate = "Certificate",
  NoteSign = "NoteSign",
  MessagePop = "MessagePop",
  CBACash = "CBACash",
  CBATravel = "CBATravel",
  ClientLogo = "ClientLogo",
  HopperLogoDesktop = "HopperLogoDesktop",
  HopperLogoMobile = "HopperLogoMobile",
  House = "house",
  Map = "map",
  Camera = "camera",
  Store = "store",
  Restaurant = "restaurant",
  WorldMap = "world-map",
  MoneyIn = "MoneyIn",
  Insights = "Insights",
  Statements = "Statements",
  Review = "Review",
}

type IconMap = {
  [name in ApacIconName]: string;
};

export const iconMap: IconMap = {
  [ApacIconName.CommbankLifeSaver]: CommbankLifeSaverIcon,
  [ApacIconName.MagnifyingGlass]: MagnifyingGlass,
  [ApacIconName.CBAFamily]: CBAFamily,
  [ApacIconName.CBAHome]: CBAHome,
  [ApacIconName.CBAPlane]: CBAPlane,
  [ApacIconName.CBAPerson]: CBAPerson,
  [ApacIconName.CBAPersonChild]: CBAPersonChildIcon,
  [ApacIconName.CBATransfer]: TransferIcon,
  [ApacIconName.AirPlane]: AirPlane,
  [ApacIconName.YellowEye]: YellowEye,
  [ApacIconName.YellowGuard]: YellowGuard,
  [ApacIconName.YellowWave]: YellowWave,
  [ApacIconName.Star]: StarIcon,
  [ApacIconName.TripAdvisorLogo]: TripAdvisorLogoIcon,
  [ApacIconName.EditPen]: EditPenIcon,
  [ApacIconName.LocationOutlined]: LocationOutlinedIcon,
  [ApacIconName.RadioChecked]: RadioCheckedIcon,
  [ApacIconName.RadioUnchecked]: RadioUncheckedIcon,
  [ApacIconName.CirclePlus]: CirclePlusIcon,
  [ApacIconName.Info]: InfoIcon,
  [ApacIconName.InfoOutlined]: InfoOutlinedIcon,
  [ApacIconName.WarnOutlined]: WarnOutlinedIcon,
  [ApacIconName.Warn]: WarnIcon,
  [ApacIconName.ChevronLeft]: ChevronLeftIcon,
  [ApacIconName.ChevronDown]: ChevronDownIcon,
  [ApacIconName.Calendar]: CalendarIcon,
  [ApacIconName.CBACalendar]: CBACalendarIcon,
  [ApacIconName.Spinner]: SpinnerIcon,
  [ApacIconName.TravelWallet]: TravelWalletIcon,
  [ApacIconName.Phone]: PhoneIcon,
  [ApacIconName.Close]: CloseIcon,
  [ApacIconName.CircularClose]: CircularCloseIcon,
  [ApacIconName.Filter]: FilterIcon,
  [ApacIconName.Savings]: SavingsIcon,
  [ApacIconName.LinkArrow]: LinkArrowIcon,
  [ApacIconName.Money]: MoneyIcon,
  [ApacIconName.PlaneDepart]: PlaneDepartIcon,
  [ApacIconName.FlightDepart]: FlightDepartIcon,
  [ApacIconName.FlightArrive]: FlightArriveIcon,
  [ApacIconName.PredictionDown]: PredictionDownIcon,
  [ApacIconName.PredictionGreen]: PredictionGreenIcon,
  [ApacIconName.PredictionRed]: PredictionRedIcon,
  [ApacIconName.PredictionUp]: PredictionUpIcon,
  [ApacIconName.PredictionYellow]: PredictionYellowIcon,
  [ApacIconName.Clock]: ClockIcon,
  [ApacIconName.Lock]: LockIcon,
  [ApacIconName.Stops]: StopsIcon,
  [ApacIconName.Checked]: CheckedIcon,
  [ApacIconName.Unchecked]: UncheckedIcon,
  [ApacIconName.Hourglass]: HourglassIcon,
  [ApacIconName.Adult]: AdultIcon,
  [ApacIconName.Child]: ChildIcon,
  [ApacIconName.Email]: EmailIcon,
  [ApacIconName.Protection]: ProtectionIcon,
  [ApacIconName.Ticket]: TicketIcon,
  [ApacIconName.ItemSuccess]: ItemSuccessIcon,
  [ApacIconName.ItemRemove]: ItemRemoveIcon,
  [ApacIconName.Sort]: SortIcon,
  [ApacIconName.DoubleTree]: DoubleTreeIcon,
  [ApacIconName.SofaSeat]: SofaSeatIcon,
  [ApacIconName.Seat]: SeatIcon,
  [ApacIconName.Luggage]: LuggageIcon,
  [ApacIconName.Documents]: DocumentsIcon,
  [ApacIconName.Certificate]: CertificateIcon,
  [ApacIconName.NoteSign]: NoteSignIcon,
  [ApacIconName.MessagePop]: MessagePopIcon,
  [ApacIconName.CBACash]: CBACashIcon,
  [ApacIconName.CBATravel]: CBATravelIcon,
  [ApacIconName.ClientLogo]: ClientLogoIcon,
  [ApacIconName.HopperLogoDesktop]: HopperLogoDesktopIcon,
  [ApacIconName.HopperLogoMobile]: HopperLogoMobileIcon,
  [ApacIconName.House]: House,
  [ApacIconName.Map]: Map,
  [ApacIconName.Camera]: Camera,
  [ApacIconName.Store]: Store,
  [ApacIconName.Restaurant]: Restaurant,
  [ApacIconName.WorldMap]: WorldMap,
  [ApacIconName.MoneyIn]: MoneyInIcon,
  [ApacIconName.Insights]: InsightsIcon,
  [ApacIconName.Statements]: StatementsIcon,
  [ApacIconName.Review]: ReviewIcon,
};

export interface ApacIconProps {
  name: ApacIconName;
  className?: string;
  ariaLabel?: string;
}

export const ApacIcon = ({
  name,
  ariaLabel,
  ...props
}: ApacIconProps): React.ReactElement => (
  <img
    src={iconMap[name]}
    alt={name}
    {...props}
    aria-label={ariaLabel ?? name}
  />
);
