import {
  CallState,
  PRICE_FREEZE_SIMILAR_FLIGHT_CONFIRMATION,
  PRICE_FREEZE_SIMILAR_FLIGHT_ERROR,
  TransferResponse,
  FlightItineraryEnum,
} from "@hopper-b2b/types";

import { put, select } from "redux-saga/effects";
import { trackEvent } from "@hopper-b2b/api";
import transferToSimilarFlights from "../../../api/v1/price-freeze/transferToSimilarFlights";
import Logger from "../../../helpers/Logger";
import { IStoreState } from "../../../reducers/types";
import { getSimilarFlightSelectedTripDetailsProperties } from "../../freeze/reducer/selectors";
import { actions } from "../actions";
import { IFetchTransferToSimilarFlights } from "../actions/actions";
import { flightApiConfigStoreKey } from "../../../reducers/types";

export function* fetchTransferSimilarFlightsSaga(
  action: IFetchTransferToSimilarFlights
) {
  const state: IStoreState = yield select();
  const apiConfig = state[flightApiConfigStoreKey];
  try {
    const response: TransferResponse = yield transferToSimilarFlights({
      id: action.id,
      itinerary: {
        fareId: action.fareId,
        tripId: action.tripId,
        Itinerary: FlightItineraryEnum.SingleItinerary,
      },
    });
    yield put(
      actions.setTransferToSimilarFlightsResponse({
        response,
        callState: CallState.Success,
      })
    );

    const properties = getSimilarFlightSelectedTripDetailsProperties(state);

    trackEvent(
      {
        eventName: PRICE_FREEZE_SIMILAR_FLIGHT_CONFIRMATION,
        properties,
      },
      apiConfig
    );
  } catch (e) {
    yield put(actions.setFetchTransferToSimilarFlightsCallStateFailed());

    const state: IStoreState = yield select();
    const properties = getSimilarFlightSelectedTripDetailsProperties(state);

    trackEvent(
      {
        eventName: PRICE_FREEZE_SIMILAR_FLIGHT_ERROR,
        properties,
      },
      apiConfig
    );
    Logger.debug(e);
  }
}
