import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  setOpenCalendarModal,
  setOpenLocationModal,
} from "../../actions/actions";
import { getRenderSearchInput, getTripCategory } from "../../../search/reducer";
import {
  fetchDepartureCalendar,
  setCalendar,
  setTripCategory,
  setRenderSearchInput,
} from "../../../search/actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { LocationModal } from "./component";

const mapDispatchToProps = {
  fetchDepartureCalendar,
  setCalendar,
  setTripCategory,
  setOpenCalendarModal,
  setOpenLocationModal,
  setRenderSearchInput,
};
const mapStateToProps = (state: IStoreState) => ({
  tripCategory: getTripCategory(state),
  renderSearchInput: getRenderSearchInput(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileFlightSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedLocationModal = withRouter(connector(LocationModal));
