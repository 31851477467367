import { disruptionRebookPlanPath } from "../paths";
import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import {
  DisruptionRebookPlanRequest,
  DisruptionRebookPlanResponse,
  NETWORK_CALL_FAILED,
} from "@hopper-b2b/types";

const disruptionRebookPlan = async (
  req: DisruptionRebookPlanRequest
): Promise<DisruptionRebookPlanResponse> => {
  try {
    const res = await axiosInstance.post<DisruptionRebookPlanResponse>(
      disruptionRebookPlanPath,
      req
    );
    const { data } = res;
    return data;
  } catch (e) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
    throw e;
  }
};

export default disruptionRebookPlan;
