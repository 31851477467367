import {
  HotelItinerary,
  HotelItinerarySummary,
  HotelItineraryResponse,
} from "@b2bportal/lodging-api";
import { MyTripsFilter, MyTripsModalTypes } from "@hopper-b2b/types";
import { call, put } from "redux-saga/effects";

import { fetchHotels } from "../../../api/v1/itinerary/fetchHotels";
import { fetchHotelReservation } from "../../../api/v1/itinerary/fetchHotelReservation";
import { actions } from "../actions";
import { addHotelType } from "../components/ItineraryList/components/FlightCard/helpers";
import { setUpMyTripsParams } from "./setUpTripsParams";

export function* fetchHotelsSaga(action: actions.IFetchHotels) {
  try {
    const { contactSupport, tripId } = yield call(setUpMyTripsParams, action);
    const hotelItinerariesResponse: HotelItineraryResponse = yield fetchHotels(
      action.request
    );
    const {
      Future = [],
      Canceled = [],
      Past = [],
      Present = [],
    } = hotelItinerariesResponse.itineraries;
    let selectedHotel: HotelItinerarySummary | null = null;
    let isSelectedHotelInPastTrips = false;
    selectedHotel = [...Future, ...Present].find(
      (hotel: HotelItinerarySummary) => hotel.reservationBookingId === tripId
    );
    if (!selectedHotel) {
      const pastSelectedHotel = [...Canceled, ...Past].find(
        (hotel: HotelItinerarySummary) => hotel.reservationBookingId === tripId
      );
      selectedHotel = pastSelectedHotel;
      isSelectedHotelInPastTrips = !!pastSelectedHotel;
    }

    if (isSelectedHotelInPastTrips) {
      yield put(actions.setTripsFilter(MyTripsFilter.PAST_TRIPS));
      yield put(actions.populateTripQueryParams(action.history, { tripId }));
    }
    if (selectedHotel) {
      const hotelItinerary: HotelItinerary = yield fetchHotelReservation(
        selectedHotel.reservationBookingId
      );
      yield put(actions.setSelectedHotel(hotelItinerary));

      if (contactSupport) {
        yield put(
          actions.setOpenModal({
            selectedItinerary: addHotelType(selectedHotel),
            type: MyTripsModalTypes.ContactSupport,
          })
        );
      }
    }
    yield put(actions.setHotels(hotelItinerariesResponse));
  } catch (e) {
    yield put(actions.fetchHotelsFailed());
    console.error(e);
  }
}
