import { Flights, TripSummary } from "@b2bportal/air-shopping-api";
import { FilteredFare } from "@hopper-b2b/types";
import dayjs from "dayjs";
import { IFlightListData } from "../../v3/components/FlightList/FlightList";

// Sort by Stops first and then sort by departure time within stops grouping
export const orderByStopsThenDeparture = (
  filteredFlights: IFlightListData[],
  flights: Flights
) => {
  return filteredFlights.sort((a, b) => {
    if (flights.slices[a.slice].stops === flights.slices[b.slice].stops) {
      return dayjs(flights.slices[a.slice].departure).diff(
        dayjs(flights.slices[b.slice].departure)
      );
    } else {
      return flights.slices[a.slice].stops - flights.slices[b.slice].stops;
    }
  });
};

export const orderByRecommended = (filteredFlights: TripSummary[]) => {
  return filteredFlights
    .sort(
      (a, b) =>
        Math.max(...b.tripFares.map((f) => f.fareScore.value)) -
        Math.max(...a.tripFares.map((f) => f.fareScore.value))
    )
    .map((flight) => flight.tripId);
};

export const orderByPrice = (filteredFlights: TripSummary[]) => {
  return filteredFlights
    .sort(
      (a, b) =>
        Math.min(...a.tripFares.map((f) => f.amount.fiat.value)) -
        Math.min(...b.tripFares.map((f) => f.amount.fiat.value))
    )
    .map((flight) => flight.tripId);
};

export const orderByDepartureTime = (
  filteredFlights: TripSummary[],
  tripSliceKey: string
) => {
  return filteredFlights
    .sort((a, b) =>
      dayjs(a[tripSliceKey]!.departureTime).diff(
        dayjs(b[tripSliceKey]!.departureTime)
      )
    )
    .map((flight) => flight.tripId);
};

export const orderByArrivalTime = (
  filteredFlights: TripSummary[],
  tripSliceKey: string
) => {
  return filteredFlights
    .sort((a, b) =>
      dayjs(a[tripSliceKey]!.arrivalTime).diff(
        dayjs(b[tripSliceKey]!.arrivalTime)
      )
    )
    .map((flight) => flight.tripId);
};

export const orderByStops = (
  filteredFlights: TripSummary[],
  tripSliceKey: string
) => {
  return filteredFlights
    .sort((a, b) => a[tripSliceKey].stops - b[tripSliceKey].stops)
    .map((flight) => flight.tripId);
};

export const orderByDuration = (
  filteredFlights: TripSummary[],
  tripSliceKey: string
) => {
  return filteredFlights
    .sort((a, b) => {
      const aMin = dayjs(a[tripSliceKey].arrivalTime).diff(
        dayjs(a[tripSliceKey].departureTime),
        "minute",
        true
      );
      const bMin = dayjs(b[tripSliceKey].arrivalTime).diff(
        dayjs(b[tripSliceKey].departureTime),
        "minute",
        true
      );

      return aMin - bMin;
    })
    .map((flight) => flight.tripId);
};

/// Flight Shop V2
export const orderByRecommendedV2 = (
  filteredFlights: IFlightListData[],
  flights: Flights
) => {
  return filteredFlights.sort(
    (a, b) =>
      flights.slices[b.slice].highestScore.value -
      flights.slices[a.slice].highestScore.value
  );
};

export const orderByPriceV2 = (filteredFlights: IFlightListData[]) => {
  return filteredFlights.sort(
    (a, b) =>
      Math.min(...a.fares.map((f: any) => f.amount.fiat.value)) -
      Math.min(...b.fares.map((f: any) => f.amount.fiat.value))
  );
};

export const orderByDepartureTimeV2 = (
  filteredFlights: IFlightListData[],
  flights: Flights
) => {
  return filteredFlights.sort((a, b) =>
    dayjs(flights.slices[a.slice].departure).diff(
      dayjs(flights.slices[b.slice].departure)
    )
  );
};

export const orderByArrivalTimeV2 = (
  filteredFlights: IFlightListData[],
  flights: Flights
) => {
  return filteredFlights.sort((a, b) =>
    dayjs(flights.slices[a.slice].arrival).diff(
      dayjs(flights.slices[b.slice].arrival)
    )
  );
};

export const orderByStopsV2 = (
  filteredFlights: IFlightListData[],
  flights: Flights
) => {
  return filteredFlights.sort(
    (a, b) => flights.slices[a.slice].stops - flights.slices[b.slice].stops
  );
};

export const orderByDurationV2 = (
  filteredFlights: IFlightListData[],
  flights: Flights
) => {
  return filteredFlights.sort((a, b) => {
    const aMin = dayjs(flights.slices[a.slice].arrival).diff(
      dayjs(flights.slices[a.slice].departure),
      "minute",
      true
    );
    const bMin = dayjs(flights.slices[b.slice].arrival).diff(
      dayjs(flights.slices[b.slice].departure),
      "minute",
      true
    );

    return aMin - bMin;
  });
};

// Flight Shop V3

// Sorting Fares
export const orderFaresByPrice = (filteredFares: FilteredFare[]) => {
  return filteredFares.sort(
    (a, b) =>
      (a.fare?.amount?.fiat?.value ?? Infinity) -
      (b.fare?.amount?.fiat?.value ?? Infinity)
  );
};

const cheapestFare = (filteredFares) => {
  let lowestPrice: number = Number.MAX_SAFE_INTEGER;
  let cheapest = filteredFares[0];
  filteredFares.forEach((fare) => {
    const value = fare.fare.amount.fiat.value;
    lowestPrice = value < lowestPrice ? value : lowestPrice;
    if (
      value < lowestPrice ||
      (value === lowestPrice &&
        (fare.fare.fareScore?.value < cheapest.fare.fareScore?.value ||
          fare.fare?.highestScore?.value < cheapest.fare?.highestScore?.value))
    ) {
      cheapest = fare;
    }
  });
  return cheapest;
};

export const orderFaresByRecommended = (filteredFares, flights: Flights) => {
  const cheapest = cheapestFare(filteredFares);
  const sortedFares = filteredFares.sort((a, b) => {
    //TODO: Remove after algo merch tags, puts the cheapest
    if (a.fare.fareSlice === cheapest.fare.fareSlice) {
      return -1;
    } else if (b.fare.fareSlice === cheapest.fare.fareSlice) {
      return 1;
    } else if (b?.fare?.fareScore) {
      return b.fare.fareScore.value - a.fare.fareScore.value;
    } else {
      return b.fare?.highestScore?.value - a.fare?.highestScore?.value;
    }
  });

  return sortedFares;
};

export const orderFaresByUberRecommended = (
  filteredFares: FilteredFare[],
  flights: Flights,
  isReturn: boolean
) => {
  // Uber wants the recommended sort to be by price, but have any with algomerch tags be placed at the beginning.
  return filteredFares.sort((a, b) => {
    const aFareSliceTags = !isReturn
      ? flights.fareSlices[a.fare.fareSlice]?.tags
      : flights.fareSlices[a.fare.return]?.tags;
    const bFareSliceTags = !isReturn
      ? flights.fareSlices[b.fare.fareSlice]?.tags
      : flights.fareSlices[b.fare.return]?.tags;
    const aHasTags =
      aFareSliceTags?.isBest ||
      aFareSliceTags?.isBestQuality ||
      aFareSliceTags?.isCheapest ||
      aFareSliceTags?.isFastest;
    const bHasTags =
      bFareSliceTags?.isBest ||
      bFareSliceTags?.isBestQuality ||
      bFareSliceTags?.isCheapest ||
      bFareSliceTags?.isFastest;
    if (aHasTags && bHasTags) {
      return (
        (a.fare?.amount?.fiat?.value ?? Infinity) -
        (b.fare?.amount?.fiat?.value ?? Infinity)
      );
    } else if (aHasTags) {
      // a has tags
      return -1;
    } else if (bHasTags) {
      // b has tags
      return 1;
    } else {
      // Neither have tags
      return (
        (a.fare?.amount?.fiat?.value ?? Infinity) -
        (b.fare?.amount?.fiat?.value ?? Infinity)
      );
    }
  });
};

export const orderFaresByStops = (
  filteredFares: FilteredFare[],
  flights: Flights
) => {
  return filteredFares.sort(
    (a, b) =>
      flights.slices[a.flight.slice].stops -
      flights.slices[b.flight.slice].stops
  );
};

export const orderFaresByDepartureTime = (
  filteredFares: FilteredFare[],
  flights: Flights
) => {
  return filteredFares.sort((a, b) =>
    dayjs(flights.slices[a.flight.slice].departure).diff(
      dayjs(flights.slices[b.flight.slice].departure)
    )
  );
};

export const orderFaresByArrivalTime = (
  filteredFares: FilteredFare[],
  flights: Flights
) => {
  return filteredFares.sort((a, b) =>
    dayjs(flights.slices[a.flight.slice].arrival).diff(
      dayjs(flights.slices[b.flight.slice].arrival)
    )
  );
};

export const orderFaresByDuration = (
  filteredFares: FilteredFare[],
  flights: Flights
) => {
  return filteredFares.sort((a, b) => {
    const aMin = dayjs(flights.slices[a.flight.slice].arrival).diff(
      dayjs(flights.slices[a.flight.slice].departure),
      "minute",
      true
    );
    const bMin = dayjs(flights.slices[b.flight.slice].arrival).diff(
      dayjs(flights.slices[b.flight.slice].departure),
      "minute",
      true
    );

    return aMin - bMin;
  });
};

// Sort by Stops first and then sort by departure time within stops grouping
export const orderFaresByStopsThenDeparture = (
  filteredFares: FilteredFare[],
  flights: Flights
) => {
  return filteredFares.sort((a, b) => {
    if (
      flights.slices[a.flight.slice].stops ===
      flights.slices[b.flight.slice].stops
    ) {
      return dayjs(flights.slices[a.flight.slice].departure).diff(
        dayjs(flights.slices[b.flight.slice].departure)
      );
    } else {
      return (
        flights.slices[a.flight.slice].stops -
        flights.slices[b.flight.slice].stops
      );
    }
  });
};
