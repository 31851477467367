import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";
import { removeTimezone } from "./dayjsHelpers";

export const DEFAULT_COUNTRY = {
  twoLetterCountryCode: "US",
  threeLetterCountryCode: "USA",
  flag: "🇺🇸",
  phonePrefix: "1",
};
/**
 * This is used to turn a `2024-05-22` date into an absolute date
 * The timezone needs to be removed otherwise everything west of UTC will come out the
 * previous day with new Date() constructor
 *
 * Issues Examples:
 * new Date(`2024-05-22`) => Tue May 21 2024 20:00:00 GMT-0400 (Eastern Daylight Time)
 * new Date(`2024-05-22`).toISOString() => 2024-05-22T00:00:00.000Z //This still has the timezone
 *
 * @param dateString
 */
export const dateStringToNewDate = (dateString: string): Date =>
  new Date(removeTimezone(new Date(dateString).toISOString()));

export const customFormatDateTime = (
  date: Date,
  locale: string,
  defaultFormat: DateTimeFormatStyle,
  customFormat?: (date: Date) => string
) =>
  customFormat
    ? customFormat(date)
    : formatDateTime(date, locale, defaultFormat);
