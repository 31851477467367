import { useEffect } from "react";

export interface IApacExternalRedirectProps {
  to: string;
}
/**
 * Component to automatically redirect to external urls
 * used instead of ReactRouter.Redirect for external URLs

 * to: External URL you want to redirect to
 *
 * example:
 * <ApacAutoRedirect to="https://travelbooking-faqs.hopper.com" />
 */
export const ApacAutoRedirect = ({ to }: IApacExternalRedirectProps) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
};
