import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import {
  fetchDepartureCalendar,
  populateSearchUrlParams,
  resetFilters,
  setCalendar,
  setCalendarVisited,
  setDepartureDate,
  setMobileSearchProgress,
  setRenderSearchInput,
  setReturnDate,
} from "../../actions/actions";
import {
  getCalendarVisited,
  getDepartureDate,
  getDepartureDateBucketsLoading,
  getDepartureMonths,
  getDestination,
  getMaxPriceFilter,
  getMobileSearchProgress,
  getOrigin,
  getPriceRangeLegends,
  getRenderSearchInput,
  getReturnDate,
  getTripCategory,
  hasSelectedDates,
  viewCalendarEventProperties,
} from "../../reducer";
import { MobileFlightSearchControl } from "./component";

const mapDispatchToProps = {
  fetchDepartureCalendar,
  setCalendar,
  setCurrentStep: setMobileSearchProgress,
  setRenderSearchInput,
  populateSearchUrlParams,
  setDepartureDate,
  setReturnDate,
  setCalendarVisited,
  resetFilters,
};

const mapStateToProps = (state: IStoreState) => ({
  tripCategory: getTripCategory(state),
  originCode: getOrigin(state)?.id.code.code,
  destinationCode: getDestination(state)?.id.code.code,
  origin: getOrigin(state),
  destination: getDestination(state),
  departureDateBucketsLoading: getDepartureDateBucketsLoading(state),
  currentStep: getMobileSearchProgress(state),
  departureDate: getDepartureDate(state),
  returnDate: getReturnDate(state),
  renderSearchInput: getRenderSearchInput(state),
  calendarVisited: getCalendarVisited(state),
  hasSelectedDates: hasSelectedDates(state),
  trackingProperties: viewCalendarEventProperties(state),
  maxPrice: getMaxPriceFilter(state),
  months: getDepartureMonths(state),
  priceTags: getPriceRangeLegends(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileFlightSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileFlightSearchControl = withRouter(
  connector(MobileFlightSearchControl)
);
