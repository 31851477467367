import { IconComponent, IconName } from "@hopper-b2b/ui";
import { Box, Collapse } from "@material-ui/core";
import { ReactNode, useState } from "react";
import { ApacIconComponent } from "../ApacIconComponent";
import { ApacIconName } from "../ApacIcon";
import "./ApacAccordion.scss";

interface ApacAccordionProps {
  initialOpen?: boolean;
  header: ReactNode;
  children?: ReactNode;
}

export const ApacAccordion = ({
  initialOpen = false,
  header,
  children,
}: ApacAccordionProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  return (
    <Box className="apac-accordion">
      <Box
        className="apac-accordion-header "
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {header}

        <ApacIconComponent
          className={"chevron-icon" + (isOpen ? " is-open" : "")}
          name={ApacIconName.ChevronDown}
        />
      </Box>

      <Collapse in={isOpen}>
        <Box className="apac-accordion-content">{children}</Box>
      </Collapse>
    </Box>
  );
};
