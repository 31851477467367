import { put, select } from "redux-saga/effects";
import { actions } from "../actions";
import { productToEarn } from "../../../api/v1/rewards/productToEarn";
import { EarnResponse, TravelProductEnum } from "@hopper-b2b/types";
import { IStoreState } from "../../../reducers/types";
import {
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getCardPaymentAccount,
} from "../reducer";
import Logger from "../../../helpers/Logger";
import { flightApiConfigStoreKey } from "../../../reducers/types";

export function* fetchProductToEarnSaga() {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const paymentTotal = getTotalCreditCardPaymentRequiredInFiatPrice(state);
    const { accountReferenceId } = getCardPaymentAccount(state) || {};

    // If we have not yet selected a credit card, or there is no payment total do nothing.
    if (!accountReferenceId || !paymentTotal.value) {
      return;
    }

    const result: EarnResponse = yield productToEarn(
      {
        account: accountReferenceId,
        travelProduct: TravelProductEnum.Flights,
        amountUsd: paymentTotal.value,
      },
      apiConfig
    );

    yield put(actions.setEarnValue(result.amount));
  } catch (e) {
    Logger.debug(e);
  }
}
