import { put, select } from "redux-saga/effects";
import { CallState, FetchOfferResponse } from "@hopper-b2b/types";

import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import { getPriceFreezeOffer } from "../../../api/v1/price-freeze/getPriceFreezeOffer";
import { flightApiConfigStoreKey } from "../../../reducers/types";
import { IStoreState } from "../../../reducers/types";

export function* getPriceFreezeOfferSaga(action: actions.IGetPriceFreezeOffer) {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const response: FetchOfferResponse = yield getPriceFreezeOffer(
      {
        offerId: action.offerId,
      },
      apiConfig
    );
    const { offer } = response;

    yield put(
      actions.setPriceFreezeOffer({
        offer,
        priceFreezeOfferCallState: CallState.Success,
      })
    );
  } catch (e) {
    yield put(actions.setPriceFreezeOfferCallStateFailed());
    Logger.debug(e);
  }
}
