import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import {
  populateTripQueryParams,
  setTripsFilter,
} from "../../../TripsList/actions/actions";
import { getTripsFilter, getTripsCounts } from "../../../TripsList/reducer";
import { MobileFilterMenu } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  tripsFilter: getTripsFilter(state),
  tripsCounts: getTripsCounts(state),
});

const mapDispatchToProps = { setTripsFilter, populateTripQueryParams };

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IMobileFilterMenuConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileFilterMenu = connector(MobileFilterMenu);
