import { ApacIcon, ApacIconName } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { IFlightGridFareInfo, IFlightGridFares } from "@hopper-b2b/types";
import { RemoteAirlineIcon } from "@hopper-b2b/ui";
import { removeTimezone } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import "./DesktopFlightCard.styles.scss";

export interface IDesktopFlightCardProps {
  airlineCode: string;
  airlineName: string;
  departureTime: string;
  arrivalTime: string;
  originCode: string;
  destinationCode: string;
  duration: string;
  layoverString: string;
  fares: IFlightGridFares;
  className?: string;
  plusDays?: number;
  onAlgomerchClick?: (tag?: string) => void;
}

export const DesktopFlightCard = (props: IDesktopFlightCardProps) => {
  const {
    airlineCode,
    airlineName,
    departureTime,
    arrivalTime,
    originCode,
    destinationCode,
    duration,
    layoverString,
    fares,
    className,
    plusDays,
    onAlgomerchClick,
  } = props;

  const { t, formatFiatCurrency } = useI18nContext();

  const DEFAULT_TIME_FORMAT = "hh:mm A";

  // time string already a timezoned timestamp, remove timezone will get timzone local time
  const formatTime = (time: string) =>
    dayjs(removeTimezone(time)).format(DEFAULT_TIME_FORMAT);

  const listCardFareItem = (key: string, value: IFlightGridFareInfo) => {
    return (
      <Box className={clsx("fare-container", className)} key={key}>
        {!!value && (
          <div className="fare-content-container">
            <div className="score-container">
              {!!value?.tags?.length && (
                <div
                  className="score-wrapper"
                  onClick={(e) => {
                    e.stopPropagation();
                    onAlgomerchClick?.();
                  }}
                >
                  <Typography className="font-small" variant="body1">
                    {value.tags[0]}
                  </Typography>

                  <ApacIcon name={ApacIconName.InfoOutlined} />
                </div>
              )}
            </div>
            <div className="price-wrapper">
              <div className="fare-name">
                <Typography variant="h6">{value.fareName}</Typography>
              </div>
              <div>
                <Typography variant="h6">
                  {formatFiatCurrency(value.fiatPrice)}
                </Typography>
                {!!value?.reward && (
                  <Typography variant="body2">{`${t("or")} ${
                    value?.reward?.split(" ")[0]
                  }`}</Typography>
                )}
                {!!value?.reward && (
                  <Typography variant="body2">
                    {t("commBank.flightBook.payment.awardsPoints")}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        )}
      </Box>
    );
  };

  return (
    <Box className="desktop-flight-item-summary-card">
      <Box className="row-header">
        <div className="airline-logo">
          <RemoteAirlineIcon
            size="medium"
            airlineCode={airlineCode}
            altText={airlineName}
          />
        </div>
        <div className="time-container">
          <div className="col">
            <Typography variant="h6">{formatTime(departureTime)}</Typography>
            <Typography className="font-small" variant="body1">
              {originCode}
            </Typography>
          </div>
          <div className="col duration">
            <ApacIcon name={ApacIconName.AirPlane} />
            <Typography className="font-small" variant="body1">
              {duration}
            </Typography>
          </div>
          <div className="col">
            <Typography variant="h6">{formatTime(arrivalTime)}</Typography>
            <Typography className="font-small" variant="body1">
              {destinationCode}
            </Typography>
          </div>
          {!!plusDays && (
            <Typography className="plus-days font-small" variant="h6">
              +{plusDays}
            </Typography>
          )}
        </div>
        <div className="desktop-flight-card-row-divider" />
        <div className="bottom">
          <Typography className="font-small" variant="body2">
            {airlineName}
          </Typography>
          <Typography variant="body2">•</Typography>
          <Typography className="font-small" variant="body2">
            {layoverString}
          </Typography>
        </div>
      </Box>
      {Object.entries(fares).map(([key, value]) =>
        listCardFareItem(key, value)
      )}
    </Box>
  );
};
