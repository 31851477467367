import { ApacIcon, ApacIconName } from "@commbank/ui";
import { LodgingData } from "@b2bportal/lodging-api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap, TripAdvisorBadge } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, DialogTitle, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useId, useState } from "react";
import { HotelDetailsDialog } from "../../../HotelDetailsDialog";
import { ModalComment } from "../ModalComment";
import { ReviewsCategories } from "../ReviewsCategories";
import styles from "./MoreReview.module.scss";

type Props = Pick<
  LodgingData["tripAdvisorReviews"],
  "overallScore" | "reviewsCount" | "comments" | "categories"
>;

export function MoreReviews({
  overallScore,
  reviewsCount,
  comments,
  categories,
}: Props) {
  const { matchesMobile: isMobile } = useDeviceTypes();
  const { t } = useI18nContext();
  const titleId = useId();
  const [open, setOpen] = useState(false);

  const onClose = () => setOpen(false);

  // On mobile we want one column; on desktop we want two columns
  const columns = isMobile ? [comments] : splitArrayAlternating(comments);

  return (
    <>
      <ButtonWrap className={styles.MoreReviews} onClick={() => setOpen(true)}>
        {t("moreReviews")}
      </ButtonWrap>
      <HotelDetailsDialog
        id="more-review-modal"
        aria-labelledby={titleId}
        // TODO:
        open={open}
        onClose={onClose}
        maxWidth="md"
      >
        <DialogTitle
          className={clsx(
            styles.DialogTitle,
            isMobile && styles.DialogTitleMobile,
            "title-container",
            isMobile ? "mobile" : null
          )}
          disableTypography
        >
          <Box className={styles.TitleSummary}>
            <ApacIcon name={ApacIconName.TripAdvisorLogo} />
            <Typography component="h2" variant="h3" id={titleId}>
              {t("customerReviews")}
            </Typography>
            {reviewsCount > 1 && (
              <Typography id={titleId} className="subtitle">
                {t("lodgingShopDetails.tripAdvisorSummary", {
                  average: (overallScore * 5).toFixed(1),
                  reviewsCount,
                })}
              </Typography>
            )}

            {!isMobile && (
              <Summary
                overallScore={overallScore}
                reviewsCount={reviewsCount}
              />
            )}
            <ReviewsCategories
              categories={categories}
              className={clsx(styles.Categories, isMobile ? "mobile" : null)}
            />
          </Box>

          <HotelDetailsDialog.CloseIconButton onClose={onClose} />
        </DialogTitle>
        <HotelDetailsDialog.DialogContent className={styles.DialogContent}>
          {!isMobile && (
            <Typography component="h3" variant="h4">
              {t("recentGuestReviews")}
            </Typography>
          )}

          <div className={styles.Columns}>
            {columns.map((column, index) => {
              return (
                <div key={index} className={styles.Comments}>
                  {column.map(({ title, text, created, score, author }) => (
                    <ModalComment
                      key={title + created}
                      title={title}
                      text={text}
                      created={created}
                      score={score}
                      author={author}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        </HotelDetailsDialog.DialogContent>
        <HotelDetailsDialog.CloseButton
          title={t("hotelShop.closeDialogButtonLabel")}
          onClose={onClose}
        />
      </HotelDetailsDialog>
    </>
  );
}

function Summary({
  overallScore,
  reviewsCount,
}: {
  overallScore: number;
  reviewsCount: number;
}) {
  const scoreOutOfFive = overallScore * 5;

  return (
    <div className={styles.Summary}>
      <strong>{scoreOutOfFive.toFixed(1)}</strong>
      <div>
        <TripAdvisorBadge
          translateToTheLeft
          withTripAdvisorIcon
          scoreOutOfFive={scoreOutOfFive}
        />
        <Typography>
          <I18nMarkup
            tKey="tripAdvisorReviewCountNoParentheses_other"
            replacements={{
              reviewsCount,
            }}
          />
        </Typography>
      </div>
    </div>
  );
}

function splitArrayAlternating<T>(array: T[]) {
  const array1: T[] = [];
  const array2: T[] = [];

  array.forEach((item, index) => {
    if (index % 2 === 0) {
      array1.push(item);
    } else {
      array2.push(item);
    }
  });

  return [array1, array2];
}
