import { Box, Divider } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import clsx from "clsx";
import { ChangeEvent, Fragment, useCallback, useEffect, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";

import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import { HotelItinerary, HotelItinerarySummary } from "@b2bportal/lodging-api";
import {
  CarReservation,
  ItineraryEnum,
  ItinerarySummaryWithType,
  ItineraryWithType,
} from "@hopper-b2b/types";
import { getHotelItinerarySummaryWithType } from "@hopper-b2b/utilities";

import { fetchHotelReservation } from "../../../../api/v1/itinerary/fetchHotelReservation";
import { FlightCard } from "../FlightCard";
import { HotelCard } from "../HotelCard";
import { PAGE_SIZE } from "./constants";
import { ItineraryListConnectorProps } from "./container";
import "./styles.scss";

interface IItineraryListProps
  extends ItineraryListConnectorProps,
    // eslint-disable-next-line @typescript-eslint/ban-types
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {
  isMobile?: boolean;
  tripSearchResults?:
    | (HotelItinerary & { type: ItineraryEnum.Hotel })
    | (BookedFlightItineraryWithDepartureTime & { type: ItineraryEnum.Flight })
    | null;
}

export const scrollTopWithOffset = (element: HTMLDivElement) => {
  const offset = 221; //header (211px) + padding below header (10px)
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

const getItineraryId = (itinerary: ItineraryWithType) => {
  if (itinerary.type === ItineraryEnum.Flight) {
    const flight = itinerary as BookedFlightItineraryWithDepartureTime;
    return flight.bookedItinerary.id;
  }
  if (itinerary.type === ItineraryEnum.Hotel) {
    const hotel = itinerary as HotelItinerarySummary;
    return hotel.reservationBookingId;
  }
  if (itinerary.type === ItineraryEnum.Car) {
    const car = itinerary as CarReservation;
    return car.bookResult.groundBookingId;
  }
  return "";
};

export const ItineraryList = (props: IItineraryListProps) => {
  const {
    history,
    itinerariesToDisplay,
    isMobile,
    populateTripQueryParams,
    selectedFlight,
    selectedHotel,
    selectedCar,
    setSelectedFlight,
    setSelectedHotel,
    setSelectedCar,
    tripSearchResults,
  } = props;

  const getExpandedCard = useCallback(() => {
    if (selectedFlight) {
      return getItineraryId({ ...selectedFlight, type: ItineraryEnum.Flight });
    }
    if (selectedHotel) {
      return selectedHotel.reservation.reservationBookingId;
    }
    // if (selectedCar) {
    //   return getItineraryId({ ...selectedCar, type: ItineraryEnum.Car });
    // }
    return "";
  }, [selectedFlight, selectedHotel]);

  const [fetchReservationError, setFetchReservationError] = useState(false);
  const [expandedCard, setExpandedCard] = useState(getExpandedCard());
  const [expandedHotelCardDetails, setExpandedHotelCardDetails] =
    useState<HotelItinerary>(null);
  useEffect(() => {
    const newExpandedCard = getExpandedCard();
    if (newExpandedCard && newExpandedCard !== expandedCard) {
      setExpandedCard(newExpandedCard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlight, selectedHotel, selectedCar]);

  const onCloseCard = useCallback(
    (itinerary: ItineraryWithType) => {
      setExpandedCard("");
      setFetchReservationError(false);
      if (itinerary.type === ItineraryEnum.Flight) {
        setSelectedFlight(null);
      }
      if (itinerary.type === ItineraryEnum.Hotel) {
        setSelectedHotel(null);
      }
      // if (itinerary.type === ItineraryEnum.Car) {
      //   setSelectedCar(null);
      // }
      populateTripQueryParams(history);
    },
    [history, populateTripQueryParams, setSelectedFlight, setSelectedHotel]
  );

  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [itinerariesToShow, setItinerariesToShow] = useState<
    (ItineraryWithType | ItinerarySummaryWithType)[]
  >([]);

  useEffect(() => {
    if (tripSearchResults) {
      if (tripSearchResults.type === ItineraryEnum.Hotel) {
        const tripSearchResultsSummary = getHotelItinerarySummaryWithType(
          tripSearchResults as HotelItinerary
        );
        setItinerariesToShow([tripSearchResultsSummary]);
      } else {
        setItinerariesToShow([tripSearchResults]);
      }
    }
  }, [tripSearchResults, setItinerariesToShow, itinerariesToDisplay]);

  const handlePageChange = useCallback(
    (_event: ChangeEvent<unknown>, value: number) => {
      setPage(value);
      selectedCar && setSelectedCar(null);
      selectedHotel && setSelectedHotel(null);
      selectedFlight && setSelectedFlight(null);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    [
      selectedCar,
      selectedFlight,
      selectedHotel,
      setSelectedCar,
      setSelectedFlight,
      setSelectedHotel,
    ]
  );

  const setTripsPage = useCallback(() => {
    let selectedItinerary = null;
    if (selectedCar) {
      selectedItinerary = { ...selectedCar, type: ItineraryEnum.Car };
    }
    const selectedItineraryId =
      selectedItinerary && getItineraryId(selectedItinerary);
    const selectedIndex = itinerariesToDisplay.findIndex(
      (itinerary) => getItineraryId(itinerary) === selectedItineraryId
    );
    const currentPage = Math.ceil((selectedIndex + 1) / PAGE_SIZE);
    if (selectedIndex !== -1 && page !== currentPage) {
      setPage(currentPage);
    }
  }, [itinerariesToDisplay, page, selectedCar]);

  const getCurrentItems = useCallback(() => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    return itinerariesToDisplay.slice(startIndex, endIndex);
  }, [itinerariesToDisplay, page]);

  const onExpandCard = useCallback(
    (itinerary: ItineraryWithType) => {
      setExpandedCard(getItineraryId(itinerary));
      if (itinerary.type === ItineraryEnum.Flight) {
        if (selectedHotel) setSelectedHotel(null);
        if (selectedCar) setSelectedCar(null);
        const selectedFlight =
          itinerary as BookedFlightItineraryWithDepartureTime;
        setSelectedFlight(selectedFlight);
        populateTripQueryParams(history, {
          tripId: selectedFlight.bookedItinerary.id,
        });
      }
      if (itinerary.type === ItineraryEnum.Hotel) {
        if (selectedFlight) setSelectedFlight(null);
        if (selectedCar) setSelectedCar(null);
        if (itinerary.lodgingSummary) {
          fetchHotelReservation(itinerary.reservationBookingId)
            .then((hotel) => {
              setFetchReservationError(false);
              setSelectedHotel(hotel);
              populateTripQueryParams(history, {
                tripId: hotel.reservation.reservationBookingId,
              });
              setExpandedHotelCardDetails(hotel);
            })
            .catch((error) => {
              setFetchReservationError(true);
            });
        }
      }
    },
    [
      history,
      populateTripQueryParams,
      selectedCar,
      selectedFlight,
      selectedHotel,
      setSelectedCar,
      setSelectedFlight,
      setSelectedHotel,
    ]
  );

  const onExpandToggleClick = useCallback(
    (itinerary: ItineraryWithType) => (cardId: string) => {
      cardId === expandedCard
        ? onCloseCard(itinerary)
        : onExpandCard(itinerary);
    },
    [expandedCard, onCloseCard, onExpandCard]
  );

  // Update itinerary type
  const renderTripCard = (itinerary: ItineraryWithType) => {
    if (itinerary.type === ItineraryEnum.Flight) {
      return (
        <FlightCard
          isMobile={isMobile}
          onExpandCard={onExpandToggleClick(itinerary)}
          expandedCard={expandedCard}
          flight={itinerary as BookedFlightItineraryWithDepartureTime}
        />
      );
    }
    if (itinerary.type === ItineraryEnum.Hotel) {
      return (
        <HotelCard
          key={itinerary.reservationBookingId}
          isMobile={isMobile}
          hotel={itinerary}
          onExpandCard={onExpandToggleClick(itinerary)}
          expandedCard={expandedCard}
          reservationDetails={
            itinerary.reservationBookingId === expandedCard
              ? expandedHotelCardDetails
              : null
          }
          fetchReservationError={fetchReservationError}
        />
      );
    }

    return null;
  };

  const getTotalPages = () =>
    Math.ceil(itinerariesToDisplay.length / PAGE_SIZE);

  useEffect(() => {
    if (!tripSearchResults) {
      setItinerariesToShow(getCurrentItems());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, tripSearchResults]);

  useEffect(() => {
    if (!tripSearchResults) {
      if (itinerariesToDisplay.length > 0) {
        const totalPagesToUpdate = getTotalPages();
        if (totalPagesToUpdate !== totalPages) {
          setTotalPages(getTotalPages());
        }
        setTripsPage();
        setItinerariesToShow(getCurrentItems());
      } else {
        setItinerariesToShow([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itinerariesToDisplay]);

  return (
    <Box className={clsx({ mobile: isMobile }, "my-trips-list", "apac")}>
      {itinerariesToShow.map((itinerary) => (
        <Fragment key={getItineraryId(itinerary)}>
          {renderTripCard(itinerary)}
        </Fragment>
      ))}
      <Box className={clsx("trips-pagination-container", { mobile: isMobile })}>
        {itinerariesToDisplay.length > 0 ? (
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            siblingCount={1}
          />
        ) : null}
      </Box>
    </Box>
  );
};
