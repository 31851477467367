import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import {
  ActionButton,
  B2BLoadingPopup,
  Checkbox,
  ErrorPopup,
  LearnMore,
  MobileFlightSummaryRowNew,
  MobileFloatingButton,
} from "@hopper-b2b/ui";
import {
  DisruptionExerciseProductType,
  DisruptionExerciseProgress,
  DisruptionFetchOfferResponseEnum,
  DisruptionRebookPlanResponse,
  FlightSummaryInfo,
  FlightSummaryInfoWithKeys,
  getSliceSummaryInfo,
} from "@hopper-b2b/types";
import { FlightItinerarySegment } from "@b2bportal/air-booking-api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { ClientContext } from "../../../../../App";
import {
  resetDisruptionExerciseState,
  setDisruptionExerciseProgress,
} from "../../../../TripsList/actions/actions";
import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import disruptionRebookPlan from "../../../../../api/v1/disruption/disruptionRebookPlan";
import {
  getAirlinesMap,
  getAirportMap,
  getDisruptionExerciseProductType,
} from "../../../../TripsList/reducer";
import { TripSlice } from "@b2bportal/air-shopping-api";
import dayjs from "dayjs";
import { toggleAdaChat } from "@hopper-b2b/utilities";

export const FlightSelect = ({
  contractId,
  flight,
  onFlightSelectContinue,
  disableFloatingButton,
}: {
  contractId: string;
  flight: BookedFlightItineraryWithDepartureTime;
  disableFloatingButton?: boolean;
  onFlightSelectContinue: (props: {
    origin: string;
    destination: string;
    tripSlice: TripSlice;
  }) => void;
}) => {
  const clientContext = useContext(ClientContext);
  const { t } = useI18nContext();
  const dispatch = useDispatch();

  const disruptionProductType = useSelector(getDisruptionExerciseProductType);
  const airportMap = useSelector(getAirportMap);
  const airlinesMap = useSelector(getAirlinesMap);

  const [selectedSegments, setSelectedSegments] = useState<
    Array<FlightItinerarySegment>
  >([]);

  const [slices, setSlices] =
    useState<{ segments: FlightItinerarySegment[] }[]>();
  const [isRoundTrip, setIsRoundTrip] = useState<boolean>(false);
  const [outboundSlices, setOutboundSlices] =
    useState<FlightItinerarySegment[]>();
  const [returnSlices, setReturnSlices] = useState<FlightItinerarySegment[]>();
  const [isOutboundSelected, setIsOutboundSelected] = useState<boolean>(true);

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const fetchDisruptionRebookPlan = useCallback(() => {
    if (contractId) {
      disruptionRebookPlan({ contractId }).then(
        (response: DisruptionRebookPlanResponse) => {
          if (
            response.DisruptionRebookPlanResponse ===
              DisruptionFetchOfferResponseEnum.RebookPlanSuccess ||
            response.DisruptionRebookPlanResponse ===
              DisruptionFetchOfferResponseEnum.Success
          ) {
            setIsRoundTrip(response.slices.length > 1);
            setOutboundSlices(response.slices?.at(0).segments);

            setReturnSlices(response.slices?.at(-1).segments);
            setSlices(response.slices);
          } else {
            // throw error modal
            setOutboundSlices([]);
            setShowErrorModal(true);
          }
        }
      );
    }
  }, [contractId]);

  useEffect(() => {
    fetchDisruptionRebookPlan();
  }, [fetchDisruptionRebookPlan]);

  const onContinue = useCallback(() => {
    const firstSegment = selectedSegments[0];
    const lastSegment = selectedSegments[selectedSegments.length - 1];
    const origin = firstSegment.origin.locationCode;
    const destination = lastSegment.destination.locationCode;
    // construct tripSlice object to pass down the road
    const tripSlice: TripSlice = {
      departureTime: firstSegment.scheduledDeparture,
      zonedDepartureTime: firstSegment.zonedScheduledDeparture,
      outgoing: false,
      arrivalTime: lastSegment.scheduledArrival,
      destinationName: lastSegment.destination.locationCode,
      originCode: firstSegment.origin.locationCode,
      id: "",
      segmentDetails: selectedSegments,
      originName: firstSegment.origin.terminalName,
      totalDurationMinutes: dayjs(lastSegment.scheduledArrival).diff(
        firstSegment.scheduledDeparture,
        "minutes"
      ),
      destinationCode: lastSegment.destination.locationCode,
      stops: selectedSegments.length - 1,
    };

    onFlightSelectContinue({
      origin: origin,
      destination: destination,
      tripSlice: tripSlice,
    });
    dispatch(
      setDisruptionExerciseProgress(DisruptionExerciseProgress.FlightSearch)
    );
  }, [dispatch, onFlightSelectContinue, selectedSegments]);

  const mapTitleKeys = ({
    titleKeys,
    ...summary
  }: FlightSummaryInfoWithKeys): FlightSummaryInfo => {
    const title = t("originToDestination", titleKeys);
    return {
      title,
      ...summary,
    };
  };

  const shouldDisableSegmentSelection = useCallback(
    (segment: FlightItinerarySegment, isOutbound: boolean) => {
      return isOutbound
        ? disruptionProductType ===
            DisruptionExerciseProductType.MissedConnection &&
            outboundSlices.indexOf(segment) === 0
        : disruptionProductType ===
            DisruptionExerciseProductType.MissedConnection &&
            returnSlices.indexOf(segment) === 0;
    },
    [disruptionProductType, outboundSlices, returnSlices]
  );

  const handleSegmentSelect = useCallback(
    (segment: FlightItinerarySegment) => {
      if (
        shouldDisableSegmentSelection(segment, outboundSlices.includes(segment))
      ) {
        // You can't exercise missed connection on the first segment
        return;
      }
      if (outboundSlices.includes(segment)) {
        if (shouldDisableSegmentSelection(segment, true)) {
          return;
        }
        if (isOutboundSelected) {
          let newSegments = [...selectedSegments];
          newSegments = outboundSlices.slice(outboundSlices.indexOf(segment));
          setIsOutboundSelected(true);
          return setSelectedSegments(newSegments);
        }
      } else {
        if (shouldDisableSegmentSelection(segment, false)) {
          return;
        }
        let newSegments = [...selectedSegments];
        newSegments = returnSlices.slice(returnSlices.indexOf(segment));
        setIsOutboundSelected(true);
        return setSelectedSegments(newSegments);
      }
    },
    [
      outboundSlices,
      returnSlices,
      selectedSegments,
      shouldDisableSegmentSelection,
    ]
  );

  const handleContactSupport = useCallback(() => {
    toggleAdaChat();
    dispatch(resetDisruptionExerciseState());
  }, [dispatch]);

  const handleTryAgain = useCallback(() => {
    dispatch(resetDisruptionExerciseState());
  }, [dispatch]);

  return showErrorModal ? (
    <ErrorPopup
      open={showErrorModal}
      title={t("disruptionExercise.error.title")}
      subtitle={t("disruptionExercise.error.planErrorSubtitle")}
      primaryButtonText={t("disruptionExercise.error.primaryButton")}
      primaryOnClick={handleTryAgain}
      secondaryButtonText={t("contactSupport")}
      secondaryOnClick={handleContactSupport}
    />
  ) : slices === undefined ? (
    <B2BLoadingPopup
      open
      fullScreen={true}
      message={t("disruptionExercise.loadingFlightSelect")}
      popupSize="mobile"
    />
  ) : (
    <div className="exercise-flight-select">
      <div className="exercise-flight-select-header">
        <h2 className="exercise-flight-select-title">
          {t("disruptionExercise.flightSelectTitle")}
        </h2>
      </div>
      <div className="exercise-flight-select-flights">
        {outboundSlices.map((segment: FlightItinerarySegment, i) => {
          const sliceSummaryInfo = mapTitleKeys(
            getSliceSummaryInfo(true, segment, airportMap, airlinesMap)
          );
          return (
            <div
              className={clsx("flight-row")}
              onClick={() => handleSegmentSelect(segment)}
              key={`outbound-${i}`}
            >
              <div className="mobile-flight-summary">
                <MobileFlightSummaryRowNew
                  flightSummaryInfo={sliceSummaryInfo}
                  hideIcon
                >
                  <div className="flight-row--checkbox">
                    <Checkbox
                      selected={selectedSegments?.includes(segment)}
                      iconSrc={clientContext?.assets?.checkmark}
                      disabled={shouldDisableSegmentSelection(segment, true)}
                    />
                  </div>
                </MobileFlightSummaryRowNew>
              </div>
            </div>
          );
        })}
        <hr />
        {isRoundTrip ? (
          <>
            {returnSlices.map((slice, i) => {
              const sliceSummaryInfo = mapTitleKeys(
                getSliceSummaryInfo(true, slice, airportMap, airlinesMap)
              );
              return (
                <div
                  className={clsx("flight-row")}
                  onClick={() => handleSegmentSelect(slice)}
                  key={`return-${i}`}
                >
                  <div className="mobile-flight-summary">
                    <MobileFlightSummaryRowNew
                      flightSummaryInfo={sliceSummaryInfo}
                      hideIcon
                    >
                      <div className="flight-row--checkbox">
                        <Checkbox
                          selected={selectedSegments?.includes(slice)}
                          iconSrc={clientContext?.assets?.checkmark}
                          disabled={shouldDisableSegmentSelection(slice, false)}
                        />
                      </div>
                    </MobileFlightSummaryRowNew>
                  </div>
                </div>
              );
            })}
            <hr />
          </>
        ) : null}
      </div>
      <div className="exercise-flight-select-description">
        <p className="exercise-flight-select-description-title">
          {t("disruptionExercise.flightSelectDescriptionTitle")}
        </p>
        <ul className="exercise-flight-select-description-list">
          <li className="exercise-flight-select-description-list-item">
            {t("disruptionExercise.flightSelectDescription1")}
          </li>
          <li className="exercise-flight-select-description-list-item">
            {t("disruptionExercise.flightSelectDescription2")}
          </li>
          <li className="exercise-flight-select-description-list-item">
            {t("disruptionExercise.flightSelectDescription3")}
            <LearnMore
              label={t("learnMore")}
              header={t("disruptionExercise.luggageLearnMoreHeader")}
              title={t("disruptionExercise.luggageLearnMoreTitle")}
              description={
                <I18nMarkup
                  tKey={"disruptionExercise.luggageLearnMoreDescription"}
                />
              }
            />
          </li>
        </ul>
      </div>
      {disableFloatingButton ? (
        <ActionButton
          className="exercise-flight-select-button"
          message={t("mobileSearchButton")}
          onClick={onContinue}
          disabled={selectedSegments?.length === 0}
        />
      ) : (
        <MobileFloatingButton
          className="exercise-flight-select-button"
          onClick={onContinue}
          wrapperClassName="exercise-flight-select-button-wrapper"
          wide
          disabled={selectedSegments?.length === 0}
        >
          {t("mobileSearchButton")}
        </MobileFloatingButton>
      )}
    </div>
  );
};
