import { defaultThemeConfigWithBasicColors } from "@hopper-b2b/themes";
import { asB2BMuiTheme } from "@hopper-b2b/themes";
import { ThemeOptions } from "@material-ui/core/styles";
import { LabComponentsPropsList } from "@material-ui/lab/themeAugmentation";
import CBABeaconSansBold from "../assets/fonts/CBABeaconSans-Bold.ttf";
import CBABeaconSansExtraBold from "../assets/fonts/CBABeaconSans-ExtraBold.ttf";
import CBABeaconSansMedium from "../assets/fonts/CBABeaconSans-Regular.ttf";

const cbaColors = {
  black: "#1e1e1e",
  "commbank-brighter-yellow": "#FAEC20",
  "commbank-yellow": "#FFCC00",
  "text-default": "#1E1E1E",
  "secondary-text": "#706D6E",
  "disabled-grey": "#505050",
  "icon-grey": "#4F4C4D",
  "loading-popup": "white",
  "border-grey": "#D3D2D2",
  "grey-background-light": "#F4F4F4",
  "blue-background-light": "#f2f7fb",
  utilityBlue: "#1175B5",
  accentGreenText: "#1A7D1A",
  utilityGreen: "#00AA00",
  utilityRed: "#E1001A",
  utilityTangerine: "#F95200",
};

export const cbaVariables = {
  ...defaultThemeConfigWithBasicColors,
  ...cbaColors,
  black: cbaColors.black,
  primary: cbaColors["commbank-yellow"],
  secondary: cbaColors["commbank-brighter-yellow"],
  "spinner-border-color": "var(--primary)",
  "spinner-top-border-color": "transparent",
  "grey-3": "var(--primary)",
  "yellow-1": "var(--primary)",
  "yellow-2": "var(--secondary)",
  "yellow-20": "#FFF4CC",
  "blue-50": cbaColors["commbank-yellow"],
  "blue-9": cbaColors["utilityBlue"],
  skyblue: cbaColors["utilityBlue"],
  "skyblue-disabled": "var(--gray-30)",
  "primary-text": cbaColors["text-default"],
  "secondary-text": cbaColors["secondary-text"],
  "primary-disabled": "var(--black-disabled)",
  "primary-hover": "var(--secondary)",
  // search
  "search-field-label": "var(--secondary-text)",
  "search-field-text": "var(--secondary-text)",
  // trips
  "trips-selected-filter-color": "var(--primary)",
  "trips-list-background-color": "var(--gray-05)",
  "trips-link-color": "var(--primary-text)",
  "profile-link-color": "var(--primary)",
  "secondary-button-background-disabled": "var(--gray-30)",
  "secondary-button-background-hover": "var(--gray-20)",
  "info-details-popup-background": "#F6F6F6",
  "gray-20": "var(--info-details-popup-border)",
  "gray-80": cbaColors["disabled-grey"],
  "card-tag-text": "var(--primary-text)",
  "card-tag-background": "var(--secondary)",
  // calendar colors
  "light-tint-blue": "#D8EEF9",
  "bucket-0-color": "#3B788D",
  "bucket-1-color": "#3B788D",
  "bucket-2-color": "var(--text-default)",
  "bucket-3-color": "#975D3F",
};

const cbaCustomTheming: ThemeOptions & Partial<LabComponentsPropsList> = {
  palette: {
    type: "light",
    primary: {
      light: cbaVariables.primary,
      main: cbaVariables.primary,
      contrastText: cbaVariables.black,
    },
    secondary: {
      light: cbaVariables.secondary,
      main: cbaVariables.secondary,
      contrastText: cbaVariables.black,
    },
    text: {
      primary: cbaVariables.black,
      secondary: cbaVariables["disabled-grey"],
      disabled: cbaVariables["disabled-grey"],
      hint: cbaVariables.black,
    },
    action: {
      disabled: cbaVariables["disabled-grey"],
    },
  },
  typography: {
    fontFamily: "CBA Beacon Sans",
    htmlFontSize: 16,
    fontSize: 16,
    h1: {
      fontFamily: "CBA Beacon Sans",
      fontSize: "24px",
      fontWeight: 800,
      letterSpacing: 0,
      color: cbaVariables["primary-text"],
    },
    h2: {
      fontFamily: "CBA Beacon Sans",
      fontSize: "20px",
      fontWeight: 800,
      letterSpacing: 0,
      color: cbaVariables["primary-text"],
    },
    h3: {
      fontFamily: "CBA Beacon Sans",
      fontSize: "16px",
      fontWeight: 800,
      letterSpacing: 0,
      color: cbaVariables["primary-text"],
      lineHeight: "24px",
    },
    h4: {
      fontFamily: "CBA Beacon Sans",
      fontSize: "16px",
      letterSpacing: 0,
    },
    h5: {
      fontFamily: "CBA Beacon Sans",
      fontSize: "16px",
      letterSpacing: 0,
    },
    h6: {
      fontFamily: "CBA Beacon Sans",
      fontSize: "14px",
      fontWeight: 800,
      letterSpacing: 0,
      color: cbaVariables["primary-text"],
    },
    body1: {
      color: cbaVariables["primary-text"],
      fontFamily: "CBA Beacon Sans",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    body2: {
      color: cbaVariables["secondary-text"],
      fontFamily: "CBA Beacon Sans",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    caption: {
      fontFamily: "CBA Beacon Sans",
      fontSize: "0.75rem",
      letterSpacing: 0,
      color: cbaVariables["gray-60"],
    },
    overline: {
      fontFamily: "CBA Beacon Sans",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    subtitle1: {
      fontFamily: "CBA Beacon Sans",
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: 0,
      color: cbaVariables["primary-text"],
    },
    subtitle2: {
      fontFamily: "CBA Beacon Sans",
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: 0,
      color: cbaVariables["secondary-text"],
    },
    button: {
      fontFamily: "CBA Beacon Sans",
      fontSize: "1rem",
      lineHeight: "1.25rem",
      textTransform: "none" as const,
      letterSpacing: 0,
      borderColor: cbaVariables["grey-11"],
    },
  },
  shape: {
    borderRadius: 8,
  },
  spacing: [0, 4, 8, 16, 32, 64],
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          {
            fontFamily: "CBA Beacon Sans",
            fontStyle: "normal",
            fontWeight: 400,
            src: `
              local("CBA Beacon Sans Regular"),
              url(${CBABeaconSansMedium}) format("truetype");
            `,
          },
          {
            fontFamily: "CBA Beacon Sans",
            fontStyle: "normal",
            fontWeight: 600,
            src: `
              local("CBA Beacon Sans Bold"),
              url(${CBABeaconSansBold}) format("truetype");
            `,
          },
          {
            fontFamily: "CBA Beacon Sans",
            fontStyle: "normal",
            fontWeight: 700,
            src: `
              local("CBA Beacon Sans Bold"),
              url(${CBABeaconSansBold}) format("truetype");
            `,
          },
          {
            fontFamily: "CBA Beacon Sans",
            fontStyle: "normal",
            fontWeight: 800,
            src: `
              local("CBA Beacon Sans Extrabold"),
              url(${CBABeaconSansExtraBold}) format("truetype");
            `,
          },
        ],
      },
    },
    MuiButton: {
      root: {
        height: 50,
        fontFamily: "CBA Beacon Sans",
      },
      sizeSmall: {
        height: 32,
      },
      sizeLarge: {
        height: 60,
      },
      outlined: {
        backgroundColor: cbaVariables.white,
        borderColor: cbaVariables["grey-11"],
      },
    },
    MuiLink: {
      root: {
        fontFamily: "CBA Beacon Sans",
      },
      underlineHover: {
        "&:hover": {
          "text-decoration": "none",
        },
      },
    },
    MuiFilledInput: {
      root: {
        color: cbaVariables["primary-text"],
        height: 60,
        backgroundColor: cbaVariables.white,
        borderRadius: 8,
        border: `1px solid ${cbaVariables["secondary-text"]}`,
        "&.Mui-focused, &.Mui-hover": {
          backgroundColor: cbaVariables.white,
          border: `1px solid ${cbaVariables["utilityBlue"]}`,
          outline: `1px solid ${cbaVariables["utilityBlue"]}`,
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 14,
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 16,
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        "&[class*='MuiFilledInput-root']": {
          paddingLeft: 36,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        height: 60,
        backgroundColor: cbaVariables.white,
      },
    },
  },
};

export const muiTheme = asB2BMuiTheme(cbaCustomTheming);
