import {
  ApacActionButton,
  ApacTermsLink,
  ApacIcon,
  ApacIconName,
  ApacTextField,
  CBASpinner,
  InputWrapper,
} from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { emailRegex, useDeviceTypes } from "@hopper-b2b/utilities";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { DesktopModalHeader } from "../PriceDropProtectionContent/PriceDropProtectionContent";
import "./PriceWatchDetails.styles.scss";
import { IPriceWatchOptInState } from "@hopper-b2b/ui";
import { CreateWatchOptions } from "../../../actions/actions";
import { useHistory } from "react-router";
export interface PriceWatchDetailsProps {
  onClose: (completed?: boolean) => void;
  onWatchThisTrip: (string, options?: CreateWatchOptions) => void;
  className?: string;
  optInState?: IPriceWatchOptInState;
}

export const PriceWatchDetails = ({
  onClose,
  onWatchThisTrip,
  optInState,
  className,
}: PriceWatchDetailsProps) => {
  const { t } = useI18nContext();
  const [nonstop, setNonstop] = useState(false);
  const [noBasicFare, setNoBasicFare] = useState(false);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const history = useHistory();
  const { matchesMobile } = useDeviceTypes();

  const isEmailValid = (email: string) => {
    return emailRegex.test(email) && !!email;
  };

  const [completed, setCompleted] = useState(false);

  return (
    <div className={clsx("price-watch-content-container", className)}>
      <DesktopModalHeader
        icon={ApacIconName.YellowEye}
        title={t("priceWatch.title")}
      />
      {![
        IPriceWatchOptInState.Watching,
        IPriceWatchOptInState.InProcess,
      ].includes(optInState) && (
        <div className={"price-watch-content"}>
          <div className="content-section">
            <Typography variant="h3">{t("priceWatch.titles.title")}</Typography>
            <Typography variant="body2">
              {t("priceWatch.titles.subtitle")}
            </Typography>
          </div>

          <div className="content-section">
            <Typography variant="h3">{t("priceWatch.preferences")}</Typography>
            <div
              className="preference-item"
              onClick={() => setNonstop((pre) => !pre)}
            >
              <ApacIcon
                name={nonstop ? ApacIconName.Checked : ApacIconName.Unchecked}
                className="checkbox"
              />
              <Typography variant="body1">
                {t("priceWatch.nonstopOnly")}
              </Typography>
            </div>
            <div
              className="preference-item"
              onClick={() => setNoBasicFare((pre) => !pre)}
            >
              <ApacIcon
                name={
                  noBasicFare ? ApacIconName.Checked : ApacIconName.Unchecked
                }
                className="checkbox"
              />
              <Typography variant="body1">
                {t("priceWatch.excludeBasic")}
              </Typography>
            </div>
          </div>

          <InputWrapper label={t("email")} error={errorMessage}>
            <ApacTextField
              value={email}
              placeholder={t("priceWatch.emailPlaceholder")}
              className={clsx({ error: !!errorMessage })}
              onChange={(event) => {
                setEmail(event.target.value);
                setErrorMessage(null);
              }}
            />
          </InputWrapper>

          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: t("priceWatch.moreDescription"),
            }}
          />

          <div className="footer">
            <ApacTermsLink
              showUnderline={false}
              scrollSelector="#best-price-guarantee"
            >
              {t("termsAndConditions")}
            </ApacTermsLink>
            <ApacActionButton
              className="end-button"
              onClick={() => {
                if (!isEmailValid(email)) {
                  setErrorMessage(t("priceWatch.emailRequired"));
                  return;
                }

                onWatchThisTrip(email, {
                  nonstop,
                  noBasicFare,
                  history,
                  isMobile: matchesMobile,
                });

                setCompleted(true);
              }}
              message={t("priceWatch.titles.buttonCopy")}
            />
          </div>
        </div>
      )}

      {optInState === IPriceWatchOptInState.Watching && (
        <div className={"price-watch-content"}>
          <div className="content-section">
            <Typography variant="h3">
              {t("priceWatch.titles.watchedTitle")}
            </Typography>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: t("priceWatch.moreDescription"),
              }}
            />
          </div>

          <div className="footer">
            <ApacTermsLink
              showUnderline={false}
              scrollSelector="#best-price-guarantee"
            >
              {t("termsAndConditions")}
            </ApacTermsLink>
            <ApacActionButton
              className="end-button"
              onClick={() => {
                onClose(completed);
              }}
              message={t("close.button")}
            />
          </div>
        </div>
      )}

      {optInState === IPriceWatchOptInState.InProcess && (
        <div className="loader-wrapper">
          <CBASpinner />
        </div>
      )}
    </div>
  );
};
