export const DEPARTS_ON = "Departs on";
export const DATE_FORMAT = "ddd, MMM DD";
export const ROUND_TRIP = "Round-trip Flight";
export const ONE_WAY_TRIP = "One-way Flight";
export const COMPLETE_BOOKING = "Complete Booking";
export const freezeAgain = (price: string) => `Freeze Again | $${price}`;
export const EXPIRES_ON = "Expires on";
export const FROZEN_HEADER = "Frozen Price | Departs on ";
export const FROZEN_HEADER_MOBILE = "Frozen Price ";
export const CONFIRMATION = "Confirmation:";
export const DEPARTURE = "Departure";
export const RETURN = "Return";
export const formatBanner = (date: string): string =>
  `This frozen price has expired on ${date}`;
