import {
  Product,
  PurchaseErrorEnum,
  type CipherText,
  type FulfillRequest,
  type PurchaseError,
} from "@b2bportal/purchase-api";
import { State } from "xstate";
import { getChildState, getParentState } from "../../../helpers";
import { CartFulfillState, ParentState } from "../../../types";
import { cartQuoteSelectors } from "../CartQuote";
import { CartContext } from "../context";
import { CartState } from "../types";
import { CompleteInteractiveAuthRequest } from "@b2bportal/card-api";

type CartFulfillStateType = State<CartContext>;
type CartFulfillStateWithoutValue = Pick<CartFulfillStateType, "context">;

export const getFullFillRequestParams = (
  state: CartFulfillStateWithoutValue
): FulfillRequest => {
  const quoteCipherToken = cartQuoteSelectors.getPriceQuoteCipherText(state);
  const payments = getFulfillRequestPayments(state);

  return {
    quoteToken: quoteCipherToken as CipherText,
    payments,
  };
};

export const getFulfillCipherText = (state: CartFulfillStateWithoutValue) =>
  state.context[CartState.cartFulfill]?.fulfillCipherText;

export const getOpenCartFulfillModal = (state: CartFulfillStateType) =>
  [
    CartFulfillState.polling,
    CartFulfillState.schedule,
    CartFulfillState.challenge,
  ].includes(getChildState(state.value) as CartFulfillState) &&
  Object.keys(state.value).includes(ParentState.cartFulfill);

export const getOpenCartFulfillErrorModal = (state: CartFulfillStateType) =>
  getParentState(state.value) === ParentState.cartFulfill &&
  getChildState(state.value) === CartFulfillState.error;

export const getCartFulfillError = (state: CartFulfillStateType) =>
  state.context[CartState.cartFulfill].error;

export const getCartFulfillFailedAt = (state: CartFulfillStateType) =>
  state.context[CartState.cartFulfill].error?.failedAt;

export const getCartFulfillErrorFailureReasons = (
  state: CartFulfillStateType
) => {
  const error = getCartFulfillError(state);

  if (!error) return;

  const errors = error?.data as PurchaseError[];
  return errors?.map((error: PurchaseError) =>
    error.Error === PurchaseErrorEnum.ErrorCode
      ? error.code
      : error.Error || "ProductError"
  );
};

export const getFulfillRequestPayments = (
  state: CartFulfillStateType | CartFulfillStateWithoutValue
) => state.context[CartState.cartFulfill]?.fulfillRequestPayments;

export const getFulfillPayments = (
  state: CartFulfillStateType | CartFulfillStateWithoutValue
) => state.context[CartState.cartFulfill]?.fulfilledPayments;

export const getFulfilledProducts = (
  state: CartFulfillStateType | CartFulfillStateWithoutValue
) => state.context[CartState.cartFulfill]?.fulfilledProducts;

export const getFulfilledProductsReservationId = (
  state: CartFulfillStateType | CartFulfillStateWithoutValue
) => getFulfilledProducts(state)?.[0]?.value?.customerReservationId;

export const getCartFulfilledLodgingBooking = (
  state: CartFulfillStateType | CartFulfillStateWithoutValue
) => {
  const products = getFulfilledProducts(state);
  const lodgingProduct = products?.find((p) => p.type === Product.Hotel);
  return lodgingProduct?.value;
};

export const getCartFulfillTrackingProperties = (
  state: CartFulfillStateType | CartFulfillStateWithoutValue
) => state.context.cartFulfill?.trackingProperties;

//TODO: Move selectors
export const hasFulfilledAirCfarProduct = (
  state: CartFulfillStateWithoutValue
): boolean => {
  const products = getFulfilledProducts(state);
  return !!products?.find((p) => p.type === Product.AirCfar);
};

export const hasFulfilledDisruptionProduct = (
  state: CartFulfillStateWithoutValue
): boolean => {
  const products = getFulfilledProducts(state);
  return !!products?.find((p) => p.type === Product.AirDisruption);
};

export const hasFulfilledAirChfarProduct = (
  state: CartFulfillStateWithoutValue
): boolean => {
  const products = getFulfilledProducts(state);
  return !!products?.find((p) => p.type === Product.AirChfar);
};

export const getCartFulfillCallState = (state: CartFulfillStateWithoutValue) =>
  state.context[CartState.cartFulfill].callState;

// 3DS Selectors
export const getCartFulfillPendingInteractive = (state: CartFulfillStateType) =>
  state.context[CartState.cartFulfill].pendingInteractive;

export const getCartFulfillCompleteInteractive = (
  state: CartFulfillStateType | CartFulfillStateWithoutValue
) => state.context[CartState.cartFulfill].completeInteractive;

export const getCompleteInteractiveFulFillRequestParams = (
  state: CartFulfillStateWithoutValue
): CompleteInteractiveAuthRequest => {
  const token = getFulfillCipherText(state).value;
  const completeInteractive = getCartFulfillCompleteInteractive(state);

  return {
    token,
    ...completeInteractive,
  };
};
