import { useI18nContext } from "@hopper-b2b/i18n";
import { ErrorPopup } from "@hopper-b2b/ui";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import { getGatekeeperCopy } from "../reducer/selectors";

export interface IGateKeeperPopupProps {
  backToTrips: () => void;
}

const defaultProps: Partial<IGateKeeperPopupProps> = {};

const GateKeeperPopup = (props: IGateKeeperPopupProps): JSX.Element => {
  const { backToTrips } = props;
  const { t } = useI18nContext();
  const gatekeeperCopy = useSelector(getGatekeeperCopy);

  return (
    <Box className="exchangeable-gatekeeper-popup">
      <ErrorPopup
        open
        onClose={backToTrips}
        primaryButtonText={t("exchangeable.backToTrips")}
        primaryOnClick={backToTrips}
        subtitle={gatekeeperCopy.body.join(" ")}
        title={gatekeeperCopy.title}
      />
    </Box>
  );
};

GateKeeperPopup.defaultProps = defaultProps;

export default GateKeeperPopup;
