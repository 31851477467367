import { WalletOffer } from "@b2bportal/hopper-wallet-api";
import { CallState } from "@hopper-b2b/types";
import { createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../reducers/types";

export const getWalletOffers = (
  state: IStoreState
): WalletOffer[] | undefined => {
  return state.flightWallet.walletOffers?.length
    ? state.flightWallet.walletOffers.sort((a, b) => {
        return a.amount.fiat.value - b.amount.fiat.value;
      })
    : undefined;
};

export const getWalletBestOffers = (
  state: IStoreState
): WalletOffer | undefined => state.flightWallet.bestOffer;

export const getWalletCreditOffer = (state: IStoreState): WalletOffer =>
  state.flightWallet.creditOffer;

export const getWalletOffersCallState = (state: IStoreState): CallState =>
  state.flightWallet.walletOfferCallState;

export const getSelectedOffer = (state: IStoreState): WalletOffer | undefined =>
  state.flightWallet.selectedWalletOffers;

export const hasSelectedCreditOffer = (state: IStoreState): boolean =>
  !!state.flightWallet.selectedCreditOffer;

export const getSelectedCreditOffer = (state: IStoreState): WalletOffer =>
  state.flightWallet.selectedCreditOffer;

export const isWalletOffersLoading = createSelector(
  getWalletOffersCallState,
  (walletOfferCallState: CallState) =>
    walletOfferCallState === CallState.InProcess
);

export const getHasWalletOffers = createSelector(
  getWalletOffersCallState,
  getWalletOffers,
  getWalletCreditOffer,
  (callState: CallState, offers?: WalletOffer[], creditOffer?: WalletOffer) =>
    !!offers?.length || !!creditOffer
);

export const getTotalWalletSavings = createSelector(
  getSelectedOffer,
  getSelectedCreditOffer,
  (offer, creditOffer) => {
    return {
      ...offer?.amount.fiat,
      ...creditOffer?.amount.fiat,
      value:
        (offer?.amount.fiat.value || 0) + (creditOffer?.amount.fiat.value || 0),
    };
  }
);
