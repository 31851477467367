import LodgingPlaceholder from "../../../../assets/images/hotels/lodging_placeholder.png";
import "./styles.scss";

export const DefaultLodgingPlaceholder = () => {
  return (
    <img
      alt=""
      className="default-lodging-placeholder"
      src={LodgingPlaceholder}
    />
  );
};
