import "./Shop.scss";
import { useDeviceTypes, useScrollToTop } from "@hopper-b2b/utilities";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LodgingDetailsPage } from "./components/LodgingDetailsPage";
import { WalletOfferPage } from "../wallet";
import { LodgingShopQueryParams, LodgingShopSteps } from "@hopper-b2b/types";
import { RoomSelectPage } from "./components/RoomSelectionPage";
import { setCurrentShopStep } from "./actions/actions";
import { getCurrentStep } from "./reducer/selectors";
import { useGoToShopLodgingDetailsStep } from "./hooks";
import clsx from "clsx";
import {
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom-v5-compat";
import { ShopProps } from "./container";
import { URL_PARAM_KEYS } from "@hopper-b2b/lodging-utils";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useDocumentTitle } from "../../../../hooks/src/useDocumentTitle";
import { useFetchRooms } from "./hooks/useFetchRooms";

export const Shop = (props: ShopProps) => {
  const { matchesMobile: isMobile, matchesLargeDesktop: isDesktop } =
    useDeviceTypes();

  /** Step */
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const goToShopLodgingDetails = useGoToShopLodgingDetailsStep();

  const { t } = useI18nContext();
  useDocumentTitle(t("commBank.hotelShopProgress.chooseRoom"));

  const fetchRooms = useFetchRooms();
  useEffect(() => {
    fetchRooms();
  }, [fetchRooms]);

  useEffect(() => {
    props.setSearchParams({
      guests: {
        adults: Number(searchParams.get(URL_PARAM_KEYS.ADULTS_COUNT)) || 1,
        // Use number of children and fake age of 1 to populate children array
        children:
          Number(searchParams.get(URL_PARAM_KEYS.CHILDREN_COUNT)) > 0
            ? new Array(
                Number(searchParams.get(URL_PARAM_KEYS.CHILDREN_COUNT))
              ).fill(1)
            : [],
      },
      rooms: Number(searchParams.get(URL_PARAM_KEYS.ROOMS_COUNT)) || 1,
      fromDate: searchParams.get(URL_PARAM_KEYS.FROM_DATE),
      untilDate: searchParams.get(URL_PARAM_KEYS.UNTIL_DATE),
      location: params["location"],
    });
  }, [location.search, params]);

  const currentQueryStep = useMemo(() => {
    return new URLSearchParams(location.search).get(
      LodgingShopQueryParams.SHOP_STEP
    );
  }, [location.search]);

  const currentStep = useSelector(getCurrentStep);

  // Update shop step state on url change
  useEffect(() => {
    const step = currentQueryStep?.toUpperCase();
    if (LodgingShopSteps[step]) {
      dispatch(setCurrentShopStep(LodgingShopSteps[step]));
    } else {
      goToShopLodgingDetails(true);
    }
  }, [currentQueryStep, dispatch, goToShopLodgingDetails]);

  const stepsComponent = useMemo(() => {
    return {
      [LodgingShopSteps.LODGING_DETAILS]: LodgingDetailsPage,
      [LodgingShopSteps.ROOM_SELECTION]: isDesktop
        ? LodgingDetailsPage
        : RoomSelectPage,
      [LodgingShopSteps.WALLET_OFFERS_SELECTION]: WalletOfferPage,
    };
  }, [isDesktop]);
  /** Step end*/

  const body = useMemo(() => {
    const CurrentComponent = stepsComponent[currentStep];
    if (currentQueryStep === currentStep)
      return <CurrentComponent {...props} />;
    else {
      return <>loading</>;
    }
  }, [currentQueryStep, currentStep, stepsComponent, props]);

  useScrollToTop();

  return (
    <div className={clsx("lodging-shop", { mobile: isMobile })}>
      <div className={"lodging-shop-content"}>{body}</div>
    </div>
  );
};
