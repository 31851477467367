import { AccountsProvider } from "@commbank/accounts";
import { FeatureFlagsProvider } from "@apac/feature-flags";
import { WalletProvider } from "@commbank/wallet";
import { apiConfig } from "@hopper-b2b/common-utils";
import I18nProvider, {
  enGB_commbankAuTranslations,
  en_commbankAuTranslations,
  getLang,
} from "@hopper-b2b/i18n";
import { installCssConfig } from "@hopper-b2b/themes";
import {
  SessionInfo,
  Translation,
  TranslationLanguage,
} from "@hopper-b2b/types";
import { UtmTrackingProvider, useWindowSize } from "@hopper-b2b/utilities";
import {
  CssBaseline,
  StylesProvider,
  ThemeProvider,
  createGenerateClassName,
} from "@material-ui/core";
import { createContext, useEffect, useMemo, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import { App } from "./App";
import AxiosInterceptorWrapper from "./components/AxiosInterceptorWrapper";
import { branding } from "./modules/branding";
import { cbaVariables, muiTheme } from "./modules/defaultTheme";
import config from "./utils/config";

// TODO: should be commbank-au experiments.
export enum FEATURE_FLAGS {
  MAINTENANCE = "commbank-au-maintenance",
  FLIGHTS_MAINTENANCE = "commbank-au-flights-maintenance",
  HOTELS_MAINTENANCE = "commbank-au-hotels-maintenance",
  TRIPS_MAINTENANCE = "commbank-au-trips-maintenance",

  DESKTOP_COMING_SOON = "commbank-au-desktop-coming-soon",

  AIR = "commbank-au-air",
  LODGING = "commbank-au-lodging",

  APP_DARKMODE = "commbank-au-dark-mode",
  VIP_SUPPORT = "commbank-au-vip-support",

  // Fintech Flags
  AIR_CFAR = "commbank-au-air-cfar",
  AIR_CHFAR = "commbank-au-air-chfar",
  AIR_DISRUPTION = "commbank-au-air-disruption",
  AIR_MISSED_CONNECTION = "commbank-au-air-disruption",
  AIR_PRICE_FREEZE = "commbank-au-air-price-freeze",
  AIR_PRICE_WATCH = "commbank-au-air-price-watch",
  AIR_PRICE_DROP = "commbank-au-air-price-drop",

  // Growth
  AIR_WALLET = "commbank-au-air-vouchers",
  LODGING_WALLET = "commbank-au-lodging-vouchers",

  // CX Experience
  AIR_EXCHANGE = "commbank-au-air-self-serve-exchange",

  // Login with code
  LOGIN_WITH_CODE = "commbank-au-login-with-code",

  // Best price guarantee
  BEST_PRICE_GUARANTEE = "commbank-au-best-price-guarantee",

  // Show tree information
  TREE_BANNER = "commbank-au-trees",

  // Retain filters on updates
  RETAIN_FILTERS = "commbank-au-retain-hotel-filters",

  // Hacker fare V2
  HFv2 = "commbank-au-hacker-fare-v2",

  // Let users know about the offers available to them on the home Hotel tab.
  DISPLAY_HOTELS_MERCH = "commbank-au-hotels-merchandising",

  DISPLAY_HOTEL_CARD_BANNER_OFFER = "commbank-au-hotel-card-banner-offer",
}

const generateClassName = createGenerateClassName({
  productionPrefix: "ptBaseModule",
  seed: "ptBaseModule",
});

const setViewWidthAndHeight = (width: string, height: string) => {
  document.body.style.setProperty(`--vw`, width);
  document.body.style.setProperty(`--vh`, height);
};

interface CBASessionInfo extends SessionInfo {
  hopperUserId: string;
  kustomerAccessToken: string;
}

type SessionInfoContext = {
  sessionInfo: CBASessionInfo;
  setSessionInfo: (info: CBASessionInfo) => void;
};

export const SessionContext = createContext<Partial<SessionInfoContext>>({});

export const Root = () => {
  const [sessionInfo, setSessionInfo] = useState<SessionInfo>(null);

  const tenantTranslation: Translation = useMemo(() => {
    return {
      // en-AU uses en-GB translations
      // We don't have libs/i18n/locales/en-AU directory because en-GB covers it.
      [TranslationLanguage["en-AU"]]: enGB_commbankAuTranslations,
      [TranslationLanguage.en]: en_commbankAuTranslations,
    };
  }, []);
  const windowSize = useWindowSize();

  useEffect(() => {
    // Add a variable for vh to use for specifying full-screen height
    // 100vh does not work properly on iOS. https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    setViewWidthAndHeight(
      `${windowSize.width * 0.01}px`,
      `${windowSize.height * 0.01}px`
    );
  }, [windowSize.height, windowSize.width]);

  useEffect(() => {
    installCssConfig(cbaVariables);
  }, []);

  return (
    <BrowserRouter>
      <CompatRouter>
        <I18nProvider
          defaultLng={DEFAULT_LOCALE}
          branding={branding}
          tenantTranslation={tenantTranslation}
        >
          <UtmTrackingProvider>
            <StylesProvider generateClassName={generateClassName}>
              <ThemeProvider theme={muiTheme}>
                <SessionContext.Provider
                  value={{ sessionInfo, setSessionInfo }}
                >
                  <CssBaseline />
                  <FeatureFlagsProvider
                    apiConfig={apiConfig}
                    isLoggedIn={!!sessionInfo?.userInfo}
                  >
                    <AccountsProvider
                      cardArtUrl={config.CBA_CARD_ART_URL}
                      isLoggedIn={!!sessionInfo?.userInfo}
                    >
                      <WalletProvider>
                        <AppWrapper />
                      </WalletProvider>
                    </AccountsProvider>
                  </FeatureFlagsProvider>
                </SessionContext.Provider>
              </ThemeProvider>
            </StylesProvider>
          </UtmTrackingProvider>
        </I18nProvider>
      </CompatRouter>
    </BrowserRouter>
  );
};

const DEFAULT_LOCALE = getLang("en-AU");

const AppWrapper = () => {
  return (
    <>
      <AxiosInterceptorWrapper />
      <App />
    </>
  );
};
