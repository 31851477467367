import {
  ApacActionButton,
  ApacActionLink,
  ApacDesktopPopupModal,
  ApacIcon,
  ApacIconName,
  ApacMobileFullScreenModal,
} from "@commbank/ui";
import { Airport, TripSlice } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { FareSliceDetails } from "@hopper-b2b/types";
import { RemoteAirlineIcon } from "@hopper-b2b/ui";
import {
  formatInterval,
  getPlusDays,
  removeTimezone,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import {
  useGoBackToFlightShop,
  useUpdateFlightShopStep,
} from "../../modules/shop/hooks";
import { FlightShopStep } from "../../modules/shop/reducer";
import { getFareAmenities } from "../../modules/shop/utils/fareAmenities";
import { Amenity } from "../../modules/shop/v3/components/FlightList/components/FareDetailsCard";
import { FlightDetailsTimeline } from "../FlightDetailsTimeline";
import styles from "./FlightReviewCard.module.scss";

type FlightReviewCardProps = {
  tripSlice: TripSlice;
  fareDetailsSlice: FareSliceDetails;

  isMixedCabinClass?: boolean;
  className?: string;

  // following required together
  showCardTitle?: boolean;
  showAction?: boolean;
  showEdit?: boolean;
  hideChangeAction?: boolean;
  airports?: { [key: string]: Airport };
};

export const FlightReviewCard = (props: FlightReviewCardProps) => {
  const {
    tripSlice,
    fareDetailsSlice,
    isMixedCabinClass,

    showCardTitle,
    showAction,
    showEdit,
    hideChangeAction,
    airports,
    className,
  } = props;
  const [openFareDetailsModal, setOpenFareDetailsModal] = useState(false);
  const { t } = useI18nContext();
  const goBackToFlightShop = useGoBackToFlightShop();
  const { matchesMobile: isMobile } = useDeviceTypes();

  const displaySegment = tripSlice.segmentDetails[0];
  const isDeparture = tripSlice.outgoing;

  // get origin name and destination name
  let originName = null;
  let destinationName = null;
  if (airports) {
    originName = airports[tripSlice.originCode]
      ? airports[tripSlice.originCode].cityName
      : tripSlice.originName;

    destinationName = airports[tripSlice.destinationCode]
      ? airports[tripSlice.destinationCode].cityName
      : tripSlice.destinationName;
  }

  const formatTime = (time: string) =>
    dayjs(removeTimezone(time)).format("h:mm A");

  const formatDuration = ({
    arrivalTime,
    departureTime,
  }: {
    arrivalTime: string;
    departureTime: string;
  }) =>
    formatInterval(
      dayjs(arrivalTime).diff(dayjs(departureTime), "minute", true)
    );

  const updateFlightShopStep = useUpdateFlightShopStep();

  const goToChooseOutbound = () => {
    updateFlightShopStep(
      isDeparture
        ? FlightShopStep.ChooseDeparture
        : FlightShopStep.ChooseReturn,
      true
    );
  };

  const onEdit = (isDeparture) =>
    goBackToFlightShop(
      isDeparture ? FlightShopStep.ChooseDeparture : FlightShopStep.ChooseReturn
    );

  const plusDays = useMemo(() => {
    if (tripSlice) {
      return getPlusDays(tripSlice);
    } else {
      return 0;
    }
  }, [tripSlice]);

  const amenities = useMemo(() => {
    return getFareAmenities(fareDetailsSlice);
  }, [fareDetailsSlice]);

  const flightSliceDetails = () => {
    return (
      <Box className={styles["flight-details-flights-container"]}>
        <div className={styles["summary-title"]}>
          <ApacIcon name={ApacIconName.CBAPlane} />
          <Typography variant={isMobile ? "h2" : "h1"}>
            {t("flightShop.cardDetailsTitle", {
              fromCity: `${airports[tripSlice.originCode].cityName} (${
                tripSlice.originCode
              })`,
              toCity: `${airports[tripSlice.destinationCode].cityName} (${
                tripSlice.destinationCode
              })`,
              date: dayjs(removeTimezone(tripSlice.departureTime)).format(
                "ddd D MMM YY"
              ),
              duration: formatInterval(tripSlice.totalDurationMinutes),
            })}
          </Typography>
        </div>

        <div className={styles["segments-container"]}>
          <FlightDetailsTimeline
            className={styles["review-itinerary-flight-details"]}
            segments={tripSlice.segmentDetails}
            departureTime={tripSlice.departureTime}
            planeInfo={
              fareDetailsSlice?.amenitiesUtas?.amenities?.aircraft.info
                .displayText
            }
            fareClass={fareDetailsSlice?.fareShelf?.shortBrandName || ""}
            showTitle={false}
            plusDays={plusDays}
            fareSlice={fareDetailsSlice}
            isMixedCabinClass={isMixedCabinClass}
          />
        </div>
      </Box>
    );
  };

  const renderSliceDetailsModalContent = () => {
    return (
      <div>
        {flightSliceDetails()}

        <div className={styles["review-row-divider"]} />

        <div className={styles["amenities"]}>
          <Typography variant={"h2"}>
            {fareDetailsSlice?.fareShelf?.brandName}
          </Typography>
          {amenities.map((policy, index) => (
            <Amenity key={index} policy={policy} showDetail={false} />
          ))}
        </div>

        <ApacActionButton
          className={styles["close-button"]}
          onClick={() => setOpenFareDetailsModal(false)}
          message={t("close.button")}
        />
      </div>
    );
  };

  return (
    <div
      className={`${styles.container} ${
        isMobile ? styles.mobile : ""
      } ${className}`}
    >
      {!!showCardTitle && !!originName && !!destinationName && (
        <Typography
          variant={isMobile ? "h3" : "h2"}
          className={styles["card-header-title"]}
        >{`${originName} (${tripSlice?.originCode}) ${t(
          "to"
        )} ${destinationName} (${tripSlice?.destinationCode})`}</Typography>
      )}
      <Box className={styles["flight-card-container"]}>
        <div className={styles["category-banner"]}>
          <Typography variant="h6">
            {`${t(
              "commBank.search.flights." +
                (isDeparture ? "departing" : "returning")
            )} - ${dayjs(removeTimezone(tripSlice.departureTime)).format(
              "ddd, DD MMM YYYY"
            )}`}
          </Typography>
        </div>
        <div className={styles["card-content-container"]}>
          <div className={styles["top"]}>
            <div className={styles["airline-logo"]}>
              <RemoteAirlineIcon
                airlineCode={displaySegment.airlineCode}
                altText={displaySegment.airlineName}
                size="medium"
              />
            </div>
            <div className={styles["time-container"]}>
              <div className={styles["col"]}>
                <Typography variant="h6">
                  {formatTime(tripSlice.departureTime)}
                </Typography>
                <Typography className={styles["font-small"]} variant="body1">
                  {tripSlice.originCode}
                </Typography>
              </div>
              <div className={`${styles["col"]} ${styles["duration"]}`}>
                <ApacIcon name={ApacIconName.AirPlane} />
                <Typography className={styles["font-small"]} variant="body1">
                  {formatDuration(tripSlice)}
                </Typography>
              </div>
              <div className={styles["col"]}>
                <Typography variant="h6">
                  {formatTime(tripSlice.arrivalTime)}
                </Typography>
                <Typography className={styles["font-small"]} variant="body1">
                  {tripSlice.destinationCode}
                </Typography>
              </div>
            </div>
          </div>
          <div className={styles["review-row-divider"]} />
          <div className={styles["bottom"]}>
            <div className={styles["fare-name"]}>
              <Typography variant="h6">
                {fareDetailsSlice?.fareShelf?.brandName ?? ""}
              </Typography>
            </div>
            <div className={styles["flight"]}>
              <Typography
                className={styles["font-small"]}
                variant="body2"
                noWrap
              >
                {displaySegment.airlineName}
                {" • "}
                {tripSlice.stops > 0
                  ? t("stopDetails.stop", { count: tripSlice.stops })
                  : t("stopDetails.nonstop")}
              </Typography>
            </div>
          </div>
          {(showAction || showEdit) && (
            <div className={styles["footer"]}>
              <ApacActionLink
                message={t("commBank.flightReview.viewDetails")}
                onClick={() => setOpenFareDetailsModal(true)}
              />
              {hideChangeAction ? null : (
                <>
                  <Typography variant="body1">{"|"}</Typography>
                  <ApacActionLink
                    message={t("change")}
                    onClick={() =>
                      showEdit ? onEdit(isDeparture) : goToChooseOutbound()
                    }
                  />
                </>
              )}
            </div>
          )}
        </div>
      </Box>

      {!!airports &&
        (isMobile ? (
          <ApacMobileFullScreenModal
            className={styles["mobile-review-flight-fare-details-modal"]}
            open={openFareDetailsModal}
            onClose={() => setOpenFareDetailsModal(false)}
          >
            {renderSliceDetailsModalContent()}
          </ApacMobileFullScreenModal>
        ) : (
          <ApacDesktopPopupModal
            className={styles["desktop-review-flight-fare-details-modal"]}
            contentClassName={styles["fare-details-modal-content"]}
            open={openFareDetailsModal}
            onClose={() => setOpenFareDetailsModal(false)}
          >
            {renderSliceDetailsModalContent()}
          </ApacDesktopPopupModal>
        ))}
    </div>
  );
};
