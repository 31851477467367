import { Box, Slider } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import "./ApacGenericSlider.styles.scss";

interface IApacGenericSliderProps {
  className?: string;
  onChange: (min: number, max: number) => void;
  sliderLabel?: React.ReactNode;
  sliderType: "singleThumb" | "doubleThumb" | "normal";
  step?: number;
  // use chosenMin as value for single thumb slider
  chosenMin: number;
  chosenMax?: number;
  sliderMin: number;
  sliderMax: number;
  minDistance?: number;
  getLabel?: string | ((value: number, index: number) => React.ReactNode);
  updateLabelsOnChange?: boolean;
}

const ApacGenericSlider = (props: IApacGenericSliderProps) => {
  const {
    className,
    onChange,
    sliderLabel,
    sliderType,
    step,
    chosenMin,
    chosenMax,
    sliderMin,
    sliderMax,
    getLabel,
    minDistance,
  } = props;

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    value: number | number[]
  ): void => {
    // single thumb slider
    if (event.isTrusted && typeof value === "number") {
      onChange(value, sliderMax);
    } else {
      // double thumb slider
      const [min, max] = value as [number, number];
      if ((minDistance > 0 && max - min >= minDistance) || !minDistance) {
        onChange(min, max);
      }
    }
  };

  return (
    <Box className={clsx("apac-generic-slider-root", className)}>
      {sliderLabel && (
        <Box className="apac-generic-slider-label">{sliderLabel}</Box>
      )}
      <Slider
        {...props}
        role="slider"
        className="apac-generic-slider"
        step={step ?? (sliderMax - sliderMin) / 50}
        min={sliderMin}
        max={sliderMax}
        value={
          sliderType === "singleThumb" ? chosenMin : [chosenMin, chosenMax]
        }
        onChange={handleChange}
        valueLabelDisplay={getLabel ? "on" : "off"}
        valueLabelFormat={getLabel}
        track={sliderType === "singleThumb" ? "inverted" : "normal"}
      />
    </Box>
  );
};

export default ApacGenericSlider;
