import React from "react";
import clsx from "clsx";
import { Link, Button, Box } from "@material-ui/core";

import { LodgingMediaAsset } from "@b2bportal/lodging-api";

import { getImagesArray } from "@hopper-b2b/utilities";
import { Icon, IconName } from "../Icon";
import "./styles.scss";
import { Slot } from "../Slots";

export interface HeroImageGalleryProps {
  className?: string;
  hideSeeMoreCta?: boolean;
  maxHeroImages?: number;
  media: LodgingMediaAsset[];
  onImageClick: (index: number) => void;
  onSeeMoreClick: (index: number) => void;
  placeholder?: React.ReactElement;
  seeMoreIcon?: JSX.Element;
  seeMoreLabel: string;
}

export const HeroImageGallery = (props: HeroImageGalleryProps) => {
  const {
    className,
    hideSeeMoreCta,
    maxHeroImages = 5,
    media,
    onImageClick,
    onSeeMoreClick,
    placeholder,
    seeMoreIcon,
    seeMoreLabel,
  } = props;

  const [imagesArray, setImagesArray] = React.useState<string[]>([]);

  React.useEffect(() => {
    getImagesArray(media ?? [], setImagesArray);
  }, [media, setImagesArray]);

  const filteredImagesArray = React.useMemo(() => {
    // The filter method is used to remove empty items in imagesArray
    return imagesArray
      .map((image, originalIndex) => {
        return { image, originalIndex };
      })
      .filter((imageTuple) => imageTuple.image);
  }, [imagesArray]);

  if (imagesArray.length === 0) {
    return placeholder ? placeholder : null;
  }

  return (
    <Box className={clsx("hotel-shop-gallery", className)}>
      <div className={"gallery-main-image"}>
        {filteredImagesArray.length > 0 && (
          <Link
            key={filteredImagesArray[0].originalIndex}
            className="gallery-thumbnail-button"
            component="button"
            onClick={() => {
              onImageClick(0);
            }}
          >
            <img
              className="gallery-thumbnail"
              alt={"Hotel"}
              src={filteredImagesArray[0].image}
            />
          </Link>
        )}
      </div>
      {filteredImagesArray.length >= maxHeroImages && (
        <>
          <Box className="gallery-grid">
            {filteredImagesArray.slice(1, maxHeroImages).map((imageTuple) => {
              return (
                <Link
                  key={imageTuple.originalIndex}
                  className="gallery-thumbnail-button"
                  component="button"
                  onClick={() => {
                    onImageClick(imageTuple.originalIndex);
                  }}
                >
                  <img
                    className="gallery-thumbnail"
                    alt={"Hotel"}
                    src={imageTuple.image}
                  />
                </Link>
              );
            })}
          </Box>
          {!hideSeeMoreCta ? (
            <Slot
              id="lodging-details-screen-gallery-show-all-button"
              onClick={() => {
                onSeeMoreClick(filteredImagesArray[0]?.originalIndex ?? 0);
              }}
              message={`${seeMoreLabel} (${filteredImagesArray.length})`}
              defaultStyle={"h4r-secondary"}
              className="show-all-photos-button medium"
              size="small"
              component={
                <Button
                  className="show-all-photos-button medium"
                  onClick={() => {
                    onSeeMoreClick(filteredImagesArray[0]?.originalIndex ?? 0);
                  }}
                >
                  {seeMoreIcon ? (
                    seeMoreIcon
                  ) : (
                    <Icon name={IconName.ShowAllImagesIcon} />
                  )}
                  {`${seeMoreLabel} (${filteredImagesArray.length})`}
                </Button>
              }
            />
          ) : null}
        </>
      )}
    </Box>
  );
};
