import axios from "axios";

import { paymentApiPrefix } from "../../paths";
import {
  IApiConfig,
  NETWORK_CALL_FAILED,
  PaymentFinalizeResult,
  PaymentSessionToken,
} from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";

const END_POINT = `${paymentApiPrefix}/purchase/pollFinalized`;

export const pollFinalized = (
  req: PaymentSessionToken,
  apiConfig?: IApiConfig
): Promise<PaymentFinalizeResult> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(END_POINT, req);

      const responseBody = res.data;
      resolve(responseBody);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });
