import { useOpenAuthModal } from "@hopper-b2b/utilities";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Treasure from "../../../assets/treasure.svg";

import { useI18nContext } from "@hopper-b2b/i18n";

import "./SignupBanner.scss";
import clsx from "clsx";

export interface ISignupBanner {
  message: string;
  buttonLabel?: string;
  mb?: number;
  imageSrc?: string;
  small?: boolean;
  imageRight?: boolean;
}

const style = {
  backgroundColor: "#f7f4ea",
  border: "1px solid #f7f4ea",
  fontWeight: 800,
  padding: 0,
  borderRadius: "20px",
};

export const SignupBanner = ({
  message,
  buttonLabel,
  mb = 3,
  imageSrc,
  small,
  imageRight = false,
}: ISignupBanner) => {
  const { t } = useI18nContext();

  const openAuthModal = useOpenAuthModal();

  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const useSmall = small || isSmall;

  const showImageRight = imageRight || isSmall;

  return (
    <Box mb={mb} className="signup-container" onClick={openAuthModal}>
      <Paper variant="outlined" style={style}>
        <Box p={3} mb={0}>
          <Grid container direction="row" alignItems="center" spacing={4}>
            {!showImageRight && (
              <Grid item style={{ padding: 0 }}>
                <img src={Treasure} alt="" />
              </Grid>
            )}
            <Grid item xs>
              <Typography variant={useSmall ? "h3" : "h2"}>
                {message}
              </Typography>
              {useSmall && (
                <Box mt={2}>
                  <Button
                    onClick={openAuthModal}
                    variant="contained"
                    color="primary"
                    size={useSmall ? "small" : "medium"}
                    disableElevation
                    className={clsx({ "button-small": useSmall || imageRight })}
                  >
                    {buttonLabel ??
                      t?.(useSmall ? "signIn" : "signInOrRegister")}
                  </Button>
                </Box>
              )}
            </Grid>
            {!useSmall && (
              <Grid item>
                <Button
                  onClick={openAuthModal}
                  variant="contained"
                  color="primary"
                  size={useSmall ? "small" : "medium"}
                  disableElevation
                  className={clsx({ "button-small": useSmall || imageRight })}
                >
                  {buttonLabel ?? t?.(useSmall ? "signIn" : "signInOrRegister")}
                </Button>
              </Grid>
            )}
            {showImageRight && (
              <Grid item style={{ padding: 0 }}>
                <img src={imageSrc ?? Treasure} alt="" />
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};
