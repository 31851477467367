import clsx from "clsx";
import { Box } from "@material-ui/core";
import { useNavigate } from "react-router-dom-v5-compat";
import { NoResults, ActionButton } from "@hopper-b2b/ui";
import { ClientName, MyTripsFilter } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { PATH_FLIGHTS, PATH_HOTELS } from "../../../../utils/paths";
import { NoTripResultsConnectorProps } from "./container";
import { SadBunny } from "@hopper-b2b/ui";
import styles from "./styles.module.scss";
import { useExperiment } from "@hopper-b2b/experiments";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { capitalize } from "lodash-es";

interface INoTripResultsProps extends NoTripResultsConnectorProps {
  isMobile?: boolean;
}

export const NoTripResults = ({
  hasTripsToDisplay,
  hasError,
  tripsFilter,
  isMobile,
}: INoTripResultsProps) => {
  const { t } = useI18nContext();
  const navigate = useNavigate();
  const isLodgingEnabled = useExperiment("hopper-web-lodging");

  const isHopper = getEnvVariables("clientName") === ClientName.HOPPER;

  const getNoResultsMessage = (tripsFilter: MyTripsFilter) => {
    switch (tripsFilter) {
      case MyTripsFilter.UPCOMING_TRIPS:
        return t("noTrips");
      case MyTripsFilter.PAST_TRIPS:
        return t("noBookings");
      case MyTripsFilter.FLIGHTS:
        return t("noFlightBookings");
      case MyTripsFilter.HOTELS:
        return t("noHotelBookings");
      // case MyTripsFilter.WATCHED_TRIPS:
      //   return NO_WATCHED_TRIPS;
      // case MyTripsFilter.PRIZE_FREEZES:
      //   return NO_PRICE_FREEZES;
    }
    return;
  };

  const handleBookAFlightSelect = () => navigate(PATH_FLIGHTS);

  const handleBookAHotelSelect = () => navigate(PATH_HOTELS);

  const renderBookButtons =
    tripsFilter === MyTripsFilter.UPCOMING_TRIPS ||
    tripsFilter === MyTripsFilter.PAST_TRIPS;
  const renderBookFlightButton =
    renderBookButtons || tripsFilter === MyTripsFilter.FLIGHTS;
  const renderBookHotelButton =
    renderBookButtons || tripsFilter === MyTripsFilter.HOTELS;

  const iconSrc = isHopper ? SadBunny : undefined;

  return (
    <Box className={clsx({ mobile: isMobile }, styles.noTripsResults)}>
      {hasError && (
        <>
          <NoResults
            className="no-trips-container"
            title={t("tripsListErrorTitle")}
            subtitle={t("tripsListErrorSubtitle")}
            subtitleVariant="body1"
            iconSrc={iconSrc}
          />
          <Box className="no-trips-buttons">
            <ActionButton
              className="retry"
              message={t("retry")}
              defaultStyle="h4r-primary"
              onClick={() => window.location.reload()}
            />
          </Box>
        </>
      )}
      {!hasTripsToDisplay && !hasError && (
        <>
          <NoResults
            className="no-trips-container"
            title={t("noTripsFound")}
            subtitle={getNoResultsMessage(tripsFilter)}
            subtitleVariant="body1"
            iconSrc={iconSrc}
          />
          {(renderBookFlightButton || renderBookHotelButton) && (
            <Box className="no-trips-buttons">
              {isLodgingEnabled && renderBookHotelButton && (
                <ActionButton
                  className="book-a-trip margin-right"
                  message={capitalize(t("bookAHotel"))}
                  onClick={handleBookAHotelSelect}
                  size="medium"
                />
              )}
              {renderBookFlightButton && (
                <ActionButton
                  className="book-a-trip button-outline"
                  message={capitalize(t("bookAFlight"))}
                  defaultStyle="h4r-secondary"
                  onClick={handleBookAFlightSelect}
                  size="medium"
                />
              )}
              {/* TODO: Uncomment when integrating Cars funnel into portal */}
              {/* <ActionButton
                  className="book-a-trip"
                  message={textConstants.RENT_A_CAR}
                  defaultStyle="h4r-secondary"
                  onClick={() => {
                    history.push(PATH_CARS);
                  }}
                /> */}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
