import { useMemo } from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";

import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";
import { FlightSummaryInfo } from "@hopper-b2b/types";
import {
  getEnvVariables,
  htmlDecode,
  removeTimezone,
} from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";

import { AirlineIcon } from "../AirlineIcon";
import { IconComponent } from "../IconComponent";
import { Icon, IconName } from "../Icon";
import { MixedCabinToolTip } from "../MixedCabinToolTip";
import "./styles.scss";

enum OpenModalEnum {
  DEPARTURE = "DEPARTURE",
  RETURN = "RETURN",
}

export type IOpenModal = OpenModalEnum | false;

export interface MobileFlightSummaryRowNewProps {
  flightSummaryInfo: FlightSummaryInfo;
  iconSrc?: string;
  onClick?: () => void;
  isMixedClass?: boolean;
  className?: string;
  children?: React.ReactNode;
  hideIcon?: boolean;
  customIcon?: JSX.Element;
  customExpandIcon?: IconName;
  renderAirlineIcon?: boolean;
  customDate?: JSX.Element | string;
  customTime?: JSX.Element | string;
  showHackerFareContent?: boolean;
}

export const MobileFlightSummaryRowNew = ({
  flightSummaryInfo,
  iconSrc,
  onClick,
  isMixedClass,
  className,
  children,
  hideIcon = false,
  customIcon,
  customExpandIcon,
  renderAirlineIcon = true,
  customDate,
  customTime,
  showHackerFareContent = false,
}: MobileFlightSummaryRowNewProps) => {
  const { t, language: locale } = useI18nContext();

  const formattedDate = useMemo(
    () =>
      formatDateTime(
        new Date(removeTimezone(flightSummaryInfo.departure)),
        locale,
        DateTimeFormatStyle.ShortMonthDayShortWeekday
      ),
    [flightSummaryInfo.departure, locale]
  );

  const formattedTime = useMemo(
    () =>
      `${formatDateTime(
        new Date(removeTimezone(flightSummaryInfo.departure)),
        locale,
        DateTimeFormatStyle.ShortTime
      )} - ${formatDateTime(
        new Date(removeTimezone(flightSummaryInfo.arrival)),
        locale,
        DateTimeFormatStyle.ShortTime
      )}`,
    [flightSummaryInfo.arrival, flightSummaryInfo.departure, locale]
  );

  return (
    <div
      className={clsx(
        "airline-details-with-chevron mobile-flight-summary-row-new",
        className,
        getEnvVariables("clientName")
      )}
    >
      {hideIcon ? null : (
        <div className="airplane-icon-container">
          {customIcon ? (
            customIcon
          ) : iconSrc ? (
            <img
              alt="Airplane icon"
              className="mobile-left-icon"
              src={iconSrc}
            />
          ) : (
            <IconComponent
              ariaLabel="Airplane icon"
              className="mobile-left-icon"
              name={
                flightSummaryInfo.isDeparture
                  ? IconName.B2BMapPin
                  : IconName.B2BAirplaneIcon
              }
            />
          )}
        </div>
      )}
      <div className="airline-details-with-title">
        <div className="card-content">
          <div className="card-header">
            <div className="card-header-title">
              {htmlDecode(flightSummaryInfo?.title)}
            </div>
            {isMixedClass ? <MixedCabinToolTip showDivider={false} /> : null}
          </div>
          <div className="airline-details-date">
            <span className="airline-details-date-formatted">
              {customDate || formattedDate}{" "}
            </span>
            {flightSummaryInfo.stops ? (
              <>
                <span className="separator">&#183;</span>{" "}
                {flightSummaryInfo.stops > 0
                  ? t("stopDetails.stop", { count: flightSummaryInfo.stops })
                  : t("stopDetails.nonstop")}
              </>
            ) : null}
          </div>
          <div className="airline-details-time">
            <Typography variant="body1">
              {customTime || formattedTime}
            </Typography>
          </div>
          <div className="airline-details-name">
            <div className="card-airline-container">
              {renderAirlineIcon ? (
                <AirlineIcon airlineCode={flightSummaryInfo.airlineCode} />
              ) : null}
              <Typography variant="body2">
                {flightSummaryInfo.airlineName}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      {onClick ? (
        <button className="chevron-container" onClick={onClick}>
          <Icon
            className="mobile-right-chevron"
            name={customExpandIcon ? customExpandIcon : IconName.Chevron}
            ariaLabel="Expand chevron icon"
          />
        </button>
      ) : children ? (
        <div>{children}</div>
      ) : null}
    </div>
  );
};

export default MobileFlightSummaryRowNew;
