import { type ILodgingFilterState } from "../reducer";

export default function countActiveFilters({
  amenities,
  filterAmenitiesAnd,
  freeCancellation,
  priceRange,
  starRating,
  userRating,
}: ILodgingFilterState) {
  let count = 0;

  [
    [userRating, 0],
    [freeCancellation, false],
    [filterAmenitiesAnd, true],
  ].forEach(([filter, defaultValue]) => {
    if (filter !== defaultValue) {
      count++;
    }
  });

  [starRating, amenities].forEach((arrayBasedFilter) => {
    if (arrayBasedFilter.length > 0) {
      count++;
    }
  });

  if (
    priceRange.min !== priceRange.lowest ||
    priceRange.max !== priceRange.highest
  ) {
    count++;
  }

  return count;
}
