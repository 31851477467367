import axios from "axios";
import { config } from "../../config";

import { CarItineraryResponse } from "@hopper-b2b/types";
import { groundItinerariesApiPrefix } from "../paths";

export const fetchCars = async (): Promise<CarItineraryResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(
        `${groundItinerariesApiPrefix}`,
        { asOf: new Date().toISOString() },
        {
          baseURL: config.baseURL,
        }
      );
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};
