import { Box } from "@material-ui/core";
import { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";

import "../styles.scss";

export interface IHotelNameProps {
  isSkeleton?: boolean;
  name: ReactNode;
}

const defaultProps: Partial<IHotelNameProps> = {
  isSkeleton: false,
};

const HotelName = (props: IHotelNameProps): JSX.Element => {
  const { isSkeleton, name } = props;

  return (
    <Box className="hotel-name">
      {isSkeleton ? (
        <Skeleton className="hotel-availability-info-skeleton name" />
      ) : (
        name
      )}
    </Box>
  );
};

HotelName.defaultProps = defaultProps;

export default HotelName;
