import { connect, ConnectedProps } from "react-redux";

import { FareClassOptionSelection } from "./FareClassOptionSelection";
import { getFareclassOptionFilter } from "../../../../../search/reducer/selectors";
import { actions } from "../../../../../search/actions";

import { IStoreState } from "../../../../../../reducers/types";
import { setRerunPrediction } from "../../../../actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  fareClassOptionFilter: getFareclassOptionFilter(state),
});

const mapDispatchToProps = {
  setFareClassOptions: actions.setFareclassOptionFilter,
  setRerunPrediction: setRerunPrediction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FareClassOptionSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFareClassOptionSelection = connector(
  FareClassOptionSelection
);
