import { ApacActionButton } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import "./FlightsScoreExplained.styles.scss";

export interface FlightsScoreExplainedProps {
  onClose: () => void;
  className?: string;
}

export const FlightsScoreExplained = ({
  onClose,
  className,
}: FlightsScoreExplainedProps) => {
  const { t } = useI18nContext();

  const scoreItem = (key: string) => {
    return (
      <div className="score-item">
        <div>
          <Typography variant="h6">{t(`flightsScore.${key}.title`)}</Typography>
        </div>
        <Typography variant="body2">
          {t(`flightsScore.${key}.description`)}
        </Typography>
      </div>
    );
  };

  return (
    <div className={clsx(className, "flights-score-explained-container")}>
      <Typography variant="h1">{t("flightsScore.modalTitle")}</Typography>
      {scoreItem("bestOverall")}
      {scoreItem("bestPrice")}
      {scoreItem("fastest")}
      {scoreItem("bestQuality")}

      <div className="button-wrapper">
        <ApacActionButton message={t("close.button")} onClick={onClose} />
      </div>
    </div>
  );
};
