import axios from "axios";
import { trackEvent } from "@hopper-b2b/api";
import {
  DisruptionFetchOfferRequest,
  IApiConfig,
  NETWORK_CALL_FAILED,
} from "@hopper-b2b/types";
import { disruptionOfferApiPrefix } from "../../v1/paths";

const fetchDisruptionOfferPath = `${disruptionOfferApiPrefix}/offers/`;

export const fetchDisruptionOffers = (
  body: DisruptionFetchOfferRequest,
  apiConfig?: IApiConfig
): Promise<any> => {
  // TODO: Update type to GetAirDisruptionOfferResponse
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(fetchDisruptionOfferPath, body);
      resolve(res.data);
    } catch (error) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(error);
    }
  });
};
