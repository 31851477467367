import {
  ApacActionButton,
  ApacTermsLink,
  ApacIcon,
  ApacIconName,
} from "@commbank/ui";
import { Dealness, Prediction } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Typography } from "@material-ui/core";
import { getPriceDropMessageParams } from "../../MobilePricePrediction/MobilePricePredictionSection";
import "./PricePredictionDetails.styles.scss";
import clsx from "clsx";

export interface PricePredictionDetailsProps {
  prediction: Prediction;
  onClose: () => void;
  onClickWatch: () => void;
  isOneway: boolean;
}

export const getForecastedPrice = (prediction: Prediction) => {
  if (prediction) {
    const key = prediction.dealness === Dealness.wait ? "minPrice" : "maxPrice";
    const displayText = prediction.pricePrediction[key]?.display || "";
    if (displayText) {
      return displayText;
      // TODO: display RewardText once the backend is ready
    } else {
      return "";
    }
  } else {
    return "";
  }
};

const forecastIconName = (key: string) => {
  switch (key) {
    case "warning":
      return ApacIconName.PredictionYellow;
    case "danger":
      return ApacIconName.PredictionRed;
    default:
      return ApacIconName.PredictionGreen;
  }
};

export const PricePredictionDetails = ({
  prediction,
  onClose,
  onClickWatch,
  isOneway,
}: PricePredictionDetailsProps) => {
  const { t, formatFiatCurrency, formatCurrency } = useI18nContext();
  const { intervals } = prediction.predictionCopy;

  const getDescription = (prediction: Prediction) => {
    let description = t(
      "pricePrediction.basicDescription",
      getPriceDropMessageParams(prediction, formatCurrency)
    );

    if (prediction?.priceDropProtection?.PriceDropProtection === "IsEligible") {
      description +=
        " " +
        t(
          "pricePrediction.priceDropDescription",
          getPriceDropMessageParams(prediction, formatCurrency)
        );
    }
    return description;
  };

  return (
    <div className="price-prediction-details-container">
      <div className="title-container">
        <ApacIcon className="modal-icon" name={ApacIconName.PredictionDown} />
        <Typography className="title">
          {t("pricePrediction.modalTitle")}
        </Typography>
      </div>
      <div className="how-it-works-container">
        <Typography className="section-title">{t("howItWorks")}</Typography>
        <Typography>{getDescription(prediction)}</Typography>
      </div>
      <div className="modal-content-divider" />

      <Box className="price-summary">
        <div>
          <Typography className="title">
            {t("pricePrediction.currentPrice")}
          </Typography>
          <div className="price-detail">
            <Typography className="price low-price">
              {formatFiatCurrency(prediction?.lowestPrice?.fiat)}
            </Typography>
            {`${t(
              isOneway
                ? "pricePrediction.lowestPriceOneWaySuffix"
                : "pricePrediction.lowestPriceRoundTripSuffix"
            )}`}
          </div>
        </div>
        <div>
          <Typography className="title">
            {prediction.dealness === Dealness.wait
              ? t("pricePrediction.forecastedLowest")
              : t("pricePrediction.forecastedHighest")}
          </Typography>
          <div className="price-detail">
            <ApacIcon
              name={
                prediction.dealness === Dealness.wait
                  ? ApacIconName.PredictionDown
                  : ApacIconName.PredictionUp
              }
            />
            <Typography
              className={clsx(
                "price",
                prediction.dealness === Dealness.wait
                  ? "low-price"
                  : "high-price"
              )}
            >
              {getForecastedPrice(prediction)}
            </Typography>
            {t(
              isOneway
                ? "pricePrediction.lowestPriceOneWaySuffix"
                : "pricePrediction.lowestPriceRoundTripSuffix"
            )}
          </div>
        </div>
      </Box>

      <Box className="forecast-container">
        {Object.keys(intervals).map((key) => (
          <Box key={key} className="prediction-forecast-item">
            <ApacIcon name={forecastIconName(key)} />
            <Box className="description">
              <Typography variant="h6" className="dates">
                {intervals[key]?.dates}
              </Typography>
              <Typography variant="subtitle2" className="copy">
                <div
                  dangerouslySetInnerHTML={{
                    __html: intervals[key]?.copy || "",
                  }}
                ></div>
              </Typography>
            </Box>
          </Box>
        ))}
        <Box className={clsx("prediction-forecast-item", "end")}>
          <ApacIcon name={ApacIconName.CBAPlane} />
          <Box className="description">
            <Typography variant="h6" className="dates">
              {t("departure")}
            </Typography>
          </Box>
        </Box>
      </Box>

      <div className="modal-content-divider" />
      <Box className="footer">
        <ApacTermsLink scrollSelector="#price-prediction" showUnderline={false}>
          {t("termsAndConditions")}
        </ApacTermsLink>
        <div className="button-group">
          <ApacActionButton
            variant="outlined"
            onClick={onClickWatch}
            message={t("priceWatch.titles.buttonCopy")}
          />

          <ApacActionButton
            onClick={onClose}
            message={t("pricePrediction.bookNowIcon")}
          />
        </div>
      </Box>
    </div>
  );
};
