import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ApacActionLink } from "../../ApacActionLink";
import { ApacCheckbox } from "../../ApacCheckbox";
import "./TravelerSelectRow.styles.scss";
import { useMemo } from "react";
import { ApacRadio } from "../../ApacRadio";

export interface TravelerSelectRowProps {
  fullName: string;
  travelerType: string;
  isSelected: boolean;
  isMobile: boolean;
  disabled: boolean;
  onClickEdit: () => void;
  onClickRemove?: () => void;
  onClickSelect?: () => void;
  isHotel?: boolean;
  isReadOnly?: boolean;
  className?: string;
}

export const TravelerSelectRow = (
  props: TravelerSelectRowProps
): JSX.Element => {
  const {
    className,
    disabled,
    fullName,
    isHotel,
    isReadOnly,
    isMobile,
    isSelected,
    onClickEdit,
    onClickRemove,
    onClickSelect,
    travelerType,
  } = props;

  const { t } = useI18nContext();
  const nameId = useMemo(
    () => fullName.toLowerCase().replace(" ", "-"),
    [fullName]
  );

  return (
    <Box
      className={clsx("apac-traveller-selection-row", className, {
        disabled,
        mobile: isMobile,
      })}
      key={nameId}
    >
      <div
        className="left"
        onClick={() => {
          !disabled && !isReadOnly && onClickSelect();
        }}
      >
        {!isReadOnly &&
          (isHotel ? (
            <ApacRadio checked={isSelected} disabled={disabled} />
          ) : (
            <ApacCheckbox checked={isSelected} disabled={disabled} />
          ))}
        <Box className="traveller-container">
          <Typography variant={disabled ? "body2" : "body1"}>
            {fullName}
          </Typography>
          <Typography variant="body2">{travelerType}</Typography>
        </Box>
      </div>

      {!disabled && !isReadOnly && (
        <div className="right">
          <ApacActionLink
            message={t("commBank.hotelBook.editTravelerButtonLabel")}
            disabled={disabled}
            onClick={() => {
              !disabled && onClickEdit();
            }}
          />
          {onClickRemove && (
            <>
              <Typography className="divider" variant="body2">
                |
              </Typography>
              <ApacActionLink
                message={t("commBank.hotelBook.deleteTravelerButtonLabel")}
                disabled={disabled}
                onClick={() => !disabled && onClickRemove()}
              />
            </>
          )}
        </div>
      )}
    </Box>
  );
};

export default TravelerSelectRow;
