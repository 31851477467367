import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../../reducers/types";
import { MobileFlightItineraryDetails } from "./component";
import {
  getAirlinesMap,
  getAirportMap,
  getSelectedFlight,
  getTripsFilter,
} from "../../../../reducer";
import { setSelectedFlight, setOpenModal } from "../../../../actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  flight: getSelectedFlight(state),
  airportMap: getAirportMap(state),
  airlineMap: getAirlinesMap(state),
  tripsFilter: getTripsFilter(state),
});

const mapDispatchToProps = {
  setSelectedFlight,
  setOpenModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileFlightItineraryDetailsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileFlightItineraryDetails = connector(
  withRouter(MobileFlightItineraryDetails)
);
