import { ExchangeActionEnum } from "@b2bportal/air-exchange-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { MobileFloatingButton } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Button, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { setOutboundSelection, setReturnSelection } from "../../reducer/search";
import {
  getOutboundChanged,
  getOutboundSelection,
  getReturnChanged,
  getReturnSelection,
} from "../../reducer/selectors";
import { getItinerarySlices } from "../../selectors";
import SliceOption from "./SlicePickerOption";

import "./styles.scss";

export interface ISlicePickerStepProps {
  onContinue: () => void;
}

const defaultProps: Partial<ISlicePickerStepProps> = {};

const SlicePickerStep = (props: ISlicePickerStepProps): JSX.Element => {
  const { onContinue } = props;
  const { matchesMobile } = useDeviceTypes();
  const dispatch = useDispatch();
  const { t } = useI18nContext();
  const [outboundSlice, returnSlice] = useSelector(getItinerarySlices);
  const outboundChanged = useSelector(getOutboundChanged);
  const outboundSelection = useSelector(getOutboundSelection);
  const returnChanged = useSelector(getReturnChanged);
  const returnSelection = useSelector(getReturnSelection);

  const modOutbound = outboundSelection === ExchangeActionEnum.Change;
  const modReturn = returnSelection === ExchangeActionEnum.Change;

  const toggleOutboundSlice = () => {
    dispatch(
      setOutboundSelection(
        modOutbound ? ExchangeActionEnum.Keep : ExchangeActionEnum.Change
      )
    );
  };

  const toggleReturnSlice = () => {
    dispatch(
      setReturnSelection(
        modReturn ? ExchangeActionEnum.Keep : ExchangeActionEnum.Change
      )
    );
  };

  return (
    <Box className="slice-picker-container">
      <Box className="slice-picker-header">
        <Typography className="slice-picker-title" variant="h3">
          {t("exchangeable.slicePicker.title")}
        </Typography>
      </Box>
      <Box className="slices-container">
        {outboundSlice && (
          <SliceOption
            disabled={outboundChanged}
            isToggled={modOutbound}
            onToggle={toggleOutboundSlice}
            slice={outboundSlice}
          />
        )}
        {returnSlice && (
          <SliceOption
            disabled={returnChanged}
            isToggled={modReturn}
            onToggle={toggleReturnSlice}
            slice={returnSlice}
          />
        )}
      </Box>
      {matchesMobile ? (
        <MobileFloatingButton
          wide
          className="slice-picker-continue-btn"
          disabled={!modOutbound && returnSlice && !modReturn}
          onClick={onContinue}
          wrapperClassName="b2b"
        >
          {t("continue")}
        </MobileFloatingButton>
      ) : (
        <Button
          className="b2b"
          disabled={!modOutbound && returnSlice && !modReturn}
          onClick={onContinue}
        >
          {t("continue")}
        </Button>
      )}
    </Box>
  );
};

SlicePickerStep.defaultProps = defaultProps;

export default SlicePickerStep;
