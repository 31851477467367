import { ApacIcon, ApacIconName, ErrorMessageWrapper } from "../..";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Collapse, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import "./DateSelectButton.styles.scss";

export const DateSelectButton = ({
  date,
  label,
  onClick,
  error,
  className,
  showIcon,
}: {
  date: Date;
  label: string;
  error?: string;
  onClick?: () => void;
  className?: string;
  showIcon?: boolean;
}) => {
  const { t } = useI18nContext();

  return (
    <Box
      className={clsx("date-button-container", className, { error: !!error })}
      onClick={onClick}
      tabIndex={0}
      onKeyDown={(event) => {
        if (["Enter", "Space"].includes(event.code)) {
          onClick();
        }
      }}
    >
      <Typography color="textPrimary" className="content-top-label" noWrap>
        {label}
      </Typography>
      <Box className="button-content">
        <Typography
          color="textPrimary"
          className={clsx("content-main-content", { label: !date })}
        >
          {date
            ? dayjs(date).format("D MMM YYYY")
            : t("commBank.hotelSearch.headerTitle.calendar")}
        </Typography>
        {showIcon && <ApacIcon name={ApacIconName.Calendar} />}
      </Box>
      <Collapse in={!!error}>
        <ErrorMessageWrapper message={error} />
      </Collapse>
    </Box>
  );
};
