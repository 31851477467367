import { connect, ConnectedProps } from "react-redux";

import { TripCategory } from "@hopper-b2b/types";

import { IStoreState } from "../../../reducers/types";
import { populateFlightBookQueryParams } from "../../book/actions/actions";
import { getSelectedAccountReferenceId } from "../../rewards/reducer";
import { actions as searchActions } from "../../search/actions";
import {
  getDepartureDate,
  getDestination,
  getFareclassOptionFilter,
  getHasSetFareClassFilter,
  getOrigin,
  getReturnDate,
  getTripCategory,
} from "../../search/reducer";
import {
  fetchSelectedTripPriceFreezeOffer,
  fetchTripDetails,
  fetchFlightsV4ForPrediction,
  listWatches,
  setChosenOutgoingSlice,
  setChosenReturnSlice,
  setOpenCalendarModal,
  setSelectedTripPriceFreezeOffer,
} from "../actions/actions";
import {
  flightShopTypeSelector,
  flightsSelector,
  getSortedAndFilteredFlights,
  getTripResultEventProperties,
  getViewedForecastPropertiesForV2,
  hasSetNonFareclassFiltersSelectorV2,
  isInChooseDepartureStepSelector,
  isInChooseReturnStepSelector,
  isOutgoingMultiTicket,
  isReturnMultiTicket,
  maxFlightPriceSelectorV2,
  predictionSelector,
  refreshPredictionSelector,
  selectedTripSelector,
  tripDetailsByIdSelector,
  tripDetailsSelector,
  tripSummariesErrorSelector,
  tripSummariesLoadingSelector,
} from "../reducer";
import { FlightShopV3 } from "./FlightShopV3";

const mapStateToProps = (state: IStoreState) => {
  const flights = flightsSelector(state);
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);

  return {
    tripSummariesLoading: tripSummariesLoadingSelector(state),
    hasFlightsError: tripSummariesErrorSelector(state) || false,
    flights,
    rewardsKey: getSelectedAccountReferenceId(state) ?? undefined,
    fareClassFilter: getFareclassOptionFilter(state),
    isRoundTrip: getTripCategory(state) === TripCategory.ROUND_TRIP,
    prediction: predictionSelector(state),
    origin: getOrigin(state),
    destination: getDestination(state),
    tripDetailsById: tripDetailsByIdSelector(state),
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    flightList: getSortedAndFilteredFlights(state),
    viewedForecastProperties: getViewedForecastPropertiesForV2(state),
    tripResultEventProperties: getTripResultEventProperties(state),
    selectedTrip,
    maxFlightPrice: maxFlightPriceSelectorV2(state),
    hasAppliedFareClassFilter: getHasSetFareClassFilter(state),
    hasAppliedNonFareclassFilter: hasSetNonFareclassFiltersSelectorV2(state),
    flightShopType: flightShopTypeSelector(state),
    refreshPrediction: refreshPredictionSelector(state),
    isMultiTicket: isOutgoingMultiTicket(state) || isReturnMultiTicket(state),
    isInChooseDepartureStep: isInChooseDepartureStepSelector(state),
    isInChooseReturnStep: isInChooseReturnStepSelector(state),
    tripDetails,
  };
};

const mapDispatchToProps = {
  fetchTripDetails,
  setSelectedTripPriceFreezeOffer,
  setChosenReturnSlice,
  setChosenOutgoingSlice,
  setOpenCalendarModal,
  setAirlineFilter: searchActions.setAirlineFilter,
  setStopsOption: searchActions.setStopsOption,
  setFlightNumberFilter: searchActions.setFlightNumberFilter,
  setOutboundArrivalTimeRange: searchActions.setOutboundArrivalTimeRange,
  setOutboundDepartureTimeRange: searchActions.setOutboundDepartureTimeRange,
  setReturnDepartureTimeRange: searchActions.setReturnDepartureTimeRange,
  setReturnArrivalTimeRange: searchActions.setReturnArrivalTimeRange,
  setMaxPriceFilter: searchActions.setMaxPriceFilter,
  setFareclassOptionFilter: searchActions.setFareclassOptionFilter,
  setAirportFilter: searchActions.setAirportFilter,
  populateFlightBookQueryParams,
  fetchFlightsV4ForPrediction: fetchFlightsV4ForPrediction,
  listWatches,
  fetchSelectedTripPriceFreezeOffer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightShop = connector(FlightShopV3);
