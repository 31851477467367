import { put } from "redux-saga/effects";
import { WatchAlertViews } from "@b2bportal/air-price-watch-api";
import {
  CallState,
  MessageMethodEnum,
  UpdateWatchAlertRequest,
} from "@hopper-b2b/types";
import { updateWatch } from "@hopper-b2b/api";

import { actions } from "../../actions";
import { listWatches } from "../../../../api/v1/price-watch/listWatches";

export function* updateWatchSaga(action: actions.IUpdateWatch) {
  try {
    const request: UpdateWatchAlertRequest = {
      key: action.alertKey,
      method: {
        email: action.email,
        MessageMethod: MessageMethodEnum.Email,
      },
    };
    yield updateWatch(request);
    const watches: WatchAlertViews = yield listWatches();
    yield put(actions.setWatches(watches));
    yield put(actions.setGetDetailsWatchCallState(CallState.Success));
    yield put(actions.setUpdateWatchCallState(CallState.Success));
  } catch (e) {
    yield put(actions.setGetDetailsWatchCallState(CallState.Failed));
    yield put(actions.setUpdateWatchCallState(CallState.Failed));
  }
}
