import { FlightItinerarySlice } from "@b2bportal/air-booking-api";
import { Slice } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { FareDetails, getDurationFromSegments } from "@hopper-b2b/types";
import { ActionLink, FlightSummaryPanel } from "@hopper-b2b/ui";
import { formatInterval, removeTimezone } from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

import { setFlightShopProgress } from "../../../shop/actions/actions";
import { FlightShopStep } from "../../../shop/reducer";
import { getExchangeType } from "../../reducer/selectors";
import { getAirlines, getAirports, getIsFtc } from "../../selectors";
import { TripType, formats } from "../../types";
import { getAirportCityName, skipShopAction } from "../../utils";

import "./styles.scss";

export interface ISliceSummaryCardProps {
  className: string;
  fareDetails?: FareDetails;
  slice: FlightItinerarySlice | Slice;
  tripType: TripType;
}

const defaultProps: Partial<ISliceSummaryCardProps> = {};

const SliceSummaryCard = (props: ISliceSummaryCardProps): JSX.Element => {
  const { className, fareDetails, slice, tripType } = props;

  const dispatch = useDispatch();
  const { t } = useI18nContext();
  const airlines = useSelector(getAirlines);
  const airports = useSelector(getAirports);
  const isFTC = useSelector(getIsFtc);
  const { outboundSelection, returnSelection } = useSelector(getExchangeType);

  let arrival,
    departure,
    destination,
    marketingAirline = "",
    plusDays = 0,
    stops = 0,
    totalDurationMinutes = 0;

  if ("id" in slice) {
    // handles Slice type (from shop response)
    ({
      arrival,
      departure,
      destination,
      marketingAirline,
      stops,
      totalDurationMinutes,
    } = slice);

    plusDays = slice.segments.reduce((days, seg) => days + seg.plusDays, 0);
  } else {
    // handles FlightItinerarySlice (from prev booking)
    const { segments } = slice;
    const numSegments = segments.length - 1;

    ({
      marketingAirline: { code: marketingAirline },
      updatedDeparture: departure,
    } = segments[0]);
    ({
      destination: { locationCode: destination },
      updatedArrival: arrival,
    } = segments[numSegments]);

    plusDays = segments.reduce((days, seg) => days + seg.plusDays, 0);
    stops = numSegments - 1;
    totalDurationMinutes = getDurationFromSegments(segments, (dep, arr) =>
      dayjs(arr).diff(dayjs(dep), "m")
    );
  }

  const airline = airlines[marketingAirline];
  const cityName = getAirportCityName(airports, destination);
  const isOutbound = tripType === TripType.Outbound;
  const blockChange = isOutbound
    ? skipShopAction(outboundSelection)
    : skipShopAction(returnSelection);
  const cardActions = [];
  let stopsString = t("stopDetails.nonstop");

  if (stops === 1) stopsString = t("stopDetails.stop_one", { count: stops });
  else if (stops > 1) {
    stopsString = t("stopDetails.stop_other", { count: stops });
  }

  if (isFTC) {
    cardActions.push(
      <ActionLink
        ariaProps={{ role: "button" }}
        className="b2b new-trip-summary-action"
        content={t("")}
        key="view-details-btn"
        label={t("")}
        onClick={() => {
          dispatch(setFlightShopProgress(FlightShopStep.ReviewItinerary));
        }}
      />
    );
  }

  if (!blockChange) {
    cardActions.push(
      <ActionLink
        ariaProps={{ role: "button" }}
        className="b2b new-trip-summary-action"
        content={t("")}
        key="change-btn"
        label={t("")}
        onClick={() => {
          dispatch(
            setFlightShopProgress(
              isOutbound
                ? FlightShopStep.ChooseDeparture
                : FlightShopStep.ChooseReturn
            )
          );
        }}
      />
    );
  }

  return (
    <Box className="slice-summary-card-container">
      <FlightSummaryPanel
        airline={airline?.displayName ?? ""}
        airlineCode={airline?.code ?? ""}
        className={clsx("flight-summary-card", className)}
        duration={formatInterval(totalDurationMinutes)}
        fareClass={fareDetails?.slices[0].fareShelf.shortBrandName ?? ""}
        formattedDepartureDescription={t("exchangeable.submit.sliceDesc", {
          cityName,
          cityCode: destination,
          date: dayjs(departure).format(formats.DISPLAY_DATE),
        })}
        formattedArrivalTime={dayjs(removeTimezone(arrival)).format(
          formats.SUMMARY_TIME
        )}
        formattedDepartureTime={dayjs(removeTimezone(departure)).format(
          formats.SUMMARY_TIME
        )}
        plusDays={plusDays}
        stopString={stopsString}
        tripCategory={tripType}
      />
    </Box>
  );
};

SliceSummaryCard.defaultProps = defaultProps;

export default SliceSummaryCard;
