import { numberWithCommas } from "@commbank/lodging";
import { ApacActionLink } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useMemo, useState } from "react";
import "./MyCardsTab.styles.scss";
import { useAccounts } from "@commbank/accounts";
import { TabFooter } from "../TabFooter";

export type MyCardsTabProps = {
  isSidePanel?: boolean;
};

export const MyCardsTab = ({ isSidePanel }: MyCardsTabProps) => {
  const { t } = useI18nContext();
  const { matchesMobile: isMobile } = useDeviceTypes();
  const { accounts } = useAccounts();

  const [showAllAccounts, setShowAllAccounts] = useState(false);

  const displayAccounts = useMemo(() => {
    return showAllAccounts ? accounts : accounts.slice(0, 6);
  }, [accounts, showAllAccounts]);

  return (
    <div>
      <div
        className={clsx("my-cards-tab-container", {
          mobile: isMobile,
          isSidePanel,
        })}
      >
        <Grid container spacing={3} className="cards-container-grid">
          {displayAccounts.map((account) => {
            return (
              <Grid
                key={account.lastFour}
                className="card-item"
                item
                xs={12}
                md={isSidePanel ? 12 : 4}
              >
                <img src={account.cardArt} alt="card art" />

                <div className="card-description">
                  <Typography className="card-title" variant="h6">
                    {account.cardDisplayName}
                  </Typography>
                  <Typography variant="body1">{`*** *** *** ${account.lastFour}`}</Typography>
                  {account.awardsBalance?.value !== undefined && (
                    <Typography variant="body1">
                      {t("breakdown.awardsPointsPrice", {
                        value: numberWithCommas(account.awardsBalance.value),
                      })}
                    </Typography>
                  )}
                </div>
              </Grid>
            );
          })}
        </Grid>

        {accounts?.length > 6 && (
          <ApacActionLink
            message={t(
              showAllAccounts ? "wallet.showLessCards" : "wallet.showMoreCards"
            )}
            onClick={() => setShowAllAccounts((pre) => !pre)}
          />
        )}
      </div>

      <TabFooter showFaq />
    </div>
  );
};
