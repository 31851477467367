import { Lodging, MediaCategory } from "@b2bportal/lodging-api";
import { Box, IconButton, Tab, Tabs, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ActionButton,
  ButtonWrap,
  DesktopPopupModal,
  IconComponent,
  IconName,
  LodgingAddress,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import { useDeviceTypes, useTenantIcons } from "@hopper-b2b/utilities";

import { ApacIcon, ApacIconName } from "@commbank/ui";
import { getAddressText } from "../../../../../../util/utils";
import {
  getSelectedLodging,
  getSelectedLodgingGroupedMedia,
  getSelectedLodgingMedia,
  getSelectedLodgingMediaCategories,
} from "../../../../reducer/selectors";
import "./styles.scss";

interface ModalGalleryProps {
  open: boolean;
  onClose: () => void;
  viewRoomOnClick: () => void;
}

interface PopoverGalleryProps extends ModalGalleryProps {
  isMobile?: boolean;
}

export const ModalGallery = (props: ModalGalleryProps) => {
  const { matchesMobile } = useDeviceTypes();

  return matchesMobile ? (
    <MobilePopoverGallery {...props} isMobile />
  ) : (
    <DesktopPopoverGallery {...props} />
  );
};

const MobilePopoverGallery = (props: PopoverGalleryProps) => {
  const { t } = useI18nContext();
  const selectedLodging = useSelector(getSelectedLodging);

  return (
    <MobilePopoverCard
      className={"mobile-expanded-gallery-popover"}
      open={props.open}
      onClose={props.onClose}
      topRightButton={
        <ButtonWrap
          aria-label="Close"
          onClick={props.onClose}
          className="close-button-icon"
        >
          <IconComponent
            name={IconName.Close}
            className="mobile-modal-close-button"
          />
        </ButtonWrap>
      }
      fullScreen
    >
      <SharedGalleryGrid {...props} selectedLodging={selectedLodging} />
    </MobilePopoverCard>
  );
};

const DesktopPopoverGallery = (props: PopoverGalleryProps) => {
  const selectedLodging = useSelector(getSelectedLodging);

  return (
    <DesktopPopupModal
      className={"desktop-expanded-gallery-popover"}
      open={props.open}
      onClose={props.onClose}
      invisibleBackdrop={false}
    >
      <SharedGalleryGrid {...props} selectedLodging={selectedLodging} />
    </DesktopPopupModal>
  );
};

interface SharedGalleryGridProps extends PopoverGalleryProps {
  selectedLodging: Lodging;
}

const SharedGalleryGrid = ({
  selectedLodging,
  isMobile,
  viewRoomOnClick,
  onClose,
}: SharedGalleryGridProps) => {
  const icons = useTenantIcons();

  const { t } = useI18nContext();
  const media = useSelector(getSelectedLodgingMedia);
  const groupedMedia = useSelector(getSelectedLodgingGroupedMedia);

  const [selectedCategory, setSelectedCategory] =
    useState<MediaCategoryWithAllPhotos>(MediaCategoryWithAllPhotos.AllPhotos);
  const [selectedImage, setSelectedImage] = useState(null);

  const filteredMedia = useMemo(() => {
    const imageList =
      selectedCategory === MediaCategoryWithAllPhotos.AllPhotos
        ? media
        : groupedMedia[selectedCategory];
    setSelectedImage(imageList[0]);
    return imageList;
  }, [media, selectedCategory]);

  const onCategoryChange = (category: MediaCategoryWithAllPhotos) => {
    setSelectedCategory(category);
  };

  return (
    <div className="shared-gallery-container">
      <div className={"expanded-gallery-content"}>
        <div className="expanded-gallery-header-container">
          <div className="title-category-container">
            <Box className="modal-title-container">
              <Typography className="modal-title" variant="h1">
                {selectedLodging.lodging.name}
              </Typography>
              <LodgingAddress
                address={getAddressText(selectedLodging.lodging.address)}
                icon={<ApacIcon name={ApacIconName.LocationOutlined} />}
              />
            </Box>
            <CategoryFilter
              onClick={onCategoryChange}
              selected={selectedCategory}
              isMobile={isMobile}
            />
          </div>
        </div>
        <div className="expanded-gallery-grid-container">
          {selectedImage ? (
            <img
              key={selectedImage.url}
              className={clsx("expanded-gallery-preview", {
                mobile: isMobile,
              })}
              alt={t("hotelImageAltWithCategory", {
                category: selectedImage.category,
              })}
              src={selectedImage.url}
            />
          ) : null}
          <div className={clsx("expanded-gallery-grid", { mobile: isMobile })}>
            {filteredMedia.map((image, index) => {
              return (
                <img
                  key={index}
                  className={clsx("expanded-gallery-thumbnail", {
                    mobile: isMobile,
                  })}
                  alt={t("hotelImageAltWithCategory", {
                    category: image.category,
                  })}
                  src={image.url}
                  onClick={() => setSelectedImage(image)}
                />
              );
            })}
          </div>
        </div>
        <ActionButton
          onClick={onClose}
          message={t("hotelShop.closeDialogButtonLabel")}
          className="close-button"
        />
      </div>
    </div>
  );
};

const CategoryFilter = ({
  onClick,
  selected,
  isMobile,
}: {
  selected: MediaCategoryWithAllPhotos;
  onClick: (category: MediaCategoryWithAllPhotos) => void;
  isMobile: boolean;
}) => {
  const icons = useTenantIcons();

  const { t } = useI18nContext();

  const categories = useSelector(getSelectedLodgingMediaCategories);
  const mappedCategoriesToI18nKeys =
    getMapOfMediaCategoriesToI18nKey(categories);

  return (
    <div className={clsx("categories-filter-container", { mobile: isMobile })}>
      <Tabs
        value={selected}
        onChange={(_, value) => onClick(value)}
        className={clsx("filter-tabs-container", { mobile: isMobile })}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="hotel image categories tabs"
        ScrollButtonComponent={(props) => {
          if (!props.direction) return null;
          return (
            <div className={clsx("scroll-icon-container", props.direction)}>
              {!props.disabled ? (
                <IconButton
                  {...props}
                  className={clsx(
                    props?.className,
                    props.direction === "left"
                      ? "left-scroll-icon"
                      : "right-scroll-icon"
                  )}
                >
                  <img
                    src={
                      props.direction === "left"
                        ? icons.chevronLeft
                        : icons.chevron
                    }
                    alt=""
                  />
                </IconButton>
              ) : null}
            </div>
          );
        }}
      >
        <Tab
          key={MediaCategoryWithAllPhotos.AllPhotos}
          id={MediaCategoryWithAllPhotos.AllPhotos}
          value={MediaCategoryWithAllPhotos.AllPhotos}
          className="tab"
          tabIndex={MediaCategoryWithAllPhotos.AllPhotos === selected ? 0 : -1}
          label={
            <Typography
              className={clsx(
                "label",
                MediaCategoryWithAllPhotos.AllPhotos === selected && "selected"
              )}
            >
              {t("mediaCategory.allPhotos")}
            </Typography>
          }
        />
        {mappedCategoriesToI18nKeys.map(([category, key]) => (
          <Tab
            key={category}
            id={category}
            value={category}
            className="tab"
            tabIndex={category === selected ? 0 : -1}
            label={
              <Typography
                className={clsx("label", category === selected && "selected")}
              >
                {t(key)}
              </Typography>
            }
          />
        ))}
      </Tabs>
    </div>
  );
};

type I18nKey = string;

const getMapOfMediaCategoriesToI18nKey = (
  categories: Array<MediaCategory>
): Array<[MediaCategory, I18nKey]> => {
  const fallbackKey = "mediaCategory.uncategorized";

  const mappedKeys: Array<[MediaCategory, I18nKey]> = categories.map(
    (category) => [
      category,
      mapOfMediaCategoriesToI18nKey[category] || fallbackKey,
    ]
  );

  // Pushes Uncategoried to the end of the list of categories
  return mappedKeys.findIndex(([, i18nKey]) => i18nKey === fallbackKey) !== -1
    ? [
        ...mappedKeys.filter(([, i18nKey]) => i18nKey !== fallbackKey),
        [MediaCategory.Uncategorized, fallbackKey],
      ]
    : mappedKeys;
};

const mapOfMediaCategoriesToI18nKey: Record<MediaCategory, I18nKey> = {
  // Copied from hopper-web-bff
  // https://github.com/hopper-org/hopper-web-bff/blob/06be1333811990040b9af458b758803138957367/server/src/main/scala/com/hopper/web/service/lodging/converter/ReservationConverters.scala#L670-L687
  [MediaCategory.Primary]: "mediaCategory.primary",
  [MediaCategory.Lobby]: "mediaCategory.lobby",
  [MediaCategory.GuestRoom]: "mediaCategory.guestRoom",
  [MediaCategory.Pool]: "mediaCategory.pool",
  [MediaCategory.Fitness]: "mediaCategory.fitness",
  [MediaCategory.Spa]: "mediaCategory.spa",
  [MediaCategory.SportsFacility]: "mediaCategory.sportsFacility",
  [MediaCategory.Amenity]: "mediaCategory.amenity",
  [MediaCategory.Dining]: "mediaCategory.dining",
  [MediaCategory.Bar]: "mediaCategory.bar",
  [MediaCategory.Interior]: "mediaCategory.interior",
  [MediaCategory.Exterior]: "mediaCategory.exterior",
  [MediaCategory.View]: "mediaCategory.view",
  [MediaCategory.PointOfInterest]: "mediaCategory.pointOfInterest",
  [MediaCategory.Uncategorized]: "mediaCategory.uncategorized",
};

const MediaCategoryWithAllPhotos = {
  ...MediaCategory,
  AllPhotos: "AllPhotos",
} as const;
export type MediaCategoryWithAllPhotos =
  (typeof MediaCategoryWithAllPhotos)[keyof typeof MediaCategoryWithAllPhotos];
