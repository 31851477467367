import { ActionLink } from "../ActionLink";
import { Icon, IconName } from "../Icon";
import clsx from "clsx";
import { ReactNode } from "react";
import "./ArrowActionLink.styles.scss";

export interface IArrowActionLinkProps {
  message: ReactNode;
  onClick?: () => void;
  className?: string;
  showArrow?: boolean;
  disabled?: boolean;
  showUnderline?: boolean;
}

/**
 * A simple component for blue clickable link text,
 * with showArrow for terms and conditions text
 */
export const ArrowActionLink = ({
  message,
  className,
  onClick,
  showArrow = false,
  disabled,
  showUnderline,
  ...rest
}: IArrowActionLinkProps) => {
  return (
    <div
      className={clsx("arrow-action-link", className, {
        showUnderline,
      })}
      onClick={onClick}
    >
      <ActionLink
        className="link"
        content={message}
        disabled={disabled}
        {...rest}
      />
      {showArrow && <Icon name={IconName.LinkArrow} />}
    </div>
  );
};
