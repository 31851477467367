import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  AirCancelEligibilityResponse,
  AirCancelFulfillmentPollResponseEnum,
  AirCancelQuoteResponse,
  AirCancelQuoteResponseEnum,
  CancelScenario,
  CancelScenarioEnum,
  NonCfarEnum,
} from "@b2bportal/air-cancel-api";
import { trackEvent } from "@hopper-b2b/api";
import { SelfServeEvents, TravelItineraryEnum } from "@hopper-b2b/types";
import { getTrackingNonCfar } from "@hopper-b2b/self-serve/src/utils";
import {
  getFlightCancelEligibility,
  cancelQuote,
  cancelFulfill,
  cancelFulfillPoll,
} from "../../../../../../../../api/v0/self-serve";
import { useEnableHFv2 } from "@hopper-b2b/utilities";
import {
  usePollingTiming,
  type UsePollingTimingHandlers,
} from "./use-polling-timing";
import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";

export enum CancelStep {
  CancelationError,
  CancelationPending,
  CancelationFlowComplete,
  CancelationInfo,
  ConfirmCancelation,
  ContactSupport,
  LoadingOrProcessing,
  SlicePicker,
}

export const getTrackingProps = (scenario) => {
  if (!scenario) return;

  return {
    cancel_scenario: scenario.CancelScenario,
    non_cfar: "NonCfar" in scenario ? getTrackingNonCfar(scenario.NonCfar) : "",
    penalty_amount:
      ("penaltyAmount" in scenario ? scenario.penaltyAmount : false) || "",
    product: "flight",
  };
};

export type UseCancellationActionsContext = {
  timedOut: boolean;
  scenario: CancelScenario;
  cancelStep: CancelStep;
  cancelInfoRef: MutableRefObject<
    AirCancelEligibilityResponse | AirCancelQuoteResponse
  >;
};

export type UseCancellationActionsHandlers = Pick<
  UsePollingTimingHandlers,
  "clearPolicyTimeout" | "startPolicyTimeout"
> & {
  setCancelStep: (step: CancelStep) => void;
  confirmCancellation: () => Promise<void>;
  getCancelInfo: (bookingLocator?: string) => Promise<void>;
};

interface UseCancellationActionsResponse {
  context: UseCancellationActionsContext;
  handlers: UseCancellationActionsHandlers;
}

export const useCancellationActions = ({
  flight,
}: {
  flight: BookedFlightItineraryWithDepartureTime;
}): UseCancellationActionsResponse => {
  const enableHFv2 = useEnableHFv2();
  const cancelInfoRef = useRef<
    AirCancelEligibilityResponse | AirCancelQuoteResponse
  >();

  const [scenario, setScenario] = useState<CancelScenario>();
  const [cancelStep, setCancelStep] = useState<CancelStep>(
    CancelStep.LoadingOrProcessing
  );

  const {
    context: { timedOut },
    handlers: {
      startPolicyTimeout,
      clearPolicyTimeout,
      clearPollInterval,
      setPolling,
    },
  } = usePollingTiming();

  const handleCancelInfo = useCallback(
    (scenario: CancelScenario) => {
      trackEvent({
        eventName: SelfServeEvents.ViewedCancelModal,
        properties: getTrackingProps(scenario),
      });

      setScenario(scenario);

      switch (scenario.CancelScenario) {
        case CancelScenarioEnum.AirlineControl:
        case CancelScenarioEnum.BookingPending:
        case CancelScenarioEnum.Canceled:
        case CancelScenarioEnum.Cfar:
        case CancelScenarioEnum.Departed:
          return setCancelStep(CancelStep.CancelationError);
        case CancelScenarioEnum.CancellationPending:
          return setCancelStep(CancelStep.CancelationPending);
        case CancelScenarioEnum.NonCfar:
          if (
            scenario.NonCfar === NonCfarEnum.ContactCustomerService ||
            (scenario.NonCfar === NonCfarEnum.MultiProvider && !enableHFv2)
          ) {
            return setCancelStep(CancelStep.ContactSupport);
          }

          setCancelStep(CancelStep.CancelationInfo);
          break;
        default:
      }
    },
    [enableHFv2]
  );

  /**
   * @description For multi-provider/multi-ticket itineraries.
   * The individual slices may have different cancellation policies
   * so we fetch their cancel eligibilities and display the slice picker.
   */
  const getCancelEligibility = useCallback(async (resId) => {
    try {
      const cancelEligibility = await getFlightCancelEligibility(resId);

      if (cancelEligibility) {
        cancelInfoRef.current = cancelEligibility;
        setCancelStep(CancelStep.SlicePicker);
      }
    } catch (err) {
      setCancelStep(CancelStep.CancelationError);
    }
  }, []);

  /**
   * @description Part 1 of the self serve cancel flow on the BE.
   * Requests the cancel info of the flight to be shown to the
   * user.
   */
  const getCancelInfo = useCallback(
    async (bookingLocator = "") => {
      setCancelStep(CancelStep.LoadingOrProcessing);

      try {
        const { id: reservationId } = flight.bookedItinerary;
        const cancelInfo = await cancelQuote(reservationId, bookingLocator);

        cancelInfoRef.current = cancelInfo;

        if ("cancelScenario" in cancelInfo) {
          handleCancelInfo(cancelInfo.cancelScenario);
        } else if (
          cancelInfo.AirCancelQuoteResponse ===
          AirCancelQuoteResponseEnum.AirCancelAlreadyRequestedFailure
        ) {
          setCancelStep(CancelStep.CancelationPending);
        } else {
          setCancelStep(CancelStep.ContactSupport);
        }
      } catch (err) {
        setCancelStep(CancelStep.CancelationError);
      }
    },
    [flight.bookedItinerary, handleCancelInfo]
  );

  const pollCancelFulfillment = useCallback(
    (pollSessionId) => {
      return new Promise<void>((resolve, reject) => {
        setPolling(async () => {
          try {
            const res = await cancelFulfillPoll(pollSessionId);

            switch (res.AirCancelFulfillmentPollResponse) {
              case AirCancelFulfillmentPollResponseEnum.Success:
                clearPollInterval();
                resolve();
                break;
              case AirCancelFulfillmentPollResponseEnum.Aborted:
                clearPollInterval();
                reject();
                break;
              default:
                break;
            }
          } catch (err) {
            reject(err);
          }
        });
      });
    },
    [clearPollInterval, setPolling]
  );

  /**
   * @description Part 2 of the self serve cancel flow on the BE.
   * Confirms the cancelation of the flight.
   */
  const confirmCancellation = useCallback(async () => {
    const { current: cancelInfo } = cancelInfoRef;

    if (cancelInfo) {
      let confirmRes;

      setCancelStep(CancelStep.LoadingOrProcessing);
      clearPolicyTimeout();
      trackEvent({
        eventName: SelfServeEvents.ConfirmCancelation,
        properties: getTrackingProps(scenario),
      });

      try {
        if ("quoteId" in cancelInfo) {
          const { quoteId } = cancelInfo;

          confirmRes = await cancelFulfill(quoteId);
          await pollCancelFulfillment(confirmRes.fulfillmentSessionId);
        }

        setCancelStep(CancelStep.CancelationFlowComplete);
      } catch (err) {
        setCancelStep(CancelStep.CancelationError);
      }
    }
  }, [clearPolicyTimeout, pollCancelFulfillment, scenario]);

  // kick off the workflow when the itinerary changes
  useEffect(() => {
    const {
      id,
      travelItinerary: { TravelItinerary },
    } = flight.bookedItinerary;

    if (TravelItinerary === TravelItineraryEnum.MultiTravelItinerary) {
      if (enableHFv2) {
        getCancelEligibility(id);
      } else {
        setCancelStep(CancelStep.ContactSupport);
      }
    } else {
      getCancelInfo();
    }

    return () => {
      clearPolicyTimeout();
      clearPollInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCancelInfo]);

  return {
    context: {
      timedOut,
      scenario,
      cancelStep,
      cancelInfoRef,
    },
    handlers: {
      startPolicyTimeout,
      clearPolicyTimeout,
      setCancelStep,
      confirmCancellation,
      getCancelInfo,
    },
  };
};
