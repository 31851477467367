import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  getCalendarVisited,
  getTripCategory,
  hasSelectedDates,
  viewCalendarEventProperties,
} from "../../../search/reducer";
import {
  fetchDepartureCalendar,
  populateSearchUrlParams,
  setCalendar,
  setCalendarVisited,
} from "../../../search/actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { CalendarModal } from "./component";

const mapDispatchToProps = {
  fetchDepartureCalendar,
  setCalendar,
  setCalendarVisited,
  populateSearchUrlParams,
};

const mapStateToProps = (state: IStoreState) => ({
  calendarVisited: getCalendarVisited(state),
  hasSelectedDates: hasSelectedDates(state),
  trackingProperties: viewCalendarEventProperties(state),
  tripCategory: getTripCategory(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileFlightSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedCalendarModal = withRouter(connector(CalendarModal));
