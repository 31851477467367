import { connect } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import {
  getTripCategory,
  getDepartureDate,
  getReturnDate,
  getOrigin,
  getDestination,
} from "../../reducer";
import { TripCategory } from "@hopper-b2b/types";
import { withRouter } from "react-router";
import { ActionButtonWithRoute } from "@hopper-b2b/ui";

const mapStateToProps = (state: IStoreState) => {
  const tripCategory = getTripCategory(state);
  const origin = getOrigin(state);
  const destination = getDestination(state);
  const departureDate = getDepartureDate(state);
  const returnDate = getReturnDate(state);

  const areTerminiSet = origin !== null && destination !== null;
  const areJuncturesSet =
    returnDate !== null ||
    (departureDate !== null && tripCategory === TripCategory.ONE_WAY);

  return {
    disabled: !(areTerminiSet && areJuncturesSet),
  };
};

const mapDispatchToProps = {};

export const ConnectedFlightSearchButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActionButtonWithRoute));
