import {
  ApacIcon,
  ApacIconName,
  ApacLoadingModal,
  CalendarPicker,
} from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionLink, Header } from "@hopper-b2b/ui";
import { tenantFlagsEnabled } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, useLocation } from "react-router";
import { PATH_HOME, PATH_SHOP } from "../../../../utils/urlPaths";
import { MobileFlightSearchStep } from "../../reducer";
import { LocationAndPassengerSelection } from "./components";
import { MobileFlightSearchConnectorProps } from "./container";
import { FlightShopStep } from "../../../shop/reducer";
import "./styles.scss";

export interface IMobileFlightSearchControlProps
  extends MobileFlightSearchConnectorProps,
    RouteComponentProps {}

export const MobileFlightSearchContent = (
  props: IMobileFlightSearchControlProps
) => {
  const {
    originCode,
    destinationCode,
    tripCategory,
    fetchDepartureCalendar,
    setCalendar,
    currentStep,
    departureDate,
    returnDate,
    setCurrentStep,
    history,
    renderSearchInput,
    setRenderSearchInput,
    calendarVisited,
    setCalendarVisited,
    populateSearchUrlParams,
    setDepartureDate,
    setReturnDate,
    trackingProperties,
    resetFilters,
    maxPrice,
    months,
    priceTags,
  } = props;

  const { t } = useI18nContext();
  const { search, state } = useLocation<{ previousStep?: FlightShopStep }>();

  const handleGoBack = useCallback(() => {
    if (renderSearchInput) {
      setRenderSearchInput("");
    } else {
      setCurrentStep(MobileFlightSearchStep.LocationSearch);
    }
  }, [renderSearchInput, setCurrentStep, setRenderSearchInput]);

  useEffect(() => {
    if (
      tenantFlagsEnabled.FlightSearchCalendarPrediction &&
      originCode &&
      destinationCode
    ) {
      fetchDepartureCalendar();
    } else {
      setCalendar();
    }
  }, [
    originCode,
    destinationCode,
    tripCategory,
    calendarVisited,
    fetchDepartureCalendar,
    setCalendar,
    setCurrentStep,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (currentStep === MobileFlightSearchStep.CalendarPicker) {
      setRenderSearchInput("");
    }
  }, [currentStep, setRenderSearchInput]);

  const isSearchHome = () =>
    currentStep === MobileFlightSearchStep.LocationSearch && !renderSearchInput;

  const handleSearch = () => {
    resetFilters(maxPrice);
    history.push(`${PATH_SHOP}${search}`);
  };

  const onCloseClick = useCallback(() => {
    setRenderSearchInput("");
    setCurrentStep(MobileFlightSearchStep.LocationSearch);

    const previousStepPath = state?.previousStep
      ? `${PATH_SHOP}${search}`
      : PATH_HOME;

    history.push(previousStepPath);
  }, [
    history,
    search,
    setCurrentStep,
    setRenderSearchInput,
    state?.previousStep,
  ]);

  return (
    <Box
      className={clsx({
        "date-range-picker":
          currentStep === MobileFlightSearchStep.CalendarPicker,
      })}
    >
      <Header
        center={
          <HeaderCenterSection
            currentStep={currentStep}
            renderSearchInput={renderSearchInput}
          />
        }
        left={
          <ActionLink
            className={clsx("mobile-flight-search-header-go-back", {
              hidden: isSearchHome(),
            })}
            onClick={handleGoBack}
            content={<ApacIcon name={ApacIconName.ChevronLeft} />}
          />
        }
        right={
          <ActionLink
            className={clsx("mobile-flight-search-header-close", {
              hidden: !isSearchHome(),
            })}
            onClick={onCloseClick}
            content={<ApacIcon name={ApacIconName.Close} />}
            label={t("close.button")}
          />
        }
        isMobile={true}
        fullWidth={true}
      />
      {currentStep === MobileFlightSearchStep.LocationSearch && (
        <Box className="location-search-container">
          <LocationAndPassengerSelection
            onComplete={() =>
              setCurrentStep(MobileFlightSearchStep.CalendarPicker)
            }
          />
        </Box>
      )}
      {currentStep === MobileFlightSearchStep.CalendarPicker && (
        <CalendarPicker
          tripCategory={tripCategory}
          departureDate={departureDate}
          returnDate={returnDate}
          setDepartureDate={setDepartureDate}
          setReturnDate={setReturnDate}
          isMobile
          months={months}
          priceTags={priceTags}
          calendarVisited={calendarVisited}
          setCalendarVisited={setCalendarVisited}
          populateSearchUrlParams={populateSearchUrlParams}
          trackingProperties={trackingProperties}
          onComplete={handleSearch}
        />
      )}
    </Box>
  );
};

export const MobileFlightSearchControl = (
  props: IMobileFlightSearchControlProps
) => {
  const { destination, origin } = props;
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const originParam = searchParams.get("origin");
  const destinationParam = searchParams.get("destination");

  // Check if redux and params match, if not wait till they match or
  // - for the params to be cleared by fetchSearchFromUrlSaga
  useEffect(() => {
    if (
      originParam &&
      originParam.length > 0 &&
      destinationParam &&
      destinationParam.length > 0
    ) {
      if (
        originParam === origin?.id?.code?.code &&
        destinationParam === destination?.id?.code?.code
      ) {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [origin, destination, originParam, destinationParam]);

  return loading ? (
    <ApacLoadingModal open={loading} />
  ) : (
    <MobileFlightSearchContent {...props} />
  );
};

interface IHeaderCenterSectionProps {
  currentStep: number;
  renderSearchInput: string;
}

const HeaderCenterSection = (props: IHeaderCenterSectionProps) => {
  const { currentStep, renderSearchInput } = props;
  const { t } = useI18nContext();

  const getHeader = () => {
    switch (renderSearchInput) {
      case "origin":
        return t("flightsPageTitles.mobileOriginHeader");
      case "destination":
        return t("flightsPageTitles.mobileDestinationHeader");
      default:
        return t("flightsPageTitles.searchFlights");
    }
  };

  return (
    <Box className="header-center-section">
      {currentStep === MobileFlightSearchStep.CalendarPicker && (
        <Box className="trip-origin-destination-summary">
          <Box className="origin-destination-container">
            <Typography>{t("mobileSearchChooseDate")}</Typography>
          </Box>
        </Box>
      )}
      {currentStep === MobileFlightSearchStep.LocationSearch && (
        <Box className="mobile-flight-search-location-label">
          <Typography>{getHeader()}</Typography>
        </Box>
      )}
    </Box>
  );
};
