import { FiatPrice } from "@b2bportal/air-shopping-api";
import { IStoreState } from "../../../../reducers/types";

export const flightShopOfferSelector = (state: IStoreState) =>
  state.flightShop.offers;

export const getVoucherDiscount = (state: IStoreState): FiatPrice => {
  const offers = flightShopOfferSelector(state);
  const voucher = offers?.bestOfferOverall;
  if (!voucher) {
    return null;
  }
  return {
    value: voucher.amount.amount,
    currencyCode: voucher.amount.currency,
  } as FiatPrice;
};
