export const getCurrencySymbol = (
  threeLetterCurrencyName: string,
  language = "en"
) => {
  const dummyAmount = 0;
  return dummyAmount
    .toLocaleString(language, {
      style: "currency",
      currency: threeLetterCurrencyName,
    })
    .replace(/\d+([,.]\d+)?/, "");
};

export const extractCurrencySymbol = (amountInText: string) => {
  return amountInText.replace(/\d+([,.]\d+)?/, "");
};
