import { useEffect, useCallback } from "react";
import { Box, Typography } from "@material-ui/core";
import queryStringParser from "query-string";
import {
  PriceFreezeEntryEnum,
  CHOOSE_PRICE_FREEZE,
  VIEWED_PRICE_FREEZE,
  ChoosePriceFreezeProperties,
  ViewedPriceFreezeInfoProperties,
  FREEZE_PRICE_CLICK,
  FreezePriceClickProperties,
  CLP_MODAL_VIEW,
  CLPModalViewProperties,
  getHopperFareRatingName,
  Airport,
  CLP_CLICK,
  CLPClickEnum,
  CLPClickProperties,
  EntryButtonEnum,
  PreviousFlightEnum,
  FiatPrice,
  RewardsPrice,
  TimeToLive,
  TripDetails,
} from "@hopper-b2b/types";
import {
  PriceFreezeEntryDetails,
  ActionLink,
  ActionButton,
  Icon,
  IconName,
  DesktopPopupModal,
  MobilePopoverCard,
  CloseButtonIcon,
  IPriceFreezeOfferEntries,
  B2BSpinner,
  LoadingIndicator,
} from "@hopper-b2b/ui";
import {
  getPriceText,
  useApiConfigSelector,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import { trackEvent } from "@hopper-b2b/api";
import { History } from "history";
import clsx from "clsx";

import { PATH_PRICE_FREEZE, PATH_FREEZE } from "../../../../utils/urlPaths";
import * as constants from "./textConstants";
import "./styles.scss";
import { PriceFreezeEntryConnectorProps } from "./container";
import { FlightShopStep } from "../../reducer";
import { flightApiConfigStoreKey } from "../../../../reducers/types";
import { getDurationText } from "./textConstants";

interface IPriceFreezeEntryProps extends PriceFreezeEntryConnectorProps {
  className?: string;
  onClickInfoIcon: () => void;
  isLowestPrice?: boolean;
  showPriceFreezeDetails?: boolean;
  history: History;
  priceFreezeOffer?: IPriceFreezeOfferEntries | null;
  fiatPrice?: string;
  rewards?: string;
  frozenPrice?: { fiat: FiatPrice; rewards: RewardsPrice | undefined };
  duration?: TimeToLive;
  tripDetails?: TripDetails;
  fareId?: string;
  handleGoToPriceFreezeCheckout?: () => void;
  openPriceFreezeDetails: boolean;
  setOpenPriceFreezeDetails: (val: boolean) => void;
  priceFreezeModalOpen: boolean;
  airports?: { [key: string]: Airport };
  showFreezeIcon?: boolean;
  longerTextInButton?: boolean;
  withSmallBanner?: boolean;
  useDetailsPrefix?: boolean;
  highlightedRewards?: boolean;
  titleCaseHeader?: boolean;
  highlightedButton?: boolean;
}

export const PriceFreezeEntry = (props: IPriceFreezeEntryProps) => {
  const {
    className,
    onClickInfoIcon,
    isLowestPrice = false,
    showPriceFreezeDetails = false,
    history,
    priceFreezeOffer,
    fiatPrice,
    rewards,
    frozenPrice,
    duration,
    tripDetails,
    fareId,
    // note: this prop is used to overwrite the default behaviour of goToPriceFreezeCheckout
    handleGoToPriceFreezeCheckout,
    openPriceFreezeDetails,
    setOpenPriceFreezeDetails,
    priceFreezeModalOpen,
    airports,
    priceDropProtectionCandidateId,
    showFreezeIcon,
    longerTextInButton,
    withSmallBanner,
    useDetailsPrefix,
    highlightedRewards,
    titleCaseHeader,
    highlightedButton,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const { t } = useI18nContext();
  const apiConfig = useApiConfigSelector(flightApiConfigStoreKey);
  const goToPriceFreezeCheckout = useCallback(
    (value) => {
      if (handleGoToPriceFreezeCheckout) {
        handleGoToPriceFreezeCheckout();
      } else {
        let extraParams = "";
        const { flightShopProgress } = queryStringParser.parse(
          history.location.search
        );
        const progress = getMapProgressToEvent(+(flightShopProgress || ""));

        if (tripDetails && fareId) {
          const qs = queryStringParser.stringify({
            tripId: tripDetails.id,
            outgoingFareId: fareId,
            returnFareId: fareId,
            priceDropCandidateId: priceDropProtectionCandidateId,
          });
          extraParams = `&${qs}`;
        }

        history.push(`${PATH_FREEZE}${history.location.search}${extraParams}`, {
          entry: progress,
          entryButton: value || EntryButtonEnum.INFO_MODAL,
          prevFlight:
            progress === PriceFreezeEntryEnum.TRIP_SUMMARY
              ? PreviousFlightEnum.CHOSEN_FLIGHT
              : PreviousFlightEnum.CLP_FLIGHT,
        });
      }
    },
    [tripDetails, fareId, history, handleGoToPriceFreezeCheckout]
  );

  const handleFreezePrice = useCallback(
    (value) => {
      const { flightShopProgress } = queryStringParser.parse(
        history.location.search
      );
      if (flightShopProgress) {
        trackEvent(
          {
            eventName: FREEZE_PRICE_CLICK,
            properties: {
              price_freeze_entry: getMapProgressToEvent(+flightShopProgress),
            } as FreezePriceClickProperties,
          },
          apiConfig
        );
      }
      if (showPriceFreezeDetails) {
        setOpenPriceFreezeDetails(true);
      } else {
        goToPriceFreezeCheckout(value);
      }
    },
    [showPriceFreezeDetails, setOpenPriceFreezeDetails, goToPriceFreezeCheckout]
  );

  const onTrackEventClpClick = (prop: CLPClickEnum) => {
    trackEvent(
      {
        eventName: CLP_CLICK,
        properties: {
          selected_button: prop,
        } as CLPClickProperties,
      },
      apiConfig
    );
  };

  const handleCloseModal = useCallback(() => {
    onTrackEventClpClick(CLPClickEnum.EXIT);
    setOpenPriceFreezeDetails(false);
  }, [setOpenPriceFreezeDetails]);

  const renderPriceFreezeEntryDetails = () => {
    const details = priceFreezeOffer?.returnFlight
      ? t("originalPriceFreeze.roundTripCategory")
      : "";

    if (!tripDetails)
      return (
        <LoadingIndicator
          className="price-freeze-trip-loading"
          indicatorSize={"small"}
          indicator={B2BSpinner}
          message={t("priceFreezeEntry.loading")}
        />
      );
    return (
      <PriceFreezeEntryDetails
        header={t("priceFreezeEntry.detailsHeader")}
        subtitle={t("priceFreezeEntry.detailsSubheader")}
        departureFlight={priceFreezeOffer?.departureFlight}
        returnFlight={priceFreezeOffer?.returnFlight}
        frozenPrice={{
          duration: t("priceFreezeEntry.priceDuration", {
            timeToLive: duration,
          }),
          price: frozenPrice
            ? `<strong>${getPriceText({
                price: frozenPrice.fiat,
              })}</strong>`
            : "",
          details,
        }}
        buttonsProps={[
          {
            content: t("continue"),
            ariaLabelText: t("priceFreezeEntry.continueAriaLabel"),
            onClick: () => {
              onTrackEventClpClick(CLPClickEnum.CONTINUE);
              goToPriceFreezeCheckout(EntryButtonEnum.CLP_MODAL);
            },
          },
          {
            content: t("priceFreezeEntry.anotherFlight"),
            ariaLabelText: t("priceFreezeEntry.anotherFlightAriaLabel"),
            buttonStyle: "h4r-secondary",
            onClick: () => {
              onTrackEventClpClick(CLPClickEnum.CHOOSE_ANOTHER_FLIGHT);
              setOpenPriceFreezeDetails(false);
            },
          },
        ]}
        termsProps={{
          content: t("readTermsAndConditions"),
          ariaLabelText: t("priceFreezeEntry.termsAndConditionsAriaLabel"),
          onClick: () => window.open(`${PATH_PRICE_FREEZE}`, "_blank"),
        }}
        isMobile={matchesMobile}
      />
    );
  };

  const getMapProgressToEvent = (progress: number) => {
    const mapProgressToEvent = {
      [FlightShopStep.ChooseDeparture]: PriceFreezeEntryEnum.FLIGHT_LIST,
      [FlightShopStep.ReviewItinerary]: PriceFreezeEntryEnum.TRIP_SUMMARY,
    };
    return mapProgressToEvent[progress];
  };

  const handleClickInfoIcon = () => {
    const { flightShopProgress } = queryStringParser.parse(
      history.location.search
    );
    if (flightShopProgress) {
      trackEvent(
        {
          eventName: CHOOSE_PRICE_FREEZE,
          properties: {
            price_freeze_entry: getMapProgressToEvent(+flightShopProgress),
          } as ChoosePriceFreezeProperties,
        },
        apiConfig
      );
    }
    onClickInfoIcon();
  };
  // effect to run tracking
  useEffect(() => {
    const { flightShopProgress } = queryStringParser.parse(
      history.location.search
    );
    if (priceFreezeModalOpen && flightShopProgress) {
      trackEvent(
        {
          eventName: VIEWED_PRICE_FREEZE,
          properties: {
            price_freeze_entry: getMapProgressToEvent(+flightShopProgress),
          } as ViewedPriceFreezeInfoProperties,
        },
        apiConfig
      );
    }
  }, [priceFreezeModalOpen, history.location.search]);

  useEffect(() => {
    const { flightShopProgress } = queryStringParser.parse(
      history.location.search
    );

    if (
      openPriceFreezeDetails &&
      flightShopProgress &&
      airports &&
      tripDetails
    ) {
      const outgoingSlice = tripDetails?.slices[0];
      const returnSlice = tripDetails?.slices[1];
      const { originCode, destinationCode } = outgoingSlice;
      trackEvent(
        {
          eventName: CLP_MODAL_VIEW,
          properties: {
            origin: originCode || outgoingSlice?.originName,
            destination: destinationCode || outgoingSlice?.destinationName,
            origin_country_code:
              airports[originCode]?.geography?.countryCode || "",
            destination_country_code:
              airports[destinationCode]?.geography?.countryCode || "",
            departure_date: outgoingSlice?.departureTime,
            return_date: returnSlice?.departureTime,
            trip_type: returnSlice?.departureTime ? "round_trip" : "one_way",
            fare_class: getHopperFareRatingName(
              tripDetails?.fareDetails[0]?.slices[0]?.fareShelf?.rating
            ),
          } as CLPModalViewProperties,
        },
        apiConfig
      );
    }
  }, [openPriceFreezeDetails, airports, tripDetails, history.location.search]);

  return (
    <>
      <Box
        className={clsx("price-freeze-entry-root", className, {
          mobile: matchesMobile,
        })}
      >
        <Box className="price-freeze-entry-container">
          <Box className="price-freeze-info-section">
            <Box className="price-freeze-info-section-title">
              {withSmallBanner && (
                <Box className="price-freeze-banner-icon">
                  <Icon
                    className="price-freeze-icon"
                    name={IconName.B2bPriceFreeze}
                  />
                </Box>
              )}
              <ActionLink
                className="price-freeze-info-link"
                onClick={handleClickInfoIcon}
                content={
                  <>
                    {showFreezeIcon && (
                      <Icon
                        className="price-freeze-icon"
                        name={IconName.B2bPriceFreezeNoCircle}
                      />
                    )}
                    <Typography
                      variant="subtitle2"
                      className="more-info"
                      dangerouslySetInnerHTML={{
                        __html: titleCaseHeader
                          ? t("priceFreezeEntry.infoHeaderTitleBold")
                          : t("priceFreezeEntry.infoHeaderTitle"),
                      }}
                    />
                    <Icon
                      className="info-icon"
                      name={IconName.InfoCircle}
                      ariaLabel={t("priceFreezeEntry.infoIconAriaLabel")}
                    />
                  </>
                }
                label={t("priceFreezeEntry.infoIconAriaLabel")}
              />
            </Box>
            <Typography
              variant="body2"
              className="price-freeze-info-details"
              dangerouslySetInnerHTML={{
                __html:
                  (useDetailsPrefix
                    ? t("priceFreezeEntry.notReadyToBook")
                    : "") +
                  (isLowestPrice
                    ? t("priceFreezeEntry.lowestPriceAndDurationDetails", {
                        duration: duration ? getDurationText(duration) : "",
                        price: fiatPrice as string,
                      })
                    : t("priceFreezeEntry.priceAndDurationDetails", {
                        duration: duration ? getDurationText(duration) : "",
                        price: fiatPrice as string,
                      })),
              }}
            />
          </Box>
          <Box className="price-freeze-button-section">
            <ActionButton
              className="freeze-price-button"
              onClick={() => handleFreezePrice(EntryButtonEnum.FREEZE_PRICE)}
              message={
                longerTextInButton
                  ? t("priceFreezeEntry.freezeThisPrice")
                  : t("priceFreezeEntry.freezePrice")
              }
              defaultStyle={highlightedButton ? "h4r-primary" : "h4r-secondary"}
              ariaLabelText={t("priceFreezeEntry.freezePrice")}
            />
          </Box>
        </Box>
      </Box>
      {matchesMobile ? (
        <MobilePopoverCard
          className="mobile-price-freeze-entry-details-popup"
          open={openPriceFreezeDetails}
          topRightButton={
            <ActionLink
              className="filter-close-button"
              content={<img src={CloseButtonIcon} alt={t("close.imageAlt")} />}
              label={t("close.button")}
              onClick={handleCloseModal}
            />
          }
        >
          {renderPriceFreezeEntryDetails()}
        </MobilePopoverCard>
      ) : (
        <DesktopPopupModal
          className="desktop-price-freeze-entry-details-popup"
          open={openPriceFreezeDetails}
          onClose={handleCloseModal}
          invisibleBackdrop={false}
        >
          {renderPriceFreezeEntryDetails()}
        </DesktopPopupModal>
      )}
    </>
  );
};
