import { ConnectedProps, connect } from "react-redux";
import { Availability } from "./Availability";
import {
  addAvailabilityLodgings,
  resetFilters,
  updateFiltersAndSort,
  setAvailabilityLodgings,
  setLoading,
  setSearchParams,
  setSort,
} from "./actions/actions";
import {
  ILodgingAvailabilityState,
  getFilters,
  getFromDate,
  getGuests,
  getIsOverFiltered,
  getLoading,
  getLodgings,
  getLodgingsFilterBoundaries,
  getPlace,
  getRooms,
  getSort,
  getAvailabilityTrackingProperties,
  getUntilDate,
  getFilteredOutCount,
  getLodgingAvailabilityTrackingProperties,
} from "./reducer";

const mapDispatchToProps = {
  setLoading,
  setAvailabilityLodgings,
  setSearchParams,
  addAvailabilityLodgings,
  updateFiltersAndSort,
  resetFilters,
  setSort,
};
const mapStateToProps = (state: {
  lodgingAvailability: ILodgingAvailabilityState;
}) => {
  const lodgings = getLodgings(state);
  const trackingProperties = getAvailabilityTrackingProperties(state);
  const hotelTrackingProps = getLodgingAvailabilityTrackingProperties(state);

  return {
    loading: getLoading(state),
    isOverFiltered: getIsOverFiltered(
      state.lodgingAvailability.lodgings,
      lodgings
    ),
    filteredOutCount: getFilteredOutCount(
      state.lodgingAvailability.lodgings,
      lodgings
    ),
    place: getPlace(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    lodgings,
    guests: getGuests(state),
    rooms: getRooms(state),
    filters: getFilters(state),
    filterBoundaries: getLodgingsFilterBoundaries(state),
    sort: getSort(state),
    trackingProperties: { ...trackingProperties, ...hotelTrackingProps },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ConnectedLodgingAvailability = connector(Availability);

export type AvailabilityProps = ConnectedProps<typeof connector>;
