import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import Pagination from "@material-ui/lab/Pagination";
import { Divider } from "@material-ui/core";
import clsx from "clsx";

import { DateTimeFormatStyle } from "@hopper-i18n/formatter";
import { TripContext, WatchAlertView } from "@b2bportal/air-price-watch-api";
import { HotelItinerarySummary } from "@b2bportal/lodging-api";
import {
  customFormatDateTime,
  dateStringToNewDate,
} from "@hopper-b2b/utilities";
import { UberMyTripsMobileCard } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  CarReservation,
  ItineraryEnum,
  ItineraryWithType,
  Watch,
} from "@hopper-b2b/types";

import { WatchModals } from "./components/WatchModals";
import { FlightCard } from "./components/FlightCard";
import { HotelCard } from "./components/HotelCard";
import { PAGE_SIZE } from "./constants";
import { ItineraryListConnectorProps } from "./container";
import "./styles.scss";

export interface IItineraryListProps
  extends ItineraryListConnectorProps,
    // eslint-disable-next-line @typescript-eslint/ban-types
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {
  isMobile?: boolean;
}

export const scrollTopWithOffset = (element: HTMLDivElement) => {
  const offset = 221; //header (211px) + padding below header (10px)
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

export const getItineraryId = (itinerary: ItineraryWithType) => {
  if (itinerary.type === ItineraryEnum.Flight) {
    const flight = itinerary as BookedFlightItineraryWithDepartureTime;
    return flight.bookedItinerary.id;
  }
  if (itinerary.type === ItineraryEnum.Hotel) {
    const hotel = itinerary as HotelItinerarySummary;
    return hotel.reservationBookingId;
  }
  if (itinerary.type === ItineraryEnum.Car) {
    const car = itinerary as CarReservation;
    return car.bookResult.groundBookingId;
  }
  if (itinerary.type === ItineraryEnum.Watch) {
    const watch = itinerary as Watch;
    return watch.id;
  }
  return "";
};

export const ItineraryList = (props: IItineraryListProps) => {
  const {
    history,
    itinerariesToDisplay,
    isMobile,
    populateTripQueryParams,
    selectedFlight,
    selectedHotel,
    selectedCar,
    selectedWatch,
    setSelectedFlight,
    setSelectedHotel,
    setSelectedCar,
    setSelectedWatch,
    getDetailsWatch,
    tripContext,
  } = props;

  const { brand, language: locale } = useI18nContext();

  const getExpandedCard = () => {
    if (selectedFlight) {
      return getItineraryId({ ...selectedFlight, type: ItineraryEnum.Flight });
    }
    return "";
  };

  const [expandedCard, setExpandedCard] = useState(getExpandedCard());
  useEffect(() => {
    const newExpandedCard = getExpandedCard();
    if (newExpandedCard && newExpandedCard !== expandedCard) {
      setExpandedCard(newExpandedCard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlight, selectedHotel, selectedCar]);

  const onCloseCard = (itinerary: ItineraryWithType) => {
    setExpandedCard("");
    if (itinerary.type === ItineraryEnum.Flight) {
      setSelectedFlight(null);
    }
    populateTripQueryParams(history);
  };

  const onExpandCard = (itinerary: ItineraryWithType) => {
    setExpandedCard(getItineraryId(itinerary));

    if (itinerary.type === ItineraryEnum.Flight) {
      if (selectedHotel) setSelectedHotel(null);
      if (selectedCar) setSelectedCar(null);
      const selectedFlight =
        itinerary as BookedFlightItineraryWithDepartureTime;
      setSelectedFlight(selectedFlight);
      populateTripQueryParams(history, {
        tripId: selectedFlight.bookedItinerary.id,
      });
    }
  };

  const onExpandToggleClick =
    (itinerary: ItineraryWithType) => (cardId: string) => {
      cardId === expandedCard
        ? onCloseCard(itinerary)
        : onExpandCard(itinerary);
    };

  const handleOnClick = useCallback(
    (watch: WatchAlertView) => {
      getDetailsWatch(watch, history);
    },
    [getDetailsWatch, history]
  );

  const renderTripCard = (itinerary: ItineraryWithType) => {
    if (itinerary.type === ItineraryEnum.Flight) {
      return (
        <FlightCard
          isMobile={isMobile}
          onExpandCard={onExpandToggleClick(itinerary)}
          expandedCard={expandedCard}
          flight={itinerary as BookedFlightItineraryWithDepartureTime}
        />
      );
    }
    if (itinerary.type === ItineraryEnum.Hotel) {
      return (
        <HotelCard
          isMobile={isMobile}
          onExpandCard={onExpandToggleClick(itinerary)}
          expandedCard={expandedCard}
          hotel={itinerary}
        />
      );
    }
    if (itinerary.type === ItineraryEnum.Watch) {
      return (
        <UberMyTripsMobileCard
          type={ItineraryEnum.Watch}
          onClick={() => handleOnClick(itinerary)}
          key={itinerary.id}
          {...getWatchDetails(itinerary, tripContext, (date: string) =>
            customFormatDateTime(
              dateStringToNewDate(date),
              locale,
              DateTimeFormatStyle.ShortMonthDayShortWeekday,
              brand?.customDateTimeFormats?.tripsList
            )
          )}
        />
      );
    }
    return null;
  };

  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [itineriesToShow, setItinerariesToShow] = useState<ItineraryWithType[]>(
    []
  );
  const handlePageChange = (_event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
    selectedCar && setSelectedCar(null);
    selectedHotel && setSelectedHotel(null);
    selectedFlight && setSelectedFlight(null);
    selectedWatch && setSelectedWatch(null);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const setTripsPage = () => {
    let selectedItinerary = null;
    if (selectedFlight) {
      selectedItinerary = { ...selectedFlight, type: ItineraryEnum.Flight };
    }
    if (selectedHotel) {
      selectedItinerary = { ...selectedHotel, type: ItineraryEnum.Hotel };
    }
    if (selectedCar) {
      selectedItinerary = { ...selectedCar, type: ItineraryEnum.Car };
    }
    if (selectedWatch) {
      selectedItinerary = { ...selectedWatch, type: ItineraryEnum.Watch };
    }
    const selectedItineraryId =
      selectedItinerary && getItineraryId(selectedItinerary);
    const selectedIndex = itinerariesToDisplay.findIndex(
      (itinerary) => getItineraryId(itinerary) === selectedItineraryId
    );
    const currentPage = Math.ceil((selectedIndex + 1) / PAGE_SIZE);
    if (selectedIndex !== -1 && page !== currentPage) {
      setPage(currentPage);
    }
  };

  const getCurrentItems = () => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    return itinerariesToDisplay.slice(startIndex, endIndex);
  };

  const getTotalPages = () =>
    Math.ceil(itinerariesToDisplay.length / PAGE_SIZE);

  useEffect(() => {
    setItinerariesToShow(getCurrentItems());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (itinerariesToDisplay.length > 0) {
      const totalPagesToUpdate = getTotalPages();
      if (totalPagesToUpdate !== totalPages) {
        setTotalPages(getTotalPages());
      }
      setTripsPage();
      setItinerariesToShow(getCurrentItems());
    } else {
      setItinerariesToShow([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itinerariesToDisplay]);

  return (
    <>
      <div
        className={clsx(
          { mobile: isMobile },
          "my-trips-list",
          brand?.clientName?.toLowerCase()
        )}
      >
        <ul>
          {itineriesToShow.map((itinerary) => (
            <li key={getItineraryId(itinerary)}>
              {renderTripCard(itinerary)}
              {!isMobile && <Divider className="itinerary-card-divider" />}
            </li>
          ))}
        </ul>
        <div
          className={clsx("trips-pagination-container", { mobile: isMobile })}
        >
          {itinerariesToDisplay.length > 0 ? (
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
            />
          ) : null}
        </div>
      </div>
      <WatchModals />
    </>
  );
};

export const getWatchDetails = (
  watch: WatchAlertView,
  context: TripContext | null,
  formatDate: (date: string) => string
) => {
  const originCode = watch.key.value.origin.code;
  const destinationCode = watch.key.value.destination.code;
  const getCityName = (code: string) => {
    return (
      context?.airports[code]?.cityName || context?.cities?.[code]?.name || ""
    );
  };
  return {
    startDate: formatDate(watch.key.value.departureDate),
    endDate: watch.key.value.returnDate
      ? formatDate(watch.key.value.returnDate)
      : undefined,
    roundTrip: !!watch.key.value.returnDate,
    origin: getCityName(originCode),
    destination: getCityName(destinationCode),
  };
};
