import { Fare, FareSliceOutbound, Flights } from "@b2bportal/air-shopping-api";
import { ISelectedTrip, TripDetails } from "@hopper-b2b/types";
import { ExpandableCard, Slot } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import clsx from "clsx";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { FareclassOptionFilter } from "../../../../search/reducer";
import { getFaresSelector } from "../../../reducer";
import "./FlightList.styles.scss";
import { FlightFindMoreResults } from "./components/FlightFindMoreResults";
import { FlightListInfo } from "./components/FlightListInfo";
import { FlightCardType } from "./components/FlightListInfo/component";
import InfiniteFlights from "./components/InfiniteFlights";
import * as constants from "./constants";

export interface IFlightListProps {
  flights: Flights | null;
  flightsToRender: IFlightListData[] | [];
  tripSummariesLoading: boolean | null;
  rewardsKey: string | undefined;
  handleFareSelect: (flight: IFlightListData, fareId: string) => void;
  fareClassFilter: FareclassOptionFilter;
  handleFlightSelect: (fareTrips: any, clickedFareRating: number) => void;
  expandedFareDetails: TripDetails | null;
  departureDate: Date | null;
  returnDate?: Date | null;
  isInChooseReturnStep: boolean;
  hasFlightsError: boolean;
  selectedTrip: ISelectedTrip;
  maxFlightPrice: number;
  hasAppliedFareClassFilter: boolean;
  isRoundTrip: boolean;
  disablePriceFreeze?: boolean;
  onErrorReload?: () => void;
  onOpenFilter?: () => void;
  handleClickAlgomerch?: () => void;
}

const SkeletonFlights = () => {
  return (
    <ExpandableCard
      key={`enabled-skeleton-${Math.round(Math.random() * 10000)}`}
      className={clsx("flight-list-item", "flight-row", "b2b")}
      variant="outlined"
      expandedCardKey={"expandedFlight"}
      cardKey={""}
      handleCardKeyChange={() => null}
      scrollExpandedIntoView={true}
      content={{
        title: (
          <FlightListInfo
            tripId={""}
            onFareClick={() => null}
            type={FlightCardType.skeleton}
          />
        ),
        body: null,
      }}
    />
  );
};

export interface IFlightListData {
  // sliceId
  slice: string;
  fares: Fare[] | FareSliceOutbound[];
}

export const FlightList = ({
  disablePriceFreeze,
  flightsToRender,
  tripSummariesLoading,
  flights,
  handleFareSelect,
  fareClassFilter,
  handleFlightSelect,
  expandedFareDetails,
  departureDate,
  isInChooseReturnStep,
  maxFlightPrice,
  isRoundTrip,
  hasAppliedFareClassFilter,
  rewardsKey,
  onOpenFilter,
  handleClickAlgomerch,
}: IFlightListProps) => {
  const { matchesMobile } = useDeviceTypes();
  const faresToShow = useSelector(getFaresSelector);
  const [numFlightsToShow, setNumFlightsToShow] = useState(
    constants.INITIAL_RESULT_SET_SIZE
  );
  const [expandedFlight, setExpandedFlight] = useState("");
  const hasFlightListItems =
    flights && flightsToRender.length && faresToShow.length;

  const setFetchMoreData = useCallback(() => {
    return setTimeout(
      () => setNumFlightsToShow(numFlightsToShow + constants.SHOW_MORE_NUM),
      500
    );
  }, [numFlightsToShow]);

  const handleSliceSelect = useCallback(
    (fareId: string, selectedFareRating: number, flight: IFlightListData) => {
      if (fareId === expandedFlight) {
        setExpandedFlight("");
      } else {
        const flightFares = isInChooseReturnStep
          ? (flight.fares as Fare[])
          : (flight.fares as FareSliceOutbound[]);
        const fareTrips = flightFares.map((fare: Fare | FareSliceOutbound) =>
          "example" in fare
            ? fare.example
            : { fare: fare.id, trip: fare.tripId }
        );

        setExpandedFlight(fareId);
        handleFlightSelect(fareTrips, selectedFareRating);
      }
    },
    [expandedFlight, handleFlightSelect, isInChooseReturnStep]
  );

  const hasMoreFlightToShow = matchesMobile
    ? numFlightsToShow < faresToShow.length
    : numFlightsToShow < flightsToRender.length;

  return (
    <div
      className={clsx(
        "flight-list",
        { "flight-list-skeleton": tripSummariesLoading },
        { mobile: matchesMobile }
      )}
    >
      {tripSummariesLoading ? (
        <Slot id="flight-search-loader" isMobile={matchesMobile} /> || (
          <SkeletonFlights />
        )
      ) : hasFlightListItems ? (
        <InfiniteFlights
          departureDate={departureDate}
          disablePriceFreeze={disablePriceFreeze}
          expandedFareDetails={expandedFareDetails}
          expandedFlight={expandedFlight}
          fareClassFilter={fareClassFilter}
          flights={flights}
          hasMore={hasMoreFlightToShow}
          flightsToShow={
            tripSummariesLoading
              ? []
              : flightsToRender.slice(0, numFlightsToShow)
          }
          rewardsKey={rewardsKey}
          onAlgomerchClick={handleClickAlgomerch}
          onFareSelect={handleFareSelect}
          onSliceSelect={handleSliceSelect}
          isInChooseReturnStep={isInChooseReturnStep}
          isRoundTrip={isRoundTrip}
          maxFlightPrice={maxFlightPrice}
          setFetchMoreData={setFetchMoreData}
          hasAppliedFareClassFilter={hasAppliedFareClassFilter}
          setExpandedFlight={setExpandedFlight}
          faresToShow={faresToShow.slice(0, numFlightsToShow)}
        />
      ) : (
        <FlightFindMoreResults
          onOpenFilter={onOpenFilter}
          variant="no-flight"
        />
      )}
      {!tripSummariesLoading &&
        !hasMoreFlightToShow &&
        flightsToRender.length !== 0 && (
          <FlightFindMoreResults onOpenFilter={onOpenFilter} />
        )}
    </div>
  );
};
