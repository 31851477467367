import dayjs from "dayjs";
import {
  FiatPrice,
  IBucketedDate,
  IDateBucket,
  IMonthBucket,
  Reservation,
} from "@hopper-b2b/types";
import {
  PaymentBreakdown,
  PaymentLineItemUserCard,
  PaymentLineItemEnum,
  PaymentLineItemTravelWallet,
  PaymentLineItemRewards,
} from "@b2bportal/air-booking-api";
import { getCurrencySymbol } from "@hopper-b2b/utilities";

export const formatRewardsValue = (value: number) =>
  value.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

/**
 * @param {IDateBucket[]} dateBuckets
 * @return {IMonthBucket[]}
 */
export const transformDateBuckets = (
  dateBuckets: IDateBucket[]
): IMonthBucket[] => {
  const processedMonths = dateBuckets.reduce(
    (months, { dates }, bucketIndex) => {
      const reducedMonths = dates.reduce(
        (monthBuckets: IMonthBucket[], dateString: string) => {
          const date = dayjs(dateString).toDate();
          const bucketedDate: IBucketedDate = { bucket: bucketIndex, date };
          const currentMonthIndex = dayjs(date).month();
          let existingMonthBucket = monthBuckets.find(({ monthIndex }) => {
            return monthIndex === currentMonthIndex;
          });

          if (typeof existingMonthBucket === "undefined") {
            existingMonthBucket = { monthIndex: currentMonthIndex, dates: [] };
            monthBuckets.push(existingMonthBucket);
          }

          existingMonthBucket.dates.push(bucketedDate);

          return monthBuckets;
        },
        months
      );

      return reducedMonths;
    },
    [] as IMonthBucket[]
  );
  const sortedMonths = processedMonths.map((processedMonth) => {
    const sortedDates = processedMonth.dates.sort(
      (a: IBucketedDate, b: IBucketedDate) => dayjs(a.date).diff(b.date)
    );
    return { monthIndex: processedMonth.monthIndex, dates: sortedDates };
  });

  return sortedMonths;
};

export const calculateHotelTotals = (
  reservation: Reservation,
  paymentBreakdown?: PaymentBreakdown
): { paidTotal: FiatPrice; calculatedTripTotal: FiatPrice } => {
  const paidCashTotal: PaymentLineItemUserCard | undefined =
    paymentBreakdown?.payments?.find(
      (p) => p.PaymentLineItem === PaymentLineItemEnum.UserCard
    ) as PaymentLineItemUserCard;
  const paidTravelWalletTotal: PaymentLineItemTravelWallet | undefined =
    paymentBreakdown?.payments?.find(
      (p) => p.PaymentLineItem === PaymentLineItemEnum.TravelWallet
    ) as PaymentLineItemTravelWallet;
  const paidRewardsTotal: PaymentLineItemRewards | undefined =
    paymentBreakdown?.payments?.find(
      (p) => p.PaymentLineItem === PaymentLineItemEnum.Rewards
    ) as PaymentLineItemRewards;
  const paidTotal: FiatPrice = {
    currencyCode: paidCashTotal?.amount.currency ?? "USD",
    currencySymbol: getCurrencySymbol(paidCashTotal?.amount.currency ?? "USD"),
    value:
      (paidCashTotal?.amount.amount ?? 0) +
      (paidRewardsTotal?.amount.fiatValue.amount ?? 0) +
      (paidTravelWalletTotal?.amount.amount ?? 0),
  };

  const extraFeeValue = reservation.pricing.feeBreakdown.total?.value ?? 0;
  const calculatedTripTotalValue = paidTotal
    ? paidTotal.value + extraFeeValue
    : reservation.pricing.tripTotal.fiat.value;

  return {
    paidTotal,
    calculatedTripTotal: {
      currencyCode: paidCashTotal?.amount.currency ?? "USD",
      currencySymbol: paidTotal.currencySymbol,
      value: calculatedTripTotalValue,
    },
  };
};
