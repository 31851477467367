import {
  PriceDropProtection,
  PriceDropProtectionEnum,
  IsEligible,
} from "@hopper-b2b/types";

export const PRICE_DROP_LINK_LABEL = "Free Price Drop Protection";
export const PDP_TITLE = "How it works";
export const PDP_CTA_TEXT = "Read Terms and Conditions";
export const PDP_HEADER =
  "Book now with <span class='font-regular`>free price drop protection</span>";

const getMonitoringDaysCount = (protection: PriceDropProtection) => {
  const days =
    protection.PriceDropProtection === PriceDropProtectionEnum.IsEligible
      ? (protection as IsEligible).monitoringDuration.inSeconds / 86400
      : 0;
  return days;
};

const getMaxRefund = (protection: PriceDropProtection) => {
  const maxRefund =
    protection.PriceDropProtection === PriceDropProtectionEnum.IsEligible &&
    protection.maximumRefund.display;
  return maxRefund;
};

export const getSubtitle = (protection: PriceDropProtection) => {
  const monitoringDays = getMonitoringDaysCount(protection);
  const maxRefund = getMaxRefund(protection);
  const subtitle = `We'll monitor the price for up to ${monitoringDays} day${
    monitoringDays > 1 ? "s" : ""
  }. If we detect a price drop, we'll refund the difference up to ${maxRefund}.`;
  return subtitle;
};
