import {
  HotelItinerarySummary,
  Reservation,
  ReservationStateEnum,
} from "@b2bportal/lodging-api";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ItineraryEnum, MyTripsModalTypes } from "@hopper-b2b/types";
import { ActionLinks, IActionLink, Slot } from "@hopper-b2b/ui";
import {
  getHotelReservationDetails,
  useTenantIcons,
} from "@hopper-b2b/utilities";
import { Box, Divider, Link, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { RouteComponentProps } from "react-router";
import {
  HotelCancellation,
  RoomImage,
} from "../../../TripsList/components/HotelCard/components/HotelInfoParts/component";
import { ConfirmationSummary } from "../../../TripsList/components/ItineraryList/components/ConfirmationSummary";
import { SummaryCard } from "../../../TripsList/components/ItineraryList/components/SummaryCard";
import { getLineItems } from "../../../TripsList/utils/helpers";
import { ExpandableSection } from "../ExpandableSection/component";
import { ItineraryDate } from "../ItineraryDate/component";
import { HotelLocation } from "./components/HotelLocation/component";
import { PaymentSummary } from "./components/PaymentSummary/component";
import { TravelerInformation } from "./components/TravelerInformation/component";
import { HotelItineraryDetailsConnectorProps } from "./container";
import "./styles.scss";
import dayjs from "dayjs";

export interface IMobileHotelItineraryDetailsProps
  extends RouteComponentProps,
    HotelItineraryDetailsConnectorProps {
  isMobile: boolean;
  itinerarySummary?: HotelItinerarySummary;
}

interface ApacReservation extends Reservation {
  lodgingData: {
    checkInInstructions: {
      checkOutTime: string;
      minimumAge: number;
      checkInTime: {
        checkInTimeFrom: string;
        checkInTimeUntil: string;
        CheckInTime: string;
      };
    };
  };
}

export const HotelItineraryDetails = ({
  hotel,
  isMobile,
  setOpenModal,
  setSelectedHotel,
  isPastTrips,
}: IMobileHotelItineraryDetailsProps) => {
  const { t, formatFiatCurrency, language: locale, brand } = useI18nContext();
  const icons = useTenantIcons();

  const onOpenModal = useCallback(
    (type: MyTripsModalTypes) =>
      setOpenModal({
        type,
        selectedItinerary: {
          type: ItineraryEnum.Hotel,
          state: hotel.reservation.state,
          reservationId: hotel.reservation.reservationId,
          reservationBookingId: hotel.reservation.reservationBookingId,
          checkInDate: hotel.reservation.checkInDate,
          checkOutDate: hotel.reservation.checkOutDate,
          lodgingSummary: {
            city: hotel.reservation.lodgingData?.city,
            name: hotel.reservation.lodgingData?.name,
            state: hotel.reservation.lodgingData?.state,
            country: hotel.reservation.lodgingData.country,
            id: hotel.reservation.lodgingData.id,
            starRating: hotel.reservation.lodgingData.starRating,
          },
          reservation: hotel.reservation,
        },
      }),
    [hotel, setOpenModal]
  );

  const { title, confirmationCode } = getHotelReservationDetails(
    hotel,
    locale,
    brand
  );

  const reservation = hotel.reservation as ApacReservation;

  const startDate = dayjs(
    reservation.checkInDate +
      "T" +
      (reservation?.lodgingData?.checkInInstructions?.checkInTime
        ?.checkInTimeFrom ?? "")
  );
  const endDate = dayjs(
    reservation.checkOutDate +
      "T" +
      (reservation?.lodgingData?.checkInInstructions?.checkOutTime ?? "")
  );

  const coordinates = useMemo(() => {
    const lat = hotel?.reservation?.lodgingData?.location?.coordinates?.lat;
    const lon = hotel?.reservation?.lodgingData?.location?.coordinates?.lon;
    return `${lat},${lon}`;
  }, [hotel]);

  const isCancelled = useMemo(
    () =>
      hotel?.reservation?.state?.ReservationState ===
        ReservationStateEnum.Cancelled ||
      hotel?.reservation?.state?.ReservationState ===
        ReservationStateEnum.CancellationFailure,
    [hotel?.reservation?.state?.ReservationState]
  );

  const actions = useMemo(() => {
    const actions: IActionLink[] = [];

    if (!isCancelled && !isPastTrips) {
      actions.push({
        content: t("hotelTripsLinks.changeReservation"),
        onClick: () => onOpenModal(MyTripsModalTypes.ChangeHotel),
      });

      actions.push({
        content: t("hotelTripsLinks.cancelReservation"),
        onClick: () => onOpenModal(MyTripsModalTypes.CancelHotel),
      });
    }

    actions.push(
      {
        content: t("tripReviewLinks.resendEmail"),
        onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
      },
      {
        content: t("tripReviewLinks.contactSupport"),
        onClick: () => onOpenModal(MyTripsModalTypes.ContactSupport),
      }
    );
    return actions;
  }, [isCancelled, isPastTrips, t, onOpenModal]);

  const paymentLineItems = useMemo(() => {
    return getLineItems(t, hotel.paymentBreakdown) || undefined;
  }, [hotel.paymentBreakdown, t]);

  return (
    <Box
      className={clsx("hotel-details-container", "apac", { mobile: isMobile })}
    >
      <Link
        className="back-button"
        onClick={() => {
          setSelectedHotel(null);
        }}
      >
        <FontAwesomeIcon className="right-chevron" icon={faChevronLeft} />
        <Typography className="back-button-text" variant="body2">
          {"Back to my trips"}
        </Typography>
      </Link>
      <Box className="hotel-details-content-container">
        <Slot id="mytrips-hotel-icon" className="hotel-icon" />
        <Box className={clsx("hotel-details-content", { mobile: isMobile })}>
          <Box className="hotel-name">
            <Typography variant="h2">{title}</Typography>
            <ConfirmationSummary confirmationCode={confirmationCode} />
          </Box>
          <ItineraryDate
            label={t("checkin")}
            date={startDate.toDate()}
            className="check-in-date"
            boldTime={false}
          />
          <ItineraryDate
            label={t("checkout")}
            date={endDate.toDate()}
            className="check-out-date"
            boldTime={false}
          />
        </Box>
      </Box>
      <HotelLocation
        coordinates={coordinates}
        hotelName={title}
        hotelAddress={hotel.reservation.lodgingData?.address}
        hotelPhone={hotel.reservation.lodgingData?.phone}
        isMobile={isMobile}
      />
      <ActionLinks className="hotel-action-links" actions={actions} />
      <SummaryCard className="hotel-room-details-container">
        <RoomImage roomInfo={hotel.reservation.roomInfo} />
        <Box>
          <Typography variant="h2" className="room-type">
            {hotel.reservation.roomInfo?.name}
          </Typography>
          <Box className="room-details">
            {hotel?.reservation?.roomInfo?.maxAdults ? (
              <Box className="guests-info">
                <img src={icons.grayGuest} alt="" className="icon" />
                <Typography variant="body2">
                  {t("roomFitsGuests", {
                    count: hotel?.reservation?.roomInfo?.maxAdults,
                  })}
                </Typography>
              </Box>
            ) : null}
            <Box className="beds-info">
              <img src={icons.bed} alt="" className="icon" />
              <Typography variant="body2">
                {hotel.reservation.roomInfo?.beds?.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      </SummaryCard>
      <Divider />
      <ExpandableSection
        title={"Traveler information"}
        className="hotel-expandable-section"
      >
        <TravelerInformation travelers={hotel.reservation.guests} />
      </ExpandableSection>
      <Divider />
      {hotel.reservation.policies.length > 0 ? (
        <>
          <ExpandableSection
            title={"Hotel policy"}
            className="hotel-expandable-section"
          >
            <Box className="hotel-policy-container">
              {hotel.reservation.policies.map((policy) => {
                return (
                  <Box className="hotel-policy-row">
                    <Typography variant="h4">{policy.title}</Typography>
                    <Typography variant="body1">{policy.text}</Typography>
                  </Box>
                );
              })}
            </Box>
          </ExpandableSection>
          <Divider />
        </>
      ) : null}
      <ExpandableSection
        title={"Cancellation policy"}
        className="hotel-expandable-section cancellation-policy"
      >
        <HotelCancellation
          hotelCfar={
            //reservationDetails.ancillaries.cfar
            undefined
          }
          cancellationPolicy={hotel.reservation.cancellationPolicy}
        />
      </ExpandableSection>
      <Divider />
      <ExpandableSection
        title={"Trip total"}
        className="hotel-expandable-section payment-summary"
      >
        <PaymentSummary
          hotel={hotel}
          tripTotalAmount={"hotel.productTotal.fiat"}
          paymentLineItems={paymentLineItems}
        />
      </ExpandableSection>
    </Box>
  );
};
