import { useState, useEffect } from "react";
import { useApiConfigSelector } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import { SliderDropdown } from "@hopper-b2b/ui";
import { trackEvent } from "@hopper-b2b/api";
import { debounce } from "lodash-es";

import { MaxPriceFilterSelectionProps } from "./container";
import { generateTrackingEvent } from "../../../../../../actions/actions";
import { flightApiConfigStoreKey } from "../../../../../../../../reducers/types";

interface IMaxPriceFilterSelectionProps extends MaxPriceFilterSelectionProps {
  showDropdownContentOnly?: boolean;
  maximumPrice: number;
  minimumPrice: number;
}

export const MaxPriceFilterSelection = (
  props: IMaxPriceFilterSelectionProps
) => {
  const {
    maxPriceFilter,
    setMaxPriceFilter,
    maximumPrice,
    minimumPrice,
    showDropdownContentOnly,
  } = props;
  const { t, formatCurrency } = useI18nContext();
  const apiConfig = useApiConfigSelector(flightApiConfigStoreKey);

  const onValueChange = (maxPriceFilter: number) => {
    trackEvent(generateTrackingEvent("price"), apiConfig);
    setMaxPriceFilter(maxPriceFilter);
  };

  const debouncedAction = debounce(onValueChange, 300);
  const [stateDebounceDispatchAction] = useState(() =>
    debounce(debouncedAction, 300, {
      leading: false,
      trailing: true,
    })
  );

  const handleChange = (newValue: number) => {
    stateDebounceDispatchAction(newValue);
  };

  //Update max price filter to max value everytime max value changes.
  useEffect(() => {
    setMaxPriceFilter(maximumPrice);
  }, [maximumPrice]);

  return (
    <SliderDropdown
      dropdownLabel={t("searchFilter.price")}
      sliderHeader={t("searchFilter.maxPrice")}
      maxValue={maximumPrice}
      minValue={maximumPrice === minimumPrice ? 0 : minimumPrice}
      value={maxPriceFilter}
      setValue={handleChange}
      sliderLabelFormat={(value) =>
        formatCurrency(value, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })
      }
      showDropdownContentOnly={showDropdownContentOnly}
      className="b2b-shop-filter"
      popoverClassName="b2b"
    />
  );
};
