import { Assistance } from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import "./AdditionalInformationSection.styles.scss";
import { ApacTextField } from "../../../ApacTextField";
import { InputWrapper } from "../../../InputWrapper";
import { ApacIcon, ApacIconName } from "../../../ApacIcon";
import { useState } from "react";
import { ApacMobileFullScreenModal } from "../../../ApacMobileFullScreenModal";
import { ApacDesktopPopupModal } from "../../../ApacDesktopPopupModal";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { ApacActionButton } from "../../../ApacActionButton";

interface IAdditionalInformationSectionProps {
  className?: string;
  assistance: Assistance[];
  setAssistance: React.Dispatch<React.SetStateAction<Assistance[]>>;
  redressNumber?: string;
  setRedressNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
  knownTravelerNumber?: string;
  setKnownTravelerNumber: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

interface IOption {
  label: string | JSX.Element;
  value: any;
}

export const AdditionalInformationSection = (
  props: IAdditionalInformationSectionProps
) => {
  const {
    className,
    assistance,
    setAssistance,
    redressNumber,
    setRedressNumber,
    knownTravelerNumber,
    setKnownTravelerNumber,
  } = props;

  const { t } = useI18nContext();
  const { matchesMobile: isMobile } = useDeviceTypes();

  const [openRequiredAssistance, setOpenRequiredAssistance] = useState(false);
  const [openRedressNumber, setOpenRedressNumber] = useState(false);
  const [openKnownTravellerNumber, setOpenKnownTravellerNumber] =
    useState(false);

  const assistanceOptions: IOption[] = [
    { label: t("assistanceOptions.blind"), value: Assistance.BLND },
    { label: t("assistanceOptions.deaf"), value: Assistance.DEAF },
    { label: t("assistanceOptions.stretcher"), value: Assistance.STCR },
    {
      label: t("assistanceOptions.wheelchairStairs"),
      value: Assistance.WCHR,
    },
    { label: t("assistanceOptions.wheelchairSeat"), value: Assistance.WCHS },
    { label: t("assistanceOptions.wheelchairCarried"), value: Assistance.WCHC },
    {
      label: t("assistanceOptions.wheelchairDryBattery"),
      value: Assistance.WCBD,
    },
    {
      label: t("assistanceOptions.wheelchairWetBattery"),
      value: Assistance.WCBW,
    },
    {
      label: t("assistanceOptions.wheelchairLithiumBattery"),
      value: Assistance.WCLB,
    },
    { label: t("assistanceOptions.wheelchairManual"), value: Assistance.WCMP },
    { label: t("assistanceOptions.wheelchairOnBoard"), value: Assistance.WCOB },
  ];

  const getAssistanceOptionLabel = (assistance: Assistance) => {
    const option = assistanceOptions.find(
      (option) => option.value === assistance
    );
    return option ? option.label : "Unrecognized assistance";
  };

  return (
    <Box className={clsx("apac-additional-info-container", className)}>
      <form className="traveler-info-form">
        <FormControl className={clsx("assistance")}>
          {infoLabel(
            t("requiredAssistance"),
            () => {
              setOpenRequiredAssistance(true);
            },
            "input-label"
          )}
          <Select
            multiple
            displayEmpty
            disableUnderline
            value={assistance}
            onChange={(event) =>
              setAssistance(event.target.value as Assistance[])
            }
            renderValue={(selected: Assistance[]) => {
              if (!selected.length) {
                return <Typography variant="body2">{t("choose")}</Typography>;
              }

              return (
                <Box className="assistance-text-container">
                  {(selected as Assistance[])
                    .map((assistance) => getAssistanceOptionLabel(assistance))
                    .join(", ")}
                </Box>
              );
            }}
          >
            {getAssistanceOptionMenuItems(assistanceOptions, assistance)}
          </Select>
        </FormControl>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <InputWrapper
              label={infoLabel(t("redressNumber"), () => {
                setOpenRedressNumber(true);
              })}
            >
              <ApacTextField
                className={clsx("traveler-info-field", "redress-number")}
                value={redressNumber}
                onChange={(event) => setRedressNumber(event.target.value)}
              />
            </InputWrapper>
          </Grid>
          <Grid item md={6} xs={12}>
            <InputWrapper
              label={infoLabel(
                t("passengerInformation.knowTravelerName"),
                () => {
                  setOpenKnownTravellerNumber(true);
                }
              )}
            >
              <ApacTextField
                className={clsx("traveler-info-field", "known-traveler-number")}
                value={knownTravelerNumber}
                onChange={(event) => {
                  const rgx = new RegExp("[^a-zA-Z0-9]", "g");
                  setKnownTravelerNumber(event.target.value.replace(rgx, ""));
                }}
              />
            </InputWrapper>
          </Grid>
        </Grid>
      </form>

      {infoModal(
        isMobile,
        openRequiredAssistance,
        () => setOpenRequiredAssistance(false),
        t("requiredAssistance"),
        t("travellerForm.requiredAssistanceDescription"),
        t("close.button")
      )}
      {infoModal(
        isMobile,
        openRedressNumber,
        () => setOpenRedressNumber(false),
        t("redressNumber"),
        t("travellerForm.redressNumberDescription"),
        t("close.button")
      )}
      {infoModal(
        isMobile,
        openKnownTravellerNumber,
        () => setOpenKnownTravellerNumber(false),
        t("knownTravelerNumber"),
        t("travellerForm.knownTravellerNumberDescription"),
        t("close.button")
      )}
    </Box>
  );
};

const infoModal = (
  isMobile: boolean,
  open: boolean,
  onClose: () => void,
  title: string,
  description: string,
  button: string
) => {
  const content = (
    <div className="info-content">
      <Typography variant="h2">{title}</Typography>
      <Typography variant="body2">{description}</Typography>
      <ApacActionButton message={button} onClick={onClose} />
    </div>
  );
  return isMobile ? (
    <ApacMobileFullScreenModal
      className="mobile-flight-checkout-modal"
      open={open}
      onClose={onClose}
    >
      {content}
    </ApacMobileFullScreenModal>
  ) : (
    <ApacDesktopPopupModal
      className="desktop-flight-checkout-modal"
      open={open}
      onClose={onClose}
    >
      {content}
    </ApacDesktopPopupModal>
  );
};

const infoLabel = (label: string, onClick: () => void, className?: string) => {
  return (
    <div className={clsx("info-label-wrapper", className)} onClick={onClick}>
      <Typography variant="body1" className="info-label">
        {label}
      </Typography>
      <ApacIcon name={ApacIconName.InfoOutlined} />
    </div>
  );
};

const getAssistanceOptionMenuItems = (
  options: IOption[],
  assistanceList: Assistance[]
) => {
  return options.map(({ label, value }, index) => (
    <MenuItem className="assistance-menu-item" value={value} key={index}>
      <Checkbox
        className={clsx("assistance-checkbox", {
          checked: assistanceList.indexOf(value) !== -1,
        })}
        checked={assistanceList.indexOf(value) !== -1}
      />
      <ListItemText>{label}</ListItemText>
    </MenuItem>
  ));
};
