import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../reducers/types";
import {
  getSelectedHotel,
  getTripsFilter,
} from "../../../../../TripsList/reducer";
import {
  setSelectedHotel,
  setOpenModal,
  populateTripQueryParams,
} from "../../../../../TripsList/actions/actions";
import { MobileHotelItineraryDetails } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  hotel: getSelectedHotel(state),
  tripsFilter: getTripsFilter(state),
});

const mapDispatchToProps = {
  setOpenModal,
  setSelectedHotel,
  populateTripQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileHotelItineraryDetailsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileHotelItineraryDetails = connector(
  withRouter(MobileHotelItineraryDetails)
);
