import { Box, Divider } from "@material-ui/core";
import { ReactComponent as ClientLogo } from "../../assets/commBank-logo.svg";
import { ReactComponent as HopperLogoDesktop } from "../../assets/hopper-logo-desktop.svg";
import { ReactComponent as HopperLogoMobile } from "../../assets/hopper-logo-mobile.svg";
import "./styles.scss";
import { useDeviceTypes } from "@hopper-b2b/utilities";

export const ApacLogo = () => {
  const { matchesMobile } = useDeviceTypes();
  return (
    <Box className="apac-logo-container">
      {matchesMobile ? (
        <HopperLogoMobile className="hopper-logo mobile" />
      ) : (
        <HopperLogoDesktop className="hopper-logo" />
      )}
      <Divider className="divider" orientation="vertical" />
      <ClientLogo className="apac-logo" />
    </Box>
  );
};
