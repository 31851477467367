import { useMemo } from "react";
import { useSelector } from "react-redux";
import { placeLabelToUrl } from "../../../util";
import { getSearchParams } from "../reducer";

export function useLodgingLink(id: string, name: string, location: string) {
  // TODO rely on useSearchParams once urlMiddleware supports react-router
  const queryParams = useSelector(getSearchParams);

  return useMemo(() => {
    const urlFriendlyName = placeLabelToUrl(name);

    return `/hotels/shop/${location}/${urlFriendlyName}/${id}?${queryParams}`;
  }, [id, name, queryParams, location]);
}
