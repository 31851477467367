import * as actionTypes from "./constants";
import { WalletOffer } from "@b2bportal/hopper-wallet-api";

export interface ISetWalletOffers {
  type: actionTypes.SET_WALLET_OFFERS;
  offers?: WalletOffer[];
  creditOffer?: WalletOffer;
  bestOffer?: WalletOffer;
}

export const setWalletOffers = (args: {
  offers?: WalletOffer[];
  creditOffer?: WalletOffer;
  bestOffer?: WalletOffer;
}): ISetWalletOffers => ({
  type: actionTypes.SET_WALLET_OFFERS,
  ...args,
});

export interface IFetchWalletOffers {
  type: actionTypes.FETCH_WALLET_OFFERS;
  tripId: string;
}

export const fetchWalletOffers = (tripId: string): IFetchWalletOffers => ({
  type: actionTypes.FETCH_WALLET_OFFERS,
  tripId,
});

export interface ISetWalletOffersFailed {
  type: actionTypes.SET_WALLET_OFFERS_FAILED;
}

export const setWalletOffersFailed = (): ISetWalletOffersFailed => ({
  type: actionTypes.SET_WALLET_OFFERS_FAILED,
});

export interface ISetBestWalletOffer {
  type: actionTypes.SET_BEST_WALLET_OFFER;
  selectedWalletOffer?: WalletOffer;
}

export const setBestWalletOffer = (
  offer?: WalletOffer
): ISetBestWalletOffer => ({
  type: actionTypes.SET_BEST_WALLET_OFFER,
  selectedWalletOffer: offer,
});

export interface ISetSelectedWalletOffer {
  type: actionTypes.SET_SELECTED_WALLET_OFFER;
  selectedWalletOffer?: WalletOffer;
}

export const setSelectedWalletOffer = (
  offer?: WalletOffer
): ISetSelectedWalletOffer => ({
  type: actionTypes.SET_SELECTED_WALLET_OFFER,
  selectedWalletOffer: offer,
});

export interface ISetSelectedCreditOffer {
  type: actionTypes.SET_SELECTED_CREDIT_OFFER;
  selectedCreditOffer?: boolean;
}

export const setSelectedCreditOffer = (
  selectedCreditOffer?: boolean
): ISetSelectedCreditOffer => ({
  type: actionTypes.SET_SELECTED_CREDIT_OFFER,
  selectedCreditOffer: selectedCreditOffer,
});

export type FlightCfarActions =
  | ISetWalletOffers
  | IFetchWalletOffers
  | ISetWalletOffersFailed
  | ISetSelectedWalletOffer
  | ISetSelectedCreditOffer
  | ISetBestWalletOffer;
