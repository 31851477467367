import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { pollFlightCancelQuotePath } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

const pollFlightCancelQuote = async (flightCancelSessionId: string) => {
  try {
    const req = {
      flightCancelSessionId,
    };
    const res = await axiosInstance.put(pollFlightCancelQuotePath, req);

    return res.data;
  } catch (err) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
  }
};

export default pollFlightCancelQuote;
