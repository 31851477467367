import {
  CallState,
  PriceFreezeView,
  TripSummaryContext,
} from "@hopper-b2b/types";
import { put } from "redux-saga/effects";
import { priceFreezeApi } from "@b2bportal/price-freeze-api";

import { actions } from "../actions";
import axios, { AxiosResponse } from "axios";

export interface IPriceFreezeResponse {
  priceFreezes: Array<PriceFreezeView>;
  tripContext: TripSummaryContext;
}

export function* fetchPriceFreezeSaga(action: actions.IListPriceFreeze) {
  try {
    const value: AxiosResponse<IPriceFreezeResponse> = yield priceFreezeApi(
      axios
    ).apiV2PricefreezeListPost({
      ListPriceFreezeRequest: action.requestType,
    });
    yield put(actions.setListPriceFreezeCallState(CallState.Success));
    yield put(actions.setPriceFreeze(value.data));
  } catch (e) {
    yield put(actions.setListPriceFreezeCallState(CallState.Failed));
  }
}
