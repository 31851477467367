import { IApiConfig } from "@hopper-b2b/types";
import {
  commbankAuAuthApi,
  GetSessionInfoResponse,
} from "@b2bportal/commbank-au-auth-api";
import { axiosInstance } from "@hopper-b2b/api";

export const fetchSession = async (
  config?: IApiConfig
): Promise<GetSessionInfoResponse> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const res = await commbankAuAuthApi(
      axiosInstance
    ).apiV0AuthSessionInfoPost();
    return res.data;
  } catch (e) {
    throw e;
  }
};
