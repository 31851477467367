import {
  HotelCancelFulfillReq,
  HotelCancelFulfillRes,
  LodgingCancelFulfillResEnum,
  NETWORK_CALL_FAILED,
  SelfServeEvents,
} from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { confirmNonCartHotelCancelPath } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

const confirmNonCartHotelCancel = async (clientTokenId: string) => {
  try {
    const req: HotelCancelFulfillReq = {
      clientTokenId,
    };

    const res = await axiosInstance.post<HotelCancelFulfillRes>(
      confirmNonCartHotelCancelPath,
      req
    );

    if (
      res.data.LodgingCancelFulfillResponse ===
      LodgingCancelFulfillResEnum.Success
    ) {
      trackEvent({
        eventName: SelfServeEvents.ResponseSuccess,
        properties: {
          product: "hotel",
        },
      });

      return res.data;
    }

    throw res.data;
  } catch (err) {
    let errString = "";

    if (typeof err === "string") {
      errString = err;
    } else if (Array.isArray(err)) {
      errString = err.map((err) => `${err.code} - ${err.details}`).join(", ");
    }

    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        location: window.location.pathname,
      },
    });
    trackEvent({
      eventName: SelfServeEvents.ResponseFailure,
      properties: {
        product: "hotel",
        reason: errString,
      },
    });

    throw err;
  }
};

export default confirmNonCartHotelCancel;
