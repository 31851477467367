import {
  CancellationInfoRequest,
  HotelCancelInfoRes,
  LodgingCancelQuoteResEnum,
  NETWORK_CALL_FAILED,
  Uuid,
} from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { getHotelCancelQuoteAnyPath } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

/**
 * @description The first of two endpoints in the self serve cancel hotels flow
 * @param {Uuid} reservationId
 * @return {CancelScenario} The cancellation info for the reservation passed in
 */
const getHotelCancelQuoteAny = async (reservationId: Uuid) => {
  try {
    const req: CancellationInfoRequest = {
      reservationId,
    };
    const res = await axiosInstance.post<HotelCancelInfoRes>(
      getHotelCancelQuoteAnyPath,
      req
    );

    if (res.data) {
      const { LodgingCancelQuoteResponse } = res.data;

      if (
        LodgingCancelQuoteResponse === LodgingCancelQuoteResEnum.CartSuccess ||
        LodgingCancelQuoteResponse === LodgingCancelQuoteResEnum.NonCartSuccess
      ) {
        return res.data;
      }
    }

    throw res;
  } catch (err) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        location: window.location.pathname,
      },
    });

    throw err;
  }
};

export default getHotelCancelQuoteAny;
