import {
  airCfarApi,
  GetAirCfarOfferRequest,
  GetAirCfarOfferResponse,
} from "@b2bportal/air-cfar-api";
import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import axios from "axios";
import { trackEvent } from "../../tracking/trackEvent";

export const fetchCfarOffers = async (
  getAirCfarOfferRequest: GetAirCfarOfferRequest,
  apiConfig?: IApiConfig
): Promise<GetAirCfarOfferResponse> => {
  try {
    const response = await airCfarApi(axios).apiV0CfarAirOfferGetPost(
      getAirCfarOfferRequest
    );

    return response.data;
  } catch (error) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      apiConfig
    );
    throw error;
  }
};
