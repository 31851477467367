import * as React from "react";
import { debounce } from "lodash-es";
import { Box } from "@material-ui/core";
import { GenericSlider, GenericDropdown } from "@hopper-b2b/ui";
import { ITimeRange, TripCategory } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import dayjs from "dayjs";
import clsx from "clsx";

import "./styles.scss";
import { TIME_RANGE_MAX } from "../../../../../../reducer";
import { DepartureArrivalSelectionProps } from "./container";

interface IDepartureArrivalSelectionProps
  extends DepartureArrivalSelectionProps {
  showDropdownContentOnly?: boolean;
  showDepartureSelectionOnly?: boolean;
  showArrivalSelectionOnly?: boolean;
}

export const DepartureArrivalSelectionDropdown = (
  props: IDepartureArrivalSelectionProps
) => {
  const {
    tripCategory,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    showDropdownContentOnly,
    showDepartureSelectionOnly,
    showArrivalSelectionOnly,
    setOutboundDepartureTimeRange,
    setOutboundArrivalTimeRange,
    setReturnDepartureTimeRange,
    setReturnArrivalTimeRange,
  } = props;

  const outboundSelection = {
    departureTimeRange: outboundDepartureTimeRange,
    onChangeDepartureTimeRange: (min: number, max: number) =>
      setOutboundDepartureTimeRange({ min, max }),
    arrivalTimeRange: outboundArrivalTimeRange,
    onChangeArrivalTimeRange: (min: number, max: number) =>
      setOutboundArrivalTimeRange({ min, max }),
  };

  const { t } = useI18nContext();

  const returnSelection =
    tripCategory === TripCategory.ROUND_TRIP
      ? {
          departureTimeRange: returnDepartureTimeRange,
          onChangeDepartureTimeRange: (min: number, max: number) =>
            setReturnDepartureTimeRange({ min, max }),
          arrivalTimeRange: returnArrivalTimeRange,
          onChangeArrivalTimeRange: (min: number, max: number) =>
            setReturnArrivalTimeRange({ min, max }),
        }
      : undefined;

  const renderDropdownContent = () => {
    return (
      <>
        {!showArrivalSelectionOnly && (
          <Box className={clsx("departure-arrival-selection-root", "outbound")}>
            <Box className="departure-arrival-selection-container">
              <Box className="selection-container">
                <Box
                  className={clsx("time-window-slider-container", "departure")}
                >
                  <Box className="label-container">{t("departure")}</Box>
                  <TimeWindowSlider
                    timeRange={outboundSelection.departureTimeRange}
                    onChange={outboundSelection.onChangeDepartureTimeRange}
                  />
                </Box>
                <Box
                  className={clsx("time-window-slider-container", "arrival")}
                >
                  <Box className="label-container">
                    {t("searchFilter.arrivalLabel")}
                  </Box>
                  <TimeWindowSlider
                    timeRange={outboundSelection.arrivalTimeRange}
                    onChange={outboundSelection.onChangeArrivalTimeRange}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {returnSelection && !showDepartureSelectionOnly && (
          <Box className={clsx("departure-arrival-selection-root", "arrival")}>
            <Box className="departure-arrival-selection-container">
              <Box className="selection-container">
                <Box
                  className={clsx("time-window-slider-container", "departure")}
                >
                  <Box className="label-container">{t("departure")}</Box>
                  <TimeWindowSlider
                    timeRange={returnSelection.departureTimeRange}
                    onChange={returnSelection.onChangeDepartureTimeRange}
                  />
                </Box>
                <Box
                  className={clsx("time-window-slider-container", "arrival")}
                >
                  <Box className="label-container">
                    {t("searchFilter.arrivalLabel")}
                  </Box>
                  <TimeWindowSlider
                    timeRange={returnSelection.arrivalTimeRange}
                    onChange={returnSelection.onChangeArrivalTimeRange}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <Box className={"departure-arrival-dropdown"}>
      {!showDropdownContentOnly && (
        <GenericDropdown
          buttonClassName={clsx("departure-arrival-dropdown")}
          popoverClassName={clsx("departure-arrival-popover", "b2b")}
          ariaLabel={`Time Filter`}
          dropdownLabel={t("searchFilter.time")}
          dropdownContent={renderDropdownContent()}
        />
      )}
      {!!showDropdownContentOnly && renderDropdownContent()}
    </Box>
  );
};

interface ITimeWindowSliderProps {
  className?: string;
  onChange: (min: number, max: number) => void;
  timeRange: ITimeRange;
}

const TimeWindowSlider = (props: ITimeWindowSliderProps) => {
  const { className, onChange, timeRange } = props;

  const [value, setValue] = React.useState(timeRange);

  const debouncedAction = debounce(onChange, 300);
  const [stateDebounceDispatchAction] = React.useState(() =>
    debounce(debouncedAction, 300, {
      leading: false,
      trailing: true,
    })
  );
  const handleChange = (min: number, max: number) => {
    setValue({ min, max });
    stateDebounceDispatchAction(min, max);
  };

  return (
    <GenericSlider
      className={clsx("time-window-slider-root", className)}
      onChange={handleChange}
      sliderType={"doubleThumb"}
      step={1}
      chosenMin={value.min}
      chosenMax={value.max}
      sliderMin={0}
      sliderMax={TIME_RANGE_MAX}
      getLabel={getTimeLabel}
    />
  );
};

const getTimeLabel = (value: number) => {
  const totalTime = dayjs().hour(0).minute(value);
  return totalTime.format("h:mm A");
};
