import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useEffect } from "react";
import { RouteComponentProps, matchPath, useLocation } from "react-router";

import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  CHANGED_TRIP_TYPE,
  ChangeTripTypeScreenEnum,
  ClientName,
  ITripTerminus,
  TripCategory,
} from "@hopper-b2b/types";
import {
  ActionLink,
  FlightCategoryToggle,
  IconComponent,
  IconName,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import { getEnvVariables, tenantFlagsEnabled } from "@hopper-b2b/utilities";

import { PATH_EXCHANGE } from "../../../../utils/urlPaths";
import { LocationSearch } from "../../../search/components/MobileFlightSearchControl/components";
import { MobileFlightSearchConnectorProps } from "./container";

import "./styles.scss";

export interface ILocationModalProps
  extends MobileFlightSearchConnectorProps,
    RouteComponentProps {
  hideCategoryBar?: boolean;
  open: boolean;
  onClose: () => void;
  origin: ITripTerminus | null;
  destination: ITripTerminus | null;
  setOrigin: (value: ITripTerminus | null) => void;
  setDestination: (value: ITripTerminus | null) => void;
  tripCategory: TripCategory;
  setTripCategory: (value: TripCategory) => void;
  setReturnDate: (value: Date | null) => void;
}

export const LocationModal = (props: ILocationModalProps) => {
  const {
    tripCategory,
    fetchDepartureCalendar,
    setCalendar,
    open,
    onClose,
    setTripCategory,
    setOpenLocationModal,
    setOpenCalendarModal,
    renderSearchInput,
    setRenderSearchInput,
    origin,
    destination,
    setOrigin,
    setDestination,
    setReturnDate,
  } = props;
  const location = useLocation();
  const { t } = useI18nContext();
  const inExchange = matchPath(location.pathname, PATH_EXCHANGE);

  useEffect(() => {
    if (
      tenantFlagsEnabled.FlightSearchCalendarPrediction &&
      origin?.id?.code?.code &&
      destination?.id?.code?.code
    ) {
      fetchDepartureCalendar();
    } else {
      setCalendar();
    }
  }, [
    tripCategory,
    fetchDepartureCalendar,
    setCalendar,
    origin?.id?.code?.code,
    destination?.id?.code?.code,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoBack = useCallback(() => {
    setRenderSearchInput("");
  }, [setRenderSearchInput]);

  const updateTripCategory = useCallback(
    (value: TripCategory) => {
      trackEvent({
        eventName: CHANGED_TRIP_TYPE,
        properties: { screen: ChangeTripTypeScreenEnum.LOCATION_MODAL },
      });
      setTripCategory(value);
      if (value === TripCategory.ONE_WAY) {
        setReturnDate(null);
      }
    },
    [setReturnDate, setTripCategory]
  );

  const onComplete = useCallback(() => {
    setOpenLocationModal(false);
    setOpenCalendarModal(true);
  }, [setOpenCalendarModal, setOpenLocationModal]);

  return (
    <MobilePopoverCard
      open={open}
      onClose={onClose}
      fullScreen={true}
      className="location-modal-popover"
      contentClassName="location-modal-wrapper"
      topLeftButton={
        isUberClient ? null : (
          <ActionLink
            className={clsx("location-modal-header-back-button", {
              hidden: !renderSearchInput,
            })}
            onClick={handleGoBack}
            content={<FontAwesomeIcon icon={faChevronLeft as IconProp} />}
          />
        )
      }
      topRightButton={
        <ActionLink
          className="location-modal-header-close-button"
          onClick={onClose}
          content={
            <IconComponent
              ariaLabel={t("close.imageAlt")}
              className={clsx("close-button-icon")}
              name={IconName.Close}
            />
          }
          label={t("close.button")}
        />
      }
    >
      <Box className={clsx("location-modal-root")}>
        <HeaderCenterSection />
        <Box className="location-search-container">
          <>
            <Box className="filters-and-trip-category-container">
              <FlightCategoryToggle
                className={clsx("flight-search-category-toggle", "b2b")}
                setTripCategory={updateTripCategory}
                category={tripCategory}
              />
            </Box>
            <LocationSearch onComplete={onComplete} />
          </>
        </Box>
      </Box>
    </MobilePopoverCard>
  );
};

const HeaderCenterSection = () => {
  const { t } = useI18nContext();
  return (
    <div className="header-center-section">
      <div className="location-modal-header-container">
        <h3 className="location-modal-header">
          {t("flightShopLocationModal")}
        </h3>
      </div>
    </div>
  );
};
