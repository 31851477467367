import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import {
  GetAccountResponse,
  commbankAuAccountsApi,
} from "@b2bportal/commbank-au-accounts-api";
import { trackEvent, axiosInstance } from "@hopper-b2b/api";

export const fetchAccounts = async (
  config?: IApiConfig
): Promise<GetAccountResponse> => {
  try {
    const res = await commbankAuAccountsApi(
      axiosInstance
    ).apiV0CommbankAccountsPost();
    return res.data;
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      config
    );
    throw e;
  }
};
