import {
  WalletCreditSummaryResponse,
  commbankAuWalletApi,
} from "@b2bportal/commbank-au-wallet-api";
import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";

export const fetchWalletSummary = async (
  config?: IApiConfig
): Promise<WalletCreditSummaryResponse> => {
  try {
    const res = await commbankAuWalletApi(
      axiosInstance
    ).apiV0WalletCreditSummaryPost();
    return res.data;
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      config
    );
    throw e;
  }
};
