import SadBunny from "../../assets/bunnies/sad_bunny.png";
import "./styles.scss";

export interface LoadingFailureProps {
  showImage: boolean;
  title: string;
  message: string;
}

export const LoadingFailure = ({
  showImage,
  title,
  message,
}: LoadingFailureProps) => (
  <div className="loading-failure">
    <div className="loading-failure-image-container">
      {showImage ? (
        <img src={SadBunny} className="loading-failure-image" />
      ) : null}
    </div>
    <h3 className="loading-failure-title">{title}</h3>
    <p className="loading-failure-title">{message}</p>
  </div>
);
