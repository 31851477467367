import {
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
} from "@b2bportal/air-booking-api";
import {
  acceptScheduleChange,
  denyScheduleChange,
  getBookingSupportId,
  trackEvent,
} from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  BookingType,
  ITimeGrouping,
  MyTripsProperties,
  SCHEDULE_CHANGE_DECISION,
  ScheduleChangeState,
  ScheduleChangeUserActionEnum,
  TimeGroupingEnum,
  VIEWED_MAJOR_SCHEDULE_CHANGE,
  getDepartureSlice,
  getReturnSlice,
} from "@hopper-b2b/types";
import {
  B2BSpinner,
  ContactSupportModalContent,
  Icon,
  IconName,
  LoadingIndicator,
  LostConnectionBunny,
  ScheduleChangeForm,
  SliceToShow,
  Slot,
} from "@hopper-b2b/ui";
import { ActionButton } from "@hopper-b2b/ui-core";
import {
  getAirlineInfoFromBookedFlightItinerary,
  getHopperLocatorFromBookedFlightItinerary,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import { getTimeChoice, sliceHasScheduleChange } from "../../utils";

import styles from "./styles.module.scss";

export interface IScheduleChangeModalContentProps {
  airlineMap: { [key: string]: Airline | undefined };
  airportMap: { [key: string]: Airport | undefined };
  flight: BookedFlightItineraryWithDepartureTime;
  onClose: (refresh?: boolean) => void;
  skchEventProps: MyTripsProperties;
}

const SKCH_EXP_DATE = "ddd, MMM DD [at] h:mm A";

const defaultProps: Partial<IScheduleChangeModalContentProps> = {};

const ScheduleChangeModalContent = (
  props: IScheduleChangeModalContentProps
): JSX.Element => {
  const { airlineMap, airportMap, flight, onClose } = props;
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const [skchState, setSkchState] = useState(ScheduleChangeState.Display);
  const { bookedItinerary, departureTime } = flight;
  const { expiry } = bookedItinerary.scheduleChange;
  const outgoingSliceChanged = sliceHasScheduleChange(bookedItinerary, true);
  const returnSliceChanged = sliceHasScheduleChange(bookedItinerary);
  const TIMES: ITimeGrouping[] = [
    {
      grouping: TimeGroupingEnum.EARLY_MORNING,
      label: t("times.earlyMorning"),
      sublabel: "12:00am - 4:59am",
    },
    {
      grouping: TimeGroupingEnum.MORNING,
      label: t("times.morning"),
      sublabel: "5:00am - 11:59am",
    },
    {
      grouping: TimeGroupingEnum.AFTERNOON,
      label: t("times.afternoon"),
      sublabel: "12:00pm - 5:59pm",
    },
    {
      grouping: TimeGroupingEnum.EVENING,
      label: t("times.evening"),
      sublabel: "6:00pm - 11:59pm",
    },
  ];
  const scheduleChangeEventProperties: MyTripsProperties = {
    agent_locator: getHopperLocatorFromBookedFlightItinerary(flight),
    agent_locator_provider: getHopperLocatorFromBookedFlightItinerary(flight),
    booking_date: departureTime,
    days_until_departure: `${dayjs(departureTime).diff(dayjs(), "days")} days`,
    has_skch: bookedItinerary.scheduleChange.id || "false",
    skch_severity: bookedItinerary.scheduleChange.severity,
  };

  const handleScheduleChangeAction = async (
    action: ScheduleChangeUserActionEnum,
    outboundTimes: TimeGroupingEnum[] = [],
    returnTimes: TimeGroupingEnum[] = []
  ) => {
    const { id: itineraryId, scheduleChange } = bookedItinerary;

    setSkchState(ScheduleChangeState.InProgress);
    trackEvent({
      eventName: SCHEDULE_CHANGE_DECISION,
      properties: {
        ...scheduleChangeEventProperties,
        decision_outcome: action.toLowerCase(),
      },
    });

    try {
      if (action === ScheduleChangeUserActionEnum.Accept) {
        await acceptScheduleChange({ itineraryId });
        setSkchState(ScheduleChangeState.Accepted);
      } else if (action === ScheduleChangeUserActionEnum.Deny) {
        const hoursToDeparture = dayjs(departureTime).diff(dayjs(), "hours");

        if (hoursToDeparture <= 75) {
          setSkchState(ScheduleChangeState.ContactAirline);
        } else {
          await denyScheduleChange({
            itineraryId,
            outboundPreferences: outboundTimes.map((val) =>
              getTimeChoice(val, scheduleChange)
            ),
            returnPreferences: returnTimes.map((val) =>
              getTimeChoice(val, scheduleChange, true)
            ),
            additionalRemarks: "",
          });
          setSkchState(ScheduleChangeState.Requested);
        }
      }
    } catch (err) {
      setSkchState(ScheduleChangeState.Failure);
    }
  };

  const renderScheduleChange = () => {
    return (
      <Box
        className={clsx(styles.displaySkchDialog, { mobile: matchesMobile })}
      >
        <Box className={styles.skchHeaderContainer}>
          <Typography gutterBottom className={styles.skchTitle} variant="h1">
            {t("scheduleChange.reviewScheduleChange")}
          </Typography>
          <Typography
            gutterBottom
            className={styles.skchSubtitle}
            variant="body1"
          >
            {t("scheduleChange.majorChangeModalDescription", {
              expDate: dayjs(expiry).format(SKCH_EXP_DATE),
            })}
          </Typography>
        </Box>
        <Grid
          container
          className={styles.itinerarySliceComparator}
          direction="column"
          spacing={3}
        >
          {outgoingSliceChanged && (
            <Grid
              container
              item
              className={styles.sliceComparatorRow}
              direction="row"
              spacing={3}
            >
              <Grid item className={styles.sliceInfo} md={6} sm={12}>
                <Typography gutterBottom variant="h3">
                  {t("scheduleChange.updatedFlight", {
                    context: "outbound",
                  })}
                </Typography>
                <Slot
                  id="slice-details"
                  isOutgoing
                  airlineMap={airlineMap}
                  airportMap={airportMap}
                  flight={flight}
                  slice={getDepartureSlice(bookedItinerary)}
                  sliceToShow={SliceToShow.updated}
                />
              </Grid>
              <Grid item className={styles.sliceInfo} md={6} sm={12}>
                <Typography gutterBottom variant="h3">
                  {t("scheduleChange.originalFlight", {
                    context: "outbound",
                  })}
                </Typography>
                <Slot
                  id="slice-details"
                  isOutgoing
                  airlineMap={airlineMap}
                  airportMap={airportMap}
                  flight={flight}
                  slice={getDepartureSlice(bookedItinerary)}
                  sliceToShow={SliceToShow.original}
                />
              </Grid>
            </Grid>
          )}
          {returnSliceChanged && (
            <Grid
              container
              item
              className={styles.sliceComparatorRow}
              direction="row"
              spacing={3}
            >
              <Grid item className={styles.sliceInfo} md={6} sm={12}>
                <Typography gutterBottom variant="h3">
                  {t("scheduleChange.updatedFlight", { context: "return" })}
                </Typography>
                <Slot
                  id="slice-details"
                  airlineMap={airlineMap}
                  airportMap={airportMap}
                  flight={flight}
                  slice={getReturnSlice(bookedItinerary)}
                  sliceToShow={SliceToShow.updated}
                />
              </Grid>
              <Grid item className={styles.sliceInfo} md={6} sm={12}>
                <Typography gutterBottom variant="h3">
                  {t("scheduleChange.originalFlight", { context: "return" })}
                </Typography>
                <Slot
                  id="slice-details"
                  airlineMap={airlineMap}
                  airportMap={airportMap}
                  flight={flight}
                  slice={getReturnSlice(bookedItinerary)}
                  sliceToShow={SliceToShow.original}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Box className={styles.scheduleChangeForm}>
          <ScheduleChangeForm
            isMobile={matchesMobile}
            onClick={handleScheduleChangeAction}
            outboundChanged={outgoingSliceChanged}
            returnChanged={returnSliceChanged}
            timeGroupings={TIMES}
            titles={{
              headingCopy: t("scheduleChangeFormTitles.headingCopy"),
              acceptButtonTitle: t(
                "scheduleChangeFormTitles.acceptButtonTitle"
              ),
              acceptDetails: t("scheduleChangeFormTitles.acceptDetails"),
              denyButtonTitle: t("scheduleChangeFormTitles.denyButtonTitle"),
              denyDetails: t("scheduleChangeFormTitles.denyDetails"),
              outboundTimePreferenceCopy: "",
              returnTimePreferenceCopy: "",
            }}
          />
        </Box>
      </Box>
    );
  };

  const renderScheduleChangeAccepted = () => {
    return (
      <Box className={styles.dialogContainer}>
        <DialogTitle className={styles.dialogTitle}>
          {t("scheduleChangeRequestedModal.acceptedTitle")}
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <DialogContentText className={styles.dialogSubtitle}>
            {t("scheduleChangeRequestedModal.acceptedSubtitle")}
          </DialogContentText>
          {/* Icon to be added per tenant when SKCH is enabled */}
          {/* <Icon name={IconName.CalendarSuccess} /> */}
        </DialogContent>
        <DialogActions className={styles.dialogFooter}>
          <ActionButton
            className={styles.dialogButton}
            message={t("done")}
            onClick={() => onClose()}
          />
        </DialogActions>
      </Box>
    );
  };

  const renderScheduleChangeContactAirline = () => {
    const [outboundInfo, returnInfo] =
      getAirlineInfoFromBookedFlightItinerary(flight);
    let airlineCode = "";
    let locator = "";

    if (outgoingSliceChanged && outboundInfo) {
      ({ code: airlineCode, locator } = outboundInfo);
    } else {
      ({ code: airlineCode, locator } = returnInfo);
    }

    const { displayName: airlineName, webLinks } =
      airlineMap[airlineCode] || {};

    return (
      <Box
        className={clsx(styles.dialogContainer, styles.contactAirlineDialog)}
      >
        <DialogTitle className={styles.dialogTitle}>
          {t("scheduleChangeRequestedModal.contactAirlineTitle")}
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <DialogContentText className={styles.dialogSubtitle}>
            {t("scheduleChangeRequestedModal.contactAirlineSubtitle", {
              airlineName,
            })}
          </DialogContentText>
          <DialogContentText className={styles.contactAirlineLocatorTitle}>
            {locator}
          </DialogContentText>
          <DialogContentText className={styles.contactAirlineLocator}>
            {t("scheduleChangeRequestedModal.contactAirlineBookingRef", {
              context: airlineName ? "airline" : undefined,
              airlineName,
            })}
          </DialogContentText>
          {/* Icon to be added per tenant when SKCH is enabled */}
          {/* <Icon name={IconName.SupportChat} /> */}
        </DialogContent>
        <DialogActions className={styles.dialogFooter}>
          <ActionButton
            className={styles.dialogButton}
            defaultStyle="h4r-secondary"
            message={t("modalClose.label")}
            onClick={() => onClose()}
          />
          {airlineName && (
            <ActionButton
              className={styles.dialogButton}
              color="primary"
              message={t("scheduleChangeRequestedModal.contactAirlineCta", {
                airlineName,
              })}
              onClick={() => {
                const { homePage, manageBooking } = webLinks;
                const airlinePage = manageBooking || homePage;

                window.open(airlinePage, "_blank");
              }}
            />
          )}
        </DialogActions>
      </Box>
    );
  };

  const renderScheduleChangeContactSupport = () => {
    return (
      <ContactSupportModalContent
        bookingId={flight.bookedItinerary.id}
        bookingType={BookingType.Flight}
        bookingUuid={flight.bookedItinerary.id}
        className="schedule-change-contact-support"
        getSupportId={getBookingSupportId}
        requestType="ScheduleChange"
      />
    );
  };

  const renderScheduleChangeFailure = () => {
    return (
      <Box className={clsx(styles.dialogContainer, styles.failureDialog)}>
        <DialogTitle className={styles.dialogTitle}>
          {t("scheduleChangeRequestedModal.failureTitle")}
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <DialogContentText className={styles.dialogSubtitle}>
            {t("scheduleChangeRequestedModal.failureSubtitle")}
          </DialogContentText>
          <img
            alt="sad bunny"
            className="failure-icon"
            src={LostConnectionBunny}
          />
        </DialogContent>
        <DialogActions className={styles.dialogFooter}>
          <ActionButton
            className={styles.dialogButton}
            message={t("retry")}
            onClick={() => setSkchState(ScheduleChangeState.Display)}
          />
        </DialogActions>
      </Box>
    );
  };

  const renderScheduleChangeInProgress = () => {
    return (
      <LoadingIndicator
        open
        className={styles.inProgressDialog}
        indicator={B2BSpinner}
        indicatorSize="small"
        message={t("loading")}
      />
    );
  };

  const renderScheduleChangeReceived = () => {
    return (
      <Box className={clsx(styles.dialogContainer, styles.receivedDialog)}>
        <DialogTitle className={styles.dialogTitle}>
          {t("scheduleChangeRequestedModal.receivedTitle")}
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <DialogContentText className={styles.dialogSubtitle}>
            {t("scheduleChangeRequestedModal.receivedSubtitle")}
          </DialogContentText>
          {/* Icon to be added per tenant when SKCH is enabled */}
          {/* <Icon name={IconName.SupportSuccess} /> */}
        </DialogContent>
        <DialogActions className={styles.dialogFooter}>
          <ActionButton
            className={styles.dialogButton}
            defaultStyle="h4r-secondary"
            message={t("contactSupport")}
            onClick={() => setSkchState(ScheduleChangeState.ContactSupport)}
          />
          <ActionButton
            className={styles.dialogButton}
            message={t("done")}
            onClick={() => onClose()}
          />
        </DialogActions>
      </Box>
    );
  };

  const modalContent = useMemo(() => {
    switch (skchState) {
      case ScheduleChangeState.Accepted:
        return renderScheduleChangeAccepted();
      case ScheduleChangeState.ContactAirline:
        return renderScheduleChangeContactAirline();
      case ScheduleChangeState.ContactSupport:
        return renderScheduleChangeContactSupport();
      case ScheduleChangeState.Display:
        return renderScheduleChange();
      case ScheduleChangeState.Failure:
        return renderScheduleChangeFailure();
      case ScheduleChangeState.InProgress:
        return renderScheduleChangeInProgress();
      case ScheduleChangeState.Requested:
        return renderScheduleChangeReceived();
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skchState]);

  useEffect(() => {
    trackEvent({
      eventName: VIEWED_MAJOR_SCHEDULE_CHANGE,
      properties: scheduleChangeEventProperties,
    });
    // get status of schedule change and set skch state accordingly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flight]);

  return (
    <Box className={styles.scheduleChangeModalContent}>{modalContent}</Box>
  );
};

ScheduleChangeModalContent.defaultProps = defaultProps;

export default ScheduleChangeModalContent;
