import { useI18nContext } from "@hopper-b2b/i18n";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useDocumentTitle } from "../../../../hooks/src/useDocumentTitle";
import { DesktopFlightSearchControl } from "./components/DesktopFlightSearchControl";
import { MobileFlightSearchControl } from "./components/MobileFlightSearchControl";
import { FlightSearchConnectorProps } from "./container";
import "./styles.scss";

type IFlightSearchProps = FlightSearchConnectorProps;

export const FlightSearch = (props: IFlightSearchProps) => {
  const { resetState, fetchSearchFromUrl } = props;
  const location = useLocation();
  const history = useHistory();
  const { matchesMobile } = useDeviceTypes();

  const { t } = useI18nContext();

  useDocumentTitle(t("browserTabTitles.searchFlight"));

  useEffect(() => {
    resetState();
    window.scrollTo(0, 0);
    fetchSearchFromUrl(location?.search, history);
  }, []);

  return matchesMobile ? (
    <Box className="mobile-flight-search-root">
      <Box className="flight-search-container-mobile">
        <MobileFlightSearchControl />
      </Box>
    </Box>
  ) : (
    <DesktopFlightSearchControl />
  );
};
