import { useUtmTracking, useUserSource } from "@hopper-b2b/utilities";
import { getLang, getRootLang } from "@hopper-b2b/i18n";
import {
  AxiosInterceptors,
  createRequestLog,
  createResponseErrorLog,
  createResponseLog,
} from "@hopper-b2b/api";
import config from "../../utils/config";
import { useFeatureFlags } from "@apac/feature-flags";
import { branding } from "../../modules/branding";
import { useAccounts } from "@commbank/accounts";

interface IAxiosInterceptors {
  children?: React.ReactNode;
  hopperSessionToken?: string;
}

const TENANT = branding.clientName;

const AxiosInterceptorWrapper = ({
  children,
  hopperSessionToken,
}: IAxiosInterceptors) => {
  const { experiments } = useFeatureFlags();
  const { userMedium, userCampaign, userSource } = useUtmTracking();
  const locale = getLang();
  const { offerTrackingProperties } = useAccounts();
  const sessionId = sessionStorage.getItem("sessionId");
  const currency = branding.currencies?.[0]?.code;
  const headers = {
    "Accept-Language": `${locale}, ${getRootLang(locale)}`,
  };
  if (sessionId) headers["Riskified-Session-Id"] = sessionId;

  return (
    <AxiosInterceptors
      trackingProperties={experiments}
      additionalTrackingProperties={offerTrackingProperties}
      userSource={userSource}
      userMedium={userMedium}
      userCampaign={userCampaign}
      tenant={TENANT}
      currency={currency}
      isAgentPortal={false}
      requestHeaders={headers}
      version={config.REACT_APP_VERSION}
      logResponse={createResponseLog}
      logRequest={createRequestLog}
      logError={createResponseErrorLog}
    >
      {children}
    </AxiosInterceptors>
  );
};

export default AxiosInterceptorWrapper;
