import { B2BSpinner } from "@hopper-b2b/ui";
import { Fragment, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useEnableWallet, useSessionContext } from "@hopper-b2b/utilities";
import { SignupBanner } from "@hopper-b2b/wallet";
import { IFlightListData } from "../../../../FlightList";
import { IInfiniteFlightsProps } from "../../index";
import { DesktopInfiniteFlightsGridComponent } from "./DesktopInfiniteFlightsGridComponent";
import { CBASpinner } from "@commbank/ui";

export interface DesktopInfiniteFlightsProps extends IInfiniteFlightsProps {
  flightsToRender: IFlightListData[];
}

export const DesktopInfiniteFlights = (props: DesktopInfiniteFlightsProps) => {
  const { t } = useI18nContext();

  const { isLoggedIn } = useSessionContext();

  const hasVouchersEnabled = useEnableWallet();

  const { flightsToRender, setFetchMoreData, hasMore } = props;

  const handleNext = useCallback(() => {
    if (setFetchMoreData) {
      setFetchMoreData();
    }
  }, [setFetchMoreData]);

  const rows: IFlightListData[] = flightsToRender;

  return (
    <InfiniteScroll
      dataLength={rows.length}
      next={handleNext}
      hasMore={hasMore}
      loader={
        <div className="loading-flights">
          <CBASpinner />
        </div>
      }
    >
      {rows.map((flight, index) => (
        <Fragment key={flight.slice || flight.fare?.example?.fare || index}>
          {index === 3 && hasVouchersEnabled && !isLoggedIn && (
            <SignupBanner message={t?.("walletBannerSignIn")} />
          )}
          <DesktopInfiniteFlightsGridComponent
            index={index}
            flightData={flight}
            {...props}
          />
        </Fragment>
      ))}
    </InfiniteScroll>
  );
};
