/* eslint-disable prefer-const */
import {
  LocationQuery,
  LocationQueryEnum,
  LocationResponse,
} from "@b2bportal/air-shopping-api";
import { fetchLocationAutocomplete } from "@hopper-b2b/api";
import { ITripTerminus } from "@hopper-b2b/types";
import { select } from "redux-saga/effects";
import {
  IStoreState,
  flightApiConfigStoreKey,
} from "../../../../reducers/types";

export function* fetchOriginDestination(origin: string, destination: string) {
  const originRequestBody: LocationQuery = {
    LocationQuery: LocationQueryEnum.Label,
    l: origin,
  };
  const state: IStoreState = yield select();
  const apiConfig = state[flightApiConfigStoreKey];
  const { categories: originCategories }: LocationResponse =
    yield fetchLocationAutocomplete(originRequestBody, apiConfig);
  const correspondingOrigin = originCategories
    .flatMap((category) => category.results)
    .find((result) => result.id.code.code === origin) as ITripTerminus;

  const destinationRequestBody: LocationQuery = {
    LocationQuery: LocationQueryEnum.Label,
    l: destination,
  };

  const { categories: destinationCategories }: LocationResponse =
    yield fetchLocationAutocomplete(destinationRequestBody);
  const correspondingDestination = destinationCategories
    .flatMap((category) => category.results)
    .find((result) => result.id.code.code === destination) as ITripTerminus;

  return { correspondingDestination, correspondingOrigin };
}
