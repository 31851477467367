import axios from "axios";
import { priceFreezeRefund } from "../paths";
import {
  RefundPriceFreezeRequest,
  RefundPriceFreezeResponse,
  NETWORK_CALL_FAILED,
  IApiConfig,
} from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";

export const refundPriceFreezeApi = (
  body: RefundPriceFreezeRequest,
  apiConfig?: IApiConfig
): Promise<RefundPriceFreezeResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(priceFreezeRefund, body);
      resolve(res.data);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });
