import { Box, Typography } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import clsx from "clsx";

import {
  ICustomLineItem,
  IMarkdownLineItem,
  IPaymentLineItem,
  IRewardsLineItem,
  ISummaryLineItem,
  ITotalLineItem,
  PaymentTypeEnum,
  PricingBreakdownTitles,
} from "@hopper-b2b/types";
import { Icon } from "@hopper-b2b/ui";
import { CurrencyFormatters } from "@hopper-b2b/utilities";
import MarkdownSummaryRow from "./MarkdownSummaryRow";
import "./styles.scss";

export interface ISummaryRowProps {
  summary: ISummaryLineItem;
  titles: Pick<
    PricingBreakdownTitles,
    "rewardsAccountText" | "rewardsAppliedText" | "totalText"
  >;
}

const defaultProps: Partial<ISummaryRowProps> = {};

/**
 * @description Mostly same logic as the component in `PriceBreakdown`
 * component but with support for exchange features (e.g. markdown)
 */
const SummaryRow = (props: ISummaryRowProps): JSX.Element => {
  const { summary, titles } = props;
  const { type: summaryType } = summary;
  let fiatText = "";
  let valueTextVariant: Variant = "subtitle1";
  let rowLabel = "label" in summary ? summary.label : "";
  let summaryClasses = "";
  let detailsLabel;
  let payableLabel;
  let rewardsText;
  let rowIcon;

  switch (summaryType) {
    case "custom": {
      const {
        className = "",
        fiatPrice,
        icon,
        rewardsPrice,
      } = summary as ICustomLineItem;
      const { currencyCode, value: fValue } = fiatPrice;

      rowIcon = icon;
      summaryClasses = className;
      fiatText = CurrencyFormatters.get(currencyCode).format(fValue);
      valueTextVariant = "subtitle1";

      if (rewardsPrice) {
        // const { currency, value: rValue } = rewardsPrice;
        // rewardsText = CurrencyFormatters.rewards(rValue, currency);
      }
      break;
    }
    case "markdown": {
      const { fiatPrice, label, markdownType, rewardsPrice } =
        summary as IMarkdownLineItem;
      const { currencyCode, value: cashAmt } = fiatPrice;
      const { value: rewardsAmt = 0 } = rewardsPrice ?? {};
      const isSplitPay = markdownType === PaymentTypeEnum.SplitAmountInfo;
      let totalText = "";

      fiatText = CurrencyFormatters.get(currencyCode).format(
        isSplitPay ? Math.abs(cashAmt) : cashAmt
      );

      if (rewardsPrice) {
        // rewardsText = CurrencyFormatters.rewards(
        //   isSplitPay ? Math.abs(rewardsAmt) : rewardsAmt,
        //   currency
        // );
      }

      if (isSplitPay) {
        // cashAmt & rewardsAmt are already negative
        totalText = CurrencyFormatters.get(currencyCode).format(
          cashAmt + rewardsAmt / 100
        );
      }

      return (
        <MarkdownSummaryRow
          fiat={fiatText}
          label={label}
          rewards={rewardsText}
          total={totalText}
        />
      );
    }
    case "payment": {
      const {
        fiatPrice: { currencyCode, value },
        label,
        rewardsPrice,
      } = summary as IPaymentLineItem;

      fiatText = CurrencyFormatters.get(currencyCode).format(value);

      if (rewardsPrice && rewardsPrice.value !== 0) {
        // const { currency, value } = rewardsPrice;
        // rewardsText = CurrencyFormatters.rewards(value, currency);
      }

      if (label) {
        rowLabel = "";
        payableLabel = label;
      }

      break;
    }
    case "rewards": {
      const {
        rewardsAccountName,
        fiatPrice: { currencyCode, value: fValue },
        // rewardsPrice: { currency, value: rValue },
      } = summary as IRewardsLineItem;

      fiatText = CurrencyFormatters.get(currencyCode).format(fValue * -1);
      //   rewardsText = CurrencyFormatters.rewards(rValue * -1, currency);
      rowLabel = titles.rewardsAppliedText(rewardsAccountName);

      break;
    }
    case "total": {
      const {
        details,
        rewardsPrice,
        fiatPrice: { currencyCode, value: fValue },
      } = summary as ITotalLineItem;

      fiatText = CurrencyFormatters.get(currencyCode).format(fValue * -1);

      if (details) detailsLabel = details;

      if (rewardsPrice) {
        // const { currency, value: rValue } = rewardsPrice;
        // rewardsText = CurrencyFormatters.rewards(rValue * -1, currency);
      }

      break;
    }
    default:
  }

  return (
    <Box className={clsx("summary-item-wrapper", summaryClasses)}>
      <Box className={`summary-line-section left ${summaryType}`}>
        {payableLabel && (
          <Typography
            className="payable-label"
            component="p"
            variant="subtitle1"
          >
            {payableLabel}
          </Typography>
        )}
        {rowLabel && (
          <Typography
            className="label"
            component="p"
            variant={
              summaryType === "total" || summaryType === "custom"
                ? "subtitle1"
                : "subtitle2"
            }
          >
            {rowIcon && <Icon className="row-icon" name={rowIcon} />}
            {rowLabel}
            {detailsLabel && (
              <span className="label-details">{detailsLabel}</span>
            )}
          </Typography>
        )}
      </Box>
      <Box className={`summary-line-section right ${summaryType}`}>
        <Typography
          className="value fiat"
          component="p"
          variant={valueTextVariant}
        >
          {fiatText}
        </Typography>
        {rewardsText && (
          <>
            <Typography
              className="separator"
              variant={valueTextVariant}
              component="p"
            >
              /
            </Typography>
            <Typography
              className="value rewards"
              variant={valueTextVariant}
              component="p"
            >
              {rewardsText}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

SummaryRow.defaultProps = defaultProps;

export default SummaryRow;
