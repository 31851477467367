import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { MyTripsFilter } from "@hopper-b2b/types";
import { IStoreState } from "../../../../reducers/types";
import {
  fetchFlights,
  setSelectedFlight,
  populateTripQueryParams,
  setOpenModal,
} from "../../../TripsList/actions/actions";
import { FlightCard } from "./component";
import {
  getAirlinesMap,
  getAirportMap,
  getTripsFilter,
} from "../../../TripsList/reducer";

const mapStateToProps = (state: IStoreState) => ({
  airportMap: getAirportMap(state),
  airlineMap: getAirlinesMap(state),
  tripsFilter: getTripsFilter(state),
  isPastTrips: getTripsFilter(state) === MyTripsFilter.PAST_TRIPS,
});

const mapDispatchToProps = {
  fetchFlights,
  setSelectedFlight,
  populateTripQueryParams,
  setOpenModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightCardConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightCard = connector(withRouter(FlightCard));
