import {
  DisruptionRebookPollPQRequest,
  IApiConfig,
  NETWORK_CALL_FAILED,
  DisruptionRebookPQRequest,
  DisruptionRebookPollPQResponse,
  DisruptionRebookPQResponseEnum,
  DisruptionRebookPollPQResponseEnum,
} from "@hopper-b2b/types";
import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import {
  disruptionRebookQuotePath,
  disruptionRebookQuotePollPath,
} from "../paths";

const disruptionRebookQuotePoll = async (
  req: DisruptionRebookPollPQRequest,
  index = 0
): Promise<any> => {
  const delayTimes = [2000, 4000, 6000, 8000, 10000, 20000, 30000];

  await new Promise(async (resolve) =>
    setTimeout(resolve, delayTimes[Math.min(index, 6)])
  );
  try {
    const response = await axiosInstance.post(
      disruptionRebookQuotePollPath,
      req
    );
    if (
      response.data.DisruptionRebookPollPQResponse ===
        DisruptionRebookPollPQResponseEnum.RebookPollPQPending ||
      response.data.DisruptionRebookPollPQResponse ===
        DisruptionRebookPollPQResponseEnum.Pending
    ) {
      return await disruptionRebookQuotePoll(req, index++);
    } else {
      response.data.token = req.token;
      return response.data;
    }
  } catch (error) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
    return Promise.reject(e.response);
  }
};

const disruptionRebookQuote = (
  req: DisruptionRebookPQRequest,
  apiConfig?: IApiConfig
): Promise<DisruptionRebookPollPQResponse> =>
  new Promise((resolve, reject) => {
    axiosInstance
      .post(disruptionRebookQuotePath, req)
      .then((response) => {
        const { data } = response;
        if (
          data.DisruptionRebookPQResponse ===
            DisruptionRebookPQResponseEnum.RebookPQSuccess ||
          data.DisruptionRebookPQResponse ===
            DisruptionRebookPQResponseEnum.Success
        ) {
          const pollReq = {
            contractId: req.contractId,
            token: { value: data.token.value },
          };
          resolve(disruptionRebookQuotePoll(pollReq));
        } else {
          resolve(data);
        }
      })
      .catch((e) => {
        trackEvent(
          {
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
        reject(e);
      });
  });

export default disruptionRebookQuote;
