import { DisruptionOffer } from "@b2bportal/air-disruption-api";
import * as actionTypes from "./constants";

export interface ISetDisruptionOffers {
  type: actionTypes.SET_DISRUPTION_OFFERS;
  offers?: DisruptionOffer[];
}

export const setDisruptionOffers = (args: {
  offers?: DisruptionOffer[];
}): ISetDisruptionOffers => ({
  type: actionTypes.SET_DISRUPTION_OFFERS,
  ...args,
});

export interface IFetchDisruptionOffers {
  type: actionTypes.FETCH_DISRUPTION_OFFERS;
}

export const fetchDisruptionOffers = (): IFetchDisruptionOffers => ({
  type: actionTypes.FETCH_DISRUPTION_OFFERS,
});

export interface ISetDisruptionOffersFailed {
  type: actionTypes.SET_DISRUPTION_OFFERS_FAILED;
}

export const setDisruptionOffersFailed = (): ISetDisruptionOffersFailed => ({
  type: actionTypes.SET_DISRUPTION_OFFERS_FAILED,
});

export interface ISetSelectedAirDisruptionOffer {
  type: actionTypes.SET_SELECTED_AIR_DISRUPTION_OFFER;
  offer: DisruptionOffer | null;
}

export const setSelectedAirDisruptionOffer = (
  offer: DisruptionOffer | null
): ISetSelectedAirDisruptionOffer => ({
  type: actionTypes.SET_SELECTED_AIR_DISRUPTION_OFFER,
  offer,
});

export type FlightDisruptionActions =
  | ISetDisruptionOffers
  | IFetchDisruptionOffers
  | ISetDisruptionOffersFailed
  | ISetSelectedAirDisruptionOffer;
