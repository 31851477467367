import axios from "axios";

import { seatMapsApiPrefix } from "../../paths";
import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";
import {
  GetSeatMapRequest,
  GetSeatMapResponse,
} from "@b2bportal/air-seats-api";

const END_POINT = `${seatMapsApiPrefix}`;

export const fetchSeatMap = (
  req: GetSeatMapRequest,
  apiConfig?: IApiConfig
): Promise<GetSeatMapResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(END_POINT, req);
      const responseBody = res.data;
      resolve(responseBody);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });
