import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { actions } from "../../../../actions";
import {
  getDestination,
  getOrigin,
  getDestinationCategoriesLoading,
  getOriginCategoriesLoading,
  getOriginCategories,
  getDestinationCategories,
  getCalendarVisited,
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getTripCategory,
  getRenderSearchInput,
} from "../../../../reducer";
import { LocationAndPassengerSelection } from "./component";
import { passengerSelectValidation } from "@commbank/ui";
import {
  APAC_MAX_PASSENGER_COUNT,
  APAC_MAX_CHILDREN_COUNT,
} from "../../../DesktopFlightSearchControl/DesktopFlightSearchControl";

const mapDispatchToProps = {
  fetchOriginCategories: actions.fetchOriginCategories,
  fetchDestinationCategories: actions.fetchDestinationCategories,
  setRenderSearchInput: actions.setRenderSearchInput,
  populateSearchUrlParams: actions.populateSearchUrlParams,
  setPassengerCounts: actions.setPassengerCounts,
};
const mapStateToProps = (state: IStoreState) => {
  const origin = getOrigin(state);
  const destination = getDestination(state);

  const readyToContinue =
    origin &&
    destination &&
    passengerSelectValidation(
      {
        adultsCount: getAdultsCount(state),
        childrenCount: getChildrenCount(state),
        infantsInSeatCount: getInfantsInSeatCount(state),
        infantsOnLapCount: getInfantsOnLapCount(state),
      },
      APAC_MAX_PASSENGER_COUNT,
      APAC_MAX_CHILDREN_COUNT
    );

  return {
    renderSearchInput: getRenderSearchInput(state),
    origin,
    destination,
    originCategories: getOriginCategories(state),
    destinationCategories: getDestinationCategories(state),
    readyToContinue,
    loading:
      !!getOriginCategoriesLoading(state) ||
      !!getDestinationCategoriesLoading(state),
    calendarVisited: getCalendarVisited(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
    tripCategory: getTripCategory(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type LocationAndPassengerSearchConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedLocationAndPassengerSelection = connector(
  LocationAndPassengerSelection
);
