import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  CfarExerciseProgress,
  ChfarExerciseProgress,
  DisruptionExerciseProgress,
  FlightItineraryState,
  HotelItineraryState,
  IApiConfig,
  MyTripsFilter,
  VIEWED_MY_TRIPS,
} from "@hopper-b2b/types";
import { B2BSpinner, LoadingPopup, TripsHeader } from "@hopper-b2b/ui";
import {
  updateApiConfigAction,
  useDeviceTypes,
  useEnablePriceFreeze,
} from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ClientContext } from "../../App";
import { tripsApiConfigStoreKey } from "../../reducers";
import {
  getCfarExerciseProgress,
  getChfarExerciseProgress,
  getDisruptionExerciseProgress,
} from "../TripsList/reducer";
import { WatchDetailsModalContent } from "../UberTripsList/components/WatchDetailsModalContent";
import { FilterTabs } from "./components/FilterTabs";
import { FlightItineraryDetails } from "./components/FlightItineraryDetails";
import { HotelItineraryDetails } from "./components/HotelItineraryDetails";
import { ItineraryList } from "./components/ItineraryList";
import { ItinerariesModal } from "./components/ItineraryList/components/ItinerariesModal";
import { NoTripResults } from "./components/NoResults";
import { TripSearch } from "./components/TripSearch";
import { WatchList } from "./components/WatchList";
import { TripsListConnectorProps } from "./container";
import "./styles.scss";

export interface ITripsListProps
  extends TripsListConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  apiConfig: IApiConfig;
  onSupportClick: () => void;
}

export const ApacTripsList = ({
  // fetchCars,
  fetchFlights,
  fetchHotels,
  history,
  isMobile,
  listWatches,
  selectedFlight,
  selectedHotel,
  selectedCar,
  selectedWatch,
  tripsFilter,
  tripsLoading,
  hasError,
  hasTripsToDisplay,
  openModal,
  listPriceFreeze,
  apiConfig,
  tripSearchResults,
}: ITripsListProps) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const dispatch = useDispatch();
  const clientContext = useContext(ClientContext);

  const isUserAuthenticated = useMemo(
    () => !!clientContext?.sessionInfo?.userInfo,
    [clientContext?.sessionInfo]
  );

  const showFlightPriceFreeze = useEnablePriceFreeze();
  const cfarExerciseProgress = useSelector(getCfarExerciseProgress);
  const chfarExerciseProgress = useSelector(getChfarExerciseProgress);
  const disruptionExerciseProgress = useSelector(getDisruptionExerciseProgress);

  useEffect(() => {
    dispatch(updateApiConfigAction(tripsApiConfigStoreKey, apiConfig));
  }, [apiConfig, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    trackEvent({
      eventName: VIEWED_MY_TRIPS,
      properties: {},
    });
  }, []);

  const fetchAllItineraries = useCallback(() => {
    // fetchCars({}, history);
    fetchFlights(
      {
        states: [
          FlightItineraryState.Canceled,
          FlightItineraryState.Future,
          FlightItineraryState.Present,
          FlightItineraryState.Past,
        ],
        referenceDateTime: dayjs().format(),
      },
      history
    );
    fetchHotels(
      {
        states: [
          HotelItineraryState.Canceled,
          HotelItineraryState.Past,
          HotelItineraryState.Future,
          HotelItineraryState.Present,
        ],
        referenceDateTime: dayjs().format(),
      },
      history
    );
  }, [fetchFlights, fetchHotels, history]);

  useEffect(() => {
    if (isUserAuthenticated) {
      fetchAllItineraries();
      listWatches(history); // TODO recheck if it is possible to fetch watches for non-authenticated users
    }
    if (showFlightPriceFreeze) listPriceFreeze();
  }, [
    isUserAuthenticated,
    fetchAllItineraries,
    listPriceFreeze,
    showFlightPriceFreeze,
    history,
    listWatches,
  ]);

  const renderFilteredList = () => {
    switch (tripsFilter) {
      case MyTripsFilter.UPCOMING_TRIPS:
      case MyTripsFilter.PAST_TRIPS:
      case MyTripsFilter.FLIGHTS:
      case MyTripsFilter.HOTELS:
        return <ItineraryList isMobile={isMobile} />;
      case MyTripsFilter.WATCHED_TRIPS:
        return <WatchList isMobile={isMobile} />;
      case MyTripsFilter.PRIZE_FREEZES:
        return <Box />;
    }
  };

  const selectedItinerary = useMemo(() => {
    const renderCfarExercise = !(
      cfarExerciseProgress === CfarExerciseProgress.NotStarted
    );

    const renderChfarExercise = !(
      chfarExerciseProgress === ChfarExerciseProgress.NotStarted
    );

    const renderDisruptionExercise = !(
      disruptionExerciseProgress === DisruptionExerciseProgress.NotStarted
    );

    return (
      <>
        {selectedFlight ? <FlightItineraryDetails isMobile={isMobile} /> : null}
        {selectedWatch ? <WatchDetailsModalContent /> : null}
        {selectedHotel ? (
          <HotelItineraryDetails isMobile={matchesMobile} />
        ) : null}
        <ItinerariesModal isMobile={isMobile} />
      </>
    );
  }, [
    cfarExerciseProgress,
    chfarExerciseProgress,
    disruptionExerciseProgress,
    selectedFlight,
    selectedWatch,
    selectedHotel,
    matchesMobile,
    isMobile,
  ]);

  return selectedFlight || selectedHotel || selectedCar || selectedWatch ? (
    selectedItinerary
  ) : (
    <Box className={clsx({ mobile: isMobile }, "trips-list", "apac")}>
      {isUserAuthenticated ? (
        <>
          <TripsHeader
            className="trips-header"
            tripHeaderCopy={{
              title: t("trips"),
              supportCTA: t("contactSupport"),
              subtitle: t("tripsSubtitle"),
            }}
            isMobile={matchesMobile}
            showSubtitleForMobile={true}
          />
          <FilterTabs mobile={isMobile} />
          <div
            id={`${tripsFilter}-tab`}
            role="tabpanel"
            aria-labelledby={tripsFilter}
          >
            {tripsLoading && (
              <LoadingPopup
                open={tripsLoading || tripsLoading === null}
                indicator={B2BSpinner}
                message={t("fetchingTrips")}
                classes={[
                  "trips-loading-modal",
                  ...(isMobile ? ["mobile"] : []),
                ]}
              />
            )}
            {!tripsLoading && <NoTripResults isMobile={isMobile} />}
            {!hasError &&
              (hasTripsToDisplay || openModal) &&
              renderFilteredList()}
          </div>
        </>
      ) : (
        <TripSearch isMobile={isMobile} tripSearchResults={tripSearchResults} />
      )}
    </Box>
  );
};
