import { ApacIcon, ApacIconName } from "@commbank/ui";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  FareSliceDetails,
  FlightItinerarySlice,
  TripSegment,
} from "@hopper-b2b/types";
import { RemoteAirlineIcon } from "@hopper-b2b/ui";
import { ActionButton } from "@hopper-b2b/ui-core";
import {
  formatInterval,
  removeTimezone,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { ReactElement, useCallback, useMemo } from "react";
import "./FlightDetailsTimeline.styles.scss";
/**
 * Flight Details Summary Card
 * @function ApacFlightDetailsSummary
 *
 */
export interface FlightDetailsTimelineProps {
  className?: string;
  departureTime: string;
  onClick?: () => void;
  planeInfo?: string;
  fareClass?: string;
  segments: TripSegment[];
  showTitle?: boolean;
  plusDays?: number;
  header?: JSX.Element;
  fareSlice?: FareSliceDetails;
  isMixedCabinClass?: boolean;
  tripSlice?: FlightItinerarySlice;
  onChange?: () => void;
  renderAirlineIconSection?: boolean;
}

const renderMixedCabinClass = (
  idx: number,
  fareClass: string,
  fareSlice?: FareSliceDetails,
  tripSlice?: FlightItinerarySlice
) => {
  const segment = fareSlice
    ? fareSlice.fareDetails.segments[idx]
    : tripSlice
    ? tripSlice.segments[idx]
    : undefined;
  if (segment) {
    if (segment.cabinClassName && segment.cabinClassName.includes("Economy")) {
      return segment.cabinClassName;
    } else {
      return fareClass;
    }
  } else {
    return fareClass;
  }
};

export const FlightDetailsTimeline = (
  props: FlightDetailsTimelineProps
): ReactElement => {
  const {
    className,
    planeInfo,
    fareClass,
    segments,
    departureTime,
    showTitle = true,
    plusDays,
    header,
    fareSlice,
    isMixedCabinClass,
    tripSlice,
    onChange,
    renderAirlineIconSection = true,
  } = props;

  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const content = useMemo(() => {
    return {
      operatedBy: (segment: TripSegment) =>
        t("operatedBy", {
          airline: segment.operatingAirline.name,
        }),
      layover: (segment: TripSegment) =>
        t("flightShop.layoverTime", {
          duration: formatInterval(segment.stopoverDurationMinutes || 0),
        }),
      flightNextDay: (plusDays: number) =>
        plusDays > 1
          ? t("flightShop.arrivesNdayAfter", { day: plusDays })
          : t("flightNextDay"),
    };
  }, [t]);

  const renderPlaneInfo = useCallback(
    (segment: TripSegment, index: number) => (
      <Typography variant="body1" className="flight-info">
        {`${segment.airlineName} / ${segment.airlineCode}
                  ${segment.flightNumber} / ${
          isMixedCabinClass
            ? (fareSlice || tripSlice) &&
              renderMixedCabinClass(index, fareClass, fareSlice, tripSlice)
            : fareClass
        }`}
        {planeInfo && ` / ${planeInfo}`}
      </Typography>
    ),
    [fareClass, fareSlice, isMixedCabinClass, planeInfo, tripSlice]
  );

  const renderTripSlice = () => {
    return (
      <Box className="trip-slice">
        {segments.map((segment: TripSegment, index: number) => {
          const hasDifferentOperatingAirline =
            segment.operatingAirline?.code !== segment.marketingAirline?.code;
          return (
            <Box key={`trip-segment-${segment.flightNumber}-${index}`}>
              <Box className={`trip-segment-${segment.flightNumber}`}>
                <Box className="flight-time-info-wrapper">
                  <Box className="time-info-line"></Box>
                  <Box className={"flight-time-info-container"}>
                    <Box className="time-details">
                      <div className="start-circle circle" />
                      <Typography variant="h6" className="departure-details">
                        {dayjs(removeTimezone(segment.departureTime)).format(
                          "hh:mm A ddd DD MMM YYYY"
                        )}
                      </Typography>
                      <Typography variant="body1" className="departure-details">
                        {segment.originName} ({segment.originCode})
                      </Typography>
                    </Box>
                    <span className="trip-timing-line" />
                    <Typography variant="body2" className="travel-time">
                      {`${t("travelTime")}: ${formatInterval(
                        dayjs(segment.arrivalTime).diff(
                          dayjs(segment.departureTime),
                          "minutes"
                        )
                      )}`}
                    </Typography>
                    <Box className="time-details">
                      <div className="circle end-circle" />
                      <Typography variant="h6" className="departure-details">
                        {dayjs(removeTimezone(segment.arrivalTime)).format(
                          "hh:mm A ddd DD MMM YYYY"
                        )}
                      </Typography>
                      <Typography variant="body1" className="departure-details">
                        {segment.destinationName} ({segment.destinationCode})
                      </Typography>
                    </Box>
                    {renderAirlineIconSection ? null : (
                      <Box className="flight-info-details no-icon">
                        {renderPlaneInfo(segment, index)}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              {renderAirlineIconSection ? (
                <Box className="flight-info-details">
                  <Box className="airline-details">
                    <RemoteAirlineIcon
                      airlineCode={segment.airlineCode}
                      altText={segment.airlineName}
                      size="medium"
                    />
                  </Box>
                  {renderPlaneInfo(segment, index)}
                </Box>
              ) : null}
              {hasDifferentOperatingAirline && (
                <Typography className="operating-airline-info" variant="body1">
                  {content.operatedBy(segment)}
                </Typography>
              )}
              {segment.stopoverDurationMinutes && (
                <div className="stopover-details-container">
                  <ApacIcon name={ApacIconName.Hourglass} />
                  <div>
                    <Typography
                      variant="body1"
                      className="stopover-details"
                      dangerouslySetInnerHTML={{
                        __html: content.layover(segment),
                      }}
                    />
                    <Typography variant="body1">
                      {segment.destinationName} ({segment.destinationCode})
                    </Typography>
                  </div>
                </div>
              )}
            </Box>
          );
        })}

        {!!plusDays && (
          <div>
            <Typography variant="body1" className="plus-days">
              {content.flightNextDay(plusDays)}
            </Typography>
          </div>
        )}
      </Box>
    );
  };

  return (
    <Box
      className={clsx("apac-flight-details-summary", className, {
        mobile: matchesMobile,
      })}
    >
      <Box className="flight-details-subtitle">
        {header && header}
        {showTitle && (
          <Typography variant="subtitle2">
            <>
              <FontAwesomeIcon icon={faPlane as IconProp} />
              {t("departingOnWithDate", {
                date: dayjs(removeTimezone(departureTime)).format("ddd, MMM D"),
              })}
            </>
          </Typography>
        )}
        {renderTripSlice()}
      </Box>
      {onChange ? (
        <Box>
          <ActionButton
            variant="outlined"
            color="primary"
            className="flight-details-change-cta"
            onClick={onChange}
            message={t("flightShopReview.changeFlight")}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default FlightDetailsTimeline;
