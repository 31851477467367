import {
  CallState,
  DisruptionFetchOfferResponse,
  DisruptionFetchOfferSuccess,
} from "@hopper-b2b/types";
import { put, select } from "redux-saga/effects";
import { fetchDisruptionOffers } from "../../../api/v0/disruption/fetchDisruptionOffers";
import Logger from "../../../helpers/Logger";
import { IStoreState, flightApiConfigStoreKey } from "../../../reducers/types";
import { fetchDisruptionOfferRequestParameters } from "../../shop/reducer";
import { actions } from "../actions";

export function* fetchDisruptionOffersSaga(_: actions.IFetchDisruptionOffers) {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];

    const request = fetchDisruptionOfferRequestParameters(state);
    if (!request) {
      yield put(actions.setDisruptionOffersFailed());
      return;
    }

    const response: DisruptionFetchOfferResponse = yield fetchDisruptionOffers(
      request,
      apiConfig
    );

    // TODO: Set fail state
    // if (
    //   (response as DisruptionFetchOfferSuccess)?.FetchOfferResponse !==
    //   DisruptionFetchOfferResponseEnum.Success
    // ) {
    //   yield put(actions.setFetchDisruptionOfferCallStateFailed());
    //   return;
    // }

    yield put(
      actions.setDisruptionOffers({
        offers: (response as DisruptionFetchOfferSuccess)?.offers,
      })
    );
  } catch (e) {
    yield put(actions.setDisruptionOffersFailed());
    Logger.debug(e);
  }
}
