import { Fare, Flights, Slice } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  AlgomerchTag,
  FlightRatingsEnum,
  FlightShopCardType,
  IFlightGridFares,
  TagType,
  mapI18nAlgomerchText,
} from "@hopper-b2b/types";
import { ButtonWrap, FlightGridRow } from "@hopper-b2b/ui";
import {
  formatInterval,
  getPlusDaysOnSlice,
  getRewardsString,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import { useCallback, useMemo } from "react";
import { matchPath } from "react-router";
import { PATH_EXCHANGE } from "../../../../../../../utils/urlPaths";
import { FareclassOptionFilter } from "../../../../../../search/reducer";
import { apacGetTags } from "../../../../../utils/helpers";
import { IFlightListData } from "../../FlightList";
import { DesktopFlightCard } from "../DesktopFlightCard";
import { MobileFlightCard } from "../MobileFlightCard";
import "./styles.scss";

export enum FlightCardType {
  content = "content",
  skeleton = "skeleton",
}

interface IFlightListInfoBaseProps {
  className?: string;
  type: FlightCardType;
}

export interface IFlightListInfoContentProps extends IFlightListInfoBaseProps {
  selectedFare: any;
  slice: Slice;
  onClick?: (selectedFareRating: number) => void;
  onFareClick?: (fareId: string) => void;
  isExpanded?: boolean;
  type: FlightCardType.content;
  flights: Flights;
  flight: IFlightListData;
  rewardsKey: string;
  fareClassFilter: FareclassOptionFilter;
  onAlgomerchClick: (val: string) => void;
  maxFlightPrice: number;
  isRoundTrip: boolean;
}

export interface IFlightListInfoSkeletonProps extends IFlightListInfoBaseProps {
  type: FlightCardType.skeleton;
}

export const FlightListInfoContent = (props: IFlightListInfoContentProps) => {
  const {
    selectedFare,
    slice,
    flight,
    onClick,
    flights,
    rewardsKey,
    onFareClick,
    onAlgomerchClick,
  } = props;
  const { matchesMobile, matchesLargeDesktop } = useDeviceTypes();
  const { t, formatFiatCurrency, brand } = useI18nContext();
  const inExchange = matchPath(window.location.pathname, PATH_EXCHANGE);
  const airline = flights.airlines[slice.marketingAirline];
  const rewards = selectedFare.amount.accountSpecific[rewardsKey];
  const rewardsPriceText =
    rewards && rewardsKey ? Math.ceil(rewards.value).toLocaleString() : "";

  const getFareSliceId = (fare: Fare): string => {
    return fare?.fareSlice || fare?.return || "";
  };

  const fareSlice = flights.fareSlices[getFareSliceId(selectedFare)];
  const tags = [
    fareSlice?.tags.isBest ? AlgomerchTag.BestFlight : null,
    fareSlice?.tags.isCheapest ? AlgomerchTag.Cheapest : null,
    fareSlice?.tags.isBestQuality ? AlgomerchTag.BestQuality : null,
    fareSlice?.tags.isFastest ? AlgomerchTag.Fastest : null,
  ]
    .filter((t): t is AlgomerchTag => t !== null)
    .map((value) => ({ value, type: TagType.Algomerch }));

  const getFareId = (fare: Fare) => {
    return fare.example?.fare || fare?.id;
  };

  const getFlightGridFares = useCallback(
    (flight: IFlightListData) => {
      const fares: IFlightGridFares = {
        basic: null,
        standard: null,
        enhanced: null,
        premium: null,
        luxury: null,
      };
      flight.fares.forEach((fare: Fare) => {
        const fareSlice = flights.fareSlices[getFareSliceId(fare)];
        const fareClass = FlightRatingsEnum[fareSlice.fareShelf.value];
        const existingPrice = fares[fareClass]?.rawPrice;
        const fiatValue = fare.amount?.fiat.value;
        if (
          fiatValue &&
          (fares[fareClass] === null ||
            (existingPrice && existingPrice > fiatValue))
        ) {
          const tags = apacGetTags(fareSlice?.tags).map((tag) =>
            t(mapI18nAlgomerchText[tag])
          );

          const reward = rewardsKey
            ? fare.amount?.accountSpecific[rewardsKey]
            : "";

          const gridFare = {
            fareId: getFareId(fare),
            fareName: fareSlice?.fareBrandName ?? "",
            fareClass: fareClass,
            price: `${fare.amount?.fiat.currencySymbol}${Math.round(
              fiatValue
            )}`,
            rawPrice: fiatValue,
            fiatPrice: fare.amount?.fiat,
            tags,
          };
          if (reward) gridFare["reward"] = getRewardsString(reward);

          fares[fareClass] = gridFare;
        }
      });

      return fares;
    },

    [flights.fareSlices, rewardsKey, t]
  );

  const gridFares = useMemo(
    () => getFlightGridFares(flight),
    [flight, getFlightGridFares]
  );

  const renderGridDesktopFlightListInfo = () => {
    return (
      // Use Box instead of button here to avoid nesting button inside a button (invalid HTML).
      // onKeyDown, role, tabIndex are added to ensure accessibility.
      <Box
        className="flight-grid-row-wrapper"
        onClick={handleOnClick}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleOnClick();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <DesktopFlightCard
          key={slice.id}
          airlineCode={airline.code}
          airlineName={airline.displayName}
          fares={gridFares}
          departureTime={slice.departure}
          arrivalTime={slice.arrival}
          originCode={slice.origin}
          destinationCode={slice.destination}
          duration={formatInterval(slice.totalDurationMinutes)}
          onAlgomerchClick={onAlgomerchClick}
          layoverString={
            slice.stops === 0
              ? t("stopDetails.nonstop")
              : t("stopDetails.stop", { count: slice.stops })
          }
          plusDays={getPlusDaysOnSlice(slice)}
        />
      </Box>
    );
  };

  const renderMobileFlightListInfo = () => {
    const { fiat } = selectedFare.amount;
    const airports = slice.segments
      .slice(0, slice.segments.length - 1)
      .map((s) => s.destination);

    let currentPriceText = formatFiatCurrency(fiat, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });

    if (inExchange) {
      const { currencySymbol, value } = fiat;

      // exchange shop returns prices as deltas from original flight
      if (value >= 0) currentPriceText = `+${currentPriceText}`;
      else
        currentPriceText = t("exchangeable.shop.minDelta", {
          currencySymbol,
        });
    }

    return (
      <ButtonWrap className="flight-card-wrapper" onClick={handleOnClick}>
        <MobileFlightCard
          duration={formatInterval(slice.totalDurationMinutes)}
          currentPriceText={currentPriceText}
          rewardText={rewardsPriceText}
          originCode={slice.origin}
          destinationCode={slice.destination}
          departureTime={slice.departure}
          arrivalTime={slice.arrival}
          airports={airports}
          brandName={fareSlice.fareBrandName ?? ""}
          primaryCarrier={airline?.code}
          airlineName={airline?.displayName}
          tags={tags}
          cardType={brand.flightShopCardType || FlightShopCardType.REGULAR}
          layoverString={
            slice.stops === 0
              ? t("stopDetails.nonstop")
              : t("stopDetails.stop", { count: slice.stops })
          }
        />
      </ButtonWrap>
    );
  };

  const handleOnClick = useCallback(() => {
    onClick && onClick(fareSlice.fareShelf.value);
    if (!matchesLargeDesktop) {
      onFareClick && onFareClick(getFareId(selectedFare));
    }
  }, [
    fareSlice.fareShelf.value,
    matchesLargeDesktop,
    onClick,
    onFareClick,
    selectedFare,
  ]);

  return (
    <div className={"flight-list-info-root"}>
      {matchesMobile
        ? renderMobileFlightListInfo()
        : renderGridDesktopFlightListInfo()}
    </div>
  );
};

export type IFlightListInfoProps =
  | IFlightListInfoContentProps
  | IFlightListInfoSkeletonProps;

export const FlightListInfo = (props: any) => {
  switch (props.type) {
    case "content":
      return <FlightListInfoContent {...props} />;
    case "skeleton":
      return (
        <Box className={"flight-list-info-root"}>
          <FlightGridRow {...props} />
        </Box>
      );
    default:
      return null;
  }
};
