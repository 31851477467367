import { ConnectedProps, connect } from "react-redux";
import {
  getAirlineFilter,
  getAirportFilter,
  getFlightNumberFilter,
  getMaxPriceFilter,
  getOutboundArrivalTimeRange,
  getOutboundDepartureTimeRange,
  getReturnArrivalTimeRange,
  getReturnDepartureTimeRange,
  getStopsOptionFilter,
} from "../../modules/search/reducer";
import {
  setRerunPrediction,
  setSortOption,
} from "../../modules/shop/actions/actions";
import { IStoreState } from "../../reducers/types";
import { FlightSearchFilterContent } from "./FlightSearchFilterContent";
import {
  allFlightShopFilterSelector,
  sortOptionSelector,
} from "../../modules/shop/reducer";
import {
  setStopsOption,
  setMaxPriceFilter,
  setOutboundDepartureTimeRange,
  setOutboundArrivalTimeRange,
  setReturnDepartureTimeRange,
  setReturnArrivalTimeRange,
  setAirportFilter,
  setAirlineFilter,
  setFlightNumberFilter,
} from "../../modules/search/actions/actions";

const mapDispatchToProps = {
  setSortOption,
  setStopsOption,
  setRerunPrediction,
  setMaxPriceFilter,
  setOutboundDepartureTimeRange,
  setOutboundArrivalTimeRange,
  setReturnDepartureTimeRange,
  setReturnArrivalTimeRange,
  setAirportFilter,
  setAirlineFilter,
  setFlightNumberFilter,
};

const mapStateToProps = (state: IStoreState) => {
  return {
    sortOption: sortOptionSelector(state),

    airlineFilter: getAirlineFilter(state),
    stopsOptionFilter: getStopsOptionFilter(state),
    getAirportFilter: getAirportFilter(state),

    outboundDepartureTimeRange: getOutboundDepartureTimeRange(state),
    outboundArrivalTimeRange: getOutboundArrivalTimeRange(state),
    returnDepartureTimeRange: getReturnDepartureTimeRange(state),
    returnArrivalTimeRange: getReturnArrivalTimeRange(state),

    maxPriceFilter: getMaxPriceFilter(state),
    flightNumberFilter: getFlightNumberFilter(state),

    flightShopFilters: allFlightShopFilterSelector(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ConnectedFlightSearchFilterContent = connector(
  FlightSearchFilterContent
);

export type FlightSearchFilterContentConnectorProps = ConnectedProps<
  typeof connector
>;
