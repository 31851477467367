import { assign } from "xstate";
import {
  getObjectKeysAsObject,
  ParentState,
  setContextWithKey,
} from "@hopper-b2b/checkout";
import {
  SetSelectedAwardsAccountEvent,
  SetAwardsAmountToPayEvent,
  SetAwardsCashAmountToPayEvent,
  SetAwardsAccountsEvent,
} from "./events";
import { ParentContextWithAwardsPayment } from "./types";

export const actions = {
  setSelectedAwardsAccountId: assign(
    (
      ctx: ParentContextWithAwardsPayment,
      event: SetSelectedAwardsAccountEvent
    ) => {
      ctx[ParentState.awardsPayment].selectedAccount = event.selectedAccount;
      return ctx;
    }
  ),

  setAwardsAccounts: assign(
    (ctx: ParentContextWithAwardsPayment, event: SetAwardsAccountsEvent) => {
      ctx[ParentState.awardsPayment].awardsAccounts = event.accounts;
      return ctx;
    }
  ),

  setAwardsAmountToPay: assign(
    (ctx: ParentContextWithAwardsPayment, event: SetAwardsAmountToPayEvent) => {
      ctx[ParentState.awardsPayment].awardsAmountToApply =
        event.awardsAmountToPay;
      return ctx;
    }
  ),

  setAwardsCashAmountToPay: assign(
    (
      ctx: ParentContextWithAwardsPayment,
      event: SetAwardsCashAmountToPayEvent
    ) => {
      ctx[ParentState.awardsPayment].awardsCashAmountToApply =
        event.awardsCashAmountToPay;
      return ctx;
    }
  ),

  setAwardsPaymentVisited: assign(
    (ctx: ParentContextWithAwardsPayment, _event) =>
      setContextWithKey(ctx, `${ParentState.payment}.visited`, true)
  ),

  // TODO(isabela): once payment BE work is done fix params
  //   setPaymentFulfillParams: assign(
  //     (ctx: ParentContextWithCardPayment, _event) => {
  //       const paymentPricing =
  //         ctx[ParentState.cartQuote].quoteBreakdown.sellAt ||
  //         ctx[ParentState.cartQuote].quoteBreakdown.balance;

  //       const cardPayment: ICardPayment = {
  //         type: Payment.Card,
  //         value: {
  //           paymentAmount: {
  //             amount: paymentPricing.fiat.value,
  //             currency: paymentPricing.fiat.currencyCode,
  //           },
  //           paymentId: ctx[ParentState.cardPayment].selectedPaymentMethodId,
  //         },
  //       };

  //       // Can only add one card for payment
  //       const payments = ctx[
  //         ParentState.cartFulfill
  //       ].fulfillRequestPayments.filter((p) => p.type !== Payment.Card);
  //       ctx[ParentState.cartFulfill].fulfillRequestPayments = [
  //         ...payments,
  //         cardPayment,
  //       ];
  //       return ctx;
  //     }
  //   ),
};

export const ActionTypes = getObjectKeysAsObject(actions);
