import { axiosInstance } from "@hopper-b2b/api";
import {
  AvailabilityByLodgingQuery,
  fetchAvailabilityByLodgingId,
} from "../availability/fetchAvailability";
import {
  AvailabilityResponse,
  Lodging,
  ShopResponse,
  lodgingApi,
} from "@b2bportal/lodging-api";

export const fetchShopByAvailabilityAPI = async (
  query: AvailabilityByLodgingQuery,
  lodgingId: string
): Promise<
  [Lodging, string | undefined, Promise<ShopResponse>, AvailabilityResponse]
> => {
  const availability = await fetchAvailabilityByLodgingId(query);
  const lodging = availability.lodgings.find(
    (lodging) => lodging.lodging.id === lodgingId
  );
  const offerOpaque: string | undefined =
    availability.offers?.opaqueProductMap?.[lodgingId];

  if (lodging) {
    return [
      lodging,
      offerOpaque,
      fetchShopByOpaqueRequestAPI(lodging?.price.opaqueShopRequest),
      availability,
    ];
  } else {
    console.error("ERROR: Lodging not found", lodgingId);
  }
};

export const fetchShopByOpaqueRequestAPI = (
  opaqueRequest: string
): Promise<ShopResponse> => {
  const body = {
    opaqueRequest,
  };
  return new Promise((resolve, reject) => {
    try {
      lodgingApi(axiosInstance)
        .apiV0HotelsShopPost(body)
        .then((res) => resolve(res.data));
    } catch (e) {
      reject(e);
    }
  });
};
