/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/alt-text */
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { FintechProductOption } from "@hopper-b2b/types";
import { ButtonWrap, OfferLoadingCard } from "@hopper-b2b/ui";
import clsx from "clsx";
import { ApacRadio } from "../ApacRadio";
import "./styles.scss";

interface ApacFintechPurchaseCardProps {
  option: FintechProductOption;
  className?: string;
  clientAssets?: Record<string, any>;
  loading?: boolean;
  isHalfSheet?: boolean;
}

export const ApacFintechPurchaseCard = ({
  option,
  className,
  loading = false,
}: ApacFintechPurchaseCardProps) => {
  if (loading) {
    <OfferLoadingCard />;
  }

  return (
    <ButtonWrap
      className={clsx("fintech-purchase-card", className, {
        selected: option.selected,
      })}
      onClick={option.onClick}
    >
      <ApacRadio
        className="fintech-purchase-radio"
        checked={option.selected}
        onChange={() => {
          console.log("dummy print to avoid warning message");
        }}
      />
      <div className="fintech-purchase-card-header">
        <p className="fintech-purchase-card-title">{option.title}</p>
        {option.cost ? (
          <p className="fintech-purchase-card-price">
            <I18nMarkup
              tKey={"costPerTraveler"}
              replacements={{ cost: option.cost }}
            />
          </p>
        ) : null}
      </div>
    </ButtonWrap>
  );
};
