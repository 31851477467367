export enum Event {
  // CommonEvents
  NEXT = "NEXT",
  PREVIOUS = "PREVIOUS",
  CHANGE = "CHANGE",
  GO_IDLE = "GO_IDLE",
  RESET_CONTEXT = "RESET_CONTEXT",
  COMPLETE = "COMPLETE",

  //  PassengerInformation
  SELECT_PASSENGER = "SELECT_PASSENGER",
  ADD_PASSENGER = "ADD_PASSENGER",
  UPDATE_PASSENGER = "UPDATE_PASSENGER",
  DELETE_PASSENGER = "DELETE_PASSENGER",
  OPEN_PASSENGER_FORM = "OPEN_PASSENGER_FORM",
  OPEN_PASSENGER_PICKER = "OPEN_PASSENGER_PICKER",
  OPEN_PASSPORT_FORM = "OPEN_PASSPORT_FORM",
  SET_CURRENT_PASSENGER = "SET_CURRENT_PASSENGER",
  CLEAR_CURRENT_INFANT_ID = "CLEAR_CURRENT_INFANT_ID",
  ON_INFANT_MODAL_CONTINUE = "ON_INFANT_MODAL_CONTINUE",
  CLEAR_PASSENGER_INFORMATION_ERROR = "CLEAR_PASSENGER_INFORMATION_ERROR",

  // ContactInformation
  SET_CONTACT_INFO = "SET_CONTACT_INFO",
  CLEAR_CONTACT_ERROR = "CLEAR_CONTACT_ERROR",

  // Passport
  UPDATE_PASSPORT_AND_CONTINUE = "UPDATE_PASSPORT_AND_CONTINUE",
  VALIDATE_PASSENGERS = "VALIDATE_PASSENGERS",
  CLEAR_PASSPORT_ERROR = "CLEAR_PASSPORT_ERROR",

  // CartQuote
  CLEAR_SCHEDULE_QUOTE_ERROR = "CLEAR_SCHEDULE_QUOTE_ERROR",

  // PaymentInformation
  OPEN_PAYMENT_FORM = "OPEN_PAYMENT_FORM",
  CLOSE_PAYMENT_FORM = "CLOSE_PAYMENT_FORM",
  SET_APPLIED_BALANCE = "SET_APPLIED_BALANCE",
  VERIFY_PAYMENT_METHOD = "VERIFY_PAYMENT_METHOD",
  DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD",
  SET_PAYMENT_METHOD_ID = "SET_PAYMENT_METHOD_ID",
  SET_SELECTED_PAYMENT_METHOD_ID = "SET_SELECTED_PAYMENT_METHOD_ID",

  // Review
  GO_TO_PASSENGER_SELECT = "GO_TO_PASSENGER_SELECT",
  GO_TO_CONTACT_INFORMATION = "GO_TO_CONTACT_INFORMATION",

  // CartFulfill
  CLEAR_SCHEDULE_FULFILL_ERROR = "CLEAR_SCHEDULE_FULFILL_ERROR",

  // Confirmation
  GO_HOME = "GO_HOME",

  // SeatSelection
  CLEAR_SEAT_ERROR = "CLEAR_SEAT_ERROR",
  SELECT_SEATS = "SELECT_SEATS",
  SKIP_SEAT_SELECTION = "SKIP_SEAT_SELECTION",

  // CartUpdate
  ADD_PRODUCTS = "ADD_PRODUCTS",
  REMOVE_PRODUCTS = "REMOVE_PRODUCTS",
  ADD_PAYMENTS = "ADD_PAYMENTS",
  REMOVE_PAYMENTS = "REMOVE_PAYMENTS",

  // Transition to state from query params
  GO_TO_PAYMENT = "GO_TO_PAYMENT",
  GO_TO_REVIEW = "GO_TO_REVIEW",
  GO_TO_BOOKING_CONFIRMATION = "GO_TO_BOOKING_CONFIRMATION",
}
