import { useDeviceTypes } from "@hopper-b2b/utilities";
import styles from "./InfoBanner.module.scss";
import { Icon, IconName } from "@hopper-b2b/ui";
import clsx from "clsx";

export const InfoBanner = ({
  icon,
  text,
  className,
}: {
  icon: string;
  text: string | JSX.Element;
  className?: string;
}) => {
  const { matchesMobile } = useDeviceTypes();
  return (
    <div className={clsx(className, styles.infoBanner)}>
      <div
        className={clsx(styles.iconContainer, {
          [styles.autoHeight]: matchesMobile,
        })}
      >
        {icon ? (
          <img alt="info-icon" src={icon} className={styles.icon} />
        ) : (
          <Icon className={styles.icon} name={IconName.InfoWhiteCircle} />
        )}
      </div>
      <div className={styles.textContainer}>
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
};
