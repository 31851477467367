import { Box, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { ButtonWrap, IActionLink } from "@hopper-b2b/ui";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import "./styles.scss";

interface IMobileActionLinksProps {
  actions: IActionLink[];
}

export const MobileActionLinks = ({ actions }: IMobileActionLinksProps) => (
  <Box className="mobile-action-links-container">
    {actions.map((action, index) => (
      <Box
        component={ButtonWrap}
        key={`mobile-action-link-${index}`}
        className={clsx("mobile-action-link", {
          "link-separator": index !== actions.length - 1,
        })}
        onClick={action.onClick}
      >
        <Typography
          className={clsx("mobile-action-link-text", action.linkClassName)}
        >
          {action.content}
        </Typography>
        <FontAwesomeIcon
          className="mobile-right-chevron"
          icon={faChevronRight as IconProp}
        />
      </Box>
    ))}
  </Box>
);
