import { useEffect } from "react";

export const useWindowEventListener = (
  eventName: string,
  eventHandler: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions
) => {
  useEffect(() => {
    window.addEventListener(eventName, eventHandler, options);
    return () => window.removeEventListener(eventName, eventHandler, false);
  }, []);
};
