import { Box } from "@material-ui/core";
import { useHistory } from "react-router";

import { FlightSearchControl } from "../../../search";

import "./styles.scss";

export interface IShopSearchControlProps {
  disabled: boolean;
  onSearch: () => void;
}

const defaultProps: Partial<IShopSearchControlProps> = {};

const ShopSearchControl = (props: IShopSearchControlProps): JSX.Element => {
  const { onSearch } = props;
  const history = useHistory();

  return (
    <Box className="shop-search-control-container">
      <FlightSearchControl
        hideCategoryToggle
        hidePassengerPicker
        history={history}
        onSearch={onSearch}
      />
    </Box>
  );
};

ShopSearchControl.defaultProps = defaultProps;

export default ShopSearchControl;
