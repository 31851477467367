import { useI18nContext } from "@hopper-b2b/i18n";
import { useEffect } from "react";

export const useDocumentTitle = (pageTitleSuffix) => {
  const { brand } = useI18nContext();

  useEffect(() => {
    document.title = `${brand.portalName} | ${pageTitleSuffix}`;

    return () => {
      document.title = brand.portalName;
    };
  }, []);
};
