import { takeLatest, takeEvery } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { fetchTripDetails } from "./fetchTripDetailsSaga";
import { populateFlightShopQueryParamsSaga } from "./populateShopQueryParamsSaga";
import { createWatchSaga } from "./createWatchSaga";
import { fetchWatchesSaga } from "./fetchWatchesSaga";
import { updateWatchSaga } from "./updateWatchSaga";
import { deleteWatchSaga } from "./deleteWatchSaga";
import { fetchSimilarFlightsSaga } from "./fetchSimilarFlightsSaga";
import { fetchTransferSimilarFlightsSaga } from "./fetchTransferToSimilarFlightsSaga";
import { fetchSelectedTripPriceFreezeOfferSaga } from "./fetchSelectedTripPriceFreezeOfferSaga";
import { fetchFlightsV4 } from "./fetchFlightsV4Saga";

export function* watchSetUpQueryParams() {
  yield takeLatest(
    actionTypes.POPULATE_FLIGHT_SHOP_QUERY_PARAMS,
    populateFlightShopQueryParamsSaga
  );
}

export function* watchFetchSimilarFlightsSaga() {
  yield takeLatest(actionTypes.FETCH_SIMILAR_FLIGHTS, fetchSimilarFlightsSaga);
}

export function* watchFetchTransferSimilarFlightsSaga() {
  yield takeLatest(
    actionTypes.FETCH_TRANSFER_TO_SIMILAR_FLIGHTS,
    fetchTransferSimilarFlightsSaga
  );
}

export function* watchFetchFlightsV4Saga() {
  yield takeLatest(actionTypes.FETCH_FLIGHTS_V4, fetchFlightsV4);
}

export function* watchFetchFlightsV4ForPredictionSaga() {
  yield takeLatest(actionTypes.FETCH_FLIGHTS_V4_FOR_PREDICTION, fetchFlightsV4);
}

export function* watchFetchTripDetailsSaga() {
  yield takeEvery(actionTypes.FETCH_TRIP_DETAILS, fetchTripDetails);
}

export function* watchFetchSelectedTripPriceFreezeOfferSaga() {
  yield takeEvery(
    actionTypes.FETCH_SELECTED_TRIP_PRICE_FREEZE_OFFER,
    fetchSelectedTripPriceFreezeOfferSaga
  );
}

export function* watchCreateWatch() {
  yield takeLatest(actionTypes.CREATE_WATCH, createWatchSaga);
}

export function* watchListWatches() {
  yield takeLatest(actionTypes.LIST_WATCHES, fetchWatchesSaga);
}

export function* watchUpdateWatch() {
  yield takeLatest(actionTypes.UPDATE_WATCH, updateWatchSaga);
}

export function* watchDeleteWatch() {
  yield takeLatest(actionTypes.DELETE_WATCH, deleteWatchSaga);
}
