import { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { ActionLink } from "../ActionLink";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ButtonWrap } from "../ButtonWrap";
import clsx from "clsx";
import "./styles.scss";

export interface MyTripsCardProps {
  className?: string;
  content: ReactNode;
  actions: ReactNode;
  cta?: ReactNode;
  banner?: JSX.Element;
  topRightAction?: ReactNode;
  expandString?: string;
  expandIcon?: IconProp;
  collapseIcon?: IconProp;
  isExpanded?: boolean;
  onExpandClick?: () => void;
}

export const MyTripsCard = ({
  className,
  content,
  actions,
  banner,
  cta,
  topRightAction,
  expandString,
  expandIcon = faCaretDown as IconProp,
  collapseIcon = faCaretUp as IconProp,
  isExpanded,
  onExpandClick = undefined,
}: MyTripsCardProps) => {
  return (
    <div className="my-trips-card">
      {banner}
      <ButtonWrap
        className={clsx("my-trips-card-content", className)}
        onClick={onExpandClick}
      >
        <div className={clsx("my-trips-left", { cta: !!cta })}>
          {content}
          {actions}
        </div>
        <div className={clsx("my-trips-right", { cta: !!cta })}>
          {cta ? (
            cta
          ) : (
            <>
              <div className="top-right">{topRightAction}</div>
              <div className="expand-link">
                <ActionLink
                  ariaProps={{ "aria-expanded": isExpanded }}
                  onClick={onExpandClick}
                  content={
                    <>
                      <div className="expand-content">{expandString}</div>
                      <FontAwesomeIcon
                        className="expand-icon"
                        icon={isExpanded ? collapseIcon : expandIcon}
                      />
                    </>
                  }
                ></ActionLink>
              </div>
            </>
          )}
        </div>
      </ButtonWrap>
    </div>
  );
};
