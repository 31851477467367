import {
  BookedFlightItineraryWithDepartureTime,
  FiatPrice,
  SeatedPassengers,
  SingleTravelItinerary,
  TotalTripPricing,
  TrackingProperties,
  TravelItinerary,
} from "@b2bportal/air-booking-api";
import { TripType } from "@b2bportal/air-shopping-api";
import { DisruptionExerciseProductType, ExerciseType } from "@hopper-b2b/types";

const getSharedDisruptionProperties = ({
  totalPricing,
  passengers,
  travelItinerary,
  type,
  exerciseCost,
}: {
  totalPricing: TotalTripPricing;
  passengers: SeatedPassengers;
  travelItinerary: TravelItinerary;
  type: DisruptionExerciseProductType;
  exerciseCost: FiatPrice | null;
}) => ({
  charged_currency: totalPricing.total.fiat.currencyCode,
  base_total: totalPricing.baseWithoutMargin.fiat.value,
  pax_total: passengers.alone.length,
  total_price: totalPricing.total.fiat.value,
  // TODO: Figure out MultiTravelItinerary: OW or RT
  trip_type:
    (travelItinerary as SingleTravelItinerary).slices.length > 1
      ? TripType.round_trip
      : TripType.one_way,
  base_total_usd: 0, //TODO: Fix when api includes usd values
  total_price_usd: 0, //TODO: Fix when api includes usd values
  ...(type === DisruptionExerciseProductType.MissedConnection
    ? {
        rebooking_tool: false, // Hardcoded to false, every rebooking is done by agents currently
        rebooking_direction: undefined, // No info b/c of agent manual rebooking/refund
        missed_connection_refund_costs: exerciseCost?.value || 0, //One of reimbursementAmount(refund) or maxTotalCost(rebook)
      }
    : {
        delay_protection_compensation_cost_usd: exerciseCost?.value || 0, //One of reimbursementAmount(refund) or maxTotalCost(rebook)
      }),
});

export const getDisruptionExerciseTracking = (
  flight: BookedFlightItineraryWithDepartureTime,
  type: DisruptionExerciseProductType,
  trackingProperties?: TrackingProperties,
  exerciseCost?: FiatPrice | null
) => {
  const { travelItinerary, sellingPricing, passengers } =
    flight.bookedItinerary;
  const { totalPricing } = sellingPricing;
  const sharedProperties = getSharedDisruptionProperties({
    travelItinerary,
    passengers,
    totalPricing,
    type,
    exerciseCost,
  });

  return {
    ...(trackingProperties || {}),
    ...sharedProperties,
    exercise_type: ExerciseType.refund,
  };
};
