import { Box, Typography } from "@material-ui/core";
import Chevron from "../../assets/icons/common/chevron.svg";
import clsx from "clsx";
import "./styles.scss";

export interface IInfoCardProps {
  className?: string;
  icon?: string;
  title: string;
  subtitle?: string;
  onClick?: () => void;
}

export const InfoCard = (props: IInfoCardProps) => {
  const { className, icon, title, subtitle, onClick } = props;
  return (
    <Box
      className={clsx(className, "info-card", { "no-icon": !icon })}
      onClick={onClick}
    >
      {!!icon && (
        <Box className="icon">
          <img src={icon} alt="info card icon" />
        </Box>
      )}
      <Box className="description">
        <Typography className="title" variant="subtitle1">
          {title}
          <img src={Chevron} className="chevron" alt="chevron icon" />
        </Typography>
        <Typography className="subtitle" variant="subtitle2">
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};
