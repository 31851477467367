// Use process.env when running Pawtucket modules on their own, otherwise use runtime config from McLean
export const BASE_URL = import.meta.env.VITE_BASE_URL;

export const GOOGLE_MAPS_API_KEY = import.meta.env
  .VITE_REACT_APP_GOOGLE_MAPS_API_KEY;

export const SPREEDLY_ENVIRONMENT_KEY = import.meta.env
  .VITE_SPREEDLY_ENVIRONMENT_KEY;

export const config = {
  // TODO implement auth when BE complete
  apiKey: "test",
  baseURL: BASE_URL!,
  googleMapsApiKey: GOOGLE_MAPS_API_KEY!,
  spreedlyEnvironmentKey: SPREEDLY_ENVIRONMENT_KEY!,
};
