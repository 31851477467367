import { useCallback } from "react";
import clsx from "clsx";
import { Link, useParams } from "react-router-dom-v5-compat";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useLodgingLink } from "../../hooks/useLodgingLink";
import { LodgingShopTrackingEvents } from "@hopper-b2b/types";
import { useTrackEvents, ProductType } from "../../../../util/trackEvent";
import { Box } from "@material-ui/core";
import { ApacActionButton, ApacIcon, ApacIconName } from "@commbank/ui";
import { LodgingCard } from "../LodgingCardNew";
import { useClickOutside } from "@commbank/hooks";

export default ({ lodging, onClose }) => {
  const { t } = useI18nContext();
  const trackEvent = useTrackEvents();
  const params = useParams();
  const location = params["location"];
  const lodgingLink = useLodgingLink(
    lodging.lodging.id,
    lodging.lodging.name,
    location
  );

  const onClickOutside = useCallback(
    (e: MouseEvent) => {
      const classList = (e.target as Element).classList;
      if (!classList.contains("marker")) {
        // the onclick on the opening markers is called after this component renders
        // we'll ignore the marker clicks and let them handle the selected state
        onClose();
      }
    },
    [onClose]
  );

  const containerRef = useClickOutside<HTMLAnchorElement>(onClickOutside);

  return (
    <Link
      ref={containerRef}
      className="quick-view-container"
      to={lodgingLink}
      target="_blank"
      onClick={() => {
        trackEvent(
          LodgingShopTrackingEvents.hotel_tapped_map_marker,
          ProductType.Hotel,
          {}
        );
      }}
    >
      <Box
        className="close-btn"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose();
        }}
      >
        <ApacIcon
          ariaLabel={t("close.imageAlt")}
          className={clsx("close-button-icon")}
          name={ApacIconName.Close}
        />
      </Box>
      <LodgingCard
        lodging={lodging}
        setOveredId={(_: string) => undefined}
        index={0}
      />
      <Box className="view-hotel-button">
        <ApacActionButton
          fullWidth
          message={t("commBank.hotelAvailability.viewHotelButton")}
        />
      </Box>
    </Link>
  );
};
