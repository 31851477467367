import { Icon, IconName } from "@hopper-b2b/ui";
import clsx from "clsx";
import "./styles.scss";
import { useI18nContext } from "@hopper-b2b/i18n";

export const Maintenance = () => {
  const { t } = useI18nContext();
  return (
    <div className={clsx("cp-maintenance-content")}>
      <Icon className="error-icon" name={IconName.ErrorState} />
      <h2>{t("commBank.maintenanceTitle")}</h2>
      <span>{t("commBank.maintenanceSubtitle")}</span>
    </div>
  );
};

export default Maintenance;
