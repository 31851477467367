import { put } from "redux-saga/effects";
import { WatchAlertViews } from "@b2bportal/air-price-watch-api";
import {
  CallState,
  DeleteWatchAlertRequest,
  STOPPED_WATCH,
} from "@hopper-b2b/types";
import { deleteWatch, trackEvent } from "@hopper-b2b/api";

import { listWatches } from "../../../../api/v1/price-watch/listWatches";
import { actions } from "../../actions";

export function* deleteWatchSaga(action: actions.IDeleteWatch) {
  try {
    const request: DeleteWatchAlertRequest = {
      key: action.watch.key,
    };
    yield deleteWatch(request);
    const watches: WatchAlertViews = yield listWatches();
    yield put(actions.setWatches(watches));
    yield put(actions.setDeleteWatchCallState(CallState.Success));
    trackEvent({
      eventName: STOPPED_WATCH,
      properties: { success: true },
    });
  } catch (e) {
    trackEvent({
      eventName: STOPPED_WATCH,
      properties: { success: false },
    });
    yield put(actions.setDeleteWatchCallState(CallState.Failed));
  }
}
