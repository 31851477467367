import clsx from "clsx";
import { type HotelStarRating } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { toStarRating } from "@hopper-b2b/utilities";
import styles from "./StarRating.module.scss";
import { Typography, TypographyVariant } from "@material-ui/core";
import { IconComponent, IconName } from "@hopper-b2b/ui";

interface Props {
  starRating: HotelStarRating;
  className?: string;
  textVariant?: TypographyVariant;
}

export function StarRating({ starRating, className, textVariant }: Props) {
  const { t } = useI18nContext();

  return (
    <div className={clsx(className, styles.Container)}>
      <IconComponent name={IconName.StarFilled} />
      <Typography color="textPrimary" variant={textVariant ?? "body1"}>
        {t("nStarHotelRating", { numOfStars: toStarRating(starRating) })}
      </Typography>
    </div>
  );
}
