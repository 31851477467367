import axios from "axios";
import { IApiConfig, IPerson, NETWORK_CALL_FAILED } from "@hopper-b2b/types";

import { passengersApiPrefix } from "../../paths";
import { trackEvent } from "@hopper-b2b/api";
const END_POINT = `${passengersApiPrefix}/update`;

export const updateUserPassenger = (
  body: IUpdateUserPassengerRequest,
  apiConfig?: IApiConfig
): Promise<IUpdateUserPassengerResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(END_POINT, body);

      const responseBody: IUpdateUserPassengerResponse = res.data;
      resolve(responseBody);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });

export interface IUpdateUserPassengerRequest {
  person: IPerson;
}

export type IUpdateUserPassengerResponse = Record<string, never>;
