import { URL_PARAM_KEYS } from "@hopper-b2b/lodging-utils";
import { CheckoutProps } from "@hopper-b2b/types";
import {
  FeatureFlagsContext,
  FeatureFlagsContextProps,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import clsx from "clsx";
import { useMemo } from "react";
import { Provider } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom-v5-compat";
import styles from "./ApacLodgingApp.module.scss";
import "./ApacLodgingApp.scss";
import { LodgingAvailability } from "./modules/availability";
import { type ILodgingAvailabilityState } from "./modules/availability/reducer";
import { LodgingCheckout } from "./modules/checkout";
import { HotelSearch } from "./modules/search";
import { Shop } from "./modules/shop";
import configureLodgingAppStore from "./store";
import {
  readFiltersFromURL,
  readSortOptionFromUrl,
} from "./store/urlMiddleWare";
import {
  PATH_AVAILABILITY_RELATIVE,
  PATH_BOOK_RELATIVE,
  PATH_HOME,
  PATH_HOME_RELATIVE,
  PATH_HOTELS_ROOT,
  PATH_SEARCH_RELATIVE,
  PATH_SHOP_RELATIVE,
  formatDateForUrl,
  toDate,
} from "./util";
import { HotelStepBar } from "./components/HotelStepBar/component";

interface LodgingModuleProps {
  checkoutProps?: CheckoutProps;
  featureFlags?: FeatureFlagsContextProps;
  openProfilePanel: boolean;
  setOpenProfilePanel: (boolean) => void;
}

const store = configureLodgingAppStore({
  lodgingAvailability: {
    fromDate: undefined,
    untilDate: undefined,
    guests: {
      adults: 1,
      children: [],
    },
    rooms: 1,
    loading: "loading-from-place",
    lodgings: [],
    location: "",
    filters: readFiltersFromURL(),
    sort: readSortOptionFromUrl(),
  },
});

export const ApacLodgingApp = ({
  featureFlags,
  checkoutProps,
  openProfilePanel,
  setOpenProfilePanel,
}: LodgingModuleProps) => {
  return (
    <Provider store={store}>
      <FeatureFlagsContext.Provider value={featureFlags ?? {}}>
        <ApacLodgingAppBody
          checkoutProps={checkoutProps}
          openProfilePanel={openProfilePanel}
          setOpenProfilePanel={setOpenProfilePanel}
        />
      </FeatureFlagsContext.Provider>
    </Provider>
  );
};

const ApacLodgingAppBody = ({
  checkoutProps,
  openProfilePanel,
  setOpenProfilePanel,
}: {
  checkoutProps?: CheckoutProps;
  openProfilePanel: boolean;
  setOpenProfilePanel: (boolean) => void;
}) => {
  const location = useLocation();
  const { matchesMobile } = useDeviceTypes();

  return (
    <div
      className={clsx("LodgingApp", {
        [styles.LodgingApp]:
          !matchesMobile &&
          (location.pathname === PATH_HOME + PATH_HOTELS_ROOT ||
            location.pathname ===
              PATH_HOME + PATH_HOTELS_ROOT + PATH_SEARCH_RELATIVE),
      })}
    >
      <HotelStepBar
        openProfilePanel={openProfilePanel}
        setOpenProfilePanel={setOpenProfilePanel}
      />
      <Routes>
        <Route
          path={PATH_HOME_RELATIVE}
          element={<HotelSearch initialAdultGuests={1} showRooms />}
        />
        <Route
          path={PATH_SEARCH_RELATIVE}
          element={<HotelSearch initialAdultGuests={1} showRooms />}
        />
        <Route
          path={PATH_AVAILABILITY_RELATIVE + ":location/:placeId?/*"}
          element={<LodgingAvailability />}
        />
        <Route
          path={PATH_SHOP_RELATIVE + ":location/:lodgingName?/:id/*"}
          element={<Shop />}
        />
        <Route
          path={PATH_BOOK_RELATIVE}
          element={<LodgingCheckout {...checkoutProps} />}
        />
        <Route
          path={"*"}
          element={<Navigate to={PATH_HOME + PATH_HOTELS_ROOT} />}
        />
      </Routes>
    </div>
  );
};
