import styles from "./ImageCarousel.module.scss";
import { Carousel } from "@hopper-b2b/ui";
import clsx from "clsx";
import { useState } from "react";
import { DotsIndicator } from "./DotsIndicator";

interface ImageCarouselProps {
  carouselImagesUrls: string[];
  className?: string;
  onCarouselNavigate?: () => void;
}

export function ImageCarousel({
  carouselImagesUrls,
  className,
  onCarouselNavigate,
}: ImageCarouselProps) {
  const [goLeft, setGoLeft] = useState<boolean>(false);
  const [goRight, setGoRight] = useState<boolean>(false);
  return (
    <div className={clsx(styles.CarouselContainer, className)}>
      <Carousel
        className={styles.ImageCarousel}
        imageUrlsArray={carouselImagesUrls}
        onNavigateToNextSlide={() => {
          setGoRight(true);
          onCarouselNavigate?.();
        }}
        onNavigateToPrevSlide={() => {
          setGoLeft(true);
          onCarouselNavigate?.();
        }}
        hideDots
      />
      <DotsIndicator
        className={styles["slider-dots-root"]}
        goLeft={goLeft}
        setGoLeft={setGoLeft}
        goRight={goRight}
        setGoRight={setGoRight}
        customDotMargin={4}
        customDotWidth={10}
      />
    </div>
  );
}
