export const FETCH_CHFAR_OFFER = "flightChfar/FETCH_CHFAR_OFFER";
export type FETCH_CHFAR_OFFER = typeof FETCH_CHFAR_OFFER;

export const SET_CHFAR_OFFER = "flightChfar/SET_CHFAR_OFFER";
export type SET_CHFAR_OFFER = typeof SET_CHFAR_OFFER;

export const SET_CHFAR_OFFER_FAILED = "flightChfar/SET_CHFAR_OFFER_FAILED";
export type SET_CHFAR_OFFER_FAILED = typeof SET_CHFAR_OFFER_FAILED;

export const SET_SELECTED_CHFAR_OFFER = "flightChfar/SET_SELECTED_CHFAR_OFFER";
export type SET_SELECTED_CHFAR_OFFER = typeof SET_SELECTED_CHFAR_OFFER;
