import { useSelector } from "react-redux";
import { getCheckInPolicies } from "../../reducer/selectors";
import { HotelDetailsSection } from "../HotelDetailsSection";
import { TextWithReadMore } from "../TextWtihReadMore";

export function CheckInPolicies() {
  const checkInPolicies = useSelector(getCheckInPolicies);

  if (!checkInPolicies || checkInPolicies.length === 0) {
    return null;
  }

  return (
    <div>
      {checkInPolicies.map(({ title, text }) => {
        if (!text) {
          return null;
        }

        return (
          <HotelDetailsSection
            key={title}
            title={title}
            className="policy-section"
          >
            <TextWithReadMore text={text} modalTitle={title} />
          </HotelDetailsSection>
        );
      })}
    </div>
  );
}
