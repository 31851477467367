import { Person } from "@b2bportal/air-booking-api";
import { FiatPrice } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ActionButton,
  DesktopPopupModal,
  GenericModalContent,
  Icon,
  IconName,
  ImportantInfoList,
} from "@hopper-b2b/ui";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  FlightShopStep,
  flightShopProgressSelector,
} from "../../../shop/reducer";
import { getConfirmPageCopy } from "../../reducer/selectors";
import { submitExchangeReq } from "../../reducer/shop";
import { getPassengers, getSummaryItems } from "../../selectors";
import CheckoutBreakdown from "./CheckoutBreakdown";
import NewTripSummary from "./NewTripSummary";

import "./styles.scss";

export interface IConfirmExchangeReqProps {
  backToTrips: () => void;
}

const defaultProps: Partial<IConfirmExchangeReqProps> = {};

const ConfirmExchangeReqStep = (
  props: IConfirmExchangeReqProps
): JSX.Element => {
  const { backToTrips } = props;
  const dispatch = useDispatch();
  const { t } = useI18nContext();
  const confirmPageCopy = useSelector(getConfirmPageCopy);
  const passengers = useSelector(getPassengers);
  const shopStep = useSelector(flightShopProgressSelector);
  const summaryItems = useSelector(getSummaryItems);
  const [modalOpen, setModalOpen] = useState(false);

  const passengersList = useMemo(() => {
    const { alone, withLapInfants } = passengers;
    const allPassengers: Person[] = alone.map((a) => a.person);

    for (let i = 0; i < withLapInfants.length; i += 1) {
      const { adult, infant } = withLapInfants[i];

      allPassengers.push(adult.person, infant.person);
    }

    return allPassengers;
  }, [passengers]);

  const totalCostFiat = useMemo<FiatPrice>(() => {
    const numPax = passengersList.length || 1;
    let currencyCode = "";
    let currencySymbol = "";
    const total = summaryItems.reduce((acc, item) => {
      ({ currencyCode, currencySymbol } = item.fiatPrice);

      return acc + item.fiatPrice.value;
    }, 0);
    const totalPerPax = total / numPax;

    return { currencyCode, currencySymbol, value: totalPerPax };
  }, [passengersList, summaryItems]);

  return (
    <Box className="confirm-exchange-req-container">
      <Box className="header-container">
        {confirmPageCopy?.title ? (
          <Typography className="confirm-header-title" variant="h3">
            {confirmPageCopy.title}
          </Typography>
        ) : (
          <Skeleton />
        )}
        {confirmPageCopy?.body?.map((subtitle: string) => (
          <Typography
            className="confirm-header-subtitle"
            dangerouslySetInnerHTML={{ __html: subtitle }}
            key={subtitle}
          />
        )) ?? <Skeleton />}
      </Box>
      <NewTripSummary />
      <CheckoutBreakdown
        isSubmitting={shopStep === FlightShopStep.ExchangeSubmitting}
        onSubmit={() => dispatch(submitExchangeReq(totalCostFiat))}
      />
      <ImportantInfoList
        ordered
        infoItems={confirmPageCopy.importantInfo}
        title={t("")}
      />
      <DesktopPopupModal
        className="submit-request-response-modal"
        invisibleBackdrop={false}
        onClose={backToTrips}
        open={modalOpen}
      >
        {shopStep === FlightShopStep.ExchangeReqSuccess && (
          <GenericModalContent
            actions={
              <ActionButton
                className="done-btn"
                message={t("")}
                onClick={backToTrips}
              />
            }
            image={<Icon className="success-icon" name={IconName.Checked} />}
            subtitle={confirmPageCopy.informativeSection.body.join(". ")}
            title={confirmPageCopy.informativeSection.title}
          />
        )}
        {shopStep === FlightShopStep.ExchangeReqFailure && (
          <GenericModalContent
            actions={
              <ActionButton
                className="contact-support-btn"
                message={t("")}
                onClick={() => {
                  setModalOpen(false);
                  dispatch(submitExchangeReq(totalCostFiat));
                }}
              />
            }
            image={<Icon className="failure-icon" name={IconName.ErrorState} />}
            subtitle={t("")}
            title={t("")}
          />
        )}
      </DesktopPopupModal>
    </Box>
  );
};

ConfirmExchangeReqStep.defaultProps = defaultProps;

export default ConfirmExchangeReqStep;
