import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../../reducers/types";
import {
  setStopsOption,
  setFareclassOptionFilter,
  setOutboundDepartureTimeRange,
  setOutboundArrivalTimeRange,
  setReturnDepartureTimeRange,
  setReturnArrivalTimeRange,
} from "../../actions/actions";
import {
  getStopsOption,
  getFareclassOptionFilter,
  getOutboundDepartureTimeRange,
  getOutboundArrivalTimeRange,
  getReturnDepartureTimeRange,
  getReturnArrivalTimeRange,
  getActiveFiltersCount,
} from "../../reducer/index";
import { setRerunPrediction } from "../../../shop/actions/actions";
import { FlightSearchFilter } from "./component";

const mapStateToProps = (state: IStoreState) => {
  const currentStopsOption = getStopsOption(state);
  const currentFareclassOptionFilter = getFareclassOptionFilter(state);
  const currentOutboundDepartureTimeRange =
    getOutboundDepartureTimeRange(state);
  const currentOutboundArrivalTimeRange = getOutboundArrivalTimeRange(state);
  const currentReturnDepartureTimeRange = getReturnDepartureTimeRange(state);
  const currentReturnArrivalTimeRange = getReturnArrivalTimeRange(state);
  const readyToReset = getActiveFiltersCount(state) > 0;
  return {
    currentStopsOption,
    currentFareclassOptionFilter,
    currentOutboundDepartureTimeRange,
    currentOutboundArrivalTimeRange,
    currentReturnDepartureTimeRange,
    currentReturnArrivalTimeRange,
    readyToReset,
  };
};

const mapDispatchToProps = {
  setCurrentStopsOption: setStopsOption,
  setCurrentFareclassOptionFilter: setFareclassOptionFilter,
  setCurrentOutboundDepartureTimeRange: setOutboundDepartureTimeRange,
  setCurrentOutboundArrivalTimeRange: setOutboundArrivalTimeRange,
  setCurrentReturnDepartureTimeRange: setReturnDepartureTimeRange,
  setCurrentReturnArrivalTimeRange: setReturnArrivalTimeRange,
  setRerunPrediction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightSearchFilterConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightSearchFilter = connector(FlightSearchFilter);
