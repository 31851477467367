import {
  AirExchangeEligibilityQuoteResponse,
  AirExchangeEligibilityQuoteResponseEnum,
  AirExchangeEligibilityQuoteSuccess,
} from "@b2bportal/air-exchange-api";
import { axiosInstance } from "@hopper-b2b/api";

import Logger from "../../../helpers/Logger";
import { fetchExchangePolicyPath } from "../paths";

export default async (
  reservationId: string
): Promise<AirExchangeEligibilityQuoteSuccess> => {
  try {
    const res = await axiosInstance.post<AirExchangeEligibilityQuoteResponse>(
      fetchExchangePolicyPath,
      { reservationId }
    );

    if (
      res.data.AirExchangeEligibilityQuoteResponse ===
      AirExchangeEligibilityQuoteResponseEnum.AirExchangeEligibilityQuoteSuccess
    ) {
      return res.data;
    }

    throw res;
  } catch (err) {
    Logger.debug(err);

    throw err;
  }
};
