import { IPassengerCounts } from "@hopper-b2b/types";

export const passengerSelectValidation = (
  counts: IPassengerCounts,
  maximumCount: number,
  maximumChildren: number,
  unlimited = false
) => {
  const { adultsCount, childrenCount, infantsInSeatCount, infantsOnLapCount } =
    counts;
  const maximumReached =
    !unlimited &&
    adultsCount + childrenCount + infantsInSeatCount >= maximumCount;
  const maximumExceeded =
    !unlimited &&
    adultsCount + childrenCount + infantsInSeatCount > maximumCount;

  const totalChildrenCount =
    childrenCount + infantsInSeatCount + infantsOnLapCount;

  const maximumChildrenExceeded =
    totalChildrenCount > maximumChildren || maximumReached;

  const moreInfantsThanAdults =
    infantsOnLapCount + infantsInSeatCount > adultsCount;

  const valid =
    !maximumExceeded && !maximumChildrenExceeded && !moreInfantsThanAdults;

  return valid;
};
