import { useI18nContext } from "@hopper-b2b/i18n";
import { TripCategory } from "@hopper-b2b/types";
import { HeaderPayload } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { getTripCategory } from "../../../search/reducer";
import {
  FlightShopStep,
  flightShopProgressSelector,
} from "../../../shop/reducer";

import "./styles.scss";

export interface IExchangeHeaderCenterProps {
  setHeader: (payload: HeaderPayload) => void;
}

const ExchangeHeaderCenter = (
  props: IExchangeHeaderCenterProps
): JSX.Element => {
  const { setHeader } = props;
  const { t } = useI18nContext();
  const shopStep = useSelector(flightShopProgressSelector);
  const tripType = useSelector(getTripCategory);
  const isOneWay = tripType === TripCategory.ONE_WAY;

  const centerLabel = useMemo(() => {
    let label = "";

    switch (shopStep) {
      case FlightShopStep.ExchangeLocationsPicker:
        label = t("exchangeable.pageTitles.landing", {
          flightType: isOneWay ? "one-way" : "round-trip",
        });
        break;
      case FlightShopStep.ExchangeDatesPicker:
        label = t("exchangeable.pageTitles.selectDate");
        break;
      case FlightShopStep.ExchangeSlicePicker:
        label = t("exchangeable.pageTitles.slicePicker");
        break;
      case FlightShopStep.ChooseDeparture:
        label = t("exchangeable.pageTitles.selectOutbound");
        break;
      case FlightShopStep.ChooseReturn:
        label = t("exchangeable.pageTitles.selectReturn");
        break;
      case FlightShopStep.ReviewItinerary:
        label = t("exchangeable.pageTitles.reviewItinerary");
        break;
      case FlightShopStep.ExchangePriceBreakdown:
        label = t("exchangeable.pageTitles.priceBreakdown");
        break;
      case FlightShopStep.ExchangeError:
        label = t("exchangeable.pageTitles.error");
        break;
      case FlightShopStep.ExchangeSubmitted:
        label = t("exchangeable.pageTitles.success");
        break;
      default:
        label = t("exchangeable.pageTitles.default");
    }

    if (label) {
      setHeader({ title: label });
    }

    return label;
  }, [isOneWay, setHeader, shopStep, t]);

  return (
    <Box className="exchange-header-center-section">
      <Typography className="exchange-step-title" variant="body1">
        {centerLabel}
      </Typography>
    </Box>
  );
};

export default ExchangeHeaderCenter;
