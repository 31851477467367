import { useMemo } from "react";
import { useAccounts } from "../hooks/useAccounts";
import { AwardsEnum } from "@b2bportal/commbank-au-accounts-api";

export const useBestTravelCreditsPercentage = () => {
  const { accounts = [] } = useAccounts();
  return useMemo(
    () =>
      accounts
        .map(
          (account) =>
            (account.eligibleOffers?.[0]?.awards.Awards ===
              AwardsEnum.TravelCredits &&
              account.eligibleOffers?.[0]?.awards?.percentage) ||
            0
        )
        .sort((a, b) => b - a)[0],
    [accounts]
  );
};
