import {
  airChfarApi,
  GetAirChfarOfferRequest,
  GetAirChfarOfferResponse,
} from "@b2bportal/air-chfar-api";
import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import axios from "axios";
import { trackEvent } from "../../tracking/trackEvent";

export const fetchChfarOffer = async (
  getAirChfarOfferRequest: GetAirChfarOfferRequest,
  apiConfig?: IApiConfig
): Promise<GetAirChfarOfferResponse> => {
  try {
    const response = await airChfarApi(axios).apiV0ChfarAirOfferGetPost(
      getAirChfarOfferRequest
    );
    return response.data;
  } catch (error) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      apiConfig
    );
    throw error;
  }
};
