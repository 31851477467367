import { useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Box } from "@material-ui/core";
import queryStringParser from "query-string";
import dayjs from "dayjs";

import {
  PriceFreezeView,
  PriceFreezeStatusEnum,
  TripDetails,
  PRICE_FREEZE_ID_QUERY_PARAM,
  VIEWED_FROZEN_PRICES,
  ViewedFrozenPricesTrips,
  ItineraryEnum,
} from "@hopper-b2b/types";
import { UberMyTripsMobileCard } from "@hopper-b2b/ui";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Airline, Airport } from "@b2bportal/price-freeze-api";
import { customFormatDateTime } from "@hopper-b2b/utilities";
import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";

import { ClientContext } from "../../../../App";
import { PATH_PRICE_FREEZE_OVERVIEW } from "../../../../utils/paths";
import * as textConstants from "./constants";
import { PriceFreezeListConnectorProps } from "./container";
import "./styles.scss";

export interface IPriceFreezeListProps
  extends PriceFreezeListConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
}

export const getPiceFreezeCardDetails = (
  tripDetails: TripDetails,
  formatDate: (date: string) => string,
  airports: { [key: string]: Airport | undefined },
  airlines: { [key: string]: Airline | undefined }
) => {
  const departureSlice = tripDetails.slices[0];
  const returnSlice = tripDetails.slices[1];
  const originCode = departureSlice.originCode;
  const destinationCode = departureSlice.destinationCode;
  const airlineCode = departureSlice.segmentDetails[0].airlineCode;
  const airlineName =
    airlines[departureSlice.segmentDetails[0].marketingAirline.code]
      ?.displayName ?? airlineCode;
  return {
    title: `${airports[originCode]?.cityName || originCode} to ${
      airports[destinationCode]?.cityName || destinationCode
    }`,
    origin: airports[originCode]?.cityName,
    destination: airports[destinationCode]?.cityName,
    startDate: formatDate(departureSlice.departureTime),
    endDate: returnSlice ? formatDate(returnSlice.departureTime) : "",
    subtitle: returnSlice
      ? // TODO: Add translations
        textConstants.ROUND_TRIP
      : textConstants.ONE_WAY_TRIP,
    airlineCode,
    airlineName,
    flightNumber:
      airlineCode +
        " " +
        departureSlice.segmentDetails[0].flightNumber.toString() ?? "",
  };
};

export function PriceFreezeList({
  priceFreezes,
  airlines,
  airports,
  history,
}: IPriceFreezeListProps) {
  const { t, language: locale, brand } = useI18nContext();
  const priceFreezeIcon = useContext(ClientContext)?.assets?.priceFreeze;
  useEffect(() => {
    if (priceFreezes.length) {
      const activePF = priceFreezes.filter(
        (pf) => pf.status.Status === PriceFreezeStatusEnum.IsActive
      ).length;
      const expiredPF = priceFreezes.length - activePF;
      trackEvent({
        eventName: VIEWED_FROZEN_PRICES,
        properties: {
          price_freeze_active: activePF,
          price_freeze_expired: expiredPF,
        } as ViewedFrozenPricesTrips,
      });
    }
  }, [priceFreezes]);

  const formatExpiredDate = (date: string): string => {
    const expired = dayjs().isAfter(date);
    const formattedDate = customFormatDateTime(
      new Date(date),
      locale,
      DateTimeFormatStyle.ShortMonthDayShortWeekday,
      brand?.customDateTimeFormats?.tripsList
    );
    const formattedTime = formatDateTime(
      new Date(date),
      locale,
      DateTimeFormatStyle.ShortTime
    );

    return expired
      ? t("priceFreezeExpiredOn", { date: formattedDate, time: formattedTime })
      : t("priceFreezeExpiresOn", { date: formattedDate, time: formattedTime });
  };

  const onViewPriceFreezeClick = (id: string) => {
    history.push({
      pathname: PATH_PRICE_FREEZE_OVERVIEW,
      state: {
        fromTrips: true,
      },
      search: queryStringParser.stringify({
        [PRICE_FREEZE_ID_QUERY_PARAM]: id,
      }),
    });
  };

  return (
    <Box className="price-freeze-list">
      {priceFreezes.map((pf: PriceFreezeView) => {
        const tripDetails = getPiceFreezeCardDetails(
          pf.tripDetails,
          (date: string) =>
            customFormatDateTime(
              new Date(date),
              locale,
              DateTimeFormatStyle.ShortMonthDayShortWeekday,
              brand?.customDateTimeFormats?.tripsList
            ),
          airports,
          airlines
        );
        const isExpired = pf.status.Status === PriceFreezeStatusEnum.IsExpired;

        return (
          <UberMyTripsMobileCard
            type={ItineraryEnum.AirPriceFreeze}
            onClick={() => onViewPriceFreezeClick(pf.priceFreeze.id)}
            key={pf.priceFreeze.id}
            priceFreezeIcon={priceFreezeIcon}
            expiryDate={formatExpiredDate(pf.priceFreeze.expiresAt)}
            expired={isExpired}
            {...tripDetails}
          />
        );
      })}
    </Box>
  );
}
