import { ChatPropertiesType } from "@b2bportal/chat-api";

interface KustomerSettings {
  enabled: boolean;
  apiKey?: string;
}

interface KustomerOptions {
  hideChatIcon?: boolean; // Optional
  brandId?: string; // Optional
  assistantId?: string; // Optional
  scheduleId?: string; // Optional
  lang?: string; // Optional
  hideHistory?: boolean; // Optional, defaults to false
  hideNewConversationButtonOnHistory?: boolean; // Optional
  hideNewConversationButtonOnEnd?: boolean; // Optional
  allowZoom?: boolean; // Optional
  zIndex?: number; // Optional, defaults to 2147483647
  chatIconSize?: number | string | { height: number; width: number }; // Optional, defaults to 50
  chatIconPosition?:
    | "right"
    | "left"
    | { alignment: "right" | "left"; verticalPadding: number; width: number }; // Optional
  preferredView?: "chat" | "kb"; // Optional, defaults to "kb"
  showTitleNotifications?: boolean; // Optional
  showBrowserNotifications?: boolean; // Optional, defaults to true
  hideArticleOpenInNewTab?: boolean; // Optional, defaults to false
}

interface KustomerConversation {
  conversationId: string;
}

interface KustomerLoginCallbackResponse {
  identified: boolean;
  customerId: string;
  email?: string;
  externalId?: string;
}

interface KustomerCreateConversationOptions {
  message?: string;
  assistantId?: string;
  customAttributes?: object; // These should be provided by the BE in almost all cases
}

interface KustomerCreateConversationCallbackResponse {
  conversationId: string;
  createdAt: string;
  ended: boolean;
  isInAssistantMode: boolean;
}

interface KustomerDescribeConversationOptions {
  conversationId: string;
  customAttributes: object;
}

interface KustomerOnConversationCreateResponse {
  conversationId: string;
}

interface KustomerOnUnreadResponse {
  total: number;
}

interface KustomerOpenConversationByIdOptions {
  conversationId: string;
}

interface Kustomer {
  start(options: KustomerOptions, callback: () => void): void;
  addListener(
    event: string,
    callback: (callbackResponse: object, error?: Error) => void
  ): void;
  login(
    options: { jwtToken: string },
    callback: (response: KustomerLoginCallbackResponse, error?: Error) => void
  ): void;
  isLoggedIn(
    options: { externalId: string },
    callback: (isLoggedIn: boolean, error?: Error) => void
  ): void;
  createConversation(
    options: KustomerCreateConversationOptions,
    callback?: (
      response: KustomerCreateConversationCallbackResponse,
      error?: Error
    ) => void
  ): void;
  describeConversation(
    options: KustomerDescribeConversationOptions,
    callback?: (
      response: KustomerDescribeConversationOptions,
      error?: Error
    ) => void
  ): void;
  open(): void;
  openConversationById(options: KustomerOpenConversationByIdOptions): void;
  getOpenConversations(
    callback: (conversations: KustomerConversation[], error?: Error) => void
  ): void;
}

declare global {
  // Extend the Window interface to include Kustomer
  interface Window {
    Kustomer: Kustomer;
  }
}

/**
 * Fetches the first open conversation from Kustomer. This function returns a promise that resolves
 * with the open conversation if there is one, or null if there are no open conversations.
 */
function getOpenConversation(): Promise<KustomerConversation | null> {
  return new Promise((resolve, reject) => {
    window.Kustomer.getOpenConversations((conversations, error) => {
      if (error) {
        reject(null);
      } else {
        if (conversations.length > 0) {
          resolve(conversations[0]);
        } else {
          resolve(null);
        }
      }
    });
  });
}

/**
 * Initializes the Kustomer chat widget by adding the Kustomer script to the DOM. This function
 * will only initialize Kustomer if it is enabled and an API key is provided.
 */
export function initializeKustomerChat(
  settings: KustomerSettings,
  onLoaded: () => void
) {
  // Only initialize Kustomer if it is enabled and an API key is provided
  if (settings.enabled && settings.apiKey) {
    window.addEventListener("kustomerLoaded", onLoaded);

    const script = document.createElement("script");
    script.src = "https://cdn.kustomerapp.com/chat-web/widget.js";
    script.setAttribute("data-kustomer-api-key", settings.apiKey);
    script.setAttribute("async", "true");
    window.document.body.appendChild(script);
  }
}

/**
 * Starts the Kustomer chat widget with the provided options. This function should be called after
 * the Kustomer script has been loaded and the `kustomerLoaded` event has been triggered.
 */
export function startKustomerChat(
  options: KustomerOptions,
  onStarted: () => void
) {
  window.Kustomer.start(options, () => {
    onStarted();

    // Initial setup of any listeners
    window.Kustomer.addListener("onConversationCreate", onConversationCreate);
  });
}

/**
 * Logs the user into Kustomer using the provided external (Hopper) ID and JWT token. This function will
 * check if the user is already logged in before attempting to log them in.
 *
 * @param externalId - The external (Hopper) ID of the user.
 * @param jwtToken - The JWT token to authenticate the user.
 */
export function loginKustomer(externalId: string, jwtToken: string) {
  // Check if the user is already logged in
  window.Kustomer.isLoggedIn({ externalId }, (isLoggedIn) => {
    if (isLoggedIn) {
      console.log("User is already logged in to Kustomer");
      return;
    }

    window.Kustomer.login({ jwtToken }, (response, error) => {
      if (error) {
        console.log("Kustomer login error:", error);
      } else {
        console.log("Kustomer login response:", response);
      }
    });
  });
}

/**
 * Creates a general support conversation using the Kustomer SDK. This function will launch
 * the Kustomer chat widget with no specific product type or itinerary ID. This should only be
 * used for general support conversations. If you need to create a conversation for a specific
 * itinerary/booking/product, use the `createSupportConversationForProduct` function.
 *
 * Note: If there is already an open conversation, this function will not create a new one, and
 * will instead open the existing conversation.
 *
 * @param tenant - The tenant of the current portal.
 */
export async function createGeneralSupportConversation(tenant: string) {
  const openConversation = await getOpenConversation();

  if (openConversation) {
    // If there are any open conversations, get the conversation ID of the first one and open it
    // rather than creating a new conversation
    window.Kustomer.openConversationById({
      conversationId: openConversation.conversationId,
    });
  } else {
    // When we create a general support conversation, we don't have any itinerary-specific
    // chat properties, but we do want to set some basic properties for automations to run.
    currentChatProperties = {
      funnelTypeStr: "general_chat",
      cloudPartnerStr: tenant,
    };
    window.Kustomer.createConversation({});
  }
}

/**
 * Creates a support conversation for a product. This function fetches the chat properties
 * and then launches the Kustomer chat widget.
 *
 * @param itineraryId - The ID of the itinerary.
 * @param productType - The type of the product.
 * @param requestType - This is used by CS to provide additional context about the user's request.
 * @param fetchChatProperties - A function that fetches chat properties. This should be a network
 * call to the BFF that will call through to StatenIsland to fetch the chat properties.
 */
export async function createSupportConversationForProduct(
  itineraryId: string,
  productType: ChatPropertiesType,
  requestType: string,
  fetchChatProperties: (
    productType: ChatPropertiesType,
    itineraryId: string
  ) => Promise<object>
) {
  const openConversation = await getOpenConversation();
  if (openConversation) {
    // If there are any open conversations, get the conversation ID of the first one and open it
    // rather than creating a new conversation
    window.Kustomer.openConversationById({
      conversationId: openConversation.conversationId,
    });
  } else {
    const fetchedChatProperties = await fetchChatProperties(
      productType,
      itineraryId
    );

    // Set funnel type and request type based on the product type.
    // Different products have different request type property names.
    switch (productType) {
      case ChatPropertiesType.Air:
        fetchedChatProperties["funnelTypeStr"] = "air_chat";
        fetchedChatProperties["requestTypeStr"] = requestType;
        break;
      case ChatPropertiesType.Hotel:
        fetchedChatProperties["funnelTypeStr"] = "hotel_chat";
        fetchedChatProperties["hotelUserRequestTypeStr"] = requestType;
        break;
      case ChatPropertiesType.Ground:
        fetchedChatProperties["funnelTypeStr"] = "ground_chat";
        fetchedChatProperties["groundUserRequestTypeStr"] = requestType;
        break;
      default:
        fetchedChatProperties["funnelTypeStr"] = "general_chat";
        break;
    }

    currentChatProperties = fetchedChatProperties;
    window.Kustomer.createConversation({});
  }
}

/**
 * Opens the Kustomer chat window to the list of the user's conversations.
 */
export function openKustomerChat() {
  window.Kustomer.open();
}

/**
 * Listens for unread count updates in the Kustomer chat widget. This function will call the
 * provided callback function whenever the unread count is updated.
 *
 * @param onUnreadCountUpdated - The callback function that will be called when the unread count
 * is updated.
 */
export function listenForUnreadCountUpdates(
  onUnreadCountUpdated: (count: number) => void
) {
  window.Kustomer.addListener(
    "onUnread",
    (unreadCount: KustomerOnUnreadResponse, error?: Error) => {
      onUnreadCountUpdated(unreadCount.total);
    }
  );
}

/**
 * Handles the response of a conversation creation. When we create a conversation for a specific
 * product type, we store the custom chat properties in the `currentChatProperties` variable.
 * Then, when the user actually types a message in the chat, this callback will be triggered
 * and we can attach the `currentChatProperties` to that conversation.
 *
 * NOTE: This is a workaround for a limitation of the Kustomer SDK that does not allow us to pass
 * custom chat properties when creating a conversation unless we also provide an initial message.
 *
 * @param response - The response object containing the conversation details.
 * @param error - An optional error object if there was an error during conversation creation.
 */
function onConversationCreate(response: object, error?: Error) {
  if (error) {
    console.log("Kustomer conversation creation error:", error);
    return;
  }

  if (currentChatProperties === undefined) {
    console.log("Kustomer conversation created without chat properties");
    return;
  }

  const conversationResponse = response as KustomerOnConversationCreateResponse;

  window.Kustomer.describeConversation({
    conversationId: conversationResponse.conversationId,
    customAttributes: currentChatProperties,
  });

  currentChatProperties = undefined;
}

// This variable will store the chat properties for the current conversation. We need to store
// these properties in a variable because the Kustomer SDK does not allow us to pass custom chat
// properties when creating a conversation unless we also provide an initial message. This is a
// workaround to that limitation.
let currentChatProperties = undefined;
