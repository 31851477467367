import {
  ApacActionLink,
  ApacIcon,
  ApacIconComponent,
  ApacIconName,
  PassengerCountPicker,
} from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { GuestsSelection, LodgingShopTrackingEvents } from "@hopper-b2b/types";
import { ActionButton, DesktopPopupModal } from "@hopper-b2b/ui";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useState } from "react";
import {
  APAC_MAX_LODGING_CHILDREN,
  APAC_MAX_LODGING_TRAVELLERS,
  APAC_MAX_ROOMS_COUNT,
} from "../HotelSearchControl";
import styles from "./GuestPicker.module.scss";
import { ProductType, useTrackEvents } from "../../../util";

const editGuestButtonLabel = (guests, rooms: number, t) => {
  if (guests?.children.length > 0) {
    return t("commBank.hotelAvailability.header.travelersDescriptionNoRoom", {
      adults: t("commBank.hotelAvailability.header.adults", {
        count: guests?.adults,
      }),
      children: t("commBank.hotelAvailability.header.children", {
        count: guests?.children.length,
      }),
    });
  } else {
    return t("commBank.hotelAvailability.header.adults", {
      count: guests?.adults,
    });
  }
};

export const GuestPicker = ({
  className,
  initialGuestCount,
  updateGuestCount,
  showRooms = false,
  useDialog = true,
  useGenericButton = false,
  disabled,
}: {
  className?: string;
  initialGuestCount: GuestsSelection;
  updateGuestCount: (count: GuestsSelection) => void;
  showRooms?: boolean;
  useDialog?: boolean;
  disabled?: boolean;
  useGenericButton?: boolean;
}) => {
  const { t } = useI18nContext();

  const [showGuestCountPicker, setShowGuestCountPicker] =
    useState<boolean>(false);
  const [guestCount, setGuestCount] =
    useState<GuestsSelection>(initialGuestCount);

  const handleClosePassengerPicker = useCallback(() => {
    setShowGuestCountPicker(false);
  }, []);

  const trackEvent = useTrackEvents();
  const handleGuestCountsChange = useCallback(
    (counts: GuestsSelection) => {
      const nextGuestCount = {
        adults: counts.adults,
        children: counts.children,
        rooms: counts.rooms,
      };
      setGuestCount(nextGuestCount);
      updateGuestCount(nextGuestCount);
      handleClosePassengerPicker();

      trackEvent(
        LodgingShopTrackingEvents.hotel_tapped_apply_guests,
        ProductType.Hotel,
        {
          adults_count: counts.adults,
          children_count: counts.children.length,
          rooms_count: counts.rooms,
        }
      );
    },
    [handleClosePassengerPicker, updateGuestCount]
  );

  const passengerPicker = (
    <PassengerCountPicker
      className={styles.picker}
      showRooms={showRooms}
      minimumCount={1}
      onClickApplyGuest={handleGuestCountsChange}
      setPassengerCounts={(counts: GuestsSelection) => {
        setGuestCount(counts);
        trackEvent(
          LodgingShopTrackingEvents.hotel_tapped_guests,
          ProductType.Hotel,
          {
            adults_count: counts.adults,
            children_count: counts.children.length,
            rooms_count: counts.rooms,
          }
        );
      }}
      counts={guestCount}
      onClose={handleClosePassengerPicker}
      hideChildrenSubtitle
      showApplyButton
      warningMessage={null}
      titles={{
        adultTitle: t("passengerCount.adultTitle"),
        adultSubtitle: undefined,
        childrenTitle: t("passengerCount.childrenTitle"),
        childrenSubtitle: t("commBank.guestCount.childrenSubtitle"),
        roomsTitle: t("passengerCount.roomsTitle"),
        roomsSubtitle: t("commBank.guestCount.roomsSubtitle"),
        travellersWarning: t("passengerCount.travellersWarning", {
          maxTravellers: APAC_MAX_LODGING_TRAVELLERS,
        }),
        maxPassengerCount: t("passengerCount.errors.maxPassengerCount", {
          maxTravellers: APAC_MAX_LODGING_TRAVELLERS,
        }),
      }}
      maximumCount={APAC_MAX_LODGING_TRAVELLERS}
      maximumChildren={APAC_MAX_LODGING_CHILDREN}
      maximumRooms={APAC_MAX_ROOMS_COUNT}
    />
  );

  return (
    <>
      {!useGenericButton && (
        <div
          className={clsx(styles["passenger-edit-button-container"], className)}
          onClick={() => !disabled && setShowGuestCountPicker(true)}
        >
          <ApacActionLink
            message={editGuestButtonLabel(guestCount, guestCount.rooms, t)}
            onClick={() => {
              //use parent onclick event
            }}
            disabled={disabled}
          />
          <ApacIcon
            className={styles.icon}
            name={ApacIconName.EditPen}
            disabled={disabled}
          />
        </div>
      )}
      {useGenericButton && !showGuestCountPicker && (
        <ActionButton
          className={clsx(className, "generic-add-traveler-button")}
          onClick={() => setShowGuestCountPicker(true)}
          variant="contained"
          message={t("commBank.hotelSearch.addTravelerButton")}
        ></ActionButton>
      )}
      {useDialog ? (
        <DesktopPopupModal
          className="guest-picker-desktop-modal"
          open={showGuestCountPicker}
          onClose={handleClosePassengerPicker}
          invisibleBackdrop={false}
          closeButtonClassName={styles.popupCloseButton}
        >
          {passengerPicker}
        </DesktopPopupModal>
      ) : showGuestCountPicker ? (
        <Box className="passenger-count-picker-wrapper">{passengerPicker}</Box>
      ) : null}
    </>
  );
};
