import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";

import Logger from "../../../helpers/Logger";
import { submitFlightExchangeRequestPath } from "../paths";

const submitFlightExchangeRequest = async (req: unknown) => {
  try {
    const res = await axiosInstance.post(submitFlightExchangeRequestPath, req);

    return res.data;
  } catch (err) {
    Logger.debug(err);

    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
  }
};

export default submitFlightExchangeRequest;
