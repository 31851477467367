import { PriceFreezeFlightPaymentFinalizeResult } from "@b2bportal/price-freeze-api";
import { Product } from "@b2bportal/purchase-api";
import { PassengerTypes } from "@hopper-b2b/types";
import { AirPriceFreezeMachineStateWithoutValue } from "../types";

export const getFinalizedPriceFreezeResult = (
  state: AirPriceFreezeMachineStateWithoutValue
) => {
  const maybePriceFreeze = state.context.cartFulfill.fulfilledProducts?.find(
    (p) => p.type === Product.AirPriceFreeze
  );
  return maybePriceFreeze
    ? (maybePriceFreeze.value as PriceFreezeFlightPaymentFinalizeResult)
    : undefined;
};
export const getFinalizedPriceFreeze = (
  state: AirPriceFreezeMachineStateWithoutValue
) => {
  const maybePriceFreeze = state.context.cartFulfill.fulfilledProducts?.find(
    (p) => p.type === Product.AirPriceFreeze
  );
  return maybePriceFreeze
    ? (maybePriceFreeze.value as PriceFreezeFlightPaymentFinalizeResult)
        .priceFreeze
    : undefined;
};

export const getFinalizedPriceFreezeId = (
  state: AirPriceFreezeMachineStateWithoutValue
) => {
  return getFinalizedPriceFreeze(state)?.id;
};

export const getFinalizedFrozenFlightPricePerPax = (
  state: AirPriceFreezeMachineStateWithoutValue
) => {
  const priceFreezeResult = getFinalizedPriceFreezeResult(state);
  const paxPricing = priceFreezeResult?.frozenFare.paxPricings;

  return paxPricing?.find((p) => p.paxType === PassengerTypes.Adult)?.pricing
    .totalAmount;
};

export const getFinalizedPriceFreezePerPax = (
  state: AirPriceFreezeMachineStateWithoutValue
) => {
  const priceFreezeResult = getFinalizedPriceFreezeResult(state);
  return priceFreezeResult?.priceFreeze.offer.perPaxAmount;
};

export const getFinalizedPriceFreezeTotalAmount = (
  state: AirPriceFreezeMachineStateWithoutValue
) => {
  const priceFreezeResult = getFinalizedPriceFreezeResult(state);
  return priceFreezeResult?.priceFreeze.offer.totalAmount;
};

export const getFinalizedPriceFreezeExpiration = (
  state: AirPriceFreezeMachineStateWithoutValue
) => {
  const priceFreezeResult = getFinalizedPriceFreezeResult(state);
  return priceFreezeResult?.priceFreeze.expiresAt;
};

export const getFinalizedPriceFreezeTtl = (
  state: AirPriceFreezeMachineStateWithoutValue
) => {
  const priceFreezeResult = getFinalizedPriceFreezeResult(state);
  return priceFreezeResult?.priceFreeze.offer.timeToLive;
};

export const getFinalizedTripDetails = (
  state: AirPriceFreezeMachineStateWithoutValue
) => {
  const priceFreezeResult = getFinalizedPriceFreezeResult(state);
  return priceFreezeResult?.frozenFlight;
};

export const getFinalizedFareDetails = (
  state: AirPriceFreezeMachineStateWithoutValue
) => {
  const priceFreezeResult = getFinalizedPriceFreezeResult(state);
  return priceFreezeResult?.frozenFlight.fareDetails;
};
