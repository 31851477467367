import { Box, CircularProgress } from "@material-ui/core";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";

import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import {
  AirExchangeEligibilityQuoteSuccess,
  ContactAirline,
  ExchangeScenarioEnum,
  TicketedVoid,
  VoidScenarioEnum,
} from "@b2bportal/air-exchange-api";
import {
  fetchFlightExchangePolicy,
  getBookingSupportId,
} from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  BookingType,
  ClientName,
  ExchangeModalState,
  FTCScenario,
  IOpenModal,
  ItineraryEnum,
  ItineraryWithType,
  MyTripsModalTypes,
} from "@hopper-b2b/types";
import { GenericModalContent, LostConnectionBunny } from "@hopper-b2b/ui";
import { ActionButton } from "@hopper-b2b/ui/core";
import { getEnvVariables, useEnableAirExchange } from "@hopper-b2b/utilities";

// import { addFlightType } from "../../../FlightCard/helpers";

import "./styles.scss";
import { ContactSupportModalContent } from "../ApacContactSupportModalContent/ContactSupportModalContent";
import { UberContactSupport } from "@hopper-b2b/self-serve";

export interface IExchangeFlightModalContentProps {
  setOpenModal: (newModal: IOpenModal) => void;
  flight: BookedFlightItineraryWithDepartureTime;
}

const defaultProps: Partial<IExchangeFlightModalContentProps> = {};

const ExchangeFlightModalContent = (
  props: IExchangeFlightModalContentProps
): JSX.Element => {
  const { flight, setOpenModal } = props;
  const enableAirExchange = useEnableAirExchange();
  const history = useHistory();
  const { t } = useI18nContext();

  const [modalState, setModalState] = useState(
    ExchangeModalState.LoadingOrProcessing
  );
  const [policy, setPolicy] = useState<AirExchangeEligibilityQuoteSuccess>();

  const client = getEnvVariables("clientName");
  const isUber = client === ClientName.UBER;

  const getExchangePolicy = useCallback(async () => {
    if (enableAirExchange) {
      const { id: itineraryId } = flight.bookedItinerary;
      setModalState(ExchangeModalState.LoadingOrProcessing);

      try {
        const policyRes = await fetchFlightExchangePolicy(itineraryId);

        setPolicy(policyRes);
        handlePolicyScenario(policyRes);
      } catch (err) {
        setModalState(ExchangeModalState.LoadFailed);
      }
    } else {
      setModalState(ExchangeModalState.ContactSupport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flight]);

  const ModalContent = useMemo<ReactNode>(() => {
    switch (modalState) {
      case ExchangeModalState.ContactAirline:
        return renderContactAirline();
      case ExchangeModalState.ContactSupport:
        return renderContactSupport();
      case ExchangeModalState.IneligibleForExchange:
        return renderIneligibleForExchange();
      case ExchangeModalState.LoadFailed:
        return renderLoadFailed();
      case ExchangeModalState.LoadingOrProcessing:
        return renderLoadingModal();
      case ExchangeModalState.TicketedVoidable:
        return renderTicketedVoidable();
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState]);

  function closeModal() {
    setOpenModal({ type: null, selectedItinerary: null });
  }

  function goToExchange() {
    closeModal();
    history.push({
      pathname: "/flights/exchange",
      search: `?bookingId=${flight.bookedItinerary.id}`,
    });
  }

  function handlePolicyScenario(policy: AirExchangeEligibilityQuoteSuccess) {
    const {
      exchangeScenario: eScen,
      voidScenario: { VoidScenario: vScen },
    } = policy;
    let newModalState = ExchangeModalState.LoadFailed;

    switch (eScen.ExchangeScenario) {
      case ExchangeScenarioEnum.AirlineControl:
      case ExchangeScenarioEnum.ContactAirline:
      case ExchangeScenarioEnum.NonExchangeable:
        newModalState = ExchangeModalState.ContactAirline;
        break;
      case ExchangeScenarioEnum.Canceled:
        newModalState = ExchangeModalState.IneligibleForExchange;
        break;
      case ExchangeScenarioEnum.ContactCustomerService:
        newModalState = ExchangeModalState.ContactSupport;
        break;
      case ExchangeScenarioEnum.Exchangeable:
        goToExchange();
        break;
      case ExchangeScenarioEnum.Ftc:
        if (eScen.Ftc === FTCScenario.available) {
          goToExchange();
        }
        break;
      default:
    }

    if (
      vScen === VoidScenarioEnum.PreTicketVoidable ||
      vScen === VoidScenarioEnum.TicketedVoid
    ) {
      newModalState = ExchangeModalState.TicketedVoidable;
    }

    setModalState(newModalState);
  }

  function openFlightCancelModal() {
    const { tripAncillaries } = flight.bookedItinerary;
    const hasCfar = Boolean(tripAncillaries.cfarId);
    const flightCancelModal = {
      selectedItinerary: {
        ...flight,
        type: ItineraryEnum.Flight,
      } as ItineraryWithType,
      type: hasCfar
        ? MyTripsModalTypes.CfarFlight
        : MyTripsModalTypes.SelfServeCancelFlight,
    };

    setOpenModal(flightCancelModal);
  }

  function renderContactAirline() {
    const {
      airlines = [],
      exchangeCopy: { body, title },
    } = policy.exchangeScenario as ContactAirline;
    const airlineBtns = airlines.map((airlineInfo) => {
      const { changeCancelPolicy, homePage, manageBooking } =
        airlineInfo.webLinks;
      const airlineWebsite = changeCancelPolicy || manageBooking || homePage;

      return (
        <ActionButton
          className="contact-airline-btn"
          defaultStyle="h4r-primary"
          message={t("selfServe.visitAirline")}
          onClick={() => {
            window.open(airlineWebsite, "_target")?.focus();
          }}
        />
      );
    });

    return (
      <GenericModalContent
        actions={airlineBtns}
        className="modal-content contact-airline-modal-content"
        columnAlign="flex-start"
        subtitle={body}
        title={title}
      />
    );
  }

  function renderContactSupport() {
    if (isUber) {
      return <UberContactSupport />;
    }

    return (
      <ContactSupportModalContent
        bookingId={flight.bookedItinerary.id}
        bookingUuid={flight.bookedItinerary.id}
        bookingType={BookingType.Flight}
        className="exchange-modal-contact-support"
        requestType="Exchange"
        getSupportId={getBookingSupportId}
        showHelpLink={false}
        subtitle={t("selfServe.supportSubtitle")}
        title={t("selfServe.supportTitle")}
      />
    );
  }

  function renderIneligibleForExchange() {
    return (
      <GenericModalContent
        actions={
          <ActionButton
            defaultStyle="h4r-primary"
            message={t("modalClose.label")}
            onClick={closeModal}
          />
        }
        className="modal-content ineligible-for-exchange-modal-content"
        image={
          <img
            alt="error-bunny"
            className="failure-icon"
            src={LostConnectionBunny}
          />
        }
        subtitle={t("notExchangeable.canceled.subtitle")}
        title={t("notExchangeable.canceled.title")}
      />
    );
  }

  function renderLoadFailed() {
    let body: string[];
    let title = "";

    if (policy && "exchangeCopy" in policy.exchangeScenario) {
      ({ body, title } = policy.exchangeScenario.exchangeCopy);
    }

    return (
      <GenericModalContent
        actions={
          <>
            <ActionButton
              defaultStyle="h4r-primary"
              message={t("tryAgain")}
              onClick={getExchangePolicy}
            />
            <ActionButton
              defaultStyle="h4r-secondary"
              message={t("selfServe.contactSupport")}
              onClick={() => setModalState(ExchangeModalState.ContactSupport)}
            />
          </>
        }
        className="load-failed-modal-content modal-content"
        image={
          <img
            alt="error-bunny"
            className="failure-icon"
            src={LostConnectionBunny}
          />
        }
        subtitle={body || t("notExchangeable.loadFailed.subtitle")}
        title={title || t("notExchangeable.loadFailed.title")}
      />
    );
  }

  function renderLoadingModal() {
    return (
      <GenericModalContent
        className="exchange-policy-loading-modal"
        image={<CircularProgress />}
        subtitle={t("notExchangeable.loading")}
      />
    );
  }

  function renderTicketedVoidable() {
    const {
      exchangeScenario: { ExchangeScenario: scenario },
      voidScenario,
    } = policy;
    const {
      voidableExchangeCopy: { body, title },
    } = voidScenario as TicketedVoid;
    const isExchangeable = scenario === ExchangeScenarioEnum.Exchangeable;

    return (
      <GenericModalContent
        actions={
          <>
            <ActionButton
              className="voidable-cancel-cta"
              defaultStyle="h4r-primary"
              message={t("cancelFlight")}
              onClick={openFlightCancelModal}
            />
            {isExchangeable && (
              <ActionButton
                className="voidable-change-cta"
                defaultStyle="h4r-secondary"
                message={t("exchangeable.voidable")}
                onClick={goToExchange}
              />
            )}
          </>
        }
        className="exchange-voidable-modal-content"
        image={
          <img
            alt="error-bunny"
            className="failure-icon"
            src={LostConnectionBunny}
          />
        }
        subtitle={body}
        title={title}
      />
    );
  }

  useEffect(() => {
    getExchangePolicy();
  }, [getExchangePolicy]);

  return <Box className="exchange-flight-modal-content">{ModalContent}</Box>;
};

ExchangeFlightModalContent.defaultProps = defaultProps;

export default ExchangeFlightModalContent;
