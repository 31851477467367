import { State } from "xstate";
import { CartUpdateError, ParentState } from "@checkout/types";
import { CartContext } from "../context";

type CartUpdateStateType = State<CartContext>;
type CartUpdateStateWithoutValue = Pick<CartUpdateStateType, "context">;

export const getCartUpdateParams = ({
  context,
}: CartUpdateStateWithoutValue) => ({
  addPayments: context[ParentState.cartUpdate].addPayments,
  addProducts: context[ParentState.cartUpdate].addProducts,
  removeProducts: context[ParentState.cartUpdate].removeProducts,
  removePayments: context[ParentState.cartUpdate].removePayments,
});

export const getCartUpdateCallState = (state: CartUpdateStateType) =>
  state.context[ParentState.cartUpdate].callState;

export const getCartUpdateError = (
  state: CartUpdateStateType
): CartUpdateError | undefined => state.context[ParentState.cartUpdate].error;
