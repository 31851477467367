import { ReactNode, RefObject } from "react";
import * as React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import clsx from "clsx";

import "./styles.scss";

export enum ActionLinkKey {
  CFAR = "cfar",
  CHFAR = "chfar",
  DELAY_PROTECTION = "delay-protection",
  MISSED_CONNECTION = "missed-connection",
  EXCHANGE = "exchange",
  HELP = "help",
  RESEND_CONFIRMATION = "resend-confirmation",
  CANCELLATION = "self-serve-cancellation",
  CHECK_IN = "check-in",
}

export interface IActionLink {
  onClick: () => void;
  content: ReactNode;
  disabled?: boolean;
  linkClassName?: string;
  linkRef?: RefObject<HTMLButtonElement>;
  key?: ActionLinkKey;
}
export interface ActionLinksProps {
  actions: IActionLink[];
  className?: string;
  showTappableArea?: boolean;
}

export const ActionLinks = (props: ActionLinksProps): JSX.Element => {
  const { className, actions, showTappableArea } = props;

  return (
    <Box className={clsx("action-links", className)}>
      {actions.map((action, index) => {
        const { content, disabled, onClick, linkClassName, linkRef } = action;
        return (
          <React.Fragment key={index}>
            <Link
              component="button"
              className={clsx(
                "action-link",
                { "show-tap-area": showTappableArea },
                linkClassName
              )}
              disabled={disabled}
              onClick={(event: React.MouseEvent) => {
                event.stopPropagation();
                onClick();
              }}
              ref={linkRef}
            >
              <Typography className="action-link-content" variant="body2">
                {content}
              </Typography>
            </Link>
            {index !== actions.length - 1 && (
              <span className="action-link-divider"> | </span>
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};
