import { Box, CircularProgress, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  getBookingSupportId,
  getHotelChangeQuote,
  getHotelChangeQuoteAny,
  trackEvent,
} from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  BookingType,
  CancelModalFlowStepEnum,
  CancelScenarioEnum,
  ClientName,
  Copy,
  HotelChangeQuoteRes,
  HotelItineraryWithType,
  IOpenModal,
  ItineraryEnum,
  ItineraryWithType,
  MyTripsModalTypes,
  SelfServeEvents,
} from "@hopper-b2b/types";
import {
  GenericModalContent,
  HappyBunny,
  LostConnectionBunny,
  SweetheartBunny,
} from "@hopper-b2b/ui";
import { ActionButton } from "@hopper-b2b/ui/core";
import { getEnvVariables } from "@hopper-b2b/utilities";

import "./styles.scss";
import { ContactSupportModalContent } from "../ApacContactSupportModalContent/ContactSupportModalContent";

export interface IChangeHotelModalContentProps {
  hotel: HotelItineraryWithType;
  isMobile?: boolean;
  setOpenModal: (newModal: IOpenModal) => void;
}

const MAX_TRY_AGAINS = 1;

const defaultProps: Partial<IChangeHotelModalContentProps> = {
  isMobile: false,
};

const ChangeHotelModalContent = (
  props: IChangeHotelModalContentProps
): JSX.Element => {
  const { hotel, isMobile, setOpenModal } = props;
  const { t } = useI18nContext();

  const loadingMsgRef = useRef(t("changeHotelModal.loadingContext"));

  const [activeStep, setActiveStep] = useState(CancelModalFlowStepEnum.loading);
  const [cancelScenario, setCancelScenario] = useState<CancelScenarioEnum>();
  const [copy, setCopy] = useState<Copy>();
  const [numAttempts, setNumAttempts] = useState(0);

  const tenant = getEnvVariables("clientName");
  const isHopperWeb = tenant === ClientName.HOPPER;

  const getChangePolicy = useCallback(async () => {
    const { reservation } = hotel;
    const { reservationBookingId } = reservation;

    loadingMsgRef.current = t("changeHotelModal.loadingContext");
    setActiveStep(CancelModalFlowStepEnum.loading);

    try {
      let changePolicy: HotelChangeQuoteRes;

      if (isHopperWeb) {
        changePolicy = await getHotelChangeQuoteAny(reservationBookingId);
      } else {
        changePolicy = await getHotelChangeQuote(reservationBookingId);
      }

      const {
        cancelScenario: { LodgingCancelScenario, cancelCopy },
        changeCopy,
      } = changePolicy;

      setCancelScenario(LodgingCancelScenario);
      setCopy(changeCopy);

      switch (LodgingCancelScenario) {
        case CancelScenarioEnum.CancellationFailure:
        case CancelScenarioEnum.Pending:
          setActiveStep(CancelModalFlowStepEnum.pending);
          break;
        case CancelScenarioEnum.ContactCustomerService:
        case CancelScenarioEnum.FullyRefundableComplex:
        case CancelScenarioEnum.OutOfPolicy:
        case CancelScenarioEnum.PartiallyRefundableComplex:
        case CancelScenarioEnum.PastCheckInDate:
          setActiveStep(CancelModalFlowStepEnum.support);
          break;
        case CancelScenarioEnum.Canceled:
          setActiveStep(CancelModalFlowStepEnum.cancelled);
          break;
        case CancelScenarioEnum.CfarRefundable:
        case CancelScenarioEnum.ChFarRefundable:
        case CancelScenarioEnum.HopperRefundable:
        case CancelScenarioEnum.LfarRefundable:
          setActiveStep(CancelModalFlowStepEnum.policy);
          setCopy(cancelCopy);
          break;
        case CancelScenarioEnum.FullyRefundable:
        case CancelScenarioEnum.NonRefundable:
        case CancelScenarioEnum.PartialRefund:
          setActiveStep(CancelModalFlowStepEnum.policy);
          break;
        default:
          setActiveStep(CancelModalFlowStepEnum.support);
      }
    } catch (err) {
      setActiveStep(CancelModalFlowStepEnum.tryAgain);
    }
  }, [hotel, isHopperWeb, t]);

  function closeModal() {
    setOpenModal({ selectedItinerary: null, type: null });
  }

  function renderChangePolicyError() {
    const ContactSupportCTA = (
      <ActionButton
        className="b2b"
        defaultStyle="h4r-secondary"
        message={t("selfServe.contactSupport") as string}
        onClick={() => setActiveStep(CancelModalFlowStepEnum.support)}
      />
    );

    return (
      <GenericModalContent
        actions={
          <>
            <ActionButton
              defaultStyle="h4r-primary"
              message={t("selfServe.tryAgainBtn") as string}
              onClick={() => {
                setNumAttempts(numAttempts + 1);
                getChangePolicy();
              }}
            />
            {numAttempts >= MAX_TRY_AGAINS && ContactSupportCTA}
          </>
        }
        className="hotel-change-error"
        image={
          <img
            alt="error-bunny"
            className="failure-icon"
            src={LostConnectionBunny}
          />
        }
        subtitle={t("changeHotelModal.error.subtitle")}
        title={t("changeHotelModal.error.title")}
      />
    );
  }

  function renderCancellationPending() {
    return (
      <GenericModalContent
        actions={
          <ActionButton
            defaultStyle="h4r-secondary"
            message={t("modalClose.label") as string}
            onClick={closeModal}
          />
        }
        className="hotel-change-pending"
        subtitle={t("cancelHotelModal.pendingSubtitle")}
        title={t("cancelHotelModal.pendingTitle")}
      />
    );
  }

  function renderLoading() {
    return (
      <GenericModalContent
        className="hotel-change-loading"
        image={<CircularProgress />}
        subtitle={
          <Typography variant="subtitle2">{loadingMsgRef.current}</Typography>
        }
      />
    );
  }

  function renderPolicyDetails() {
    const ContactSupportBtn = (
      <ActionButton
        className="policy-contact-support-btn"
        defaultStyle="h4r-primary"
        message={t("contactSupport")}
        onClick={() => setActiveStep(CancelModalFlowStepEnum.support)}
      />
    );
    let isRefundable = true;
    let subtitle1 = "";
    let subtitle2 = "";
    let title = t("changeHotelModal.cancelAndRebookTitle");
    let PrimaryAction = (
      <ActionButton
        className="view-cancel-policy-btn"
        defaultStyle="h4r-primary"
        message={t("changeHotelModal.viewCancelPolicyBtn") as string}
        onClick={() =>
          setOpenModal({
            selectedItinerary: {
              ...hotel,
              type: ItineraryEnum.Hotel,
            } as ItineraryWithType,
            type: MyTripsModalTypes.CancelHotel,
          })
        }
      />
    );

    if (copy) {
      ({
        body: [subtitle1, subtitle2],
        title,
      } = copy);
    }

    switch (cancelScenario) {
      case CancelScenarioEnum.CfarRefundable:
      case CancelScenarioEnum.ChFarRefundable:
      case CancelScenarioEnum.HopperRefundable:
      case CancelScenarioEnum.LfarRefundable:
        PrimaryAction = ContactSupportBtn;
        break;
      case CancelScenarioEnum.NonRefundable:
        isRefundable = false;
        PrimaryAction = (
          <ActionButton
            className="close-btn"
            defaultStyle="h4r-primary"
            message={t("backToTrips") as string}
            onClick={closeModal}
          />
        );
        break;
      default:
    }

    return (
      <GenericModalContent
        actions={PrimaryAction}
        className="hotel-change-policy"
        content={
          <Box className="hotel-change-policy-body">
            <Typography
              className="subtitle"
              dangerouslySetInnerHTML={{ __html: subtitle1 }}
              variant="subtitle2"
            />
            <Typography
              className="subtitle"
              dangerouslySetInnerHTML={{ __html: subtitle2 }}
              variant="subtitle2"
            />
          </Box>
        }
        image={
          isRefundable ? (
            <img alt="happy-bunny" className="success-icon" src={HappyBunny} />
          ) : null
        }
        title={title}
      />
    );
  }

  const ModalContent = useMemo(() => {
    switch (activeStep) {
      case CancelModalFlowStepEnum.loading:
        return renderLoading();
      case CancelModalFlowStepEnum.pending:
        return renderCancellationPending();
      case CancelModalFlowStepEnum.policy:
        return renderPolicyDetails();
      case CancelModalFlowStepEnum.tryAgain:
        return renderChangePolicyError();
      default:
        return (
          <ContactSupportModalContent
            bookingId={hotel.reservation.reservationId}
            bookingUuid={hotel.reservation.reservationBookingId}
            bookingType={BookingType.Lodging}
            className="hotel-change-contact-support"
            requestType="ChangeHotel"
            getSupportId={getBookingSupportId}
            icon={<img alt="cs-bunny" src={SweetheartBunny} />}
            showHelpLink={false}
            subtitle={t("selfServe.supportSubtitle") as string}
            title={t("selfServe.supportTitle") as string}
          />
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, hotel, t]);

  useEffect(() => {
    if (hotel) {
      trackEvent({
        eventName: SelfServeEvents.ViewedChangeModal,
        properties: {
          product: "hotel",
          lob: "hotel",
        },
        encryptedProperties: [],
      });

      getChangePolicy();
    }
  }, [hotel, getChangePolicy]);

  return (
    <Box className={clsx("change-hotel-modal-content", { mobile: isMobile })}>
      {ModalContent}
    </Box>
  );
};

ChangeHotelModalContent.defaultProps = defaultProps;

export default ChangeHotelModalContent;
