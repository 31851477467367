import { ApacIcon, ApacIconName } from "@commbank/ui";
import "./styles.scss";

export interface MyTripsGuestIconProps {
  age?: number;
}

export const MyTripsGuestIcon = ({ age }: MyTripsGuestIconProps) => {
  return (
    <ApacIcon
      name={
        !age || age >= 18 ? ApacIconName.CBAPerson : ApacIconName.CBAPersonChild
      }
      className="mytrips-guest-icon"
    />
  );
};
