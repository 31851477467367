import { Fragment } from "react";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { PaymentLineItemEnum } from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ILineItem } from "../FlightCard/helpers";
import "./styles.scss";

interface IPaymentSummary {
  className?: string;
  tripTotalLabel?: string;
  tripTotalAmount: string;
  paymentLineItems: ILineItem[] | undefined;
}

export const PaymentSummary = ({
  className,
  tripTotalLabel,
  tripTotalAmount,
  paymentLineItems,
}: IPaymentSummary) => {
  const { t, formatFiatCurrency } = useI18nContext();
  return (
    <Box className={clsx("payment-summary-container", className)}>
      <Box
        className={clsx("pricing-row", {
          "row-border": paymentLineItems?.length > 0,
        })}
      >
        <Typography
          variant="subtitle2"
          className={clsx("trip-total-label", "bold")}
        >
          {tripTotalLabel || t("tripTotal")}
        </Typography>
        <Typography
          variant="body2"
          className={clsx("trip-total-details", "bold")}
        >
          {tripTotalAmount}
        </Typography>
      </Box>
      {paymentLineItems?.map((item, idx) => (
        <Box key={idx} className={"pricing-row"}>
          <Fragment key={idx}>
            <Box className="payment-card-row-icon">
              {item.PaymentLineItem === PaymentLineItemEnum.UserCard && (
                <FontAwesomeIcon
                  icon={faCreditCard as IconProp}
                  className="credit-card-icon"
                />
              )}
              <Typography variant="subtitle2" className="payment-card-details">
                {item.label}
              </Typography>
            </Box>
            <Typography variant="body2" className="pricing-details">
              {formatFiatCurrency({
                value: item.value,
                currencyCode: item.currency,
              }) || ""}
            </Typography>
          </Fragment>
        </Box>
      ))}
    </Box>
  );
};
