import { put } from "redux-saga/effects";
import {
  GetWatchAlertDetailsResponseResponse,
  ResponseEnum,
} from "@b2bportal/air-price-watch-api";
import { getWatchDetails } from "@hopper-b2b/api";
import { CallState } from "@hopper-b2b/types";

import { actions } from "../../actions";

export function* getWatchDetailsSaga(action: actions.IGetWatchDetails) {
  try {
    const response: GetWatchAlertDetailsResponseResponse =
      yield getWatchDetails({
        key: action.watch.key,
      });

    if (response.Response === ResponseEnum.Success) {
      yield put(
        actions.populateTripQueryParams(action.history, {
          tripId: action.watch.id,
        })
      );
      yield put(actions.setGetDetailsWatchCallState(CallState.Success));
      yield put(actions.setSelectedWatchDetails(response.value));
      yield put(actions.setSelectedWatch(action.watch));
    } else {
      yield put(actions.setGetDetailsWatchCallState(CallState.Failed));
    }
  } catch (e) {
    yield put(actions.setGetDetailsWatchCallState(CallState.Failed));
  }
}
