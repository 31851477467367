import { Box, Collapse, Typography } from "@material-ui/core";
import { PropsWithChildren } from "react";
import styles from "./InputWrapper.module.scss";
import clsx from "clsx";
import { ErrorMessageWrapper } from "../ErrorMessageWrapper";

interface InputWrapperProps extends PropsWithChildren {
  label?: string | JSX.Element;
  error?: string | JSX.Element;
  className?: string;
}

export const InputWrapper = ({
  label,
  error,
  children,
  className,
}: InputWrapperProps): JSX.Element => {
  return (
    <Box className={clsx(styles.Container, className)}>
      {typeof label === "string" ? (
        <Typography className={styles.Label} variant="body1">
          {label}
        </Typography>
      ) : (
        label
      )}
      {children}
      <Collapse orientation="vertical" in={!!error}>
        {typeof error === "string" ? (
          <ErrorMessageWrapper message={error} />
        ) : (
          error
        )}
      </Collapse>
    </Box>
  );
};
