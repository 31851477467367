import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ITripTerminus, SelfServeEvents } from "@hopper-b2b/types";
import { CurrencyFormatters, useUberBridge } from "@hopper-b2b/utilities";
import { Box, Collapse, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions as searchActions } from "../../../search/actions";
import { getDestination, getOrigin } from "../../../search/reducer";
import { getChangeFee, getMarketingAirline } from "../../reducer/selectors";
import { getBaseEventProps, getIsFtc } from "../../selectors";
import { LocationSearch } from "../../../search/components/MobileFlightSearchControl/components";

import "./styles.scss";

export interface IMobileTerminiPickerProps {
  onContinue: () => void;
}

const defaultProps: Partial<IMobileTerminiPickerProps> = {};

const MobileTerminiPicker = (props: IMobileTerminiPickerProps): JSX.Element => {
  const { onContinue } = props;
  const dispatch = useDispatch();
  const { t } = useI18nContext();

  const airline = useSelector(getMarketingAirline);
  const baseEventProps = useSelector(getBaseEventProps);
  const changeFee = useSelector(getChangeFee);
  const destination = useSelector(getDestination);
  const isFTC = useSelector(getIsFtc);
  const origin = useSelector(getOrigin);

  const [showPolicy, setShowPolicy] = useState(true);

  const { postEvent } = useUberBridge();

  const changeFeeAmt = CurrencyFormatters.get(changeFee?.currency).format(
    changeFee?.amount
  );

  const onDestinationChange = (newDest: ITripTerminus) => {
    dispatch(searchActions.setDestination(newDest));
  };

  const onOriginChange = (newOrigin: ITripTerminus) => {
    dispatch(searchActions.setOrigin(newOrigin));
  };

  useEffect(() => {
    const eventName = isFTC
      ? SelfServeEvents.ViewedFTCExchangeLanding
      : SelfServeEvents.ViewedExchangeLanding;

    trackEvent({
      eventName,
      properties: baseEventProps,
    });
    postEvent(eventName, baseEventProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="airport-picker-container">
      <Collapse
        classes={{
          root: "exchange-policy-info-container",
          wrapper: "policy-info",
        }}
        in={showPolicy}
      >
        <Typography className="policy-title" variant="h3">
          {changeFee?.amount
            ? t("exchangeable.landing.title.fee", {
                airline: airline,
                amount: changeFeeAmt,
                interpolation: {
                  defaultVariables: {
                    airline: t("exchangeable.landing.fallbackAirline"),
                  },
                },
              })
            : t("exchangeable.landing.title.noFee", {
                airline: airline,
                interpolation: {
                  defaultVariables: {
                    airline: t("exchangeable.landing.fallbackAirline"),
                  },
                },
              })}
        </Typography>
        <Typography className="policy-body" variant="body1">
          {t("exchangeable.landing.body")}
        </Typography>
      </Collapse>
      <Box className="location-search-container">
        <LocationSearch
          hideCategoryBar
          autoSearch={false}
          destination={destination}
          onComplete={onContinue}
          onBlur={() => setShowPolicy(true)}
          onFocus={() => setShowPolicy(false)}
          origin={origin}
          popperClassName="exchange-search-airport-popper"
          popupIcon={null} // hides autocomplete dropdown icon
          setDestination={onDestinationChange}
          setOrigin={onOriginChange}
          setTripCategory={undefined}
        />
      </Box>
    </Box>
  );
};

MobileTerminiPicker.defaultProps = defaultProps;

export default MobileTerminiPicker;
