import { type Lodging } from "@b2bportal/lodging-api";
import { Typography, Box } from "@material-ui/core";
import { getAddressText } from "../../../../util/utils";
import { ApacIcon, ApacIconName } from "@commbank/ui";
import { Amenities } from "../Amenities";

interface Props {
  lodging: Lodging;
  isMobile: boolean;
}

export function MobileHotelHeader({
  lodging: {
    lodging: { name, address },
  },
  isMobile,
}: Props) {
  return (
    <Box
      className="hotel-title-header"
      mt="2rem"
      display="flex"
      gridGap="1rem"
      flexWrap="wrap"
      alignItems="center"
    >
      <Box gridGap="0.5rem" display="flex" flexDirection="column">
        <Typography
          className="title"
          style={{ lineHeight: 1 }}
          variant={isMobile ? "h6" : "h1"}
        >
          {name}
        </Typography>
        <Box display="flex" gridGap="5px" alignItems="center">
          <ApacIcon name={ApacIconName.LocationOutlined} />
          <Typography color="textSecondary" variant="body2">
            {getAddressText(address)}
          </Typography>
        </Box>
      </Box>
      <Amenities isMobile={isMobile} />
    </Box>
  );
}
