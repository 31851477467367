import { getEnvVariables } from "@hopper-b2b/utilities";
import { ParentState } from "@checkout/types";
import { CartQuoteState } from "../../../types";
import { getChildState } from "../../../helpers";
import { State } from "xstate";
import { CartContext } from "../context";
import { CartState } from "../types";
import { Prices } from "@b2bportal/purchase-api";

type CartQuoteStateType = State<CartContext>;
type CartQuoteStateWithoutValue = Pick<CartQuoteStateType, "context">;

export const getPriceQuoteCipherText = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].priceQuoteCipherText;

export const getOpenCartQuoteModal = (state: CartQuoteStateType) =>
  [CartQuoteState.polling, CartQuoteState.schedule].includes(
    getChildState(state.value) as CartQuoteState
  ) && Object.keys(state.value).includes(ParentState.cartQuote);

export const getCartQuoteError = (state: CartQuoteStateType) =>
  state.context[CartState.cartQuote].error;

export const getCartQuoteFailedAt = (state: CartQuoteStateType) =>
  state.context[CartState.cartQuote].error?.failedAt;

export const getCartQuoteProducts = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].products;

export const getCartQuoteCustomerEmail = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].customerEmail;

export const getQuoteBreakdown = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].quoteBreakdown;

export const getCartQuoteIsLocked = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].cartLocked;

export const getOpenCartQuoteErrorModal = (state: CartQuoteStateType) =>
  getChildState(state.value) === CartQuoteState.error;

export const getCartQuotePriceChange = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].priceChange;

export const getUnavailableProducts = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].unavailableProducts;

export const getAllowQuoteRetry = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) =>
  state.context[CartState.cartQuote].retries <
  parseInt(getEnvVariables("allowedQuoteRetries")?.toString() || "0", 10);

export const getCartQuoteTrackingProperties = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context.cartQuote.trackingProperties;

export const getRemainingBalance = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].quoteBreakdown?.balance;

export const getCartQuoteCallState = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].callState;

export const getCartBreakdownProductTypes = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => {
  const breakdown = getQuoteBreakdown(state);
  return breakdown?.products.map((p) => p.product.type);
};

export const getCartBreakdownBalance = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
): Prices | undefined =>
  state.context[ParentState.cartQuote].quoteBreakdown?.balance;
