import { put, select } from "redux-saga/effects";
import dayjs from "dayjs";
import {
  PriceFreezeOfferDataRequest,
  PriceFreezeOfferDataResponse,
} from "@b2bportal/price-freeze-api";
import {
  IFetchSelectedTripPriceFreezeOffer,
  setSelectedTripPriceFreezeOffer,
} from "../actions/actions";
import { fetchSelectedTripPriceFreezeOffer } from "../../../api/v1/price-freeze/fetchSelectedTripPriceFreezeOffer";
import { flightApiConfigStoreKey, IStoreState } from "../../../reducers/types";

export function* fetchSelectedTripPriceFreezeOfferSaga(
  action: IFetchSelectedTripPriceFreezeOffer
) {
  const state: IStoreState = yield select();
  const apiConfig = state[flightApiConfigStoreKey];
  const { fareId, tripId, departureDate } = action;

  if (departureDate) {
    const req: PriceFreezeOfferDataRequest = {
      tripId,
      fareId,
      departureDate: dayjs(departureDate).format("YYYY-MM-DD"),
    };

    try {
      const response: PriceFreezeOfferDataResponse =
        yield fetchSelectedTripPriceFreezeOffer(req, apiConfig);

      yield put(setSelectedTripPriceFreezeOffer(response?.offerData[0]));
    } catch (e) {
      console.error(e);
    }
  }
}
