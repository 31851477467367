import { useApiConfigSelector } from "@hopper-b2b/utilities";
import { SliceStopCountFilter } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { RadioDropdown } from "@hopper-b2b/ui";

import { StopsOptionSelectionProps } from "./container";
import { flightApiConfigStoreKey } from "../../../../../../../../reducers/types";
import "./styles.scss";

export interface IStopsOptionSelectionProps extends StopsOptionSelectionProps {
  showDropdownContentOnly?: boolean;
  rerunPricePrediction?: boolean;
  setNonstopFilterSelected?: (nonstopFilterSelected: boolean) => void;
}

interface IStopsOption {
  label: string;
  value: SliceStopCountFilter;
}

export const StopsOptionSelection = (props: IStopsOptionSelectionProps) => {
  const {
    stopsOption,
    setStopsOption,
    setRerunPrediction,
    showDropdownContentOnly,
    setNonstopFilterSelected,
  } = props;

  const apiConfig = useApiConfigSelector(flightApiConfigStoreKey);

  const { t } = useI18nContext();

  const handleChange = (value: string) => {
    const nonStopClicked =
      value === SliceStopCountFilter.NONE ||
      stopsOption === SliceStopCountFilter.NONE;

    if (setNonstopFilterSelected) {
      // Mobile
      nonStopClicked
        ? setNonstopFilterSelected(true)
        : setNonstopFilterSelected(false);
    } else {
      // Desktop
      nonStopClicked && setRerunPrediction();
    }
    setStopsOption(value as SliceStopCountFilter, apiConfig);
  };

  const stopsOptions: IStopsOption[] = [
    {
      label: t("searchFilter.anyStop"),
      value: SliceStopCountFilter.ANY_NUMBER,
    },
    {
      label: t("searchFilter.noStops"),
      value: SliceStopCountFilter.NONE,
    },
    {
      label: t("searchFilter.oneOrFewerStops"),
      value: SliceStopCountFilter.ONE_OR_LESS,
    },
    {
      label: t("searchFilter.twoOrFewerStops"),
      value: SliceStopCountFilter.TWO_OR_LESS,
    },
  ];

  return (
    <RadioDropdown
      dropdownLabel={t("searchFilter.stops")}
      options={stopsOptions}
      setOption={handleChange}
      selected={stopsOption}
      showDropdownContentOnly={showDropdownContentOnly}
      buttonClassName="b2b-shop-filter"
      groupAriaLabel={t("searchFilter.filterStops")}
      buttonAriaLabel={t("searchFilter.changeFilterStops")}
      popoverClassName="b2b"
    />
  );
};
