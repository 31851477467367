import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import {
  ApacIconComponent,
  ApacIconName,
  TravelCreditsOfferModalLink,
} from "@commbank/ui";
import {
  AwardsEnum,
  CommbankCardAccount,
} from "@b2bportal/commbank-au-accounts-api";
import { Funnel, FunnelToFunnelInfo } from "../components/type";
import { useFeatureFlag } from "@apac/feature-flags";
import { FEATURE_FLAGS } from "../Root";
import {
  useAccounts,
  useBestTravelCreditsPercentage,
} from "@commbank/accounts";

import React from "react";

const hasLodgingPointsOffer = (accounts: CommbankCardAccount[]) => {
  return !!accounts.find((account) => {
    return !!account.eligibleOffers.find(
      (offer) => offer.awards.Awards === AwardsEnum.Points
    );
  });
};

const useHomepageSearchCopy = (
  funnelToFunnelInfo: FunnelToFunnelInfo,
  currentFunnel: Funnel
) => {
  const { accounts = [] } = useAccounts();
  const bestOfferPercentage = useBestTravelCreditsPercentage();

  const { t } = useI18nContext();
  const displayHotelMerch = useFeatureFlag(FEATURE_FLAGS.DISPLAY_HOTELS_MERCH);
  let title: string | React.ReactElement = t(
    `${funnelToFunnelInfo[currentFunnel].stringId}.searchTitle`
  );
  let subtitle: string | React.ReactElement = t(
    `${funnelToFunnelInfo[currentFunnel].stringId}.searchSubtitle`
  );
  if (displayHotelMerch && currentFunnel === Funnel.Hotels) {
    if (bestOfferPercentage > 0) {
      title = (
        <div>
          {t(`commBank.search.hotels.searchTitleYello`)}
          <TravelCreditsOfferModalLink
            bestOfferPercentage={bestOfferPercentage}
          >
            <ApacIconComponent
              className="travel-credits-info-icon"
              name={ApacIconName.InfoOutlined}
            />
          </TravelCreditsOfferModalLink>
        </div>
      );
      subtitle = t("commBank.search.hotels.searchSubtitleYello");
    } else if (hasLodgingPointsOffer(accounts)) {
      title = t(`commBank.search.hotels.searchTitlePoints`);
      subtitle = t(`commBank.search.hotels.searchSubtitlePoints`);
    } else {
      title = t(`commBank.search.hotels.searchTitleDefault`);
      subtitle = t(`commBank.search.hotels.searchSubtitleDefault`);
    }
  }

  return {
    title,
    subtitle,
  };
};

export default useHomepageSearchCopy;
