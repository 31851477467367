import * as React from "react";
import { Box, Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import { SliceStopCountFilter } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";

import "./styles.scss";
import * as textConstants from "./textConstants";

export interface IStopsOptionSelectionBase {
  stopsOption: SliceStopCountFilter;
  setStopsOption: React.Dispatch<React.SetStateAction<SliceStopCountFilter>>;
}

interface IStopsOptionSelectionProps extends IStopsOptionSelectionBase {
  className?: string;
  hideTitle?: boolean;
}

const StopsOptionSelection = (props: IStopsOptionSelectionProps) => {
  const { className, hideTitle, stopsOption, setStopsOption } = props;
  const { t } = useI18nContext();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStopsOption(
      (event.target as HTMLInputElement).value as SliceStopCountFilter
    );
  };

  const stopsOptions: IStopsOption[] = [
    {
      label: textConstants.ANY_NUMBER_OF_STOPS_TEXT,
      option: SliceStopCountFilter.ANY_NUMBER,
    },
    { label: textConstants.NO_STOPS_ONLY, option: SliceStopCountFilter.NONE },
  ];

  return (
    <Box className={clsx("stops-option-selection-root", className)}>
      <Box className="stops-option-selection-container">
        {!hideTitle && (
          <Box className="header-container">{t("searchFilter.stopHeader")}</Box>
        )}
        <Box className="selection-container">
          <RadioGroup
            className="stops-option-selection"
            value={stopsOption}
            onChange={handleChange}
          >
            {renderRadios(stopsOptions)}
          </RadioGroup>
        </Box>
      </Box>
    </Box>
  );
};

interface IStopsOption {
  label: string;
  option: SliceStopCountFilter;
}

const renderRadios = (options: IStopsOption[]) =>
  options.map(({ label, option }) => (
    <FormControlLabel
      className="stops-option"
      key={option}
      value={option}
      label={<span className="stops-option-label">{label}</span>}
      labelPlacement="start"
      control={<Radio className="stops-option-radio" />}
    />
  ));

export default StopsOptionSelection;
