import { useContext, useMemo } from "react";
import { Typography, Box, Button } from "@material-ui/core";
import { uniqWith, isEqual } from "lodash-es";
import clsx from "clsx";
import {
  Airline,
  BookedFlightItineraryWithDepartureTime,
} from "@b2bportal/air-booking-api";

import {
  MultiTravelItinerary,
  SingleTravelItinerary,
  TravelItineraryEnum,
} from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { openExternalLink } from "@hopper-b2b/utilities";
import { Slot } from "@hopper-b2b/ui";

import { ClientContext } from "../../../../../../../../App";
import { ConfirmationModalContentConnectorProps } from "./container";
import { copyTextToClipboardPromise } from "./helpers";
import * as textConstants from "./constants";
import "./styles.scss";

interface IConfirmationModalContentProps
  extends ConfirmationModalContentConnectorProps {
  flight: BookedFlightItineraryWithDepartureTime;
}

export interface IConfirmationNumber {
  label: string;
  locator: string;
  redirectUrl: string;
}

const onCopyAndGo = async (locator: string, redirectUrl: string) => {
  await copyTextToClipboardPromise(locator);
  openExternalLink(redirectUrl);
};

const filterDuplicates = (confirmationNumbers: IConfirmationNumber[]) =>
  uniqWith(confirmationNumbers, isEqual);

export const getConfirmationNumbers = ({
  flight,
  airlineMap,
  labels,
}: {
  flight: BookedFlightItineraryWithDepartureTime;
  airlineMap: { [key: string]: Airline | undefined };
  labels?: textConstants.IConfirmationModalLabels;
}): IConfirmationNumber[] => {
  const { bookedItinerary } = flight;
  const { travelItinerary } = bookedItinerary;
  const { TravelItinerary } = travelItinerary;

  const confirmationNumbers: IConfirmationNumber[] = [];

  if (
    (travelItinerary as SingleTravelItinerary)?.locators?.agent?.unscopedValue
  ) {
    confirmationNumbers.push({
      label: labels ? labels.confirmationCode : textConstants.HOPPER_TRAVEL,
      locator:
        (travelItinerary as SingleTravelItinerary).locators?.agent?.value || "",
      redirectUrl: textConstants.HOPPER_WEBSITE,
      unscopedValue:
        (travelItinerary as SingleTravelItinerary).locators?.agent
          ?.unscopedValue || "",
    });
  }

  if (TravelItinerary === TravelItineraryEnum.SingleTravelItinerary) {
    const singleTravelItinerary = travelItinerary as SingleTravelItinerary;
    singleTravelItinerary.slices.forEach((slice, sliceIndex) => {
      slice.segments.forEach((segment) => {
        const confirmationNumber = textConstants.getConfirmationNumber({
          segment,
          airlineMap,
          isReturn: sliceIndex !== 0,
          labels,
        });
        if (confirmationNumber) {
          confirmationNumbers.push(confirmationNumber);
        }
      });
    });
  }
  if (TravelItinerary === TravelItineraryEnum.MultiTravelItinerary) {
    const multipleTravelItinerary = travelItinerary as MultiTravelItinerary;

    if (multipleTravelItinerary.locators?.children) {
      multipleTravelItinerary.travelItineraries.forEach((itinerary) => {
        itinerary.slices.forEach((slice, sliceIndex) => {
          slice.segments.forEach((segment) => {
            const confirmationNumber = textConstants.getConfirmationNumber({
              segment,
              airlineMap,
              isReturn: sliceIndex !== 0,
              labels,
            });
            if (confirmationNumber) {
              confirmationNumbers.push(confirmationNumber);
            }
          });
        });
      });
    }
  }
  return confirmationNumbers;
};

const ConfirmationNumbers = ({
  confirmationNumbers,
}: {
  confirmationNumbers: IConfirmationNumber[];
}) => {
  const { t } = useI18nContext();
  const clientContext = useContext(ClientContext);

  return (
    <Box className="confirmation-container">
      {confirmationNumbers.map(({ label, locator, redirectUrl }, index) => (
        <Box
          key={`${locator}-${index}`}
          className={clsx("confirmation-details-container", {
            "container-border": index !== confirmationNumbers.length - 1,
          })}
        >
          <Box className="confirmation-details">
            <Typography className="confirmation-label" variant="body2">
              {label}
            </Typography>
            <Typography variant="body2" className="confirmation-locator">
              {locator}
            </Typography>
          </Box>
          {index !== 0 && (
            <Slot
              id="trips-confirmation-modal-copy-and-go-button"
              className="confirmation-copy-container"
              locator={locator}
              redirectUrl={redirectUrl}
              onClick={() => onCopyAndGo(locator, redirectUrl)}
              aria-label="Copy and Go"
              component={
                <Button
                  className="confirmation-copy-container"
                  onClick={() => onCopyAndGo(locator, redirectUrl)}
                  aria-label="Copy and Go"
                >
                  <img
                    src={clientContext?.assets?.copy}
                    className="copy-icon"
                    alt=""
                  />
                  <Typography variant="body2" className="copy-text">
                    {t("copyAndGo")}
                  </Typography>
                </Button>
              }
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

export const ConfirmationModalContent = ({
  flight,
  airlineMap,
}: IConfirmationModalContentProps) => {
  const { t } = useI18nContext();

  const labels = useMemo(() => {
    return {
      confirmationCode: t("confirmationModal.confirmationCodeLabel"),
      return: t("return"),
      outbound: t("outbound"),
      originToDestination: (origin: string, destination: string) =>
        t("originToDestination", {
          origin: origin,
          destination: destination,
          interpolation: { escapeValue: false },
        }),
    };
  }, [t]);

  const confirmationNumbers = filterDuplicates(
    getConfirmationNumbers({ flight, airlineMap, labels })
  );

  return (
    <Box className="confirmation-modal-content">
      <Box className="confirmation-title-container modal-header">
        <Typography variant="h4" className="confirmation-title modal-title">
          {`${t("confirmationModal.title")} (${confirmationNumbers.length})`}
        </Typography>
        <Typography
          variant="body2"
          className="confirmation-text modal-subtitle"
        >
          {t("confirmationModal.subtitle")}
        </Typography>
      </Box>
      <ConfirmationNumbers confirmationNumbers={confirmationNumbers} />
    </Box>
  );
};
