import { Box, Dialog, Slide } from "@material-ui/core";
import clsx from "clsx";
import { FunctionComponent, PropsWithChildren } from "react";
import { IMobilePopoverCardProps } from "../MobilePopoverCard/component";
import "./styles.scss";

export const FintechMobilePopoverCard: FunctionComponent<
  IMobilePopoverCardProps
> = ({
  id,
  role,
  open,
  onClose,
  className,
  contentClassName,
  children,
  direction,
  fullScreen,
  scroll,
  centered = true,
  headerElement,
  topLeftButton,
  topRightButton,
  bottomButton,
  fullScreenWithBanner,
  disableEnforceFocus,
}: PropsWithChildren<IMobilePopoverCardProps>) => (
  <Dialog
    id={id}
    role={role}
    hideBackdrop={false}
    BackdropProps={{
      invisible: false,
    }}
    open={open}
    scroll={scroll}
    onClose={onClose}
    TransitionComponent={Slide}
    TransitionProps={{
      // See https://mui.com/material-ui/transitions/#performance-amp-seo
      unmountOnExit: true,
      /* eslint-disable */
      // @ts-ignore: Fix the type definition of TransitionProps to include direction.
      direction: direction ?? "up",
      /* eslint-enable */
    }}
    className={clsx("mobile-popover-card-root", className, {
      "full-screen": fullScreen,
      centered,
      "full-screen-banner": fullScreenWithBanner,
    })}
    onClick={(event) => {
      event.stopPropagation();
    }}
    disableEnforceFocus={disableEnforceFocus}
    paperfullscreen={fullScreen ? "true" : "false"}
  >
    <Box className="mobile-popover-card-container">
      <Box className="mobile-popover-card-header">
        <Box className={clsx("mobile-popover-card-top-button", "left")}>
          {topLeftButton}
        </Box>
        {headerElement && headerElement}
        <Box className={clsx("mobile-popover-card-top-button", "right")}>
          {topRightButton}
        </Box>
      </Box>

      <Box
        className={clsx(
          "mobile-popover-card-content-container",
          contentClassName
        )}
      >
        {children}
      </Box>
      {bottomButton && (
        <Box className="mobile-popover-card-bottom-button">{bottomButton}</Box>
      )}
    </Box>
  </Dialog>
);
