import { DateSelectButton } from "@commbank/ui";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ButtonBase, ButtonBaseProps } from "@material-ui/core";
import clsx from "clsx";
import { ReferenceObject } from "popper.js";
import * as React from "react";
import { useState } from "react";
import "./styles.scss";
import { END_DATE, FocusedInput, START_DATE } from "@datepicker-react/hooks";

export interface DatePickerButtonProps {
  buttonProps?: ButtonBaseProps;
  dateFormat?: string;
  startDate: Date | null;
  startDateError?: string;
  endDate: Date | null;
  endDateError?: string;
  disabled?: boolean;
  startLabel: string;
  endLabel: string;
  renderCalendarPopup?: (extraProps: {
    open: boolean;
    closePopup: () => void;
    anchorEl: ReferenceObject;
  }) => React.ReactElement;
  onClick?: () => void;
  tabIndex?: number;
  buttonClass?: string;
  hideEndDate?: boolean;
  hideSeparator?: boolean;
  ariaLabel?: string;
  classes?: string[];
  setDefaultFocusedInput?: (value: React.SetStateAction<FocusedInput>) => void;
}

type IAnchorEl = EventTarget | null;

export const DatePickerButton = (props: DatePickerButtonProps) => {
  const {
    startDate,
    startDateError,
    endDate,
    endDateError,
    startLabel,
    endLabel,
    disabled,
    renderCalendarPopup,
    onClick,
    buttonProps = {},
    dateFormat,
    tabIndex,
    buttonClass,
    hideEndDate,
    hideSeparator,
    ariaLabel,
    classes = [],
    setDefaultFocusedInput,
  } = props;

  const [anchorEl, setAnchorEl] = useState<IAnchorEl>(null);

  const handleClick = (target: EventTarget) => {
    setAnchorEl(anchorEl ? null : target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const separatorIcon = (
    <Box className="separator-container">
      <FontAwesomeIcon icon={faArrowRight as IconProp} />
    </Box>
  );

  return (
    <Box className={clsx("date-picker-button-container", ...classes)}>
      <ButtonBase
        className={clsx("date-picker-button", buttonClass)}
        aria-label={ariaLabel}
        disabled={disabled}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleClick(e.currentTarget);
            onClick && onClick();
          }
        }}
        onClick={
          disabled
            ? undefined
            : (e) => {
                handleClick(e.currentTarget);
                onClick && onClick();
              }
        }
        tabIndex={tabIndex}
        disableTouchRipple
        disableRipple
        {...buttonProps}
      >
        <DateSelectButton
          className="start-date-container"
          date={startDate}
          label={startLabel}
          error={startDateError}
          showIcon
          onClick={() => {
            setDefaultFocusedInput(START_DATE);
          }}
        />
        {!hideEndDate && !hideSeparator && separatorIcon}
        {!hideEndDate && (
          <DateSelectButton
            className="end-date-container"
            date={endDate}
            label={endLabel}
            error={endDateError}
            showIcon
            onClick={() => {
              setDefaultFocusedInput(END_DATE);
            }}
          />
        )}
      </ButtonBase>
      {renderCalendarPopup && (
        <div>
          {renderCalendarPopup({
            open: Boolean(anchorEl),
            closePopup: handleClose,
            anchorEl: anchorEl as unknown as ReferenceObject,
          })}
        </div>
      )}
    </Box>
  );
};
