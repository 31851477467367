import { PropsWithChildren } from "react";
import { Typography } from "@material-ui/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import "./styles.scss";

export interface ReviewSummaryRowProps extends PropsWithChildren {
  title: string;
  iconSrc?: string;
  onClick?: () => void;
  buttonLabel?: string;
  className?: string;
  hideIcon?: boolean;
  button?: JSX.Element;
}

export const ReviewSummaryRow = ({
  title,
  iconSrc,
  onClick,
  buttonLabel,
  className,
  children,
  hideIcon,
  button,
}: ReviewSummaryRowProps) => {
  return (
    <div className={clsx("review-summary-row", className)}>
      <div className="review-summary-row-left">
        {!hideIcon ? (
          <div className="review-summary-row-icon">
            {iconSrc ? (
              <img className="mobile-left-icon" src={iconSrc} />
            ) : null}
          </div>
        ) : null}
        <div className="review-summary-row-value">
          <div className="review-text">
            <Typography className="content" variant="body1">
              {title}
            </Typography>
          </div>
          {children ? <div className="description-text">{children}</div> : null}
        </div>
      </div>
      {button ??
        (onClick ? (
          <div className="review-summary-row-right">
            {buttonLabel ? (
              <button className="mobile-right-chevron" onClick={onClick}>
                {buttonLabel}
              </button>
            ) : (
              <FontAwesomeIcon
                className="mobile-right-chevron"
                icon={faChevronRight as IconProp}
                onClick={onClick}
              />
            )}
          </div>
        ) : null)}
    </div>
  );
};

export default ReviewSummaryRow;
