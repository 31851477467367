import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Divider,
  ListItem,
  ListSubheader,
  Popper,
  PopperProps,
  TextField,
} from "@material-ui/core";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import { IconComponent, IconName } from "../../index";
import "./styles.scss";

export type CategoryType<ValueType> = {
  results: ValueType[];
  label: string;
  category: string;
};

export type OptionType<ValueType> = ValueType & {
  categoryLabel: string;
};

export interface IB2BSearchAutocompleteProps<ValueType> {
  value: ValueType | null;
  valueCategories: CategoryType<ValueType>[] | null;
  setValue: (value: ValueType | null) => void;
  fetchValueCategories: (queryString: string) => void;
  loading?: boolean;
  loadingText?: string;
  className?: string;
  hideIcon?: boolean;
  autoFocus?: boolean;
  openOnFocus?: boolean;
  keepOpen?: boolean;
  icon?: IconProp;
  customIcon?: JSX.Element;
  topContent?: JSX.Element;
  afterSetValue?: () => void;
  afterSetValidValue?: () => void;
  initialInputValue?: string;
  overrideInputValue?: string;
  getOptionSelected: (
    option: OptionType<ValueType>,
    value: ValueType
  ) => boolean;
  hideFloatingLabel?: boolean;
  label: string;
  popperClassName?: string;
  disabled?: boolean;
  noOptionsText?: JSX.Element;
  isComboBox?: boolean;
  onBlur?: () => void;
  onClose?: () => void;
  onFocus?: () => void;
  onClick?: () => void;
  customClearIcon?: JSX.Element;
  popupIcon?: JSX.Element;
  noTabIndex?: boolean;
  disablePortal?: boolean;
  freeSolo?: boolean;
}

export const B2BSearchAutocomplete = <
  ValueType extends {
    label: string;
  }
>(
  props: IB2BSearchAutocompleteProps<ValueType>
): JSX.Element => {
  const {
    loading,
    loadingText,
    className,
    value,
    valueCategories,
    hideIcon,
    autoFocus,
    openOnFocus,
    icon,
    customIcon,
    topContent,
    setValue,
    afterSetValue,
    afterSetValidValue,
    getOptionSelected,
    fetchValueCategories,
    initialInputValue,
    overrideInputValue,
    hideFloatingLabel,
    label,
    popperClassName,
    disabled,
    noOptionsText,
    isComboBox,
    onBlur,
    onClose,
    onFocus,
    customClearIcon,
    popupIcon,
    keepOpen,
    onClick,
    noTabIndex,
    disablePortal,
    freeSolo,
  } = props;

  const { t } = useI18nContext();

  const getOptionLabel = (option: OptionType<ValueType>) => option.label;

  const AutocompleteCustomPopper = (props: PopperProps) => {
    return (
      <Popper
        disablePortal={disablePortal}
        {...props}
        className={clsx("autocomplete-popper", popperClassName)}
        placement={
          popperClassName === "mobile" ? "right-start" : "bottom-start"
        }
      />
    );
  };

  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState(
    value ? value.label : initialInputValue || ""
  );

  const handleSelection = useCallback(
    (_: React.ChangeEvent<unknown>, value: ValueType | null): void => {
      if (!disabled) {
        setValue(value);
        afterSetValue && afterSetValue();
        value?.label && afterSetValidValue && afterSetValidValue();
      }
    },
    [afterSetValidValue, afterSetValue, disabled, setValue]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<unknown>, inputValue: string) => {
      if (!disabled) {
        if (inputValue.length > 0 && e && e.type === "change") {
          fetchValueCategories(inputValue);
        }

        if (e) setInputValue(inputValue);
      }
    },
    [disabled, fetchValueCategories]
  );

  const handleClear = useCallback(() => {
    if (!disabled) {
      fetchValueCategories("");
      setInputValue("");
      setValue(null);
      afterSetValue && afterSetValue();
    }
  }, [afterSetValue, disabled, fetchValueCategories, setValue]);

  const options = useMemo(() => {
    if (valueCategories?.length > 0) {
      return valueCategories.flatMap((category) => {
        const { results, label: categoryLabel } = category;

        return results.map((result) => ({
          ...result,
          categoryLabel,
        }));
      });
    }

    return [];
  }, [valueCategories]);

  // Updates the inputValue when value (e.g.: origin / destination) is parsed and set in the consumer
  useEffect(() => {
    if (overrideInputValue) setInputValue(overrideInputValue);
    else if (value) {
      setInputValue(value.label);
    } else {
      setInputValue("");
    }
  }, [value, overrideInputValue]);

  const chosenOption = value
    ? options.find((option) => getOptionSelected(option, value)) ?? value
    : null;

  const renderGroup = useCallback(
    (params: any) => {
      const renderTopContent = () => {
        if (topContent && params.key === 0) {
          return (
            <>
              <ListItem>{topContent}</ListItem>
              <Divider />
            </>
          );
        }
        return null;
      };
      return [
        <div key={params.group}>
          {renderTopContent()}
          <ListSubheader key={params.key} component="div">
            {params.group}
          </ListSubheader>
        </div>,
        params.children,
      ];
    },
    [topContent]
  );

  const autoCompleteInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      return (
        <B2BAutoCompleteInput
          {...{
            ...params,
            hideIcon,
            autoFocus,
            icon,
            customIcon,
            label,
            hideFloatingLabel,
            disabled: !!disabled,
            ariaExpanded:
              (!disabled &&
                !overrideInputValue &&
                inputValue.length > 0 &&
                (!value || value?.label !== inputValue)) ||
              (options.length > 1 && focused) ||
              (!!overrideInputValue && inputValue.length > 0 && focused),
            handleClear: handleClear,
            customClearIcon,
            noTabIndex,
          }}
        />
      );
    },
    [
      autoFocus,
      customClearIcon,
      customIcon,
      disabled,
      focused,
      handleClear,
      hideFloatingLabel,
      hideIcon,
      icon,
      inputValue,
      label,
      noTabIndex,
      options.length,
      overrideInputValue,
      value,
    ]
  );

  const onBlurAutocomplete = useCallback(() => {
    if (onBlur) onBlur();
  }, [onBlur]);

  const onCloseAutoComplete = useCallback(() => {
    onClose && onClose();
    if (!disabled) setFocused(false);
  }, [disabled, onClose]);

  const onFocusAutocomplete = useCallback(() => {
    if (!disabled) setFocused(true);
    if (onFocus) onFocus();
  }, [disabled, onFocus]);

  const openAutocomplete = useMemo(() => {
    return (
      (!disabled &&
        focused &&
        !overrideInputValue &&
        inputValue.length > 0 &&
        (!value || value?.label !== inputValue)) ||
      (options.length > 1 && keepOpen) ||
      (options.length > 1 && focused) ||
      (!!overrideInputValue && inputValue.length > 0 && focused)
    );
  }, [
    disabled,
    focused,
    inputValue,
    keepOpen,
    options.length,
    overrideInputValue,
    value,
  ]);

  return (
    <Box className={clsx("terminus-search", className)}>
      <Autocomplete
        autoComplete
        disableClearable
        value={chosenOption}
        inputValue={inputValue}
        onChange={handleSelection}
        open={openAutocomplete}
        popupIcon={popupIcon}
        PopperComponent={AutocompleteCustomPopper}
        onInputChange={handleInputChange}
        onBlur={onBlurAutocomplete}
        onFocus={onFocusAutocomplete}
        onClose={onCloseAutoComplete}
        openOnFocus={openOnFocus}
        filterOptions={(options) => options}
        className="terminus-search"
        loading={loading}
        loadingText={loadingText || ""}
        options={options}
        groupBy={(option: OptionType<ValueType>) => option.categoryLabel}
        getOptionLabel={getOptionLabel}
        freeSolo={freeSolo}
        renderGroup={renderGroup}
        renderInput={autoCompleteInput}
        disabled={disabled}
        noOptionsText={noOptionsText}
        onClick={onClick}
        // Accessibility Props
        role={isComboBox ? "combobox" : undefined}
        aria-expanded={undefined}
        aria-label={label}
        aria-autocomplete="list"
        aria-owns="options"
      />
      <span id="initInstr" style={{ display: "none" }}>
        {t("autocompleteAriaHelperText")}
      </span>
      <div aria-live="assertive" className="screen-reader-text"></div>
    </Box>
  );
};

export interface IB2BAutoCompleteInputProps
  extends AutocompleteRenderInputParams {
  autoFocus?: boolean;
  hideIcon?: boolean;
  icon?: IconProp;
  customIcon?: JSX.Element;
  hideFloatingLabel?: boolean;
  label: string;
  ariaExpanded?: boolean;
  handleClear: () => void;
  customClearIcon?: JSX.Element;
  noTabIndex?: boolean;
}

const B2BAutoCompleteInput = (props: IB2BAutoCompleteInputProps) => {
  const {
    InputProps: {
      endAdornment = null,
      startAdornment = null,
      ...defaultInputProps
    },
    autoFocus,
    hideIcon,
    icon,
    customIcon,
    hideFloatingLabel,
    label,
    ariaExpanded,
    handleClear,
    customClearIcon,
    noTabIndex,
    ...defaultProps
  } = props;

  const [focused, setFocused] = useState<boolean>(false);
  const onFocus = useCallback(
    () => !defaultProps.disabled && setFocused(true),
    [defaultProps.disabled]
  );
  const onBlur = useCallback(
    () => !defaultProps.disabled && setFocused(false),
    [defaultProps.disabled]
  );
  const {
    inputProps: { value },
  }: { inputProps: any } = useMemo(() => defaultProps, [defaultProps]);

  defaultProps.inputProps = {
    ...defaultProps.inputProps,
    role: "combobox",
    "aria-autocomplete": "list",
    "aria-expanded": ariaExpanded,
    "aria-haspopup": true,
    "aria-describedby": "initInstr",
  };

  return (
    <Box
      className={`b2b-autocomplete-input ${focused && "focused"}`}
      tabIndex={noTabIndex ? undefined : 0}
    >
      {!hideIcon && (
        <Box className={clsx("b2b-autocomplete-input-icon-container")}>
          {customIcon ?? (
            <FontAwesomeIcon
              className="icon"
              icon={icon ?? (faMapMarkerAlt as IconProp)}
            />
          )}
        </Box>
      )}
      <TextField
        {...defaultProps}
        fullWidth={true}
        name="terminus-input"
        label={hideFloatingLabel ? null : label}
        autoFocus={autoFocus}
        InputProps={{
          endAdornment,
          startAdornment,
          disableUnderline: true,
        }}
        value={value || ""}
        {...defaultInputProps}
        className={clsx("b2b-autocomplete-input-text")}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <button
        className={clsx("b2b-autocomplete-input-clear-button")}
        onClick={() => handleClear()}
        type={"button"}
      >
        {customClearIcon ? (
          customClearIcon
        ) : (
          <IconComponent
            ariaLabel="Close button icon"
            className={clsx("close-button-icon")}
            name={IconName.Close}
          />
        )}
      </button>
    </Box>
  );
};
