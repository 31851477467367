import { History } from "history";
import { GetPriceFreezeResponseV2 } from "@b2bportal/price-freeze-api";
import {
  Offer,
  CallState,
  IPassengerCounts,
  PriceFreezePriceQuote,
} from "@hopper-b2b/types";
import * as actionTypes from "./constants";

export const setPriceFreezeOffer = (args: {
  priceFreezeOfferCallState: CallState;
  offer: Offer | null;
}): ISetPriceFreezeOffer => ({
  type: actionTypes.SET_PRICE_FREEZE_OFFER,
  ...args,
});

export interface ISetPriceFreezeOffer {
  type: actionTypes.SET_PRICE_FREEZE_OFFER;
  priceFreezeOfferCallState: CallState;
  offer: Offer | null;
}

export const getPriceFreezeOffer = (offerId: string): IGetPriceFreezeOffer => ({
  type: actionTypes.GET_PRICE_FREEZE_OFFER,
  offerId,
});

export interface IGetPriceFreezeOffer {
  type: actionTypes.GET_PRICE_FREEZE_OFFER;
  offerId: string;
}

export const setPriceFreezeOfferCallStateFailed =
  (): ISetPriceFreezeOfferCallStateFailed => ({
    type: actionTypes.SET_PRICE_FREEZE_OFFER_CALL_STATE_FAILED,
  });

export interface ISetPriceFreezeOfferCallStateFailed {
  type: actionTypes.SET_PRICE_FREEZE_OFFER_CALL_STATE_FAILED;
}

export const setUpFlightFreezeParams = (args: {
  history: History;
  isMobile?: boolean;
}): ISetUpFlightFreezeParams => ({
  type: actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS,
  ...args,
});

export interface ISetUpFlightFreezeParams {
  type: actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS;
  history: History;
  isMobile?: boolean;
}

export const setUpFlightFreezeParamsCallStateFailed =
  (): ISetUpFlightFreezeParamsCallStateFailed => ({
    type: actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_FAILED,
  });

export interface ISetUpFlightFreezeParamsCallStateFailed {
  type: actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_FAILED;
}

export const setUpFlightFreezeParamsCallStateSucceeded =
  (): ISetUpFlightFreezeParamsCallStateSucceeded => ({
    type: actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_SUCCEEDED,
  });

export interface ISetUpFlightFreezeParamsCallStateSucceeded {
  type: actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_SUCCEEDED;
}

export const setPassengerCounts = (
  counts: IPassengerCounts
): ISetPassengerCounts => ({
  type: actionTypes.SET_PASSENGER_COUNTS,
  counts,
});

export interface ISetPassengerCounts {
  type: actionTypes.SET_PASSENGER_COUNTS;
  counts: IPassengerCounts;
}

export const setPriceFreezeQuoteData = (
  quoteData: PriceFreezePriceQuote
): ISetPriceFreezeQuoteData => ({
  type: actionTypes.SET_PRICE_FREEZE_QUOTE_DATA,
  quoteData,
});

export interface ISetPriceFreezeQuoteData {
  type: actionTypes.SET_PRICE_FREEZE_QUOTE_DATA;
  quoteData: PriceFreezePriceQuote;
}

export const resetPriceFreezePurchase = (): IResetPriceFreezePurchase => ({
  type: actionTypes.RESET_PRICE_FREEZE_PURCHASE,
});

export interface IResetPriceFreezePurchase {
  type: actionTypes.RESET_PRICE_FREEZE_PURCHASE;
}

export type FlightFreezeActions =
  | IGetPriceFreezeOffer
  | ISetPriceFreezeOffer
  | ISetPriceFreezeOfferCallStateFailed
  | ISetUpFlightFreezeParams
  | ISetUpFlightFreezeParamsCallStateFailed
  | ISetUpFlightFreezeParamsCallStateSucceeded
  | ISetPassengerCounts
  | ISetPriceFreeze
  | ISetPriceFreezeCallStateFailed
  | IFetchPriceFreeze
  | IRefundPriceFreeze
  | ISetRefundPriceFreeze
  | IDisplayRefundPriceFreezeModal
  | IGetPriceFreeze
  | ISetPriceFreezeQuoteData
  | IResetPriceFreezePurchase;

export interface IFetchPriceFreeze {
  type: actionTypes.FETCH_PRICE_FREEZE;
  priceFreezeId: string;
}

export const fetchPriceFreeze = (priceFreezeId: string): IFetchPriceFreeze => ({
  type: actionTypes.FETCH_PRICE_FREEZE,
  priceFreezeId,
});

export interface IGetPriceFreeze {
  type: actionTypes.GET_PRICE_FREEZE;
  frozenPriceId: string;
}

export const getPriceFreeze = (frozenPriceId: string): IGetPriceFreeze => ({
  type: actionTypes.GET_PRICE_FREEZE,
  frozenPriceId,
});

export interface IRefundPriceFreeze {
  type: actionTypes.REFUND_PRICE_FREEZE;
  frozenPriceId: string;
}

export const refundPriceFreeze = (
  frozenPriceId: string
): IRefundPriceFreeze => ({
  type: actionTypes.REFUND_PRICE_FREEZE,
  frozenPriceId,
});

export interface IDisplayRefundPriceFreezeModal {
  type: actionTypes.DISPLAY_PF_REFUND_MODAL_FLOW;
  value: boolean;
}

export const setDisplayPriceFreezeRefundModal = (
  value: boolean
): IDisplayRefundPriceFreezeModal => ({
  type: actionTypes.DISPLAY_PF_REFUND_MODAL_FLOW,
  value,
});

export interface ISetRefundPriceFreeze {
  type: actionTypes.SET_REFUND_PRICE_FREEZE;
  refundPriceFreezeCallState: CallState;
}

export const setRefundPriceFreeze = (args: {
  refundPriceFreezeCallState: CallState;
}): ISetRefundPriceFreeze => ({
  type: actionTypes.SET_REFUND_PRICE_FREEZE,
  ...args,
});

export interface ISetPriceFreeze {
  type: actionTypes.SET_PRICE_FREEZE;
  priceFreeze: GetPriceFreezeResponseV2;
  priceFreezeCallState: CallState;
}

export const setPriceFreeze = (args: {
  priceFreezeCallState: CallState;
  priceFreeze: GetPriceFreezeResponseV2;
}): ISetPriceFreeze => ({
  type: actionTypes.SET_PRICE_FREEZE,
  ...args,
});

export interface ISetPriceFreezeCallStateFailed {
  type: actionTypes.SET_PRICE_FREEZE_CALL_STATE_FAILED;
}

export const setPriceFreezeCallStateFailed =
  (): ISetPriceFreezeCallStateFailed => ({
    type: actionTypes.SET_PRICE_FREEZE_CALL_STATE_FAILED,
  });
