import { Box, Tooltip, Typography, TypographyProps } from "@material-ui/core";
import clsx from "clsx";
import { isString } from "lodash-es";
import { ReactNode } from "react";

import { useI18nContext } from "@hopper-b2b/i18n";

import { ImportantInfoList } from "../ImportantInfoList";
import { BannerSeverity, NotificationBanner } from "../NotificationBanner";

import "./styles.scss";

export interface IFlightCancelationInfoProps {
  ItinerarySummary?: ReactNode;
  actions?: ReactNode | ReactNode[];
  className?: string;
  disclaimer?: string;
  infoItems: string[];
  subtitle?: ReactNode;
  subtitleProps?: TypographyProps;
  tcHelpProps?: TypographyProps;
  tcHelpText?: ReactNode;
  title?: ReactNode;
  titleProps?: TypographyProps;
}

const defaultProps: Partial<IFlightCancelationInfoProps> = {
  ItinerarySummary: null,
  infoItems: [],
};

/**
 * @description Renders a title, optional notification, optional text,
 * itinerary as a row, optional cancelation info, and optional actions
 * as a left-justified column.
 * Can be used in a modal or any container element.
 * @param {IFlightCancelationInfoProps} props
 * @return {JSX.Element}
 */
const FlightCancelationInfo = (
  props: IFlightCancelationInfoProps
): JSX.Element => {
  const {
    ItinerarySummary,
    actions,
    className,
    disclaimer,
    infoItems,
    subtitleProps,
    tcHelpProps,
    title,
    titleProps,
  } = props;
  const { t } = useI18nContext();
  const updatedSubtitleProps: TypographyProps = subtitleProps ?? {};
  let { subtitle, tcHelpText } = props;

  // if subtitle is string[], format to show as separate sentences
  if (
    subtitle &&
    Array.isArray(subtitle) &&
    subtitle.every((s) => isString(s)) &&
    !subtitleProps?.dangerouslySetInnerHTML
  ) {
    updatedSubtitleProps.dangerouslySetInnerHTML = {
      __html: subtitle.join(" "),
    };
    subtitle = null;
  }

  if (Array.isArray(tcHelpText)) {
    tcHelpText = tcHelpText.join(" ");
  }

  return (
    <Box className={clsx(className, "flight-cancelation-info-container")}>
      <Typography
        variant="h2"
        {...titleProps}
        className={clsx("modal-title", titleProps?.className)}
      >
        {title || ""}
      </Typography>
      {disclaimer && (
        <NotificationBanner
          html={disclaimer}
          severity={BannerSeverity.WARNING}
        />
      )}
      {(subtitle || updatedSubtitleProps) && (
        <Typography
          {...updatedSubtitleProps}
          className={clsx("subtitle", updatedSubtitleProps?.className)}
        >
          {subtitle}
        </Typography>
      )}
      {tcHelpText && (
        <Box className="tc-help-container">
          <Tooltip
            PopperProps={{ disablePortal: true }}
            classes={{
              popper: "how-tc-works-popper",
              tooltip: "how-tc-works-tooltip",
            }}
            placement="bottom"
            title={
              <Typography
                {...tcHelpProps}
                className={clsx("tc-help-text", tcHelpProps?.className)}
              >
                {tcHelpText}
              </Typography>
            }
          >
            <span className="how-tc-works-label">
              {t("selfServe.howTcWorks")}
            </span>
          </Tooltip>
        </Box>
      )}
      {ItinerarySummary}
      {infoItems.length ? (
        <Box className="info-items-container">
          <ImportantInfoList
            infoItems={infoItems}
            title={t("cancelFlightModal.infoItemsTitle") || ""}
          />
        </Box>
      ) : null}
      {actions && <Box className="actions-container">{actions}</Box>}
    </Box>
  );
};

FlightCancelationInfo.defaultProps = defaultProps;

export default FlightCancelationInfo;
