import { AirCfarOffer, TrackingProperties } from "@b2bportal/air-cfar-api";
import { CallState } from "@hopper-b2b/types";
import { actionTypes, actions } from "../actions";

export interface IFlightCfarState {
  cfarOffersCallState: CallState;
  cfarOffersTrackingProperties?: TrackingProperties;
  cfarOffers?: AirCfarOffer[];
  selectedCfarOffer?: AirCfarOffer | null;
}

export const initialState: IFlightCfarState = {
  cfarOffersCallState: CallState.NotCalled,
  cfarOffersTrackingProperties: undefined,
  cfarOffers: undefined,
  selectedCfarOffer: undefined,
};

export function reducer(
  state: IFlightCfarState = initialState,
  action: actions.FlightCfarActions
): IFlightCfarState {
  switch (action.type) {
    case actionTypes.FETCH_CFAR_OFFERS:
      return {
        ...initialState,
        cfarOffersCallState: CallState.InProcess,
      };

    case actionTypes.SET_CFAR_OFFERS:
      return {
        ...state,
        cfarOffers: action.offers,
        cfarOffersCallState: CallState.Success,
        selectedCfarOffer: undefined,
        cfarOffersTrackingProperties: action.trackingProperties,
      };

    case actionTypes.SET_CFAR_OFFERS_FAILED:
      return {
        ...state,
        cfarOffersCallState: CallState.Failed,
      };

    case actionTypes.SET_SELECTED_CFAR_OFFER:
      return {
        ...state,
        selectedCfarOffer: action.selectedCfarOffer,
      };

    default:
      return state;
  }
}

export * from "./selectors";
