import { useI18nextContext } from "../I18nProvider";
import {
  ClientName,
  Currency,
  FiatPrice,
  I18nNamespace,
  RewardsPrice,
  TranslationLanguage,
} from "@hopper-b2b/types";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { getLang } from "../utils/getLang";
import { formatCurrencyWithOptions as hopperFormatCurrency } from "@hopper-i18n/formatter";

type CurrencyConfig = {
  locale: string;
  code: string;
  symbolOverride?: string;
  localeOverride?: string;
};

export function useI18nCurrency() {
  const ctx = useI18nextContext();
  if (ctx === undefined) throw new Error(`must be used within a I18nProvider`);
  const { t } = ctx;

  const tenant = getEnvVariables("clientName");
  const isHopper = tenant === ClientName.HOPPER;

  // Use the `t` function to let i18next select the right locale to work with
  const currencies = t(`${I18nNamespace.brand}:currencies`, {
    returnObjects: true,
  }) as unknown as CurrencyConfig[];
  const currency = t(`${I18nNamespace.brand}:currency`, {
    returnObjects: true,
  }) as unknown as CurrencyConfig;

  // `t` function can return the translation key string if the key was not found.
  // `currenies` and `currency` above are not meant to be used as a string,
  // so check each of them here and undefine it if it was a string
  const supportedCurrencies: CurrencyConfig[] | undefined =
    typeof currencies === "string" ? undefined : currencies;
  const selectedCurrency: CurrencyConfig | undefined =
    typeof currency === "string" ? undefined : currency;

  let locale = selectedCurrency?.locale ?? TranslationLanguage.en;
  if (isHopper) {
    locale = getLang(locale, true);
  }

  /**
   * @deprecated
   * always use formatFiatCurrency
   */
  const formatCurrency = (
    value: number,
    options?: Intl.NumberFormatOptions
  ) => {
    const code = selectedCurrency?.code || Currency.USD;
    const fiatAmount: Omit<FiatPrice, "currencySymbol"> = {
      value,
      currencyCode: code,
    };
    return formatFiatCurrency(fiatAmount, options);
  };

  // Use function overloading to enforce that a formatted price will always be
  // returned when a `fiatPrice` is specified.
  function formatFiatCurrency(
    fiatPrice: Omit<FiatPrice, "currencySymbol">,
    options?: Intl.NumberFormatOptions
  ): string;
  function formatFiatCurrency(
    fiatPrice?: Omit<FiatPrice, "currencySymbol">,
    options?: Intl.NumberFormatOptions
  ): string | undefined;
  function formatFiatCurrency(
    fiatPrice?: Omit<FiatPrice, "currencySymbol">,
    options?: Intl.NumberFormatOptions
  ): string | undefined {
    if (fiatPrice == null) return;
    const currencyConfig = supportedCurrencies?.find(
      (c) => c.code === fiatPrice.currencyCode
    );

    // https://hopper-jira.atlassian.net/wiki/spaces/IEP/pages/6205308931/Tenant-specific+currency+symbols
    // `brandingSymbol` may become undefined in most cases (see the LegacySymbolOverrides table below)
    // and it is fine to pass undefined to `hopperFormatCurrency` function below.
    const brandingSymbol: string | undefined = currencyConfig?.symbolOverride;
    const brandingLocale: string | undefined = currencyConfig?.localeOverride;
    return hopperFormatCurrency({
      currencyCode: fiatPrice.currencyCode,
      locale: brandingLocale ?? locale,
      amount: fiatPrice.value,
      symbol: brandingSymbol,
      options,
    });
  }

  // Use function overloading to enforce that a formatted price will always be
  // returned when a `rewardsPrice` is specified.
  function formatRewardsCurrency(
    rewardsPrice: RewardsPrice,
    options?: Intl.NumberFormatOptions
  ): string;
  function formatRewardsCurrency(
    rewardsPrice?: RewardsPrice,
    options?: Intl.NumberFormatOptions
  ): string | undefined;
  function formatRewardsCurrency(
    rewardsPrice?: RewardsPrice,
    options?: Intl.NumberFormatOptions
  ): string | undefined {
    if (!rewardsPrice) return;
    const formattedAmount = new Intl.NumberFormat(locale, options).format(
      rewardsPrice.value
    );
    return (
      t(`${I18nNamespace.brand}:rewardsPriceFormatterKey`, {
        amount: formattedAmount,
        unitLabel: rewardsPrice.currency,
      }) ?? ""
    );
  }

  return { formatCurrency, formatFiatCurrency, formatRewardsCurrency };
}
