import { TripAdvisorComment } from "@b2bportal/lodging-api";
import { TripAdvisorBadge } from "@hopper-b2b/ui";
import { Typography } from "@material-ui/core";
import { useTrimmedString } from "../../../../hooks/useTrimmedString";
import styles from "./Comment.module.scss";

export function Comment({
  title,
  text,
  author,
  created,
  score,
}: TripAdvisorComment) {
  const { trimmedString } = useTrimmedString(text);

  return (
    <article className={styles.CommentContainer}>
      <Typography className={styles.Title} variant="body2" component="h4">
        “{title}”
      </Typography>
      <TripAdvisorBadge
        translateToTheLeft
        withTripAdvisorIcon
        scoreOutOfFive={score * 5}
      />
      <Typography className={styles.Text}>{trimmedString}</Typography>
      <Typography className={styles.Text}>
        {author}, {created}
      </Typography>
    </article>
  );
}
