// These keys are used for redux state only
// Ensure the keys in FARE_CLASSES, FARE_CLASS_I18N_KEY and FARE_CLASS_DETAILS_I18N_KEY
// are the same by using the FARES type

type FARES = {
  basic: string;
  standard: string;
  enhanced: string;
  premium: string;
  luxury: string;
};

export const FARE_CLASSES: FARES = {
  basic: "Basic",
  standard: "Standard",
  enhanced: "Enhanced",
  premium: "Premium",
  luxury: "Luxury",
};

export const FARE_CLASS_I18N_KEY: FARES = {
  basic: "fareClass.basicLabel",
  standard: "fareClass.standardLabel",
  enhanced: "fareClass.enhancedLabel",
  premium: "fareClass.premiumLabel",
  luxury: "fareClass.luxuryLabel",
};

export const MOBILE_FARE_I18N_KEY = {
  all: "fareClass.all",
  ...FARE_CLASS_I18N_KEY,
};

export const FARE_CLASS_DETAILS_I18N_KEY: FARES = {
  basic: "fareClass.basicDetails",
  standard: "fareClass.standardDetails",
  enhanced: "fareClass.enhancedDetails",
  premium: "fareClass.premiumDetails",
  luxury: "fareClass.luxuryDetails",
};
