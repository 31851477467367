import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import clsx from "clsx";

import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionButton, MobileFloatingButton } from "@hopper-b2b/ui";
import { ContactInfoForm, IContactInfo } from "@hopper-b2b/checkout";
import { emailRegex, getEnvVariables, phoneRegex } from "@hopper-b2b/utilities";

import { PATH_DISRUPTION_EXERCISE_REBOOK_REVIEW } from "../../../../../utils/paths";
import {
  populateTripQueryParams,
  setDisruptionExerciseProgress,
} from "../../../../TripsList/actions/actions";
import { DisruptionExerciseProgress } from "@hopper-b2b/types";

export interface IDisruptionContactInfoFormProps {
  contactInfo: IContactInfo;
  updateContactInfo: (changeInfo: IContactInfo) => void;
  disableFloatingButton?: boolean;
}

export const DisruptionContactInfoForm = ({
  contactInfo,
  updateContactInfo,
  disableFloatingButton,
}: IDisruptionContactInfoFormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useI18nContext();

  const isUber = getEnvVariables("clientName") === "uber";

  const hasContactInfo = useMemo((): boolean => {
    return (
      !!contactInfo?.email &&
      !!contactInfo?.phoneNumber &&
      emailRegex.test(contactInfo?.email) &&
      phoneRegex.test(contactInfo?.phoneNumber.replace(/\D/g, ""))
    );
  }, [contactInfo]);

  // TODO: Update disruption exercise progress
  const contactInfoFormContinue = useCallback(() => {
    if (isUber) {
      const newHistory = dispatch(populateTripQueryParams(history)).history;
      history.replace(
        PATH_DISRUPTION_EXERCISE_REBOOK_REVIEW + newHistory.location.search
      );
    } else {
      dispatch(populateTripQueryParams(history));
      dispatch(
        setDisruptionExerciseProgress(DisruptionExerciseProgress.RebookReview)
      );
    }
  }, [dispatch, history, isUber]);

  return (
    <>
      <ContactInfoForm
        className="disruption-exercise-contact-info-form"
        isMobile
        title={t("contactInformation")}
        subtitle={t("contactInfoSubtitle")}
        contactInfo={contactInfo}
        onContactInfoChange={updateContactInfo}
      />
      {disableFloatingButton ? (
        <ActionButton
          className="mobile-contact-info-form-save-button disruption-exercise-contact-info-form-save-button"
          message={t("continue")}
          onClick={contactInfoFormContinue}
        />
      ) : (
        <MobileFloatingButton
          className="mobile-contact-info-form-save-button disruption-exercise-contact-info-form-save-button"
          wrapperClassName={clsx(
            "mobile-contact-info-form-save-button-container",
            "disruption-exercise-contact-info-form-save-button-container"
          )}
          onClick={contactInfoFormContinue}
          disabled={!hasContactInfo}
          wide
        >
          {t("continue")}
        </MobileFloatingButton>
      )}
    </>
  );
};
