import { put } from "redux-saga/effects";
import { actions } from "../../actions";
import {
  fetchUserPassengers,
  IListUserPassengersResponse,
} from "../../../../api/v1/book/user-passengers/fetchUserPassengers";
import { CallState } from "@hopper-b2b/types";

export function* fetchUserPassengersSaga(_: actions.IFetchUserPassengers) {
  try {
    const userPassengers: IListUserPassengersResponse =
      yield fetchUserPassengers({});

    yield put(
      actions.setUserPassengers({
        userPassengerCallState: CallState.Success,
        userPassengers: userPassengers,
      })
    );
  } catch (e) {
    yield put(actions.setUserPassengersCallStateFailed());
  }
}
