import axios from "axios";

import { paymentMethodsApiPrefix } from "../../paths";
import { IApiConfig, NETWORK_CALL_FAILED, Payment } from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";

const END_POINT = `${paymentMethodsApiPrefix}/list`;

export const listPaymentMethods = (
  apiConfig?: IApiConfig
): Promise<IListPaymentMethodsResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(END_POINT, {});

      const responseBody: Payment[] = res.data.value;
      resolve(responseBody);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });

export type IListPaymentMethodsResponse = Array<Payment>;
