import { useI18nContext } from "@hopper-b2b/i18n";
import {
  AppBar,
  Container,
  Grid,
  Toolbar,
  Typography,
} from "@material-ui/core";
import config from "../../utils/config";
import * as constants from "./constants";
import { Routes, Route } from "react-router-dom-v5-compat";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import "./styles.scss";
import { ApacModalLink, ApacTermsLink } from "@commbank/ui";

export const Footer = () => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const FooterBar = () => (
    <AppBar
      component="footer"
      position="relative"
      variant="outlined"
      color="inherit"
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters variant="dense">
          <Grid container alignItems="center">
            <Grid item>
              <Typography variant="body1">
                {constants.LEGAL(t("commBank.commBankBranding.copyright"))}
              </Typography>
            </Grid>
            <Grid item xs />
            <Grid item>
              <FooterLinks />
            </Grid>
            <Grid item xs />
            <Grid item>
              <Typography variant="caption">
                {config.REACT_APP_VERSION}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );

  return (
    <Routes>
      {/* Show footber bar for first-level pages for both mobile and desktop */}
      <Route path="/" element={<FooterBar />} />
      <Route path="/flights" element={<FooterBar />} />
      <Route path="/hotels" element={<FooterBar />} />
      <Route path="/cars" element={<FooterBar />} />
      {/* Show footber bar for subsequent pages for desktop only */}
      <Route path="/*" element={matchesMobile ? null : <FooterBar />} />
    </Routes>
  );
};

const FooterLinks = () => {
  const {
    t,
    brand: { urls },
  } = useI18nContext();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <ApacTermsLink showUnderline={false} className="subdued-link">
          {t("termsAndConditions")}
        </ApacTermsLink>
      </Grid>
      <Grid item>
        <ApacModalLink
          iframeTitle={t("privacyPolicy")}
          modalClassName="footer-privacy-modal"
          linkProps={{
            showUnderline: false,
            className: "subdued-link",
          }}
          url={urls.privacyPolicy}
        >
          {t("privacyPolicy")}
        </ApacModalLink>
      </Grid>
    </Grid>
  );
};
