/* eslint-disable prefer-const */
import { call, put, putResolve, select } from "redux-saga/effects";

import {
  LocationQuery,
  LocationQueryEnum,
  LocationResponse,
  PriceFreezeResultEnum,
  ShopSummaryResponseV4Response,
  TrackingProperties,
} from "@b2bportal/air-shopping-api";
import {
  fetchChfarFlights,
  fetchLocationAutocomplete,
  trackEvent,
} from "@hopper-b2b/api";
import {
  AIR_ENTRY,
  AirEntryProperties,
  FlightShopType,
  ITripTerminus,
  OfferWithSuggested,
  SliceStopCountFilter,
  TripCategory,
  VIEWED_FLIGHT_LIST,
} from "@hopper-b2b/types";
import dayjs from "dayjs";
import { History } from "history";
import { fetchFlights } from "../../../api/v4/shop/fetchFlights";
import Logger from "../../../helpers/Logger";
import { IStoreState, flightApiConfigStoreKey } from "../../../reducers/types";
import { PATH_HOME } from "../../../utils/urlPaths";
import { actions as searchActions } from "../../search/actions";
import { setMobileSearchProgress } from "../../search/actions/actions";
import { MobileFlightSearchStep } from "../../search/reducer";
import { actions } from "../actions";
import { IShopParams } from "../actions/actions";
import {
  getAirEntryProperties,
  getPriceFreezeOfferWithSuggested,
  getViewedForecastPropertiesForV2,
  maxFlightPriceSelectorV2,
} from "../reducer/selectors";
import { IFlightShopParsedQuery } from "../utils/parseQueryString";
import {
  getExistingStateVariables,
  populateFlightShopQueryParametersFromState,
} from "./populateShopQueryParamsSaga";

const shouldRedirect = ({
  origin,
  destination,
  departureDate,
  tripCategory,
  returnDate,
  stopsOption,
}: any) =>
  !origin ||
  !destination ||
  !departureDate ||
  !stopsOption ||
  (tripCategory === TripCategory.ROUND_TRIP && !returnDate);

export function* fetchFlightsV4(
  action: actions.IFetchFlightsV4
): Generator<any, any, any> {
  try {
    const {
      departureDate,
      returnDate,
      origin,
      destination,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      noLCC,
    } = yield call(setUpFlightShopParams, action.history);
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const requestBody: IShopParams = {
      origin: { ...origin.id.code },
      destination: { ...destination.id.code },
      ...(returnDate && {
        returnDate: dayjs(returnDate).format("YYYY-MM-DD"),
      }),
      departureDate: dayjs(departureDate).format("YYYY-MM-DD"),
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      fareclassOptionFilter: {
        basic: false,
        standard: noLCC,
        enhanced: noLCC,
        premium: noLCC,
        luxury: noLCC,
      },
    };

    const chfarId = state.flightShop.chfarId;
    const isChfarFlightShop =
      state.flightShop.flightShopType === FlightShopType.CHFAR_EXERCISE &&
      chfarId;
    const response: ShopSummaryResponseV4Response = isChfarFlightShop
      ? yield fetchChfarFlights(
          requestBody,
          chfarId,
          action.isMobile,
          apiConfig
        )
      : yield fetchFlights(requestBody, action.isMobile, apiConfig);

    if (response.Response === "Failure") {
      yield put(actions.setTripSummariesError());
      yield put(actions.setPredictionError());

      const properties: AirEntryProperties | null = yield select(
        getAirEntryProperties
      );
      yield trackEvent(
        {
          eventName: AIR_ENTRY,
          properties: {
            ...properties,
            customer_credit_balance_aud: action.creditBalance?.value,
          },
        },
        apiConfig
      );
    } else {
      // note: the order does matter here! having redux set priceFreezeOffer first will allow it to update priceFreezeOffer
      // related selectors prior to that of the prediction
      yield putResolve(actions.setFlights(response.value.flights));
      const trackingProps = response.value?.trackingProperties;
      const properties: AirEntryProperties | null = yield select(
        getAirEntryProperties
      );
      yield trackEvent(
        {
          eventName: AIR_ENTRY,
          properties: {
            ...properties,
            customer_credit_balance_aud: action.creditBalance.value,
            ...(trackingProps ? trackingProps.properties : {}),
          },
          encryptedProperties: trackingProps?.encryptedProperties
            ? [trackingProps.encryptedProperties]
            : [],
        },
        apiConfig
      );

      const priceFreezeOffer =
        response.value.priceFreezeOffer?.PriceFreezeResult ===
        PriceFreezeResultEnum.PriceFreezeAvailable
          ? response.value.priceFreezeOffer.offer
          : null;

      yield putResolve(actions.setPriceFreezeOffer(priceFreezeOffer));

      yield putResolve(actions.setPrediction(response.value.prediction));

      yield putResolve(actions.setFlightShopOffers(response.value.offers));

      const priceFreeze: OfferWithSuggested | null = yield select(
        getPriceFreezeOfferWithSuggested
      );
      if (priceFreeze) {
        yield put(actions.fetchTripDetails(priceFreeze.cheapestTrip.tripId));
      }
      const maxPriceFlight: number = yield select(maxFlightPriceSelectorV2);
      yield put(searchActions.setMaxPriceFilter(maxPriceFlight));

      yield put(searchActions.setAwaitingRefetch(false));

      const viewedForecastProperties = yield select(
        getViewedForecastPropertiesForV2
      );
      trackEvent(
        {
          eventName: VIEWED_FLIGHT_LIST,
          properties: {
            ...viewedForecastProperties,
            ...(trackingProps ? trackingProps.properties : {}),
          },
          encryptedProperties: trackingProps?.encryptedProperties
            ? [trackingProps.encryptedProperties]
            : [],
        },
        apiConfig
      );
    }
  } catch (e) {
    yield put(actions.setTripSummariesError());
    yield put(actions.setPredictionError());
    Logger.debug(e);
  }
}

export function* setUpFlightShopParams(history: History) {
  const state: IStoreState = yield select();

  let {
    departureDate,
    returnDate,
    origin,
    destination,
    tripCategory,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    stopsOption,
    noLCC,
  } = getExistingStateVariables(state);

  const parsedQueryString: IFlightShopParsedQuery =
    yield populateFlightShopQueryParametersFromState({
      state,
      history,
      isV2: true,
    });

  if (
    !origin ||
    !destination ||
    !departureDate ||
    (tripCategory === TripCategory.ROUND_TRIP && !returnDate) ||
    // note: when it's from FlightWatch, it should treat parsedQueryString as the source of truth.
    parsedQueryString.isFromFlightWatch
  ) {
    const { correspondingDestination, correspondingOrigin } =
      yield fetchOriginDestination(parsedQueryString);
    [
      origin,
      destination,
      departureDate,
      returnDate,
      tripCategory,
      stopsOption,
      noLCC,
    ] = [
      correspondingOrigin,
      correspondingDestination,
      parsedQueryString.departureDate,
      parsedQueryString.returnDate,
      parsedQueryString.tripCategory,
      parsedQueryString.stopsOption as SliceStopCountFilter,
      parsedQueryString.noLCC,
    ];

    // If we are missing the data we need from both the state and the query params
    // we should redirect the user home.
    if (
      shouldRedirect({
        departureDate,
        origin,
        destination,
        returnDate,
        tripCategory,
        stopsOption,
      })
    ) {
      yield putResolve(
        setMobileSearchProgress(MobileFlightSearchStep.LocationSearch)
      );
      history.push(PATH_HOME);
      return;
    }
    // Order here matters because of the reducer structure.
    // Set trip category resets the returnDate field.
    yield putResolve(searchActions.setTripCategory(tripCategory));
    yield putResolve(searchActions.setOrigin(origin));
    yield putResolve(searchActions.setDestination(destination));
    yield putResolve(searchActions.setDepartureDate(departureDate));
    yield putResolve(searchActions.setReturnDate(returnDate));
    yield putResolve(searchActions.setStopsOption(stopsOption));
    yield putResolve(
      searchActions.setFareclassOptionFilter({
        basic: false,
        standard: noLCC,
        enhanced: noLCC,
        premium: noLCC,
        luxury: noLCC,
      })
    );
  }

  return {
    departureDate,
    returnDate,
    origin,
    destination,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    stopsOption,
    noLCC,
  };
}

function* fetchOriginDestination(parsedQueryString: IFlightShopParsedQuery) {
  const originRequestBody: LocationQuery = {
    LocationQuery: LocationQueryEnum.Label,
    l: parsedQueryString.origin,
  };
  const state: IStoreState = yield select();
  const apiConfig = state[flightApiConfigStoreKey];
  const { categories: originCategories }: LocationResponse =
    yield fetchLocationAutocomplete(originRequestBody, apiConfig);
  const correspondingOrigin = originCategories
    .flatMap((category) => category.results)
    .find(
      (result) => result.id.code.code === parsedQueryString.origin
    ) as ITripTerminus;

  const destinationRequestBody: LocationQuery = {
    LocationQuery: LocationQueryEnum.Label,
    l: parsedQueryString.destination,
  };

  const { categories: destinationCategories }: LocationResponse =
    yield fetchLocationAutocomplete(destinationRequestBody, apiConfig);
  const correspondingDestination = destinationCategories
    .flatMap((category) => category.results)
    .find(
      (result) => result.id.code.code === parsedQueryString.destination
    ) as ITripTerminus;

  return { correspondingDestination, correspondingOrigin };
}
