import clsx from "clsx";
import { TripAdvisorCategory } from "@b2bportal/lodging-api";
import styles from "./ReviewsCategories.module.scss";
import { TripAdvisorBadge } from "@hopper-b2b/ui";
import { Box, Typography } from "@material-ui/core";

export function ReviewsCategories({
  categories,
  className,
}: {
  categories: TripAdvisorCategory[];
  className?: string;
}) {
  return (
    <div className={clsx(styles.Categories, className)}>
      {categories.map(({ categoryName, score }) => {
        return (
          <Box className={styles.CategorySection}>
            <TripAdvisorBadge
              translateToTheLeft
              withTripAdvisorIcon={false}
              scoreOutOfFive={score * 5}
            />
            <Typography>{categoryName}</Typography>
          </Box>
        );
      })}
    </div>
  );
}
