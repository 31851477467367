import { put, select } from "redux-saga/effects";
import { IStoreState, flightApiConfigStoreKey } from "../../../reducers/types";
import { actions } from "../actions";
import { fetchApplicableWalletOffers } from "@hopper-b2b/api";
import {
  GetApplicableWalletOffersResponseEnum,
  GetApplicableWalletOffersResponseSuccess,
  ProductSearchRequestEnum,
} from "@b2bportal/hopper-wallet-api";

export function* fetchWalletOffersSaga(action: actions.IFetchWalletOffers) {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];

    const response: GetApplicableWalletOffersResponseSuccess =
      yield fetchApplicableWalletOffers(
        {
          productSearchRequest: {
            tripId: action.tripId,
            fareId: action.fareId,
            ProductSearchRequest: ProductSearchRequestEnum.FlightSearchRequest,
          },
        },
        apiConfig
      );

    if (
      response.GetApplicableWalletOffersResponse ===
      GetApplicableWalletOffersResponseEnum.Success
    ) {
      const { offerChoiceResponse } =
        response as GetApplicableWalletOffersResponseSuccess;

      yield put(
        actions.setWalletOffers({
          offers: offerChoiceResponse.offers,
          creditOffer: offerChoiceResponse.creditOffer,
          bestOffer: offerChoiceResponse.bestOfferOverall,
        })
      );
    } else {
      yield put(actions.setWalletOffersFailed());
    }
  } catch (error) {
    yield put(actions.setWalletOffersFailed());
  }
}
