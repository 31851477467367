import { ID } from "../../apis/tysons/price-freeze";

export enum PriceFreezeTrackingEvents {
  FREEZE_PRICE_CLICK = "freeze_price_click",
  PRICE_FREEZE_REVIEW_DETAILS = "price_freeze_review_details",
  CHANGED_DURATION = "changed_duration",
  EDIT_TRAVELERS = "edit_travelers",
  SELECTED_PRICE_FREEZE_PURCHASE = "selected_price_freeze_purchase",
}

export const CHOOSE_PRICE_FREEZE = "choose_price_freeze";
export const VIEWED_PRICE_FREEZE = "viewed_price_freeze";
export const FREEZE_PRICE_CLICK = "freeze_price_click";
export const PRICE_FREEZE_REVIEW_DETAILS = "price_freeze_review_details";
export const VIEW_PRICE_FREEZE_ITINERARY = "view_price_freeze_itinerary";
export const BOOK_PRICE_FREEZE = "book_price_freeze";
export const PRICE_FREEZE_REFRESH = "price_freeze_refresh";
export const VIEWED_PRICE_FREEZE_SIMILAR_FLIGHT_LIST =
  "viewed_price_freeze_similar_flight_list";
export const PRICE_FREEZE_SIMILAR_FLIGHT_CONFIRMATION =
  "price_freeze_similar_flight_confirmation";
export const PRICE_FREEZE_SIMILAR_FLIGHT_ERROR =
  "price_freeze_similar_flight_error";

export interface ChoosePriceFreezeProperties {
  price_freeze_entry: PriceFreezeEntryEnum;
}

export type FreezePriceClickProperties = ChoosePriceFreezeProperties;
export type ViewedPriceFreezeInfoProperties = ChoosePriceFreezeProperties;

export enum PriceFreezeEntryEnum {
  FLIGHT_LIST = "flight_list",
  FORECAST = "forecast",
  TRIP_SUMMARY = "trip_summary",
}

export enum EntryButtonEnum {
  FREEZE_PRICE = "freeze_price",
  INFO_MODAL = "info_modal",
  CLP_MODAL = "CLP_modal",
}

export enum PreviousFlightEnum {
  CLP_FLIGHT = "CLP_flight",
  CHOSEN_FLIGHT = "chosen_flight",
}

export interface PriceFreezeHistoryState {
  priceFreezeEntry?: PriceFreezeEntryEnum;
  entryButton?: EntryButtonEnum;
  previousFlight?: PreviousFlightEnum;
}

export interface PriceFreezeReviewDetailsProps {
  price_freeze_entry?: PriceFreezeEntryEnum;
  entry_button?: EntryButtonEnum;
  previous_flight?: PreviousFlightEnum;
  price_freeze_duration?: number;
  price_freeze_otm_cap_usd?: number;
  // note: in power-loader, we have `price_freeze_total_cost_usd: number; = Field(coalesce=['price_freeze_total_cost'])`
  price_freeze_total_cost?: number;
  // note: in power-loader, we have `price_freeze_cost_per_pax_usd: number; = Field(coalesce=['price_freeze_cost_per_pax'])`
  price_freeze_cost_per_pax?: number;
  frozen_price_total_usd?: number;
}

export interface PriceFreezeUserSelectedDurationProperties {
  default_fee_price_per_traveler_usd?: number;
  default_duration_offered?: number;
  min_fee_price_per_traveler_usd?: number;
  min_duration_offered?: number;
  max_fee_price_per_traveler_usd?: number;
  max_duration_offered?: number;
}

export interface ChangedPriceFreezeDurationProperties
  extends PriceFreezeUserSelectedDurationProperties {
  selected_duration?: number;
  selected_fee_price_per_traveler_usd?: number;
  price_freeze_otm_cap_usd?: number;
}

export interface SelectedPriceFreezePurchaseProperties
  extends ChangedPriceFreezeDurationProperties {
  price_freeze_id?: string;
}

export interface EditTravelersTrackingProps {
  success: boolean;
  price_freeze_pax_total: number;
  price_freeze_pax_adults: number;
  price_freeze_pax_children: number;
  price_freeze_pax_lap_infants: number;
  price_freeze_pax_seat_infants: number;
}
export const COMPLETE_BOOKING_PF = "complete_booking_pf";
export interface CompleteBookinPF {
  price_freeze_id: ID;
}
export const VIEWED_FROZEN_PRICES = "viewed_frozen_prices";
export interface ViewedFrozenPricesTrips {
  price_freeze_active: number;
  price_freeze_expired: number;
}

export interface ViewPriceFreezeItinerary {
  price_freeze_entry: "hp_banner" | "frozen_prices";
  advance: number;
  trip_type: "round_trip" | "one_way";
  fare_class:
    | "BasicEconomy"
    | "Economy"
    | "Business"
    | "PremiumEconomy"
    | "First";
  departure_date: string;
  return_date?: string;
  price_freeze_flow: boolean;
  price_freeze_id: ID;
  price_freeze_duration: number;
  price_freeze_pax_total: number;
  time_since_price_freeze_purchase?: number;
  price_freeze_shown_OTM_total_usd: number;
  price_freeze_cap_hit: boolean;
  frozen_price_total_usd: number;
  price_freeze_total_cost: number;
  price_freeze_otm_cap_usd: number;
  price_freeze_cost_per_pax: number;
  price_freeze_expiration_date: string;
}

export interface PriceFreezeRefresh {
  shop_advance: number;
  price_freeze_id: ID;
  price_freeze_duration: number;
  price_freeze_pax_total: number;
  time_since_price_freeze_purchase?: number;
  frozen_product_found: boolean;
  Carrier: string;
  fc_has_fares: boolean;
  fc_one_fare_match: boolean;
  fc_selected_fare_match: boolean;
  fc_selected_upgradable: boolean;
  fc_selected_upgradable_difference_usd: boolean;
}

export interface ViewedPriceFreezeProperties {
  price_freeze_shown: boolean;
  price_freeze_duration: number; // should be in seconds
  price_freeze_otm_cap_usd: number;
  price_freeze_total_cost?: number;
  price_freeze_cost_per_pax: number;
  current_lowest_price_usd?: number;
  frozen_price_total_usd?: number;
}

export interface CompleteBuyAirPriceFreezeProperties {
  price_freeze_flow: boolean;
  price_freeze_id: ID;
  price_freeze_shown_OTM_total_usd: number;
  price_freeze_cap_hit: boolean;
  price_freeze_duration: number;
  price_freeze_pax_total: number;
  price_freeze_pax_adults: number;
  price_freeze_pax_children: number;
  price_freeze_pax_lap_infants: number;
  price_freeze_pax_seat_infants: number;
  price_freeze_entry: "flight_search" | "PF_exercise";
  frozen_price_total_usd: number;
  price_freeze_total_cost: number;
  price_freeze_otm_cap_usd: number;
  price_freeze_cost_per_pax: number;
}
