import {
  AirCancelFulfillmentPollRequest,
  AirCancelFulfillmentPollResponse,
  AirCancelFulfillmentPollResponseEnum,
  CipherText,
} from "@b2bportal/air-cancel-api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { pollFlightCancelFulfillPath } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

const pollFlightCancelFulfill = async (
  fulfillmentSessionId: CipherText
): Promise<AirCancelFulfillmentPollResponseEnum | undefined> => {
  try {
    const req: AirCancelFulfillmentPollRequest = {
      fulfillmentSessionId,
    };
    const res = await axiosInstance.put<AirCancelFulfillmentPollResponse>(
      pollFlightCancelFulfillPath,
      req
    );

    return res.data.AirCancelFulfillmentPollResponse;
  } catch (err) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
  }
};

export default pollFlightCancelFulfill;
