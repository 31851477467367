import { trackEvent } from "@hopper-b2b/api";
import { useExperiment } from "@hopper-b2b/experiments";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ClientName,
  DisruptionExerciseProgress,
  FlightItineraryState,
  HotelItineraryState,
  IApiConfig,
  MyTripsFilter,
  VIEWED_MY_TRIPS,
} from "@hopper-b2b/types";
import { B2BSpinner, LoadingPopup, TripsHeader } from "@hopper-b2b/ui";
import {
  SessionStateEnum,
  getEnvVariables,
  updateApiConfigAction,
  useDeviceTypes,
  useEnablePriceFreeze,
  useFeatureFlagsContext,
  useSessionContext,
} from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ClientContext } from "../../App";
import { tripsApiConfigStoreKey } from "../../reducers";
import { DisruptionExerciseModal } from "../UberTripsList/components/DisruptionExercise";
import { FilterTabs } from "./components/FilterTabs";
import { ItineraryList } from "./components/ItineraryList";
import { ItinerariesModal } from "./components/ItineraryList/components/ItinerariesModal";
import { MobileFlightItineraryDetails } from "./components/ItineraryList/components/MobileFlightItineraryDetails";
import { MobileHotelItineraryDetails } from "./components/ItineraryList/components/MobileHotelItineraryDetails";
import { NoTripResults } from "./components/NoResults";
import { PriceFreezeList } from "./components/PriceFreezeList";
import { TripSearch } from "./components/TripSearch";
import { WatchList } from "./components/WatchList";
import { TripsListConnectorProps } from "./container";
import { getDisruptionExerciseProgress } from "./reducer";

import clsx from "clsx";
import dayjs from "dayjs";

import "./styles.scss";

export interface ITripsListProps
  extends TripsListConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  apiConfig: IApiConfig;
}

export const TripsList = ({
  // fetchCars,
  fetchFlights,
  fetchHotels,
  history,
  isMobile,
  listWatches,
  selectedFlight,
  selectedHotel,
  selectedCar,
  tripsFilter,
  tripsLoading,
  hasError,
  hasTripsToDisplay,
  openModal,
  listPriceFreeze,
  apiConfig,
  tripSearchResults,
}: ITripsListProps) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const dispatch = useDispatch();
  const clientContext = useContext(ClientContext);
  const { enableLodging } = useFeatureFlagsContext();

  const { sessionType } = useSessionContext();

  const isUserAuthenticated = useMemo(
    () =>
      clientContext?.sessionInfo?.userScope?.isSignedIn ||
      sessionType === SessionStateEnum.Authenticated,
    [clientContext?.sessionInfo?.userScope?.isSignedIn, sessionType]
  );

  const showFlightPriceFreeze = useEnablePriceFreeze();
  const disruptionExerciseProgress = useSelector(getDisruptionExerciseProgress);
  const isCommbank = getEnvVariables("clientName") === ClientName.COMMBANK_AU;
  const isLodgingEnabled =
    useExperiment("hopper-web-lodging") || isCommbank || enableLodging;
  const areWatchesEnabled = isCommbank;

  useEffect(() => {
    dispatch(updateApiConfigAction(tripsApiConfigStoreKey, apiConfig));
  }, [apiConfig, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    trackEvent({
      eventName: VIEWED_MY_TRIPS,
      properties: {},
    });
  }, []);

  const fetchAllItineraries = useCallback(() => {
    // fetchCars({}, history);
    fetchFlights(
      {
        states: [
          FlightItineraryState.Canceled,
          FlightItineraryState.Future,
          FlightItineraryState.Present,
          FlightItineraryState.Past,
        ],
        referenceDateTime: dayjs().format(),
      },
      history
    );
    if (isLodgingEnabled) {
      fetchHotels(
        {
          states: [
            HotelItineraryState.Canceled,
            HotelItineraryState.Past,
            HotelItineraryState.Future,
            HotelItineraryState.Present,
          ],
          referenceDateTime: dayjs().format(),
        },
        history
      );
    }
  }, [isLodgingEnabled, fetchFlights, fetchHotels, history]);

  useEffect(() => {
    if (isUserAuthenticated) fetchAllItineraries();
    if (areWatchesEnabled) listWatches(history);
    if (showFlightPriceFreeze) listPriceFreeze();
  }, [
    isUserAuthenticated,
    fetchAllItineraries,
    listPriceFreeze,
    showFlightPriceFreeze,
    areWatchesEnabled,
    history,
    listWatches,
  ]);

  const renderFilteredList = () => {
    switch (tripsFilter) {
      case MyTripsFilter.UPCOMING_TRIPS:
      case MyTripsFilter.PAST_TRIPS:
      case MyTripsFilter.FLIGHTS:
      case MyTripsFilter.HOTELS:
        return <ItineraryList isMobile={isMobile} />;
      case MyTripsFilter.WATCHED_TRIPS:
        return <WatchList isMobile={isMobile} />;
      case MyTripsFilter.PRIZE_FREEZES:
        return <PriceFreezeList isMobile={isMobile} />;
    }
  };

  const renderSelectedItinerary = () => {
    const renderDisruptionExercise = !(
      disruptionExerciseProgress === DisruptionExerciseProgress.NotStarted
    );
    return (
      <>
        {selectedFlight ? (
          renderDisruptionExercise ? (
            <DisruptionExerciseModal flight={selectedFlight} />
          ) : (
            <MobileFlightItineraryDetails />
          )
        ) : null}
        {selectedHotel && <MobileHotelItineraryDetails />}
        <ItinerariesModal isMobile={isMobile} />
      </>
    );
  };

  return isMobile && (selectedFlight || selectedHotel || selectedCar) ? (
    renderSelectedItinerary()
  ) : (
    <Box className={clsx({ mobile: isMobile }, "trips-list")}>
      {isUserAuthenticated ? (
        <>
          <TripsHeader
            tripHeaderCopy={{
              title: t("trips"),
              supportCTA: t("contactSupport"),
              subtitle: isCommbank ? t("tripsSubtitle") : "",
            }}
            isMobile={matchesMobile}
          />
          <FilterTabs mobile={isMobile} />
          <div
            id={`${tripsFilter}-tab`}
            role="tabpanel"
            aria-labelledby={tripsFilter}
          >
            {tripsLoading && (
              <LoadingPopup
                open={tripsLoading || tripsLoading === null}
                indicator={B2BSpinner}
                message={t("fetchingTrips")}
                classes={[
                  "trips-loading-modal",
                  ...(isMobile ? ["mobile"] : []),
                ]}
              />
            )}
            {!tripsLoading && <NoTripResults isMobile={isMobile} />}
            {!hasError &&
              (hasTripsToDisplay || openModal) &&
              renderFilteredList()}
          </div>
        </>
      ) : (
        <TripSearch isMobile={isMobile} tripSearchResults={tripSearchResults} />
      )}
    </Box>
  );
};
