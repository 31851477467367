import { ChatPropertiesType } from "@b2bportal/chat-api";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import {
  createGeneralSupportConversation,
  createSupportConversationForProduct,
  initializeKustomerChat,
  listenForUnreadCountUpdates,
  loginKustomer,
  openKustomerChat,
  startKustomerChat,
} from "./kustomerChat";
import { useLocation } from "react-router";
import { ISupportContext, SupportContext } from "@hopper-b2b/utilities";

export interface IKustomerConfigurationProps {
  apiKey?: string;
  brandId?: string;
  enabled: boolean;
  hopperUserId?: string;
  kustomerAccessToken?: string;
  tenant: string;
  fetchChatProperties: (
    chatPropertiesType: ChatPropertiesType,
    productId: string
  ) => Promise<any>;
}

export interface ISupportContextProvider {
  kustomerConfig: IKustomerConfigurationProps;
  supportContext?: Partial<ISupportContext>;
}

export const SupportContextProvider = ({
  children,
  kustomerConfig,
}: PropsWithChildren<ISupportContextProvider>) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [started, setStarted] = useState<boolean>(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const queryString = useLocation().search;

  const onStartedChat = useCallback(() => {
    setStarted(true);
    listenForUnreadCountUpdates((count) => {
      setUnreadCount(count);
    });
  }, []);

  const onLoadedChat = useCallback(() => {
    setIsLoading(false);
    setLoaded(true);
    if (!started) {
      startKustomerChat(
        {
          brandId: kustomerConfig.brandId,
          hideChatIcon: true,
          hideNewConversationButtonOnHistory: true,
          hideNewConversationButtonOnEnd: true,
        },
        onStartedChat
      );
    }
  }, [kustomerConfig, onStartedChat, started]);

  useEffect(() => {
    const queryParams = new URLSearchParams(queryString);
    const showSupportChat = queryParams.has("chat-support");

    if (!loaded && !isLoading) {
      setIsLoading(true);
      initializeKustomerChat(
        {
          apiKey: kustomerConfig.apiKey,
          enabled: kustomerConfig.enabled,
        },
        onLoadedChat
      );
    }

    if (
      started &&
      kustomerConfig.kustomerAccessToken &&
      kustomerConfig.hopperUserId
    ) {
      loginKustomer(
        kustomerConfig.hopperUserId,
        kustomerConfig.kustomerAccessToken
      );
    }

    if (showSupportChat && started) {
      createGeneralSupportConversation(kustomerConfig.tenant);
    }
  }, [isLoading, kustomerConfig, loaded, onLoadedChat, queryString, started]);

  const supportContext: ISupportContext = {
    generalSupportChat: () => {
      createGeneralSupportConversation(kustomerConfig.tenant);
    },
    productSupportChat: async (productId, productType, requestType) => {
      createSupportConversationForProduct(
        productId,
        productType,
        requestType,
        kustomerConfig.fetchChatProperties
      );
    },
    openChatWindow: () => {
      openKustomerChat();
    },
    unreadCount,
  };

  return (
    <SupportContext.Provider value={supportContext}>
      {children}
    </SupportContext.Provider>
  );
};
