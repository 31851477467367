import {
  ApacDesktopPopupModal,
  ApacMobileContinueButton,
  ErrorMessageWrapper,
} from "../..";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  IFlightSearchCriteria,
  IMonthBucket,
  TripCategory,
  VIEW_CALENDAR,
} from "@hopper-b2b/types";
import { ActionButton } from "@hopper-b2b/ui";
import { Box, Collapse } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
  PickerType,
} from "../index";
import "./CalendarPicker.styles.scss";
import { FocusedInput } from "@datepicker-react/hooks";

export interface CalendarTrackingProperties {
  origin: string;
  destination: string;
  tripCategory: TripCategory;
}

interface ICalendarPickerProps {
  open?: boolean;
  departureDate: Date | null;
  returnDate: Date | null;
  setDepartureDate: (value: Date | null) => void;
  setReturnDate: (value: Date | null) => void;
  closePopup?: () => void;
  tripCategory: TripCategory;
  onComplete?: () => void;
  isHistoryPushDisabled?: boolean;
  isMobile?: boolean;
  modal?: boolean;
  calendarVisited?: boolean;
  setCalendarVisited?: (calendarVisited: boolean) => void;
  populateSearchUrlParams?: (
    params: IFlightSearchCriteria,
    history: History
  ) => void;
  priceTags?: string[];
  months?: IMonthBucket[] | null;
  mobileShopPath?: string;
  isHotel?: boolean;
  trackingProperties?: CalendarTrackingProperties;
  defaultFocusedInput?: FocusedInput;
}

export const CalendarPicker = (props: ICalendarPickerProps) => {
  const {
    open,
    closePopup,
    departureDate,
    returnDate,
    tripCategory,
    onComplete,
    isMobile = false,
    modal = false,
    setDepartureDate,
    setReturnDate,
    calendarVisited,
    setCalendarVisited,
    trackingProperties,
    populateSearchUrlParams,
    months,
    priceTags,
    isHotel,
    defaultFocusedInput,
  } = props;
  const { t } = useI18nContext();
  useLocation();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [departingError, setDepartingError] = useState<string>(null);
  const [returningError, setReturningError] = useState<string>(null);

  const isOneWay = tripCategory === TripCategory.ONE_WAY;

  const headerTitle = useMemo(
    () =>
      isOneWay
        ? t("searchControl.calendarOneWay")
        : t("searchControl.calendarRoundTrip"),
    [isOneWay, t]
  );

  const calendarClassName = useMemo(
    () => (isOneWay ? "one-way" : "roundtrip"),
    [isOneWay]
  );

  const focusedMonthIndex = departureDate
    ? dayjs(departureDate).diff(dayjs(), "month")
    : 0;

  useEffect(() => {
    if (!calendarVisited && setCalendarVisited) setCalendarVisited(true);
    trackEvent({
      eventName: VIEW_CALENDAR,
      properties: trackingProperties,
    });
  }, []);

  const isDateSelected = useMemo(() => {
    return isOneWay ? !!departureDate : !!returnDate && !!departureDate;
  }, [isOneWay, departureDate, returnDate]);

  const onDatesSelect = useCallback(() => {
    if (isDateSelected) {
      onComplete ? onComplete() : closePopup?.();
    } else {
      setErrorMessage(t("commBank.search.flights.selectDateMessage"));
    }
  }, [closePopup, isDateSelected, onComplete, t]);

  const setStartDate = useCallback(
    (value: Date | null) => {
      if (!modal) {
        populateSearchUrlParams &&
          populateSearchUrlParams({ departureDate: value }, history as any);
      }
      return setDepartureDate(value);
    },
    [modal, setDepartureDate, populateSearchUrlParams, history]
  );

  const setEndDate = useCallback(
    (value: Date | null) => {
      if (!modal) {
        populateSearchUrlParams &&
          populateSearchUrlParams({ returnDate: value }, history as any);
      }
      return setReturnDate(value);
    },
    [modal, setReturnDate, populateSearchUrlParams, history]
  );

  const onSearchMobileClick = useCallback(() => {
    if (isDateSelected) {
      onComplete && onComplete();
    } else {
      setErrorMessage(
        isHotel
          ? t("commBank.hotelSearch.hotelDateError")
          : t("commBank.search.flights.selectDateMessage")
      );
      if (!departureDate) {
        setDepartingError(
          isHotel
            ? t("commBank.hotelSearch.checkinRequired")
            : t("commBank.search.flights.departingDateError")
        );
      }
      if (!isOneWay && !returnDate) {
        setReturningError(
          isHotel
            ? t("commBank.hotelSearch.checkoutRequired")
            : t("commBank.search.flights.returningDateError")
        );
      }
    }
  }, [
    isDateSelected,
    onComplete,
    isHotel,
    t,
    departureDate,
    isOneWay,
    returnDate,
  ]);

  const cleanError = () => {
    setErrorMessage(null);
    setDepartingError(null);
    setReturningError(null);
  };

  const onSetStartDate = (v: Date) => {
    setStartDate(v);
    cleanError();
  };

  const onSetEndDate = (v: Date) => {
    setEndDate(v);
    cleanError();
  };

  return isMobile ? (
    <Box className="apac-mobile-calendar-picker-root">
      <MonthAndDatePicker
        className={calendarClassName}
        viewType={MonthAndDatePickerType.Column}
        focusedMonthIndex={focusedMonthIndex}
        setStartDate={onSetStartDate}
        setEndDate={onSetEndDate}
        startDate={departureDate}
        endDate={returnDate}
        isOneWay={isOneWay}
        departingError={departingError}
        returningError={returningError}
        months={months}
        priceTags={priceTags}
        pickerType={
          tripCategory === TripCategory.ROUND_TRIP
            ? PickerType.RANGE
            : PickerType.DAY
        }
        isHotel={isHotel}
        defaultFocusedInput={defaultFocusedInput}
      />
      <ApacMobileContinueButton
        handleContinue={onSearchMobileClick}
        title={
          isHotel ? t("hotelPageTitles.searchHotels") : t("mobileSearchButton")
        }
        errorMessage={errorMessage}
      />
    </Box>
  ) : (
    <ApacDesktopPopupModal
      open={Boolean(open)}
      onClose={closePopup}
      maxWidth="xl"
      className={clsx(
        "apac-desktop-calendar-picker-popup-root",
        calendarClassName
      )}
      contentClassName="calendar-modal-content"
    >
      <MonthAndDatePicker
        className={`${calendarClassName}`}
        viewType={MonthAndDatePickerType.Horizontal}
        isOneWay={isOneWay}
        startDate={departureDate}
        endDate={returnDate}
        setStartDate={onSetStartDate}
        setEndDate={onSetEndDate}
        header={headerTitle}
        months={months}
        priceTags={priceTags}
        pickerType={
          tripCategory === TripCategory.ROUND_TRIP
            ? PickerType.RANGE
            : PickerType.DAY
        }
        isHotel={isHotel}
        defaultFocusedInput={defaultFocusedInput}
      />

      <Collapse in={!!errorMessage} className="error-messsage-container">
        <ErrorMessageWrapper message={errorMessage} />
      </Collapse>
      <div className="calendar-button-group">
        <ActionButton
          onClick={onDatesSelect}
          className="calendar-apply-button"
          message={t("apply")}
        />
        <ActionButton
          onClick={closePopup}
          className="calendar-cancel-button"
          message={t("cancel")}
        />
      </div>
    </ApacDesktopPopupModal>
  );
};
