import { ApacIcon, ApacIconName } from "@commbank/ui";
import { AmenityStatus } from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Divider, Typography } from "@material-ui/core";
import { useId, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  mappedOrderedAmenities,
  orderedAmenitiesLength,
} from "../../../../util/utils";
import { SECTION_ID } from "../../constants";
import { getSelectedLodgingAmenities } from "../../reducer/selectors";
import { AmenitiesList } from "../AmenitiesList";
import { HotelDetailsDialog } from "../HotelDetailsDialog";
import { HotelDetailsSection } from "../HotelDetailsSection";
import styles from "./Amenities.module.scss";
import { MoreAmenitiesLink } from "./MoreAmenitiesLink";

const DESKTOP_MAX_AMENITIES = 8;
const MOBILE_MAX_AMENITIES = 4;

export const Amenities = ({ isMobile }: { isMobile: boolean }) => {
  const { t } = useI18nContext();

  const amenities = useSelector(getSelectedLodgingAmenities);

  const { sortedAndSlicedAmenities, sortedAmenities } = useMemo(() => {
    const getIndex = (amenity: string) => {
      const i = mappedOrderedAmenities[amenity.toLowerCase()] || -1;
      return i >= 0 ? i : orderedAmenitiesLength;
    };
    const sort = (a: AmenityStatus, b: AmenityStatus) =>
      getIndex(a.amenity) - getIndex(b.amenity);

    const maxAmenitiesToShow = isMobile
      ? MOBILE_MAX_AMENITIES
      : DESKTOP_MAX_AMENITIES;

    const copyOfAmenities = Array.isArray(amenities) ? [...amenities] : [];

    const sortedAndSlicedAmenities = copyOfAmenities
      .sort(sort)
      .slice(0, maxAmenitiesToShow);

    const sortedAmenities = copyOfAmenities.sort(sort);

    return { sortedAndSlicedAmenities, sortedAmenities };
  }, [amenities, isMobile]);

  const moreAmenityCount = useMemo(() => {
    return (amenities?.length || 0) - (sortedAndSlicedAmenities?.length || 0);
  }, [amenities, sortedAndSlicedAmenities]);

  return (
    <div className="amenities">
      {isMobile ? (
        <Box className="brief-list">
          {sortedAndSlicedAmenities?.map(({ displayName }) => (
            <Typography key={displayName} className="shown-tag">
              {displayName}
            </Typography>
          ))}
          {moreAmenityCount > 0 && (
            <MoreAmenitiesLink
              className="more-amenities"
              linkText={t("lodgingShopDetails.mobileMoreAmenities", {
                count: moreAmenityCount,
              })}
              amenities={sortedAmenities}
            />
          )}
        </Box>
      ) : (
        <HotelDetailsSection
          id={SECTION_ID.amenities}
          title={t("amenitiesHeader")}
        >
          <div>
            <Divider className={styles.Divider} />

            <AmenitiesList
              className={styles.AmenitiesList}
              amenities={sortedAndSlicedAmenities}
            />
            {moreAmenityCount > 0 && (
              <MoreAmenitiesLink
                className={styles.MoreAmenities}
                linkText={t("moreAmenities", { count: moreAmenityCount })}
                amenities={sortedAmenities}
              />
            )}
          </div>
        </HotelDetailsSection>
      )}
    </div>
  );
};
