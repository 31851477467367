import * as React from "react";
import Select from "@material-ui/core/Select";

import { StyledMenuItem } from "./components/StyledMenuItem";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import "./styles.scss";

interface IOption {
  label: string;
  value: any;
}

export interface ILabelDropDown {
  label: string | React.ReactElement;
  options: IOption[];
  onChange: (value: any) => void;
  value: any;
  classes?: string[];
  ariaLabel?: string;
}

const LabelDropDown = ({
  options,
  onChange,
  value,
  classes,
  label,
  ariaLabel,
}: ILabelDropDown) => {
  if (!ariaLabel && label && typeof label === "string") {
    ariaLabel = label;
  }
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & Element) | null
  >(null);

  const onSelection = (currentValue: any, newValue: any) => {
    if (currentValue !== newValue) {
      onChange(newValue);
    }
  };

  const togglePopover = (event: React.MouseEvent) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <FormControl className={clsx("label-drop-down", classes)}>
      <Select
        tabIndex={0}
        value={value}
        onChange={(event) => onSelection(value, event.target.value)}
        MenuProps={{
          anchorEl,
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          open: isPopoverOpen,
        }}
        input={
          <Input
            aria-label={ariaLabel}
            className="input-root"
            disableUnderline={true}
            startAdornment={
              <InputAdornment position="start">{label}</InputAdornment>
            }
          />
        }
        onClick={(event) => togglePopover(event)}
      >
        {renderMenu(options)}
      </Select>
    </FormControl>
  );
};

const renderMenu = (options: IOption[]) =>
  options.map(({ label, value }) => (
    <StyledMenuItem key={label} value={value}>
      {label}
    </StyledMenuItem>
  ));

export default LabelDropDown;
