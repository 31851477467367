import { useI18nContext } from "@hopper-b2b/i18n";
import { FlightSummaryPanel } from "@hopper-b2b/ui";
import { formatInterval } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import {
  FlightShopStep,
  flightShopProgressSelector,
} from "../../../shop/reducer";
import {
  getAirlines,
  getAirports,
  getPrevOutboundSlice,
  getPrevReturnSlice,
} from "../../selectors";
import { formats } from "../../types";

import "./styles.scss";

export interface IFlightComparatorRowProps {
  [prop: string]: unknown;
}

const defaultProps: Partial<IFlightComparatorRowProps> = {};

const FlightComparatorRow = (
  _props: IFlightComparatorRowProps
): JSX.Element => {
  const { t } = useI18nContext();

  const airlines = useSelector(getAirlines);
  const airports = useSelector(getAirports);
  const prevOutbound = useSelector(getPrevOutboundSlice);
  const prevReturn = useSelector(getPrevReturnSlice);
  const shopStep = useSelector(flightShopProgressSelector);

  const onChooseReturn = shopStep === FlightShopStep.ChooseReturn;
  const prevSegs = onChooseReturn ? prevReturn.segments : prevOutbound.segments;
  const {
    destination: { locationCode: destCode = "" },
    updatedArrival,
  } = prevSegs[prevSegs.length - 1] ?? {};
  const {
    cabinClassName,
    marketingAirline: { code: airlineCode = "" },
    origin: { locationCode: originCode = "" },
    updatedDeparture,
  } = prevSegs[0];
  const { plusDays, stops } = prevSegs.reduce(
    (acc, seg) => ({
      plusDays: acc.plusDays + seg.plusDays,
      stops: acc.stops + seg.stops,
    }),
    { plusDays: 0, stops: 0 }
  );
  const airline = airlines[airlineCode]?.displayName;
  const arrival = dayjs(updatedArrival);
  const departure = dayjs(updatedDeparture);
  const departureDesc = t("exchangeable.shop.comparator.departureDesc", {
    destCode,
    originCode,
    destCity: airports[destCode]?.cityName || "",
    originCity: airports[originCode]?.cityName || "",
    date: departure.format(formats.DISPLAY_DATE),
  });
  const flightDir = onChooseReturn ? t("return") : t("outbound");
  const flightType = flightDir.toLowerCase();
  let stopsString: string = t("stopDetails.nonstop");

  if (stops === 1) stopsString = t("stopDetails.stop_one", { count: stops });
  else if (stops > 1) {
    stopsString = t("stopDetails.stop_other", { count: stops });
  }

  return (
    <Box className="flight-comparator-row-container">
      <Box className="flight-info-card new-flight">
        <Typography className="title">
          {t("exchangeable.shop.comparator.newFlightTitle", { flightType })}
        </Typography>
        <Typography className="subtitle">
          {t("exchangeable.shop.comparator.newFlightSubtitle")}
        </Typography>
      </Box>
      <Box className="flight-info-card prev-flight">
        <Box className="flight-summary-container">
          <Typography className="title">
            {t("exchangeable.shop.comparator.prevFlightTitle", {
              flightType,
            })}
          </Typography>
          <FlightSummaryPanel
            airline={airline}
            airlineCode={airlineCode ?? ""}
            className="prev-flight-summary"
            duration={formatInterval(arrival.diff(departure, "minute"))}
            fareClass={cabinClassName}
            formattedArrivalTime={arrival.format(formats.SUMMARY_TIME)}
            formattedDepartureDescription={departureDesc}
            formattedDepartureTime={departure.format(formats.SUMMARY_TIME)}
            isMobile={false}
            plusDays={plusDays}
            stopString={stopsString}
            tripCategory={""} // intentionally left blank
          />
        </Box>
      </Box>
    </Box>
  );
};

FlightComparatorRow.defaultProps = defaultProps;

export default FlightComparatorRow;
