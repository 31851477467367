import { actionTypes } from "../actions";
import { takeLatest } from "redux-saga/effects";
import { fetchCarsSaga } from "./fetchCarsSaga";
import { fetchFlightsSaga } from "./fetchFlightsSaga";
import { fetchHotelsSaga } from "./fetchHotelsSaga";
import { fetchPriceFreezeSaga } from "./fetchPriceFreezeSaga";
import { fetchWatchesSaga } from "./air-price-watch/fetchWatchesSaga";
import { updateWatchSaga } from "./air-price-watch/updateWatchSaga";
import { deleteWatchSaga } from "./air-price-watch/deleteWatchSaga";
import {
  populateTripQueryParamsSaga,
  replaceTripQueryParamsSaga,
} from "./populateTripQueryParamsSaga";
import { acceptScheduleChangeSaga } from "./acceptScheduleChangeSaga";
import { denyScheduleChangeSaga } from "./denyScheduleChangeSaga";

import { confirmFlightCfarCancellationSaga } from "./cfar/confirmFlightCfarCancellationSaga";
import { fetchFlightCfarCancellationQuoteSaga } from "./cfar/fetchFlightCfarCancellationQuoteSaga";
import { discardFlightCfarCancellationQuoteSaga } from "./cfar/discardFlightCfarCancellationSaga";
import { getWatchDetailsSaga } from "./air-price-watch/getWatchDetails";
import { createWatchSaga } from "./air-price-watch/createWatchSaga";
import { fetchFlightCfarCancellationQuoteV1Saga } from "./cfar/v1/fetchFlightCfarCancellationQuoteSaga";
import { confirmFlightCfarCancellationV1Saga } from "./cfar/v1/confirmFlightCfarCancellationSaga";

export function* watchFetchCarsSaga() {
  yield takeLatest(actionTypes.FETCH_CARS, fetchCarsSaga);
}

export function* watchFetchFlightsSaga() {
  yield takeLatest(actionTypes.FETCH_FLIGHTS, fetchFlightsSaga);
}

export function* watchFetchHotelsSaga() {
  yield takeLatest(actionTypes.FETCH_HOTELS, fetchHotelsSaga);
}

export function* watchListWatches() {
  yield takeLatest(actionTypes.LIST_WATCHES, fetchWatchesSaga);
}

export function* watchListPriceFreeze() {
  yield takeLatest(actionTypes.LIST_PRICE_FREEZE, fetchPriceFreezeSaga);
}

export function* watchUpdateWatch() {
  yield takeLatest(actionTypes.UPDATE_WATCH, updateWatchSaga);
}

export function* watchDeleteWatch() {
  yield takeLatest(actionTypes.DELETE_WATCH, deleteWatchSaga);
}

export function* watchPopulateTripQueryParams() {
  yield takeLatest(
    actionTypes.POPULATE_TRIP_QUERY_PARAMS,
    populateTripQueryParamsSaga
  );
}

export function* watchReplaceTripQueryParams() {
  yield takeLatest(
    actionTypes.REPLACE_TRIP_QUERY_PARAMS,
    replaceTripQueryParamsSaga
  );
}

export function* watchAcceptScheduleChange() {
  yield takeLatest(
    actionTypes.ACCEPT_SCHEDULE_CHANGE,
    acceptScheduleChangeSaga
  );
}

export function* watchDenyScheduleChange() {
  yield takeLatest(actionTypes.DENY_SCHEDULE_CHANGE, denyScheduleChangeSaga);
}

export function* watchFetchFlightCfarCancellationQuoteSaga() {
  yield takeLatest(
    actionTypes.FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE,
    fetchFlightCfarCancellationQuoteSaga
  );
}

export function* watchConfirmFlightCfarCancellationSaga() {
  yield takeLatest(
    actionTypes.CONFIRM_FLIGHT_CFAR_CANCELLATION,
    confirmFlightCfarCancellationSaga
  );
}

export function* watchDiscardFlightCfarCancellationQuoteSaga() {
  yield takeLatest(
    actionTypes.DISCARD_FLIGHT_CFAR_CANCELLATION_QUOTE,
    discardFlightCfarCancellationQuoteSaga
  );
}

export function* watchFetchFlightCfarCancellationQuoteV1Saga() {
  yield takeLatest(
    actionTypes.FETCH_FLIGHT_CFAR_CANCELLATION_QUOTE_V1,
    fetchFlightCfarCancellationQuoteV1Saga
  );
}

export function* watchConfirmFlightCfarCancellationV1Saga() {
  yield takeLatest(
    actionTypes.CONFIRM_FLIGHT_CFAR_CANCELLATION_V1,
    confirmFlightCfarCancellationV1Saga
  );
}

export function* watchGetWatchDetailsSaga() {
  yield takeLatest(actionTypes.GET_WATCH_DETAILS, getWatchDetailsSaga);
}

export function* watchCreateWatchSaga() {
  yield takeLatest(actionTypes.CREATE_WATCH, createWatchSaga);
}
