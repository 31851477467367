import {
  FirstDayOfWeek,
  FocusedInput,
  MonthType,
  OnDatesChangeProps,
  useDatepicker,
  useMonth,
} from "@datepicker-react/hooks";
import { getLang, useI18nContext } from "@hopper-b2b/i18n";
import { customFormatDateTime } from "@hopper-b2b/utilities";
import { DateTimeFormatStyle } from "@hopper-i18n/formatter";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { firstWeekDay } from "../../component";
import { Month } from "../Month";
import "./styles.scss";

export interface IColumnViewProps {
  startDate: Date | null;
  cleanedEndDate: Date | null;
  setStartDate?: (date: Date | null) => void;
  setEndDate?: (date: Date | null) => void;
  minAllowedDate: Date | null;
  maxAllowedDate: Date | null;
  numberOfMonths: number;
  focusedMonthIndex?: number;
  hideSelectedDateIcon?: boolean;
  startDateLabel?: string;
  endDateLabel?: string;
  className?: string;
  isMissingDate?: boolean;
  focusedInput?: FocusedInput;
}

export const ColumnView = ({
  startDate,
  cleanedEndDate,
  setStartDate,
  setEndDate,
  minAllowedDate,
  maxAllowedDate,
  numberOfMonths,
  focusedMonthIndex,
  hideSelectedDateIcon,
  startDateLabel,
  endDateLabel,
  className,
  isMissingDate = false,
  focusedInput,
}: IColumnViewProps) => {
  const { t, brand, language } = useI18nContext();
  const [scrolledOnStart, setScrolledOnStart] = useState<boolean>(false);
  const monthRefs = Array.from({ length: numberOfMonths }, (_) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useRef<HTMLDivElement | null>(null);
  });

  const handleDateChange = (props: OnDatesChangeProps) => {
    const { startDate, endDate } = props;
    setStartDate && setStartDate(startDate);
    setEndDate && setEndDate(endDate);
  };

  const { firstDayOfWeek, activeMonths } = useDatepicker({
    firstDayOfWeek: firstWeekDay(getLang()) as FirstDayOfWeek, // 0 is Sunday.
    startDate: minAllowedDate,
    endDate: maxAllowedDate,
    focusedInput,
    onDatesChange: handleDateChange,
    numberOfMonths,
  });

  const [firstMonth] = activeMonths;

  const getFormattedWeekdayLabel = useCallback(
    (date: Date) => {
      const formatted = customFormatDateTime(
        new Date(date),
        language,
        DateTimeFormatStyle.Custom({
          weekday: "short",
        }),
        brand?.customDateTimeFormats?.calendarDay
      );

      return brand?.customDateTimeFormats?.calendarDay
        ? formatted
        : formatted[0].toLocaleUpperCase(language);
    },
    [language, brand]
  );

  const { weekdayLabels } = useMonth({
    year: firstMonth.year,
    month: firstMonth.month,
    firstDayOfWeek: firstDayOfWeek,
    weekdayLabelFormat: (date: Date) => getFormattedWeekdayLabel(date),
  });

  const currentFocusIndex = useMemo(() => {
    return (
      focusedMonthIndex ??
      // when focusedMonthIndex is not given, use startDate to determine index
      (startDate && minAllowedDate
        ? // left side returns 1 or 0; increment is 12-based
          (startDate.getFullYear() - minAllowedDate.getFullYear()) * 12 +
          (startDate.getMonth() - minAllowedDate.getMonth())
        : 0)
    );
  }, [focusedMonthIndex, minAllowedDate, startDate]);

  useEffect(() => {
    if (!scrolledOnStart) {
      monthRefs[currentFocusIndex]?.current?.scrollIntoView();
      setScrolledOnStart(true);
    }
  }, [scrolledOnStart, currentFocusIndex, monthRefs]);

  return (
    <div className={clsx("column-view-date-range-picker", className)}>
      {/* <div className="weekday-label-section">
        {generateRenderWeekdayLabels({
          month: firstMonth.month,
          year: firstMonth.year,
        })(weekdayLabels)}
      </div> */}
      <div className="scrollable-months-container">
        {renderMonths(
          activeMonths,
          firstDayOfWeek,
          monthRefs,
          brand?.calendarMonthFormat || "MMM"
        )}
      </div>
    </div>
  );
};

const renderMonths = (
  months: MonthType[],
  firstDayOfWeek: FirstDayOfWeek,
  monthRefs: React.MutableRefObject<HTMLDivElement | null>[],
  monthFormat?: string
) =>
  months.map((month: MonthType, index: number) => (
    <div
      ref={monthRefs[index]}
      key={`${month.year}-${month.month}`}
      className="column-view-month-wrapper"
    >
      <Month
        year={month.year}
        month={month.month}
        firstDayOfWeek={firstDayOfWeek}
        date={month.date}
        hideWeekdayLabels={false}
        headerClassName="column-view-month-label"
        monthFormat={monthFormat}
      />
    </div>
  ));
