import { SvgrComponent } from "@hopper-b2b/ui";
import { ReactComponent as MagnifierIcon } from "./commbank-au/magnifier.svg";
import React from "react";

export enum CommBankAuIconName {
  Magnifier = "magnifier",
}

type CommBankAuIconComponentMap = {
  [name in CommBankAuIconName]: SvgrComponent;
};

export const commBankAuIconComponent: CommBankAuIconComponentMap = {
  [CommBankAuIconName.Magnifier]: MagnifierIcon,
};

export interface CommBankAuIconComponentProps {
  name: CommBankAuIconName;
  className?: string;
  ariaLabel?: string;
}

export const CommBankAuIconComponent = ({
  name,
  ariaLabel,
  ...props
}: CommBankAuIconComponentProps): React.ReactElement => {
  const CommBankAuIconComponent: SvgrComponent = commBankAuIconComponent[
    name
  ] as SvgrComponent;

  return (
    <CommBankAuIconComponent
      {...props}
      className={`${props.className} icon-component`}
      aria-label={ariaLabel ?? name}
    ></CommBankAuIconComponent>
  );
};
