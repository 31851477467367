import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { rootReducer } from "../reducer";
import urlMiddleWare from "./urlMiddleWare";
import { type ILodgingAvailabilityState } from "../modules/availability/reducer";

const configureLodgingAppStore = (preloadedState: {
  lodgingAvailability?: Partial<ILodgingAvailabilityState>;
}) => {
  const store = configureStore({
    preloadedState: preloadedState,
    reducer: rootReducer,
    middleware: [urlMiddleWare, ...getDefaultMiddleware()],
    devTools: process.env.NODE_ENV !== "production",
  });

  return store;
};

export default configureLodgingAppStore;
