import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import clsx from "clsx";

import "./styles.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface IAccordionContent {
  title: JSX.Element;
  body: JSX.Element;
}

export type AccordionContent = IAccordionContent | null;

export interface IAccordionCollectionProps {
  className?: string;
  accordionContents: AccordionContent[];
  expandIcon?: React.ReactNode;
}

export const AccordionCollection = (props: IAccordionCollectionProps) => {
  const { className, accordionContents, expandIcon } = props;
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) =>
    (_: React.ChangeEvent<unknown>, isExpanded: boolean): void => {
      setExpanded(isExpanded ? panel : false);
    };

  const renderAccordions = () =>
    accordionContents.map((content, index) =>
      content !== null ? (
        <Accordion
          key={index}
          className="accordion-collection-child-content"
          expanded={expanded === `${ACCORDION_KEY_PREFIX}${index}`}
          onChange={handleChange(`${ACCORDION_KEY_PREFIX}${index}`)}
        >
          <AccordionSummary
            className="accordion-title"
            expandIcon={
              expandIcon ?? (
                <FontAwesomeIcon
                  icon={faChevronDown as IconProp}
                  className="chevron-expand-icon"
                />
              )
            }
          >
            {content.title}
          </AccordionSummary>
          <AccordionDetails className="accordion-body">
            {content.body}
          </AccordionDetails>
        </Accordion>
      ) : null
    );

  return (
    <Box className={clsx(className, "accordion-collection-root")}>
      {renderAccordions()}
    </Box>
  );
};

const ACCORDION_KEY_PREFIX = "panel";
