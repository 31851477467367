import { AirCfarOffer } from "@b2bportal/air-cfar-api";

import {
  ApacDesktopPopupModal,
  ApacFintechPurchase,
  ApacIcon,
  ApacIconName,
} from "@commbank/ui";
import { FiatPrice, Prices } from "@b2bportal/air-shopping-api";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  CFAR_OFFER_QUOTE_ID_QUERY_PARAM,
  CfarTrackingEvents,
  CfarTrackingPageSelected,
  FintechCoverageType,
  FintechProductOption,
  AirOrchestratorCfarTrackingEvents,
} from "@hopper-b2b/types";
import {
  B2BSpinner,
  FintechMobilePopoverCard,
  IconComponent,
  IconName,
  LoadingIndicator,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import { useDeviceTypes, useUberBridge } from "@hopper-b2b/utilities";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClientContext } from "../../../ApacFlightApp";
import { useUpdateQueryParams } from "../../shop/hooks";
import { setSelectedCfarOffer } from "../actions/actions";
import {
  getCfarOffers,
  getCfarOffersTrackingProperties,
  getSelectedCfarOffer,
  getSelectedCfarOfferTrackingProperties,
  isCfarOffersLoading,
  isCfarOffersValid,
} from "../reducer";
import { CfarHowItWorksContent } from "./CfarContent/CfarHowItWorksContent";
import "./styles.scss";

const OPEN_CFAR_DETAILS = "open_cfar_details";

interface CfarOffersProps {
  goToNextStep?: (replace?: boolean) => void;
  isHalfSheet?: boolean;
  onClose?: () => void;
  onChange?: () => void;
  hasDefaultSelection?: boolean; // used in hopper web customize page
  hideContinueButton?: boolean;
  cfarCoverage?: Prices;
}

export const CfarOffers = ({ goToNextStep, ...rest }: CfarOffersProps) => {
  const isLoading = useSelector(isCfarOffersLoading);
  const isValid = useSelector(isCfarOffersValid(true));
  if (!isValid) {
    goToNextStep?.(true);
  }

  return isLoading ? (
    <CfarLoading />
  ) : isValid ? (
    <CfarContent goToNextStep={goToNextStep} {...rest} />
  ) : null;
};

export const CfarContent = (props: CfarOffersProps) => {
  const {
    goToNextStep,
    onClose,
    isHalfSheet,
    onChange,
    hasDefaultSelection,
    hideContinueButton,
    cfarCoverage,
  } = props;

  const { t, formatFiatCurrency } = useI18nContext();
  const clientContext = useContext(ClientContext);
  const { setHeader } = useUberBridge();
  const { matchesMobile } = useDeviceTypes();

  const dispatch = useDispatch();

  const updateQueryParams = useUpdateQueryParams();

  const cfarOffers = useSelector(getCfarOffers);
  const selectedCfarOffer = useSelector(getSelectedCfarOffer);
  const cfarOffersTrackingProperties = useSelector(
    getCfarOffersTrackingProperties
  );
  const selectedCfarOfferTrackingProperties = useSelector(
    getSelectedCfarOfferTrackingProperties
  );

  const [open, setOpen] = useState<boolean>(false);
  const onOpenLearnMore = useCallback(() => {
    setOpen(true);
    trackEvent({
      eventName: CfarTrackingEvents.TAP_LEARN_MORE,
      properties: { page_selected: CfarTrackingPageSelected.OFFER },
    });
  }, []);

  const handleSelect = useCallback(
    (offer?: AirCfarOffer) => {
      onChange?.();
      dispatch(setSelectedCfarOffer(offer));
      if (
        offer &&
        Object.keys(selectedCfarOfferTrackingProperties.properties).length != 0
      ) {
        trackEvent({
          eventName: AirOrchestratorCfarTrackingEvents.CHOOSE_CFAR,
          properties: {
            ...selectedCfarOfferTrackingProperties.properties,
            cfar_choice: true,
          },
          encryptedProperties:
            selectedCfarOfferTrackingProperties.encryptedProperties,
        });
      }
    },
    [dispatch, onChange, selectedCfarOfferTrackingProperties]
  );

  const noCoverageSelected = selectedCfarOffer === null;

  // On Load
  useEffect(() => {
    handleSelect(selectedCfarOffer);
    updateQueryParams(
      {
        [CFAR_OFFER_QUOTE_ID_QUERY_PARAM]: selectedCfarOffer?.quoteId,
      },
      true
    );
  }, [handleSelect, updateQueryParams, selectedCfarOffer]);

  useEffect(() => {
    if (hasDefaultSelection && cfarOffers?.length) {
      handleSelect(cfarOffers[0]);
    }
  }, [cfarOffers, handleSelect, hasDefaultSelection]);

  // Send tracking event
  useEffect(() => {
    trackEvent({
      eventName: CfarTrackingEvents.VIEWED_CFAR,
      ...cfarOffersTrackingProperties,
    });
    trackEvent({
      eventName: AirOrchestratorCfarTrackingEvents.VIEW_CFAR,
      ...cfarOffersTrackingProperties,
    });
  }, [cfarOffersTrackingProperties]);

  const selectNoCoverage = useCallback(() => {
    handleSelect(null);
    trackEvent({
      eventName: AirOrchestratorCfarTrackingEvents.CHOOSE_CFAR,
      properties: {
        ...cfarOffersTrackingProperties.properties,
        cfar_choice: false,
      },
      encryptedProperties: cfarOffersTrackingProperties.encryptedProperties,
    });
  }, [handleSelect, cfarOffersTrackingProperties]);

  const handleGoToNext = useCallback(() => {
    updateQueryParams(
      {
        [CFAR_OFFER_QUOTE_ID_QUERY_PARAM]: selectedCfarOffer?.quoteId,
      },
      true
    );

    goToNextStep?.();
  }, [goToNextStep, selectedCfarOffer?.quoteId, updateQueryParams]);

  const declineCoverage = useCallback(() => {
    trackEvent({
      eventName: CfarTrackingEvents.DECLINED_CFAR,
      properties: undefined,
    });

    handleGoToNext();
  }, [handleGoToNext]);

  const addCoverage = useCallback(() => {
    handleGoToNext();
  }, [handleGoToNext]);

  useEffect(() => {
    setHeader({
      customButtons: [
        {
          text: t("learnMore"),
          action: {
            name: OPEN_CFAR_DETAILS,
            callback: onOpenLearnMore,
          },
        },
      ],
      title: "",
    });
  }, [onOpenLearnMore, setHeader, t]);

  const coveragePercentage = useMemo(() => {
    return cfarOffers?.length ? cfarOffers[0].coveragePercentage : null;
  }, [cfarOffers]);

  const formatFiat = useCallback(
    (fiat: FiatPrice) => {
      if (fiat) {
        return formatFiatCurrency(fiat);
      } else return undefined;
    },
    [formatFiatCurrency]
  );

  const options = useMemo(() => {
    const result: FintechProductOption[] = [];
    cfarOffers.forEach((offer) => {
      // TODO: @sbai remove full coverage.
      if (offer.coveragePercentage === "100") {
        result.push({
          value: FintechCoverageType.Full,
          title: t("cfar.optionTitle", { percent: 100 }),
          cost: formatFiat(offer?.premiumAmount?.fiat),
          selected: selectedCfarOffer?.coveragePercentage === "100",
          onClick: () => handleSelect(offer),
          ctaLabel: t("acceptCta"),
        });
      } else {
        result.push({
          value: FintechCoverageType.Partial,
          title: t("cfar.optionTitle", {
            percent: offer?.coveragePercentage,
          }),
          cost: formatFiat(offer?.premiumAmount?.fiat),
          selected:
            selectedCfarOffer?.coveragePercentage !== "100" &&
            !!selectedCfarOffer?.coveragePercentage,
          onClick: () => handleSelect(offer),
          ctaLabel: t("declineCta"),
        });
      }
    });

    result.push({
      value: FintechCoverageType.None,
      title: t("disruptionPurchase.noCoverage"),
      cost: "",
      description: [],
      selected: noCoverageSelected,
      onClick: selectNoCoverage,
      ctaLabel: t("declineCta"),
    });
    return result;
  }, [
    cfarOffers,
    formatFiat,
    handleSelect,
    noCoverageSelected,
    selectNoCoverage,
    selectedCfarOffer?.coveragePercentage,
    t,
  ]);

  const CfarOfferContent = () => (
    <div className="cfar-offers-container">
      <ApacFintechPurchase
        isHalfSheet={isHalfSheet}
        className="cfar"
        options={options}
        headerIcon={ApacIconName.Ticket}
        headerTitle={isHalfSheet ? null : t("cfarOffers.title")}
        benefitDescriptions={[
          t("cfar.timeDescription"),
          t("cfar.refundDescription", { percent: coveragePercentage }),
          t("cfar.helpDescription"),
        ]}
        extraDescription={t("cfar.noAddDescription")}
        headerImgSrc={
          isHalfSheet
            ? null
            : clientContext.assets && clientContext.assets["shield"]
        }
        clientAssets={clientContext.assets}
        ctaLabel={t(
          selectedCfarOffer && !noCoverageSelected
            ? "cfarOffers.coverageButtonText"
            : "cfarOffers.declineCoverageButtonText"
        )}
        disabled={selectedCfarOffer === undefined}
        handleContinue={
          hideContinueButton
            ? undefined
            : noCoverageSelected
            ? declineCoverage
            : addCoverage
        }
        selectedProduct={{
          selected: !!selectedCfarOffer || noCoverageSelected,
          cost: formatFiat(selectedCfarOffer?.premiumAmount?.fiat),
        }}
        isMobile={matchesMobile}
        onLearnMoreClick={() => setOpen(true)}
        termsScrollSelector="#flex-it"
      />
      {matchesMobile ? (
        <MobilePopoverCard
          open={open}
          className="full-screen"
          contentClassName="full-screen-content-container"
          onClose={() => setOpen(false)}
          topRightButton={
            <ApacIcon
              name={ApacIconName.Close}
              onClick={() => setOpen(false)}
            />
          }
        >
          <CfarHowItWorksContent
            onClose={() => setOpen(false)}
            amount={
              cfarCoverage?.fiat
                ? formatFiatCurrency(cfarCoverage?.fiat)
                : coveragePercentage + "%"
            }
          />
        </MobilePopoverCard>
      ) : (
        <ApacDesktopPopupModal
          className="desktop-flight-shop-modal"
          contentClassName="modal-content"
          open={open}
          onClose={() => setOpen(false)}
          fullScreen={true}
        >
          <CfarHowItWorksContent
            onClose={() => setOpen(false)}
            amount={
              cfarCoverage?.fiat
                ? formatFiatCurrency(cfarCoverage?.fiat)
                : coveragePercentage + "%"
            }
          />
        </ApacDesktopPopupModal>
      )}
    </div>
  );

  return isHalfSheet ? (
    <FintechMobilePopoverCard
      centered
      open={isHalfSheet}
      onClose={onClose}
      fullScreen={false}
      scroll="body"
      className="cfar-half-sheet"
      contentClassName="cfar-half-sheet-content"
      headerElement={t("cfarOffers.title")}
      topLeftButton={
        <button className="cfar-half-sheet-close-button" onClick={onClose}>
          <IconComponent name={IconName.Close} />
        </button>
      }
    >
      <CfarOfferContent />
    </FintechMobilePopoverCard>
  ) : (
    <CfarOfferContent />
  );
};

const CfarLoading = () => {
  const { t } = useI18nContext();
  return (
    <LoadingIndicator
      className="cfar-loader"
      indicatorSize={"small"}
      indicator={B2BSpinner}
      message={t("loading")}
    />
  );
};
