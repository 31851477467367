import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { validatePassengers } from "../../../../api/v1/book/book-flow/validatePassengers";
import {
  InvalidPassengers,
  PassengersResponse,
  PassengersResponseEnum,
} from "@hopper-b2b/types";
import { selectedTripSelector } from "../../../shop/reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import { getUserPassengers, ancillaryIdsSelector } from "../../reducer";
import { flightApiConfigStoreKey } from "../../../../reducers/types";

export function* validatePassengersSaga({
  seatedPassengerIds,
  lapInfantsIds,
}: actions.IValidatePassengers) {
  try {
    const state: IStoreState = yield select();
    const selectedTrip = selectedTripSelector(state);
    const passengers = getUserPassengers(state);
    const ancillaryIds = ancillaryIdsSelector(state);
    const apiConfig = state[flightApiConfigStoreKey];
    if (!selectedTrip || !passengers) {
      yield put(actions.setPassengerValidationCallStateFailure());
    }

    const response: PassengersResponse = yield validatePassengers(
      {
        tripId: selectedTrip.tripId!,
        fareId: selectedTrip.returnFareId
          ? selectedTrip.returnFareId!
          : selectedTrip.outgoingFareId!,
        seatedPassengers: passengers
          .filter((p) => seatedPassengerIds.includes(p.id))
          .map((u) => u.id),
        lapInfants: passengers
          .filter((p) => lapInfantsIds.includes(p.id))
          .map((u) => u.id),
        ancillaryIds,
      },
      apiConfig
    );

    switch (response.PassengersResponse) {
      case PassengersResponseEnum.InvalidPassengers: {
        const errors = (response as InvalidPassengers).errors;
        yield put(actions.setPassengersInvalid(errors));
        yield put(
          actions.setUserSelectedPassengerIds({ userSelectedPassengerIds: [] })
        );
        yield put(
          actions.setUserSelectedLapInfantIds({ userSelectedLapInfantIds: [] })
        );
        yield put(actions.setPassengerValidationCallStateFailure());
        break;
      }
      case PassengersResponseEnum.PassengersOk:
        yield put(actions.setPassengersValid());
        yield put(actions.setPassengerValidationCallStateSuccess());
        break;
    }
  } catch (e) {
    yield put(actions.setPassengerValidationCallStateFailure());
  }
}
