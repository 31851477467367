import {
  AirCancelQuoteRequest,
  AirCancelQuoteResponse,
  airCancelApi,
} from "@b2bportal/air-cancel-api";
import { axiosInstance } from "@hopper-b2b/api";

export const cancelQuote = async (
  reservationId,
  bookingLocator
): Promise<AirCancelQuoteResponse> => {
  const body: AirCancelQuoteRequest = {
    reservationId,
    bookingLocator,
  };

  return new Promise((resolve, reject) => {
    try {
      airCancelApi(axiosInstance)
        .apiV0SelfserveFlightCancelQuotePost(body)
        .then((res) => resolve(res.data));
    } catch (e) {
      reject(e);
    }
  });
};
