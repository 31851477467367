import * as React from "react";
import { FormControl } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";

import "./styles.scss";

export enum TripCategory {
  ONE_WAY = "one_way",
  ROUND_TRIP = "round_trip",
}

export interface ITripCategoryOption {
  label: string;
  category: TripCategory;
}

export interface IFlightCategoryToggleProps {
  setTripCategory: (tripCategory: TripCategory) => void;
  category: TripCategory;
  className?: string;
}
export const FlightCategoryToggle = ({
  setTripCategory,
  category,
  className,
}: IFlightCategoryToggleProps) => {
  const { t } = useI18nContext();

  const TRIP_TYPES: ITripCategoryOption[] = [
    { label: t("flightRoundTrip"), category: TripCategory.ROUND_TRIP },
    { label: t("flightOneWay"), category: TripCategory.ONE_WAY },
  ];

  const [selectedCategory, setSelectCategory] =
    React.useState<TripCategory>(category);

  const renderToggleButtons = () =>
    TRIP_TYPES.map(({ label, category }) => (
      <ToggleButton
        className={clsx(
          "trip-category-ToggleButton",
          category === TripCategory.ROUND_TRIP ? "round-trip" : "one-way"
        )}
        key={label}
        value={category}
        role="radio"
        aria-checked={selectedCategory === category}
        aria-pressed={undefined}
      >
        {label}
      </ToggleButton>
    ));

  const handleOnChange = (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    value: TripCategory
  ) => {
    value && setTripCategory(value);
    setSelectCategory(value);
  };

  return (
    <FormControl className={clsx("trip-category-form", className)}>
      <ToggleButtonGroup
        exclusive
        onChange={handleOnChange}
        className="trip-category-ToggleButton-group"
        value={category}
        role="radiogroup"
        aria-label={t("tripCategory")}
      >
        {renderToggleButtons()}
      </ToggleButtonGroup>
    </FormControl>
  );
};
