import { getEnvVariables } from "./envVariableHelpers";

// TODO: Remove tenant specific code
export const openExternalLink = (url: string) => {
  if (getEnvVariables("clientName") === "uber") {
    uberOpenExternalLink(url);
  } else {
    window.open(url, "_blank");
  }
};

// TODO: Move this to apps/uber
export const uberOpenExternalLink = (url: string) => {
  window.parent.postMessage(
    {
      type: "OPEN_LINK",
      payload: { url },
    },
    import.meta.env["VITE_UBER_URL"]
  );
};
