import { apiConfig } from "@hopper-b2b/common-utils";
import { useI18nContext } from "@hopper-b2b/i18n";
import { IApiConfig } from "@hopper-b2b/types";
import { Box, InputAdornment, TextField, Typography } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { useCallback, useState } from "react";
import { Loading } from "../Loading";
import styles from "./Autocomplete.module.scss";
import clsx from "clsx";
import { ApacIconName, ApacIconComponent, InputWrapper } from "@commbank/ui";

export type AutocompleteProps<T> = {
  id: string;
  className?: string;
  defaultValue?: T;
  defaultInputValue?: string;
  label: string;
  disabled?: boolean;
  fetch: (
    apiConfig: IApiConfig | undefined,
    search: string,
    callback: (newOptions: T[]) => void
  ) => Promise<void>;
  onChange: (value: T) => void;
  sortOptions?: (options?: T[]) => T[];
  groupBy?: (option: T) => string;
  getOptionLabel?: (option: T) => string;
  getOptionLabelIcon?: (option: T) => ApacIconName;
  getGroupLabelKey?: (group: string) => string;
  icon?: string;
  onOpen?: () => void;
  alwaysOpen?: boolean;
  error?: string;
};

export const Autocomplete = <T,>({
  id,
  className,
  defaultValue,
  defaultInputValue = "",
  label,
  disabled,
  fetch,
  sortOptions,
  onChange,
  groupBy,
  getOptionLabel,
  getOptionLabelIcon,
  icon,
  onOpen,
  alwaysOpen,
  error,
}: AutocompleteProps<T>) => {
  const { t } = useI18nContext();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [, setShrink] = useState<boolean>(false);

  const [value, setValue] = useState<T>(() => defaultValue);
  const [search, setSearch] = useState(
    value ? getOptionLabel(value) : defaultInputValue
  );
  const [options, setOptions] = useState<T[]>([]);

  const handleOnInputChange = useCallback(
    (_event, newInputValue) => {
      setLoading(true);

      fetch(apiConfig, newInputValue, (newOptions: T[]) => {
        if (newOptions.length > 0) {
          setOptions(sortOptions ? sortOptions(newOptions) : newOptions);
        }
        setLoading(false);
      });
    },
    [fetch, sortOptions]
  );

  const handleOnChangeValue = useCallback(
    (_event, value: T) => {
      setValue(value);
      setSearch(getOptionLabel(value));
      onChange(value);
    },
    [getOptionLabel, onChange]
  );

  const handleOnOpen = useCallback(() => {
    onOpen && onOpen();
    setOpen(true);
  }, [onOpen]);

  return (
    <InputWrapper className={className} error={error}>
      <MuiAutocomplete
        id={id}
        open={search?.length > 0 && (alwaysOpen || open)}
        disabled={disabled}
        onOpen={() => handleOnOpen()}
        onClose={() => {
          setOpen(false);
        }}
        classes={{
          popupIndicator: styles.popupIndicator,
        }}
        popupIcon={
          <ApacIconComponent
            name={ApacIconName.MagnifyingGlass}
            className={styles.icon}
          />
        }
        forcePopupIcon
        value={value}
        inputValue={search}
        defaultValue={defaultValue}
        options={options}
        loading={loading}
        noOptionsText={t?.("noOptions")}
        onChange={handleOnChangeValue}
        onInputChange={handleOnInputChange}
        groupBy={groupBy}
        getOptionLabel={getOptionLabel}
        disableClearable={loading}
        onFocus={() => setShrink(true)}
        onBlur={() => setShrink(false)}
        renderInput={(params) => (
          <Box className={styles.wrapper}>
            <Typography className={styles.label} variant="body1">
              {label}
            </Typography>
            <TextField
              {...params}
              aria-label={label}
              variant="filled"
              placeholder={t("commBank.hotelSearch.searchInputPlaceholder")}
              InputProps={{
                ...params.InputProps,
                autoFocus: true,
                disableUnderline: true,
                className: clsx(styles.input, { error: !!error }),
                startAdornment: icon ? (
                  <>
                    <InputAdornment
                      position="start"
                      className={styles.startAdornment}
                    >
                      <img src={icon} alt="" />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ) : null,
                endAdornment: (
                  <>
                    <InputAdornment
                      position="end"
                      className={styles.endAdornment}
                    >
                      <Loading loading={loading} />
                    </InputAdornment>
                    {params.InputProps.endAdornment}
                  </>
                ),
                value: search,
                onChange: (e) => {
                  setSearch(e.target.value);
                },
              }}
            />
          </Box>
        )}
        renderOption={(params) => (
          <Box component="li" className={clsx(styles.autoCompleteContent)}>
            <ApacIconComponent
              className={clsx(styles.autocompleteIcon)}
              name={getOptionLabelIcon(params)}
            />
            <Typography variant="body1">{getOptionLabel(params)}</Typography>
          </Box>
        )}
        renderGroup={(params) => (
          <Box key={params.key} className={clsx(styles.searchContainer)}>
            {params.children}
          </Box>
        )}
        // Disable UI filtering
        filterOptions={(options: T[]) => options}
      />
    </InputWrapper>
  );
};
