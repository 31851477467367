import {
  BookedFlightItineraryWithDepartureTime,
  FlightItineraryResponse,
} from "@b2bportal/air-booking-api";
import { MyTripsFilter, MyTripsModalTypes } from "@hopper-b2b/types";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { call, put } from "redux-saga/effects";

import { fetchFlights } from "../../../api/v1/itinerary/fetchFlights";
import { actions } from "../actions";
import { addFlightType } from "../components/ItineraryList/components/FlightCard/helpers";
import { setUpMyTripsParams } from "./setUpTripsParams";

export function* fetchFlightsSaga(action: actions.IFetchFlights) {
  try {
    const { contactSupport, tripId } = yield call(setUpMyTripsParams, action);
    const flightItinerariesResponse: FlightItineraryResponse =
      yield fetchFlights(action.request);
    const {
      future = [],
      canceled = [],
      past = [],
      present = [],
    } = flightItinerariesResponse?.itineraries || {};
    let selectedFlight = null;
    let isSelectedFlightInPastTrips = false;
    selectedFlight = [...future, ...present].find(
      (flight: BookedFlightItineraryWithDepartureTime) =>
        flight.bookedItinerary.id === tripId
    );
    if (!selectedFlight) {
      const pastSelectedFlight = [...canceled, ...past].find(
        (flight: BookedFlightItineraryWithDepartureTime) =>
          flight.bookedItinerary.id === tripId
      );
      selectedFlight = pastSelectedFlight;
      isSelectedFlightInPastTrips = !!pastSelectedFlight;
    }

    if (isSelectedFlightInPastTrips) {
      yield put(actions.setTripsFilter(MyTripsFilter.PAST_TRIPS));
      yield put(actions.populateTripQueryParams(action.history, { tripId }));
    }
    if (selectedFlight) {
      yield put(actions.setSelectedFlight(selectedFlight));

      if (contactSupport) {
        yield put(
          actions.setOpenModal({
            selectedItinerary: addFlightType(selectedFlight),
            type: MyTripsModalTypes.ContactSupport,
          })
        );
      }
    }
    yield put(actions.setFlights(flightItinerariesResponse));
    fireUberStartSession();
  } catch (e) {
    yield put(actions.fetchFlightsFailed());
    console.error(e);
    fireUberStartSession();
  }
}

const fireUberStartSession = () => {
  // Following event is fired to indicate that the portal is loaded when coming from uber
  // Fired after:
  // - hydrated redux store from query params
  // - rendered trips UI
  // - fetched flights trips
  if (getEnvVariables("clientName") === "uber") {
    window.parent.postMessage(
      {
        type: "START_SESSION",
        payload: null,
      },
      import.meta.env["VITE_UBER_URL"]
    );
  }
};
