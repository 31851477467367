import { ApacIcon, ApacIconName } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { AlgomerchTag, FlightShopCardType, TagType } from "@hopper-b2b/types";
import { FlightDuration, FlightTags, RemoteAirlineIcon } from "@hopper-b2b/ui";
import { removeTimezone } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import "./styles.scss";

export interface IMobileFlightCardProps {
  duration: string;
  tags: {
    value: AlgomerchTag;
    type: TagType;
  }[];
  currentPriceText: string;
  rewardText: string;
  originCode: string;
  destinationCode: string;
  departureTime: string;
  arrivalTime: string;
  airports: string[];
  brandName: string;
  primaryCarrier: string;
  airlineName: string;
  onClickTag?: (label: string) => void;
  onClick?: () => void;
  cardType?: FlightShopCardType;
  layoverString: string;
  tagsLimit?: number;
}

const DEFAULT_TIME_FORMAT = "hh:mm A";
export const MobileFlightCard = (props: IMobileFlightCardProps) => {
  const {
    currentPriceText,
    rewardText,
    tags,
    onClickTag,
    primaryCarrier,
    airlineName,
    departureTime,
    arrivalTime,
    originCode,
    destinationCode,
    duration,
    brandName,
    layoverString,
  } = props;

  const { t } = useI18nContext();

  return (
    <Box className="mobile-flight-card">
      {tags.length > 0 ? (
        <FlightTags
          tags={tags}
          mergeTags={true}
          onClickTag={onClickTag}
          showFastest
        />
      ) : null}
      <Box className="mobile-flight-card-top-section">
        <RemoteAirlineIcon
          airlineCode={primaryCarrier}
          altText={airlineName}
          size="medium"
        />
        <Box className="mobile-flight-info-container">
          <Box className={clsx("flight-timing")}>
            <Typography className="departure-time">
              {dayjs(removeTimezone(departureTime)).format(DEFAULT_TIME_FORMAT)}
            </Typography>
            <ApacIcon name={ApacIconName.AirPlane} />
            <Typography className="arrival-time">
              {dayjs(removeTimezone(arrivalTime)).format(DEFAULT_TIME_FORMAT)}
            </Typography>
          </Box>
          <Box className={clsx("flight-locations")}>
            <Typography className="origin">{originCode}</Typography>
            <FlightDuration duration={duration} />
            <Typography className="destination">{destinationCode}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className="mobile-flight-card-bottom-section">
        <div className="title">
          <Typography className="fare-brand" variant="h6">
            {brandName}
          </Typography>
          <Typography className="fiat-price" variant="h6">
            {currentPriceText}
          </Typography>
        </div>
        <div className="subtitle">
          <Typography className="airline-name" variant="body2">
            {airlineName} <span>&#8226;</span> {layoverString}
          </Typography>
          {!!rewardText && (
            <Typography className="rewards-price" variant="body2">
              {t("flightShop.orAwardPoints", { points: rewardText })}
            </Typography>
          )}
        </div>
      </Box>
    </Box>
  );
};
