import { useDeviceTypes } from "@hopper-b2b/utilities";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import { ReactComponent as TriangleIcon } from "../../assets/icons/common/triangle.svg";

import "./styles.scss";

const MOBILE_OFFSET_SCROLL = 300;
const DESKTOP_OFFSET_SCROLL = 250;

interface IExpandableCardContent {
  title: JSX.Element;
  expandedTitle?: JSX.Element;
  body: JSX.Element;
}

export type ExpandableCardContent = IExpandableCardContent;

export interface IExpandableCardProps {
  className?: string;
  variant?: "outlined" | "elevation";
  expandedCardKey: string;
  cardKey: string;
  handleCardKeyChange: () => void;
  content: ExpandableCardContent;
  scrollExpandedIntoView?: boolean;
}

export const ExpandableCard = ({
  className,
  variant = "elevation",
  expandedCardKey,
  cardKey,
  handleCardKeyChange,
  content,
  scrollExpandedIntoView,
}: IExpandableCardProps): React.ReactElement => {
  const isExpanded = cardKey === expandedCardKey;
  const cardRef = React.useRef<HTMLElement>(null);
  const { matchesMobile } = useDeviceTypes();

  return (
    <Box
      className={clsx(className, "expandable-card-root", {
        mobile: matchesMobile,
      })}
    >
      <Box className="expandable-card-container">
        <Accordion
          ref={cardRef}
          variant={variant}
          className="expandable-card-content"
          expanded={isExpanded}
          onChange={(_event, expanded) => {
            if (expanded && scrollExpandedIntoView) {
              setTimeout(() => {
                const OFFSET = matchesMobile
                  ? MOBILE_OFFSET_SCROLL
                  : DESKTOP_OFFSET_SCROLL;
                const cardTop =
                  cardRef.current?.getBoundingClientRect().top || 0;
                window.scrollBy({
                  top: (cardTop as number) - OFFSET,
                  behavior: matchesMobile ? "auto" : "smooth",
                });
              }, 100);
            }
            handleCardKeyChange();
          }}
        >
          <AccordionSummary
            className="expandable-card-title"
            expandIcon={
              <TriangleIcon
                className="chevron-expand-icon"
                aria-label="triangle icon"
              />
            }
          >
            {isExpanded && !!content.expandedTitle
              ? content.expandedTitle
              : content.title}
          </AccordionSummary>
          <AccordionDetails className="expandable-card-body">
            {isExpanded && content.body}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};
