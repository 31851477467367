import { connect } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../actions";
import { populateSearchUrlParams } from "../../actions/actions";
import {
  getTripCategory,
  getDestination,
  getOrigin,
  getNumTravelers,
  getActiveFiltersCount,
  getAdultsCount,
  getChildrenCount,
  getDepartureDate,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getReturnDate,
} from "../../reducer";
import { FlightSearchControl } from "./component";

const { fetchDepartureCalendar, setCalendar } = actions;
const mapDispatchToProps = {
  fetchDepartureCalendar,
  setCalendar,
  populateSearchUrlParams,
};
const mapStateToProps = (state: IStoreState) => {
  const departureDate = getDepartureDate(state);
  const returnDate = getReturnDate(state);

  return {
    tripCategory: getTripCategory(state),
    origin: getOrigin(state),
    destination: getDestination(state),
    numTravelers: getNumTravelers(state),
    filtersCount: getActiveFiltersCount(state),
    departureDate: departureDate ? new Date(departureDate) : null,
    returnDate: returnDate ? new Date(returnDate) : null,
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
  };
};

export const ConnectedFlightSearchControl = connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightSearchControl);
