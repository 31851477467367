import {
  ApacActionLink,
  ApacIcon,
  ApacIconName,
  ApacLogo,
  ApacModalLink,
  ApacTermsLink,
  TravelCreditsOfferModalLink,
} from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { HamburgerButton } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { AppBar, Box, Toolbar, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Route, Routes } from "react-router-dom-v5-compat";
import { SessionContext } from "../../Root";
import { endSession } from "../../api/endSession";
import { PATH_AUTH_INVALID_SESSION, PATH_HOME } from "../../utils/urlPaths";
import { useBestTravelCreditsPercentage } from "@commbank/accounts";
import { PriceCreditsModalLink } from "./PriceCreditsModalLink";

import "./styles.scss";

const FAQ_URL = "https://travelbooking-faqs.hopper.com/";

const pages = [
  { path: "/", titleKey: "navigationMenu.home", icon: ApacIconName.Ticket },
  {
    path: "/profile",
    titleKey: "navigationMenu.walletTitle",
    icon: ApacIconName.TravelWallet,
  },
  { path: "/trips", titleKey: "myTrips", icon: ApacIconName.Luggage },
  {
    path: "/traveller",
    titleKey: "navigationMenu.travellersList",
    icon: ApacIconName.CBAFamily,
  },
  {
    path: "/customer-support",
    titleKey: "customerSupport",
    icon: ApacIconName.MessagePop,
  },
];

export const Header = () => {
  return (
    <Routes>
      <Route path="/flights" element={<HeaderBar currentPath="/" />} />
      <Route path="/hotels" element={<HeaderBar currentPath="/" />} />
      {pages.map((page) => (
        <Route
          key={page.path}
          path={`${page.path}`}
          element={<HeaderBar currentPath={page.path} />}
        />
      ))}
      <Route path="/inactive-error" element={<HeaderBar />} />
      <Route path="/access-denied" element={<HeaderBar />} />
      {/* none first level path page, hide nav bar without current path variable */}
      <Route path="/*" element={<HeaderBar />} />
    </Routes>
  );
};

export const trimSlash = (path: string) => {
  return path.replace(/^\/|\/$/g, "");
};

export const HeaderBar = ({ currentPath }: { currentPath?: string }) => {
  const { t, brand } = useI18nContext();
  const { urls } = brand;
  const { matchesMobile: isMobile } = useDeviceTypes();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  // we will save this in component state, as it is component in hight level App component, almost doesn't get rerendered unless page refresh
  const [showRewardsBanner, setShowRewardsBanner] = useState(true);
  const bestOfferPercentage = useBestTravelCreditsPercentage();

  const { sessionInfo, setSessionInfo } = useContext(SessionContext);

  const handleLogOff = async () => {
    setSessionInfo && setSessionInfo(undefined);

    const response = await endSession();
    if (response.EndSessionResponse === "EndSessionSuccess") {
      history.replace("/" + PATH_AUTH_INVALID_SESSION);
    }
  };

  const renderMobileNav = () => {
    return (
      <div
        className="mobile-nav-paper-container"
        onClick={() => setShowMenu(false)}
      >
        <ApacIcon
          className="close-button"
          name={ApacIconName.Close}
          onClick={() => setShowMenu(false)}
        />
        <Box className="menus-section">
          {pages
            .filter((page) => !page.hideOnSections)
            .map((page) => {
              return (
                <div
                  key={page.path}
                  className="mobile-menu-item"
                  onClick={() => history.replace(page.path)}
                >
                  <ApacIcon name={page.icon} />
                  <Typography variant="body1">{t(page.titleKey)}</Typography>
                </div>
              );
            })}
        </Box>

        <Box className="terms-section">
          <ApacTermsLink showUnderline={false}>
            <div className="mobile-menu-item">
              <ApacIcon name={ApacIconName.NoteSign} />
              <Typography variant="body1">{t("termsAndConditions")}</Typography>
            </div>
          </ApacTermsLink>
          <ApacModalLink
            modalClassName="header-privacy-modal"
            linkProps={{
              showUnderline: false,
            }}
            url={urls.privacyPolicy}
          >
            <div className="mobile-menu-item">
              <ApacIcon name={ApacIconName.Certificate} />
              <Typography variant="body1">{t("privacyPolicy")}</Typography>
            </div>
          </ApacModalLink>

          <ApacModalLink
            linkProps={{
              showUnderline: false,
            }}
            url={FAQ_URL}
          >
            <div className="mobile-menu-item">
              <ApacIcon name={ApacIconName.Documents} />
              <Typography variant="body1">{t("faq")}</Typography>
            </div>
          </ApacModalLink>
        </Box>
      </div>
    );
  };

  const showNavBar = useMemo(() => {
    return (
      !isMobile &&
      (!!currentPath ||
        ["flights/search", "hotels/search"].includes(
          trimSlash(history.location.pathname)
        ))
    );
  }, [currentPath, history.location.pathname, isMobile]);

  const bannerTitle = () => {
    return bestOfferPercentage
      ? t("commBank.earnBanner.hasOfferTitle", {
          percent: bestOfferPercentage,
        })
      : t("commBank.earnBanner.noOfferTitle");
  };

  const showReadOnlyHeader = useMemo(
    () =>
      ["inactive-error", "access-denied"].includes(
        trimSlash(history.location.pathname)
      ),
    [history.location.pathname]
  );

  return (
    <AppBar
      component="header"
      position="relative"
      variant="outlined"
      color="inherit"
      className={clsx("cba-header", { mobile: isMobile })}
    >
      {isMobile ? (
        (!!currentPath || showReadOnlyHeader) && (
          <Toolbar
            disableGutters
            variant="dense"
            className="mobile-header-container"
          >
            {!showReadOnlyHeader && (
              <HamburgerButton
                backgroundImage={null}
                className="mobile-header-menu"
                onOpen={() => setShowMenu(true)}
                onClose={() => setShowMenu(false)}
                open={showMenu}
                direction="right"
                menu={renderMobileNav()}
              />
            )}
            <div className="header-content-container">
              <Typography className="mobile-header-title" variant="h3">
                {brand.portalName}
              </Typography>
              <ApacLogo />
            </div>
          </Toolbar>
        )
      ) : (
        <Toolbar disableGutters variant="dense" className="header-container">
          <button
            className="logo-button"
            onClick={() => history.push(PATH_HOME)}
          >
            <ApacLogo />
            <Typography className="header-title" variant="h2">
              {brand.portalName}
            </Typography>
          </button>
          {sessionInfo && (
            <div className="logoff-button" onClick={handleLogOff}>
              <ApacIcon name={ApacIconName.Lock} />
              <Typography variant="body1">{t("commBank.logoff")}</Typography>
            </div>
          )}
        </Toolbar>
      )}

      {showNavBar && (
        <Box className="desktop-navigation-bar-container">
          <div className="menus-container">
            {pages.map((page) => {
              return (
                <div
                  key={page.path}
                  className={clsx("menu-item", {
                    selected: page.path === currentPath,
                  })}
                  onClick={() => history.replace(page.path)}
                >
                  <Typography className="title" variant="body1">
                    {t(page.titleKey)}
                  </Typography>
                </div>
              );
            })}
          </div>
        </Box>
      )}

      {currentPath && showRewardsBanner && (
        <div className="rewards-banner">
          <Typography variant="body1" className="banner-text">
            {bannerTitle()}
            {bestOfferPercentage > 0 ? (
              <TravelCreditsOfferModalLink
                className="learn-more-link"
                bestOfferPercentage={bestOfferPercentage}
              >
                {t("commBank.earnBanner.learnMoreLinkLabel")}
              </TravelCreditsOfferModalLink>
            ) : (
              <PriceCreditsModalLink className="learn-more-link">
                {t("commBank.earnBanner.learnMoreLinkLabel")}
              </PriceCreditsModalLink>
            )}
          </Typography>
          <ApacActionLink
            className="rewards-banner-close-button"
            message={<ApacIcon name={ApacIconName.Close} />}
            onClick={() => setShowRewardsBanner(false)}
          />
        </div>
      )}
    </AppBar>
  );
};
