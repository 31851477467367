import { useI18nContext } from "@hopper-b2b/i18n";
import { BookingType, ChatAvailability } from "@hopper-b2b/types";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { ReactNode } from "react";

import { ActionButton, Slot, SweetheartBunny } from "../..";

import "./styles.scss";

interface SupportModalTextContentProps {
  bookingType: BookingType;
  icon?: ReactNode;
  hasVipSupport: boolean;
  supportId?: string;
  showContact?: boolean;
  showHelpLink?: boolean;
  subtitle?: string;
  title?: string;
  showLoginDisclaimer?: boolean;
  chatSupportAction?: () => void;
}

export const SupportModalTextContent = ({
  bookingType,
  chatSupportAction,
  icon,
  hasVipSupport,
  supportId,
  showContact = true,
  showHelpLink = true,
  subtitle,
  title,
  showLoginDisclaimer,
}: SupportModalTextContentProps) => {
  const { matchesMobile } = useDeviceTypes();
  const { brand, t } = useI18nContext();
  const {
    chatAvailability,
    displayIntlSupportNumber,
    displaySupportNumber,
    displaySupportNumberPhonewords,
    phoneAvailability,
  } = brand.contactSupport;
  const showChatSupportDetails =
    chatAvailability === ChatAvailability.All ||
    (chatAvailability === ChatAvailability.VipOnly && hasVipSupport);
  const currUtcHours = dayjs.utc().hour(); // 0 - 23
  // default to show at all times; limit availability if config set
  let serviceStartUtc = 0;
  let serviceEndUtc = 24;

  if (phoneAvailability) {
    const { air, ground, hotel } = phoneAvailability;

    if (bookingType === BookingType.Flight && air) {
      ({ serviceStartUtc, serviceEndUtc } = air);
    } else if (bookingType === BookingType.Ground && ground) {
      ({ serviceStartUtc, serviceEndUtc } = ground);
    } else if (bookingType === BookingType.Lodging && hotel) {
      ({ serviceStartUtc, serviceEndUtc } = hotel);
    }
  }

  const showPhoneSupportDetails = Boolean(
    currUtcHours >= serviceStartUtc && currUtcHours < serviceEndUtc
  );

  return (
    <div className="support-modal-text-content">
      <Box className="icon-row">
        {icon || <img alt="cs-bunny" src={SweetheartBunny} />}
      </Box>
      <Box className="title-row">
        <Typography
          className="title"
          variant="h1"
          component="h1"
          id="support-modal-title"
        >
          {title || t("contactSupportModal.title")}
        </Typography>
      </Box>
      <Box className="subtitle-row">
        <Typography className="subtitle" variant="subtitle2">
          {subtitle || t("contactSupportModal.subtitle")}
        </Typography>
        {showHelpLink && (
          <Link
            className="b2b help-center-link"
            target="_blank"
            color="primary"
            href={t("contactSupportModal.helpCenterUrl")}
          >
            {t("contactSupportModal.helpCenterLink")}
          </Link>
        )}
      </Box>
      {showContact && (
        <Box className="contact-support-row">
          {showHelpLink && (
            <Typography className="subtitle" variant="subtitle2">
              {t("contactSupportModal.forOtherEnquiries")}
            </Typography>
          )}
          <Box className="support-info-container">
            {supportId && (
              <Box className="support-id-row">
                <Typography className="support-id-title title" variant="h2">
                  {hasVipSupport
                    ? t("contactSupportModal.vipSupportId")
                    : t("contactSupportModal.basicSupportId")}
                </Typography>
                <Typography className="support-id subtitle" variant="subtitle1">
                  {showLoginDisclaimer
                    ? t("contactSupportModal.loginForSupportId")
                    : supportId}
                </Typography>
              </Box>
            )}
            <Grid container className="contact-details-row" direction="row">
              {showChatSupportDetails && chatSupportAction && (
                <Grid
                  container
                  item
                  md
                  alignItems="center"
                  className="contact-details chat-details"
                  direction="column"
                  sm={12}
                >
                  <Typography className="title" variant="h2">
                    {t("contactSupportModal.chatTitle")}
                  </Typography>
                  <Grid item className="chat-button-container">
                    <ActionButton
                      className="open-chat-button"
                      message="Open Chat"
                      size="medium"
                      onClick={chatSupportAction}
                    />
                  </Grid>
                </Grid>
              )}
              {showPhoneSupportDetails && (
                <Grid
                  container
                  item
                  md
                  alignItems="center"
                  className="contact-details phone-details"
                  direction="column"
                  sm={12}
                >
                  <Box className="toll-free-num-row">
                    <Typography
                      className="toll-free-title title"
                      variant="h2"
                      dangerouslySetInnerHTML={{
                        __html: t("contactSupportModal.tollFreeNumTitle", {
                          // when the display is in 2 columns, wrap the text to be cleaner
                          context:
                            !matchesMobile && showChatSupportDetails
                              ? "wrap"
                              : undefined,
                        }),
                      }}
                    />
                    {displaySupportNumberPhonewords && (
                      <Typography
                        className="toll-free-num subtitle"
                        variant="subtitle1"
                      >
                        {displaySupportNumberPhonewords}
                      </Typography>
                    )}
                    {displaySupportNumber && (
                      <Typography
                        className="toll-free-num-full caption"
                        variant="caption"
                      >
                        {`(${displaySupportNumber})`}
                      </Typography>
                    )}
                  </Box>
                  {displayIntlSupportNumber && (
                    <Box className="intl-num-row">
                      <Typography className="intl-num-title title" variant="h2">
                        {t("contactSupportModal.intlNumTitle")}
                      </Typography>
                      <Typography
                        className="intl-num subtitle"
                        variant="subtitle1"
                      >
                        {displayIntlSupportNumber}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
            <Slot id="mytrips-contact-support-image" />
          </Box>
        </Box>
      )}
    </div>
  );
};
