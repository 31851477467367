import {
  TripDetails,
  PenaltyEnum,
  PenaltiesInfoEnum,
  BaggageInfoEnum,
} from "@hopper-b2b/types";

export const tripDetails: any = {
  shopId: "cb090550-5190-42e8-af2f-fca77644d419",
  configurationId: {
    timestamp: "2020-12-18T10:23:33.570-05:00",
  },
  fareIntent: {
    flightType: "published",
    notificationType: "published",
  },
  pointOfSaleCountryCode: "US",
  fareDetails: [
    {
      penaltiesInfo: {
        changePenalty: {
          Penalty: PenaltyEnum.NoPenalty,
        },
        cancelPenalty: {
          Penalty: PenaltyEnum.NotPossible,
        },
        PenaltiesInfo: PenaltiesInfoEnum.Known,
      },
      paxPricings: [
        {
          paxType: "ADT",
          pricing: {
            baseAmount: {
              fiat: {
                currencyCode: "USD",
                currencySymbol: "$",
                value: 149,
              },
              rewards: {
                "t.b70776bc93874f65ada967b206a6ec68": {
                  currency: "Miles",
                  value: 14900,
                },
                "t.0d62f1f5026e4d1982eba0a7f17524ba": {
                  currency: "Cash",
                  value: 149,
                },
                "t.3a04bdf22d3443c1ba4e726e9db6b8f3": {
                  currency: "Points",
                  value: 14900,
                },
                "t.61dddce7c7624554ba3ba1a8374f72aa": {
                  currency: "Miles",
                  value: 14900,
                },
                "t.a003ac12bf8f41128d837df990172470": {
                  currency: "Miles",
                  value: 14900,
                },
                "t.1af6701fa04e4eccbef6e4102290d7a6": {
                  currency: "Miles",
                  value: 15000,
                },
                "t.95d80d87a042468b8c484f351a9b21e0": {
                  currency: "Cash",
                  value: 149,
                },
              },
            },
            taxAmount: {
              fiat: {
                currencyCode: "USD",
                currencySymbol: "$",
                value: 35.19,
              },
              rewards: {
                "t.b70776bc93874f65ada967b206a6ec68": {
                  currency: "Miles",
                  value: 3519,
                },
                "t.0d62f1f5026e4d1982eba0a7f17524ba": {
                  currency: "Cash",
                  value: 36,
                },
                "t.3a04bdf22d3443c1ba4e726e9db6b8f3": {
                  currency: "Points",
                  value: 3519,
                },
                "t.61dddce7c7624554ba3ba1a8374f72aa": {
                  currency: "Miles",
                  value: 3519,
                },
                "t.a003ac12bf8f41128d837df990172470": {
                  currency: "Miles",
                  value: 3519,
                },
                "t.1af6701fa04e4eccbef6e4102290d7a6": {
                  currency: "Miles",
                  value: 15000,
                },
                "t.95d80d87a042468b8c484f351a9b21e0": {
                  currency: "Cash",
                  value: 36,
                },
              },
            },
            lastTicketDate: "2021-08-26",
          },
          quantity: 1,
        },
      ],
      timestamp: 1629917164708,
      took: 2866,
      id: "f1625986-b04b-4788-b989-08303d838e1e",
      conversationId: "8884514b-4984-48b6-aeb2-7797f9c32fc0",
      slices: [
        {
          outgoing: true,
          fareShelf: {
            rating: 0,
            brandName: "Basic",
            shortBrandName: "Basic",
            opaqueMapperStep: "brandName",
          },
          fareDetails: {
            segments: [
              {
                penaltiesInfo: {
                  changePenalty: {
                    Penalty: PenaltyEnum.NoPenalty,
                  },
                  cancelPenalty: {
                    Penalty: PenaltyEnum.NotPossible,
                  },
                  PenaltiesInfo: PenaltiesInfoEnum.Known,
                },
                ticketIndex: 0,
                outgoing: true,
                brand: {
                  sabreFareBrand: {
                    brandId: "BASIC",
                    brandName: "BASIC",
                  },
                },
                carrierCabinClass: "Y",
                code: "LT0D0ZBG",
                codes: {
                  ADT: "LT0D0ZBG",
                },
                validatingCarrierCode: "WS",
                bookingCode: "E",
                privateFare: false,
                connection: true,
                availableSeats: 9,
                baggageInfo: {
                  allowance: {
                    pieces: 0,
                    descriptions: [],
                  },
                  charges: [
                    {
                      firstPiece: 1,
                      amount: 30,
                      lastPiece: 1,
                      currency: "USD",
                      descriptions: [],
                    },
                    {
                      firstPiece: 2,
                      amount: 50,
                      lastPiece: 2,
                      currency: "USD",
                      descriptions: [],
                    },
                  ],
                  BaggageInfo: BaggageInfoEnum.Allowed,
                },
              },
              {
                penaltiesInfo: {
                  changePenalty: {
                    Penalty: PenaltyEnum.NoPenalty,
                  },
                  cancelPenalty: {
                    Penalty: PenaltyEnum.NotPossible,
                  },
                  PenaltiesInfo: PenaltiesInfoEnum.Known,
                },
                ticketIndex: 0,
                outgoing: true,
                brand: {
                  sabreFareBrand: {
                    brandId: "BASIC",
                    brandName: "BASIC",
                  },
                },
                carrierCabinClass: "Y",
                code: "LT0D0ZBG",
                codes: {
                  ADT: "LT0D0ZBG",
                },
                validatingCarrierCode: "WS",
                bookingCode: "E",
                privateFare: false,
                connection: false,
                availableSeats: 9,
                baggageInfo: {
                  allowance: {
                    pieces: 0,
                    descriptions: [],
                  },
                  charges: [
                    {
                      firstPiece: 1,
                      amount: 30,
                      lastPiece: 1,
                      currency: "USD",
                      descriptions: [],
                    },
                    {
                      firstPiece: 2,
                      amount: 50,
                      lastPiece: 2,
                      currency: "USD",
                      descriptions: [],
                    },
                  ],
                  BaggageInfo: BaggageInfoEnum.Allowed,
                },
              },
            ],
          },
          amenitiesUtas: {
            amenities: {
              seat: {
                flatness: "not_flat",
                info: {
                  displayText: "79 cm seat pitch",
                  quality: "standard",
                  updatedAt: "2016-07-01T06:09:52.000Z",
                },
                pitch: "31",
                legroom: "standard",
                width: "standard",
              },
              aircraft: {
                info: {
                  displayText: "737 (narrowbody)",
                  quality: "standard",
                  updatedAt: "2016-05-31T10:00:47.000Z",
                },
              },
              entertainment: {
                info: {
                  displayText: "Streaming entertainment",
                  quality: "better",
                  updatedAt: "2020-04-07T04:43:58.000Z",
                },
                exists: "yes",
              },
              beverage: {
                info: {
                  displayText: "Alcohol (fee) & beverages provided",
                  quality: "standard",
                  updatedAt: "2018-06-05T07:17:32.000Z",
                },
                exists: "yes",
              },
              wifi: {
                info: {
                  displayText: "Basic web browsing (fee)",
                  quality: "standard",
                  updatedAt: "2018-08-31T03:46:10.000Z",
                },
                exists: "yes",
              },
              layout: {
                info: {
                  displayText: "3-3 seat layout",
                  quality: "standard",
                  updatedAt: "2016-05-19T05:01:00.000Z",
                },
              },
              power: {
                info: {
                  displayText: "Power & USB outlets",
                  quality: "better",
                  updatedAt: "2016-05-19T05:01:01.000Z",
                },
                exists: "yes",
              },
              freshFood: {
                info: {
                  displayText: "Premium snacks (fee)",
                  quality: "better",
                  updatedAt: "2019-01-07T01:32:22.000Z",
                },
                exists: "yes",
              },
            },
            utas: {
              fare: {
                brandName: "BASIC",
                brandCodes: ["BASIC"],
              },
              utas: [
                {
                  headline: "Not allowed",
                  description: "Advance change not allowed",
                  bagLimits: {},
                  category: "advance-change",
                  assessment: "restriction",
                },
                {
                  headline: "Not allowed",
                  description: "Advance change not allowed",
                  bagLimits: {},
                  category: "advance-change",
                  assessment: "restriction",
                },
                {
                  headline: "Non-refundable",
                  description:
                    "First checked bag for CAD39.50 and Second checked bag for CAD60.00 per passenger",
                  bagLimits: {},
                  category: "cancellation",
                  assessment: "restriction",
                },
                {
                  headline: "Non-refundable",
                  description:
                    "First checked bag for CAD39.50 and Second checked bag for CAD60.00 per passenger",
                  bagLimits: {},
                  category: "cancellation",
                  assessment: "restriction",
                },
                {
                  headline: "Not allowed",
                  description: "Lounge access not allowed",
                  icon: {
                    smallIconUrl:
                      "https://d13cihc9138cdj.cloudfront.net/icons/9c5ab2dc-9e97-4adb-9386-dc29ae11f0c1/small/lounge-access.png?channel_id=gyr8kszn",
                    largeIconUrl:
                      "https://d13cihc9138cdj.cloudfront.net/icons/9c5ab2dc-9e97-4adb-9386-dc29ae11f0c1/large/lounge-access.png?channel_id=gyr8kszn",
                  },
                  bagLimits: {},
                  category: "lounge-access",
                  assessment: "restriction",
                },
                {
                  headline: "Not allowed",
                  description: "Lounge access not allowed",
                  icon: {
                    smallIconUrl:
                      "https://d13cihc9138cdj.cloudfront.net/icons/9c5ab2dc-9e97-4adb-9386-dc29ae11f0c1/small/lounge-access.png?channel_id=gyr8kszn",
                    largeIconUrl:
                      "https://d13cihc9138cdj.cloudfront.net/icons/9c5ab2dc-9e97-4adb-9386-dc29ae11f0c1/large/lounge-access.png?channel_id=gyr8kszn",
                  },
                  bagLimits: {},
                  category: "lounge-access",
                  assessment: "restriction",
                },
                {
                  headline: "At check-in or for a fee",
                  description: "Select seat at check-in or anytime for a fee",
                  bagLimits: {},
                  category: "seat-selection",
                  assessment: "neutral",
                },
                {
                  headline: "At check-in or for a fee",
                  description: "Select seat at check-in or anytime for a fee",
                  bagLimits: {},
                  category: "seat-selection",
                  assessment: "neutral",
                },
                {
                  headline: "1st for CAD30  - 2nd for CAD50",
                  description:
                    "First checked bag for CAD30.00 and Second checked bag for CAD50.00 per passenger",
                  icon: {
                    smallIconUrl:
                      "https://d13cihc9138cdj.cloudfront.net/icons/6b105b08-49e0-44d1-92bd-18a28bb9bb00/small/checked-bag-allowance.png",
                    largeIconUrl:
                      "https://d13cihc9138cdj.cloudfront.net/icons/6b105b08-49e0-44d1-92bd-18a28bb9bb00/large/checked-bag-allowance.png",
                  },
                  bagLimits: {
                    weightKg: "23",
                    sizeLcm: "158",
                  },
                  category: "checked-bag-allowance",
                  assessment: "fee",
                },
                {
                  headline: "1 free & personal item",
                  description: "1 carry on bag & personal item",
                  icon: {
                    smallIconUrl:
                      "https://d13cihc9138cdj.cloudfront.net/icons/07d977fd-089e-47ce-88a6-c0b092a357df/small/carry-on-allowance.png",
                    largeIconUrl:
                      "https://d13cihc9138cdj.cloudfront.net/icons/07d977fd-089e-47ce-88a6-c0b092a357df/large/carry-on-allowance.png",
                  },
                  bagLimits: {},
                  category: "carry-on-allowance",
                  assessment: "benefit",
                },
              ],
            },
            upas: [
              {
                headline: "Minimizing the Spread of COVID-19",
                description:
                  "Our aircrafts are equipped with HEPA filters to achieve a viral removal efficiency of greater than 99.99%.",
                icon: {
                  smallIconUrl:
                    "https://d13cihc9138cdj.cloudfront.net/icons/49f53a4d-d365-425c-a482-a8f97745569d/small/Plane.png?channel_id=gyr8kszn",
                  largeIconUrl:
                    "https://d13cihc9138cdj.cloudfront.net/icons/49f53a4d-d365-425c-a482-a8f97745569d/large/Plane.png?channel_id=gyr8kszn",
                },
                cta: {
                  text: "Learn More",
                  url: "https://www.westjet.com/en-us/travel-info/advisories#coronavirus",
                },
                category: "promotion",
              },
              {
                headline: "Minimizing the Spread of COVID-19",
                description:
                  "We're taking additional precautionary measures by expanding and increasing the frequency of our aircraft sanitization at our busiest bases.",
                icon: {
                  smallIconUrl:
                    "https://d13cihc9138cdj.cloudfront.net/icons/da81f565-0ca8-4155-9d98-024d8f64b57f/small/cleaning.png?channel_id=gyr8kszn",
                  largeIconUrl:
                    "https://d13cihc9138cdj.cloudfront.net/icons/da81f565-0ca8-4155-9d98-024d8f64b57f/large/cleaning.png?channel_id=gyr8kszn",
                },
                cta: {
                  text: "Learn More",
                  url: "https://www.westjet.com/en-us/travel-info/advisories#coronavirus",
                },
                category: "promotion",
              },
            ],
          },
        },
      ],
      fareScore: {
        isFastest: false,
        isBestQuality: false,
        isCheapest: false,
        comfort: -184.19,
        isBest: false,
        value: -184.19,
      },
    },
    {
      penaltiesInfo: {
        changePenalty: {
          amount: 100,
          currency: "USD",
          Penalty: PenaltyEnum.WithFee,
        },
        cancelPenalty: {
          Penalty: PenaltyEnum.NotPossible,
        },
        PenaltiesInfo: PenaltiesInfoEnum.Known,
      },
      paxPricings: [
        {
          paxType: "ADT",
          pricing: {
            baseAmount: {
              fiat: {
                currencyCode: "USD",
                currencySymbol: "$",
                value: 171,
              },
              rewards: {
                "t.b70776bc93874f65ada967b206a6ec68": {
                  currency: "Miles",
                  value: 17100,
                },
                "t.0d62f1f5026e4d1982eba0a7f17524ba": {
                  currency: "Cash",
                  value: 171,
                },
                "t.3a04bdf22d3443c1ba4e726e9db6b8f3": {
                  currency: "Points",
                  value: 17100,
                },
                "t.61dddce7c7624554ba3ba1a8374f72aa": {
                  currency: "Miles",
                  value: 17100,
                },
                "t.a003ac12bf8f41128d837df990172470": {
                  currency: "Miles",
                  value: 17100,
                },
                "t.1af6701fa04e4eccbef6e4102290d7a6": {
                  currency: "Miles",
                  value: 35000,
                },
                "t.95d80d87a042468b8c484f351a9b21e0": {
                  currency: "Cash",
                  value: 171,
                },
              },
            },
            taxAmount: {
              fiat: {
                currencyCode: "USD",
                currencySymbol: "$",
                value: 36.84,
              },
              rewards: {
                "t.b70776bc93874f65ada967b206a6ec68": {
                  currency: "Miles",
                  value: 3684,
                },
                "t.0d62f1f5026e4d1982eba0a7f17524ba": {
                  currency: "Cash",
                  value: 37,
                },
                "t.3a04bdf22d3443c1ba4e726e9db6b8f3": {
                  currency: "Points",
                  value: 3684,
                },
                "t.61dddce7c7624554ba3ba1a8374f72aa": {
                  currency: "Miles",
                  value: 3684,
                },
                "t.a003ac12bf8f41128d837df990172470": {
                  currency: "Miles",
                  value: 3684,
                },
                "t.1af6701fa04e4eccbef6e4102290d7a6": {
                  currency: "Miles",
                  value: 15000,
                },
                "t.95d80d87a042468b8c484f351a9b21e0": {
                  currency: "Cash",
                  value: 37,
                },
              },
            },
            lastTicketDate: "2021-08-26",
          },
          quantity: 1,
        },
      ],
      timestamp: 1629917164708,
      took: 2866,
      id: "d5efe2b0-46f2-4ce2-a8dd-e7df7a3998ec",
      conversationId: "8884514b-4984-48b6-aeb2-7797f9c32fc0",
      slices: [
        {
          outgoing: true,
          fareShelf: {
            rating: 1,
            brandName: "Econo",
            shortBrandName: "Econo",
            opaqueMapperStep: "brandName",
          },
          fareDetails: {
            segments: [
              {
                penaltiesInfo: {
                  changePenalty: {
                    amount: 100,
                    currency: "USD",
                    Penalty: PenaltyEnum.WithFee,
                  },
                  cancelPenalty: {
                    Penalty: PenaltyEnum.NotPossible,
                  },
                  PenaltiesInfo: PenaltiesInfoEnum.Known,
                },
                ticketIndex: 0,
                outgoing: true,
                brand: {
                  sabreFareBrand: {
                    brandId: "ECONO",
                    brandName: "ECONO",
                  },
                },
                carrierCabinClass: "Y",
                code: "LT0D0ZEG",
                codes: {
                  ADT: "LT0D0ZEG",
                },
                validatingCarrierCode: "WS",
                bookingCode: "L",
                privateFare: false,
                connection: true,
                availableSeats: 9,
                baggageInfo: {
                  allowance: {
                    pieces: 0,
                    descriptions: [],
                  },
                  charges: [
                    {
                      firstPiece: 1,
                      amount: 30,
                      lastPiece: 1,
                      currency: "USD",
                      descriptions: [],
                    },
                    {
                      firstPiece: 2,
                      amount: 50,
                      lastPiece: 2,
                      currency: "USD",
                      descriptions: [],
                    },
                  ],
                  BaggageInfo: BaggageInfoEnum.Allowed,
                },
              },
              {
                penaltiesInfo: {
                  changePenalty: {
                    amount: 100,
                    currency: "USD",
                    Penalty: PenaltyEnum.WithFee,
                  },
                  cancelPenalty: {
                    Penalty: PenaltyEnum.NotPossible,
                  },
                  PenaltiesInfo: PenaltiesInfoEnum.Known,
                },
                ticketIndex: 0,
                outgoing: true,
                brand: {
                  sabreFareBrand: {
                    brandId: "ECONO",
                    brandName: "ECONO",
                  },
                },
                carrierCabinClass: "Y",
                code: "LT0D0ZEG",
                codes: {
                  ADT: "LT0D0ZEG",
                },
                validatingCarrierCode: "WS",
                bookingCode: "L",
                privateFare: false,
                connection: false,
                availableSeats: 9,
                baggageInfo: {
                  allowance: {
                    pieces: 0,
                    descriptions: [],
                  },
                  charges: [
                    {
                      firstPiece: 1,
                      amount: 30,
                      lastPiece: 1,
                      currency: "USD",
                      descriptions: [],
                    },
                    {
                      firstPiece: 2,
                      amount: 50,
                      lastPiece: 2,
                      currency: "USD",
                      descriptions: [],
                    },
                  ],
                  BaggageInfo: BaggageInfoEnum.Allowed,
                },
              },
            ],
          },
        },
      ],
      fareScore: {
        isFastest: false,
        isBestQuality: false,
        isCheapest: false,
        comfort: -184.19,
        isBest: false,
        value: -184.19,
      },
    },
    {
      penaltiesInfo: {
        changePenalty: {
          amount: 100,
          currency: "USD",
          Penalty: PenaltyEnum.WithFee,
        },
        cancelPenalty: {
          Penalty: PenaltyEnum.NotPossible,
        },
        PenaltiesInfo: PenaltiesInfoEnum.Known,
      },
      paxPricings: [
        {
          paxType: "ADT",
          pricing: {
            baseAmount: {
              fiat: {
                currencyCode: "USD",
                currencySymbol: "$",
                value: 791,
              },
              rewards: {
                "t.b70776bc93874f65ada967b206a6ec68": {
                  currency: "Miles",
                  value: 79100,
                },
                "t.0d62f1f5026e4d1982eba0a7f17524ba": {
                  currency: "Cash",
                  value: 791,
                },
                "t.3a04bdf22d3443c1ba4e726e9db6b8f3": {
                  currency: "Points",
                  value: 79100,
                },
                "t.61dddce7c7624554ba3ba1a8374f72aa": {
                  currency: "Miles",
                  value: 79100,
                },
                "t.a003ac12bf8f41128d837df990172470": {
                  currency: "Miles",
                  value: 79100,
                },
                "t.1af6701fa04e4eccbef6e4102290d7a6": {
                  currency: "Miles",
                  value: 79100,
                },
                "t.95d80d87a042468b8c484f351a9b21e0": {
                  currency: "Cash",
                  value: 791,
                },
              },
            },
            taxAmount: {
              fiat: {
                currencyCode: "USD",
                currencySymbol: "$",
                value: 83.34,
              },
              rewards: {
                "t.b70776bc93874f65ada967b206a6ec68": {
                  currency: "Miles",
                  value: 8334,
                },
                "t.0d62f1f5026e4d1982eba0a7f17524ba": {
                  currency: "Cash",
                  value: 84,
                },
                "t.3a04bdf22d3443c1ba4e726e9db6b8f3": {
                  currency: "Points",
                  value: 8334,
                },
                "t.61dddce7c7624554ba3ba1a8374f72aa": {
                  currency: "Miles",
                  value: 8334,
                },
                "t.a003ac12bf8f41128d837df990172470": {
                  currency: "Miles",
                  value: 8334,
                },
                "t.1af6701fa04e4eccbef6e4102290d7a6": {
                  currency: "Miles",
                  value: 15000,
                },
                "t.95d80d87a042468b8c484f351a9b21e0": {
                  currency: "Cash",
                  value: 84,
                },
              },
            },
            lastTicketDate: "2021-08-26",
          },
          quantity: 1,
        },
      ],
      timestamp: 1629917164708,
      took: 2866,
      id: "e01a49fb-2c5c-403e-a500-c8ccd353df13",
      conversationId: "8884514b-4984-48b6-aeb2-7797f9c32fc0",
      slices: [
        {
          outgoing: true,
          fareShelf: {
            rating: 2,
            brandName: "Premium",
            shortBrandName: "Premium",
            opaqueMapperStep: "brandName",
          },
          fareDetails: {
            segments: [
              {
                penaltiesInfo: {
                  changePenalty: {
                    amount: 100,
                    currency: "USD",
                    Penalty: PenaltyEnum.WithFee,
                  },
                  cancelPenalty: {
                    Penalty: PenaltyEnum.NotPossible,
                  },
                  PenaltiesInfo: PenaltiesInfoEnum.Known,
                },
                ticketIndex: 0,
                outgoing: true,
                brand: {
                  sabreFareBrand: {
                    brandId: "PREMIUM",
                    brandName: "PREMIUM",
                  },
                },
                carrierCabinClass: "S",
                code: "RT0D0ZPS",
                codes: {
                  ADT: "RT0D0ZPS",
                },
                validatingCarrierCode: "WS",
                bookingCode: "R",
                privateFare: false,
                connection: true,
                availableSeats: 9,
                baggageInfo: {
                  allowance: {
                    pieces: 2,
                    descriptions: [],
                  },
                  charges: [],
                  BaggageInfo: BaggageInfoEnum.Allowed,
                },
              },
              {
                penaltiesInfo: {
                  changePenalty: {
                    amount: 100,
                    currency: "USD",
                    Penalty: PenaltyEnum.WithFee,
                  },
                  cancelPenalty: {
                    Penalty: PenaltyEnum.NotPossible,
                  },
                  PenaltiesInfo: PenaltiesInfoEnum.Known,
                },
                ticketIndex: 0,
                outgoing: true,
                brand: {
                  sabreFareBrand: {
                    brandId: "PREMIUM",
                    brandName: "PREMIUM",
                  },
                },
                carrierCabinClass: "S",
                code: "RT0D0ZPS",
                codes: {
                  ADT: "RT0D0ZPS",
                },
                validatingCarrierCode: "WS",
                bookingCode: "R",
                privateFare: false,
                connection: false,
                availableSeats: 6,
                baggageInfo: {
                  allowance: {
                    pieces: 2,
                    descriptions: [],
                  },
                  charges: [],
                  BaggageInfo: BaggageInfoEnum.Allowed,
                },
              },
            ],
          },
        },
      ],
      fareScore: {
        isFastest: false,
        isBestQuality: false,
        isCheapest: false,
        comfort: -184.19,
        isBest: false,
        value: -184.19,
      },
    },
  ],
  id: "0xMhOcKjNCouAXA86Bxx4W8JSL4uHE",
  validatingCarrierCode: "UA",
  slices: [
    {
      id: "12345",
      originName: "Toronto",
      destinationName: "Vancouver",
      departureTime: "2021-02-15T18:00:00.000-05:00",
      outgoing: true,
      arrivalTime: "2021-02-15T21:14:00.000-08:00",
      originCode: "EWR",
      totalDurationMinutes: 374,
      destinationCode: "SFO",
      segmentDetails: [
        {
          airlineName: "Toronto Airport",
          originName: "Toronto",
          destinationName: "Vancouver",
          departureTime: "2021-02-15T18:00:00.000-05:00",
          airlineCode: "UA",
          arrivalTime: "2021-02-15T21:14:00.000-08:00",
          originCode: "EWR",
          flightNumber: "2006",
          destinationCode: "SFO",
        },
      ],
      stops: 0,
    },
    {
      id: "12345",
      originName: "Vancouver",
      destinationName: "Toronto",
      departureTime: "2021-02-22T12:30:00.000-08:00",
      outgoing: false,
      arrivalTime: "2021-02-22T21:08:00.000-05:00",
      originCode: "SFO",
      totalDurationMinutes: 338,
      destinationCode: "EWR",
      segmentDetails: [
        {
          airlineName: "Toronto Airport",
          originName: "Toronto",
          destinationName: "Vancouver",
          departureTime: "2021-02-22T12:30:00.000-08:00",
          airlineCode: "UA",
          arrivalTime: "2021-02-22T21:08:00.000-05:00",
          originCode: "SFO",
          flightNumber: "646",
          destinationCode: "EWR",
        },
      ],
      stops: 0,
    },
  ],
};
