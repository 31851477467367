import { useEffect, useMemo } from "react";
import { CheckoutComponentMap, CheckoutProps } from "@hopper-b2b/types";

import { CheckoutStateProvider } from "./context/CheckoutProvider";
import { useCheckoutState } from "./context/hooks";
import { getParentState } from "./helpers";
import { Event } from "./events";

export interface CheckoutComponentProps {
  key: string;
  className?: string;
  goNext: () => void;
  goPrevious: () => void;
}

export const Checkout = ({
  actions,
  context,
  guards,
  stateMachine,
  Component,
  services,
  onLoad,
  onCleanUp,
  getInitialContext,
  onStateValueChange,
  onPathnameChange,
  validateContext,
}: CheckoutProps) => {
  // Run any initializing actions on page load here
  useEffect(() => {
    onLoad?.();
    return () => {
      onCleanUp?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CheckoutStateProvider
      stateMachine={stateMachine}
      actions={actions}
      guards={guards}
      context={context}
      services={services}
      getInitialContext={getInitialContext}
      onStateValueChange={onStateValueChange}
      onPathnameChange={onPathnameChange}
      validateContext={validateContext}
    >
      <Component />
    </CheckoutStateProvider>
  );
};

interface CheckoutWorkflowProps {
  componentMap: CheckoutComponentMap;
}

/**
 * CheckoutMapComponent
 * -----------------
 * Creates an array of components based on componentMap which
 * is passed from the tenant.
 **/
export const CheckoutMapComponent = ({
  componentMap,
}: CheckoutWorkflowProps) => {
  const [state, send] = useCheckoutState();

  const currentParentState = useMemo(
    () => getParentState(state?.value),
    [state?.value]
  );

  const goNext = () => send(Event.NEXT);

  const goPrevious = () => send(Event.PREVIOUS);

  const components = useMemo(() => {
    const states = Object.keys(componentMap);
    return states.map((state) => componentMap[state]);
  }, [componentMap]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
    // INFO: Scroll To the top of the browser page on url change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return (
    <>
      {components.map((mappedDetails, index: number) => {
        const CurrentComponent = mappedDetails.component;
        const currentProps = mappedDetails.props || {};
        const className = mappedDetails.className;
        const persist = mappedDetails.controlType === "persist";
        // If persist => always render, if not controlType = "hide",
        // where component renders based on current xstate's state value
        const shouldRender =
          persist || currentParentState === mappedDetails.state;
        return (
          shouldRender && (
            <CurrentComponent
              {...currentProps}
              key={index}
              className={className}
              goNext={goNext}
              goPrevious={goPrevious}
            />
          )
        );
      })}
    </>
  );
};
