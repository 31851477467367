import { connect, ConnectedProps } from "react-redux";
import { getCandidateIdIfPDPEligible } from "../../reducer/selectors";
import { PriceFreezeEntry } from "./component";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    priceDropProtectionCandidateId: getCandidateIdIfPDPEligible(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeEntryConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPriceFreezeEntry = connector(PriceFreezeEntry);
