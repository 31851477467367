import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Dialog, Link, Slide } from "@material-ui/core";
import { HopperFooterBackground } from "../../index";
import "./styles.scss";

export interface IHamburgerButtonProps {
  onOpen: () => void;
  onClose: () => void;
  open: boolean;
  menu: JSX.Element;
  direction?: string;
  backgroundImage?: string;
}

export const HamburgerButton = ({
  onOpen,
  onClose,
  open,
  menu,
  backgroundImage = HopperFooterBackground,
  direction,
}: IHamburgerButtonProps) => (
  <>
    <Box className="hamburger-button-wrapper">
      <Link component="button" className="hamburger-button" onClick={onOpen}>
        <FontAwesomeIcon icon={faBars as IconProp} />
      </Link>
    </Box>
    <Dialog
      className="hamburger-menu-popup"
      open={open}
      onClose={onClose}
      TransitionComponent={Slide}
      TransitionProps={{
        // See https://mui.com/material-ui/transitions/#performance-amp-seo
        unmountOnExit: true,
        /* eslint-disable */
        // @ts-ignore: Fix the type definition of TransitionProps to include direction.
        direction: direction || "left",
        /* eslint-enable */
      }}
      fullScreen
    >
      <div
        className="hamburger-menu-background"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        {menu}
      </div>
    </Dialog>
  </>
);
