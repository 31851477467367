import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";

export const fetchFlightReservation = async (
  itineraryId: string,
  headerToken: string
): Promise<any> => {
  const body: { itineraryId: string; referenceDateTime: string } = {
    itineraryId: itineraryId,
    referenceDateTime: new Date().toISOString(),
  };
  try {
    const res = await axiosInstance.put(
      "/api/v0/itinerary/flight/single",
      body,
      {
        headers: {
          "Trip-Access-Token": headerToken,
        },
      }
    );
    const { data } = res;
    return data;
  } catch (e) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
    throw e;
  }
};
