import "@datadog/browser-logs/bundle/datadog-logs";
import { datadogRum } from "@datadog/browser-rum";
import { inspect } from "@xstate/inspect";
import { createRoot } from "react-dom/client";
import { v4 as uuidv4 } from "uuid";
import { Root } from "./Root";
import "./flights-overrides.scss";
import "./trips/trips-overrides.scss";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import {
  CLIENT_TOKEN,
  DATADOG_SITE,
  initializeRum,
  startRumSessionReplayRecording,
} from "@hopper-b2b/datadog";
import config from "./utils/config";
import { ClientName } from "@hopper-b2b/types";

// XState
process.env.NODE_ENV !== "production" &&
  inspect({
    iframe: false,
  });

// Datadog
const sessionIdKey = "sessionId";
const sessionId = sessionStorage.getItem(sessionIdKey)
  ? sessionStorage.getItem(sessionIdKey)
  : uuidv4();
sessionStorage.setItem(sessionIdKey, sessionId);
const storeDomain = "commbank.com";

window.DD_LOGS.init({
  clientToken: CLIENT_TOKEN,
  site: DATADOG_SITE,
  service: ClientName.COMMBANK_AU,
  version: config.REACT_APP_VERSION,
  env: config.ENV,
  sampleRate: 100,
  trackInteractions: true,
  beforeSend: (log: any) => {
    log.browser_session_id = sessionId;
    log.path = window.location.pathname;
  },
});

setTimeout(() => {
  initializeRum({
    enableSessionRecording: true,
    service: ClientName.COMMBANK_AU,
    env: config.ENV,
    version: config.REACT_APP_VERSION,
    allowedTracingUrls: [
      "http://localhost:3003",
      "https://commbank-au.eng.app-development.h4r.io/",
      "https://commbank-au.staging.hopper.com/",
      "https://travelbooking.hopper.com/",
    ],
  });
  startRumSessionReplayRecording();
}, 1000);

setTimeout(() => {
  window.DD_LOGS.logger.info(
    `DD RUM: ${JSON.stringify(datadogRum.getInternalContext())}`
  );
}, 2000);

// Riskified
const riskifiedScript = document.createElement("script");
riskifiedScript.src =
  ("https:" === document.location.protocol ? "https://" : "http://") +
  "beacon.riskified.com?shop=" +
  storeDomain +
  "&sid=" +
  sessionId;
riskifiedScript.async = true;
riskifiedScript.type = "text/javascript";
document.head.appendChild(riskifiedScript);

// Recaptcha
const recaptcha_script = document.createElement("script");
recaptcha_script.defer = true;
recaptcha_script.async = true;
recaptcha_script.src = `https://www.google.com/recaptcha/enterprise.js?render=${config.RECAPTCHA_KEY}&waf=session`;
const head = document.getElementsByTagName("head")[0];
head.appendChild(recaptcha_script);

// Render application
const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
