import { ClientName } from "@hopper-b2b/types";
import { ActionButton } from "@hopper-b2b/ui-core";
import { getEnvVariables } from "@hopper-b2b/utilities";
import { Box, Paper } from "@material-ui/core";
import clsx from "clsx";
import { FloatingBox } from "../FloatingBox";
import "./styles.scss";

export interface MobileFloatingSectionButton {
  children: string | JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  wrapperClassName?: string;
}

export interface IMobileFloatingSectionProps {
  className?: string;
  primaryButton: MobileFloatingSectionButton;
  secondaryButton?: MobileFloatingSectionButton | null;
  description?: any;
}

export const MobileFloatingSection = (props: IMobileFloatingSectionProps) => {
  const { className } = props;

  const usePaper = getEnvVariables("clientName") === ClientName.HOPPER;

  return (
    <FloatingBox className={clsx(className, "mobile-floating-section")}>
      {usePaper ? (
        <Paper variant="outlined" square>
          <Box p={3}>
            <MobileFloatingSectionContent {...props} />
          </Box>
        </Paper>
      ) : (
        <MobileFloatingSectionContent {...props} />
      )}
    </FloatingBox>
  );
};

const MobileFloatingSectionContent = (props: IMobileFloatingSectionProps) => {
  const { description, primaryButton, secondaryButton } = props;

  return (
    <>
      {description ? (
        <div className="mobile-floating-section-description">{description}</div>
      ) : null}
      {secondaryButton ? (
        <ActionButton
          className={clsx(
            "mobile-floating-section-wrapper",
            "secondary-button-wrapper",
            secondaryButton.wrapperClassName
          )}
          buttonClassName={clsx(
            "mobile-floating-section-button",
            "secondary-button",
            secondaryButton.className
          )}
          defaultStyle="h4r-secondary"
          disabled={secondaryButton.disabled}
          message={secondaryButton.children}
          onClick={secondaryButton.onClick}
        />
      ) : null}
      <ActionButton
        className={clsx(
          "mobile-floating-section-wrapper",
          "primary-button-wrapper",
          primaryButton.wrapperClassName
        )}
        buttonClassName={clsx(
          "mobile-floating-section-button",
          "primary-button",
          primaryButton.className
        )}
        disabled={primaryButton.disabled}
        message={primaryButton.children}
        onClick={primaryButton.onClick}
      />
    </>
  );
};
