import { useEffect } from "react";
import { MultiSelectDropdown } from "@hopper-b2b/ui";

import * as textConstants from "./textConstants";
import { AirportFilterProps } from "./container";
import { generateTrackingEvent } from "../../../../../../actions/actions";
import { trackEvent } from "@hopper-b2b/api";
import { IAirportOptions } from "../../../../../../../shop/reducer";
import { useApiConfigSelector } from "@hopper-b2b/utilities";
import { flightApiConfigStoreKey } from "../../../../../../../../reducers/types";

interface IAirportFilterProps extends AirportFilterProps {
  allAirports: IAirportOptions[];
  showDropdownContentOnly?: boolean;
}

export const AirportFilterSelection = (props: IAirportFilterProps) => {
  const {
    airportFilter = [],
    setAirportFilter,
    allAirports,
    showDropdownContentOnly,
  } = props;
  const apiConfig = useApiConfigSelector(flightApiConfigStoreKey);

  useEffect(() => {
    if (airportFilter.length > 0)
      trackEvent(generateTrackingEvent("airport"), apiConfig);
  }, [airportFilter]);

  return (
    <MultiSelectDropdown
      currentValue={airportFilter}
      dropdownLabel={textConstants.AIRPORTS}
      options={allAirports}
      setValue={setAirportFilter}
      showDropdownContentOnly={showDropdownContentOnly}
      className="b2b-shop-filter"
      popoverClassName="b2b"
    />
  );
};
