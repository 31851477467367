import { FlightShopType, TripCategory } from "@hopper-b2b/types";
import {
  useEnableCfar,
  useEnableChfar,
  useEnableDisruptionProtection,
  useEnableFintechSelection,
  useEnableWallet,
  useHideReviewItitinerary,
  useIsSessionAuthenticated,
} from "@hopper-b2b/utilities";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { populateFlightBookQueryParams } from "../../book/actions/actions";
import { isCfarOffersValid } from "../../cfar/reducer";
import { isChfarOffersValid } from "../../chfar/reducer";
import { isAirDisruptionValid } from "../../disruption/reducer";
import { getTripCategory } from "../../search/reducer";
import {
  populateFlightShopQueryParams,
  setFlightShopProgress,
} from "../actions/actions";
import {
  FlightShopStep,
  flightShopProgressSelector,
  flightShopTypeSelector,
} from "../reducer";
import { pushToPathWithExistingQueryParams } from "../utils/parseQueryString";
import { PATH_BOOK } from "../../../utils";

export const useUpdateQueryParams = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  return useCallback(
    (params: any, replace = false) => {
      pushToPathWithExistingQueryParams(history, pathname, params, replace);
    },
    [history, pathname]
  );
};

export const useUpdateFlightShopStep = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const goBackToFlightShop = useGoBackToFlightShop();
  const updateQueryParams = useUpdateQueryParams();

  const isBookingStep = useMemo(() => pathname.includes(PATH_BOOK), [pathname]);

  return useCallback(
    (flightShopProgress?: FlightShopStep, replace = false) => {
      // If on booking step, we need to go back to shop path first
      if (isBookingStep) {
        goBackToFlightShop(flightShopProgress);
        return;
      }

      if (flightShopProgress !== undefined) {
        dispatch(setFlightShopProgress(flightShopProgress));
      }

      updateQueryParams({ flightShopProgress }, replace);
    },
    [isBookingStep, updateQueryParams, goBackToFlightShop, dispatch]
  );
};

/**
 * Go back to flight shop from flight checkout
 */
export const useGoBackToFlightShop = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return useCallback(
    (flightShopStep?: FlightShopStep, useHistoryPush?: boolean) => {
      dispatch(
        populateFlightShopQueryParams({
          history,
          useHistoryPush: useHistoryPush || false,
          newQueryParams: { flightShopProgress: flightShopStep },
        })
      );
    },
    [dispatch, history]
  );
};

export const useGoToCheckout = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  return useCallback(
    (replace?: boolean) =>
      dispatch(populateFlightBookQueryParams({ history, replace })),
    [dispatch, history]
  );
};

const useShowDisruptionStep = () => {
  const shopType = useSelector(flightShopTypeSelector);
  const defaultFlightShop = shopType === FlightShopType.DEFAULT;

  const enableDisruption = useEnableDisruptionProtection();
  const hasValidAirDisruptionOffer = useSelector(isAirDisruptionValid(true));

  return useMemo(
    () => enableDisruption && hasValidAirDisruptionOffer && defaultFlightShop,
    [enableDisruption, hasValidAirDisruptionOffer, defaultFlightShop]
  );
};

const useShowCfarStep = () => {
  const shopType = useSelector(flightShopTypeSelector);
  const defaultFlightShop = shopType === FlightShopType.DEFAULT;

  const showFlightCfar = useEnableCfar();
  const hasValidCfarOffers = useSelector(isCfarOffersValid(true));
  const enableFintechSelection = useEnableFintechSelection();

  return useMemo(
    () =>
      !enableFintechSelection &&
      showFlightCfar &&
      hasValidCfarOffers &&
      defaultFlightShop,
    [
      enableFintechSelection,
      hasValidCfarOffers,
      showFlightCfar,
      defaultFlightShop,
    ]
  );
};

const useShowChfarStep = () => {
  const shopType = useSelector(flightShopTypeSelector);
  const defaultFlightShop = shopType === FlightShopType.DEFAULT;

  const showFlightChfar = useEnableChfar();
  const hasValidChfarOffers = useSelector(isChfarOffersValid(true));
  const enableFintechSelection = useEnableFintechSelection();

  return useMemo(
    () =>
      !enableFintechSelection &&
      showFlightChfar &&
      hasValidChfarOffers &&
      defaultFlightShop,
    [
      enableFintechSelection,
      hasValidChfarOffers,
      showFlightChfar,
      defaultFlightShop,
    ]
  );
};

const useWalletOfferStep = () => {
  const shopType = useSelector(flightShopTypeSelector);
  const defaultFlightShop = shopType === FlightShopType.DEFAULT;

  const enableWallet = useEnableWallet();
  const authenticated = useIsSessionAuthenticated();

  return useMemo(
    () => enableWallet && defaultFlightShop && authenticated,
    [enableWallet, defaultFlightShop, authenticated]
  );
};

export const useGoToNextStep = () => {
  const tripCategory = useSelector(getTripCategory);
  const isRoundTrip = tripCategory === TripCategory.ROUND_TRIP;

  const shopType = useSelector(flightShopTypeSelector);
  const defaultFlightShop = shopType === FlightShopType.DEFAULT;

  const showDisruptionStep = useShowDisruptionStep();
  const showCfarStep = useShowCfarStep();
  const showChfarStep = useShowChfarStep();

  const showWalletOfferStep = useWalletOfferStep();

  const hideReviewItinerary = useHideReviewItitinerary();
  // TODO(isabela) - decide with CBA if we want a fare details step
  const hideFareDetails = true;

  const enableFintechSelection =
    useEnableFintechSelection() && defaultFlightShop;

  const updateFlightShopStep = useUpdateFlightShopStep();
  const goToCheckout = useGoToCheckout();

  const currentFlightShopStep = useSelector(flightShopProgressSelector);

  const flightShopSteps = useMemo(() => {
    const steps = [FlightShopStep.ChooseDeparture];

    if (isRoundTrip) steps.push(FlightShopStep.ChooseReturn);

    if (!hideReviewItinerary) steps.push(FlightShopStep.ReviewItinerary);

    if (enableFintechSelection) steps.push(FlightShopStep.FintechSelection);

    if (showDisruptionStep) steps.push(FlightShopStep.DisruptionProtection);

    if (showCfarStep) steps.push(FlightShopStep.CfarOffers);
    if (showChfarStep) steps.push(FlightShopStep.ChfarOffer);
    if (showWalletOfferStep) steps.push(FlightShopStep.WalletOffers);

    if (!hideFareDetails) steps.push(FlightShopStep.FareDetails);

    return steps;
  }, [
    enableFintechSelection,
    hideFareDetails,
    hideReviewItinerary,
    isRoundTrip,
    showCfarStep,
    showChfarStep,
    showDisruptionStep,
    showWalletOfferStep,
  ]);

  return useCallback(
    (replace = false) => {
      const currentFlightStepIndex = flightShopSteps.findIndex(
        (step: FlightShopStep) => step === currentFlightShopStep
      );
      const nextFlightShopStep =
        currentFlightStepIndex !== -1
          ? flightShopSteps[currentFlightStepIndex + 1]
          : undefined;

      if (nextFlightShopStep !== undefined) {
        return updateFlightShopStep(nextFlightShopStep, replace);
      } else {
        return goToCheckout(replace);
      }
    },
    [currentFlightShopStep, flightShopSteps, goToCheckout, updateFlightShopStep]
  );
};
