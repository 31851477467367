import { useMemo } from "react";
import { Box } from "@material-ui/core";
import dayjs from "dayjs";
import { DateTimeFormatStyle, formatDateTime } from "@hopper-i18n/formatter";
import { Airport, TripSlice } from "@b2bportal/air-shopping-api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { formatInterval, removeTimezone } from "@hopper-b2b/utilities";
import { AirlineIcon } from "../AirlineIcon";
import "./styles.scss";

interface MiniFlightShopPanelProps {
  airports: { [key: string]: Airport };
  tripSlice: TripSlice;
}

export const MiniFlightSummaryPanel = ({
  airports,
  tripSlice,
}: MiniFlightShopPanelProps) => {
  const { t, language: locale } = useI18nContext();
  const firstTripSegment = tripSlice.segmentDetails[0];

  const flightTimeSpan = useMemo(
    () =>
      `${formatDateTime(
        new Date(removeTimezone(tripSlice.departureTime)),
        locale,
        DateTimeFormatStyle.ShortTime
      )} - ${formatDateTime(
        new Date(removeTimezone(tripSlice.arrivalTime)),
        locale,
        DateTimeFormatStyle.ShortTime
      )} `,
    [tripSlice]
  );

  return (
    <Box className="mini-flight-summary-panel">
      <div className="mini-flight-summary-panel-left">
        <div className="mini-flight-summary-panel-destination">
          <I18nMarkup
            tKey={"flightShopReview.departureTo"}
            replacements={{
              location: airports[tripSlice.destinationCode]
                ? airports[tripSlice.destinationCode].cityName
                : tripSlice.destinationName,
            }}
          />
        </div>
        <div className="mini-flight-summary-panel-airline">
          <AirlineIcon airlineCode={firstTripSegment.airlineCode} />
          <p>{firstTripSegment.airlineName}</p>
        </div>
      </div>
      <div className="mini-flight-summary-panel-right">
        <div className="mini-flight-summary-panel-time">
          <p className="flight-timespan">{flightTimeSpan}</p>
        </div>
        <div className="mini-flight-summary-panel-details">
          <p className="mini-flight-summary-panel-timespan">
            {`${formatInterval(
              dayjs(tripSlice.arrivalTime).diff(
                dayjs(tripSlice.departureTime),
                "minute",
                true
              )
            )}, ${
              tripSlice.stops === 0
                ? t("stopDetails.nonstop")
                : t("stopDetails.stop", { count: tripSlice.stops })
            }`}
          </p>
        </div>
      </div>
    </Box>
  );
};
