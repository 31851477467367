import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";
import {
  fetchFlights,
  fetchHotels,
  fetchCars,
  listWatches,
  setTripsFilter,
  listPriceFreeze,
  setSelectedHotel,
} from "./actions/actions";
import { TripsList } from "./component";
import {
  getSelectedCar,
  getSelectedFlight,
  getSelectedHotel,
  getTripsFilter,
  getTripsLoading,
  hasFetchTripsError,
  hasTripsToDisplay,
  getOpenModal,
  getTripSearchResults,
} from "./reducer";

const mapStateToProps = (state: IStoreState) => ({
  tripsFilter: getTripsFilter(state),
  selectedFlight: getSelectedFlight(state),
  selectedHotel: getSelectedHotel(state),
  selectedCar: getSelectedCar(state),
  tripsLoading: getTripsLoading(state),
  hasError: hasFetchTripsError(state),
  hasTripsToDisplay: hasTripsToDisplay(state),
  openModal: getOpenModal(state),
  tripSearchResults: getTripSearchResults(state),
});

const mapDispatchToProps = {
  fetchCars,
  fetchFlights,
  fetchHotels,
  listWatches,
  setTripsFilter,
  listPriceFreeze,
  setSelectedHotel,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TripsListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedTripsList = connector(withRouter(TripsList));
