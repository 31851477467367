import { TripCategory } from "@hopper-b2b/types";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { getSelectedAccountReferenceId } from "../../../rewards/reducer";
import {
  getDepartureDate,
  getReturnDate,
  getTripCategory,
} from "../../../search/reducer";
import {
  airportsSelector,
  getViewedAmenityReviewProperties,
  isOutgoingMultiTicket,
  isReturnMultiTicket,
  selectedTripSelector,
  tripDetailsLoadingSelector,
  tripDetailsSelector,
  tripTotalIncludingOffersSelector,
} from "../../reducer/selectors";
import { MobileFareDetails } from "./component";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);
  const airports = airportsSelector(state, tripId);
  const selectedFareId = selectedTrip.returnFareId
    ? selectedTrip.returnFareId
    : selectedTrip.outgoingFareId;
  const fareDetails = tripDetails?.fareDetails.find(
    (f) => f.id === selectedFareId
  );

  return {
    isOneWay: getTripCategory(state) === TripCategory.ONE_WAY,
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    tripDetails,
    airports,
    fareDetails,
    tripDetailsLoading: tripDetailsLoadingSelector(state),
    rewardsKey: getSelectedAccountReferenceId(state),
    isMultiTicket: isOutgoingMultiTicket(state) || isReturnMultiTicket(state),
    viewedAmenityReviewProperties: getViewedAmenityReviewProperties(state),
    tripTotalIncludingOffers: tripTotalIncludingOffersSelector(state),
  };
};

const connector = connect(mapStateToProps);

export type MobileFareDetailsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileFareDetails = connector(
  withRouter(MobileFareDetails)
);
