import { useI18nContext } from "@hopper-b2b/i18n";
import { LodgingSummary, RoomInfo } from "@hopper-b2b/types";
import { Box, Grid } from "@material-ui/core";
import clsx from "clsx";
import dayjs, { ConfigType as DayJsInput } from "dayjs";
import { ReactNode } from "react";

import { Icon, IconName } from "../Icon";
import HotelAddress from "./components/HotelAddress";
import HotelName from "./components/HotelName";
import HotelRoomName from "./components/HotelRoomName";
import HotelStarRating from "./components/HotelStarRating";

import "./styles.scss";

export interface IHotelSummaryPanelProps {
  actions?: ReactNode;
  checkIn: DayJsInput | null;
  checkOut: DayJsInput | null;
  className?: string;
  dateFormat?: string;
  fullImage?: boolean;
  hideImage?: boolean;
  hideStarRating?: boolean;
  isMobile?: boolean;
  lodging: LodgingSummary;
  roomInfo?: RoomInfo;
}

const defaultProps: Partial<IHotelSummaryPanelProps> = {
  dateFormat: "MMM D",
  fullImage: false,
  hideImage: false,
  hideStarRating: false,
  isMobile: false,
};

export const HotelSummaryPanel = (
  props: IHotelSummaryPanelProps
): JSX.Element => {
  const {
    actions,
    checkIn,
    checkOut,
    className,
    dateFormat,
    // fullImage,
    // hideImage,
    hideStarRating,
    isMobile,
    lodging,
    roomInfo,
  } = props;
  const { t } = useI18nContext();
  // const [image] = getImageUrlsArray(lodging.media);

  return (
    <Box
      className={clsx("hotel-summary-panel", { mobile: isMobile }, className)}
    >
      {/* {!hideImage && !isMobile && (
        <img
          alt="lodging-media"
          className={clsx("hotel-image", { "full-image": fullImage })}
          src={image}
        />
      )} */}
      <Box className="booking-details">
        {!hideStarRating && <HotelStarRating rating={lodging.starRating} />}
        <HotelName name={lodging.name} />
        <HotelAddress showFullAddress lodgingData={lodging} />
        {!isMobile && roomInfo && (
          <HotelRoomName
            hideImage
            roomInfo={roomInfo}
            isMobile={isMobile}
            // image={image}
          />
        )}
        <Grid
          container
          alignItems="center"
          className="booking-dates"
          direction="row"
          spacing={2}
        >
          <Grid item>
            <Icon className="calendar-icon" name={IconName.Calendar} />
          </Grid>
          <Grid item>
            <Box className="booking-dates-header">{t("checkIn")}</Box>
            <Box className="booking-dates-date">
              {dayjs(checkIn).format(dateFormat)}
            </Box>
          </Grid>
          <Grid item>
            <Icon className="calendar-icon" name={IconName.Calendar} />
          </Grid>
          <Grid item>
            <Box className="booking-dates-header">{t("checkOut")}</Box>
            <Box className="booking-dates-date">
              {dayjs(checkOut).format(dateFormat)}
            </Box>
          </Grid>
        </Grid>
        {isMobile && roomInfo && (
          <HotelRoomName
            roomInfo={roomInfo}
            isMobile={isMobile}
            // image={image}
          />
        )}
      </Box>
      {actions}
    </Box>
  );
};

HotelSummaryPanel.defaultProps = defaultProps;

export default HotelSummaryPanel;
