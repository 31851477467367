import GoogleMap from "google-maps-react-markers";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useSupercluster from "use-supercluster";
import { Lodging } from "@b2bportal/lodging-api";
import { type LoadingLodgings } from "../../reducer";
import { useI18nContext } from "@hopper-b2b/i18n";
import { B2BButton, IconComponent, IconName } from "@hopper-b2b/ui";
import { LodgingShopTrackingEvents } from "@hopper-b2b/types";
import { useTrackEvents, ProductType } from "../../../../util/trackEvent";
import { ClusterMarker, MapMarker } from "./Markers";
import "./styles.scss";

export const DEFAULT_ZOOM = 13;

const LodgingMap = ({
  loading,
  lodgings,
  selectedId,
  overedId,
  centroid,
  zoom,
  mapBounds,
  disableInteraction,
  onSearchArea,
  onSelected,
  onMapChange,
  onMapLoaded,
}: {
  loading: LoadingLodgings;
  lodgings: Lodging[];
  selectedId: string | null;
  overedId: string | null;
  centroid: { lat: number; lng: number };
  zoom?: number;
  mapBounds: [number, number, number, number] | [];
  disableInteraction?: boolean;
  onSearchArea: (bounds: number[]) => void;
  onSelected: (id: string) => void;
  onMapChange: ({ zoom, bounds }) => void;
  onMapLoaded: () => void;
}) => {
  const mapRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [defaultProps] = useState({
    center: centroid,
    zoom: zoom,
  });
  const { t, formatFiatCurrency } = useI18nContext();
  const trackEvent = useTrackEvents();

  useEffect(() => {
    if (
      mapLoaded &&
      !isNaN(centroid.lat) &&
      !isNaN(centroid.lng) &&
      !selectedId
    ) {
      mapRef?.current?.panTo(centroid);
    }
  }, [centroid, mapLoaded]);

  const points = useMemo(() => {
    return lodgings.map((lodging) => ({
      type: "Feature",
      properties: {
        cluster: false,
        lodgingId: lodging.lodging.id,
        lodgingName: lodging.lodging.name,
        price: formatFiatCurrency(lodging.price.nightlyPrice.fiat, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }),
        lodging,
      },
      geometry: {
        type: "Point",
        coordinates: [
          lodging?.lodging.location.coordinates.lon,
          lodging?.lodging.location.coordinates.lat,
        ],
      },
    }));
  }, [lodgings, formatFiatCurrency]);

  const { clusters, supercluster } = useSupercluster({
    points: points as any,
    bounds: mapBounds as any,
    zoom,
    options: { radius: 95, maxZoom: 16 },
  });

  useEffect(() => {
    const cluster = clusters.find(
      (c) => selectedId === c?.properties?.lodgingId
    );
    if (cluster) {
      const [longitude, latitude]: number[] = cluster.geometry.coordinates;
      mapRef?.current?.panTo({
        lat: latitude,
        lng: longitude,
      });
    }
  }, [selectedId]);

  const onSearchAreaClick = useCallback(() => {
    onSearchArea(mapBounds);
  }, [mapBounds, onSearchArea]);

  const onMoveMapTo = useCallback((lat: number, lng: number, z: number) => {
    mapRef?.current?.setZoom(z);
    mapRef?.current?.panTo({
      lat: lat,
      lng: lng,
    });
  }, []);

  useEffect(() => {
    if (!disableInteraction) {
      mapRef?.current?.setOptions({
        draggable: true,
        zoomControl: true,
        scrollwheel: true,
        disableDoubleClickZoom: false,
      });
    } else {
      mapRef?.current?.setOptions({
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
      });
    }
    // We need to trigger this again after loading is changed as
    // Google Maps is reloaded when loading is finished causing the settings above to be overridden.
  }, [disableInteraction, loading]);

  const onMarkerClick = useCallback(
    (id: string) => {
      onSelected(id);
      trackEvent(
        LodgingShopTrackingEvents.hotel_tapped_map_marker,
        ProductType.Hotel
      );
    },
    [onSelected, trackEvent]
  );

  const handleAPILoaded = useCallback(
    ({ map }) => {
      mapRef.current = map;
      setMapLoaded(true);
      onMapLoaded();
    },
    [onMapLoaded]
  );

  const expansionZoom = useCallback(
    (clusterId: number) => {
      try {
        return supercluster?.getClusterExpansionZoom(clusterId);
      } catch (e) {
        return DEFAULT_ZOOM;
      }
    },
    [supercluster]
  );

  const mapPoints = useMemo(() => {
    if (!clusters.length || loading) {
      return null;
    }
    return clusters?.map((cluster) => {
      const [longitude, latitude]: number[] = cluster.geometry.coordinates;

      if (cluster.properties.cluster) {
        const clusterId = Number(cluster.id);
        const pointsInCluster = supercluster.getLeaves(clusterId, Infinity);
        const isOveredInLodgingList = pointsInCluster.find(
          ({ properties: { lodgingId } }) => lodgingId === overedId
        );
        return (
          <ClusterMarker
            key={`cluster-${cluster.id}`}
            cluster={cluster}
            lat={latitude}
            lng={longitude}
            expansionZoom={expansionZoom(clusterId)}
            isOveredInLodgingList={isOveredInLodgingList}
            onClick={disableInteraction ? undefined : onMoveMapTo}
          />
        );
      } else {
        return (
          <MapMarker
            lodging={cluster.properties.lodging}
            selected={selectedId === cluster.properties.lodgingId}
            isOveredInLodgingList={overedId === cluster.properties.lodgingId}
            onClick={disableInteraction ? undefined : onMarkerClick}
            key={`marker-${cluster?.properties?.lodgingId}`}
            lat={latitude}
            lng={longitude}
          />
        );
      }
    });
  }, [
    clusters,
    expansionZoom,
    loading,
    onMarkerClick,
    onMoveMapTo,
    overedId,
    selectedId,
    supercluster,
    disableInteraction,
  ]);

  return (
    <>
      <GoogleMap
        apiKey={import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onChange={onMapChange}
        options={{
          clickableIcons: false,
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
        }}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={handleAPILoaded}
        errorContent={null}
      >
        {points.length ? mapPoints : null}
      </GoogleMap>
      <div className="Map-SearchArea">
        {loading === "loading-from-place" ? null : (
          <B2BButton
            variant="b2b-shop-filter"
            disabled={Boolean(loading)}
            aria-busy={Boolean(loading)}
            onClick={onSearchAreaClick}
          >
            <IconComponent name={IconName.Reload} />
            {t("searchThisArea")}
          </B2BButton>
        )}
      </div>
    </>
  );
};
export default LodgingMap;
