import { trackEvent } from "@hopper-b2b/api";
import {
  AirlineCode,
  APPLIED_FILTER,
  AppliedFilterProperties,
  IApiConfig,
  IDepartureCalendarReport,
  IFlightSearchCriteria,
  IMonthBucket,
  ITimeRange,
  ITripTerminus,
  SliceStopCountFilter,
  TripCategory,
} from "@hopper-b2b/types";
import { PassengerCountPickerType } from "@hopper-b2b/ui";
import { History } from "history";

import {
  FareclassOptionFilter,
  IFlightNumberFilter,
  initialFilterOptions,
  MobileFlightSearchStep,
} from "../reducer";
import { ITripTerminusCategory } from "../types";
import * as actionTypes from "./constants";

export interface IResetFareclassOptionFilter {
  type: actionTypes.RESET_FARECLASS_OPTION_FILTER;
}
export const resetFareclassOptionFilter = (): IResetFareclassOptionFilter => ({
  type: actionTypes.RESET_FARECLASS_OPTION_FILTER,
});

export interface IResetFilters {
  type: actionTypes.RESET_FILTERS;
}
export const resetFilters = (maxPrice: number): IResetFilters => ({
  type: actionTypes.RESET_FILTERS,
  maxPrice,
});

export interface ISetTripCategory {
  type: actionTypes.SET_TRIP_CATEGORY;
  tripCategory: TripCategory;
}

export const setTripCategory = (
  tripCategory: TripCategory
): ISetTripCategory => ({
  type: actionTypes.SET_TRIP_CATEGORY,
  tripCategory,
});

export interface ISetRenderSearchInput {
  type: actionTypes.SET_RENDER_SEARCH_INPUT;
  renderSearchInput: string;
}

export const setRenderSearchInput = (
  renderSearchInput: string
): ISetRenderSearchInput => ({
  type: actionTypes.SET_RENDER_SEARCH_INPUT,
  renderSearchInput,
});

export interface ISetCalendarVisited {
  type: actionTypes.SET_CALENDAR_VISITED;
  calendarVisited: boolean;
}

export const setCalendarVisited = (
  calendarVisited: boolean
): ISetCalendarVisited => ({
  type: actionTypes.SET_CALENDAR_VISITED,
  calendarVisited,
});

export interface ISetOrigin {
  type: actionTypes.SET_ORIGIN;
  origin: ITripTerminus | null;
}

export const setOrigin = (origin: ITripTerminus | null): ISetOrigin => ({
  type: actionTypes.SET_ORIGIN,
  origin,
});

export interface ISetOpenDatesModal {
  type: actionTypes.SET_OPEN_DATES_MODAL;
  openDatesModal: boolean;
}

export const setOpenDatesModal = (
  openDatesModal: boolean
): ISetOpenDatesModal => ({
  type: actionTypes.SET_OPEN_DATES_MODAL,
  openDatesModal,
});

export interface ISetOpenActionModal {
  type: actionTypes.SET_OPEN_BALANCE_PERMISSIONS_MODAL;
  openActionModal: boolean;
}

export const setOpenActionModal = (
  openActionModal: boolean
): ISetOpenActionModal => ({
  type: actionTypes.SET_OPEN_BALANCE_PERMISSIONS_MODAL,
  openActionModal,
});

export interface ISetOriginCategories {
  type: actionTypes.SET_ORIGIN_CATEGORIES;
  originCategories: ITripTerminusCategory[];
}

export const setOriginCategories = (
  originCategories: ITripTerminusCategory[]
): ISetOriginCategories => ({
  type: actionTypes.SET_ORIGIN_CATEGORIES,
  originCategories,
});

export interface IFetchOriginCategories {
  type: actionTypes.FETCH_ORIGIN_CATEGORIES;
  queryString: string;
}

export const fetchOriginCategories = (
  queryString: string
): IFetchOriginCategories => ({
  type: actionTypes.FETCH_ORIGIN_CATEGORIES,
  queryString,
});

export interface ISetDestination {
  type: actionTypes.SET_DESTINATION;
  destination: ITripTerminus | null;
}

export const setDestination = (
  destination: ITripTerminus | null
): ISetDestination => ({
  type: actionTypes.SET_DESTINATION,
  destination,
});

export interface ISetDestinationCategories {
  type: actionTypes.SET_DESTINATION_CATEGORIES;
  destinationCategories: ITripTerminusCategory[];
}

export const setDestinationCategories = (
  destinationCategories: ITripTerminusCategory[]
): ISetDestinationCategories => ({
  type: actionTypes.SET_DESTINATION_CATEGORIES,
  destinationCategories,
});

export interface IFetchDestinationCategories {
  type: actionTypes.FETCH_DESTINATION_CATEGORIES;
  queryString: string;
}

export const fetchDestinationCategories = (
  queryString: string
): IFetchDestinationCategories => ({
  type: actionTypes.FETCH_DESTINATION_CATEGORIES,
  queryString,
});

export interface ISetDepartureDate {
  type: actionTypes.SET_DEPARTURE_DATE;
  date: Date | null;
}

export const setDepartureDate = (date: Date | null): ISetDepartureDate => ({
  type: actionTypes.SET_DEPARTURE_DATE,
  date,
});

export interface ISetReturnDate {
  type: actionTypes.SET_RETURN_DATE;
  date: Date | null;
}

export const setReturnDate = (date: Date | null): ISetReturnDate => ({
  type: actionTypes.SET_RETURN_DATE,
  date,
});

export interface IFetchSearchFromUrl {
  type: actionTypes.FETCH_SEARCH_FROM_URL;
  queryString: string;
  history: History;
}

export const fetchSearchFromUrl = (
  queryString: string,
  history: History
): IFetchSearchFromUrl => ({
  type: actionTypes.FETCH_SEARCH_FROM_URL,
  queryString,
  history,
});

export interface IPopulateSearchUrlParams {
  type: actionTypes.POPULATE_SEARCH_URL_PARAMS;
  params: IFlightSearchCriteria;
  history: History;
}

export const populateSearchUrlParams = (
  params: IFlightSearchCriteria,
  history: History
): IPopulateSearchUrlParams => ({
  type: actionTypes.POPULATE_SEARCH_URL_PARAMS,
  params,
  history,
});

export interface IFetchDepartureCalendar {
  type: actionTypes.FETCH_DEPARTURE_CALENDAR;
}

export const fetchDepartureCalendar = (): IFetchDepartureCalendar => ({
  type: actionTypes.FETCH_DEPARTURE_CALENDAR,
});

export interface ISetDepartureCalendar extends ISetDepartureCalendarArgs {
  type: actionTypes.SET_DEPARTURE_CALENDAR;
}

export interface ISetDepartureCalendarArgs
  extends Partial<IDepartureCalendarReport> {
  departureMonths?: IMonthBucket[];
}

export const setCalendar = (
  report: ISetDepartureCalendarArgs = {}
): ISetDepartureCalendar => {
  return { type: actionTypes.SET_DEPARTURE_CALENDAR, ...report };
};

export interface ISetPassengerCounts {
  type: actionTypes.SET_PASSENGER_COUNTS;
  counts: PassengerCountPickerType;
}

export const setPassengerCounts = (
  counts: PassengerCountPickerType
): ISetPassengerCounts => ({
  type: actionTypes.SET_PASSENGER_COUNTS,
  counts,
});

// Filter option actions
export interface ISetStopsOption {
  type: actionTypes.SET_STOPS_OPTION;
  stopsOption: SliceStopCountFilter;
}

export const setStopsOption = (
  stopsOption: SliceStopCountFilter,
  apiConfig?: IApiConfig
): ISetStopsOption => {
  if (stopsOption !== initialFilterOptions.stopsOption) {
    trackEvent(generateTrackingEvent("stops"), apiConfig);
  }
  return {
    type: actionTypes.SET_STOPS_OPTION,
    stopsOption,
  };
};

export interface ISetMaxPriceFilter {
  type: actionTypes.SET_MAX_PRICE_FILTER;
  maxPriceFilter: number;
}

export const setMaxPriceFilter = (
  maxPriceFilter: number
): ISetMaxPriceFilter => {
  return {
    type: actionTypes.SET_MAX_PRICE_FILTER,
    maxPriceFilter,
  };
};

export interface ISetFareclassOptionFilter {
  type: actionTypes.SET_FARECLASS_OPTION_FILTER;
  fareclassOptionFilter: FareclassOptionFilter;
}

export const setFareclassOptionFilter = (
  fareclassOptionFilter: FareclassOptionFilter
): ISetFareclassOptionFilter => {
  return {
    type: actionTypes.SET_FARECLASS_OPTION_FILTER,
    fareclassOptionFilter,
  };
};

export interface ISetOutboundDepartureTimeRange {
  type: actionTypes.SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER;
  outboundDepartureTimeRange: ITimeRange;
}

export const setOutboundDepartureTimeRange = (
  outboundDepartureTimeRange: ITimeRange,
  apiConfig?: IApiConfig
): ISetOutboundDepartureTimeRange => {
  if (
    outboundDepartureTimeRange !==
    initialFilterOptions.outboundDepartureTimeRange
  ) {
    trackEvent(generateTrackingEvent("time"), apiConfig);
  }

  return {
    type: actionTypes.SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER,
    outboundDepartureTimeRange,
  };
};

export interface ISetOutboundArrivalTimeRange {
  type: actionTypes.SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER;
  outboundArrivalTimeRange: ITimeRange;
}

export const setOutboundArrivalTimeRange = (
  outboundArrivalTimeRange: ITimeRange,
  apiConfig?: IApiConfig
): ISetOutboundArrivalTimeRange => {
  if (
    outboundArrivalTimeRange !== initialFilterOptions.outboundArrivalTimeRange
  ) {
    trackEvent(generateTrackingEvent("time"), apiConfig);
  }

  return {
    type: actionTypes.SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER,
    outboundArrivalTimeRange,
  };
};

export interface ISetReturnDepartureTimeRange {
  type: actionTypes.SET_RETURN_DEPARTURE_TIME_RANGE_FILTER;
  returnDepartureTimeRange: ITimeRange;
}

export const setReturnDepartureTimeRange = (
  returnDepartureTimeRange: ITimeRange,
  apiConfig?: IApiConfig
): ISetReturnDepartureTimeRange => {
  if (
    returnDepartureTimeRange !== initialFilterOptions.returnDepartureTimeRange
  ) {
    trackEvent(generateTrackingEvent("time"), apiConfig);
  }

  return {
    type: actionTypes.SET_RETURN_DEPARTURE_TIME_RANGE_FILTER,
    returnDepartureTimeRange,
  };
};

export interface ISetReturnArrivalTimeRange {
  type: actionTypes.SET_RETURN_ARRIVAL_TIME_RANGE_FILTER;
  returnArrivalTimeRange: ITimeRange;
}

export const setReturnArrivalTimeRange = (
  returnArrivalTimeRange: ITimeRange,
  apiConfig?: IApiConfig
): ISetReturnArrivalTimeRange => {
  if (returnArrivalTimeRange !== initialFilterOptions.returnArrivalTimeRange) {
    trackEvent(generateTrackingEvent("time"), apiConfig);
  }

  return {
    type: actionTypes.SET_RETURN_ARRIVAL_TIME_RANGE_FILTER,
    returnArrivalTimeRange,
  };
};

export interface ISetAirlineFilter {
  type: actionTypes.SET_AIRLINE_FILTER;
  airlineFilter: AirlineCode[];
}

export const setAirlineFilter = (
  airlineFilter: AirlineCode[],
  apiConfig?: IApiConfig
): ISetAirlineFilter => {
  if (airlineFilter !== initialFilterOptions.airlineFilter) {
    trackEvent(generateTrackingEvent("airline"), apiConfig);
  }
  return {
    type: actionTypes.SET_AIRLINE_FILTER,
    airlineFilter,
  };
};

export interface ISetAirportFilter {
  type: actionTypes.SET_AIRPORT_FILTER;
  airportFilter: string[];
}

export const setAirportFilter = (
  airportFilter: string[]
): ISetAirportFilter => {
  return {
    type: actionTypes.SET_AIRPORT_FILTER,
    airportFilter,
  };
};

export interface ISetFlightNumberFilter {
  type: actionTypes.SET_FLIGHT_NUMBER_FILTER;
  flightNumberFilter: IFlightNumberFilter[];
}

export const setFlightNumberFilter = (
  flightNumberFilter: IFlightNumberFilter[]
): ISetFlightNumberFilter => {
  return {
    type: actionTypes.SET_FLIGHT_NUMBER_FILTER,
    flightNumberFilter,
  };
};

export interface ISetAwaitingRefetch {
  type: actionTypes.SET_AWAITING_REFETCH;
  awaitingRefetch: boolean;
}

export const setAwaitingRefetch = (
  awaitingRefetch: boolean
): ISetAwaitingRefetch => ({
  type: actionTypes.SET_AWAITING_REFETCH,
  awaitingRefetch,
});

export interface ISetMobileSearchProgress {
  type: actionTypes.SET_MOBILE_SEARCH_PROGRESS;
  step: MobileFlightSearchStep;
}

export const setMobileSearchProgress = (
  step: MobileFlightSearchStep
): ISetMobileSearchProgress => ({
  type: actionTypes.SET_MOBILE_SEARCH_PROGRESS,
  step,
});

export type FlightSearchActions =
  | IResetFareclassOptionFilter
  | ISetTripCategory
  | ISetRenderSearchInput
  | ISetOrigin
  | ISetOriginCategories
  | IFetchOriginCategories
  | ISetDestination
  | ISetDestinationCategories
  | IFetchDestinationCategories
  | ISetDepartureDate
  | ISetReturnDate
  | IFetchDepartureCalendar
  | ISetDepartureCalendar
  | ISetStopsOption
  | ISetMaxPriceFilter
  | ISetFareclassOptionFilter
  | ISetOutboundDepartureTimeRange
  | ISetOutboundArrivalTimeRange
  | ISetReturnDepartureTimeRange
  | ISetReturnArrivalTimeRange
  | ISetAirlineFilter
  | ISetAirportFilter
  | ISetFlightNumberFilter
  | ISetPassengerCounts
  | ISetAwaitingRefetch
  | ISetMobileSearchProgress
  | ISetOpenDatesModal
  | ISetCalendarVisited
  | ISetOpenActionModal
  | IResetFilters;

export const generateTrackingEvent = (
  type: "price" | "stops" | "airline" | "time" | "airport" | "flight number"
): { eventName: string; properties: AppliedFilterProperties } => {
  return {
    eventName: APPLIED_FILTER,
    properties: { filter_type: type },
  };
};
