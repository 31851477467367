import clsx from "clsx";
import { TravelItineraryEnum } from "@b2bportal/air-booking-api";
import { ItinerarySummary } from "@hopper-b2b/ui";
import styles from "./styles.module.scss";

export const ItinerarySummaryRow = ({
  flight,
  locator,
  airlines,
  airports,
  getStatusTag,
}) => {
  const { travelItinerary: tIt } = flight.bookedItinerary;

  if (
    tIt.TravelItinerary === TravelItineraryEnum.MultiTravelItinerary &&
    locator
  ) {
    const itinerary = tIt.travelItineraries.find(
      (it) => it.locators.b2b === locator
    );

    if (itinerary) {
      return (
        <div className={clsx(styles.itineraryDataRow, "itinerary-data-row")}>
          {itinerary.slices.map((slice, idx) => (
            <ItinerarySummary
              hideMixedCabinTooltip
              airlineMap={airlines}
              airportMap={airports}
              className={idx === 0 ? "outgoing-itinerary" : "return-itinerary"}
              isOutgoing={idx === 0}
              key={`slice-${idx}`}
              slice={slice}
              statusTag={getStatusTag(idx === 0)}
            />
          ))}
        </div>
      );
    }

    return null;
  }

  return (
    <div className={clsx(styles.itineraryDataRow, "itinerary-data-row")}>
      <ItinerarySummary
        hideMixedCabinTooltip
        isOutgoing
        airlineMap={airlines}
        airportMap={airports}
        className="outgoing-itinerary"
        flight={flight}
        statusTag={getStatusTag(true)}
      />
      <ItinerarySummary
        hideMixedCabinTooltip
        airlineMap={airlines}
        airportMap={airports}
        className="return-itinerary"
        flight={flight}
        statusTag={getStatusTag(false)}
      />
    </div>
  );
};
