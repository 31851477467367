import { StepCrumb, TravelWalletDropdown } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { TripCategory } from "@hopper-b2b/types";
import {
  useEnableCfar,
  useEnableDisruptionProtection,
} from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { getTripCategory } from "../../../search/reducer";
import { useGoBackToFlightShop, useUpdateFlightShopStep } from "../../hooks";
import { FlightShopStep, flightShopProgressSelector } from "../../reducer";
import "./DesktopFlightStepBar.styles.scss";
import { getAirDisruptionOffers } from "../../../disruption/reducer";
import { getCfarOffers } from "../../../cfar/reducer";

export enum DesktopFlightBarStep {
  ChooseDeparture,
  ChooseReturn,
  Review,
  Protection,
  Payment,
}

export interface DesktopFlightBarProps {
  isReviewItineraryHidden?: boolean;
  isPayment?: boolean;
  openProfilePanel?: boolean;
  setOpenProfilePanel?: React.Dispatch<React.SetStateAction<boolean>>;
}

const flightShopStepToBarStep = (flightShopStep: FlightShopStep) => {
  switch (flightShopStep) {
    case FlightShopStep.ChooseDeparture:
      return DesktopFlightBarStep.ChooseDeparture;
    case FlightShopStep.ChooseReturn:
      return DesktopFlightBarStep.ChooseReturn;
    case FlightShopStep.ReviewItinerary:
      return DesktopFlightBarStep.Review;
    case FlightShopStep.FintechSelection:
      return DesktopFlightBarStep.Protection;
    default:
      console.error("unexpected desktop flight shop step: ", flightShopStep);
      return DesktopFlightBarStep.ChooseDeparture;
  }
};

const barStepToFlightStep = (step: DesktopFlightBarStep) => {
  switch (step) {
    case DesktopFlightBarStep.ChooseDeparture:
      return FlightShopStep.ChooseDeparture;
    case DesktopFlightBarStep.ChooseReturn:
      return FlightShopStep.ChooseReturn;
    case DesktopFlightBarStep.Review:
      return FlightShopStep.ReviewItinerary;
    case DesktopFlightBarStep.Protection:
      return FlightShopStep.FintechSelection;
    // payment step isn't clickable as it is last step
    default:
      console.error("unexpected desktop bar step: ", step.toString());
      return FlightShopStep.ChooseDeparture;
  }
};

export const DesktopFlightStepBar = ({
  isReviewItineraryHidden,
  isPayment,
  openProfilePanel,
  setOpenProfilePanel,
}: DesktopFlightBarProps) => {
  const { t } = useI18nContext();

  const tripCategory = useSelector(getTripCategory);
  const isOneWay = tripCategory === TripCategory.ONE_WAY;

  const flightShopStep = useSelector(flightShopProgressSelector);
  const updateFlightShopStep = useUpdateFlightShopStep();
  const goBackToFlightShop = useGoBackToFlightShop();

  const showFlightCfar = useEnableCfar();
  const showDisruption = useEnableDisruptionProtection();
  const disruptionOffers = useSelector(getAirDisruptionOffers);
  const cfarOffers = useSelector(getCfarOffers);

  const barSteps: DesktopFlightBarStep[] = useMemo(() => {
    let hasFintech = false;
    if (flightShopStep > FlightShopStep.ChooseReturn) {
      hasFintech =
        (showFlightCfar && !!cfarOffers?.length) ||
        (showDisruption && !!disruptionOffers?.length);
    } else {
      hasFintech = showFlightCfar || showDisruption;
    }

    const steps = [DesktopFlightBarStep.ChooseDeparture];
    if (!isOneWay) {
      steps.push(DesktopFlightBarStep.ChooseReturn);
    }
    if (!isReviewItineraryHidden) {
      steps.push(DesktopFlightBarStep.Review);
    }

    if (hasFintech) {
      steps.push(DesktopFlightBarStep.Protection);
    }

    steps.push(DesktopFlightBarStep.Payment);

    return steps;
  }, [
    cfarOffers,
    disruptionOffers,
    flightShopStep,
    isOneWay,
    isReviewItineraryHidden,
    showDisruption,
    showFlightCfar,
  ]);

  const stepName = useCallback(
    (step: DesktopFlightBarStep) => {
      switch (step) {
        case DesktopFlightBarStep.ChooseDeparture: {
          return t("commBank.flightShopProgress.departure");
        }
        case DesktopFlightBarStep.ChooseReturn: {
          return t("commBank.flightShopProgress.return");
        }
        case DesktopFlightBarStep.Review: {
          return t("commBank.flightShopProgress.review");
        }
        case DesktopFlightBarStep.Protection: {
          return t("commBank.flightShopProgress.protection");
        }
        case DesktopFlightBarStep.Payment: {
          return t("commBank.flightShopProgress.payment");
        }
      }
    },
    [t]
  );

  const currentBarStep = useMemo(() => {
    if (isPayment) {
      return DesktopFlightBarStep.Payment;
    } else {
      return flightShopStepToBarStep(flightShopStep);
    }
  }, [flightShopStep, isPayment]);

  const getStatus = (barStep: DesktopFlightBarStep) => {
    return barStep < currentBarStep
      ? "completed"
      : barStep > currentBarStep
      ? "pending"
      : "selected";
  };

  const navigateToStep = (targetStep: DesktopFlightBarStep) => {
    if (getStatus(targetStep) !== "completed") {
      // only go back to previous step
      return;
    }

    const flightShopStep = barStepToFlightStep(targetStep);
    if (currentBarStep === DesktopFlightBarStep.Payment) {
      goBackToFlightShop(flightShopStep);
    } else if (targetStep < currentBarStep) {
      updateFlightShopStep(flightShopStep, true);
    }
  };

  return (
    <Box className="desktop-flight-shop-step-bar-container">
      <div className="steps-container">
        {barSteps?.map((step: DesktopFlightBarStep) => {
          return (
            <StepCrumb
              key={step}
              title={stepName(step)}
              status={getStatus(step)}
              onClick={() => navigateToStep(step)}
            />
          );
        })}
      </div>

      {!isPayment && (
        <TravelWalletDropdown
          openProfilePanel={openProfilePanel}
          setOpenProfilePanel={setOpenProfilePanel}
        />
      )}
    </Box>
  );
};
