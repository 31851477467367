import axios from "axios";
import {
  IApiConfig,
  NETWORK_CALL_FAILED,
  RewardsAccountsResponse,
} from "@hopper-b2b/types";

import { trackEvent } from "@hopper-b2b/api";
import { rewardsApiPrefix } from "../paths";

const END_POINT = `${rewardsApiPrefix}/accounts`;

export const fetchRewardsAccounts = (
  apiConfig?: IApiConfig
): Promise<RewardsAccountsResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(END_POINT, {});

      resolve(res.data);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });
