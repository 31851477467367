import { disruptionRebookShopPath } from "../paths";
import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import {
  DisruptionRebookShopRequest,
  DisruptionRebookShopResponse,
  NETWORK_CALL_FAILED,
} from "@hopper-b2b/types";

// TODO: Update types from any
const disruptionRebookShop = async (
  req: DisruptionRebookShopRequest
): Promise<DisruptionRebookShopResponse> => {
  try {
    const res = await axiosInstance.post<DisruptionRebookShopResponse>(
      disruptionRebookShopPath,
      req
    );
    const { data } = res;
    return data;
  } catch (e) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });
    throw e;
  }
};

export default disruptionRebookShop;
