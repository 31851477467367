import { IconComponent, IconName } from "@hopper-b2b/ui";
import dayjs from "dayjs";
import { ClientContext } from "../../../../../../App";
import { useContext } from "react";
import { TripSlice } from "@b2bportal/air-shopping-api";

export const LocationAndDate = ({
  origin,
  destination,
  tripSlice,
}: {
  origin: string;
  destination: string;
  tripSlice: TripSlice;
}) => {
  const clientContext = useContext(ClientContext);
  return (
    <div className="locations-and-dates-section">
      <button className="locations-section">
        {clientContext?.assets?.locationMarker ? (
          <img
            alt="Location Icon"
            className="location-section-icon"
            src={clientContext?.assets.locationMarker}
          />
        ) : (
          <IconComponent
            ariaLabel="Location Icon"
            name={IconName.B2BMapPin}
            className="location-section-icon"
          />
        )}
        <span className="locations-section-value">
          {`${origin} - ${destination}`}
        </span>
      </button>
      <button className="dates-section">
        {clientContext?.assets?.calendar ? (
          <img
            alt="Date Icon"
            className="dates-section-icon"
            src={clientContext?.assets.calendar}
          />
        ) : (
          <IconComponent
            ariaLabel="Date Icon"
            className="dates-section-icon"
            name={IconName.Calendar}
          />
        )}
        <span className="dates-section-value">
          {`${dayjs(tripSlice.departureTime).format("MMM DD")}`}
        </span>
      </button>
    </div>
  );
};
