import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Divider } from "@material-ui/core";
import GoogleMap from "google-maps-react-markers";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getAddressText } from "../../../../util/utils";
import { SECTION_ID } from "../../constants";
import {
  getSelectedLodging,
  getSelectedLodgingCoordinatesForStaticMap,
} from "../../reducer/selectors";
import { HotelDetailsSection } from "../HotelDetailsSection";
import { TextWithReadMore } from "../TextWtihReadMore";
import "./styles.scss";

const MAP_LINK_PREFIX = `https://www.google.com/maps/search/?api=1`;

export const AboutHotelSection = () => {
  const { t } = useI18nContext();
  const { matchesMobile: isMobile } = useDeviceTypes();

  const selectedLodging = useSelector(getSelectedLodging);

  const lodgingCoordinates = useSelector(
    getSelectedLodgingCoordinatesForStaticMap
  );
  const centroid = useMemo(() => {
    return {
      lat: selectedLodging.lodging.location.coordinates.lat,
      lng: selectedLodging.lodging.location.coordinates.lon,
    };
  }, [selectedLodging]);
  const mapRef = useRef(null);
  useEffect(() => {
    if (mapRef?.current && !isNaN(centroid.lat) && !isNaN(centroid.lng)) {
      mapRef?.current?.panTo(centroid);
    }
  }, [centroid]);
  const [defaultProps] = useState({
    center: centroid,
    zoom: 14,
  });

  const onMapClick = (
    coordinateString: string,
    address: string,
    name: string
  ) => {
    const url = `${MAP_LINK_PREFIX}&query=${coordinateString}&query_place_id=${encodeURI(
      address
    )}&query=${encodeURI(name)}`;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleAPILoaded = useCallback(({ map, maps }) => {
    mapRef.current = map;
  }, []);

  return (
    <HotelDetailsSection
      id={SECTION_ID.about}
      className="hotel-about-container"
      title={t("aboutProperty")}
    >
      {isMobile ? null : <Divider className="divider" />}
      <TextWithReadMore
        className="hotel-description"
        readMoreClassName="read-more-button"
        text={selectedLodging?.lodging?.description}
        modalTitle={t("aboutProperty")}
      />
      <div className="Shop-map">
        {lodgingCoordinates ? (
          <Box className="map-container">
            <GoogleMap
              apiKey={import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              options={{
                clickableIcons: false,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
              }}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={handleAPILoaded}
              errorContent={null}
            >
              <HotelMarker
                id={selectedLodging.lodging.id}
                lat={centroid.lat}
                lng={centroid.lng}
                onClick={() =>
                  onMapClick(
                    lodgingCoordinates,
                    getAddressText(selectedLodging?.lodging?.address),
                    selectedLodging?.lodging?.name
                  )
                }
              />
            </GoogleMap>
          </Box>
        ) : null}
      </div>
    </HotelDetailsSection>
  );
};

const HotelMarker = ({ id, lat, lng, onClick }) => {
  return (
    <FontAwesomeIcon
      key={id}
      markerId={id}
      lat={lat}
      lng={lng}
      className="hotel-map-marker"
      icon={faMapMarkerAlt as IconProp}
      onClick={onClick}
    />
  );
};
