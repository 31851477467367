import { useState } from "react";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import {
  ApacMobileFullScreenModal,
  ApacActionButton,
  ApacDesktopPopupModal,
  ApacTermsLink,
} from "@commbank/ui";

const Modal = ({ children, open, onClose }) => {
  const { matchesMobile: isMobile } = useDeviceTypes();
  return isMobile ? (
    <ApacMobileFullScreenModal open={open} onClose={onClose}>
      {children}
    </ApacMobileFullScreenModal>
  ) : (
    <ApacDesktopPopupModal open={open} onClose={onClose}>
      {children}
    </ApacDesktopPopupModal>
  );
};

export const PriceCreditsModalLink = ({ children, className = null }) => {
  const { t } = useI18nContext();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button className={className} onClick={() => setShowModal(true)}>
        {children}
      </button>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box className="offer-details-modal-content">
          <Box className="header-image" />
          <Typography variant="h2">
            {t("commBank.earnBanner.noOfferTitle")}
          </Typography>
          <Typography variant="body1">
            <Trans
              i18nKey={t("commBank.earnBanner.noOfferDescription")}
              components={[
                <ApacTermsLink
                  className="offer-details-terms-link"
                  scrollSelector="#best-price-guarantee"
                />,
              ]}
            />
          </Typography>
          <ApacActionButton
            onClick={() => {
              setShowModal(false);
            }}
            message={t("close.button")}
          />
        </Box>
      </Modal>
    </>
  );
};
