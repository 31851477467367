import { CategorizedResponse, Suggestion } from "@b2bportal/lodging-api";
import { fetchLodgingLocationAutocomplete } from "@hopper-b2b/api";
import { IApiConfig } from "@hopper-b2b/types";

export const fetchLocations = async (
  apiConfig: IApiConfig | undefined,
  search: string,
  callback: (newOptions: Suggestion[]) => void
): Promise<void> => {
  const response = await fetchLodgingLocationAutocomplete(search, apiConfig);

  const options: Suggestion[] = [];
  response?.forEach((categoryRes: CategorizedResponse) => {
    options.push(...(categoryRes.results as Suggestion[]));
  });

  callback(options);
};
