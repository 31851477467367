export interface ITrackingPropertiesRequest<T = object> {
  properties: T;
  encryptedProperties: Array<string>;
}

export interface ITrackingProperties<T = object> {
  properties?: T;
  encryptedProperties?: string;
}

//Combine multiple tracking property objects received from the backend and properties added from the frontend.
// ex. BE tracking property 1 + FE properties
export const combineTrackingProperties = (
  trackingProperties: ITrackingProperties[],
  properties: object = {}, //Tracking properties populated in the FE.
  encryptedProperties: Array<string> = []
): ITrackingPropertiesRequest => {
  return trackingProperties.reduce(
    (
      combined: ITrackingPropertiesRequest,
      current: ITrackingProperties = {}
    ) => {
      return {
        properties:
          typeof current?.properties === "object"
            ? {
                ...combined.properties,
                ...current.properties,
              }
            : combined.properties,
        encryptedProperties:
          typeof current?.encryptedProperties === "string"
            ? [...combined.encryptedProperties, current.encryptedProperties]
            : combined.encryptedProperties,
      } as ITrackingPropertiesRequest;
    },
    {
      properties,
      encryptedProperties,
    } as ITrackingPropertiesRequest
  );
};

// Update the tracking propertyRequest to add multiple tracking property objects received from the backend and properties added from the frontend.
// ex. BE tracking property 1 +  BE tracking property 1 + FE properties
export const updateTrackingPropertiesRequest = (
  trackingPropertiesRequest: ITrackingPropertiesRequest,
  trackingProperties: ITrackingProperties[] = [],
  properties: object = {} //Tracking properties populated in the FE.
): ITrackingPropertiesRequest => {
  return trackingProperties.reduce(
    (
      combined: ITrackingPropertiesRequest,
      current: ITrackingProperties = {}
    ) => {
      return {
        properties:
          typeof current?.properties === "object"
            ? {
                ...combined.properties,
                ...current.properties,
              }
            : combined.properties,
        encryptedProperties:
          typeof current?.encryptedProperties === "string"
            ? [...combined.encryptedProperties, current.encryptedProperties]
            : combined.encryptedProperties,
      } as ITrackingPropertiesRequest;
    },
    {
      properties: { ...trackingPropertiesRequest?.properties, ...properties },
      encryptedProperties: trackingPropertiesRequest?.encryptedProperties,
    } as ITrackingPropertiesRequest
  );
};
