import { MyTripsFilter, TRIPS_FILTER_QUERY_PARAM } from "@hopper-b2b/types";
import { putResolve, select } from "redux-saga/effects";

import { IStoreState } from "../../../reducers/types";
import { actions } from "../actions";
import {
  getExistingStateVariables,
  parseQueryString,
} from "./populateTripQueryParamsSaga";

export interface ITripsParsedQuery {
  contactSupport: string;
  tripsFilter: string;
  tripId: string;
}

const isQueryParamOfTypeMyTripsFilter = (queryParamsTripFilter: string) =>
  [
    MyTripsFilter.PAST_TRIPS,
    MyTripsFilter.PRIZE_FREEZES,
    MyTripsFilter.UPCOMING_TRIPS,
    MyTripsFilter.WATCHED_TRIPS,
  ].includes(queryParamsTripFilter as MyTripsFilter);

export function* setUpMyTripsParams(
  action:
    | actions.IFetchHotels
    | actions.IFetchFlights
    | actions.IFetchCars
    | actions.IListWatches
) {
  const state: IStoreState = yield select();
  const { history } = action;

  const { tripsFilter, tripId } = getExistingStateVariables(state);

  const {
    contactSupport,
    tripId: queryParamsTripId,
    tripsFilter: queryParamsFilter,
  } = parseQueryString(history);

  // Make it backwards compatible, so old links keep working.
  // Handle space and case sensitive links.
  const queryParamsTripFilter = queryParamsFilter
    ? queryParamsFilter.replaceAll(" ", "_").toLowerCase()
    : "";

  if (queryParamsTripFilter && queryParamsTripFilter !== tripsFilter) {
    if (isQueryParamOfTypeMyTripsFilter(queryParamsTripFilter)) {
      yield putResolve(
        actions.setTripsFilter(queryParamsTripFilter as MyTripsFilter)
      );
    } else {
      yield putResolve(actions.populateTripQueryParams(history));
    }
  }

  return {
    contactSupport,
    [TRIPS_FILTER_QUERY_PARAM]: queryParamsTripFilter || tripsFilter,
    tripId: queryParamsTripId || tripId,
  };
}
