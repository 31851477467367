import { formatDateTime, removeTimezone } from "@hopper-b2b/utilities";
import { Box, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import Skeleton from "react-loading-skeleton";
import { AirlineIcon } from "../AirlineIcon";
import { Icon, IconName } from "../Icon";
import { FlightAirplaneIcon } from "../FlightAirplaneIcon";

export interface IFlightIconCardBaseProps {
  className?: string;
  type: "content" | "skeleton";
}
export interface IFlightIconCardContentProps extends IFlightIconCardBaseProps {
  departureTime: string;
  arrivalTime: string;
  airlineCode: string;
  originCode: string;
  destinationCode: string;
  duration: string;
  airlineName: string;
  layoverString: string;
  className?: string;
  plusDays?: number;
  type: "content";
}

export interface IFlightIconCardSkeletonProps extends IFlightIconCardBaseProps {
  type: "skeleton";
}
export const FlightIconCardContent = ({
  departureTime,
  arrivalTime,
  airlineCode,
  airlineName,
  duration,
  layoverString,
  plusDays = 0,
  originCode,
  destinationCode,
  className,
}: IFlightIconCardContentProps) => {
  return (
    <Box className={className} textAlign="left" mt={3}>
      <Grid container spacing={4}>
        <Grid item>
          <AirlineIcon
            airlineCode={airlineCode}
            altText={airlineName}
            size="small"
          />
        </Grid>
        <Grid item xs>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item>
              <Typography
                variant="h4"
                gutterBottom
                aria-label={`Departure at ${formatDateTime(
                  removeTimezone(departureTime),
                  "h:mm A"
                )}`}
              >
                {formatDateTime(removeTimezone(departureTime), "h:mm A")}
              </Typography>
              <Typography variant="body1">{airlineName}</Typography>
              <Typography variant="body1" color="textSecondary">
                {originCode} - {destinationCode}
              </Typography>
            </Grid>
            <Grid item>
              <FlightAirplaneIcon />
            </Grid>
            <Grid item>
              <Typography
                variant="h4"
                gutterBottom
                aria-label={`Arrival at ${formatDateTime(
                  removeTimezone(arrivalTime),
                  "h:mm A"
                )}`}
              >
                {formatDateTime(removeTimezone(arrivalTime), "h:mm A")}
              </Typography>
              <Typography variant="body1" aria-label={`Duration ${duration}`}>
                {duration}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                aria-label={`Layover ${layoverString}  ${
                  plusDays > 0 ? "+" : "-"
                }${Math.abs(plusDays)}d`}
              >
                {layoverString}
                {plusDays !== 0 && (
                  <Typography variant="caption" color="error">
                    {`${plusDays > 0 ? "+" : "-"}${Math.abs(plusDays)}d`}
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export const FlightIconCardSkeleton = ({
  className,
}: IFlightIconCardSkeletonProps) => {
  return (
    <Box className={clsx("flight-icon-card", className)}>
      <Box>
        <AirlineIcon size="small" className="airline-icon" airlineCode={""} />
      </Box>
      <Box>
        <Box className="flight-time-info">
          <Skeleton className="departureTime" />
          <Box className="flight-time-icon">
            <Icon name={IconName.B2BAirplaneIcon} ariaLabel="" />
            <Box className="flight-line" />
            <Box className="flight-line-circle" />
          </Box>
          <Skeleton className="arrivalTime" />
        </Box>
        <Box className="fields">
          <Skeleton className="airline" />
          <Skeleton className="duration" />
          <Skeleton className="brand-name" />
          <Skeleton className="stop-summary" />
        </Box>
      </Box>
    </Box>
  );
};

export type IFlightIconCardProps =
  | IFlightIconCardContentProps
  | IFlightIconCardSkeletonProps;

export const FlightIconCard = (props: IFlightIconCardProps) => {
  switch (props.type) {
    case "content":
      return <FlightIconCardContent {...props} />;
    case "skeleton":
      return <FlightIconCardSkeleton {...props} />;
    default:
      return <></>;
  }
};
