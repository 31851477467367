import { useI18nContext } from "@hopper-b2b/i18n";
import {
  IconComponent,
  IconName,
  MobileFloatingButton,
  PredictionForecast,
  Slot,
} from "../../../index";
import { useUberBridge } from "@hopper-b2b/utilities";
import { Typography } from "@material-ui/core";
import { PropsWithChildren, useEffect, useMemo } from "react";
import "./styles.scss";
import {
  Dealness,
  PredictionCopy,
  PriceDropProtectionEnum,
  PricePrediction,
  Prices,
} from "@b2bportal/air-shopping-api";
import clsx from "clsx";

export interface PredictionDetails {
  predictionCopy?: PredictionCopy;
  dealness?: Dealness;
  lowestPrice?: Prices;
  pricePrediction?: PricePrediction;
  priceDropProtection: PriceDropProtectionEnum;
}

interface MobilePricePredictionProps extends PropsWithChildren {
  onClose: () => void;
  predictionDetails: PredictionDetails;
  className?: string;
}

export const MobilePricePrediction = ({
  onClose,
  predictionDetails,
  className,
  children,
}: MobilePricePredictionProps) => {
  const { setHeader } = useUberBridge();

  const { t, formatFiatCurrency } = useI18nContext();

  const getForecastedPrice = () => {
    if (predictionDetails.dealness) {
      const key =
        predictionDetails.dealness === Dealness.wait ? "minPrice" : "maxPrice";
      const displayText = predictionDetails.pricePrediction[key]?.display || "";
      if (displayText) {
        return (
          <Typography className="content" variant="h4">
            {displayText}
          </Typography>
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  useEffect(() => {
    setHeader({
      title: t("pricePrediction.header"),
    });
  }, []);

  const pricePredictionCopy = useMemo(
    () => ({
      title:
        predictionDetails?.dealness === Dealness.fair
          ? t("pricePredictionCard.fairTitle")
          : predictionDetails?.predictionCopy?.recommendationTitle
              .join(" ")
              .replace("Hopper", "Uber Travel") ?? "",
      detail:
        predictionDetails?.dealness === Dealness.fair
          ? t("pricePredictionCard.fairDetail")
          : predictionDetails?.predictionCopy?.recommendationBody
              .join(" ")
              .replace("Hopper", "Uber Travel") ?? "",
    }),
    [
      predictionDetails?.dealness,
      predictionDetails?.predictionCopy?.recommendationBody,
      predictionDetails?.predictionCopy?.recommendationTitle,
      t,
    ]
  );

  return (
    <div className={clsx("uber mobile-price-prediction-full", className)}>
      {predictionDetails?.predictionCopy ? (
        <div className="recommendation-container">
          <h6
            className="recommendation-title"
            dangerouslySetInnerHTML={{
              __html: pricePredictionCopy.title,
            }}
          />
          <p
            className="recommendation-body"
            dangerouslySetInnerHTML={{
              __html: pricePredictionCopy.detail,
            }}
          />
        </div>
      ) : null}

      <Slot
        id="flight-price-drop-protection-banner"
        isEligible={
          predictionDetails.priceDropProtection ===
          PriceDropProtectionEnum.IsEligible
        }
        showMore
      />

      {predictionDetails?.lowestPrice && (
        <div className="lowest-price-container">
          <IconComponent className={"subtract-icon"} name={IconName.Subtract} />
          <div className="lowest-price-description">
            <p className="lowest-price">
              {formatFiatCurrency(predictionDetails?.lowestPrice.fiat, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </p>
            <p className="lowest-price-label">
              {t("pricePrediction.currentPrice")}
            </p>
          </div>
        </div>
      )}

      {predictionDetails && !!getForecastedPrice() && (
        <div className="forecast-container">
          <IconComponent
            className={
              predictionDetails.dealness === Dealness.wait
                ? "forecasted-lowest-icon"
                : "forecasted-highest-icon"
            }
            name={
              predictionDetails.dealness === Dealness.wait
                ? IconName.DecreaseReviewed
                : IconName.IncreasedReviewed
            }
          />
          <div className="forecast-description">
            <p className="forecast-price">{getForecastedPrice()}</p>
            <p className="forecast-label">
              {predictionDetails.dealness === Dealness.wait
                ? t("pricePrediction.forecastedLowest")
                : t("pricePrediction.forecastedHighest")}
            </p>
          </div>
        </div>
      )}
      {predictionDetails?.predictionCopy?.intervals && (
        <div className="prediction-forecast-section">
          <PredictionForecast
            className="b2b"
            intervals={predictionDetails.predictionCopy.intervals}
          />
        </div>
      )}

      <div className={"price-prediction-action-button-container"}>
        {children}
        <MobileFloatingButton
          wrapperClassName="price-prediction-action-button"
          onClick={onClose}
          wide
        >
          {t("gotIt")}
        </MobileFloatingButton>
      </div>
    </div>
  );
};
