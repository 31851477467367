import { put } from "redux-saga/effects";
import { RewardsAccount } from "@hopper-b2b/types";

import { actions } from "../../actions";
import { fetchRewardsAccounts } from "../../../../api/v1/rewards/fetchRewardsAccounts";

export function* fetchRewardsAccountsSaga(_: actions.IFetchRewardsAccounts) {
  try {
    const rewardsAccounts: RewardsAccount[] = yield fetchRewardsAccounts();

    yield put(actions.setRewardsAccounts(rewardsAccounts));
  } catch (e) {
    yield put(actions.fetchRewardsAccountsCallStateFailed());
  }
}
