import { call, delay, put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import {
  airCfarApi,
  PollFulfillAirCfarExerciseQuoteRequest,
  PollFulfillAirCfarExerciseQuoteResponse,
  PollFulfillAirCfarExerciseQuoteResponseEnum,
  PollFulfillAirCfarResponseFailureUnknown,
  ScheduleFulfillAirCfarExerciseQuoteRequest,
  ScheduleFulfillAirCfarExerciseQuoteResponse,
  ScheduleFulfillAirCfarExerciseQuoteResponseEnum,
  ScheduleFulfillAirCfarExerciseQuoteResponseFailure,
  ScheduleFulfillAirCfarExerciseQuoteResponseSuccess,
} from "@b2bportal/air-cfar-api";
import axios, { AxiosResponse } from "axios";

import { CfarExerciseProgress, CfarTrackingEvents } from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";
import { getCfarExerciseTrackingProperties } from "../../reducer";
import {
  combineTrackingProperties,
  ITrackingProperties,
} from "@hopper-b2b/utilities";

const DELAY_TIMES = [2000, 4000, 6000, 8000, 10000, 20000, 30000, 30000, 30000];

export function* confirmFlightCfarCancellationSaga(
  _action: actions.IConfirmFlightCfarCancellation
) {
  try {
    const quoteToken = _action.quoteToken;
    const request: ScheduleFulfillAirCfarExerciseQuoteRequest = {
      quoteToken,
    };

    const {
      data: response,
    }: AxiosResponse<ScheduleFulfillAirCfarExerciseQuoteResponse> =
      yield airCfarApi(axios).apiV0CfarAirExerciseFulfillSchedulePost(request);

    if (
      (response as ScheduleFulfillAirCfarExerciseQuoteResponseFailure)
        ?.ScheduleFulfillAirCfarExerciseQuoteResponse ===
      ScheduleFulfillAirCfarExerciseQuoteResponseEnum.Failure
    ) {
      yield put(actions.setConfirmFlightCfarCancellationCallStateFailed());
      return;
    }

    const fulfillmentSessionId = (
      response as ScheduleFulfillAirCfarExerciseQuoteResponseSuccess
    ).fulfillmentSessionId;

    yield put(
      actions.setConfirmFlightCfarCancellationResponse({
        response,
      })
    );

    yield call(pollFulfillFlightCfarCancellation, {
      sessionToken: fulfillmentSessionId,
      quoteToken,
    });
  } catch (e) {
    yield put(actions.setConfirmFlightCfarCancellationCallStateFailed());
  }
}

export function* pollFulfillFlightCfarCancellation(
  request: PollFulfillAirCfarExerciseQuoteRequest
) {
  try {
    let pollFailed = false;
    let index = 0;

    const trackingProps = yield select(
      getCfarExerciseTrackingProperties
    ) as ITrackingProperties;
    const cfarTrackingProps = combineTrackingProperties([trackingProps]);

    while (!pollFailed) {
      yield delay(DELAY_TIMES[index]);
      const {
        data: pollResponse,
      }: AxiosResponse<PollFulfillAirCfarExerciseQuoteResponse> =
        yield airCfarApi(axios).apiV0CfarAirExerciseFulfillPollPost(request);

      switch (pollResponse.PollFulfillAirCfarExerciseQuoteResponse) {
        case PollFulfillAirCfarExerciseQuoteResponseEnum.Failure:
          pollFailed = true;
          yield put(
            actions.setPollFulfillFlightCfarCancellationResponse({
              response: pollResponse,
            })
          );
          yield trackEvent({
            eventName: CfarTrackingEvents.VIEWED_CFAR_EXERCISE_ERROR,
            properties: {},
          });
          yield put(
            actions.setCfarExerciseProgress(CfarExerciseProgress.NotStarted)
          );
          return;
        case PollFulfillAirCfarExerciseQuoteResponseEnum.Pending:
          pollFailed = false;
          yield put(
            actions.setPollFulfillFlightCfarCancellationResponse({
              response: pollResponse,
            })
          );
          break;
        case PollFulfillAirCfarExerciseQuoteResponseEnum.Success:
          yield put(
            actions.setPollFulfillFlightCfarCancellationResponse({
              response: pollResponse,
            })
          );
          yield trackEvent({
            eventName: CfarTrackingEvents.CFAR_POLICY_EXERCISED,
            ...cfarTrackingProps,
          });
          yield put(
            actions.setCfarExerciseProgress(CfarExerciseProgress.Completed)
          );
          return;
        default:
          pollFailed = true;
          throw new Error("Cancellation fulfillment failed");
      }
      if (index !== DELAY_TIMES.length - 1) {
        index++;
      }
    }
  } catch (e) {
    yield put(
      actions.setPollFulfillFlightCfarCancellationResponse({
        response: {
          error: e,
          Failure: "Unknown",
          PollFulfillAirCfarExerciseQuoteResponse: "Failure",
        } as PollFulfillAirCfarResponseFailureUnknown,
      })
    );
  }
}
