import {
  AirExchangeEligibilityQuoteResponse,
  AirExchangeEligibilityQuoteResponseEnum,
  AirExchangeEligibilityQuoteSuccess,
} from "@b2bportal/air-exchange-api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { getFlightExchangePolicyPath } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

const fetchFlightExchangePolicy = async (
  reservationId: string
): Promise<AirExchangeEligibilityQuoteSuccess | undefined> => {
  try {
    const res = await axiosInstance.post<AirExchangeEligibilityQuoteResponse>(
      getFlightExchangePolicyPath,
      { reservationId }
    );

    if (
      res.data.AirExchangeEligibilityQuoteResponse ===
      AirExchangeEligibilityQuoteResponseEnum.AirExchangeEligibilityQuoteSuccess
    ) {
      return res.data;
    }
  } catch (err) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });

    throw err;
  }
};

export default fetchFlightExchangePolicy;
