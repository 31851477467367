import { useEffect, useCallback } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { LocationSelection } from "../LocationSelection";
import { TripCategory } from "@hopper-b2b/types";
import clsx from "clsx";
import { InputButton, IconName, IconComponent } from "@hopper-b2b/ui";
import { IPassengerCounts } from "@hopper-b2b/types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useHistory } from "react-router";
import { LocationAndPassengerSearchConnectorProps } from "./container";
import "./styles.scss";
import { ApacIconName, ApacIcon, PassengerCountPicker } from "@commbank/ui";
import {
  APAC_MAX_PASSENGER_COUNT,
  APAC_MAX_CHILDREN_COUNT,
} from "../../../DesktopFlightSearchControl/DesktopFlightSearchControl";

export interface ILocationAndPassengerSelectionProps
  extends LocationAndPassengerSearchConnectorProps {
  onComplete?: () => void;
}

export const LocationAndPassengerSelection = (
  props: ILocationAndPassengerSelectionProps
) => {
  const {
    origin,
    destination,
    onComplete,
    readyToContinue,
    populateSearchUrlParams,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    setPassengerCounts: setCounts,
    tripCategory,
    renderSearchInput,
    setRenderSearchInput,
  } = props;

  const { t } = useI18nContext();

  const history = useHistory();

  const counts = {
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
  };

  const onSearch = useCallback(() => {
    if (
      onComplete &&
      origin &&
      destination &&
      origin.id.code.code !== destination.id.code.code
    ) {
      populateSearchUrlParams({ passengers: counts }, history);
      onComplete();
    }
  }, [
    destination,
    onComplete,
    origin,
    populateSearchUrlParams,
    history,
    counts,
  ]);

  const updateTripCategory = (value: TripCategory) => {
    populateSearchUrlParams({ tripCategory: value }, history);
  };

  // Dont make a callback, should only run once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const populateParams = () => {
    if (origin && destination) {
      populateSearchUrlParams(
        {
          origin,
          destination,
          tripCategory,
          passengers: counts,
        },
        history
      );
    }
  };

  useEffect(() => {
    // Ensures URL params are set to current state on redirect to landing page
    // - only important for page redirect not first page load, as tracks existing state
    populateParams();
  }, []);

  return renderSearchInput ? (
    <LocationSelection
      isOrigin={renderSearchInput === "origin"}
      onComplete={() => setRenderSearchInput(null)}
    />
  ) : (
    <div className="mobile-flight-search-selection-container">
      <Box className="mobile-flight-search-selection">
        <Box className="trip-category-container">
          <ApacIcon name={ApacIconName.CBAPlane} />
          <Button
            variant="contained"
            className={clsx("trip-category-button", "round-trip", {
              selected: tripCategory === TripCategory.ROUND_TRIP,
            })}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                updateTripCategory(TripCategory.ROUND_TRIP);
              }
            }}
            onClick={() => updateTripCategory(TripCategory.ROUND_TRIP)}
          >
            {t("roundTrip")}
          </Button>
          <Button
            variant="contained"
            className={clsx("trip-category-button", "round-trip", {
              selected: tripCategory === TripCategory.ONE_WAY,
            })}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                updateTripCategory(TripCategory.ONE_WAY);
              }
            }}
            onClick={() => updateTripCategory(TripCategory.ONE_WAY)}
          >
            {t("oneWay")}
          </Button>
        </Box>
        <Box className="mobile-location-pickers">
          <Typography variant="body1" className="mobile-location-subtitle">
            {t("commBank.search.flights.locationSubtitle")}
          </Typography>
          <InputButton
            className={clsx("origin-auto-complete", "mobile")}
            prefixString={origin ? origin.label : t("searchControl.whereFrom")}
            customEndIcon={
              <IconComponent
                name={IconName.MagnifyingGlass}
                className={clsx("origin-icon")}
              />
            }
            onClick={() => setRenderSearchInput("origin")}
            label={origin ? t("searchControl.whereFrom") : ""}
          />
          <InputButton
            className={clsx("destination-auto-complete", "mobile")}
            prefixString={
              destination ? destination.label : t("searchControl.whereTo")
            }
            customEndIcon={
              <IconComponent
                name={IconName.MagnifyingGlass}
                className={clsx("destination-icon")}
              />
            }
            onClick={() => setRenderSearchInput("destination")}
            label={destination ? t("searchControl.whereTo") : ""}
          />
          <PassengerCountPicker
            maximumCount={APAC_MAX_PASSENGER_COUNT}
            minimumCount={1}
            maximumChildren={APAC_MAX_CHILDREN_COUNT}
            onUpdateCounts={(v) => {
              setCounts(v as IPassengerCounts);
            }}
            counts={counts}
            titles={{
              modalSubtitle: t("flightsPageTitles.travellersWarning", {
                maxTravellers: APAC_MAX_PASSENGER_COUNT,
              }),
              adultTitle: t("passengerCount.adultTitle"),
              childrenTitle: t("passengersCounterTitles.childrenTitle"),
              childrenSubtitle: t("passengersCounterTitles.childrenSubtitle"),
              infantLapTitle: t("passengersCounterTitles.infantLapTitle"),
              infantLapSubtitle: t("passengersCounterTitles.infantLapSubtitle"),
              infantSeatTitle: t("passengersCounterTitles.infantSeatTitle"),
              infantSeatSubtitle: t(
                "passengersCounterTitles.infantSeatSubtitle"
              ),

              maxPassengerCount: t("passengerCount.errors.maxPassengerCount", {
                maxTravellers: APAC_MAX_PASSENGER_COUNT,
              }),
              maxChildrenCount: t("passengerCount.errors.maxChildrenCount", {
                maximumChildren: APAC_MAX_CHILDREN_COUNT,
              }),
              minAdultPerInfant: t("passengerCount.errors.minAdultPerInfant"),
            }}
            isFlight
          />
        </Box>
      </Box>
      <div className="continue-button-container">
        <button
          className="mobile-autocomplete-continue-button"
          onClick={onSearch}
          disabled={!readyToContinue}
        >
          <Typography>{t("flightsPageTitles.selectDate")}</Typography>
        </button>
      </div>
    </div>
  );
};
