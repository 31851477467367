import { Typography } from "@material-ui/core";
import {
  ApacActionButton,
  ApacTermsLink,
  ApacIcon,
  ApacIconName,
} from "@commbank/ui";
import { Prediction } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import { getPriceDropMessageParams } from "../../MobilePricePrediction/MobilePricePredictionSection";
import "./PriceDropProtectionContent.styles.scss";

interface IPriceDropProtectionContentTitleProps {
  icon: ApacIconName;
  title: string;
}

export const DesktopModalHeader = ({
  icon,
  title,
}: IPriceDropProtectionContentTitleProps) => {
  return (
    <div className="modal-title-container">
      <ApacIcon className="title-icon" name={icon} />
      <Typography variant="h1" className="modal-title-text">
        {title}
      </Typography>
    </div>
  );
};

interface IPriceDropProtectionContentProps {
  onClose: () => void;
  prediction: Prediction;
}

export const PriceDropProtectionContent = ({
  onClose,
  prediction,
}: IPriceDropProtectionContentProps) => {
  const { t, formatCurrency } = useI18nContext();

  const renderDesktopTermsAndCondition = () => {
    return (
      <ApacTermsLink scrollSelector="#price-drop" showUnderline={false}>
        {t("termsAndConditions")}
      </ApacTermsLink>
    );
  };

  return (
    <div className={clsx("price-drop-protection-content-container")}>
      <DesktopModalHeader
        icon={ApacIconName.YellowGuard}
        title={t("airPriceDrop")}
      />
      <Typography variant="h3">{t("priceWatch.titles.title")}</Typography>
      <Typography variant="body2" className="content-text">
        {t(
          "pricePrediction.priceDropDescription",
          getPriceDropMessageParams(prediction, formatCurrency)
        )}
      </Typography>

      <div className="footer">
        {renderDesktopTermsAndCondition()}
        <ApacActionButton
          className="close-button"
          onClick={onClose}
          message={t("close.button")}
        />
      </div>
    </div>
  );
};
