import axios from "axios";
import { trackEvent } from "@hopper-b2b/api";
import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import {
  priceFreezeApi,
  PriceFreezeOfferDataRequest,
  PriceFreezeOfferDataResponse,
} from "@b2bportal/price-freeze-api";

export const fetchSelectedTripPriceFreezeOffer = (
  body: PriceFreezeOfferDataRequest,
  apiConfig?: IApiConfig
): Promise<PriceFreezeOfferDataResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await priceFreezeApi(
        axios
      ).apiV0PricefreezeOfferCustomDataPost(body);
      resolve(res.data);
    } catch (error) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(error);
    }
  });
};
