import { useEnableRetainFilters } from "@hopper-b2b/utilities";
import { Suggestion } from "@b2bportal/lodging-api";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom-v5-compat";
import { PriceRange, SortOption } from "../reducer";
import { IUpdateFilters } from "../actions/actions";
import { ProductType, useTrackEvents } from "../../../util/trackEvent";
import { LodgingShopTrackingEvents } from "@hopper-b2b/types";

interface UseLodgingFiltersParams {
  context: {
    afterFirstSearch: boolean;
    place: Suggestion;
    filteredOutCount: number;
  };
  handlers: {
    resetFilters: () => void;
    updateFiltersAndSort: (filters: IUpdateFilters, sort?: SortOption) => void;
  };
}

interface UseLodingFiltersResponse {
  filterContext: {
    showFilterSnackbar: boolean;
    showFiltersModal: boolean;
  };
  filterHandlers: {
    setSort: (sort: SortOption) => void;
    closeFiltersAlert: () => void;
    openFiltersModal: () => void;
    closeFiltersModal: () => void;
    onFreeCancellationChange: (freeCancellation: boolean) => void;
    onStarRatingChange: (starRating: number[]) => void;
    onUserRatingChange: (userRating: number) => void;
    onFilterPriceRangeChange: (
      priceRange: Pick<PriceRange, "min" | "max">
    ) => void;
  };
}

export const useLodgingFilters = ({
  context: { afterFirstSearch, place, filteredOutCount },
  handlers: { resetFilters, updateFiltersAndSort },
}: UseLodgingFiltersParams): UseLodingFiltersResponse => {
  const params = useParams();
  const trackEvent = useTrackEvents();

  const retainHotelFiltersFlag = useEnableRetainFilters();
  const shouldShowFiltersInitially = useMemo(
    () => filteredOutCount > 0 && retainHotelFiltersFlag,
    [filteredOutCount, retainHotelFiltersFlag]
  );

  const [showFilterSnackbar, setFilterSnackbar] = useState<boolean>(
    shouldShowFiltersInitially
  );
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);

  const closeFiltersAlert = useCallback(
    () => setFilterSnackbar(false),
    [setFilterSnackbar]
  );

  const openFiltersModal = useCallback(() => {
    setShowFiltersModal(true);
    trackEvent(
      LodgingShopTrackingEvents.hotel_tapped_filter,
      ProductType.Hotel,
      {}
    );
  }, [trackEvent]);

  const closeFiltersModal = useCallback(() => {
    setShowFiltersModal(false);
  }, []);

  const onFreeCancellationChange = useCallback(
    (freeCancellation: boolean) => updateFiltersAndSort({ freeCancellation }),
    [updateFiltersAndSort]
  );

  const onStarRatingChange = useCallback(
    (starRating: number[]) => updateFiltersAndSort({ starRating }),
    [updateFiltersAndSort]
  );

  const onUserRatingChange = useCallback(
    (userRating: number) => updateFiltersAndSort({ userRating }),
    [updateFiltersAndSort]
  );

  const onFilterPriceRangeChange = useCallback(
    (priceRange: Pick<PriceRange, "min" | "max">) =>
      updateFiltersAndSort({ priceRange }),
    [updateFiltersAndSort]
  );

  const setSort = useCallback(
    (sort: SortOption) => updateFiltersAndSort({}, sort),
    [updateFiltersAndSort]
  );

  /**
   * Logic to reset filters, depends on RETAIN_FEATURES flag in useEffects
   * When enabled, we only reset on location change
   * When disabled (default), we reset on all changes e.g dates, pax count etc
   */
  useEffect(() => {
    setFilterSnackbar(shouldShowFiltersInitially);
  }, [shouldShowFiltersInitially]);

  useEffect(() => {
    const isNewPlaceSearch =
      place &&
      place?.label !== params.location &&
      params.location !== "location";

    if (afterFirstSearch && (!retainHotelFiltersFlag || isNewPlaceSearch)) {
      resetFilters();
    }
  }, [afterFirstSearch, params, place, resetFilters, retainHotelFiltersFlag]);

  return {
    filterContext: {
      showFiltersModal,
      showFilterSnackbar,
    },
    filterHandlers: {
      setSort,
      openFiltersModal,
      closeFiltersModal,
      closeFiltersAlert,
      onFreeCancellationChange,
      onStarRatingChange,
      onUserRatingChange,
      onFilterPriceRangeChange,
    },
  };
};
