import "./styles.scss";
import clsx from "clsx";

export interface IApacRadioButtonProps {
  checked: boolean;
  onChange?: () => void;
  className?: string;
  disabled?: boolean;
}

export const ApacRadio = ({
  className,
  onChange,
  disabled = false,
  checked,
}: IApacRadioButtonProps) => {
  return (
    <input
      className={clsx("apac-radio", className)}
      type="radio"
      checked={checked}
      onChange={onChange}
      readOnly={!onChange}
      disabled={disabled}
    />
  );
};
