import { put, select } from "redux-saga/effects";
import { RewardsAccount } from "@hopper-b2b/types";

import { actions } from "../../actions";
import { fetchRewardsAccounts } from "../../../../api/v1/rewards/fetchRewardsAccounts";
import { flightApiConfigStoreKey } from "../../../../reducers/types";
import { IStoreState } from "../../../../reducers/types";

export function* fetchRewardsAccountsSaga(_: actions.IFetchRewardsAccounts) {
  try {
    const state: IStoreState = yield select();
    const apiConfig = state[flightApiConfigStoreKey];
    const rewardsAccounts: RewardsAccount[] = yield fetchRewardsAccounts(
      apiConfig
    );

    yield put(actions.setRewardsAccounts(rewardsAccounts));
  } catch (e) {
    yield put(actions.fetchRewardsAccountsCallStateFailed());
  }
}
