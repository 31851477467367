import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../reducers/types";
import { PriceFreezePurchase } from "./component";
import {
  getDefaultPriceFreezeOffer,
  selectedTripSelector,
  tripDetailsSelector,
  airportsSelector,
} from "../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  const tripId = selectedTripSelector(state).tripId || "";
  return {
    selectedTrip: selectedTripSelector(state),
    tripDetails: tripDetailsSelector(state, tripId),
    defaultPriceFreezeOffer: getDefaultPriceFreezeOffer(state),
    airports: airportsSelector(state, tripId),
  };
};

const connector = connect(mapStateToProps);

export type PriceFreezePurchaseConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezePurchase = connector(PriceFreezePurchase);
