import { assign, DoneInvokeEvent } from "xstate";
import { CreditCard } from "@b2bportal/card-api";
import { setContextWithKey } from "../../../helpers";
import { getObjectKeysAsObject } from "../../../helpers";
import {
  ICardPayment,
  ParentContextWithCardPayment,
  PaymentError,
} from "./types";
import {
  SetSelectedPaymentMethodIdEvent,
  VerifyPaymentMethodEvent,
} from "./events";
import { DeletePaymentMethodResponse } from "./services/deletePaymentMethod";
import { SuccessfulVerifyPaymentResponse } from "./services/verifyPayment";
import { Payment } from "@b2bportal/purchase-api";
import { ParentState } from "../../../";

export const actions = {
  setPaymentMethods: assign(
    (ctx: ParentContextWithCardPayment, event: DoneInvokeEvent<CreditCard[]>) =>
      setContextWithKey(
        ctx,
        `${ParentState.cardPayment}.paymentMethods`,
        event.data
      )
  ),

  handleListPayments: assign(
    (
      ctx: ParentContextWithCardPayment,
      event: DoneInvokeEvent<CreditCard[]>
    ) => {
      return setContextWithKey(
        ctx,
        `${ParentState.cardPayment}.paymentMethods`,
        event.data
      );
    }
  ),

  setPaymentMethodId: assign(
    (
      ctx: ParentContextWithCardPayment,
      event: DoneInvokeEvent<SuccessfulVerifyPaymentResponse>
    ) => {
      const { paymentMethods, selectedPaymentMethodId } = event.data;
      ctx[ParentState.cardPayment].paymentMethods = paymentMethods;
      ctx[ParentState.cardPayment].selectedPaymentMethodId =
        selectedPaymentMethodId;
      return ctx;
    }
  ),

  setSelectedPaymentMethodId: assign(
    (
      ctx: ParentContextWithCardPayment,
      event: SetSelectedPaymentMethodIdEvent
    ) => {
      ctx[ParentState.cardPayment].selectedPaymentMethodId =
        event.paymentMethod.paymentId;
      return ctx;
    }
  ),

  setDefaultPaymentMethodId: assign(
    (ctx: ParentContextWithCardPayment, _event) => {
      if (
        ctx[ParentState.cardPayment].paymentMethods.length > 0 &&
        ctx[ParentState.cardPayment].selectedPaymentMethodId === undefined
      ) {
        ctx[ParentState.cardPayment].selectedPaymentMethodId =
          ctx[ParentState.cardPayment].paymentMethods[0].id;

        if (!ctx[ParentState.payment].paymentType) {
          ctx[ParentState.payment].paymentType = Payment.Card;
        }
      }
      return ctx;
    }
  ),

  setPaymentError: assign(
    (
      ctx: ParentContextWithCardPayment,
      event: DoneInvokeEvent<PaymentError>
    ) => {
      // Refresh payment methods if possible to avoid stale data on list success only
      const { paymentMethods } = event.data;
      if (paymentMethods) {
        ctx[ParentState.cardPayment].paymentMethods = paymentMethods;
      }
      setContextWithKey(ctx, `${ParentState.cardPayment}.error`, event.data);
      return ctx;
    }
  ),

  setSpreedlyToken: assign(
    (ctx: ParentContextWithCardPayment, event: VerifyPaymentMethodEvent) =>
      setContextWithKey(
        ctx,
        `${ParentState.cardPayment}.spreedlyToken`,
        event.token
      )
  ),

  deletePaymentMethod: assign(
    (
      ctx: ParentContextWithCardPayment,
      event: DoneInvokeEvent<DeletePaymentMethodResponse>
    ) => {
      const { paymentMethods, deletedPaymentId } = event.data;
      if (
        ctx[ParentState.cardPayment].selectedPaymentMethodId ===
        deletedPaymentId
      ) {
        ctx[ParentState.cardPayment].selectedPaymentMethodId = undefined;
      }
      ctx[ParentState.cardPayment].paymentMethods = paymentMethods;
      return ctx;
    }
  ),
  setPaymentVisited: assign((ctx: ParentContextWithCardPayment, _event) =>
    setContextWithKey(ctx, `${ParentState.cardPayment}.visited`, true)
  ),

  setPaymentFulfillParams: assign(
    (ctx: ParentContextWithCardPayment, _event) => {
      const paymentPricing =
        ctx[ParentState.cartQuote].quoteBreakdown.sellAt ||
        ctx[ParentState.cartQuote].quoteBreakdown.balance;

      const cardPayment: ICardPayment = {
        type: Payment.Card,
        value: {
          paymentAmount: {
            amount: paymentPricing.fiat.value,
            currency: paymentPricing.fiat.currencyCode,
          },
          paymentId: ctx[ParentState.cardPayment].selectedPaymentMethodId,
        },
      };

      // Can only add one card for payment
      const payments = ctx[
        ParentState.cartFulfill
      ].fulfillRequestPayments.filter((p) => p.type !== Payment.Card);
      ctx[ParentState.cartFulfill].fulfillRequestPayments = [
        ...payments,
        cardPayment,
      ];
      return ctx;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);
