import clsx from "clsx";
import { Skeleton } from "@material-ui/lab";
import { Box, Typography } from "@material-ui/core";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ShopPage } from "../../../ShopPage/component";
import { HotelDetailsSection } from "../../../HotelDetailsSection";
import styles from "../../LodgingDetailsPage.module.scss";
import { useScrollToTop } from "@hopper-b2b/utilities";

export function LodgingDetailsPageSkeleton({
  isMobile,
  selectRoomLabel,
}: {
  isMobile: boolean;
  selectRoomLabel?: string;
}) {
  const { t } = useI18nContext();

  useScrollToTop();

  return (
    <ShopPage
      className={clsx(
        styles.LodingDetailsPage,
        isMobile && styles.mobile,
        styles.LodingDetailsPageSkeleton
      )}
      footer={
        isMobile ? null : (
          <RoomSelectSkeleton
            isMobile={isMobile}
            selectRoomLabel={selectRoomLabel}
          />
        )
      }
    >
      <Skeleton width={116} variant="text" />
      <Skeleton style={{ margin: "32px 0 8px" }} height={40} variant="text" />
      <Skeleton width={320} variant="text" />

      <Skeleton
        style={{ margin: "25px 0", borderRadius: "var(--border-radius" }}
        variant="rect"
        height={isMobile ? 241 : 419}
      />

      <Box className={styles.TopSection}>
        <div className={clsx(styles.Grid, isMobile && styles.mobile)}>
          <HotelDetailsSection title={t("amenitiesHeader")}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: isMobile ? "100%" : "1fr 1fr",
                gap: 24,
              }}
            >
              <SkeletonTexts quantity={isMobile ? 4 : 8} height={21} />
            </div>
            <Skeleton style={{ marginTop: 32 }} width={120} variant="text" />
          </HotelDetailsSection>

          <HotelDetailsSection title={t("aboutProperty")}>
            <SkeletonTexts quantity={4} />
            <Skeleton style={{ margin: "15px 0" }} width={67} variant="text" />
            <Skeleton variant="rect" height={300} />
          </HotelDetailsSection>

          <SkeletonCard />

          <SkeletonCard />
        </div>
        {!isMobile ? (
          <SkeletonCard className={styles.SearchDetailsSkeleton} />
        ) : null}
      </Box>
    </ShopPage>
  );
}

function SkeletonTexts({
  quantity,
  height,
}: {
  quantity: number;
  height?: number;
}) {
  const array = new Array(quantity).fill(null);
  return (
    <div>
      {array.map((_, index) => (
        <Skeleton key={index} height={height} variant="text" />
      ))}
    </div>
  );
}

export function SkeletonCard({ className }: { className?: string }) {
  return (
    <HotelDetailsSection className={className}>
      <Skeleton style={{ marginBottom: 26 }} width={200} variant="text" />
      <SkeletonTexts quantity={2} />
      <Skeleton style={{ marginTop: 15 }} width={67} variant="text" />
    </HotelDetailsSection>
  );
}

function RoomSelectSkeleton({
  isMobile,
  selectRoomLabel,
}: {
  isMobile: boolean;
  selectRoomLabel?: string;
}) {
  const { t } = useI18nContext();

  return (
    <div className={clsx("room-select-container", { mobile: isMobile })}>
      <Typography className="room-select-title" variant="h2">
        {selectRoomLabel ? selectRoomLabel : t("roomSelectionTitle")}
      </Typography>
      <Skeleton
        variant="rect"
        height={640}
        style={{ margin: "0 4rem", borderRadius: "var(--border-radius" }}
      />
    </div>
  );
}
