import { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { CBASpinner } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import styles from "./Privacy.module.scss";

const PRIVACY_POLICY_URL = "https://hopper.com/legal/privacy-policy";

export const Privacy = () => {
  const { t } = useI18nContext();
  const [iframeLoaded, setIFrameLoaded] = useState(false);
  return (
    <Box className={styles.privacyContainer}>
      <iframe
        title="Privacy Policy"
        src={PRIVACY_POLICY_URL}
        onLoad={() => setIFrameLoaded(true)}
      />
      {!iframeLoaded && (
        <Box className={styles.loadingMessage}>
          <CBASpinner />
          <Typography variant="h2">{t("loading")}</Typography>
        </Box>
      )}
    </Box>
  );
};
