import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../reducers/types";
import { fetchPriceFreeze } from "../actions/actions";
import { getCurrentPriceFreezeDataForOverviewPage } from "../reducer";
import { PriceFreezeOverviewPage } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    currentPriceFreezeOverview: getCurrentPriceFreezeDataForOverviewPage(state),
  };
};

const mapDispatchToProps = {
  fetchPriceFreeze,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeOverviewPageConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezeOverviewPage = connector(
  PriceFreezeOverviewPage
);
