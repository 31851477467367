import {
  Failure,
  Person,
  PersonListResponse,
  PersonListSuccess,
} from "@b2bportal/air-booking-api";

import { PassengerErrorModalTypes } from "@checkout/types";
import {
  fetchUserPassengers,
  trackEvent,
  updateUserPassenger,
} from "@hopper-b2b/api";
import { ADD_PASSPORT, SAVE_TRAVELER } from "@hopper-b2b/types";
import { FlightContextV2 } from "../../../types";
import { FlightPassengerSelectorsV2 } from "..";

export const updateUserPassengerService = (
  context: FlightContextV2,
  event: { person: Person; onUpdate?: boolean }
) =>
  new Promise((resolve, reject) => {
    const { person, onUpdate } = event;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { passport, driverLicense, ...restOfPayload } = person;
    const personToUpdate: Person = { ...restOfPayload };
    if (passport?.countryOfIssue?.length === 2) {
      personToUpdate.passport = passport;
    }
    const addPassport = FlightPassengerSelectorsV2.getAddPassport({ context });
    updateUserPassenger({ person: personToUpdate })
      .then(() => {
        trackEvent({ eventName: SAVE_TRAVELER, properties: {} });
        fetchUserPassengers({}).then((response: PersonListResponse) => {
          if (addPassport) {
            trackEvent({
              eventName: ADD_PASSPORT,
              properties: { success: false },
            });
          }
          if ((response as Failure).Response === "Failure") {
            reject({
              type: PassengerErrorModalTypes.UpdatePassenger,
              data: (response as Failure).errors,
            });
          } else {
            resolve({
              id: personToUpdate.id,
              value: (response as PersonListSuccess).value,
              onUpdate,
            });
          }
        });
      })
      .catch((e) => {
        if (addPassport) {
          trackEvent({
            eventName: ADD_PASSPORT,
            properties: { success: false },
          });
        }
        reject({
          type: PassengerErrorModalTypes.UpdatePassenger,
          data: e,
        });
      });
  });
