import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { PartialParentContextWithAirDisruptionV1 } from "./types";
import { useEnableDisruptionProtection } from "@hopper-b2b/utilities";

export const guards = {
  hasDisruptionOfferAndEnabled: (
    ctx: PartialParentContextWithAirDisruptionV1,
    _event: unknown
  ) =>
    ctx.featureFlags?.enableDisruptionProtection &&
    ctx[ParentState.disruptionV1]?.selectedOffers.length > 0,
};

export const GuardTypes = getObjectKeysAsObject(guards);
