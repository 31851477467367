import { put, select } from "redux-saga/effects";
import { WatchAlertView } from "@b2bportal/air-price-watch-api";
import {
  ADDED_WATCH,
  CallState,
  CreateWatchAlertRequest,
  Dealness,
  IOpenModal,
  MessageMethodEnum,
  WatchDetails,
} from "@hopper-b2b/types";
import { createWatch, trackEvent } from "@hopper-b2b/api";

import { actions } from "../../actions";
import { getOpenModal, getSelectedWatch } from "../../reducer";

export function* createWatchSaga(action: actions.ICreateWatch) {
  try {
    const selectedWatch: WatchAlertView = yield select(getSelectedWatch);
    const openModal: IOpenModal = yield select(getOpenModal);
    const watchDealness = (openModal.selectedItinerary as WatchDetails)
      ?.predictionCopy?.dealness;
    const request: CreateWatchAlertRequest = {
      key: selectedWatch.key,
      method: {
        email: action.email,
        MessageMethod: MessageMethodEnum.Email,
      },
      passengers: selectedWatch.passengers,
    };
    yield createWatch(request);
    yield put(actions.setCreateWatchCallState(CallState.Success));
    trackEvent({
      eventName: ADDED_WATCH,
      properties: {
        recommendation: watchDealness
          ? watchDealness === Dealness.Wait
            ? "wait"
            : "buy"
          : null,
      },
    });
  } catch (e) {
    yield put(actions.setCreateWatchCallState(CallState.Failed));
  }
}
