import { useCallback, useContext, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { TFunction } from "i18next";
import { startCase } from "lodash-es";
import clsx from "clsx";

import {
  FlightDetailsSummary,
  IconContentView,
  Icon,
  IconName,
  IconComponent,
} from "@hopper-b2b/ui";
import {
  getEnvVariables,
  getSliceIndex,
  getPlusDays,
  tenantFlagsEnabled,
  useDeviceTypes,
  useEnableCfar,
  useEnableChfar,
  getEmptyRestrictionsText,
} from "@hopper-b2b/utilities";
import {
  FareDetails,
  TripDetails,
  DullesUta,
  AirRestrictionStatus,
} from "@hopper-b2b/types";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";

import { ClientContext } from "../../../../ApacFlightApp";

import "./styles.scss";
import { useSelector } from "react-redux";
import { getSelectedCfarOffer } from "../../../cfar/reducer";
import { getSelectedChfarOffer } from "../../../chfar/reducer";
import { UtaCategory } from "@b2bportal/air-shopping-api";
import dayjs from "dayjs";
import { useHistory } from "react-router";

interface IFlightShopReviewDetailsProps {
  departure: boolean;
  tripDetails: TripDetails;
  fareDetails: FareDetails;
  isMultiTicket?: boolean;
  isMixedCabinClass?: boolean;
  stacked?: boolean;
  nonHackerFareTitle?: string;
  origin: string;
  destination: string;
  showChangeFlight?: boolean;
}

export interface IRestrictionProps {
  symbol: AirRestrictionStatus;
  name: string;
  description: string | JSX.Element;
  hideName?: boolean;
}

export const getRestrictions = ({
  fareDetails,
  sliceIndex,
  translate,
  customerSupport,
  cfarSelected = false,
  chfarSelected = false,
}: {
  fareDetails: FareDetails;
  sliceIndex: number;
  translate: TFunction;
  customerSupport: boolean;
  cfarSelected: boolean | undefined;
  chfarSelected: boolean | undefined;
}): IRestrictionProps[] => {
  const restrictions: IRestrictionProps[] =
    fareDetails.slices[sliceIndex].amenitiesUtas?.utas.utas.map(
      (uta: DullesUta) => {
        const cfarIncluded =
          uta.category === UtaCategory.cancellation && cfarSelected;
        const chfarIncluded =
          uta.category === UtaCategory.advance_change && chfarSelected;

        const restriction: IRestrictionProps = {
          // TODO: the assessment field should probably be an enum
          symbol:
            uta.assessment === "benefit" || cfarIncluded || chfarIncluded
              ? AirRestrictionStatus.INCLUDED
              : uta.assessment === "fee"
              ? AirRestrictionStatus.PURCHASABLE
              : AirRestrictionStatus.UNAVAILABLE,
          // TODO: probably need a different type for category
          // TODO: i18n - test out with diff langs
          name: uta.category
            .split("-")
            .reduce((name: string, segment: string) => {
              return `${name}${name.length > 0 ? " " : ""}${startCase(
                segment
              )}`;
            }, ""),
          description: cfarIncluded
            ? translate("cfarOffers.restrictionsCfarText")
            : chfarIncluded
            ? translate("chOffer.restrictionsChfarText")
            : uta.description,
        };

        return restriction;
      }
    ) ?? [];

  // TODO: Add customer support (find it from data)
  if (customerSupport) {
    restrictions.push({
      symbol: AirRestrictionStatus.INCLUDED,
      name: translate("flightShopReview.customerSupportTitle"),
      description: translate("flightShopReview.customerSupportSubtitle"),
    });
  }
  return restrictions;
};

export const FlightShopReviewDetails = ({
  departure,
  tripDetails,
  fareDetails,
  isMultiTicket,
  isMixedCabinClass,
  stacked,
  nonHackerFareTitle,
  origin,
  destination,
  showChangeFlight,
}: IFlightShopReviewDetailsProps) => {
  const sliceIndex = getSliceIndex(departure, tripDetails);
  const { matchesMobile } = useDeviceTypes();
  const clientContext = useContext(ClientContext);
  const { t: translate } = useI18nContext();
  const history = useHistory();

  const selectedCfarOffer = useSelector(getSelectedCfarOffer);
  const selectedChfarOffer = useSelector(getSelectedChfarOffer);

  const fareIndex = fareDetails.slices.findIndex((slice) =>
    departure ? slice.outgoing : !slice.outgoing
  );

  const slice = departure ? tripDetails.slices[0] : tripDetails.slices[1];
  const plusDays = getPlusDays(slice);

  const fare = fareDetails.slices[fareIndex];
  const customerSupport = tenantFlagsEnabled.CustomerSupport;
  const showFlightCFAR = useEnableCfar();
  const showFlightChfar = useEnableChfar();

  const cfarSelected = showFlightCFAR && !!selectedCfarOffer;
  const chfarSelected = showFlightChfar && !!selectedChfarOffer;
  const restrictions = getRestrictions({
    fareDetails,
    sliceIndex,
    translate,
    customerSupport,
    cfarSelected,
    chfarSelected,
  });

  const departureTime = useMemo(
    () =>
      departure
        ? tripDetails.slices[0].departureTime
        : tripDetails.slices[tripDetails.slices.length - 1].departureTime,
    [departure, tripDetails]
  );

  const handleSelectChangeFlight = () => history.goBack();

  return (
    <Box
      className={clsx("flight-shop-review-details-root", {
        mobile: matchesMobile,
      })}
    >
      <Box
        className={clsx("flight-shop-review-details-container", {
          stacked: stacked,
        })}
      >
        <FlightDetailsSummary
          className="review-itinerary-flight-details"
          showTitle={false}
          segments={
            departure
              ? tripDetails.slices[0].segmentDetails
              : tripDetails.slices[tripDetails.slices.length - 1].segmentDetails
          }
          departureTime={departureTime}
          planeInfo={
            fare.amenitiesUtas?.amenities?.aircraft.info.displayText || ""
          }
          fareClass={fare.fareShelf?.shortBrandName || ""}
          plusDays={plusDays}
          fareSlice={departure ? fareDetails.slices[0] : fareDetails.slices[1]}
          isMixedCabinClass={isMixedCabinClass}
          header={
            origin && destination ? (
              <I18nMarkup
                tKey={"flightShopReview.outboundCardHeaderWithOrigin"}
                replacements={{
                  origin,
                  destination,
                  date: dayjs(departureTime).format("dddd, MMMM D"),
                }}
              />
            ) : null
          }
          onChange={showChangeFlight && handleSelectChangeFlight}
          renderAirlineIconSection={true}
        />
        <Box className="restriction-details">
          <Box className="restriction-image-overlay-with-text">
            {!stacked && clientContext.itineraryReviewBackground && (
              <img
                src={clientContext.itineraryReviewBackground}
                alt="itinerary-review-background"
                className="itinerary-review-background"
              />
            )}
            <Box className="itinerary-overlay-text">
              <Typography className="title">
                {isMultiTicket
                  ? translate("flightShopReview.multiTicketTitle")
                  : nonHackerFareTitle ??
                    translate("flightShopReview.nonMultiTicketTitle")}
              </Typography>
              <Typography className="subtitle">
                {isMultiTicket
                  ? translate("flightShopReview.multiTicketSubtitle")
                  : translate("flightShopReview.nonMultiTicketSubtitle")}
              </Typography>
            </Box>
          </Box>

          <Box
            className={clsx("restrictions-section", {
              empty: restrictions.length === 0,
            })}
          >
            {restrictions.length > 0 ? (
              <>
                {restrictions.map((restriction) => (
                  <Restriction
                    key={restriction.name}
                    symbol={restriction.symbol}
                    name={restriction.name}
                    description={
                      <Typography variant="subtitle2" className="description">
                        {restriction.description}
                      </Typography>
                    }
                    hideName
                  />
                ))}
              </>
            ) : (
              getEmptyRestrictionsText(fare.fareShelf?.rating, translate)
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const restrictionIcon: { [k in AirRestrictionStatus]: JSX.Element } = {
  [AirRestrictionStatus.INCLUDED]: (
    <FontAwesomeIcon
      className="icon-available"
      icon={faCheckCircle as IconProp}
    />
  ),
  [AirRestrictionStatus.PURCHASABLE]: (
    <Icon className="icon-paid" name={IconName.MoneyOutlineTransparentIcon} />
  ),
  [AirRestrictionStatus.UNAVAILABLE]: (
    <Icon className="icon-unavailable" name={IconName.NotAllowedSign} />
  ),
  [AirRestrictionStatus.UNKNOWN]: null,
  [AirRestrictionStatus.GENERIC]: null,
};

const uberRestrictionIcon: { [k in AirRestrictionStatus]: JSX.Element } = {
  [AirRestrictionStatus.INCLUDED]: (
    <FontAwesomeIcon
      className="icon-available"
      icon={faCheckCircle as IconProp}
    />
  ),
  [AirRestrictionStatus.PURCHASABLE]: (
    <IconComponent className="icon-paid" name={IconName.PoundCurrencySymbol} />
  ),
  [AirRestrictionStatus.UNAVAILABLE]: (
    <IconComponent className="icon-unavailable" name={IconName.XCircleFilled} />
  ),
  [AirRestrictionStatus.UNKNOWN]: null,
  [AirRestrictionStatus.GENERIC]: null,
};

export const Restriction = ({
  symbol,
  name,
  description,
  hideName = false,
}: IRestrictionProps) => {
  const selectedCfarOffer = useSelector(getSelectedCfarOffer);
  const selectedChfarOffer = useSelector(getSelectedChfarOffer);

  const isUber = getEnvVariables("clientName") === "uber";
  let icon = isUber ? uberRestrictionIcon[symbol] : restrictionIcon[symbol];
  const cfarSelected =
    isUber && name === "Cancellation" && !!selectedCfarOffer?.quoteId;
  const chfarSelected =
    isUber && name === "Advance Change" && !!selectedChfarOffer?.quoteId;

  if (isUber && (cfarSelected || chfarSelected)) {
    icon = restrictionIcon[AirRestrictionStatus.INCLUDED];
  }

  return (
    <IconContentView
      className="restriction"
      icon={icon}
      content={
        <>
          {hideName ? null : <Typography className="title">{name}</Typography>}
          <Typography variant="caption">{description}</Typography>
        </>
      }
    />
  );
};
