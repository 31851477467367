import {
  ApacDesktopPopupModal,
  ApacIcon,
  ApacIconName,
  ApacMobileFullScreenModal,
} from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { FlightSearchFilterContent } from "../../../../components/FlightSearchFilterContent";
import { FlightShopStep, sortOptionSelector } from "../../reducer";
import { FlightsScoreExplained } from "../PricePrediction/FlightsScoreExplained";
import "./FlightShopHeader.styles.scss";
import { FareClassOptionSelection } from "./components/FareClassOptionSelection";
import { getSortLabelKey } from "./components/SortOptionSelection/component";
import { FlightShopHeaderConnectorProps } from "./container";

export interface IFlightShopHeaderProps extends FlightShopHeaderConnectorProps {
  isMobile: boolean;
  openFilterModal?: boolean;
  setOpenFilterModal?: Dispatch<SetStateAction<boolean>>;
}

export const FlightShopHeader = ({
  currentStep,
  isOneWay,
  destination,
  origin,
  hasChosenDepartureFlight,
  isMobile,
  openFilterModal,
  setOpenFilterModal,
}: IFlightShopHeaderProps) => {
  const { t } = useI18nContext();

  const sortOption = useSelector(sortOptionSelector);

  const renderFilterContent = () => (
    <FlightSearchFilterContent
      sliceOrigin={
        currentStep === FlightShopStep.ChooseReturn ? destination : origin
      }
      sliceDestination={
        currentStep === FlightShopStep.ChooseReturn ? origin : destination
      }
      isOneWay={isOneWay}
      currentStep={currentStep}
      hasChosenDepartureFlight={hasChosenDepartureFlight}
      isMobile={false}
      onClose={() => setOpenFilterModal(false)}
    />
  );

  if (isMobile && currentStep === FlightShopStep.FareDetails) {
    return null;
  }

  return (
    <div
      className={clsx("flight-shop-result-header-container", {
        mobile: isMobile,
        desktop: !isMobile,
      })}
    >
      <div className="flight-step-title-container">
        <Typography className="title-wrapper">
          {t("commBank.flightShopProgress.chooseFlight", {
            place: (currentStep === FlightShopStep.ChooseDeparture
              ? destination
              : origin
            )?.label?.split(",")[0],
          })}
        </Typography>
        <Typography variant="body2" className="subtitle">
          {t("commBank.flightShopProgress.priceInclusive", {
            tripCategory: t(isOneWay ? "oneWay" : "roundTrip"),
          })}
        </Typography>
      </div>
      <div className="filters-container">
        <div
          className="filter-and-sort"
          onClick={() => setOpenFilterModal(true)}
        >
          <div className="filter-wrapper">
            <ApacIcon className="filter-icon" name={ApacIconName.Filter} />
            <Typography variant="body1">{t("filter")}</Typography>
          </div>
          <div className="sort-wrapper">
            <ApacIcon className="sort-icon" name={ApacIconName.Sort} />
            <Typography variant="body1">
              {`${t("searchFilter.sortHeader")} ${t(
                getSortLabelKey(sortOption)
              )}`}
            </Typography>
          </div>
        </div>
        <FareClassOptionSelection />
      </div>

      {isMobile ? (
        <ApacMobileFullScreenModal
          open={openFilterModal}
          onClose={() => setOpenFilterModal(false)}
        >
          <Typography variant="h1">{t("flightShop.filterTitle")}</Typography>
          {renderFilterContent()}
        </ApacMobileFullScreenModal>
      ) : (
        <ApacDesktopPopupModal
          open={openFilterModal}
          onClose={() => setOpenFilterModal(false)}
          className="desktop-flights-score-modal"
          contentClassName="modal-content"
        >
          <Typography variant="h1">{t("flightShop.filterTitle")}</Typography>
          {renderFilterContent()}
        </ApacDesktopPopupModal>
      )}
    </div>
  );
};
