import { ApacModalLink, ApacTermsLink } from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Typography } from "@material-ui/core";
import "./styles.scss";

const FAQ_URL = "https://travelbooking-faqs.hopper.com/";

interface TabFooterProps {
  showTerms?: boolean;
  showFaq?: boolean;
}

export const TabFooter: React.FC<TabFooterProps> = ({ showTerms, showFaq }) => {
  const { t } = useI18nContext();

  return (
    <div className="tab-footer">
      <Typography variant="body1">{t("wallet.learnMore")}</Typography>

      {showTerms && (
        <ApacTermsLink showUnderline={false} scrollSelector="#travel-credits">
          {t("termsAndConditions")}
        </ApacTermsLink>
      )}

      {showFaq && (
        <ApacModalLink
          linkProps={{
            showUnderline: false,
          }}
          url={FAQ_URL}
        >
          {t("faq")}
        </ApacModalLink>
      )}
    </div>
  );
};
