import "./styles.scss";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { AirlineIcon } from "../AirlineIcon";

/**
 * Flight Summary Panel
 * @function FlightSummaryPanel
 * @param classes
 *
 */
export interface IFlightSummaryPanelProps {
  className?: string;
  airlineCode: string;
  formattedDepartureDescription: string; // e.g.: 'to Vancouver (YVR) on Mon, Nov 17'
  formattedDepartureTime: string; // e.g.: '6:30 AM'
  formattedArrivalTime: string; // e.g.: '6:30 PM'
  airline: string;
  duration: string;
  stopString: string;
  tripCategory: string; // e.g.: 'Outbound' / 'Return'
  plusDays?: number;
  fareClass?: string;
  isMobile?: boolean;
}

export const FlightSummaryPanel = ({
  className,
  airlineCode,
  formattedDepartureDescription,
  formattedDepartureTime,
  formattedArrivalTime,
  airline,
  duration,
  stopString,
  tripCategory,
  plusDays = 0,
  fareClass,
  isMobile,
}: IFlightSummaryPanelProps) => {
  return (
    <Box
      className={clsx(
        "flight-summary-panel-root",
        { mobile: isMobile },
        className
      )}
    >
      <Box className="flight-summary-panel-container">
        <Box className={clsx("header", "category-and-date")}>
          <Box>
            <Typography
              variant="subtitle1"
              className="trip-category-description"
            >
              <b className="trip-category-text">{tripCategory}</b>
              <span
                dangerouslySetInnerHTML={{
                  __html: ` ${formattedDepartureDescription}`,
                }}
              ></span>
              {plusDays !== 0 && (
                <Typography variant="inherit" className="plus-day-text">
                  {`${plusDays > 0 ? "+" : "-"}${Math.abs(plusDays)}d`}
                </Typography>
              )}
            </Typography>
          </Box>
        </Box>
        <Box className="summary-columns-section">
          <Box className={clsx("summary-column", "departure-and-airline")}>
            <Box>
              <Typography variant="h4">
                {`${formattedDepartureTime} - ${formattedArrivalTime}`}
              </Typography>
            </Box>
            <Box className="airline-icon">
              <AirlineIcon airlineCode={airlineCode} altText={airline} />
              <Typography variant="body2">{airline}</Typography>
              {fareClass && (
                <Typography variant="body2">&nbsp;- {fareClass}</Typography>
              )}
            </Box>
          </Box>
          <Box className={clsx("summary-column", "duration-and-stops")}>
            <Box>
              <Typography variant="h4">{duration}</Typography>
            </Box>
            <Box>
              <Typography variant="body2">{stopString}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
