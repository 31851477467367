import {
  TripCategory,
  PRICE_FREEZE_ID_QUERY_PARAM,
  CFAR_OFFER_QUOTE_ID_QUERY_PARAM,
  CHFAR_OFFER_QUOTE_ID_QUERY_PARAM,
  CHFAR_ID_QUERY_PARAM,
} from "@hopper-b2b/types";
import { select } from "redux-saga/effects";

import { IStoreState } from "../../../reducers/types";
import { getTripCategory } from "../../search/reducer/selectors";
import { IPopulateFlightBookQueryParams } from "../actions/actions";
import * as H from "history";
import queryStringParser from "query-string";
import { PATH_BOOK } from "../../../utils/urlPaths";
import {
  selectedTripSelector,
  tripDetailsSelector,
  getCandidateIdIfPDPEligible,
} from "../../shop/reducer/selectors";

export interface IFlightBookParsedQuery {
  tripId: string;
  outgoingFareId: string;
  returnFareId: string;
  tripCategory: TripCategory;
  priceDropCandidateId: string;
  [PRICE_FREEZE_ID_QUERY_PARAM]: string;
  [CFAR_OFFER_QUOTE_ID_QUERY_PARAM]: string;
  [CHFAR_OFFER_QUOTE_ID_QUERY_PARAM]: string;
  [CHFAR_ID_QUERY_PARAM]: string;
}

export function* populateFlightBookQueryParamsSaga(
  setUpQueryParamsAction: IPopulateFlightBookQueryParams
) {
  const state: IStoreState = yield select();
  populateFlightBookQueryParametersFromState({
    state,
    ...setUpQueryParamsAction,
  });
}

export const populateFlightBookQueryParametersFromState = ({
  state,
  history,
  pathname,
  preserveQuery,
  replace,
}: {
  state: IStoreState;
  history: H.History;
  pathname?: string;
  preserveQuery?: boolean;
  replace?: boolean;
}): IFlightBookParsedQuery => {
  const {
    outgoingFareId,
    returnFareId,
    tripId,
    tripCategory,
    priceDropCandidateId,
  } = getExistingStateVariables(state);

  const queryString = parseQueryString(history);

  const defaultBookQuery = {
    tripCategory: queryString.tripCategory || tripCategory,
    outgoingFareId: queryString.outgoingFareId || outgoingFareId || "",
    returnFareId: queryString.returnFareId || returnFareId || "",
    tripId: queryString.tripId || tripId || "",
    priceDropCandidateId:
      queryString.priceDropCandidateId || priceDropCandidateId || "",
    cfarOfferQuoteId: queryString[CFAR_OFFER_QUOTE_ID_QUERY_PARAM],
    chfarOfferQuoteId: queryString[CHFAR_OFFER_QUOTE_ID_QUERY_PARAM],
    [CHFAR_ID_QUERY_PARAM]: queryString[CHFAR_ID_QUERY_PARAM],
  };
  const exerciseBookQuery = {
    [PRICE_FREEZE_ID_QUERY_PARAM]: queryString[PRICE_FREEZE_ID_QUERY_PARAM],
  };
  // note: when priceFreezeId is given in the query param, treat the current session as a PF exercise flow
  const newQuery = exerciseBookQuery[PRICE_FREEZE_ID_QUERY_PARAM]
    ? exerciseBookQuery
    : defaultBookQuery;

  const parsedQueryStringPrimitive = queryStringParser.parse(
    history.location.search
  );

  const navigate = replace ? history.replace : history.push;

  navigate({
    pathname: pathname ?? PATH_BOOK,
    search: queryStringParser.stringify(
      preserveQuery ? { ...parsedQueryStringPrimitive, ...newQuery } : newQuery
    ),
  });

  return { ...defaultBookQuery, ...exerciseBookQuery };
};

export function getExistingStateVariables(state: IStoreState) {
  return {
    tripId: selectedTripSelector(state).tripId,
    tripDetails: tripDetailsSelector(
      state,
      selectedTripSelector(state).tripId || ""
    ),
    outgoingFareId: selectedTripSelector(state).outgoingFareId,
    returnFareId: selectedTripSelector(state).returnFareId,
    tripCategory: getTripCategory(state),
    priceDropCandidateId: getCandidateIdIfPDPEligible(state),
  };
}

export const parseQueryString = (
  history: H.History
): IFlightBookParsedQuery => {
  const queryString = history?.location?.search || "";

  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);
  return {
    tripCategory: parsedQueryStringPrimitive.tripCategory as TripCategory,
    outgoingFareId: parsedQueryStringPrimitive.outgoingFareId as string,
    returnFareId: parsedQueryStringPrimitive.returnFareId as string,
    tripId: parsedQueryStringPrimitive.tripId as string,
    priceDropCandidateId:
      parsedQueryStringPrimitive.priceDropCandidateId as string,
    [PRICE_FREEZE_ID_QUERY_PARAM]: parsedQueryStringPrimitive[
      PRICE_FREEZE_ID_QUERY_PARAM
    ] as string,
    [CFAR_OFFER_QUOTE_ID_QUERY_PARAM]:
      parsedQueryStringPrimitive.cfarOfferQuoteId as string,
    [CHFAR_OFFER_QUOTE_ID_QUERY_PARAM]:
      parsedQueryStringPrimitive.chfarOfferQuoteId as string,
    [CHFAR_ID_QUERY_PARAM]: parsedQueryStringPrimitive[
      CHFAR_ID_QUERY_PARAM
    ] as string,
  };
};
