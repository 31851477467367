import { ApacIconComponent, ApacIconName } from "@commbank/ui";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useI18nContext } from "@hopper-b2b/i18n";
import { CallState } from "@hopper-b2b/types";
import { ActionButton, FloatingBox } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTotalNights } from "../../../availability/reducer";
import { setSelectedRate, setSelectedRoom } from "../../actions/actions";
import { SECTION_ID } from "../../constants";
import { useGoToNextStep } from "../../hooks";
import {
  getRooms,
  getRoomsCallState,
  getSelectedRoomId,
  getSelectedRoom,
  getSelectedRoomRateId,
  getSelectedRoomFiatAndPointPrice,
} from "../../reducer/selectors";
import { RoomCard } from "../RoomCard/RoomCard";
import "./styles.scss";
import { LodgingShopTrackingEvents } from "@hopper-b2b/types";
import { useTrackEvents, ProductType } from "../../../../util/trackEvent";
import { PriceSummary } from "../PriceSummary";
import { useRoomSelectedTrackingProperties } from "../../hooks/useRoomSelectedTrackingProperties";

export const RoomSelect = forwardRef(
  (_props, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useI18nContext();

    const titleId = useId();

    const dispatch = useDispatch();
    const [showSelectionErrorMessage, setShowSelectionErrorMessage] =
      useState(false);
    const { matchesMobile: isMobile } = useDeviceTypes();

    const rooms = useSelector(getRooms);
    const isLoadingRooms =
      useSelector(getRoomsCallState) === CallState.InProcess;
    const selectedRoom = useSelector(getSelectedRoom);
    const roomId = useSelector(getSelectedRoomId);
    const rateId = useSelector(getSelectedRoomRateId);
    const selectedRoomPrice = useSelector(getSelectedRoomFiatAndPointPrice);

    const nights = useSelector(getTotalNights);

    const goToNextStep = useGoToNextStep();

    const carouselElementRef = useRef<HTMLDivElement | null>(null);
    const singleCardWidth = 380;

    const trackEvent = useTrackEvents();

    const trackingProperties = useRoomSelectedTrackingProperties();

    useEffect(() => {
      if (rooms.length) {
        trackEvent(
          LodgingShopTrackingEvents.hotel_loaded_room_info_list,
          ProductType.Hotel,
          trackingProperties.properties,
          trackingProperties.encryptedProperties
        );
      }
    }, [trackEvent, trackingProperties, rooms]);

    useEffect(() => {
      if (isMobile && !selectedRoom && rooms?.length > 0) {
        dispatch(setSelectedRoom(rooms[0]));
      }
    }, [dispatch, isMobile, rooms, selectedRoom]);

    /*
    room and product selected on room card, one continue botton on room selection page, check if product selected before continue
    */
    const onMobileContinue = useCallback(() => {
      if (!roomId || !rateId) {
        setShowSelectionErrorMessage(true);
      } else {
        goToNextStep();
        trackEvent(
          LodgingShopTrackingEvents.hotel_tapped_room_selection_continue,
          ProductType.Hotel,
          trackingProperties.properties,
          trackingProperties.encryptedProperties
        );
        setShowSelectionErrorMessage(false);
      }
    }, [trackEvent, trackingProperties, goToNextStep, rateId, roomId]);

    const onRateChange = useCallback(
      (roomId: string, rateId: string) => {
        dispatch(
          setSelectedRoom(rooms.find((r) => r?.roomInfo?.roomId === roomId))
        );
        dispatch(setSelectedRate(rateId));
      },
      [dispatch, rooms]
    );

    const onRoomChange = useCallback(
      (roomId: string) => {
        dispatch(
          setSelectedRoom(rooms.find((r) => r?.roomInfo?.roomId === roomId))
        );
      },
      [dispatch, rooms]
    );

    const moveCarouselRight = useCallback(() => {
      carouselElementRef.current.scrollTo({
        top: 0,
        left: carouselElementRef.current.scrollLeft + singleCardWidth,
        behavior: "smooth",
      });
    }, []);

    const moveCarouselLeft = useCallback(() => {
      carouselElementRef.current.scrollTo({
        top: 0,
        left: carouselElementRef.current.scrollLeft - singleCardWidth,
        behavior: "smooth",
      });
    }, []);

    const roomsRender = useMemo(() => {
      return rooms.map((room, i) => (
        <RoomCard
          room={room}
          key={`room-${room.roomInfo.roomId}-${i}`}
          nights={nights}
          currSelectedRoom={roomId}
          onSelectedRoomChange={onRoomChange}
          onRateChange={onRateChange}
        />
      ));
    }, [nights, onRateChange, onRoomChange, roomId, rooms]);

    const carousel = useMemo(() => {
      return (
        <>
          {rooms.length > 1 ? (
            <button
              className="Rooms-list-arrow left"
              onClick={moveCarouselLeft}
            >
              <FontAwesomeIcon
                icon={faChevronCircleLeft as IconProp}
                size={"3x"}
              />
            </button>
          ) : null}
          <div className="Rooms-list-scroll" ref={carouselElementRef}>
            {roomsRender}
          </div>
          {rooms.length > 1 ? (
            <button
              className="Rooms-list-arrow right"
              onClick={moveCarouselRight}
            >
              <FontAwesomeIcon
                icon={faChevronCircleRight as IconProp}
                size={"3x"}
              />
            </button>
          ) : null}
        </>
      );
    }, [rooms.length, moveCarouselLeft, roomsRender, moveCarouselRight]);

    return (
      <section
        id={SECTION_ID.rooms}
        className={clsx("room-select-container", { mobile: isMobile })}
        ref={ref}
        aria-labelledby={titleId}
      >
        <Typography id={titleId} className="room-select-title" variant="h2">
          {t("roomSelectionTitle")}
        </Typography>
        {rooms.length ? (
          <div>
            <div className={clsx("Rooms-list", { mobile: isMobile })}>
              {isMobile ? roomsRender : carousel}
            </div>

            {isMobile && (
              <FloatingBox className="continue-button-container">
                {showSelectionErrorMessage && (
                  <div className="no-selection-error">
                    <ApacIconComponent name={ApacIconName.Info} />
                    <Typography>
                      {t("lodgingShopDetails.selectOptionToContinue")}
                    </Typography>
                  </div>
                )}

                {selectedRoom && (
                  <PriceSummary
                    title={selectedRoom.roomInfo.name}
                    price={selectedRoomPrice}
                    nights={nights}
                  />
                )}
                <ActionButton
                  className={clsx("primary-button", "full-width")}
                  onClick={onMobileContinue}
                  message={t("hotelShop.reserveRoomGenericButtonLabel")}
                />
              </FloatingBox>
            )}
          </div>
        ) : isLoadingRooms ? (
          <Skeleton
            variant="rect"
            height={640}
            style={{ borderRadius: "var(--border-radius" }}
          />
        ) : null}
      </section>
    );
  }
);
