import {
  BookedFlightItineraryWithDepartureTime,
  SingleFlightItineraryResponse,
} from "@b2bportal/air-booking-api";
import {
  AirExchangeEligibilityQuoteSuccess,
  Airline,
  Airport,
  CipherText,
  ExchangeScenario,
  VoidScenario,
} from "@b2bportal/air-exchange-api";
import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import { isObject } from "lodash-es";

export enum FetchState {
  NotStarted,
  InProgress,
  Done,
}

export interface PolicyInitialState {
  airlines: Record<string, Airline>;
  airports: Record<string, Airport>;
  exchangePolicy?: ExchangeScenario;
  itinerary?: BookedFlightItineraryWithDepartureTime;
  itineraryFetchState: FetchState;
  policyFetchState: FetchState;
  quoteId?: CipherText;
  voidPolicy?: VoidScenario;
}

const SLICE_NAME = "exchange/policy";

const initialState: PolicyInitialState = {
  airlines: {},
  airports: {},
  exchangePolicy: undefined,
  itinerary: undefined,
  itineraryFetchState: FetchState.NotStarted,
  policyFetchState: FetchState.NotStarted,
  quoteId: undefined,
  voidPolicy: undefined,
};

const policySlice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    addAirlines(
      state,
      action: PayloadAction<Airline | Airline[] | Record<string, Airline>>
    ) {
      const { payload: airlines } = action;

      if (isObject(airlines)) {
        if (Array.isArray(airlines)) {
          for (let i = 0; i < airlines.length; i += 1) {
            state.airlines[airlines[i].code] = airlines[i];
          }
        } else {
          Object.assign(state.airlines, airlines);
        }
      } else {
        const airline = airlines as Airline;

        state.airlines[airline.code] = airline;
      }
    },
    addAirports(
      state,
      action: PayloadAction<Airport | Airport[] | Record<string, Airport>>
    ) {
      const { payload: airports } = action;

      if (isObject(airports)) {
        if (Array.isArray(airports)) {
          for (let i = 0; i < airports.length; i += 1) {
            state.airports[airports[i].code] = airports[i];
          }
        } else {
          Object.assign(state.airports, airports);
        }
      } else {
        const airport = airports as Airport;

        state.airports[airport.code] = airport;
      }
    },
    setPolicy(
      state,
      action: PayloadAction<AirExchangeEligibilityQuoteSuccess>
    ) {
      const { exchangeScenario, quoteId, voidScenario } = action.payload;

      state.exchangePolicy = exchangeScenario;
      state.policyFetchState = FetchState.Done;
      state.quoteId = quoteId;
      state.voidPolicy = voidScenario;
    },
    setPrevItinerary(
      state,
      action: PayloadAction<SingleFlightItineraryResponse>
    ) {
      const { itinerary, context } = action.payload;

      state.airlines = context.airlines;
      state.airports = context.airports;
      state.itinerary = itinerary;
      state.itineraryFetchState = FetchState.Done;
    },
  },
});

export const getExchangeContext = createAction<string>(
  `${SLICE_NAME}/getExchangeContext`
);

export { initialState as policyInitialState };
export const { addAirlines, addAirports, setPolicy, setPrevItinerary } =
  policySlice.actions;
export default policySlice.reducer;
