import { CallState } from "@hopper-b2b/types";
import { call, put } from "redux-saga/effects";

import { listWatches } from "../../../../api/v1/price-watch/listWatches";
import { actions } from "../../actions";
import { IListWatches } from "../../actions/actions";
import { setUpMyTripsParams } from "../setUpTripsParams";
import { WatchAlertViews } from "@b2bportal/air-price-watch-api";

export function* fetchWatchesSaga(action: IListWatches) {
  try {
    const { tripId } = yield call(setUpMyTripsParams, action);
    const value: WatchAlertViews = yield listWatches();
    const selectedWatch = value.watches.find((watch) => watch.id === tripId);
    if (tripId && selectedWatch) {
      yield put(actions.getDetailsWatch(selectedWatch, action.history));
    }
    yield put(actions.setListWatchesCallState(CallState.Success));
    yield put(actions.setWatches(value));
  } catch (e) {
    yield put(actions.setListWatchesCallState(CallState.Failed));
  }
}
