import {
  ApacActionButton,
  ApacTermsLink,
  ApacIconComponent,
  ApacIconName,
} from "@commbank/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import "./CfarHowItWorksContent.styles.scss";
import { DesktopModalHeader } from "../../../shop/components/PricePrediction/PriceDropProtectionContent/PriceDropProtectionContent";

interface CfarHowItWorksContentProps {
  onClose: () => void;
  amount: string;
}

export const CfarHowItWorksContent = ({
  onClose,
  amount,
}: CfarHowItWorksContentProps) => {
  const { t } = useI18nContext();

  return (
    <div className={clsx("cfar-how-it-works-content-container")}>
      <DesktopModalHeader
        icon={ApacIconName.Ticket}
        title={t("cfarOffers.title")}
      />

      <Box className="section-container">
        <Typography variant="h3">{t("howItWorks")}</Typography>
        <div className="description-item">
          <div className="item-prefix-container">
            <Typography className="item-number">1</Typography>
          </div>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: t("cfar.howItWorks.descriptionItem1"),
            }}
          />
        </div>
        <div className="description-item">
          <div className="item-prefix-container">
            <Typography className="item-number">2</Typography>
          </div>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: t("cfar.howItWorks.descriptionItem2", { amount }),
            }}
          />
        </div>
      </Box>

      <Box className="section-container">
        <Typography variant="h3">
          {t("disruptionPurchase.howItWorks.noticeTitle")}
        </Typography>
        <div className="description-item">
          <ApacIconComponent
            className="prefix-icon warn"
            name={ApacIconName.WarnOutlined}
          />
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: t("cfar.howItWorks.noticeItem1"),
            }}
          />
        </div>
        <div className="description-item">
          <ApacIconComponent
            className="prefix-icon info"
            name={ApacIconName.Money}
          />
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: t("cfar.howItWorks.noticeItem2"),
            }}
          />
        </div>
        <div className="description-item">
          <ApacIconComponent
            className="prefix-icon info"
            name={ApacIconName.InfoOutlined}
          />
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: t("cfar.howItWorks.noticeItem3"),
            }}
          />
        </div>
        <div className="description-item">
          <ApacIconComponent
            className="prefix-icon info"
            name={ApacIconName.Protection}
          />
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: t("cfar.howItWorks.noticeItem4"),
            }}
          />
        </div>
      </Box>

      <Box className="section-container">
        <Typography variant="h3">{t("cfar.howItWorks.extraTitle")}</Typography>
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: t("cfar.howItWorks.extraDescription"),
          }}
        />
      </Box>

      <div className="footer">
        <ApacTermsLink showUnderline={false} scrollSelector="#flex-it">
          {t("termsAndConditions")}
        </ApacTermsLink>
        <ApacActionButton
          className="close-button"
          onClick={onClose}
          message={t("close.button")}
        />
      </div>
    </div>
  );
};
