import { FlightItinerarySlice } from "@b2bportal/air-booking-api";
import { ExchangeActionEnum } from "@b2bportal/air-exchange-api";
import { Slice } from "@b2bportal/air-shopping-api";
import {
  FareDetails,
  getDepartureSlice,
  getReturnSlice,
} from "@hopper-b2b/types";
import { Box } from "@material-ui/core";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  flightsSelector,
  selectedTripSelector,
  tripDetailsByIdSelector,
} from "../../../shop/reducer";
import {
  getOutboundSelection,
  getReturnSelection,
} from "../../reducer/selectors";
import { getItinerary } from "../../selectors";
import { TripType } from "../../types";
import SliceSummaryCard from "./SliceSummaryCard";

import "./styles.scss";

export interface INewTripSummaryProps {
  [prop: string]: unknown;
}

const defaultProps: Partial<INewTripSummaryProps> = {};

const NewTripSummary = (_props: INewTripSummaryProps): JSX.Element => {
  // const {  } = props;
  const booking = useSelector(getItinerary);
  const flights = useSelector(flightsSelector);
  const outboundSelection = useSelector(getOutboundSelection);
  const returnSelection = useSelector(getReturnSelection);
  const shoppedTrip = useSelector(selectedTripSelector);
  const tripDetailsMap = useSelector(tripDetailsByIdSelector);

  const { outgoingFareId, returnFareId, tripId } = shoppedTrip;
  const tripDetails = tripDetailsMap[tripId ?? ""];
  let fareDetails: FareDetails | undefined;

  const { outgoingSlice, returnSlice } = useMemo(() => {
    const { outgoingSliceId = "", returnSliceId = "" } = shoppedTrip;
    let oSlice: FlightItinerarySlice | Slice | null = null;
    let rSlice: FlightItinerarySlice | Slice | null = null;

    if (outboundSelection === ExchangeActionEnum.Keep && booking) {
      // use prev booking outbound slice
      oSlice = getDepartureSlice(
        booking.bookedItinerary
      ) as FlightItinerarySlice;
    } else if (outgoingSliceId) {
      // use shopped outbound slice
      oSlice = flights?.slices[outgoingSliceId] as Slice;
    }

    if (returnSelection === ExchangeActionEnum.Keep && booking) {
      // use prev booking return slice
      rSlice = getReturnSlice(booking.bookedItinerary) as FlightItinerarySlice;
    } else if (returnSliceId) {
      // use shopped return slice
      rSlice = flights?.slices[returnSliceId] as Slice;
    }

    return { outgoingSlice: oSlice, returnSlice: rSlice };
  }, [booking, flights, outboundSelection, returnSelection, shoppedTrip]);

  if (tripDetails) {
    const selectedFareId = returnFareId || outgoingFareId;

    fareDetails = tripDetails.fareDetails.find(
      (fd) => fd.id === selectedFareId
    );
  }

  return (
    <Box className="new-trip-summary">
      {fareDetails && outgoingSlice && (
        <SliceSummaryCard
          className="outbound"
          fareDetails={fareDetails}
          slice={outgoingSlice}
          tripType={TripType.Outbound}
        />
      )}
      {fareDetails && returnSlice && (
        <SliceSummaryCard
          className="return"
          fareDetails={fareDetails}
          slice={returnSlice}
          tripType={TripType.Return}
        />
      )}
    </Box>
  );
};

NewTripSummary.defaultProps = defaultProps;

export default NewTripSummary;
