import {
  airChfarApi,
  AirChfarShopRequest,
  AirChfarShopResponse,
} from "@b2bportal/air-chfar-api";
import {
  IApiConfig,
  IShopParams,
  NETWORK_CALL_FAILED,
  PassengerTypes,
  Platform,
  SliceStopCountFilter,
} from "@hopper-b2b/types";
import axios from "axios";
import { trackEvent } from "../../tracking/trackEvent";
import { ShopFilter } from "@b2bportal/air-shopping-api";

export const fetchChfarFlights = async (
  shopParams: IShopParams,
  chfarId: string,
  isMobile: boolean,
  apiConfig?: IApiConfig
): Promise<AirChfarShopResponse> => {
  return new Promise(async (resolve, reject) => {
    const {
      origin,
      destination,
      departureDate,
      returnDate,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      fareclassOptionFilter,
    } = shopParams;
    const passengerObj = {
      [PassengerTypes.Adult]: adultsCount,
      [PassengerTypes.InfantInSeat]: infantsInSeatCount,
      [PassengerTypes.InfantInLap]: infantsOnLapCount,
      [PassengerTypes.Child]: childrenCount,
    };

    const filterOutBasicFares =
      !fareclassOptionFilter.basic &&
      fareclassOptionFilter.luxury &&
      fareclassOptionFilter.enhanced &&
      fareclassOptionFilter.premium &&
      fareclassOptionFilter.standard;

    const tripFilter =
      fareclassOptionFilter && filterOutBasicFares
        ? stopsOption === SliceStopCountFilter.NONE
          ? ShopFilter.NonStopNoLCC
          : ShopFilter.NoLCC
        : stopsOption === SliceStopCountFilter.NONE
        ? ShopFilter.NonStop
        : ShopFilter.NoFilter;

    const request: AirChfarShopRequest = {
      contractId: chfarId,
      rebookingShopFilters: {
        passengers: passengerObj,
        departureDate,
        ...(returnDate && { returnDate }),
        route: {
          origin,
          destination,
        },
        tripFilter,
        platform: isMobile ? Platform.Mobile : Platform.Desktop,
      },
    };

    try {
      const res = await airChfarApi(axios).apiV0ChfarAirShopPost(request);
      resolve(res.data);
    } catch (error) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(error);
    }
  });
};
