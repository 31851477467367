import axios from "axios";

import { paymentApiPrefix } from "../../paths";
import {
  PaymentSplitRequest,
  ConnectionResult,
  NETWORK_CALL_FAILED,
  IApiConfig,
} from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";

const END_POINT = `${paymentApiPrefix}/payment/schedule`;

export const schedulePayment = (
  req: PaymentSplitRequest,
  apiConfig?: IApiConfig
): Promise<ConnectionResult> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(END_POINT, req);

      const responseBody: ConnectionResult = res.data;
      resolve(responseBody);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });
