import { Airport, Prediction } from "@b2bportal/air-shopping-api";
import {
  CallState,
  FiatPrice,
  FlightShopType,
  TripCategory,
} from "@hopper-b2b/types";
import { Dispatch } from "@reduxjs/toolkit";
import * as H from "history";
import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { getSelectedAccountReferenceId } from "../../../rewards/reducer";
import { actions } from "../../../search/actions";
import {
  getHasSetFareClassFilter,
  getHasSetStopsOption,
  getTripCategory,
  initialFilterOptions,
} from "../../../search/reducer";
import { actions as shopActions } from "../../actions";
import {
  CreateWatchOptions,
  ICreateWatch,
  IDeleteWatch,
  IRerunPrediction,
  ISetCreateWatchCallState,
  ISetFlightShopProgress,
  ISetOpenCalendarModal,
  setFlightShopProgress,
} from "../../actions/actions";
import {
  createWatchCallStateSelector,
  FlightShopStep,
  flightShopTypeSelector,
  ITripSummariesById,
  listWatchCallStateSelector,
  tripDetailsLoadingSelector,
} from "../../reducer";
import {
  airportsSelector,
  allTripSummariesSelector,
  getPriceFreezeOfferCheapestTripTripId,
  getSortedAndFilteredFlights,
  isWatchingSelector,
  maxFlightPriceSelector,
  predictionLoadingSelector,
  predictionSelector,
  refreshPredictionSelector,
} from "../../reducer/selectors";
import { IFlightListData } from "../../v3/components/FlightList/FlightList";
import { MobilePricePredictionSection } from "./MobilePricePredictionSection";

const mapStateToProps = (state: IStoreState): IStateProps => {
  const airports = airportsSelector(
    state,
    getPriceFreezeOfferCheapestTripTripId(state)
  );

  return {
    refreshPrediction: refreshPredictionSelector(state),
    prediction: predictionSelector(state),
    tripCategory: getTripCategory(state),
    data: {
      maxFlightPrice: maxFlightPriceSelector(state),
    },
    isWatching: isWatchingSelector(state),
    hasFilters: getHasSetStopsOption(state) || getHasSetFareClassFilter(state),
    tripSummaries: allTripSummariesSelector(state),
    airports,
    isTripDetailsLoading: tripDetailsLoadingSelector(state),
    flightShopType: flightShopTypeSelector(state),
    flightList: getSortedAndFilteredFlights(state),
    predictionLoading: predictionLoadingSelector(state),
    createWatchCallState: createWatchCallStateSelector(state),
    listWatchCallState: listWatchCallStateSelector(state),
    selectedRewardsAccountId: getSelectedAccountReferenceId(state),
  };
};

interface IStateProps {
  data: { maxFlightPrice: number };
  prediction: Prediction | null;
  selectedRewardsAccountId: string | null;
  tripCategory: TripCategory;
  refreshPrediction: boolean;
  isWatching: boolean;
  hasFilters: boolean;
  tripSummaries: ITripSummariesById;
  airports: { [key: string]: Airport };

  isTripDetailsLoading: boolean | null;
  flightShopType: FlightShopType;
  flightList: IFlightListData[];
  predictionLoading: boolean | null;

  createWatchCallState: CallState;
  listWatchCallState: CallState;
}

interface IDispatchProps {
  fetchTripSummaries: any;
  setFlightShopProgress: (step: FlightShopStep) => ISetFlightShopProgress;
  resetFilters: (maxPrice: number) => void;
  deleteWatch: () => IDeleteWatch;
  rerunPrediction: () => IRerunPrediction;
  setOpenFlightShopCalendarMobile: (
    openFlightShopCalendarMobile: boolean
  ) => ISetOpenCalendarModal;
  createWatch: (email: string, options?: CreateWatchOptions) => ICreateWatch;
  setCreateWatchCallState: (callState: CallState) => ISetCreateWatchCallState;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  createWatch: (email: string, options?: CreateWatchOptions) =>
    dispatch(shopActions.createWatch(email, options)),
  setCreateWatchCallState: (callState: CallState) =>
    dispatch(shopActions.setCreateWatchCallState(callState)),
  deleteWatch: () => dispatch(shopActions.deleteWatch()),
  setFlightShopProgress: (step: FlightShopStep) =>
    dispatch(setFlightShopProgress(step)),
  fetchTripSummaries: (
    history: any,
    isMobile?: boolean,
    creditBalance?: FiatPrice
  ) => dispatch(shopActions.fetchFlightsV4(history, isMobile, creditBalance)),
  resetFilters: (maxPrice: number) => {
    dispatch(actions.setStopsOption(initialFilterOptions.stopsOption));
    dispatch(actions.setAirlineFilter(initialFilterOptions.airlineFilter));
    dispatch(actions.setMaxPriceFilter(maxPrice));
    dispatch(actions.setAirportFilter(initialFilterOptions.airportFilter));
    dispatch(
      actions.setFlightNumberFilter(initialFilterOptions.flightNumberFilter)
    );
    dispatch(
      actions.setOutboundArrivalTimeRange(
        initialFilterOptions.outboundArrivalTimeRange
      )
    );
    dispatch(
      actions.setOutboundDepartureTimeRange(
        initialFilterOptions.outboundDepartureTimeRange
      )
    );
    dispatch(
      actions.setReturnDepartureTimeRange(
        initialFilterOptions.returnDepartureTimeRange
      )
    );
    dispatch(
      actions.setReturnArrivalTimeRange(
        initialFilterOptions.returnArrivalTimeRange
      )
    );
  },
  rerunPrediction: () => dispatch(shopActions.setRerunPrediction()),
  setOpenFlightShopCalendarMobile: (openFlightShopCalendarMobile: boolean) =>
    dispatch(shopActions.setOpenCalendarModal(openFlightShopCalendarMobile)),
});

interface OwnProps {
  filtersOpen: boolean;
  setFiltersOpen: (open: boolean) => void;
  history: H.History;
}

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: OwnProps
) => {
  const { maxFlightPrice } = stateProps.data;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    resetFilters: () => dispatchProps.resetFilters(maxFlightPrice),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type MobilePricePredictionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobilePriceSectionPrediction = connector(
  MobilePricePredictionSection
);
