import clsx from "clsx";
import "./styles.scss";
import { Slot } from "../Slots";

export interface ITripHeaderTitles {
  title: string;
  subtitle?: string;
  supportCTA: string;
}
export interface ITripsHeaderProps {
  className?: string;
  isMobile?: boolean;
  tripHeaderCopy: ITripHeaderTitles;
  showSubtitleForMobile?: boolean;
}

const defaultProps: Partial<ITripsHeaderProps> = {};

export const TripsHeader = ({
  className,
  isMobile,
  tripHeaderCopy,
  showSubtitleForMobile,
}: ITripsHeaderProps): JSX.Element => {
  const { subtitle, title } = tripHeaderCopy;

  return (
    <div
      className={clsx(className, "trips-header", {
        mobile: isMobile,
        desktop: !isMobile,
      })}
    >
      <div className="trips-header-titles-container">
        <Slot id="mytrips-landing-page-icon" />
        <div className="trips-header-titles">
          <h2 className="trips-header-title">{title}</h2>
          {(!isMobile || showSubtitleForMobile) && subtitle ? (
            <p className="trips-header-subtitle">{subtitle}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

TripsHeader.defaultProps = defaultProps;
