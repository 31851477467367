import axios from "axios";
import { priceFreezeOfferPath } from "../paths";
import {
  NETWORK_CALL_FAILED,
  FetchOfferRequest,
  FetchOfferResponse,
  IApiConfig,
} from "@hopper-b2b/types";
import { trackEvent } from "@hopper-b2b/api";

const getPriceFreezeOfferPath = `${priceFreezeOfferPath}/get`;

export const getPriceFreezeOffer = (
  body: FetchOfferRequest,
  apiConfig?: IApiConfig
): Promise<FetchOfferResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(getPriceFreezeOfferPath, body);

      resolve(res.data);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });

export default getPriceFreezeOffer;
