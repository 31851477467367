import { useI18nContext } from "@hopper-b2b/i18n";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { ApacDesktopPopupModal } from "../ApacDesktopPopupModal";
import { CBASpinner } from "../CBASpinner";
import "./ApacLoadingModal.styles.scss";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { ApacMobileFullScreenModal } from "../ApacMobileFullScreenModal";

export const ApacLoadingModal = ({
  open,
  title,
  subtitle,
}: {
  open: boolean;
  title?: string;
  subtitle?: string;
}) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const loadingContent = () => {
    return (
      <div className="loading-content-container">
        <CBASpinner />
        <Typography variant="h2" className="title">
          {title || t("loading")}
        </Typography>
        {!!subtitle && (
          <Typography variant="body1" className="subtitle">
            {subtitle}
          </Typography>
        )}
      </div>
    );
  };
  return matchesMobile ? (
    <ApacMobileFullScreenModal
      open={open}
      className={clsx("apac-loading-modal mobile")}
    >
      {loadingContent()}
    </ApacMobileFullScreenModal>
  ) : (
    <ApacDesktopPopupModal
      open={open}
      className="apac-loading-modal desktop"
      contentClassName="apac-loading-modal-content"
      hideXButton
    >
      {loadingContent()}
    </ApacDesktopPopupModal>
  );
};
