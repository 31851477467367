import { Slice } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Fare, FareDetails, FlightShopCardType } from "@hopper-b2b/types";
import { LoadingIndicator } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import clsx from "clsx";
import { get } from "lodash-es";
import { FC, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { CBASpinner } from "@commbank/ui";
import { Box } from "@material-ui/core";
import {
  setChosenOutgoingSlice,
  setChosenReturnSlice,
} from "../../../../../../actions/actions";
import { IFlightListData } from "../../../FlightList";
import { DesktopFlightCardDetails } from "../../DesktopFlightCard/DesktopFlightCardDetails";
import { FlightDetails } from "../../FlightDetails";
import { FlightListInfo } from "../../FlightListInfo";
import { FlightCardType } from "../../FlightListInfo/component";
import { IInfiniteFlightsProps } from "../index";

const MOBILE_OFFSET_SCROLL = 80;
const DESKTOP_OFFSET_SCROLL = 250;

export const FlightComponent: FC<
  IInfiniteFlightsProps<{
    flight: IFlightListData;
    slice: Slice | undefined;
    index: number;
    selectedFare: Fare;
    openFlightDetailModal?: boolean;
    setOpenFlightDetailModal?: (open: boolean) => void;
    clickedFareId?: string;
    setClickedFareId?: (fareId: string) => void;
  }>
> = (props) => {
  const {
    departureDate,
    expandedFareDetails,
    expandedFlight,
    fareClassFilter,
    flight,
    flights,
    onAlgomerchClick,
    onFareSelect,
    onSliceSelect,
    isInChooseReturnStep,
    isRoundTrip,
    maxFlightPrice,
    returnDate,
    rewardsKey,
    outgoingFareRating,
    slice,
    selectedFare,
    openFlightDetailModal,
    setOpenFlightDetailModal,
    clickedFareId,
    setClickedFareId,
    setExpandedFlight,
  } = props;

  const { t, brand } = useI18nContext();
  const dispatch = useDispatch();

  const { matchesMobile, matchesLargeDesktop } = useDeviceTypes();

  const [selectedFareId, setSelectedFareId] = useState("");

  const sliceId = flight.slice;
  const isTripInternational = !slice?.domestic;

  const history = useHistory();
  const location = useLocation();

  const handleFareChange = (fareId: string) => {
    setClickedFareId(fareId);
    if (isInChooseReturnStep) {
      dispatch(
        setChosenReturnSlice({
          returnFareId: fareId,
          returnSliceId: sliceId,
          returnFareRating: get(
            flights,
            `fareSlices[${fareId}].fareShelf.value`,
            0
          ),
          tripId: get(flights, `fares[${fareId}]tripId`, ""),
        })
      );
    } else {
      dispatch(
        setChosenOutgoingSlice({
          outgoingFareId: fareId,
          outgoingSliceId: sliceId,
          outgoingFareRating: get(
            flights,
            `fareSlices[${fareId}].fareShelf.value`,
            0
          ),
          tripId: get(flight, `fares[${fareId}].tripId`, ""),
          // whenever selecting a different departure flight, reset return ids
          resetReturnIds: true,
        })
      );
    }
  };

  const fareId = selectedFare.example?.fare || selectedFare.id;

  const handleFareSelect = useCallback(
    (_sliceId: string, fare: FareDetails) => {
      setExpandedFlight && setExpandedFlight("");
      history.replace(`${location.pathname}${location.search}`);
      onFareSelect(flight, fare!.id);
    },
    [
      flight,
      history,
      location.pathname,
      location.search,
      onFareSelect,
      setExpandedFlight,
    ]
  );

  const handleOnClick = useCallback(
    (selectedFareRating: number) => {
      // For Uber: select trip and first fare id
      // TODO: Test other tenants' flights flows and add in if needed
      if (isInChooseReturnStep) {
        dispatch(
          setChosenReturnSlice({
            returnFareId: fareId,
            returnSliceId: sliceId,
            returnFareRating: selectedFareRating,
            tripId: get(flights, `fares[${fareId}].tripId`, ""),
          })
        );
      } else {
        dispatch(
          setChosenOutgoingSlice({
            outgoingFareId: fareId,
            outgoingSliceId: sliceId,
            outgoingFareRating: get(
              flights,
              `fareSlices[${fareId}].fareShelf.value`,
              0
            ),
            tripId: get(flight, `fares[${selectedFareId}].tripId`, ""),
            // whenever selecting a different departure flight, reset return ids
            resetReturnIds: true,
          })
        );
      }

      onSliceSelect(fareId, selectedFareRating, flight);
      setTimeout(() => {
        const OFFSET = matchesMobile
          ? MOBILE_OFFSET_SCROLL
          : DESKTOP_OFFSET_SCROLL;
        const cardTop =
          document?.getElementById(fareId)?.getBoundingClientRect().top || 0;
        window.scrollBy({
          top: (cardTop as number) - OFFSET,
          behavior: "smooth",
        });
        // Wait 0.5s to let the smooth animation complete
        setTimeout(() => {
          // Force a rescroll
          window.scrollBy({ top: 1 });
        }, 500);
      }, 100);
    },
    [
      isInChooseReturnStep,
      onSliceSelect,
      fareId,
      flight,
      dispatch,
      sliceId,
      flights,
      selectedFareId,
      matchesMobile,
    ]
  );

  const isLargeDesktop = useMemo(
    () => matchesLargeDesktop && isTripInternational,
    [isTripInternational, matchesLargeDesktop]
  );
  return (
    <div
      id={fareId}
      className={clsx(
        "flight-list-item",
        "flight-row",
        {
          "row-view-desktop": isLargeDesktop,
          "row-view-mobile": matchesMobile,
          selected: fareId === expandedFlight,
          mini: brand.flightShopCardType === FlightShopCardType.MINI,
        },
        "b2b"
      )}
    >
      <FlightListInfo
        selectedFare={selectedFare}
        slice={slice}
        flights={flights}
        flight={flight}
        rewardsKey={rewardsKey}
        fareClassFilter={fareClassFilter}
        maxFlightPrice={maxFlightPrice}
        isRoundTrip={isRoundTrip}
        type={FlightCardType.content}
        isExpanded={fareId === expandedFlight}
        onClick={handleOnClick}
        onAlgomerchClick={onAlgomerchClick}
        onFareClick={setSelectedFareId}
      />

      {fareId === expandedFlight && expandedFareDetails && matchesMobile && (
        <FlightDetails
          isOutgoing={!isInChooseReturnStep}
          selectedFareId={selectedFareId}
          onFareClick={handleFareSelect}
          onAlgomerchClick={onAlgomerchClick}
          tripDetails={expandedFareDetails}
          airports={flights!.airports}
          rewardsKey={rewardsKey}
          departureDate={departureDate}
          returnDate={returnDate}
          outgoingFareRating={outgoingFareRating}
          openFlightDetailModal={openFlightDetailModal}
          setOpenFlightDetailModal={setOpenFlightDetailModal}
          clickedFareId={clickedFareId}
          setClickedFareId={handleFareChange}
          setExpandedFlight={setExpandedFlight}
        />
      )}

      {fareId === expandedFlight && (
        <Box>
          {expandedFareDetails ? (
            !matchesMobile && (
              <DesktopFlightCardDetails
                isOutgoing={!isInChooseReturnStep}
                isRoundTrip={isRoundTrip}
                selectedFareId={selectedFareId}
                onFareClick={handleFareSelect}
                onAlgomerchClick={onAlgomerchClick}
                tripDetails={expandedFareDetails}
                airports={flights!.airports}
                rewardsKey={rewardsKey}
                departureDate={departureDate}
                returnDate={returnDate}
                outgoingFareRating={outgoingFareRating}
                openFlightDetailModal={openFlightDetailModal}
                setOpenFlightDetailModal={setOpenFlightDetailModal}
                clickedFareId={clickedFareId}
                setClickedFareId={handleFareChange}
                setExpandedFlight={setExpandedFlight}
              />
            )
          ) : (
            <LoadingIndicator
              className="flight-shop-details-loading-indicator"
              indicatorSize={"small"}
              indicator={CBASpinner}
              message={t("loadingFlightDetails")}
            />
          )}
        </Box>
      )}
    </div>
  );
};
