import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../../../../../../reducers/types";
import { setMaxPriceFilter } from "../../../../../../actions/actions";
import { getMaxPriceFilter } from "../../../../../../reducer/index";
import { MaxPriceFilterSelection } from "./component";

const mapStateToProps = (state: IStoreState) => {
  const maxPriceFilter = getMaxPriceFilter(state);

  return {
    maxPriceFilter,
  };
};

const mapDispatchToProps = {
  setMaxPriceFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MaxPriceFilterSelectionProps = ConnectedProps<typeof connector>;

export const ConnectedMaxPriceFilterSelection = connector(
  MaxPriceFilterSelection
);
