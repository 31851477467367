import axios from "axios";
import { IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { trackEvent } from "../../tracking/trackEvent";
import {
  airPriceWatchApi,
  WatchAlertView,
  WatchAlertViewsSuccess,
  ResponseEnum,
  Failure,
} from "@b2bportal/air-price-watch-api";

export const listWatches = (
  apiConfig?: IApiConfig
): Promise<WatchAlertView[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await airPriceWatchApi(axios).apiV0AlertWatchListPut();
      switch (res.data.Response) {
        case ResponseEnum.Success: {
          resolve((res.data as WatchAlertViewsSuccess).value.watches);
          break;
        }
        case ResponseEnum.Failure: {
          reject((res.data as Failure).errors);
          break;
        }
      }
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      reject(e);
    }
  });
};
