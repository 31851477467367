import { ApacIcon, ApacIconName } from "@commbank/ui";
import {
  AccountPrice,
  FiatPrice,
  RoomInfoProducts,
  RoomProduct,
} from "@b2bportal/lodging-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionLink, Slot } from "@hopper-b2b/ui";
import { useDeviceTypes, useTenantIcons } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ImageCarousel } from "../../../../components/ImageCarousel";
import { numberWithCommas } from "../../../../util/utils";
import { useCallback, useEffect, useId, useState } from "react";
import { useSelector } from "react-redux";
import { HotelLoyaltyChip } from "../../../availability/components/HotelLoyaltyChip";
import { getRooms } from "../../../availability/reducer";
import { getSelectedLodgingGroupedMedia } from "../../reducer/selectors";
import { DetailsAndAmenitiesModal } from "./components";
import "./styles.scss";
import { getLowestRoomPrice } from "../../utils/getLowestPointsPrice";
import { ProductType, useTrackEvents } from "../../../../util/trackEvent";
import { LodgingShopTrackingEvents } from "@hopper-b2b/types";

export const RoomCard = ({
  room,
  onSelectedRoomChange,
  currSelectedRoom,
  nights,
  onRateChange,
}: {
  room: RoomInfoProducts;
  onSelectedRoomChange: (roomId: string) => void;
  currSelectedRoom: string;
  nights: number;
  onRateChange: (roomId: string, rateId: string) => void;
}) => {
  const [selectedId, setSelectedId] = useState(room.products[0].rateId.value);
  const [selectedRoom, setSelectedRoom] = useState(room.products[0]);
  const [lowestRoomPrice, setLowestRoomPrice] = useState<{
    fiat: FiatPrice;
    points: AccountPrice;
  }>();
  const [openAmenitiesModal, setOpenAmenitiesModal] = useState(false);
  const icons = useTenantIcons();
  const { t, formatFiatCurrency } = useI18nContext();
  const inputId = useId();
  const { matchesMobile: isMobile } = useDeviceTypes();
  const numberOfRoom = useSelector(getRooms);

  const onChange = useCallback(
    (selectedRate: RoomProduct) => {
      const selectedRateId = selectedRate.rateId.value;
      setSelectedRoom(selectedRate);
      setSelectedId(selectedRateId);
      if (room.roomInfo.roomId !== currSelectedRoom) {
        onSelectedRoomChange(room.roomInfo.roomId);
      }
      onRateChange(room.roomInfo.roomId, selectedRateId);
    },
    [currSelectedRoom, onRateChange, onSelectedRoomChange, room.roomInfo.roomId]
  );

  useEffect(() => {
    setSelectedId(room.products[0].rateId.value);

    // sort product with price ascending
    room.products.sort((a, b) => a.total.fiat.value - b.total.fiat.value);
    if (room.products.length) {
      // setLowestRoomPrice(getLowestRoomPrice(room.products[0]));
      setLowestRoomPrice({
        fiat: room.products[0].total.fiat,
        points: getLowestRoomPrice(room.products[0].total),
      });
    }
  }, [room.products]);

  // TODO - once room.roomInfo.media is consistently available in metadata, we should probably stop using images from genericGuestRoomMediaUrls here
  const roomMediaUrls = room.roomInfo.media.map((asset) => asset.url);
  const genericGuestRoomMediaUrls =
    useSelector(getSelectedLodgingGroupedMedia)?.GuestRoom?.map(
      (asset) => asset.url
    ) ?? [];

  const isProductChecked = (product) => {
    return (
      currSelectedRoom === room.roomInfo.roomId &&
      selectedId === product.rateId.value
    );
  };

  const addSpace = (title: string) => title.replace(/([A-Z])/g, " $1").trim();
  const trackEvent = useTrackEvents();

  return (
    <div
      className={clsx(
        "RoomCard",
        { mobile: isMobile },
        { selected: currSelectedRoom === room.roomInfo.roomId }
      )}
      id={room.roomInfo.roomId}
    >
      <div>
        <ImageCarousel
          carouselImagesUrls={
            roomMediaUrls.length !== 0
              ? roomMediaUrls
              : genericGuestRoomMediaUrls
          }
          className={clsx("RoomCard-carousel")}
        />
        <div className="RoomCard-content">
          <div className="RoomCard-info">
            <div className="title">{room.roomInfo.name}</div>
            <div className="RoomCard-quickInfo-container">
              {/* 
              * Removing maxAdults for now as it's not accurate
              *
              {room.roomInfo.maxAdults ? (
                <div className="RoomCard-quickInfo">
                  <img src={icons.grayGuest} alt="" />
                  {t("roomFitsGuests", {
                    count: room.roomInfo.maxAdults,
                  })}
                </div>
              ) : null} */}
              <div className="RoomCard-quickInfo">
                <img src={icons.bed} alt="" />
                {room.roomInfo.beds.description}
              </div>
            </div>
            {room.products[0].loyaltyProgramCode && (
              <div>
                <HotelLoyaltyChip
                  loyaltyCategory={room.products[0].loyaltyProgramCode}
                />
              </div>
            )}
            <ActionLink
              className="RoomCard-amenities-text"
              content={t("viewDetailsAndAmenities")}
              onClick={() => {
                setOpenAmenitiesModal(true);

                const roomRate =
                  room.products?.find(isProductChecked) || room.products?.[0];
                trackEvent(
                  LodgingShopTrackingEvents.hotel_viewed_bed_types,
                  ProductType.Hotel,
                  {
                    ...room.trackingPropertiesV2.properties,
                    ...roomRate?.trackingPropertiesV2?.properties,
                    breakfast_included_shown:
                      room?.roomInfo?.amenities?.filter(
                        (a) => a.amenity === "FreeBreakfast"
                      )?.length > 0,
                  },
                  [
                    room?.trackingPropertiesV2.encryptedProperties,
                    roomRate?.trackingPropertiesV2?.encryptedProperties,
                  ]
                );
              }}
            />
          </div>

          {lowestRoomPrice?.fiat && (
            <Box className="room-price">
              <Typography
                className="per-night"
                dangerouslySetInnerHTML={{
                  __html: t("commBank.lodgingShopDetails.perNightPrice", {
                    priceWithSymbol: formatFiatCurrency(
                      {
                        currencyCode: lowestRoomPrice.fiat.currencyCode,
                        currencySymbol: lowestRoomPrice.fiat.currencySymbol,
                        value: lowestRoomPrice.fiat.value / nights,
                      },
                      {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 0,
                      }
                    ),
                  }),
                }}
              />
              <Box className="total">
                {lowestRoomPrice.points ? (
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: t("commBank.totalPrice", {
                        cashPriceWithSymbol: formatFiatCurrency(
                          lowestRoomPrice.fiat,
                          {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 0,
                          }
                        ),
                        numberOfNights: nights,
                        pointPrice: numberWithCommas(
                          lowestRoomPrice.points?.value
                        ),
                      }),
                    }}
                  />
                ) : (
                  <div>
                    {t("priceForNights", {
                      price: formatFiatCurrency(lowestRoomPrice.fiat, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 0,
                      }),
                      count: nights,
                    })}
                  </div>
                )}
              </Box>
            </Box>
          )}

          <fieldset id={room.roomInfo.roomId} className="product-container">
            <Typography className="product-list">
              {t("lodgingShopDetails.chooseCancellationPolicy")}
            </Typography>
            {room.products.map((product, i) => (
              <div
                className="RoomCard-product"
                key={`RoomCard-product-${product.rateId.value}`}
                onClick={() => onChange(product)}
              >
                <div
                  className={clsx("radio-checker", {
                    checked: isProductChecked(product),
                  })}
                  onClick={() => onChange(product)}
                >
                  <ApacIcon
                    name={
                      isProductChecked(product)
                        ? ApacIconName.RadioChecked
                        : ApacIconName.RadioUnchecked
                    }
                  />
                </div>

                <div className="product" id={inputId}>
                  <div className="product-name">
                    {addSpace(
                      product.cancellationPolicyV2.CancellationPolicyV2
                    )}
                  </div>
                  {product.cancellationPolicyV2.primaryText !==
                    "Non-refundable" && (
                    <div className="product-description">
                      {product.cancellationPolicyV2.primaryText}
                    </div>
                  )}
                </div>

                <div>
                  <Typography className="extra-price">
                    +
                    {product?.total?.fiat && lowestRoomPrice?.fiat
                      ? formatFiatCurrency({
                          ...product.total.fiat,
                          value:
                            product.total.fiat.value -
                            lowestRoomPrice.fiat.value,
                        })
                      : "$0"}
                  </Typography>
                </div>
              </div>
            ))}
          </fieldset>
        </div>
      </div>

      <DetailsAndAmenitiesModal
        open={openAmenitiesModal}
        onClose={() => setOpenAmenitiesModal(false)}
        {...room.roomInfo}
      />

      {!isMobile && (
        <div className="room-card-button-container">
          <Slot id="room-card-policy" product={selectedRoom} />
        </div>
      )}
    </div>
  );
};
