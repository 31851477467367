import { ApacDesktopPopupModal, ApacIcon, ApacIconName } from "@commbank/ui";
import { Dealness, Prediction } from "@b2bportal/air-shopping-api";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { CallState, VIEWED_FORECAST } from "@hopper-b2b/types";
import {
  IPriceWatchOptInState,
  PriceForecast,
  PricePredictionCard,
} from "@hopper-b2b/ui";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLowestPriceInRewards } from "../../constants";
import { getViewedForecastProperties } from "../../reducer";
import { PriceWatchCard } from "../PriceWatchCard";
import "./DesktopPricePredictionSection.styles.scss";
import { PriceDropProtectionContent } from "./PriceDropProtectionContent/PriceDropProtectionContent";
import { PricePredictionDetails } from "./PricePredictionDetails";
import { PriceWatchDetails } from "./PriceWatchDetails";
import { DesktopPricePredictionConnectorProps as DesktopPricePredictionSectionConnectorProps } from "./container";
import { getRecommendationTitle } from "../MobilePricePrediction/MobilePricePredictionSection";

export interface DesktopPricePredictionSectionProps
  extends DesktopPricePredictionSectionConnectorProps {
  prediction: Prediction;
  isRoundTrip: boolean;
  className?: string;
  onOpenPrediction?: () => void;
}

export const DesktopPricePredictionSection = ({
  prediction,
  rewardsKey,
  className,
  isRoundTrip,
  createWatchCallState,
  listWatchCallState,
  isWatching,
  createWatch,
  deleteWatch,
  setCreateWatchCallState,
  onOpenPrediction,
}: DesktopPricePredictionSectionProps) => {
  const { t, formatFiatCurrency } = useI18nContext();

  const PRICE_FORECAST_TITLES = [
    { title: t("priceForecast.greatTitle"), dealness: Dealness.great },
    { title: t("priceForecast.goodTitle"), dealness: Dealness.good },
    { title: t("pricePrediction.neutralScaleTitle"), dealness: Dealness.fair },
    { title: t("priceForecast.waitTitle"), dealness: Dealness.wait },
  ];

  const [openPredictionModal, setOpenPredictionModal] = useState(false);
  const [openPriceDropModal, setOpenPriceDropModal] = useState(false);
  const [openPriceWatchModal, setOpenPriceWatchModal] = useState(false);
  const [hasViewedForecast, setHasViewedForecast] = useState(false);
  const viewedForecastProperties = useSelector(getViewedForecastProperties);

  const getOptInState = () => {
    if (
      createWatchCallState === CallState.InProcess ||
      listWatchCallState === CallState.InProcess
    ) {
      return IPriceWatchOptInState.InProcess;
    } else if (isWatching) {
      return IPriceWatchOptInState.Watching;
    } else if (createWatchCallState === CallState.Failed) {
      return IPriceWatchOptInState.Failure;
    } else {
      return IPriceWatchOptInState.NotWatching;
    }
  };

  useEffect(() => {
    if (prediction && !hasViewedForecast && viewedForecastProperties) {
      trackEvent({
        eventName: VIEWED_FORECAST,
        properties: { ...viewedForecastProperties },
      });
      setHasViewedForecast(true);
    }
  }, [prediction, hasViewedForecast, viewedForecastProperties]);

  return (
    <div
      className={clsx("desktop-price-prediction-section-container", className)}
    >
      <Box className="prediction-container">
        <PricePredictionCard
          className={clsx("current-price")}
          title={""}
          subtitle={""}
          content={
            <Box>
              <Typography className="current-lowest">
                {t("pricePrediction.currentPrice")}
              </Typography>
              {!!prediction?.lowestPrice && (
                <Typography className="lowest-price-content">
                  <span className="lowest-price-cash bold-text">
                    {formatFiatCurrency(prediction?.lowestPrice?.fiat)}
                  </span>
                  {rewardsKey &&
                  prediction?.lowestPrice?.accountSpecific[rewardsKey] ? (
                    <span>
                      {" or "}
                      <span className="bold-text">
                        {getLowestPriceInRewards(
                          prediction?.lowestPrice,
                          rewardsKey
                        )}
                      </span>
                      {!isRoundTrip
                        ? t("pricePrediction.lowestPriceOneWaySuffix")
                        : t("pricePrediction.lowestPriceRoundTripSuffix")}
                    </span>
                  ) : (
                    ``
                  )}
                </Typography>
              )}
              <Typography className="current-subtitle">
                {getRecommendationTitle(prediction, t)}
                <ApacIcon
                  name={ApacIconName.InfoOutlined}
                  onClick={() => {
                    if (onOpenPrediction) {
                      onOpenPrediction();
                    }
                    setOpenPredictionModal(true);
                  }}
                />
              </Typography>
            </Box>
          }
        />
        <PriceForecast
          items={PRICE_FORECAST_TITLES}
          dealness={prediction.dealness}
        />
      </Box>

      <PriceWatchCard
        onPriceDropClick={
          prediction?.priceDropProtection?.PriceDropProtection === "IsEligible"
            ? () => setOpenPriceDropModal(true)
            : undefined
        }
        onWatchClick={() =>
          isWatching ? deleteWatch() : setOpenPriceWatchModal(true)
        }
        isWatching={isWatching}
      />

      <ApacDesktopPopupModal
        open={openPredictionModal}
        onClose={() => setOpenPredictionModal(false)}
        className="desktop-flight-shop-prediction-details-modal desktop-flight-shop-modal"
        contentClassName="modal-content"
      >
        <PricePredictionDetails
          onClose={() => setOpenPredictionModal(false)}
          onClickWatch={() => setOpenPriceWatchModal(true)}
          isOneway={!isRoundTrip}
          prediction={prediction}
        />
      </ApacDesktopPopupModal>

      <ApacDesktopPopupModal
        open={openPriceDropModal}
        onClose={() => setOpenPriceDropModal(false)}
        className="desktop-flight-price-drop-protection-modal desktop-flight-shop-modal"
        contentClassName="modal-content"
      >
        <PriceDropProtectionContent
          onClose={() => setOpenPriceDropModal(false)}
          prediction={prediction}
        />
      </ApacDesktopPopupModal>

      <ApacDesktopPopupModal
        open={openPriceWatchModal}
        onClose={() => setOpenPriceWatchModal(false)}
        className="desktop-flight-price-watch-modal desktop-flight-shop-modal"
        contentClassName="modal-content"
      >
        <PriceWatchDetails
          onClose={(completed) => {
            setOpenPriceWatchModal(false);
            setCreateWatchCallState(CallState.NotCalled);

            // Close price prediction if watch was successful
            if (completed) {
              setOpenPredictionModal(false);
            }
          }}
          onWatchThisTrip={(email, options) => createWatch(email, options)}
          optInState={getOptInState()}
        />
      </ApacDesktopPopupModal>
    </div>
  );
};
