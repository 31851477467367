import { WalletOffers } from "@hopper-b2b/wallet";
import {
  getWalletBestOffers,
  getWalletOffers,
  getHasWalletOffers,
  hasSelectedCreditOffer,
  getTotalWalletSavings,
  getSelectedOffer,
  getWalletCreditOffer,
  getWalletOffersCallState,
} from "../reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedCreditOffer,
  setSelectedWalletOffer,
} from "../actions/actions";
import { useI18nContext } from "@hopper-b2b/i18n";
import "./styles.scss";
import { useFetchWalletOffers } from "../../shop/hooks/fetchWalletOffers";
import { useEffect } from "react";
import { selectedTripSelector } from "../../shop/reducer";
import { CallState } from "@hopper-b2b/types";

interface WalletOffersPageProps {
  goToNextStep?: (replace?: boolean) => void;
}

export const WalletOfferPage = ({ goToNextStep }: WalletOffersPageProps) => {
  const { t } = useI18nContext();
  const hasWalletOffers = useSelector(getHasWalletOffers);
  const dispatch = useDispatch();

  const offers = useSelector(getWalletOffers);
  const selectedOffer = useSelector(getSelectedOffer);
  const bestOffer = useSelector(getWalletBestOffers);
  const useCreditOffer = useSelector(hasSelectedCreditOffer);
  const creditOffer = useSelector(getWalletCreditOffer);

  const walletSavings = useSelector(getTotalWalletSavings);

  const fetchWalletOffers = useFetchWalletOffers();
  const selectedTrip = useSelector(selectedTripSelector);

  const callState = useSelector(getWalletOffersCallState);

  // If wallet offers are not fetched, fetch it.
  useEffect(() => {
    if (callState === CallState.NotCalled) {
      const tripId = selectedTrip.tripId;
      const fareId = selectedTrip.returnFareId
        ? selectedTrip.returnFareId
        : selectedTrip.outgoingFareId;
      fetchWalletOffers(tripId, fareId);
    }
  }, []);

  // If valid offer does not exist go to next step
  useEffect(() => {
    if (
      (!hasWalletOffers && callState === CallState.Success) ||
      callState === CallState.Failed
    ) {
      goToNextStep(true);
    }
  }, [callState, goToNextStep, hasWalletOffers]);

  return (
    <div className="wallet-offers-container">
      <WalletOffers
        offers={offers}
        headerSubtitle={""}
        ctaLabel={t("walletOffers.nextStepButton")}
        handleContinue={() => goToNextStep()}
        setSelectedWalletOffer={(id) => {
          const offer = id ? offers.find((o) => o.id === id) : undefined;
          dispatch(setSelectedWalletOffer(offer));
        }}
        setSelectedCreditOffer={(bool) => {
          dispatch(setSelectedCreditOffer(bool));
        }}
        creditOffer={creditOffer}
        bestOffer={bestOffer}
        useCreditOffer={useCreditOffer}
        totalSavings={walletSavings}
        selectedOffer={selectedOffer}
      />
    </div>
  );
};
