import { useEffect } from "react";
import { useFetchRooms } from "../../hooks/useFetchRooms";
import { RoomSelect } from "../RoomSelect";
import { CallState } from "@hopper-b2b/types";
import { useSessionContext } from "@hopper-b2b/utilities";
import { useSelector } from "react-redux";
import { getShopCallState } from "../../reducer/selectors";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ShopPage } from "../ShopPage/component";

export const RoomSelectPage = () => {
  const { t } = useI18nContext();

  const { isLoggedIn } = useSessionContext();

  const shopCallState = useSelector(getShopCallState);

  const fetchRooms = useFetchRooms();

  useEffect(() => {
    if (shopCallState === CallState.NotCalled) {
      fetchRooms();
    }
  }, [fetchRooms, isLoggedIn, shopCallState]);

  return (
    <ShopPage title={t("roomSelectionTitle")}>
      <RoomSelect />
    </ShopPage>
  );
};
