import { ExchangeActionEnum } from "@b2bportal/air-exchange-api";
import { ExchangeForTypeEnum } from "@hopper-b2b/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface SearchInitialState {
  exchangeFor: ExchangeForTypeEnum;
  outboundSelection: ExchangeActionEnum;
  returnSelection?: ExchangeActionEnum;
}

const SLICE_NAME = "exchange/search";

const initialState: SearchInitialState = {
  exchangeFor: ExchangeForTypeEnum.roundTrip,
  outboundSelection: ExchangeActionEnum.Keep,
  returnSelection: undefined,
};

const searchSlice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    setExchangeFor(state, action: PayloadAction<ExchangeForTypeEnum>) {
      state.exchangeFor = action.payload;
    },
    setOutboundSelection(state, action: PayloadAction<ExchangeActionEnum>) {
      state.outboundSelection = action.payload;
    },
    setReturnSelection(state, action: PayloadAction<ExchangeActionEnum>) {
      state.returnSelection = action.payload;
    },
  },
});

export { initialState as searchInitialState };
export const { setExchangeFor, setOutboundSelection, setReturnSelection } =
  searchSlice.actions;
export default searchSlice.reducer;
