import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  setOpenModal,
  setSelectedFlight,
  populateTripQueryParams,
} from "../../../TripsList/actions/actions";
import {
  getAirlinesMap,
  getAirportMap,
  getSelectedFlight,
  getTripsFilter,
} from "../../../TripsList/reducer";
import { FlightItineraryDetails } from "./component";
import { MyTripsFilter } from "@hopper-b2b/types";

const mapStateToProps = (state: IStoreState) => ({
  flight: getSelectedFlight(state),
  airportMap: getAirportMap(state),
  airlineMap: getAirlinesMap(state),
  isPastTrips: getTripsFilter(state) === MyTripsFilter.PAST_TRIPS,
});

const mapDispatchToProps = {
  setSelectedFlight,
  setOpenModal,
  populateTripQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightItineraryDetailsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightItineraryDetails = connector(
  withRouter(FlightItineraryDetails)
);
